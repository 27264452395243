import * as React from 'react';
import { useInput } from '../../Hooks';
import { getConfig } from '../../utils/config';
import { winnreNoAuth } from '../../utils/axios';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Modal from '../../Components/Organisms/Modal';
import GrClose from '@meronex/icons/gr/GrClose';
import TiTick from '@meronex/icons/ti/TiTick';

let interval = null;

const OfflineDealLanding = () => {
    const config = getConfig();
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [timeLeftToResend, setTimeLeftToResend] = React.useState(0);
    const [voucherCode, setVoucherCode] = React.useState(
        queryParams.get('voucherCode')
    );
    const [loading, setLoading] = React.useState(false);
    const [otpUserHash, setOtpUserHash] = React.useState(
        queryParams.get('userHash')
    );
    const [isOtpSent, setIsOtpSent] = React.useState(false);
    const [otp, setOtp] = React.useState('');
    const [customerDetails, setCustomerDetails] = React.useState(null);
    const [modalMessage, setModalMessage] = React.useState(null);

    const startTimer = React.useCallback(() => {
        interval = setInterval(() => {
            if (timeLeftToResend >= 1) {
                setTimeLeftToResend((prevState) => prevState - 1);
            } else if (timeLeftToResend < 1) {
                setTimeLeftToResend(0);
            }
        }, 1000);
    }, [timeLeftToResend]);

    React.useEffect(() => {
        if (timeLeftToResend < 1 && interval) {
            clearInterval(interval);
        }
        if (timeLeftToResend === 59) {
            startTimer();
        }
    }, [timeLeftToResend]);

    React.useEffect(() => {
        if (interval && !isOtpSent) {
            clearInterval(interval);
        }
    }, [isOtpSent]);

    React.useEffect(() => {
        if (queryParams.has('userHash') || queryParams.has('voucherCode')) {
            queryParams.delete('userHash');
            queryParams.delete('voucherCode');
            history.replace({
                search: queryParams.toString()
            });
        }
        window?.webengage?.user?.logout();
        if (otpUserHash && voucherCode) {
            window?.webengage?.user?.login(otpUserHash);
            window?.webengage?.track('VoucherQRScanned', { voucherCode });
        }
    }, []);

    const {
        value: name,
        isValid: nameIsValid,
        hasError: nameHasError,
        valueChangeHandler: nameChangedHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetNameInput
    } = useInput((value) => value.trim() !== '');

    const {
        value: store,
        isValid: storeIsValid,
        hasError: storeHasError,
        valueChangeHandler: storeChangedHandler,
        inputBlurHandler: storeBlurHandler,
        reset: resetStoreInput
    } = useInput(() => true);

    const resetData = () => {
        resetNameInput();
        resetStoreInput();
        setCustomerDetails(null);
        setOtp('');
        setOtpUserHash(null);
        setVoucherCode(null);
        setIsOtpSent(false);
        setModalMessage(null);
    };

    const onSubmit = ({ resendOTP }) => {
        if (!isOtpSent || resendOTP) {
            setTimeLeftToResend(59);
            setLoading(true);
            winnreNoAuth({
                method: 'GET',
                url:
                    '/winnre/vendor/deals/validate-voucher-redemption?userHash=' +
                    otpUserHash
            })
                .then((res) => {
                    if (res.status === 'FAILED') {
                        setLoading(false);
                        toast.error('Something Went Wrong');
                        return;
                    }
                    if (voucherCode && !resendOTP) {
                        window?.webengage?.track('VoucherOTPRequested', {
                            voucherCode,
                            store
                        });
                    }
                    setIsOtpSent(true);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                    toast.error('Something Went Wrong');
                });
        } else {
            setLoading(true);
            winnreNoAuth({
                method: 'POST',
                url: `/winnre/vendor/deals/redeem-voucher?userHash=${otpUserHash}&otp=${otp}&voucherCode=${voucherCode}&employeeName=${name}&store=${store}`
            })
                .then((res) => {
                    if (res.status === 'FAILED') {
                        setLoading(false);
                        setModalMessage('REPEAT:' + res.message);
                        return;
                    }
                    window?.webengage?.track('VoucherOTPVerified', {
                        voucherCode,
                        voucherStatus: 'REDEEMED'
                    });
                    window?.webengage?.user?.logout();
                    setCustomerDetails(res);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                    toast.error('Something Went Wrong');
                });
        }
    };

    return (
        <form className="form-control p-6  relative ">
            <div
                style={{
                    backgroundColor: `${config?.primaryColor + '66'}`,
                    borderRadius: '10px'
                }}
                className="p-2 max-w-md w-full bg-blue-400 shadow-lg rounded-lg mx-auto"

            >
                {!voucherCode || !otpUserHash ? (
                    <>
                    <div className="py-10 w-full bg-white shadow-lg rounded-lg mx-auto">    
                        <p className="text-center py-10 flex-wrap">
                            Please open this link using a QR code to continue...
                        </p>
                    </div>
                    </>
                ) : customerDetails ? (
                    <>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="191.301"
                            height="219.08"
                            viewBox="0 0 191.301 219.08"
                        >
                            <g
                                id="Group_3239"
                                data-name="Group 3239"
                                transform="translate(-105.85 -265)"
                            >
                                <circle
                                    id="Ellipse_1530"
                                    data-name="Ellipse 1530"
                                    cx="6"
                                    cy="6"
                                    r="6"
                                    transform="translate(112 292)"
                                    fill="#fcc71a"
                                />
                                <circle
                                    id="Ellipse_1531"
                                    data-name="Ellipse 1531"
                                    cx="4"
                                    cy="4"
                                    r="4"
                                    transform="translate(236 269)"
                                    fill="#15519e"
                                />
                                <circle
                                    id="Ellipse_1532"
                                    data-name="Ellipse 1532"
                                    cx="6"
                                    cy="6"
                                    r="6"
                                    transform="translate(201 286)"
                                    fill="#1cb0bd"
                                />
                                <path
                                    id="_9310131_star_sparkle_icon"
                                    data-name="9310131_star_sparkle_icon"
                                    d="M20.546,11.913c-.12,0-.083-.363-.913-.442-.049,0-.5-.035-1.251-.154a5.5,5.5,0,0,1-2.735-1.178,4.922,4.922,0,0,1-1.215-1.56,9.264,9.264,0,0,1-.734-2.2,21.969,21.969,0,0,1-.429-4.735c0-.089,0-.207,0-.3a.407.407,0,0,0-.417-.436c-.288-.023-.523.152-.523.353s0,.31,0,.4a26.457,26.457,0,0,1-.264,3.679,9.372,9.372,0,0,1-1.25,3.757,4.72,4.72,0,0,1-2.771,1.963,11.954,11.954,0,0,1-2.991.409c-.863.006-.641.408-.641.408,0,.429.641.51.641.51L6,12.424a10.688,10.688,0,0,1,1.961.3,4.884,4.884,0,0,1,2.351,1.314A6.607,6.607,0,0,1,11.786,16.9a17.965,17.965,0,0,1,.524,3.466c.062.812.071,1.636.087,2.358,0,0,.03.287.414.275s.4-.275.4-.275a24.22,24.22,0,0,1,.512-5.653A6.163,6.163,0,0,1,15.452,13.8,4.8,4.8,0,0,1,17.4,12.728a5.6,5.6,0,0,1,.88-.218,5.781,5.781,0,0,1,.751-.114,10.56,10.56,0,0,0,1.27-.153C20.546,12.218,20.4,11.913,20.546,11.913Z"
                                    transform="translate(101.454 325.363)"
                                    fill="#7700ed"
                                />
                                <path
                                    id="_9310131_star_sparkle_icon-2"
                                    data-name="9310131_star_sparkle_icon"
                                    d="M20.546,11.913c-.12,0-.083-.363-.913-.442-.049,0-.5-.035-1.251-.154a5.5,5.5,0,0,1-2.735-1.178,4.922,4.922,0,0,1-1.215-1.56,9.264,9.264,0,0,1-.734-2.2,21.969,21.969,0,0,1-.429-4.735c0-.089,0-.207,0-.3a.407.407,0,0,0-.417-.436c-.288-.023-.523.152-.523.353s0,.31,0,.4a26.457,26.457,0,0,1-.264,3.679,9.372,9.372,0,0,1-1.25,3.757,4.72,4.72,0,0,1-2.771,1.963,11.954,11.954,0,0,1-2.991.409c-.863.006-.641.408-.641.408,0,.429.641.51.641.51L6,12.424a10.688,10.688,0,0,1,1.961.3,4.884,4.884,0,0,1,2.351,1.314A6.607,6.607,0,0,1,11.786,16.9a17.965,17.965,0,0,1,.524,3.466c.062.812.071,1.636.087,2.358,0,0,.03.287.414.275s.4-.275.4-.275a24.22,24.22,0,0,1,.512-5.653A6.163,6.163,0,0,1,15.452,13.8,4.8,4.8,0,0,1,17.4,12.728a5.6,5.6,0,0,1,.88-.218,5.781,5.781,0,0,1,.751-.114,10.56,10.56,0,0,0,1.27-.153C20.546,12.218,20.4,11.913,20.546,11.913Z"
                                    transform="translate(276.604 325.363)"
                                    fill="#7700ed"
                                />
                                <circle
                                    id="Ellipse_1536"
                                    data-name="Ellipse 1536"
                                    cx="4"
                                    cy="4"
                                    r="4"
                                    transform="translate(171 265)"
                                    fill="red"
                                />
                                <path
                                    id="_9310131_star_sparkle_icon-3"
                                    data-name="9310131_star_sparkle_icon"
                                    d="M26.545,16c-.164,0-.114-.5-1.252-.606-.067,0-.692-.048-1.715-.212a7.541,7.541,0,0,1-3.75-1.616,6.751,6.751,0,0,1-1.667-2.14,12.705,12.705,0,0,1-1.006-3.012,30.13,30.13,0,0,1-.589-6.494c0-.122.006-.284.006-.408A.559.559,0,0,0,16,.915c-.4-.032-.717.209-.717.484s0,.425,0,.554A36.285,36.285,0,0,1,14.925,7a12.854,12.854,0,0,1-1.714,5.152,6.473,6.473,0,0,1-3.8,2.692,16.4,16.4,0,0,1-4.1.561c-1.183.008-.879.559-.879.559,0,.589.879.7.879.7l1.281.039a14.658,14.658,0,0,1,2.689.409,6.7,6.7,0,0,1,3.224,1.8,9.062,9.062,0,0,1,2.027,3.925,24.639,24.639,0,0,1,.718,4.753c.085,1.114.1,2.244.119,3.234,0,0,.041.394.568.378s.552-.378.552-.378a33.217,33.217,0,0,1,.7-7.753,8.452,8.452,0,0,1,2.37-4.477,6.584,6.584,0,0,1,2.667-1.476,7.686,7.686,0,0,1,1.208-.3,7.929,7.929,0,0,1,1.03-.157,14.483,14.483,0,0,0,1.742-.209C26.545,16.418,26.339,16,26.545,16Z"
                                    transform="translate(239.454 291.219)"
                                    fill="#fcc71a"
                                />
                                <path
                                    id="_9310131_star_sparkle_icon-4"
                                    data-name="9310131_star_sparkle_icon"
                                    d="M26.545,16c-.164,0-.114-.5-1.252-.606-.067,0-.692-.048-1.715-.212a7.541,7.541,0,0,1-3.75-1.616,6.751,6.751,0,0,1-1.667-2.14,12.705,12.705,0,0,1-1.006-3.012,30.13,30.13,0,0,1-.589-6.494c0-.122.006-.284.006-.408A.559.559,0,0,0,16,.915c-.4-.032-.717.209-.717.484s0,.425,0,.554A36.285,36.285,0,0,1,14.925,7a12.854,12.854,0,0,1-1.714,5.152,6.473,6.473,0,0,1-3.8,2.692,16.4,16.4,0,0,1-4.1.561c-1.183.008-.879.559-.879.559,0,.589.879.7.879.7l1.281.039a14.658,14.658,0,0,1,2.689.409,6.7,6.7,0,0,1,3.224,1.8,9.062,9.062,0,0,1,2.027,3.925,24.639,24.639,0,0,1,.718,4.753c.085,1.114.1,2.244.119,3.234,0,0,.041.394.568.378s.552-.378.552-.378a33.217,33.217,0,0,1,.7-7.753,8.452,8.452,0,0,1,2.37-4.477,6.584,6.584,0,0,1,2.667-1.476,7.686,7.686,0,0,1,1.208-.3,7.929,7.929,0,0,1,1.03-.157,14.483,14.483,0,0,0,1.742-.209C26.545,16.418,26.339,16,26.545,16Z"
                                    transform="translate(144.529 291.219)"
                                    fill="#4d78eb"
                                />
                                <path
                                    id="Path_6070"
                                    data-name="Path 6070"
                                    d="M-11190.579,17451.926l-27.5-31.309-5.526-9.254-2.683-11.592,1.334-9.434,3.546-10.316,5.959-8.4,7.865-6.645,7.787-3.41,9.223-2.551,11.415,1.176,13.279,4.785,11.147,12.271,5.037,13.086v17.764l-5.037,9.549-11.147,13.715-17.968,20.561,4.688,9.4h-15.9Z"
                                    transform="translate(11391 -17051)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_6071"
                                    data-name="Path 6071"
                                    d="M-11147.15,17490.154l4.808-9.684-28.34-32.51-1.731-8.109,11.523-14.168,7.274-8.689,3.487-8.184,1.111-6.693v-13.4l5.654-1.4h7.257l7.1,1.4,7.5,2.543,6.737,4.465,3.814,4.2,4.043,5.3,2.776,6.676,3.015,10.092v8.975l-3.015,8.875-2.776,6.605-6.444,7.441-8.15,9.33-7.5,7.379-5.061,6.211-2.035,3.652,4.569,8.742Z"
                                    transform="translate(11391 -17051)"
                                    fill="#da867d"
                                />
                                <path
                                    id="Path_6072"
                                    data-name="Path 6072"
                                    d="M-11226.293,17490.154h-15.482l5.247-9.58-28.694-32.83-4.191-8.885-2.418-12.41v-9.17l4.271-8.361,4.4-7.463,6.458-6.721,8.384-4.6,8.374-2.031,5.316-1.238h8.339l1.777,1.238-1.777,8.793v5.979l1.777,6.041,4,8.361,4.156,5.434,4.839,5.678,3.136,3.525,4.457,4.389,5.24,6.277-6.377,8.77-25.8,29.223Z"
                                    transform="translate(11391 -17051)"
                                    fill="#69aef8"
                                />
                                <g
                                    id="Balloons"
                                    transform="translate(46.493 248.09)"
                                >
                                    <path
                                        id="Path_6061"
                                        data-name="Path 6061"
                                        d="M155.3,349.45a.929.929,0,0,0-.929.929V389.62a.929.929,0,1,0,1.858,0V350.379A.929.929,0,0,0,155.3,349.45Z"
                                        transform="translate(-44.379 -155.059)"
                                        fill="#1e247e"
                                        stroke="#1e247e"
                                        stroke-width="1"
                                    />
                                    <path
                                        id="Path_6062"
                                        data-name="Path 6062"
                                        d="M357.679,349.45a.929.929,0,0,0-.929.929V389.62a.929.929,0,1,0,1.858,0V350.379A.929.929,0,0,0,357.679,349.45Z"
                                        transform="translate(-152.768 -155.059)"
                                        fill="#1e247e"
                                        stroke="#1e247e"
                                        stroke-width="1"
                                    />
                                    <path
                                        id="Path_6063"
                                        data-name="Path 6063"
                                        d="M254.929,289.557a.929.929,0,0,0-.929.929v67.057a.929.929,0,1,0,1.858,0V290.486A.929.929,0,0,0,254.929,289.557Z"
                                        transform="translate(-97.738 -122.982)"
                                        fill="#1e247e"
                                        stroke="#1e247e"
                                        stroke-width="1"
                                    />
                                    <path
                                        id="Path_6064"
                                        data-name="Path 6064"
                                        d="M418.957,204.462a26.869,26.869,0,0,1-.762,6.245.929.929,0,0,0,1.805.44,28.735,28.735,0,0,0,.814-6.677.929.929,0,0,0-.925-.933h0a.929.929,0,0,0-.929.925Z"
                                        transform="translate(-185.662 -76.912)"
                                        fill="#fff"
                                        stroke="#fff"
                                        stroke-width="1"
                                    />
                                    <path
                                        id="Path_6065"
                                        data-name="Path 6065"
                                        d="M385.17,157.74a29.409,29.409,0,0,0-27.491-17.81.929.929,0,1,0,0,1.858,27.6,27.6,0,0,1,25.8,16.711,30.53,30.53,0,0,1,1.564,4.335.929.929,0,0,0,1.791-.495A32.386,32.386,0,0,0,385.17,157.74Z"
                                        transform="translate(-152.768 -42.846)"
                                        fill="#fff"
                                        stroke="#fff"
                                        stroke-width="1"
                                    />
                                    <path
                                        id="Path_6066"
                                        data-name="Path 6066"
                                        d="M317.139,143.537h0a.929.929,0,0,0-.929.925,26.881,26.881,0,0,1-.762,6.245.929.929,0,0,0,1.805.439,28.74,28.74,0,0,0,.814-6.677A.929.929,0,0,0,317.139,143.537Z"
                                        transform="translate(-130.632 -44.778)"
                                        fill="#14897b"
                                        stroke="#14897b"
                                        stroke-width="1"
                                    />
                                    <path
                                        id="Path_6067"
                                        data-name="Path 6067"
                                        d="M283.184,103.516a.93.93,0,0,0,.9-1.176,32.386,32.386,0,0,0-1.66-4.6,29.409,29.409,0,0,0-27.491-17.81.929.929,0,0,0,0,1.858,27.6,27.6,0,0,1,25.8,16.711,30.535,30.535,0,0,1,1.564,4.335A.929.929,0,0,0,283.184,103.516Z"
                                        transform="translate(-97.738 -10.711)"
                                        fill="#14897b"
                                        stroke="#14897b"
                                        stroke-width="1"
                                    />
                                    <circle
                                        id="Ellipse_1537"
                                        data-name="Ellipse 1537"
                                        cx="2.747"
                                        cy="2.747"
                                        r="2.747"
                                        transform="translate(158.814 93.361) rotate(-45)"
                                        fill="#14897b"
                                        stroke="#14897b"
                                        stroke-width="1"
                                    />
                                    <circle
                                        id="Ellipse_1538"
                                        data-name="Ellipse 1538"
                                        cx="2.747"
                                        cy="2.747"
                                        r="2.747"
                                        transform="translate(148.38 91.32) rotate(-13.282)"
                                        fill="#14897b"
                                        stroke="#14897b"
                                        stroke-width="1"
                                    />
                                    <path
                                        id="Path_6068"
                                        data-name="Path 6068"
                                        d="M253.433,144.193a.93.93,0,0,0-1.279.3,14.3,14.3,0,0,1-24.329,0,.929.929,0,1,0-1.58.977,16.154,16.154,0,0,0,27.489,0A.929.929,0,0,0,253.433,144.193Z"
                                        transform="translate(-82.799 -45.055)"
                                        fill="#14897b"
                                        stroke="#14897b"
                                        stroke-width="1"
                                    />
                                    <path
                                        id="Path_6069"
                                        data-name="Path 6069"
                                        d="M240.879,111.1A38.961,38.961,0,0,0,204.91,87.8a40.809,40.809,0,0,0-9.687,1.155,40.785,40.785,0,0,0-2.065-5.72,39.412,39.412,0,0,0-71.937,0,40.836,40.836,0,0,0-1.98,5.411,41.046,41.046,0,0,0-8.323-.846,39.412,39.412,0,0,0-29.407,65.651l25.418,28.486-6.02,10.428h20.018l-6.02-10.427,25.418-28.486a42,42,0,0,0,2.88-3.607.929.929,0,1,0-1.514-1.077,40.166,40.166,0,0,1-2.752,3.446L112.628,181.7l5.083,8.8H104.128l5.083-8.8L82.9,152.21A37.554,37.554,0,0,1,110.92,89.653a39.138,39.138,0,0,1,7.853.79,38.883,38.883,0,0,0,9.01,35.139L153.2,154.067l-6.02,10.428H167.2l-6.02-10.427L186.6,125.582a38.883,38.883,0,0,0,9.082-34.828,38.9,38.9,0,0,1,9.232-1.1,37.554,37.554,0,0,1,28.021,62.557L206.619,181.7l5.083,8.8H198.119l5.083-8.8L176.89,152.21a39.988,39.988,0,0,1-3.018-3.824.929.929,0,1,0-1.527,1.059,41.818,41.818,0,0,0,3.158,4l25.418,28.486-6.02,10.428H214.92L208.9,181.933l25.418-28.486a38.961,38.961,0,0,0,6.561-42.351Zm-55.667,13.248L158.9,153.833l5.083,8.8H150.4l5.083-8.8L129.17,124.345a37.554,37.554,0,1,1,56.041,0Z"
                                        fill="#1e247e"
                                        stroke="#1e247e"
                                        stroke-width="1"
                                    />
                                </g>
                            </g>
                        </svg>

                        <h1 className=" font-Poppins font-bold text-lg mt-2 leading-5 ">
                            Hurray!
                        </h1>
                        <h1 className="text-center mt-2">
                            Free Gift Redeemed for{' '}
                            <span className="font-semibold">
                                {customerDetails.name}
                            </span>
                            ,{' '}
                            <span className="font-semibold">
                                {customerDetails.phoneNumber}
                            </span>
                        </h1>
                        <small className="mt-6">
                            Scan more QR codes to continue
                        </small>
                    </>
                ) : isOtpSent ? (
                    <>
                    <div className="p-10 max-w-md w-full bg-white shadow-lg rounded-lg mx-auto">                        <p className={'text-lg text-center mb-4'}>
                    <h2 className="text-lg font-medium text-center mb-4">
                        Enter the OTP received on Customer's Number
                    </h2>
                        </p>{' '}
                        <input
                            type="text"
                            id="otp"
                            className='inputfield w-full mt-2 p-2 border border-gray-300 rounded text-gray-900 text-base placeholder-gray-400 focus:outline-none focus:border-indigo-500'
                            placeholder="One Time Password"
                            onChange={(e) => setOtp(e.target.value)}
                            value={otp}
                            autoComplete="off"
                            inputMode="numeric"
                            autoFocus
                        />
                                <div className="my-4">
                                <button
                                     className="sub-btn text-white hover:bg-primary-focus disabled:opacity-80 disabled:hover:bg-gray-400 disabled:bg-gray-400 disabled:cursor-not-allowed text-center"
                                    onClick={() =>
                                        onSubmit({ resendOTP: true })
                                    }
                                >
                                    Validate OTP
                                </button>
                                <p className="opacity-75 mb-2 text-sm">
                                    Didn't Receive OTP?{' '}
                                    {timeLeftToResend > 0 ? (
                                        <b>
                                            Request Again in 00:
                                            {timeLeftToResend < 10
                                                ? '0' + timeLeftToResend
                                                : timeLeftToResend}
                                        </b>
                                    ) : (
                                        <b
                                            className="opacity-75 link"
                                            onClick={() =>
                                                onSubmit({ resendOTP: true })
                                            }
                                        >
                                            Request Again
                                        </b>
                                    )}
                                </p>
                            </div>
                             <div className="mt-4 pt-1 pb-1">
                             <div className="flex items-center justify-center px-4 sm:px-6 md:px-8"> 
                                <p className="text-center sm:whitespace-normal max-w-md mx-auto sm:max-w-full overflow-wrap break-word">In case of any issues, please reach out to <Link to="/contact-us" className="blue-500 text-[#16519E] text-[15px] underline"> customer care</Link></p>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                    <div className="p-10 max-w-md w-full bg-white shadow-lg rounded-lg mx-auto">
                        <div className="my-4">
                            <div className="text-center mb-6">
                                <div className="text-center text-gray-500 mb-4 text-justify">
                                    Please fill in the below details and trigger an OTP to the customer’s registered mobile number. Please hand over the Housewarming Gift to the customer on successful verification.
                                </div>
                            </div>
                            <div className="my-4">
                                <h2 className="text-lg font-medium">
                                    Employee Name<span className="text-red-400"> *</span>
                                </h2>
                                <input
                                    type="text"
                                    placeholder="Employee Name*"
                                    className="inputfield w-full mt-2 p-2 border border-gray-300 rounded text-gray-900 text-base placeholder-gray-400 focus:outline-none focus:border-indigo-500"
                                    value={name}
                                    onBlur={nameBlurHandler}
                                    onChange={nameChangedHandler}
                                    autoFocus
                                />
                                {nameHasError && (
                                    <p className="text-left text-sm font-semibold text-red-500">
                                        Please enter a valid name.
                                    </p>
                                )}
                            </div>
                            <div className="my-4">
                                <h2 className="text-lg font-medium">Store Location</h2>
                                <input
                                    type="text"
                                    placeholder="Store Location"
                                    className="inputfield w-full mt-2 p-2 border border-gray-300 rounded text-gray-900 text-base placeholder-gray-400 focus:outline-none focus:border-indigo-500"
                                    value={store}
                                    onBlur={storeBlurHandler}
                                    onChange={storeChangedHandler}
                                />
                                {storeHasError && (
                                    <p className="text-left text-sm font-semibold text-red-500">
                                        Please enter a valid store.
                                    </p>
                                )}
                            </div>
                            {!customerDetails && voucherCode && otpUserHash && (
                                <>
                                    <button
                                        type="button"
                            className="sub-btn text-white hover:bg-primary-focus disabled:opacity-80 disabled:hover:bg-gray-400 disabled:bg-gray-400 disabled:cursor-not-allowed text-center"
                            // onClick={referHandler}
                                        disabled={
                                            loading ||
                                            (customerDetails
                                                ? false
                                                : isOtpSent
                                                ? otp.length != 4
                                                : !nameIsValid || !storeIsValid)
                                        }
                            onClick={
                                customerDetails
                                    ? () => resetData()
                                    : () => onSubmit({})
                            }
                                    >
                            {customerDetails
                                ? 'Redeem Again'
                                : isOtpSent
                                ? 'Validate OTP'
                                : 'Send OTP'}
                                    </button>
                                    {isOtpSent && (
                            <p className="opacity-75 mb-2 text-sm">
                                            Didn't Receive OTP?{' '}
                                            {timeLeftToResend > 0 ? (
                                                <b>
                                                    Request Again in 00:
                                        {timeLeftToResend < 10
                                            ? '0' + timeLeftToResend
                                            : timeLeftToResend}
                                                </b>
                                            ) : (
                                                <b
                                        className="opacity-75 link"
                                        onClick={() =>
                                            onSubmit({ resendOTP: true })
                                        }
                                                >
                                                    Request Again
                                                </b>
                                            )}
                                        </p>
                                    )}
                                </>
                            )}
                            
                            <div className="mt-4 pt-1 pb-1">
                                <div className="flex items-center justify-center px-4 sm:px-6 md:px-8 flex-wrap"> 
                                <p className="text-center sm:whitespace-normal max-w-md mx-auto sm:max-w-full overflow-wrap break-word">In case of any issues, please reach out to <Link to="/contact-us" className="blue-500 text-[#16519E] text-[15px] underline"> customer care</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>


                    </>
                )}

            </div>
            <Modal
                visible={!!modalMessage}
                onClick={() => setModalMessage(null)}
            >
                <div className="flex justify-end px-4 ">
                    <GrClose
                        onClick={() => setModalMessage(null)}
                        className={` h-5 w-5 cursor-pointer `}
                    />
                </div>
                <div className="relative py-6 flex flex-col">
                    <div className="flex flex-col justify-center items-center">
                        {modalMessage?.includes('REPEAT:') ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-auto p-0 m-0"
                                viewBox="0 0 81.452 95.027"
                            >
                                <g
                                    id="_299112_warning_shield_icon"
                                    data-name="299112_warning_shield_icon"
                                    transform="translate(-3 -1030.4)"
                                >
                                    <path
                                        id="Path_171"
                                        data-name="Path 171"
                                        d="M3,1031.4v45.251c0,19,16.436,36.2,40.726,45.251,24.291-9.05,40.726-26.246,40.726-45.251V1031.4Z"
                                        transform="translate(0 3.525)"
                                        fill="#95a5a6"
                                    />
                                    <path
                                        id="Path_172"
                                        data-name="Path 172"
                                        d="M3,1030.4v45.25c0,19.005,16.436,36.2,40.726,45.251,24.291-9.05,40.726-26.246,40.726-45.251V1030.4Z"
                                        transform="translate(0 0)"
                                        fill="#ecf0f1"
                                    />
                                    <path
                                        id="Path_173"
                                        data-name="Path 173"
                                        d="M3,1030.4v45.25c0,19.005,16.436,36.2,40.726,45.251v-90.5Z"
                                        transform="translate(0 0)"
                                        fill="#bdc3c7"
                                    />
                                    <path
                                        id="Path_174"
                                        data-name="Path 174"
                                        d="M5,1032.4v36.2c0,15.386,12.784,28.961,31.676,36.2,18.892-7.24,31.676-20.815,31.676-36.2v-36.2Z"
                                        transform="translate(7.05 7.051)"
                                        fill="#f39c12"
                                    />
                                    <path
                                        id="Path_175"
                                        data-name="Path 175"
                                        d="M12,1032.4v72.4c18.892-7.24,31.676-20.815,31.676-36.2v-36.2Z"
                                        transform="translate(31.726 7.051)"
                                        fill="#f1c40f"
                                    />
                                    <path
                                        id="Path_176"
                                        data-name="Path 176"
                                        d="M15.525,1034.4A4.394,4.394,0,0,0,11,1038.925l2.263,31.675h4.525l2.263-31.675A4.394,4.394,0,0,0,15.525,1034.4Zm0,40.726A4.394,4.394,0,0,0,11,1079.651a4.685,4.685,0,0,0,4.525,4.525,4.685,4.685,0,0,0,4.525-4.525A4.394,4.394,0,0,0,15.525,1075.126Z"
                                        transform="translate(28.201 14.1)"
                                        fill="#34495e"
                                    />
                                </g>
                            </svg>
                        ) : (
                            <div className=" rounded-full bg-green-500 p-2 md:p-4">
                                <TiTick className=" text-white h-7 w-7 md:h-8 md:w-8" />
                            </div>
                        )}
                        <p
                            className={`
                        ${
                            config?.deals.dealsSection.subAdditionalTextClass
                                ? config?.deals.dealsSection
                                      .subAdditionalTextClass
                                : ''
                        } z-10 mt-2 mx-auto w-[90%] lg:w-[80%] text-center font-semibold `}
                        >
                            {modalMessage?.includes('REPEAT:')
                                ? modalMessage.split('REPEAT:')[1]
                                : modalMessage}
                        </p>
                    </div>
                </div>
            </Modal>

        </form>
    );
};

export default OfflineDealLanding;
