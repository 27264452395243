import React from 'react';
import { winnreAuth } from '../../../../../utils/axios';
import { Button } from '@material-ui/core';
import { LoadingSpinner } from '../../../../../Components/Molecules/LoadingUI';

function FourthStep({
    checkKycVerificationStatus,
    checkBankVerificationStatus,
    setStep,
    getUserAccountSummary
}) {
    const [loading, setLoading] = React.useState(true);
    const [rewardLoading, setRewardLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [details, setDetails] = React.useState(null);

    const claimReward = () => {
        setRewardLoading(true);
        winnreAuth({
            method: 'POST',
            url: '/winnre/user/customer/claim-rewards'
        }).then((res) => {
            setRewardLoading(false);
            if (res?.status == 'FAILED') {
                setStep(2); // Add code what happens if req fails
            }
            setStep(2);
            getUserAccountSummary();
           
            // console.log('CLAIM REWARD', res);
        });
    };
    const getBankAndAdhaarDetails = () => {
        setDetails(null);
        setLoading(true);
        setError(false);
        winnreAuth({
            method: 'GET',
            url: `/winnre/user/bank-account`
        })
            .then((res) => {
                setDetails(res);
                return winnreAuth({
                    method: 'GET',
                    url: `/winnre/cp/document`
                });
            })
            .then((res) => {
                const kyc = (res || []).find(
                    (doc) => doc.type === 'AADHAAR'
                )?.documentNumber;
                if (kyc) {
                    setDetails((prevState) => ({
                        ...prevState,
                        kycNumber: kyc
                    }));
                }
                setLoading(false);
            })
            .catch((err) => {
                setError(true);
                setLoading(false);
            });
    };

    React.useEffect(() => {
        getBankAndAdhaarDetails();
    }, []);

    return loading ? (
        <LoadingSpinner />
    ) : !loading && details ? (
        <div className="px-3 md:px-8 py-5">
            {/* <h1 className=" font-Poppins font-bold text-lg ">
                                    Confirm your details
                                </h1> */}
            <h2>
                Your Aadhar number is{' '}
                <span className="font-semibold">
                    {'XXXXXXXX' +
                        String(details.kycNumber || '')?.substring(7, 11)}
                </span>
            </h2>
            <h2>
                Your bank account number is{' '}
                <span className="font-semibold"> {details.accountNumber} </span>
            </h2>
            <h2>
                Bank account beneficiary name is{' '}
                <span className="font-semibold uppercase">
                    {details.beneficiaryName}
                </span>{' '}
            </h2>
            <div className="mt-4 flex flex-wrap gap-x-3">
                <button
                    className="w-full md:w-auto bg-primary text-white rounded font-bold px-2 h-10 mt-3"
                    onClick={() => checkKycVerificationStatus(true)}
                >
                    Update KYC
                </button>
                <button
                    className="w-full md:w-auto bg-primary text-white rounded font-bold px-2 h-10 mt-3"
                    onClick={() => checkBankVerificationStatus(true)}
                >
                    Update Bank Account
                </button>
                <button
                    className="w-full md:w-auto bg-primary text-white rounded font-bold px-2 h-10 mt-3"
                    onClick={() => claimReward()}
                >
                    {rewardLoading ? 'loading' : 'I Confirm'}
                </button>
            </div>
        </div>
    ) : (
        <>
            <Button onClick={() => getBankAndAdhaarDetails()}>Try Again</Button>
        </>
    );
}

export default FourthStep;
