import axios from 'axios';
import { getConfig } from './config';
import { decryptData } from './sec';
var _ = require('lodash');

const config = getConfig();
const ENV = window.reloy_env.ENV || process.env.REACT_APP_ENV;
const replaceCDNValues = (object) => {
    const newObject = _.clone(object);

    _.each(object, (val, key) => {
        if (val && typeof val === 'string' && val.includes('cdn.loyalie.in')) {
            newObject[key] = val.replace('cdn.loyalie.in', 'cdn.loyalie.in');
        } else if (typeof val === 'object' || typeof val === 'array') {
            newObject[key] = replaceCDNValues(val);
        }
    });

    return newObject;
};

const getBaseUrl = () => {
    switch (ENV) {
        case 'PRODUCTION':
            return 'https://prod.loyalie.in';
        case 'UAT':
            return 'https://uat.loyalie.in';
        default:
            return 'https://dev.loyalie.in';
    }
};

export const setWinnreAuthToken = (authToken) => {
    winnreAuth.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
};

export const deleteWinnreAuthToken = () => {
    delete winnreAuth.defaults.headers.common['Authorization'];
};

const cmsClient = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        'Client-Id': `${config?.id}`
    }
});

const winnreAuth = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        'Client-Id': `${config?.clientId}`
    }
});

const winnreNoAuth = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        'Client-Id': `${config?.clientId}`
    }
});

cmsClient.interceptors.response.use(
    function (response) {
        const newRes = { ...response };
        newRes.data = replaceCDNValues(response.data);
        return newRes;
    },
    function (err) {
        return err;
    }
);

winnreNoAuth.interceptors.response.use(
    async function (response) {
        let decrypt = await decryptData(response.data);
        return replaceCDNValues(JSON.parse(decrypt));
    },
    async function (error) {
        let decrypt = await decryptData(error.response.data);
        return JSON.parse(decrypt);
    }
);

winnreAuth.interceptors.response.use(
    async function (response) {
        try {
            let decrypt = await decryptData(response.data);
            return replaceCDNValues(JSON.parse(decrypt));
        } catch (err) {
            // console.log(err);
        }
    },
    async function (error) {
        let decrypt = await decryptData(error.response.data);
        return JSON.parse(decrypt);
    }
);

export { winnreAuth, winnreNoAuth, cmsClient };
