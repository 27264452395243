import React, { useEffect, useState } from 'react';
import {
    UserProjectForm,
    UserTypeForm,
    CreateOnBehalForm,
    AfterFormSubmittedChoice
} from '../index';
import DisplayMessage from '../DisplayMessage';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CurrentStepIndicator from '../CurrentStepIndicator';
import { winnreNoAuth } from '../../../../utils/axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function ReferralForm({
    config,
    setOpenScratchCard,
    rewardData,
    enquiryCities,
    websiteUserRole,
    userStatus,
    userTypeFormData,
    setUserTypeFormData
}) {
    const [playInfluencerVideo, setPlayInfluencerVideo] = useState(false);

    // FINAL STATES
    const [currentStep, setCurrentStep] = useState('');
    const [projectToRefer, setProjectToRefer] = useState(null);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [displayMessage, setDisplayMessage] = useState(
        'You are not eligible to refer. Kindly contact the support team for further assistance'
    );

    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const project = queryParams.get('projectId');

    const fetchProjectDetails = async (id) => {
        try {
            const response = await winnreNoAuth.get(
                `/winnre/user/project/${id}`
            );
            console.log('API response status:', response.status);
            console.log('API response data:', response);

            if (response) {
                const projectDetails = response.data;
                history.push(
                    `/gift-and-earn${window.location.search}&city=${response.city}`
                );
                //setProjectToRefer(projectDetails);
                setDisplayMessage('afterFormSubmittedChoice');
            }
        } catch (error) {
            console.error('error', error);
        }
    };

    useEffect(() => {
        if (project) {
            fetchProjectDetails(project);
        }
    }, [project]);

    //TESTING PHASE

    const isEmployeeType =
        websiteUserRole == 'EMPLOYEE' ||
        websiteUserRole == 'RELATIONSHIP_MANAGER';

    const allowAllEmployeeReferrals =
        config?.userTypes.salesAdmin && config?.userTypes.employee;

    const pendingUser = userStatus === 'PENDING_APPROVAL';

    useEffect(() => {
        // setCurrentStep('afterFormSubmittedChoice')
        if (websiteUserRole === 'CUSTOMER') {
            if (userStatus === 'PENDING_APPROVAL') {
                setCurrentStep('displayMessage');

                setDisplayMessage(
                    'Your registration is being reviewed. Once it is approved, you will become eligible for our referral rewards and campaigns!'
                );
                return;
            }
            setCurrentStep('userProjectForm');
            return;
        }

        if (isEmployeeType) {
            if (allowAllEmployeeReferrals) {
                setCurrentStep('userTypeForm');
                return;
            }
            if (
                config.userTypes.salesAdmin === false &&
                config.userTypes.employee === false
            ) {
                setCurrentStep('displayMessage');
                return;
            }
            if (websiteUserRole == 'EMPLOYEE') {
                config.userTypes.employee
                    ? setCurrentStep('userProjectForm')
                    : setCurrentStep('displayMessage');
            }
            if (websiteUserRole == 'RELATIONSHIP_MANAGER') {
                config.userTypes.salesAdmin
                    ? setCurrentStep('createOnBehalfForm')
                    : setCurrentStep('displayMessage');
            }
            // config?.userTypes.salesAdmin
            //     ? setCurrentStep('createOnBehalfForm')
            //     : setCurrentStep('userProjectForm');
            return;
        }
        if (websiteUserRole === 'INFLUENCER') {
            setCurrentStep('influencerTypeForm');
            return;
        }
        if (websiteUserRole === 'SITE_VISITOR') {
            if (config?.allowSVReferral) {
                setCurrentStep('userProjectForm');
                return;
            } else {
                setCurrentStep('displayMessage');
                setDisplayMessage(
                    'You are not a booked customer. Once you complete your booking, you will become eligible for our referral rewards and campaigns!'
                );
            }
        }
    }, [websiteUserRole]);

    return (
        <section
            // className={`${'min-h-screen lg:min-h-[80vh] lg:h-[80vh]'} relative`}
            className="h-full relative"
        >
            <div
                className={`flex flex-col lg:flex-row w-full h-full  border border-gray-300  rounded-lg justify-center`}
            >
                {/* Left SIDE */}
                <div className="relative w-full lg:w-[70%] xl:w-[75%] pb-5 lg:pb-0">
                    {/* {currentStep === 'displayMessage' && (
                        <h1 className="text-black text-3xl lg:pl-6 xl:pl-12 font-semibold mt-7 lg:mt-0">
                            Referral Form
                        </h1>
                    )} */}

                    {currentStep === 'displayMessage' && (
                        <DisplayMessage
                            config={config}
                            content={displayMessage}
                        />
                    )}
                    {currentStep === 'userTypeForm' && (
                        <UserTypeForm
                            userRole={websiteUserRole || userTypeFormData}
                            setUserRole={setUserTypeFormData}
                            userTypeFormData={userTypeFormData}
                            changeCurrentStep={setCurrentStep}
                            config={config}
                        />
                    )}
                    {currentStep === 'createOnBehalfForm' && (
                        <CreateOnBehalForm
                            changeCurrentStep={setCurrentStep}
                            customerDetails={customerDetails}
                            setCustomerDetails={setCustomerDetails}
                            userRole={userTypeFormData}
                            config={config}
                        />
                    )}
                    {currentStep === 'userProjectForm' && (
                        <UserProjectForm
                            setPlayInfluencerVideo={setPlayInfluencerVideo}
                            playInfluencerVideo={playInfluencerVideo}
                            userRole={userTypeFormData}
                            changeCurrentStep={setCurrentStep}
                            config={config}
                            currentStep={currentStep}
                            projectToRefer={projectToRefer}
                            setProjectToRefer={setProjectToRefer}
                            customerData={customerDetails}
                            customerID={customerDetails?.customerId}
                            employeeTypeSelected={userTypeFormData}
                            enquiryCities={enquiryCities}
                        />
                    )}

                    {currentStep === 'afterFormSubmittedChoice' && (
                        <AfterFormSubmittedChoice
                            changeCurrentStep={setCurrentStep}
                            userRole={websiteUserRole || userTypeFormData}
                            setOpenScratchCard={setOpenScratchCard}
                            rewardData={rewardData}
                            config={config}
                        />
                    )}
                </div>

                {/* Right side */}
                {currentStep !== 'displayMessage' && (
                    <div className="w-full lg:w-[35%] bg-[#F0EFEF]  md:rounded-r-lg ">
                        <CurrentStepIndicator
                            setPlayInfluencerVideo={setPlayInfluencerVideo}
                            playInfluencerVideo={playInfluencerVideo}
                            userRole={userTypeFormData}
                            selectedProjectId={projectToRefer?.projectId}
                            changeCurrentStep={setCurrentStep}
                            referralConfig={config}
                            projectToRefer={projectToRefer}
                        />
                    </div>
                )}
            </div>
        </section>
    );
}

export default ReferralForm;
