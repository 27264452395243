import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function ChangingImage({
    imageArr,
    plainBannerImage,
    draggable,
    showDots,
    arrows,
    infinite,
    autoPlay,
    autoPlaySpeed,
    transitionDuration,
    customTransition,
    swipeable,
    classNameContainer
}) {
    const images = [
        {
            url: 'https://images.unsplash.com/photo-1639246105965-f53b618f15c9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'
        },
        {
            url: 'https://images.unsplash.com/photo-1638675304037-7c4347b851e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'
        },
        {
            url: 'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80'
        }
    ];
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <Carousel
            swipeable={!!swipeable}
            draggable={!!draggable}
            showDots={!!showDots}
            arrows={!!arrows}
            responsive={responsive}
            ssr={true}
            containerClass={
                classNameContainer ? classNameContainer : 'w-full p-0 h-full '
            } //p-0 added
            // itemClass="object-cover h-full w-full"
            infinite={!!infinite}
            // containerClass=" p-0 w-full"
            autoPlay={!!autoPlay}
            autoPlaySpeed={autoPlaySpeed}
            sliderClass=" h-full object-cover "
            transitionDuration={transitionDuration}
            customTransition={customTransition}
        >
            {plainBannerImage && imageArr?.length === 0 && (
                <div className=" w-full h-full ">
                    <img
                        className=" w-full h-full object-cover"
                        src={plainBannerImage}
                    />
                </div>
            )}

            {imageArr?.length > 1 &&
                imageArr.map((imgSrc) => (
                    <div className=" w-full h-full">
                        <img
                            className=" w-full h-full object-cover "
                            src={imgSrc.url}
                        />
                    </div>
                ))}
        </Carousel>
    );
}

export default ChangingImage;
