import React, { useState, useEffect } from 'react';
import { LoadingSpinner } from '../../../Components/Molecules/LoadingUI';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLocation } from 'react-router-dom';
import { authActions } from '../../../store/auth-slice';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { getConfig } from '../../../utils/config';
import RiArrowRightSLine from '@meronex/icons/ri/RiArrowRightSLine';
import { useHistory } from 'react-router-dom';
import AiOutlineEdit from '@meronex/icons/ai/AiOutlineEdit';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Modal from '../../../Components/Organisms/Modal';
import BsTrash from '@meronex/icons/bs/BsTrash';
import { winnreAuth } from '../../../utils/axios';
import { Splide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { useData } from './data';
import { APIErrorCondition } from '../../../constants';
import { toast } from 'react-toastify';
import MdcTicketPercentOutline from '@meronex/icons/mdc/MdcTicketPercentOutline';
import TiTick from '@meronex/icons/ti/TiTick';
import OfferLayout from '../Components/OfferLayout';
import DeletingContent from '../Components/DeletingContent';

function UserProfile() {
    const {
        isAuthenticated,
        profileCompletionPercentage,
        userName,
        userDetails,
        userId
    } = useSelector((state) => state.auth);
    const { email, birthDate, address } = useSelector(
        (state) => state.auth.userDetails
    );
    const history = useHistory();
    const config = getConfig();
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    let accessToken = queryParams.get('token');
    let deleteAccountQuery = queryParams.get('deleteAccount');
    let postLoginUrl = queryParams.get('postLoginUrl');

    const [updateBirthDate, setUpdateBirthDate] = useState(moment());
    const [openModal, setOpenModal] = useState(null);
    const [dobUpdatedMoreThanOnce, setDobUpdatedMoreThanOnce] = useState(false);
    const [updatingDOB, setUpdatingDOB] = useState(false);
    const {
        offers,
        loading,
        deleteClicked,
        setDeleteClicked,
        fetchUserOffers
    } = useData();

    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
    };
    const openPropertyOnboarding = () => {
        dispatch(authActions.openOnboardingModal());
    };

    useEffect(() => {
        if (deleteClicked && isAuthenticated) {
            fetchUserOffers();
        }
    }, [deleteClicked, isAuthenticated]);

    useEffect(() => {
        if (deleteAccountQuery) {
            setDeleteClicked(true);
        }
    }, [deleteAccountQuery]);

    useEffect(() => {
        if (postLoginUrl && !isAuthenticated) {
            openLoginModal();
        }
    }, [postLoginUrl]);

    const handleDateChange = (newValue) => {
        const formattedDate = newValue.format('YYYY-MM-DD');
        dispatch(
            authActions.setUserDetails({
                ...userDetails,
                birthDate: formattedDate
            })
        );
        setUpdateBirthDate(newValue);
    };

    const updateDOB = () => {
        const formattedDate = updateBirthDate.format('YYYY-MM-DD');
        setUpdatingDOB(true);
        winnreAuth({
            method: 'POST',
            url: `/winnre/user/on-board-data`,
            data: JSON.stringify({
                dateOfBirth: formattedDate
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                setUpdatingDOB(false);
                if (res?.status == APIErrorCondition) {
                    toast.error(res.message);
                } else {
                    dispatch(
                        authActions.setUserDetails({
                            fieldName: 'birthDate',
                            value: formattedDate
                        })
                    );
                    setDobUpdatedMoreThanOnce(true);
                    setOpenModal(null);
                }
            })
            .catch((error) => {
                console.log('Setting modal content to dobUpdateError');
            });
    };

    const deleteUserAccount = () => {
        if (!userId) {
            console.error('User ID not available');
            return;
        }
        winnreAuth({
            method: 'POST',
            url: `/winnre/admin/user/delete?id=${userId}`
        })
            .then((res) => {
                if (res?.status == APIErrorCondition) {
                    toast.error(res.message);
                } else {
                    setOpenModal('DELETE');
                    setTimeout(() => {
                        setOpenModal(null);
                        dispatch(authActions.logout());
                        history.push('/');
                    }, 5000);
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    const maxDate = moment().subtract(4, 'years');
    const minDate = moment().subtract(80, 'years');

    return (
        <>
            {!isAuthenticated && !accessToken && (
                <div className="py-12 flex justify-center">
                    <button
                        onClick={openLoginModal}
                        className="self-start bg-primary text-white py-2 px-6 rounded-lg hover:shadow-xl "
                    >
                        Login to access My Profile
                    </button>
                </div>
            )}
            {isAuthenticated && (
                <div className="w-full lg:pl-5 2xl:pl-7">
                    {deleteClicked ? (
                        <>
                            <div className="w-full border border-gray-300 rounded p-4">
                                <div className="flex flex-col lg:flex-row items-center justify-between">
                                    <div className="flex flex-col lg:flex-row items-center mb-4 lg:mb-0">
                                        <img
                                            src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                                            alt="User"
                                            className="object-cover rounded-full w-20 mr-4"
                                        />
                                        <div className="flex flex-col">
                                            <h1 className="font-bold text-lg">
                                                Delete your account?
                                            </h1>
                                            <p className="text-sm text-gray-700 leading-4">
                                                We are sorry to see you go!
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full lg:w-auto items-center justify-center md:flex-row lg:items-center lg:justify-between mt-4 lg:mt-0 lg:ml-4">
                                        <button
                                            onClick={() =>
                                                setDeleteClicked(false)
                                            }
                                            className="bg-primary text-white px-4 py-2 rounded mb-2 md:mb-0 lg:mr-2 w-full lg:w-auto"
                                        >
                                            Keep my account
                                        </button>
                                        <button
                                            onClick={deleteUserAccount}
                                            className="bg-red-500 text-white px-4 py-2 rounded w-full lg:w-auto"
                                        >
                                            Continue with Deletion
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-14 w-full text-center">
                                <div className="flex flex-col justify-center items-center">
                                    <MdcTicketPercentOutline className="text-[#15509E]  h-7 w-7" />
                                    <h2 className="text-xl font-semibold mb-4">
                                        View exclusive offers curated for you
                                    </h2>
                                </div>
                                {loading && <LoadingSpinner />}
                                {!loading && (
                                    <Splide
                                        options={{
                                            perPage: 3,
                                            perMove: 1,
                                            loop: true,
                                            snap: false,
                                            drag: 'free',
                                            arrow: false,
                                            pagination: true,
                                            breakpoints: {
                                                2560: {
                                                    perPage: 3
                                                },
                                                1024: {
                                                    perPage: 2
                                                },
                                                1000: {
                                                    perPage: 2,
                                                    pagination: false
                                                },
                                                750: {
                                                    perPage: 1,
                                                    pagination: false,
                                                    arrows: false
                                                },
                                                650: {
                                                    perPage: 1,
                                                    pagination: false,
                                                    arrows: false
                                                },
                                                495: {
                                                    perPage: 1,
                                                    gap: '8px',
                                                    pagination: true,
                                                    arrows: true
                                                },
                                                425: {
                                                    perPage: 1,
                                                    gap: '7px',
                                                    pagination: true,
                                                    arrows: true
                                                },
                                                375: {
                                                    perPage: 1,
                                                    gap: '7px',
                                                    pagination: true,
                                                    arrows: true
                                                }
                                            }
                                        }}
                                    >
                                        {offers?.map((deal) => (
                                            <OfferLayout deal={deal} />
                                        ))}
                                    </Splide>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="w-full">
                                <div className="flex justify-center">
                                    <div className="text-center ">
                                        <div className=" h-28 w-28 mx-auto relative mb-10  ">
                                            <img
                                                src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                                                className=" mx-auto object-center object-cover rounded-full z-10  "
                                            />
                                        </div>

                                        <h1 className="capitalize font-Poppins text-xl md:text-xl font-bold">
                                            Welcome, {userName}
                                        </h1>
                                        <h3 className=" font-Poppins pt-1 text-sm md:text-sm">
                                            Enhance security and tailor your
                                            experience by updating your details
                                        </h3>
                                    </div>
                                    {window.reloy_env.client_config.MY_PROFILE
                                        ?.ALLOW_ONBOARDING &&
                                        profileCompletionPercentage < 100 &&
                                        userDetails?.status == 'REGISTERED' && (
                                            <button
                                                onClick={openPropertyOnboarding}
                                                className="bg-info  text-white text-sm md:text-base rounded-[5px] flex justify-center items-center px-2 md:px-4 py-1 h-8 md:h-10 gap-x-1"
                                            >
                                                Complete My Profile
                                            </button>
                                        )}
                                </div>
                            </div>

                            <div className="grid grid-cols-1 lg:grid-cols-2 lg:pl-5 2xl:pl-7 gap-x-4 lg:gap-x-6 gap-y-7 leading-5 mt-10">
                                {/* BASIC INFO BOX */}
                                <div className="border rounded-[5px] relative">
                                    <div className="p-4 lg:p-5 lg:h-[228px]">
                                        <div className="flex justify-between items-start">
                                            <div>
                                                <h1 className="font-normal lg:text-2xl break-words">
                                                    Basic Information
                                                </h1>
                                                <p className="text-sm text-[#949a98] pb-3 lg:pb-5">
                                                    Access your essential
                                                    details here.
                                                </p>
                                            </div>
                                            <button
                                                onClick={() =>
                                                    setOpenModal('EDIT')
                                                }
                                                className="m-2"
                                            >
                                                <AiOutlineEdit className="inline text-xl" />
                                            </button>
                                        </div>

                                        <h2 className="text-sm text-[#949a98]">
                                            Name
                                        </h2>
                                        <h3 className="font-bold lg:text-lg break-words">
                                            {userName}
                                        </h3>

                                        <h2 className="text-sm text-[#949a98] pt-2 lg:pt-2">
                                            Date of Birth
                                        </h2>
                                        <h3 className="font-bold lg:text-lg break-words">
                                            {birthDate}
                                        </h3>
                                        {!birthDate && (
                                            <p className="text-xs text-gray-500 mt-1">
                                                Note: This can only be updated
                                                once.
                                            </p>
                                        )}
                                        <div className="flex justify-end">
                                            <button
                                                onClick={() =>
                                                    setDeleteClicked(true)
                                                }
                                                className="bg-transparent text-red-500 hover:text-red-700 flex items-center mb-2 justify-center font-semibold"
                                            >
                                                <BsTrash className="mr-1" />
                                                Delete Account?
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* PROFILE COMPLETION BOX */}
                                <div className="border rounded-[5px]">
                                    <div className="p-5 h-[200px] lg:h-[228px] ">
                                        <h1 className="font-normal lg:text-2xl break-words">
                                            Profile Completion
                                        </h1>
                                        <p className="text-sm text-[#949a98] pb-5">
                                            Track your profile's completion
                                            status.
                                        </p>
                                        <div className="flex items-center font-Poppins">
                                            <CircularProgressbarWithChildren
                                                styles={{
                                                    path: {
                                                        stroke: config?.primaryColor
                                                    }
                                                }}
                                                strokeWidth={9}
                                                className="w-24 lg:w-28 lg:h-28 text-primary"
                                                value={
                                                    profileCompletionPercentage
                                                }
                                            >
                                                <div className="font-Poppins text-center font-semibold text-xl xl:text-2xl">
                                                    {
                                                        profileCompletionPercentage
                                                    }
                                                    %
                                                </div>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                    </div>
                                </div>
                                {/* EMAIL BOX */}
                                <div className="border rounded-[5px]">
                                    <div className="p-5 h-[140px] lg:h-[168px]">
                                        <h1 className="font-normal lg:text-2xl break-words">
                                            Email Information
                                        </h1>
                                        <p className="text-sm text-[#949a98] pb-5">
                                            View your email details here.
                                        </p>

                                        <h2 className="text-sm text-[#949a98]">
                                            Email
                                        </h2>
                                        <h3 className="font-bold lg:text-lg break-words ">
                                            {email || '-'}
                                        </h3>
                                    </div>
                                </div>
                                {/* ADDRESS BOX */}
                                <div className="border rounded-[5px]">
                                    <div className="p-5 h-[140px] lg:h-[168px]">
                                        <h1 className="font-normal lg:text-2xl break-words">
                                            Address
                                        </h1>
                                        <p className="text-sm text-[#949a98] pb-5">
                                            Review and update your address
                                            details.
                                        </p>

                                        <h3 className="font-bold lg:text-lg break-words">
                                            {address?.line1 || address?.line2
                                                ? `${address?.line1} ${address.line2}`
                                                : '-'}
                                        </h3>
                                    </div>
                                </div>
                                {/* DOC SECTION */}
                                <div
                                    onClick={() => {
                                        history.push('/myProfile/docs');
                                    }}
                                    className="border rounded-[5px] px-8 py-4 lg:col-span-2 flex items-center justify-between hover:border-primary cursor-pointer"
                                >
                                    <div className="bg-gray-200 rounded-full p-2">
                                        <img
                                            src="https://cdn.loyalie.in/1698302580432_document_icon"
                                            className=" object-center object-cover rounded-full w-20 "
                                        />
                                    </div>

                                    <div className="mr-auto px-5">
                                        <h1 className="font-normal lg:text-2xl break-words">
                                            Document Center
                                        </h1>
                                        <p className="text-sm text-[#949a98]">
                                            Manage your documents here
                                        </p>
                                    </div>
                                    <RiArrowRightSLine className="inline text-primary text-2xl" />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
            <Modal
                visible={openModal != null}
                onClick={() => setOpenModal(null)}
            >
                {openModal == 'EDIT' ? (
                    !updatingDOB ? (
                        <div className="px-7 py-4">
                            <h1 className="font-semibold mb-2">
                                Update your DOB
                            </h1>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label=""
                                    value={updateBirthDate}
                                    onChange={handleDateChange}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    className="w-full"
                                    format="DD/MM/YYYY"
                                    renderInput={() => null}
                                    sx={{}}
                                    disabled={dobUpdatedMoreThanOnce}
                                />
                            </LocalizationProvider>
                            <p className="text-xs text-gray-500 mt-1">
                                Note: This can only be updated once.
                            </p>
                            <div className="flex justify-end">
                                <button
                                    onClick={updateDOB}
                                    className="bg-primary text-white px-6 py-2 rounded disabled:cursor-not-allowed disabled:bg-gray-200 "
                                    disabled={dobUpdatedMoreThanOnce}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="px-7 py-4 font-semibold flex flex-col justify-center items-center">
                            <div className=" animate-pulse rounded-full bg-green-500 p-2 md:p-4 mb-2">
                                <TiTick className=" text-white h-7 w-7 md:h-8 md:w-8" />
                            </div>
                            <p>Succesfully updating your Date of Birth</p>
                        </div>
                    )
                ) : (
                    <DeletingContent />
                )}
            </Modal>
        </>
    );
}

export default UserProfile;
