import React, { useState } from 'react';
import usePhoneInput from '../../../../Hooks/usePhoneInput';
import PhoneInput from 'react-phone-input-2';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import useInput from '../../../../Hooks/useInput';

import { RE_Pin } from '../../../../constants';
import FaMale from '@meronex/icons/fa/FaMale';
import FaFemale from '@meronex/icons/fa/FaFemale';
import { backArrowSvg } from '../../svg';

function StepThree({
    setStep,
    gender,
    onGenderChange,
    duration,
    onDurationChange,
    onMobileChange,
    birthDate,
    onBirthDateChange,
    onPinChange,
    onFormSubmit
}) {
   
    const durationTypes = [
        { value: '0-30 (Days)' },
        { value: '1-2 (Months)' },
        { value: '2-6 (Months)' }
    ];
    const maxDate = moment().subtract(10, 'years');
    const minDate = moment().subtract(100, 'years');
    const theme = useTheme();

    const {
        state: {
            isValid: mobileNumberIsValid,
            mobile,
            country,
            mobileHasError,
            isInputFocused
        },
        onChange,
        validator,
        setMobile,
        setIsTouched,
        getMobileWithoutCountryCode,
        setInputFocused
    } = usePhoneInput(onMobileChange);

    const {
        value: Pin,
        isValid: PinIsValid,
        hasError: PinHasError,
        valueChangeHandler: PinChangedHandler,
        inputBlurHandler: PinBlurHandler,
        reset: resetPin
    } = useInput((value) => RE_Pin.test(value));

    const formIsValid = PinIsValid;

    return (
        <div>
            <div className="flex text-lg font-sm justify-between py-5">
                <div
                    className="flex items-center gap-1 cursor-pointer"
                    onClick={() => setStep((prev) => prev - 1)}
                >
                 {backArrowSvg}
                    <button className="text-primary">Back to Step 2</button>
                </div>

                <p>
                    Step <span className="text-primary">3</span>
                    <span className="text-sm">/3</span>
                </p>
            </div>

            <div className="flex flex-col gap-4">
                <div className="pt-3 flex  items-center">
                    <h2 className="font-bold">
                        When are you planning to take loan ?
                    </h2>
                </div>

                <div className="flex flex-wrap justify-between items-center ">
                    {durationTypes.map((type) => (
                        <div
                            onClick={() => {
                                onDurationChange(type.value);
                            }}
                            className={`w-[30%] cursor-pointer border rounded-[10px] p-2 ${
                                duration === type.value ? 'bg-primary' : ''
                            }`}
                        >
                            <button
                                className={`${
                                    duration === type.value ? 'text-white' : ''
                                }`}
                            >
                                {type.value}
                            </button>
                        </div>
                    ))}
                </div>

                <div className="pt-3 flex  items-center">
                    <h2 className="font-bold">Other Details</h2>
                </div>
                <div className="flex flex-col gap-3">
<h1 className='font-medium'>Alternate mobile number</h1>
                    <div className="mb-2 h-12">
                        <PhoneInput
                            country={'in'}
                            countryCodeEditable={false}
                            containerClass={`bg-white h-10 rounded outline-none border-none ${
                                isInputFocused ? 'outline-none ' : ''
                            }`}
                            onlyCountries={[
                                'in',
                                'hk',
                                'id',
                                'kw',
                                'my',
                                'om',
                                'qa',
                                'sa',
                                'sg',
                                'ae',
                                'gb',
                                'eu',
                                'us',
                                'th'
                            ]}
                            containerStyle={{
                                width: '100%',
                                boxShadow: '0px',
                                border: 'none',
                                outline: 'none',
                                borderRadius: 'none',
                                marginTop: '-1.5px',
                                height: '100%'
                            }}
                            inputStyle={{
                                width: '100%',
                                height: '100%',

                                border: 'none',
                                borderRadius: 'none',
                                border: '2px solid #EBEAF2',
                                backgroundColor: '#FFFFFF'
                            }}
                            buttonStyle={{
                                backgroundColor: '#FFFFFF',
                                border: 'none',
                                border: '2px solid #EBEAF2'
                            }}
                            isValid={validator}
                            onChange={onChange}
                            value={mobile}
                            enableSearch
                            onBlur={() => {
                               setIsTouched(true);
                                setInputFocused(false);
                            }}
                            
                            onFocus={() => setInputFocused(true)}
                            inputClass=""
                        />
                        { mobileHasError && ( 
                            <div className='mb-6'>
                                <p className="text-left text-red-400 text-sm lg:text-base mt-1 ">
                                {`Please enter a valid number`}
                            </p>
                            </div>
                           
                        )}
                    </div>

                    <div className="flex flex-wrap justify-between mt-2">
                        <div className="flex w-[42%]  justify-between border rounded-[10px] p-3">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label=""
                                    value={birthDate}
                                    onChange={(newValue) => {
                                        onBirthDateChange(newValue);
                                    }}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    className="w-full"
                                    format="DD/MM/YYYY"
                                    slotProps={{
                                        textField: {
                                            placeholder: 'Enter DOB'
                                        }
                                    }}
                                    sx={{
                                        input: {
                                            fontSize: '14px',
                                            [theme.breakpoints.down('md')]: {
                                                fontSize: '14px'
                                            },
                                            // fontWeight: '600',
                                            padding: '14px',
                                            // fontFamily: 'Poppins'
                                            outline: 'none'
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&:hover fieldset': {
                                                // borderColor: '#bfbfbf'
                                                border: 'none'
                                            },
                                            '&.Mui-focused fieldset': {
                                                // borderColor: '#6B7280'
                                                border: 'none'
                                            }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className=" flex flex-1 w-[42%] justify-between gap-1 border rounded-[10px] p-3 ">
                            <div
                             onClick={() => {
                                onGenderChange('FEMALE');
                            }}
                                className={`flex items-center gap-1 cursor-pointer border rounded-[8px] p-3 ${
                                    gender === 'FEMALE'
                                        ? 'bg-primary && text-white'
                                        : ''
                                } `}
                            >
                                <FaFemale className='h-5'/>
                                <button
                                   
                                >
                                    {' '}
                                    Female
                                </button>
                            </div>

                            <div
                                className={`flex items-center gap-1 cursor-pointer border rounded-[8px] p-3 ${
                                    gender === 'MALE'
                                        ? 'bg-primary && text-white'
                                        : ''
                                } `}
                                onClick={() => {
                                    onGenderChange('MALE');
                                }}
                            >
                                <FaMale className='h-5'/>
                                <button
                                   
                                >
                                    Male
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="border rounded-[10px] p-3 ">
                        <input
                            className="outline-none"
                            type="text"
                            placeholder="PIN code*"
                            value={Pin}
                            onChange={(e) => {
                                PinChangedHandler(e);
                                onPinChange(e.target.value);
                            }}
                            onBlur={PinBlurHandler}
                        />
                      
                    </div>
                    <div className="flex justify-between text-sm">
                            {PinHasError && (
                                <p className="text-left text-red-400 text-sm lg:text-base">
                                    {`Please enter a valid pin`}
                                </p>
                            )}
                        </div>
                </div>

                <button
                    disabled={!formIsValid}
                    className={`w-full text-white p-3 rounded-[10px] disabled:cursor-not-allowed disabled:bg-[#adadad] bg-primary`}
                    onClick={() => {
                        onFormSubmit();
                    }}
                >
                    Submit
                </button>
            </div>
        </div>
    );
}

export default StepThree;
