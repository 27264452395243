import RiArrowRightSLine from '@meronex/icons/ri/RiArrowRightSLine'
import { SplideSlide } from '@splidejs/react-splide'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function OfferLayout({deal}) {
    const history = useHistory()
  return (
    <SplideSlide>
    <div
        onClick={() =>
            history.push(
                `/deals/${deal.id}`
            )
        }
        className="cursor-pointer min-w-[320px] mr-6 "
    >
        <div className="w-full h-[230px] relative">
            <img
                src={
                    deal.displayImageUrl
                }
                className="w-full h-full object-cover rounded "
            />
            <div className="absolute bg-white p-2 top-2 left-2 rounded font-semibold text-sm">
                Valid till:{' '}
                {moment(
                    deal.endDate
                ).format(
                    'Mo MMM, YYYY'
                )}
            </div>
        </div>
        <div className="text-left mt-2">
            <h1 className="font-semibold break-words">
                {deal.title}
            </h1>
            <button className="flex text-primary items-center font-semibold justify-center">
                Know More
                <RiArrowRightSLine className="text-primary text-xl" />
            </button>
        </div>
    </div>
</SplideSlide>
  )
}

export default OfferLayout
