import React from 'react';
import Select, { components } from 'react-select';
import BsArrowDown from '@meronex/icons/bs/BsArrowDown';

const Option = (props) => {
    // console.log(props)
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{' '}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const MultiValueRemove = (props) => {
    return null;
};

const MultiValueLabel = (props) => {
    console.log(props);
    return (
        <components.MultiValueLabel {...props}>
            <div>
                {props.children}
                {props.selectProps?.value[props.selectProps?.value.length - 1]
                    ?.label == props.children
                    ? ''
                    : ','}
            </div>
        </components.MultiValueLabel>
    );
};
const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <BsArrowDown />
        </components.DropdownIndicator>
    );
};

const IndicatorSeparator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <BsArrowDown />
        </components.DropdownIndicator>
    );
};

function CustomSelect({
    onChangeCallback,
    placeholder,
    className,
    options,
    selectedValues
}) {
    return (
        <div className={className}>
            <Select
                closeMenuOnSelect={false}
                isMulti
                components={{
                    Option,
                    MultiValueRemove,
                    MultiValueLabel,
                    IndicatorSeparator: () => null,
                    DropdownIndicator
                }}
                selectionType="text"
                options={options}
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                onChange={(e) => onChangeCallback(e)}
                placeholder={placeholder}
                isClearable={false}
                styles={{
                    multiValueLabel: (base) => ({
                        ...base,
                        backgroundColor: '#ffffff',
                        color: 'black'
                    }),
                    placeholder: (base) => ({
                        ...base,
                        fontWeight: 500,
                        color: 'black'
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '11px 8px'
                    }),
                    control: (base) => ({
                        ...base,
                        borderColor: '#DDDDDD',
                        borderRadius: '0.5rem'
                    })
                }}
                value={selectedValues}
                // {...otherProps}
            />
        </div>
    );
}

export default CustomSelect;
