import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import MuiAlert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import Property from '../../Molecules/Property/indexNew';
import ListTitle from '../../Molecules/ListTitle';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { propertyLayoutActions } from '../../../../store/property-layout-switch-slice';
import useData from './data';
import ProjectMapLoadingUI from '../../../../Components/Molecules/LoadingUI';
import MapBox from '../../Molecules/Map/mapbox';
import Pagination from '../../../../Components/Molecules/Pagination';
import { getConfig } from '../../../../utils/config';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PropertyMapView = (props) => {
    const isMapView = useSelector((state) => state.propertyLayout.isMapView);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const screenBelowLg = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();
    const config = getConfig();
    const {
        projects,
        projectsHeading,
        loadingProjects,
        totalPages,
        currentPage,
        onPageChange,
        propertyBackgroundColorWithOverlay,
        viewMapHandler,
        mapViewActive
    } = props;

    const [projectId, setProjectId] = useState(null);
    const { getProjectLocation, lng, lat, viewport, setViewport } =
        useData(projectId);

    useEffect(() => {
        if (screenBelowLg) {
            dispatch(propertyLayoutActions.switchPropertyLayout(false));
        }
    }, [screenBelowLg]);

    return (
        <div className="w-full">
            {loadingProjects && (
                <ProjectMapLoadingUI className="w-full flex items-start flex-col animate-bounce mt-7" />
            )}
            {!loadingProjects && (
                <div>
                    {projects?.length === 0 && !loadingProjects && (
                        <div className="flex">
                            <p
                                className={`z-10 p-2 lg:w-[45%] xl:w-[50%] ${config?.projects.moduleConfig?.fontTitle}`}
                                style={{
                                    backgroundColor:
                                    config.primaryColor,
                                    color: 'white'
                                }}
                            >
                                Sorry, No Projects To Show
                            </p>
                        </div>
                    )}
                    <div
                        className={`grid gap-x-10 pr-4 mb-5 h-[90vh] scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thin scrollbar-thumb-primary scrollbar-track-gray-200 overflow-y-scroll ${
                            isMapView
                                ? ' grid-cols-1 gap-y-5'
                                : 'lg:grid-cols-2 gap-y-10 md:gap-y-4 '
                        } z-10`}
                    >
                        {projects?.map((project, i) => (
                            <div key={i}>
                                <Property
                                    setProjectId={setProjectId}
                                    projectId={project.id}
                                    projectName={project.projectName}
                                    projectDescription={
                                        project?.projectDescription === null
                                            ? ''
                                            : project?.projectDescription
                                    }
                                    projectCity={project.city}
                                    projectState={project.state}
                                    projectPrice={project.price}
                                    projectLogo={project.projectLogo}
                                    projectBackground={project.projectBgImage}
                                    projectStatus={project.status}
                                    projectApartmentSize={
                                        project?.apartment.charAt(0) === '0'
                                            ? project?.apartment?.substring(2)
                                            : project?.apartment
                                    }
                                    setViewport={setViewport}
                                    viewport={viewport}
                                    getProjectLocation={getProjectLocation}
                                    onClick={viewMapHandler}
                                    mapViewActive={mapViewActive}
                                    propertyBackgroundColorWithOverlay={
                                        propertyBackgroundColorWithOverlay
                                    }
                                    currentPage={currentPage}
                                    projectLocation={project.projectLocation}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col lg:flex-row justify-between items-center mb-6">
                        <p className="font-semibold self-start mb-7 lg:mb-0 text-sm xl:text-base ">
                            Showing {currentPage} out of {totalPages}
                        </p>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={onPageChange}
                            scrollTo='project_section'
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PropertyMapView;
