import React from 'react';
import Modal from '../../Components/Organisms/Modal';
import GrClose from '@meronex/icons/gr/GrClose';
import { LoadingSpinner } from '../../Components/Molecules/LoadingUI';

const NotInterestedModal = ({
    setNotInterestedModalVisibility,
    showNotInterestedModal,
    updateStatus,
    loading
}) => {
    return (
        <Modal
            visible={showNotInterestedModal}
            onClick={() => {
                if (loading) return;
                setNotInterestedModalVisibility(false);
            }}
        >
            <div className="flex justify-end px-4 ">
                <GrClose
                    onClick={() => {
                        if (loading) return;
                        setNotInterestedModalVisibility(false);
                    }}
                    className={` h-5 w-5 cursor-pointer `}
                />
            </div>
            <div className="py-6 flex flex-col">
                <p
                    className={`
             z-10 mt-2 mx-auto w-[90%] lg:w-[80%] text-center `}
                >
                    Are you sure ?
                </p>
                <p
                    className={`
             z-10 mx-auto w-[90%] lg:w-[80%] text-center `}
                >
                    This deal will be marked as Not Interested.
                </p>
                <div className="flex flex-row align-items-center justify-center mt-2">
                    <button
                        onClick={() => updateStatus()}
                        className={
                            'bg-secondary justify-self-center px-5 mt-2 py-1.5 text-white text-center mr-2 rounded self-center'
                        }
                        disabled={loading}
                    >
                        {loading ? 'Loading...' : 'Mark as Not Interested'}
                    </button>

                    <button
                        onClick={() => setNotInterestedModalVisibility(false)}
                        className={
                            'bg-primary justify-self-center px-5 mt-2 py-1.5 text-white text-center mr-2 rounded self-center'
                        }
                        disabled={loading}
                    >
                        Continue with the deal
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default NotInterestedModal;
