import React, { useState } from 'react';
import AiOutlineDown from '@meronex/icons/ai/AiOutlineDown';
import BiArrowBack from '@meronex/icons/bi/BiArrowBack';
import { Stack, Button, Drawer, Divider, Modal } from '@mui/material';
import Scrollbar from './Scrollbar';
import PriceRange from './PriceRange';
import ApartmentToggle from './ApartmentToggle';
import CityList from './CityList';
import { getConfig } from '../../../../utils/config';
import useData from '../../Molecules/Filter/data';

export default function ProjectFilter({
    isOpenFilter,
    onResetFilter,
    applyFilter,
    onCloseFilter,
}) {
    const [cities, setCities] = useState([]);
    const [range, setRange] = useState([]);
    const [apartment, setApartment] = useState([]);

    const [pCities, setPCities] = useState([]);
    const [pRange, setPRange] = useState([0, 100]);
    const [pApartment, setPApartment] = useState([]);

    const [cityShow, setCityShow] = useState(true);
    const [rangeShow, setRangeShow] = useState(true);
    const [apartmentShow, setApartmentShow] = useState(true);
    const { apartmentTypesList, projectCityList } =
    useData();
    const config = getConfig()
    
    const cityUpdate = (cities) => {
        const city = cities
            .filter((city) => city.checked)
            .map((city) => city.name);
        setPCities(city);
        setCities(city);
    };

    const priceUpdate = (price) => {
        setPRange(price);
        const minPrice = 1000000 * price[0];
        const maxPrice = 1000000 * price[1];
        setRange([minPrice, maxPrice]);
    };

    const apartmentUpdate = (apartment) => {
        setPApartment(apartment);
        setApartment(apartment);
    };

    const applyFilters = () => {
        // WEBENGAGE for filters applied
        window?.webengage?.track('FilterApplied', {
            Layout: apartment.join(),
            Locations: cities.join(),
            Budget: `${range.length === 0 ? 0 : range[0]} - ${
                range.length === 0 ? 99999999999 : range[1]
            }`
        });
        applyFilter(cities, range, apartment);
    };

    const clearFilter = () => {
        setPCities([]);
        setPRange([0, 100]);
        setPApartment([]);
        onResetFilter();
    };

    return (
        <div>
            <Modal
                open={isOpenFilter}
                onClose={onCloseFilter}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Drawer
                    variant="persistent"
                    anchor="right"
                    open={isOpenFilter}
                    onClose={onCloseFilter}
                    elevation={8}
                    PaperProps={{
                        sx: {
                            width: { xs: '100%', md: 400 },
                            border: 'none',
                            overflow: 'hidden'
                        }
                    }}
                >
                    <div className="flex flex-col h-[100%]">
                        <div className="">
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ mx: 3, py: 2 }}
                            >
                                <div className="flex">
                                    <BiArrowBack
                                        onClick={onCloseFilter}
                                        className="cursor-pointer my-auto mr-2 h-6 w-6"
                                    />
                                    <p
                                        className={`text-xl font-semibold ${config?.projects.moduleConfig
                                            ?.fontTitle}`}
                                    >
                                        Filter
                                    </p>
                                </div>
                                <p
                                    onClick={() => clearFilter()}
                                    className={`text=lg cursor-pointer text-blue-400 ${config?.projects.moduleConfig
                                        ?.fontBody}`}
                                >
                                    Clear All
                                </p>
                            </Stack>
                            <Divider sx={{ mx: 3 }} />
                        </div>

                        <div className="flex-1 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-300">
                            <Scrollbar>
                                <Stack sx={{ mx: 4, mt: 1 }}>
                                    {projectCityList.length > 1 && (
                                        <>
                                            <div
                                                onClick={() =>
                                                    setCityShow(!cityShow)
                                                }
                                                className=" cursor-pointer flex justify-between mb-2"
                                            >
                                                <p
                                                    className={`text-lg font-bold ${config?.projects.moduleConfig
                                                        ?.fontTitle}`}
                                                >
                                                    Location
                                                </p>
                                                <AiOutlineDown className="my-auto" />
                                            </div>

                                            {cityShow && (
                                                <CityList
                                                    cities={pCities}
                                                    cityUpdate={cityUpdate}
                                                    projectCityList={
                                                        projectCityList
                                                    }
                                                />
                                            )}

                                            <Divider
                                                sx={{ margin: '12px 0' }}
                                            />
                                        </>
                                    )}

                                    <div
                                        onClick={() => setRangeShow(!rangeShow)}
                                        className="cursor-pointer flex justify-between mb-2"
                                    >
                                        <p
                                            className={`text-lg font-bold ${config?.projects.moduleConfig
                                                ?.fontTitle}`}
                                        >
                                            Budget
                                        </p>
                                        <AiOutlineDown className="my-auto" />
                                    </div>

                                    {rangeShow && (
                                        <PriceRange
                                            range={pRange}
                                            priceUpdate={priceUpdate}
                                        />
                                    )}

                                    <Divider sx={{ margin: '12px 0' }} />

                                    <div
                                        onClick={() =>
                                            setApartmentShow(!apartmentShow)
                                        }
                                        className={`cursor-pointer flex justify-between mb-2 ${config?.projects.moduleConfig
                                            ?.fontTitle}`}
                                    >
                                        <p className="text-lg font-bold">
                                            No. of Bedrooms
                                        </p>
                                        <AiOutlineDown className="my-auto" />
                                    </div>

                                    {apartmentShow && (
                                        <ApartmentToggle
                                            apartments={pApartment}
                                            apartmentUpdate={apartmentUpdate}
                                            apartmentTypes={apartmentTypesList}
                                        />
                                    )}
                                </Stack>
                            </Scrollbar>
                        </div>

                        {/* <div className='fixed bottom-2 md:bottom-0 md:absolute w-full'> */}
                        <div className="w-full py-4 px-8">
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Button
                                    type="submit"
                                    sx={{
                                        paddingX: 4,
                                        paddingY: 1,
                                        background: '#BFBFBF',
                                        '&:hover': {
                                            background: '#AAAAAA'
                                        },
                                        fontWeight: 'bold'
                                        // fontFamily:`${fontDetails.fontBody}`
                                    }}
                                    variant="contained"
                                    onClick={onCloseFilter}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        paddingX: 4,
                                        paddingY: 1,
                                        background: config.primaryColor,
                                        '&:hover': {
                                            background: config.primaryColor,
                                            opacity: '90%'
                                        },
                                        fontWeight: 'bold'
                                        // fontFamily:`${fontDetails.fontTitle}`
                                    }}
                                    onClick={applyFilters}
                                >
                                    Apply Filters
                                </Button>
                            </Stack>
                        </div>
                    </div>
                </Drawer>
            </Modal>
        </div>
    );
}
