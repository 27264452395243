
import { winnreAuth } from '../../../utils/axios';
import { useState } from 'react';

const useData = () => {
    const [userProperty, setUserProperty] = useState(null);
    const [amenities, setAmenities] = useState([]);
const [loading, setLoading] = useState(false)
    const getUserProperty = () => {
        setLoading(true)
        winnreAuth({
            method: 'GET',
            url: '/winnre/user/property/customer'
        }).then((res) => {
            // console.log(res)
        setLoading(false)
            const propertyForOnboarding = res.content.filter(
                (obj) => obj.onboarded === false // change to false
            );
            // console.log('property', propertyForOnboarding);
            if (propertyForOnboarding.length == 0) {
                return;
            } else {
                setUserProperty(propertyForOnboarding[0]);
                winnreAuth({
                    method: 'GET',
                    url: `/winnre/user/projectAmenity?projectId=${propertyForOnboarding[0]?.projectId}`
                }).then((res) => {
                    const amenityList = res.content.map((obj) => {
                        return { id: obj.id, title: obj.title };
                    });
                    setAmenities(amenityList);
                });
            }
        });
    };

    return { getUserProperty, userProperty, amenities, loading };
};

export default useData;
