exports.isContactUsVisible =
    window.reloy_env.client_config.NAVIGATION_ROUTES?.CONTACT_US?.show ||
    window.reloy_env.client_config.NAVIGATION_DROPDOWN?.CONTACT_US?.show ||
    window.reloy_env.client_config.NAVIGATION_FOOTER?.CONTACT_US?.show ||
    window.reloy_env.client_config.NAVIGATION_WEBVIEW?.CONTACT_US?.show;

exports.isProjectsVisible =
    window.reloy_env.client_config.NAVIGATION_ROUTES?.PROJECTS?.show ||
    window.reloy_env.client_config.NAVIGATION_DROPDOWN?.PROJECTS?.show ||
    window.reloy_env.client_config.NAVIGATION_FOOTER?.PROJECTS?.show ||
    window.reloy_env.client_config.NAVIGATION_WEBVIEW?.PROJECTS?.show;

exports.isBlogsVisible =
    window.reloy_env.client_config.NAVIGATION_ROUTES?.BLOGS?.show ||
    window.reloy_env.client_config.NAVIGATION_DROPDOWN?.BLOGS?.show ||
    window.reloy_env.client_config.NAVIGATION_FOOTER?.BLOGS?.show ||
    window.reloy_env.client_config.NAVIGATION_WEBVIEW?.BLOGS?.show;

exports.isDealsVisible =
    window.reloy_env.client_config.NAVIGATION_ROUTES?.DEALS?.show ||
    window.reloy_env.client_config.NAVIGATION_DROPDOWN?.DEALS?.show ||
    window.reloy_env.client_config.NAVIGATION_FOOTER?.DEALS?.show ||
    window.reloy_env.client_config.NAVIGATION_WEBVIEW?.DEALS?.show;

exports.isEventsVisible =
    window.reloy_env.client_config.NAVIGATION_ROUTES?.EVENTS?.show ||
    window.reloy_env.client_config.NAVIGATION_DROPDOWN?.EVENTS?.show ||
    window.reloy_env.client_config.NAVIGATION_FOOTER?.EVENTS?.show ||
    window.reloy_env.client_config.NAVIGATION_WEBVIEW?.EVENTS?.show;

exports.isTermsAndConditionsVisible =
    window.reloy_env.client_config.NAVIGATION_ROUTES?.TERMS?.show ||
    window.reloy_env.client_config.NAVIGATION_DROPDOWN?.TERMS?.show ||
    window.reloy_env.client_config.NAVIGATION_FOOTER?.TERMS?.show ||
    window.reloy_env.client_config.NAVIGATION_WEBVIEW?.TERMS?.show;

exports.isMyProfileVisible =
    window.reloy_env.client_config.MY_PROFILE?.SHOW_MY_PROPERTY;

exports.isReferralVisible =
    window.reloy_env.client_config.NAVIGATION_ROUTES?.REFERRAL?.show ||
    window.reloy_env.client_config.NAVIGATION_DROPDOWN?.REFERRAL?.show ||
    window.reloy_env.client_config.NAVIGATION_FOOTER?.REFERRAL?.show ||
    window.reloy_env.client_config.NAVIGATION_WEBVIEW?.REFERRAL?.show;
