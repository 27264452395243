import React, { useEffect } from 'react'
import MdcStarFace from '@meronex/icons/mdc/MdcStarFace';
import BiLinkExternal from '@meronex/icons/bi/BiLinkExternal';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const BookedSite = (props) => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
        const redirect = queryParams.get('redirect')

    const { projectName, projectBgImage, projectCity, bookedDate, bookedTime, type } = props;
    // console.log(type)

    useEffect(() => {
        if(redirect == 'whatsapp'){
            window.open(
                `https://wa.me/+91${window.reloy_env.client_config.CONTACT_DETAILS?.WHATSAPP_CHATBOT}?text=Successfully%20Booked%20your%20site%20visit`
            );
        }
    }, [redirect])
    return (
        <>

            <div className='text-center'>
                <MdcStarFace className="text-[100px] text-primary mx-auto" />
                <h1 className='text-2xl font-bold text-center'>Hurray!</h1>
                <p className='italic text-sm text-[#adadad]'>Successfully {type == 'Reschedule' ? 'rescheduled' : 'booked'} your site visit</p>

                <div className='border rounded-[10px] p-5 my-5'>

                    <div className='flex items-center pb-5'>
                        <div>
                            <img src={projectBgImage} className='w-16 h-10 object-cover object-center rounded-[5px]' />
                        </div>
                        <div className='text-left pl-3'>
                            <h1 className='font-bold leading-[1] text-lg'>{projectName}</h1>
                            <p className='italic text-sm text-[#adadad]'>{projectCity}</p>
                        </div>
                    </div>

                    <svg viewBox="0 0 457 2"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1" fill="#e5e5e5" /></g></g></svg>

                    <h1 className='font-bold py-3 text-left text-md'>Booking Details</h1>

                    <div className='grid grid-cols-2 gap-6 items-center mb-5'>
                        <div className='text-left'>
                            <h1 className='leading-[1] text-sm'>DATE</h1>
                            <h2 className='font-bold text-md'>{bookedDate}</h2>
                        </div>
                        <div className='text-left'>
                            <h1 className='leading-[1] text-sm'>TIME</h1>
                            <h2 className='font-bold  text-md'>{bookedTime}</h2>
                        </div>
                        <div className='text-left col-span-2 '>
                            <h1 className='leading-[1.2] text-sm'>LOCATION</h1>
                            <h2 className='font-bold  text-md flex items-center'>{projectCity} <BiLinkExternal className="ml-1 text-xl text-primary inline" /></h2>
                        </div>
                    </div>
                    {/* <div className='text-left'>

                    <h3 className='text-primary font-semibold text-lg'><BsPlus className="inline text-[40px]" /> Add to calendar</h3>

                </div> */}
                </div>
            </div>
        </>
    )
}

export default BookedSite