import React, { Suspense } from 'react';
import Home from './Home';
import { Route, Switch } from 'react-router-dom';
import ComponentsRenderer from '../Components/ComponentsRenderer';
import { cmsClient } from '../utils/axios';
import { LoadingSpinner } from '../Components/Molecules/LoadingUI';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../store/auth-slice';
import PrivateRoute from '../Components/Molecules/PrivateRoute';
import DealDetails from './DealsAndDiscounts/Components/DealDetails/newIndex';

import Booklet from '../Components/Molecules/Booklet';
import ReferralLink from '../Components/Molecules/ReferralLink';
import ReferralPage from './ReferralPage';
import OfflineDealLanding from './OfflineDealLanding';
import UserProfile from './MyProfile/UserProfile';
import Enquiry from './Enquiry';
import PageNotFound from './404';
import ConditionalRoute from '../Components/Molecules/ConditionalRoute';
import Projects from './Projects';
import PropertyDetail from './Projects/Components/PropertyDetail';
import {
    isBlogsVisible,
    isContactUsVisible,
    isDealsVisible,
    isEventsVisible,
    isMyProfileVisible,
    isProjectsVisible,
    isReferralVisible,
    isTermsAndConditionsVisible
} from '../utils/routing';
import { getConfig } from '../utils/config';
import SiteVisit from './SiteVisit';
import BookSiteVisit from './Projects/Components/ProjectDetail/BookSiteVisit';
import Referrals from './Referrals';
import Thankyou from './Thankyou';
import HomeLoans from './HomeLoans';
import ProductListing from './DealsAndDiscounts/Components/ProductListing';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Rewards from './Rewards';
import BundleDeals from './BundleDeals';

const EventImageVideoGallery = React.lazy(() =>
    import('./Events/Components/EventImageVideoGallery')
);
const Events = React.lazy(() => import('./Events'));
const Login = React.lazy(() => import('./Login'));
const ContactUs = React.lazy(() => import('./ContactUs'));
const Denied = React.lazy(() => import('./Denied'));
const TermsAndConditions = React.lazy(() => import('./TermsAndConditions'));
const DealsAndDiscounts = React.lazy(() => import('./DealsAndDiscounts'));
const BlogDetails = React.lazy(() => import('./Blogs/Components/BlogDetails'));
const Blogs = React.lazy(() => import('./Blogs'));
const ReferAndEarn = React.lazy(() => import('./ReferAndEarn'));
const MyProperties = React.lazy(() => import('./MyProperties'));
const MyProperty = React.lazy(() => import('./MyProperty'));
const Documentation = React.lazy(() => import('./Documentation'));
const MyProfile = React.lazy(() => import('./MyProfile'));
const BundleDetails = React.lazy(() => import('./BundleDetails'));
function Routes({ routesConfig }) {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userRole = useSelector((state) => state.auth.userRole);
    const jwtToken = useSelector((state) => state.auth.token);
    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
    };
    const config = getConfig();
    return (
        <Suspense
            fallback={
                <div className=" h-screen">
                    <LoadingSpinner />
                </div>
            }
        >
            <main className="">
                <Switch>
                    <Route path="/" exact>
                        <Home config={routesConfig} />
                    </Route>
                    <Route path="/denied">
                        <Denied />
                    </Route>

                    <ConditionalRoute
                        isVisible={isContactUsVisible}
                        path="/contact-us"
                    >
                        <ContactUs />
                    </ConditionalRoute>

                    <ConditionalRoute
                        isVisible={isProjectsVisible}
                        path="/projects"
                        exact
                    >
                        <Projects
                            propertyBackgroundColorWithOverlay={
                                routesConfig?.projects.moduleConfig
                                    ?.propertyBackgroundColorWithOverlay
                            }
                        />
                    </ConditionalRoute>
                    <ConditionalRoute
                        isVisible={isProjectsVisible}
                        path="/projects/detail"
                    >
                        <PropertyDetail
                            constructionVisible={
                                routesConfig?.projects.moduleConfig
                                    ?.constructionVisible
                            }
                            sectionBackgroundImage={
                                routesConfig?.projects.moduleConfig
                                    ?.sectionBackgroundImage
                            }
                            propertyBackgroundColorWithOverlay={
                                routesConfig?.projects.moduleConfig
                                    ?.propertyBackgroundColorWithOverlay
                            }
                            addHeader={routesConfig?.header || false}
                            headerClass={
                                routesConfig?.header?.headerClass || ''
                            }
                        />
                    </ConditionalRoute>
                    <ConditionalRoute
                        isVisible={isProjectsVisible}
                        path="/projects/book-a-site-visit"
                    >
                        <PrivateRoute
                            roleRequired={['CUSTOMER', 'SITE_VISITOR']}
                        >
                            <BookSiteVisit />
                        </PrivateRoute>
                    </ConditionalRoute>

                    <ConditionalRoute
                        isVisible={isBlogsVisible}
                        exact
                        path="/blogs"
                    >
                        <Blogs config={routesConfig} />
                    </ConditionalRoute>
                    <ConditionalRoute
                        isVisible={isBlogsVisible}
                        path="/blogs/:blogId"
                    >
                        <BlogDetails />
                    </ConditionalRoute>

                    <ConditionalRoute
                        isVisible={isDealsVisible}
                        exact
                        path="/deals"
                    >
                        <DealsAndDiscounts />
                    </ConditionalRoute>
                    <ConditionalRoute
                        isVisible={isDealsVisible}
                        path="/deals/:dealId"
                        exact
                    >
                        <DealDetails />

                    </ConditionalRoute>
                    <ConditionalRoute
                        isVisible={isDealsVisible}
                        exact
                        path="/dealListing"
                    >
                        <ProductListing />
                    </ConditionalRoute>

                    <ConditionalRoute
                        isVisible={isDealsVisible}
                        exact
                        path="/bundle-deals"
                    >
                       <BundleDeals/>
                    </ConditionalRoute>


                    {/* <ConditionalRoute
                        isVisible={isDealsVisible}
                        exact
                        path="/deals/:vendorName/:vendorId"
                    >
                        <VendorOffers />
                        
                    </ConditionalRoute> */}
                      <ConditionalRoute
                        isVisible={isDealsVisible}
                        exact
                        path="/bundleDetails"
                    >
                        <BundleDetails />
                    </ConditionalRoute>
                    <ConditionalRoute
                        isVisible={isDealsVisible}
                        path="/moveInOffers"
                    >
                        <Redirect to='dealListing?categoryID=2&categoryName=Exclusive%20Move%20In%20Offers'/>

                    </ConditionalRoute>
                    <ConditionalRoute
                        isVisible={isDealsVisible}
                        path="/lifestyleOffers"
                    >
                        <Redirect to='dealListing?categoryID=1&categoryName=Member%20Benefits'/>
                    </ConditionalRoute>
                    <ConditionalRoute
                        isVisible={isDealsVisible}
                        path="/financeOffers"
                    >
                        
                        <Redirect to='dealListing?categoryID=3&categoryName=Home%20Financing%20Deals'/>

                    </ConditionalRoute>
                    <ConditionalRoute
                        isVisible={isDealsVisible}
                        path="/digitalAmenities"
                    >
                        <Redirect to='/dealListing?categoryID=5&categoryName=Digital%20Amenities'/>

                    </ConditionalRoute>

                    <Route path="/login">
                        <Login />
                    </Route>

                    {routesConfig?.booklet && (
                        <Route path="/booklet">
                            <Booklet />
                        </Route>
                    )}

                    {routesConfig?.referralLink && (
                        <Route path="/generate-referral-link">
                            <ReferralLink />
                        </Route>
                    )}

                    <ConditionalRoute
                        isVisible={isEventsVisible}
                        exact
                        path="/events"
                    >
                        <Events />
                    </ConditionalRoute>
                    <ConditionalRoute
                        isVisible={isEventsVisible}
                        path="/events/:eventId"
                    >
                        <EventImageVideoGallery />
                    </ConditionalRoute>

                    <ConditionalRoute
                        isVisible={isTermsAndConditionsVisible}
                        path="/terms-and-conditions"
                    >
                        <TermsAndConditions />
                    </ConditionalRoute>

                    <ConditionalRoute
                        isVisible={isReferralVisible}
                        path="/gift-and-earn"
                    >
                        <ReferralPage config={routesConfig} />
                    </ConditionalRoute>

                    <Route path="/myprofile/docs">
                        <PrivateRoute
                            roleRequired={[
                                'CUSTOMER',
                                'EMPLOYEE',
                                'SITE_VISITOR'
                            ]}
                        >
                            <MyProfile>
                                <Documentation />
                            </MyProfile>
                        </PrivateRoute>
                    </Route>

                    <ConditionalRoute
                        isVisible={isMyProfileVisible}
                        path="/myprofile/property"
                    >
                        <PrivateRoute roleRequired={['CUSTOMER']}>
                            <MyProfile>
                                <MyProperties />
                            </MyProfile>
                        </PrivateRoute>
                    </ConditionalRoute>

                    {isDealsVisible && !config?.deals?.showOnlyBanner && (
                        <Route path="/myprofile/enquiries">
                            <PrivateRoute
                                roleRequired={['CUSTOMER', 'SITE_VISITOR']}
                            >
                                <MyProfile>
                                    <Enquiry />
                                </MyProfile>
                            </PrivateRoute>
                        </Route>
                    )}
                    
                    <Route path="/myprofile/referrals">
                        <PrivateRoute roleRequired={['CUSTOMER', 'EMPLOYEE', 'SITE_VISITOR']}>
                            <MyProfile>
                                <Referrals  config={routesConfig}/>
                            </MyProfile>
                        </PrivateRoute>
                    </Route>

                    <Route path="/myprofile/info">
                            <MyProfile>
                                <UserProfile />
                            </MyProfile>
                    </Route>
                    <Route path="/myprofile/site-visits" exact>
                        <PrivateRoute
                            roleRequired={['CUSTOMER', 'SITE_VISITOR']}
                        >
                            <MyProfile>
                                <SiteVisit />
                            </MyProfile>
                        </PrivateRoute>
                    </Route>
                    <Route path="/home-loans" exact>
                        
                        <PrivateRoute
                            roleRequired={['CUSTOMER', 'SITE_VISITOR', 'EMPLOYEE']}
                        >    
                        <HomeLoans/>
                        </PrivateRoute>
                    </Route>

                    <Route path="/redeem-voucher">
                        <OfflineDealLanding />
                    </Route>
                    <Route path="/myprofile/rewards">
                        <PrivateRoute roleRequired={['CUSTOMER', 'EMPLOYEE', 'SITE_VISITOR']}>
                            <MyProfile>
                                <Rewards/>
                            </MyProfile>
                        </PrivateRoute>
                    </Route>
                    <Route path="/thank-you">
                        <Thankyou/>
                    </Route>
                    <Route path="*">
                        <PageNotFound />
                    </Route>
                </Switch>
            </main>
        </Suspense>
    );
}

export default Routes;
