import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function PrivateRoute({ children, roleRequired }) {
    const { isAuthenticated, userRole } = useSelector((state) => state.auth);
    const unauthorizedWebViewUser = useSelector(
        (state) => state.webView.unauthorizedWebViewUser
    );

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const webViewAccessToken = queryParams.get('token');

    const Out = <Redirect to="/" />;
    const Denied = <Redirect to="/denied" />;

    if (unauthorizedWebViewUser) {
        return (
            <div className="h-screen flex items-center justify-center">
                This session token is not valid. Please log out and log in
                again.
            </div>
        );
    }

    if (webViewAccessToken) {
        return <>{children}</>;
    }

    if (!isAuthenticated) {
        return <>{Out}</>;
    } else if (roleRequired) {
        return isAuthenticated ? (
            roleRequired.includes(userRole) ? (
                <>{children}</>
            ) : (
                <>{Denied}</>
            )
        ) : (
            <>{Out}</>
        );
    }
}

export default PrivateRoute;
