import React from 'react'
import { getConfig } from '../../../../utils/config'

function Disclaimer({ disclaimer }) {
    const config = getConfig()
    return (
        <div className='flex flex-col'>
            <p className={` ${config.fontTitle} z-10 text-xl text-[#130E2F] mt-8 font-bold`}>
                Disclaimer
            </p>
            <p
                
                className={`z-10 scrollbar-thin text-[#130E2F] mt-2 mb-8 ${config.fontBody}`}
            >
                {disclaimer}
            </p>
        </div>
    )
}

export default Disclaimer
