import React from 'react'
import SkeletonGeneralOffer from '../SkeletonCategoryBar/SkeletonGeneralOffer'

const SkeletonBundleDeals = () => {
  return (
    <div className="h-auto w-full ">
       <div
                id="DealsLoader"
                className="flex w-full gap-4 overflow-x-scroll overflow-x-hidden"
            >
               {[...Array(4).keys()].map((n) => (
            <SkeletonGeneralOffer
                key={n}
                shape="square"
            />
        ))}
            </div>
    </div>
  )
}

export default SkeletonBundleDeals

