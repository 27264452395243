const Footer = () => {
    return (
        <div className="bg-secondary-content py-4">
            <a
                className="bg-primary-focus text-gray-200 text-center block pt-4"
                href="/"
            >
                Terms and Conditions
            </a>
            <footer class="p-4 footer text-base-content footer-center">
                <div className=" w-2/3 text-gray-200">
                    <p>
                        This referral program is run by Loyalie IT Solutions
                        Pvt. Ltd. and the referral payout will be processed by
                        them. Loyalie IT Solutions Pvt. Ltd. is registered as a
                        Real Estate Agent under RERA in the following states :
                        Maharashtra - MAHARERA NO – A51900010267,Karnataka RERA
                        NO -PRM/KA/RERA/1251/309/AG/180524/000935, Tamil Nadu
                        RERA NO– TN/Agent/0092/2023, West Bengal RERA NO -
                        HIRA/A/KOL/2019/000350, Haryana RERA NO–
                        HRERA-PKL-REA-239-2020
                    </p>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
