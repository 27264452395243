import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/dist/plugins/fullscreen';
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom';
import 'yet-another-react-lightbox/dist/styles.css';
import { getConfig } from '../../../../utils/config';
import ReactPlayer from 'react-player';


export default function ProjectConsUpdateNew({
    constructionGallery,
    constructionProgress
}) {
    const [index, setIndex] = useState(-1);

    const config = getConfig();
    const pImages = constructionGallery?.map((image) => image.url);

    console.log('construction', constructionGallery);
    if (pImages?.length <= 0) {
        return null;
    }
    return (
        <div className={' pt-5 flex flex-col'}>
            <h4
                className={`z-10 text-xl mt-4 text-[#130E2F] font-bold ${config.fontTitle}`}
            >
                Construction Update
            </h4>
            <div className="mt-4">
                <div className="z-10 bg-white grid grid-cols-2 md:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 auto-rows-fr w-full gap-2">
                    <div className="bg-gray-100 col-span-1 row-span-1 md:col-span-2 md:row-span-2  flex-none mr-2 h-full">
                        <div
                            // className="mt-4"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignSelf: 'center'
                            }}
                        >
                            <CircularProgressbarWithChildren
                                styles={{
                                    path: {
                                        stroke: config.primaryColor
                                    }
                                }}
                                strokeWidth={4}
                                className="w-28 h-28 md:w-60 md:h-60 p-2 md:p-4"
                                value={constructionProgress}
                            >
                                <div
                                    style={{
                                        fontSize: 24,
                                        textAlign: 'center'
                                    }}
                                >
                                    <div>
                                        <strong className="text-[20px] md:text-[24px]">
                                            {constructionProgress}%
                                        </strong>
                                        <br />
                                        <p
                                            className={`text-[14px] md:text-[24px] ${config.fontBody}`}
                                        >
                                            Completed
                                        </p>
                                    </div>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>
                    {constructionGallery.map((image, i) => {
                        if (image.urlType == 'IMAGE') {
                            return <img
                                onClick={() => setIndex(i)}
                                className={`z-10 h-[120px] w-[250px] cursor-pointer object-cover `}
                                src={image.url}
                            />;
                        }
                        if (image.urlType == 'VIDEO'){
                            return <div className={`${i == 0 ? 'md:row-span-2 md:col-span-2 md:h-full' : 'row-span-1 md:h-[120px] xl:h-[120px] '} object-cover w-full cursor-pointer`}>
                            <ReactPlayer
                                height='100%'
                                width='100%'
                                url={image.url}
                                controls={true} />
                        </div>
                        }
                    })}
                </div>
            </div>

            <Lightbox
                slides={pImages.map((image) => {
                    return { src: image, height: null, width: null };
                })}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                plugins={[Fullscreen, Zoom]}
            />
        </div>
    );
}
