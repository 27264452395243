import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
// console.log(window.location.origin);
const domain = window.location.origin;
const EvosConfig = {
    clientId:
        'cb7b53153acf63d24539eae5875ba745d336aea5a00d4d03fa2928425fc73cbd',
    id: 34,
    name: 'Evos Buildcon',
    fullName: 'Evos Buildcon',
    programName:'Evos Parivar',
    theme: 'evosTheme',
    favicon: 'https://cdn.loyalie.in/34832725.png',
    primaryColor: '#000000',
    fontBody: `${getFont('EVOS').body}`,
    fontTitle: `${getFont('EVOS').heading}`,
    googleConfig: {
        gID: 'G-TTQDR5MFT7',
        keywordsContent:
            'evos parivar, evos buildcon, ceo evos, evos esports, evos logo, evos company, evos bilasini enclave, evos city homes, evos opal, evos career, leading real estate company in odisha, evos palace, builders in bhubaneswar, refer and earn, real estate affiliate program, evos buildcon referral program, evos parivar program, evos referral sales, evo referral code, evos buildcon contact number, evos nirvana, evos buildcon career, evos buildcon logo, evos bhubaneswar, residential projects By evos buildcon, evos builders, evos buildcon parivar, evos parivar leading real esate company in odisha',
        descriptionContent:
            'Evos Parivar is a loyalty and referral program designed and engineered for our close-knit family of homeowners. The cornerstone of the brand was built on trust, respect and affinity towards the loyal homeowners of Evos Buildcon. ',
        authorContent: 'Evos Parivar'
    },

    facebookPixelConfig: {
        id: '635835794731356', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: '0b7q3pb72fepc30a7bjbkw6h0tzhau'
    },
    webengageLic: 'in~~15ba20678',
    webengageAPIKey:'fa7a1715-d70f-4b93-83dc-9d2b79fb9f40',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: false,

    logoLeft: {
        name: 'evosLogo',
        url: 'https://cdn.loyalie.in/46003125.png',
        stickyNavBarLogoClass:
            ' h-8 md:h-25 lg:h-10 xl:h-12 2xl:h-14 pl-3 xl:pl-10',
        baseNavBarLogoClass:
            ' h-8 md:h-25 lg:h-10 xl:h-12 2xl:h-14 pl-3 xl:pl-10 lg:mt-3'
    },

    logoRight: {
        name: 'evosLogo',
        url: 'https://cdn.loyalie.in/78557300.png',
        stickyNavBarLogoClass:
            'hidden lg:block lg:h-8 xl:h-12 2xl:h-14 pr-3 xl:pr-10 ',
        baseNavBarLogoClass:
            'h-8 md:h-25 lg:h-8 xl:h-12 2xl:h-14 pr-3 xl:pr-10 lg:mt-3'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('EVOS').heading}`,
        height: 'h-16 xl:h-20',
        mobileIconColor: 'text-black',
        dropDownBg: 'bg-white lg:bg-black',
        showActiveLinkColor: false,
        footerNavItemClass: `tracking-widest uppercase text-white text-[12px] md:text-[14px] lg:text-white hover:text-white font-normal lg:text-[12px] xl:text-[14px] 3xl:text-lg  ${
            getFont('EVOS').heading
        }`,
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-white lg:bg-transparent', // default: transparent
            containerClass: ' lg:pt-4 xl:pt-8',
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black lg:text-white text-[14px] lg:text-black hover:text-black font-normal lg:text-[12px] xl:text-[14px] 2xl:text-[15px] 3xl:text-lg ',
            dropDownContainerClass: `hover:text-primary lg:hover:text-gray-900 lg:hover:bg-white lg:focus:text-black lg:focus:bg-white lg:focus:outline-none lg:focus:shadow-outline `,
            dropDownItemClass:
                'tracking-widest text-black lg:text-white text-[14px] lg:font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-white lg:bg-white drop-shadow-md', //default: primary-content,
            containerClass: 'lg:py-16 ', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black lg:text-white text-[14px] lg:text-black hover:text-black font-normal lg:text-[12px] xl:text-[14px] 3xl:text-lg ' // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: '-mt-5 lg:-mt-20 2xl:-mt-20', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 ${
        getFont('EVOS').bold
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage: 'https://cdn.loyalie.in/78613308.jpg',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/78613308.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    bannerContainerClass:
                        'h-[60vh] md:h-[80vh] lg:h-[99vh] bg-cover bg-center lg:bg-right-top ',
                    bannerItemContainerClass:
                        'items-start justify-center md:justify-center pt-36 xl:pt-40 2xl:pt-56 md:px-8 md:pl-6 lg:pl-8 xl:pl-14',
                    position: 'text-left',

                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'text-center mx-auto md:-mx-0 md:text-left leading-8 lg:leading-6 relative ',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'YOUR HOME, YOUR COMMUNITY',
                                    className: `inline text-black text-[20px] md:text-[25px] xl:text-[30px] 2xl:text-[36px]  ${
                                        getFont('EVOS').heading
                                    }`
                                },

                                {
                                    type: 'IMAGE',
                                    url: 'https://cdn.loyalie.in/26119489.png',
                                    className:
                                        'w-[30px] md:w-[45px] 2xl:w-[55px] absolute top-[-19px] md:top-[-35px] lg:top-[-37px] xl:top-[-40px] 2xl:top-[-50px] left-[311px] md:left-[387px] lg:left-[385px] xl:left-[466px] 2xl:left-[558px]'
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'A joint initiative by Evos Buildcon & Reloy.',
                                    className: `text-black text-[16px] md:text-[17px] xl:text-[17px] 2xl:text-[20px] leading-8 md:pt-2 font-normal ${
                                        getFont('EVOS').body
                                    }`
                                }
                            ]
                        },

                        {
                            type: 'BUTTON',
                            content: 'REFER NOW',
                            className: `mx-auto text-center md:mx-0 mt-2 md:mt-4  text-[12px] md:text-[16px] xl:text-lg tracking-wider bg-black text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 z-10 ${
                                getFont('EVOS').bold
                            }`,
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH_AND_SCROLL',
                            scrollTo: 'referral_section'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'overview',
                    url: 'https://cdn.loyalie.in/852472.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        size: '80%',
                        opacity: '0.70'
                    },
                    className:
                        'flex flex-col justify-center text-center py-6 2xl:py-10 px-4 ',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'There is no place better than home, at Evos we take a few extra steps to make our homebuyers feel at home. We consider every homeowner as a part of our family and every wish they have, to be our command.',
                            className: `pt-1 text-[16px] md:text-[17px] xl:text-[17px] 2xl:text-[20px] text-secondary-focus leading-6 xl:leading-[27px] self-center w-[90%] xl:w-[88%] font-normal 2xl:w-[74%] break-words mb-5  ${
                                getFont('EVOS').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Evos Parivar is a loyalty and referral program designed and engineered for our close-knit family of homeowners. The cornerstone of the brand was built on trust, respect and affinity towards the loyal homeowners of Evos Buildcon. To show our gratitude to customers who put their faith on us, we reward up to 2.5% of total sale value on every successful referral.',
                            className: `pt-1 text-[16px] md:text-[17px] xl:text-[17px] 2xl:text-[20px] text-secondary-focus leading-6 xl:leading-[27px] self-center w-[90%] xl:w-[88%] font-normal 2xl:w-[74%] break-words mb-5  ${
                                getFont('EVOS').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'This loyalty program is thoughtfully designed to bring more love , excitement and a touch of luxury to your life, while you earn handsome rewards each time a loved one joins our family.',
                            className: `pt-1 text-[16px] md:text-[17px] xl:text-[17px] 2xl:text-[20px] text-secondary-focus leading-6 xl:leading-[27px] self-center w-[90%] xl:w-[88%] 2xl:w-[74%] break-words font-normal ${
                                getFont('EVOS').body
                            }`
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: '',
                    url: 'https://cdn.loyalie.in/67189632.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0',
                        size: 'cover',
                        position: 'top'
                    },
                    bannerItemContainerClass: 'bg-contain',
                    className:
                        'text-left p-3 xl:p-5 bg-center lg:bg-top relative',
                    components: [
                        {
                            type: 'TEXT',
                            content: ' ',
                            className:
                                'h-[216px] md:h-[260px] lg:h-[285px] 2xl:h-[295px] w-[80%] 2xl:w-[60%] bg-gradient-to-r from-[#000000ce] to-transparent absolute bottom-0 left-0 z-0'
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'flex flex-col  items-start justify-end p-1 md:p-4 h-[50vh] md:h-[55vh]  lg:h-[70vh] xl:h-[80vh] 2xl:h-[82vh] ',
                            bannerItemContainerClass: '',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-full lg:w-[37%] xl:w-[35%] 2xl:w-[35%] text-left  lg:pl-5 z-10 relative',
                                    bannerItemContainerClass: '',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content: 'Referrals and Earnings',
                                            className: `inline text-white text-[20px] md:text-[25px] xl:text-[30px] 2xl:text-[36px] uppercase  ${
                                                getFont('EVOS').heading
                                            }`
                                        },
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/26119489.png',
                                            className:
                                                'w-[30px] md:w-[45px] 2xl:w-[55px] absolute top-[-19px] md:top-[-32px] lg:top-[-30px] xl:top-[-28px] 2xl:top-[-36px] left-[233px] md:left-[290px] lg:left-[310px] xl:left-[370px] 2xl:left-[440px]'
                                        },
                                        {
                                            type: 'DIVIDER',
                                            className:
                                                'my-4 self-center mx-0 w-[17%] lg:w-[18%] bg-white border-none h-[4px] xl:h-[5px]',
                                            classNameContainer: ''
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-[95%] md:w-[60%] lg:w-full mx-0',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'There is always space for building your own community. Invite your loved ones to be part of our rewarding and gratifying community and earn referral benefits upto 2.5% of total sales value on every successful referral.',
                                                    className: ` text-white text-[16px] lg:text-[17px] xl:text-[18px] 2xl:text-[20px] font-light leading-5 lg:leading-6   ${
                                                        getFont('EVOS').body
                                                    }`
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/852472.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.70',
                        size: '80% auto'
                    },
                    className:
                        'flex flex-col justify-center text-center pt-6 xl:py-10 relative ',

                    components: [
                        {
                            type: 'IMAGE',
                            url: 'https://cdn.loyalie.in/26119489.png',
                            className:
                                'w-[30px] md:w-[45px] 2xl:w-[55px] absolute top-[64px] md:top-[21px] xl:top-[40px]  2xl:top-[18px] right-[36px] md:right-[45px] lg:right-[173px] xl:right-[238px] 2xl:right-[280px]'
                        },
                        {
                            type: 'TEXT',
                            content:
                                'PRIVILEGES FOR ALL OUR HOMEOWNERS ARE ENDLESS',
                            className: `${
                                getFont('EVOS').heading
                            } text-black self-center text-center w-[75%] md:w-[90%] text-[20px] md:text-[25px] xl:text-[30px] 2xl:text-[36px] leading-7 xl:leading-9 pb-2 pt-8 `
                        },

                        {
                            type: 'TEXT',
                            content: 'With Evos Parivar you get access to:',
                            className: `${
                                getFont('EVOS').body
                            } text-black self-center text-center w-[90%] text-[17px] md:text-[25px] lg:text-[25px] xl:text-3xl leading-7 xl:leading-9 pb-5 xl:pb-10`
                        },

                        /* 3 Grid-COls Section */
                        {
                            type: 'CUSTOM',
                            id: '',
                            url: '',
                            linearGradient: {
                                R: '255',
                                G: '255',
                                B: '255',
                                opacity: '0',
                                size: '70% auto'
                            },
                            bannerItemContainerClass: 'bg-cover',
                            className: ' ',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'flex lg:flex-none  overflow-x-scroll lg:overflow-x-hidden gap-6 lg:grid-cols-3 lg:gap-8 xl:gap-12 w-[90%] md:w-[83%] lg:w-[90%] xl:w-[82%] mx-auto justify-left lg:justify-center h-auto pb-5 mb-5 lg:mb-0 lg:pb-10 xl:pb-5',
                                    bannerItemContainerClass: '',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'justify-top min-w-[75vw] md:min-w-[40vw] lg:min-w-[25vw] border border-white p-3 bg-white drop-shadow-md',
                                            bannerItemContainerClass: '',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className: ' ',
                                                    bannerItemContainerClass:
                                                        ' ',

                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            id: '',
                                                            url: 'https://cdn.loyalie.in/20977925.jpg',
                                                            linearGradient: {
                                                                R: '255',
                                                                G: '255',
                                                                B: '255',
                                                                opacity: '0',
                                                                size: 'cover'
                                                            },
                                                            bannerItemContainerClass:
                                                                'bg-cover ',
                                                            className:
                                                                'text-left p-5 md:p-8 lg:p-3 xl:p-8 h-[255px] md:h-[280px] lg:h-[240px] xl:h-[290px] 2xl:h-[328px] ',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    onClick:
                                                                        'CHANGE_PATH',
                                                                    path: 'deals',
                                                                    content:
                                                                        'OFFERS AND PROMOTIONS',
                                                                    className: `${
                                                                        getFont(
                                                                            'EVOS'
                                                                        )
                                                                            .heading
                                                                    } text-white self-center text-center text-[24px] xl:text-3xl leading-7 xl:leading-9 py-20 2xl:py-24 px-5 cursor-pointer`
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'Unlock a plethora of offers and deals from top brands around the world to complement your luxurious lifestyle.',
                                                    className: `text-black text-left xl:px-5 pt-5 pb-2 text-[16px] lg:text-[17px] xl:text-[17px] 2xl:text-[20px] font-normal leading-6  break-words  ${
                                                        getFont('EVOS').body
                                                    }`
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                ' justify-top min-w-[75vw] md:min-w-[40vw] lg:min-w-[25vw] border border-white p-3 bg-white drop-shadow-md',
                                            bannerItemContainerClass: '',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className: ' ',
                                                    bannerItemContainerClass:
                                                        ' ',
                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            id: '',
                                                            url: 'https://cdn.loyalie.in/46022253.jpg',
                                                            linearGradient: {
                                                                R: '255',
                                                                G: '255',
                                                                B: '255',
                                                                opacity: '0',
                                                                size: 'cover'
                                                            },
                                                            bannerItemContainerClass:
                                                                'bg-cover ',
                                                            className:
                                                                'text-left p-5 md:p-8 lg:p-3 xl:p-8 h-[255px] md:h-[280px] lg:h-[240px] xl:h-[290px] 2xl:h-[328px] ',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    onClick:
                                                                        'CHANGE_PATH',
                                                                    path: 'events',
                                                                    content:
                                                                        'WORKSHOPS AND EVENTS',
                                                                    className: `${
                                                                        getFont(
                                                                            'EVOS'
                                                                        )
                                                                            .heading
                                                                    } text-white self-center text-center text-[24px] xl:text-3xl leading-7 xl:leading-9 py-20 2xl:py-24 px-5 cursor-pointer`
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'All work and no play makes our homeowners dull and gloomy. We design workshops and exciting events to celebrate the essence of community living.',
                                                    className: `text-black text-left xl:px-5 pt-5 pb-2 text-[16px] lg:text-[17px] xl:text-[17px] 2xl:text-[20px] font-normal leading-6  break-words  ${
                                                        getFont('EVOS').body
                                                    }`
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'justify-top min-w-[75vw] md:min-w-[40vw] lg:min-w-[25vw] border border-white p-3 bg-white drop-shadow-md',
                                            bannerItemContainerClass: '',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className: ' ',
                                                    bannerItemContainerClass:
                                                        ' ',
                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            id: '',
                                                            url: 'https://cdn.loyalie.in/69893991.jpg',
                                                            linearGradient: {
                                                                R: '255',
                                                                G: '255',
                                                                B: '255',
                                                                opacity: '0',
                                                                size: 'cover'
                                                            },
                                                            bannerItemContainerClass:
                                                                'bg-cover ',
                                                            className:
                                                                'text-left p-5 md:p-8 lg:p-3 xl:p-8  h-[255px] md:h-[280px] lg:h-[240px] xl:h-[290px] 2xl:h-[328px]',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'INFORMATIVE BLOGS',
                                                                    onClick:
                                                                        'CHANGE_PATH',
                                                                    path: 'blogs',
                                                                    className: `${
                                                                        getFont(
                                                                            'EVOS'
                                                                        )
                                                                            .heading
                                                                    } text-white self-center text-center text-[24px] xl:text-3xl leading-7 xl:leading-9 py-20 2xl:py-24 px-5 cursor-pointer`
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'Discover a wide range of promotional offers from top Pan India brands, designed to complement your distinguished lifestyle.',
                                                    className: `text-black text-left xl:px-5 pt-5 pb-2 text-[17px] md:text-[16px] lg:text-[17px] xl:text-[17px] 2xl:text-[20px] font-normal leading-6  break-words  ${
                                                        getFont('EVOS').body
                                                    }`
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                //REFERRAL FORM
                {
                    type: 'REFERRAL_FORM_BANNER'
                },

                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/852472.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.70',
                        size: '80%'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-10 relative pb-10 lg:pb-20 -mb-28',
                    components: [
                        {
                            type: 'IMAGE',
                            url: 'https://cdn.loyalie.in/26119489.png',
                            className:
                                'w-[30px] md:w-[45px] 2xl:w-[55px] absolute top-[42px] md:top-[30px] xl:top-[32px] 2xl:top-[25px] right-[56px] md:right-[215px] lg:right-[342px] xl:right-[442px] 2xl:right-[525px]'
                        },
                        {
                            type: 'TEXT',
                            content: 'OUR Exclusive Offers',
                            className: `text-black tracking-wide text-center px-2 text-[20px] md:text-[25px] xl:text-[30px] 2xl:text-[36px] pt-5 uppercase ${
                                getFont('EVOS').heading
                            }`
                        },
                        {
                            type: 'CAROUSEL',
                            // getVendorLogos: true,
                            offerCards: true,
                            getVendorData: true,
                            ContainerClassName:
                                'bg-cover rounded-lg h-auto mx-3 2xl:mx-5 p-8',
                            caraouselClassName: `w-[80%] md:w-full xl:w-[86%] 2xl:w-[95%] 3xl:w-[80%] mx-auto md:px-12  pt-6 relative pb-10 ${
                                getFont('EVOS').body
                            }`,

                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 4,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow
                        },
                        {
                            type: 'TEXT',
                            content: 'OUR PROJECTS',
                            className: `text-black tracking-wide text-center px-2 text-[20px] md:text-[25px] xl:text-[30px] 2xl:text-[36px]  uppercase ${
                                getFont('EVOS').heading
                            }`
                        },
                        {
                            type: 'CAROUSEL',
                            customSlide: true,
                            caraouselClassName:
                                ' lg:w-[80%] 2xl:w-[70%] mx-auto px-8 md:px-14 xl:px-28 md:pb-10 pt-1 relative pb-16 lg:pb-10',
                            imageContainerClassName: 'mx-4 relative',
                            imageClassName: 'w-full mx-auto lg:bg-cover',
                            captionClass: ` text-sm lg:text-lg leading-8 ${
                                getFont('EVOS').body
                            }`,
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 2,
                            xlSlidesToShow: 2,
                            minSlides: true, // only 2 per screen
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/38496273.png',
                                    alt: 'Nirvana',
                                    url: `${domain}/evos-website/projects/detail?projectId=6`,
                                    caption: 'Nirvana'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/14799639.png',
                                    alt: 'EVOS Sea Roses',
                                    url: `${domain}/evos-website/projects/detail?projectId=11`,
                                    caption: 'Sea Roses'
                                }
                                // {
                                //     imgUrl: 'https://cdn.loyalie.in/12325708.png',
                                //     alt: 'Evos Opal',
                                //     url: 'https://evosbuildcon.com/projects/evos-opal/',
                                //     caption: 'Evos Opal'
                                // }
                            ]
                        }
                    ]
                }
            ]
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://cdn.loyalie.in/1692626196588_refer%20page%20banner',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1692625772205_refer%20page%20mob',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover bg-right lg:bg-left ',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center lg:text-left text-primary text-[20px] md:text-[25px] xl:text-[30px] 2xl:text-[36px] uppercase my-5 md:w-[80%] lg:w-[60%] xl:w-[70%] ml-auto leading-8 lg:leading-10 ${
                                getFont('EVOS').heading
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'md:w-[80%] lg:w-[60%] xl:w-[70%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `flex text-[15px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 mx-auto lg:mx-0  ${
                                        getFont('EVOS').bold
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0'
                },
                backgroundImage:
                    'https://cdn.loyalie.in/1692626196588_refer%20page%20banner',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1692625772205_refer%20page%20mob',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[70vh] object-cover bg-right lg:bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  my-5 tracking-widest  lg:w-[80%] mx-auto  ${
                    //         getFont('ARVIND').body
                    //     }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[45px] font-bold  pb-5  tracking-widest lg:w-[50%] mx-auto  ${
                    //         getFont('ARVIND').heading
                    //     }`
                    // },
                    // {
                    //     type: 'BUTTON',
                    //     content: 'Start Referring Now',
                    //     className: `text-md font-medium ARVIND-button hover:ARVIND-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 lg:w-[20%] mx-auto ${
                    //         getFont('ARVIND').heading
                    //     }`,
                    //     scrollTo: 'referral_section',
                    //     onClick: 'SCROLL'
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold bg-primary text-white ${
                getFont('ARVIND').heading
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[90vh] lg:h-[97vh] lg:h-screen md:bg-center md:bg-top  mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.20'
            },
            backgroundImage: 'https://cdn.loyalie.in/68352429.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/68352429.jpg',
            components: [
                {
                    type: 'TEXT',
                    content: 'EVOS READERS’ CLUB',
                    className: ` mt-12 md:mt-10 mb-2 text-black text-[20px] md:text-[25px] xl:text-[30px] 2xl:text-[36px] ${
                        getFont('EVOS').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[5px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: ` mt-5 mb-3 text-black text-[17px] xl:text-[17px] 2xl:text-[20px] leading-6 md:leading-8 pt-2 font-normal ${
                        getFont('EVOS').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('EVOS').bold
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'EVOS READERS’ CLUB',
                    className: `mt-12 md:mt-10 mb-2 text-black text-[20px] md:text-[25px] xl:text-[30px] 2xl:text-[36px] ${
                        getFont('EVOS').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[5px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `mt-5 mb-3 text-black text-[17px] xl:text-[17px] 2xl:text-[20px] leading-6 md:leading-8 pt-2 font-normal ${
                        getFont('EVOS').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('EVOS').bold
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('EVOS').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('EVOS').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-[#1c2936] ${
                    getFont('EVOS').heading
                }`,
                headingClass: `text-[25px] xl:text-[30px] 2xl:text-[36px] uppercase font-normal text-secondary mt-3 leading-9 break-words ${
                    getFont('EVOS').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('EVOS').blogs
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('EVOS').heading
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('EVOS').body
                }`
            },
            sectionHeadings: `px-10 lg:px-16 pt-6 pb-2 text-[22px] text-[25px] xl:text-[30px] 2xl:text-[36px] tracking-wider text-center uppercase ${
                getFont('EVOS').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            cardHeight: 'h-[530px] lg:h-[520px] xl:h-[520px] 3xl:h-[580px]',
            bodyHeight:
                'h-[280px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 md:h-[45px] lg:h-[70px] text-[19px] 3xl:text-[20px] font-medium uppercase leading-6 break-words mx-5 ${
                getFont('EVOS').heading
            }`,
            categoryClass: `bg-secondary text-white ${getFont('EVOS').body}`,
            summaryClass: `font-normal text-[16.5px] xl:text-[17px] mt-2 leading-6 text-[#777777] mx-5 md:h-[50px] lg:h-[70px] ${
                getFont('EVOS').body
            }`,
            buttonClass: `bg-accent-focus hover:bg-primary flex justify-center self-end float-right text-[13px] xl:text-[16px] text-white rounded-lg py-1.5 px-4 md:px-4 m-5 ${
                getFont('EVOS').body
            }`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-[#919396] ${
                getFont('EVOS').heading
            }`,
            noBlogsClass: `bg-secondary p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('EVOS').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/77375170.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/77375170.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0'
            },

            negativeMargin: '-mt-20 2xl:-mt-24',
            containerClass: ' text-white w-[50%] ',
            heading: '',
            headingClass: `font-normal text-center lg:w-[70%] text-[25px] xl:text-[30px] 2xl:text-[36px] text-black uppercase ${
                getFont('EVOS').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('EVOS').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('EVOS').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[5px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('EVOS').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('EVOS').bold
                    }`
                }
            ]
        },

        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: `self-center text-center text-[25px] xl:text-[30px] 2xl:text-[36px] text-neutral w-[60%] mt-16 ${
                getFont('EVOS').heading
            }`,
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('EVOS').body
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('EVOS').body
            }`
        },
        eventLayout: {
            titleClass: `text-accent leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('EVOS').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${
            getFont('EVOS').heading
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('EVOS').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                ' text-left hidden md:hero hero-content  max-w-full h-[30vh] lg:h-[45vh] bg-center bg-cover', // //bg-top
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0' //0.5
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692626308381_deals%20banner',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692629526987_deals%20banner%20mob',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            sectionBackgroundImage: ' ',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.97',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center font-semibold text-[20px] text-center text-xl text-neutral w-[60%] ${
                getFont('EVOS').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `text-2xl xl:text-3xl uppercase text-secondary font-normal ${
                getFont('EVOS').heading
            } `,
            subAdditionalText:
                'Evos Parivar is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: `font-normal text-neutral ${
                getFont('EVOS').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('EVOS').body}`
        },
        dealLayout: {
            listTitleClass: `text-primary text-lg lg:text-[16px] 2xl:text-lg leading-6 lg:leading-6 text-primary ${
                getFont('EVOS').heading
            }`,
            listBodyClass: `text-primary ${getFont('EVOS').body}`,
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/852472.png ',
            pattern2: 'https://cdn.loyalie.in/852472.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.70',
                repeat: 'no-repeat',
                size: '100%',
                position: ''
            },
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` self-center text-center text-accent font-medium uppercase text-2xl mt-3 ${
                getFont('EVOS').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('EVOS').heading
            }`,
            dateClassName: `text-neutral capitalize text-[12px] text-black  ${
                getFont('EVOS').heading
            }`,
            termsAndConditionsClassName: `font-semibold  capitalize text-neutral text-sm ${
                getFont('EVOS').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-neutral text-xs  break-words leading-1 ${
                getFont('EVOS').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-primary text-white font-normal uppercase self-center rounded text-[16px] py-2.5 px-7  ${
                    getFont('EVOS').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `bg-primary self-center text-white rounded uppercase text-[16px] py-[9px] px-10 ${
                    getFont('EVOS').body
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.55'
                },
                backgroundImage: 'https://cdn.loyalie.in/57545540.png',
                backgroundImageMobile: 'https://cdn.loyalie.in/84236386.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[90vh] lg:h-screen md:bg-center',

                components: [
                    {
                        type: 'TEXT',
                        content: 'EVOS RESIDENTIAL PROJECTS',
                        className: `font-normal mx-auto text-center mb-2 text-black text-[25px] xl:text-[30px] 2xl:text-[36px] ${
                            getFont('EVOS').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[25%] xl:w-[20%] bg-primary border-none h-[5px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {
       
            constructionVisible: false,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },
    header: {
        headerClass: 'hidden lg:block h-24 bg-primary-content'
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/46329169.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.97',
            repeat: 'repeat',
            size: 'contain'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/3967386.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/3967386.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full mg:bg-center lg:bg-top h-[90vh] lg:h-[97vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: `mx-auto text-center mb-2 text-black text-[25px] xl:text-[30px] 2xl:text-[36px] ${
                            getFont('EVOS').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[15%] xl:w-[15%] bg-primary border-none h-[5px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],

    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('EVOS').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('EVOS').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/23745754.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/23745754.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center lg:bg-top h-[90vh] lg:h-[97vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS & CONDITIONS',

                        className: ` mx-auto text-center mb-2 text-black text-[25px] xl:text-[30px] 2xl:text-[36px] ${
                            getFont('EVOS').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[25%] xl:w-[20%] bg-primary border-none h-[5px]',
                        classNameContainer: ''
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: '',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-6 md:p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('EVOS').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('EVOS').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Evos  project, and has entered into a registered Agreement to Sell; or (ii) employee of Evos ; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Evos  or any of its sister concerns/subsidiaries/affiliates (“Evos  Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Evos  Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Evos .',
                            'Referrer shall not be able to refer existing customer(s) of Evos . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Evos  or (ii) is/are already registered with Evos  as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Evos ’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Evos  within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Evos ',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'Evos Parivar Loyalty members to be awarded with -'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('EVOS').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'CUSTOM',
                        className: 'px-0 md:px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'i) For upto 1.5 Cr the reward bonus is 1.5% of the total sale value',

                                className: `${
                                    getFont('EVOS').body
                                } text-neutral font-normal text-[17px] leading-7   `
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'ii) For above 1.5 Cr upto 2.5 Cr the reward bonus is 2% of the total sale value',

                                className: `${
                                    getFont('EVOS').body
                                } text-neutral font-normal text-[17px] leading-7   `
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'iii) For above 2.5 Cr the reward bonus is 2.5% of the total sale value',
                                className: `${
                                    getFont('EVOS').body
                                } text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Sale value = unit price, parking charges and floor escalation as applicable',

                                className: `${
                                    getFont('EVOS').body
                                }  text-neutral font-normal text-[17px] leading-7 `
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Note: Base Payout of 1% will be released immediately once the registered agreement of sales is executed and the referred customer has made 20% payment of the total consideration value. The remaining bonus payout will be released at the end of the financial year of the scheme',

                                className: `${
                                    getFont('EVOS').body
                                } text-neutral font-normal text-[17px] leading-7   `
                            }
                        ]
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Evos  or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('EVOS').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-2'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Evos Lifespaces, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('EVOS').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('EVOS').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('EVOS').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('EVOS').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('EVOS').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('EVOS').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('EVOS').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        showMyProperty: false,
        appFontHeading: `${getFont('EVOS').body}`,
        appStoreLink:
            'https://apps.apple.com/in/app/evos-parivar/id1672992192?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.evos&hl=en&gl=US&utm_medium=email',
        appScreen: 'https://cdn.loyalie.in/18457694.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('EVOS').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[11px] 3xl:text-[15px] leading-[18px] tracking-tight text-white font-light ${
            getFont('EVOS').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('EVOS').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-info bg-opacity-90'
    },
    referralForm: {
        name: 'Evos',
        theme: 'evosTheme',
        color: '#000000',
        backgroundImage: 'https://cdn.loyalie.in/77876033.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '52',
            G1: '52',
            B1: '52',
            opacity1: '0',
            R2: '52',
            G2: '52',
            B2: '52',
            opacity2: '0'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own an',
            // bookedCustomerHeading2: 'Evos Property',

            employee: false,
            employeeHeading1: 'I am an',
            // employeeHeading1: 'Evos Employee',

            salesAdmin: true,
            salesAdminHeading1: 'I am an',
            // salesAdminHeading2: 'Evos Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        customConfig: {
            welcomeContent: '',
            headingColor: 'text-white',
            userBgColor: 'bg-transparent',
            borderColor: 'border-white',
            animateUserType: true
        },
        pastReferralConfig: {
            selectedStatusColor: 'text-[#B39C64]',
            bgColor: 'bg-primary'
        },
        formContent: {
            heading: '"LET THE REWARDING JOURNEY BEGIN"',
            headingClass: `self-center text-white  mt-10 text-3xl 2xl:text-5xl w-[80%] lg:w-[80%] 2xl:w-[90%] text-center leading-8 tracking-wide ${
                getFont('EVOS').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%23000'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: false,
        termsAndConditionsLink: '',
        customLink: '/embassy-website/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default EvosConfig;
