import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
const AlohaConfig = {
    clientId:
        '239c65cb76f499014d6585d35a253fa1d6a967239b6953701af8c8fe9d24c526',
    id: 50,
    name: 'Aloha',
    fullName: 'Aloha',
    programName:'Aloha Luxe Club',
    primaryColor: '#5c9886',
    theme: 'AlohaTheme',
    favicon:
        'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1709745642556_favicon mayfair',

    googleConfig: {
        gID: 'G-1EJTV2EP09',
        keywordsContent:
            'Aloha real estate referral programs, Aloha real estate referral program, Aloha real estate refer and earn, Aloha real estate project refer, Aloha real estate, Aloha real estate loyalty program, Aloha real estate news, Aloha real estate upcoming projects in Bengaluru, Aloha real estate launch Aloha real estate, Aloha real estate launch, Aloha real estate program, Aloha real estate projects, Aloha real estate blogs, Aloha real estate offers, Aloha real estate upcoming projects in Bengaluru, Aloha real estate events, Aloha, Aloha, Aloha real estate best residential projects, Aloha real estate real estate developers, All New Projects by Aloha real estate Builders & Developers, Ongoing Projects by Aloha real estate Pvt Ltd, Aloha real estate Bengaluru, Aloha real estate new program, Aloha press release, Aloha upcoming projects, JP property group, Aloha bangalore, JP real estate, JP reit, jitu virwani, JP commercial projects, Aloha projects, Aloha projects in bangalore',
        descriptionContent:
            "We present to you a bouquet of such experiences and more with Aloha - a bespoke loyalty and referral programme that's designed exclusively for Aloha homeowners",
        authorContent: 'Aloha'
    },

    facebookPixelConfig: {
        id: '', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~~2024c246',
    uatFacebookPixelConfig: {
        id: '315907378197242',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5'
    },
    activateReferPage: false,
    logoLeft: {
        name: 'Aloha Logo',
        url: 'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1709746390668_aloha logo white',
        stickyNavBarLogoClass: ' h-36 pl-3 xl:pl-10',
        baseNavBarLogoClass: ' h-36 pl-3 xl:pl-10 '
    },

    // logoRight: {
    //     name: 'JPInfra Logo',
    //     url: 'https://cdn.loyalie.in/73643924.png',
    //     stickyNavBarLogoClass:
    //         'hidden lg:block lg:h-10 2xl:h-12 pr-3 xl:pr-14 ',
    //     baseNavBarLogoClass: 'h-20 md:h-25 lg:h-10 2xl:h-12 pr-3 xl:pr-14 '
    // },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('JP').body}`,
        height: 'h-20 2xl:h-24',
        mobileIconColor: 'text-primary-focus',
        dropDownBg: 'bg-black lg:bg-primary',
        showActiveLinkColor: false,
        footerNavItemClass: `tracking-widest uppercase text-white text-[12px] md:text-[14px] lg:text-white hover:text-primary-focus font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg  ${
            getFont('JP').body
        }`,
        baseNavBar: {
            // border: true,
            // borderClass: 'border-b-[3px] border-primary-focus',
            backgroundColor: 'bg-primary-content', // default: transparent
            containerClass: '',
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-white text-[14px] lg:text-primary hover:text-primary-focus font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg ',
            dropDownContainerClass: `hover:text-primary-focus lg:hover:text-white lg:hover:bg-primary-focus lg:focus:text-gray-900 lg:focus:bg-primary lg:focus:outline-none lg:focus:shadow-outline  `,
            dropDownItemClass:
                'tracking-widest text-white lg:text-white text-[14px] font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg '
        },

        stickyNavBar: {
            // border: true,
            // borderClass: 'border-b-[3px] border-primary-focus',
            backgroundColor: 'bg-primary-content', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-white text-[14px] lg:text-primary hover:text-primary-focus font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg ' // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `mx-auto text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 ${
        getFont('JP').body
    }`,

    loginModal: {
        showEmployeeLogin: true,
        showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Loyalie/Images/13329312.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Loyalie/Images/13329312.jpg',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0',
                        size: 'cover'
                    },
                    bannerContainerClass:
                        'h-[87vh] bg-center lg:bg-right-top lg:bg-cover border-b-[3px] border-primary-focus ',
                    bannerItemContainerClass:
                        'items-center md:items-start justify-center  px-8 md:pl-10 xl:pl-16 ',
                    position: 'text-left',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'text-center md:text-left w-[80%] md:w-[40%] lg:w-[33%] xl:w-[35%] 2xl:w-[32.5%] mx-auto md:mx-0 px-4 py-20 md:p-8 xl:p-10',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'STAY TUNED',
                                    className: `inline-block uppercase text-white tracking-wide leading-8 md:leading-10 lg:leading-8 xl:leading-10 2xl:leading-10 text-[35px] md:text-[40px] xl:text-[52px] 2xl:text-6xl ${
                                        getFont('JP').bold
                                    }`
                                }
                                // {
                                //     type: 'TEXT',

                                //     content: 'Luxury for You',
                                //     className: `text-[35px] inline-block uppercase lg:pt-3 leading-8 md:leading-10 lg:leading-4 xl:leading-10 tracking-wide text-white md:text-[40px] xl:text-[52px] 2xl:text-6xl ${
                                //         getFont('JP').bold
                                //     }`
                                // },
                                // {
                                //     type: 'TEXT',
                                //     content:
                                //         'A Joint Initiative by JP Infra and Reloy',
                                //     className: `pt-3 text-[13px] md:text-[13px] xl:text-[17px] 2xl:text-[20px] text-white tracking-normal font-medium self-center break-words ${
                                //         getFont('JP').body
                                //     }`
                                // },
                                // {
                                //     type: 'BUTTON',
                                //     content: 'Refer Now',
                                //     className: `mx-auto text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 ${
                                //         getFont('JP').body
                                //     }`,
                                //     path: 'gift-and-earn',
                                //     onClick: 'CHANGE_PATH_AND_SCROLL',
                                //     scrollTo: 'referral_section'
                                // }
                            ]
                        }
                    ]
                }

                // {
                //     type: 'CUSTOM',
                //     id: '',
                //     url: 'https://cdn.loyalie.in/82775700.png',
                //     linearGradient: {
                //         R: '255',
                //         G: '255',
                //         B: '255',
                //         opacity: '0.20'
                //     },
                //     className:
                //         'flex flex-col justify-center text-center pt-5 px-4 ',
                //     components: [
                //         {
                //             type: 'TEXT',
                //             content: 'About Aloha',
                //             className: `text-primary uppercase pt-5 text-center uppercase text-3xl lg:text-[40px] xl:text-[52px] 2xl:text-6xl tracking-wider  ${
                //                 getFont('JP').heading
                //             }`
                //         },

                //         {
                //             type: 'TEXT',
                //             content:
                //                 'Aloha is an exclusive loyalty and referral for all residents of JP Infra. We are initiating the platform to ensure richer experiences for our homeowners and unlock a new lifestyle. When you choose to buy a home with us, it shows your trust in our brand. We understand that our brand is built on this trust and relationship, and in our effort to create something stronger and more lasting, we bring you this platform. Our loyalty benefits are designed to bring more to your life, and every time you introduce your friends and loved ones to us, you earn exclusive rewards.',
                //             className: `pt-3 lg:pt-5 px-5 text-[17px] xl:text-[20px] text-secondary-focus leading-6 xl:leading-[27px] self-center w-[90%] lg:w-[90%] 2xl:w-[80%] break-words xl:mb-5  ${
                //                 getFont('JP').body
                //             }`
                //         },
                //         {
                //             type: 'TEXT',
                //             content: 'Your Premium Access To',
                //             className: ` text-[18px] xl:text-[23px] pt-10 pb-3 text-secondary-focus font-medium leading-6 xl:leading-[27px] self-center w-[90%] xl:w-[90%] 2xl:w-[80%] break-words ${
                //                 getFont('JP').body
                //             }`
                //         },
                //         {
                //             type: 'TEXT',
                //             content: 'a world full of rewards',
                //             className: `${
                //                 getFont('JP').heading
                //             } text-primary self-center text-center w-[90%] md:w-[60%] lg:w-[50%] 2xl:w-[41%] text-3xl lg:text-[32px] xl:text-[42px] 2xl:text-5xl leading-7 xl:leading-9 pb-5 2xl:pb-10`
                //         },
                //         {
                //             type: 'ICONS_LAYOUT',
                //             containerClassName:
                //                 'h-content w-full lg:flex lg:h-auto items-center py-3 xl:py-5 ',
                //             gridClassName:
                //                 'grid lg:grid-cols-3 lg:w-full m-auto',
                //             infotxtClassName: ` mx-auto leading-5 2xl:leading-[25px] w-[95%] px-4 xl:px-6 text-secondary-focus text-[15px] xl:text-[16px] font-normal pb-8 ${
                //                 getFont('JP').body
                //             }`,
                //             infoHeadingClassName: `w-full mx-auto leading-6 xl:leading-[27px] md:w-[60%] lg:w-[90%] xl:w-[80%] 2xl:w-[70%] md:px-2 text-[20px] md:text-[22px] xl:text-[35px]  text-secondary font-normal pt-5 xl:pb-2  tracking-wide ${
                //                 getFont('JP').heading
                //             }`,
                //             dividerClassName:
                //                 'w-[20%] mx-auto border-none h-[6px] xl:h-[8px] my-3 xl:my-6',
                //             imgContainerClassName: '',
                //             imgClassName:
                //                 'w-[16%] md:w-[8%] lg:w-[13%] lg:mx-auto mx-auto lg:mb-2',
                //             info: [
                //                 {
                //                     imgUrl: 'https://cdn.loyalie.in/4636399.png',
                //                     infoHeading: 'OFFERS AND PROMOTIONS',
                //                     divider: true,
                //                     dividerColor: '#f47d3a',
                //                     infoText:
                //                         'Discover a wide range of promotional offers from top Pan India brands, designed to complement your distinguished lifestyle'
                //                 },
                //                 {
                //                     imgUrl: 'https://cdn.loyalie.in/89659026.png',
                //                     infoHeading: 'WORKSHOPS AND EVENTS',
                //                     divider: true,
                //                     dividerColor: '#eb0988',
                //                     infoText:
                //                         'Enjoy curated online & offline workshops and events from top collaborators to add more delight to your community experiences'
                //                 },
                //                 {
                //                     imgUrl: 'https://cdn.loyalie.in/40545117.png',
                //                     infoHeading: 'INTERACTIVE BLOGS',
                //                     divider: true,
                //                     dividerColor: '#f47d3a',
                //                     infoText:
                //                         'Know about the latest trends and insights on the real estate industry and unlock a chance to interact with a community of like-minded people'
                //                 }
                //             ]
                //         }
                //     ]
                // },
                // {
                //     type: 'CUSTOM',
                //     id: '',
                //     url: 'https://cdn.loyalie.in/10041783.png',
                //     linearGradient: {
                //         R: '255',
                //         G: '255',
                //         B: '255',
                //         opacity: '0',
                //         size: 'cover',
                //         position: 'right'
                //     },
                //     bannerItemContainerClass: 'bg-cover',
                //     className:
                //         'text-left p-3 xl:p-5 bg-left lg:bg-top border-t-[3px]  border-b-[3px] border-primary-focus',
                //     components: [
                //         {
                //             type: 'CUSTOM',
                //             className:
                //                 'flex flex-col  items-center lg:items-start lg:justify-center p-1 md:p-4 h-[50vh] md:h-[85vh]  lg:h-[60vh] xl:h-[80vh] 2xl:h-[70vh] ',
                //             bannerItemContainerClass: '',
                //             components: [
                //                 {
                //                     type: 'CUSTOM',
                //                     className:
                //                         'w-full lg:w-[37%] xl:w-[35%] 2xl:w-[35%] text-center lg:text-left  lg:pl-5 ',
                //                     bannerItemContainerClass: '',
                //                     components: [
                //                         {
                //                             type: 'TEXT',
                //                             content: 'Referrals and Earnings',
                //                             className: `text-[#f8f9e5] uppercase tracking-wide text-3xl lg:text-[40px] xl:text-[48px] 2xl:text-6xl lg:w-[65%] lg:leading-[45px] xl:leading-[60px] ${
                //                                 getFont('JP').heading
                //                             }`
                //                         },
                //                         {
                //                             type: 'DIVIDER',
                //                             className:
                //                                 'my-6 self-center mx-auto lg:mx-0 w-[40%] lg:w-[18%] bg-[#eb0988] border-none h-2 md:h-[8px]',
                //                             classNameContainer: ''
                //                         },
                //                         {
                //                             type: 'CUSTOM',
                //                             className:
                //                                 'w-[70%] lg:w-full mx-auto lg:mx-0',
                //                             components: [
                //                                 {
                //                                     type: 'TEXT',
                //                                     content:
                //                                         'Pass along your experiences to your loved ones and ',
                //                                     className: `inline text-[#f8f9e5] text-[17px] md:text-[16px] lg:text-[14px] xl:text-[16.5px] 2xl:text-[20px] lg:leading-2 xl:leading-5 2xl:leading-6   ${
                //                                         getFont('JP').body
                //                                     }`
                //                                 },
                //                                 {
                //                                     type: 'TEXT',
                //                                     content:
                //                                         'get assured gifts upto ₹1,00,000 on every successful referral.',
                //                                     className: `inline text-[#f8f9e5] font-semibold  text-[14px] md:text-[16px] lg:text-[14px] xl:text-[16.5px] 2xl:text-[20px] lg:leading-2 xl:leading-5 2xl:leading-6  ${
                //                                         getFont('JP').body
                //                                     }`
                //                                 }
                //                             ]
                //                         }
                //                     ]
                //                 }
                //             ]
                //         }
                //     ]
                // },
                // {
                //     type: 'CUSTOM',
                //     id: '',
                //     url: 'https://cdn.loyalie.in/82775700.png',
                //     linearGradient: {
                //         R: '255',
                //         G: '255',
                //         B: '255',
                //         opacity: '0.20'
                //     },
                //     className:
                //         'flex flex-col justify-center text-center py-14 px-4',
                //     components: [
                //         {
                //             type: 'TEXT',
                //             content:
                //                 'Refer your loved ones to us and enjoy unbounded indulgence together.',
                //             className: `${
                //                 getFont('JP').heading
                //             } text-primary tracking-wide self-center text-center text-3xl lg:text-[30px] xl:text-[38px] 2xl:text-[48px] leading-7 xl:leading-9`
                //         }
                //     ]
                // },

                // //REFERRAL FORM
                // {
                //     type: 'REFERRAL_FORM_BANNER'
                // },
                // {
                //     type: 'CUSTOM',
                //     url: 'https://cdn.loyalie.in/82775700.png',
                //     linearGradient: {
                //         R: '255',
                //         G: '255',
                //         B: '255',
                //         opacity: '0.20',
                //         size: '40%'
                //     },
                //     className:
                //         'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-5 ',
                //     components: [
                //         {
                //             type: 'TEXT',
                //             content: 'OUR Exclusive Offers',
                //             className: `${
                //                 getFont('JP').heading
                //             } text-primary-focus self-center mx-auto text-center w-[90%] md:w-[60%] lg:w-[50%] 2xl:w-[41%] text-3xl lg:text-[32px] xl:text-[42px] 2xl:text-[52px] leading-7 xl:leading-9 py-6`
                //         },
                //         {
                //             type: 'CAROUSEL',

                //             getVendorData: true,
                //             offerCards: true,
                //             ContainerClassName:
                //                 'bg-cover rounded-lg h-auto mx-3 2xl:mx-5 p-8',
                //             caraouselClassName:
                //                 'w-[80%] md:w-full xl:w-[86%] 2xl:w-[95%] 3xl:w-[80%] mx-auto md:px-12  pt-6 relative pb-10',

                //             arrows: true,
                //             autoplay: true,
                //             autoplaySpeed: 3000,
                //             infinite: true,
                //             dots: false,
                //             slidesToShow: 4,
                //             pauseOnHover: true,
                //             speed: 3000,
                //             onImageClick: openURLNewWindow
                //         }
                //     ]
                // },
                // // {
                // //     type: 'CUSTOM',
                // //     url: '',
                // //     linearGradient: {
                // //         R: '235',
                // //         G: '235',
                // //         B: '235',
                // //         opacity: '1'
                // //     },
                // //     className:
                // //         'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] ',
                // //     components: [
                // //         {
                // //             type: 'TEXT',
                // //             content: 'our wow stories',
                // //             className: `${
                // //                 getFont('JP').heading
                // //             } text-primary-focus self-center mx-auto text-center w-[90%] md:w-[60%] lg:w-[50%] 2xl:w-[41%] text-[21px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px] leading-7 xl:leading-9 pt-10`
                // //         },
                // //         {
                // //             type: 'CAROUSEL',
                // //             customSlide: true,
                // //             caraouselClassName:
                // //                 'px-10 w-[95%]  mx-auto  relative xl:py-6',
                // //             imageContainerClassName: 'm-4  relative',
                // //             imageClassName:
                // //                 'w-[90%] mx-auto h-auto lg:bg-cover',
                // //             captionClass: ` text-sm lg:text-lg leading-8 ${
                // //                 getFont('JP').body
                // //             }`,
                // //             arrows: true,
                // //             autoplay: true,
                // //             autoplaySpeed: 3000,
                // //             infinite: true,
                // //             dots: false,
                // //             slidesToShow: 2,
                // //             pauseOnHover: true,
                // //             speed: 3000,
                // //             onImageClick: openURLNewWindow,
                // //             imgArr: [
                // //                 {
                // //                     url: 'https://www.youtube.com/watch?v=ZZhHCN0laBk&t',
                // //                     imgUrl: 'https://cdn.loyalie.in/4761645.jpg'
                // //                 },
                // //                 {
                // //                     url: 'https://www.youtube.com/watch?v=s-Fcb7JXWic',
                // //                     imgUrl: 'https://cdn.loyalie.in/4761645.jpg'
                // //                 },
                // //                 {
                // //                     url: 'https://www.youtube.com/watch?v=ZZhHCN0laBk&t',
                // //                     imgUrl: 'https://cdn.loyalie.in/4761645.jpg'
                // //                 },
                // //                 {
                // //                     url: 'https://www.youtube.com/watch?v=s-Fcb7JXWic',
                // //                     imgUrl: 'https://cdn.loyalie.in/4761645.jpg'
                // //                 },
                // //                 {
                // //                     url: 'https://www.youtube.com/watch?v=ZZhHCN0laBk&t',
                // //                     imgUrl: 'https://cdn.loyalie.in/4761645.jpg'
                // //                 },
                // //                 {
                // //                     url: 'https://www.youtube.com/watch?v=s-Fcb7JXWic',
                // //                     imgUrl: 'https://cdn.loyalie.in/4761645.jpg'
                // //                 }
                // //             ]
                // //         }
                // //     ]
                // // },

                // // OUR PROJECTS
                // {
                //     type: 'CUSTOM',
                //     url: 'https://cdn.loyalie.in/82775700.png',
                //     linearGradient: {
                //         R: '255',
                //         G: '255',
                //         B: '255',
                //         opacity: '0.20',
                //         size: '40%'
                //     },
                //     className:
                //         'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-10 pb-16 lg:pb-28  -mb-28   ',
                //     components: [
                //         {
                //             type: 'TEXT',
                //             content: 'OUR PROJECTS',
                //             className: `${
                //                 getFont('JP').heading
                //             } text-primary-focus self-center mx-auto text-center w-[90%] md:w-[60%] lg:w-[50%] 2xl:w-[41%] text-3xl lg:text-[32px] xl:text-[42px] 2xl:text-[52px] leading-7 xl:leading-9`
                //         },
                //         {
                //             type: 'CAROUSEL',
                //             customSlide: true,
                //             caraouselClassName:
                //                 'px-8 w-[99%] md:w-[97%] xl:w-[84%] 2xl:w-[92%] 3xl:w-[80%] mx-auto md:pb-10 pt-1 relative pb-10 lg:py-5',
                //             imageContainerClassName: 'm-4 relative',
                //             imageClassName:
                //                 'w-[90%] mx-auto h-auto lg:bg-cover',
                //             captionClass: `uppercase text-sm lg:text-lg leading-8 ${
                //                 getFont('JP').body
                //             }`,
                //             xlSlidesToShow: 4,
                //             arrows: true,
                //             autoplay: true,
                //             autoplaySpeed: 3000,
                //             infinite: true,
                //             dots: false,
                //             slidesToShow: 5,
                //             pauseOnHover: true,
                //             speed: 3000,
                //             onImageClick: openURLNewWindow,
                //             imgArr: [
                //                 {
                //                     imgUrl: 'https://cdn.loyalie.in/34920800.jpg',
                //                     alt: 'img',
                //                     url: ``,
                //                     caption: 'Altus'
                //                 },
                //                 {
                //                     imgUrl: 'https://cdn.loyalie.in/65490945.jpg',
                //                     alt: 'img',
                //                     url: `${domain}/jp-website/projects/detail?projectId=6`,
                //                     caption: 'North Barcelona'
                //                 },

                //                 {
                //                     imgUrl: 'https://cdn.loyalie.in/87801722.jpg',
                //                     alt: 'img',
                //                     url: `${domain}/jp-website/projects/detail?projectId=12`,
                //                     caption: 'North Euphoria'
                //                 },
                //                 {
                //                     imgUrl: 'https://cdn.loyalie.in/59115545.jpg',
                //                     alt: 'JP Eminence',
                //                     url: 'https://www.jpinfra.com/jpeminence/',
                //                     caption: 'JP Eminence'
                //                 },
                //                 {
                //                     imgUrl: 'https://cdn.loyalie.in/59601954.jpg',
                //                     alt: 'North Imperia',
                //                     url: 'https://www.jpinfra.com/northimperia/',
                //                     caption: 'North Imperia'
                //                 },

                //                 {
                //                     imgUrl: 'https://cdn.loyalie.in/16967698.jpg',
                //                     alt: 'North Garden City',
                //                     url: 'https://www.jpinfra.com/jp-north/gardencity/',
                //                     caption: 'North Garden City'
                //                 }
                //             ]
                //         }
                //     ]
                // }
            ]
        },

        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },

        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[75vh] md:h-[70vh] object-cover bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center lg:text-left text-black text-[15px] md:text-[20px] xl:text-[25px] uppercase my-5 tracking-widest lg:w-[55%] ml-auto  ${
                                getFont('JP').body
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: 'flex lg:w-[55%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 mx-auto lg:mx-0 ${
                                        getFont('JP').body
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[45vh] md:h-[70vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').body
                    //         }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-black text-[15px] leading-10 md:text-[20px] xl:text-[45px] font-bold tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').heading
                    //         }`
                    // },
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex w-[70%] ml-auto justify-center mt-7', components: [{
                    //         type: 'BUTTON',
                    //         content: 'Start Referring Now',
                    //         scrollTo: 'referral_section',
                    //         onClick: 'SCROLL'
                    //     }]
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold bg-primary text-white  ${
                getFont('JP').body
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[87vh] bg-center md:bg-right  mx-auto text-center border-b-[3px] border-primary-focus',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0.20'
            },
            backgroundImage: 'https://cdn.loyalie.in/19150976.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/19150976.jpg',
            components: [
                {
                    type: 'TEXT',
                    content: 'ALOHA READERS’ CLUB',
                    className: `mt-12 md:mt-10 mb-5 text-[30px] lg:text-[40px] xl:text-[42px] 2xl:text-[62px] tracking-wider ${
                        getFont('JP').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] g:w-[15%] xl:w-[20%] bg-primary-focus border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-5 mb-3  text-xl md:text-[16px] xl:text-[17px] tracking-wider ${
                        getFont('JP').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `mx-auto text-center mt-3 font-medium text-[12px] md:text-[17px] tracking-wider bg-primary-focus text-white py-3 2xl:py-3 px-12 md:px-8 ${
                        getFont('JP').body
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'ALOHA READERS’ CLUB',
                    className: `mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] xl:text-[42px] 2xl:text-[62px]  tracking-wider ${
                        getFont('JP').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] g:w-[15%] xl:w-[20%] bg-primary-focus border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('JP').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `mx-auto text-center mt-3 font-medium text-[12px] md:text-[17px] tracking-wider bg-primary-focus text-white py-3 2xl:py-3 px-12 md:px-8 ${
                        getFont('JP').bold
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('JP').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${getFont('JP').heading}`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary-focus text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0 tracking-normal text-[20px] text-[#1c2936] ${
                    getFont('JP').heading
                }`,
                headingClass: `text-[32px] xl:text-[42px] uppercase tracking-tight text-secondary-focus mt-3 leading-10 break-words ${
                    getFont('JP').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('JP').body
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('JP').body
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('JP').body
                }`
            },
            sectionHeadings: `text-primary uppercase pt-10 text-center uppercase text-[32px] xl:text-[52px] tracking-wide uppercase ${
                getFont('JP').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            cardHeight:
                'h-[500px] md:h-[470px] lg:h-[500px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[260px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 md:h-[45px] lg:h-[70px] text-[27px] 3xl:text-[20px] tracking-tight	 font-medium uppercase leading-7 break-words mx-5 ${
                getFont('JP').heading
            }`,
            categoryClass: `bg-primary text-white ${getFont('JP').body}`,
            summaryClass: `font-normal text-[16.5px] xl:text-[17px] mt-2 leading-6 text-secondary-focus mx-5 md:h-[50px] lg:h-[70px] ${
                getFont('JP').body
            }`,
            buttonClass: `bg-primary-focus flex justify-center self-end float-right text-[13px] xl:text-[16px] text-white py-1.5 px-4 md:px-4 m-5 ${
                getFont('JP').body
            }`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-secondary-focus ${
                getFont('JP').body
            }`,
            noBlogsClass: `bg-secondary text-white p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('JP').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/33191553.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/33191553.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0',
                size: 'cover'
            },

            negativeMargin: ' ',
            containerClass: ' text-white w-[50%] bg-top',
            heading: '',
            headingClass: ` text-center lg:w-[70%] text-[30px] xl:text-[42px] 2xl:text-[62px] text-white uppercase ${
                getFont('JP').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('JP').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('JP').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('JP').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('JP').bold
                    }`
                }
            ]
        },

        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: `self-center text-[32px] xl:text-[42px] uppercase tracking-tight text-primary mt-14 leading-10 break-words ${
                getFont('JP').heading
            }`,
            dividerClass:
                'my-4 mx-auto  w-[30%] md:w-[20%] lg:w-[10%] bg-[#eb0988] border-none h-2 md:h-[8px]',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('JP').heading
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('JP').body
            }`
        },
        eventLayout: {
            loadMoreButtonClass: `mx-auto text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 ${
                getFont('JP').body
            }`,
            titleClass: `text-primary-focus leading-5 uppercase text-[22px] font-medium mt-2 ${
                getFont('JP').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-accent font-semibold text-lg my-4 z-10 ${
            getFont('JP').body
        }`,
        noGalleryClass: ` text-accent font-semibold ${getFont('JP').body}`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content  max-w-full h-[30vh] lg:h-[45vh] bg-top bg-cover', //bg-top bg-cover
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0',
                size: 'cover'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692636102370_deals%20banner',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692636102370_deals%20banner',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/4685974.png',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.97',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center text-[22px] md:text-[20px] text-center text-xl text-neutral w-[60%] ${
                getFont('JP').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `text-primary text-2xl xl:text-3xl leading-7 xl:leading-9 ${
                getFont('JP').heading
            } `,
            subAdditionalText:
                'Aloha is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-normal text-[14px] text-neutral ${
                getFont('JP').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('JP').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/82775700.png',
            pattern2: 'https://cdn.loyalie.in/82775700.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.40',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-2xl lg:text-xl leading-6 lg:leading-5 text-primary ${
                getFont('JP').body
            }`,
            listBodyClass: `${getFont('JP').body}`,
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` text-2xl xl:text-4xl text-primary  leading-7 xl:leading-9 font-normal ${
                getFont('JP').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('JP').body
            }`,
            dateClassName: ` text-neutral capitalize font-medium  text-black ${
                getFont('JP').body
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-sm  ${
                getFont('JP').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-xs text-neutral break-words leading-1 ${
                getFont('JP').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-primary-focus text-white font-medium self-center text-[16px] py-2.5 px-7 py-3 2xl:py-3 px-12 md:px-8  ${
                    getFont('JP').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `hover:shadow-xl rounded bg-primary-focus hover:bg-primary text-white font-medium self-center text-[16px] py-2.5 px-7 py-3 2xl:py-3 px-7 md:px-8 ${
                    getFont('JP').body
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.30'
                },
                backgroundImage: 'https://cdn.loyalie.in/12147265.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/12147265.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[87vh]  md:bg-bottom',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ALOHA RESIDENTIAL PROJECTS',

                        className: `text-center text-[30px] xl:text-[42px] 2xl:text-[62px] text-white uppercase ${
                            getFont('JP').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[15%] xl:w-[20%] bg-primary-focus border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {
            constructionVisible: true,

            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/82775700.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.20',
            repeat: 'repeat',
            size: 'contain'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.45'
                },
                backgroundImage: 'https://cdn.loyalie.in/62759552.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/26633965.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center lg:bg-top h-[90vh] lg:h-[97vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: `text-center text-[30px] xl:text-[42px] 2xl:text-[62px] text-white uppercase ${
                            getFont('JP').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[20%] xl:w-[15%] bg-primary-focus border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ]
    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: `tracking-wide text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('JP').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('JP').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.35'
                },
                backgroundImage: 'https://cdn.loyalie.in/14952553.jpeg',
                backgroundImageMobile: 'https://cdn.loyalie.in/14952553.jpeg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center h-[90vh] lg:h-[97vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS & CONDITIONS',

                        className: `text-center text-[30px] xl:text-[42px] 2xl:text-[62px] text-white uppercase ${
                            getFont('JP').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[20%] xl:w-[15%] bg-primary-focus border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('JP').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('JP').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Aloha  project, and has entered into a registered Agreement to Sell; or (ii) employee of Aloha ; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Aloha or any of its sister concerns/subsidiaries/affiliates (Aloha Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Aloha  Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Aloha .',
                            'Referrer shall not be able to refer existing customer(s) of Aloha . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Aloha  or (ii) is/are already registered with Aloha  as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Aloha ’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Aloha  within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Aloha ',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Aloha  or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('JP').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'CUSTOM',
                        className: 'px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Aloha, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('JP').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('JP').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('JP').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('JP').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('JP').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('JP').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('JP').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        showMyProperty: false,
        appContainerClassName: 'bg-white text-primary border-primary-focus',
        appFontHeading: `${getFont('JP').body}`,
        appStoreLink:
            'https://apps.apple.com/in/app/jpwow/id6444639537?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.jpwow&hl=en&gl=US&utm_medium=email',
        appScreen: 'https://cdn.loyalie.in/54255047.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('JP').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[10.5px] 3xl:text-[15px] leading-[18px] tracking-tight text-white ${
            getFont('JP').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('JP').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-90'
    },
    referralForm: {
        name: 'ALOHA',
        theme: 'AlohaTheme',
        color: '#ffffff',
        // backgroundImage:
        //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/33213763.jpg',
        backgroundImage: 'https://cdn.loyalie.in/7023826.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '20',
            G1: '20',
            B1: '20',
            opacity1: '0',
            R2: '52',
            G2: '52',
            B2: '52',
            opacity2: '0'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own a',
            // bookedCustomerHeading2: 'JP Infra Property',

            employee: true,
            employeeHeading1: 'I am a',
            // employeeHeading1: 'JP Infra Employee',

            salesAdmin: true,
            salesAdminHeading1: 'I am an',
            // salesAdminHeading2: 'JP Infra Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        customConfig: {
            welcomeContent: '',
            headingColor: 'text-white',
            userBgColor: 'bg-transparent',
            borderColor: 'border-primary',
            animateUserType: true
        },
        pastReferralConfig: {
            selectedStatusColor: 'text-white',
            bgColor: 'bg-primary'
        },
        formContent: {
            heading: '"Build your own bespoke community"',
            headingClass: `self-center text-white sm:mt-0 text-3xl md:text-4xl xl:text-[48px] 2xl:text-[62px] w-[80%] lg:w-[50%] xl:w-[80%] 2xl:w-[90%] text-center tracking-wide leading-8 xl:leading-[50px] 2xl:leading-[60px] ${
                getFont('JP').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%230c5391'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink: '/aloha-home/terms-and-conditions',
        customLink: '/aloha-home/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default AlohaConfig;
