import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getConfig } from "../../utils/config";
import { deleteWinnreAuthToken, winnreAuth } from "../../utils/axios";
import { authActions } from "../../store/auth-slice";
import useAxios from "../../Hooks/use-axios";
import { toast } from "react-toastify";
import { APIErrorCondition } from "../../constants";


const useData = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const categoryId = queryParams.get('id');

    const [siteVisits, setSiteVisits] = useState([]);
    const [siteVisitsStatusChanged, setSiteVisitsStatusChanged] = useState(false)
    const [selectedCategories, setSelectedCategories] = useState(
        categoryId ? [Number(categoryId)] : []
    );
    const [catalyst, setCatalyst] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [unauthorizedUserMessage, setUnauthorizedUserMessage] =
        useState(null);

    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const isInternalWebview = useSelector(
        (state) => state.webView.internalWebview
    );

    const config = getConfig();

    const onFilterClick = (tag) => {
        // console.log("filter clicked", tag)
        if (selectedCategories.includes(tag)) {
            setSelectedCategories([]);
        } else {
            // console.log('adding tag')
            setSelectedCategories([tag]);
        }

        if (currentPage === 1) {
            setCatalyst((prevState) => prevState + 1);
        } else {
            setCurrentPage(1);
        }
    };

    const logout = () => {
        setTotalPages(0);
        window?.webengage?.track('LogOut');
        window?.webengage?.user?.logout();
        if (config?.autoLogout) {
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('accessToken');
        } else {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
        }
        deleteWinnreAuthToken();
        dispatch(authActions.logout());
    };

    const { isLoading: loadingVisits, sendRequest: fetchSiteVisits } =
        useAxios();

    const getSiteVisits = () => {
        let url = `/winnre/user/enquiry/my-visits?pageSize=5&pageNumber=${currentPage - 1
            }`;

        let method = 'get';

        fetchSiteVisits(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url,
                method,
                isFormData: true
            },
            (data) => {
                // console.log(data)
                if (isAuthenticated) {
                    if (
                        data?.status === 'UNAUTHORIZED' ||
                        data?.status === 'FAILED'
                    ) {
                        setSiteVisits([]);
                        setTotalPages(0);
                        return;
                    }
                    if (
                        data?.status === 'TOKEN_EXPIRED' ||
                        data?.status === 'INVALID_TOKEN'
                    ) {
                        if (isInternalWebview) {
                            dispatch(
                                authActions.setUnauthorizedUser(data.message)
                            );
                            return;
                        }
                        logout();
                        return;
                    }
                }
                setTotalPages(data?.totalPages);
                setSiteVisits(data?.content || []);
            }
        );
    };

    const cancelVisit = (visitId, setShowCancelModal) => {
        let data = {
            status: 'CANCELLED',
        }
        winnreAuth({
            method: 'POST',
            url: `/winnre/user/activity/update/${visitId}`,
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            if (res?.status == 'FAILED') {
                toast.error(res?.message)
            }
            setShowCancelModal(false)
            setSiteVisitsStatusChanged(true)
        }
        ).catch(() => {
            setShowCancelModal(false)
            toast.error("Something went wrong")
        })
    }

    const bookNow = (enquiryId, setShowBookNowModal) => {
        winnreAuth({
            method: 'POST',
            url: `/winnre/user/enquiry/book-now/${enquiryId}`,
        }).then((res) => {
            if (res?.status == APIErrorCondition) {
                toast.error(res?.message)
            }
            setShowBookNowModal(false)
        }
        ).catch(() => {
            setShowBookNowModal(false)
            toast.error("Something went wrong")
        })
    }

    useEffect(() => {
        if (isAuthenticated) {
            setCurrentPage(1);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    useEffect(() => {
        getSiteVisits()
    }, [currentPage, catalyst, isAuthenticated, siteVisitsStatusChanged]);

    return {
        siteVisits,
        loadingVisits,
        cancelVisit,
        bookNow,
        totalPages,
        currentPage,
        setCurrentPage
    };
};

export default useData;
