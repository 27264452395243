import React from 'react';
import styled from 'styled-components';

const Img = styled.img`
    ${(props) => props.height && `height: ${props.height} !important;`}
    ${(props) => props.width && `width: ${props.width} !important;`}
`;

const ImgContainer = styled.div`
    ${(props) =>
        props.position && `justify-content: ${props.position} !important;`}
`;

const Image = (props) => {
    const { url, height, width, position, className, classNameContainer, onImageClick } =
        props;

    if (!url) {
        return <div />;
    }

    return (
        <ImgContainer
            className={classNameContainer ? classNameContainer : 'flex'}
            position={position}
        >
            <Img
                height={height}
                width={width}
                className={className || ''}
                src={url}
                onClick={onImageClick}
            />
        </ImgContainer>
    );
};

export default Image;
