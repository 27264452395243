import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../Components/Organisms/Modal';
import useFilePicker from '../../Hooks/use-filePicker';
import GrClose from '@meronex/icons/gr/GrClose';
import FileLayout from '../../Components/Molecules/FileLayout';

function UploadInvoiceModal({
    isVisible,
    setInvoiceModalVisibility,
    updateInvoice,
    loading
}) {
    const {
        state: { files },
        pickFile,
        removeFile,
        setFiles
    } = useFilePicker({ inputId: 'hidden-input', multiple: false });

    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setFiles([]);
    }, [isVisible]);

    React.useEffect(() => {
        setError(null);
    }, [files]);

    const onSubmit = () => {
        if (files.length === 0) {
            return;
        }
        if (files?.some((f) => f.size > 10000000)) {
            setError('File should be less than 10 MB');
            return;
        }
        updateInvoice(files[0]);
    };

    return (
        <Modal
            visible={isVisible}
            onClick={() => setInvoiceModalVisibility(false)}
        >
            {/* {uploadingDocuments && (
                <div className=" absolute h-full w-full bg-black opacity-90 z-30 inset-0 rounded-xl">
                    <LoadingSpinner />
                </div>
            )} */}

            <div className="px-3 md:px-8">
                <div className={`flex justify-between pb-0 md:pb-2 `}>
                    <h1 className=" font-Poppins font-bold text-lg md:text-2xl ">
                        Upload Invoice
                    </h1>
                    <GrClose
                        onClick={() => setInvoiceModalVisibility(false)}
                        className={`md:self-center h-5 w-5 cursor-pointer `}
                    />
                </div>
                {/* <h3 className=" font-Poppins text-sm md:text-base">
                    Upload file
                </h3> */}
                <div class=" ">
                    <main class="container mx-auto h-full">
                        <article
                            aria-label="File Upload Modal"
                            class="relative h-full flex flex-col"
                        >
                            <section class="h-full overflow-auto pt-3 pb-5 w-full flex flex-col">
                                <header class="border-dashed border-2 rounded border-gray-400 py-6 flex justify-center items-center">
                                    <label className=" h-10 w-10 md:w-16 md:h-16 rounded-lg  bg-[#154F9D1A] cursor-pointer flex items-center justify-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            className="h-5 w-5 md:h-7 md:w-7"
                                        >
                                            <g
                                                id="plus"
                                                transform="translate(1 1)"
                                            >
                                                <line
                                                    id="Line_197"
                                                    data-name="Line 197"
                                                    y2="18"
                                                    transform="translate(9)"
                                                    fill="none"
                                                    stroke="#15509e"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                />
                                                <line
                                                    id="Line_198"
                                                    data-name="Line 198"
                                                    x2="18"
                                                    transform="translate(0 9)"
                                                    fill="none"
                                                    stroke="#15509e"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                />
                                            </g>
                                        </svg>

                                        <input
                                            id="hidden-input"
                                            type="file"
                                            multiple={false}
                                            class="hidden h-full w-full"
                                            accept="image/jpeg,image/png,application/pdf"
                                            onChange={(e) => {
                                                pickFile(e);
                                            }}
                                            autoComplete="off"
                                        />
                                    </label>
                                    <div class="text-[#BFBFBF] flex flex-col justify-center font-Poppins text-sm md:text-base pl-2">
                                        <p>Drop your file here or</p>
                                        <p>Click to browse</p>
                                    </div>
                                </header>
                                <template id="file-template">
                                    <li class="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24">
                                        <article
                                            tabIndex="0"
                                            class="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm"
                                        >
                                            <img
                                                alt="upload preview"
                                                class="img-preview hidden w-full h-full sticky object-cover rounded-md bg-fixed"
                                            />

                                            <section class="flex flex-col rounded-md text-sm break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                                                <h1 class="flex-1 group-hover:text-blue-800"></h1>
                                                <div class="flex">
                                                    <span class="p-1 text-blue-800">
                                                        <i>
                                                            <svg
                                                                class="fill-current w-4 h-4 ml-auto pt-1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                                                            </svg>
                                                        </i>
                                                    </span>
                                                    <p class="p-1 size text-sm text-gray-700"></p>
                                                    <button class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md text-gray-800">
                                                        <svg
                                                            class="pointer-events-none fill-current w-4 h-4 ml-auto"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                class="pointer-events-none"
                                                                d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </section>
                                        </article>
                                    </li>
                                </template>
                            </section>
                        </article>
                    </main>
                </div>

                <template id="file-template">
                    <h1>FILE</h1>
                    <li class="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24">
                        <article
                            tabIndex="0"
                            class="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm"
                        >
                            <img
                                alt="upload preview"
                                class="img-preview hidden w-full h-full sticky object-cover rounded-md bg-fixed"
                            />

                            <section class="flex flex-col rounded-md text-sm break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                                <h1 class="flex-1 group-hover:text-blue-800"></h1>
                                <div class="flex">
                                    <span class="p-1 text-blue-800">
                                        <i>
                                            <svg
                                                class="fill-current w-4 h-4 ml-auto pt-1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                                            </svg>
                                        </i>
                                    </span>
                                    <p class="p-1 size text-sm text-gray-700"></p>
                                    <button class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md text-gray-800">
                                        <svg
                                            class="pointer-events-none fill-current w-4 h-4 ml-auto"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                class="pointer-events-none"
                                                d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </section>
                        </article>
                    </li>
                </template>
                <div className=" min-h-full max-h-48 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 overflow-y-scroll pr-4">
                    {files.map((file, i) => (
                        <FileLayout
                            file={file}
                            index={i}
                            removeFile={removeFile}
                        />
                    ))}
                </div>

                {!!error && (
                    <p className=" text-sm md:text-base bg-[#CE1D1D1A] text-[#CE1D1D] border-[#CE1D1D] border-[1px] rounded-lg px-2 font-medium text-center py-2 animate-pulse">
                        {error}
                    </p>
                )}

                <div className="flex justify-between pt-4">
                    <button
                        onClick={() => setInvoiceModalVisibility(false)}
                        className="bg-[#BFBFBF] text-white rounded-sm px-6 h-8 md:h-10 "
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onSubmit}
                        className="bg-info text-white rounded-sm px-6 h-8 md:h-10 "
                        disabled={files.length === 0 || loading}
                    >
                        {loading ? 'loading...' : 'Add'}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default UploadInvoiceModal;
