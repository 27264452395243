import moment from 'moment';
import { getConfig } from '../utils/config';
import axios from 'axios';
import { winnreAuth, winnreNoAuth } from '../utils/axios';

export const getFont = (client) => {
    switch (client) {
        case 'GODREJ':
            return {
                heading: 'font-Helvetica_Medium',
                body: 'font-Helvetica_Light'
            };
        case 'CENTURY':
            return {
                heading: 'font-CorporateSSC_Medium',
                body: 'font-CorporateS_Regular',
                secondary: 'font-CorporateS_Light',
                navbar: 'font-CorporateS_Demi'
            };
        case 'SRIJAN':
            return {
                heading: 'font-Montserrat',
                body: 'font-ProximaNovaRegular',
                secondary: 'font-Libre_Baskerville'
            };

        case 'MAHINDRA':
            return {
                heading: 'font-SourceSansPro',
                body: 'font-SourceSansPro',
                secondary: 'font-SourceSansPro'
            };

        case 'EMBASSY':
            return {
                heading: 'font-Gotham_Light',
                body: 'font-Gotham_Book',
                secondary: 'font-Gotham_XLight',
                thin: 'font-Gotham_Thin',
                medium: 'font-Gotham_Medium',
                bold: 'font-Gotham_Bold'
            };

        case 'ARVIND':
            return {
                heading: 'font-Marcellus',
                body: 'font-Lato'
            };

        case 'KOHINOOR':
            return {
                heading: 'font-BebasNeue_Bold',
                body: 'font-Montserrat'
            };

        case 'GERA':
            return {
                heading: 'font-Futura_Medium',
                body: 'font-Futura_Light',
                bold: 'Futura_Bold'
            };
        case 'SPRE':
            return {
                heading: 'font-Gotham_Bold',
                body: 'font-Metropolis',
                secondary: 'font-Din_Medium',
                medium: 'font-Gotham_Medium',
                bold: 'font-Gotham_Bold'
            };

        case 'KRC':
            return {
                body: 'font-Montserrat',
                bold: 'font-PlayfairDisplay'
            };

        case 'JP':
            return {
                heading: 'font-Bebas_Regular',
                body: 'font-Montserrat',
                secondary: 'font-Montserrat',
                medium: 'font-Montserrat',
                bold: 'font-Bebas_Regular'
            };
        case 'M3M':
            return {
                heading: 'font-Marcellus',
                body: 'font-Lato'
            };

        case 'EVOS':
            return {
                heading: 'font-Marcellus',
                body: 'font-Roboto'
            };

        case 'KC':
            return {
                heading: 'font-Poppins',
                body: 'font-Montserrat'
            };

        case 'KP':
            return {
                heading: 'font-Gotham_Light',
                secondary: 'font-Gotham_Medium',
                body: 'font-Gotham_Light',
                bold: 'font-Gotham_Bold'
            };
        case 'METRO':
            return {
                heading: 'font-Gotham_Medium',
                secondary: 'font-Gotham_Light',
                body: 'font-Gotham_Medium',
                bold: 'font-Bell'
            };
        case 'SUSHMA':
            return {
                heading: 'font-Futura_Heavy',
                secondary: 'font-Gotham_Book',
                body: 'font-Gotham_Book',
                bold: 'font-Gotham_Bold'
            };

        case 'BRAMHA':
            return {
                heading: 'font-Poppins',
                body: 'font-Roboto',
                secondary: 'font-Montserrat',
                medium: 'font-Gotham_Medium'
            };
        case 'PURTI':
            return {
                heading: 'font-Literata_Black',
                bold: 'font-Literata_Extrabold',
                body: 'font-Mulish',
                secondary: 'font-Montserrat',
                medium: 'font-Gotham_Medium'
            };
        case 'HDFC':
            return {
                heading: 'font-Helvetica_Regular',
                body: 'font-Helvetica_Regular',
                secondary: 'font-Helvetica_Regular',
                medium: 'font-HelveticaNeue_Bold'
            };
        case 'MAX':
            return {
                heading: 'font-Optima',
                body: 'font-Montserrat',
                secondary: 'font-Lato',
                medium: 'font-Lato'
            };
        case 'NAMAN':
            return {
                heading: 'font-BebasNeue_Bold',
                medium: 'font-Gotham_Medium',
                body: 'font-Gotham_Light'
            };

        case 'AJMERA':
            return {
                heading: 'font-HelveticaNeue_Bold',
                body: 'font-Gotham_Light',
                secondary: 'font-Helvetica_Regular',
                medium: 'font-Gotham_Medium'
            };
        case 'BPTP':
            return {
                heading: 'font-Marcellus',
                body: 'font-Metropolis',
                bold: 'font-Metropolis_Bold'
            };

        case 'SMARTWORLD':
            return {
                heading: 'font-Bebas_Regular',
                body: 'font-OpenSans'
            };
        case 'SMARTWORLDCARE':
            return {
                heading: 'font-Bebas_Regular',
                secondary: 'font-BebasNeue_Light',
                body: 'font-OpenSans'
            };
        case 'BIRLA':
            return {
                heading: 'font-DINNextLTPro_Regular',
                secondary: 'font-Lato_Light',
                body: 'font-DINNextLTPro_Light',
                medium: 'font-DINNextLTPro_Medium',
                thin: 'font-DINNextLTPro_UltraLight'
            };

        default:
            return {
                heading: 'font-Helvetica_Medium',
                body: 'font-Helvetica_Medium'
            };
    }
};

export const getBgColor = (index) => {
    const colors = [
        'bg-red-500',
        'bg-pink-500',
        'bg-purple-500',
        'bg-blue-500',
        'bg-gray-800',
        'bg-yellow-400'
    ];
    return colors[Math.floor(Math.random() * colors.length)];
    // switch (index) {
    //     case 0:
    //         return 'bg-purple-500';
    //     case 1:
    //         return 'bg-pink-500';
    //     case 2:
    //         return 'bg-red-500';
    //     default:
    //         return;
    // }
};

export const addPaddingStringEnd = (str, strLen) => {
    return str.padEnd(strLen, '');
};

export function truncate(str, no_words) {
    return str.split(' ').splice(0, no_words).join(' ');
}

export function toIndianRs(number, showDashForZero) {
    return isNaN(parseInt(number)) || (!parseInt(number) && showDashForZero)
        ? '-'
        : '₹' + parseInt(number).toLocaleString('en-IN');
}

export const currencyToIndianRsWord = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'long'
});

export function openURLNewWindow(data) {
    // console.log('I got clicked');
    return window.open(data.url);
}

export const getTimeInDaysAndHours = (timeString) => {
    // let hours = moment().diff(moment(timeString), 'hours');
    let days = moment().diff(moment(timeString), 'days');

    if (days < 3) {
        return moment(timeString).startOf('hour').fromNow();
    } else {
        return moment(timeString).format('DD/MM/YYYY');
    }
};

export const getInitials = (name, single) => {
    if (!name) {
        return 'U';
    }
    let initials = name?.split(' ');
    if (single) {
        return (initials = name?.substring(0, 1));
    }
    if (initials?.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
    } else {
        initials = name?.substring(0, 2);
    }

    return initials?.toUpperCase();
};

export const getBudgetList = () => [
    {
        id: 0,
        label: 'Below 25 lacs',
        isSystemGenerated: true,
        minAmount: 0,
        maxAmount: 2500000
    },
    {
        id: 1,
        label: '25 - 50 lacs',
        isSystemGenerated: true,
        minAmount: 2500000,
        maxAmount: 5000000
    },
    {
        id: 2,
        label: '50 - 75 lacs',
        isSystemGenerated: true,
        minAmount: 5000000,
        maxAmount: 7500000
    },
    {
        id: 3,
        label: '75 - 1 cr',
        isSystemGenerated: true,
        minAmount: 7500000,
        maxAmount: 10000000
    },
    {
        id: 4,
        label: '1 - 1.5 cr',
        isSystemGenerated: true,
        minAmount: 10000000,
        maxAmount: 15000000
    },
    {
        id: 5,
        label: '1.5 - 2.5 cr',
        isSystemGenerated: true,
        minAmount: 15000000,
        maxAmount: 25000000
    },
    {
        id: 6,
        label: '2.5 cr Onwards',
        isSystemGenerated: true,
        minAmount: 25000000,
        maxAmount: 100000000
    }
];

export const renderDate = (date ,includeSpace = true) => {
    const dateStr = moment(date).format('Do');
    let superScript = '';
    if (dateStr.includes('th')) {
        superScript = 'th';
    } else if (dateStr.includes('rd')) {
        superScript = 'rd';
    } else if (dateStr.includes('st')) {
        superScript = 'st';
    } else {
        superScript = 'nd';
    }
    return (
        <span>
            {moment(date).format('DD')}
            {includeSpace && <>&nbsp;</>}
            <sup>{superScript}</sup>
            {moment(date).format(' MMMM YYYY')}
        </span>
    );
};


export const copyCodeHandler = async (data, setCopiedText, cardId = null) => {
    if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(data);
    }
    setCopiedText(true)

    if (cardId) {
        setCopiedText((prev) => ({ ...prev, [cardId]: true }));

        setTimeout(() => {
            setCopiedText((prev) => ({ ...prev, [cardId]: false }));
        }, 1500);
    } else {
        setCopiedText(true);

        setTimeout(() => {
            setCopiedText(false);
        }, 1500);
    }
};



export const isInViewport = (el) => {
    if (!el) {
        return false;
    }
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    );
};

export const getFormattedUserName = (userName) => {
    if (!userName) {
        return 'USER';
    }
    let username = userName.split(' ');
    const [firstName, surname] = username;
    return firstName + ' ' + (surname?.length >= 1 ? surname[0] : '');
};

const REGEX =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

export const LinkParagraph = (paragraph) => {
    const paragraphArray = paragraph.split(' ');
    return (
        <div>
            {paragraphArray.map((word) => {
                return word.match(REGEX) ? (
                    <>
                        <a
                            href={word}
                            target="_blank"
                            className="text-blue-400"
                        >
                            {word}
                        </a>{' '}
                    </>
                ) : (
                    word + ' '
                );
            })}
        </div>
    );
};

export const webEngageInit = (licenseCode) => {
    let webengage = document.getElementById('_webengage_script_tag');
    webengage.innerHTML = `var webengage;
    ! function(w, e, b, n, g) {
      function o(e, t) {
        e[t[t.length - 1]] = function() {
          r.__queue.push([t.join("."), arguments])
        }
      }
      var i, s, r = w[b],
        z = " ",
        l = "init options track screen onReady".split(z),
        a = "feedback survey notification".split(z),
        c = "options render clear abort".split(z),
        p = "Open Close Submit Complete View Click".split(z),
        u = "identify login logout setAttribute".split(z);
      if (!r || !r.__v) {
        for (w[b] = r = {
            __queue: [],
            __v: "6.0",
            user: {}
          }, i = 0; i < l.length; i++) o(r, [l[i]]);
        for (i = 0; i < a.length; i++) {
          for (r[a[i]] = {}, s = 0; s < c.length; s++) o(r[a[i]], [a[i], c[s]]);
          for (s = 0; s < p.length; s++) o(r[a[i]], [a[i], "on" + p[s]])
        }
        for (i = 0; i < u.length; i++) o(r.user, ["user", u[i]])
      }
    }(window, document, "webengage");
  
  
    if (window.__WEBENGAGE_MOBILE_BRIDGE__) {
  
      (function(bridge) {
  
        var type = Object.prototype.toString;
  
        webengage.user.login = webengage.user.identify = function(id) {
          bridge.login(id)
        };
        webengage.user.logout = function() {
          bridge.logout()
        };
  
        webengage.user.setAttribute = function(name, value) {
          var attr = null;
  
          if (type.call(name) === '[object Object]') {
            attr = name;
          } else {
            attr = {};
            attr[name] = value;
          }
  
          if (type.call(attr) === '[object Object]') {
            bridge.setAttribute(JSON.stringify(attr));
          }
        };
  
        webengage.screen = function(name, data) {
          if (arguments.length === 1 && type.call(name) === '[object Object]') {
            data = name;
            name = null;
          }
  
          bridge.screen(name || null, type.call(data) === '[object Object]' ? JSON.stringify(data) : null);
        };
  
        webengage.track = function(name, data) {
          bridge.track(name, type.call(data) === '[object Object]' ? JSON.stringify(data) : null);
        };
  
      })(window.__WEBENGAGE_MOBILE_BRIDGE__);
  
    } else {
  
      setTimeout(function() {
        var f = document.createElement("script"),
          d = document.getElementById("_webengage_script_tag");
        f.type = "text/javascript",
          f.async = !0,
          f.src = ("https:" == window.location.protocol ? "https://widgets.in.webengage.com" : "http://widgets.in.webengage.com") + "/js/webengage-min-v-6.0.js",
          d.parentNode.insertBefore(f, d)
      });
  
    }
  
    webengage.init("${licenseCode}");`;
};

export const checkRole = (userRole) => {
    if (userRole.includes('CUSTOMER')) {
        return 'CUSTOMER';
    } else if (
        userRole.includes('SALES_MANAGER') ||
        userRole.includes('RELATIONSHIP_MANAGER')
    ) {
        return 'RELATIONSHIP_MANAGER';
    } else if (userRole.includes('EMPLOYEE')) {
        return 'EMPLOYEE';
    } else if (userRole.includes('SITE_VISITOR')) {
        return 'SITE_VISITOR';
    }
};

export const projectStatus = (status) => {
    switch (status) {
        case 'READY_TO_OCCUPY':
            return 'Ready to Move';
        case 'UNDER_CONSTRUCTION':
            return 'Work in Progress';
        case 'NEWLY_LAUNCHED':
            return 'Newly Launched';
        case 'SOLD_OUT':
            return 'Sold Out';
        case 'READY_FOR_FIT_OUTS':
            return 'Ready for Fit Outs';
    }

}

export const getCategoryIdByName = (name) => {
    switch(name){
        case "Member Benefits": return 1
        case "Exclusive Move In Offers": return 2
        case "Home Financing Deals": return 3
        case "Digital Amenities": return 5
    }
}

export const urlShortner = (url) => {
    console.log('trying to shorten link', url)
    return winnreAuth.post(`/winnre/user/short-link?originalUrl=${url}`).then((res) => console.log('res struc', res))
    // axios.post('https://api.short.io/links',  {
    //     domain: 'b1cq.short.gy',
    //     allowDuplicates: true,
    //     originalURL: url,
    //     title: 'Test',
    //   },
    //   {
    //     headers: {
    //       Authorization: 'sk_q8at9dKdglBdnNLh', 
    //       'Content-Type': 'application/json',
    //     },
    //   }).then((res) => {
        
    //     console.log('API RES', res)
    //     return res.data.shortURL}
    //   )
}

export function titleCase(str) {
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }

  export function truncateHtml(html, maxLength) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    let text = doc.body.textContent || '';
  
    if (text.length > maxLength) {
      text = text.slice(0, maxLength) + '...';
  
      // Create a new document to hold the truncated content
      const truncatedDoc = document.implementation.createHTMLDocument();
      const tempDiv = truncatedDoc.createElement('div');
      tempDiv.textContent = text;
      truncatedDoc.body.appendChild(tempDiv);
  
      // Return the inner HTML of the new document's body
      return truncatedDoc.body.innerHTML;
    }
    return html;
  }
  
