import React from 'react';
import AmenityAccordianNew from '../../Molecules/AmenityAccordian/newIndex';
import { getConfig } from '../../../../utils/config';
export default function ProjectAmenitiesNew({
    amenties=[]
}) {
    const config = getConfig()
    return (
        <div id='amenities' className=" mt-8">
            <div className="flex flex-col">
              
                <h4 className={`z-10 text-xl text-[#130E2F] my-2 font-bold ${config.fontTitle}`}>Amenities</h4>
            </div>
            {amenties?.length >= 1 && (
                <AmenityAccordianNew
                    projectAmenities={amenties}
                />
            )}
        </div>
    );
}
