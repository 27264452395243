import React, { useState, useEffect } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import HiOutlineMail from '@meronex/icons/hi/HiOutlineMail';
import { useData } from '../../LoginModal/data';
import { getConfig } from '../../../utils/config';
import GrClose from '@meronex/icons/gr/GrClose';
import SignUp from '../SignUp';
import BiPhone from '@meronex/icons/bi/BiPhone';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BsInfoCircle from '@meronex/icons/bs/BsInfoCircle';

function LoginForm({ closeLoginModal }) {
    const [termsCheckbox, setTermsCheckbox] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Customer');
    const [showInfoText, setShowInfoText] = useState(false);
    const dealRedeemUser = useSelector((state) => state.auth.dealRedeemUser); // To check the redeem user

    const config = getConfig();
    const history = useHistory();
    const onValueChange = (e) => {
        setIsOtpSent(false);
        setSelectedOption(e.target.value);
    };

    const {
        state: {
            mobileNumberIsValid,
            mobile,
            mobileHasError,
            sendOtpLoading,
            OTP,
            OTPHasError,
            OTPIsValid,
            loginLoading,
            email,
            emailHasError,
            emailIsValid,
            alertMessage,
            showSuccessAlert,
            showErrorAlert,
            isInternationalCustomer,
            createRegistration,
            isOtpSent,
            timeLeftToResend,
            loginWithEmail
        },
        onChange,
        validator,
        setIsOtpSent,
        setIsTouched,
        getOTP,
        setInputFocused,
        authenticateHandler,
        OTPChangedHandler,
        OTPBlurHandler,
        emailBlurHandler,
        emailChangedHandler,
        setShowErrorAlert,
        setShowSuccessAlert,
        setAlertMessage,
        setCreateRegistration,
        resetEmail,
        setMobile,
        setIsInternationalCustomer,
        setLoginWithEmail
    } = useData(selectedOption);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSuccessAlert(false);
            setShowErrorAlert(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
            // setAlertMessage(null);
        };
    }, [showSuccessAlert, showErrorAlert]);

    useEffect(() => {
        if (selectedOption == 'Site Visitor') {
            setLoginWithEmail(false);
        }
    }, [selectedOption]);

    const backHandler = () => {
        resetEmail();
        setMobile('+91');
        setIsInternationalCustomer(false);
        setIsTouched(false);
        setTermsCheckbox(false);
        setCreateRegistration(false);
        setIsOtpSent(false);
    };

    const handleOnChange = () => {
        setTermsCheckbox(!termsCheckbox);
    };

    return (
        <>
            {createRegistration ? (
                <>
                    <SignUp
                        setAlertMessage={setAlertMessage}
                        setShowErrorAlert={setShowErrorAlert}
                        setShowSuccessAlert={setShowSuccessAlert}
                        emailIsValid={emailIsValid}
                        email={email}
                        emailBlurHandler={emailBlurHandler}
                        emailHasError={emailHasError}
                        emailChangedHandler={emailChangedHandler}
                        backHandler={backHandler}
                        closeLoginModal={closeLoginModal}
                        loginWithEmail={loginWithEmail}
                        isInternationalCustomer={isInternationalCustomer}
                        selectedOption={selectedOption}
                        createRegistration={createRegistration}
                    />
                </>
            ) : (
                <div className="">
                    <div className={`flex  px-8 pb-2 justify-between`}>
                        <h1
                            className={`self-center text-lg md:text-2xl font-semibold ${config?.loginModal.text} `}
                        >
                            {dealRedeemUser
                                ? 'Please verify your phone number'
                                : 'Login'}
                        </h1>
                        <GrClose
                            onClick={closeLoginModal}
                            className={`self-center h-4 w-4 cursor-pointer ${config?.loginModal.text} `}
                        />
                    </div>
                    {!dealRedeemUser && (
                        <div className="px-8 pb-4 ">
                            {/* <p className="block text-sm md:text-base tracking-wide self-centre text-gray-600 ">
                            Select your role{' '}
                        </p> */}
                            <div className="flex gap-y-2 flex-wrap md:flex-row gap-x-2 mt-2">
                                <button
                                    value="Customer"
                                    onClick={(e) => onValueChange(e)}
                                    className={`py-2 px-3 ${
                                        selectedOption == 'Customer'
                                            ? 'bg-primary text-white'
                                            : 'border-2 border-gray-200'
                                    }  rounded`}
                                >
                                    Customer
                                </button>
                                {config?.loginModal?.showEmployeeLogin && (
                                    <button
                                        value="Employee"
                                        onClick={(e) => onValueChange(e)}
                                        className={`py-2 px-3 ${
                                            selectedOption == 'Employee'
                                                ? 'bg-primary text-white'
                                                : 'border-2 border-gray-200'
                                        }  rounded`}
                                    >
                                        Employee
                                    </button>
                                )}
                                {config?.loginModal?.showSVLogin && (
                                    <button
                                        value="Site Visitor"
                                        onClick={(e) => onValueChange(e)}
                                        className={`py-2 px-3 ${
                                            selectedOption == 'Site Visitor'
                                                ? 'bg-primary text-white'
                                                : 'border-2 border-gray-200'
                                        }  rounded`}
                                    >
                                        Guest
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    <div class="px-8 ">
                        <form class="w-full">
                            {selectedOption !== 'Employee' &&
                                !loginWithEmail && (
                                    <div
                                        className={` w-full flex flex-col mx-auto ${
                                            selectedOption === 'Customer'
                                                ? 'mb-2'
                                                : 'mb-2'
                                        }  relative`}
                                    >
                                        <label className="block font-semibold mb-1 text-sm md:text-base tracking-wide self-centre text-gray-600 ">
                                            Phone number
                                        </label>
                                        <PhoneInput
                                            disabled={isOtpSent}
                                            country="in"
                                            countryCodeEditable={false}
                                            containerClass={
                                                'peer py-3 border border-gray-300 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full  h-12 placeholder-transparent'
                                            }
                                            containerStyle={{
                                                width: '100%',
                                                border: '2px solid grey !important',
                                                outline: 'none'
                                            }}
                                            buttonStyle={{
                                                backgroundColor: 'white',
                                                border: 'none'
                                            }}
                                            inputStyle={{
                                                width: '100%',
                                                height: 'auto',
                                                border: 'none',
                                                outline: 'none',
                                                paddingLeft: '44px',
                                                fontSize: '16px'
                                            }}
                                            isValid={validator}
                                            onChange={onChange}
                                            // value={mobile}
                                            placeholder="Phone"
                                            enableSearch
                                            onBlur={() => {
                                                setIsTouched(true);
                                                setInputFocused(false);
                                            }}
                                            onFocus={() =>
                                                setInputFocused(true)
                                            }
                                            inputClass="border border-gray-300"
                                        />
                                        {mobileHasError && (
                                            <p
                                                className={` text-left text-sm mt-1 font-Roboto text-red-400`}
                                            >
                                                Please enter a valid number
                                            </p>
                                        )}
                                    </div>
                                )}
                            {(isInternationalCustomer ||
                                selectedOption === 'Employee' ||
                                loginWithEmail) && (
                                <div
                                    class={`${
                                        selectedOption === 'Customer'
                                            ? 'mb-2'
                                            : 'mb-2 md:mb-2'
                                    }  relative`}
                                >
                                    <input
                                        type="email"
                                        id="email"
                                        class="peer pt-8 border border-gray-300 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full p-3 h-12 placeholder-transparent"
                                        placeholder="name@example.com"
                                        onChange={emailChangedHandler}
                                        onBlur={emailBlurHandler}
                                        value={email}
                                        disabled={isOtpSent}
                                        autoComplete="off"
                                    />
                                    <label
                                        for="email"
                                        class="peer-placeholder-shown:opacity-100  opacity-75 peer-focus:opacity-75 peer-placeholder-shown:scale-100 scale-75 peer-focus:scale-75 peer-placeholder-shown:translate-y-0 -translate-y-3 peer-focus:-translate-y-3 peer-placeholder-shown:translate-x-0 translate-x-1 peer-focus:translate-x-1 absolute top-0 left-0 px-3 py-3 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out"
                                    >
                                        {selectedOption === 'Employee'
                                            ? 'Company Email Address'
                                            : 'Email address'}
                                    </label>
                                    {emailHasError && (
                                        <p className="text-left text-sm mt-1 font-Roboto text-red-400">
                                            Please enter a valid email address
                                        </p>
                                    )}
                                </div>
                            )}
                            <div className="flex my-3">
                                <input
                                    disabled={isOtpSent}
                                    type="checkbox"
                                    checked={termsCheckbox}
                                    onChange={handleOnChange}
                                    className="checkbox-sm bg-white rounded-md border-gray-100 checked:bg-blue-600"
                                    autoComplete="off"
                                />
                                <p className={` mx-2 text-sm `}>
                                    I agree to the
                                    <span
                                        onClick={() => {
                                            history.push(
                                                '/terms-and-conditions'
                                            );
                                            closeLoginModal();
                                        }}
                                        className="font-semibold text-primary cursor-pointer"
                                    >
                                        {` Terms & Conditions`}
                                    </span>
                                </p>
                            </div>
                            <button
                                className={`w-full ${config?.loginModal.buttonBg} text-white p-2 md:p-3 rounded-md disabled:hover:text-white disabled:opacity-90 disabled:bg-gray-400 disabled:cursor-not-allowed`}
                                disabled={
                                    // showEmail
                                    //     ? !mobileNumberIsValid || !emailIsValid
                                    //     : !mobileNumberIsValid || sendOtpLoading
                                    isOtpSent
                                        ? !isOtpSent
                                        : selectedOption === 'Employee'
                                        ? !emailIsValid ||
                                          sendOtpLoading ||
                                          !termsCheckbox
                                        : isInternationalCustomer
                                        ? !mobileNumberIsValid ||
                                          !emailIsValid ||
                                          sendOtpLoading ||
                                          !termsCheckbox
                                        : loginWithEmail
                                        ? !emailIsValid ||
                                          !termsCheckbox ||
                                          sendOtpLoading
                                        : !mobileNumberIsValid ||
                                          sendOtpLoading ||
                                          !termsCheckbox
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (isOtpSent) {
                                        setIsOtpSent(false);
                                    } else {
                                        getOTP();
                                    }
                                }}
                                type={isOtpSent ? 'button' : 'submit'}
                            >
                                {isOtpSent
                                    ? `Use Another ${
                                          selectedOption === 'Employee' ||
                                          isInternationalCustomer ||
                                          loginWithEmail
                                              ? 'Email'
                                              : 'Mobile Number'
                                      }`
                                    : loginWithEmail ||
                                      selectedOption === 'Employee' ||
                                      isInternationalCustomer
                                    ? 'Get OTP on Mail'
                                    : 'Get OTP on Phone'}
                            </button>

                            {isOtpSent && (
                                <>
                                    <p className="mb-2 mt-2 opacity-75">
                                        OTP sent to{' '}
                                        <b>
                                            {selectedOption === 'Employee' ||
                                            isInternationalCustomer ||
                                            loginWithEmail
                                                ? email
                                                : mobile?.substr(2)}
                                        </b>
                                    </p>
                                    <div
                                        class={`${
                                            selectedOption === 'Customer'
                                                ? 'mb-2'
                                                : 'mb-2 md:mb-2'
                                        } relative`}
                                    >
                                        <input
                                            type="text"
                                            id="otp"
                                            class="peer pt-8 border border-gray-300 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full p-3 h-12 placeholder-transparent"
                                            placeholder="password"
                                            onChange={OTPChangedHandler}
                                            onBlur={OTPBlurHandler}
                                            value={OTP}
                                            autoComplete="off"
                                        />
                                        <label
                                            for="password"
                                            class="peer-placeholder-shown:opacity-100   opacity-75 peer-focus:opacity-75 peer-placeholder-shown:scale-100 scale-75 peer-focus:scale-75 peer-placeholder-shown:translate-y-0 -translate-y-3 peer-focus:-translate-y-3 peer-placeholder-shown:translate-x-0 translate-x-1 peer-focus:translate-x-1 absolute top-0 left-0 px-3 py-3 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out"
                                        >
                                            OTP
                                        </label>
                                        {OTPHasError && (
                                            <p className="text-left text-sm mt-1 font-Roboto text-red-400">
                                                {`Please enter a valid OTP`}
                                            </p>
                                        )}
                                    </div>

                                    <p className="opacity-75 mb-2 text-sm">
                                        Didn't Receive OTP?{' '}
                                        {timeLeftToResend > 0 ? (
                                            <b>
                                                Request Again in 00:
                                                {timeLeftToResend < 10
                                                    ? '0' + timeLeftToResend
                                                    : timeLeftToResend}
                                            </b>
                                        ) : (
                                            <b
                                                className="opacity-75 link"
                                                onClick={getOTP}
                                            >
                                                Request Again
                                            </b>
                                        )}
                                    </p>
                                    <button
                                        type={!isOtpSent ? 'button' : 'submit'}
                                        disabled={!OTPIsValid || loginLoading}
                                        onClick={authenticateHandler}
                                        class={`w-full ${config?.loginModal.buttonBg} text-white p-2 md:p-3 rounded-md mb-3 disabled:opacity-90 disabled:bg-gray-400 disabled:cursor-not-allowed`}
                                    >
                                        Log in
                                    </button>
                                </>
                            )}
                            {selectedOption == 'Customer' &&
                                !isOtpSent &&
                                !dealRedeemUser && (
                                    <>
                                        {' '}
                                        <h3 className="w-full text-center border-b-[1px] border-gray-300 mt-4 mb-4 leading-[0.1em]">
                                            <span className="px-1 bg-white text-gray-400">
                                                or
                                            </span>
                                        </h3>
                                        <button
                                            className={`flex items-center justify-center mt-5  w-full border-[2px] border-primary text-primary p-2 md:p-3 rounded-md  `}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setLoginWithEmail(
                                                    (prev) => !prev
                                                );
                                            }}
                                            type={
                                                isOtpSent ? 'button' : 'submit'
                                            }
                                        >
                                            {loginWithEmail ? (
                                                <BiPhone className="h-6 w-6 mr-1" />
                                            ) : (
                                                <HiOutlineMail className="h-6 w-6 mr-1" />
                                            )}
                                            {loginWithEmail
                                                ? 'Continue with Mobile Number'
                                                : 'Continue with Email'}
                                        </button>
                                    </>
                                )}
                        </form>
                    </div>
                </div>
            )}
            {showSuccessAlert && (
                <div class="alert alert-success  mx-8 animate-pulse  h-auto mt-2">
                    <label>{alertMessage}</label>
                </div>
            )}
            {showErrorAlert && (
                <div class="alert alert-error  mx-8 animate-pulse h-auto mt-2 ">
                    <label>{alertMessage}</label>
                </div>
            )}
            <div className="flex justify-center items-center pt-3 relative">
                <button
                    onClick={() => {
                        closeLoginModal();
                        history.push('/contact-us?loginissue=true');
                    }}
                    className={`text-[#16519E] text-center text-[15px] underline `}
                >
                    {createRegistration
                        ? 'Already registered as a customer?'
                        : ' Having trouble logging in?'}
                </button>
                {/* {createRegistration && <div
                    onMouseEnter={() => setShowInfoText(true)}
                    onMouseLeave={() => setShowInfoText(false)}
                    className="cursor-pointer"
                >
                    <BsInfoCircle className="ml-2" />
                </div>}
               {showInfoText && createRegistration && <div className='absolute w-2/3 md:w-1/2 bottom-6 right-2 text-center text-xs md:text-sm bg-[#F0F0F0] rounded-lg p-2'>If you are already registered as a customer, we would recommend using the “Having trouble logging In?” button below to help us update your info</div>} */}
            </div>
        </>
    );
}

export default LoginForm;
