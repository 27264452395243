import axios from 'axios';
import React from 'react';
import Modal from '../Modal';

import { useEffect, useState } from 'react';
import { winnreAuth } from '../../../../utils/axios';

import { LOYALTY, RE_EMAIL, RE_NAME } from '../../../../constants';

import { useSelector } from 'react-redux';
import { useHistory,useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import StepOne from './Component/StepOne';
import StepTwo from './Component/StepTwo';
import usePhoneInput from '../../../../Hooks/usePhoneInput';
import useInput from '../../../../Hooks/useInput';
import { getConfig } from '../../../../utils/config';
import ScratchCard2 from '../Scratchcard2';
import { toast } from 'react-toastify';
import EiExternalLink from '@meronex/icons/ei/EiExternalLink';
import BiCopy from '@meronex/icons/bi/BiCopy';
import AiOutlineClose from '@meronex/icons/ai/AiOutlineClose';
import moment from 'moment';

function UserProjectForm({
    changeCurrentStep,
    userRole,
    projectToRefer,
    setProjectToRefer,
    customerID,
    employeeTypeSelected,
    customerData,
    enquiryCities,
    setPlayInfluencerVideo,
    currentStep
}) {
    const [step, setStep] = useState(1);
    const [termsCheckbox, setTermsCheckbox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [loyalty, setLoyalty] = useState(null);
    const [freeText, setFreeText] = useState(null);
    const [RM, setRM] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [cityProjectList, setCityProjectList] = useState([]);
    

    const config=getConfig();
    const {
        state: {
            isValid: mobileNumberIsValid,
            mobile,
            country,
            mobileHasError,
            isInputFocused
        },
        onChange,
        validator,
        setIsTouched: setMobileIsTouched,
        setMobile,
        getMobileWithoutCountryCode,
        setInputFocused
    } = usePhoneInput();
    const {
        value: referredLeadName,
        isValid: referredLeadNameIsValid,
        hasError: referredLeadNameHasError,
        valueChangeHandler: referredLeadNameChangedHandler,
        inputBlurHandler: referredLeadNameBlurHandler,
        reset: resetReferredLeadNameInput,
        setIsTouched: setReferredLeadNameIsTouched
    } = useInput((value) => !RE_NAME.test(value) && value !== '');

    const {
        value: comments,
        valueChangeHandler: commentsChangedHandler,
        reset: resetcommentsInput
    } = useInput((value) => value.trim() !== '');

    const {
        value: referredLeadEmail,
        isValid: referredLeadEmailIsValid,
        valueChangeHandler: referredLeadEmailChangedHandler,
        reset: resetreferredLeadEmailInput,
        hasError: referredLeadEmailHasError,
        inputBlurHandler: referredLeadEmailBlurHandler,
        setIsTouched: setReferredLeadEmailIsTouched

    } = useInput((value) => RE_EMAIL.test(value));

    const [isFormValid, setIsFormValid] = useState(false);

    const handleFormValidityChange = (isValid) => {
        setIsFormValid(isValid);
    };
    let referralSource = userRole;
    if (userRole === 'RELATIONSHIP_MANAGER') {
        config?.referralForm.userTypes.salesAdmin
            ? employeeTypeSelected === 'EMPLOYEE'
                ? (referralSource = 'EMPLOYEE')
                : (referralSource = 'CUSTOMER')
            : (referralSource = 'EMPLOYEE');
    } else if (userRole === 'EMPLOYEE') {
        config?.referralForm.userTypes.employee
            ? employeeTypeSelected === 'RELATIONSHIP_MANAGER'
                ? (referralSource = 'CUSTOMER')
                : (referralSource = 'EMPLOYEE')
            : (referralSource = 'CUSTOMER');
    }



    const referHandler = (e) => {
        e.preventDefault();
       
        window?.webengage?.track('ReferralActions', {
            City: `${projectToRefer?.city}`,
            Project: `${projectToRefer?.projectName}`
        });
        let result =
            loyalty === 'From my RM'
                ? config?.disableRMDetails ? '' : { loyalty: RM?.rmId }
                : loyalty === 'Other'
                ? { loyalty: freeText }
                : { loyalty: loyalty };

        let data = {
            name: referredLeadName,
            mobileNumber: getMobileWithoutCountryCode(),
            projectId: projectToRefer?.projectId,
            referralSource,
            source: 'REFERRAL',
            loyalty: result,
            email: referredLeadEmail,
            comment: comments
        };
        let url;
        if (
            (userRole === 'RELATIONSHIP_MANAGER' &&
                referralSource === 'CUSTOMER') ||
            (userRole === 'EMPLOYEE' && referralSource === 'CUSTOMER')
        ) {
            url = `/winnre/sm/enquiry?userId=${customerID}`;
        } else {
            url = `/winnre/user/enquiry`;
        }
        // if (!isFormValid) {
        //     return;
        // }

        setLoading(true)
        setModalMessage('Submitting referral details');
        setShowModal(true);
        winnreAuth({
            method: 'post',
            url,
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            data: JSON.stringify(data)
        })
            .then((res) => {
                console.log('referral response', res);
                setLoading(false);
                if (res?.status === 'FAILED') {
                    setModalMessage(
                        res?.message ||
                            'Something went wrong. Please try again!'
                    );
                    setShowModal(true);
                    window?.webengage?.track('ReferralFailed', {
                        Reason: `${res?.message}`
                    });
                } else if (res?.status === 'TOKEN_EXPIRED') {
                    setModalMessage(
                        'Session expired. Please refresh and submit form again!'
                    );
                    setShowModal(true);
                    window?.webengage?.track('ReferralFailed', {
                        Reason: `${res?.message}`
                    });
                } else if (res?.lead) {
                    setProjectToRefer(null);
                    resetReferredLeadNameInput();
                    setLoyalty('');
                    //setRM(null);
                    setMobile('+91');
                  
                    setMobileIsTouched(false);
                    setTermsCheckbox(false);
                    resetcommentsInput();
                    resetreferredLeadEmailInput();
                    changeCurrentStep('afterFormSubmittedChoice');
                    window?.webengage?.track('ReferralDone', {
                        LeadName: `${referredLeadName}`,
                        ProjectId: `${projectToRefer?.projectId}`,
                        ProjectName: `${projectToRefer?.projectName}`,
                        ProjectCity: `${projectToRefer?.city}`,
                        Source: 'Frontend',
                        EnquiryId: `${res?.id}`
                    });
                    return;
                }
            })
            .catch((err) => {
                setProjectToRefer(null);
                setModalMessage('Could not create lead! Please try again!');
                setShowModal(true);
                setLoading(false);
                window?.webengage?.track('ReferralFailed', {
                    Reason: `${err}`
                });
            });
    };
// useEffect(() => {generateScratchcard()
//     setOpenScratchCard('OPEN')
// }, [])


    // To switch the form between 2 steps

    const getConditionalFormStep = () => {
        switch (step) {
            case 1:
                return (
                    <StepOne
                        setStep={setStep}
                        enquiryCities={enquiryCities}
                        projectToRefer={projectToRefer}
                        setProjectToRefer={setProjectToRefer}
                        setPlayInfluencerVideo={setPlayInfluencerVideo}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        selectedCity={selectedCity}
                        setSelectedCity={setSelectedCity}
                        cityProjectList={cityProjectList}
                        setCityProjectList={setCityProjectList}

                    />
                );
            case 2:
                return (
                    <StepTwo
                        setStep={setStep}
                        enquiryCities={enquiryCities}
                        config={config?.referralForm}
                        userRole={userRole}
                        changeCurrentStep={changeCurrentStep}
                        termsCheckbox={termsCheckbox}
                        setTermsCheckbox={setTermsCheckbox}
                        loyalty={loyalty}
                        setLoyalty={setLoyalty}
                        freeText={freeText}
                        setFreeText={setFreeText}
                        RM={RM}
                        setRM={setRM}
                        isInputFocused={isInputFocused}
                        setInputFocused={setInputFocused}
                        onChange={onChange}
                        validator={validator}
                        mobile={mobile}
                        setMobileIsTouched={setMobileIsTouched}
                        setMobile={setMobile}
                        mobileHasError={mobileHasError}
                        mobileNumberIsValid={mobileNumberIsValid}
                        referredLeadName={referredLeadName}
                        referredLeadNameHasError={referredLeadNameHasError}
                        referredLeadNameChangedHandler={
                            referredLeadNameChangedHandler
                        }
                        referredLeadNameBlurHandler={
                            referredLeadNameBlurHandler
                        }
                        referredLeadNameIsValid={referredLeadNameIsValid}
                        referredLeadEmail={referredLeadEmail}
                        referredLeadEmailIsValid={referredLeadEmailIsValid}
                        referredLeadEmailChangedHandler={
                            referredLeadEmailChangedHandler
                        }
                        referredLeadEmailHasError={referredLeadEmailHasError}
                        referredLeadEmailBlurHandler={
                            referredLeadEmailBlurHandler
                        }
                        comments={comments}
                        commentsChangedHandler={commentsChangedHandler}
                        onFormValidityChange={handleFormValidityChange}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        referHandler={referHandler}
                        projectToRefer={projectToRefer}
                        setReferredLeadNameIsTouched={setReferredLeadNameIsTouched}
                        setReferredLeadEmailIsTouched={setReferredLeadEmailIsTouched}
                    />
                );
            default:
                <></>;
        }
    };

    return (
        <div className="mt-8 mx-auto flex flex-col h-full w-[80%] lg:w-[95%] lg:mx-0 2xl:w-[90%]  text-black ">
            <div className=" lg:pl-6 xl:pl-8">
                <div className=" flex flex-col lg:flex-row gap-4 lg:justify-between">
                    <p className="text-lg md:text-xl xl:text-2xl font-bold whitespace-nowrap">
                        Enter Referral details
                    </p>
                    {step === 1 ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="190"
                            height="40"
                            viewBox="0 0 190 40"
                        >
                            <g
                                id="Group_11157"
                                data-name="Group 11157"
                                transform="translate(-603 -632)"
                            >
                                <g
                                    id="Group_11155"
                                    data-name="Group 11155"
                                    transform="translate(146)"
                                >
                                    <circle
                                        id="Ellipse_1776"
                                        data-name="Ellipse 1776"
                                        cx="20"
                                        cy="20"
                                        r="20"
                                        transform="translate(607 632)"
                                        fill="#f0f0f0"
                                    />
                                    <text
                                        id="_2"
                                        data-name="2"
                                        transform="translate(622 658)"
                                        fill="#908f95"
                                        font-size="18"
                                        font-family="Roboto-Regular, Roboto"
                                    >
                                        <tspan x="0" y="0">
                                            2
                                        </tspan>
                                    </text>
                                </g>
                                <rect
                                    id="Rectangle_17159"
                                    data-name="Rectangle 17159"
                                    width="125"
                                    height="4"
                                    transform="translate(634 650)"
                                    fill="#f0f0f0"
                                />
                                <g
                                    id="Group_11156"
                                    data-name="Group 11156"
                                    transform="translate(106)"
                                >
                                    <circle
                                        id="Ellipse_1775"
                                        data-name="Ellipse 1775"
                                        cx="20"
                                        cy="20"
                                        r="20"
                                        transform="translate(497 632)"
                                        fill={config.primaryColor}
                                    />
                                    <text
                                        id="_1"
                                        data-name="1"
                                        transform="translate(512 658)"
                                        fill="#fff"
                                        font-size="18"
                                        font-family="Roboto-Regular, Roboto"
                                    >
                                        <tspan x="0" y="0">
                                            1
                                        </tspan>
                                    </text>
                                </g>
                            </g>
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="190"
                            height="40"
                            viewBox="0 0 190 40"
                        >
                            <g
                                id="Group_11157"
                                data-name="Group 11157"
                                transform="translate(-603 -632)"
                            >
                                <g
                                    id="Group_11156"
                                    data-name="Group 11156"
                                    transform="translate(106)"
                                >
                                    <circle
                                        id="Ellipse_1775"
                                        data-name="Ellipse 1775"
                                        cx="20"
                                        cy="20"
                                        r="20"
                                        transform="translate(497 632)"
                                        fill={config.primaryColor}
                                    />
                                    <text
                                        id="_1"
                                        data-name="1"
                                        transform="translate(513 658)"
                                        fill="#fff"
                                        font-size="18"
                                        font-family="Roboto-Regular, Roboto"
                                    >
                                        <tspan x="0" y="0">
                                            1
                                        </tspan>
                                    </text>
                                </g>
                                <g
                                    id="Group_11155"
                                    data-name="Group 11155"
                                    transform="translate(146)"
                                >
                                    <circle
                                        id="Ellipse_1776"
                                        data-name="Ellipse 1776"
                                        cx="20"
                                        cy="20"
                                        r="20"
                                        transform="translate(607 632)"
                                        fill={config.primaryColor}
                                    />
                                    <text
                                        id="_2"
                                        data-name="2"
                                        transform="translate(622 658)"
                                        fill="#fff"
                                        font-size="18"
                                        font-family="Roboto-Regular, Roboto"
                                    >
                                        <tspan x="0" y="0">
                                            2
                                        </tspan>
                                    </text>
                                </g>
                                <rect
                                    id="Rectangle_17159"
                                    data-name="Rectangle 17159"
                                    width="125"
                                    height="4"
                                    transform="translate(634 650)"
                                    fill={config.primaryColor}
                                />
                            </g>
                        </svg>
                    )}
                </div>

                {getConditionalFormStep()}
            </div>

            <Modal
                visible={showModal}
                onClick={() => setShowModal(false)}
                content={modalMessage}
            />

{/* <Modal
                onClick={() => {}}
                visible={openScratchCard === 'OPEN'}
                opacity="bg-opacity-90"
                showButton={false}
            >
                <div className="flex flex-col items-center justify-center">
                    <button
                        onClick={() => {
                            setOpenScratchCard('CLOSE');
                            handleView(rewardData?.id);
                        }}
                        className="text-white mb-6 font-semibold flex items-center justify-center"
                    >
                        <AiOutlineClose />
                        CLOSE
                    </button>
                    <ScratchCard2
                        foregroundImageSrc="https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1718014789061_reward"
                        completedAt={100}
                        onCompleted={() => {
                            setRewardClass('z-50');
                            handleScratch(rewardData?.id);
                        }}
                        size="h-[350px] w-[350px] "
                        isModalSC
                    >
                        <div
                            className={`bg-gray-100 cursor-default flex flex-col h-[350px] w-[350px] rounded-2xl justify-center px-2 ${rewardClass}`}
                        >
                            <img
                                className="w-[100px] h-[100px] mx-auto rounded-lg"
                                src={rewardData?.deal?.vendor?.logo?.url}
                            />

                            <h1 className=" mt-4 mx-auto text-center text-gray-600 font-medium uppercase text-[16px] leading-4 ">
                                <span>{rewardData?.deal?.title}</span>
                            </h1>
                            <p className='text-sm px-5 py-2 text-center break-all'>
                                {rewardData?.deal?.description.slice(0, 135).replace(/<\/?p>/g, '')}...
                            </p>
                                {rewardData?.deal?.redemptionType ===
                                    'CODE' && (
                                    <div className="flex flex-col justify-center  w-full">
                                        <h2 className="uppercase text-gray-600 md:py-2 text-center text-xs tracking-widest">
                                            Coupon Code
                                        </h2>
                                        <div className="flex justify-center mb-2">
                                                {!copiedText[
                                                    rewardData?.id
                                                ] && (
                                                    <div
                                                        title="Click to copy "
                                                        onClick={() =>
                                                            copyCodeHandler(
                                                                rewardData?.deal
                                                                    ?.dealRedemptionCode
                                                                    ?.dealCode,
                                                                rewardData?.id
                                                            )
                                                        }
                                                        className="border-2 tracking-widest flex items-center justify-between bg-[#f0f0f0] text-gray-700 border-[#dcdcdc] border-dashed py-2 px-12 hover:border-blue-900 cursor-pointer my-auto rounded-lg text-[15px] relative"
                                                    >
                                                        <p className="text-sm">
                                                            {
                                                                rewardData?.deal
                                                                    ?.dealRedemptionCode
                                                                    ?.dealCode
                                                            }
                                                        </p>
                                                        <svg
                                                                        className="ml-2"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16.391"
                                                                        height="17.859"
                                                                        viewBox="0 0 16.391 17.859"
                                                                    >
                                                                        <path
                                                                            id="copy-copy"
                                                                            d="M76.7-4.892H68.5a2.05,2.05,0,0,1-2.049-2.05V-16.6a2.05,2.05,0,0,1,2.049-2.05h8.2a2.05,2.05,0,0,1,2.049,2.05v9.658A2.05,2.05,0,0,1,76.7-4.892ZM77.72-16.6A1.025,1.025,0,0,0,76.7-17.626H68.5A1.025,1.025,0,0,0,67.476-16.6v9.658A1.025,1.025,0,0,0,68.5-5.918h8.2A1.025,1.025,0,0,0,77.72-6.943V-16.6ZM63.378-12.5v9.658A1.025,1.025,0,0,0,64.4-1.817h8.2a1.025,1.025,0,0,0,1.024-1.025V-3.867h1.024v1.025A2.05,2.05,0,0,1,72.6-.792H64.4a2.05,2.05,0,0,1-2.049-2.05V-12.5A2.05,2.05,0,0,1,64.4-14.55h1.024v1.025H64.4A1.025,1.025,0,0,0,63.378-12.5Z"
                                                                            transform="translate(-62.354 18.651)"
                                                                            fill="#16519e"
                                                                        />
                                                                    </svg>
                                                    </div>
                                                )}
                                                {copiedText[rewardData?.id] && (
                                                    <p
                                                        className={`self-center mx-auto text-center font-normal animate-pulse bg-[#154F9D1A] text-[#006EB7] py-2 px-12 text-sm rounded text-[15px] md:text-base`}
                                                    >
                                                        Code Copied
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                )}
                                {rewardData?.redemptionType !== 'CODE' && (
                                    <p
                                        className={` text-sm text-[#373737] mt-3 text-center`}
                                    >
                                        Valid upto :{' '}
                                        {renderDate(rewardData?.deal?.endDate)}
                                    </p>
                                )}
                                <button
                                    onClick={() => {
                                        handleRedeem(rewardData?.id);
                                        window.open(rewardData?.deal?.url);
                                    }}
                                    className={`text-sm mt-3 cursor-pointer font-semibold text-[#16519E] w-full flex items-center justify-center`}
                                >
                                    Click to redeem{' '}
                                    <EiExternalLink className="w-5 h-5 text-[#16519E]" />
                                </button>
                        </div>
                    </ScratchCard2>
                </div>
            </Modal> */}
        </div>
    );
}

export default UserProjectForm;
