import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { winnreAuth } from '../../utils/axios';
import EiExternalLink from '@meronex/icons/ei/EiExternalLink';
import AiOutlineClose from '@meronex/icons/ai/AiOutlineClose';
import Modal from '../ReferralPage/Components/Modal';
import ScratchCard2 from '../ReferralPage/Components/Scratchcard2';
import { copyCodeHandler } from '../../helpers';
import { renderDate } from '../../helpers';
import { truncateHtml } from '../../helpers';

const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
const isHTML2 = (content) => /<\/?[a-z][\s\S]*>/i.test(content);

function Thankyou() {
    const history = useHistory();
    const location = useLocation();
    const [rewardData, setRewardData] = useState();
    const [rewardClass, setRewardClass] = useState('');
    const [copiedText, setCopiedText] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const scParam = queryParams.get('sc');
    const [openScratchCard, setOpenScratchCard] = useState(
        !!scParam ? 'TRIGGER' : 'CLOSE'
    );

    const websiteUserRole = useSelector((state) => state.auth.userRole);
    const userRole = websiteUserRole;
    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    const clickThroughMobile = clientWebview || internalWebview;

    const generateScratchcard = () => {
        let url = `/winnre/user/scratchCard/generate?eventName=survey_submit`;
        // if (openScratchCard === 'TRIGGER') {
        if (userRole === 'RELATIONSHIP_MANAGER' || userRole === 'EMPLOYEE') {
            return;
        }
        winnreAuth({
            method: 'post',
            url
        })
            .then((res) => {
                console.log('sc', res);

                if (res.status === 'FAILED') {
                    console.log('No more Assigned deals');
                    return;
                }
                setOpenScratchCard('OPEN');
                setRewardData(res);
            })
            .catch((err) => {
                console.log(err);
                // toast.error('Something went wrong. Please try again');
            });
        // }
    };

    useEffect(() => {
        generateScratchcard();
    }, []);

    const description = rewardData?.deal?.description || '';
    const isHtmlContent = isHTML2(description);

    const truncatedDescription = isHtmlContent
        ? truncateHtml(description, 135)
        : description.slice(0, 135).replace(/<\/?p>/g, '') + '...';

    const updateScratchCardStatus = (id, status) => {
        winnreAuth({
            method: 'post',
            url: `/winnre/user/scratchCard/updateStatus?id=${id}&status=${status}`
        })
            .then((res) => {
                console.log('Update Status Response:', res);
                if (res.status === 'FAILED') {
                    toast.error('Failed to update status. Please try again');
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Something went wrong. Please try again');
            });
    };

    const rewardRedeemHandler = (id) => {
        winnreAuth({
            method: 'post',
            url: `winnre/user/deals/redeem-v2?dealId=${id}&sourcePlatform=${
                clickThroughMobile ? 'MOBILE' : 'WEBSITE'
            }`
        })
            .then((res) => {
                console.log('reward', res);

                window?.webengage?.track('DealClicked', {
                    OfferName: `${rewardData?.deal?.title}}`,
                    RedemptionType: `${rewardData?.deal?.redemptionType}`,
                    CashbackPercentage: `${rewardData?.deal?.eligibleCashback}`,
                    CouponCode: `${rewardData?.deal?.dealRedemptionCode?.dealCode}`,
                    Category: `${rewardData?.deal?.category?.name}`,
                    Vendor: `${rewardData?.deal?.vendor?.name}`,
                    Link: `${rewardData?.deal?.url}`,
                    EndDate: `${rewardData?.deal?.endDate}`,
                    Tags: `${rewardData?.deal?.tags?.map((tag) => tag?.name)}`,
                    DealId: `${rewardData?.id}`,
                    VoucherCode:
                        rewardData?.deal?.redemptionType === 'VOUCHER' &&
                        res?.status == 'SUCCESS'
                            ? res?.message
                            : '',
                    Status: res?.status
                    // Channel: channel ? channel : 'Direct'
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error('Something went wrong. Please try again');
            });
    };

    const handleView = (id) => {
        updateScratchCardStatus(id, 'VIEWED');
    };
    const handleRedeem = (id) => {
        rewardRedeemHandler(id);
        updateScratchCardStatus(id, 'REDEEMED');
    };
    const handleScratch = (id) => {
        updateScratchCardStatus(id, 'SCRATCHED');
    };

    return (
        <>
            <div className="flex flex-col justify-center text-center items-center mt-7 lg:mt-0 h-[60vh] px-4 md:px-0">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-20"
                    width="100"
                    height="99"
                >
                    <g
                        id="_6954609_balloon_balloons_celebration_decoration_halloween_icon"
                        data-name="6954609_balloon_balloons_celebration_decoration_halloween_icon"
                        transform="translate(-0.001 -0.394)"
                    >
                        <path
                            id="Path_6382"
                            data-name="Path 6382"
                            d="M32.392,71.834a1.428,1.428,0,0,1-1.428-1.428V43.314a1.428,1.428,0,0,1,2.857,0V70.406A1.428,1.428,0,0,1,32.392,71.834Z"
                            transform="translate(11.73 15.718)"
                            fill="#f3f0f3"
                        />
                        <path
                            id="Path_6383"
                            data-name="Path 6383"
                            d="M19.045,70.657a1.428,1.428,0,0,1-1.428-1.428V46.422a1.428,1.428,0,1,1,2.857,0V69.228A1.428,1.428,0,0,1,19.045,70.657Z"
                            transform="translate(6.673 16.896)"
                            fill="#f3f0f3"
                        />
                        <path
                            id="Path_6384"
                            data-name="Path 6384"
                            d="M45.739,70.657a1.428,1.428,0,0,1-1.428-1.428V46.422a1.428,1.428,0,1,1,2.857,0V69.228A1.428,1.428,0,0,1,45.739,70.657Z"
                            transform="translate(16.786 16.896)"
                            fill="#f3f0f3"
                        />
                        <path
                            id="Path_6385"
                            data-name="Path 6385"
                            d="M40.336,8.031a25.711,25.711,0,1,0-18.4,46.58c.714.114,1.442.186,2.185.244.529.028,1.058.043,1.6.043s1.071-.015,1.6-.043c.743-.058,1.473-.13,2.187-.244A25.7,25.7,0,0,0,40.336,8.031Z"
                            transform="translate(0 1.164)"
                            fill="#ee6161"
                        />
                        <path
                            id="Path_6386"
                            data-name="Path 6386"
                            d="M8.843,29.163A25.73,25.73,0,0,1,30.093,3.846,25.71,25.71,0,1,0,21.838,54.6c.715.112,1.443.183,2.186.241.529.028,1.058.043,1.6.043s1.071-.015,1.6-.043c.743-.058,1.473-.13,2.187-.244.232-.033.454-.092.683-.138A25.707,25.707,0,0,1,8.843,29.163Z"
                            transform="translate(0.095 1.18)"
                            fill="#de5454"
                        />
                        <path
                            id="Path_6387"
                            data-name="Path 6387"
                            d="M37.8,8.031A25.711,25.711,0,1,1,56.2,54.61c-.714.114-1.442.186-2.185.244-.529.028-1.058.043-1.6.043s-1.071-.015-1.6-.043c-.743-.058-1.473-.13-2.187-.244A25.7,25.7,0,0,1,37.8,8.031Z"
                            transform="translate(10.112 1.164)"
                            fill="#8bdf9e"
                        />
                        <path
                            id="Path_6388"
                            data-name="Path 6388"
                            d="M63.357,29.163A25.73,25.73,0,0,0,42.106,3.846,25.71,25.71,0,1,1,50.362,54.6c-.715.112-1.443.183-2.186.241-.529.028-1.058.043-1.6.043s-1.071-.015-1.6-.043c-.743-.058-1.473-.13-2.187-.244-.232-.033-.454-.092-.683-.138A25.707,25.707,0,0,0,63.357,29.163Z"
                            transform="translate(15.951 1.18)"
                            fill="#72c485"
                        />
                        <path
                            id="Path_6389"
                            data-name="Path 6389"
                            d="M31.73,12.594a.123.123,0,0,0-.015-.072,5.7,5.7,0,0,0-7.808-3.3,4.352,4.352,0,0,0-.572.3,5.721,5.721,0,0,0-2.4,2.958,1.07,1.07,0,0,1-.043.128,5.653,5.653,0,0,0-.285,1.792,5.862,5.862,0,0,0,.085,1,5.728,5.728,0,0,0,9.972,2.722,5.55,5.55,0,0,0,1.3-2.9,3.919,3.919,0,0,0,.072-.827,5.344,5.344,0,0,0-.3-1.806Zm-5.416.371a1.43,1.43,0,1,1-1.428,1.428A1.43,1.43,0,0,1,26.314,12.965Z"
                            transform="translate(7.806 3.139)"
                            fill="#fbb03b"
                        />
                        <path
                            id="Path_6390"
                            data-name="Path 6390"
                            d="M46.239,12.592a.138.138,0,0,0-.014-.072,5.722,5.722,0,0,0-10.788-.043c-.014.043-.029.1-.043.128a5.62,5.62,0,0,0-.285,1.792,3.918,3.918,0,0,0,.072.827,5.55,5.55,0,0,0,1.3,2.9A5.724,5.724,0,0,0,46.547,14.4,5.346,5.346,0,0,0,46.239,12.592Zm-5.415.371A1.43,1.43,0,1,1,39.4,14.391a1.43,1.43,0,0,1,1.428-1.428Z"
                            transform="translate(13.3 3.142)"
                            fill="#fbb03b"
                        />
                        <path
                            id="Path_6391"
                            data-name="Path 6391"
                            d="M53.009,48.768a1.423,1.423,0,0,1-1.241,2.114H42.309a1.422,1.422,0,0,1-1.241-2.114l4.372-7.945v-.057c.528.043,1.056.057,1.6.057s1.071-.014,1.6-.043v.043Z"
                            transform="translate(15.489 15.295)"
                            fill="#8bdf9e"
                        />
                        <path
                            id="Path_6392"
                            data-name="Path 6392"
                            d="M53.682,4.964a25.711,25.711,0,1,0-18.4,46.58c.714.114,1.444.186,2.185.243.529.029,1.058.043,1.6.043s1.071-.014,1.6-.043c.742-.057,1.471-.128,2.185-.243A25.7,25.7,0,0,0,53.682,4.964Z"
                            transform="translate(5.056 0.002)"
                            fill="#ffbc5c"
                        />
                        <path
                            id="Path_6393"
                            data-name="Path 6393"
                            d="M22.292,26.112A25.735,25.735,0,0,1,43.543.8a25.713,25.713,0,1,0-8.255,50.753c.714.114,1.444.186,2.185.243.529.029,1.058.043,1.6.043s1.071-.014,1.6-.043c.742-.057,1.471-.128,2.185-.243.232-.034.454-.092.683-.138a25.708,25.708,0,0,1-21.251-25.3Z"
                            transform="translate(5.048 0)"
                            fill="#fca956"
                        />
                        <path
                            id="Path_6398"
                            data-name="Path 6398"
                            d="M39.663,45.655a1.424,1.424,0,0,1-1.244,2.122H28.96a1.424,1.424,0,0,1-1.241-2.115l4.37-7.95v-.043c.529.029,1.058.043,1.6.043s1.071-.014,1.6-.043v.043Z"
                            transform="translate(10.433 14.121)"
                            fill="#ffbc5c"
                        />
                        <path
                            id="Path_6399"
                            data-name="Path 6399"
                            d="M26.314,48.768a1.422,1.422,0,0,1-1.241,2.114H15.614a1.423,1.423,0,0,1-1.241-2.114l4.372-7.945v-.043c.529.029,1.058.043,1.6.043s1.071-.014,1.6-.057v.057Z"
                            transform="translate(5.377 15.295)"
                            fill="#ee6161"
                        />
                    </g>
                </svg>

                <h1 className="font-bold text-2xl lg:text-3xl lg:text-left mt-4">
                    Thank You for Participating!
                </h1>
                <h2 className="text-lg lg:text-xl md:hidden">
                    We hope you enjoyed the challenge. Stay tuned for more
                    exciting quizzes/competitions and exclusive offers.
                </h2>
                <h2 className="text-lg lg:text-xl hidden md:block">
                    We hope you enjoyed the challenge. Stay tuned for more
                    exciting
                </h2>
                <h2 className="text-lg lg:text-xl hidden md:block">
                    quizzes/competitions and exclusive offers.
                </h2>
                <div className="flex gap-x-3 mt-7">
                    <button
                        onClick={() => history.push('/gift-and-earn')}
                        className="bg-primary text-white px-5 py-2 rounded font-medium"
                    >
                        Submit Referrals
                    </button>
                    <button
                        onClick={() => history.push('/deals')}
                        className="bg-primary text-white px-5 py-2 rounded font-medium"
                    >
                        Explore Offers
                    </button>
                </div>
            </div>

            {openScratchCard === 'OPEN' && (
                <Modal
                    onClick={() => {}}
                    visible={openScratchCard === 'OPEN'}
                    opacity="bg-opacity-90"
                    showButton={false}
                >
                    <div className="flex flex-col items-center justify-center">
                        <button
                            onClick={() => {
                                setOpenScratchCard('CLOSE');
                                handleView(rewardData?.id);
                            }}
                            className="text-white mb-6 font-semibold flex items-center justify-center"
                        >
                            <AiOutlineClose />
                            CLOSE
                        </button>
                        <ScratchCard2
                            completedAt={100}
                            onCompleted={() => {
                                setRewardClass('z-50');
                                handleScratch(rewardData?.id);
                            }}
                            size="h-[370px] w-[370px] "
                            isModalSC
                        >
                            <div
                                className={`bg-gray-100 cursor-default flex flex-col h-[370px] w-[370px] rounded-2xl justify-center px-2 ${rewardClass}`}
                            >
                                <img
                                    className="w-[100px] h-[100px] mx-auto rounded-lg"
                                    src={rewardData?.deal?.vendor?.logo?.url}
                                />

                                <h1 className=" mt-4 mx-auto text-center text-gray-600 font-medium uppercase text-[16px] leading-4 ">
                                    <span>{rewardData?.deal?.title}</span>
                                </h1>

                                {isHtmlContent && (
                                    <div
                                        className="text-sm px-5 py-2 text-center break-all"
                                        dangerouslySetInnerHTML={{
                                            __html: truncatedDescription
                                        }}
                                    />
                                )}
                                {!isHTML(rewardData?.deal?.description) && (
                                    <p className="text-sm px-5 py-2 text-center break-all">
                                        {rewardData?.deal?.description
                                            .slice(0, 135)
                                            .replace(/<\/?p>/g, '')}
                                        ...
                                    </p>
                                )}
                                {rewardData?.deal?.redemptionType ===
                                    'CODE' && (
                                    <div className="flex flex-col justify-center  w-full">
                                        <h2 className="uppercase text-gray-600 md:py-2 mt-2 text-center text-xs tracking-widest">
                                            Coupon Code
                                        </h2>
                                        <div className="flex justify-center mb-2">
                                            {!copiedText && (
                                                <div
                                                    title="Click to copy "
                                                    onClick={() =>
                                                        copyCodeHandler(
                                                            rewardData?.deal
                                                                ?.dealRedemptionCode
                                                                ?.dealCode,
                                                            setCopiedText
                                                        )
                                                    }
                                                    className="border-2 tracking-widest flex items-center justify-between bg-[#f0f0f0] text-gray-700 border-[#dcdcdc] border-dashed py-2 px-12 hover:border-blue-900 cursor-pointer my-auto rounded-lg text-[15px] relative"
                                                >
                                                    <p className="text-sm">
                                                        {
                                                            rewardData?.deal
                                                                ?.dealRedemptionCode
                                                                ?.dealCode
                                                        }
                                                    </p>
                                                    <svg
                                                        className="ml-2"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16.391"
                                                        height="17.859"
                                                        viewBox="0 0 16.391 17.859"
                                                    >
                                                        <path
                                                            id="copy-copy"
                                                            d="M76.7-4.892H68.5a2.05,2.05,0,0,1-2.049-2.05V-16.6a2.05,2.05,0,0,1,2.049-2.05h8.2a2.05,2.05,0,0,1,2.049,2.05v9.658A2.05,2.05,0,0,1,76.7-4.892ZM77.72-16.6A1.025,1.025,0,0,0,76.7-17.626H68.5A1.025,1.025,0,0,0,67.476-16.6v9.658A1.025,1.025,0,0,0,68.5-5.918h8.2A1.025,1.025,0,0,0,77.72-6.943V-16.6ZM63.378-12.5v9.658A1.025,1.025,0,0,0,64.4-1.817h8.2a1.025,1.025,0,0,0,1.024-1.025V-3.867h1.024v1.025A2.05,2.05,0,0,1,72.6-.792H64.4a2.05,2.05,0,0,1-2.049-2.05V-12.5A2.05,2.05,0,0,1,64.4-14.55h1.024v1.025H64.4A1.025,1.025,0,0,0,63.378-12.5Z"
                                                            transform="translate(-62.354 18.651)"
                                                            fill="#16519e"
                                                        />
                                                    </svg>
                                                </div>
                                            )}
                                            {copiedText && (
                                                <p
                                                    className={`self-center mx-auto text-center font-normal animate-pulse bg-[#154F9D1A] text-[#006EB7] py-2 px-12 text-sm rounded text-[15px] md:text-base`}
                                                >
                                                    Code Copied
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {rewardData?.deal?.redemptionType !==
                                    'CODE' && (
                                    <p
                                        className={` text-sm text-[#373737] mt-4 text-center`}
                                    >
                                        Valid upto :{' '}
                                        {renderDate(rewardData?.deal?.endDate)}
                                    </p>
                                )}
                                <button
                                    onClick={() => {
                                        handleRedeem(rewardData?.id);
                                        if (
                                            rewardData?.deal?.redemptionType !==
                                            'CODE'
                                        ) {
                                            history.push(
                                                `/deals/${rewardData.deal.id}`
                                            );
                                        } else {
                                            window.open(rewardData?.deal?.url);
                                        }
                                    }}
                                    className={`cursor-pointer text-white self-center bg-[#16519E] rounded-full py-2 px-8 font-CorporateSSC_Medium transition-all duration-300 ease-in-out  hover:bg-transparent hover:border-2 hover:border-[#16519E]  hover:text-[#16519E] ${
                                        rewardData?.deal?.redemptionType !==
                                        'CODE'
                                            ? 'mt-4'
                                            : ''
                                    }`}
                                >
                                    REDEEM NOW
                                </button>
                            </div>
                        </ScratchCard2>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default Thankyou;
