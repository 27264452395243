import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { diamondSvg } from '../../svg'

function StepFour() {
  const history=useHistory()
  const handleClick=()=>{
    history.push('/')
  }
  const handleEnquiry=()=>{
    history.push('/myProfile/enquiries')
  }

  return (
   
       
        <div className="flex items-center justify-center h-screen max-w-full mx-auto pl-6 lg:p-0 my-5 lg:m-0">
          

         <div className="text-center pl-3">
          <div className='flex justify-center mb-2'>
    {diamondSvg}

          </div>
                    <h1 className="font-bold leading-[1] text-2xl ">Thank You </h1>
                    <p className="font-semibold leading-[1] text-lg my-2 ">For Your Home Loan Enquiry!</p>
                    <p className=" text-sm text-[#adadad]">You can manage your Enquires from <br/> <span className='text-primary font-semibold cursor-pointer' onClick={handleEnquiry}>My Enquires</span> section under my profile</p>
                  <div className='flex  text-primary justify-center my-2 items-center gap-1 cursor-pointer' onClick={handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
                   <path id="_8666777_chevron_right_arrow_icon" data-name="8666777_chevron_right_arrow_icon" d="M15,18,9,12l6-6" transform="translate(-8 -4.586)" fill="none" stroke="#15519e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                   </svg> 
                   <button  className='text-primary text-lg my-2'>Back to Homepage</button>

                  </div>
                   
                </div>
            </div>
    
  )
}

export default StepFour
