import React from 'react';

function SkeletonElement({ type, size }) {
    const addCssByType = () => {
        switch (type) {
            case 'title':
                return 'w-[50%] h-4';
            case 'text':
                return 'w-full h-3';
            case 'circle':
                return 'w-24 h-24 rounded-full';
            case 'thumbnail':
                return 'w-24 h-24 ';
        }
    };

    return (
        <div
            className={` bg-[#ddd] rounded-lg my-2 animate-pulse ${
                size ? size : addCssByType()
            }`}
        ></div>
    );
}

export default SkeletonElement;
