import React, { useEffect } from 'react';
import ComponentsRenderer from '../../Components/ComponentsRenderer';
import ReactGA from 'react-ga4';
import { cmsClient } from '../../utils/axios';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Home = ({ config }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const clientToken = queryParams.get('clientToken');
    const navbarVisible = useSelector((state) => state.webView.isNavbarVisible);
    const floaterVisibleMobile = useSelector(
        (state) => state.webView.isFloaterVisible
    );
    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const footerVisible = useSelector((state) => state.webView.isFooterVisible);
    useEffect(() => {
        if (
            cmsClient.defaults.baseURL === 'https://prod.loyalie.in' ||
            cmsClient.defaults.baseURL === 'https://uat.loyalie.in'
        ) {
            window.fbq('trackCustom', 'HomePage');
        }
        ReactGA.ga('send', 'pageview', '/home');
    }, []);
    useEffect(() => {
        // console.log('HOME PAGE');
        if (clientWebview || clientToken) {
            const sectionsToHide = document.getElementsByClassName('hide');
            setTimeout(() => {
                // console.log(sectionsToHide.item(0));
                for (var i = 0; i <= sectionsToHide.length; i++) {
                    sectionsToHide[i]?.classList.add('hidden');
                }
            }, 0);
        }
    }, []);
    useEffect(() => {
        window?.webengage?.track('HomeScreen');
    }, []);
    return <ComponentsRenderer components={config.routes.home.components} />;
};

export default Home;
