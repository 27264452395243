import { getFont, openURLNewWindow } from '../../helpers';
import EmbassyBg from '../../assets/ReferralForm/Embassy.jpg';
import store from '../../store';
// console.log(window.location.origin);
const domain = window.location.origin;
const EmbassyConfig = {
    clientId:
        '576105b5b11f0894b219ccfbadece57ae637db4513eaee8c1c12436438925fed',
    id: 19,
    name: 'Embassy',
    fullName: 'Embassy Group',
    programName:'Embassy Priority',
    theme: 'embassyTheme',
    favicon: 'https://cdn.loyalie.in/73158962.png',
    whatsappChatbot: '6292315122',
    primaryColor: '#F7941d',
    fontBody: `${getFont('EMBASSY').body}`,
    fontTitle: `${getFont('EMBASSY').bold}`,
    googleConfig: {
        gID: 'G-80ZGKP5PDS',
        keywordsContent:
            'embassy priority real estate referral programs, embassy group real estate referral program, embassy group real estate refer and earn, embassy priority real estate project refer, embassy group real estate, embassy priority real estate loyalty program, embassy group real estate news, embassy group real estate upcoming projects in Bengaluru, embassy group real estate launch embassy priority real estate, embassy group real estate launch, embassy group real estate program, embassy group real estate projects, embassy group real estate blogs, embassy group real estate offers, embassy group real estate upcoming projects in Bengaluru, embassy group real estate events, embassy group, embassy priority, embassy group real estate best residential projects, embassy group real estate real estate developers, All New Projects by embassy group real estate Builders & Developers, Ongoing Projects by embassy group real estate Pvt Ltd, embassy group real estate Bengaluru, embassy group real estate new program, embassy group press release, embassy group upcoming projects, embassy property group, embassy group bangalore, embassy real estate, embassy reit, jitu virwani, embassy commercial projects, embassy group projects, embassy group projects in bangalore',
        descriptionContent:
            "We present to you a bouquet of such experiences and more with Embassy Priority - a bespoke loyalty and referral programme that's designed exclusively for Embassy homeowners",
        authorContent: 'Embassy Priority'
    },

    facebookPixelConfig: {
        id: '536279081587389', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: '0b7q3pb72fepc30a7bjbkw6h0tzhau'
    },
    webengageLic: 'in~~2024c1cd',
    webengageAPIKey:'768394c5-c54c-470a-aacc-90c9c2a6b9e8',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: false,
    logoLeft: {
        name: 'embassyLogo',
        url: 'https://cdn.loyalie.in/84390641.png',
        stickyNavBarLogoClass:
            ' h-8 md:h-25 lg:h-8 xl:h-10 2xl:h-10 pl-3 xl:pl-10',
        baseNavBarLogoClass:
            ' h-8 md:h-25 lg:h-8 xl:h-10 2xl:h-12 pl-3 xl:pl-10 lg:mt-10'
    },

    logoRight: {
        name: 'embassyLogo',
        url: 'https://cdn.loyalie.in/33728039.png',
        stickyNavBarLogoClass:
            'hidden lg:block lg:h-12 2xl:h-14 pr-3 xl:pr-14 ',
        baseNavBarLogoClass:
            'h-20 md:h-25 lg:h-12 xl:h-12 2xl:h-20 pr-3 xl:pr-14 mt-10'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('EMBASSY').heading}`,
        height: 'h-20 2xl:h-20',
        mobileIconColor: 'text-white',
        dropDownBg: 'bg-white lg:bg-secondary-focus',
        showActiveLinkColor: false,
        footerNavItemClass: `tracking-widest uppercase text-white text-[12px] md:text-[14px] lg:text-white hover:text-primary font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg  ${
            getFont('EMBASSY').heading
        }`,
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-secondary-focus lg:bg-transparent', // default: transparent
            containerClass: 'lg:pt-10',
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black lg:text-white text-[14px] lg:text-white hover:text-primary font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg ',
            dropDownContainerClass: `hover:text-primary lg:hover:text-gray-900 lg:hover:bg-primary lg:focus:text-gray-900 lg:focus:bg-primary lg:focus:outline-none lg:focus:shadow-outline `,
            dropDownItemClass:
                'uppercase tracking-widest text-black lg:text-white text-[14px] font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-secondary-focus', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black lg:text-white text-[14px] lg:text-white hover:text-primary font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg ' // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: true,
    floaterType: 'CALL',
    mobile: '9513238779',
    floaterColor: 'bg-primary',
    bannerNegativeTopMarginClass: 'lg:-mt-20 2xl:-mt-24', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 ${
        getFont('EMBASSY').bold
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,
    webviewRoutes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true
        },
        events: {
            name: 'CURATED EVENTS',
            path: '/events',
            component: 'Events',
            show: true
        }
    },
    webview: {
        textStyle: `${getFont('EMBASSY').heading} uppercase font-medium`,
        iconColor: `#000000`,
        initialsClass: 'text-black'
    },
    referralLink: true,
    routes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true,

            components: [
                {
                    type: 'SLIDER_BANNER',
                    classWeb:
                        'hidden lg:block h-full w-full object-cover lg:-mt-20',
                    slides: [
                        {
                            id: 0,
                            text: '',
                            displayImage: {
                                url: 'https://cdn.loyalie.in/56408518.jpg',
                                mobileUrl: 'https://cdn.loyalie.in/54656189.jpg'
                            },
                            linearGradient: {
                                R: '0',
                                G: '0',
                                B: '0',
                                opacity: '0.5'
                            },
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'relative w-full h-full text-center px-7 lg:px-0 md:text-left lg:pl-10 xl:pl-20  ',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'text-center md:text-left leading-8 lg:leading-6',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'WHERE EXCLUSIVITY ISN',
                                                    className: `inline mt-20 md:mt-10 xl:pt-28 text-white text-[30px] 2xl:text-3xl ${
                                                        getFont('EMBASSY')
                                                            .secondary
                                                    }`
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content: "'T",
                                                    className: `lg:hidden text-white text-[30px] 2xl:text-3xl inline ${
                                                        getFont('EMBASSY')
                                                            .secondary
                                                    }`
                                                },
                                                {
                                                    type: 'SUP',
                                                    content: ',',
                                                    className: `hidden text-white text-4xl lg:inline ${
                                                        getFont('EMBASSY')
                                                            .secondary
                                                    }`
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content: 'T',
                                                    className: `hidden text-white text-[30px] 2xl:text-3xl lg:inline ${
                                                        getFont('EMBASSY')
                                                            .secondary
                                                    }`
                                                }
                                            ]
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'text-center md:text-left pb-4',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content: 'A PRIVILEGE, ',
                                                    className: `text-white inline-block text-[30px] 2xl:text-3xl leading-8  ${
                                                        getFont('EMBASSY')
                                                            .secondary
                                                    }`
                                                },
                                                {
                                                    type: 'TEXT',

                                                    content:
                                                        ' BUT A LIFESTYLE.',
                                                    className: `text-[30px] 2xl:text-3xl inline-block pt-2 lg:pt-0 font-semibold text-white md:ml-2 leading-8 lg:leading-7 ${
                                                        getFont('EMBASSY').bold
                                                    }`
                                                }
                                            ]
                                        },

                                        {
                                            type: 'BUTTON',
                                            content: 'REFER NOW',
                                            className: `mx-auto text-center md:mx-0 md:mt-6  text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                                                getFont('EMBASSY').bold
                                            }`,
                                            path: 'gift-and-earn',
                                            onClick: 'CHANGE_PATH_AND_SCROLL',
                                            scrollTo: 'referral_section'
                                        }
                                    ]
                                }
                            ]
                        }
                        // {
                        //     id: 1,
                        //     linearGradient: {
                        //         R: '0',
                        //         G: '0',
                        //         B: '0',
                        //         opacity: '0'
                        //     },
                        //     displayImage: {
                        //         url: 'https://cdn.loyalie.in/1693560318721_Embassy_New_Website_Banner',
                        //         mobileUrl:
                        //             'https://cdn.loyalie.in/1693563835863_Embassy_New_Website_Banner_SM'
                        //     },
                        //     components: [
                        //         {
                        //             type: 'CUSTOM',
                        //             className:
                        //                 'relative w-full h-full flex items-center justify-center lg:justify-end lg:text-right pt-56 lg:pt-40 xl:pt-40 2xl:pt-72',
                        //             components: [
                        //                 {
                        //                     type: 'BUTTON',
                        //                     content: 'Refer Now',
                        //                     className: ` text-[15px] md:text-[18px] 2xl:text-[20px] uppercase tracking-wider bg-transparent text-primary font-semibold  w-[70%] lg:w-[50%] h-[250px] md:h-[350px] xl:h-[400px] rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] opacity-0 ${getFont('SUSHMA').body
                        //                         }`,
                        //                         path: 'events',
                        //                         onClickFunc: () => {window.open('https://www.embassylaketerraces.com/elt-lp/')},
                        //                         onClick: 'FUNC'
                        //                 }

                        //             ]
                        //         }
                        //     ]
                        // }
                        // {
                        //     id: 2,
                        //     linearGradient: {
                        //         R: '0',
                        //         G: '0',
                        //         B: '0',
                        //         opacity: '0'
                        //     },
                        //     displayImage: {
                        //         url: 'https://cdn.loyalie.in/82807804.jpg',
                        //         mobileUrl:
                        //             'https://cdn.loyalie.in/24472109.jpg'
                        //     },
                        //     components: [
                        //         {
                        //             type: 'CUSTOM',
                        //             className:
                        //                 'fixed top-[20px] lg:top-[39px] xl:top-[37px] 2xl:top-[27px] right-5 lg:right-20 xl:right-32 2xl:right-44 w-[35%] md:w-[25%] lg:w-[12%] xl:w-[10%] 2xl:w-[13%]',
                        //             components: [
                        //                 {
                        //                     type: 'IMAGE',
                        //                     url: 'https://cdn.loyalie.in/15737089.png'
                        //                 }
                        //             ]
                        //         }
                        //     ]
                        // }
                    ]
                },

                {
                    type: 'CUSTOM',
                    className: 'relative',
                    components: [
                        {
                            type: 'IMAGE',
                            url: 'https://cdn.loyalie.in/43593488.png',
                            position: 'center',
                            classNameContainer:
                                'flex absolute -mt-6 md:-mt-10 xl:-mt-14 mx-auto inset-0 z-20',
                            className: 'h-12 md:h-20 xl:h-28'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'overview',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    className:
                        'flex flex-col justify-center text-center pt-10 px-4 ',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Curated experiences, premium benefits and more.',
                            className: `text-secondary-focus uppercase pt-16 text-center text-[22px] md:text-[24px] lg:text-[25px] xl:text-[31px] tracking-wider leading-7 ${
                                getFont('EMBASSY').heading
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Divider.svg',
                            width: '90',
                            height: '64',
                            name: 'first',
                            imgHeight: 'h-14',
                            className:
                                'flex justify-center text-center m-auto text-center mt-2'
                        },
                        {
                            type: 'TEXT',
                            content:
                                "What if indulgence could become an everyday affair? Where you live a life of rare and extravagant experiences that are tailor-made just for you to enthrall, excite and pamper you. We present to you a bouquet of such experiences and more with Embassy Priority - a bespoke loyalty and referral programme that's designed exclusively for Embassy homeowners.",
                            className: `pt-1 text-[18px] h-full xl:text-[22px] text-secondary-focus leading-6 xl:leading-[27px] self-center w-[90%] xl:w-[88%] 2xl:w-[74%] break-words mb-5  ${
                                getFont('EMBASSY').body
                            }`
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    className:
                        'flex flex-col justify-center text-center pt-6 xl:pt-10',

                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'DISCOVER THE MANY INDULGENCES OF AN EMBASSY PRIORITY LIFESTYLE',
                            className: `${
                                getFont('EMBASSY').heading
                            } text-secondary self-center text-center w-[90%] md:w-[60%] lg:w-[50%] 2xl:w-[41%] text-[21px] lg:text-[24px] xl:text-3xl leading-7 xl:leading-9 pb-5 xl:pb-10`
                        },

                        {
                            type: 'ICONS_LAYOUT',
                            containerClassName:
                                'h-content lg:h-auto bg-white items-center py-3 xl:py-5 ',
                            gridClassName:
                                'flex lg:grid lg:grid-cols-3 w-full m-auto overflow-x-scroll lg:overflow-x-hidden',
                            infotxtClassName: ` mx-auto leading-6 xl:leading-[27px] 2xl:w-[95%] px-11 md:px-20 text-secondary-focus text-[18px] xl:text-[22px] font-normal pb-8 ${
                                getFont('EMBASSY').body
                            }`,
                            infoHeadingClassName: `w-full mx-auto leading-6 xl:leading-[27px] md:w-[60%] lg:w-[60%] xl:w-[50%] md:px-2 text-[20px] md:text-[22px] xl:text-2xl  text-secondary font-normal pt-10 pb-6 tracking-wide ${
                                getFont('EMBASSY').heading
                            }`,
                            infoContainerClassName:
                                'min-w-[80vw] lg:min-w-full mt-4 lg:mt-0',
                            imgContainerClassName: '',
                            imgClassName:
                                'w-[16%] md:w-[8%] lg:w-[11%] lg:mx-auto mx-auto lg:mb-2',
                            info: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/68130387.png',
                                    infoHeading: 'OFFERS AND PROMOTIONS',
                                    infoText:
                                        'Explore an endless spectrum of select offers and special promotions handpicked to enrich your discerning lifestyle.'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/47243329.png',
                                    infoHeading: 'WORKSHOPS AND EVENTS',
                                    infoText:
                                        'Meet and connect with like-minded people from an esteemed circle at curated workshops and extravagant events.   '
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/639615.png',
                                    infoHeading: 'INTERACTIVE BLOGS',
                                    infoText:
                                        "Get privileged access to content that's crafted to your interests and preferences, and interact with people of similar tastes."
                                }
                            ]
                        },
                        {
                            type: 'IMAGE_SECTION',
                            className:
                                'flex overflow-x-scroll lg:overflow-x-hidden lg:grid md:grid-flow-col md:auto-cols-fr border-gray-300 w-full h-content border-t-4 ',
                            photosection: 'min-w-[80vw] lg:min-w-full',
                            imgClassName:
                                '2xl:object-cover 2xl:h-full 2xl:w-full',
                            imgArr: [
                                {
                                    imgSrc: 'https://cdn.loyalie.in/2675939.jpg'
                                },
                                {
                                    imgSrc: 'https://cdn.loyalie.in/13709837.jpg'
                                },
                                {
                                    imgSrc: 'https://cdn.loyalie.in/23574928.jpg'
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: '',
                    url: '',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    className:
                        'flex flex-col justify-center text-center pt-14 pb-16 px-4',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'A community of connoisseurs',
                            className: `text-secondary-focus uppercase text-center px-2 text-[22px] md:text-[24px] lg:text-[25px] xl:text-[31px] tracking-wider ${
                                getFont('EMBASSY').heading
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Divider.svg',
                            width: '90',
                            height: '64',
                            name: 'second',
                            imgHeight: 'h-14',
                            className:
                                'flex justify-center text-center m-auto text-center mt-2'
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Beyond the privileges and experiences of an exclusive loyalty programme, Embassy Priority also opens to you a world unlike any other. A world of peers who share a nuanced taste for the finer things in life. This is a unique opportunity to craft one such world for yourself - by introducing your friends and family to the Embassy Priority community.   ',
                            className: `pt-1 text-[18px] h-full 2xl:text-[22px] text-secondary-focus leading-6 xl:leading-[27px] self-center w-[90%] lg:w-[75%] xl:w-[80%] break-words ${
                                getFont('EMBASSY').body
                            }`
                        }
                    ]
                },

                //REFERRAL FORM
                {
                    type: 'REFERRAL_FORM_BANNER'
                },

                // OUR PROJECTS
                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/69546771.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.95',
                        size: '100%'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] lg:pb-20 -mb-28 ',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'EMBASSY PRIORITY LATEST BLOGS',
                            className: `text-black tracking-wide text-center px-2 text-[22px] md:text-[24px] lg:text-[25px] xl:text-[30px]   ${
                                getFont('EMBASSY').heading
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Divider.svg',
                            width: '90',
                            height: '64',
                            name: 'third',
                            imgHeight: 'h-14',
                            className:
                                'flex justify-center text-center m-auto text-center mt-2'
                        },

                        { type: 'BLOG_LAYOUT_VERTICAL1' },

                        {
                            type: 'TEXT',
                            content: 'EMBASSY RESIDENTIAL',
                            className: `text-black tracking-wide text-center px-2 text-[22px] md:text-[24px] lg:text-[25px] xl:text-[30px]   ${
                                getFont('EMBASSY').heading
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Divider.svg',
                            width: '90',
                            height: '64',
                            name: 'fourth',
                            imgHeight: 'h-14',
                            className:
                                'flex justify-center text-center m-auto text-center mt-2'
                        },
                        {
                            type: 'CAROUSEL',
                            customSlide: true,
                            caraouselClassName:
                                'px-8 md:px-10 xl:px-36 md:pb-10 pt-1 relative pb-16 lg:pb-10',
                            imageContainerClassName: 'm-4 relative',
                            imageClassName: 'w-full h-40 lg:h-56 lg:bg-cover',
                            captionClass: ` text-sm lg:text-lg leading-8 ${
                                getFont('EMBASSY').body
                            }`,
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 3,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/2831585.jpg',
                                    alt: 'img',
                                    url: `${domain}/embassy-website/projects/detail?projectId=36`,
                                    coverImg:
                                        'https://cdn.loyalie.in/78286886.jpg',
                                    caption: 'EMBASSY EDGE'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/63514085.jpg',
                                    alt: 'img',
                                    url: `${domain}/embassy-website/projects/detail?projectId=33`,
                                    coverImg:
                                        'https://cdn.loyalie.in/16383455.jpg',
                                    caption: 'EMBASSY BOULEVARD'
                                },

                                {
                                    imgUrl: 'https://cdn.loyalie.in/49872109.jpg',
                                    alt: 'img',
                                    url: `${domain}/embassy-website/projects/detail?projectId=34`,
                                    coverImg:
                                        'https://cdn.loyalie.in/89958637.jpg',
                                    caption:
                                        'FOUR SEASON PRIVATE RESIDENCES BENGALURU AT EMBASSY ONE'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/27042011.jpg',
                                    alt: 'img',
                                    url: `${domain}/embassy-website/projects/detail?projectId=31`,
                                    coverImg:
                                        'https://cdn.loyalie.in/48583868.jpg',
                                    caption: 'EMBASSY LAKE TERRACES'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/84010387.jpg',
                                    alt: 'img',
                                    url: `${domain}/embassy-website/projects/detail?projectId=32`,
                                    coverImg:
                                        'https://cdn.loyalie.in/40080447.jpg',
                                    caption: 'EMBASSY GROVE'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/68062329.jpg',
                                    alt: 'img',
                                    url: `${domain}/embassy-website/projects/detail?projectId=35`,
                                    coverImg:
                                        'https://cdn.loyalie.in/22662304.png',
                                    caption: 'EMBASSY SPRINGS'
                                }
                            ]
                            // getProjects: true
                        }
                    ]
                }
            ]
        },

        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0.5'
                    },
                    backgroundImage:
                        'https://cdn.loyalie.in/1692634018187_refer%20page%20banner',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1692634139175_refer%20page%20mob',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-left text-white text-[15px] md:text-[20px] xl:text-[25px] uppercase my-5 tracking-widest lg:w-[60%] ml-auto lg:leading-8  ${
                                getFont('BRAMHA').secondary
                            }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'Start Referring Now',
                            onClick: 'FUNC',
                            onClickFunc: () =>
                                store.dispatch({
                                    type: 'authentication/openLoginModal'
                                })
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0.5'
                },
                position: 'text-center',
                backgroundImage:
                    'https://cdn.loyalie.in/1692634018187_refer%20page%20banner',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1692634139175_refer%20page%20mob',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[60vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').body
                    //         }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-black text-[15px] leading-10 md:text-[20px] xl:text-[45px] font-bold tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').heading
                    //         }`
                    // },
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex w-[70%] ml-auto justify-center mt-7', components: [{
                    //         type: 'BUTTON',
                    //         content: 'Start Referring Now',
                    //         scrollTo: 'referral_section',
                    //         onClick: 'SCROLL'
                    //     }
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold  bg-primary text-white ${
                getFont('BRAMHA').heading
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[90vh] lg:h-[97vh] lg:h-screen bg-center md:bg-top  mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0.65'
            },
            backgroundImage: 'https://cdn.loyalie.in/89682006.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/40950830.jpg',
            components: [
                {
                    type: 'TEXT',
                    content: 'EMBASSY READERS’ CLUB',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('EMBASSY').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('EMBASSY').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('EMBASSY').bold
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'EMBASSY READERS’ CLUB',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('EMBASSY').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('EMBASSY').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('EMBASSY').bold
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('EMBASSY').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('EMBASSY').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                components: [
                    {
                        type: 'ANIMATE',
                        url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Divider.svg',
                        width: '90',
                        height: '64',
                        name: 'first',
                        imgHeight: 'h-14',
                        className:
                            'flex justify-center text-center m-auto text-center '
                    }
                ],
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-[#1c2936] ${
                    getFont('EMBASSY').heading
                }`,
                headingClass: `text-[30px] uppercase font-semibold text-secondary mt-3 leading-9 break-words ${
                    getFont('EMBASSY').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('EMBASSY').heading
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('EMBASSY').heading
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('EMBASSY').heading
                }`
            },
            sectionHeadings: `px-10 lg:px-16 pt-6 pb-2 text-[22px] md:text-[24px] lg:text-[25px] xl:text-[30px] tracking-wider text-center uppercase ${
                getFont('EMBASSY').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            components: [
                {
                    type: 'ANIMATE',
                    url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Divider.svg',
                    width: '90',
                    height: '64',
                    name: 'fourth',
                    imgHeight: 'h-14',
                    className:
                        'flex justify-center text-center m-auto text-center '
                }
            ],
            cardHeight:
                'h-[480px] md:h-[470px] lg:h-[500px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[230px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 md:h-[45px] lg:h-[70px] text-[19px] 3xl:text-[20px] font-medium uppercase leading-6 break-words mx-5 ${
                getFont('EMBASSY').heading
            }`,
            categoryClass: `bg-secondary text-white ${getFont('EMBASSY').body}`,
            summaryClass: `font-normal text-[16.5px] xl:text-[17px] mt-2 leading-6 text-[#777777] mx-5 md:h-[50px] lg:h-[70px] ${
                getFont('EMBASSY').body
            }`,
            buttonClass: `bg-accent-focus hover:bg-primary flex justify-center self-end float-right text-[13px] xl:text-[16px] text-white rounded-lg py-1.5 px-4 md:px-4 m-5 ${
                getFont('EMBASSY').body
            }`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-[#919396] ${
                getFont('EMBASSY').heading
            }`,
            noBlogsClass: `bg-secondary p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('EMBASSY').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/69080231.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/69080231.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.2'
            },

            negativeMargin: 'lg:-mt-20 2xl:-mt-24',
            containerClass: ' text-white w-full ',
            heading: 'Explore a glimpse of our past events',
            headingClass: `font-semibold text-center mx-auto lg:w-[70%] text-[25px] lg:text-[30px] 2xl:text-[40px] text-white uppercase ${
                getFont('EMBASSY').secondary
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('EMBASSY').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('EMBASSY').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('EMBASSY').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('EMBASSY').bold
                    }`
                }
            ]
        },

        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: `self-center text-center text-[22px] md:text-[24px] lg:text-[25px] xl:text-[30px] text-neutral w-[60%] mt-16 ${
                getFont('EMBASSY').heading
            }`,
            components: [
                {
                    type: 'ANIMATE',
                    url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Divider.svg',
                    width: '90',
                    height: '64',
                    name: 'fourth',
                    imgHeight: 'h-14',
                    className:
                        'flex justify-center text-center m-auto text-center mt-2'
                }
            ],
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('EMBASSY').body
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('EMBASSY').body
            }`
        },
        eventLayout: {
            titleClass: `text-accent leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('EMBASSY').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${
            getFont('EMBASSY').heading
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('EMBASSY').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[30vh] lg:h-[45vh] bg-center lg:bg-top bg-cover', //
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0.5'
            },
            backgroundImage: 'https://cdn.loyalie.in/1690440507863.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/1690738194126.jpg',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/4685974.png',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.97',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center font-semibold text-[20px] text-center text-xl text-neutral w-[60%] ${
                getFont('EMBASSY').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `text-lg uppercase text-secondary font-semibold  ${
                getFont('EMBASSY').heading
            } `,
            subAdditionalText:
                'Embassy Priority is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-normal  text-neutral ${
                getFont('EMBASSY').heading
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('EMBASSY').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/4685974.png',
            pattern2: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '1',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-2xl lg:text-[18px] leading-6 lg:leading-5 text-white ${
                getFont('EMBASSY').heading
            }`,
            listBodyClass: `${getFont('EMBASSY').heading}`,
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` text-accent font-normal uppercase text-2xl xl:text-3xl ${
                getFont('EMBASSY').medium
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('EMBASSY').heading
            }`,
            dateClassName: ` capitalize  text-black  ${
                getFont('EMBASSY').heading
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-[12px]  ${
                getFont('EMBASSY').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-neutral text-xs break-words leading-1 ${
                getFont('EMBASSY').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-primary text-white font-bold uppercase self-center rounded text-[16px] py-2.5 px-7  ${
                    getFont('EMBASSY').bold
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: ` border-primary border-[2px] hover:text-primary hover:bg-white hover:font-semibold  bg-primary self-center text-white rounded uppercase text-[16px] py-[9px] px-7 xl:px-10 ${
                    getFont('EMBASSY').heading
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.45'
                },
                backgroundImage: 'https://cdn.loyalie.in/52724091.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/84236386.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[90vh] lg:h-screen md:bg-bottom',

                components: [
                    {
                        type: 'TEXT',
                        content: 'EMBASSY RESIDENTIAL PROJECTS',

                        className: `font-semibold mx-auto text-center mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                            getFont('EMBASSY').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[25%] xl:w-[20%] bg-primary border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {
       
            constructionVisible: false,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },
    header: {
        headerClass: 'hidden lg:block h-32 bg-[#58595B]'
    },

    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/46329169.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.97',
            repeat: 'repeat',
            size: 'contain'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.65'
                },
                backgroundImage: 'https://cdn.loyalie.in/62759552.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/26633965.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-top h-[90vh] lg:h-[97vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: `font-semibold mx-auto text-center mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                            getFont('EMBASSY').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[25%] xl:w-[20%] bg-primary border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],


    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('EMBASSY').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('EMBASSY').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.65'
                },
                backgroundImage: 'https://cdn.loyalie.in/14952553.jpeg',
                backgroundImageMobile: 'https://cdn.loyalie.in/14952553.jpeg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center lg:bg-top h-[90vh] lg:h-[97vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS & CONDITIONS',

                        className: `font-semibold mx-auto text-center mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                            getFont('EMBASSY').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[25%] xl:w-[20%] bg-primary border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('EMBASSY').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('EMBASSY').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Embassy  project, and has entered into a registered Agreement to Sell; or (ii) employee of Embassy ; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Embassy  or any of its sister concerns/subsidiaries/affiliates (“Embassy  Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Embassy  Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Embassy .',
                            'Referrer shall not be able to refer existing customer(s) of Embassy . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Embassy  or (ii) is/are already registered with Embassy  as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Embassy ’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Embassy  within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Embassy ',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Embassy  or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('EMBASSY').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-0 lg:px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Embassy Lifespaces, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('EMBASSY').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('EMBASSY').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('EMBASSY').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('EMBASSY').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('EMBASSY').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('EMBASSY').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('EMBASSY').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('EMBASSY').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[10.5px] 3xl:text-[15px] leading-[18px] tracking-tight text-white ${
            getFont('EMBASSY').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('EMBASSY').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-primary bg-opacity-90'
    },
    referralForm: {
        name: 'Embassy',
        theme: 'embassyTheme',
        color: '#F7941d',
        backgroundImage: EmbassyBg,
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '52',
            G1: '52',
            B1: '52',
            opacity1: '0.75',
            R2: '52',
            G2: '52',
            B2: '52',
            opacity2: '0.75'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: ' ',
            bookedCustomerHeading2: 'Embassy Home Owner',

            employee: false,
            employeeHeading1: 'I am an',
            // employeeHeading1: 'Embassy Employee',

            salesAdmin: true,
            salesAdminHeading1: ' ',
            salesAdminHeading2: 'Embassy Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        formContent: {
            heading: '"SHARE THE GOOD LIFE WITH YOUR LOVED ONES"',
            headingClass: `self-center text-white sm:mt-0 text-3xl md:text-4xl 2xl:text-5xl w-[80%] lg:w-[50%] 2xl:w-[60%] text-center leading-8 tracking-wide ${
                getFont('EMBASSY').body
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='white'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='%23F7941d' stroke-width='7' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='7' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink:
            'https://embassypriority.com/embassy-website/terms-and-conditions',
        customLink: '/embassy-website/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default EmbassyConfig;
