import react, { useEffect, useState } from 'react';
import useAxios from '../../../../Hooks/use-axios';
import { winnreNoAuth } from '../../../../utils/axios';
import { APIErrorCondition } from '../../../../constants';
const useData = (projectId) => {
    const [projectDetails, setProjectDetails] = useState([]);
    const [loadingProject, setLoadingProject] = useState(false)
    const [highlightCategories, setHighlightCategories] = useState([]);
    const [projectGallery, setProjectGallery] = useState([]);
    const [VrGallery, setVrGallery] = useState([])
    const [constructionGallery, setConstructionGallery] = useState([]);
    const [projectAmenities, setProjectAmenities] = useState([]);
    const [projectAdditionalHighlights, setProjectAdditionalHighlights] = useState([]);
    const [projectDocs, setProjectDocs] = useState([])
    const [projectBrochure, setProjectBrochure] = useState(null);
    const dummyVR = [{ tagLine: 'hello', caption: 'bigger hello', url: "https://btvrprojects.s3.ap-south-1.amazonaws.com/Smart_World+3BHK+-+Integrated_Tour/index.htm" }, { tagLine: 'hello', caption: 'bigger hello', url: "https://btvrprojects.s3.ap-south-1.amazonaws.com/Smart+World_3.5BHK_Luxury-Tour/index.htm" }, { tagLine: 'hello', caption: 'bigger hello', url: "https://btvrprojects.s3.ap-south-1.amazonaws.com/Smart_World+4.5BHK+-+Integrated_Tour/index.htm" }]


    const getProjeHighlights = () => {
        let url = `/winnre/user/projectHighlight?projectId=${projectId}&pageNumber=0&pageSize=1000`;
        winnreNoAuth.get(url).then((data) => {
            const highlightCategorie = data.content.reduce((acc, curr) => {
                if (acc[curr.title]) {
                    acc[curr.title].push(curr);
                    return acc;
                } else {
                    acc[curr.title] = [curr];
                    return acc;
                }
            }, {});
            setHighlightCategories(highlightCategorie);
        })

    };

    const getProjectAdditionalHighlights = () => {
        let url = `/winnre/user/projectAdditionalHighlight?projectId=${projectId}&pageNumber=0&pageSize=1000`;
        winnreNoAuth.get(url).then((data) => {
            const additionalHighlights = data.content.reduce((acc, curr) => {
                if (acc[curr.title]) {
                    acc[curr.title].push(curr);
                    return acc;
                } else {
                    acc[curr.title] = [curr];
                    return acc;
                }
            }, {});
            setProjectAdditionalHighlights(additionalHighlights);
            // console.log('ADDITIONAL HIGHLIGHTS', additionalHighlights);
        })
    };

    const getProjectAmenity = () => {
        let url = `/winnre/user/projectAmenity?projectId=${projectId}&pageNumber=0&pageSize=1000`;
        winnreNoAuth.get(url).then((data) => {
            setProjectAmenities(data.content);
        })
    };

    const getUrl = (type) => {
        return  `/winnre/user/projectGallery/type?galleryType=${type}&projectId=${projectId}&sortBy=updatedOn&isAscending=false&pageNumber=0&pageSize=50`
    }

    const getProjectGallery = () => {
     
        winnreNoAuth.get(getUrl('GALLERY')).then( (res) => {
            // console.log('gallery', res)
            setProjectGallery(
                res?.content
            );
        })
        winnreNoAuth.get(getUrl('CONSTRUCTION_PROGRESS')).then( (res) => {
            // console.log('construction', res)
            setConstructionGallery(
                res?.content
            );
        })
        winnreNoAuth.get(getUrl('AR_VR')).then( (res) => {
            // console.log('VR', res)
            setVrGallery(res?.content)
        })
    };

    const getProjectDetails = () => {
        setLoadingProject(true)
        let url = `/winnre/user/project/${projectId}`;
        winnreNoAuth.get(url).then((data) => {
            // console.log("project details", data)
            setLoadingProject(false)
            setProjectDetails(data);

        })
    };

    const getProjectDocuments = () => {
        let url = `/winnre/user/project/documents/${projectId}`;
        winnreNoAuth.get(url).then((data) => {
            // console.log("project docs", data)
            setProjectDocs(data); 

        })
    };
    const getProjectBrochure = () => {
        let url = `/winnre/user/projectBrochure/${projectId}`;
        winnreNoAuth.get(url).then((data) => {
            console.log("project broch", data)
            if(data.status == APIErrorCondition){
                setProjectBrochure(null)
                return
            }
            setProjectBrochure(data);
        });
    };
    useEffect(() => {
        getProjectDetails();
        getProjeHighlights();
        getProjectAdditionalHighlights();
        getProjectGallery();
        getProjectAmenity();
        getProjectDocuments();
        getProjectBrochure();
    }, []);

    return {
        projectDetails,
        highlightCategories,
        projectAdditionalHighlights,
        projectAmenities,
        projectGallery,
        constructionGallery,
        VrGallery,
        projectDocs,
        projectBrochure,
        loadingProject
    };
};

export default useData;
