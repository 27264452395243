import ReferralForm from '../Components/ReferralForm';
import React, { useEffect, useRef, useState } from 'react';
import '../src/index.css';
import moment from 'moment';
import Modal from '../Components/Modal';
import AiOutlineClose from '@meronex/icons/ai/AiOutlineClose';
import ScratchCard2 from '../Components/Scratchcard2';
import foregroundImageSrc from '../assets/img.jpg';
import { getConfig } from '../../../utils/config';
import { scrollToTargetAdjusted } from '../../../utils/ui';
import { useLocation } from 'react-router-dom';
import { cmsClient } from '../../../utils/axios';
import { useSelector } from 'react-redux';
import { renderDate } from '../../../helpers';
function ReferralFormMain({ referralConfig, enquiryCities }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const scParam = queryParams.get('sc');
   // const EventName = queryParams.get('eventName');
   const referralStage=queryParams.get('referralStage');
    const [openScratchCard, setOpenScratchCard] = useState(
        !!scParam ? 'TRIGGER' : 'CLOSE'
    );
    const [rewardData, setRewardData] = useState(null);
    const [rewardClass, setRewardClass] = useState('');
    // const [copiedText, setCopiedText] = useState(false);
    const config = getConfig();
    const websiteUserRole = useSelector((state) => state.auth.userRole);
    const userStatus = useSelector((state) => state.auth.userDetails?.status);
    const [userTypeFormData, setUserTypeFormData] = useState(websiteUserRole);
    const userRole = websiteUserRole || userTypeFormData;

    // const renderDate = (date) => {
    //     const dateStr = moment(date).format('Do');
    //     let superScript = '';
    //     if (dateStr.includes('th')) {
    //         superScript = 'th';
    //     } else if (dateStr.includes('rd')) {
    //         superScript = 'rd';
    //     } else if (dateStr.includes('st')) {
    //         superScript = 'st';
    //     } else {
    //         superScript = 'nd';
    //     }
    //     return (
    //         <span>
    //             {moment(date).format('DD')}
    //             <sup>{superScript}</sup>
    //             {moment(date).format(' MMMM YYYY')}
    //         </span>
    //     );
    // };

    // useEffect(() => {
    //     if (openScratchCard === 'TRIGGER') {
    //         if (
    //             userRole === 'RELATIONSHIP_MANAGER' ||
    //             userRole === 'EMPLOYEE'
    //         ) {
    //             return;
    //         }
    //         cmsClient({
    //             method: 'GET',
    //             url: `/cms_service/deals/referral-reward-deal?referralStage=${referralStage}&clientId=${config?.id}`
    //         }).then((res) => {
    //             // console.log(res);
    //             if (res.data) {
    //                 setOpenScratchCard('OPEN');
    //                 setRewardData(res.data);
    //             }
    //         });
    //     }
    // }, [openScratchCard]);


    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setCopiedText(false);
    //     }, 1500);

    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, [copiedText]);

    // const copyCodeHandler = async () => {
    //     if ('clipboard' in navigator) {
    //         await navigator.clipboard.writeText(
    //             rewardData?.dealRedemptionCode?.dealCode
    //         );
    //     }
    //     setCopiedText(true);
    // };

    return (
        <div id="referral_section">
            {/* <Modal
                onClick={() => {}}
                visible={openScratchCard === 'OPEN'}
                opacity="bg-opacity-90"
                showButton={false}
            >
                <div className="flex flex-col items-center justify-center">
                    <button
                        onClick={() => setOpenScratchCard('CLOSE')}
                        className="text-white mb-1 font-semibold flex items-center justify-center"
                    >
                        <AiOutlineClose />
                        CLOSE
                    </button>
                    <ScratchCard2
                        foregroundImageSrc={foregroundImageSrc}
                        completedAt={60}
                        onCompleted={() => {
                            
                            setRewardClass('z-50');
                            window?.webengage?.track('ScratchCardRedeemed', {
                                DealTitle: rewardData?.title,
                                OfferCode:  rewardData
                                ?.dealRedemptionCode
                                ?.dealCode,
                                Vendor: rewardData?.vendor?.name,
                            });
                        }}
                        isModalSC
                    >
                        <div
                            // onClick={() => {
                            //     window.open(rewardData?.url);
                            // }}
                            className={` bg-white h-full w-full cursor-pointer flex flex-col items-center rounded-lg ${rewardClass}`}
                        >
                            <img
                                src={rewardData?.displayImage?.url}
                                className="h-48 w-full object-cover rounded-t-lg"
                            />
                            <div className="px-3 w-full h-full ">
                                <div className="flex items-center mt-2">
                                    <img
                                        className="h-24 w-24 md:h-20 md:w-20 rounded-lg shadow-lg   "
                                        src={rewardData?.vendor?.logo?.url}
                                    />
                                    <h1
                                        className={`${
                                            config?.deals?.dealLayout
                                                ?.listTitleClass
                                                ? config?.deals?.dealLayout
                                                      ?.listTitleClass
                                                : 'text-xl'
                                        } font-semibold ml-2 animate-pulse `}
                                    >
                                        {rewardData?.title.slice(0, 50)}
                                    </h1>
                                </div>
                                {rewardData?.redemptionType === 'CODE' && (
                                    <div className="flex flex-col lg:flex-row mt-3 w-full">
                                        <div className="flex items-center">
                                            <div className="flex">
                                                {!copiedText && (
                                                    <p
                                                        className={`border-2 font-semibold border-gray-300 border-dashed py-1 px-3 md:px-7 'hover:border-blue-900
                                                    cursor-pointer my-auto mr-2 md:mr-2 rounded text-[15px] md:text-base`}
                                                        title="Click to copy "
                                                        onClick={() =>
                                                            copyCodeHandler()
                                                        }
                                                    >
                                                        {'CODE: ' +
                                                            rewardData
                                                                ?.dealRedemptionCode
                                                                ?.dealCode}
                                                    </p>
                                                )}
                                                {copiedText && (
                                                    <p
                                                        className={`self-center mx-auto text-center font-normal animate-pulse bg-[#154F9D1A] text-[#006EB7]'
                                                     py-2 px-12 text-sm rounded text-[15px] md:text-base`}
                                                    >
                                                        Code Copied
                                                    </p>
                                                )}
                                                {!copiedText && (
                                                    <button
                                                        onClick={() =>
                                                            copyCodeHandler()
                                                        }
                                                        className="bg-primary text-white rounded px-4 text-[15px] md:text-base"
                                                    >
                                                        Copy
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {rewardData?.redemptionType !== 'CODE' && (
                                    <p
                                        className={`${config?.deals?.dealLayout?.listBodyClass} text-sm text-[#373737] mt-5`}
                                    >
                                        Valid upto :{' '}
                                        {renderDate(rewardData?.endDate)}
                                    </p>
                                )}
                                {rewardData?.redemptionType !== 'CODE' && (
                                    <p
                                        className={`${config?.deals?.dealLayout?.listBodyClass}  text-[#373737] text-sm`}
                                    >
                                        T&C Apply
                                    </p>
                                )}
                                {rewardData?.redemptionType === 'CODE' && (
                                    <p
                                        className={`${config?.deals?.dealLayout?.listBodyClass} text-2xs text-[#373737] mt-1 `}
                                    >
                                        *T&C Apply
                                    </p>
                                )}
                            </div>

                            <button
                                onClick={() => {
                                    window.open(rewardData?.url);
                                }}
                                className={`text-sm h-10 text-white rounded-b-md cursor-pointer font-semibold bg-primary w-full py-2 mt-2 `}
                            >
                                AVAIL NOW
                            </button>
                        </div>
                    </ScratchCard2>
                </div>
            </Modal> */}
            <ReferralForm
                config={referralConfig}
                setOpenScratchCard={setOpenScratchCard}
                rewardData={rewardData}
                enquiryCities={enquiryCities}
                websiteUserRole={websiteUserRole}
                userStatus={userStatus}
                userTypeFormData={userTypeFormData}
                setUserTypeFormData={setUserTypeFormData}
            />
        </div>
    );
}

export default ReferralFormMain;
