import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
// console.log(window.location.origin);
const domain = window.location.origin;
const BPTPConfig = {
    clientId:
        '9821d132cf75037b0b77441d54485292112cd9de652b67431187cf324596d6f7',
    id: 28,
    name: 'Bptp',
    fullName: 'BPTP Privilege',
    programName:'BPTP Privilege',
    primaryColor: '#F9CC62',
    fontBody: `${getFont('BPTP').body}`,
    fontTitle: `${getFont('BPTP').bold}`,
    theme: 'BPTPTheme',
    favicon: 'https://cdn.loyalie.in/1696421473066_FAVICON',
    whatsappChatbot: '6292315127',
    googleConfig: {
        gID: '',
        keywordsContent:
            'BPTP Privilege real estate referral programs, BPTP group real estate referral program, BPTP group real estate refer and earn, BPTP Privilege real estate project refer, BPTP group real estate, BPTP Privilege real estate loyalty program, BPTP group real estate news, BPTP group real estate upcoming projects in Bengaluru, BPTP group real estate launch BPTP Privilege real estate, BPTP group real estate launch, BPTP group real estate program, BPTP group real estate projects, BPTP group real estate blogs, BPTP group real estate offers, BPTP group real estate upcoming projects in Bengaluru, BPTP group real estate events, BPTP group, BPTP Privilege, BPTP group real estate best residential projects, BPTP group real estate real estate developers, All New Projects by BPTP group real estate Builders & Developers, Ongoing Projects by BPTP group real estate Pvt Ltd, BPTP group real estate Bengaluru, BPTP group real estate new program, BPTP group press release, BPTP group upcoming projects, BPTP property group, BPTP group bangalore, BPTP real estate, BPTP reit, jitu virwani, BPTP commercial projects, BPTP group projects, BPTP group projects in bangalore',
        descriptionContent:
            "We present to you a bouquet of such experiences and more with BPTP Privilege - a bespoke loyalty and referral programme that's designed exclusively for BPTP homeowners",
        authorContent: 'BPTP Privilege'
    },

    facebookPixelConfig: {
        id: '', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~~2024c218',
    webengageAPIKey:'57f1fe83-a5ba-4157-83a9-11765e9b983d',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: true,
    logoLeft: {
        name: 'BPTP Privilege Logo',
        url: 'https://cdn.loyalie.in/1696333586993_Bptp_Privilege_Logo',
        stickyNavBarLogoClass:
            ' h-10 md:h-12 lg:h-12 xl:h-14 2xl:h-12 pl-3 xl:pl-10 ease-in duration-500',
        baseNavBarLogoClass:
            ' h-10 md:h-12 lg:h-12 xl:h-14 2xl:h-16 pl-3 xl:pl-10 ease-out duration-500'
    },

    logoRight: {
        name: 'BPTP Logo',
        url: 'https://cdn.loyalie.in/1696333602176_Bptp_Logo',
        stickyNavBarLogoClass:
            'hidden lg:block lg:h-10 2xl:h-8 pr-3 xl:pr-14 ease-in duration-500',
        baseNavBarLogoClass:
            'h-20 md:h-25 lg:h-10 2xl:h-10 pr-3 xl:pr-14 ease-out duration-500'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('BPTP').body}`,
        height: 'h-20 2xl:h-24 ease-out duration-500',
        stickyHeight: 'h-16 2xl:h-16 ease-in duration-500',
        mobileIconColor: 'text-primary-focus',
        dropDownBg: 'bg-white lg:bg-primary',
        showActiveLinkColor: false,
        footerNavItemClass: `tracking-widest uppercase text-white text-[12px] md:text-[14px] lg:text-white hover:text-white font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg  ${
            getFont('BPTP').body
        }`,
        baseNavBar: {
            border: true,
            borderClass: '',
            backgroundColor: 'bg-primary-content', // default: transparent
            containerClass: '',
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black text-[14px] lg:text-primary hover:text-primary-focus font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg',
            dropDownContainerClass: `hover:text-primary-focus lg:hover:text-white lg:hover:bg-primary-focus lg:focus:text-white lg:focus:bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] lg:focus:outline-none lg:focus:shadow-outline  `,
            dropDownItemClass:
                'tracking-widest text-black lg:text-white text-[14px] font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: true,
            borderClass: 'drop-shadow-lg',
            backgroundColor: 'bg-primary-content', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black text-[14px] lg:text-primary hover:text-primary-focus font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg ' // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: true,
    floaterType: 'CALL',
    mobile: '6292315127',
    floaterColor: 'bg-white',
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `mx-auto text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 ${
        getFont('BPTP').body
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage:
                        'https://cdn.loyalie.in/1696333496187_Homebanner',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1696585211046_Homebanner_SM',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0',
                        size: 'cover'
                    },
                    bannerContainerClass:
                        'h-[90vh] lg:h-[75vh] 2xl:h-[80vh] bg-center lg:bg-left-bottom lg:bg-cover',
                    bannerItemContainerClass:
                        'justify-start lg:justify-center pt-5 lg:pt-0  px-8 md:pl-10 xl:pl-16 ',
                    position: 'text-left',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'text-center bg-primary w-[270px] h-[270px] md:w-[340px] md:h-[340px]  xl:w-[440px] xl:h-[440px] rounded-full bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] !bg-auto flex items-center justify-center mx-auto lg:mx-0',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'bg-secondary p-5 rounded-full w-[240px] h-[240px] md:w-[300px] md:h-[300px] xl:w-[400px] xl:h-[400px] flex items-center',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content: 'BE PART OF A',
                                                    className: `uppercase text-white tracking-wide leading-[1.1] text-[18px] md:text-[25px] xl:text-[32px] ${
                                                        getFont('BPTP').heading
                                                    }`
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'NEW HOMEOWNING LEGACY',
                                                    className: ` uppercase text-white tracking-wide leading-[1.1] text-[18px] md:text-[25px] xl:text-[32px] ${
                                                        getFont('BPTP').heading
                                                    }`
                                                },

                                                {
                                                    type: 'TEXT',
                                                    content: 'BPTP PRIVILEGE',
                                                    className: ` uppercase text-transparent bg-clip-text bg-gradient-to-r from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] tracking-wide leading-[1] text-[26px] md:text-[30px] xl:text-[35px] 2xl:text-[42px] py-3  ${
                                                        getFont('BPTP').heading
                                                    }`
                                                },

                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'A Loyalty & Referral Program for BPTP residents',
                                                    className: `text-[13px] md:text-[13px] lg:text-[15px] xl:text-[17px] text-white tracking-normal font-medium mx-auto break-words leading-[1.3]  xl:w-[70%] ${
                                                        getFont('BPTP').body
                                                    }`
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: '',
                    url: 'https://cdn.loyalie.in/1695201979484_Pattern',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.97',
                        size: '65%'
                    },
                    className: 'flex flex-col justify-center text-center px-4 ',
                    components: [
                        {
                            type: 'BUTTON',
                            content: 'REFER NOW',
                            className: `mx-auto text-center -mt-6 font-semibold text-[15px] xl:text-[17px] tracking-wider  bg-gradient-to-r from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] text-secondary py-2.5 xl:py-3 px-8 z-20 ${
                                getFont('BPTP').body
                            }`,
                            // embeddedReferSection: true,
                            // scrollTo: 'referral_section',
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH'
                        },
                        {
                            type: 'TEXT',
                            content: 'ABOUT BPTP PRIVILEGE',
                            className: `text-primary uppercase pt-10 xl:pt-12 2xl:pt-16 text-center text-[25px] md:text-[30px]  xl:text-[32px] 2xl:text-[35px]  tracking-wider  ${
                                getFont('BPTP').heading
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://cdn.loyalie.in/1696414611459_Divider',
                            width: '120',
                            height: '2',
                            name: 'first',
                            imgHeight: 'h-[1.5px]',
                            className:
                                'flex justify-center text-center mx-auto text-center mt-3 lg:mt-5 w-[20%] lg:w-full '
                        },
                        {
                            type: 'TEXT',
                            content:
                                'With a rich legacy spanning over two decades in the real estate industry, BPTP has firmly positioned itself as one of the leading developers in India. Our unwavering commitment lies in establishing our brand as the epitome of trust, reliability, and preference.',
                            className: `pt-3 lg:pt-5 px-5 text-[16px] lg:text-[15px] xl:text-[17px] 2xl:text-[20px] text-secondary leading-[1.3] lg:leading-[1.2] self-center w-full lg:w-[80%] xl:w-[75%] break-words xl:mb-5  ${
                                getFont('BPTP').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'BPTP Privilege offers you a bouquet of experiences to bring you a homeowning experience that extends beyond the boundaries of your home.',
                            className: `pt-3  px-5 text-[16px] lg:text-[15px] xl:text-[17px] 2xl:text-[20px] text-secondary leading-[1.3] lg:leading-[1.2] self-center w-full lg:w-[80%] xl:w-[75%] break-words mb-5  ${
                                getFont('BPTP').body
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'rounded-2xl bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] !bg-auto flex items-center justify-center p-1 w-[95%] lg:w-[85%] 2xl:w-[80%] mx-auto ',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'rounded-xl bg-white py-10 xl:py-14',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content:
                                                'INDULGE IN PRIVILEGES WITH US',
                                            className: `text-primary uppercase text-center text-3xl text-[25px] md:text-[30px] xl:text-[32px] 2xl:text-[35px]  tracking-wider  ${
                                                getFont('BPTP').heading
                                            }`
                                        },
                                        {
                                            type: 'ANIMATE',
                                            url: 'https://cdn.loyalie.in/1696414611459_Divider',
                                            width: '120',
                                            height: '2',
                                            name: 'second',
                                            imgHeight: 'h-[1.5px]',
                                            className:
                                                'flex justify-center text-center mx-auto text-center mt-5 w-[20%] lg:w-full'
                                        },
                                        {
                                            type: 'CUSTOM',
                                            onClick: 'CHANGE_PATH',
                                            path: 'gift-and-earn',
                                            className:
                                                ' flex flex-col lg:flex-row  items-center cursor-pointer justify-center w-[90%] gap-x-24 xl:gap-x-28 gap-y-5 lg:gap-y-0 mx-auto mt-10 xl:mt-12 2xl:mt-16',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/1696416630413_Image_1',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-full lg:w-[60%] '
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-full lg:w-[40%] text-center lg:text-left ',
                                                    components: [
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Gift & Earn upto ',
                                                            className: `inline-block text-primary text-[22px] xl:text-[28px] 2xl:text-[32px] tracking-wider leading-[1] md:leading-[1.2] ${
                                                                getFont('BPTP')
                                                                    .body
                                                            }`
                                                        },

                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                ' ₹3.75 Lacs ',
                                                            className: `inline-block  text-primary text-[22px] xl:text-[28px] 2xl:text-[32px] tracking-wider leading-[1.2] ${
                                                                getFont('BPTP')
                                                                    .bold
                                                            }`
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                ' on every successful referral.',
                                                            className: `inline text-primary text-[22px] xl:text-[28px] 2xl:text-[32px] tracking-wider leading-[1] md:leading-[1.2] ${
                                                                getFont('BPTP')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'flex flex-col-reverse lg:flex-row  items-center cursor-pointer justify-center w-[90%] gap-x-24 xl:gap-x-28 gap-y-5 lg:gap-y-0 mx-auto mt-10 xl:mt-12 2xl:mt-16',
                                            onClick: 'CHANGE_PATH',
                                            path: 'deals',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-full lg:w-[40%] text-center lg:text-left ',
                                                    components: [
                                                        {
                                                            type: 'TEXT',
                                                            content: 'Enjoy',
                                                            className: `inline-block text-primary text-[22px] xl:text-[28px] 2xl:text-[32px] tracking-wider leading-[1] md:leading-[1.2] ${
                                                                getFont('BPTP')
                                                                    .body
                                                            }`
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                ' exclusive membership benefits',
                                                            className: `inline text-primary text-[22px] xl:text-[28px] 2xl:text-[32px] tracking-wider leading-[1] md:leading-[1.2]  ${
                                                                getFont('BPTP')
                                                                    .bold
                                                            }`
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'from curated brands.',
                                                            className: `text-primary text-[22px] xl:text-[28px] 2xl:text-[32px] tracking-wider leading-[1.2] ${
                                                                getFont('BPTP')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/1696416641319_Image_2',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-full lg:w-[60%]'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            onClick: 'CHANGE_PATH',
                                            path: 'events',
                                            className:
                                                'flex flex-col lg:flex-row  items-center cursor-pointer justify-center w-[90%] gap-x-24 xl:gap-x-28 gap-y-5 lg:gap-y-0 mx-auto mt-10 xl:mt-12 2xl:mt-16',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/1696416651360_Image_3',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-full lg:w-[60%]'
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-full lg:w-[40%] text-center lg:text-left ',
                                                    components: [
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Explore curated',
                                                            className: `inline-block text-primary text-[22px] xl:text-[28px] 2xl:text-[32px] tracking-wider leading-[1] md:leading-[1.2] ${
                                                                getFont('BPTP')
                                                                    .body
                                                            }`
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'workshops & events',
                                                            className: `text-primary text-[22px] xl:text-[28px] 2xl:text-[32px] tracking-wider leading-[1.2] ${
                                                                getFont('BPTP')
                                                                    .bold
                                                            }`
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            onClick: 'CHANGE_PATH',
                                            path: 'blogs',
                                            className:
                                                'flex flex-col-reverse lg:flex-row items-center cursor-pointer justify-center w-[90%] gap-x-24 xl:gap-x-28 gap-y-5 lg:gap-y-0 mx-auto mt-10 xl:mt-12 2xl:mt-16',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[90%] lg:w-[40%] text-center lg:text-left ',
                                                    components: [
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Explore our',
                                                            className: `inline-block text-primary text-[22px] xl:text-[28px] 2xl:text-[32px] tracking-wider leading-[1] md:leading-[1.2] ${
                                                                getFont('BPTP')
                                                                    .body
                                                            }`
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                ' home & lifestyle blogs',
                                                            className: `inline text-primary text-[22px] xl:text-[28px] 2xl:text-[32px] tracking-wider leading-[1] md:leading-[1.2] ${
                                                                getFont('BPTP')
                                                                    .bold
                                                            }`
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                ' for new trends.',
                                                            className: `inline text-primary text-[22px] xl:text-[28px] 2xl:text-[32px] tracking-wider leading-[1.2] ${
                                                                getFont('BPTP')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/1696416661323_Image_4',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-full lg:w-[60%]'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'TEXT',
                            content: 'OUR LATEST BLOGS',
                            className: `text-primary uppercase text-center pt-20 text-[25px] lg:text-[30px] xl:text-[32px] 2xl:text-[35px] tracking-wider  ${
                                getFont('BPTP').heading
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://cdn.loyalie.in/1696414611459_Divider',
                            width: '90',
                            height: '2',
                            name: 'third',
                            imgHeight: 'h-[1.5px]',
                            className:
                                'flex justify-center text-center m-auto text-center mt-5 w-[20%] lg:w-full'
                        },
                        {
                            type: 'BLOG_LAYOUT_VERTICAL1',
                            className:
                                'w-full h-auto flex flex-col-reverse lg:flex-row my-10 md:px-2 2xl:px-16 justify-center ',
                            classNameContainer:
                                ' w-full px-5 md:px-10 lg:px-10 2xl:w-[95%] overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-3 gap-x-4 md:gap-x-10 lg:gap-x-6 xl:gap-x-5 2xl:gap-x-16 3xl:gap-x-20 gap-y-14 '
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    className: 'flex flex-col lg:flex-row  w-full',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'w-full lg:w-[35%] xl:w-[40%] text-left bg-secondary h-[20vh] lg:h-[50vh] 2xl:h-[55vh] flex items-center justify-center ',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'EXCLUSIVE OFFERS',
                                    className: `text-white uppercase text-center lg:text-left text-3xl lg:text-[40px] xl:text-[45px] 2xl:text-[55px] tracking-wider w-full lg:w-[60%] xl:w-[50%] leading-[1.1] ${
                                        getFont('BPTP').heading
                                    }`
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'w-full lg:w-[65%] xl:w-[60%] text-left bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629]  !bg-auto h-[50vh] 2xl:h-[55vh] flex items-center justify-center',
                            components: [
                                {
                                    type: 'CAROUSEL',

                                    getVendorData: true,
                                    offerCards: true,
                                    caraouselClassName:
                                        'w-[80%] z-20 md:w-full xl:w-[95%] 2xl:w-[95%] 3xl:w-[80%] mx-auto md:px-12',
                                    minSlides: true,
                                    xlSlidesToShow: 2,
                                    arrows: true,
                                    autoplay: true,
                                    autoplaySpeed: 3000,
                                    infinite: true,
                                    dots: false,
                                    slidesToShow: 2,
                                    pauseOnHover: true,
                                    speed: 3000,
                                    onImageClick: openURLNewWindow
                                }
                            ]
                        }
                    ]
                },

                // OUR PROJECTS
                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/1695201979484_Pattern',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.97',
                        size: '65%'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-10 ',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR PROJECTS',
                            className: `text-primary uppercase text-center text-[25px] lg:text-[30px] xl:text-[35px] tracking-wider  ${
                                getFont('BPTP').heading
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://cdn.loyalie.in/1696414611459_Divider',
                            width: '50',
                            height: '1',
                            name: 'four',
                            imgHeight: 'h-[1px]',
                            className:
                                'flex justify-center text-center m-auto text-center mt-5 w-[20%] lg:w-full'
                        },
                        {
                            type: 'CAROUSEL',
                            customSlide: true,
                            caraouselClassName:
                                'px-8 w-[99%] md:w-[97%] xl:w-[84%] 2xl:w-[92%] 3xl:w-[80%] mx-auto md:pb-10 pt-1 relative pb-10 lg:py-5',
                            imageContainerClassName:
                                'm-4 relative bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629]  !bg-auto p-1  rounded-l-[500px] rounded-r-[500px]',
                            imageClassName:
                                'w-[600px] h-[150px] object-cover mx-auto h-auto lg:bg-cover rounded-l-[500px] rounded-r-[500px]',
                            captionClass: `uppercase text-sm lg:text-lg leading-8 ${
                                getFont('BPTP').body
                            }`,
                            xlSlidesToShow: 3,
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 4,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://bptpuat.s3.ap-south-1.amazonaws.com/Document/Project/14/40444100.jpg',
                                    alt: '',
                                    url: `${domain}/bptp-home/projects/detail?projectId=13`,
                                    caption: 'District 1 Block A'
                                },
                                {
                                    imgUrl: 'https://smartworlduat.s3.ap-south-1.amazonaws.com/Document/Project/9/ProjectGallery/68545876.jpg',
                                    alt: '',
                                    url: `${domain}/bptp-home/projects/detail?projectId=9`,
                                    caption: 'Fortuna'
                                },

                                {
                                    imgUrl: 'https://smartworlduat.s3.ap-south-1.amazonaws.com/Document/Project/10/ProjectGallery/24635596.jpg',
                                    alt: '',
                                    url: `${domain}/bptp-home/projects/detail?projectId=10`,
                                    caption: 'Terra'
                                },
                                {
                                    imgUrl: 'https://bptpuat.s3.ap-south-1.amazonaws.com/Document/Project/11/57141676.jpg',
                                    alt: '',
                                    url: `${domain}/bptp-home/projects/detail?projectId=11`,
                                    caption: 'Amstoria'
                                },
                                {
                                    imgUrl: 'https://bptpuat.s3.ap-south-1.amazonaws.com/Document/Project/12/56349637.jpg',
                                    alt: '',
                                    url: `${domain}/bptp-home/projects/detail?projectId=12`,
                                    caption: 'Green Oaks'
                                },

                                {
                                    imgUrl: 'https://www.bptp.com/images/projects/thumbnail/164369330120220201.jpg',
                                    alt: '',
                                    url: `${domain}/bptp-home/projects/detail?projectId=18`,
                                    caption: 'Discovery Park'
                                },

                                {
                                    imgUrl: 'https://bptpuat.s3.ap-south-1.amazonaws.com/Document/Project/17/37757340.jpg',
                                    alt: 'North Garden City',
                                    url: `${domain}/bptp-home/projects/detail?projectId=17`,
                                    caption: 'District 3 Block A'
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },

        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        referPage: {
            show: true,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: false,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[75vh] md:h-[70vh] object-cover bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center lg:text-left text-black text-[15px] md:text-[20px] xl:text-[25px] uppercase my-5 tracking-widest lg:w-[55%] ml-auto  ${
                                getFont('BPTP').body
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: 'flex lg:w-[55%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 mx-auto lg:mx-0 ${
                                        getFont('BPTP').body
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[45vh] md:h-[70vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').body
                    //         }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-black text-[15px] leading-10 md:text-[20px] xl:text-[45px] font-bold tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').heading
                    //         }`
                    // },
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex w-[70%] ml-auto justify-center mt-7', components: [{
                    //         type: 'BUTTON',
                    //         content: 'Start Referring Now',
                    //         scrollTo: 'referral_section',
                    //         onClick: 'SCROLL'
                    //     }]
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `mx-auto text-center font-semibold text-[15px] xl:text-[17px] tracking-wider  bg-gradient-to-r from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] text-secondary py-2.5 xl:py-3 px-8 z-20 ${
                getFont('BPTP').body
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[87vh] bg-center md:bg-right  mx-auto text-center',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0.05'
            },
            backgroundImage: 'https://cdn.loyalie.in/17711472.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/17711472.jpg',
            components: [
                {
                    type: 'TEXT',
                    content: 'BPTP PRIVILEGE READERS’ CLUB',
                    className: `mt-12 md:mt-10 text-[20pxpx] mb-3 lg:text-[30px] xl:text-[35px] 2xl:text-[42px] tracking-wide ${
                        getFont('BPTP').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[15%] xl:w-[20%] bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-5 mb-3  text-xl md:text-[16px] xl:text-[17px] tracking-wider ${
                        getFont('BPTP').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `mx-auto text-center mt-3 font-bold text-[12px] md:text-[17px] tracking-wider bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] text-secondary py-3 2xl:py-3 px-12 md:px-8 ${
                        getFont('BPTP').body
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'BPTP PRIVILEGE READERS’ CLUB',
                    className: `mt-12 md:mt-10 text-[20pxpx] mb-3 lg:text-[30px] xl:text-[35px] 2xl:text-[42px] tracking-wide ${
                        getFont('BPTP').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[15%] xl:w-[20%] bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-5 mb-3  text-xl md:text-[16px] xl:text-[17px] tracking-wider ${
                        getFont('BPTP').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `mx-auto text-center mt-3 font-bold text-[12px] md:text-[17px] tracking-wider bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] text-secondary py-3 2xl:py-3 px-12 md:px-8 ${
                        getFont('BPTP').heading
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('BPTP').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('BPTP').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary-focus text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0 tracking-normal text-[20px] text-[#1c2936] ${
                    getFont('BPTP').heading
                }`,
                headingClass: `text-[25px] lg:text-[27px] 2xl:text-[32px] uppercase tracking-tight text-secondary-focus mt-3 leading-8 break-words ${
                    getFont('BPTP').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('BPTP').body
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('BPTP').body
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('BPTP').body
                }`
            },
            sectionHeadings: `text-primary uppercase pt-10 text-center uppercase text-[22px] lg:text-[33px] 2xl:text-[35px] tracking-wide uppercase ${
                getFont('BPTP').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            cardHeight:
                'h-[500px] md:h-[470px] lg:h-[500px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[260px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 md:h-[45px] lg:h-[70px] text-[17px] xl:text-[18px] 3xl:text-[20px] tracking-tight	 font-medium uppercase leading-6 break-words mx-5 ${
                getFont('BPTP').heading
            }`,
            categoryClass: `bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] text-secondary opacity-90 ${
                getFont('BPTP').heading
            }`,
            summaryClass: `font-normal text-[15px] mt-2 leading-[1.2] text-secondary-focus mx-5 md:h-[50px] lg:h-[70px] ${
                getFont('BPTP').body
            }`,
            buttonClass: `bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] flex justify-center self-end float-right text-[13px] xl:text-[16px] text-secondary py-1.5 px-4 md:px-4 m-5 ${
                getFont('BPTP').body
            }`,
            buttonArrowUrl: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.16 11.59'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23231F20;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cline class='cls-1' y1='5.62' x2='55' y2='5.62'/%3E%3Cpolyline class='cls-1' points='48.5 0.39 51.93 3.09 55.35 5.79 51.93 8.49 48.5 11.19'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-secondary-focus ${
                getFont('BPTP').body
            }`,
            noBlogsClass: `bg-secondary text-white p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('BPTP').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/33191553.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/33191553.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.05',
                size: 'cover'
            },

            negativeMargin: ' ',
            containerClass: ' text-white w-[50%] bg-top ',
            heading: '',
            headingClass: ` text-center lg:w-[70%] text-[30px] xl:text-[32px] 2xl:text-[42px] text-white uppercase ${
                getFont('BPTP').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('BPTP').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('BPTP').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('BPTP').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('BPTP').heading
                    }`
                }
            ]
        },

        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: `self-center text-[32px] 2xl:text-[42px] uppercase tracking-tight text-primary mt-14 leading-10 break-words ${
                getFont('BPTP').heading
            }`,
            components: [
                {
                    type: 'ANIMATE',
                    url: 'https://cdn.loyalie.in/1696414611459_Divider',
                    width: '90',
                    height: '2',
                    name: 'five',
                    imgHeight: 'h-[1.5px]',
                    className:
                        'flex justify-center text-center m-auto text-center mt-5'
                }
            ],
            dividerClass:
                'hidden my-4 mx-auto  w-[30%] md:w-[20%] lg:w-[10%] bg-[#eb0988] border-none h-2 md:h-[8px]',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('BPTP').heading
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('BPTP').body
            }`
        },
        eventLayout: {
            loadMoreButtonClass: `mx-auto text-center font-semibold text-[15px] xl:text-[17px] tracking-wider  bg-gradient-to-r from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] text-secondary py-2.5 xl:py-3 px-8 z-20 ${
                getFont('BPTP').body
            }`,
            titleClass: `text-primary-focus leading-5 uppercase text-[22px] font-medium mt-2 ${
                getFont('BPTP').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-accent font-semibold text-lg my-4 z-10 ${
            getFont('BPTP').body
        }`,
        noGalleryClass: ` text-accent font-semibold ${getFont('BPTP').body}`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content  max-w-full h-[30vh] lg:h-[45vh] bg-top bg-cover', //bg-top bg-cover
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0',
                size: 'cover'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692635066109_offer%20page',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692635066109_offer%20page',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            sectionBackgroundImage:
                'https://cdn.loyalie.in/1695201979484_Pattern',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.98',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center text-[22px] md:text-[20px] text-center text-xl text-neutral w-[60%] ${
                getFont('BPTP').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `text-primary text-2xl xl:text-3xl leading-7 xl:leading-9 ${
                getFont('BPTP').heading
            } `,
            subAdditionalText:
                'BPTP Privilege is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-normal text-[14px] text-neutral ${
                getFont('BPTP').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('BPTP').heading}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/82775700.png',
            pattern2: 'https://cdn.loyalie.in/82775700.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.40',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-2xl lg:text-xl leading-6 lg:leading-5 text-primary ${
                getFont('BPTP').body
            }`,
            listBodyClass: `${getFont('BPTP').body}`,
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` text-2xl xl:text-3xl text-primary  leading-7 xl:leading-9 font-normal ${
                getFont('BPTP').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('BPTP').heading
            }`,
            dateClassName: ` text-neutral capitalize font-medium  text-black ${
                getFont('BPTP').body
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-sm  ${
                getFont('BPTP').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-xs text-neutral break-words leading-1 ${
                getFont('BPTP').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-gradient-to-r from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] text-secondary font-semibold text-secondary self-center text-[16px] py-2.5 px-7 py-3 2xl:py-3 px-12 md:px-8  ${
                    getFont('BPTP').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `hover:shadow-xl rounded bg-gradient-to-r from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] text-secondary font-semibold self-center text-[16px] py-2.5 px-7 py-3 2xl:py-3 px-7 md:px-8 ${
                    getFont('BPTP').body
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.50'
                },
                backgroundImage:
                    'https://cdn.loyalie.in/1696485542949_Project_Banner',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1696485542949_Project_Banner',
                bannerContainerClassName:
                    'hidden md:hero flex items-start justify-center max-w-full h-[85vh]  md:bg-bottom',

                components: [
                    {
                        type: 'TEXT',
                        content: 'BPTP RESIDENTIAL PROJECTS',

                        className: `text-center text-[30px] xl:text-[32px] 2xl:text-[42px] text-white uppercase tracking-wide ${
                            getFont('BPTP').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[20%] lg:w-[15%] xl:w-[20%] bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {
         
            constructionVisible: true,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/1695201979484_Pattern', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.97',
            size: '95%'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.30'
                },
                backgroundImage: 'https://cdn.loyalie.in/89848625.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/89848625.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center lg:bg-top h-[90vh] lg:h-[90vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: `text-center text-[30px] xl:text-[32px] 2xl:text-[42px] text-white uppercase ${
                            getFont('BPTP').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[20%] lg:w-[15%] xl:w-[25%] bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],

    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('BPTP').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('BPTP').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.30'
                },
                backgroundImage: 'https://cdn.loyalie.in/14952553.jpeg',
                backgroundImageMobile: 'https://cdn.loyalie.in/14952553.jpeg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center h-[90vh] lg:h-[80vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS & CONDITIONS',

                        className: `text-center text-[30px] xl:text-[32px] 2xl:text-[42px] text-white uppercase ${
                            getFont('BPTP').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[20%] lg:w-[15%] xl:w-[25%] bg-gradient-to-r  from-[#bd862c] via-[#ddaa3e] via-[#f4c65d] to-[#d19629] border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-10 lg:p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('BPTP').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('BPTP').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a BPTP  project, and has entered into a registered Agreement to Sell; or (ii) employee of BPTP ; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by BPTP  or any of its sister concerns/subsidiaries/affiliates (“BPTP  Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate BPTP  Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by BPTP .',
                            'Referrer shall not be able to refer existing customer(s) of BPTP . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with BPTP  or (ii) is/are already registered with BPTP  as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the BPTP ’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to BPTP  within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to BPTP ',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the BPTP  or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('BPTP').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or BPTP, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('BPTP').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('BPTP').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('BPTP').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('BPTP').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('BPTP').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('BPTP').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('BPTP').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        showMyProperty: false,
        appScreen: 'https://cdn.loyalie.in/54255047.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('BPTP').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[10.5px] 3xl:text-[15px] leading-[18px] tracking-tight text-white ${
            getFont('BPTP').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('BPTP').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-90'
    },
    referralForm: {
        name: 'BPTP',
        theme: 'BPTPTheme',
        color: '#ffffff',
        // backgroundImage:
        //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/33213763.jpg',
        backgroundImage: 'https://cdn.loyalie.in/7023826.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '20',
            G1: '20',
            B1: '20',
            opacity1: '0',
            R2: '52',
            G2: '52',
            B2: '52',
            opacity2: '0'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own a',
            // bookedCustomerHeading2: 'BPTP Property',

            employee: true,
            employeeHeading1: 'I am a',
            // employeeHeading1: 'BPTP Employee',

            salesAdmin: true,
            salesAdminHeading1: 'I am an',
            // salesAdminHeading2: 'BPTP Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        customConfig: {
            welcomeContent: '',
            headingColor: 'text-white',
            userBgColor: 'bg-transparent',
            borderColor: 'border-primary',
            animateUserType: true
        },
        pastReferralConfig: {
            selectedStatusColor: 'text-white',
            bgColor: 'bg-primary'
        },
        formContent: {
            heading: '"Build your own bespoke community"',
            headingClass: `self-center text-white sm:mt-0 text-3xl md:text-4xl xl:text-[48px] 2xl:text-[62px] w-[80%] lg:w-[50%] xl:w-[80%] 2xl:w-[90%] text-center tracking-wide leading-8 xl:leading-[50px] 2xl:leading-[60px] ${
                getFont('BPTP').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%230c5391'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink: '/bptp-home/terms-and-conditions',
        customLink: '/bptp-home/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default BPTPConfig;
