import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
// console.log(window.location.origin);
const domain = window.location.origin;
const SmartWorldCareConfig = {
    clientId:
        '03684311049f5cd78c9b0d8a932d4ea6e10af7e6f3ded89a860462321670b8bd',
    id: 26,
    name: 'Smartworld',
    fullName: 'Smartworld',
    programName:'Smartworld Care',
    primaryColor: '#0057B7',
    // fontBody: `${getFont('SMARTWORLDCARE').body}`,
    // fontTitle: `${getFont('SMARTWORLDCARE').heading}`,
    theme: 'SmartWorldCareTheme',
    favicon: 'https://cdn.loyalie.in/1698747014626_favicon%20smartword',

    googleConfig: {
        gID: 'G-7SSPFN87GX',
        keywordsContent:
            'Smartworld real estate referral programs, Smartworld real estate referral program, Smartworld real estate refer and earn, Smartworld real estate project refer, Smartworld real estate, Smartworld real estate loyalty program, Smartworld real estate news, Smartworld real estate upcoming projects in Bengaluru, Smartworld real estate launch Smartworld real estate, Smartworld real estate launch, Smartworld real estate program, Smartworld real estate projects, Smartworld real estate blogs, Smartworld real estate offers, Smartworld real estate upcoming projects in Bengaluru, Smartworld real estate events, Smartworld, Smartworld, Smartworld real estate best residential projects, Smartworld real estate real estate developers, All New Projects by Smartworld real estate Builders & Developers, Ongoing Projects by Smartworld real estate Pvt Ltd, Smartworld real estate Bengaluru, Smartworld real estate new program, Smartworld press release, Smartworld upcoming projects, JP property group, Smartworld bangalore, JP real estate, JP reit, jitu virwani, JP commercial projects, Smartworld projects, Smartworld projects in bangalore',
        descriptionContent:
            "We present to you a bouquet of such experiences and more with Smartworld - a bespoke loyalty and referral programme that's designed exclusively for JP homeowners",
        authorContent: 'Smartworld'
    },

    facebookPixelConfig: {
        id: '3673540119557484', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~~1341061c6',
    webengageAPIKey:'ac923dfc-a628-4714-9e44-d55a1d995b5c',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: true,
    logoLeft: {
        name: 'Smart World Care Logo',
        url: 'https://cdn.loyalie.in/1698649546646_Smartworld_Care_logo',
        stickyNavBarLogoClass:
            ' h-10 md:h-12 lg:h-10 xl:h-12 2xl:h-14 pl-4 pr-20 xl:pl-14 ease-in duration-500',
        baseNavBarLogoClass:
            ' h-10 md:h-12 lg:h-12 xl:h-14 2xl:h-16 pl-4  pr-16 xl:pl-14 ease-out duration-500'
    },

    logoRight: {
        name: 'Smart World Logo',
        url: 'https://cdn.loyalie.in/1698649558734_Smartworld_logo',
        stickyNavBarLogoClass:
            'hidden lg:block lg:h-10 xl:h-12 2xl:h-14 pr-3 xl:pr-14 ease-in duration-500',
        baseNavBarLogoClass:
            'h-8 md:h-10 lg:h-10 xl:h-12 2xl:h-14 pr-3 xl:pr-14 ease-out duration-500'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('SMARTWORLDCARE').body}`,
        height: 'h-20 2xl:h-24 ease-out duration-500',
        stickyHeight: 'h-16 2xl:h-16 ease-in duration-500',
        mobileIconColor: 'text-primary-focus',
        dropDownBg: 'bg-white lg:bg-primary',
        showActiveLinkColor: false,
        footerNavItemClass: `tracking-widest uppercase text-white text-[12px] md:text-[14px] lg:text-white hover:text-white font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg  ${
            getFont('SMARTWORLDCARE').body
        }`,
        baseNavBar: {
            border: true,
            borderClass: '',
            backgroundColor: 'bg-primary-content lg:bg-transparent', // default: transparent
            containerClass: '',
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black text-[14px] lg:text-black hover:text-primary-focus font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg ',
            dropDownContainerClass: `hover:text-primary-focus lg:hover:text-white lg:hover:bg-primary-focus lg:focus:text-gray-900 lg:focus:bg-primary lg:focus:outline-none lg:focus:shadow-outline  `,
            dropDownItemClass:
                'tracking-widest text-black lg:text-white text-[14px] font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: true,
            borderClass: 'drop-shadow-lg',
            backgroundColor: 'bg-white ', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black text-[14px] lg:text-primary hover:text-primary-focus font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg ' // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: 'lg:-mt-20 2xl:-mt-24', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `mx-auto text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 ${
        getFont('SMARTWORLDCARE').body
    }`,

    loginModal: {
        showEmployeeLogin: true,
        showSVLogin:false,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    // backgroundImage: 'https://cdn.loyalie.in/15203611.jpg',
                    // backgroundImageMobile:  'https://cdn.loyalie.in/41865225.png',
                    linearGradient: {
                        R: '241',
                        G: '241',
                        B: '241',
                        opacity: '1',
                        size: 'cover'
                    },
                    bannerContainerClass:
                        'h-[95vh] lg:h-[90vh] xl:h-[95vh] bg-center lg:bg-right-top lg:bg-cover border-b-[3px] border-primary-focus ',
                    bannerItemContainerClass:
                        'items-center md:items-start justify-center  px-8 md:pl-10 xl:pl-16 ',
                    position: 'text-left',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'text-center md:text-left w-full flex flex-col-reverse flex-col lg:flex-row items-center justify-between mx-auto px-4 py-10 lg:py-20 md:p-8 xl:p-10',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className: 'lg:w-[50%]',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'lg:w-[70%] pt-5 lg:pt-0',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content: 'Introducing',
                                                    className: `text-[13px] md:text-[13px] xl:text-[17px] 2xl:text-[23.5px] text-black tracking-wide font-bold self-center break-words leading-[1.4] ${
                                                        getFont(
                                                            'SMARTWORLDCARE'
                                                        ).body
                                                    }`
                                                },
                                                {
                                                    type: 'TEXT',

                                                    content: 'SMARTWORLD CARE',
                                                    className: `text-[35px] uppercase lg:pt-3 leading-8 md:leading-10 lg:leading-4 xl:leading-10 tracking-wide text-primary md:text-[40px] xl:text-[52px] 2xl:text-6xl font-bold ${
                                                        getFont(
                                                            'SMARTWORLDCARE'
                                                        ).heading
                                                    }`
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'A Loyalty & Referral Program for Smartworld Residents.',
                                                    className: `pt-3 text-[13px] md:text-[13px] xl:text-[17px] 2xl:text-[23.5px] text-black tracking-wide font-bold self-center break-words leading-[1.4] ${
                                                        getFont(
                                                            'SMARTWORLDCARE'
                                                        ).body
                                                    }`
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[50%] flex items-center justify-center lg:justify-start py-4 mx-auto lg:mx-0',
                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[18px] h-[18px] border-[4px] border-black rounded-full mr-6',
                                                            components: []
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[18px] h-[18px] border-[4px] border-black rounded-full',
                                                            components: []
                                                        }
                                                    ]
                                                },

                                                {
                                                    type: 'BUTTON',
                                                    content: 'Refer Now',
                                                    className: `mx-auto text-center mt-2 font-bold text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 ${
                                                        getFont(
                                                            'SMARTWORLDCARE'
                                                        ).body
                                                    }`,
                                                    path: 'gift-and-earn',
                                                    onClick:
                                                        'CHANGE_PATH_AND_SCROLL',
                                                    scrollTo: 'referral_section'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'lg:w-[50%] flex items-center justify-center ',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/1698655307245_Image_1',
                                            position: 'center',
                                            classNameContainer:
                                                'w-full lg:w-[70%] lg:mt-8 '
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: 'overview',
                    url: 'https://cdn.loyalie.in/1698649573528_Smart_Care_Pattern',
                    linearGradient: {
                        R: '0',
                        G: '87',
                        B: '183',
                        size: 'cover 25%',
                        opacity: '0.20'
                    },
                    className:
                        'flex flex-col justify-start bg-primary pt-10 px-4 ',
                    components: [
                        {
                            type: 'CUSTOM',
                            className: 'w-[90%] px-4 mx-auto',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'About',
                                    className: `text-white uppercase pt-5 text-left uppercase text-3xl lg:text-[40px] xl:text-[52px] 2xl:text-[65px] tracking-wider font-semibold leading-[1] ${
                                        getFont('SMARTWORLDCARE').secondary
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: 'SMARTWORLD CARE',
                                    className: `text-white uppercase text-left uppercase text-3xl lg:text-[40px] xl:text-[52px] 2xl:text-[55px] tracking-wide leading-[1] ${
                                        getFont('SMARTWORLDCARE').heading
                                    }`
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'flex flex-col lg:flex-row pt-8 lg:pt-16 gap-x-10',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className: 'flex lg:pt-1.5',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[20px] h-[20px] border-[4px] border-white rounded-full mr-6',
                                                    components: []
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[20px] h-[20px] border-[4px] border-white rounded-full',
                                                    components: []
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'lg:w-[90%] pt-5 lg:pt-0',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'Smartworld Developers place you above everything else, and the Smartworld Care referral program is tailored to enhance your experience by providing you with access to exclusive events, offers, and promotions in all categories.',
                                                    className: `text-[17px] xl:text-[20px] text-white leading-[1.4] break-words xl:mb-5  ${
                                                        getFont(
                                                            'SMARTWORLDCARE'
                                                        ).body
                                                    }`
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'Furthermore, we recognize and appreciate your efforts in expanding our community by rewarding your referrals. With this platform, we aim to prioritize your experience and give it the attention it deserves, placing you at the center of affluence.',
                                                    className: `pt-3 text-[17px] xl:text-[20px] text-white leading-[1.4] break-words xl:mb-5  ${
                                                        getFont(
                                                            'SMARTWORLDCARE'
                                                        ).body
                                                    }`
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'ICONS_LAYOUT',
                            containerClassName:
                                'h-content w-full lg:flex lg:h-auto items-start py-3 xl:py-10 my-5 ',
                            gridClassName:
                                'overflow-x-scroll lg:overflow-x-hidden flex lg:grid  lg:grid-cols-4 w-[90%] ml-auto pb-6 lg:pb-0',
                            infotxtClassName: `leading-5 2xl:leading-[25px] text-secondary-focus text-[15px] lg:text-[18px] font-normal text-left ${
                                getFont('SMARTWORLDCARE').body
                            }`,
                            infoHeadingClassName: `cursor-pointer w-full leading-[1.1] w-[70%] lg:w-[75%] text-[20px] md:text-[23px] xl:text-[28px] 2xl:text-[35px]  text-white font-normal py-5 tracking-wide text-left h-[110px] lg:h-auto ${
                                getFont('SMARTWORLDCARE').heading
                            }`,
                            dividerClassName:
                                'w-[20%] mx-auto border-none h-[6px] xl:h-[8px] my-3 xl:my-6',
                            imgContainerClassName: '',
                            imgClassName:
                                'w-[16%] md:w-[8%] lg:w-[18%] lg:mb-2',
                            info: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/1698649601137_Icon_1',
                                    infoHeading:
                                        'Gift Referral Discounts & earn up to Rs. XXXX',
                                    divider: false,
                                    dividerColor: '#f47d3a',
                                    infoText: '⚪ㅤ⚪',
                                    onClick: 'CHANGE_PATH',
                                    path: '/gift-and-earn'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/1698649609868_Icon_2',
                                    infoHeading:
                                        'Enjoy membership benefits from top brands',
                                    divider: false,
                                    dividerColor: '#f47d3a',
                                    infoText: '⚪ㅤ⚪',
                                    onClick: 'CHANGE_PATH',
                                    path: '/deals'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/1698649623919_Icon_3',
                                    infoHeading:
                                        'Participate in community events & workshops',
                                    divider: false,
                                    dividerColor: '#f47d3a',
                                    infoText: '⚪ㅤ⚪',
                                    onClick: 'CHANGE_PATH',
                                    path: '/events'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/1698649633896_Icon_4',
                                    infoHeading:
                                        'Engage withㅤㅤOUR trending blogs',
                                    divider: false,
                                    dividerColor: '#f47d3a',
                                    infoText: '⚪ㅤ⚪',
                                    onClick: 'CHANGE_PATH',
                                    path: '/blogs'
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',

                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-5 bg-primary-content ',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OFFERS OF THE MONTH',
                            className: `${
                                getFont('SMARTWORLDCARE').body
                            } text-secondary self-center mx-auto text-center w-[90%] md:w-[60%] lg:w-[50%] 2xl:w-[41%] text-[22px] xl:text-[32px] 2xl:text-[42px] leading-7 xl:leading-9 py-6 font-bold`
                        },
                        {
                            type: 'CAROUSEL',

                            getVendorData: true,
                            offerCards: true,
                            ContainerClassName:
                                'bg-cover rounded-lg h-auto mx-3 2xl:mx-5 p-8',
                            caraouselClassName:
                                'w-[80%] md:w-full xl:w-[86%] 2xl:w-[95%] 3xl:w-[80%] mx-auto md:px-12  pt-6 relative pb-14',

                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 4,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow
                        }
                    ]
                },

                {
                    type: 'BLOG_LAYOUT_GRID1',
                    url: 'https://cdn.loyalie.in/1698649573528_Smart_Care_Pattern',
                    linearGradient: {
                        R: '0',
                        G: '87',
                        B: '183',
                        size: 'cover 25%',
                        opacity: '0.20'
                    },
                    sectionHeading: 'OUR LATEST BLOG',
                    sectionHeadingClass: `${
                        getFont('SMARTWORLDCARE').body
                    } text-white self-center mx-auto text-center text-[22px] xl:text-[32px] 2xl:text-[42px] pt-7 pb-8 2xl:pb-16 font-bold`,
                    headingClass: `text-white my-5 text-[27px] 3xl:text-[20px] tracking-tight font-medium uppercase leading-[1.2] break-words mr-5 ${
                        getFont('SMARTWORLDCARE').heading
                    }`,
                    summaryClass: `font-normal text-[16.5px] xl:text-[17px] mt-2 leading-6 text-white ${
                        getFont('SMARTWORLDCARE').body
                    }`,
                    buttonClass: `font-bold text-[13px] xl:text-[16px] text-secondary py-3 px-4 md:px-4 absolute right-0 lg:-right-4 bottom-1 bg-white ${
                        getFont('SMARTWORLDCARE').body
                    }`,
                    className:
                        'w-full h-auto px-5 lg:px-8 xl:px-2 2xl:px-10 justify-center pt-10 pb-5 bg-primary',
                    classNameContainer:
                        ' w-full px-5 md:px-44 lg:px-0 xl:px-20 lg:w-[90%] 2xl:w-[80%] mx-auto overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-2 lg:grid-flow-row gap-x-8 lg:gap-x-24 xl:gap-x-36 3xl:gap-x-28 gap-y-5'
                },

                // OUR PROJECTS
                {
                    type: 'CUSTOM',
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] lg:pt-8 pb-24 -mb-28 bg-primary-content ',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR PROJECTS',
                            className: `${
                                getFont('SMARTWORLDCARE').body
                            } text-secondary self-center mx-auto text-center w-[90%] md:w-[60%] lg:w-[50%] 2xl:w-[41%] text-[22px] xl:text-[32px] 2xl:text-[42px] leading-7 xl:leading-9 pt-6 font-bold`
                        },
                        {
                            type: 'CAROUSEL',
                            customSlide: true,
                            caraouselClassName:
                                'px-8 w-[99%] md:w-[97%] xl:w-[84%] 2xl:w-[92%] 3xl:w-[80%] mx-auto py-3 lg:py-10 relative ',
                            imageContainerClassName: 'relative',
                            imageClassName:
                                'w-full px-5 h-44 object-cover lg:bg-cover',
                            captionClass: `mx-5 uppercase text-sm lg:text-lg leading-8 bg-primary py-2 w-auto !text-white ${
                                getFont('SMARTWORLDCARE').body
                            }`,
                            xlSlidesToShow: 3,
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 4,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://smartworld-prod.s3.ap-south-1.amazonaws.com/Document/Project/5/2eVKAUuBPC.jpeg',
                                    alt: 'img',
                                    url: `${domain}/smartworld-home/projects/detail?projectId=5`,
                                    caption: 'SMARTWORLD ONE dxp'
                                },
                                {
                                    imgUrl: 'https://smartworld-prod.s3.ap-south-1.amazonaws.com/Document/Project/2/C12OIMxDw4.jpg',
                                    alt: 'img',
                                    url: `${domain}/smartworld-home/projects/detail?projectId=2`,
                                    caption: 'SMARTWORLD ORCHARD'
                                },

                                {
                                    imgUrl: 'https://smartworld-prod.s3.ap-south-1.amazonaws.com/Document/Project/9/Gy12sGhCGb.jpg',
                                    alt: 'img',
                                    url: `${domain}/smartworld-home/projects/detail?projectId=9`,
                                    caption: 'Smartworld The Edition'
                                },
                                {
                                    imgUrl: 'https://smartworld-prod.s3.ap-south-1.amazonaws.com/Document/Project/4/C7XbwMc2hF.jpg',
                                    alt: 'img',
                                    url: `${domain}/smartworld-home/projects/detail?projectId=4`,
                                    caption: 'Smartworld Gems'
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },

        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        referPage: {
            show: true,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: false,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[75vh] md:h-[70vh] object-cover bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center lg:text-left text-black text-[15px] md:text-[20px] xl:text-[25px] uppercase my-5 tracking-widest lg:w-[55%] ml-auto  ${
                                getFont('SMARTWORLDCARE').body
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: 'flex lg:w-[55%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 mx-auto lg:mx-0 ${
                                        getFont('SMARTWORLDCARE').body
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[45vh] md:h-[70vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').body
                    //         }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-black text-[15px] leading-10 md:text-[20px] xl:text-[45px] font-bold tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').heading
                    //         }`
                    // },
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex w-[70%] ml-auto justify-center mt-7', components: [{
                    //         type: 'BUTTON',
                    //         content: 'Start Referring Now',
                    //         scrollTo: 'referral_section',
                    //         onClick: 'SCROLL'
                    //     }]
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold bg-primary text-white  ${
                getFont('SMARTWORLDCARE').body
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[95vh] bg-center md:bg-top-right  mx-auto text-center border-b-[3px] border-primary-focus',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.30'
            },
            backgroundImage: 'https://cdn.loyalie.in/1695987591646_blog_banner',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1695987591646_blog_banner',
            components: [
                {
                    type: 'TEXT',
                    content: 'SMARTWORLD CARE READERS’ CLUB',
                    className: `mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[22px] xl:text-[32px] 2xl:text-[42px]  tracking-wider text-white drop-shadow  ${
                        getFont('SMARTWORLDCARE').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] g:w-[15%] xl:w-[20%] bg-primary-focus border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-bold mt-5 mb-3  text-xl md:text-[16px] xl:text-[17px] tracking-wider text-secondary ${
                        getFont('SMARTWORLDCARE').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `mx-auto text-center mt-3 font-bold text-[12px] md:text-[17px] tracking-wider bg-primary-focus text-white py-3 2xl:py-3 px-12 md:px-8 ${
                        getFont('SMARTWORLDCARE').body
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'SMARTWORLD CARE READERS’ CLUB',
                    className: `mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[22px] xl:text-[32px] 2xl:text-[42px]  tracking-wider text-white drop-shadow ${
                        getFont('SMARTWORLDCARE').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] g:w-[15%] xl:w-[20%] bg-primary-focus border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-bold mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider text-secondary ${
                        getFont('SMARTWORLDCARE').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `mx-auto text-center mt-3 font-bold text-[12px] md:text-[17px] tracking-wider bg-primary-focus text-white py-3 2xl:py-3 px-12 md:px-8  ${
                        getFont('SMARTWORLDCARE').bold
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('SMARTWORLDCARE').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('SMARTWORLDCARE').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary-focus text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0 tracking-normal text-[20px] text-[#1c2936] ${
                    getFont('SMARTWORLDCARE').heading
                }`,
                headingClass: `text-[32px] xl:text-[42px] uppercase tracking-tight text-primary mt-3 leading-10 break-words ${
                    getFont('SMARTWORLDCARE').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-secondary  min-h-[40px] lg:min-h-[70px] ${
                    getFont('SMARTWORLDCARE').body
                }`,
                dateClass: `text-[13px] uppercase text-secondary font-semibold my-3 ${
                    getFont('SMARTWORLDCARE').body
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 font-medium ${
                    getFont('SMARTWORLDCARE').body
                }`
            },
            sectionHeadings: `text-secondary uppercase pt-10 text-center uppercase  text-[22px] xl:text-[32px] 2xl:text-[42px] tracking-wide uppercase font-bold ${
                getFont('SMARTWORLDCARE').body
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            cardHeight:
                'h-[500px] md:h-[470px] lg:h-[500px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[260px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 md:h-[45px] lg:h-[70px] text-[27px] 3xl:text-[20px] tracking-tight	 font-medium uppercase leading-7 break-words mx-5 ${
                getFont('SMARTWORLDCARE').heading
            }`,
            categoryClass: `bg-primary text-white ${
                getFont('SMARTWORLDCARE').body
            }`,
            summaryClass: `font-normal text-[16.5px] xl:text-[17px] mt-2 leading-6 text-secondary-focus mx-5 md:h-[50px] lg:h-[70px] ${
                getFont('SMARTWORLDCARE').body
            }`,
            buttonClass: `bg-primary-focus flex justify-center self-end float-right text-[13px] xl:text-[16px] text-white py-1.5 px-4 md:px-4 m-5 ${
                getFont('SMARTWORLDCARE').body
            }`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-secondary-focus ${
                getFont('SMARTWORLDCARE').body
            }`,
            noBlogsClass: `bg-secondary text-white p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('SMARTWORLDCARE').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/33191553.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/33191553.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0',
                size: 'cover'
            },

            negativeMargin: 'lg:-mt-20 2xl:-mt-24',
            containerClass: ' text-white w-[50%] bg-top',
            heading: '',
            headingClass: ` text-center lg:w-[70%] text-[30px] xl:text-[42px] 2xl:text-[62px] text-white uppercase ${
                getFont('SMARTWORLDCARE').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('SMARTWORLDCARE').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('SMARTWORLDCARE').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('SMARTWORLDCARE').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('SMARTWORLDCARE').bold
                    }`
                }
            ]
        },

        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: `self-center text-[22px] xl:text-[32px] 2xl:text-[42px] uppercase tracking-tight text-primary mt-14 leading-10 break-words ${
                getFont('SMARTWORLDCARE').heading
            }`,
            dividerClass:
                'my-4 mx-auto  w-[30%] md:w-[20%] lg:w-[10%] bg-[#eb0988] border-none h-2 md:h-[8px] hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('SMARTWORLDCARE').heading
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('SMARTWORLDCARE').body
            }`
        },
        eventLayout: {
            loadMoreButtonClass: `mx-auto text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 ${
                getFont('SMARTWORLDCARE').body
            }`,
            titleClass: `text-primary-focus leading-5 uppercase text-[22px] font-medium mt-2 ${
                getFont('SMARTWORLDCARE').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-accent font-semibold text-lg my-4 z-10 ${
            getFont('SMARTWORLDCARE').body
        }`,
        noGalleryClass: ` text-accent font-semibold ${
            getFont('SMARTWORLDCARE').body
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content  max-w-full h-[30vh] lg:h-[45vh] bg-top bg-cover', //bg-top bg-cover
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0',
                size: 'cover'
            },
            backgroundImage:
                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1697179801608_offer banner',
            backgroundImageMobile:
                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1697179801608_offer banner',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/4685974.png',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.97',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center text-[22px] md:text-[20px] text-center text-xl text-neutral w-[60%] ${
                getFont('SMARTWORLDCARE').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `text-secondary uppercase font-bold text-2xl xl:text-3xl leading-7 xl:leading-9 ${
                getFont('SMARTWORLDCARE').body
            } `,
            subAdditionalText:
                'Smartworld is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-normal text-[14px] text-neutral ${
                getFont('SMARTWORLDCARE').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('SMARTWORLDCARE').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/82775700.png',
            pattern2: 'https://cdn.loyalie.in/82775700.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.40',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-2xl lg:text-xl leading-6 lg:leading-5 text-primary ${
                getFont('SMARTWORLDCARE').body
            }`,
            listBodyClass: `${getFont('SMARTWORLDCARE').body}`,
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` text-2xl xl:text-4xl text-primary  leading-7 xl:leading-9 font-normal ${
                getFont('SMARTWORLDCARE').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('SMARTWORLDCARE').body
            }`,
            dateClassName: ` text-neutral capitalize font-medium  text-black ${
                getFont('SMARTWORLDCARE').body
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-sm  ${
                getFont('SMARTWORLDCARE').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-xs text-neutral break-words leading-1 ${
                getFont('SMARTWORLDCARE').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-primary-focus text-white font-medium self-center text-[16px] py-2.5 px-7 py-3 2xl:py-3 px-12 md:px-8  ${
                    getFont('SMARTWORLDCARE').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `hover:shadow-xl rounded bg-primary-focus hover:bg-primary text-white font-medium self-center text-[16px] py-2.5 px-7 py-3 2xl:py-3 px-7 md:px-8 ${
                    getFont('SMARTWORLDCARE').body
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.40'
                },

                backgroundImage:
                    // 'https://www.smartworldm3m.com/images/one-dxpbanner.webp',
                    'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1718181467053_SmartWorld',
                backgroundImageMobile: 'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1718181467053_SmartWorld',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[97vh]  md:bg-bottom bg-cover',

                components: [
                    {
                        type: 'TEXT',
                        content: 'SMARTWORLD RESIDENTIAL PROJECTS',

                        className: `text-center text-2xl md:text-4xl lg:text-[22px] xl:text-[32px] 2xl:text-[42px] text-white uppercase pb-5 drop-shadow ${
                            getFont('SMARTWORLDCARE').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[15%] xl:w-[20%] bg-primary-focus border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {
          
            constructionVisible: true,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },

    header: {
        headerClass: 'hidden lg:block h-28 bg-primary-content'
    },

    contactUs: {
        sectionBackgroundImage:
            'https://cdn.loyalie.in/1698749701395_Smart_Care_Pattern1', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.20',
            repeat: 'bg-no-repeat',
            size: 'cover'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/16571251.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/16571251.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center lg:bg-center h-[90vh] lg:h-[93vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: `text-center text-2xl md:text-4xl lg:text-[22px] xl:text-[32px] 2xl:text-[42px] drop-shadow text-white uppercase pb-5 ${
                            getFont('SMARTWORLDCARE').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[20%] bg-primary-focus border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],

    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('SMARTWORLDCARE').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('SMARTWORLDCARE').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage:
                    'https://piramaluat.s3.ap-south-1.amazonaws.com/Website/Uploads/Piramal/Images/48020127.jpg',
                backgroundImageMobile:
                    'https://piramaluat.s3.ap-south-1.amazonaws.com/Website/Uploads/Piramal/Images/48020127.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center h-[90vh] lg:h-[90vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS & CONDITIONS',

                        className: `text-center text-2xl md:text-4xl lg:text-[22px] xl:text-[32px] 2xl:text-[42px] drop-shadow text-white uppercase pb-5 ${
                            getFont('SMARTWORLDCARE').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[20%] xl:w-[15%] bg-primary-focus border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-10 lg:p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('SMARTWORLDCARE').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('SMARTWORLDCARE').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Smartworld project, and has entered into a registered Agreement to Sell; or (ii) employee of Smartworld ; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Smartworld  or any of its sister concerns/subsidiaries/affiliates (“Smartworld  Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Smartworld  Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Smartworld .',
                            'Referrer shall not be able to refer existing customer(s) of Smartworld . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Smartworld  or (ii) is/are already registered with Smartworld  as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Smartworld ’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Smartworld  within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Smartworld ',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Smartworld  or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('SMARTWORLDCARE').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Smartworld Lifespaces, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('SMARTWORLDCARE').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('SMARTWORLDCARE').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('SMARTWORLDCARE').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('SMARTWORLDCARE').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('SMARTWORLDCARE').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('SMARTWORLDCARE').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('SMARTWORLDCARE').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        showMyProperty: false,
        appContainerClassName: 'bg-white text-secondary border-primary',
        appFontHeading: `${getFont('SMARTWORLDCARE').body}`,
        appStoreLink:
            'https://apps.apple.com/tr/app/smartworld-care/id6470877463?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.smartworld&utm_medium=email',
        appScreen: 'https://cdn.loyalie.in/54255047.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('SMARTWORLDCARE').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[10.5px] 3xl:text-[15px] leading-[18px] tracking-tight text-white ${
            getFont('SMARTWORLDCARE').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('SMARTWORLDCARE').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-primary border-primary hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-90'
    },
    referralForm: {
        name: 'Smartworld',
        theme: 'JPTheme',
        color: '#ffffff',
        // backgroundImage:
        //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/33213763.jpg',
        backgroundImage: 'https://cdn.loyalie.in/7023826.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '20',
            G1: '20',
            B1: '20',
            opacity1: '0',
            R2: '52',
            G2: '52',
            B2: '52',
            opacity2: '0'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own a',
            // bookedCustomerHeading2: 'Smartworld Property',

            employee: true,
            employeeHeading1: 'I am a',
            // employeeHeading1: 'Smartworld Employee',

            salesAdmin: true,
            salesAdminHeading1: 'I am an',
            // salesAdminHeading2: 'Smartworld Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        customConfig: {
            welcomeContent: '',
            headingColor: 'text-white',
            userBgColor: 'bg-transparent',
            borderColor: 'border-primary',
            animateUserType: true
        },
        pastReferralConfig: {
            selectedStatusColor: 'text-white',
            bgColor: 'bg-primary'
        },
        formContent: {
            heading: '"Build your own bespoke community"',
            headingClass: `self-center text-white sm:mt-0 text-3xl md:text-4xl xl:text-[48px] 2xl:text-[62px] w-[80%] lg:w-[50%] xl:w-[80%] 2xl:w-[90%] text-center tracking-wide leading-8 xl:leading-[50px] 2xl:leading-[60px] ${
                getFont('SMARTWORLDCARE').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%230c5391'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink: '/jp-website/terms-and-conditions',
        customLink: '/jp-website/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default SmartWorldCareConfig;
