import { useEffect, useState } from "react"
import { winnreNoAuth } from "../../utils/axios"

const useCity = () => {
    const [cities, setCity] = useState(null)
    const getEnquiryCities = () => {
     winnreNoAuth({method:'GET', url:'winnre/no-auth/enum/enquiry-project-cities'}).then((res) => {
            setCity(res)})}
     return { cities, getEnquiryCities}
}

export default useCity