import { getFont } from '../../helpers';
import godrejBgImage from '../../assets/ReferralForm/Godrej.png';

const GodrejFormConfig = {
    clientId:
        '4757d92ecbc85bd93320870e1d15ae79788920c0cbf41abe884bfbc4980a0c08',
    id: 7,
    name: 'Godrej',
    theme: 'godrejTheme',
    favicon: 'https://cdn.loyalie.in/38567636.jpg',
    logo: {
        url: '',
        stickyNavBarLogoClass: 'hidden',
        baseNavBarLogoClass: 'hidden',
        name: 'logo'
    },
    facebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },
    navBarConfig: {
        showLogin: false,

        font: ``,
        height: 'h-0',
        mobileIconColor: '',
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: '',
            containerClass: '',
            itemClass: ''
        },
        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: '',
            containerClass: '',
            itemClass: ''
        },
        showActiveLinkColor: false
    },
    floatingButton: false,
    showFooterRoutes: false,
    routes: {
        home: {
            name: '',
            path: '/',
            show: true,
            components: [
                {
                    type: 'REFERRAL_FORM'
                }
            ]
        },
        refer: {
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn',
            show: false
        },

        events: {
            name: 'EVENTS',
            path: '/events',
            component: 'Events',
            show: false,
            layout: 'Layout2'
        },
        blogs: {
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs',
            show: false
        },
        deals: {
            name: 'OFFERS',
            authenticatedRoute: true,
            path: '/deals',
            component: 'DealsAndDiscounts',
            show: false
        },
        projects: {
            name: 'PROJECTS',
            authenticatedRoute: false,
            path: '/projects',
            component: 'Projects',
            show: false
        },
        contactUs: {
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us',
            show: false,
            layout: 'Layout2'
        },
        termsAndConditions: {
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions',
            show: false
        }
    },

    referralForm: {
        name: 'Godrej',
        theme: 'godrejTheme',
        color: '#F26722',
        backgroundImage: godrejBgImage,
        bgOverlay: true,
        linearGradient: {
            degree: '90deg',
            R1: '255',
            G1: '255',
            B1: '255',
            opacity1: '0.3',
            R2: '255',
            G2: '71',
            B2: '19',
            opacity2: '0.4'
        },
        userTypes: {
            length: 2,
            siteVisitor: false,
            siteVisitorHeading1: '',
            siteVisitorHeading2: '',

            bookedCustomer: true,
            bookedCustomerHeading1: '',
            bookedCustomerHeading2: '',

            employee: false,
            employeeHeading1: '',
            employeeHeading2: '',

            salesAdmin: true,
            salesAdminHeading1: 'I am a ',
            salesAdminHeading2: 'Godrej Employee*',

            influencer: false,
            influencerHeading1: '',
            influencerHeading2: ''
        },
        formContent: {
            heading: 'ENRICHING EXPERIENCES',
            headingClass: `self-center text-white sm:mt-0 text-4xl sm:text-5xl  w-[80%] lg:w-full  text-center tracking-widest  ${
                getFont('GODREJ').heading
            }`,
            notification: {
                line1: 'Refer & Share The Benefits With Your Loved Ones',
                line1Class: `self-center text-center text-white font-semibold text-sm sm:text-xl lg:text-lg 2xl:text-2xl lg:tracking-wider ${
                    getFont('GODREJ').default
                }`,
                line2: 'Introduce your loved ones to their dream abode and earn exclusive benefits',
                line2Class: `self-center text-center text-gray-300 mt-1  text-xs sm:text-md lg:text-sm 2xl:text-md ${
                    getFont('GODREJ').default
                }`,
                line3: 'FOR BEING A FRIEND INDEED',
                line3Class: `self-center  text-center  text-primary font-semibold tracking-wider mt-1  text-xl sm:text-4xl lg:text-2xl 2xl:text-3xl ${
                    getFont('GODREJ').default
                }`
            }
        },
        leadEmailMandatory: false,
        sendReferralAttemptNotification: false,
        isDirectTAndCLink: true,
        termsAndConditionsLink:
            'https://www.godrejambassador.com/terms-and-conditions/',
        customLink: ``,
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: true,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: true,
        footerDisclaimerContent: ''
    }
};
export default GodrejFormConfig;
