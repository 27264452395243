import React, { useState, useRef, useEffect } from 'react';
import ComponentsRenderer from '../../Components/ComponentsRenderer';
import useData from './data';  
import CampaignSection from './CampaignSection';
import PastReferrals from './PastReferrals';
import TotalReferral from './TotalReferral';
import TotalRewards from './TotalRewards';
import ReferralFormMain from './ReferralForm';
import { useSelector } from 'react-redux';
import { gsap } from 'gsap';
import useCampaign from './CampaignSection/data';
import ProjectsSection from './ProjectsSection';
import Header from './Components/Header';
import useCity from '../../Hooks/API/use-city';
import { cmsClient } from '../../utils/axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function ReferralPage({ config }) {
    const [filterCity, setFilterCity] = useState(null);

    const {
        getActiveCampaigns,
        campaigns,
        getProjects,
        projects,
        getCityBasedProjectList
    } = useCampaign(filterCity);
    const { isLoading, isAuthenticated, userAccountSummary, uploadDocuments } =
        useData();
    const { cities, getEnquiryCities } = useCity();

    useEffect(() => {
        if (isAuthenticated) {
            getActiveCampaigns();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (filterCity) {
            getCityBasedProjectList(filterCity);
        }
    }, [filterCity]);

    useEffect(() => {
        if (isAuthenticated) {
            getEnquiryCities();
        }
    }, [isAuthenticated]);

    const parallaxRef = useRef(null);

    useEffect(() => {
        const parallax = (event) => {
            const elements = parallaxRef?.current?.querySelectorAll(
                '.parallax-wrap span'
            );
            const x = (window.innerWidth - event.pageX) / 50;
            const y = (window.innerHeight - event.pageY) / 50;

            gsap.to(elements, { x: x, y: y });
        };

        document.addEventListener('mousemove', parallax);

        return () => {
            document.removeEventListener('mousemove', parallax);
        };
    }, []);
    const history=useHistory();
    const handleClick=()=>{
      history.push('/myProfile/referrals');
    }

    return (
        <section
            className={` ${config?.deals.dealsSection.overlayClass ? 'relative' : ''
                } `}
            style={{
                backgroundImage: `linear-gradient(90deg, rgba(${config?.deals.dealsSection.linearGradient?.R}, ${config?.deals.dealsSection.linearGradient?.G}, ${config?.deals.dealsSection.linearGradient?.B}, ${config?.deals.dealsSection.linearGradient?.opacity}),rgba(${config?.deals.dealsSection.linearGradient?.R}, ${config?.deals.dealsSection.linearGradient?.G}, ${config?.deals.dealsSection.linearGradient?.B}, ${config?.deals.dealsSection.linearGradient?.opacity})), url("${config?.deals.dealsSection?.sectionBackgroundImage}")`,
                backgroundAttachment: 'fixed',
                backgroundSize: `${config?.deals.dealsSection.linearGradient?.size
                        ? config?.deals.dealsSection.linearGradient?.size
                        : 'cover'
                    }`
            }}
        >
            {config?.deals.dealsSection.overlayClass ? (
                <div
                    className={
                        `${config?.deals.dealsSection.overlayClass}` + ' '
                    }
                ></div>
            ) : null}

            {!isAuthenticated && (
                <div className="">
                    <ComponentsRenderer
                        components={[
                            ...config?.referPage?.banner?.loginBannerComponents?.map(
                                (c) => {
                                    if (c.type === 'BANNER') {
                                        return {
                                            ...c,
                                            backgroundImage:
                                                window.reloy_env.client_config
                                                    .REFER_PRE_LOGIN_BANNER,
                                            backgroundImageMobile:
                                                window.reloy_env.client_config
                                                    .REFER_PRE_LOGIN_BANNER_MOBILE
                                        };
                                    }
                                    return c;
                                }
                            )
                        ]}
                    />
                </div>
            )}

            {isAuthenticated && (
                <>
                    <div>
                        <div className="">
                            <ComponentsRenderer
                                components={[
                                    ...config?.referPage?.components?.map(
                                        (c) => {
                                            if (c.type === 'BANNER') {
                                                return {
                                                    ...c,
                                                    backgroundImage:
                                                        window.reloy_env
                                                            .client_config
                                                            .REFER_POST_LOGIN_BANNER,
                                                    backgroundImageMobile:
                                                        window.reloy_env
                                                            .client_config
                                                            .REFER_POST_LOGIN_BANNER_MOBILE
                                                };
                                            }
                                            return c;
                                        }
                                    )
                                ]}
                            />
                        </div>
                     
                    <section className="w-full">
                    <div className="flex lg:gap-5 xl:gap-8  pt-5 lg:pt-10 xl:pt-8 pb-1 lg:pb-0 xl:pb-2 px-6 lg:px-14  2xl:px-28 ">
                    <div className='hidden lg:block ml-2 lg:ml-0'>
                    <img className='sm:h-[800px] sm:w-[900px] md:h-[300px] md:w-[300px] lg:h-[300px]  lg:w-[1900px] xl:w-[1800px] 4xl:w-[2000px]' src='https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1717572951846_NEW%20REFER%20AND%20EARN%20BANNER' alt='referral'/>
                    </div>
                    <div className='flex flex-col w-full h-full justify-center md:ml-7 md:mr-7 lg:ml-0 lg:mr-0 lg:w-[250px] xl:w-[500px] lg:h-[300px] 4xl:w-[500px] 4xl:h-[400px] px-3 py-3 lg:px bg-primary rounded-lg '>
                        <div className='flex gap-3  lg:flex-col items-center mt-4  md:ml-0 '>
                       <svg xmlns="http://www.w3.org/2000/svg" width="63.305" height="75.674" viewBox="0 0 63.305 75.674">
                         <g id="OBJECTS" transform="translate(-80.32 -39.34)">
                    <path id="Path_6949" data-name="Path 6949" d="M143.625,391.631c0,2.744-14.17,6.518-31.652,6.518s-31.653-3.774-31.653-6.518,14.17-4.971,31.653-4.971S143.625,388.887,143.625,391.631Z" transform="translate(0 -283.135)" fill="#1a4c8d"/>
    <g id="Group_11058" data-name="Group 11058" transform="translate(86.282 39.34)">
      <g id="Group_11046" data-name="Group 11046" transform="translate(42.897 55.916)">
        <g id="Group_11045" data-name="Group 11045" transform="translate(0 0)">
          <circle id="Ellipse_1743" data-name="Ellipse 1743" cx="6.017" cy="6.017" r="6.017" transform="translate(0 11.786) rotate(-78.363)" fill="#dba32c"/>
          <circle id="Ellipse_1744" data-name="Ellipse 1744" cx="4.456" cy="4.456" r="4.456" transform="translate(1.181 9.242) rotate(-64.785)" fill="#edcb3a"/>
          <g id="Group_11044" data-name="Group 11044" transform="translate(3.433 3.502)">
            <g id="Group_11043" data-name="Group 11043">
              <path id="Path_6950" data-name="Path 6950" d="M377.581,372.166a3.665,3.665,0,0,0-.373-1.116l-5.378,4.77a3.666,3.666,0,0,0,1.064.5Z" transform="translate(-370.25 -369.169)" fill="#ffeea4"/>
              <path id="Path_6951" data-name="Path 6951" d="M367.815,360.87l-4.535,4.023a3.693,3.693,0,0,0,1.138,2.273l5.516-4.893A3.686,3.686,0,0,0,367.815,360.87Z" transform="translate(-363.28 -360.87)" fill="#ffeea4"/>
            </g>
          </g>
        </g>
        <path id="Path_6952" data-name="Path 6952" d="M376.111,365.006h0a.277.277,0,0,0-.261-.294l-3.611-.214a.277.277,0,0,0-.294.261h0a.277.277,0,0,0,.261.294l.979.059a1.307,1.307,0,0,1,1.173.854l-2.2-.131a.277.277,0,0,0-.294.261h0a.277.277,0,0,0,.261.294l2.273.135a1.274,1.274,0,0,1-1.358,1l-.979-.059a.277.277,0,0,0-.294.261h0a.277.277,0,0,0,.261.294l.6.035,1.66,2.526a.338.338,0,0,0,.259.15h0a.332.332,0,0,0,.3-.514l-1.388-2.114,2.186.129a.277.277,0,0,0,.294-.261h0a.277.277,0,0,0-.261-.294l-.97-.057a1.859,1.859,0,0,0,.508-1.046l.525.031a.277.277,0,0,0,.294-.261h0a.277.277,0,0,0-.261-.294l-.571-.033a1.887,1.887,0,0,0-.353-.8l.97.057A.279.279,0,0,0,376.111,365.006Z" transform="translate(-366.766 -360.325)" fill="#aa7921"/>
      </g>
      <path id="Path_6953" data-name="Path 6953" d="M350.632,353.5a6.017,6.017,0,0,0,2.924,5.527c.028-.031.057-.061.085-.092l.028-.031c.059-.07.116-.14.174-.211l.046-.057c.055-.068.107-.139.161-.209l.05-.066c.052-.07.1-.14.153-.213.017-.022.033-.046.048-.07q.075-.108.15-.222c.013-.02.026-.041.041-.061.052-.079.1-.159.152-.238.007-.011.013-.024.02-.035.111-.183.218-.37.322-.562q.092-.172.177-.349c.046-.094.089-.189.133-.285,0,0,0-.006,0-.007.043-.092.081-.187.122-.281.009-.022.018-.043.028-.063l.039-.092c.024-.057.044-.116.068-.176.011-.028.022-.055.031-.083.033-.089.067-.177.1-.268.011-.03.02-.061.031-.091.031-.091.061-.181.091-.274l.028-.089c.03-.1.059-.192.087-.288.007-.022.013-.046.018-.068.024-.081.046-.165.068-.248.074-.279.139-.564.2-.856.03-.146.057-.294.081-.445.05-.3.094-.608.129-.922s.063-.638.083-.968q.017-.288.022-.577c0-.061,0-.124,0-.185,0-.133,0-.266,0-.4,0-.07,0-.142-.006-.213,0-.129-.011-.259-.018-.388,0-.068-.007-.137-.013-.205-.011-.159-.026-.32-.043-.481,0-.042-.007-.085-.013-.126-.02-.181-.043-.362-.068-.545-.006-.044-.013-.091-.02-.135A6.014,6.014,0,0,0,350.632,353.5Z" transform="translate(-306.631 -290.837)" fill="#554c3d" opacity="0.32"/>
      <g id="Group_11047" data-name="Group 11047" transform="translate(21.898 1.544)">
        <path id="Path_6954" data-name="Path 6954" d="M238.987,72.1l-7.261-4.5a1.371,1.371,0,0,1-.444-1.887l10.761-17.369a1.371,1.371,0,0,1,1.887-.444l7.261,4.5a1.371,1.371,0,0,1,.444,1.887L240.874,71.657A1.37,1.37,0,0,1,238.987,72.1Z" transform="translate(-231.077 -47.697)" fill="#efc2ec"/>
        <path id="Path_6955" data-name="Path 6955" d="M253.756,56.734,243.1,73.926a.914.914,0,0,1-1.257.3l-7.083-4.389a.914.914,0,0,1-.3-1.257L245.12,51.384a.914.914,0,0,1,1.257-.3l7.083,4.389A.912.912,0,0,1,253.756,56.734Z" transform="translate(-233.731 -50.351)" fill="#d292cc"/>
        <path id="Path_6956" data-name="Path 6956" d="M269.115,51.905,263.9,60.323l8.636,5.35,5.217-8.419a1.121,1.121,0,0,0-.362-1.543l-6.73-4.169A1.123,1.123,0,0,0,269.115,51.905Z" transform="translate(-257.834 -50.694)" fill="#a0679c"/>
        <circle id="Ellipse_1745" data-name="Ellipse 1745" cx="4.688" cy="4.688" r="4.688" transform="translate(4.731 8.833) rotate(-13.433)" fill="#efc2ec"/>
        <path id="Path_6957" data-name="Path 6957" d="M269.808,95.782h0a.3.3,0,0,0-.414.1l-2.062,3.33a.3.3,0,0,0,.1.414h0a.3.3,0,0,0,.414-.1l.56-.9a1.415,1.415,0,0,1,1.416-.678l-1.257,2.027a.3.3,0,0,0,.1.414h0a.3.3,0,0,0,.414-.1l1.3-2.1a1.377,1.377,0,0,1,.249,1.805l-.56.9a.3.3,0,0,0,.1.414h0a.3.3,0,0,0,.414-.1l.342-.553,3.262-.281a.362.362,0,0,0,.275-.168h0a.36.36,0,0,0-.336-.547l-2.728.235,1.249-2.016a.3.3,0,0,0-.1-.414h0a.3.3,0,0,0-.414.1l-.554.894a2.032,2.032,0,0,0-.734-1.024l.3-.484a.3.3,0,0,0-.1-.414h0a.3.3,0,0,0-.414.1l-.325.527a2.038,2.038,0,0,0-.948-.078l.554-.894A.3.3,0,0,0,269.808,95.782Z" transform="translate(-260.595 -86.859)" fill="#604260"/>
        <path id="Path_6958" data-name="Path 6958" d="M236.08,135.3l-1.151,1.859a1.121,1.121,0,0,0,.362,1.543l1.87,1.159A3.383,3.383,0,0,0,236.08,135.3Z" transform="translate(-234.08 -119.111)" fill="#604260"/>
        <path id="Path_6959" data-name="Path 6959" d="M263.97,163.235l1.859,1.151a1.121,1.121,0,0,0,1.543-.362l1.159-1.87A3.384,3.384,0,0,0,263.97,163.235Z" transform="translate(-257.891 -140.62)" fill="#604260"/>
        <path id="Path_6960" data-name="Path 6960" d="M325.781,73.131l1.151-1.859a1.121,1.121,0,0,0-.362-1.543L324.7,68.57A3.382,3.382,0,0,0,325.781,73.131Z" transform="translate(-307.016 -64.713)" fill="#604260"/>
        <path id="Path_6961" data-name="Path 6961" d="M290.431,52.67l-1.859-1.151a1.121,1.121,0,0,0-1.543.362l-1.159,1.87A3.386,3.386,0,0,0,290.431,52.67Z" transform="translate(-275.744 -50.675)" fill="#604260"/>
      </g>
      <g id="Group_11048" data-name="Group 11048" transform="translate(4.87 1.786)">
        <path id="Path_6962" data-name="Path 6962" d="M160.409,67.613l-6.751,5.23a1.371,1.371,0,0,1-1.924-.244L139.222,56.446a1.371,1.371,0,0,1,.244-1.924l6.753-5.23a1.371,1.371,0,0,1,1.924.244l12.513,16.153A1.373,1.373,0,0,1,160.409,67.613Z" transform="translate(-138.935 -49.005)" fill="#efcc80"/>
        <path id="Path_6963" data-name="Path 6963" d="M150.435,52.628l12.385,15.989a.912.912,0,0,1-.163,1.281L156.072,75a.912.912,0,0,1-1.281-.163L142.406,58.849a.912.912,0,0,1,.163-1.281l6.586-5.1A.913.913,0,0,1,150.435,52.628Z" transform="translate(-141.608 -51.67)" fill="#d2ae5d"/>
        <path id="Path_6964" data-name="Path 6964" d="M142.893,59.384l6.065,7.83,8.03-6.22-6.065-7.83a1.121,1.121,0,0,0-1.573-.2l-6.259,4.847A1.122,1.122,0,0,0,142.893,59.384Z" transform="translate(-141.97 -52.041)" fill="#a0803b"/>
        <circle id="Ellipse_1746" data-name="Ellipse 1746" cx="4.688" cy="4.688" r="4.688" transform="translate(4.403 12.688) rotate(-50.401)" fill="#efcc80"/>
        <path id="Path_6965" data-name="Path 6965" d="M178.606,100.838h0a.3.3,0,0,0-.054.421l2.4,3.1a.3.3,0,0,0,.421.054h0a.3.3,0,0,0,.054-.421l-.65-.839a1.411,1.411,0,0,1-.14-1.563l1.46,1.885a.3.3,0,0,0,.421.054h0a.3.3,0,0,0,.054-.421l-1.51-1.95a1.378,1.378,0,0,1,1.78.4l.65.839a.3.3,0,0,0,.421.054h0a.3.3,0,0,0,.054-.421l-.4-.514.878-3.155a.359.359,0,0,0-.063-.316h0a.36.36,0,0,0-.63.124l-.734,2.639-1.453-1.874a.3.3,0,0,0-.421-.054h0a.3.3,0,0,0-.054.421l.645.832a2.02,2.02,0,0,0-1.216.331l-.349-.449a.3.3,0,0,0-.421-.054h0a.3.3,0,0,0-.054.421l.379.49a2.031,2.031,0,0,0-.4.861l-.645-.832A.3.3,0,0,0,178.606,100.838Z" transform="translate(-171.18 -88.862)" fill="#785f3a"/>
        <path id="Path_6966" data-name="Path 6966" d="M202.32,161.161l1.34,1.73a1.121,1.121,0,0,0,1.573.2l1.739-1.347A3.384,3.384,0,0,0,202.32,161.161Z" transform="translate(-190.606 -139.887)" fill="#785f3a"/>
        <path id="Path_6967" data-name="Path 6967" d="M239.651,135.081l1.73-1.34a1.121,1.121,0,0,0,.2-1.573l-1.347-1.739A3.385,3.385,0,0,0,239.651,135.081Z" transform="translate(-220.492 -115.383)" fill="#785f3a"/>
        <path id="Path_6968" data-name="Path 6968" d="M174.111,54.883l-1.34-1.73a1.121,1.121,0,0,0-1.573-.2L169.46,54.3A3.383,3.383,0,0,0,174.111,54.883Z" transform="translate(-163.819 -52.033)" fill="#785f3a"/>
        <path id="Path_6969" data-name="Path 6969" d="M144.8,72.96l-1.73,1.34a1.121,1.121,0,0,0-.2,1.573l1.347,1.739A3.385,3.385,0,0,0,144.8,72.96Z" transform="translate(-141.955 -68.533)" fill="#785f3a"/>
      </g>
      <g id="Group_11049" data-name="Group 11049" transform="translate(18.155)">
        <path id="Path_6970" data-name="Path 6970" d="M220.742,62.514l-8.541,0a1.37,1.37,0,0,1-1.371-1.369l-.009-20.433a1.37,1.37,0,0,1,1.369-1.371l8.541,0a1.37,1.37,0,0,1,1.371,1.369l.009,20.433A1.37,1.37,0,0,1,220.742,62.514Z" transform="translate(-210.82 -39.34)" fill="#bdefdc"/>
        <path id="Path_6971" data-name="Path 6971" d="M224.018,43.293l.009,20.224a.913.913,0,0,1-.913.913l-8.333,0a.913.913,0,0,1-.913-.913L213.86,43.3a.913.913,0,0,1,.913-.913l8.333,0A.913.913,0,0,1,224.018,43.293Z" transform="translate(-213.298 -41.818)" fill="#8ed2bb"/>
        <path id="Path_6972" data-name="Path 6972" d="M213.87,43.514l0,9.9,10.158,0,0-9.9a1.121,1.121,0,0,0-1.122-1.12l-7.917,0A1.117,1.117,0,0,0,213.87,43.514Z" transform="translate(-213.306 -41.826)" fill="#64a08a"/>
        <circle id="Ellipse_1747" data-name="Ellipse 1747" cx="4.688" cy="4.688" r="4.688" transform="translate(0.957 6.9)" fill="#bdefdc"/>
        <path id="Path_6973" data-name="Path 6973" d="M225.021,89.822h0a.3.3,0,0,0-.3.3l0,3.916a.3.3,0,0,0,.3.3h0a.3.3,0,0,0,.3-.3V92.976a1.414,1.414,0,0,1,.846-1.323l0,2.384a.3.3,0,0,0,.3.3h0a.3.3,0,0,0,.3-.3l0-2.465a1.375,1.375,0,0,1,1.164,1.4v1.063a.3.3,0,0,0,.3.3h0a.3.3,0,0,0,.3-.3v-.65l2.624-1.957a.362.362,0,0,0,.144-.288h0a.358.358,0,0,0-.573-.286l-2.2,1.637,0-2.371a.3.3,0,0,0-.3-.3h0a.3.3,0,0,0-.3.3v1.052a2.021,2.021,0,0,0-1.162-.482v-.569a.3.3,0,0,0-.3-.3h0a.3.3,0,0,0-.3.3v.619a2.033,2.033,0,0,0-.846.434V90.121A.3.3,0,0,0,225.021,89.822Z" transform="translate(-222.151 -80.491)" fill="#406052"/>
        <path id="Path_6974" data-name="Path 6974" d="M213.91,143.82l0,2.186a1.121,1.121,0,0,0,1.122,1.12l2.2,0A3.385,3.385,0,0,0,213.91,143.82Z" transform="translate(-213.339 -124.512)" fill="#406052"/>
        <path id="Path_6975" data-name="Path 6975" d="M250.98,147.083l2.186,0a1.121,1.121,0,0,0,1.12-1.122l0-2.2A3.384,3.384,0,0,0,250.98,147.083Z" transform="translate(-243.558 -124.463)" fill="#406052"/>
        <path id="Path_6976" data-name="Path 6976" d="M254.183,45.7l0-2.186a1.121,1.121,0,0,0-1.122-1.12l-2.2,0A3.384,3.384,0,0,0,254.183,45.7Z" transform="translate(-243.461 -41.826)" fill="#406052"/>
        <path id="Path_6977" data-name="Path 6977" d="M217.176,42.36l-2.186,0a1.121,1.121,0,0,0-1.12,1.122l0,2.2A3.384,3.384,0,0,0,217.176,42.36Z" transform="translate(-213.306 -41.802)" fill="#406052"/>
      </g>
      <g id="Group_11053" data-name="Group 11053" transform="translate(0 16.218)">
        <path id="Path_6978" data-name="Path 6978" d="M147.681,138.441l3.91-5.72a3.594,3.594,0,0,0-2.968-5.622h-23.24a3.594,3.594,0,0,0-2.968,5.622l3.951,5.782a3.593,3.593,0,0,1-.715,4.827c-6.8,5.477-13.071,14.483-13.071,22.987,0,13.452,10.905,17.512,24.357,17.512s24.357-4.058,24.357-17.512c0-8.534-6.089-17.574-12.9-23.046A3.6,3.6,0,0,1,147.681,138.441Z" transform="translate(-112.58 -127.1)" fill="#d0b170"/>
        <path id="Path_6979" data-name="Path 6979" d="M148.5,156.786a3.592,3.592,0,0,1-.717-4.829l3.91-5.72c.018-.028.035-.057.054-.085-3.177,2.473-8.006,3.883-15.34,2.779-6.884-1.037-11.456-2.994-14.479-5.252a3.537,3.537,0,0,0,.591,2.558l3.951,5.782a3.589,3.589,0,0,1,.177,3.763c6.906-1.009,23.5-2.558,27.7,18.219,3.846,19.057-33.438,22.03-41.643,6.754.49,12.716,11.2,16.589,24.338,16.589,13.452,0,24.357-4.058,24.357-17.512C161.393,171.3,155.306,162.258,148.5,156.786Z" transform="translate(-112.678 -140.616)" fill="#c19e61"/>
        <path id="Path_6980" data-name="Path 6980" d="M216.072,263.47v-2.21h-16.6v2.21h5.008a5.189,5.189,0,0,1,4.858,3.11H199.47v2.21h10.166a5.06,5.06,0,0,1-5.158,4.274H199.47v2.21h3.493l7.584,10.177,3.288,0-7.582-10.171h9.818v-2.21H211.1a7.422,7.422,0,0,0,1.774-4.274h3.2v-2.21h-3.378a7.49,7.49,0,0,0-1.595-3.11h4.975Z" transform="translate(-183.413 -236.467)" fill="#554c3d"/>
        <path id="Path_6981" data-name="Path 6981" d="M165.3,294.026a23.765,23.765,0,0,0-2.6-10.266c2.868,20.533-25.231,33.037-45.206,16.331,2.875,8.647,12.284,11.447,23.451,11.447C154.393,311.535,165.3,307.477,165.3,294.026Z" transform="translate(-116.583 -254.809)" fill="#af8c54"/>
        <path id="Path_6982" data-name="Path 6982" d="M203.587,181.533l.336-.492a38.961,38.961,0,0,1-22.764-1.081l1.116,1.634a3.573,3.573,0,0,1,.625,1.924,67.268,67.268,0,0,1,21.173,2.637A3.591,3.591,0,0,1,203.587,181.533Z" transform="translate(-168.486 -170.191)" fill="#af8c54"/>
        <path id="Path_6983" data-name="Path 6983" d="M130.51,239.587s2.3-10.382,11.672-16.157Z" transform="translate(-127.197 -205.628)" fill="#e8c589"/>
        <g id="Group_11050" data-name="Group 11050" transform="translate(12.507 11.741)">
          <path id="Path_6984" data-name="Path 6984" d="M181.356,193.423a1.1,1.1,0,0,1-.159-2.186,95.484,95.484,0,0,1,21.836-.006,1.1,1.1,0,1,1-.231,2.186,95.292,95.292,0,0,0-21.287-.006A1.128,1.128,0,0,1,181.356,193.423Z" transform="translate(-180.258 -190.633)" fill="#554c3d"/>
        </g>
        <path id="Path_6985" data-name="Path 6985" d="M180.31,197.687a.063.063,0,0,0,0,.018,1.1,1.1,0,0,0,1.087.941,1.194,1.194,0,0,0,.161-.011,95.291,95.291,0,0,1,21.287.006,1.1,1.1,0,0,0,1.2-.95A105.23,105.23,0,0,0,180.31,197.687Z" transform="translate(-167.794 -184.115)" fill="#3f3b34"/>
        <g id="Group_11051" data-name="Group 11051" transform="translate(13.062 11.74)">
          <path id="Path_6986" data-name="Path 6986" d="M277.71,193.925l.558.035v-2.2c-.187-.013-.373-.024-.558-.035Z" transform="translate(-260.256 -191.519)" fill="#685d4e"/>
          <path id="Path_6987" data-name="Path 6987" d="M270.97,193.545c.185.009.371.018.558.03v-2.2c-.187-.011-.373-.02-.558-.03Z" transform="translate(-254.761 -191.209)" fill="#685d4e"/>
          <path id="Path_6988" data-name="Path 6988" d="M257.48,193.035l.558.017v-2.2l-.558-.017Z" transform="translate(-243.764 -190.793)" fill="#685d4e"/>
          <path id="Path_6989" data-name="Path 6989" d="M264.22,193.263l.558.022v-2.2l-.558-.022Z" transform="translate(-249.259 -190.981)" fill="#685d4e"/>
          <path id="Path_6990" data-name="Path 6990" d="M297.95,195.51l.558.055v-2.21l-.558-.055Z" transform="translate(-276.755 -192.807)" fill="#685d4e"/>
          <path id="Path_6991" data-name="Path 6991" d="M304.7,194.12v2.092a1.1,1.1,0,0,0,.558-.408V194.53A1.089,1.089,0,0,0,304.7,194.12Z" transform="translate(-282.258 -193.475)" fill="#685d4e"/>
          <path id="Path_6992" data-name="Path 6992" d="M250.73,192.9c.185,0,.37.006.558.009v-2.2c-.188,0-.373-.007-.558-.009Z" transform="translate(-238.262 -190.687)" fill="#685d4e"/>
          <path id="Path_6993" data-name="Path 6993" d="M284.46,194.375c.185.013.371.028.558.043v-2.2l-.558-.042Z" transform="translate(-265.758 -191.885)" fill="#685d4e"/>
          <path id="Path_6994" data-name="Path 6994" d="M291.21,194.915c.185.017.371.031.558.05v-2.207c-.187-.017-.373-.033-.558-.048Z" transform="translate(-271.261 -192.326)" fill="#685d4e"/>
          <path id="Path_6995" data-name="Path 6995" d="M223.74,193.067l.558-.018V190.84l-.558.017Z" transform="translate(-216.259 -190.801)" fill="#685d4e"/>
          <path id="Path_6996" data-name="Path 6996" d="M196.76,194.6c.174-.017.36-.033.558-.05V192.34l-.558.05Z" transform="translate(-194.265 -192.024)" fill="#685d4e"/>
          <path id="Path_6997" data-name="Path 6997" d="M190.01,195.247c.155-.018.342-.039.558-.063V192.97l-.558.061Z" transform="translate(-188.763 -192.538)" fill="#685d4e"/>
          <path id="Path_6998" data-name="Path 6998" d="M183.26,193.927v1.907a1.1,1.1,0,0,0,.543.146l.015,0V193.77l-.174.024A1.092,1.092,0,0,0,183.26,193.927Z" transform="translate(-183.26 -193.19)" fill="#685d4e"/>
          <path id="Path_6999" data-name="Path 6999" d="M203.5,194.073c.179-.013.366-.028.558-.041V191.82c-.192.013-.379.028-.558.041Z" transform="translate(-199.76 -191.6)" fill="#685d4e"/>
          <path id="Path_7000" data-name="Path 7000" d="M243.98,192.845c.185,0,.37,0,.558,0v-2.2l-.558,0Z" transform="translate(-232.759 -190.638)" fill="#685d4e"/>
          <path id="Path_7001" data-name="Path 7001" d="M230.49,192.918l.558-.011V190.7c-.189,0-.375.006-.558.011Z" transform="translate(-221.762 -190.687)" fill="#685d4e"/>
          <path id="Path_7002" data-name="Path 7002" d="M210.25,193.643l.558-.033V191.4l-.558.031Z" transform="translate(-205.262 -191.258)" fill="#685d4e"/>
          <path id="Path_7003" data-name="Path 7003" d="M237.24,192.84c.183,0,.37,0,.558,0V190.63c-.189,0-.375,0-.558,0Z" transform="translate(-227.264 -190.63)" fill="#685d4e"/>
          <path id="Path_7004" data-name="Path 7004" d="M217,193.314l.558-.026V191.08L217,191.1Z" transform="translate(-210.765 -190.997)" fill="#685d4e"/>
        </g>
        <path id="Path_7005" data-name="Path 7005" d="M295.1,199.78s1.552,5.866,5.405,9.314" transform="translate(-261.37 -186.349)" fill="none" stroke="#554c3d" stroke-linecap="round" stroke-miterlimit="10" stroke-width="13"/>
        <path id="Path_7006" data-name="Path 7006" d="M284.831,198.19a18.685,18.685,0,0,0,1.238,8.573" transform="translate(-252.929 -185.053)" fill="none" stroke="#554c3d" stroke-linecap="round" stroke-miterlimit="10" stroke-width="13"/>
        <g id="Group_11052" data-name="Group 11052" transform="translate(29.289 9.578)">
          <circle id="Ellipse_1748" data-name="Ellipse 1748" cx="2.517" cy="2.517" r="2.517" transform="translate(0 3.56) rotate(-45)" fill="#554c3d"/>
          <path id="Path_7007" data-name="Path 7007" d="M279.237,197.577a2.517,2.517,0,0,1-2.469-2.027,2.517,2.517,0,1,0,4.938,0A2.52,2.52,0,0,1,279.237,197.577Z" transform="translate(-275.676 -192.478)" fill="#3f3b34"/>
          <ellipse id="Ellipse_1749" data-name="Ellipse 1749" cx="1.56" cy="0.979" rx="1.56" ry="0.979" transform="translate(2.001 1.603)" fill="#685d4e"/>
        </g>
        <path id="Path_7008" data-name="Path 7008" d="M191.6,360.91c-3.454,6.682-11.378,12.119-23.353,12.119-8.931,0-14.583-1.192-18.048-2.436,4.42,2.787,10.585,3.835,17.408,3.835C179.624,374.428,189.6,371.189,191.6,360.91Z" transform="translate(-143.248 -317.702)" fill="#937344"/>
        <path id="Path_7009" data-name="Path 7009" d="M186.764,130.38H208.5a.1.1,0,0,1,.015.188,80.028,80.028,0,0,1-21.769,0A.1.1,0,0,1,186.764,130.38Z" transform="translate(-172.978 -129.774)" fill="#e8c589"/>
      </g>
      <g id="Group_11057" data-name="Group 11057" transform="translate(36.185 56.737)">
        <g id="Group_11056" data-name="Group 11056" transform="translate(0 0)">
          <circle id="Ellipse_1750" data-name="Ellipse 1750" cx="6.017" cy="6.017" r="6.017" transform="translate(0 8.509) rotate(-45)" fill="#dba32c"/>
          <circle id="Ellipse_1751" data-name="Ellipse 1751" cx="4.456" cy="4.456" r="4.456" transform="translate(2.767 5.911) rotate(-20.622)" fill="#edcb3a"/>
          <g id="Group_11055" data-name="Group 11055" transform="translate(4.835 4.909)">
            <g id="Group_11054" data-name="Group 11054">
              <path id="Path_7010" data-name="Path 7010" d="M348.841,384.216a3.666,3.666,0,0,0-.373-1.116l-5.378,4.77a3.666,3.666,0,0,0,1.064.5Z" transform="translate(-341.512 -381.219)" fill="#ffeea4"/>
              <path id="Path_7011" data-name="Path 7011" d="M339.085,372.92l-4.535,4.023a3.693,3.693,0,0,0,1.138,2.273l5.516-4.893A3.691,3.691,0,0,0,339.085,372.92Z" transform="translate(-334.55 -372.92)" fill="#ffeea4"/>
            </g>
          </g>
        </g>
        <path id="Path_7012" data-name="Path 7012" d="M347.371,377.056h0a.277.277,0,0,0-.261-.294l-3.611-.214a.277.277,0,0,0-.294.261h0a.277.277,0,0,0,.261.294l.979.059a1.307,1.307,0,0,1,1.173.854l-2.2-.131a.277.277,0,0,0-.294.261h0a.277.277,0,0,0,.261.294l2.273.135a1.274,1.274,0,0,1-1.358,1l-.979-.059a.277.277,0,0,0-.294.261h0a.277.277,0,0,0,.261.294l.6.035,1.66,2.526a.338.338,0,0,0,.259.15h0a.332.332,0,0,0,.3-.514l-1.388-2.114,2.186.129a.277.277,0,0,0,.294-.261h0a.277.277,0,0,0-.261-.294l-.97-.057a1.859,1.859,0,0,0,.508-1.046l.525.031a.277.277,0,0,0,.294-.261h0a.277.277,0,0,0-.261-.294l-.571-.033a1.887,1.887,0,0,0-.353-.8l.97.057A.278.278,0,0,0,347.371,377.056Z" transform="translate(-336.626 -370.968)" fill="#aa7921"/>
      </g>
      <path id="Path_7013" data-name="Path 7013" d="M390.828,346.159a1.3,1.3,0,1,1,.155-2.589,1.3,1.3,0,1,1-.155,2.589Z" transform="translate(-338.399 -287.348)" fill="#fff"/>
      <path id="Path_7014" data-name="Path 7014" d="M328.358,360.269a1.3,1.3,0,1,1,.155-2.589,1.3,1.3,0,1,1-.155,2.589Z" transform="translate(-287.473 -298.851)" fill="#fff"/>
      <path id="Path_7015" data-name="Path 7015" d="M376.458,404.839a1.3,1.3,0,1,1,.155-2.589,1.3,1.3,0,1,1-.155,2.589Z" transform="translate(-326.684 -335.184)" fill="#fff"/>
    </g>
  </g>
</svg>
<p className='text-white lg:text-center text-base'>Earn more by simply <br></br><span>referring your friends and family</span></p>
</div>
<div className='flex justify-center my-3'>
<svg xmlns="http://www.w3.org/2000/svg" width="324" height="1" viewBox="0 0 324 1">
  <line id="Line_1300" data-name="Line 1300" x2="323" transform="translate(0.5 0.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" opacity="0.2"/>
</svg>
</div>

<div className=' flex flex-col ml-4 mr-4 mb-3 lg:mb-2 '>
    <p className='text-white text-base font-semibold'>View Past Referrals</p>
    <div className='flex  justify-between'>
    <p className='text-white text-sm mt-1'> Track your ongoing enquiries<br></br> and claim your rewards</p>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" onClick={handleClick} className='cursor-pointer'>
  <g id="Group_10830" data-name="Group 10830" transform="translate(-1280 -424)">
    <rect id="Rectangle_17038" data-name="Rectangle 17038" width="30" height="30" rx="5" transform="translate(1280 424)" fill="#fff" opacity="0.2"/>
    <path id="_8666777_chevron_right_arrow_icon" data-name="8666777_chevron_right_arrow_icon" d="M0,0,5.5,5.5,0,11" transform="translate(1292.5 433.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
  </g>
</svg>

    </div>
   
</div>

</div>
                   </div>
                            {config.projects.moduleConfig?.hideProjects && <div className='text-center py-14 text-primary font-bold text-2xl'>Share the Love, Earn Rewards!
                            <p className='text-black font-semibold text-xl'>Invite friends to join our community and earn rewards together!</p></div>}
                            <div className="relative ">

                                <div className=" py-8 md:py-16 px-6 md:px-14 2xl:px-28  ">
                                    <ReferralFormMain
                                        referralConfig={config?.referralForm}
                                        enquiryCities={cities}
                                    />
                                    <div className=' hidden lg:absolute lg:flex lg:right-4 lg:top-6'>
                                        <p className=" text-white text-xs xl:text-sm mr-2 justify-between self-end">
                                            Powered by
                                        </p>
                                        <div>
                                            <img
                                                className="h-5 lg:h-5 xl:h-7 self-center"
                                                src="https://cdn.loyalie.in/28602817.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <style>
                                {`
                    
                                  .parallax-wrap {
                                    position: relative;
                                    width: 100%;
                                    height: 100vh;
                                    overflow: hidden;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                  }

                                  .parallax-wrap1 {
                                    position: relative;
                                    width: 100%;
                                    height: 50vh;
                                    overflow: hidden;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                  }
                                        
                                `}
                            </style>
                        </section>
                    </div>
                </>
            )}
        </section>
    );
}

export default ReferralPage;
