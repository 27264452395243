import { getFont } from '../../helpers';
import { openURLNewWindow } from '../../helpers';
import store from '../../store';
const KeeclubConfig = {
    clientId:
        '975fa9cb66b0d5d89cea9e33ecfa3a7438d368695e6addce57816a13c4b4e570',
    id: 2,
    name: 'Piramal',
    fullName: 'Piramal Realty',
    programName:'Kee Club',
    theme: 'KCTheme',
    darktheme: true,
    favicon: 'https://cdn.loyalie.in/77823820.png',
    setSolidBg: 'bg-white',
    primaryColor: '#FF4713',
    fontBody: `${getFont('KC').body}`,
    fontTitle: `${getFont('KC').heading}`,
    googleConfig: {
        gID: 'G-PS923YR4K0',
        keywordsContent:
            'Kee Club, piramal, Piramal Group, Piramal Capital and Housing Finance Limited, Piramal Realty, Piramal Foundation, PGP Glass',
        descriptionContent:
            'Kee Club is an exclusive loyalty and referral program for the entire Piramal family. Every member is a brand ambassador in our vision of adding more value.',
        authorContent: 'Kee Club'
    },

    facebookPixelConfig: {
        id: '678667701101741',
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~76aa298',
    webengageAPIKey:'2e155f2e-b5f8-456c-9693-9028029accfa',
    uatFacebookPixelConfig: {
        id: ' ',
        metaName: '',
        metaContent: ''
    },
    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: true,

    logoLeft: {
        name: 'Keeclub Logo',
        url: 'https://piramaluat.s3.ap-south-1.amazonaws.com/Website/Uploads/Piramal/Images/4192015.png',
        stickyNavBarLogoClass: 'relative w-20 ml-4 lg:ml-7 3xl:ml-10', //to match godrej nav bar
        baseNavBarLogoClass:
            'relative w-20 lg:w-28 left-4 lg:left-7 3xl:left-10 lg:top-10 '
    },

    navBarConfig: {
        showLogin: true,

        font: `${getFont('KC').heading}`,
        height: 'h-20',
        mobileIconColor: 'text-white',
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-primary-content lg:bg-transparent', // default: transparent
            containerClass: 'lg:mr-4 xl:mr-7 3xl:mr-10',
            itemClass:
                'uppercase text-white text-[14px] lg:mt-16 lg:text-white hover:text-accent  lg:text-[14px] xl:text-[14px] 3xl:text-lg lg:mr-6 xl:mr-5'
        },
        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: '', //default: primary-content,
            containerClass: ' lg:mr-4 xl:mr-7 3xl:mr-10', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'text-white text-[14px] lg:text-white hover:text-accent  lg:text-[14px] xl:text-[14px] 3xl:text-lg lg:mr-6 xl:mr-5 ' // Each list element in NavBar (Text color, hover color, size)
        },
        showActiveLinkColor: false,
        footerNavItemClass:
            'font-Montserrat text-[12px] lg:text-[14px] xl:text-[15px]  hover:text-accent'
    },
    floatingButton: true,
    floaterClass: 'bg-primary hover:bg-primary-content text-white',
    floaterType: 'REFERRAL',
    bannerNegativeTopMarginClass: 'lg:-mt-20', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 80,

    clientButtonClassName: `z-10 bg-accent rounded-sm text-[16px] py-2 px-10 self-center text-white hover:bg-accent-focus ${
        getFont('KC').heading
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },
    booklet: true,

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,
    webviewRoutes: {
        home: {
            name: 'OVERVIEW',
            path: '/',
            show: true
        },
        events: {
            show: true,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        }
    },
    webview: {
        customIcon: {
            refer: 'https://cdn.loyalie.in/1696584835374_recer icon 3',
            offer: 'https://cdn.loyalie.in/1696584853344_offer icon',
            blogs: 'https://cdn.loyalie.in/1696584975826_blogs icon',
            more: 'https://cdn.loyalie.in/1696584989277_more icon'
        },
        textStyle: `${getFont('KC').heading} uppercase font-medium`,
        iconColor: `white`,
        initialsClass: 'text-white'
    },
    routes: {
        home: {
            name: 'Home',
            path: '/',
            show: true,
            components: [
                {
                    type: 'SLIDER_BANNER',
                    classWeb:
                        'hidden lg:block h-screen lg:h-[110vh] w-full object-cover -mt-20  ',
                    slides: [
                        {
                            id: 0,
                            text: '',
                            displayImage: {
                                url: 'https://cdn.loyalie.in/63942134.jpg',
                                mobileUrl:
                                    'https://piramalprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Piramal/Images/6925640.jpg'
                            },
                            linearGradient: {
                                R1: '18',
                                G1: '44',
                                B1: '59',
                                R2: '255',
                                G2: '71',
                                B2: '19',
                                opacity1: '0.50',
                                opacity2: '0.3',
                                deg: '100deg'
                            },
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        ' w-full text-center text-white md:pt-20 md:pt-16 z-10',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Homes are meant to be more,',
                                            className: `uppercase leading-5 text-[24px] md:text-[45px] mb-3 ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Because every apartment becomes a world by itself.',
                                            className: ` md:tracking-widest text-[14px] md:text-[22px] mb-5 pb-7 ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content: 'KEE CLUB',
                                            className: ` tracking-widest text-[44px] md:text-[65px] font-semibold ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'A joint initiative by Piramal Realty and Reloy ',
                                            className: `leading-3 text-[12px] md:text-[15px] mb-6 ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'Refer Now',
                                            path: 'gift-and-earn',
                                            onClick: 'CHANGE_PATH'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: 1,
                            linearGradient: {
                                R1: '18',
                                G1: '44',
                                B1: '59',
                                R2: '255',
                                G2: '71',
                                B2: '19',
                                opacity1: '0.50',
                                opacity2: '0.3',
                                deg: '100deg'
                            },
                            displayImage: {
                                url: 'https://cdn.loyalie.in/19307095.jpg',
                                mobileUrl:
                                    'https://piramalprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Piramal/Images/46254646.jpg'
                            },
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        ' w-full text-center text-white md:pt-20 md:pt-16 z-10',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Homes are meant to be more,',
                                            className: `uppercase leading-5 text-[24px] md:text-[45px] mb-3 ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Because every apartment becomes a world by itself.',
                                            className: ` md:tracking-widest text-[14px] md:text-[22px] mb-5 pb-7 ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content: 'KEE CLUB',
                                            className: ` tracking-widest text-[44px] md:text-[65px] font-semibold ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'A joint initiative by Piramal Realty and Reloy ',
                                            className: `leading-3 text-[12px] md:text-[15px] mb-6 ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'Refer Now',
                                            path: 'gift-and-earn',
                                            onClick: 'CHANGE_PATH'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: 2,
                            linearGradient: {
                                R1: '18',
                                G1: '44',
                                B1: '59',
                                R2: '255',
                                G2: '71',
                                B2: '19',
                                opacity1: '0.45',
                                opacity2: '0.4',
                                deg: '100deg'
                            },

                            displayImage: {
                                url: 'https://cdn.loyalie.in/1694512493531_Banner home',
                                mobileUrl:
                                    'https://piramalprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Piramal/Images/34036674.jpg'
                            },
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        ' w-full text-center text-white md:pt-20 md:pt-16 z-10',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Homes are meant to be more,',
                                            className: `uppercase leading-5 text-[24px] md:text-[45px] mb-3 ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Because every apartment becomes a world by itself.',
                                            className: ` md:tracking-widest text-[14px] md:text-[22px] mb-5 pb-7 ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content: 'KEE CLUB',
                                            className: ` tracking-widest text-[44px] md:text-[65px] font-semibold ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'A joint initiative by Piramal Realty and Reloy ',
                                            className: `leading-3 text-[12px] md:text-[15px] mb-6 ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'Refer Now',
                                            path: 'gift-and-earn',
                                            onClick: 'CHANGE_PATH'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    className: `bg-[#F4F5F9]`,
                    components: [
                        {
                            type: 'CUSTOM',
                            url: 'https://cdn.loyalie.in/54950130.png',
                            linearGradient: {
                                R: '244',
                                G: '245',
                                B: '249',
                                opacity: '1',
                                size: '80% auto'
                            },
                            className:
                                'flex flex-col justify-center text-center pt-5 px-5 md:px-[40px] xl:px-[100px] mx-auto text-center break-words',
                            components: [
                                {
                                    type: 'TEXT',
                                    content:
                                        'Kee Club is an exclusive loyalty and referral program for the entire Piramal family. Every member is a brand ambassador in our vision of adding more value to homeownership and unlocking the Kee Club lifestyle.',
                                    className: ` pt-3 lg:pt-10 font-medium px-5 xl:px-0 text-[16px] md:text-[17px] xl:text-[20px] text-secondary leading-6 xl:leading-[26px]  xl:mb-5  ${
                                        getFont('KC').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'Buying a new home requires trust, assurance, quality, and care – all of which are an integral part of the Piramal brand. We know that our brand is only as strong as what our customers think of us. When you recommend us to people that you care about; you encourage us to do better. ',
                                    className: ` xl:px-12 text-[15px] md:text-[16px] xl:text-[17px] pt-10 text-secondary-focus leading-6 xl:leading-[26px]   ${
                                        getFont('KC').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'Our loyalty benefits are designed to help serve you better and cherish each and every member of the Piramal family through this program. It is our firm belief that the success of our brand rests on your happiness and Kee Club is created to pursue this idea with vigor.   ',
                                    className: ` xl:px-12 text-[15px] md:text-[16px] xl:text-[17px] pt-4 text-secondary-focus leading-6 xl:leading-[26px]   ${
                                        getFont('KC').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: 'Kee Club Unlocks:',
                                    className: ` text-[18px] xl:text-[20px] pt-10 pb-8 text-secondary font-medium leading-6 xl:leading-[27px]  ${
                                        getFont('KC').heading
                                    }`
                                }
                            ]
                        },

                        {
                            type: 'CUSTOM',
                            className:
                                'relative bg-[#F4F5F9] w-[90%] mx-auto rounded-xl drop-shadow-3xl grid lg:h-[70vh] xl:h-[6ovh] 2xl:h-[80vh] sm:grid-cols-1 lg:grid-cols-2 md:grid-cols-reverse',

                            components: [
                                {
                                    type: 'CHANGING_IMAGE',
                                    position: 'center',
                                    imageArr: [
                                        {
                                            url: 'https://cdn.loyalie.in/52489263.jpg'
                                        },
                                        {
                                            url: 'https://cdn.loyalie.in/58039376.jpg'
                                        }
                                    ],
                                    className: 'h-full w-full object-fill ',
                                    classNameContainer:
                                        'w-full h-full  order-first lg:order-none',
                                    swipeable: false,
                                    draggable: false,
                                    showDots: false,
                                    arrows: false,
                                    infinite: true,
                                    autoPlay: true,
                                    autoPlaySpeed: 3000,
                                    transitionDuration: 3000,
                                    customTransition: 'animate-fadeInAnimation'
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'px-5 xl:px-7 2xl:px-12 m-auto h-auto mb-4 lg:m-auto text-left py-5 md:py-10 lg:py-0 bg-[#F2F5FC] ',
                                    components: [
                                        {
                                            type: 'BUTTON',
                                            path: 'gift-and-earn',
                                            onClick: 'CHANGE_PATH',
                                            content: 'One Family Many Rewards',
                                            className: `text-primary-content mb-4 cursor-pointer tracking-wide text-[23px] md:text-[30px] lg:text-[20px] xl:text-[30px] ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            onClick: 'CHANGE_PATH',
                                            path: 'refer',
                                            content:
                                                'An exclusive referral campaign, curated just for you!',
                                            className: `text-primary-content leading-8 cursor-pointer tracking-wide text-[23px] md:text-[25px] lg:text-[20px] xl:text-[25px]  ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'DIVIDER',

                                            className:
                                                ' w-[20%] md:w-[16%] lg:w-[20%] xl:w-[19%] 2xl:w-[16%] bg-secondary border-none h-[2px]',
                                            classNameContainer: 'my-5'
                                        },

                                        {
                                            type: 'TEXT',
                                            content:
                                                'Piramal Realty knows what it means to build model communities that care for each other, and with One Family Many Rewards, we intend to reward every member who helps build the Piramal family. We bring you the opportunity to include your loved ones in the Piramal family and win exclusive rewards for yourselves and the referred basis various slabs. To know more, please refer to the attached e-brochures.',

                                            className: `text-secondary-focus text-[15px] leading-6 md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'Know More',
                                            className: `z-10 mt-5 bg-primary rounded-sm text-[16px] py-1 xl:py-2 px-5 xl:px-10 self-center text-white ${
                                                getFont('KC').heading
                                            }`,
                                            path: 'gift-and-earn',
                                            // scrollTo: 'referral_section',
                                            onClick: 'CHANGE_PATH'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'relative w-[90%] drop-shadow-3xl rounded-xl mx-auto mt-4 grid lg:h-[70vh] xl:h-[6ovh] 2xl:h-[80vh] sm:grid-cols-1 lg:grid-cols-2 bg-primary-content text-white',
                            url: 'https://cdn.loyalie.in/54950130.png',
                            linearGradient: {
                                R: '244',
                                G: '245',
                                B: '249',
                                opacity: '1',
                                size: '80% auto'
                            },
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'px-5 xl:px-7 2xl:px-12 m-auto h-auto mb-4 lg:m-auto text-left text-white py-5 md:py-10 lg:py-0 bg-[#F2F5FC]',

                                    components: [
                                        {
                                            type: 'BUTTON',
                                            content: 'Referrals and Earning',
                                            className: `text-primary-content mb-4 cursor-pointer tracking-wide text-[23px] md:text-[30px] lg:text-[20px] xl:text-[30px] ${
                                                getFont('KC').heading
                                            }`,
                                            path: 'gift-and-earn',
                                            onClick: 'CHANGE_PATH'
                                        },
                                        {
                                            type: 'DIVIDER',
                                            position: '',
                                            className:
                                                ' w-[20%] md:w-[16%] lg:w-[20%] xl:w-[19%] 2xl:w-[16%] bg-secondary border-none h-[2px]',
                                            classNameContainer: 'my-5'
                                        },

                                        {
                                            type: 'TEXT',
                                            content:
                                                'We believe that the best communities are built on trust. As a Kee Club member, you have the opportunity to share your lifestyle with your family and friends and earn exclusive benefits on every successful referral.',
                                            className: `text-secondary-focus text-[15px] leading-6 md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Kee Club offers the following referral rewards to each of its members:',
                                            className: `text-secondary-focus mt-4 xl:mt-5 text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                '> Rewards worth 2% of agreement value you receive for buying again at Piramal Realty',
                                            className: `text-secondary-focus mt-4 xl:mt-5 text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                '> Rewards worth 0.65% of agreement value you receive for successfully referring a loved one',
                                            className: `text-secondary-focus text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                '> Rewards worth 0.65% of agreement value your loved one receives on booking with us through your referral',
                                            className: `text-secondary-focus text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'You are our brand ambassador in this vision.',
                                            className: `text-secondary-focus mt-4 xl:mt-5 text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'Refer Now',
                                            className: `z-10 mt-5 bg-primary rounded-sm text-[16px] py-1 xl:py-2 px-5 xl:px-10 self-center text-white ${
                                                getFont('KC').heading
                                            }`,
                                            path: 'gift-and-earn',
                                            onClick: 'CHANGE_PATH'
                                        }
                                    ]
                                },
                                {
                                    type: 'CHANGING_IMAGE',
                                    position: 'center',
                                    imageArr: [
                                        {
                                            url: 'https://cdn.loyalie.in/31403130.jpg'
                                        },
                                        {
                                            url: 'https://cdn.loyalie.in/1211494.jpg'
                                        }
                                    ],
                                    className:
                                        'h-full lg:h-[500px] 2xl:h-[600px] w-full object-fill ',
                                    classNameContainer:
                                        'w-full h-full order-first lg:order-none',
                                    swipeable: false,
                                    draggable: false,
                                    showDots: false,
                                    arrows: false,
                                    infinite: true,
                                    autoPlay: true,
                                    autoPlaySpeed: 3000,
                                    transitionDuration: 3000,
                                    customTransition: 'animate-fadeInAnimation'
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'relative w-[90%] mx-auto drop-shadow-3xl rounded-xl mt-4 grid lg:h-[70vh] xl:h-[6ovh] 2xl:h-[80vh] sm:grid-cols-1 lg:grid-cols-2 bg-primary-content text-white',
                            url: 'https://cdn.loyalie.in/54950130.png',
                            linearGradient: {
                                R: '244',
                                G: '245',
                                B: '249',
                                opacity: '1',
                                size: '80% auto'
                            },
                            components: [
                                {
                                    type: 'CHANGING_IMAGE',
                                    position: 'center',
                                    imageArr: [
                                        {
                                            url: 'https://cdn.loyalie.in/44816977.jpg'
                                        },
                                        {
                                            url: 'https://cdn.loyalie.in/87352334.jpg'
                                        },
                                        {
                                            url: 'https://cdn.loyalie.in/86915063.jpg'
                                        }
                                    ],
                                    className:
                                        'h-full lg:h-[500px] 2xl:h-[600px] w-full object-fill ',
                                    classNameContainer:
                                        'w-full h-full  order-first lg:order-none',
                                    swipeable: false,
                                    draggable: false,
                                    showDots: false,
                                    arrows: false,
                                    infinite: true,
                                    autoPlay: true,
                                    autoPlaySpeed: 3000,
                                    transitionDuration: 3000,
                                    customTransition: 'animate-fadeInAnimation'
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'px-5 xl:px-7 2xl:px-12 m-auto h-auto mb-4 lg:m-auto text-left text-white py-5 md:py-10 lg:py-0 bg-[#F2F5FC]',

                                    components: [
                                        {
                                            type: 'TEXT',

                                            onClick: 'CHANGE_PATH',
                                            path: 'deals',
                                            content: 'Offers and Promotions',
                                            className: `text-primary-content mb-4 cursor-pointer tracking-wide text-[23px] md:text-[30px] lg:text-[20px] xl:text-[30px] ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'DIVIDER',
                                            className:
                                                ' w-[20%] md:w-[16%] lg:w-[20%] xl:w-[19%] 2xl:w-[16%] bg-secondary border-none h-[2px]',
                                            classNameContainer: 'my-5'
                                        },

                                        {
                                            type: 'TEXT',
                                            content:
                                                'Kee Club believes in putting your needs above everything else. We give you access to exclusive deals from premium and PAN-india brands like US Polo Assn, Hakkasan, Lenovo, Luxulo, and many more to elevate your lifestyle beyond the ordinary and deliver the luxury you deserve.',
                                            className: `text-secondary-focus text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Our goal is to bring you the best experiences from across the country and add value worth sharing with your social circle.',
                                            className: `text-secondary-focus mt-4 xl:mt-5 text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content: 'Disclaimer*',
                                            className: `text-secondary-focus mt-4 xl:mt-5 text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'All offers on Kee Club are valid till promotions last and may change from time to time.',
                                            className: `text-secondary-focus mt-4 xl:mt-5 text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'Avail Now',
                                            className: `z-10 mt-5 bg-primary rounded-sm text-[16px] py-1 xl:py-2 px-5 xl:px-10 self-center text-white ${
                                                getFont('KC').heading
                                            }`,
                                            path: 'deals',
                                            onClick: 'CHANGE_PATH'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'relative w-[90%] drop-shadow-3xl rounded-xl mx-auto mt-4 grid lg:h-[70vh] xl:h-[6ovh] 2xl:h-[80vh] sm:grid-cols-1 lg:grid-cols-2 bg-primary-content text-white',
                            url: 'https://cdn.loyalie.in/54950130.png',
                            linearGradient: {
                                R: '244',
                                G: '245',
                                B: '249',
                                opacity: '1',
                                size: '80% auto'
                            },
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'px-5 xl:px-7 2xl:px-12 m-auto h-auto mb-4 lg:m-auto text-left text-white py-5 md:py-10 lg:py-0 bg-[#F2F5FC]',

                                    components: [
                                        {
                                            type: 'TEXT',

                                            onClick: 'CHANGE_PATH',
                                            path: 'events',
                                            content: 'Workshops and Events',
                                            className: `text-primary-content mb-4 cursor-pointer tracking-wide text-[23px] md:text-[30px] lg:text-[20px] xl:text-[30px] ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'DIVIDER',
                                            className:
                                                ' w-[20%] md:w-[16%] lg:w-[20%] xl:w-[19%] 2xl:w-[16%] bg-secondary border-none h-[2px]',
                                            classNameContainer: 'my-5'
                                        },

                                        {
                                            type: 'TEXT',
                                            content:
                                                'There is more to life than daily routines and Kee Club focuses on delivering each of its members with impactful engagement that enriches their lives through knowledge, care, and action.',
                                            className: `text-secondary-focus text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'We bring our members various events and workshops with the best collaborators, so that every Kee Club family can get more value and lasting memories. ',
                                            className: `text-secondary-focus mt-4 xl:mt-5 text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'View Past Events',
                                            className: `z-10 mt-5 bg-primary rounded-sm text-[16px] py-1 xl:py-2 px-5 xl:px-10 self-center text-white ${
                                                getFont('KC').heading
                                            }`,
                                            path: 'events',
                                            onClick: 'CHANGE_PATH'
                                        }
                                    ]
                                },
                                {
                                    type: 'CHANGING_IMAGE',
                                    position: 'center',
                                    imageArr: [
                                        {
                                            url: 'https://cdn.loyalie.in/82694837.jpg'
                                        },
                                        {
                                            url: 'https://cdn.loyalie.in/35299959.jpg'
                                        }
                                    ],
                                    className:
                                        'h-full lg:h-[500px] 2xl:h-[600px] w-full object-fill ',
                                    classNameContainer:
                                        'w-full h-full  order-first lg:order-none',
                                    swipeable: false,
                                    draggable: false,
                                    showDots: false,
                                    arrows: false,
                                    infinite: true,
                                    autoPlay: true,
                                    autoPlaySpeed: 3000,
                                    transitionDuration: 3000,
                                    customTransition: 'animate-fadeInAnimation'
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'relative w-[90%] mx-auto drop-shadow-3xl rounded-xl mt-4 grid lg:h-[70vh] xl:h-[6ovh] 2xl:h-[80vh] sm:grid-cols-1 lg:grid-cols-2 bg-primary-content text-white',
                            url: 'https://cdn.loyalie.in/54950130.png',
                            linearGradient: {
                                R: '244',
                                G: '245',
                                B: '249',
                                opacity: '1',
                                size: '80% auto'
                            },
                            components: [
                                {
                                    type: 'CHANGING_IMAGE',
                                    position: 'center',
                                    imageArr: [
                                        {
                                            url: 'https://cdn.loyalie.in/45370947.jpg'
                                        },
                                        {
                                            url: 'https://cdn.loyalie.in/79860851.jpg'
                                        },
                                        {
                                            url: 'https://cdn.loyalie.in/13891394.jpg'
                                        }
                                    ],
                                    className:
                                        'h-full lg:h-[500px] 2xl:h-[600px] w-full object-fill ',
                                    classNameContainer:
                                        'w-full h-full  order-first lg:order-none',
                                    swipeable: false,
                                    draggable: false,
                                    showDots: false,
                                    arrows: false,
                                    infinite: true,
                                    autoPlay: true,
                                    autoPlaySpeed: 3000,
                                    transitionDuration: 3000,
                                    customTransition: 'animate-fadeInAnimation'
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'px-5 xl:px-7 2xl:px-12 m-auto h-auto mb-4 lg:m-auto text-left text-white py-5 md:py-10 lg:py-0 bg-[#F2F5FC]',

                                    components: [
                                        {
                                            type: 'TEXT',

                                            onClick: 'CHANGE_PATH',
                                            path: 'blogs',
                                            content:
                                                'Infotainment and Interactive Blogs',
                                            className: `text-primary-content mb-4 cursor-pointer tracking-wide text-[23px] md:text-[30px] lg:text-[20px] xl:text-[30px] font-light ${
                                                getFont('KC').heading
                                            }`
                                        },
                                        {
                                            type: 'DIVIDER',
                                            className:
                                                ' w-[20%] md:w-[16%] lg:w-[20%] xl:w-[19%] 2xl:w-[16%] bg-secondary border-none h-[2px]',
                                            classNameContainer: 'my-5'
                                        },

                                        {
                                            type: 'TEXT',
                                            content:
                                                'Kee Club understands the power of learning and the role it plays in our lives. We bring you infotainment and interactive blogs that align with our core values of knowledge, action, care, and impact.',
                                            className: `text-secondary-focus text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Our blogs will allow you to learn new things every day in bite-sized reads. You can read our latest pieces and comment on them to start a discussion with your entire community.',
                                            className: `text-secondary-focus mt-4 xl:mt-5 text-[15px] leading-6  md:text-[16px] lg:text-[14px] xl:text-[16px] ${
                                                getFont('KC').secondary
                                            }`
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'Read Now',
                                            className: `z-10 mt-5 bg-primary rounded-sm text-[16px] py-1 xl:py-2 px-5 xl:px-10 self-center text-white ${
                                                getFont('KC').heading
                                            }`,
                                            path: 'blogs',
                                            onClick: 'CHANGE_PATH'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    className:
                        'relative flex flex-col justify-center text-center mt-4 pt-8 text-primary',
                    linearGradient: {
                        R: '244',
                        G: '245',
                        B: '249',
                        opacity: '1',
                        size: '80% auto'
                    },
                    components: [
                        {
                            type: 'TEXT',
                            content: 'Projects',
                            className: `cursor-pointer tracking-wide text-[23px] md:text-[30px] lg:text-[25px] xl:text-[35px] font-medium ${
                                getFont('KC').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                ' w-[15%] md:w-[8%] lg:w-[6%] bg-secondary border-none h-[2px] mx-auto',
                            classNameContainer: 'my-5'
                        },
                        ,
                        {
                            type: 'CAROUSEL',
                            linearGradient: {
                                R: '244',
                                G: '245',
                                B: '249',
                                opacity: '1'
                            },
                            caraouselClassName:
                                'px-10 md:px-10 lg:px-10 xl:px-24 2xl:px-48 md:py-4 2xl:pt-5 relative bg-[#F4F5F9]',
                            imageContainerClassName:
                                'w-auto p-12 md:p-10 lg:p-12 xl:p-7 2xl:p-7',
                            imageClassName:
                                'mx-auto p-2 lg:p-4 2xl:p-4 cursor-pointer',
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 1000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 3,
                            pauseOnHover: true,
                            speed: 1000,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/21060060.png',
                                    alt: 'img',
                                    url: 'https://www.piramalaranya.com/'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/38253813.png',
                                    alt: 'img',
                                    url: 'https://www.piramalvaikunth.com/'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/89999699.png',
                                    alt: 'img',
                                    url: 'https://www.piramalmahalaxmi.com/'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/85641729.png',
                                    alt: 'img',
                                    url: 'https://www.piramalrevanta.com/'
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        referPage: {
            show: true,
            name: 'Refer And Earn',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        events: {
            show: true,
            name: 'Events',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: true,
            name: 'Blogs',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'Offers',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: true,
            name: 'Projects',
            path: '/projects',
            component: 'Projects'
        },

        contactUs: {
            show: false,
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'T&C',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://cdn.loyalie.in/1695057542516_referral banner',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1695057542516_referral banner',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[75vh] md:h-[70vh] object-cover bg-center lg:bg-left ',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `-mt-40 text-center lg:text-left text-white text-[15px] md:text-[20px] xl:text-[25px] uppercase lg:my-5 tracking-widest lg:w-[42%] ml-auto  ${
                                getFont('KC').heading
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: 'flex lg:w-[42%] lg:ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `z-10 mt-2 mx-auto lg:m-0 bg-primary rounded-sm text-[16px] py-1 xl:py-2 px-5 xl:px-10 self-center text-white ${
                                        getFont('KC').heading
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://cdn.loyalie.in/1695057542516_referral banner',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1695057542516_referral banner',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[45vh] md:h-[70vh] md:h-[70vh] object-cover bg-left ',
                components: []
            }
        ],
        customize: {
            claimButton: `z-10 mt-5 bg-primary rounded-sm text-[16px] py-1 xl:py-2 px-5 xl:px-10 self-center text-white ${
                getFont('KC').heading
            }`,
            deactivateProjectClick: true
        }
    },
    blogs: {
        linearGradient: {
            R: '244',
            G: '245',
            B: '249',
            opacity: '1'
        },
        banner: {
            bannerContainerClassName:
                'hero hero-content max-w-full h-[80vh] md:h-[70vh] lg:h-[97vh] lg:h-screen bg-top mx-auto text-center lg:-mt-20 ',
            linearGradient: {
                R1: '18',
                G1: '44',
                B1: '59',
                R2: '255',
                G2: '71',
                B2: '19',
                opacity1: '0.50',
                opacity2: '0.3',
                deg: '100deg'
            },
            backgroundImage: 'https://cdn.loyalie.in/82083460.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/82083460.jpg',

            components: [
                {
                    type: 'TEXT',
                    content: "KEE READER'S CLUB",
                    className: ` text-3xl md:text-4xl xl:text-[43px] text-white font-semibold tracking-wide ${
                        getFont('KC').heading
                    }`
                },
                {
                    type: 'DIVIDER',

                    className:
                        'mx-auto w-[20%] md:w-[16%] lg:w-[20%] xl:w-[13%] 2xl:w-[12%] bg-secondary border-none h-[2px]',
                    classNameContainer: 'my-5'
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing together information and entertainment for you.',
                    className: `mt-5 text-white  text-[15px]  mb-5 ${
                        getFont('KC').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL'
                }
            ],

            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: "Kee Reader's Club",
                    className: ` text-3xl md:text-4xl xl:text-[43px] text-white font-semibold tracking-wide ${
                        getFont('KC').heading
                    }`
                },
                {
                    type: 'DIVIDER',

                    className:
                        'mx-auto w-[20%] md:w-[16%] lg:w-[20%] xl:w-[13%] 2xl:w-[12%] bg-secondary border-none h-[2px]',
                    classNameContainer: 'my-5'
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing together information and entertainment for you.',
                    className: `mt-5 mb-4 text-white text-[15px] leading-6 ${
                        getFont('KC').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        })
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('KC').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${getFont('KC').heading}`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-accent text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-secondary-content ${
                    getFont('KC').heading
                }`,
                headingClass: `text-[25px] md:text-[30px] uppercase text-black font-medium mt-3 leading-7 md:leading-9 break-words ${
                    getFont('KC').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-secondary-focus  min-h-[40px] lg:min-h-[70px] ${
                    getFont('KC').bodyHeight
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('KC').heading
                } `,
                buttonClass: `inline text-[17px] text-primary ml-1 ${
                    getFont('KC').heading
                }`
            },
            sectionHeadings: `px-10 lg:px-16 pt-10 pb-2 text-[22px] lg:text-[25px] xl:text-[35px] tracking-wider text-center uppercase ${
                getFont('KC').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            dividerClass: 'hidden m-0 p-0 ',

            backgroundColor: 'bg-accent',
            cardHeight:
                ' h-[530px] lg:h-[520px] xl:h-[540px] 3xl:h-[580px] drop-shadow-2xl bg-[#F2F5FC] ',
            bodyHeight:
                ' h-[280px] md:h-[280px] lg:h-[270px] xl:h-[290px] 3xl:h-[380px]',
            headingClass: `text-primary-content my-5 md:my-7 text-[20px] 3xl:text-[20px] uppercase leading-5 font-semibold break-words mx-5 ${
                getFont('KC').heading
            }`,

            summaryClass: `text-[16.5px] lg:text-[15px] xl:text-[17px] mt-2 lg:my-6 leading-6 text-primary-content mx-5 ${
                getFont('KC').heading
            }`,
            categoryClass: 'bg-[#00000075] text-white text-[11px]',
            buttonClass: `bg-accent flex justify-center self-start text-[13px] xl:text-[14px] text-white py-2 px-4 md:px-4 m-4 ${
                getFont('KC').heading
            }`,
            dateClass: `text-[14px] md:text-[17px] xl:text-[13px]  self-center text-[#919396] ${
                getFont('KC').heading
            }`,
            noBlogsClass: `bg-primary text-white p-2 md:w-[30%] lg:w-[70%] xl:w-[50%] 3xl:w-[30%] mt-10 ${
                getFont('KC').heading
            }`
        }
    },
    referAndEarn: {
        components: [
            {
                type: 'SLIDER_BANNER',
                classWeb:
                    'hidden lg:block h-[110vh] w-full object-cover -mt-20',
                slides: [
                    {
                        id: 1,
                        linearGradient: {
                            R: '0',
                            G: '0',
                            B: '0',
                            opacity: '0'
                        },
                        displayImage: {
                            url: 'https://cdn.loyalie.in/18166252.jpg',
                            mobileUrl: 'https://cdn.loyalie.in/18166252.jpg'
                        },
                        components: [
                            {
                                type: 'CUSTOM',
                                className:
                                    'relative w-full text-white text-left pl-5 lg:pl-10 xl:pl-20  ',
                                components: [
                                    {
                                        type: 'TEXT',
                                        content: 'ONE FAMILY MANY REWARDS',
                                        className: `text-transparent bg-clip-text bg-gradient-to-b from-[#A97F40] to-[#FFE271] font-semibold text-2xl leading-none md:text-4xl lg:text-3xl xl:text-[33px] ${
                                            getFont('KC').heading
                                        }`
                                    },
                                    {
                                        type: 'TEXT',
                                        content:
                                            'An exclusive referral campaign, curated just for you!',
                                        className: `font-medium mt-2 mb-7 text-lg tracking-widest md:text-xl lg:text-lg xl:text-[18px] ${
                                            getFont('KC').heading
                                        }`
                                    },

                                    {
                                        type: 'BUTTON',
                                        content: 'Explore More',
                                        className: `z-10 bg-[#FFE271] rounded-sm text-[14px] md:text-[16px] py-2 px-5 md:px-7 self-center text-black ${
                                            getFont('KC').heading
                                        }`,
                                        path: 'gift-and-earn',
                                        onClick: 'CHANGE_PATH'
                                    },
                                    {
                                        type: 'CUSTOM',
                                        className: 'flex items-center mt-10',
                                        components: [
                                            {
                                                type: 'IMAGE',
                                                classNameContainer:
                                                    'w-40 md:w-64 pr-12 border-r-[2px] border-white ',
                                                className: 'object-contein',
                                                url: 'https://cdn.loyalie.in/13135995.png'
                                            },
                                            {
                                                type: 'IMAGE',
                                                classNameContainer:
                                                    'w-48 md:w-80 pl-12',
                                                className: 'object-contein',
                                                url: 'https://cdn.loyalie.in/86171210.png'
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 2,
                        linearGradient: {
                            R: '255',
                            G: '71',
                            B: '19',
                            opacity: '0.3'
                        },
                        displayImage: {
                            url: 'https://cdn.loyalie.in/53844805.jpg',
                            mobileUrl: 'https://cdn.loyalie.in/53844805.jpg'
                        },
                        components: [
                            {
                                type: 'CUSTOM',
                                className:
                                    'relative w-full text-white text-left  pl-5 lg:pl-10 xl:pl-20',
                                components: [
                                    {
                                        type: 'TEXT',
                                        content: 'ONE FAMILY MANY REWARDS',
                                        className: `font-semibold text-3xl leading-none md:text-4xl lg:text-3xl xl:text-[33px] ${
                                            getFont('KC').heading
                                        }`
                                    },
                                    {
                                        type: 'TEXT',
                                        content:
                                            'An exclusive referral campaign, curated just for you!',
                                        className: `font-medium mt-2 mb-7 text-3xl tracking-widest md:text-xl lg:text-lg xl:text-[18px] ${
                                            getFont('KC').heading
                                        }`
                                    },

                                    {
                                        type: 'BUTTON',
                                        content: 'Explore More',
                                        className: `z-10 bg-primary rounded-sm text-[16px] py-2 px-7 self-center text-white ${
                                            getFont('KC').heading
                                        }`,
                                        path: 'gift-and-earn',
                                        onClick: 'CHANGE_PATH_AND_SCROLL',
                                        scrollTo: 'referral_section'
                                    },
                                    {
                                        type: 'CUSTOM',
                                        className: 'flex items-center mt-10',
                                        components: [
                                            {
                                                type: 'IMAGE',
                                                classNameContainer:
                                                    'w-40 md:w-64 pr-12 border-r-[2px] border-white ',
                                                className: 'object-contein',
                                                url: 'https://cdn.loyalie.in/21490471.png'
                                            },
                                            {
                                                type: 'IMAGE',
                                                classNameContainer:
                                                    'w-40 md:w-64 pl-12',
                                                className: 'object-contein',
                                                url: 'https://cdn.loyalie.in/3361928.png'
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'CUSTOM',
                id: 'overview',
                className:
                    'relative flex flex-col justify-center text-center pt-8',
                url: 'https://cdn.loyalie.in/54950130.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0',
                    size: '80% auto'
                },
                components: [
                    {
                        type: 'CUSTOM',
                        className:
                            'z-10 w-full text-center w-[92%] 2xl:w-[90%] mx-auto mb-3 drop-shadow-3xl',
                        components: [
                            {
                                type: 'TEXT',
                                content: 'One Family Many Rewards',
                                className: `mt-10 font-semibold px-5 text-[18px] xl:text-[20px] text-secondary leading-6 xl:leading-[27px] ${
                                    getFont('KC').heading
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'An exclusive referral campaign, curated just for you!',
                                className: `font-medium px-5 text-[18px] xl:text-[20px] text-secondary leading-6 xl:leading-[27px] ${
                                    getFont('KC').heading
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Piramal Realty knows what it means to build model communities that care for each other, and with One Family Many Rewards, we intend to reward every member who helps build the Piramal family. We bring you the opportunity to share your lifestyle with your loved ones and earn exclusive rewards on every successful referral.',
                                className: ` text-[15px] md:text-[16px] xl:text-[17px] pt-5 text-secondary-focus leading-6 xl:leading-[26px]   ${
                                    getFont('KC').heading
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Refer your loved ones to Piramal Realty lifestyle and stand a chance to win exciting rewards, specially curated for you. Higher the agreement value of the referred customer, better the rewards. To know more about the referral rewards for every project, click on the links below:',
                                className: `px-10 text-[15px] md:text-[16px] xl:text-[17px] pt-5 text-secondary-focus leading-6 xl:leading-[26px]   ${
                                    getFont('KC').heading
                                }`
                            }
                        ]
                    },
                    {
                        type: 'CUSTOM',
                        className:
                            'z-10 lg:flex m-auto lg:m-0 w-[80%] lg:w-full items-center justify-center pt-2 pb-7',
                        components: [
                            {
                                type: 'CUSTOM',
                                className:
                                    'lg:pr-16 border-b-[1px] pb-10 lg:pb-0 lg:border-r-[1px] lg:border-b-0 border-primary',
                                components: [
                                    {
                                        type: 'CUSTOM',
                                        className:
                                            'flex items-center justify-center mt-10 mb-5',
                                        components: [
                                            {
                                                type: 'IMAGE',
                                                classNameContainer:
                                                    'w-52 pr-4 border-r-[2px] border-gray-200 ',
                                                className: 'object-contein',
                                                url: 'https://cdn.loyalie.in/24821208.png'
                                            },
                                            {
                                                type: 'IMAGE',
                                                classNameContainer: 'w-44 pl-4',
                                                className: 'object-contein',
                                                url: 'https://cdn.loyalie.in/70385746.png'
                                            }
                                        ]
                                    },
                                    {
                                        type: 'BUTTON',
                                        content: 'Click To Know More',
                                        className: `z-10 bg-primary rounded-sm text-[16px] py-2 px-5 self-center text-white ${
                                            getFont('KC').heading
                                        }`,
                                        path: 'booklet',
                                        onClick: 'CHANGE_PATH',
                                        pdf: 'SOBO'
                                    }
                                ]
                            },
                            {
                                type: 'CUSTOM',
                                className: 'lg:pl-16',
                                components: [
                                    {
                                        type: 'CUSTOM',
                                        className:
                                            'flex items-center justify-center mt-10 mb-5',
                                        components: [
                                            {
                                                type: 'IMAGE',
                                                classNameContainer:
                                                    'w-44 pr-4 border-r-[2px] border-gray-200 ',
                                                className: 'object-contein',
                                                url: 'https://cdn.loyalie.in/77328867.png'
                                            },
                                            {
                                                type: 'IMAGE',
                                                classNameContainer: 'w-44 pl-4',
                                                className: 'object-contein',
                                                url: 'https://cdn.loyalie.in/88900317.png'
                                            }
                                        ]
                                    },
                                    {
                                        type: 'BUTTON',
                                        content: 'Click To Know More',
                                        className: `z-10 bg-primary rounded-sm text-[16px] py-2 px-5 self-center text-white ${
                                            getFont('KC').heading
                                        }`,
                                        path: 'booklet',
                                        onClick: 'CHANGE_PATH',
                                        pdf: 'SUBURB'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: 'TEXT',
                        content: 'Your Referral Journey',
                        className: `my-4 font-semibold px-5 text-[18px] xl:text-[20px] text-secondary leading-6 xl:leading-[27px] ${
                            getFont('KC').heading
                        }`
                    },
                    {
                        type: 'ICONS_LAYOUT_WITH_ARROW',
                        icontextclassName: ` text-[15.5px] xl:text-[16px] text-secondary-focus text-center mx-auto  ${
                            getFont('KC').heading
                        }`,
                        className: 'w-[55%] lg:w-[75%] xl:w-[70%]',
                        ArrowRightIcon:
                            'hidden lg:block self-center h-7 md:h-8 lg:h-5 w-7 md:w-8 my-10 lg:my-0 lg:w-10 mx-7 text-primary',
                        ArrowDownIcon:
                            'block lg:hidden self-center h-7 md:h-8 lg:h-5 w-7 md:w-8 my-5 lg:my-0 lg:w-10 mx-7 text-primary',
                        iconClass: 'w-24 mb-4 mx-auto',
                        icon1: 'https://cdn.loyalie.in/21246591.png',
                        icontext1: 'Refer your friend using the form',
                        icon2: 'https://cdn.loyalie.in/51944731.png',
                        icontext2:
                            'Wait for them to complete property booking and registration',
                        icon3: 'https://cdn.loyalie.in/85448861.png',
                        icontext3: 'Earn your referral rewards within 90 days'
                    },
                    {
                        type: 'TEXT',
                        content:
                            'Start referring now to earn exclusive rewards and help your loved ones with special benefits they receive when they are referred by you.',
                        className: `font-medium mx-auto mt-16 mb-20 w-[70%] text-[18px] xl:text-[20px] text-secondary leading-6 xl:leading-[27px] ${
                            getFont('KC').heading
                        }`
                    }
                ]
            },
            {
                type: 'REFERRAL_FORM_BANNER'
            },
            {
                type: 'CUSTOM',
                className:
                    'relative flex flex-col justify-center text-center pt-8 text-primary',
                url: 'https://cdn.loyalie.in/54950130.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0',
                    size: '80% auto'
                },
                components: [
                    {
                        type: 'TEXT',
                        content: 'Projects',
                        className: `cursor-pointer tracking-wide text-[23px] md:text-[30px] lg:text-[25px] xl:text-[35px] font-medium ${
                            getFont('KC').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            ' w-[15%] md:w-[8%] lg:w-[6%] bg-secondary border-none h-[2px] mx-auto',
                        classNameContainer: 'my-5'
                    },
                    ,
                    {
                        type: 'CAROUSEL',
                        linearGradient: {
                            R: '255',
                            G: '255',
                            B: '255',
                            opacity: '0.30',
                            size: '80% auto'
                        },
                        caraouselClassName:
                            'px-10 md:px-10 lg:px-10 xl:px-24 2xl:px-48 md:py-4 2xl:pt-5 relative',
                        imageContainerClassName:
                            'w-auto p-12 md:p-10 lg:p-12 xl:p-7 2xl:p-7',
                        imageClassName:
                            'mx-auto p-2 lg:p-4 2xl:p-4 cursor-pointer',
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 1000,
                        infinite: true,
                        dots: false,
                        slidesToShow: 3,
                        pauseOnHover: true,
                        speed: 1000,
                        onImageClick: openURLNewWindow,
                        imgArr: [
                            {
                                imgUrl: 'https://cdn.loyalie.in/21060060.png',
                                alt: 'img',
                                url: 'https://www.piramalaranya.com/'
                            },
                            {
                                imgUrl: 'https://cdn.loyalie.in/38253813.png',
                                alt: 'img',
                                url: 'https://www.piramalvaikunth.com/'
                            },
                            {
                                imgUrl: 'https://cdn.loyalie.in/89999699.png',
                                alt: 'img',
                                url: 'https://www.piramalmahalaxmi.com/'
                            },
                            {
                                imgUrl: 'https://cdn.loyalie.in/85641729.png',
                                alt: 'img',
                                url: 'https://www.piramalrevanta.com/'
                            }
                        ]
                    }
                ]
            }
        ]
    },

    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/79205305.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/79205305.jpg',

            linearGradient: {
                R1: '18',
                G1: '44',
                B1: '59',
                R2: '255',
                G2: '71',
                B2: '19',
                opacity1: '0.50',
                opacity2: '0.3',
                deg: '100deg'
            },
            negativeMargin: '-mt-20',
            containerClass: 'text-white mx-auto',
            heading: 'Events Archive',
            headingClass: ` text-3xl md:text-4xl xl:text-[43px] text-white font-semibold tracking-wide ${
                getFont('KC').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-neutral uppercase font-semibold text-[33px] ${
                getFont('KC').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                ' hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[97vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `-mt-36 md:mt-56 lg:-mt-48 mb-3 text-secondary text-2xl md:text-4xl xl:text-[40px] text-secondary tracking-wider ${
                        getFont('KC').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    onClick: 'FUNC',
                    className: `bg-accent rounded-sm text-[16px] py-1 md:py-[5.5px] px-5 md:px-10 self-center font-CorporateS_Demi text-[#64412b]`
                }
            ]
        },

        eventsSection: {
            linearGradient: {
                R: '244',
                G: '245',
                B: '249',
                opacity: '1'
            },
            heading: 'PAST EVENTS',
            headingClass: `z-10 text-primary text-[25px] md:text-text-[30px] lg:text-[33px] text-center font-semibold mt-10 2xl:mt-7 ${
                getFont('KC').heading
            }`,
            dividerClass:
                'z-10 self-center mt-2 2xl:mt-3 mx-auto  w-[25%] md:w-[16%] lg:w-[14%] 2xl:w-[9%]  bg-primary border-none h-[2px]  mb-8',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `z-10 self-center text-[20px]  text-center text-xl text-primary-content w-[60%] ${
                getFont('KC').heading
            }`,
            additionalText: ' ',
            additionalTextClass: `self-center text-md text-center text-black  w-[60%] ${
                getFont('KC').heading
            } `,
            subAdditionalText: ' ',
            subAdditionalTextClass: `capitalize self-center text-md text-center text-black w-[60%] mb-16 ${
                getFont('KC').heading
            }`,
            noEvents: '',
            noEventsClass: `z-10 w-[30%] mx-auto py-1 text-accent bg-secondary-focus text-xl text-center ${
                getFont('KC').heading
            }`
        },
        eventLayout: {
            titleClass: `text-primary-content leading-6 uppercase font-semibold ${
                getFont('KC').heading
            }`,
            hostClass: `${getFont('KC').heading} text-primary`,
            dateClass: '',
            loadMoreButtonClass: `z-10 bg-accent rounded-sm text-[16px] py-2 px-10 self-center text-white  ${
                getFont('KC').heading
            }`
        }
    },
    eventsGallery: {
        show: true,
        titleClass: `text-secondary-focus font-semibold text-xl 2xl:text-2xl my-4 z-10 ${
            getFont('KC').heading
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('KC').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[30vh] lg:h-[45vh] bg-center lg:bg-top bg-cover',
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1695628033484_Offer banner',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1695628033484_Offer banner',
            components: [
                // {
                //     type: 'TEXT',
                //     content: 'Exclusive Offers, Curated Just For You',
                //     className: ` text-2xl md:text-4xl xl:text-[43px] text-center text-white font-semibold tracking-wide ${
                //         getFont('KC').heading
                //     }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mx-auto w-[20%] md:w-[16%] lg:w-[20%] xl:w-[13%] 2xl:w-[12%] bg-secondary border-none h-[2px]',
                //     classNameContainer: 'my-5'
                // }
            ],
            loginBannerComponents: [
                // {
                //     type: 'TEXT',
                //     content: 'Exclusive Offers, Curated Just For You',
                //     className: ` text-2xl md:text-4xl xl:text-[43px] text-center text-white font-semibold tracking-wide ${
                //         getFont('KC').heading
                //     }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mx-auto w-[20%] md:w-[16%] lg:w-[20%] xl:w-[13%] 2xl:w-[12%] bg-secondary border-none h-[2px]',
                //     classNameContainer: 'my-5'
                // }
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC',
                //     className: `bg-accent rounded-sm text-[16px] py-1 md:py-[5.5px] px-5 md:px-10 self-center font-CorporateS_Demi text-[#64412b] `
                // }
            ]
        },
        dealsSection: {
            linearGradient: {
                R: '244',
                G: '245',
                B: '249',
                opacity: '1'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center font-semibold md:text-[20px] text-center text-[17px] text-neutral w-[90%] ${
                getFont('KC').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `uppercase text-secondary text-2xl xl:text-3xl ${
                getFont('KC').heading
            } `,
            subAdditionalText:
                'Kee Club is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: `font-normal text-[14px] py-2 mt-2 leading-6 ${
                getFont('KC').heading
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('KC').heading}`
        },
        dealLayout: {
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.60',
                repeat: 'no-repeat',
                size: '140%',
                position: ''
            },
            listTitleClass: `text-[18px] leading-6 lg:leading-5 2xl:leading-6 text-primary ${
                getFont('KC').heading
            }`,
            listBodyClass: `${getFont('KC').heading}`,
            logoClassName: `self-center rounded-md drop-shadow-lg z-10 `,
            titleClassName: ` text-primary font-medium uppercase text-2xl xl:text-xl 2xl:text-2xl leading-7  mt-4 ${
                getFont('KC').heading
            } `,
            couponClassName: `block md:text-[22px] text-[19px] self-center text-center text-primary font-bold leading-7 ${
                getFont('KC').heading
            }`,
            dateClassName: `py-[6px] text-neutral capitalize text-[17px] text-secondary-focus  ${
                getFont('KC').heading
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-[14px]  ${
                getFont('KC').heading
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-neutral text-[12px] break-words leading-1 ${
                getFont('KC').heading
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-accent text-white uppercase self-center text-[16px] py-2.5 px-7  ${
                    getFont('KC').heading
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `rounded bg-accent hover:bg-accent-focus hover:font-semibold text-white uppercase self-center text-[16px] py-2.5 px-7 ${
                    getFont('KC').heading
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                position: 'center',
                linearGradient: {
                    R1: '18',
                    G1: '44',
                    B1: '59',
                    R2: '255',
                    G2: '71',
                    B2: '19',
                    opacity1: '0.5',
                    opacity2: '0.4',
                    deg: '100deg'
                },
                backgroundImage:
                    'https://piramaluat.s3.ap-south-1.amazonaws.com/Website/Uploads/Piramal/Images/12674882.jpg',
                backgroundImageMobile:
                    'https://piramaluat.s3.ap-south-1.amazonaws.com/Website/Uploads/Piramal/Images/12674882.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[80vh] md:h-[70vh] lg:h-[97vh] lg:h-screen ',

                components: [
                    {
                        type: 'TEXT',
                        content: 'PIRAMAL PROJECTS',
                        className: ` text-3xl md:text-4xl xl:text-[43px] text-white text-center font-semibold tracking-wide ${
                            getFont('KC').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',

                        className:
                            'mx-auto w-[20%] md:w-[16%] lg:w-[20%] xl:w-[13%] 2xl:w-[12%] bg-secondary border-none h-[2px]',
                        classNameContainer: 'my-5'
                    }
                ]
            }
        ],
        moduleConfig: {
       
            constructionVisible: true,
            
            sectionBackgroundImage: '',
            overlayClass: 'bg-[#F4F5F9]',
            propertyBackgroundColorWithOverlay: 'bg-[#F2F5FC] drop-shadow-2xl',
            fontBody: `${getFont('KC').heading}`,
            fontTitle: `${getFont('KC').heading}`
        }
    },
    header: {
        headerClass: 'hidden lg:block h-36 bg-[#122c3b]'
    },
    contactUs: {
        linearGradient: {
            R: '244',
            G: '245',
            B: '249',
            opacity: '1'
        },
        components: [
            {
                type: 'BANNER',
                position: 'text-center',
                linearGradient: {
                    R1: '18',
                    G1: '44',
                    B1: '59',
                    R2: '255',
                    G2: '71',
                    B2: '19',
                    opacity1: '0.50',
                    opacity2: '0.3',
                    deg: '100deg'
                },
                backgroundImage: 'https://cdn.loyalie.in/33057284.jpg',

                backgroundImageMobile: 'https://cdn.loyalie.in/33057284.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[80vh] md:h-[70vh] lg:h-[97vh] lg:h-screen',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',
                        className: ` text-3xl md:text-4xl xl:text-[43px] text-white font-semibold tracking-wide ${
                            getFont('KC').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',

                        className:
                            'mx-auto w-[20%] md:w-[16%] lg:w-[20%] xl:w-[13%] 2xl:w-[12%] bg-secondary border-none h-[2px]',
                        classNameContainer: 'my-5'
                    }
                ]
            }
        ],

    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: `z-10 tracking-wide text-black mt-4 font-semibold text-[16px] md:text-[16px] ${
            getFont('KC').heading
        }`,
        listClassName: `z-10 text-black text-[15px] leading-6 mt-4 ${
            getFont('KC').heading
        }`,
        components: [
            {
                type: 'BANNER',
                position: 'text-center',
                linearGradient: {
                    R1: '18',
                    G1: '44',
                    B1: '59',
                    R2: '255',
                    G2: '71',
                    B2: '19',
                    opacity1: '0.50',
                    opacity2: '0.3',
                    deg: '100deg'
                },

                backgroundImage:
                    'https://piramaluat.s3.ap-south-1.amazonaws.com/Website/Uploads/Piramal/Images/48020127.jpg',

                backgroundImageMobile:
                    'https://piramaluat.s3.ap-south-1.amazonaws.com/Website/Uploads/Piramal/Images/48020127.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[80vh] md:h-[70vh] lg:h-[97vh] lg:h-screen',

                components: [
                    {
                        type: 'TEXT',
                        content: 'Terms And Conditions',
                        className: ` text-3xl md:text-4xl xl:text-[43px] text-white font-semibold tracking-wide ${
                            getFont('KC').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',

                        className:
                            'mx-auto w-[20%] md:w-[16%] lg:w-[20%] xl:w-[13%] 2xl:w-[12%] bg-secondary border-none h-[2px]',
                        classNameContainer: 'my-5'
                    }
                ]
            },
            {
                type: 'CUSTOM',
                className:
                    'relative flex flex-col justify-start text-left p-7 md:p-16 lg:px-24 xl:px-16 2xl:px-36',
                linearGradient: {
                    R: '244',
                    G: '245',
                    B: '249',
                    opacity: '1'
                },
                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `z-10 tracking-wide text-black font-semibold text-[20px] md:text-[16px] ${
                            getFont('KC').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: `z-10 tracking-wide text-black mt-4 font-semibold text-[16px] md:text-[16px] ${
                            getFont('KC').heading
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a PR project, and has entered into a registered Agreement to Sell; or (ii) employee of PR; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by PR or any of its sister concerns/subsidiaries/affiliates (“PR Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate PR Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by PR.',
                            'Referrer shall not be able to refer existing customer(s) of PR. Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with PR or (ii) is/are already registered with PR as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the PR’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',
                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to PR within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to PR.',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by PR or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `z-10 text-black text-[15px] leading-6 mt-4 ${
                            getFont('KC').heading
                        }`,
                        classNameContainer: 'z-10 px-6 mt-6'
                    },
                    { type: 'RAZORPAY_TERMS', classNameContainer: 'px-6 mt-6' },

                    {
                        type: 'CUSTOM',
                        className: 'z-10 px-6 pt-5',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or PR, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('KC').heading
                                } inline text-black text-[15px] leading-6 `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-red-400 font-medium text-[16px] leading-6 ${
                                    getFont('KC').heading
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and to the privacy policy on',
                                className: `inline text-black text-[16px] leading-6 ${
                                    getFont('KC').heading
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy',
                                className: `inline-block ml-1 text-red-400 font-medium text-[16px] leading-6 ${
                                    getFont('KC').heading
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.. The Participants also provides consent to receive messages, emails and any other form of communication from Reloy and/or the Company, for the purposes of promoting this Program and its benefits or the Company’s Project/s.',
                                className: `inline ml-1 text-black text-[16px] leading-6 ${
                                    getFont('KC').heading
                                } `
                            }
                        ]
                    },

                    {
                        type: 'CUSTOM',
                        className: 'z-10 px-6 pt-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',

                                className: `${
                                    getFont('KC').heading
                                } inline text-black text-[16px] leading-6 `
                            },

                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-red-400 font-medium text-[16px] leading-6 ${
                                    getFont('KC').heading
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    '.Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',

                                className: `${
                                    getFont('KC').heading
                                } inline text-black text-[16px] leading-6 `
                            }
                        ]
                    }
                ]
            }
        ]
    },

    myProfile: {
        allowOnboarding: false
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/88838082.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-0',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('KC').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[10.5px] 3xl:text-[15px] leading-[18px] tracking-tight text-white ${
            getFont('KC').heading
        }`
    },
    commonComponents: {
        filterTagClassName: `z-10 text-black leading-8 text-[16px]  ${
            getFont('KC').heading
        }`,
        paginationClass:
            ' text-white disabled:text-white disabled:cursor-not-allowed bg-primary-content border-primary-content hover:opacity-90 disabled:bg-[#d3d3d3]',
        paginationActivePageColor: 'text-accent bg-opacity-90',
        backgroundColor: ''
    },
    referralForm: {
        name: 'Piramal',
        theme: 'KCTheme',
        color: '#FF4713',
        backgroundImage: 'https://cdn.loyalie.in/11055866.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '100',
            G1: '65',
            B1: '43',
            opacity1: '0',
            R2: '100',
            G2: '65',
            B2: '43',
            opacity2: '0'
        },
        showLoginScreen: true,
        userTypes: {
            length: 6,
            siteVisitor: false,
            siteVisitorHeading1: '',
            siteVisitorHeading2: '',

            bookedCustomer: true,
            bookedCustomerHeading1: '',
            bookedCustomerHeading2: '',

            employee: true,
            employeeHeading1: '',
            employeeHeading2: '',

            salesAdmin: true,
            salesAdminHeading1: '',
            salesAdminHeading2: '',
            influencer: false,
            influencerHeading1: '',
            influencerHeading2: ''
        },
        formContent: {
            heading: 'REWARDING YOU BEYOND THE EXPERIENCE',
            headingClass: `self-center text-white sm:mt-0 text-3xl md:text-4xl 2xl:text-5xl w-[80%] lg:w-[70%] 2xl:w-[80%] text-center leading-8 tracking-wide ${
                getFont('KC').heading
            }`,
            notification: {
                line1: 'REWARDING YOU',
                line1Class: `text-primary font-semibold mx-auto text-4xl lg:text-[50px]  ${
                    getFont('KC').heading
                } `,
                line2: 'Beyond The Experience',
                line2Class: `text-white text-4xl font-semibold ${
                    getFont('KC').heading
                } `,
                line3: ' ',
                line3Class: ``
            },
            employeeDisclaimer: false
        },
        leadEmailMandatory: true,
        termsAndConditionsLink: '/kc-website/terms-and-conditions',
        customLink: '/kc-website/projects/detail'
    },

    components: {
        card: {
            showHeader: true,
            showImage: true,
            showFooter: true
        }
    }
};
export default KeeclubConfig;
