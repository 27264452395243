import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function SearchBar({searchValue, onSearchChange, width, navigateOnClick}) {
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );

    const history = useHistory( )
    return (
        
        <div class={`${width} flex items-center h-14 rounded-lg border border-[#dddddd] p-2 ${internalWebview ? 'bg-white' : ''}`}>
            <input
                class="peer h-full w-full outline-none text-sm text-gray-700 pr-2 placeholder:font-semibold"
                type="text"
                id="search"
                placeholder="Type here to search"
                value={searchValue}
                onChange={(e) => onSearchChange(e)}
                autoComplete="off"
                // onClick={() => navigateOnClick && history.push('/dealListing')}
            />
            <button className="bg-primary ml-2 rounded text-white h-full px-3 z-10">
                Search
            </button>
        </div>
        
    );
}

export default SearchBar;
