import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth-slice';
import webViewSlice from './webview';
import propertyLayoutSlice from './property-layout-switch-slice';
import propertyFilterSlice from './property-filter-slice';

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        propertyLayout: propertyLayoutSlice.reducer,
        propertyFilter: propertyFilterSlice.reducer,
        webView: webViewSlice.reducer
    }
});

export default store;
