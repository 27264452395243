import React, { useEffect, useState } from 'react';

const Iframe = (props) => {
    const { id, url, className, height, width } = props;

    if (!url) {
        return <div />;
    }

    return (
        <iframe
            id={id}
            frameborder="0"
            allowfullscreen
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            oallowfullscreen="true"
            msallowfullscreen="true"
            src={url}
            height={height}
            width={width}
            className={className || ''}
        ></iframe>
    );
};

export default Iframe;
