import React from 'react'

function Overview(props) {
  return (
    <svg fill={props.fill}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.87"
                        height="15"
                        viewBox="0 0 20.87 15"
                    >
                        <path
                            id="_9112825_binoculars_solid_icon"
                            data-name="9112825_binoculars_solid_icon"
                            d="M7.174,5A2.579,2.579,0,0,0,4.647,7.16,3.863,3.863,0,0,0,1.875,9.545h-.02L.469,13.438a4.561,4.561,0,1,0,8.6,2.731,1.919,1.919,0,0,0,2.731,0,4.564,4.564,0,1,0,8.7-2.527L19.015,9.484l-.02-.02v-.02A4.006,4.006,0,0,0,16.223,7.16a2.558,2.558,0,0,0-5.014-.2H9.66A2.579,2.579,0,0,0,7.174,5Zm0,1.3a1.238,1.238,0,0,1,1.3,1.3v.652h3.913V7.609a1.3,1.3,0,0,1,2.609,0V8.2l.591.061a2.747,2.747,0,0,1,2.181,1.651c.005.013.015.028.02.041l.489,1.386a4.509,4.509,0,0,0-6.2,2.385,1.968,1.968,0,0,0-3.3,0,4.532,4.532,0,0,0-6.175-2.4L3.1,9.993v-.02A2.544,2.544,0,0,1,5.279,8.261L5.87,8.2V7.609A1.238,1.238,0,0,1,7.174,6.3Zm-2.609,5.87A3.327,3.327,0,1,1,1.59,14.151c.01-.025.01-.056.02-.082A3.246,3.246,0,0,1,4.565,12.174Zm11.739,0a3.261,3.261,0,1,1-3.261,3.261A3.251,3.251,0,0,1,16.3,12.174Zm-5.87,1.957a.652.652,0,1,1-.652.652A.644.644,0,0,1,10.435,14.13Z"
                            transform="translate(0 -5)"
                        />
                    </svg>
  )
}

export default Overview
