import React, { useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { getConfig } from '../../../../utils/config';
import { currencyToIndianRsWord } from '../../../../helpers';

const SingleGMaps = ({ location, viewport, setViewport, projectName , projectPrice, projectCity }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD8g5LLt4YxRR363AOmHEZhGHaUctJIz4Q"
    });

    const [viewP, setViewP] = useState({
        ...viewport,
        center: { lat: 22.43, lng: 80.84 },
        zoom: 14
    });

    const config = getConfig();

    useEffect(() => {
        if (location && location.latitude && location.longitude) {
            setViewP({
                ...viewP,
                center: {
                    lat: parseFloat(location.latitude.trim()).toFixed(5),
                    lng: parseFloat(location.longitude.trim()).toFixed(5)
                }
            });
        }
    }, [location]);

    useEffect(() => {
        if (setViewport) {
            setViewport(viewP);
        }
    }, [viewP, setViewport]);

    const currencyFormating = (price) => {
        if (price >= 10000000)
            return currencyToIndianRsWord
                .format(price)
                .slice(0, -2)
                .concat(' Cr*');
        return currencyToIndianRsWord.format(price).slice(0, -1).concat(' L');
    };

    return isLoaded ? (
        <GoogleMap
            center={{
                lat: parseFloat(viewP.center.lat),
                lng: parseFloat(viewP.center.lng)
            }}
            zoom={viewP.zoom}
            mapContainerStyle={{ width: '100%', height: '50vh' }}
        >
            {location && viewP.center.lat && viewP.center.lng && (
                <Marker
                    position={{
                        lat: parseFloat(viewP.center.lat),
                        lng: parseFloat(viewP.center.lng)
                    }}
                >
                    <InfoWindow
                        position={{
                            lat: parseFloat(viewP.center.lat),
                            lng: parseFloat(viewP.center.lng)
                        }}
                        >
                        <div className='select-none cursor-pointer' >
                            <h2 className={`${config.fontBody} uppercase font-semibold md:text-[11px]`}>{projectName} {`(${projectCity})`}</h2>
                            <p className={`${config.fontTitle} pt-1 text-center font-semibold md:text-[12px]`}>
                                STARTING: {currencyFormating(projectPrice)}
                            </p>
                        </div>
                    </InfoWindow>
                </Marker>
            )}
        </GoogleMap>
    ) : <></>;
};

export default SingleGMaps;
