import React, { useEffect, useState } from 'react';
import { winnreAuth, winnreNoAuth } from '../../../../utils/axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import '../index.css';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useDispatch, useSelector } from 'react-redux';
import { useInput } from '../../../../Hooks';
import { RE_EMAIL, RE_NAME } from '../../../../constants';
import BsCheckCircle from '@meronex/icons/bs/BsCheckCircle';
import BiLoader from '@meronex/icons/bi/BiLoader';
import moment from 'moment';
import { LoadingSpinner } from '../../../Molecules/LoadingUI';
import { useTheme } from '@mui/material/styles';
import { authActions } from '../../../../store/auth-slice';
function StepOne({
    config,
    setStep,
    setAlertMessage,
    setShowErrorAlert,
    showErrorAlert,
    alertMessage,
    userProperty
}) {
    const [emailVerified, setEmailVerified] = useState(false);
    const userEmail = useSelector((state) => state.auth?.userDetails?.email);
    const [birthDate, setBirthDate] = useState(null);
    const maxDate = moment().subtract(10, 'years');
    const minDate = moment().subtract(100, 'years');
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();
    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail
    } = useInput((value) => RE_EMAIL.test(value), userEmail);

    const {
        value: name,
        isValid: nameIsValid,
        hasError: nameHasError,
        valueChangeHandler: nameChangedHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetName
    } = useInput((value) => !RE_NAME.test(value) && value !== '');

    const verifyEmailHandler = () => {
        window?.webengage?.track('EmailVerified');
        setLoading(true);
        winnreAuth({
            method: 'POST',
            url: `/winnre/login/verifyEmail?verifyByLink=true&appType=CONNECTRE&email=${email}`
        })
            .then((res) => {
                setLoading(false);
                if (
                    res?.status === 'INVALID_TOKEN' ||
                    res?.status === 'FAILED'
                ) {
                    setAlertMessage(res?.message);
                    setShowErrorAlert(true);
                    return;
                }
                setEmailVerified(true);
            })
            .catch((e) => {});
    };
    let formIsValid = nameIsValid && birthDate && emailVerified;
    const nextStepHandler = () => {
        winnreAuth({
            method: 'POST',
            url: `/winnre/user/on-board-data`,
            data: JSON.stringify({
                preferredName: name,
                dateOfBirth: moment(birthDate).format('YYYY-MM-DD')
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                if (
                    res?.status === 'INVALID_TOKEN' ||
                    res?.status === 'FAILED'
                ) {
                    setAlertMessage(res?.message);
                    setShowErrorAlert(true);
                    return;
                }
                winnreAuth({
                    method: 'POST',
                    url: `/winnre/user/changeFinishedFirstTimeLoginGuide?finishedFirstTimeLoginGuide=true`
                }).then((res) => {
                    // console.log(res);
                    dispatch(authActions.setFinishedFirstTimeLoginGuide(true));
                    setStep(2);
                });
            })
            .catch((e) => {});
    };
    useEffect(() => {
        setEmailVerified(false);
    }, [email]);
    return (
        <>
            <p className="mt-4 lg:mt-0 font-Poppins font-medium text-sm lg:text-base">
                What would you like to be called?
            </p>
            <div className="relative mt-2">
                <input
                    type="text"
                    id="otp"
                    className="placeholder-black border font-semibold border-[#bfbfbf] focus:outline-none rounded-[4px] focus:border-[2px] focus:border-gray-500 focus:shadow-sm w-full px-3 py-3 text-sm lg:text-base"
                    placeholder="Enter Name"
                    onChange={nameChangedHandler}
                    onBlur={nameBlurHandler}
                    value={name}
                    autoComplete="off"
                />
                <div className="flex justify-between text-sm mt-1">
                    <p className="text-[#606967]">Eg: John</p>
                    {nameHasError && (
                        <p className="text-left text-red-400 text-sm lg:text-base">
                            {`Please enter a valid Name`}
                        </p>
                    )}
                </div>
            </div>
            <p className="mt-4 lg:mt-6 font-Poppins font-medium text-sm lg:text-base">
                Please confirm your email
            </p>
            <div className="relative mt-2">
                <input
                    type="text"
                    id="otp"
                    className="placeholder-black font-semibold border border-[#bfbfbf] focus:outline-none rounded-[4px] focus:border-[2px] focus:border-gray-500 focus:shadow-sm w-full px-3 py-3 text-sm lg:text-base"
                    placeholder="Enter Email"
                    onChange={emailChangedHandler}
                    onBlur={emailBlurHandler}
                    value={email}
                    autoComplete="off"
                />
                {loading && (
                    <BiLoader className="absolute right-5 animate-pulse h-4 top-1/2 -translate-y-1/2 " />
                )}
                {!loading && (
                    <button
                        onClick={verifyEmailHandler}
                        disabled={emailHasError || emailVerified}
                        className="absolute right-2 bg-primary top-1/2 -translate-y-1/2 text-sm lg:text-base px-3 lg:px-4 py-2  rounded text-white disabled:opacity-90 disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                        Verify
                    </button>
                )}
            </div>
            {emailHasError && (
                <p className="text-left text-sm mt-1 text-red-400">
                    Please enter a valid email address
                </p>
            )}
            {emailVerified && (
                <div className="flex items-center mt-1">
                    <BsCheckCircle className="text-[#14897b] " />
                    <p className="text-[#14897b] ml-2 text-sm lg:text-base">
                        Verification Sent
                    </p>
                </div>
            )}
            <p className="mt-4 lg:mt-6 mb-2 font-Poppins font-medium text-sm lg:text-base">
                What is your date of birth?
            </p>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label=""
                    value={birthDate}
                    onChange={(newValue) => setBirthDate(newValue)}
                    maxDate={maxDate}
                    minDate={minDate}
                    className="w-full"
                    format="DD/MM/YYYY"
                    slotProps={{
                        textField: {
                            placeholder: 'Enter Date of Birth'
                        }
                    }}
                    sx={{
                        input: {
                            color: 'black',
                            '&::placeholder': {
                                // <----- Add this.
                                opacity: 1
                            },
                            fontSize: '16px',
                            [theme.breakpoints.down('md')]: {
                                fontSize: '14px'
                            },
                            fontWeight: '600',
                            padding: '14px',
                            fontFamily: 'Poppins'
                        },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: '#bfbfbf'
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#6B7280'
                            }
                        }
                    }}
                />
            </LocalizationProvider>

            <div className="flex  justify-end mt-4 lg:mt-7 ">
                {/* <button
                    onClick={closeModal}
                    className={` ${config?.loginModal.buttonBg} w-24 lg:w-28 form-button`}
                >
                    Cancel
                </button> */}
                <button
                    onClick={nextStepHandler}
                    disabled={!formIsValid}
                    className={` ${config?.loginModal.buttonBg} w-24 lg:w-28 form-button`}
                >
                    Next
                </button>
            </div>
            {showErrorAlert && (
                <div class="alert alert-error w-full animate-pulse h-auto mt-2 ">
                    <label>{alertMessage}</label>
                </div>
            )}
        </>
    );
}

export default StepOne;
