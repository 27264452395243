import React from 'react';
import FiArrowDown from '@meronex/icons/fi/FiArrowDown';
import FiArrowRight from '@meronex/icons/fi/FiArrowRight';

function IconsLayoutWithArrow({
    className,
    iconClass,
    icon1,
    icon2,
    icon3,
    icontext1,
    icontext2,
    icontext3,
    icontextclassName,
    ArrowRightIcon,
    ArrowDownIcon
}) {
    return (
        <div
            className={`
                ${
                    className
                        ? className
                        : 'pt-5 md:px-40 lg:px-2 xl:px-28 2xl:px-56 3xl:px-56'
                } 
                      z-10 h-content mx-auto   
        `}
        >
            <div
                className={
                    ' flex flex-col lg:flex-row justify-center text-center w-full '
                }
            >
                <div className="mb-1 lg:w-1/3 ">
                    <img
                        className={
                            iconClass || 'mx-auto w-[82px] lg:w-[72px] mb-2'
                        }
                        src={icon1}
                        alt="icon1"
                    />
                    <p
                        className={
                            icontextclassName ||
                            'px-10 lg:px-5 xl:px-0 text-[18px] md:text-[19px] lg:[20px] xl:text-[18px] 3xl:text-[20px] text-secondary-focus  leading-6 font-CorporateS_Regular'
                        }
                    >
                        {icontext1}
                    </p>
                </div>
                <FiArrowRight
                    className={
                        ArrowRightIcon ||
                        'hidden lg:block self-center h-7 md:h-8 lg:h-10 w-7 md:w-8 my-10 lg:my-0 lg:w-10 mx-7 text-secondary-focus'
                    }
                />
                <FiArrowDown
                    className={
                        ArrowDownIcon ||
                        'block lg:hidden self-center h-7 md:h-8 lg:h-10 w-7 md:w-8 my-5 lg:my-0 lg:w-10 mx-7 text-secondary-focus'
                    }
                />

                <div className="lg:w-1/3 mb-1 ">
                    <img
                        className={
                            iconClass || 'mx-auto  w-[82px] lg:w-[72px]  mb-2'
                        }
                        src={icon2}
                        alt="icon2"
                    />
                    <p
                        className={
                            icontextclassName ||
                            'px-10 lg:px-5 xl:px-0 text-[18px] md:text-[19px] lg:[20px] xl:text-[18px] 3xl:text-[20px] text-secondary-focus leading-6 font-CorporateS_Regular'
                        }
                    >
                        {icontext2}
                    </p>
                </div>
                <FiArrowRight
                    className={
                        ArrowRightIcon ||
                        'hidden lg:block self-center h-7 md:h-8 lg:h-10 w-7 md:w-8  my-10 lg:my-0 lg:w-10 mx-7 text-secondary-focus'
                    }
                />
                <FiArrowDown
                    className={
                        ArrowDownIcon ||
                        'block lg:hidden self-center h-7 md:h-8 lg:h-10 w-7 md:w-8 my-5 lg:my-0 lg:w-10 mx-7 text-secondary-focus'
                    }
                />

                <div className="lg:w-1/3 mb-1 ">
                    <img
                        className={
                            iconClass || 'mx-auto  w-[82px] lg:w-[72px] mb-2'
                        }
                        src={icon3}
                        alt="icon3"
                    />
                    <p
                        className={
                            icontextclassName ||
                            'px-10 lg:px-5 xl:px-0 text-[18px] md:text-[19px] lg:[20px] xl:text-[18px] 3xl:text-[20px] text-secondary-focus leading-6 font-CorporateS_Regular'
                        }
                    >
                        {icontext3}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default IconsLayoutWithArrow;
