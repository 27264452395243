import React from 'react';
import SkeletonElement from '../../../SkeletonElement';

function SkeletonListCard() {
    return (
        <div className="shadow-xl p-2 h-[350px] w-full">
            <SkeletonElement type="thumbnail" size="h-36 2xl:h-36 w-full" />
            <div className="flex gap-x-2">
                <SkeletonElement type="thumbnail" size="h-16 w-16" />
                <div className="flex flex-col justify-center items-center w-[70%]">
                    <SkeletonElement type="text" />
                    <SkeletonElement type="text" />
                </div>
            </div>
            <SkeletonElement type="title" />
            <SkeletonElement type="title" />
        </div>
    );
}

export default SkeletonListCard;
