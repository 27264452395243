import React, { useEffect, useState } from 'react';
import { getConfig } from '../../../../utils/config';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from "yet-another-react-lightbox/dist/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import "yet-another-react-lightbox/dist/styles.css";
import ReactPlayer from 'react-player';

export default function ProjectGalleryNew({ projectGallery }) {

    const [index, setIndex] = useState(-1);

    const config = getConfig()
    const images = projectGallery?.filter((image) => image.urlType === "IMAGE")
    const videos = projectGallery?.filter((image) => image.urlType === "VIDEO")


    return (
        <div id='gallery' className="mt-5">
            {/* IMAGES */}
            {images?.length > 0 && <div className="flex flex-col">
                <h4 className={`z-10 text-xl mt-4 text-[#130E2F] mb-2 font-bold ${config.fontTitle}`}>Gallery</h4>
                <div className='z-10 grid grid-cols-3 md:grid-cols-7 lg:grid-cols-5 xl:grid-cols-7 auto-rows-fr w-full gap-2'>
                    {images.slice(0, 18).map((image, i) => (<img onClick={() => setIndex(i)} className={`${i == 0 ? 'row-span-2 col-span-2 min-h-[100px] xl:min-h-[120px] h-full' : 'row-span-1 h-[100px] xl:h-[120px] '} object-cover w-full cursor-pointer`} src={image.url} />))}
                </div>
                {(images.length) > 18 && <button onClick={() => setIndex(18)} className={`${config.fontBody} float-right mt-1 z-10 bg-gray-100 py-1 px-2 font-medium cursor-pointer`}>+ {(projectGallery.length) - 18 + ' more images'}</button>
                }

                <Lightbox
                    slides={images.map((image) => { return { src: image.url, height: null, width: null } })}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    plugins={[Fullscreen, Zoom]}
                />
            </div>}

            {/* VIDEOS */}
            {videos?.length > 0 &&
                <div className="flex flex-col mt-5">
                    <h4 className={`z-10 text-xl mt-4 text-[#130E2F] mb-2 font-bold ${config.fontTitle}`}>Video</h4>
                    <div className='z-10 grid grid-cols-1 md:grid-cols-7 lg:grid-cols-5 xl:grid-cols-7 auto-rows-fr w-full gap-2'>
                        {videos.map((item, i) => (
                            <div className={`${i == 0 ? 'md:row-span-2 md:col-span-2 md:h-full' : 'row-span-1 md:h-[100px] xl:h-[120px] '} object-cover w-full cursor-pointer`}>
                                <ReactPlayer
                                    height='100%'
                                    width='100%'
                                    url={item.url}
                                    controls={true} />
                            </div>))}
                    </div>
                </div>}
        </div>
    );
}
