import { getConfig } from '../../../utils/config';
import Footer2 from '../Footer2';
import Accordian from '../../Atoms/Accordian';

import FaFacebookF from '@meronex/icons/fa/FaFacebookF';
import FaTwitter from '@meronex/icons/fa/FaTwitter';
import FaLinkedinIn from '@meronex/icons/fa/FaLinkedinIn';
import FaYoutube from '@meronex/icons/fa/FaYoutube';
import FaInstagram from '@meronex/icons/fa/FaInstagram';

const Footer5 = () => {
    const config = getConfig();
    return (
        <>
            <footer className={'bg-[#2F3031] pt-0 px-2 xl:px-5 '}>
                <div className="w-[95%] xl:w-[90%] mx-auto ">
                    <div className="flex border-neutral-focus  lg:border-b-[1px]  xl:mx-10 lg:py-8 py-4">
                        <div
                            className={
                                config?.footer.footerClientLogoClassName
                                    ? config?.footer.footerClientLogoClassName
                                    : ' self-center w-full md:w-[20%] lg:w-[15%]'
                            }
                        >
                            <img
                                className=" h-auto px-0 my-4 md:my-0 w-full md:h-auto md:w-auto md:px-0 md:py-0 "
                                src={
                                    config?.footer?.footerClientLogo ||
                                    'https://cdn.loyalie.in/28602817.png'
                                }
                            />
                        </div>
                        <div className="lg:flex flex-col w-full lg:w-[33%] mx-auto hidden">
                            <div className="flex justify-items-start w-full mt-2">
                                <div
                                    className="rounded-full p-3 bg-neutral-focus cursor-pointer"
                                    onClick={() =>
                                        window.open(
                                            'https://www.facebook.com/MahindraLifespaces/'
                                        )
                                    }
                                >
                                    <FaFacebookF className="h-3 w-3 md:h-5 md:w-5 3xl:h-10 3xl:w-10  text-white" />
                                </div>

                                <div
                                    className="ml-4 rounded-full p-3 bg-neutral-focus cursor-pointer"
                                    onClick={() =>
                                        window.open(
                                            'https://twitter.com/Life_Spaces'
                                        )
                                    }
                                >
                                    <FaTwitter className="h-3 w-3 md:h-5 md:w-5 3xl:h-10 3xl:w-10  text-white" />
                                </div>

                                <div
                                    className="ml-4 rounded-full p-3 bg-neutral-focus cursor-pointer "
                                    onClick={() =>
                                        window.open(
                                            'https://in.linkedin.com/company/mahindra-lifespace-developers-ltd-'
                                        )
                                    }
                                >
                                    <FaLinkedinIn className="h-3 w-3 md:h-5 md:w-5 3xl:h-10 3xl:w-10 text-white" />
                                </div>

                                <div
                                    className="ml-4 rounded-full p-3 bg-neutral-focus cursor-pointer"
                                    onClick={() =>
                                        window.open(
                                            'https://www.youtube.com/channel/UCuf6DV_48ThAdIbF5Mkv-Uw'
                                        )
                                    }
                                >
                                    <FaYoutube className="h-3 w-3 md:h-5 md:w-5 3xl:h-10 3xl:w-10 text-white" />
                                </div>

                                <div
                                    className="ml-4 rounded-full p-3 cursor-pointer bg-neutral-focus"
                                    onClick={() =>
                                        window.open(
                                            'https://www.instagram.com/mahindralifespaces/'
                                        )
                                    }
                                >
                                    <FaInstagram className="h-3 w-3 md:h-5 md:w-5 3xl:h-10 3xl:w-10  text-white" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" mx-3 xl:mx-10 justify-between lg:block hidden">
                        <div className="border-neutral-focus  border-b-[1px] grid grid-cols-2 lg:grid-cols-5 gap-10 xl:gap-16 py-8">
                            <div>
                                <h1 className="text-[#ffffffe6] font-light font-SourceSansPro text-[24px] font-heading pb-2 leading-7">
                                    About Us
                                </h1>
                                <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/about-us/'
                                            )
                                        }
                                    >
                                        Overview
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/about-us/#corporate-philosophy'
                                            )
                                        }
                                    >
                                        Corporate Philosophy
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/leadership/'
                                            )
                                        }
                                    >
                                        Leadership
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/leadership/?category=board'
                                            )
                                        }
                                    >
                                        Board of Directors
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/sustainability/'
                                            )
                                        }
                                    >
                                        Sustainability
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/timeline/'
                                            )
                                        }
                                    >
                                        Timeline
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h1 className="text-[#ffffffe6] font-light font-SourceSansPro text-[24px] font-heading pb-2 leading-7">
                                    Our Businesses
                                </h1>
                                <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/real-estate-properties/'
                                            )
                                        }
                                    >
                                        Residential Homes
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindraworldcity.com/'
                                            )
                                        }
                                    >
                                        Integrated Cities
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindraworldcity.com/'
                                            )
                                        }
                                    >
                                        Industrial Clusters
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h1 className="text-[#ffffffe6] font-light font-SourceSansPro text-[24px] font-heading pb-2 leading-7">
                                    Information Centre
                                </h1>
                                <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/blog'
                                            )
                                        }
                                    >
                                        Blogs
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/media'
                                            )
                                        }
                                    >
                                        Media
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/video'
                                            )
                                        }
                                    >
                                        Videos
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h1 className="text-[#ffffffe6] font-light font-SourceSansPro text-[24px] font-heading pb-2 leading-7">
                                    Our Locations
                                </h1>
                                <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property'
                                            )
                                        }
                                    >
                                        Mumbai
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/real-estate-properties/chennai-property'
                                            )
                                        }
                                    >
                                        Chennai
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/real-estate-properties/nagpur-property'
                                            )
                                        }
                                    >
                                        Nagpur
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/real-estate-properties/pune-property'
                                            )
                                        }
                                    >
                                        Pune
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/real-estate-properties/gurgaon-property'
                                            )
                                        }
                                    >
                                        Gurugram
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/real-estate-properties/bangalore-property/'
                                            )
                                        }
                                    >
                                        Banglore
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h1 className="text-[#ffffffe6] font-light font-SourceSansPro text-[24px] font-heading pb-2 leading-7">
                                    Investors
                                </h1>
                                <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/investors/'
                                            )
                                        }
                                    >
                                        Overview
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/investor-center/?category=code-policies'
                                            )
                                        }
                                    >
                                        Codes & Policies
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/investor-center/?category=stock-information'
                                            )
                                        }
                                    >
                                        Stock Information
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/investor-center/?category=annual-reports'
                                            )
                                        }
                                    >
                                        Financial Reporting
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/investor-center/?category=investor-contact'
                                            )
                                        }
                                    >
                                        Shareholders & Services
                                    </li>
                                    <li
                                        className="hover:text-white"
                                        onClick={() =>
                                            window.open(
                                                'https://www.mahindralifespaces.com/investor-center/?category=credit-rating'
                                            )
                                        }
                                    >
                                        Credit Rating
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="border-neutral-focus  border-b-[1px] justify-between py-8">
                            <h1 className="text-[#ffffffe6] font-light font-SourceSansPro text-[24px] font-heading pb-2 leading-7">
                                Explore Homes
                            </h1>
                            <div className="grid grid-cols-2 lg:grid-cols-4 gap-10 xl:gap-16">
                                <div>
                                    <ul className="leadinflist-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/happinest-avadi/'
                                                )
                                            }
                                        >
                                            Chennai – Happinest – Avadi
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/mahindra-happinest-mahindra-world-city-chennai/'
                                                )
                                            }
                                        >
                                            Chennai – Happinest – World City
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/mahindra-lakewoods-chengalpattu/'
                                                )
                                            }
                                        >
                                            Chennai – Lakewoods
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/gurgaon-property/luminare-golf-course-extension-road/'
                                                )
                                            }
                                        >
                                            Gurgaon – Luminare
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate/mumbai-property/vicino-andheri-east/'
                                                )
                                            }
                                        >
                                            Mumbai – Vicino
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/mahindra-happinest-kalyan/'
                                                )
                                            }
                                        >
                                            Mumbai – Happinest – Kalyan
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/mahindra-happinest-kalyan-2/'
                                                )
                                            }
                                        >
                                            Mumbai – Happinest – Kalyan-2
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/happinest-palghar-1/'
                                                )
                                            }
                                        >
                                            Mumbai – Happinest – Palghar
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/mahindra-happinest-palghar-2/'
                                                )
                                            }
                                        >
                                            Mumbai – Happinest – Palghar-2
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/happinest-boisar/'
                                                )
                                            }
                                        >
                                            Mumbai – Happinest – Boisar
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/roots-kandivali-east/'
                                                )
                                            }
                                        >
                                            Mumbai – Roots – Kandivali
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/nagpur-property/bloomdale-mihan/'
                                                )
                                            }
                                        >
                                            Naagpur – Bloomdale
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/pune-property/mahindra-happinest-tathawade/'
                                                )
                                            }
                                        >
                                            Pune – Happinest – Tathawade
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/bangalore-property/mahindra-eden-off-kanakapura-road/'
                                                )
                                            }
                                        >
                                            Bangalore – Eden
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="border-neutral-focus  border-b-[1px] justify-between py-8">
                            <h1 className="text-[#ffffffe6] font-light font-SourceSansPro text-[24px] font-heading pb-2 leading-7">
                                Find Your Home
                            </h1>
                            <div className="grid grid-cols-2 lg:grid-cols-5 gap-10 xl:gap-16">
                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/1-bhk-flats-in-mumbai/'
                                                )
                                            }
                                        >
                                            1 BHK Flats In Mumbai
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/2-bhk-flats-in-mumbai/'
                                                )
                                            }
                                        >
                                            2 BHK Flats In Mumbai
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/bangalore-property/3-bhk-flats-in-bangalore/'
                                                )
                                            }
                                        >
                                            3 BHK Flats In Bengaluru
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/bangalore-property/4-bhk-flats-in-bangalore/'
                                                )
                                            }
                                        >
                                            4 BHK Flats In Bengaluru
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/1-bhk-flats-in-chennai/'
                                                )
                                            }
                                        >
                                            1 BHK Flats In Chennai
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/2-bhk-flats-in-chennai/'
                                                )
                                            }
                                        >
                                            2 BHK Flats In Chennai
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/3-bhk-flats-in-chennai/'
                                                )
                                            }
                                        >
                                            3 BHK Flats In Chennai
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/4-bhk-flats-in-chennai/'
                                                )
                                            }
                                        >
                                            4 BHK Flats In Chennai
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/nagpur-property/1-bhk-flats-in-nagpur/'
                                                )
                                            }
                                        >
                                            1 BHK Flats In Nagpur
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/nagpur-property/2-bhk-flats-in-nagpur/'
                                                )
                                            }
                                        >
                                            2 BHK Flats In Nagpur
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/nagpur-property/3-bhk-flats-in-nagpur/'
                                                )
                                            }
                                        >
                                            3 BHK Flats In Nagpur
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/pune-property/1-bhk-flats-in-pune/'
                                                )
                                            }
                                        >
                                            1 BHK Flats In Pune
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/pune-property/2-bhk-flats-in-pune/'
                                                )
                                            }
                                        >
                                            2 BHK Flats In Pune
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/pune-property/3-bhk-flats-in-pune/'
                                                )
                                            }
                                        >
                                            3 BHK Flats In Pune
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/gurgaon-property/3-bhk-flats-in-gurgaon/'
                                                )
                                            }
                                        >
                                            3 BHK Flats In Gurgaon
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/gurgaon-property/4-bhk-flats-in-gurgaon/'
                                                )
                                            }
                                        >
                                            4 BHK Flats In Gurgaon
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="block lg:hidden">
                        <Accordian title="About Us">
                            <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/about-us/'
                                        )
                                    }
                                >
                                    Overview
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/about-us/#corporate-philosophy'
                                        )
                                    }
                                >
                                    Corporate Philosophy
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/leadership/'
                                        )
                                    }
                                >
                                    Leadership
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/leadership/?category=board'
                                        )
                                    }
                                >
                                    Board of Directors
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/sustainability/'
                                        )
                                    }
                                >
                                    Sustainability
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/timeline/'
                                        )
                                    }
                                >
                                    Timeline
                                </li>
                            </ul>
                        </Accordian>

                        <Accordian title="Our Businesses">
                            <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/real-estate-properties/'
                                        )
                                    }
                                >
                                    Residential Homes
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindraworldcity.com/'
                                        )
                                    }
                                >
                                    Integrated Cities
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindraworldcity.com/'
                                        )
                                    }
                                >
                                    Industrial Clusters
                                </li>
                            </ul>
                        </Accordian>

                        <Accordian title="Information Centre">
                            <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/blog'
                                        )
                                    }
                                >
                                    Blogs
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/media'
                                        )
                                    }
                                >
                                    Media
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/video'
                                        )
                                    }
                                >
                                    Videos
                                </li>
                            </ul>
                        </Accordian>

                        <Accordian title="Our Locations">
                            <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property'
                                        )
                                    }
                                >
                                    Mumbai
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/real-estate-properties/chennai-property'
                                        )
                                    }
                                >
                                    Chennai
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/real-estate-properties/nagpur-property'
                                        )
                                    }
                                >
                                    Nagpur
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/real-estate-properties/pune-property'
                                        )
                                    }
                                >
                                    Pune
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/real-estate-properties/gurgaon-property'
                                        )
                                    }
                                >
                                    Gurugram
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/real-estate-properties/bangalore-property/'
                                        )
                                    }
                                >
                                    Banglore
                                </li>
                            </ul>
                        </Accordian>

                        <Accordian title="Investors">
                            <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/investors/'
                                        )
                                    }
                                >
                                    Overview
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/investor-center/?category=code-policies'
                                        )
                                    }
                                >
                                    Codes & Policies
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/investor-center/?category=stock-information'
                                        )
                                    }
                                >
                                    Stock Information
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/investor-center/?category=annual-reports'
                                        )
                                    }
                                >
                                    Financial Reporting
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/investor-center/?category=investor-contact'
                                        )
                                    }
                                >
                                    Shareholders & Services
                                </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/investor-center/?category=credit-rating'
                                        )
                                    }
                                >
                                    Credit Rating
                                </li>
                            </ul>
                        </Accordian>

                        <Accordian title="Explore Homes">
                            <div className="grid grid-cols-2 lg:grid-cols-4 gap-10 xl:gap-16">
                                <div>
                                    <ul className="leadinflist-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/happinest-avadi/'
                                                )
                                            }
                                        >
                                            Chennai – Happinest – Avadi
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/mahindra-happinest-mahindra-world-city-chennai/'
                                                )
                                            }
                                        >
                                            Chennai – Happinest – World City
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/mahindra-lakewoods-chengalpattu/'
                                                )
                                            }
                                        >
                                            Chennai – Lakewoods
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/gurgaon-property/luminare-golf-course-extension-road/'
                                                )
                                            }
                                        >
                                            Gurgaon – Luminare
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate/mumbai-property/vicino-andheri-east/'
                                                )
                                            }
                                        >
                                            Mumbai – Vicino
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/mahindra-happinest-kalyan/'
                                                )
                                            }
                                        >
                                            Mumbai – Happinest – Kalyan
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/mahindra-happinest-kalyan-2/'
                                                )
                                            }
                                        >
                                            Mumbai – Happinest – Kalyan-2
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/happinest-palghar-1/'
                                                )
                                            }
                                        >
                                            Mumbai – Happinest – Palghar
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/mahindra-happinest-palghar-2/'
                                                )
                                            }
                                        >
                                            Mumbai – Happinest – Palghar-2
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/happinest-boisar/'
                                                )
                                            }
                                        >
                                            Mumbai – Happinest – Boisar
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/roots-kandivali-east/'
                                                )
                                            }
                                        >
                                            Mumbai – Roots – Kandivali
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/nagpur-property/bloomdale-mihan/'
                                                )
                                            }
                                        >
                                            Naagpur – Bloomdale
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/pune-property/mahindra-happinest-tathawade/'
                                                )
                                            }
                                        >
                                            Pune – Happinest – Tathawade
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/bangalore-property/mahindra-eden-off-kanakapura-road/'
                                                )
                                            }
                                        >
                                            Bangalore – Eden
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Accordian>

                        <Accordian title="Find Your Home">
                            <div className="grid grid-cols-2 lg:grid-cols-5 gap-10 xl:gap-16">
                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/1-bhk-flats-in-mumbai/'
                                                )
                                            }
                                        >
                                            1 BHK Flats In Mumbai
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/mumbai-property/2-bhk-flats-in-mumbai/'
                                                )
                                            }
                                        >
                                            2 BHK Flats In Mumbai
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/bangalore-property/3-bhk-flats-in-bangalore/'
                                                )
                                            }
                                        >
                                            3 BHK Flats In Bengaluru
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/bangalore-property/4-bhk-flats-in-bangalore/'
                                                )
                                            }
                                        >
                                            4 BHK Flats In Bengaluru
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/1-bhk-flats-in-chennai/'
                                                )
                                            }
                                        >
                                            1 BHK Flats In Chennai
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/2-bhk-flats-in-chennai/'
                                                )
                                            }
                                        >
                                            2 BHK Flats In Chennai
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/3-bhk-flats-in-chennai/'
                                                )
                                            }
                                        >
                                            3 BHK Flats In Chennai
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/chennai-property/4-bhk-flats-in-chennai/'
                                                )
                                            }
                                        >
                                            4 BHK Flats In Chennai
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/nagpur-property/1-bhk-flats-in-nagpur/'
                                                )
                                            }
                                        >
                                            1 BHK Flats In Nagpur
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/nagpur-property/2-bhk-flats-in-nagpur/'
                                                )
                                            }
                                        >
                                            2 BHK Flats In Nagpur
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/nagpur-property/3-bhk-flats-in-nagpur/'
                                                )
                                            }
                                        >
                                            3 BHK Flats In Nagpur
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/pune-property/1-bhk-flats-in-pune/'
                                                )
                                            }
                                        >
                                            1 BHK Flats In Pune
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className="list-none text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] cursor-pointer grid  gap-y-1">
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/pune-property/2-bhk-flats-in-pune/'
                                                )
                                            }
                                        >
                                            2 BHK Flats In Pune
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/pune-property/3-bhk-flats-in-pune/'
                                                )
                                            }
                                        >
                                            3 BHK Flats In Pune
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/gurgaon-property/3-bhk-flats-in-gurgaon/'
                                                )
                                            }
                                        >
                                            3 BHK Flats In Gurgaon
                                        </li>
                                        <li
                                            className="hover:text-white"
                                            onClick={() =>
                                                window.open(
                                                    'https://www.mahindralifespaces.com/real-estate-properties/gurgaon-property/4-bhk-flats-in-gurgaon/'
                                                )
                                            }
                                        >
                                            4 BHK Flats In Gurgaon
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Accordian>
                    </div>

                    <div className="flex flex-col w-full lg:w-[33%] lg:hidden">
                        <div className="flex justify-center  mx-auto  w-full mt-6">
                            <div
                                className="rounded-full p-3 bg-neutral-focus cursor-pointer"
                                onClick={() =>
                                    window.open(
                                        'https://www.facebook.com/MahindraLifespaces/'
                                    )
                                }
                            >
                                <FaFacebookF className="h-3 w-3 md:h-5 md:w-5 3xl:h-10 3xl:w-10  text-white" />
                            </div>

                            <div
                                className="ml-4 rounded-full p-3 bg-neutral-focus cursor-pointer"
                                onClick={() =>
                                    window.open(
                                        'https://twitter.com/Life_Spaces'
                                    )
                                }
                            >
                                <FaTwitter className="h-3 w-3 md:h-5 md:w-5 3xl:h-10 3xl:w-10  text-white" />
                            </div>

                            <div
                                className="ml-4 rounded-full p-3 bg-neutral-focus cursor-pointer "
                                onClick={() =>
                                    window.open(
                                        'https://in.linkedin.com/company/mahindra-lifespace-developers-ltd-'
                                    )
                                }
                            >
                                <FaLinkedinIn className="h-3 w-3 md:h-5 md:w-5 3xl:h-10 3xl:w-10 text-white" />
                            </div>

                            <div
                                className="ml-4 rounded-full p-3 bg-neutral-focus cursor-pointer"
                                onClick={() =>
                                    window.open(
                                        'https://www.youtube.com/channel/UCuf6DV_48ThAdIbF5Mkv-Uw'
                                    )
                                }
                            >
                                <FaYoutube className="h-3 w-3 md:h-5 md:w-5 3xl:h-10 3xl:w-10 text-white" />
                            </div>

                            <div
                                className="ml-4 rounded-full p-3 cursor-pointer bg-neutral-focus"
                                onClick={() =>
                                    window.open(
                                        'https://www.instagram.com/mahindralifespaces/'
                                    )
                                }
                            >
                                <FaInstagram className="h-3 w-3 md:h-5 md:w-5 3xl:h-10 3xl:w-10  text-white" />
                            </div>
                        </div>
                    </div>

                    <div className="mx-3 lg:mx-10 justify-between lg:py-8 py-5">
                        <div className="grid grid-cols-1 mx-auto">
                            <p className="text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] text-center">
                                Copyright © 2022 Mahindra Lifespace Developers
                                Ltd. All rights reserved.
                            </p>
                            <ul className="list-none text-[#ffffff99] flex tracking-widest font-SourceSansPro text-[12px] cursor-pointer mx-auto pt-2">
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/terms-of-use/'
                                        )
                                    }
                                >
                                    Terms Of Use
                                </li>
                                <li className="px-1"> | </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/privacy-policy/'
                                        )
                                    }
                                >
                                    Privacy Policy
                                </li>
                                <li className="px-1"> | </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/disclaimer/'
                                        )
                                    }
                                >
                                    Disclaimer
                                </li>
                                <li className="px-1"> | </li>
                                <li
                                    className="hover:text-white"
                                    onClick={() =>
                                        window.open(
                                            'https://www.mahindralifespaces.com/sitemap/'
                                        )
                                    }
                                >
                                    Sitemap
                                </li>
                            </ul>
                            <p className="text-[#ffffff99] tracking-widest font-SourceSansPro text-[12px] text-center">
                                {' '}
                                Designed By{' '}
                                <a
                                    className="hover:text-white cursor-pointer"
                                    onClick={() =>
                                        window.open('https://screenroot.com/')
                                    }
                                >
                                    ScreenRoot
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <Footer2 />
        </>
    );
};

export default Footer5;
