import React from 'react';
import AiOutlineClose from '@meronex/icons/ai/AiOutlineClose';
import AiFillFileText from '@meronex/icons/ai/AiFillFileText';

function FileLayout({ file, index, removeFile }) {
    return (
        <>
            {file.format !== 'pdf' && file.format !== 'doc' ? (
                <div className="flex justify-between py-2 border-b-2 ">
                    {/* IMAGE + DETAILS */}
                    <div className=" w-full md:w-[80%] flex">
                        <li id={index} class="block h-20 w-[40%] md:w-[30%]">
                            <article
                                tabIndex="0"
                                class="group hasImage w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative  text-white lg:text-transparent lg:hover:text-white shadow-sm"
                            >
                                <img
                                    src={file.preview}
                                    alt="upload preview"
                                    class="img-preview w-full h-full sticky fileect-cover rounded-md bg-fixed"
                                />

                                <section class="flex items-center md:items-start justify-center md:justify-between rounded-md text-xs lg:text-sm break-words w-full h-full z-20 absolute bg-[#0000008C] lg:bg-transparent lg:hover:bg-[#000000A6] top-0 py-1 px-2 md:py-2 md:px-3">
                                    <p class="py-1 pr-1 size text-xs lg:text-[13px]">
                                        {file.formattedSize}
                                    </p>

                                    <AiOutlineClose
                                        onClick={() => removeFile(index)}
                                        className="hidden md:block delete my-1 focus:outline-none hover:bg-gray-300 rounded-md h-5 w-5 text-center"
                                    />
                                </section>
                            </article>
                        </li>
                        <div className="flex flex-col  pl-1 w-[60%] md:w-[70%]">
                            <div className="flex justify-between md:block">
                                <p className=" hidden md:block font-medium font-Poppins text-sm leading-4 pl-1 pt-1  text-black break-all mb-2">
                                    {file.name.length > 24 &&
                                        file.name.slice(0, 12) +
                                            '.....' +
                                            file.name.slice(-12)}
                                    {file.name.length <= 24 && file.name}
                                </p>
                                <p className="block md:hidden font-medium font-Poppins text-sm leading-4 pl-1 pt-1 pr-1 md:pr-0 text-black break-all mb-2">
                                    {file.name.length > 20 &&
                                        file.name.slice(0, 10) +
                                            '....' +
                                            file.name.slice(-10)}
                                    {file.name.length <= 20 && file.name}
                                </p>
                                <div class="flex flex-col md:hidden rounded-lg text-gray-500 text-xs md:text-base  ">
                                    <button
                                        onClick={() => removeFile(index)}
                                        class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md"
                                    >
                                        <svg
                                            class="pointer-events-none fill-current w-4 h-4 ml-auto "
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                class="pointer-events-none"
                                                d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* DELETE ICON */}
                    <div class="md:flex md:flex-col hidden md:w-[20%] rounded-lg text-gray-500  text-xs md:text-base  ">
                        <button
                            onClick={() => removeFile(index)}
                            class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md"
                        >
                            <svg
                                class="pointer-events-none fill-current w-4 h-4 ml-auto "
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    class="pointer-events-none"
                                    d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            ) : (
                <div className="flex justify-between py-2 border-b-2 ">
                    <div className=" w-full md:w-[80%] flex ">
                        <li id={index} class="block h-20 w-[40%] md:w-[30%]">
                            <article
                                tabIndex="0"
                                class="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm"
                            >
                                <img
                                    alt="upload preview"
                                    class="img-preview hidden w-full h-full sticky fileect-cover rounded-md bg-fixed"
                                />

                                <section class="flex flex-col items-center justify-center rounded-md text-xs lg:text-sm break-words w-full h-full z-20 absolute bg-[#0000008C] lg:bg-transparent lg:hover:bg-[#000000A6] top-0 py-1 px-2 md:py-2 md:px-3">
                                    <AiFillFileText className="w-7 h-7" />
                                    <p class="py-1 size text-xs lg:text-[13px] text-gray-200">
                                        {file.formattedSize}
                                    </p>
                                </section>
                            </article>
                        </li>
                        <div className="flex flex-col  pl-1 w-[60%] md:w-[70%]">
                            <div className="flex justify-between md:block">
                                <p className=" hidden md:block font-medium font-Poppins text-sm leading-4 pl-1 pt-1  text-black break-all mb-2">
                                    {file.name.length > 24 &&
                                        file.name.slice(0, 12) +
                                            '.....' +
                                            file.name.slice(-12)}
                                    {file.name.length <= 24 && file.name}
                                </p>
                                <p className="block md:hidden font-medium font-Poppins text-sm leading-4 pl-1 pt-1 pr-1 md:pr-0 text-black break-all mb-2">
                                    {file.name.length > 20 &&
                                        file.name.slice(0, 10) +
                                            '...' +
                                            file.name.slice(-10)}
                                    {file.name.length <= 20 && file.name}
                                </p>
                                <div class="flex flex-col md:hidden rounded-lg text-gray-500 text-xs md:text-base  ">
                                    <button
                                        onClick={() => removeFile(index)}
                                        class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md"
                                    >
                                        <svg
                                            class="pointer-events-none fill-current w-4 h-4 ml-auto "
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                class="pointer-events-none"
                                                d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* DELETE ICON */}
                    <div class="hidden md:flex md:flex-col md:w-[20%] rounded-lg text-gray-500  text-xs md:text-base  ">
                        <button
                            onClick={() => removeFile(index)}
                            class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md"
                        >
                            <svg
                                class="pointer-events-none fill-current w-4 h-4 ml-auto "
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    class="pointer-events-none"
                                    d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default FileLayout;
