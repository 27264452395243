import react, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import useAxios from '../../../../Hooks/use-axios';
import useSearchInput from '../../../../Hooks/useSearchInput';
import { cmsClient, deleteWinnreAuthToken, winnreAuth } from '../../../../utils/axios';
import { authActions } from '../../../../store/auth-slice';
import { getConfig } from '../../../../utils/config';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useData = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const tagID = queryParams.get('tagID');
    const categoryID = queryParams.get('categoryID');
    const brandID = queryParams.get('brandID');
    const brandName = queryParams.get('brandName');
    const tagName = queryParams.get('tagName');
    const categoryName = queryParams.get('categoryName');
    const search = queryParams.get('search');

    const callback = (searchValue) => {
        window?.webengage?.track('OfferSearched', {
            'searchQuery': `${searchValue}`
        });
        getOffers();
    };

    const [offers, setOffers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [tags, setTags] = useState([]);
    const [topOffers , setTopOffers]=useState([]);
    const [selectedTags, setSelectedTags] = useState(
        tagID ? [{ value: Number(tagID), label: tagName }] : []
    );
    const [selectedBrands, setSelectedBrands] = useState(
        brandID ? [{ value: Number(brandID), label: brandName }] : []
    );
    const [selectedCategories, setSelectedCategories] = useState(
        categoryID ? [{ value: Number(categoryID), label: categoryName }] : []
    );

    const [searchInput, handleChange] = useSearchInput(
        search ? search : '',
        callback
    );
    const [loadMoreOffers, setLoadMoreOffers] = useState(8);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [unauthorizedUserMessage, setUnauthorizedUserMessage] =
        useState(null);

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const isInternalWebview = useSelector(
        (state) => state.webView.internalWebview
    );

    const dispatch = useDispatch();
    const config = getConfig();

    const clearFilters = () => {
        setSelectedTags([]);
        setSelectedBrands([]);
        setSelectedCategories([]);
    };

    useEffect(() => {
        if (selectedTags.length > 0 || selectedCategories.length > 0 || selectedBrands.length > 0) {
            window?.webengage?.track('SearchFilterApplied', {
                selectedTags: selectedTags.map(tag => tag.label),
                selectedCategories: selectedCategories.map(category => category.label),
                selectedBrands: selectedBrands.map(brand => brand.label)
            });
        }
    }, [selectedTags, selectedCategories, selectedBrands]);

    const logout = () => {
        setOffers([]);
        setTotalPages(0);
        window?.webengage?.track('LogOut');
        window?.webengage?.user?.logout();
        if (config?.autoLogout) {
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('accessToken');
        } else {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
        }
        deleteWinnreAuthToken();
        dispatch(authActions.logout());
    };
    const {
        isLoading: loadingOffers,
        error: offersHasError,
        sendRequest: fetchOffers
    } = useAxios();

    const {isLoading: loadingSimilarDeals, sendRequest: fetchSimilarDeals } =
        useAxios();

    const date = moment().format();
    let tagList = selectedTags.map((tag) => tag.value);
    let categoryList = selectedCategories.map((category) => category.value);
    let vendorList = selectedBrands.map((brand) => brand.value);

    console.log("tagList" ,tagList);
    console.log("categoryList" ,categoryList);
    console.log("vendorList" , vendorList);
   

    const getOffersOfTheMonth = () => {
        winnreAuth({
            method: 'GET',
            url: '/winnre/user/deals/filter-v2?status=PUBLISHED&isAscending=false&sortBy=priority&categoryList=1,2,3,5&pageSize=10&tagList=52'
        }).then((data) => {
            setTopOffers(data?.content || []);
        });
    };

    const getOffers = () => {
        let url;
        if (isAuthenticated) {
            url = `/winnre/user/deals/filter-v2?`;
        } else {
            url = `/cms_service/deals/filter-v2?isVisibleToAll=true&clientId=${config?.id}&`;
        }
        const method = 'get';
        url =
            url +
            `${
                selectedTags.length > 0 ? `&tagList=${tagList.join()}` : ''
            }&pageNumber=${
                currentPage - 1
            }&endDate=${date}&status=PUBLISHED&pageSize=${loadMoreOffers}&isAscending=false&sortBy=priority&categoryList=${categoryList.join()}${
                searchInput.length > 0 ? `&searchValue=${searchInput}` : ''
            }${
                selectedBrands.length > 0
                    ? `&vendorIds=${vendorList.join()}`
                    : ''
            }`;

        fetchOffers(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url,
                method,
                isFormData: true
            },
            (data) => {
                console.log('Offers based on query', data);
                if (isAuthenticated) {
                    if (
                        data?.status === 'UNAUTHORIZED' ||
                        data?.status === 'FAILED'
                    ) {
                        setUnauthorizedUserMessage(
                            data?.status === 'UNAUTHORIZED'
                                ? 'Become our customer to avail deals.'
                                : 'Something went wrong. Could not load offers. Please try again!'
                        );
                        toast.error(
                            'Failed to load offers. Please try again later!'
                        );
                        setOffers([]);
                        setTotalPages(0);
                        return;
                    }
                    if (
                        data?.status === 'TOKEN_EXPIRED' ||
                        data?.status === 'INVALID_TOKEN'
                    ) {
                        if (isInternalWebview) {
                            dispatch(
                                authActions.setUnauthorizedUser(data.message)
                            );
                            return;
                        }
                        logout();
                        return;
                    }
                }
                if (data?.content.length === 0) {
                    getOffersOfTheMonth();
            
                }
                setTotalPages(data.totalPages || 1);
                setOffers(data.content || []);
                 
               
            }
        );
    };

    

    const getBrands = () => {
        cmsClient({
            method: 'GET',
            url: `/cms_service/vendors/getAllByFilter?clientId=${config?.id}${
                selectedCategories.length > 0
                    ? `&categoryIds=${categoryList.join()}`
                    : ''
            }`
        }).then((res) => {
            console.log('vendor data', res);
            setBrands(res.data.content);
        });
    };

    const getCategories = () => {
        cmsClient({
            method: 'GET',
            url: 'cms_service/categories/getAll'
        }).then((res) => {
            console.log("categoriesData", res);
            setCategories(res.data.content);
        });
    };

    const getTags = () => {
        cmsClient({
            method: 'GET',
            url: `/cms_service/tags/getAllByType?types=DEAL${
                selectedCategories.length > 0
                    ? `&categoryIds=${categoryList.join()}`
                    : ''
            }`
        }).then((res) => {
            console.log(res);
            setTags(res.data.content);
        });
    };

    useEffect(() => {
        getOffers();
    }, [
        currentPage,
        isAuthenticated,
        selectedCategories,
        selectedTags,
        loadMoreOffers,
        selectedBrands
    ]);

    useEffect(() => {
        if (brandID) {
            if (offers.length == 1) {
                history.replace(`/deals/${offers[0].id}`);
            }
        }
    }, [brandID, offers]);

    useEffect(() => {
        getTags();
        getBrands();
    }, [selectedCategories]);

    useEffect(() => {
        getCategories();
    }, []);

    return {
        offers,
        loadingOffers,
        offersHasError,
        selectedCategories,
        currentPage,
        setCurrentPage,
        totalPages,
        unauthorizedUserMessage,
        handleChange,
        searchInput,
        setSelectedTags,
        setSelectedBrands,
        setSelectedCategories,
        selectedTags,
        selectedBrands,
        clearFilters,
        categories,
        brands,
        tags,
        setLoadMoreOffers,
        loadMoreOffers,
        topOffers
    };
};

export default useData;
