import { useHistory } from 'react-router-dom';
import { getConfig } from '../../../utils/config';

const ReferralFormBanner = () => {
    const config = getConfig();
    const history = useHistory();
    return (
        <section
            id="referral_section"
            className={`${config?.referralForm?.height? config?.referralForm?.height : 'h-[70vh] lg:h-[60vh]'} relative`}
        >
            <div
                className={`absolute ${config?.referralForm?.removeBlur? '' : 'blur-sm'} hero w-full h-full`}
                style={{
                    backgroundImage: `linear-gradient(${config?.referralForm?.linearGradient.degree}, rgba(${config?.referralForm?.linearGradient?.R1}, ${config?.referralForm?.linearGradient.G1}, ${config?.referralForm?.linearGradient.B1}, ${config?.referralForm?.linearGradient.opacity1}),rgba(${config?.referralForm?.linearGradient?.R2}, ${config?.referralForm?.linearGradient.G2}, ${config?.referralForm?.linearGradient.B2}, ${config?.referralForm?.linearGradient.opacity2})), url(${config?.referralForm?.backgroundImage})`,
                    zIndex: '0'
                }}
            ></div>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:justify-center h-full">
                <div className="flex item-center justify-center w-full lg:w-[80%] mx-auto">
                    <h1
                        className={`col-span-1 text-center z-10 
                   ${
                       config?.referralForm?.formContent.headingClass
                           ? config?.referralForm?.formContent.headingClass
                           : 'text-white self-center font-brandFont text-4xl sm:text-5xl 2xl:text-6xl w-[80%] lg:w-full xl:w-[80%] font-semibold text-center tracking-widest'
                   }`}
                    >
                        {config?.referralForm?.formContent.heading}
                    </h1>
                </div>
                <div className="flex lg:items-center justify-center w-[80%] mx-auto self-start lg:self-center">
                    <button
                        className={`${
                            config?.referralForm?.formContent.loginButton
                                ? config?.referralForm?.formContent.loginButton
                                : 'text-white border-4 font-semibold'
                        }  col-span-1 self-center relative py-5 px-10`}
                        onClick={() => history.push('/gift-and-earn')}
                        style={{
                            borderImage: `url("${config?.referralForm?.borderUrl}") 1`
                        }}
                    >
                        Refer Now
                    </button>
                </div>
            </div>
            <div
                className={`flex absolute w-full lg:w-auto justify-center lg:mx-0 bottom-2 lg:left-6`}
            >
                <p className=" text-white text-sm lg:text-xs xl:text-sm mr-2 justify-between self-end">
                    Powered by
                </p>
                <div>
                    <img
                        className="h-7 lg:h-5 xl:h-7 self-center"
                        src="https://cdn.loyalie.in/64180896.png"
                    />
                </div>
            </div>
        </section>
    );
};
export default ReferralFormBanner;
