import React from 'react';
import { getConfig } from '../../../utils/config';
import ComponentsRenderer from '../../ComponentsRenderer';

function BannerNew({
    components,
    backgroundImage,
    backgroundImageMobile,
    linearGradient,
    bannerItemContainerClass,
    bannerContainerClass
}) {
    const config = getConfig();
    return (
        <section className={`relative ${config.bannerNegativeTopMarginClass}`}>
            {/* MOBILE BANNER */}
            <div className="block lg:hidden">
                <div
                    className={bannerContainerClass}
                    style={{
                        backgroundImage: `linear-gradient(90deg, rgba(${linearGradient?.R}, ${linearGradient?.G}, ${linearGradient?.B}, ${linearGradient?.opacity}),rgba(${linearGradient?.R}, ${linearGradient?.G}, ${linearGradient?.B}, ${linearGradient?.opacity})), url('${backgroundImageMobile}')`,
                        // backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                        // backgroundAttachment: 'fixed'
                    }}
                >
                    <div
                        className={` flex flex-col h-full w-full ${bannerItemContainerClass}`}
                    >
                        <ComponentsRenderer components={components} />
                    </div>
                </div>
            </div>
            {/* DESKTOP BANNER */}
            <div className="hidden lg:block">
                <div
                    className={bannerContainerClass}
                    style={{
                        backgroundImage: `linear-gradient(${'90deg' || linearGradient?.deg }, rgba(${linearGradient?.R || linearGradient?.R1}, ${linearGradient?.G || linearGradient?.G1}, ${linearGradient?.B || linearGradient?.B1}, ${linearGradient?.opacity || linearGradient?.opacity1}),rgba(${linearGradient?.R || linearGradient?.R2}, ${linearGradient?.G || linearGradient?.G2}, ${linearGradient?.B || linearGradient?.B2}, ${linearGradient?.opacity || linearGradient?.opacity2})), url('${backgroundImage}')`,
                        // backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                        // backgroundAttachment: 'fixed'
                    }}
                >
                    <div
                        className={` flex flex-col h-full w-full ${bannerItemContainerClass}`}
                    >
                        <ComponentsRenderer components={components} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BannerNew;
