import React, { useState } from 'react';
import CgMathMinus from '@meronex/icons/cg/CgMathMinus';
import CgMathPlus from '@meronex/icons/cg/CgMathPlus';
import AiOutlineDown from '@meronex/icons/ai/AiOutlineDown';
import AiOutlineUp from '@meronex/icons/ai/AiOutlineUp';

const Accordian = ({
    title,
    children,
    header,
    textColor,
    useArrows,
    wrapperClassName
}) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <div
            className={`accordion-wrapper text-${
                textColor || 'white'
            } py-3 border-[#ffffff1a] border-b-[1px] ${
                wrapperClassName ? wrapperClassName : ''
            }`}
            onClick={() => setOpen(!isOpen)}
        >
            <div className="flex flex-row items-center justify-between">
                {header ? (
                    header
                ) : (
                    <h1
                        className={`text-[#ffffffe6] font-light font-SourceSansPro text-[18px] font-heading pb-2 leading-7 inline ${
                            isOpen ? 'open' : ''
                        }`}
                    >
                        {title}
                    </h1>
                )}

                <div className="inline float-right ">
                    {isOpen ? (
                        useArrows ? (
                            <AiOutlineUp className="inline-block align-middle text-bold" />
                        ) : (
                            <CgMathMinus className="inline-block align-middle" />
                        )
                    ) : useArrows ? (
                        <AiOutlineDown className="inline-block align-middle text-bold" />
                    ) : (
                        <CgMathPlus className="inline-block align-middle" />
                    )}
                </div>
            </div>
            <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
                <div className="accordion-content py-3">{children}</div>
            </div>
        </div>
    );
};

export default Accordian;
