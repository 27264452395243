import React from 'react'

function Offers(props) {
  return (
    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.693"
                        height="20.041"
                        viewBox="0 0 17.693 20.041"
                    >
                        <g
                            id="_8150284_discount_bag_offer_shopping_ecommerce_icon"
                            data-name="8150284_discount_bag_offer_shopping_ecommerce_icon"
                            transform="translate(-4.68 -3.92)"
                        >
                            <path
                                id="Path_2646"
                                data-name="Path 2646"
                                d="M17.372,12.8l-.116-2.754a1.443,1.443,0,0,0-1.443-1.36H14.767V7.371a3.451,3.451,0,1,0-6.9,0V8.715H6.77a1.443,1.443,0,0,0-1.443,1.36l-.647,11.4a2.372,2.372,0,0,0,2.372,2.389h8.727a6.321,6.321,0,0,0,1.045.1,5.558,5.558,0,0,0,.6-11.082ZM9.11,7.371a2.207,2.207,0,0,1,4.4,0V8.715H9.11ZM7.069,22.584h0a1.112,1.112,0,0,1-.8-.332,1.2,1.2,0,0,1-.332-.879l.647-11.248a.2.2,0,0,1,.2-.2H7.865v.564a.63.63,0,1,0,1.244,0V9.926h4.4v.564a.63.63,0,1,0,1.244,0V9.926H15.8a.182.182,0,0,1,.2.182l.116,2.7a5.508,5.508,0,0,0-2.87,9.772Zm9.705,0a4.314,4.314,0,1,1-.149-8.627.3.3,0,0,0,.166,0h.116a4.339,4.339,0,0,1-.083,8.677Z"
                                transform="translate(0 0)"
                            />
                            <path
                                id="Path_2647"
                                data-name="Path 2647"
                                d="M13.318,12.57a1.112,1.112,0,1,0,1.095,1.095,1.112,1.112,0,0,0-1.095-1.095Zm0,1.477a.382.382,0,1,1,0-.747.373.373,0,0,1,0,.747Z"
                                transform="translate(4.949 5.701)"
                            />
                            <path
                                id="Path_2648"
                                data-name="Path 2648"
                                d="M12.707,12.442a1.095,1.095,0,0,0-1.095-1.112A1.112,1.112,0,0,0,10.5,12.442a1.095,1.095,0,0,0,1.112,1.095,1.078,1.078,0,0,0,1.095-1.095Zm-1.377,0a.365.365,0,1,1,.365.365.365.365,0,0,1-.365-.365Z"
                                transform="translate(3.836 4.883)"
                            />
                            <path
                                id="Path_2649"
                                data-name="Path 2649"
                                d="M14.358,11.362a.365.365,0,0,0-.514,0l-2.8,3.683a.382.382,0,0,0,0,.514.4.4,0,0,0,.232.083.348.348,0,0,0,.282-.149l2.8-3.617A.365.365,0,0,0,14.358,11.362Z"
                                transform="translate(4.126 4.835)"
                            />
                        </g>
                    </svg>
  )
}

export default Offers
