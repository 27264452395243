import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
// console.log(window.location.origin);
const domain = window.location.origin;
const KpConfig = {
    clientId:
        '73fcf82a96d9d71fc9bfd320e43e79367e46dbac04f7191b8679bf04e9a3334d',
    id: 36,
    name: 'Kolte Patil',
    fullName: 'Kolte Patil',
    programName:'Kolte Patil Nest',
    theme: 'kpTheme',
    favicon: 'https://www.koltepatil.com/assets/dist/images/favicon.ico',
    primaryColor: '#f89520',
    fontBody: `${getFont('KP').body}`,
    fontTitle: `${getFont('KP').bold}`,
    googleConfig: {
        gID: 'G-NY94Y4SD0L',
        keywordsContent:
            'Kolte Patil priority real estate referral programs, Kolte Patil real estate referral program, Kolte Patil real estate refer and earn, Kolte Patil priority real estate project refer, Kolte Patil real estate, Kolte Patil priority real estate loyalty program, Kolte Patil real estate news, Kolte Patil real estate upcoming projects in Bengaluru, Kolte Patil real estate launch Kolte Patil priority real estate, Kolte Patil real estate launch, Kolte Patil real estate program, Kolte Patil real estate projects, Kolte Patil real estate blogs, Kolte Patil real estate offers, Kolte Patil real estate upcoming projects in Bengaluru, Kolte Patil real estate events, Kolte Patil, Kolte Patil priority, Kolte Patil real estate best residential projects, Kolte Patil real estate real estate developers, All New Projects by Kolte Patil real estate Builders & Developers, Ongoing Projects by Kolte Patil real estate Pvt Ltd, Kolte Patil real estate Bengaluru, Kolte Patil real estate new program, Kolte Patil press release, Kolte Patil upcoming projects, Kolte Patil property group, Kolte Patil bangalore, Kolte Patil real estate, Kolte Patil reit, jitu virwani, Kolte Patil commercial projects, Kolte Patil projects, Kolte Patil projects in bangalore',
        descriptionContent:
            'Kolte Patil Nest is an exclusive referral and loyalty program designed for Kolte-Patil residents. The program aims to make you the brand ambassador of the Kolte-Patil lifestyle by adding more value to your home ownership and creating delight.',
        authorContent: 'Kolte Patil'
    },

    facebookPixelConfig: {
        id: '1414850269057649', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~~c2ab3682',
    webengageAPIKey:'3c76a6c6-1ea3-49dc-9e80-22a3b0f41bdb',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: false,

    logoLeft: {
        name: 'Kolte Patil Nest Logo',
        baseUrl: 'https://cdn.loyalie.in/1695024116218_Logo',
        stickyUrl: 'https://cdn.loyalie.in/1695024116218_Logo',
        stickyNavBarLogoClass:
            'relative h-10 lg:h-10 xl:h-16 left-2 lg:left-8 ease-in duration-300',
        baseNavBarLogoClass:
            ' relative h-10 lg:h-10 xl:h-20 left-2 lg:left-8 ease-out duration-300'
    },

    // logoRight: {
    //     name: 'Kolte Patil Logo',
    //     url: 'https://cdn.loyalie.in/81564804.png',
    //     stickyNavBarLogoClass:
    //         'relative h-10 lg:h-10 xl:h-16  right-2 lg:right-8 ease-in duration-300',
    //     baseNavBarLogoClass:
    //         ' relative h-10 lg:h-10 xl:h-20 right-2 lg:right-8 ease-out duration-300'
    // },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('KP').heading}`,
        height: 'h-16 lg:h-20 ease-out duration-300',
        stickyHeight: 'h-16 lg:h-18 ease-in duration-300 	',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-white',
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-white lg:drop-shadow-md ', // default: transparent
            containerClass: ' ',
            itemClass:
                'uppercase lg:mr-7 text-primary-focus lg:text-primary-focus hover:text-primary-focus  text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-bold',
            dropDownContainerClass: `text-primary-focus lg:text-primary-focus hover:text-primary-focus  focus:text-primary-focus lg:focus:shadow-outline `,
            dropDownItemClass:
                'tracking-widest hover:text-primary text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-bold hover:border-[3px] border-accent'
        },
        stickyNavBar: {
            border: true,
            borderClass: ' ',
            backgroundColor: 'bg-white lg:drop-shadow-md ', //default: primary-content,
            containerClass: ' ', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 text-primary-focus lg:text-primary-focus hover:text-primary-focus text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-bold' // Each list element in NavBar (Text color, hover color, size)
        },
        showActiveLinkColor: false
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 ${
        getFont('KP').bold
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage: 'https://cdn.loyalie.in/13003282.jpg',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/68863417.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    bannerContainerClass:
                        'h-[70vh] md:h-[85vh] lg:h-[88vh] bg-right-bottom lg:bg-top ',
                    bannerItemContainerClass:
                        'items-start justify-center pt-28 md:pt-0 px-8 pl-5 md:pl-10 lg:pl-20',
                    position: 'text-left',

                    components: [
                        {
                            type: 'TEXT',
                            content: 'Nurturing ',
                            className: ` text-transparent bg-clip-text bg-gradient-to-r from-accent to-primary uppercase text-[20px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-5  md:leading-8 font-bold ${
                                getFont('KP').bold
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'w-[80%] text-left md:mt-5 lg:mt-0 md:w-[50%] xl:w-[45%] 2xl:w-[50%]',
                            components: [
                                // {
                                //     type: 'TEXT',
                                //     content: 'Nurturing the home feeling with a little bit more.',
                                //     className: ` text-white  text-[28px] md:text-[25px] lg:text-[30px] leading-8 xl:leading-[2.3rem] 2xl:leading-[2.9rem] xl:text-[35px] 2xl:text-[40px]  ${getFont('KP').heading
                                //         }`
                                // },

                                {
                                    type: 'TEXT',
                                    content: 'the ',
                                    className: `inline-block text-white uppercase pt-1 xl:pt-2 2xl:pt-3 text-center text-[20px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-5  md:leading-8 font-normal ${
                                        getFont('KP').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: 'home ',
                                    className: `inline-block mx-2 md:mx-3 text-transparent bg-clip-text bg-gradient-to-r from-accent to-primary uppercase pt-1 xl:pt-2 2xl:pt-3 text-center text-[20px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-5  md:leading-8 font-bold ${
                                        getFont('KP').bold
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: ' feeling with',
                                    className: ` inline-block text-white uppercase pt-1 xl:pt-2 2xl:pt-3 text-center text-[20px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-5  md:leading-8 font-normal ${
                                        getFont('KP').heading
                                    }`
                                },

                                {
                                    type: 'TEXT',
                                    content: 'a little bit more.',
                                    className: ` text-white uppercase pt-1 md:pt-0.5 xl:pt-1.5 2xl:pt-2 text-left text-[20px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-5  md:leading-8 font-normal ${
                                        getFont('KP').heading
                                    }`
                                }
                                // {
                                //     type: 'TEXT',
                                //     content: ' a little bit more.',
                                //     className: ` inline-block text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-3 text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${getFont('KP').bold
                                //         }`
                                // }
                            ]
                        },
                        {
                            type: 'BUTTON',
                            content: 'Click to Refer Now',
                            className: `md:mx-0 text-left mt-4 xl:mt-6  text-[15px] md:text-[17px] uppercase tracking-wider bg-transparent text-white font-normal py-2 2xl:py-2 px-3 md:px-6 border-[3px]  border-accent hover:border-primary ${
                                getFont('KP').body
                            }`,
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH_AND_SCROLL',
                            scrollTo: 'referral_section'
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: '',
                    url: 'https://cdn.loyalie.in/15293547.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.10',
                        size: '70%'
                    },
                    className: 'flex flex-col justify-center text-center px-2',
                    components: [
                        {
                            type: 'CUSTOM',
                            id: 'overview',
                            className:
                                'flex flex-col justify-center text-center my-10 xl:my-16 bg-white w-[90%] border-[3px] border-accent mx-auto ',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className: 'w-full md:pt-5',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content: 'About ',
                                            className: `inline-block text-secondary-focus uppercase pt-6 text-center text-[20px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-normal ${
                                                getFont('KP').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content: ' KOLTE PATIL Nest',
                                            className: ` inline-block ml-2  text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-6 text-center text-[20px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                                                getFont('KP').bold
                                            }`
                                        }
                                    ]
                                },

                                {
                                    type: 'TEXT',
                                    content:
                                        'Kolte Patil Nest is an exclusive referral and loyalty program designed for Kolte-Patil residents. The program aims to make you the brand ambassador of the Kolte-Patil lifestyle by adding more value to your home ownership and creating delight.',
                                    className: `pt-6 text-[17px] md:text-[18px] h-full xl:text-[20px] text-secondary-focus leading-6 xl:leading-[25px] self-center w-[90%] xl:w-[85%] 2xl:w-[92%] break-words mb-4  ${
                                        getFont('KP').body
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: `Kolte-Patil stands by its motto of "Creation, not Construction." The loyalty program is another step towards this goal, where we create a community out of all who nest their dreams with us. `,
                                    className: `text-[17px] md:text-[18px] h-full xl:text-[20px] text-secondary-focus leading-6 xl:leading-[25px] self-center w-[90%] xl:w-[85%] 2xl:w-[92%] break-words mb-4  ${
                                        getFont('KP').body
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'The Kolte-Patil loyalty program is aimed at better serving and appreciating every member of our family. We firmly believe that the success of our brand is based on customer happiness, and Kolte Patil Nest was established to actively pursue this goal.',
                                    className: `text-[17px] md:text-[18px] h-full xl:text-[20px] text-secondary-focus leading-6 xl:leading-[25px] self-center w-[90%] xl:w-[85%] 2xl:w-[92%] break-words font-normal ${
                                        getFont('KP').body
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'self-center m-auto w-[20%] lg:w-[10%] bg-accent border-none h-[5px]',
                                    classNameContainer: 'my-5 xl:my-7'
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'Kolte Patil Nest brings you a world of exclusive privileges:',
                                    className: `${
                                        getFont('KP').heading
                                    } text-secondary self-center text-center w-[90%] text-[20px] md:text-[24px] lg:text-[25px] xl:text-[30px] leading-7 xl:leading-9 pb-4 xl:pb-5 font-semibold `
                                },

                                {
                                    type: 'TEXT',
                                    content: 'Create Your Community',
                                    className: `${
                                        getFont('KP').heading
                                    } text-secondary-focus uppercase self-center text-center w-[90%] text-[20px] md:text-[22px] lg:text-[25px] xl:text-[30px] 2xl:text-[35px] leading-7 xl:leading-9 pb-4 xl:pb-5 font-normal `
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'Get rewarded for creating a community by referring your loved ones to us.',
                                    className: `text-[17px] md:text-[18px] h-full xl:text-[20px] text-secondary-focus leading-6 xl:leading-[27px] self-center w-[90%] xl:w-[95%] 2xl:w-[90%] break-words mb-3 xl:mb-4  ${
                                        getFont('KP').body
                                    }`
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-full mt-5 lg:mt-0 leading-6 md:leading-9 text-center -mb-6 md:-mb-5 2xl:-mb-6 lg:leading-none',
                                    components: [
                                        {
                                            type: 'BUTTON',
                                            content: 'Refer Now',
                                            className: `md:mx-0 mt-6  text-[15px] md:text-[17px] uppercase tracking-wider bg-white text-secondary-focus  font-semibold py-2 2xl:py-[11px] px-5 md:px-6 border-[3px] border-accent hover:border-primary ${
                                                getFont('KP').body
                                            }`,

                                            path: 'gift-and-earn',
                                            onClick: 'CHANGE_PATH_AND_SCROLL',
                                            scrollTo: 'referral_section'
                                        }
                                    ]
                                }
                            ]
                        },

                        {
                            type: 'CUSTOM',
                            id: ' ',
                            className:
                                ' overflow-x-scroll lg:overflow-x-hidden overflow-y-hidden flex lg:grid lg:grid-cols-3 lg:justify-center text-center pt-5 pb-10 md:pb-20 mb-5 w-[90%] mx-auto bg-white  ',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'min-w-[80vw] lg:min-w-full mt-5 lg:mt-0 leading-6 md:leading-9 text-center lg:leading-none border-t-[3px] border-b-[3px] border-l-[3px] border-r-[1.5px] border-accent ',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/12495344.jpg',
                                            position: 'center',
                                            classNameContainer:
                                                'w-[100%] flex lg:gap-0 gap-0 brightness-[0.70] hover:brightness-100 '
                                        },
                                        {
                                            type: 'TEXT',
                                            content: 'Embrace Indulgence',
                                            className: `text-primary uppercase pt-6 text-center text-[20px] md:text-[24px] lg:text-[20px] xl:text-[25px] w-[50%] mx-auto tracking-wider leading-6 xl:leading-7 font-bold ${
                                                getFont('KP').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Experience the privilege of being a Kolte-Patil resident and embrace the indulgence of curated offers and promotions from premium PAN-Indian brands.',
                                            className: `pt-5 text-[17px] md:text-[18px] xl:text-[20px] text-secondary-focus leading-5 xl:leading-[25px] w-[90%] xl:w-[95%] 2xl:w-[75%] break-words  mx-auto h-[150px] xl:h-[145px] ${
                                                getFont('KP').body
                                            }`
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full -mb-6 md:-mb-5 xl:-mb-5 2xl:-mb-6',
                                            components: [
                                                {
                                                    type: 'BUTTON',
                                                    content:
                                                        'View Deals & Discounts',
                                                    className: `md:mx-0  mt-6  text-[15px]  xl:text-[17px] uppercase  tracking-wider bg-white text-secondary-focus  font-semibold py-2 2xl:py-[11px] px-3 xl:px-6 border-[3px] border-accent hover:border-primary ${
                                                        getFont('KP').body
                                                    }`,
                                                    path: 'deals',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'min-w-[80vw] lg:min-w-full mt-5 lg:mt-0 leading-6 md:leading-9 text-center lg:leading-none border-t-[3px] border-b-[3px] border-r-[2px] border-l-[2px] border-accent ',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/28507065.jpg',
                                            position: 'center',
                                            classNameContainer:
                                                'w-[100%] flex lg:gap-0 gap-0 brightness-[0.70] hover:brightness-100 '
                                        },
                                        {
                                            type: 'TEXT',
                                            content: 'WORKSHOPS AND EVENTS',
                                            className: `text-primary uppercase pt-6 text-center text-[20px] md:text-[24px] lg:text-[20px] xl:text-[25px] w-[50%] mx-auto tracking-wider leading-6 xl:leading-7 font-bold ${
                                                getFont('KP').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Participate in workshops & events designed specifically to enrich your living experience with us.',
                                            className: `pt-5 text-[17px] md:text-[18px] xl:text-[20px] text-secondary-focus leading-5 xl:leading-[25px] w-[70%] xl:w-[55%] 2xl:w-[55%] break-words  mx-auto h-[150px] xl:h-[145px] ${
                                                getFont('KP').body
                                            }`
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full -mb-6 md:-mb-5 xl:-mb-5 2xl:-mb-6',
                                            components: [
                                                {
                                                    type: 'BUTTON',
                                                    content: 'Register Here',
                                                    className: `md:mx-0 mt-6  text-[15px] xl:text-[17px] uppercase tracking-wider bg-white text-secondary-focus  font-semibold py-2 2xl:py-[11px] px-3 xl:px-6 border-[3px] border-accent hover:border-primary ${
                                                        getFont('KP').body
                                                    }`,
                                                    path: 'events',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'min-w-[80vw] lg:min-w-full mt-5 lg:mt-0 leading-6 md:leading-9 text-center lg:leading-none border-t-[3px] border-b-[3px] border-r-[3px] border-l-[1.5px] border-accent ',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/29824788.jpg',
                                            position: 'center',
                                            classNameContainer:
                                                'w-[100%] flex lg:gap-0 gap-0 brightness-[0.70] hover:brightness-100 '
                                        },
                                        {
                                            type: 'TEXT',
                                            content: 'BLOGS',
                                            className: `text-primary uppercase pt-6 text-center text-[20px] md:text-[24px] lg:text-[20px] xl:text-[25px] w-[50%] mx-auto tracking-wider leading-6 xl:leading-7 font-bold ${
                                                getFont('KP').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Unlock community interests and conversations by actively interacting on our blog forum and sharing your experience with others.',
                                            className: `pt-5 text-[17px] md:text-[18px] xl:text-[20px] text-secondary-focus leading-5 xl:leading-[25px] w-[80%] md:w-[90%] xl:w-[75%] 2xl:w-[70%] break-words mx-auto h-[150px] xl:h-[145px] ${
                                                getFont('KP').body
                                            }`
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full -mb-6 xl:-mb-5 2xl:-mb-6',
                                            components: [
                                                {
                                                    type: 'BUTTON',
                                                    content: 'Read Blogs',
                                                    className: `md:mx-0  mt-6  text-[15px] xl:text-[17px] uppercase  tracking-wider bg-white text-secondary-focus  font-semibold py-2 2xl:py-[11px] px-3 xl:px-6 border-[3px] border-accent hover:border-primary ${
                                                        getFont('KP').body
                                                    }`,
                                                    path: 'blogs',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },

                //REFERRAL FORM
                {
                    type: 'REFERRAL_FORM_BANNER'
                },

                // OUR PROJECTS
                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/15293547.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.10',
                        size: '70%'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] py-10 md:pt-20 pb-36 -mb-28',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'w-[90%] mx-auto border-[3px] border-accent relative md:pt-10',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className: 'w-full ',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content: 'latest ',
                                            className: `inline-block text-secondary-focus uppercase pt-6 text-center text-[20px] md:text-[24px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                                                getFont('KP').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content: ' blogs',
                                            className: ` inline-block ml-2  text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-6 text-center text-[20px] md:text-[24px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-7 2xl:leading-8 font-bold ${
                                                getFont('KP').bold
                                            }`
                                        }
                                    ]
                                },

                                // {
                                //     type: 'DIVIDER',
                                //     className:
                                //         'self-center m-auto w-[15%] lg:w-[8%] bg-accent border-none h-[4px]',
                                //     classNameContainer: 'my-2'
                                // },
                                {
                                    type: 'BLOG_LAYOUT_VERTICAL1',
                                    className:
                                        'w-full h-auto flex flex-col-reverse lg:flex-row mt-10 md:px-10 xl:px-14 2xl:px-16 justify-center ',
                                    classNameContainer:
                                        ' w-full px-5 md:px-44 lg:px-0 xl:w-full overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-3 gap-x-4 md:gap-x-8 lg:gap-x-6 xl:gap-x-10 2xl:gap-x-16 3xl:gap-x-20 gap-y-14 '
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            className: 'w-[90%] mx-auto relative mt-20 ',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'md:flex w-full items-center border-[3px] border-accent md:border-none',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'md:w-[25%] text-center md:text-left',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-full pt-5 md:pt-0',
                                                    components: [
                                                        {
                                                            type: 'TEXT',
                                                            content: 'Group ',
                                                            className: ` inline-block md:block text-secondary-focus uppercase text-[22px] md:text-[24px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                                                                getFont('KP')
                                                                    .heading
                                                            }`
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                ' Projects',
                                                            className: ` inline-block  ml-2 md:block md:ml-0 text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-3 text-[22px] md:text-[24px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-7 2xl:leading-8 font-bold ${
                                                                getFont('KP')
                                                                    .bold
                                                            }`
                                                        }
                                                    ]
                                                },
                                                // {
                                                //     type: 'TEXT',
                                                //     content: 'Group Projects',
                                                //     className: `text-primary text-[22px] w-[70%] md:text-[24px] lg:text-[25px] xl:text-[30px] leading-8 font-bold uppercase  ${getFont('KP').heading
                                                //         }`
                                                // },

                                                {
                                                    type: 'DIVIDER',
                                                    className:
                                                        'hidden md:block w-[20%] lg:w-[20%] bg-accent border-none h-[4px]',
                                                    classNameContainer:
                                                        'md:my-7'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-[80%] mx-auto md:mx-0 md:w-[75%] md:border-[3px] md:border-accent ',
                                            components: [
                                                {
                                                    type: 'CAROUSEL',
                                                    customSlide: true,
                                                    caraouselClassName:
                                                        'md:py-10 py-1 relative lg:py-10 md:-ml-10',
                                                    imageContainerClassName:
                                                        'm-4 relative',
                                                    imageClassName:
                                                        'w-full h-40 lg:h-56 lg:bg-cover object-cover',
                                                    captionClass: ` text-sm lg:text-lg uppercase leading-8 ${
                                                        getFont('KP').body
                                                    }`,
                                                    arrows: true,
                                                    autoplay: true,
                                                    autoplaySpeed: 3000,
                                                    infinite: true,
                                                    dots: false,
                                                    slidesToShow: 3,
                                                    pauseOnHover: true,
                                                    speed: 3000,
                                                    onImageClick:
                                                        openURLNewWindow,
                                                    imgArr: [
                                                        {
                                                            imgUrl: 'https://koltepatilprod.s3.ap-south-1.amazonaws.com/Document/Project/66/q3NNs6ZxYb.jpg',
                                                            alt: 'img',
                                                            url: `${domain}/nest-home/projects/detail?projectId=66`,

                                                            caption:
                                                                '24 K Manor'
                                                        },
                                                        {
                                                            imgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/22/73121608.jpg',
                                                            alt: 'img',
                                                            url: `${domain}/nest-home/projects/detail?projectId=22`,

                                                            caption: 'Aros'
                                                        },
                                                        {
                                                            imgUrl: 'https://koltepatilprod.s3.ap-south-1.amazonaws.com/Document/Project/62/lblJYh7TkJ.jpg',
                                                            alt: 'img',
                                                            url: `${domain}/nest-home/projects/detail?projectId=62`,

                                                            caption: 'Alora'
                                                        },
                                                        {
                                                            imgUrl: 'https://koltepatilprod.s3.ap-south-1.amazonaws.com/Document/Project/67/4RWOV5j2Si.jpg',
                                                            alt: 'img',
                                                            url: `${domain}/nest-home/projects/detail?projectId=67`,

                                                            caption: 'Atmos'
                                                        },
                                                        {
                                                            imgUrl: 'https://koltepatilprod.s3.ap-south-1.amazonaws.com/Document/Project/65/7L0b1wSdxc.jpg',
                                                            alt: 'img',
                                                            url: `${domain}/nest-home/projects/detail?projectId=65`,

                                                            caption:
                                                                '24K Altura'
                                                        },
                                                        {
                                                            imgUrl: 'https://koltepatilprod.s3.ap-south-1.amazonaws.com/Document/Project/58/AKUUBs0WCS.jpg',
                                                            alt: 'img',
                                                            url: `${domain}/nest-home/projects/detail?projectId=58`,
                                                            caption: 'K52'
                                                        },
                                                        {
                                                            imgUrl: 'https://koltepatilprod.s3.ap-south-1.amazonaws.com/Document/Project/56/0p7buM2N57.jpg',
                                                            alt: 'img',
                                                            url: `${domain}/nest-home/projects/detail?projectId=56`,
                                                            caption: 'Equa'
                                                        },
                                                        {
                                                            imgUrl: 'https://koltepatilprod.s3.ap-south-1.amazonaws.com/Document/Project/13/rwfBH2pFmf.jpg',
                                                            alt: 'img',
                                                            url: `${domain}/nest-home/projects/detail?projectId=13`,

                                                            caption:
                                                                'Little Earth'
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                },
                {
                    deals: {
                        show: false,
                        name: 'BENEFITS',
                        path: '/deals'
                    }
                }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0.3'
                    },
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Kolte-Patil/Images/13514712.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Kolte-Patil/Images/13514712.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center md:text-left text-white text-[15px] md:text-[20px] xl:text-[22px] uppercase my-5 tracking-widest w-full md:w-[40%] font-semibold md:font-normal lg:w-[48%] ml-auto  lg:leading-7 ${
                                getFont('PURTI').body
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'flex -full md:w-[40%] lg:w-[48%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `mx-auto lg:mx-0  text-left text-[15px] md:text-[17px] 2xl:text-[18px] tracking-wider bg-transparent text-white font-semibold py-2 md:py-2 2xl:py-2 px-5 md:px-6 border-[3px] border-accent hover:border-primary ${
                                        getFont('KP').heading
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Kolte-Patil/Images/13514712.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Kolte-Patil/Images/13514712.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[60vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').body
                    //         }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-black text-[15px] leading-10 md:text-[20px] xl:text-[45px] font-bold tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').heading
                    //         }`
                    // },
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex w-[70%] ml-auto justify-center mt-7', components: [{
                    //         type: 'BUTTON',
                    //         content: 'Start Referring Now',
                    //         scrollTo: 'referral_section',
                    //         onClick: 'SCROLL'
                    //     }]
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold tracking-widest bg-primary text-white px-8 md:px-6 py-3 lg:mt-2 xl:mt-0 mx-auto ${
                getFont('PURTI').heading
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hero hero-content max-w-full h-[80vh] lg:h-[88vh] lg:h-screen bg-center md:bg-top  mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage: 'https://cdn.loyalie.in/25876909.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/82724704.jpg',
            components: [
                {
                    type: 'CUSTOM',
                    className:
                        'w-full lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'KOLTE PATIL ',
                            className: `inline-block text-white uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-6 md:leading-8 font-normal ${
                                getFont('KP').heading
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: ' READERS’ CLUB',
                            className: `inline-block md:ml-3 text-transparent bg-clip-text bg-gradient-to-r from-accent to-primary uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                                getFont('KP').bold
                            }`
                        }
                    ]
                },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-3 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-2 md:mt-5 md:mb-3 leading-5 text-[17px] md:text-base lg:text-[17px] tracking-wider ${
                        getFont('KP').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `md:mx-0 text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[18px] tracking-wider bg-transparent text-white font-normal py-2 md:py-2 2xl:py-2 px-5 md:px-6 border-[3px] border-accent hover:border-primary ${
                        getFont('KP').heading
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'CUSTOM',
                    className:
                        'w-full lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'KOLTE PATIL ',
                            className: `inline-block text-white uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-6 md:leading-8 font-normal ${
                                getFont('KP').heading
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: ' READERS’ CLUB',
                            className: `inline-block md:ml-3 text-transparent bg-clip-text bg-gradient-to-r from-accent to-primary uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                                getFont('KP').bold
                            }`
                        }
                    ]
                },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-3 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-2 md:mt-5 md:mb-3 leading-5 text-[17px] md:text-base lg:text-[17px] tracking-wider ${
                        getFont('KP').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `md:mx-0 text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[18px] tracking-wider bg-transparent text-white font-normal py-2 md:py-2 2xl:py-2 px-5 md:px-6 border-[3px] border-accent hover:border-primary ${
                        getFont('KP').heading
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('KP').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${getFont('KP').heading}`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                components: [
                    // {
                    //     type: 'DIVIDER',
                    //     className:
                    //         'self-center m-auto w-[20%] lg:w-[10%] bg-accent border-none h-[4px]',
                    //     classNameContainer: ''
                    // },
                ],
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-[#1c2936] ${
                    getFont('KP').heading
                }`,
                headingClass: `text-[22px] lg:text-[30px] uppercase font-semibold text-secondary mt-3 leading-7 lg:leading-9 break-words ${
                    getFont('KP').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936] leading-5 min-h-[40px] lg:min-h-[70px] ${
                    getFont('KP').heading
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('KP').heading
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('KP').heading
                }`
            },
            sectionHeadings: ` text-secondary-focus uppercase pt-10 text-center text-[20px] md:text-[24px] lg:text-[25px] xl:text-[40px] tracking-wider leading-8 font-normal ${
                getFont('KP').heading
            }`,
            sectionHeadingsSpan: ` text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-10 text-center text-[20px] md:text-[24px] lg:text-[25px] xl:text-[40px] tracking-wider leading-8 font-bold ${
                getFont('KP').bold
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            components: [
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'self-center m-auto w-[20%] lg:w-[10%] bg-accent border-none h-[4px]',
                //     classNameContainer: ''
                // },
            ],
            cardHeight:
                'h-[510px] md:h-[507px] lg:h-[500px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[280px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 md:h-[45px] lg:h-[70px] text-[19px] 3xl:text-[20px] font-medium uppercase leading-6 break-words mx-5 ${
                getFont('KP').heading
            }`,
            categoryClass: `bg-secondary-content bg-opacity-[0.65] border-[2px] border-accent text-white ${
                getFont('KP').body
            }`,
            summaryClass: `font-normal text-[15px] md:text-[16.5px] xl:text-[17px] mt-2 leading-5 text-[#777777] mx-5 md:h-[50px] lg:h-[70px] ${
                getFont('KP').body
            }`,
            buttonClass: `bg-white border-[3px] border-accent hover:border-primary flex justify-center self-end float-right text-[13px] xl:text-[16px] text-secondary-focus py-1.5 px-4 md:px-4 m-5 ${
                getFont('KP').body
            }`,
            buttonArrowUrl: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.16 11.59'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23000;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cline class='cls-1' y1='5.62' x2='55' y2='5.62'/%3E%3Cpolyline class='cls-1' points='48.5 0.39 51.93 3.09 55.35 5.79 51.93 8.49 48.5 11.19'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-[#919396] ${
                getFont('KP').heading
            }`,
            noBlogsClass: `bg-secondary p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('KP').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/69080231.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/17389270.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.20'
            },

            negativeMargin: '-mt-16 2xl:-mt-16',
            containerClass: ' text-white md:w-[50%] ',
            heading: '',
            headingClass: ` text-transparent bg-clip-text bg-gradient-to-r from-accent to-primary uppercase md:pl-20 text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                getFont('KP').bold
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('KP').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('KP').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('KP').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('KP').bold
                    }`
                }
            ]
        },

        eventsSection: {
            heading: ' ',
            headingClass: `self-center text-center text-[22px] md:text-[24px] lg:text-[25px] xl:text-[30px] text-neutral w-[60%] mt-16 ${
                getFont('KP').heading
            }`,
            components: [
                {
                    type: 'CUSTOM',
                    className:
                        'w-full mb-3 text-center lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'PAST ',
                            className: `inline-block text-secondary-focus uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-normal ${
                                getFont('KP').heading
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: ' EVENTS',
                            className: `inline-block ml-3 text-transparent bg-clip-text bg-gradient-to-r from-accent to-primary uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                                getFont('KP').bold
                            }`
                        }
                    ]
                }
            ],
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-secondary-focus w-[60%] ${
                getFont('KP').heading
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('KP').heading
            }`
        },
        eventLayout: {
            titleClass: `text-primary leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('KP').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ' text-[15px]'
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${
            getFont('KP').heading
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('KP').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                ' hidden md:hero hero-content items-start md:items-center max-w-full h-[30vh] lg:h-[45vh] bg-center bg-cover', // //bg-top
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0' //0.5
            },
            backgroundImage:
                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1704354763271_offer banner',
            backgroundImageMobile:
                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1704354763271_offer banner',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/4685974.png',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.9',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `font-semibold text-[20px] text-center text-xl text-neutral w-[60%] ${
                getFont('KP').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `tracking-wide text-2xl xl:text-3xl leading-7 text-transparent bg-clip-text bg-gradient-to-r from-accent via-primary to-primary uppercase font-semibold text-left hover:border-b-accent ${
                getFont('KP').bold
            } `,
            subAdditionalText:
                'Embassy Priority is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` leading-5 xl:leading-6  break-words ${
                getFont('KP').heading
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('KP').heading}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/4685974.png',
            pattern2: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '1',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-2xl lg:text-[18px] leading-6 lg:leading-5 text-primary ${
                getFont('KP').heading
            }`,
            listBodyClass: `${getFont('KP').heading}`,
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` text-2xl xl:text-3xl text-primary uppercase leading-8 xl:leading-9  ${
                getFont('KP').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('KP').heading
            }`,
            dateClassName: `py-[6px] text-neutral capitalize text-[17px] text-black  ${
                getFont('KP').heading
            }`,
            termsAndConditionsClassName: `capitalize text-black text-sm font-semibold text-[12px] ${
                getFont('KP').heading
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `text-black text-xs break-words leading-1 ${
                getFont('KP').heading
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-transparent text-secondary-focus font-bold uppercase self-center rounded text-[16px] py-2.5 px-7 border-[3px] border-accent ${
                    getFont('KP').bold
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `bg-transparent text-secondary-focus rounded uppercase text-[16px] py-[9px] px-10 border-[3px] border-accent ${
                    getFont('KP').heading
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.20'
                },
                backgroundImage:
                    'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1704354826792_projects banner',
                backgroundImageMobile:
                    'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1704354826792_projects banner',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[88vh] bg-bottom',

                components: [
                    {
                        type: 'CUSTOM',
                        className:
                            'w-full lg:mt-0 lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto text-center',
                        components: [
                            {
                                type: 'TEXT',
                                content: 'KOLTE PATIL ',
                                className: `inline-block text-white uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-normal ${
                                    getFont('KP').heading
                                }`
                            },
                            {
                                type: 'TEXT',
                                content: ' RESIDENTIAL PROJECTS ',
                                className: `inline-block md:ml-3 text-transparent bg-clip-text bg-gradient-to-r from-accent to-primary uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                                    getFont('KP').bold
                                }`
                            }
                        ]
                    }
                ]
            }
        ],
        moduleConfig: {
            
            constructionVisible: false,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: '',
            addHeader: true,
            headerClass: 'hidden lg:block h-32 bg-[#58595B]'
        }
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/15293547.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.10',
            repeat: 'repeat',
            size: '70%'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.65'
                },
                backgroundImage: 'https://cdn.loyalie.in/45495424.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/75823444.jpg',
                bannerContainerClassName:
                    'hero hero-content  text-center max-w-full bg-left lg:bg-center h-[90vh] lg:h-[88vh]',
                components: [
                    {
                        type: 'CUSTOM',
                        className:
                            'w-full  lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                        components: [
                            {
                                type: 'TEXT',
                                content: 'CONTACT US',
                                className: `inline-block ml-3 text-transparent bg-clip-text bg-gradient-to-r from-accent to-primary uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                                    getFont('KP').bold
                                }`
                            }
                        ]
                    }
                ]
            }
        ],

  
    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[18px] md:text-[20px] ${
            getFont('KP').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-6 md:leading-7  ${
            getFont('KP').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.65'
                },
                backgroundImage: 'https://cdn.loyalie.in/14952553.jpeg',
                backgroundImageMobile: 'https://cdn.loyalie.in/73085313.jpg',
                bannerContainerClassName:
                    'hero hero-content  text-center max-w-full bg-right lg:bg-center h-[90vh] lg:h-[88vh]',
                components: [
                    {
                        type: 'CUSTOM',
                        className:
                            'w-full  lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                        components: [
                            {
                                type: 'TEXT',
                                content: 'TERMS & CONDITIONS',
                                className: `inline-block ml-3 text-transparent bg-clip-text bg-gradient-to-r from-accent to-primary uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                                    getFont('KP').bold
                                }`
                            }
                        ]
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-8 lg:p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('KP').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[18px] md:text-[20px] ${
                            getFont('KP').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Kolte Patil  project, and has entered into a registered Agreement to Sell; or (ii) employee of Kolte Patil ; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Kolte Patil  or any of its sister concerns/subsidiaries/affiliates (“Kolte Patil  Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Kolte Patil  Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Kolte Patil .',
                            'Referrer shall not be able to refer existing customer(s) of Kolte Patil . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Kolte Patil  or (ii) is/are already registered with Kolte Patil  as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Kolte Patil ’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Kolte Patil  within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Kolte Patil ',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Kolte Patil  or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-6 md:leading-7  ${
                            getFont('KP').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-0 lg:px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Kolte Patil, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('KP').body
                                } inline text-neutral font-normal text-[17px] leading-6 md:leading-7   `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-6 md:leading-7 ${
                                    getFont('KP').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-6 md:leading-7 ${
                                    getFont('KP').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-6 md:leading-7  ${
                                    getFont('KP').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-6 md:leading-7  ${
                                    getFont('KP').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-6 md:leading-7  ${
                                    getFont('KP').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-6 md:leading-7  ${
                                    getFont('KP').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        appContainerClassName: 'bg-white text-secondary-focus border-accent',
        appFontHeading: `${getFont('KP').body}`,
        appStoreLink:
            'https://apps.apple.com/us/app/kolte-patil-nest/id6446821170?l=zh-Hans-CN&utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.kolte&utm_medium=email',
        appScreen: 'https://cdn.loyalie.in/18548339.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('KP').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 |Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA No: DLRERA2022A0017 | Goa RERA NO – AGGO02210817 | Telangana RERA No – A02500001321 |maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[10.5px] 3xl:text-[15px] leading-[18px] tracking-tight text-white ${
            getFont('KP').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('KP').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-primary bg-opacity-90'
    },
    referralForm: {
        name: 'Kolte Patil',
        theme: 'kpTheme',
        color: '#F7941d',
        backgroundImage: 'https://cdn.loyalie.in/35103521.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '0',
            G1: '0',
            B1: '0',
            opacity1: '0.50',
            R2: '0',
            G2: '0',
            B2: '0',
            opacity2: '0.10'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: ' ',
            bookedCustomerHeading2: 'Kolte Patil Home Owner',

            employee: false,
            employeeHeading1: 'I am an',
            // employeeHeading1: 'Kolte Patil Employee',

            salesAdmin: true,
            salesAdminHeading1: ' ',
            salesAdminHeading2: 'Kolte Patil Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        formContent: {
            heading: '"SHARE THE GOOD LIFE WITH YOUR LOVED ONES"',
            headingClass: `self-center text-white sm:mt-0 text-2xl md:text-3xl 2xl:text-4xl w-[80%] lg:w-[80%] 2xl:w-[80%] text-center leading-7 tracking-wide ${
                getFont('KP').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton: ` w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold  ${
                getFont('KP').heading
            }`
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='white'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='%23fadc02' stroke-width='7' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='7' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink: 'nest-home/terms-and-conditions',
        customLink: '/nest-home/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default KpConfig;
