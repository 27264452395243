export const filterSvg = <svg
xmlns="http://www.w3.org/2000/svg"
width="19.909"
height="21.443"
viewBox="0 0 19.909 21.443"
>
<path
    id="filter-141"
    d="M.766,3.574H7.509a2.8,2.8,0,0,0,5.4,0h7.768a.766.766,0,1,0,0-1.531H12.907a2.8,2.8,0,0,0-5.4,0H.766a.766.766,0,0,0,0,1.531Zm9.442-2.042A1.276,1.276,0,1,1,8.932,2.808a1.276,1.276,0,0,1,1.276-1.276ZM20.675,9.189H17.926a2.8,2.8,0,0,0-5.225,0H.766a.766.766,0,1,0,0,1.531H12.557a2.805,2.805,0,0,0,5.517,0h2.6a.766.766,0,1,0,0-1.531Zm-5.362,2.3A1.276,1.276,0,1,1,16.59,10.21,1.276,1.276,0,0,1,15.313,11.486Zm5.362,4.85H9.339a2.8,2.8,0,0,0-5.4,0H.766a.766.766,0,0,0,0,1.531H3.941a2.8,2.8,0,0,0,5.4,0H20.675a.766.766,0,1,0,0-1.531ZM6.64,18.378A1.276,1.276,0,1,1,7.916,17.1,1.276,1.276,0,0,1,6.64,18.378Z"
    transform="translate(19.909) rotate(90)"
/>
</svg>

export const loadMoreSvg =        
<svg
xmlns="http://www.w3.org/2000/svg"
width="9"
height="8"
viewBox="0 0 9 8"
>
<g
    id="Group_11001"
    data-name="Group 11001"
    transform="translate(-630 -1255)"
>
    <rect
        id="Rectangle_17098"
        data-name="Rectangle 17098"
        width="1"
        height="8"
        rx="0.5"
        transform="translate(630 1255)"
        fill="#908f95"
    />
    <rect
        id="Rectangle_17099"
        data-name="Rectangle 17099"
        width="1"
        height="5"
        rx="0.5"
        transform="translate(634 1258)"
        fill="#908f95"
    />
    <rect
        id="Rectangle_17100"
        data-name="Rectangle 17100"
        width="1"
        height="6"
        rx="0.5"
        transform="translate(638 1255)"
        fill="#908f95"
    />
</g>
</svg>
export const noDealsvg=
<svg xmlns="http://www.w3.org/2000/svg" width="90.898" height="90.624" viewBox="0 0 90.898 90.624">
  <g id="space" transform="translate(-114.735 -125)">
    <path id="Path_7211" data-name="Path 7211" d="M1020.679,1013.937a.165.165,0,0,1-.165-.16l-1.677-51.674a.165.165,0,0,1,.12-.164l21.842-6.215a.165.165,0,0,1,.188.076l32.773,57.232a.165.165,0,0,1-.141.247l-52.938.657h0Zm-1.508-51.715,1.667,51.383,52.5-.651-32.569-56.876-21.594,6.145Z" transform="translate(-874.262 -803.3)" fill="#d1def0"/>
    <g id="Group_11351" data-name="Group 11351" transform="translate(114.735 130.32)">
      <g id="Group_11344" data-name="Group 11344" transform="translate(27.102 35.255)">
        <path id="Path_7212" data-name="Path 7212" d="M946.059,1368.432a2.669,2.669,0,0,0-1.242-1.52l1.01-7.339-1.9-.262-1.008,7.327c-.074.017-.147.036-.221.059a2.677,2.677,0,1,0,3.364,1.735Z" transform="translate(-940.667 -1359.145)" fill="#7a8593"/>
        <path id="Path_7213" data-name="Path 7213" d="M938.7,1367.265a2.842,2.842,0,0,1-.867-5.549c.039-.012.08-.024.122-.036l.993-7.216a.165.165,0,0,1,.186-.141l1.9.262a.165.165,0,0,1,.141.186l-1,7.23a2.842,2.842,0,0,1-1.484,5.264Zm.553-12.593-.986,7.163a.165.165,0,0,1-.127.138c-.076.017-.144.036-.207.056a2.512,2.512,0,1,0,1.992.2.164.164,0,0,1-.083-.166l.987-7.176-1.577-.217Z" transform="translate(-935.856 -1354.322)" fill="#535a63"/>
      </g>
      <g id="Group_11345" data-name="Group 11345" transform="translate(53.215 27.449)">
        <path id="Path_7214" data-name="Path 7214" d="M1742.1,1129.87a2.677,2.677,0,0,0-3.365-1.735c-.073.023-.144.05-.214.079l-5.071-5.384-1.4,1.318,5.08,5.394a2.677,2.677,0,1,0,4.969.329Z" transform="translate(-1731.885 -1122.665)" fill="#7a8593"/>
        <path id="Path_7215" data-name="Path 7215" d="M1734.716,1128.7a2.841,2.841,0,0,1-2.617-3.953l-5-5.313a.165.165,0,0,1,.007-.233l1.4-1.318a.167.167,0,0,1,.118-.045.164.164,0,0,1,.115.052l4.994,5.3c.041-.016.081-.029.12-.042a2.842,2.842,0,1,1,.867,5.549Zm-7.268-9.372,4.966,5.273a.165.165,0,0,1,.029.184,2.511,2.511,0,1,0,1.505-1.319c-.063.02-.129.045-.2.074a.165.165,0,0,1-.183-.039l-4.958-5.264-1.159,1.091Z" transform="translate(-1727.05 -1117.838)" fill="#535a63"/>
      </g>
      <g id="Group_11346" data-name="Group 11346" transform="translate(19.962 13.434)">
        <ellipse id="Ellipse_1793" data-name="Ellipse 1793" cx="19.66" cy="8.765" rx="19.66" ry="8.765" transform="matrix(0.953, -0.304, 0.304, 0.953, 0, 11.953)" fill="#7a8593"/>
        <path id="Path_7216" data-name="Path 7216" d="M799.925,830.827a24.35,24.35,0,0,1-2.665-.14,13.3,13.3,0,0,1-4.923-1.391,4.7,4.7,0,0,1-2.146-6.716,13.312,13.312,0,0,1,3.2-3.988,39.081,39.081,0,0,1,26.612-8.5,13.3,13.3,0,0,1,4.923,1.391,4.7,4.7,0,0,1,2.146,6.716,13.31,13.31,0,0,1-3.2,3.988,35.309,35.309,0,0,1-12.52,6.712,38.369,38.369,0,0,1-11.427,1.934Zm17.416-20.551a38.01,38.01,0,0,0-11.325,1.918,34.981,34.981,0,0,0-12.4,6.643,12.99,12.99,0,0,0-3.127,3.885,4.377,4.377,0,0,0,2.008,6.284,12.985,12.985,0,0,0,4.8,1.352,23.968,23.968,0,0,0,2.63.139,38.009,38.009,0,0,0,11.325-1.918,34.98,34.98,0,0,0,12.4-6.643,12.988,12.988,0,0,0,3.127-3.885,4.377,4.377,0,0,0-2.008-6.284,12.987,12.987,0,0,0-4.8-1.352A23.972,23.972,0,0,0,817.341,810.277Z" transform="translate(-787.239 -806.093)" fill="#535a63"/>
      </g>
      <g id="Group_11347" data-name="Group 11347" transform="translate(1.714 9.968)">
        <path id="Path_7217" data-name="Path 7217" d="M245.637,593.64l-72.6,23.2-1.408.45.508,1.589c1.032,3.23,18.751.452,39.577-6.2s36.871-14.669,35.839-17.9l-.508-1.589-1.408.45Z" transform="translate(-171.466 -593.025)" fill="#9ca9ba"/>
        <path id="Path_7218" data-name="Path 7218" d="M171.915,615.559q-.474,0-.905-.018c-2.273-.1-3.565-.581-3.841-1.444l-.508-1.589a.165.165,0,0,1,.107-.207l75.415-24.1a.165.165,0,0,1,.207.107l.508,1.589c.276.862-.5,2.008-2.292,3.4a46.969,46.969,0,0,1-7.515,4.459A192.637,192.637,0,0,1,206.953,608a192.643,192.643,0,0,1-27.235,6.814,52.516,52.516,0,0,1-7.8.744Zm-4.889-2.994.457,1.431c.226.707,1.45,1.126,3.541,1.215a46.66,46.66,0,0,0,8.643-.722,192.277,192.277,0,0,0,27.186-6.8,192.3,192.3,0,0,0,26.093-10.224,46.662,46.662,0,0,0,7.46-4.424c1.652-1.284,2.406-2.336,2.18-3.043l-.457-1.432-1.251.4-.05-.157.05.157-73.851,23.6Z" transform="translate(-166.654 -588.191)" fill="#535a63"/>
      </g>
      <g id="Group_11348" data-name="Group 11348" transform="translate(0 4.331)">
        <ellipse id="Ellipse_1794" data-name="Ellipse 1794" cx="39.587" cy="6.139" rx="39.587" ry="6.139" transform="matrix(0.953, -0.304, 0.304, 0.953, 0, 24.069)" fill="#becee2"/>
        <path id="Path_7219" data-name="Path 7219" d="M170.03,574.494q-.474,0-.9-.018c-2.273-.1-3.565-.582-3.841-1.444s.5-2.007,2.292-3.4a46.958,46.958,0,0,1,7.515-4.459,192.6,192.6,0,0,1,26.139-10.244,192.627,192.627,0,0,1,27.236-6.814,46.934,46.934,0,0,1,8.708-.725c2.273.1,3.565.581,3.841,1.444s-.5,2.007-2.292,3.4a46.97,46.97,0,0,1-7.515,4.459,192.57,192.57,0,0,1-26.139,10.244,192.618,192.618,0,0,1-27.236,6.814A52.516,52.516,0,0,1,170.03,574.494Zm66.236-26.8a52.243,52.243,0,0,0-7.75.74,192.287,192.287,0,0,0-27.186,6.8,192.307,192.307,0,0,0-26.093,10.224,46.668,46.668,0,0,0-7.46,4.424c-1.652,1.284-2.406,2.336-2.18,3.043s1.45,1.127,3.54,1.215a46.644,46.644,0,0,0,8.642-.722,192.31,192.31,0,0,0,27.186-6.8A192.287,192.287,0,0,0,231.059,556.4a46.659,46.659,0,0,0,7.46-4.424c1.652-1.284,2.406-2.336,2.18-3.043s-1.45-1.127-3.541-1.215Q236.734,547.7,236.266,547.7Z" transform="translate(-163.563 -543.078)" fill="#535a63"/>
      </g>
      <path id="Path_7220" data-name="Path 7220" d="M397.55,601.131q-.336,0-.645-.012c-1.894-.07-2.926-.453-3.156-1.17s.389-1.628,1.892-2.783a38.919,38.919,0,0,1,6.149-3.62,181.716,181.716,0,0,1,43.6-13.933A38.9,38.9,0,0,1,452.5,579c1.894.07,2.926.453,3.156,1.17s-.389,1.628-1.892,2.783a38.909,38.909,0,0,1-6.149,3.62,181.725,181.725,0,0,1-43.6,13.933,42.972,42.972,0,0,1-6.464.629Zm54.3-21.816a42.636,42.636,0,0,0-6.411.625,181.427,181.427,0,0,0-43.5,13.9,38.573,38.573,0,0,0-6.095,3.586c-.935.719-2,1.723-1.779,2.421s1.675.9,2.853.941a38.618,38.618,0,0,0,7.045-.614,181.435,181.435,0,0,0,43.5-13.9,38.574,38.574,0,0,0,6.095-3.586c.935-.719,2-1.723,1.779-2.421s-1.675-.9-2.853-.941Q452.181,579.315,451.85,579.315Z" transform="translate(-384.495 -569.32)" fill="#535a63"/>
      <g id="Group_11349" data-name="Group 11349" transform="translate(17.161 0)">
        <path id="Path_7221" data-name="Path 7221" d="M682.69,306.3a89.775,89.775,0,0,1-41.66,13.314,1.264,1.264,0,0,1-1.346-1.324l.305-6.209a21.961,21.961,0,0,1,39.178-12.521l3.85,4.882A1.264,1.264,0,0,1,682.69,306.3Z" transform="translate(-639.518 -291.027)" fill="#fff"/>
        <path id="Path_7222" data-name="Path 7222" d="M636.116,314.928a1.429,1.429,0,0,1-1.427-1.5l.305-6.209A22.124,22.124,0,0,1,674.467,294.6l3.85,4.882a1.429,1.429,0,0,1-.371,2.1,89.942,89.942,0,0,1-41.735,13.338c-.032,0-.063,0-.095,0Zm20.976-28.42h-.061a21.793,21.793,0,0,0-21.706,20.727l-.305,6.209a1.1,1.1,0,0,0,1.17,1.152,89.615,89.615,0,0,0,41.584-13.29l.086.141-.086-.141a1.1,1.1,0,0,0,.285-1.616l-3.85-4.881a21.8,21.8,0,0,0-17.117-8.3Z" transform="translate(-634.687 -286.177)" fill="#535a63"/>
      </g>
      <g id="Group_11350" data-name="Group 11350" transform="translate(17.161 9.729)">
        <path id="Path_7223" data-name="Path 7223" d="M680.245,585.94A89.694,89.694,0,0,1,639.9,598.832l-.22,4.472a1.264,1.264,0,0,0,1.346,1.324,89.775,89.775,0,0,0,41.66-13.314,1.264,1.264,0,0,0,.328-1.859Z" transform="translate(-639.518 -585.775)" fill="#7a8593"/>
        <path id="Path_7224" data-name="Path 7224" d="M636.116,599.958a1.429,1.429,0,0,1-1.427-1.5l.22-4.473a.165.165,0,0,1,.153-.156,89.532,89.532,0,0,0,40.267-12.869.165.165,0,0,1,.215.039l2.773,3.516a1.429,1.429,0,0,1-.371,2.1,89.943,89.943,0,0,1-41.735,13.338c-.032,0-.063,0-.1,0Zm-.884-5.811-.213,4.327a1.1,1.1,0,0,0,1.17,1.152,89.615,89.615,0,0,0,41.584-13.29,1.1,1.1,0,0,0,.285-1.616l-2.683-3.4a89.845,89.845,0,0,1-40.144,12.83Z" transform="translate(-634.687 -580.935)" fill="#535a63"/>
      </g>
    </g>
    <g id="Group_11352" data-name="Group 11352" transform="translate(120.079 125)">
      <path id="Path_7225" data-name="Path 7225" d="M2077.281,291.2v-.012l0,.006,0-.006v.012a1.784,1.784,0,0,1-1.078,1.21,1.784,1.784,0,0,1,1.078,1.21v.012a.014.014,0,0,0,0-.006.054.054,0,0,1,0,.006v-.012a1.785,1.785,0,0,1,1.078-1.21A1.785,1.785,0,0,1,2077.281,291.2Z" transform="translate(-2016.804 -285.705)" fill="#9ca9ba"/>
      <path id="Path_7226" data-name="Path 7226" d="M2646.9,664.752v-.012s0,0,0,.006a.052.052,0,0,1,0-.006v.012a1.218,1.218,0,0,0,0,2.42v.012s0,0,0-.006a.018.018,0,0,1,0,.006v-.012a1.218,1.218,0,0,0,0-2.42Z" transform="translate(-2567.624 -646.926)" fill="#9ca9ba"/>
      <path id="Path_7227" data-name="Path 7227" d="M503.562,1657.022v-.012l0,.006,0-.006v.012a1.218,1.218,0,0,0,0,2.42v.012l0-.006a.038.038,0,0,1,0,.006v-.012a1.218,1.218,0,0,0,0-2.42Z" transform="translate(-495.026 -1606.445)" fill="#9ca9ba"/>
      <path id="Path_7228" data-name="Path 7228" d="M689.682,162.042v-.012l0,.006a.027.027,0,0,1,0-.006v.012a1.786,1.786,0,0,1-1.078,1.21,1.786,1.786,0,0,1,1.078,1.21v.012l0-.006,0,.006v-.012a1.785,1.785,0,0,1,1.078-1.21A1.785,1.785,0,0,1,689.682,162.042Z" transform="translate(-675.003 -160.808)" fill="#9ca9ba"/>
      <path id="Path_7229" data-name="Path 7229" d="M277.722,877.482v-.012l0,.006,0-.006v.012a1.218,1.218,0,0,0,0,2.42v.012l0-.006,0,.006V879.9a1.218,1.218,0,0,0,0-2.42Z" transform="translate(-276.64 -852.634)" fill="#9ca9ba"/>
      <path id="Path_7230" data-name="Path 7230" d="M357.783,361.834v-.024l0,.012,0-.012v.024a3.572,3.572,0,0,1-2.157,2.42,3.573,3.573,0,0,1,2.157,2.42v.024l0-.012,0,.012v-.024a3.572,3.572,0,0,1,2.157-2.42A3.573,3.573,0,0,1,357.783,361.834Z" transform="translate(-353.013 -353.994)" fill="#9ca9ba"/>
      <path id="Path_7231" data-name="Path 7231" d="M2475.543,125.024V125a.114.114,0,0,1,0,.012.111.111,0,0,1,0-.012v.024a3.572,3.572,0,0,1-2.157,2.42,3.574,3.574,0,0,1,2.157,2.42v.024a.113.113,0,0,1,0-.012.117.117,0,0,1,0,.012v-.024a3.573,3.573,0,0,1,2.156-2.42A3.573,3.573,0,0,1,2475.543,125.024Z" transform="translate(-2400.875 -125)" fill="#9ca9ba"/>
      <path id="Path_7232" data-name="Path 7232" d="M2355.583,1163.964v-.024a.112.112,0,0,1,0,.012s0-.008,0-.012v.024a2.436,2.436,0,0,0,0,4.84v.024s0-.008,0-.012a.123.123,0,0,1,0,.012v-.024a2.436,2.436,0,0,0,0-4.84Z" transform="translate(-2284.874 -1129.649)" fill="#9ca9ba"/>
    </g>
    <ellipse id="Ellipse_1795" data-name="Ellipse 1795" cx="26.724" cy="5.152" rx="26.724" ry="5.152" transform="translate(146.252 205.321)" fill="#d1def0"/>
    <path id="Path_7233" data-name="Path 7233" d="M277.829,1957.664l-1.189-3.72,4.625-14.908,5.149-1.646,3.808,11.916,2.211-.707,1.275,3.99-2.211.707,1.12,3.5-4.61,1.474-1.12-3.505-9.058,2.9Zm5.918-13.836-2.341,8.3,4.529-1.448Z" transform="translate(-156.561 -1752.571)" fill="#adb9c8"/>
    <path id="Path_7234" data-name="Path 7234" d="M2353.41,1320.524l.4-3.886,10.2-11.818,5.378.549-1.271,12.445,2.308.236-.426,4.167-2.309-.236-.374,3.66-4.815-.491.374-3.66Zm10.954-10.319-5.461,6.669,4.73.483Z" transform="translate(-2164.787 -1140.879)" fill="#adb9c8"/>
    <path id="Path_7235" data-name="Path 7235" d="M1350.1,1652.1q2.892-1.74,6.686.037t4.31,5.112a16.683,16.683,0,0,1-6.529,13.944q-2.893,1.739-6.686-.037t-4.31-5.112a16.687,16.687,0,0,1,6.529-13.944Zm6.444,6.627a2.921,2.921,0,0,0-4.665-2.185,12.2,12.2,0,0,0-2.43,3.722,18.479,18.479,0,0,0-1.145,2.98,4.07,4.07,0,0,0-.031,2.235,3.094,3.094,0,0,0,3.717,1.74,4.078,4.078,0,0,0,1.7-1.455,18.509,18.509,0,0,0,1.555-2.788,12.218,12.218,0,0,0,1.3-4.25Z" transform="translate(-1188.175 -1475.87)" fill="#adb9c8"/>
  </g>
</svg>
