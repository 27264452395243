import { getConfig } from '../../../utils/config';
import Footer2 from '../Footer2';

import SiFacebook from '@meronex/icons/si/SiFacebook';
import SiInstagram from '@meronex/icons/si/SiInstagram';
import SiTwitter from '@meronex/icons/si/SiTwitter';
import SiLinkedin from '@meronex/icons/si/SiLinkedin';
import SiYoutube from '@meronex/icons/si/SiYoutube';

const Footer4 = () => {
    const config = getConfig();
    return (
        <footer
            className={
                'bg-secondary-content pt-3 border-t-2 border-secondary-focus'
            }
        >
            <div className="flex flex-col-reverse md:flex-row border-black  border-b-[1px] mx-10 justify-between pb-6 pt-2">
                <div className="flex flex-col">
                    <p className=" font-semibold text-secondary-focus w-full md:w-[60%] text-[21px] lg:text-[25px] font-CorporateS_Regular">
                        Sales Galleria
                    </p>
                    <p className=" text-secondary-focus w-full md:w-[60%] lg:text-[17px] font-CorporateS_Regular">
                        Century Ethos, Cross, Bellary Rd, opposite
                        Sahakaranagar, Bengaluru, Karnataka 560092
                    </p>
                    <div className="flex justify-items-start w-full md:w-[40%] mt-2">
                        <SiFacebook
                            onClick={() =>
                                window.open(
                                    'https://www.facebook.com/centuryrealestatebangalore'
                                )
                            }
                            className="h-6 w-6 3xl:h-8 3xl:w-8 cursor-pointer text-[#64412B]"
                        />

                        <SiInstagram
                            onClick={() =>
                                window.open(
                                    'https://www.instagram.com/centuryrealestate/'
                                )
                            }
                            className=" ml-6 h-6 w-6 3xl:h-8 3xl:w-8 cursor-pointer text-[#64412B]"
                        />
                        <SiTwitter
                            onClick={() =>
                                window.open('https://twitter.com/centuryblr')
                            }
                            className="ml-6 h-6 w-6 3xl:h-8 3xl:w-8 cursor-pointer text-[#64412B]"
                        />
                        <SiYoutube
                            onClick={() =>
                                window.open(
                                    'https://www.youtube.com/c/CenturyRealEstateHoldingsPvtLtd'
                                )
                            }
                            className="ml-6 h-6 w-6 3xl:h-8 3xl:w-8 cursor-pointer text-[#64412B]"
                        />
                        <SiLinkedin
                            onClick={() =>
                                window.open(
                                    'https://www.linkedin.com/company/century-real-estate-holdings-pvt-ltd'
                                )
                            }
                            className="ml-6 h-6 w-6  3xl:h-8 3xl:w-8 cursor-pointer text-[#64412B]"
                        />
                    </div>
                </div>
                <div
                    className={
                        config?.footer.footerClientLogoClassName
                            ? config?.footer.footerClientLogoClassName
                            : ' self-center w-full md:w-[20%] lg:w-[15%]'
                    }
                >
                    <img
                        className=" h-auto px-0 my-4 md:my-0 w-full md:h-auto md:w-auto md:px-0 md:py-0 xl:pl-0 2xl:pl-6 3xl:pl-10 "
                        src={
                            config?.footer?.footerClientLogo ||
                            'https://cdn.loyalie.in/28602817.png'
                        }
                    />
                </div>
            </div>
            <Footer2 />
        </footer>
    );
};

export default Footer4;
