import React from 'react';
import EnDotSingle from '@meronex/icons/en/EnDotSingle';
import BiCopy from '@meronex/icons/bi/BiCopy';
import Modal from '../../ReferralPage/Components/Modal';
import moment from 'moment';
import AiOutlineClose from '@meronex/icons/ai/AiOutlineClose';
import EiExternalLink from '@meronex/icons/ei/EiExternalLink';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { truncateHtml } from '../../../helpers';
const RewardCard = ({
    card,
    rewardClass,
    toggleView,
    viewStates,
    copyCodeHandler,
    copiedText,
    handleRedeem,
    setCopiedText
}) => {
    const isExpired = moment().isAfter(moment(card.expiryDate, 'YYYY-MM-DD'));
    const history = useHistory();
    const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

    const isHTML2 = (content) => /<\/?[a-z][\s\S]*>/i.test(content);

    const description = card?.description || '';
    const isHtmlContent = isHTML2(description);

    
    const truncatedDescription = isHtmlContent
        ? truncateHtml(description, 135)
        : description.slice(0, 135).replace(/<\/?p>/g, '') + '...';
    return (
        <>
            <div
                onClick={() => toggleView(card.id)}
                key={card.id}
                className={` w-[260px] h-[260px] lg:w-[210px] lg:h-[210px] xl:w-[260px] xl:h-[260px] border rounded-2xl border-gray-300 bg-[#fafafa] ${
                    isExpired ? 'pointer-events-none' : 'cursor-pointer'
                }`}
            >
                <div className="flex flex-col relative h-full justify-center">
                    {card.status !== 'VIEWED' && !isExpired && (
                        <div className=" absolute top-2 right-2 flex justify-end ">
                            <div className="flex gap-1 items-center font-semibold text-green-600 text-xs bg-green-100 px-3 py-[2px] rounded-full">
                                <EnDotSingle />
                                ACTIVE
                            </div>
                        </div>
                    )}
                    {card.status !== 'VIEWED' && isExpired && (
                        <div className="absolute top-2 right-2 flex justify-end">
                            <div className="flex gap-1 items-center font-semibold text-blue-900 text-xs bg-blue-100 px-3 py-[2px] rounded-full">
                                <EnDotSingle />
                                EXPIRED
                            </div>
                        </div>
                    )}
                    <div className={isExpired ? 'opacity-50' : ''}>
                        <img
                            className="w-[100px] h-[100px] lg:w-[60px] lg:h-[60px] xl:w-[100px] xl:h-[100px] mx-auto rounded-lg"
                            src={card.logoUrl}
                            alt="vendor logo"
                        />
                        <h1 className=" mt-4 mx-auto text-center text-black font-medium uppercase text-[13px] leading-4">
                            <span>{card.title}</span>
                        </h1>
                        <p className="text-center text-xs mt-1">
                            Valid Upto:{' '}
                            {moment(card.expiryDate, 'YYYY-MM-DD').format(
                                'DD MMM, YY'
                            )}
                        </p>
                        {!viewStates[card.id] && (
                            <div className="flex items-center justify-center mt-3">
                                <button
                                    className={`text-[#16519E] font-semibold text-xs text-center`}
                                    disabled={isExpired}
                                >
                                    Tap for more info
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {viewStates[card.id] && !isExpired && (
                <Modal
                    visible={viewStates[card.id] && !isExpired}
                    showButton={false}
                >
                    <div className="flex flex-col items-center justify-center">
                        <button
                            onClick={() => {
                                toggleView(card.id);
                            }}
                            className="text-white mb-2 font-semibold flex items-center justify-center"
                        >
                            <AiOutlineClose />
                            CLOSE
                        </button>

                        <div
                            className={`bg-gray-100 cursor-default flex flex-col h-[360px] w-[360px] rounded-2xl justify-center px-2 ${rewardClass}`}
                        >
                            <img
                                className="w-[100px] h-[100px] mx-auto rounded-lg "
                                src={card.logoUrl}
                            />

                            <h1 className=" mt-4 mx-auto text-center text-gray-600 font-medium uppercase text-[16px] leading-4 ">
                                {card.title}
                            </h1>

                            {isHtmlContent && (
                                <div
                                    className="text-sm px-5 py-2 text-center break-all"
                                    dangerouslySetInnerHTML={{
                                        __html: truncatedDescription
                                    }}
                                />
                            )}
                            {!isHTML(card?.description) && (
                                <p className="text-sm px-5 py-2 text-center break-all">
                                    {card?.description
                                        .slice(0, 135)
                                        .replace(/<\/?p>/g, '')}
                                    ...
                                </p>
                            )}

                            {card?.redemptionType === 'CODE' && (
                                <div className="flex flex-col justify-center  w-full">
                                    <h2 className="uppercase text-gray-600 md:py-2  text-center text-xs tracking-widest">
                                        Coupon Code
                                    </h2>
                                    <div className="flex justify-center mb-3">
                                        {!copiedText && (
                                            <div
                                                title="Click to copy "
                                                onClick={() =>
                                                    copyCodeHandler(
                                                        card?.redemptionCode,
                                                        setCopiedText
                                                    )
                                                }
                                                className="border-2 tracking-widest flex items-center bg-[#f0f0f0] text-gray-700 border-[#dcdcdc] border-dashed py-2 px-4 hover:border-blue-900 cursor-pointer my-auto rounded-lg text-[15px] relative"
                                            >
                                                <p className="text-sm">
                                                    {card.redemptionCode}{' '}
                                                </p>
                                                <svg
                                                    className="ml-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16.391"
                                                    height="17.859"
                                                    viewBox="0 0 16.391 17.859"
                                                >
                                                    <path
                                                        id="copy-copy"
                                                        d="M76.7-4.892H68.5a2.05,2.05,0,0,1-2.049-2.05V-16.6a2.05,2.05,0,0,1,2.049-2.05h8.2a2.05,2.05,0,0,1,2.049,2.05v9.658A2.05,2.05,0,0,1,76.7-4.892ZM77.72-16.6A1.025,1.025,0,0,0,76.7-17.626H68.5A1.025,1.025,0,0,0,67.476-16.6v9.658A1.025,1.025,0,0,0,68.5-5.918h8.2A1.025,1.025,0,0,0,77.72-6.943V-16.6ZM63.378-12.5v9.658A1.025,1.025,0,0,0,64.4-1.817h8.2a1.025,1.025,0,0,0,1.024-1.025V-3.867h1.024v1.025A2.05,2.05,0,0,1,72.6-.792H64.4a2.05,2.05,0,0,1-2.049-2.05V-12.5A2.05,2.05,0,0,1,64.4-14.55h1.024v1.025H64.4A1.025,1.025,0,0,0,63.378-12.5Z"
                                                        transform="translate(-62.354 18.651)"
                                                        fill="#16519e"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                        {copiedText && (
                                            <p
                                                className={`self-center mx-auto text-center font-normal animate-pulse bg-[#154F9D1A] text-[#006EB7] py-2 px-12 text-sm rounded text-[15px] md:text-base`}
                                            >
                                                Code Copied
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                            <button
                                onClick={() => {
                                    handleRedeem(card, false);
                                    if (card?.redemptionType !== 'CODE') {
                                        history.push(`/deals/${card.dealId}`);
                                    } else {
                                        window.open(card?.url);
                                    }
                                }}
                                className={` cursor-pointer text-white self-center bg-[#16519E] rounded-full py-2 px-8  transition-all duration-300 ease-in-out  hover:bg-transparent hover:border-2 hover:border-[#16519E]  hover:text-[#16519E] ${
                                    card?.redemptionType !== 'CODE'
                                        ? 'mt-4'
                                        : ''
                                }`}
                            >
                                REDEEM NOW
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default RewardCard;
