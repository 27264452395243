import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { usePhoneInput } from '../../../../Hooks';
import { winnreAuth } from '../../../../utils/axios';
import { toast } from 'react-toastify';
import { authActions } from '../../../../store/auth-slice';
import { useDispatch } from 'react-redux';
import { APIErrorCondition } from '../../../../constants';

function PhoneNumber({ setCaptureUserMobile, redeemNowClickedHandler }) {

    const dispatch = useDispatch()

    const {
        state: {
            isValid: currentMobileNumberIsValid,
            mobile: currentMobile,
            country: currentCountry,
            mobileHasError: currentMobileHasError,
            isInputFocused: isCurrentMobileInputFocused
        },
        onChange: currentMobileOnChange,
        validator: currentMobileValidtor,
        setIsTouched: setCurrentMobileIsTouched,
        getMobileWithoutCountryCode: getCurrentMobileWithoutCountryCode,
        setInputFocused: setCurrentMobileInputFocused
    } = usePhoneInput();

    const submitHandler = () => {
        winnreAuth({
            method: 'POST',
            url: `/winnre/user/add-mobile-number?mobileNumber=${getCurrentMobileWithoutCountryCode()}&countryCode=${currentCountry?.dialCode}`
        }).then((res) => {
            // console.log(res);
            if (res?.status == APIErrorCondition) {
                toast.error(res.message);
            } else {
                setCaptureUserMobile(false)
                dispatch(
                    authActions.setUserMobile({
                            mobileCountryCode: currentCountry?.dialCode,
                            mobileNumber: getCurrentMobileWithoutCountryCode(),
                    })
                );
                // console.log('handler')
                redeemNowClickedHandler(getCurrentMobileWithoutCountryCode())
            }
        });
    };

    return (
        <div className="py-4 px-6 flex flex-col">
            <p className="text-center font-semibold">
                Please enter your phone number to help us serve you better
            </p>
            <div
                className={` w-[70%] mx-auto mt-4 mb-2 md:mb-5'
          relative`}
            >
                <PhoneInput
                    country="in"
                    countryCodeEditable={false}
                    containerClass={
                        'mx-auto peer py-3 border border-gray-300 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full  h-12 placeholder-transparent'
                    }
                    containerStyle={{
                        width: '100%',
                        border: '2px solid grey !important',
                        outline: 'none'
                    }}
                    buttonStyle={{
                        backgroundColor: 'white',
                        border: 'none'
                    }}
                    inputStyle={{
                        width: '100%',
                        height: 'auto',
                        border: 'none',
                        outline: 'none',
                        paddingLeft: '44px',
                        fontSize: '16px'
                    }}
                    isValid={currentMobileValidtor}
                    onChange={currentMobileOnChange}
                    value={currentMobile}
                    enableSearch
                    onBlur={() => {
                        setCurrentMobileIsTouched(true);
                        setCurrentMobileInputFocused(false);
                    }}
                    onFocus={() => setCurrentMobileInputFocused(true)}
                    inputClass="border border-gray-300"
                />
                {currentMobileHasError && (
                    <p
                        className={` text-left text-sm mt-1 font-Roboto text-red-400`}
                    >
                        Please enter a valid number
                    </p>
                )}
            </div>
            <button
                disabled={!currentMobileNumberIsValid}
                className={
                    'disabled:bg-gray-300 bg-primary px-5 mt-2 py-1.5 text-white text-center mr-2 rounded self-center'
                }
                onClick={submitHandler}
            >
                Submit
            </button>
        </div>
    );
}

export default PhoneNumber;
