import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
// console.log(window.location.origin);
const domain = window.location.origin;
const NamanConfig = {
    clientId:
        'fd7f9ec0d5ad805adc07b227e9de4b32e03380334c387f3c569b4c4198bc1e46',
    id: 46,
    name: 'Shree Naman',
    fullName: 'Shree Naman Group',
    programName:'Naman Loyalty Club',
    primaryColor: '#0061a8',
    // fontBody: `${getFont('NAMAN').body}`,
    // fontTitle: `${getFont('NAMAN').heading}`,
    theme: 'namanTheme',
    favicon:
        'https://img.staticmb.com/mbimages/photo_dir/developer/original_images/53356/Shree-Naman-Group-1387879356591-Developer-Logo.jpg',

    googleConfig: {
        gID: 'G-QSM3NHNBWL',
        keywordsContent:
            'Naman Loyalty Club real estate referral programs, Shree Naman Group real estate referral program, Shree Naman Group real estate refer and earn, Naman Loyalty Club real estate project refer, Shree Naman Group real estate, Naman Loyalty Club real estate loyalty program, Shree Naman Group real estate news, Shree Naman Group real estate upcoming projects in Bengaluru, Shree Naman Group real estate launch Naman Loyalty Club real estate, Shree Naman Group real estate launch, Shree Naman Group real estate program, Shree Naman Group real estate projects, Shree Naman Group real estate blogs, Shree Naman Group real estate offers, Shree Naman Group real estate upcoming projects in Bengaluru, Shree Naman Group real estate events, Shree Naman Group, Naman Loyalty Club, Shree Naman Group real estate best residential projects, Shree Naman Group real estate real estate developers, All New Projects by Shree Naman Group real estate Builders & Developers, Ongoing Projects by Shree Naman Group real estate Pvt Ltd, Shree Naman Group real estate Bengaluru, Shree Naman Group real estate new program, Shree Naman Group press release, Shree Naman Group upcoming projects, Shree Naman Group property group, Shree Naman Group bangalore, Shree Naman Group real estate, Shree Naman Group reit, jitu virwani, Shree Naman Group commercial projects, Shree Naman Group projects, Shree Naman Group projects in bangalore',
        descriptionContent:
            "We present to you a bouquet of such experiences and more with Naman Loyalty Club - a bespoke loyalty and referral programme that's designed exclusively for JP homeowners",
        authorContent: 'Shree Naman Group wow'
    },

    facebookPixelConfig: {
        id: '850297703460406', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~76aa273',
    webengageAPIKey:'0a904f10-a5c7-4b55-b2b5-e5df0d0da3c9',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout3',
        
    },
    activateReferPage: true,
    logoLeft: {
        name: 'Shree Loyalty Club Logo',
        url: 'https://cdn.loyalie.in/1695806044375_Naman_Loyalty_Club',
        stickyNavBarLogoClass:
            ' h-8 md:h-8 lg:h-7 xl:h-10 pl-3 md:pl-10 2xl:pl-14 ease-in duration-300',
        baseNavBarLogoClass:
            ' h-9 md:h-9 lg:h-7 xl:h-10 pl-3 md:pl-10 2xl:pl-14 ease-out duration-300'
    },

    logoRight: {
        name: 'Shree Naman Group Logo',
        url: 'https://cdn.loyalie.in/1695817898212_Shree_Naman_Group',
        stickyNavBarLogoClass:
            'hidden lg:block lg:h-8 xl:h-8 2xl:h-12 pr-3 lg:pr-10 2xl:pr-14 ease-in duration-300',
        baseNavBarLogoClass:
            'h-20 md:h-25 lg:h-8 xl:h-10 2xl:h-12 pr-3 lg:pr-10 2xl:pr-14  ease-out duration-300'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('NAMAN').medium}`,
        height: 'h-16 xl:h-20 2xl:h-24 ease-out duration-500',
        stickyHeight: 'h-12 xl:h-14 2xl:h-16 ease-in duration-500 	',
        mobileIconColor: 'text-primary-focus',
        dropDownBg: 'bg-white lg:bg-primary',
        showActiveLinkColor: false,
        footerNavItemClass: `tracking-widest uppercase text-white text-[12px] md:text-[14px] lg:text-white font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg  ${
            getFont('NAMAN').body
        }`,
        baseNavBar: {
            border: true,
            borderClass: 'border-b-[0px] border-primary-focus',
            backgroundColor: 'bg-primary-content', // default: transparent
            containerClass: '',
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-secondary text-[14px] lg:text-primary hover:text-primary-focus font-bold lg:text-[12px] xl:text-[14px] 3xl:text-lg ',
            dropDownContainerClass: `hover:text-primary-focus lg:hover:text-white lg:hover:bg-secondary-focus lg:focus:text-white lg:focus:bg-accent lg:focus:outline-none lg:focus:shadow-outline  `,
            dropDownItemClass:
                'tracking-widest text-secondary lg:text-white text-[14px] font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: true,
            borderClass: 'border-b-[3px] border-primary-focus',
            backgroundColor: 'bg-primary-content', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-secondary text-[14px] lg:text-primary hover:text-primary-focus font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg ' // Each list element in NavBar (Text color, hover color, size)
        }
    },
    floatingButton: true,
    floaterClass: 'bg-primary text-white',
    floaterType: 'REFERRAL',
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `mx-auto text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 ${
        getFont('NAMAN').body
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            path: '/',
            show: true,
            scrollTo: 'overview',
            components: [
                {
                    type: 'HORIZONTAL_CAROUSEL',
                    duration: 2500,
                    arrows: false,
                    imgHeight:
                        'h-[70vh] md:h-[70vh] lg:h-[62vh] xl:h-[72vh] 2xl:h-[78vh]',
                    images: [
                   
                        {
                            imgUrl: 'https://reloy-internal.s3.ap-south-1.amazonaws.com/AICS/Metro/Web/Naman_Banner_1.jpg',
                            imgPosition: 'object-center',
                            mobileImgUrl:'https://reloy-internal.s3.ap-south-1.amazonaws.com/AICS/Metro/Web/Naman_Banner_1_Mobile.jpg',
                            mobileImgPosition: 'object-top',
                            onClick: 'CHANGE_PATH',
                            path: 'gift-and-earn',
                        },
                        {
                            imgUrl: 'https://reloy-internal.s3.ap-south-1.amazonaws.com/AICS/Metro/Web/Naman_Banner_2.jpg',
                            imgPosition: 'object-center',
                            mobileImgUrl: 'https://reloy-internal.s3.ap-south-1.amazonaws.com/AICS/Metro/Web/Naman_Banner_2_mobile.jpg',
                            mobileImgPosition: 'object-right-top',
                           
                        }
                    ]
                },
                // {
                //     type: 'BANNER_NEW',
                //     backgroundImage:
                //         'https://cdn.loyalie.in/1695818753281_Homepage_Banner',
                //     backgroundImageMobile:
                //         'https://cdn.loyalie.in/1695983502175_homebanner_mobile',
                //     linearGradient: {
                //         R: '0',
                //         G: '0',
                //         B: '0',
                //         opacity: '0',
                //         size: 'cover'
                //     },
                //     bannerContainerClass:
                //         'h-[60vh] md:h-[45vh] lg:h-[75vh] bg-center w-[95%] md:w-[70%] lg:w-[75%] ml-auto bg-center lg:bg-right-top lg:bg-cover mb-5 md:mb-10',
                //     bannerItemContainerClass:
                //         'items-start md:items-start justify-top md:justify-center md:-ml-48 lg:-ml-48  xl:-ml-64 2xl:-ml-72',
                //     position: 'text-left',
                //     components: [
                //         {
                //             type: 'CUSTOM',
                //             className:
                //                 'w-[90%] md:w-[70%] lg:w-[50%] xl:w-[55%] md:mx-0 pl-8 md:pl-0 pr-4 pt-7 md:pt-9 xl:pt-10 pb-6 md:pb-8 md:pr-6 xl:pr-10 !bg-gradient-to-r !from-white from-90% !via-white !bg-auto	',
                //             components: [
                //                 {
                //                     type: 'TEXT',
                //                     content:
                //                         'Experience privilege at every step with Naman Loyalty Club ',
                //                     className: `inline-block uppercase text-primary tracking-wide leading-[1] text-[25px] md:text-[32px] xl:text-[42px] 2xl:text-[52px] ${
                //                         getFont('NAMAN').heading
                //                     }`
                //                 },
                //                 {
                //                     type: 'TEXT',
                //                     content:
                //                         'An initiative by Shree Naman Group.',
                //                     className: `pt-3 text-[14px] md:text-[14px] xl:text-[17px] 2xl:text-[20px] text-secondary tracking-normal font-medium self-center break-words leading-[1] ${
                //                         getFont('NAMAN').medium
                //                     }`
                //                 }
                //             ]
                //         },
                //         {
                //             type: 'BUTTON',
                //             content: 'Refer Now',
                //             className: ` text-center font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-4 md:px-8 ml-8 md:ml-0 ${
                //                 getFont('NAMAN').medium
                //             }`,
                //             path: 'gift-and-earn',
                //             onClick: 'CHANGE_PATH_AND_SCROLL',
                //             scrollTo: 'referral_section'
                //         }
                //     ]
                // },

                {
                    type: 'CUSTOM',
                    id: 'overview',
                    url: 'https://cdn.loyalie.in/82775700.png',
                    linearGradient: {
                        R: '0',
                        G: '97',
                        B: '168',
                        opacity: '1'
                    },
                    className:
                        'flex flex-col justify-center text-center py-10 px-4 bg-primary ',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'About Naman Loyalty Club',
                            className: `text-white uppercase text-center uppercase text-[25px] md:text-[35px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px] leading-[1] tracking-wide  ${
                                getFont('NAMAN').heading
                            }`
                        },

                        {
                            type: 'TEXT',
                            content:
                                'The quest for innovation & excellence is our true definition. Since our inception in 1993, Shree Naman Group has been at the forefront of developing innovative services. Naman Loyalty Club is an exclusive initiative by Shree Naman Group designed to create unique experiences for you and help you build your circle through referrals and get rewarded for it.',
                            className: `pt-3 px-5 text-[15px]  md:text-[14px] xl:text-[17px] text-white leading-[1.3] self-center w-[95%] lg:w-[90%] 2xl:w-[85%] break-words font-normal   ${
                                getFont('NAMAN').body
                            }`
                        }
                    ]
                },
                // OUR PROJECTS
                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/82775700.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '1',
                        size: '40%'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-12 xl:pt-16 pb-10',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR PROJECTS',
                            className: `${
                                getFont('NAMAN').heading
                            } text-primary-focus self-center mx-auto text-center w-[90%] md:w-[60%] lg:w-[50%] 2xl:w-[41%] text-[25px] md:text-[35px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px] leading-[1] xl:leading-9 tracking-wide`
                        },
                        {
                            type: 'CAROUSEL',
                            customSlide: true,
                            caraouselClassName:
                                'px-8 w-[99%] md:w-[97%] xl:w-[84%] 2xl:w-[92%] 3xl:w-[80%] mx-auto md:pb-10 pt-1 relative lg:pb-10 lg:py-5',
                            imageContainerClassName: 'm-4 relative',
                            imageClassName:
                                'mx-auto lg:bg-cover h-full w-[400px] object-cover object-center rounded-lg',
                            captionClass: `uppercase text-sm lg:text-lg leading-8 ${
                                getFont('NAMAN').body
                            }`,
                            xlSlidesToShow: 3,
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 3,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/1698226610543_naman resi',
                                    alt: 'img',
                                    url: `${domain}/naman-home/projects/detail?projectId=4`,
                                    caption: 'Naman Residency - BKC'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/1698226601025_habitat',
                                    alt: 'img',
                                    url: `${domain}/naman-home/projects/detail?projectId=3`,
                                    caption: 'Naman Habitat'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/1698229979513_premier',
                                    alt: 'img',
                                    url: `${domain}/naman-home/projects/detail?projectId=2`,
                                    caption: 'Naman Premier'
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: '',
                    bannerItemContainerClass: 'bg-cover',
                    className: 'text-left bg-left lg:bg-top ',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'flex  items-center lg:items-start relative',
                            bannerItemContainerClass: '',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-[80%] md:w-[50%] lg:w-[56%] xl:w-[57%] 2xl:w-[60%] border-t-[3px] border-b-[3px] border-r-[3px] border-primary-focus pt-10 xl:pt-14 text-left h-[130vh] md:h-[69vh]  lg:h-[120vh] xl:h-[112vh] 2xl:h-[138vh] ',
                                    bannerItemContainerClass: '',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full space-y-12 md:space-y-8 xl:space-y-16 2xl:space-y-20 absolute right-0 ',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[98%] md:flex  flex-col md:flex-row items-center md:space-x-8 lg:space-x-12 2xl:space-x-16 2xl:pl-20 px-8 lg:px-14 xl:px-20 2xl:px-0',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://cdn.loyalie.in/1695887399047_Image-1',
                                                            position: 'center',
                                                            classNameContainer:
                                                                'w-full cursor-pointer',
                                                            onImageClick:
                                                                () => {
                                                                    window.open(
                                                                        `${domain}/naman-home/deals`
                                                                    );
                                                                }
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[80%] md:w-[70%] lg:w-[50%] pt-5 md:pt-0  ',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    onClick:
                                                                        'CHANGE_PATH',
                                                                    path: 'deals',
                                                                    content:
                                                                        'Enjoy Exclusive Membership Benefits',
                                                                    className: `${
                                                                        getFont(
                                                                            'NAMAN'
                                                                        )
                                                                            .heading
                                                                    } text-primary tracking-wide text-[25px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px] leading-[1] pb-2 cursor-pointer`
                                                                },
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'Discover a wide range of deals and discounts across 10+ categories from top brands across the country.',
                                                                    className: `text-[15px] md:text-[14px] xl:text-[17px] text-secondary leading-[1.3] font-semibold 2xl:w-[90%]  ${
                                                                        getFont(
                                                                            'NAMAN'
                                                                        ).body
                                                                    }`
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[98%] md:flex  flex-col md:flex-row items-center md:space-x-8 lg:space-x-12 2xl:space-x-16 2xl:pl-20 px-8 lg:px-14 xl:px-20 2xl:px-0',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://cdn.loyalie.in/1695887409360_Image-2',
                                                            position: 'center',
                                                            classNameContainer:
                                                                'w-full cursor-pointer',
                                                            onImageClick:
                                                                () => {
                                                                    window.open(
                                                                        `${domain}/naman-home/events`
                                                                    );
                                                                }
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[80%] md:w-[70%] lg:w-[50%] pt-5 md:pt-0 ',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'WORKSHOPS AND EVENTS',
                                                                    onClick:
                                                                        'CHANGE_PATH',
                                                                    path: 'events',
                                                                    className: `${
                                                                        getFont(
                                                                            'NAMAN'
                                                                        )
                                                                            .heading
                                                                    } text-primary tracking-wide text-[25px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px] leading-[1] pb-2 cursor-pointer	`
                                                                },
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'Enjoy curated workshops & events to add more delight to your community experiences.',
                                                                    className: `text-[15px] md:text-[14px] xl:text-[17px] text-secondary leading-[1.3] font-semibold 2xl:w-[90%]  ${
                                                                        getFont(
                                                                            'NAMAN'
                                                                        ).body
                                                                    }`
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[98%] md:flex  flex-col md:flex-row items-center md:space-x-8 lg:space-x-12 2xl:space-x-16 2xl:pl-20 px-8 lg:px-14 xl:px-20 2xl:px-0',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://cdn.loyalie.in/1695887419884_Image-3',
                                                            position: 'center',
                                                            classNameContainer:
                                                                'w-full cursor-pointer',
                                                            onImageClick:
                                                                () => {
                                                                    window.open(
                                                                        `${domain}/naman-home/blogs`
                                                                    );
                                                                }
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[80%] md:w-[70%] lg:w-[50%] pt-5 md:pt-0 ',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'Explore our Home & Lifestyle Guide',
                                                                    onClick:
                                                                        'CHANGE_PATH',
                                                                    path: 'blogs',
                                                                    className: `${
                                                                        getFont(
                                                                            'NAMAN'
                                                                        )
                                                                            .heading
                                                                    } text-primary tracking-wide text-[25px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px] leading-[1] pb-2 cursor-pointer`
                                                                },
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'Unlock a new world of trends in Home & Lifestyle on our platform and design your world with a personal touch.',
                                                                    className: `text-[15px] md:text-[14px] xl:text-[17px] text-secondary leading-[1.3] font-semibold  2xl:w-[85%] ${
                                                                        getFont(
                                                                            'NAMAN'
                                                                        ).body
                                                                    }`
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-16 xl:pt-20',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR LATEST BLOGS',
                            className: `${
                                getFont('NAMAN').heading
                            } text-primary-focus self-center tracking-wide text-center w-full md:w-[80%] lg:w-[70%] 2xl:w-[60%] text-[25px] md:text-[35px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px] mx-auto text-center leading-[1]`
                        },
                        {
                            type: 'BLOG_LAYOUT_VERTICAL',
                            className:
                                'w-full h-auto flex flex-col-reverse lg:flex-row mt-10 px-5 lg:px-4 xl:px-2 2xl:px-10 justify-center',
                            classNameContainer:
                                'overflow-x-auto lg:overflow-x-hidden w-[95%] md:w-[70%] mx-auto lg:w-full px-4 md:px-10 xl:w-[90%] flex lg:grid lg:grid-cols-12 gap-x-10 lg:gap-x-6 xl:gap-x-14 3xl:gap-x-24 gap-y-14 pb-10 lg:pb-20 '
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] py-6 relative',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR Exclusive Offers',
                            className: `${
                                getFont('NAMAN').heading
                            } text-primary-focus self-center tracking-wide text-center w-[90%] md:w-[80%] lg:w-[70%] 2xl:w-[60%] text-[25px] md:text-[35px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px] mx-auto text-center leading-[1] uppercase `
                        },
                        {
                            type: 'CAROUSEL',
                            getVendorLogos: true,
                            offerCards: true,
                            getVendorData: true,
                            backgroundImageNone: true,
                            xlSlidesToShow: 4,
                            classNameCTA: `bg-primary text-[15px] rounded-md px-5 py-2.5 text-white text-center mt-2 mx-auto uppercase font-semibold ${
                                getFont('NAMAN').body
                            }`,
                            ContainerClassName:
                                'bg-cover rounded-lg h-auto border-[20px] border-primary ',
                            caraouselClassName: `w-[80%] drop-shadow-lg md:w-full lg:w-[80%] xl:w-[80%] 2xl:w-[95%] 3xl:w-[80%] mx-auto md:px-12  pt-6 relative pb-10  ${
                                getFont('NAMAN').body
                            }`,

                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 5,
                            pauseOnHover: true,
                            speed: 1000,
                            onImageClick: openURLNewWindow
                        }
                    ]
                }
            ]
        },

        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },

        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        referPage: {
            show: true,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            scrollTo: 'referral_section',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: false,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[75vh] md:h-[70vh] object-cover bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center lg:text-left text-secondary text-[15px] md:text-[20px] xl:text-[25px] uppercase my-5 tracking-widest lg:w-[55%] ml-auto  ${
                                getFont('NAMAN').medium
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: 'flex lg:w-[55%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: ` text-center font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-4 md:px-8 ml-8 md:ml-0 ${
                                        getFont('NAMAN').medium
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[45vh] md:h-[70vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: [
                    {
                        type: 'TEXT',
                        content: 'Refer and Earn 1%* of agreement value',
                        className: `text-center lg:text-left text-secondary text-[15px] md:text-[20px] xl:text-[25px] uppercase my-5 tracking-widest lg:w-[55%] ml-auto  ${
                            getFont('NAMAN').medium
                        }`
                    }
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex lg:w-[55%] ml-auto',
                    //     components: [
                    //         {
                    //             type: 'BUTTON',
                    //             content: 'Start Referring Now',
                    //             onClick: 'FUNC',
                    //             onClickFunc: () =>
                    //                 store.dispatch({
                    //                     type: 'authentication/openLoginModal'
                    //                 }),
                    //                 className: ` text-center font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-4 md:px-8 ml-8 md:ml-0 ${getFont('NAMAN').medium
                    //             }`,
                    //         }
                    //     ]
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-white bg-primary text-[15px] uppercase ${
                getFont('NAMAN').medium
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[85vh] bg-top md:bg-top  mx-auto text-center border-b-[3px] border-primary-focus',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.10'
            },
            backgroundImage: 'https://cdn.loyalie.in/1695987591646_blog_banner',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1695987603876_blog_banner_mobile',
            components: [
                {
                    type: 'TEXT',
                    content: 'SHREE NAMAN READERS’ CLUB',
                    className: `mt-12 md:mt-10 text-primary text-[25px] md:text-[35px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px] tracking-wider ${
                        getFont('NAMAN').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] g:w-[15%] xl:w-[20%] bg-white border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal text-white mt-5 mb-3  text-xl md:text-[16px] xl:text-[17px] tracking-wider ${
                        getFont('NAMAN').medium
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `mx-auto text-center mt-3 font-medium text-[12px] md:text-[17px] tracking-wider bg-primary-focus text-white py-3 2xl:py-3 px-12 md:px-8 ${
                        getFont('NAMAN').medium
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'SHREE NAMAN READERS’ CLUB',
                    className: `mt-12 md:mt-10 text-primary text-[25px] md:text-[35px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px] tracking-wider ${
                        getFont('NAMAN').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] g:w-[15%] xl:w-[20%] bg-white border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal text-white mt-5 mb-3  text-xl md:text-[16px] xl:text-[17px] tracking-wider ${
                        getFont('NAMAN').medium
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `mx-auto text-center mt-3 font-medium text-[12px] md:text-[17px] tracking-wider bg-primary-focus text-white py-3 2xl:py-3 px-12 md:px-8 ${
                        getFont('NAMAN').medium
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('NAMAN').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('NAMAN').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary-focus text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0 tracking-normal text-[20px] text-[#1c2936] ${
                    getFont('NAMAN').heading
                }`,
                headingClass: `text-[25px] xl:text-[32px] 2xl:text-[42px] uppercase text-primary mt-3 leading-[1] break-words ${
                    getFont('NAMAN').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('NAMAN').medium
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('NAMAN').body
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('NAMAN').medium
                }`
            },
            sectionHeadings: `text-primary uppercase pt-10 text-center uppercase text-[25px] md:text-[35px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px] tracking-wide uppercase ${
                getFont('NAMAN').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout3: {
            cardHeight:
                'h-[500px] md:h-[470px] lg:h-[500px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[260px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-primary my-4 md:my-5 h-[45px] lg:h-[70px] text-[25px] md:text-[27px] 3xl:text-[30px] tracking-normal font-medium uppercase leading-[1] break-words ${
                getFont('NAMAN').heading
            }`,
            categoryClass: `bg-primary text-white ${getFont('NAMAN').body}`,
            summaryClass: `font-normal text-[15px] md:text-[16.5px] xl:text-[17px] mt-2 leading-[1.2] text-secondary h-[77px] md:h-[50px] lg:h-[80px] ${
                getFont('NAMAN').medium
            }`,
            buttonClass: `bg-primary-focus flex justify-center self-end float-right text-[13px] xl:text-[16px] text-white py-1.5 px-4 md:px-4 m-5 ${
                getFont('NAMAN').medium
            }`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[13px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-secondary ${
                getFont('NAMAN').body
            }`,
            noBlogsClass: `bg-secondary text-white p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('NAMAN').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/33191553.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/33191553.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0',
                size: 'cover'
            },

            negativeMargin: ' ',
            containerClass: ' text-white w-[50%] bg-top',
            heading: '',
            headingClass: ` text-center lg:w-[70%] text-[30px] xl:text-[42px] 2xl:text-[62px] text-white uppercase ${
                getFont('NAMAN').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('NAMAN').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('NAMAN').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('NAMAN').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('NAMAN').bold
                    }`
                }
            ]
        },

        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: `self-center text-[32px] xl:text-[42px] 2xl:text-[52px] uppercase tracking-wide text-primary mt-14 leading-10 break-words ${
                getFont('NAMAN').heading
            }`,
            dividerClass:
                'my-4 mx-auto  w-[30%] md:w-[20%] lg:w-[10%] bg-[#eb0988] border-none h-2 md:h-[8px] hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('NAMAN').heading
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('NAMAN').body
            }`
        },
        eventLayout: {
            loadMoreButtonClass: `mx-auto text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 ${
                getFont('NAMAN').body
            }`,
            titleClass: `text-primary-focus leading-5 uppercase text-[22px] font-medium mt-2 ${
                getFont('NAMAN').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-accent font-semibold text-lg my-4 z-10 ${
            getFont('NAMAN').body
        }`,
        noGalleryClass: ` text-accent font-semibold ${getFont('NAMAN').body}`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content  max-w-full h-[30vh] lg:h-[45vh] bg-top bg-cover', //bg-top bg-cover
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0',
                size: 'cover'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1697179801608_offer banner',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1697179801608_offer banner',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            sectionBackgroundImage: '',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.98',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center text-[22px] md:text-[20px] text-center text-xl text-neutral w-[60%] ${
                getFont('NAMAN').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `text-primary text-2xl xl:text-3xl leading-7 xl:leading-9 ${
                getFont('NAMAN').heading
            } `,
            subAdditionalText:
                'Naman Loyalty Club is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-normal text-[14px] text-neutral ${
                getFont('NAMAN').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('NAMAN').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/82775700.png',
            pattern2: 'https://cdn.loyalie.in/82775700.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.40',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-2xl lg:text-xl leading-6 lg:leading-5 text-primary ${
                getFont('NAMAN').body
            }`,
            listBodyClass: `${getFont('NAMAN').body}`,
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` text-2xl xl:text-3xl text-primary  leading-7 xl:leading-9 font-normal ${
                getFont('NAMAN').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('NAMAN').heading
            }`,
            dateClassName: ` text-neutral capitalize font-medium  text-secondary ${
                getFont('NAMAN').body
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-sm  ${
                getFont('NAMAN').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-xs text-neutral break-words leading-1 ${
                getFont('NAMAN').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-primary-focus text-white font-medium self-center text-[16px] py-2.5 px-7 py-3 2xl:py-3 px-12 md:px-8  ${
                    getFont('NAMAN').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `hover:shadow-xl rounded bg-primary-focus hover:bg-primary text-white font-medium self-center text-[16px] py-2.5 px-7 py-3 2xl:py-3 px-7 md:px-8 ${
                    getFont('NAMAN').body
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0'
                },
                backgroundImage:
                    'https://cdn.loyalie.in/1698238576534_pro banner',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1698238576534_pro banner',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[87vh]  md:bg-center'
            }
        ],
        moduleConfig: {
       
            constructionVisible: true,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: '',
          
            QRCodeImage: true
        }
    },
    contactUs: {
        sectionBackgroundImage: '', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.30',
            repeat: 'repeat',
            size: 'contain'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.30'
                },
                backgroundImage: 'https://cdn.loyalie.in/38922635.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/26633965.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center lg:bg-center h-[90vh] lg:h-[85vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: `text-center text-[25px] md:text-[35px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px]  tracking-wide text-primary uppercase ${
                            getFont('NAMAN').heading
                        }`
                    }
                ]
            }
        ],

    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('NAMAN').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('NAMAN').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.30'
                },
                backgroundImage: 'https://cdn.loyalie.in/14952553.jpeg',
                backgroundImageMobile: 'https://cdn.loyalie.in/14952553.jpeg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center h-[90vh] lg:h-[85vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS & CONDITIONS',

                        className: `text-center text-[25px] md:text-[35px] lg:text-[32px] xl:text-[42px] 2xl:text-[52px]  text-primary tracking-wide uppercase ${
                            getFont('NAMAN').heading
                        }`
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('NAMAN').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('NAMAN').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Shree Naman Group  project, and has entered into a registered Agreement to Sell; or (ii) employee of Shree Naman Group ; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Shree Naman Group  or any of its sister concerns/subsidiaries/affiliates (“Shree Naman Group  Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Shree Naman Group  Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Shree Naman Group .',
                            'Referrer shall not be able to refer existing customer(s) of Shree Naman Group . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Shree Naman Group  or (ii) is/are already registered with Shree Naman Group  as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Shree Naman Group ’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Shree Naman Group  within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Shree Naman Group ',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Shree Naman Group  or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('NAMAN').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Shree Naman Group, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('NAMAN').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('NAMAN').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('NAMAN').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('NAMAN').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('NAMAN').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('NAMAN').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('NAMAN').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        showMyProperty: false,
        appFontHeading: `${getFont('NAMAN').body}`,
        appStoreLink:
            'https://apps.apple.com/tr/app/naman-loyalty-club/id6467755573?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.naman&utm_medium=email',
        appScreen: 'https://cdn.loyalie.in/54255047.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('NAMAN').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[10.5px] 3xl:text-[15px] leading-[18px] tracking-tight text-white ${
            getFont('NAMAN').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-secondary text-extrabold text-[17.px] ${
            getFont('NAMAN').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-90'
    },
    referralForm: {
        name: 'Naman',
        theme: 'namanTheme',
        color: '#ffffff',
        // backgroundImage:
        //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/33213763.jpg',
        backgroundImage: 'https://cdn.loyalie.in/7023826.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '20',
            G1: '20',
            B1: '20',
            opacity1: '0',
            R2: '52',
            G2: '52',
            B2: '52',
            opacity2: '0'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own a',
            // bookedCustomerHeading2: 'Shree Naman Group Property',

            employee: true,
            employeeHeading1: 'I am a',
            // employeeHeading1: 'Shree Naman Group Employee',

            salesAdmin: true,
            salesAdminHeading1: 'I am an',
            // salesAdminHeading2: 'Shree Naman Group Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        customConfig: {
            welcomeContent: '',
            headingColor: 'text-white',
            userBgColor: 'bg-transparent',
            borderColor: 'border-primary',
            animateUserType: true
        },
        pastReferralConfig: {
            selectedStatusColor: 'text-white',
            bgColor: 'bg-primary'
        },
        formContent: {
            heading: '"Build your own bespoke community"',
            headingClass: `self-center text-white sm:mt-0 text-3xl md:text-4xl xl:text-[48px] 2xl:text-[62px] w-[80%] lg:w-[50%] xl:w-[80%] 2xl:w-[90%] text-center tracking-wide leading-8 xl:leading-[50px] 2xl:leading-[60px] ${
                getFont('NAMAN').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%230c5391'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink: '/naman-home/terms-and-conditions',
        customLink: '/naman-home/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default NamanConfig;
