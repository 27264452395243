import React from 'react';
import BsInfoCircle from '@meronex/icons/bs/BsInfoCircle';

function DisplayMessage({ content, className, config }) {
    const messages = content.split('. ').map((msg, index) => (
        msg.trim() + (index !== content.split('. ').length - 1 ? '.' : '')
    ));
    return (
        <div className=" w-full lg:pl-6 mt-8 lg:mb-8 ">
            <div className='flex flex-col lg:flex-row  lg:gap-10 '>
                <div className='flex justify-center lg:justify-start'>
                <BsInfoCircle className='h-20 w-20 text-black ' /> 
                </div>
                  
                   <div className=' lg:border-l-2  lg:border-gray-300'>
                   <h1 className='text-black font-bold mt-4 text-xl lg:ml-8 text-center lg:text-left'>Hello There</h1>

                   {messages.map((msg, index) => (
               
                        <h3 key={index}
                         className={` ${className
                        ? className
                        : 'text-black text-base w-full mt-2 lg:mt-1  lg:ml-8 text-center lg:text-left '
                        }`}

                        >
                           {msg}
                      </h3>
                  ))}
            
                   </div>
                  
            </div>
        
           

           
        </div>
    );
}

export default DisplayMessage;
