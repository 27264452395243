import { createSlice } from '@reduxjs/toolkit';

const propertyLayoutSlice = createSlice({
    name: 'propertyLayout',
    initialState: { isMapView: true },
    reducers: {
        switchPropertyLayout(state, action) {
            // console.log(state.isMapView);
            state.isMapView = action.payload;
        }
    }
});

export const propertyLayoutActions = propertyLayoutSlice.actions;
export default propertyLayoutSlice;
