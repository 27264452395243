
import React from 'react'
import { getConfig } from '../../../../utils/config'
import { getFileExtension } from '../../../Documentation/helper'

const getIcon = (type) => {
  switch (type) {
    case 'jpeg':
    case 'jpg':
    case 'png': return renderImageIcon();
    case 'docx':
    case 'doc':
    case 'xml': return renderDocxIcon();
    case 'pdf': return renderPdfIcon();
    case 'csv':
    case 'xlsx':
    case 'xlsm': return renderExcelIcon();
    default: return renderPdfIcon();
  }
}

const renderPdfIcon = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_10531" data-name="Group 10531" transform="translate(-1009 -689)">
      <path id="Path_6451" data-name="Path 6451" d="M5,0H35a5,5,0,0,1,5,5V35a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" transform="translate(1009 689)" fill="#d24f3e" />
      <text id="PDF" transform="translate(1029 715)" fill="#fff" font-size="14" font-family="SegoeUI, Segoe UI"><tspan x="-12.247" y="0">PDF</tspan></text>
    </g>
  </svg>
  )
}

const renderDocxIcon = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_10531" data-name="Group 10531" transform="translate(-1009 -689)">
      <g id="Component_1_1" data-name="Component 1 – 1" transform="translate(1009 689)">
        <path id="Path_6451" data-name="Path 6451" d="M5,0H35a5,5,0,0,1,5,5V35a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" fill="#4d78eb" />
      </g>
      <text id="DOC" transform="translate(1029 715)" fill="#fff" font-size="14" font-family="SegoeUI, Segoe UI"><tspan x="-14.52" y="0">DOC</tspan></text>
    </g>
  </svg>

  )
}

const renderExcelIcon = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_10531" data-name="Group 10531" transform="translate(-1009 -689)">
      <g id="Component_1_1" data-name="Component 1 – 1" transform="translate(1009 689)">
        <path id="Path_6451" data-name="Path 6451" d="M5,0H35a5,5,0,0,1,5,5V35a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" fill="#14897b" />
      </g>
      <text id="XLS" transform="translate(1029 715)" fill="#fff" font-size="14" font-family="SegoeUI, Segoe UI"><tspan x="-11.143" y="0">XLS</tspan></text>
    </g>
  </svg>

  )
}

const renderImageIcon = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_10531" data-name="Group 10531" transform="translate(-1009 -689)">
      <g id="Component_1_1" data-name="Component 1 – 1" transform="translate(1009 689)">
        <path id="Path_6451" data-name="Path 6451" d="M5,0H35a5,5,0,0,1,5,5V35a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" fill="#4652a4" />
      </g>
      <text id="JPG" transform="translate(1029 715)" fill="#fff" font-size="14" font-family="SegoeUI, Segoe UI"><tspan x="-11.221" y="0">JPG</tspan></text>
    </g>
  </svg>

  )
}

function ProjectDocs({ documents }) {
  const config = getConfig()
  const isImage = ['jpeg', 'jpg', 'png']
  const isPdf = 'pdf'

  const displayType = (src) => {
    const fileType = getFileExtension(src)
    if (isImage.includes(fileType)) {
      return <img className='h-[200px] rounded-xl' src={src} />
    } else {
      return <iframe
        src={`${src}#view=FitV&page=1&scrollbar=0&toolbar=0&statusbar=0&messages=0&navpanes=0`}
        width="100%"
        height="100%"
        title="PDF Preview"
      />
    }
  }
  return (
    <div className=" mt-8 flex flex-col">
      <h4 className={`z-10 text-xl text-[#130E2F] my-2 font-bold ${config.fontTitle}`}>Documents</h4>
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5'>
        {documents?.map((doc) => (
          <div onClick={() => window.open(doc.link)} className={` ${config.fontBody} z-10 bg-gray-200 p-4 rounded-xl cursor-pointer `}>
            <div className='flex items-center mb-2'>
              {getIcon(getFileExtension(doc?.link ? doc?.link : doc?.name))}
              <h1 className='font-semibold ml-2  xl:text-[15px] 2xl:text-[16px]'>{doc.type}</h1>
            </div>
            <div className='' >
              {displayType(doc?.link ? doc?.link : doc?.name)}
            </div>

          </div>))}
      </div>
    </div>
  )
}

export default ProjectDocs
