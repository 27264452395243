import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { scrollToTargetAdjusted } from '../../../utils/ui';


import ComponentsRenderer from '../../ComponentsRenderer';

const Div = styled.div`
    ${(props) => props.margin && `margin: ${props.margin} !important;`}
    ${(props) => props.padding && `padding: ${props.padding} !important;`}
`;

const CustomSection = (props) => {
    const {
        className,
        components,
        padding,
        margin,
        id = '',
        url,
        children,
        overlayClass,
        linearGradient,
        path,
        onClick,
        scrollTo
    } = props;


    const history = useHistory();

    const changePath = (path) => {
        history.push(path);
    };

    const onClickHandler = (type) => {
        switch (type) {
            case 'CHANGE_PATH':
                return changePath(path);
            case 'SCROLL':
                return scrollToTargetAdjusted(scrollTo);
            default:
                return;
        }
    };

    return (
        <div
            
            style={{
                backgroundImage: `linear-gradient(90deg, rgba(${linearGradient?.R
                    }, ${linearGradient?.G}, ${linearGradient?.B}, ${linearGradient?.opacity
                    }),rgba(${linearGradient?.R}, ${linearGradient?.G}, ${linearGradient?.B
                    }, ${linearGradient?.opacity})), url('${url || ''}')`,
                backgroundRepeat: `${linearGradient?.repeat ? linearGradient?.repeat : 'repeat'
                    } `,
                backgroundSize: `${linearGradient?.size ? linearGradient?.size : '50% auto'
                    } `,
                backgroundPosition: `${linearGradient?.position}`
            }}
            className={className || ''}
            id={id}
            onClick={() => onClickHandler(onClick)} 
            
        >
            {overlayClass ? (
                <div
                    className={
                        `${overlayClass}` +
                        ' absolute top-0 bottom-0 left-0 right-0 z-0'
                    }
                ></div>
            ) : null}
            <ComponentsRenderer components={components} />
            {children}
        </div>
    );
};

export default CustomSection;
