import React, { Fragment } from 'react';
import LoginModal from '../../LoginModal';
import { useState, useEffect } from 'react';
import { authActions } from '../../../store/auth-slice';
import NavItem from '../../Atoms/NavItem/index';
import FaBars from '@meronex/icons/fa/FaBars';
import FaTimes from '@meronex/icons/fa/FaTimes';
import { getConfig, routePathMap } from '../../../utils/config';
import setAuthToken from '../../../utils/authtoken';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { getFormattedUserName } from '../../../helpers';
import { deleteWinnreAuthToken } from '../../../utils/axios';

const Overlay = ({ visible }) => {
    return (
        <div
            className={
                'fixed z-30 inset-0 bg-gray-700 bg-opacity-60 overflow-y-auto h-full w-full' +
                (visible ? ' block' : ' hidden')
            }
            id="my-modal"
        ></div>
    );
};

function NavBar({ navBarConfig, setOpenMobileDropdown }) {
    const [showNavBar, setShowNavBar] = useState(false);
    const [showMMenu, setShowMMenu] = useState(false); //s
    const [clicked, setClicked] = useState(false); //s
    const [openMyProfile, setOpenMyProfile] = useState(false);
    const config = getConfig();
    const dispatch = useDispatch();
    const { userName, userRole, isAuthenticated, isLoginModalVisible } =
        useSelector((state) => state.auth);

    useEffect(() => {
        window.addEventListener('scroll', transitionNavBar);

        return () => {
            window.removeEventListener('scroll', transitionNavBar);
        };
    }, []);

    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
    };
    // console.log('render navbar');
    const logout = () => {
        // localStorage.removeItem('token');
        if (config?.autoLogout) {
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('accessToken');
        } else {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
        }
        // setAuthToken(false);
        window?.webengage?.track('LogOut');
        window?.webengage?.user?.logout();
        deleteWinnreAuthToken();
        dispatch(authActions.logout());
    };

    const handleClicks = () => {
        setClicked((prevState) => !prevState);

        if (clicked) {
            setShowMMenu(false);
        } else {
            setShowMMenu(true);
        }
    };

    const setClickFalse = () => {
        setClicked(false);
        setShowMMenu(false);
    };

    const transitionNavBar = () => {
        // alert(window.scrollY);
        if (window.scrollY > 100) {
            setShowNavBar(true);
        } else {
            setShowNavBar(false);
        }
    };

    const routeList = window.reloy_env.client_config.NAVIGATION_ROUTES;

    return (
        <>
            {/* {isLoginModalVisible && <LoginModal />} */}
            {showMMenu && <Overlay visible={showMMenu} />}
            {!window.reloy_env.WEBFORM_ONLY && (
                <header
                    className={
                        `flex justify-between items-center top-0 w-full sticky z-30 ${
                            showNavBar
                                ? config.navBarConfig.stickyHeight
                                    ? config.navBarConfig.stickyHeight
                                    : config.navBarConfig.height || 'h-20 '
                                : config.navBarConfig.height || 'h-20 '
                        }  ${config.navBarConfig?.font || 'font-Roboto'} ` +
                        (showNavBar
                            ? ` ${
                                  config?.navBarConfig.stickyNavBar
                                      .backgroundColor || 'bg-primary-content'
                              }  ${
                                  config?.navBarConfig.stickyNavBar.border
                                      ? `${config?.navBarConfig.stickyNavBar.borderClass}`
                                      : ``
                              } 
                              ${
                                  config?.navBarConfig.stickyNavBar.height
                                      ? `${config?.navBarConfig.stickyNavBar.height}`
                                      : `${config.navBarConfig.height}`
                              } `
                            : ` ${
                                  config.navBarConfig.baseNavBar
                                      .backgroundColor || 'bg-transparent'
                              } ${
                                  config?.navBarConfig.baseNavBar.border
                                      ? `${config?.navBarConfig.baseNavBar.borderClass}`
                                      : ''
                              }
                              ${
                                  config?.navBarConfig.baseNavBar.height
                                      ? `${config?.navBarConfig.baseNavBar.height}`
                                      : `${config.navBarConfig.height}`
                              }
                     
                           `) +
                        (showNavBar
                            ? `${
                                  config?.navBarConfig.stickyNavBar
                                      .backgroundColor || 'bg-primary-content'
                              }`
                            : ``)
                    }
                >
                    {/* Logo */}
                    <Link to="/">
                        <div className="self-center">
                            <img
                                className={
                                    '  object-contain cursor-pointer ' +
                                    (showNavBar
                                        ? `${
                                              config.logoLeft
                                                  ?.stickyNavBarLogoClass ||
                                              ' h-7 xl:h-10 2xl:h-20 mt-2 ml-2'
                                          }`
                                        : `${
                                              config.logoLeft
                                                  ?.baseNavBarLogoClass ||
                                              ' h-7 xl:h-10 2xl:h-20 top-4 2xl:top-10 left-4'
                                          }`)
                                }
                                src={
                                    showNavBar
                                        ? config?.logoLeft?.stickyUrl ||
                                          config?.logoLeft?.url
                                        : config?.logoLeft?.baseUrl ||
                                          config?.logoLeft?.url
                                }
                                alt={config.logoLeft?.name || 'BrandLogo'}
                            />
                        </div>
                    </Link>

                    <div className="flex ">
                        {isAuthenticated && (
                            <div
                                class="lg:hidden relative inline-block "
                                onMouseOver={() => setOpenMyProfile(true)}
                                onMouseLeave={() => setOpenMyProfile(false)}
                            >
                                <button
                                    className={
                                        'flex flex-row items-center px-6 py-2 2xl:px-2 2xl:py-2 lg:pb-2 lg:border-none lg:mr-7 lg:px-0 lg:inline-block transition ease-out duration-75 w-full md:w-auto md:inline ' +
                                        (showNavBar
                                            ? `${config?.navBarConfig.stickyNavBar.itemClass}`
                                            : ` ${config.navBarConfig.baseNavBar.itemClass}`)
                                    }
                                >
                                    {getFormattedUserName(userName)}
                                </button>
                            </div>
                        )}
                        {/* Mobile Toggle Icon */}

                        <div
                            onClick={handleClicks}
                            className={`block lg:hidden self-center mr-4 z-30 ${config?.navBarConfig.mobileIconColor}`}
                        >
                            {clicked ? <FaTimes /> : <FaBars />}
                        </div>
                    </div>
                    {/* Nav */}
                    <ul
                        className={
                            'bg-primary-content self-center lg:bg-transparent flex flex-col lg:flex-row w-full lg:w-auto h-auto l-2 opacity-100 absolute lg:relative py-4 top-0 justify-end lg:block  ' +
                            (showMMenu
                                ? 'visible transition md:text-lg ease-in'
                                : 'invisible lg:visible transition ease-in ') +
                            (showNavBar
                                ? `${
                                      config?.navBarConfig.stickyNavBar
                                          .containerClass ||
                                      ' md:text-lg min-w-full'
                                  }`
                                : `${
                                      config?.navBarConfig.baseNavBar
                                          .containerClass || 'lg:mt-7 2xl:mt-7'
                                  }`)
                        }
                    >
                        <li className="lg:text-center" onClick={setClickFalse}>
                            {Object.keys(routeList)
                                .sort(
                                    (a, b) =>
                                        routeList[a].order - routeList[b].order
                                )
                                .map((page) => (
                                    <NavItem
                                        key={routeList[page].name}
                                        label={routeList[page].name}
                                        show={routeList[page].show}
                                        pathname={
                                            routeList[page].path ||
                                            routePathMap[page] ||
                                            '/'
                                        }
                                        scrollTo={routeList[page].scrollTo}
                                        customUrl={routeList[page].customUrl}
                                        showNavBar={showNavBar}
                                        showMMenu={showMMenu}
                                        setOpenMobileDropdown={
                                            setOpenMobileDropdown
                                        }
                                    />
                                ))}
                            {isAuthenticated && userRole === 'CUSTOMER' && (
                                <NavLink
                                    className={
                                        `px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                        (showNavBar
                                            ? `${
                                                  config?.navBarConfig
                                                      .stickyNavBar.itemClass ||
                                                  'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                              }`
                                            : ` ${
                                                  config.navBarConfig.baseNavBar
                                                      .itemClass ||
                                                  'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                              }`)
                                    }
                                    activeClassName={
                                        config.navBarConfig.showActiveLinkColor
                                            ? 'text-secondary-focus'
                                            : ''
                                    }
                                    to="/myprofile/info"
                                >
                                    My Profile
                                </NavLink>
                            )}
                            {isAuthenticated && userRole === 'CUSTOMER' && (
                                <NavLink
                                    className={
                                        `hidden lg:block px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                        (showNavBar
                                            ? `${
                                                  config?.navBarConfig
                                                      .stickyNavBar.itemClass ||
                                                  'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                              }`
                                            : ` ${
                                                  config.navBarConfig.baseNavBar
                                                      .itemClass ||
                                                  'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                              }`)
                                    }
                                    activeClassName={
                                        config.navBarConfig.showActiveLinkColor
                                            ? 'text-secondary-focus'
                                            : ''
                                    }
                                    to="/myprofile/docs"
                                >
                                    Documents
                                </NavLink>
                            )}

                            {isAuthenticated &&
                                userRole === 'CUSTOMER' &&
                                window.reloy_env.client_config.MY_PROFILE
                                    ?.SHOW_MY_PROPERTY && (
                                    <NavLink
                                        className={
                                            `hidden lg:block px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                            (showNavBar
                                                ? `${
                                                      config?.navBarConfig
                                                          .stickyNavBar
                                                          .itemClass ||
                                                      'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                                  }`
                                                : ` ${
                                                      config.navBarConfig
                                                          .baseNavBar
                                                          .itemClass ||
                                                      'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                                  }`)
                                        }
                                        activeClassName={
                                            config.navBarConfig
                                                .showActiveLinkColor
                                                ? 'text-secondary-focus'
                                                : ''
                                        }
                                        to="/myprofile/property"
                                    >
                                        My Property
                                    </NavLink>
                                )}
                            {isAuthenticated && userRole === 'CUSTOMER' && (
                                <NavLink
                                    className={
                                        `hidden lg:block px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                        (showNavBar
                                            ? `${
                                                  config?.navBarConfig
                                                      .stickyNavBar.itemClass ||
                                                  'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                              }`
                                            : ` ${
                                                  config.navBarConfig.baseNavBar
                                                      .itemClass ||
                                                  'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                              }`)
                                    }
                                    activeClassName={
                                        config.navBarConfig.showActiveLinkColor
                                            ? 'text-secondary-focus'
                                            : ''
                                    }
                                    to="/myprofile/enquiries"
                                >
                                    Enquiries
                                </NavLink>
                            )}
                              {isAuthenticated && (userRole === 'CUSTOMER' || userRole == 'SITE_VISITOR') && config?.loginModal?.showSVLogin &&(
                                <NavLink
                                    className={
                                        `hidden lg:block px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                        (showNavBar
                                            ? `${
                                                  config?.navBarConfig
                                                      .stickyNavBar.itemClass ||
                                                  'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                              }`
                                            : ` ${
                                                  config.navBarConfig.baseNavBar
                                                      .itemClass ||
                                                  'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                              }`)
                                    }
                                    activeClassName={
                                        config.navBarConfig.showActiveLinkColor
                                            ? 'text-secondary-focus'
                                            : ''
                                    }
                                    to="/myprofile/site-visits"
                                >
                                    Site Visits
                                </NavLink>
                            )}
                            {isAuthenticated && (
                                <button
                                    onClick={logout}
                                    className={
                                        `px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                        (showNavBar
                                            ? `${
                                                  config?.navBarConfig
                                                      .stickyNavBar.itemClass ||
                                                  'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                              }`
                                            : ` ${
                                                  config.navBarConfig.baseNavBar
                                                      .itemClass ||
                                                  'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                              }`)
                                    }
                                    activeClassName={
                                        config.navBarConfig.showActiveLinkColor
                                            ? 'text-secondary-focus'
                                            : ''
                                    }
                                >
                                    Logout
                                </button>
                            )}
                            {!isAuthenticated && (
                                <button
                                    onClick={openLoginModal}
                                    className={`text-left px-6 w-full py-2 lg:p-0 lg:border-none lg:w-auto lg:mr-6  ${
                                        showNavBar
                                            ? ` ${config.navBarConfig.stickyNavBar.itemClass}`
                                            : ` ${config.navBarConfig.baseNavBar.itemClass}`
                                    }  `}
                                >
                                    Login
                                </button>
                            )}

                            {isAuthenticated && (
                                <div
                                    class="hidden lg:relative lg:inline-block "
                                    onMouseOver={() => setOpenMyProfile(true)}
                                    onMouseLeave={() => setOpenMyProfile(false)}
                                >
                                    <button
                                        className={
                                            'uppercase flex flex-row items-center px-6 py-2 2xl:px-2 2xl:py-2 lg:pb-2 lg:border-none lg:mr-7 lg:px-0 lg:inline-block transition ease-out duration-75 w-full md:w-auto md:inline ' +
                                            (showNavBar
                                                ? `${config?.navBarConfig.stickyNavBar.itemClass}`
                                                : ` ${config.navBarConfig.baseNavBar.itemClass}`)
                                        }
                                    >
                                        {getFormattedUserName(userName)}

                                        <svg
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            class={
                                                `${
                                                    openMyProfile
                                                        ? ' rotate-180'
                                                        : ' rotate-0'
                                                }` +
                                                ' inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1'
                                            }
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </button>

                                    {openMyProfile && (
                                        <div
                                            class={`z-20 lg:absolute right-0 w-full transition-opacity duration-300  origin-top-right lg:rounded-md lg:shadow-lg md:w-48 `}
                                        >
                                            <div
                                                class={`px-2 lg:rounded-md lg:shadow ${
                                                    userRole === 'CUSTOMER'
                                                        ? 'lg:py-4'
                                                        : ''
                                                } ${
                                                    config?.navBarConfig
                                                        .dropDownBg
                                                        ? config?.navBarConfig
                                                              .dropDownBg
                                                        : 'bg-white'
                                                }`}
                                            >
                                                {/* {userRole === 'CUSTOMER' && ( */}
                                                <NavLink
                                                    to="/myprofile/docs"
                                                    className={` py-2 block px-4 bg-transparent rounded-lg md:mt-0 ${
                                                        config?.navBarConfig
                                                            .baseNavBar
                                                            .dropDownItemClass
                                                    }  ${
                                                        config?.navBarConfig
                                                            .baseNavBar
                                                            .dropDownContainerClass
                                                            ? config
                                                                  ?.navBarConfig
                                                                  .baseNavBar
                                                                  .dropDownContainerClass
                                                            : 'hover:text-gray-900 hover:bg-gray-200'
                                                    } `}
                                                >
                                                    My Profile
                                                </NavLink>
                                                {/* )} */}
                                                <a
                                                    onClick={logout}
                                                    className={`block px-4 py-2 lg:mt-2 bg-transparent rounded-lg md:mt-0 cursor-pointer ${
                                                        config?.navBarConfig
                                                            .baseNavBar
                                                            .dropDownItemClass
                                                    }  ${
                                                        config?.navBarConfig
                                                            .baseNavBar
                                                            .dropDownContainerClass
                                                            ? config
                                                                  ?.navBarConfig
                                                                  .baseNavBar
                                                                  .dropDownContainerClass
                                                            : 'hover:text-gray-900 hover:bg-gray-200'
                                                    } `}
                                                >
                                                    Logout
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {/* </Transition> */}
                                </div>
                            )}
                        </li>
                    </ul>
                </header>
            )}
        </>
    );
}

export default NavBar;
