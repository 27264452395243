import React, { useState } from 'react';
import Circle from '../../../Components/Atoms/Circle';
import useData from '../data';
import { winnreAuth, winnreNoAuth } from '../../../utils/axios';
import Modal from '../../../Components/Organisms/Modal';
import { LoadingSpinner } from '../../../Components/Molecules/LoadingUI';
import { useHistory } from 'react-router-dom';
import FirstStep from './ClaimSteps/FirstStep';
import SecondStep from './ClaimSteps/SecondStep';
import ThirdStep from './ClaimSteps/ThirdStep';
import { useEffect } from 'react';
import { toIndianRs } from '../../../helpers';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { useSelector } from 'react-redux';
import GrClose from '@meronex/icons/gr/GrClose';
import { useLocation } from 'react-router-dom';
import { scrollToTargetAdjusted } from '../../../utils/ui';
import FourthStep from './ClaimSteps/FourthStep';
import MdArrowBack from '@meronex/icons/ios/MdArrowBack';

const TotalRewards = ({ config, userAccountSummary ,getUserAccountSummary}) => {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isKycCompleted, setKycCompleted] = useState(false);
    const [claimInProgress, setClaimInProgress] = useState(false);
    const [openUploadBox, setOpenUploadBox] = useState(false);
    const [error, setError] = useState(null);
    const [showError, setShowError] = useState(false);
    const [isUpdateWorkflow, setUpdateWorkflow] = useState(false);
    const history = useHistory();
    const userRole = useSelector((state) => state.auth.userRole);
    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);
    const status = queryParams.get('status');
    const message = queryParams.get('message');
    const process = queryParams.get('process');

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowError(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, [showError]);

    useEffect(() => {
        // initiateWorkflow('BANK_ACCOUNT_VERIFICATION')
        // initiateWorkflow('DIGILOCKER')

        if (status) {
            scrollToTargetAdjusted('rewards');
        }
        if (status == 'success' && process == 'DIGILOCKER') {
            setKycCompleted(true);
            winnreAuth({
                method: 'POST',
                url: `/winnre/user/change-kyc-status?status=PROCESSING`
            }).then((res) => console.log(res));
            checkBankVerificationStatus();
        }
        if (status == 'success' && process == 'BANK_ACCOUNT_VERIFICATION') {
            setLoading(true);
            winnreAuth({
                method: 'POST',
                url: `/winnre/user/bank-account/change-verification-status?status=PROCESSING`
            }).then((res) => {
                setLoading(false);
                // console.log(res);
            });
            setOpenUploadBox(true);
            setClaimInProgress(true);
            setStep(2);
        }
    }, [process]);

    const checkKycVerificationStatus = (forceInitate, silentCheck) => {
        setLoading(true);
        setUpdateWorkflow(!!forceInitate);
        winnreAuth({ method: 'GET', url: `/winnre/user/user/kyc-status` })
            .then((res) => {
                // console.log('kyc', res);
                let data = 'COMPLETED';
                setLoading(false);

                // this condition is not supposed to be used
                if (res?.status === 'FAILED' && false) {
                    // setLoading(false);
                    setError(res?.message);
                } else {
                    if (res?.message == 'COMPLETED' && !forceInitate) {
                        setKycCompleted(true);
                        checkBankVerificationStatus();
                    } else if (
                        res?.message == 'NOT_INITIATED' ||
                        res?.message == 'INITIATED' ||
                        res.message === 'null' ||
                        res?.message === 'FAILED' ||
                        forceInitate
                    ) {
                        if (silentCheck) {
                            setStep(0);
                            return;
                        }
                        setKycCompleted(false);
                        // setLoading(false);
                        setClaimInProgress(false);
                        setStep(1);
                    } else if (res?.message == 'PROCESSING') {
                        // setKycCompleted(false);
                        // setClaimInProgress(false);
                        // setStep(1);
                        setKycCompleted(true);
                        checkBankVerificationStatus();
                    } else {
                        setError(
                            'Something went wrong during KYC verification. Please try again after sometime'
                        );
                        // setLoading(false);
                    }
                }
            })
            .catch((err) => {
                // console.log(err);
                setLoading(false);
                setError(
                    'Something went wrong during KYC verification. Please try again after sometime'
                );
            });
    };

    const checkBankVerificationStatus = (forceInitate) => {
        setUpdateWorkflow(!!forceInitate);
        setLoading(true);
        winnreAuth({
            method: 'GET',
            url: `/winnre/user/bank-account/verification-status`
        })
            .then((res) => {
                // console.log('bank', res);
                setLoading(false);
                // this condition is not supposed to be used
                if (res?.status === 'FAILED' && false) {
                    setLoading(false);
                    setError(res?.message);
                } else {
                    let data = 'COMPLETED';
                    if (res?.message == 'COMPLETED' && !forceInitate) {
                        setClaimInProgress(false);
                        // winnreAuth({ method: 'POST', url: '/winnre/user/customer/claim-rewards' }).then((res) => {
                        //     setLoading(false);
                        setStep(3);
                        setOpenUploadBox(true);
                        return;
                    } else if (
                        res?.message == 'NOT_INITIATED' ||
                        res?.message == 'INITIATED' ||
                        res?.message === 'null' ||
                        res?.message === 'FAILED' ||
                        forceInitate
                    ) {
                        setKycCompleted(true);
                        setOpenUploadBox(true);
                        setClaimInProgress(false);
                        setStep(1);
                        setLoading(false);
                        return;
                    } else if (res?.message == 'PROCESSING') {
                        setLoading(false);
                        setClaimInProgress(true);
                        setOpenUploadBox(true);
                        setStep(2);
                    } else {
                        setError(
                            'Something went wrong during bank verification. Please try again after sometime'
                        );
                        setLoading(false);
                    }
                }
            })
            .catch(() => {
                setLoading(false);
                setError('Something went wrong during bank verification');
            });
    };

    const initiateWorkflow = (type) => {
        // console.log('type', type);
        setLoading(true);
        winnreAuth({
            method: 'POST',
            url: `/winnre/user/kyc/initiate-workflow?workflowType=${type}`
        })
            .then((res) => {
                // console.log('initiate workflow', res);
                // console.log(res.id, res.customer_identifier, res?.access_token?.id)
                const link = `${window.location.href.split('?')[0]}?process=`;
                if (res?.status === 'FAILED') {
                    setLoading(false);
                    setError(res?.message);
                    setShowError(true);
                } else if (!res.id) {
                    setLoading(false);
                    setError(
                        `Something went wrong during ${
                            type == 'DIGILOCKER' ? 'KYC' : 'bank verification'
                        } initiation. Please try again later`
                    );
                    return;
                }
                const digioOptions = {
                    // trigger env to production for prod deployments
                    environment:
                        winnreAuth.defaults.baseURL ===
                        'https://prod.loyalie.in'
                            ? 'production'
                            : 'sandbox',
                    is_redirection_approach: true, //for redirection
                    is_iframe: false,
                    redirect_url: link + type,
                    logo:
                        config?.logoLeft?.url ||
                        config?.logo?.url ||
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Loyalie/Images/27822817.png',
                    theme: {
                        primaryColor: config?.primaryColor,
                        secondaryColor: '#000000'
                    }
                };

                const digio = new window.Digio(digioOptions);
                digio.init();
                // console.log(res.id, res.customer_identifier, res?.access_token?.id)
                digio.submit(
                    res.id,
                    res.customer_identifier,
                    res?.access_token?.id
                );
            })
            .catch((err) => {
                // console.log(err);
                setLoading(false);
                setError(
                    `Something went wrong during ${
                        type == 'DIGILOCKER' ? 'KYC' : 'bank verification'
                    } initiation`
                );
            });
    };

    const claimNowHandler = () => {
        checkKycVerificationStatus(false, true);
        setOpenUploadBox(true);
        setError(null);
    };
    const closeUploadBox = () => {
        setStep(0);
        setError(null);
        setKycCompleted(false);
        setClaimInProgress(false);
        setOpenUploadBox(false);
        getUserAccountSummary();
    };

    const conditionalComponent = () => {
     
        if (
            isNaN(parseInt(userAccountSummary?.unInvoicedCommission)) ||
            !parseInt(userAccountSummary?.unInvoicedCommission)
        ) {
            return (
                <ThirdStep
                    loading={loading}
                    nothingToClaim
                    closeUploadBox={closeUploadBox}
                />
            );
        }
        switch (step) {
            case 0:
                return (
                    <FirstStep
                        loading={loading}
                        checkKycVerificationStatus={checkKycVerificationStatus}
                        error={error}
                        setOpenUploadBox={setOpenUploadBox}
                    />
                );
            case 1:
                return (
                    <>
                        <div
                            className={`flex ${
                                isUpdateWorkflow
                                    ? 'justify-between'
                                    : 'justify-end'
                            } `}
                        >
                            {isUpdateWorkflow && (
                                <div className="flex items-center ml-3">
                                    {' '}
                                    <MdArrowBack />{' '}
                                    <button
                                        className="ml-1"
                                        onClick={() => setStep(3)}
                                    >
                                        Go Back
                                    </button>
                                </div>
                            )}
                            <GrClose
                                onClick={closeUploadBox}
                                className={`md:self-center h-4 w-4 cursor-pointer mr-3 `}
                            />
                        </div>
                        <SecondStep
                            loading={loading}
                            isUpdateWorkflow={isUpdateWorkflow}
                            initiateWorkflow={initiateWorkflow}
                            closeUploadBox={closeUploadBox}
                            isKycCompleted={isKycCompleted}
                        />
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="flex justify-end">
                            <GrClose
                                onClick={closeUploadBox}
                                className={`md:self-center h-4 w-4 cursor-pointer mr-3 `}
                            />
                        </div>
                        <ThirdStep
                            loading={loading}
                            claimInProgress={claimInProgress}
                        />
                    </>
                );
            case 3:
                return (
                    <>
                        <div className="flex justify-between pl-3 md:pl-8 pr-2 pt-4">
                            <h2 className=" font-Poppins font-bold text-lg ">
                                Confirm your details
                            </h2>
                            <GrClose
                                onClick={closeUploadBox}
                                className={`md:self-center h-4 w-4 cursor-pointer mr-3 `}
                            />
                        </div>
                        <FourthStep
                            checkKycVerificationStatus={
                                checkKycVerificationStatus
                            }
                            claimInProgress={claimInProgress}
                            checkBankVerificationStatus={
                                checkBankVerificationStatus
                            }
                            getUserAccountSummary={getUserAccountSummary}
                            setStep={setStep}
                        />
                    </>
                );
        }
    };
    return (
        <>
            <div>
                <div className=" mt-5 lg:mt-7  flex flex-wrap  justify-between  lg:justify-start  lg:gap-x-6 xl:gap-x-6">

                <div className=" flex flex-col lg:flex-row  bg-[#F5F5F5] lg:bg-transparent rounded-xl  md:gap-x-4 w-full lg:w-[70%]  border-[2px] border-[#EDEDED]">
                <div
                                className=" pl-3 py-5 w-1/2  lg:w-[40%] md:gap-x-4"
                              
                            >
                            
                                <div className=" flex items-center gap-3 text-sm  lg:border-r-2 border-[#EDEDED] background-grey">
                                    <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38.06" viewBox="0 0 34 38.06">
                                  <g id="_7671601_present_gift_packaging_birthday_celebration_icon" data-name="7671601_present_gift_packaging_birthday_celebration_icon" transform="translate(-56.573 -32.76)">
                                 <path id="Path_7024" data-name="Path 7024" d="M306.636,37.708v1.6a11.671,11.671,0,0,1,6.913-1.611l-6.913,2.539v1.044h6.649c3.751,0,3.27-4.508.7-7.246-.032-.034-.065-.068-.1-.1a3.46,3.46,0,0,0-2.524-1.168c-2.137,0-4.163,2.117-5.185,3.386A2.911,2.911,0,0,1,306.636,37.708Z" transform="translate(-228.33)" fill={config.primaryColor}/>
                                 <rect id="Rectangle_17141" data-name="Rectangle 17141" width="7.148" height="7.148" rx="3.574" transform="translate(70.003 35.933)" fill={config.primaryColor}/>
                               <path id="Path_7025" data-name="Path 7025" d="M89.655,147.243a3.286,3.286,0,0,0-2.394-1.023H78.08a2.936,2.936,0,0,1-2.706,1.8h-3.6a2.937,2.937,0,0,1-2.706-1.8H59.883a3.31,3.31,0,0,0-3.307,3.455l.945,21.765a3.3,3.3,0,0,0,3.307,3.168H86.316a3.3,3.3,0,0,0,3.308-3.168l.944-21.764A3.278,3.278,0,0,0,89.655,147.243ZM69.8,172.873h-8.97a1.574,1.574,0,0,1-1.577-1.508L58.308,149.6a1.578,1.578,0,0,1,1.575-1.645h4l-3.466,5.15,3.621-.682.362,4.7,5.4-8.707Zm18.095-1.508a1.574,1.574,0,0,1-1.575,1.508H77.346v-24.34l5.326,8.6.362-4.7,3.621.682-3.466-5.152h4.071a1.578,1.578,0,0,1,1.576,1.645Z" transform="translate(0 -103.788)" fill={config.primaryColor}/>
                              <path id="Path_7026" data-name="Path 7026" d="M93.286,41.276h6.649V40.232l-6.913-2.539A11.666,11.666,0,0,1,99.935,39.3v-1.6a2.914,2.914,0,0,1,.453-1.557c-1.023-1.268-3.049-3.39-5.189-3.39a3.459,3.459,0,0,0-2.52,1.166c-.034.034-.066.068-.1.1C90.016,36.768,89.531,41.276,93.286,41.276Z" transform="translate(-31.093)" fill={config.primaryColor}/>
                                </g>
                             </svg>

                                    </div>
                                <div  className='flex flex-col'>
                                    <h4 className="text-xl  font-bold text-primary">
                                    {toIndianRs(
                                    userAccountSummary?.commissionEarned,
                                    true
                                )}
                                    </h4>
                                    <p>Total Rewards</p>
                                    </div> 
                                  
                                </div>
                            </div>

                            <div className='flex justify-between border-t-2 border-[#EDEDED] lg:border-t-0 '>
                           
                            <div
                                className=" w-1/2  pl-3 lg:pl-2 py-5 lg:w-[10%]
                                    "
                                
                            >
                                <div className="text-sm w-full ">
                                    <h4 className="text-xl  font-bold text-primary">
                                    {toIndianRs(
                                    userAccountSummary?.outStanding,
                                    true
                                )}
                                    </h4>
                                   
                                    <p>Processing</p>
                                </div>
                            </div>
                            <div
                                className={` w-1/2 pl-2 py-5 lg:w-[20%]
                                        `}
                                
                            >
                                <div className=" border-l-2  border-[#EDEDED]  pl-3 text-sm w-full ">
                                    <h4 className="text-xl  font-bold text-primary">
                                    {toIndianRs(userAccountSummary?.commissionPaid)}{' '}
                                    </h4>
                                   
                                    <p>Claimed</p>
                                </div>
                            </div>
                            </div>

                    
                </div>


                <div
                    id="rewards"
                    className=" p-5 rounded-xl text-sm w-[100%] sm:w-[100%] lg:w-[25%] xl:w-[20%] text-white bg-primary mt-5 lg:mt-0  "
                >
                   <h4 className="text-xl font-bold">
                           {toIndianRs(
                                            userAccountSummary?.unInvoicedCommission
                                        )}
                                </h4>
                                <div className="flex  md:gap-2 justify-between items-center">
                                    <div className="">
                                        <p>Unclaimed </p>
                                        <p>Referral</p>
                                    </div>
                           
                            <button
                            id='Claimnow'
                               className='bg-primary lg:px-3 xl:px-4 px-10 py-2 xl:py-2 mr-1 lg:mt-2 xl:mt-0 tracking-widest rounded  border border-white bg-opacity-95'
                                onClick={claimNowHandler}
                            >
                                Claim 
                            </button>
                       
                                </div>

                </div>
               </div>

                <Modal visible={openUploadBox} onClick={closeUploadBox}>
                    {conditionalComponent(step)}
                    {showError && (
                        <div class="alert alert-error w-[80%] animate-pulse mx-auto h-auto mb-2 ">
                            <label>{error}</label>
                        </div>
                    )}
                </Modal>
            </div>
        </>
    );
};

export default TotalRewards;
