import React from 'react'
import Accordian from '../../../../Components/Atoms/Accordian'

const DealFaq = () => {
  return (
    <div className="w-full mb-5 lg:mb-0 ">
    <h1
        className={`font-semibold mx-auto text-2xl pt-10 pb-10 text-center `}
    >
        Frequently Asked Questions
    </h1>
    <div className="w-100">
        <Accordian
            header={
                <h1 className="font-semibold">
                    What is a Cashback Concierge
                    service?
                </h1>
            }
            wrapperClassName={
                'bg-[#fbfbfb] p-3 border-[1px] border-[#c5c5c5] rounded mb-3'
            }
            textColor={'black'}
            useArrows
        >
            <p className="text-sm">
                A Cashback Concierge service is a
                personalized and customer-centric
                feature that rewards users with
                cashback when they present a valid
                invoice for their purchases. It's
                designed to enhance the shopping
                experience by providing users with a
                seamless way to earn cashback on
                their transactions.
            </p>
        </Accordian>
        <Accordian
            header={
                <h1 className="font-semibold">
                    How does a Cashback Concierge
                    service work?
                </h1>
            }
            wrapperClassName={
                'bg-[#fbfbfb] p-3 border-[1px] border-[#c5c5c5] rounded mb-3'
            }
            textColor={'black'}
            useArrows
        >
            <p className="text-sm">
                The Cashback Concierge service
                typically operates as follows:
                <p>&nbsp;</p>
                <ul>
                    <li>
                        <b>
                            1. User Makes a
                            Purchase:
                        </b>{' '}
                        A user makes a purchase from
                        a participating retailer or
                        service provider.
                    </li>
                    <p>&nbsp;</p>
                    <li>
                        <b>
                            2. Presentation of Valid
                            Invoice:
                        </b>{' '}
                        After the purchase, the user
                        presents a valid invoice or
                        proof of purchase to the
                        Cashback Concierge.
                    </li>
                    <p>&nbsp;</p>
                    <li>
                        <b>3. Verification:</b> The
                        Cashback Concierge team
                        verifies the invoice to
                        ensure it meets the
                        eligibility criteria for
                        cashback.
                    </li>
                    <p>&nbsp;</p>
                    <li>
                        <b>4. Cashback Rewards:</b>{' '}
                        Upon successful
                        verification, the user is
                        rewarded with cashback,
                        either as a direct refund or
                        credited to their account.
                    </li>
                </ul>
            </p>
        </Accordian>
        <Accordian
            header={
                <h1 className="font-semibold">
                    What kind of assistance does a
                    Cashback Concierge provide?
                </h1>
            }
            wrapperClassName={
                'bg-[#fbfbfb] p-3 border-[1px] border-[#c5c5c5] rounded mb-3'
            }
            textColor={'black'}
            useArrows
        >
            <p className="text-sm">
                A Cashback Concierge service offers
                various forms of assistance to
                users:
                <p>&nbsp;</p>
                <ul>
                    <li>
                        <b>
                            1. Cashback Information:
                        </b>{' '}
                        They provide users with
                        information about eligible
                        purchases and cashback
                        offers.
                    </li>
                    <p>&nbsp;</p>
                    <li>
                        <b>
                            2. Invoice Submission
                            Support:
                        </b>{' '}
                        They assist users in
                        submitting valid invoices
                        and guide them through the
                        process.
                    </li>
                    <p>&nbsp;</p>
                    <li>
                        <b>
                            3. Verification
                            Assistance:
                        </b>{' '}
                        The Cashback Concierge team
                        verifies invoices to ensure
                        accuracy and eligibility.
                    </li>
                    <p>&nbsp;</p>
                    <li>
                        <b>
                            4. Reward Distribution:
                        </b>{' '}
                        They facilitate the
                        distribution of cashback
                        rewards to users.
                    </li>
                    <p>&nbsp;</p>
                    <li>
                        <b>5. Query Resolution:</b>{' '}
                        Users can reach out to the
                        Cashback Concierge for any
                        queries or concerns related
                        to cashback rewards.
                    </li>
                </ul>
            </p>
        </Accordian>
    </div>
</div>
  )
}

export default DealFaq