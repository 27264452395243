import React from 'react';
import SiFacebook from '@meronex/icons/si/SiFacebook';
import SiInstagram from '@meronex/icons/si/SiInstagram';
import SiTwitter from '@meronex/icons/si/SiTwitter';
import SiLinkedin from '@meronex/icons/si/SiLinkedin';

function Icon(props) {
    const { iconName, onClickUrl, classNameIcon, classNameContainer } = props;

    const getIcon = (iconName) => {
        switch (iconName) {
            case 'BsFacebook':
                return <SiFacebook className={classNameIcon} />;
            case 'BsInstagram':
                return <SiInstagram className={classNameIcon} />;
            case 'BsTwitter':
                return <SiTwitter className={classNameIcon} />;
            case 'BsLinkedin':
                return <SiLinkedin className={classNameIcon} />;
            default:
                return;
        }
    };
    return (
        <div
            className={classNameContainer || ''}
            onClick={() => window.open(onClickUrl)}
        >
            {getIcon(iconName)}
        </div>
    );
}

export default Icon;
