import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
const domain = window.location.origin;
const PurtiConfig = {
    clientId:
        '6b6e22e7059b0914d97659142af96bd203db6edf2f8337f468912ae2f4ad9098',
    id: 37,
    name: 'Purti Realty',
    fullName: 'Purti Realty',
    programName:'Purti Privilege',
    primaryColor: '#57382B',
    // fontBody: `${getFont('PURTI').body}`,
    // fontTitle: `${getFont('PURTI').bold}`,
    theme: 'purtiTheme',
    favicon: 'https://cdn.loyalie.in/67928654.png',

    googleConfig: {
        gID: 'G-28WWZVK623',
        keywordsContent:
            'Purti Privilege real estate referral programs, Bramhacorp real estate referral program, Bramhacorp real estate refer and earn, Purti Privilege real estate project refer, Bramhacorp real estate, Purti Privilege real estate loyalty program, Bramhacorp real estate news, Bramhacorp real estate upcoming projects in Bengaluru, Bramhacorp real estate launch Purti Privilege real estate, Bramhacorp real estate launch, Bramhacorp real estate program, Bramhacorp real estate projects, Bramhacorp real estate blogs, Bramhacorp real estate offers, Bramhacorp real estate upcoming projects in Bengaluru, Bramhacorp real estate events, Bramhacorp, Purti Privilege, Bramhacorp real estate best residential projects, Bramhacorp real estate real estate developers, All New Projects by Bramhacorp real estate Builders & Developers, Ongoing Projects by Bramhacorp real estate Pvt Ltd, Bramhacorp real estate Bengaluru, Bramhacorp real estate new program, Bramhacorp press release, Bramhacorp upcoming projects, Bramhacorp property group, Bramhacorp bangalore, Bramhacorp real estate, Bramhacorp reit, jitu virwani, Bramhacorp commercial projects, Bramhacorp projects, Bramhacorp projects in bangalore',
        descriptionContent:
            'Purti Privilege is an exclusive referral and loyalty program designed for Purti residents. The program aims to make you the brand ambassador of the Purti lifestyle by adding more value to your home ownership and creating delight.',
        authorContent: 'Purti Privilege'
    },

    facebookPixelConfig: {
        id: '220669430858761', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~~991991d0',
    webengageAPIKey:'24437230-e8cf-4622-948a-c1c8744f7e0f',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: false,
    logoLeft: {
        name: 'Purti Logo',
        url: 'https://cdn.loyalie.in/9665604.png',
        stickyNavBarLogoClass: ' h-6 lg:h-7 xl:h-10 pl-4 lg:pl-12 xl:pl-24 ',
        baseNavBarLogoClass: 'h-6 lg:h-7 xl:h-10 pl-4 lg:pl-12 xl:pl-24 '
    },

    // logoRight: {
    //     name: 'Purti Logo',
    //     url: 'https://cdn.loyalie.in/71737049.jpg',
    //     stickyNavBarLogoClass:
    //         'hidden lg:block h-4 lg:h-10 xl:h-12 pr-8 xl:pr-14 ',
    //     baseNavBarLogoClass: 'h-4 lg:h-10 xl:h-12 pr-8 xl:pr-14 '
    // },
    navBarConfig: {
        dropDown: true,
        font: `${getFont('PURTI').body}`,
        height: 'h-16 xl:h-20 ease-out duration-300',
        stickyHeight: 'h-16 xl:h-20 ease-in duration-300 	',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-white',
        footerNavItemClass: `tracking-widest uppercase text-black text-[14px] lg:text-black font-light lg:text-[12px] xl:text-[15px] 3xl:text-lg  ${
            getFont('PURTI').body
        }`,
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-white', // default: transparent
            containerClass: ' lg:mr-5',
            itemClass: `uppercase lg:mr-7 text-primary-focus lg:text-primary-focus hover:text-primary-focus  text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[14px] tracking-wide ${
                getFont('PURTI').body
            }`,
            dropDownContainerClass: `text-primary-focus lg:text-primary-focus hover:text-primary-focus  focus:text-primary-focus lg:focus:shadow-outline tracking-wide ${
                getFont('PURTI').body
            }`,
            dropDownItemClass: `tracking-widest hover:text-primary text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[14px] font-medium lg:hover:text-white lg:hover:bg-accent tracking-wide ${
                getFont('PURTI').body
            }`
        },
        stickyNavBar: {
            border: true,
            borderClass: ' ',
            backgroundColor: 'bg-white lg:drop-shadow-md ', //default: primary-content,
            containerClass: 'lg:mr-5 ', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass: `uppercase lg:mr-7 text-primary-focus lg:text-primary-focus hover:text-primary-focus text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[14px] tracking-wide ${
                getFont('PURTI').body
            }` // Each list element in NavBar (Text color, hover color, size)
        },
        showActiveLinkColor: false
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[14px] md:text-[17px] 2xl:text-[20px] tracking-wider text-secondary font-normal py-1 md:py-2 px-3 md:px-5 md:px-6 border-[2px] border-accent ${
        getFont('PURTI').body
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage: 'https://cdn.loyalie.in/27555726.jpg',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/35553981.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    bannerContainerClass:
                        'h-[80vh] md:h-[85vh] lg:h-[90vh] bg-right lg:bg-top xl:bg-center-top ',
                    bannerItemContainerClass:
                        'items-start lg:justify-center pt-2 md:pt-5 lg:pt-0 px-4 lg:px-12 xl:px-24',
                    position: 'text-left',

                    components: [
                        {
                            type: 'TEXT',
                            content: 'Experience the Joy of Gifting ',
                            className: `text-white md:pt-2 xl:pt-3 text-[18px] md:text-[25px] lg:text-[28px] xl:text-[24px] 2xl:text-[30px] tracking-wide leading-6 lg:leading-8 font-normal ${
                                getFont('PURTI').bold
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'with your Family & Friends',
                            className: `text-white text-[18px] md:text-[25px] lg:text-[28px] xl:text-[24px] 2xl:text-[30px] tracking-wide leading-6 lg:leading-8 font-normal ${
                                getFont('PURTI').bold
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: 'w-[12%] bg-accent h-2 my-3 md:my-5'
                        },
                        {
                            type: 'TEXT',
                            content: 'INTRODUCING',
                            className: ` text-white uppercase text-[20px] md:text-[25px] lg:text-[30px] xl:text-[42px] 2xl:text-[30px] tracking-widest leading-8  ${
                                getFont('PURTI').body
                            }`
                        },
                        {
                            type: 'IMAGE',
                            url: 'https://cdn.loyalie.in/15654949.png',
                            position: 'center',
                            classNameContainer:
                                'w-[40%] lg:w-[35%] 2xl:w-[35%] pt-2'
                        },
                        {
                            type: 'TEXT',
                            content: 'A companion platform designed for ',
                            className: `text-white md:pt-2 lg:pt-3 text-[17px] md:text-[25px] lg:text-[19px] xl:text-[24px] 2xl:text-[22px] tracking-widest leading-6 xl:leading-7 2xl:leading-7 font-normal ${
                                getFont('PURTI').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'Purti homeowners to get regular ',
                            className: `text-white text-[17px] md:text-[25px] lg:text-[19px] xl:text-[24px] 2xl:text-[22px] tracking-widest leading-6 xl:leading-7 2xl:leading-7 font-normal ${
                                getFont('PURTI').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'updates, enjoy membership benefits, ',
                            className: `text-white text-[17px] md:text-[25px] lg:text-[19px] xl:text-[24px] 2xl:text-[22px] tracking-widest leading-6 xl:leading-7 2xl:leading-7 font-normal ${
                                getFont('PURTI').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: '& refer friends and family.',
                            className: `text-white text-[17px] md:text-[25px] lg:text-[19px] xl:text-[24px] 2xl:text-[22px] tracking-widest leading-6 xl:leading-7 2xl:leading-7 font-normal ${
                                getFont('PURTI').body
                            }`
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: '',
                    url: '',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '1',
                        size: '70%'
                    },
                    className: 'flex flex-col ',
                    components: [
                        {
                            type: 'CUSTOM',
                            id: 'overview',
                            className:
                                'flex flex-col mt-10 xl:mt-16 bg-white w-[90%] lg:w-[95%] xl:w-[98%] 2xl:w-[82%] px-4 lg:px-12 xl:px-24',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'ABOUT',
                                    className: `text-accent uppercase text-[20px] md:text-[25px] lg:text-[25px] xl:text-[30px] tracking-wide leading-7 xl:leading-8 ${
                                        getFont('PURTI').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: 'PURTI PRIVILEGE',
                                    className: `text-secondary uppercase text-[20px] md:text-[25px] lg:text-[34px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-7 xl:leading-8 ${
                                        getFont('PURTI').heading
                                    }`
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            className: 'w-full pl-4 lg:pl-12 xl:px-24 mb-10 ',
                            components: [
                                {
                                    type: 'TEXT',
                                    content:
                                        'We, at Purti Realty, prioritize our customers by building homes that offer an ideal living experience and by building communities that create a strong and enduring relationship with them. Purti Privilege is a loyalty program curated exclusively for you, tailored to enhance your homeowning experience by providing access to exclusive events, offers, infotainment blogs, and more.',
                                    className: `mt-12 text-[18px] h-full xl:text-[20px] text-secondary-focus leading-6 xl:leading-[25px] break-words mb-5  ${
                                        getFont('PURTI').body
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: `Furthermore, we recognize and appreciate your efforts in expanding our community by rewarding you.
                                    With this platform, we aim to prioritize your experience and give it the attention it deserves, placing you at the center of affluence.`,
                                    className: `text-[18px] h-full xl:text-[20px] text-secondary-focus leading-6 xl:leading-[25px] break-words ${
                                        getFont('PURTI').body
                                    }`
                                }
                            ]
                        },

                        {
                            type: 'CUSTOM',
                            id: 'overview',
                            url: 'https://cdn.loyalie.in/76002790.jpg',
                            linearGradient: {
                                R: '255',
                                G: '255',
                                B: '255',
                                opacity: '0',
                                size: 'cover'
                            },
                            className: 'h-[320px] md:h-[500px] mt-40 2xl:mt-40',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-[90%] xl:w-[80%] mx-auto flex flex-col md:flex-row items-center md:items-stretch -mt-40 md:-mt-24',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            classNameContainer:
                                                'z-10 w-[70%] md:w-[35%] border-[7px] border-[#FCF4E8]',
                                            className: 'h-42 lg:h-64 w-full',
                                            url: 'https://cdn.loyalie.in/84992818.jpeg'
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-[85%] md:w-[65%] flex flex-col bg-[#FCF4E8] justify-center -mt-20  z-0 md:my-6 ',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[90%] xl:w-[70%] flex flex-col justify-center mx-auto pt-24 pb-5 md:py-0',
                                                    components: [
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'HOME UPDATES',
                                                            className: ` text-secondary text-center md:text-left uppercase text-[20px] md:text-[25px] lg:text-[28px] 2xl:text-[30px] tracking-wide leading-5 lg:leading-7 ${
                                                                getFont('PURTI')
                                                                    .bold
                                                            }`
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'ACCESS ALL YOUR PROPERTY UPDATES',
                                                            className: ` xl:mt-2 text-secondary text-center md:text-left uppercase text-[16px] md:text-[25px] lg:text-[22px] xl:text-[25px] leading-5 lg:leading-7 ${
                                                                getFont('PURTI')
                                                                    .body
                                                            }`
                                                        },
                                                        {
                                                            type: 'BUTTON',
                                                            content:
                                                                'Explore Now',
                                                            className: `mt-2 md:mt-5 mx-10 md:w-[40%] md:mx-0 text-[14px] md:text-[17px] 2xl:text-[20px] tracking-wider text-secondary uppercase font-normal py-1 md:py-2 px-3 md:px-5 md:px-6 border-[2px] border-accent ${
                                                                getFont('PURTI')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            components: [
                                {
                                    type: 'ICONS_LAYOUT',
                                    containerClassName:
                                        'h-content w-full lg:flex lg:h-auto items-center md:-mt-24 2xl:-mt-36  ',
                                    gridClassName:
                                        'grid lg:grid-cols-3 w-[70%] md:w-[60%] lg:w-[85%] xl:w-[80%] gap-x-5 lg:gap-x-12 2xl:gap-x-16 mx-auto',
                                    infoContainerClassName:
                                        'mt-36 marker:mb-4 lg:mt-0 min-w-[60vw] lg:min-w-full',
                                    infoHeadingClassName: `w-full mx-auto leading-6 xl:leading-[27px] md:w-[80%] lg:w-[60%] xl:w-[75%] md:px-2 text-[20px] md:text-[22px] 2xl:text-[20px] uppercase text-secondary font-normal pt-10 pb-6 ${
                                        getFont('PURTI').body
                                    }`,
                                    imgClassName:
                                        'w-[70%] md:w-[50%] lg:w-[75%] rounded-[50%] lg:mx-auto mx-auto -mt-32 lg:-mt-28 xl:-mt-36 border-[7px] border-[#FCF4E8]',
                                    info: [
                                        {
                                            BGColor: '#FCF4E8',
                                            imgUrl: 'https://cdn.loyalie.in/26253987.png',
                                            infoHeading:
                                                'Access Project Information',
                                            buttonClass: `mb-8 text-[15px] md:text-[17px] 2xl:text-[18px] tracking-wider text-secondary uppercase font-normal py-2 px-5 md:px-6 border-[2px] border-accent ${
                                                getFont('PURTI').body
                                            }`,
                                            buttonName: 'VIEW NOW',
                                            onClick: 'CHANGE_PATH',
                                            path: '/projects'
                                        },
                                        {
                                            BGColor: '#FCF4E8',
                                            imgUrl: 'https://cdn.loyalie.in/32516174.png',
                                            infoHeading:
                                                'Avail Exclusive Benefits',
                                            buttonClass: `mb-8 text-[15px] md:text-[17px] 2xl:text-[18px] tracking-wider text-secondary uppercase font-normal py-2 px-5 md:px-6 border-[2px] border-accent ${
                                                getFont('PURTI').body
                                            }`,
                                            buttonName: 'CLAIM NOW',
                                            onClick: 'CHANGE_PATH',
                                            path: '/deals'
                                        },
                                        {
                                            BGColor: '#FCF4E8',
                                            imgUrl: 'https://cdn.loyalie.in/66726756.png',
                                            infoHeading:
                                                'Explore Trending Topics',
                                            buttonClass: `mb-8 text-[15px] md:text-[17px] 2xl:text-[18px] tracking-wider text-secondary uppercase font-normal py-2 px-5 md:px-6 border-[2px] border-accent ${
                                                getFont('PURTI').body
                                            }`,
                                            buttonName: 'READ NOW',
                                            onClick: 'CHANGE_PATH',
                                            path: '/blogs'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    className: 'w-full bg-white py-10',
                    components: [
                        {
                            type: 'TEXT',
                            content: ' OUR EXCLUSIVE OFFERS',
                            className: ` text-secondary uppercase pt-6 text-center text-[20px] md:text-[24px] lg:text-[25px] xl:text-[28px] 2xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                                getFont('PURTI').bold
                            }`
                        },
                        {
                            type: 'CAROUSEL',
                            getVendorLogos: true,
                            offerCards: true,
                            getVendorData: true,
                            backgroundImageNone: true,
                            classNameCTA: `bg-transparent text-[14px] px-4 py-1 text-secondary text-center mt-3 mx-auto uppercase font-semibold border-[2px] border-accent ${
                                getFont('SUSHMA').body
                            }`,
                            border: 'border-[2px] border-accent rounded-none p-4',
                            ContainerClassName: 'bg-cover bg-[#f4f4f4] h-auto ',
                            caraouselClassName: ` w-[85%] xl:w-[86%] 2xl:w-[85%] 3xl:w-[80%] mx-auto md:px-0  pt-16 relative pb-10 ${
                                getFont('SUSHMA').body
                            }`,

                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 4,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow
                        }
                    ]
                },
                {
                    type: 'REFERRAL_FORM_BANNER'
                },

                {
                    type: 'CUSTOM',
                    className: 'w-full  mx-auto relative',
                    components: [
                        {
                            type: 'CUSTOM',
                            className: 'w-full h-[250px]',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'GROUP PROJECTS',
                                    className: `py-16 text-secondary uppercase text-center text-[20px] md:text-[24px] lg:text-[25px] xl:text-[28px] 2xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                                        getFont('PURTI').bold
                                    }`
                                },
                                {
                                    type: 'CUSTOM',
                                    className: 'w-[80%] lg:w-[90%] mx-auto',
                                    components: [
                                        {
                                            type: 'CAROUSEL',
                                            customSlide: true,
                                            caraouselClassName:
                                                '  py-1 relative  ',
                                            imageContainerClassName: 'px-4',
                                            imageClassName:
                                                'w-full h-60 lg:h-60 lg:bg-cover object-cover object-center px-2 pt-2 pb-8 bg-[#FCF4E8] ',
                                            captionClass: ` uppercase px-6 text-[16px] absolute z-1 bottom-1 ${
                                                getFont('PURTI').body
                                            }`,
                                            arrows: true,
                                            autoplay: true,
                                            autoplaySpeed: 3000,
                                            infinite: true,
                                            dots: false,
                                            slidesToShow: 4,
                                            pauseOnHover: true,
                                            speed: 3000,
                                            onImageClick: openURLNewWindow,
                                            imgArr: [
                                                {
                                                    imgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/4/65707564.jpeg',
                                                    alt: 'img',
                                                    url: `${domain}/purti-website/projects/detail?projectId=4`,
                                                    caption: 'Purti Aroma'
                                                },
                                                {
                                                    imgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/5/53935442.jpg',
                                                    alt: 'img',
                                                    url: `${domain}/purti-website/projects/detail?projectId=5`,
                                                    caption: 'Purti Iris'
                                                },
                                                {
                                                    imgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/6/31309966.jpg',
                                                    alt: 'img',
                                                    url: `${domain}/purti-website/projects/detail?projectId=6`,
                                                    caption: 'Purti Veda'
                                                },
                                                {
                                                    imgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/7/20260016.jpg',
                                                    alt: 'img',
                                                    url: `${domain}/purti-website/projects/detail?projectId=7`,

                                                    caption: 'Purti Hastings'
                                                },
                                                {
                                                    imgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/8/48380560.jpg',
                                                    alt: 'img',
                                                    url: `${domain}/purti-website/projects/detail?projectId=8`,

                                                    caption: 'Purti Flowers'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'overview',
                    url: 'https://cdn.loyalie.in/76002790.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0',
                        size: 'cover'
                    },
                    className: 'h-[250px] md:h-[350px] -mb-28'
                }
            ]
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        dropDown: {
            show: true,
            name: 'EXCLUSIVE ACCESS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'TRENDING TOPICS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'COMMUNITY EVENTS',
                        path: '/events'
                    }
                },
                {
                    deals: {
                        show: true,
                        name: 'MEMBERSHIP BENEFITS',
                        path: '/deals'
                    }
                }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: false,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Purti-Realty/Images/74925585.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Purti-Realty/Images/74925585.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center md:text-left text-white text-[15px] md:text-[20px] xl:text-[25px] uppercase my-5 tracking-widest w-full md:w-[60%] ml-auto  lg:leading-8 ${
                                getFont('PURTI').bold
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: 'flex w-full md:w-[60%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `mx-auto md:mx-0 text-[14px] md:text-[17px] 2xl:text-[20px] tracking-wider text-white uppercase font-normal py-1 md:py-2 px-3 md:px-5 md:px-6 border-[2px] border-accent ${
                                        getFont('PURTI').body
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Purti-Realty/Images/74925585.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Purti-Realty/Images/74925585.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[60vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').body
                    //         }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-black text-[15px] leading-10 md:text-[20px] xl:text-[45px] font-bold tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').heading
                    //         }`
                    // },
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex w-[70%] ml-auto justify-center mt-7', components: [{
                    //         type: 'BUTTON',
                    //         content: 'Start Referring Now',
                    //         scrollTo: 'referral_section',
                    //         onClick: 'SCROLL'
                    //     }]
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold tracking-widest bg-primary text-white px-8 md:px-6 py-3 lg:mt-2 xl:mt-0 mx-auto ${
                getFont('PURTI').heading
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hero hero-content max-w-full h-[70vh] md:h-[85vh] bg-top mx-auto text-center ',
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage: 'https://cdn.loyalie.in/69148702.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/42903137.jpg',
            components: [
                {
                    type: 'CUSTOM',
                    className:
                        'w-full lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto mt-20'
                    // components: [
                    //     {
                    //         type: 'TEXT',
                    //         content: 'PURTICORP READERS’ CLUB',
                    //         className: `ml-3 text-secondary-focus uppercase text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                    //             getFont('PURTI').bold
                    //         }`
                    //     }
                    // ]
                }
                // {
                //     type: 'TEXT',
                //     content:
                //         'Bringing Together Information And Entertainment For You.',
                //     className: `font-normal mt-2 mb-3 text-black text-xl md:text-base lg:text-[17px] tracking-wider ${
                //         getFont('PURTI').heading
                //     }`
                // }
                // {
                //     type: 'BUTTON',
                //     content: 'Read More',
                //     scrollTo: 'blogs_section',
                //     onClick: 'SCROLL',
                //     className: `md:mx-0 text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[18px] tracking-wider bg-transparent text-secondary-focus font-normal py-2 md:py-2 2xl:py-2 px-5 md:px-6 border-[3px] border-accent hover:border-primary ${
                //         getFont('PURTI').heading
                //     }`
                // }
            ]
            // loginBannerComponents: [
            //     {
            //         type: 'CUSTOM',
            //         className:
            //             'w-full lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
            //         components: [
            //             {
            //                 type: 'TEXT',
            //                 content: 'PURTICORP ',
            //                 className: `inline-block text-primary uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-normal ${
            //                     getFont('PURTI').heading
            //                 }`
            //             },
            //             {
            //                 type: 'TEXT',
            //                 content: ' READERS’ CLUB',
            //                 className: `inline-block ml-3 text-secondary-focus uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
            //                     getFont('PURTI').bold
            //                 }`
            //             }
            //         ]
            //     },
            //     {
            //         type: 'TEXT',
            //         content:
            //             'Bringing Together Information And Entertainment For You.',
            //         className: `font-normal mt-5 mb-3 text-black text-xl md:text-base lg:text-[17px] tracking-wider ${
            //             getFont('PURTI').heading
            //         }`
            //     },
            //     {
            //         type: 'BUTTON',
            //         content: 'Login To Read Now',
            //         onClick: 'FUNC',
            //         onClickFunc: () =>
            //             store.dispatch({
            //                 type: 'authentication/openLoginModal'
            //             }),
            //         className: `md:mx-0 text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[18px] tracking-wider bg-transparent text-secondary-focus font-normal py-2 md:py-2 2xl:py-2 px-5 md:px-6 border-[3px] border-accent hover:border-primary ${
            //             getFont('PURTI').heading
            //         }`
            //     }
            // ]
        },
        sideBarClass: `text-primary ${getFont('PURTI').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('PURTI').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[16px] md:text-[20px] text-[#1c2936] ${
                    getFont('PURTI').body
                }`,
                headingClass: `text-[20px] md:text-[25px] xl:text-[30px] uppercase font-semibold text-secondary mt-3 leading-6 md:leading-7 xl:leading-9 break-words ${
                    getFont('PURTI').body
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('PURTI').body
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('PURTI').body
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('PURTI').body
                }`
            },
            sectionHeadings: ` text-secondary uppercase pt-10 text-center text-[20px] md:text-[24px] lg:text-[25px] xl:text-[28px] 2xl:text-[30px] 2xl:text-[35px] tracking-wide leading-7 2xl:leading-8     ${
                getFont('PURTI').heading
            }`,
            dividerClass: 'w-[12%] mx-auto bg-accent border-none h-[3px] my-4'
        },
        layout5: {
            cardHeight:
                'h-[505px] md:h-[470px] lg:h-[500px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[260px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'w-[12%] mx-auto bg-accent border-none h-[3px] my-4',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 h-[70px] md:h-[45px] lg:h-[70px] text-[16px] lg:text-[19px] 3xl:text-[20px] font-semibold uppercase tracking-wide leading-6 break-words mx-5  ${
                getFont('PURTI').body
            }`,
            categoryClass: `bg-secondary-content bg-opacity-[0.65] text-white text-[11px] ${
                getFont('PURTI').body
            }`,
            summaryClass: `font-normal text-[16.5px] xl:text-[17px] mt-2 leading-6 text-secondary-focus mx-5 md:h-[50px] lg:h-[70px] ${
                getFont('PURTI').body
            }`,
            buttonClass: `bg-transparent hover:border-primary flex justify-center self-end float-right text-[13px] xl:text-[16px] text-secondary-focus py-1.5 px-4 md:px-4 m-5 font-medium ${
                getFont('PURTI').body
            }`,
            buttonArrowUrl: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.16 11.59'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%2392b4a7;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cline class='cls-1' y1='5.62' x2='55' y2='5.62'/%3E%3Cpolyline class='cls-1' points='48.5 0.39 51.93 3.09 55.35 5.79 51.93 8.49 48.5 11.19'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-[#919396] ${
                getFont('PURTI').body
            }`,
            noBlogsClass: `bg-secondary p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('PURTI').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/72047745.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/80690153.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0'
            },

            negativeMargin: '',
            containerClass: ' text-secondary-focus lg:w-[50%] ',
            heading: '',
            headingClass: ` text-secondary-content uppercase lg:pl-10  text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide font-semibold ${
                getFont('PURTI').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('PURTI').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                ' hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('PURTI').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('PURTI').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-secondary-focus rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('PURTI').bold
                    }`
                }
            ]
        },

        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: ` text-secondary uppercase mt-16 text-center text-[20px] md:text-[24px] lg:text-[25px] xl:text-[28px] 2xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                getFont('PURTI').heading
            }`,
            dividerClass: 'w-[10%] mx-auto bg-accent border-none h-[3px] my-4',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-2 text-secondary w-[60%] ${
                getFont('PURTI').body
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('PURTI').heading
            }`
        },
        eventLayout: {
            titleClass: `text-accent leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('PURTI').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ' text-[15px]'
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary font-semibold text-lg my-4 z-10 ${
            getFont('PURTI').body
        }`,
        noGalleryClass: ` text-secondary font-semibold ${getFont('PURTI').body}`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                ' hidden md:hero hero-content items-start md:items-center max-w-full h-[30vh] lg:h-[45vh] bg-center bg-cover', // //bg-top
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage: 'https://cdn.loyalie.in/1692638473031_offerpurti',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692638473031_offerpurti',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            additionalTextClass: `tracking-wide text-[20px] md:text-[24px] lg:text-[25px] xl:text-[28px] 2xl:text-[30px] leading-7 text-primary uppercase text-left hover:border-b-primary ${
                getFont('PURTI').heading
            } `,
            subAdditionalTextClass: ` leading-5 xl:leading-6  break-words ${
                getFont('PURTI').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('PURTI').heading}`
        },
        dealLayout: {
            listTitleClass: `text-2xl lg:text-[18px] leading-6 lg:leading-5 text-primary ${
                getFont('PURTI').body
            }`,
            listBodyClass: `${getFont('PURTI').body}`,
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` text-2xl xl:text-3xl text-primary uppercase leading-8 xl:leading-9  ${
                getFont('PURTI').body
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('PURTI').body
            }`,
            dateClassName: `py-[6px] text-neutral capitalize text-[17px] text-black  ${
                getFont('PURTI').body
            }`,
            termsAndConditionsClassName: `capitalize text-black text-sm font-semibold text-[12px] ${
                getFont('PURTI').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `text-black text-xs break-words leading-1 ${
                getFont('PURTI').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-transparent text-secondary-focus font-bold uppercase self-center rounded text-[16px] py-2.5 px-7 border-[3px] border-accent ${
                    getFont('PURTI').bold
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `bg-transparent text-secondary-focus rounded uppercase text-[16px] py-[7px] px-10 border-[3px] border-accent ${
                    getFont('PURTI').body
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/26756784.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/70814610.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[70vh] md:h-[80vh] bg-bottom lg:bg-right'

                // components: [
                //     {
                //         type: 'CUSTOM',
                //         className:
                //             'w-full lg:mt-0 lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                //         components: [
                //             {
                //                 type: 'TEXT',
                //                 content: 'PURTICORP ',
                //                 className: `inline-block text-secondary-focus uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-normal ${
                //                     getFont('PURTI').heading
                //                 }`
                //             },
                //             {
                //                 type: 'TEXT',
                //                 content: ' RESIDENTIAL PROJECTS ',
                //                 className: `inline-block ml-3 text-secondary-focus uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                //                     getFont('PURTI').bold
                //                 }`
                //             }
                //         ]
                //     }
                // ]
            }
        ],
        moduleConfig: {
         
            constructionVisible: false,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: '',
            addHeader: true,
            headerClass: 'hidden lg:block h-32 bg-[#58595B]'
        }
    },
    contactUs: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/26267901.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/19520512.jpg',
                bannerContainerClassName:
                    'hero hero-content  text-center max-w-full lg:bg-center h-[70vh] md:h-[85vh]'
                // components: [
                //     {
                //         type: 'CUSTOM',
                //         className:
                //             'w-full  lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                //         components: [
                //             {
                //                 type: 'TEXT',
                //                 content: 'CONTACT US',
                //                 className: `inline-block ml-3 text-primary uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                //                     getFont('PURTI').bold
                //                 }`
                //             }
                //         ]
                //     }
                // ]
            }
        ],

  
    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('PURTI').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('PURTI').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/64990900.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/60632607.jpg',
                bannerContainerClassName:
                    'hero hero-content text-center max-w-full h-[70vh] md:h-[85vh]'
                // components: [
                //     {
                //         type: 'CUSTOM',
                //         className:
                //             'w-full  lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                //         components: [
                //             {
                //                 type: 'TEXT',
                //                 content: 'TERMS & CONDITIONS',
                //                 className: `inline-block ml-3 text-secondary-focus uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                //                     getFont('PURTI').bold
                //                 }`
                //             }
                //         ]
                //     }
                // ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-secondary font-medium text-[20px] md:text-[20px] ${
                            getFont('PURTI').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('PURTI').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Purti Realty  project, and has entered into a registered Agreement to Sell; or (ii) employee of Purti Realty ; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Purti Realty  or any of its sister concerns/subsidiaries/affiliates (“Purti Realty  Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Purti Realty  Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Purti Realty .',
                            'Referrer shall not be able to refer existing customer(s) of Purti Realty . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Purti Realty  or (ii) is/are already registered with Purti Realty  as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Purti Realty ’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Purti Realty  within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Purti Realty ',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Purti Realty  or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('PURTI').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-0 lg:px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Purti Realty, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('PURTI').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('PURTI').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('PURTI').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('PURTI').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('PURTI').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('PURTI').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('PURTI').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        showMyProperty: true,
        appContainerClassName: 'bg-white text-primary border-accent',
        appFontHeading: `${getFont('PURTI').body}`,
        appStoreLink:
            'https://apps.apple.com/th/app/purti-privilege/id6464290881?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.purti&utm_medium=email',
        appScreen:
            'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Purti-Realty/Images/80551380.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/27822817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: 'pb-3',
        termsAndConditionsContent: ' ',
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NOPRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA No: DLRERA2022A0017 | Goa RERA NO – AGGO02210817 | Telangana RERA No – A02500001321 | Odhisa RERA No – ORA/0143/2023 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in | https://rera.odisha.gov.in/',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[11px] 3xl:text-[15px] leading-[17px] tracking-tight text-black font-normal ${
            getFont('PURTI').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-secondary text-semibold text-[17.px] ${
            getFont('PURTI').body
        }`,
        paginationClass:
            ' text-secondary-focus disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-primary bg-opacity-90'
    },
    referralForm: {
        name: 'Purti',
        theme: 'purtiTheme',
        color: '#57382B',
        backgroundImage: 'https://cdn.loyalie.in/21181292.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '0',
            G1: '0',
            B1: '0',
            opacity1: '0',
            R2: '0',
            G2: '0',
            B2: '0',
            opacity2: '0.20'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: ' ',
            bookedCustomerHeading2: 'Purti Home Owner',

            employee: false,
            employeeHeading1: 'I am an',
            // employeeHeading1: 'Purti Employee',

            salesAdmin: true,
            salesAdminHeading1: ' ',
            salesAdminHeading2: 'Purti Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        // customConfig: {
        //     welcomeContent: '',
        //     headingColor: 'text-white',
        //     userBgColor: 'bg-[#4D4D4F]',
        //     borderColor: 'border-primary',
        //     animateUserType: true
        // },
        // pastReferralConfig: {
        //     selectedStatusColor: 'text-primary',
        //     bgColor: 'bg-[#4D4D4F]'
        // },
        formContent: {
            heading: 'Create your own story - at our community!',
            headingClass: `self-center text-center lg:text-left text-white text-[22px] md:text-3xl 2xl:text-4xl w-[80%] lg:w-[60%] 2xl:w-[70%] text-left leading-7 tracking-wide font-black ${
                getFont('PURTI').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton: ` w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold  ${
                getFont('PURTI').heading
            }`
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='white'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='%23C69248' stroke-width='7' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='7' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink: 'purti-website/terms-and-conditions',
        customLink: '/purti-website/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default PurtiConfig;
