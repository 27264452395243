import React, { useRef } from 'react';
import { getConfig } from '../../../utils/config';
import BsArrowRightShort from '@meronex/icons/bs/BsArrowRightShort';
import AiOutlineRight from '@meronex/icons/ai/AiOutlineRight';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { padding } from '@mui/system';
import { authActions } from '../../../store/auth-slice';
import { useErrorBoundary } from 'react-error-boundary';

function CategoryBar({
    categories,
    selectedCategories,
    onFilterClick,
    showArrowIndicator,
    scrollable,
    noScroll,
    showCashbackButton,
    forClient
}) {
    const history = useHistory();
    const config = getConfig();
    const splideRef = useRef();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const location = useLocation();
    const { showBoundary } = useErrorBoundary();

    const dispatch = useDispatch();

    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
    };

    const onTrackCashback = () => {
        try {
            if (isAuthenticated) {
                history.push(`myProfile/enquiries`);
            } else {
                history.push(
                    `${location.pathname}?postLoginUrl=myProfile/enquiries`
                );
                openLoginModal();
            }
        } catch (err) {
            showBoundary(err);
        }
    };

    if (forClient) {
        return (
            <div className="flex items-center justify-evenly">
                {categories?.map((tag) => {
                    if (tag.id === 52) {
                        return;
                    }
                    return (
                        <div
                            key={tag.id}
                            className={`z-10 w-[25%] flex self-center items-center justify-center`}
                        >
                            <div
                                className={`${
                                    config?.darktheme
                                        ? config?.deals?.darkTheme?.selected
                                        : 'hover:bg-[#f7f7f7] focus:bg-[#f7f7f7]'
                                } ${
                                    selectedCategories?.includes(tag.id)
                                        ? config?.darktheme
                                            ? config?.deals?.darkTheme?.selected
                                            : 'bg-[#f7f7f7]'
                                        : ''
                                }  ${
                                    selectedCategories?.includes(tag.id)
                                        ? 'text-info'
                                        : config?.darktheme
                                        ? config?.deals?.darkTheme?.text
                                        : 'text-black'
                                }  p-3 pb-2 text-sm font-sans hover:transition-all hover:ease-in hover:duration-300 hover:-translate-y-1 overflow-hidden flex items-center justify-center flex-col w-[85%] ${
                                    !forClient
                                        ? 'border-2 border-gray-100 '
                                        : ''
                                } h-[102px] rounded border-rounded`}
                                type="button"
                                value={tag.id}
                                onClick={() => onFilterClick(tag.id, tag.name)}
                            >
                                <div className="drop-shadow-2xl">
                                    <img
                                        src={tag?.logo?.url}
                                        className={`${
                                            selectedCategories?.includes(tag.id)
                                                ? 'w-10 md:w-12 xl:w-14'
                                                : 'w-8 md:w-10 xl:w-12'
                                        }  mx-auto mb-1`}
                                    />
                                </div>
                                <p
                                    className={`text-center text-xs ${
                                        config?.webview?.textStyle
                                            ? config?.webview?.textStyle
                                            : forClient
                                            ? 'font-bold'
                                            : ''
                                    }`}
                                >
                                    {tag.name}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <>
            {showCashbackButton && !forClient && (
                <div
                    onClick={onTrackCashback}
                    className="bg-primary mr-7 py-4 px-3 z-10 justify-between items-center flex flex-row md:hidden rounded"
                >
                    <div className="items-center flex flex-row">
                        <svg
                            fill="#000000"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 21.372 19.761"
                            class="pointer-events-none fill-current w-8 h-8 text-white text-xl"
                        >
                            <g
                                id="Navigator"
                                transform="translate(-29.11 -47.942)"
                            >
                                <path
                                    id="Path_6370"
                                    data-name="Path 6370"
                                    d="M314.642,50.016a3.788,3.788,0,0,0-5.761,4.88l2.476,3.467a.745.745,0,0,0,1.213,0l2.476-3.467A3.776,3.776,0,0,0,314.642,50.016Z"
                                    transform="translate(-266.189)"
                                    fill="none"
                                    stroke="white"
                                    stroke-width="1.5"
                                />
                                <path
                                    id="Path_6371"
                                    data-name="Path 6371"
                                    d="M82.119,127.289a.745.745,0,0,0-.745.745v1.589a2.634,2.634,0,1,1-5.267,0v-6.757a4.124,4.124,0,0,0-8.247,0v3.513a.745.745,0,0,0,1.49,0v-3.513a2.634,2.634,0,0,1,5.267,0v6.757a4.124,4.124,0,0,0,8.247,0v-1.589a.745.745,0,0,0-.745-.745Z"
                                    transform="translate(-36.344 -66.792)"
                                    fill="white"
                                />
                                <path
                                    id="Path_6372"
                                    data-name="Path 6372"
                                    d="M32.261,352.9a2.4,2.4,0,1,0,2.4,2.4A2.4,2.4,0,0,0,32.261,352.9Z"
                                    transform="translate(0 -290.75)"
                                    fill="none"
                                    stroke="white"
                                    stroke-width="1.5"
                                />
                            </g>
                        </svg>

                        <div className="flex flex-row items-center justify-between ml-4">
                            <div className="w-[90%]">
                                <p className="text-white text-sm text-bold">
                                    Claim Your Cashback
                                </p>
                                <p
                                    className="text-white text-xs"
                                    style={{ fontSize: '0.65rem' }}
                                >
                                    Track your ongoing enquires and claim your
                                    rewards
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <AiOutlineRight className={'text-white'} />
                    </div>
                </div>
            )}
            <div
                className={`relative ${
                    forClient ? '' : 'pt-3 md:flex md:flex-row'
                } `}
            >
                <div
                    className={`${
                        showCashbackButton
                            ? 'md:w-[65%] lg:w-[75%]'
                            : 'w-[100%] mx-auto'
                    } `}
                >
                    {!forClient && (
                        <h1 className="text-lg font-semibold ml-4">&nbsp;</h1>
                    )}
                    <Splide
                        // className={`${forClient? 'pl-7' : ''}`}
                        ref={splideRef}
                        options={{
                            // perPage: 8,
                            // autoplay: false,
                            arrows: false,
                            pagination: false,
                            // scrollable: true,
                            // type: 'loop',
                            loop: true,
                            snap: false,
                            drag: 'free',
                            drag: 'free',
                            padding: '2rem',
                            breakpoints: {
                                2560: {
                                    perPage: 8
                                    // perMove: 3
                                    // fixedWidth: '489px',
                                    // gap: '18px'
                                },
                                1980: {
                                    perPage: 8
                                    // perMove: 3
                                },
                                1024: {
                                    perPage: 6
                                    // perMove: 3
                                },
                                1000: {
                                    perPage: 6
                                    // perMove: 3
                                },
                                750: {
                                    perPage: 4
                                    // perMove: 3
                                    // gap: '16px',
                                },
                                650: {
                                    perPage: 4
                                    // perMove: 2
                                    // gap: '16px',
                                },
                                495: {
                                    perPage: noScroll ? 4 : 3,
                                    padding: noScroll ? '0rem' : '2rem'
                                    // perMove: 2
                                },
                                425: {
                                    perPage: noScroll ? 4 : 2
                                    // perMove: 2
                                },
                                375: {
                                    perPage: noScroll ? 4 : 2
                                    // perMove: 2
                                },
                                0: {
                                    perPage: noScroll ? 4 : 2
                                    // perMove: 2
                                }
                            }
                        }}
                    >
                        {categories?.map((tag) => {
                            if (tag.id === 52) {
                                return;
                            }
                            return (
                                <SplideSlide
                                    key={tag.id}
                                    className={`z-10 flex self-center md:justify-center sm:justify-start`}
                                >
                                    <div
                                        className={`${
                                            config?.darktheme
                                                ? config?.deals?.darkTheme
                                                      ?.selected
                                                : 'hover:bg-[#f7f7f7] focus:bg-[#f7f7f7]'
                                        } ${
                                            selectedCategories?.includes(tag.id)
                                                ? config?.darktheme
                                                    ? config?.deals?.darkTheme
                                                          ?.selected
                                                    : 'bg-[#f7f7f7]'
                                                : ''
                                        }  ${
                                            selectedCategories?.includes(tag.id)
                                                ? 'text-info'
                                                : config?.darktheme
                                                ? config?.deals?.darkTheme?.text
                                                : 'text-black'
                                        }  p-3 pb-2 text-sm font-sans hover:transition-all hover:ease-in hover:duration-300 hover:-translate-y-1 overflow-hidden flex items-center justify-center flex-col w-[85%] ${
                                            !forClient
                                                ? 'border-2 border-gray-100 '
                                                : ''
                                        } h-[102px] rounded border-rounded`}
                                        type="button"
                                        value={tag.id}
                                        onClick={() =>
                                            onFilterClick(tag.id, tag.name)
                                        }
                                    >
                                        <div className="drop-shadow-2xl">
                                            <img
                                                src={tag?.logo?.url}
                                                className={`${
                                                    selectedCategories?.includes(
                                                        tag.id
                                                    )
                                                        ? 'w-10 md:w-12 xl:w-14'
                                                        : 'w-8 md:w-10 xl:w-12'
                                                }  mx-auto mb-1`}
                                            />
                                        </div>
                                        <p
                                            className={`text-center text-xs ${
                                                config?.webview?.textStyle
                                                    ? config?.webview?.textStyle
                                                    : forClient
                                                    ? 'font-bold'
                                                    : ''
                                            }`}
                                        >
                                            {tag.name}
                                        </p>
                                    </div>
                                </SplideSlide>
                            );
                        })}
                    </Splide>
                </div>

                {showArrowIndicator && false && (
                    <div className="absolute animate-pulse flex justify-center items-center w-10 h-10 xl:w-12 xl:h-12 rounded-full bg-[#15509d21] text-[#15509E] top-1/2 -translate-y-1/2 right-2 z-20">
                        <BsArrowRightShort className="w-7 h-7" />
                    </div>
                )}
                {showCashbackButton && (
                    <div
                        onClick={onTrackCashback}
                        className="bg-primary mx-6 p-4 z-10 md:justify-between hidden md:flex md:flex-col md:w-[35%] lg:w-[25%] rounded cursor-pointer"
                    >
                        <div>
                            <svg
                                fill="#000000"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 21.372 19.761"
                                class="pointer-events-none fill-current w-10 h-10 text-white text-xl"
                            >
                                <g
                                    id="Navigator"
                                    transform="translate(-29.11 -47.942)"
                                >
                                    <path
                                        id="Path_6370"
                                        data-name="Path 6370"
                                        d="M314.642,50.016a3.788,3.788,0,0,0-5.761,4.88l2.476,3.467a.745.745,0,0,0,1.213,0l2.476-3.467A3.776,3.776,0,0,0,314.642,50.016Z"
                                        transform="translate(-266.189)"
                                        fill="none"
                                        stroke="white"
                                        stroke-width="1.5"
                                    />
                                    <path
                                        id="Path_6371"
                                        data-name="Path 6371"
                                        d="M82.119,127.289a.745.745,0,0,0-.745.745v1.589a2.634,2.634,0,1,1-5.267,0v-6.757a4.124,4.124,0,0,0-8.247,0v3.513a.745.745,0,0,0,1.49,0v-3.513a2.634,2.634,0,0,1,5.267,0v6.757a4.124,4.124,0,0,0,8.247,0v-1.589a.745.745,0,0,0-.745-.745Z"
                                        transform="translate(-36.344 -66.792)"
                                        fill="white"
                                    />
                                    <path
                                        id="Path_6372"
                                        data-name="Path 6372"
                                        d="M32.261,352.9a2.4,2.4,0,1,0,2.4,2.4A2.4,2.4,0,0,0,32.261,352.9Z"
                                        transform="translate(0 -290.75)"
                                        fill="none"
                                        stroke="white"
                                        stroke-width="1.5"
                                    />
                                </g>
                            </svg>
                        </div>
                        <div className="flex flex-row items-center justify-between">
                            <div className="w-[100%]">
                                <p className="text-white text-sm text-bold">
                                    Claim Your Cashback
                                </p>
                                <p
                                    className="text-white text-xs"
                                    style={{ fontSize: '0.65rem' }}
                                >
                                    Track your ongoing enquires and claim your
                                    rewards
                                </p>
                            </div>
                            <AiOutlineRight className={'text-white'} />
                        </div>
                    </div>
                )}
                {/* </div> */}
            </div>
        </>
    );
}

export default CategoryBar;
