import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
// console.log(window.location.origin);
const domain = window.location.origin;
const BramhaConfig = {
    clientId:
        '0aa480b36122c84cb5df47318322c02f05d0b5c047e28f7dbe28f2da6b8fefb5',
    id: 40,
    name: 'Bramha',
    fullName: 'BramhaCorp Ltd',
    programName:'Bramhacorp Connect',
    theme: 'bramhaTheme',
    favicon: 'https://cdn.loyalie.in/9410223.png',
    primaryColor: '#c14953',
    fontBody: `${getFont('BRAMHA').body}`,
    fontTitle: `${getFont('BRAMHA').heading}`,
    googleConfig: {
        gID: 'G-PL8L2M4SMP',
        keywordsContent:
            'BramhaCorp priority real estate referral programs, BramhaCorp real estate referral program, BramhaCorp real estate refer and earn, BramhaCorp priority real estate project refer, BramhaCorp real estate, BramhaCorp priority real estate loyalty program, BramhaCorp real estate news, BramhaCorp real estate upcoming projects in Bengaluru, BramhaCorp real estate launch BramhaCorp priority real estate, BramhaCorp real estate launch, BramhaCorp real estate program, BramhaCorp real estate projects, BramhaCorp real estate blogs, BramhaCorp real estate offers, BramhaCorp real estate upcoming projects in Bengaluru, BramhaCorp real estate events, BramhaCorp, BramhaCorp priority, BramhaCorp real estate best residential projects, BramhaCorp real estate real estate developers, All New Projects by BramhaCorp real estate Builders & Developers, Ongoing Projects by BramhaCorp real estate Pvt Ltd, BramhaCorp real estate Bengaluru, BramhaCorp real estate new program, BramhaCorp press release, BramhaCorp upcoming projects, BramhaCorp property group, BramhaCorp bangalore, BramhaCorp real estate, BramhaCorp reit, jitu virwani, BramhaCorp commercial projects, BramhaCorp projects, BramhaCorp projects in bangalore',
        descriptionContent:
            'Br is an exclusive referral and loyalty program designed for BramhaCorp residents. The program aims to make you the brand ambassador of the BramhaCorp lifestyle by adding more value to your home ownership and creating delight.',
        authorContent: 'BramhaCorp'
    },

    facebookPixelConfig: {
        id: '812237803579755', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~~2024c1d8',
    webengageAPIKey:'99120654-d951-4911-93da-05612613c996',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: false,
    logoLeft: {
        name: 'BramhaCorp Logo',
        baseUrl: 'https://cdn.loyalie.in/42443593.png',
        stickyUrl: 'https://cdn.loyalie.in/42443593.png',
        stickyNavBarLogoClass:
            'relative h-4 lg:h-4 xl:h-5 2xl:h-6 left-6 lg:left-12 ease-in duration-300',
        baseNavBarLogoClass:
            ' relative h-4 lg:h-5 xl:h-6 2xl:h-7 left-6 lg:left-12 ease-out duration-300'
    },
    navBarConfig: {
        dropDown: true,
        font: `${getFont('BRAMHA').heading}`,
        height: 'h-16 lg:h-12 xl:h-14 2xl:h-16 ease-out duration-300',
        stickyHeight: 'h-16 lg:h-10 2xl:h-12 ease-in duration-300 	',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-white',
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-white', // default: transparent
            containerClass: ' lg:mr-5',
            itemClass: `uppercase lg:mr-7 text-primary-focus lg:text-primary-focus hover:text-primary-focus  text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-medium tracking-wide ${
                getFont('BRAMHA').secondary
            }`,
            dropDownContainerClass: `text-primary-focus lg:text-primary-focus hover:text-primary-focus  focus:text-primary-focus lg:focus:shadow-outline tracking-wide ${
                getFont('BRAMHA').secondary
            }`,
            dropDownItemClass: `tracking-widest hover:text-primary text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-medium hover:bg-[#f4f4f4] tracking-wide ${
                getFont('BRAMHA').secondary
            }`
        },
        stickyNavBar: {
            border: true,
            borderClass: ' ',
            backgroundColor: 'bg-white lg:drop-shadow-md ', //default: primary-content,
            containerClass: 'lg:mr-5 ', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass: `uppercase lg:mr-7 text-primary-focus lg:text-primary-focus hover:text-primary-focus text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-medium tracking-wide ${
                getFont('BRAMHA').secondary
            }` // Each list element in NavBar (Text color, hover color, size)
        },
        showActiveLinkColor: false
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `py-[5.5px] 2xl:py-[10px] px-8 self-center text-secondary-focus rounded border-[3px] border-accent  ${
        getFont('BRAMHA').heading
    } `,
    loginModal: {
        showEmployeeLogin: false,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            show: true,
            scrollTo: 'overview',
            path: '/',
            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage: 'https://cdn.loyalie.in/40230007.jpg',
                    backgroundImageMobile: 'https://cdn.loyalie.in/2825843.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    bannerContainerClass:
                        'h-[80vh] md:h-[85vh] lg:h-[95vh] bg-left lg:bg-center ',
                    bannerItemContainerClass:
                        'items-start lg:justify-center pt-2 md:pt-5 lg:pt-0 px-6 md:pl-10 lg:pl-12',
                    position: 'text-left',

                    components: [
                        {
                            type: 'TEXT',
                            content: 'INTRODUCING',
                            className: ` text-secondary-focus uppercase text-[20px] md:text-[25px] lg:text-[28px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold border-b-[3px] border-primary pb-3 ${
                                getFont('BRAMHA').heading
                            }`
                        },
                        {
                            type: 'IMAGE',
                            url: 'https://cdn.loyalie.in/87557466.png',
                            position: 'center',
                            classNameContainer:
                                'w-[40%] lg:w-[22%] xl:w-[21.5%] 2xl:w-[20%] pt-3 lg:pt-5 pb-7'
                        },

                        {
                            type: 'CUSTOM',
                            className:
                                'w-full text-left mt-2 lg:mt-0 lg:w-[24%] xl:w-[22%]',
                            components: [
                                {
                                    type: 'TEXT',
                                    content:
                                        'Elevate your home, elevate your lifestyle! ',
                                    className: `text-secondary-focus xl:pt-2 2xl:pt-3 text-[18px] xl:text-[19px] 2xl:text-[22px] tracking-wide leading-6 xl:leading-7  font-bold ${
                                        getFont('BRAMHA').body
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'A Loyalty & Referral Program curated for the homeowners of BramhaCorp Ltd.',
                                    className: `text-secondary-focus md:pt-2 xl:pt-3 text-[17px] xl:text-[18px] 2xl:text-[19px] tracking-wide leading-5 xl:leading-6 font-normal ${
                                        getFont('BRAMHA').body
                                    }`
                                }
                            ]
                        }
                        // {
                        //     type: 'BUTTON',
                        //     content: 'Click to Refer Now',
                        //     className: `md:mx-0 text-left lg:mt-4 xl:mt-6  text-[15px] md:text-[17px] uppercase tracking-wider bg-transparent text-secondary-focus font-normal py-3 md:py-2 2xl:py-2 px-3 md:px-6 border-[3px]  border-accent hover:border-primary ${
                        //         getFont('BRAMHA').body
                        //     }`,
                        //     path: 'refer',
                        //     scrollTo: 'referral_section',
                        //     onClick: 'SCROLL'
                        // }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: '',
                    url: '',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '1',
                        size: '70%'
                    },
                    className: 'flex flex-col  px-2',
                    components: [
                        {
                            type: 'CUSTOM',
                            id: 'overview',
                            className:
                                'flex flex-col my-10 xl:my-16 bg-white w-[90%] lg:w-[95%] 2xl:w-[82%] px-4 lg:px-12',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'ABOUT BRAMHACORP CONNECT',
                                    className: `text-secondary-focus uppercase text-[20px] md:text-[25px] lg:text-[25px] xl:text-[30px] 2xl:text-[35px] tracking-wide leading-8 font-bold  border-l-[8px] pl-5 border-primary py-2 ${
                                        getFont('BRAMHA').heading
                                    }`
                                },

                                {
                                    type: 'CUSTOM',
                                    className: 'pl-7',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content:
                                                'BramhaCorp Ltd is a trusted real estate company in India, committed to designing exceptional living spaces that are tailored to fulfill the desires of its customers.',
                                            className: `pt-6 text-[17px] xl:text-[18px] h-full 2xl:text-[19px] text-secondary-focus leading-5 xl:leading-6  break-words mb-4  ${
                                                getFont('BRAMHA').body
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'As part of our commitment to our customers, we have curated a loyalty and referral program called BramhaCorp Connect. This program is designed to enhance your homeowning experience by providing access to a wide range of exclusive benefits. Experience everyday delight with referral opportunities to bring your loved ones closer, enjoy curated events, explore our home & lifestyle guide, and get access to membership benefits from exclusive brand tie-ups.',
                                            className: `text-[17px] xl:text-[18px] h-full 2xl:text-[19px] text-secondary-focus leading-5 xl:leading-6 break-words ${
                                                getFont('BRAMHA').body
                                            }`
                                        }
                                    ]
                                }
                            ]
                        },

                        {
                            type: 'CUSTOM',
                            className:
                                'overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-2 gap-2 lg:gap-10 2xl:gap-16 mb-12 lg:mb-20 bg-white lg:w-full px-4 lg:px-12 h-auto',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'flex flex-col lg:flex-row items-center bg-[#f4f4f4] min-w-[80%] lg:w-full',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full lg:w-[55%] 2xl:w-[60%] bg-[#f4f4f4]',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/18638864.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-full h-auto'
                                                }
                                            ]
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full lg:w-[45%] 2xl:w-[40%] bg-[#f4f4f4] px-6 lg:px-4 xl:px-6 pb-2 lg:pb-0',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/84746696.png',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-[15%] pb-5 pt-4 lg:pt-0'
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content: `Gift discounts & earn 1% on basic unit cost`,
                                                    className: `cursor-pointer hover:text-primary text-[17px] xl:text-[18px] 2xl:text-[19px] text-secondary-focus leading-5 xl:leading-6 break-words w-[90%] ${
                                                        getFont('BRAMHA').body
                                                    }`,
                                                    path: 'gift-and-earn',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        }
                                    ]
                                },

                                {
                                    type: 'CUSTOM',
                                    className:
                                        'flex  flex-col lg:flex-row items-center bg-[#f4f4f4] min-w-[80%] lg:w-full',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full lg:w-[55%] 2xl:w-[60%] bg-[#f4f4f4]',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/59008485.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-full h-auto'
                                                }
                                            ]
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full lg:w-[45%] 2xl:w-[40%] bg-[#f4f4f4] px-6 lg:px-4 xl:px-6 pb-2 lg:pb-0',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/83706608.png',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-[15%] pb-5 pt-4 lg:pt-0'
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content: `Enjoy membership benefits from top brands`,
                                                    className: `cursor-pointer hover:text-primary text-[17px] xl:text-[18px] 2xl:text-[19px] text-secondary-focus leading-5 xl:leading-6 break-words ${
                                                        getFont('BRAMHA').body
                                                    }`,
                                                    onClick: 'CHANGE_PATH',
                                                    path: 'deals'
                                                }
                                            ]
                                        }
                                    ]
                                },

                                {
                                    type: 'CUSTOM',
                                    className:
                                        'flex  flex-col-reverse lg:flex-row items-center bg-[#f4f4f4] min-w-[80%] lg:w-full',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full lg:w-[45%] 2xl:w-[40%] bg-[#f4f4f4] px-6 lg:px-4 xl:px-6 pb-2 lg:pb-0',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/31798556.png',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-[15%] pb-5 pt-4 lg:pt-0'
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content: `Participate in community events & workshops`,
                                                    className: `cursor-pointer hover:text-primary text-[17px] xl:text-[18px] 2xl:text-[19px] text-secondary-focus leading-5 xl:leading-6  break-words ${
                                                        getFont('BRAMHA').body
                                                    }`,
                                                    onClick: 'CHANGE_PATH',
                                                    path: 'events'
                                                }
                                            ]
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full lg:w-[55%] 2xl:w-[60%] bg-[#f4f4f4] ',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/22942313.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-full h-auto'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'flex  flex-col-reverse lg:flex-row items-center bg-[#f4f4f4] min-w-[80%] lg:w-full',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full lg:w-[45%] 2xl:w-[40%] bg-[#f4f4f4] px-6 lg:px-4 xl:px-6 pb-2 lg:pb-0',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/38731773.png',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-[15%] pb-5 pt-4 lg:pt-0'
                                                },
                                                {
                                                    type: 'TEXT',
                                                    content: `Engage with trending blogs`,
                                                    className: `cursor-pointer hover:text-primary text-[17px] xl:text-[18px] 2xl:text-[19px] text-secondary-focus leading-5 xl:leading-6 break-words lg:w-[65%] ${
                                                        getFont('BRAMHA').body
                                                    }`,
                                                    onClick: 'CHANGE_PATH',
                                                    path: 'blogs'
                                                }
                                            ]
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full h-full lg:w-[55%] 2xl:w-[60%] bg-[#f4f4f4]',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/3695216.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-full h-auto'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },

                //REFERRAL FORM
                {
                    type: 'REFERRAL_FORM_BANNER'
                },

                {
                    type: 'CUSTOM',
                    className: 'w-full  mx-auto relative',
                    components: [
                        {
                            type: 'CUSTOM',
                            className: 'w-full bg-white py-10',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: ' OUR EXCLUSIVE OFFERS',
                                    className: ` text-secondary-focus uppercase pt-6 text-center text-[20px] lg:text-[22px] xl:text-[25px] 2xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                                        getFont('BRAMHA').heading
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'w-[10%] mx-auto bg-primary border-none h-[3px]',
                                    classNameContainer: ' py-4 '
                                },
                                {
                                    type: 'CAROUSEL',
                                    getVendorLogos: true,
                                    offerCards: true,
                                    getVendorData: true,
                                    backgroundImageNone: true,
                                    classNameCTA: `bg-transparents text-[14px] px-5 py-2 text-secondary-focus text-center mt-3 mx-auto uppercase font-semibold border-[2px] border-[#92b4a7] ${
                                        getFont('SUSHMA').body
                                    }`,
                                    ContainerClassName:
                                        'bg-cover bg-[#f4f4f4] rounded-lg h-auto ',
                                    caraouselClassName: `drop-shadow-lg w-[70%] xl:w-[86%] 2xl:w-[95%] 3xl:w-[80%] mx-auto md:px-12  pt-6 relative pb-10 ${
                                        getFont('SUSHMA').body
                                    }`,

                                    arrows: true,
                                    autoplay: true,
                                    autoplaySpeed: 3000,
                                    infinite: true,
                                    dots: false,
                                    slidesToShow: 5,
                                    pauseOnHover: true,
                                    speed: 3000,
                                    onImageClick: openURLNewWindow
                                }
                            ]
                        },

                        {
                            type: 'CUSTOM',
                            className:
                                'w-full lg:px-12 bg-[#f4f4f4] pt-10 pb-6',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'OUR LATEST BLOGS',
                                    className: ` text-secondary-focus uppercase pt-6 text-center text-[20px] lg:text-[22px] xl:text-[25px] 2xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                                        getFont('BRAMHA').heading
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'w-[10%] mx-auto bg-primary border-none h-[3px]',
                                    classNameContainer: ' py-4 '
                                },
                                {
                                    type: 'BLOG_LAYOUT_VERTICAL1',
                                    className:
                                        'w-full h-auto flex flex-col-reverse lg:flex-row mt-6 px-10 lg:px-5 2xl:px-16 justify-center ',
                                    classNameContainer:
                                        ' w-full px-5 md:px-44 lg:px-0 xl:w-[90%] overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-3 gap-x-4 md:gap-x-8 lg:gap-x-6 xl:gap-x-10 2xl:gap-x-16 3xl:gap-x-20 gap-y-14 '
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            className: 'w-full bg-white pt-10',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'ONGOING PROJECTS',
                                    className: ` text-secondary-focus uppercase pt-3 2xl:pt-6 text-center text-[20px] lg:text-[22px] xl:text-[25px] 2xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                                        getFont('BRAMHA').heading
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'w-[10%] mx-auto bg-primary border-none h-[3px]',
                                    classNameContainer: ' py-4 '
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-3 gap-5 lg:gap-x-6 xl:gap-x-10 2xl:gap-x-16 gap-y-2 lg:gap-y-16 2xl:gap-y-20 mb-12 lg:mb-5 bg-white lg:w-[90%] pb-14 pt-6 px-4 2xl:px-12 h-auto mx-auto',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-[90%]  h-auto mx-auto',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://bramhacorp-prod.s3.ap-south-1.amazonaws.com/Document/Project/51/5236996.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        ' w-[300px] lg:w-full h-full object-cover object-center cursor-pointer',
                                                    onImageClick: () => {
                                                        window.open(
                                                            `${domain}/bramhacorp-website/projects/detail?projectId=51`
                                                        );
                                                    }
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[90%] flex items-center bg-white mx-auto drop-shadow-xl -mt-20  p-3',
                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[90%] h-auto mx-auto',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'HUES OF SKY',
                                                                    className: ` text-[#988a63] uppercase text-[15px] 2xl:text-[17px] tracking-wide font-normal ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        '2, 3, 3.5 & 4.5 BHK ',
                                                                    className: ` text-[#988a63] py-1 uppercase text-[13px] 2xl:text-[14px] tracking-wide font-extralight	 ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },

                                                                {
                                                                    type: 'CUSTOM',
                                                                    className:
                                                                        'flex',
                                                                    components:
                                                                        [
                                                                            {
                                                                                type: 'IMAGE',
                                                                                url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='currentColor' class='bi bi-geo-alt-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'/%3E%3C/svg%3E`,
                                                                                position:
                                                                                    'center',
                                                                                classNameContainer:
                                                                                    'pr-2 opacity-[0.4]'
                                                                            },
                                                                            {
                                                                                type: 'TEXT',
                                                                                content:
                                                                                    'Sadhu Vaswani Chowk, Camp ',
                                                                                className: ` text-secondary-focus opacity-[0.7] text-[12px] tracking-wide font-light ${
                                                                                    getFont(
                                                                                        'BRAMHA'
                                                                                    )
                                                                                        .heading
                                                                                }`
                                                                            }
                                                                        ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[10%] mx-auto  h-auto ',
                                                            components: [
                                                                {
                                                                    type: 'IMAGE',
                                                                    url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='%23b0a683' class='bi bi-arrow-right-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E`,
                                                                    position:
                                                                        'center',
                                                                    classNameContainer:
                                                                        'w-full h-full object-cover object-center cursor-pointer',
                                                                    onImageClick:
                                                                        () => {
                                                                            window.open(
                                                                                `${domain}/bramhacorp-website/projects/detail?projectId=51`
                                                                            );
                                                                        }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-[90%]  h-auto mx-auto',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Bramha-Corp/Images/32753479.jpeg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        ' w-[300px] lg:w-full h-full object-cover object-center cursor-pointer',
                                                    onImageClick: () => {
                                                        window.open(
                                                            `${domain}/bramhacorp-website/projects/detail?projectId=5`
                                                        );
                                                    }
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[90%] flex items-center bg-white mx-auto drop-shadow-xl -mt-20  p-3',
                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[90%] h-auto mx-auto',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'August Towers',
                                                                    className: ` text-[#988a63] uppercase text-[15px] 2xl:text-[17px] tracking-wide font-normal ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        '2bhk ',
                                                                    className: ` text-[#988a63] py-1 uppercase text-[13px] 2xl:text-[14px] tracking-wide font-extralight	 ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },

                                                                {
                                                                    type: 'CUSTOM',
                                                                    className:
                                                                        'flex',
                                                                    components:
                                                                        [
                                                                            {
                                                                                type: 'IMAGE',
                                                                                url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='currentColor' class='bi bi-geo-alt-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'/%3E%3C/svg%3E`,
                                                                                position:
                                                                                    'center',
                                                                                classNameContainer:
                                                                                    'pr-2 opacity-[0.4]'
                                                                            },
                                                                            {
                                                                                type: 'TEXT',
                                                                                content:
                                                                                    'New Kalyani Nagar, Pune ',
                                                                                className: ` text-secondary-focus opacity-[0.7] text-[12px] tracking-wide font-light ${
                                                                                    getFont(
                                                                                        'BRAMHA'
                                                                                    )
                                                                                        .heading
                                                                                }`
                                                                            }
                                                                        ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[10%] mx-auto  h-auto ',
                                                            components: [
                                                                {
                                                                    type: 'IMAGE',
                                                                    url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='%23b0a683' class='bi bi-arrow-right-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E`,
                                                                    position:
                                                                        'center',
                                                                    classNameContainer:
                                                                        'w-full h-full object-cover object-center cursor-pointer',
                                                                    onImageClick:
                                                                        () => {
                                                                            window.open(
                                                                                `${domain}/bramhacorp-website/projects/detail?projectId=5`
                                                                            );
                                                                        }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-[90%] h-auto mx-auto',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Bramha-Corp/Images/31175571.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        ' w-[300px] lg:w-full h-full object-cover object-center cursor-pointer',
                                                    onImageClick: () => {
                                                        window.open(
                                                            `${domain}/bramhacorp-website/projects/detail?projectId=3`
                                                        );
                                                    }
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[90%] flex items-center bg-white mx-auto drop-shadow-xl -mt-20  p-3',
                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[90%] h-auto mx-auto',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'The Collection',
                                                                    className: ` text-[#988a63] uppercase text-[15px] 2xl:text-[17px] tracking-wide font-normal ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        '2, 3 & 4 BHK ',
                                                                    className: ` text-[#988a63] py-1 uppercase text-[13px] 2xl:text-[14px] tracking-wide font-extralight	 ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },

                                                                {
                                                                    type: 'CUSTOM',
                                                                    className:
                                                                        'flex',
                                                                    components:
                                                                        [
                                                                            {
                                                                                type: 'IMAGE',
                                                                                url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='currentColor' class='bi bi-geo-alt-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'/%3E%3C/svg%3E`,
                                                                                position:
                                                                                    'center',
                                                                                classNameContainer:
                                                                                    'pr-2 opacity-[0.4]'
                                                                            },
                                                                            {
                                                                                type: 'TEXT',
                                                                                content:
                                                                                    'New Kalyani Nagar, Pune',
                                                                                className: ` text-secondary-focus opacity-[0.7] text-[12px] tracking-wide font-light ${
                                                                                    getFont(
                                                                                        'BRAMHA'
                                                                                    )
                                                                                        .heading
                                                                                }`
                                                                            }
                                                                        ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[10%] mx-auto  h-auto ',
                                                            components: [
                                                                {
                                                                    type: 'IMAGE',
                                                                    url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='%23b0a683' class='bi bi-arrow-right-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E`,
                                                                    position:
                                                                        'center',
                                                                    classNameContainer:
                                                                        'w-full h-full object-cover object-center cursor-pointer',
                                                                    onImageClick:
                                                                        () => {
                                                                            window.open(
                                                                                `${domain}/bramhacorp-website/projects/detail?projectId=3`
                                                                            );
                                                                        }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-[90%] h-auto mx-auto',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Bramha-Corp/Images/52963958.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        ' w-[300px] lg:w-full h-full object-cover object-center cursor-pointer',
                                                    onImageClick: () => {
                                                        window.open(
                                                            `${domain}/bramhacorp-website/projects/detail?projectId=7`
                                                        );
                                                    }
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[90%] flex items-center bg-white mx-auto drop-shadow-xl -mt-20  p-3',
                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[90%] h-auto mx-auto',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'BramhaCorp Townhouse',
                                                                    className: ` text-[#988a63] uppercase text-[15px] 2xl:text-[17px] tracking-wide font-normal ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'Studio ',
                                                                    className: ` text-[#988a63] py-1 uppercase text-[13px] 2xl:text-[14px] tracking-wide font-extralight	 ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },

                                                                {
                                                                    type: 'CUSTOM',
                                                                    className:
                                                                        'flex',
                                                                    components:
                                                                        [
                                                                            {
                                                                                type: 'IMAGE',
                                                                                url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='currentColor' class='bi bi-geo-alt-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'/%3E%3C/svg%3E`,
                                                                                position:
                                                                                    'center',
                                                                                classNameContainer:
                                                                                    'pr-2 opacity-[0.4]'
                                                                            },
                                                                            {
                                                                                type: 'TEXT',
                                                                                content:
                                                                                    'Balewadi, Pune',
                                                                                className: ` text-secondary-focus opacity-[0.7] text-[12px] tracking-wide font-light ${
                                                                                    getFont(
                                                                                        'BRAMHA'
                                                                                    )
                                                                                        .heading
                                                                                }`
                                                                            }
                                                                        ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[10%] mx-auto  h-auto ',
                                                            components: [
                                                                {
                                                                    type: 'IMAGE',
                                                                    url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='%23b0a683' class='bi bi-arrow-right-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E`,
                                                                    position:
                                                                        'center',
                                                                    classNameContainer:
                                                                        'w-full h-full object-cover object-center cursor-pointer',
                                                                    onImageClick:
                                                                        () => {
                                                                            window.open(
                                                                                `${domain}/bramhacorp-website/projects/detail?projectId=7`
                                                                            );
                                                                        }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-[90%] h-auto mx-auto',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Bramha-Corp/Images/63439127.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        ' w-[300px] lg:w-full h-full object-cover object-center cursor-pointer',
                                                    onImageClick: () => {
                                                        window.open(
                                                            `${domain}/bramhacorp-website/projects/detail?projectId=6`
                                                        );
                                                    }
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[90%] flex items-center bg-white mx-auto drop-shadow-xl -mt-20  p-3',
                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[90%] h-auto mx-auto',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'BramhaCorp Smart',
                                                                    className: ` text-[#988a63] uppercase text-[15px] 2xl:text-[17px] tracking-wide font-normal ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'Studio',
                                                                    className: ` text-[#988a63] py-1 uppercase text-[13px] 2xl:text-[14px] tracking-wide font-extralight	 ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },

                                                                {
                                                                    type: 'CUSTOM',
                                                                    className:
                                                                        'flex',
                                                                    components:
                                                                        [
                                                                            {
                                                                                type: 'IMAGE',
                                                                                url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='currentColor' class='bi bi-geo-alt-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'/%3E%3C/svg%3E`,
                                                                                position:
                                                                                    'center',
                                                                                classNameContainer:
                                                                                    'pr-2 opacity-[0.4]'
                                                                            },
                                                                            {
                                                                                type: 'TEXT',
                                                                                content:
                                                                                    'New Kalyani Nagar, Pune',
                                                                                className: ` text-secondary-focus opacity-[0.7] text-[12px] tracking-wide font-light ${
                                                                                    getFont(
                                                                                        'BRAMHA'
                                                                                    )
                                                                                        .heading
                                                                                }`
                                                                            }
                                                                        ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[10%] mx-auto  h-auto ',
                                                            components: [
                                                                {
                                                                    type: 'IMAGE',
                                                                    url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='%23b0a683' class='bi bi-arrow-right-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E`,
                                                                    position:
                                                                        'center',
                                                                    classNameContainer:
                                                                        'w-full h-full object-cover object-center cursor-pointer',
                                                                    onImageClick:
                                                                        () => {
                                                                            window.open(
                                                                                `${domain}/bramhacorp-website/projects/detail?projectId=6`
                                                                            );
                                                                        }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-[90%] h-auto mx-auto',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Bramha-Corp/Images/39066707.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        ' w-[300px] lg:w-full h-full object-cover object-center cursor-pointer ',
                                                    onImageClick: () => {
                                                        window.open(
                                                            `${domain}/bramhacorp-website/projects/detail?projectId=2`
                                                        );
                                                    }
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[90%] flex items-center bg-white mx-auto drop-shadow-xl -mt-20  p-3',
                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[90%] h-auto mx-auto',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'BOULEVARD TOWERS',
                                                                    className: ` text-[#988a63] uppercase text-[15px] 2xl:text-[17px] tracking-wide font-normal ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'OFFICES & SHOWROOMS',
                                                                    className: ` text-[#988a63] py-1 uppercase text-[13px] 2xl:text-[14px] tracking-wide font-extralight	 ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },

                                                                {
                                                                    type: 'CUSTOM',
                                                                    className:
                                                                        'flex',
                                                                    components:
                                                                        [
                                                                            {
                                                                                type: 'IMAGE',
                                                                                url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='currentColor' class='bi bi-geo-alt-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'/%3E%3C/svg%3E`,
                                                                                position:
                                                                                    'center',
                                                                                classNameContainer:
                                                                                    'pr-2 opacity-[0.4] '
                                                                            },
                                                                            {
                                                                                type: 'TEXT',
                                                                                content:
                                                                                    'Pune Camp',
                                                                                className: ` text-secondary-focus opacity-[0.7] text-[12px] tracking-wide font-light ${
                                                                                    getFont(
                                                                                        'BRAMHA'
                                                                                    )
                                                                                        .heading
                                                                                }`
                                                                            }
                                                                        ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[10%] mx-auto  h-auto ',
                                                            components: [
                                                                {
                                                                    type: 'IMAGE',
                                                                    url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='%23b0a683' class='bi bi-arrow-right-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E`,
                                                                    position:
                                                                        'center',
                                                                    classNameContainer:
                                                                        'w-full h-full object-cover object-center cursor-pointer',
                                                                    onImageClick:
                                                                        () => {
                                                                            window.open(
                                                                                `${domain}/bramhacorp-website/projects/detail?projectId=2`
                                                                            );
                                                                        }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-[90%] h-auto mx-auto',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Bramha-Corp/Images/31733023.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        ' w-[300px] lg:w-full h-full object-cover object-center cursor-pointer ',
                                                    onImageClick: () => {
                                                        window.open(
                                                            `${domain}/bramhacorp-website/projects/detail?projectId=4`
                                                        );
                                                    }
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[90%] flex items-center bg-white mx-auto drop-shadow-xl -mt-20  p-3',
                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[90%] h-auto mx-auto',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'BRAMHACORP BUSINESS PARK',
                                                                    className: ` text-[#988a63] uppercase text-[15px] 2xl:text-[17px] tracking-wide font-normal ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'OFFICES & SHOWROOMS',
                                                                    className: ` text-[#988a63] py-1 uppercase text-[13px] 2xl:text-[14px] tracking-wide font-extralight	 ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },

                                                                {
                                                                    type: 'CUSTOM',
                                                                    className:
                                                                        'flex',
                                                                    components:
                                                                        [
                                                                            {
                                                                                type: 'IMAGE',
                                                                                url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='currentColor' class='bi bi-geo-alt-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'/%3E%3C/svg%3E`,
                                                                                position:
                                                                                    'center',
                                                                                classNameContainer:
                                                                                    'pr-2 opacity-[0.4] '
                                                                            },
                                                                            {
                                                                                type: 'TEXT',
                                                                                content:
                                                                                    'New Kalyani Nagar',
                                                                                className: ` text-secondary-focus opacity-[0.7] text-[12px] tracking-wide font-light ${
                                                                                    getFont(
                                                                                        'BRAMHA'
                                                                                    )
                                                                                        .heading
                                                                                }`
                                                                            }
                                                                        ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[10%] mx-auto  h-auto ',
                                                            components: [
                                                                {
                                                                    type: 'IMAGE',
                                                                    url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='%23b0a683' class='bi bi-arrow-right-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E`,
                                                                    position:
                                                                        'center',
                                                                    classNameContainer:
                                                                        'w-full h-full object-cover object-center cursor-pointer',
                                                                    onImageClick:
                                                                        () => {
                                                                            window.open(
                                                                                `${domain}/bramhacorp-website/projects/detail?projectId=4`
                                                                            );
                                                                        }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-[90%]  h-auto mx-auto',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Bramha-Corp/Images/73999701.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        ' w-[300px] lg:w-full h-full object-cover object-center cursor-pointer',
                                                    onImageClick: () => {
                                                        window.open(
                                                            `${domain}/bramhacorp-website/projects/detail?projectId=8`
                                                        );
                                                    }
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'w-[90%] flex items-center bg-white mx-auto drop-shadow-xl -mt-20  p-3',
                                                    components: [
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[90%] h-auto mx-auto',
                                                            components: [
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'F Plaza',
                                                                    className: ` text-[#988a63] uppercase text-[15px] 2xl:text-[17px] tracking-wide font-normal ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },
                                                                {
                                                                    type: 'TEXT',
                                                                    content:
                                                                        'SHOWROOMS & SHOPS',
                                                                    className: ` text-[#988a63] py-1 uppercase text-[13px] 2xl:text-[14px] tracking-wide font-extralight	 ${
                                                                        getFont(
                                                                            'BRAMHA'
                                                                        )
                                                                            .heading
                                                                    }`
                                                                },

                                                                {
                                                                    type: 'CUSTOM',
                                                                    className:
                                                                        'flex',
                                                                    components:
                                                                        [
                                                                            {
                                                                                type: 'IMAGE',
                                                                                url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='currentColor' class='bi bi-geo-alt-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'/%3E%3C/svg%3E`,
                                                                                position:
                                                                                    'center',
                                                                                classNameContainer:
                                                                                    'pr-2 opacity-[0.4]'
                                                                            },
                                                                            {
                                                                                type: 'TEXT',
                                                                                content:
                                                                                    'New Kalyani Nagar, Pune ',
                                                                                className: ` text-secondary-focus opacity-[0.7] text-[12px] tracking-wide font-light ${
                                                                                    getFont(
                                                                                        'BRAMHA'
                                                                                    )
                                                                                        .heading
                                                                                }`
                                                                            }
                                                                        ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'CUSTOM',
                                                            className:
                                                                'w-[10%] mx-auto  h-auto ',
                                                            components: [
                                                                {
                                                                    type: 'IMAGE',
                                                                    url: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='%23b0a683' class='bi bi-arrow-right-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E`,
                                                                    position:
                                                                        'center',
                                                                    classNameContainer:
                                                                        'w-full h-full object-cover object-center cursor-pointer',
                                                                    onImageClick:
                                                                        () => {
                                                                            window.open(
                                                                                `${domain}/bramhacorp-website/projects/detail?projectId=8`
                                                                            );
                                                                        }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                                // {
                                //     type: 'CUSTOM',
                                //     className: 'w-[80%] lg:w-[90%] mx-auto',
                                //     components: [
                                //         {
                                //             type: 'CAROUSEL',
                                //             customSlide: true,
                                //             caraouselClassName:
                                //                 ' md:py-10 py-1 relative py-16 lg:py-5',
                                //             imageContainerClassName:
                                //                 'm-4 relative',
                                //             imageClassName: 'w-full h-40 lg:h-full lg:bg-cover object-cover object-center',
                                //             captionClass: ` uppercase px-4 text-[16px] ${getFont('BRAMHA').body
                                //                 }`,
                                //             arrows: true,
                                //             autoplay: true,
                                //             autoplaySpeed: 3000,
                                //             infinite: true,
                                //             dots: false,
                                //             slidesToShow: 4,
                                //             pauseOnHover: true,
                                //             speed: 3000,
                                //             onImageClick: openURLNewWindow,
                                //             imgArr: [
                                //                 {
                                //                     imgUrl: 'https://images.prismic.io/bramhacorp/20d788a4-3477-4fc5-9d93-e0d974a44cd9_AUGUST%20TOWERS_Desktop%20Banner%20Size%20with%20logo%203110%20x%201570.jpg',
                                //                     alt: 'img',
                                //                     url: `${domain}/bramhacorp-website/projects`,
                                //                     caption: 'August Towers'
                                //                 },
                                //                 {
                                //                     imgUrl: 'https://images.prismic.io/bramhacorp/cdac64ea-d369-4931-8590-eef887753b0a_TC_Project%20Main%20Page%20-%20Desktop.jpg',
                                //                     alt: 'img',
                                //                     url: `${domain}/bramhacorp-website/projects`,
                                //                     caption: 'The Collection'
                                //                 },
                                //                 {
                                //                     imgUrl: 'https://images.prismic.io/bramhacorp/cabc7ab2-153e-4609-8ae2-66e2ad892ec6_Townhouse_Desktop%20Banner%20Size%20with%20logo%203110%20x%201570.jpg',
                                //                     alt: 'img',
                                //                     url: `${domain}/bramhacorp-website/projects`,

                                //                     caption:
                                //                         'BramhaCorp Townhouse'
                                //                 },
                                //                 {
                                //                     imgUrl: 'https://images.prismic.io/bramhacorp/be138721-8e4a-499f-977c-d23c6092cb33_Project%20Main%20Page%20Hero%20Image%20Banner%20(Mobile)%20%E2%80%93%20720%20x%20960%20Pixel.jpg',
                                //                     alt: 'img',
                                //                     url: `${domain}/bramhacorp-website/projects`,

                                //                     caption: 'BramhaCorp Smart'
                                //                 },
                                //                 {
                                //                     imgUrl: 'https://images.prismic.io/bramhacorp/8ee9599d-1967-4037-a085-9d982c7be76b_F.jpg',
                                //                     alt: 'img',
                                //                     url: `${domain}/bramhacorp-website/projects`,

                                //                     caption:
                                //                         'F Residences Phase 2'
                                //                 }
                                //             ]
                                //         }
                                //     ]
                                // }
                            ]
                        }
                    ]
                }
            ]
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                },
                {
                    deals: {
                        show: false,
                        name: 'BENEFITS',
                        path: '/deals'
                    }
                }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Bramha-Corp/Images/82799097.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Bramha-Corp/Images/82799097.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover bg-center md:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center lg:text-left text-primary text-[15px] md:text-[20px] xl:text-[25px] uppercase my-5 tracking-widest md:w-[55%] lg:w-[60%] ml-auto lg:leading-8  ${
                                getFont('BRAMHA').body
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: ' md:w-[55%] lg:w-[60%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `flex py-[5.5px] 2xl:py-[10px] px-8 self-center text-secondary-focus rounded border-[3px] border-accent mx-auto lg:mx-0 ${
                                        getFont('BRAMHA').heading
                                    } `
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Bramha-Corp/Images/82799097.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Bramha-Corp/Images/82799097.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[70vh] md:h-[70vh] object-cover bg-left',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  my-5 tracking-widest  lg:w-[80%] mx-auto  ${
                    //         getFont('BRAMHA').body
                    //     }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[45px] font-bold  pb-5  tracking-widest lg:w-[50%] mx-auto  ${
                    //         getFont('BRAMHA').heading
                    //     }`
                    // },
                    // {
                    //     type: 'BUTTON',
                    //     content: 'Start Referring Now',
                    //     className: `text-md font-medium BRAMHA-button hover:BRAMHA-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 lg:w-[20%] mx-auto ${
                    //         getFont('BRAMHA').heading
                    //     }`,
                    //     scrollTo: 'referral_section',
                    //     onClick: 'SCROLL'
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold bg-primary text-white ${
                getFont('BRAMHA').heading
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hero hero-content max-w-full h-[70vh] md:h-[85vh] bg-top mx-auto text-center ',
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage: 'https://cdn.loyalie.in/47915566.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/47915566.jpg',
            components: [
                {
                    type: 'CUSTOM',
                    className:
                        'w-full lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto mt-20'
                    // components: [
                    //     {
                    //         type: 'TEXT',
                    //         content: 'BRAMHACORP READERS’ CLUB',
                    //         className: `ml-3 text-secondary-focus uppercase text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                    //             getFont('BRAMHA').bold
                    //         }`
                    //     }
                    // ]
                }
                // {
                //     type: 'TEXT',
                //     content:
                //         'Bringing Together Information And Entertainment For You.',
                //     className: `font-normal mt-2 mb-3 text-black text-xl md:text-base lg:text-[17px] tracking-wider ${
                //         getFont('BRAMHA').heading
                //     }`
                // }
                // {
                //     type: 'BUTTON',
                //     content: 'Read More',
                //     scrollTo: 'blogs_section',
                //     onClick: 'SCROLL',
                //     className: `md:mx-0 text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[18px] tracking-wider bg-transparent text-secondary-focus font-normal py-2 md:py-2 2xl:py-2 px-5 md:px-6 border-[3px] border-accent hover:border-primary ${
                //         getFont('BRAMHA').heading
                //     }`
                // }
            ]
            // loginBannerComponents: [
            //     {
            //         type: 'CUSTOM',
            //         className:
            //             'w-full lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
            //         components: [
            //             {
            //                 type: 'TEXT',
            //                 content: 'BRAMHACORP ',
            //                 className: `inline-block text-primary uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-normal ${
            //                     getFont('BRAMHA').heading
            //                 }`
            //             },
            //             {
            //                 type: 'TEXT',
            //                 content: ' READERS’ CLUB',
            //                 className: `inline-block ml-3 text-secondary-focus uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
            //                     getFont('BRAMHA').bold
            //                 }`
            //             }
            //         ]
            //     },
            //     {
            //         type: 'TEXT',
            //         content:
            //             'Bringing Together Information And Entertainment For You.',
            //         className: `font-normal mt-5 mb-3 text-black text-xl md:text-base lg:text-[17px] tracking-wider ${
            //             getFont('BRAMHA').heading
            //         }`
            //     },
            //     {
            //         type: 'BUTTON',
            //         content: 'Login To Read Now',
            //         onClick: 'FUNC',
            //         onClickFunc: () =>
            //             store.dispatch({
            //                 type: 'authentication/openLoginModal'
            //             }),
            //         className: `md:mx-0 text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[18px] tracking-wider bg-transparent text-secondary-focus font-normal py-2 md:py-2 2xl:py-2 px-5 md:px-6 border-[3px] border-accent hover:border-primary ${
            //             getFont('BRAMHA').heading
            //         }`
            //     }
            // ]
        },
        sideBarClass: `text-primary ${getFont('BRAMHA').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('BRAMHA').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-secondary-focus',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-[#1c2936] ${
                    getFont('BRAMHA').heading
                }`,
                headingClass: `text-[30px] uppercase font-semibold text-secondary mt-3 leading-9 break-words ${
                    getFont('BRAMHA').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('BRAMHA').heading
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('BRAMHA').heading
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('BRAMHA').heading
                }`
            },
            sectionHeadings: ` text-secondary-content uppercase pt-10 text-center text-[20px] lg:text-[22px] xl:text-[25px] 2xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                getFont('BRAMHA').heading
            }`,
            dividerClass: 'w-[10%] mx-auto bg-primary border-none h-[3px] my-4'
        },
        layout5: {
            cardHeight:
                'h-[505px] md:h-[470px] lg:h-[500px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[280px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 md:h-[45px] lg:h-[70px] text-[18px] 3xl:text-[20px] font-bold uppercase leading-6 break-words mx-5  ${
                getFont('BRAMHA').heading
            }`,
            categoryClass: `bg-secondary-content bg-opacity-[0.65] text-white text-[11px] ${
                getFont('BRAMHA').body
            }`,
            summaryClass: `font-normal text-[16.5px] xl:text-[17px] mt-2 leading-6 text-secondary-focus mx-5 h-[96px] md:h-[50px] lg:h-[70px] ${
                getFont('BRAMHA').body
            }`,
            buttonClass: `bg-transparent hover:border-primary flex justify-center self-end float-right text-[13px] xl:text-[16px] text-secondary-focus py-3 px-4 md:px-4 font-medium ${
                getFont('BRAMHA').secondary
            }`,
            buttonArrowUrl: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.16 11.59'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%2392b4a7;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cline class='cls-1' y1='5.62' x2='55' y2='5.62'/%3E%3Cpolyline class='cls-1' points='48.5 0.39 51.93 3.09 55.35 5.79 51.93 8.49 48.5 11.19'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-[#919396] ${
                getFont('BRAMHA').heading
            }`,
            noBlogsClass: `bg-secondary p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('BRAMHA').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/22584268.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/22584268.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0'
            },

            negativeMargin: '',
            containerClass: ' text-secondary-focus w-[50%] ',
            heading: 'Explore Previous Events',
            headingClass: ` text-secondary-content lg:pl-10  text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide font-semibold ${
                getFont('BRAMHA').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('BRAMHA').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('BRAMHA').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('BRAMHA').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-secondary-focus rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('BRAMHA').bold
                    }`
                }
            ]
        },

        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: ` text-secondary-content uppercase mt-16 text-center text-[20px] lg:text-[22px] xl:text-[25px] 2xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                getFont('BRAMHA').heading
            }`,
            dividerClass: 'w-[10%] mx-auto bg-primary border-none h-[3px] my-4',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-2 text-secondary-content w-[60%] ${
                getFont('BRAMHA').heading
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('BRAMHA').heading
            }`
        },
        eventLayout: {
            titleClass: `text-primary leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('BRAMHA').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ' text-[15px]'
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${
            getFont('BRAMHA').heading
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('BRAMHA').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                ' hidden md:hero hero-content items-start md:items-center max-w-full h-[30vh] lg:h-[45vh] bg-center bg-cover', // //bg-top
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692626442507_deals banner',
            backgroundImageMobile: 'https://cdn.loyalie.in/53907965.jpg',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            additionalTextClass: `tracking-wide text-2xl xl:text-3xl leading-7 text-secondary-content uppercase text-left hover:border-b-accent ${
                getFont('BRAMHA').heading
            } `,
            subAdditionalTextClass: ` leading-5 xl:leading-6  break-words ${
                getFont('BRAMHA').heading
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('BRAMHA').heading}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/4685974.png',
            pattern2: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '1',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-2xl lg:text-[18px] leading-6 lg:leading-5 text-primary ${
                getFont('BRAMHA').heading
            }`,
            listBodyClass: `${getFont('BRAMHA').heading}`,
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` text-2xl xl:text-3xl text-primary uppercase leading-8 xl:leading-9  ${
                getFont('BRAMHA').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('BRAMHA').heading
            }`,
            dateClassName: `py-[6px] text-neutral capitalize text-[17px] text-black  ${
                getFont('BRAMHA').heading
            }`,
            termsAndConditionsClassName: `capitalize text-black text-sm font-semibold text-[12px] ${
                getFont('BRAMHA').heading
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `text-black text-xs break-words leading-1 ${
                getFont('BRAMHA').heading
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-transparent text-secondary-focus font-bold uppercase self-center rounded text-[16px] py-2.5 px-7 border-[3px] border-accent ${
                    getFont('BRAMHA').bold
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `bg-transparent text-secondary-focus rounded uppercase text-[16px] py-[9px] px-10 border-[3px] border-accent ${
                    getFont('BRAMHA').heading
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/34154199.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/38278047.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[70vh] md:h-[90vh] bg-top ',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ONGOING PROJECTS',
                        className: ` text-white uppercase pt-3 2xl:pt-6 text-center text-[22px] md:text-[24px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wider leading-7 2xl:leading-8 font-normal ${
                            getFont('BRAMHA').heading
                        }`
                    }
                ]

                // components: [
                //     {
                //         type: 'CUSTOM',
                //         className:
                //             'w-full lg:mt-0 lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                //         components: [
                //             {
                //                 type: 'TEXT',
                //                 content: 'BRAMHACORP ',
                //                 className: `inline-block text-secondary-focus uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-normal ${
                //                     getFont('BRAMHA').heading
                //                 }`
                //             },
                //             {
                //                 type: 'TEXT',
                //                 content: ' RESIDENTIAL PROJECTS ',
                //                 className: `inline-block ml-3 text-secondary-focus uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                //                     getFont('BRAMHA').bold
                //                 }`
                //             }
                //         ]
                //     }
                // ]
            }
        ],
        moduleConfig: {
           
            constructionVisible: false,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: '',
            addHeader: true,
            headerClass: 'hidden lg:block h-32 bg-[#58595B]'
        }
    },
    contactUs: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/83852082.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/83852082.jpg',
                bannerContainerClassName:
                    'hero hero-content  text-center max-w-full lg:bg-center h-[70vh] md:h-[85vh]'
                // components: [
                //     {
                //         type: 'CUSTOM',
                //         className:
                //             'w-full  lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                //         components: [
                //             {
                //                 type: 'TEXT',
                //                 content: 'CONTACT US',
                //                 className: `inline-block ml-3 text-primary uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                //                     getFont('BRAMHA').bold
                //                 }`
                //             }
                //         ]
                //     }
                // ]
            }
        ],

    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('BRAMHA').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('BRAMHA').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/9394642.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/9394642.jpg',
                bannerContainerClassName:
                    'hero hero-content text-center max-w-full h-[70vh] md:h-[85vh]'
                // components: [
                //     {
                //         type: 'CUSTOM',
                //         className:
                //             'w-full  lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                //         components: [
                //             {
                //                 type: 'TEXT',
                //                 content: 'TERMS & CONDITIONS',
                //                 className: `inline-block ml-3 text-secondary-focus uppercase text-center text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide leading-8 font-bold ${
                //                     getFont('BRAMHA').bold
                //                 }`
                //             }
                //         ]
                //     }
                // ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-secondary-content font-medium text-[20px] md:text-[20px] ${
                            getFont('BRAMHA').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('BRAMHA').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a BramhaCorp Ltd project, and has entered into a registered Agreement to Sell; or (ii) employee of BramhaCorp Ltd; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by BramhaCorp Ltd  or any of its sister concerns/subsidiaries/affiliates (“Bramhacorp Ltd Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate BramhaCorp Ltd Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by BramhaCorp Ltd.',
                            'Referrer shall not be able to refer existing customer(s) of BramhaCorp Ltd. Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with BramhaCorp Ltd or (ii) is/are already registered with BramhaCorp Ltd as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Bramhacorp’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to BramhaCorp Ltd within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to BramhaCorp Ltd',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the BramhaCorp Ltd or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('BRAMHA').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-0 lg:px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or BramhaCorp Ltd, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('BRAMHA').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('BRAMHA').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('BRAMHA').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('BRAMHA').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('BRAMHA').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('BRAMHA').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('BRAMHA').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        appContainerClassName: 'bg-white text-secondary-focus border-primary',
        appFontHeading: `${getFont('BRAMHA').body}`,
        appStoreLink:
            'https://apps.apple.com/vn/app/bramhacorp-connect/id6469042749?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.bramhacorp&hl=en&gl=US&utm_medium=email',
        appScreen: 'https://cdn.loyalie.in/61507141.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: 'pb-3',
        termsAndConditionsContent: ' ',
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[11px] 3xl:text-[15px] leading-[17px] tracking-tight text-white font-normal ${
            getFont('BRAMHA').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('BRAMHA').heading
        }`,
        paginationClass:
            ' text-secondary-focus disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-primary bg-opacity-90'
    },
    referralForm: {
        name: 'BramhaCorp',
        theme: 'bramhaTheme',
        color: '#F7941d',
        backgroundImage: 'https://cdn.loyalie.in/87252958.jpg',
        reloyLogo: 'https://cdn.loyalie.in/27822817.png',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '0',
            G1: '0',
            B1: '0',
            opacity1: '0',
            R2: '0',
            G2: '0',
            B2: '0',
            opacity2: '0.20'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: ' ',
            bookedCustomerHeading2: 'BramhaCorp Home Owner',

            employee: false,
            employeeHeading1: 'I am an',
            // employeeHeading1: 'BramhaCorp Employee',

            salesAdmin: true,
            salesAdminHeading1: ' ',
            salesAdminHeading2: 'BramhaCorp Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        customConfig: {
            welcomeContent: '',
            headingColor: 'text-white',
            userBgColor: 'bg-[#4D4D4F]',
            borderColor: 'border-primary',
            animateUserType: true
        },
        pastReferralConfig: {
            selectedStatusColor: 'text-primary',
            bgColor: 'bg-[#4D4D4F]'
        },
        formContent: {
            heading: 'STAY CONNECTED. GET REWARDED.',
            headingClass: `self-center text-center lg:text-left text-secondary-focus text-[22px] lg:text-[25px] xl:text-[30px] 2xl:text-[35px] w-[80%] lg:w-[55%] 2xl:w-[65%] text-left leading-7 xl:leading-9 tracking-wide font-bold ${
                getFont('BRAMHA').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton: ` w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold  ${
                getFont('BRAMHA').heading
            }`
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='white'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='%23c14953' stroke-width='7' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='7' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink: 'bramhacorp-website/terms-and-conditions',
        customLink: '/bramhacorp-website/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default BramhaConfig;
