import React, { useEffect, useState } from 'react';
import DealsLayout from '../DealsLayout/newIndex';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { getConfig } from '../../../../utils/config';
import Grid from '@splidejs/splide-extension-grid';
import { giftSvg } from '../../svg';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../../store/auth-slice';
import { cmsClient, winnreAuth } from '../../../../utils/axios';
import { toast } from 'react-toastify';
import {
    useHistory,
    useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import ReactGA from 'react-ga4';
import TiTick from '@meronex/icons/ti/TiTick';
import GrClose from '@meronex/icons/gr/GrClose';
import PhoneNumber from '../PhoneNumber';
import Modal from '../../../../Components/Organisms/Modal';

const singleRow = {
    2560: {
        perPage: 1,
        padding: '2.5rem',
        fixedWidth: '385px',
        grid: {
            rows: 1,
            cols: 1
        }
    },
    2220: {
        perPage: 1,
        padding: '2.5rem',
        fixedWidth: '385px',
        grid: {
            rows: 1,
            cols: 1
        }
    },
    1920: {
        perPage: 1,
        padding: '2.5rem',
        fixedWidth: '385px',
        grid: {
            rows: 1,
            cols: 1
        }
    },
    1536: {
        perPage: 1,
        padding: '2.5rem',
        fixedWidth: '350px',
        grid: {
            rows: 1,
            cols: 1
        }
    },
    1440: {
        perPage: 1,
        padding: '2.5rem',
        fixedWidth: '350px',
        grid: {
            rows: 1,
            cols: 1
        }
    },
    1280: {
        perPage: 1,
        padding: '2.5rem',
        fixedWidth: '340px',
        grid: {
            rows: 1,
            cols: 1
        }
    },
    1024: {
        perPage: 1,
        padding: '122px',
        fixedWidth: '310px',
        grid: {
            rows: 1,
            cols: 1
        }
    },
    768: {
        perPage: 1,
        arrows: false,
        padding: '200px',
        pagination: false,
        fixedWidth: '270px',
        grid: {
            rows: 1,
            cols: 1
        },
        arrows: false
    },
    750: {
        perPage: 1,
        arrows: false,
        padding: '200px',
        pagination: false,
        fixedWidth: '270px',
        grid: {
            rows: 1,
            cols: 1
        },
        arrows: false
    },
    600: {
        perPage: 1,
        arrows: false,
        padding: '50px',
        pagination: false,
        fixedWidth: '270px',
        grid: {
            rows: 1,
            cols: 1
        },
        arrows: false
    },
    550: {
        perPage: 1,
        arrows: false,
        padding: '0',
        pagination: false,
        fixedWidth: '270px',
        grid: {
            rows: 1,
            cols: 1
        },
        arrows: false
    },
    425: {
        perPage: 1,
        arrows: false,
        padding: '127px',
        pagination: false,
        fixedWidth: '270px',
        grid: {
            rows: 1,
            cols: 1
        },
        arrows: false
    },
    400: {
        perPage: 1,
        arrows: false,
        padding: '102px',
        pagination: false,
        fixedWidth: '270px',
        grid: {
            rows: 1,
            cols: 1
        },
        arrows: false
    },
    375: {
        perPage: 1,
        arrows: false,
        padding: '77px',
        pagination: false,
        fixedWidth: '270px',
        grid: {
            rows: 1,
            cols: 1
        },
        arrows: false
    },
    320: {
        perPage: 1,
        arrows: false,
        padding: '22px',
        pagination: false,
        fixedWidth: '270px',
        grid: {
            rows: 1,
            cols: 1
        },
        arrows: false
    },
    0: {
        perPage: 1,
        arrows: false,
        padding: '0px',
        pagination: false,
        fixedWidth: '270px',
        grid: {
            rows: 1,
            cols: 1
        },
        arrows: false
    }
};
function BundleOffers({ bundleOffers }) {
    const config = getConfig();
    const [step, setStep] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [bundleLoading, setBundleLoading] = useState(false);
    const [bundleDealDetails, setBundleDealDetails] = useState([]);
    const [captureUserMobile, setCaptureUserMobile] = useState(false);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userMobile = useSelector(
        (state) => state.auth?.userDetails?.mobileNumber
    );
    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    console.log("bundleOffers" , bundleOffers)
    const clickThroughMobile = clientWebview || internalWebview;
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const channel = queryParams.get('utm_medium');

    const redeemAllClicked = (mobile) => {
        if (!isAuthenticated) {
            dispatch(authActions.openLoginModal());
            return;
        }
        console.log({ userMobile });
        if (!userMobile && !mobile) {
            setCaptureUserMobile(true);
            setShowModal(true);
            return;
        }
        setBundleLoading(true);
        const bundleIds = bundleOffers.map((offer) => offer.id);
        console.log(bundleIds);
        let apiCall = Promise.all(
            bundleIds?.map((id) =>
                winnreAuth({
                    method: 'GET',
                    url: `/winnre/user/deals/${id}`
                })
            )
        );
        setBundleLoading(true);

        apiCall
            .then((res) => {
                setBundleDealDetails(res);
                setShowModal(true);
                setBundleLoading(false);
                for (const bundleDeal of bundleOffers) {
                      window?.webengage?.track('RedeemAllClicked', {
                      DealId:`${bundleDeal?.id}`,
                      BrandName:`${bundleDeal?.vendorName}`,
                      Tags: `${bundleDeal?.tags?.map((tag) => tag?.name)}`
                     
                    })
                }
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
                setBundleLoading(false);
            });
    };
    


    useEffect(() => {
        if (!showModal) {
            setStep(0);
        }
    }, [showModal]);

    const redeemAllBundleOffers = () => {
       
        if (
            cmsClient.defaults.baseURL === 'https://prod.loyalie.in' ||
            cmsClient.defaults.baseURL === 'https://uat.loyalie.in'
        ) {
            window.fbq('trackCustom', 'RedeemNowButtonClicked');
        }
        ReactGA.event({
            category: 'OFFER',
            action: 'REDEEM NOW CLICKED',
            label: 'REDEEM OFFER BUTTON'
        });

        let apiCall = Promise.all(
            bundleOffers?.map((bundleDeal) =>
                winnreAuth({
                    method: 'POST',
                    url: `winnre/user/deals/redeem?dealId=${
                        bundleDeal?.id
                    }&sourcePlatform=${
                        clickThroughMobile ? 'MOBILE' : 'WEBSITE'
                    }${channel ? `&channel=${channel}` : ''}`
                })
            )
        );

        setBundleLoading(true);

        apiCall
            .then((res) => {
                // if (res.some((r) => r.status == 'FAILED')) {
                //     console.log('failed')
                //     toast.error('Something went wrong')
                //     setShowModal(false)
                //     return
                // }

                console.log('redeem', res);

                let index = 0;
                const offers = bundleDealDetails;
                for (const bundleDeal of bundleOffers) {
                    window?.webengage?.track('DealClicked', {
                        OfferName: `${bundleDeal?.title}`,
                        RedemptionType: `${bundleDeal?.redemptionType}`,
                        CashbackPercentage: `${bundleDeal?.eligibleCashback}`,
                        CouponCode: `${bundleDeal?.dealRedemptionCode}`,
                        Category: `${bundleDeal?.categoryName}`,
                        Vendor: `${bundleDeal?.vendorName}`,
                        Link: `${bundleDeal?.url}`,
                        EndDate: `${bundleDeal?.endDate}`,
                        Tags: `${bundleDeal?.tags?.map((tag) => tag?.name)}`,
                        DealId: `${bundleDeal?.id}`,
                        VoucherCode:
                            bundleDeal.dealType === 'OFFLINE'
                                ? ''
                                : bundleDeal?.redemptionType === 'VOUCHER' &&
                                  res[index]?.status == 'SUCCESS'
                                ? res[index]?.message
                                : res[index]?.status == 'FAILED' &&
                                  res[index]?.message.includes('Repeat:')
                                ? res[index]?.message.split('Repeat:')[1]
                                : '',
                        ...(bundleDeal.redemptionType === 'VOUCHER' &&
                            bundleDeal.dealType === 'OFFLINE' && {
                                QRCode:
                                    res[index]?.status == 'SUCCESS'
                                        ? res[index]?.message
                                        : res[index]?.status == 'FAILED'
                                        ? res[index]?.message.split(
                                              'Repeat:'
                                          )[1]
                                        : ''
                            }),
                        Channel: channel ? channel : 'Direct'
                    });
                    const offersIndex = offers.findIndex(
                        (o) => o.id === bundleDeal?.id
                    );
                    offers[offersIndex] = {
                        ...offers[offersIndex],
                        ...bundleDeal,
                        VoucherCode:
                            bundleDeal.dealType === 'OFFLINE'
                                ? ''
                                : bundleDeal?.redemptionType === 'VOUCHER' &&
                                  res[index]?.status == 'SUCCESS'
                                ? res[index]?.message
                                : res[index]?.status == 'FAILED' &&
                                  res[index]?.message.includes('Repeat:')
                                ? res[index]?.message.split('Repeat:')[1]
                                : '',
                        ...(bundleDeal.redemptionType === 'VOUCHER' &&
                            bundleDeal.dealType === 'OFFLINE' && {
                                QRCode:
                                    res[index]?.status == 'SUCCESS'
                                        ? res[index]?.message
                                        : res[index]?.status == 'FAILED'
                                        ? res[index]?.message.split(
                                              'Repeat:'
                                          )[1]
                                        : ''
                            })
                    };
                    index++;
                }
                history.push({
                    pathname: '/bundleDetails',
                    state: { offers }
                });
                window?.webengage?.track('PdfViewed');
                setBundleLoading(false);
            })
            .catch((err) => {
                setBundleLoading(false);
                toast.error('Something Went Wrong');
            });
    };

    return (
        <>
            <div id='Housewarming'
                className={`flex mt-8 lg:mt-16 mx-auto rounded-lg bg-[#F0F0F0] `}
            >
                <div
                    className="hidden lg:inline-flex lg:w-[25%] xl:w-[20%] text-white bg-cover rounded-l-lg bg-no-repeat	"
                    style={{
                        backgroundImage:
                            'url("https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1713209956763_bundle%20offers")'
                    }}
                >
                    <div className="pl-10 xl:pl-8 mt-12">
                        {giftSvg}
                        <h1 className="text-2xl font-medium mt-2 tracking-wider w-full lg:w-1/3">
                           {bundleOffers.length > 0 ? bundleOffers[0].tags[0].name :'Housewarming Gifts'}
                        </h1>
                        <button
                            onClick={redeemAllClicked}
                            className="hidden lg:block text-center text-lg  from-yellow-400 bg-gradient-to-l to-[#FFA500] mt-4 px-8 py-2 text-white rounded-lg "
                        >
                            Redeem All
                        </button>
                    </div>
                </div>
                <div className="w-full lg:w-[75%] xl:w-[80%] p-5 lg:px-10 lg:py-4">
                    <div className="flex lg:hidden justify-between items-center mb-5">
                        <div className="flex items-center">
                            {giftSvg}
                            <h1 className="text-xl font-medium mt-2 tracking-wider ml-2">
                             {bundleOffers[0]?.tags[0]?.name || 'Housewarming Gifts'}
                            </h1>
                        </div>
                    </div>

                    <div
                        id="BundleOffers"
                        className="flex w-full gap-4 mt-4 overflow-x-scroll overflow-x-hidden"
                    >
                        {bundleOffers?.map((d, i) => {
                            return (
                                <DealsLayout
                                    key={i}
                                    id={d.id}
                                    title={d.title}
                                    date={d.endDate}
                                    thumbnailImageUrl={d.thumbnailImageUrl}
                                    displayImageUrl={d.displayImageUrl}
                                    vendorLogoUrl={d.vendorLogoUrl}
                                    className="w-full h-full gap-2 md:w-[392px] md:h-[250px] lg:w-[489px] lg:h-[312px] xl:w-[489]"
                                />
                            );
                        })}
                    </div>
                    <button onClick={redeemAllClicked} className="lg:hidden text-center text-lg flex justify-center from-yellow-400 bg-gradient-to-l to-[#FFA500] mt-4 px-8 py-2 text-white rounded-lg mx-auto ">
                        Redeem All
                    </button>
                    {/* </Splide> */}
                    {/* <style>
                        {`
                     #splide02-track {
                        padding-left: 0px !important;
                        padding-right: 102px !important;
                     }
                     
                     #splide02-slide01 .splide__slide {
                        width: 300px !important;
                     }

                     @media (max-width: 1024px) {
                        #splide02-list {
                            column-gap: 50px !important;
                        }
                     }
                     `}
                    </style> */}
                </div>
            </div>
            <Modal visible={showModal} onClick={() => setShowModal(false)}>
                <div className="flex justify-end px-4 ">
                    <GrClose
                        onClick={() => setShowModal(false)}
                        className={` h-5 w-5 cursor-pointer `}
                    />
                </div>
                {captureUserMobile && (
                    <PhoneNumber
                        redeemNowClickedHandler={(mobile) =>
                            redeemAllClicked(mobile)
                        }
                        setCaptureUserMobile={setCaptureUserMobile}
                    />
                )}
                {!captureUserMobile && (
                    <div className="py-3 px-4 flex flex-col">
                        {step == '0' ? (
                            <>
                                <p className="font-semibold">
                                    By clicking on Redeem All , you agree &
                                    authorize Reloy and all partner brands part
                                    of this bundle deal to call you or contact
                                    you via various communication modes to
                                    assist you with your queries. This consent
                                    will override your DNC/NDNC registration if
                                    any.
                                </p>
                                <div className="flex justify-end mt-4 ">
                                    <button
                                        onClick={redeemAllBundleOffers}
                                        className="bg-[#154F9D1A] text-[#15509E] py-2 px-7 rounded"
                                    >
                                        {bundleLoading && showModal
                                            ? 'Loading...'
                                            : 'Agree'}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="p-4 flex flex-col items-center justify-center">
                                <div className=" rounded-full mb-5 bg-green-500 p-2 md:p-4">
                                    <TiTick className=" text-white h-7 w-7 md:h-8 md:w-8" />
                                </div>
                                <h1 className="text-center">
                                    Thank you for redeeming the exciting offers
                                    part of this Housewarming Kit. You will be
                                    communicated the steps to redeem shortly.{' '}
                                </h1>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        </>
    );
}

export default BundleOffers;
