import qs from 'qs';
import '../../src/index.css';
import axios from 'axios';
import Modal from '../Modal';
import 'react-phone-input-2/lib/style.css';
import useInput from '../../../../Hooks/useInput';
import PhoneInput from 'react-phone-input-2';
import React, { useState } from 'react';
import usePhoneInput from '../../../../Hooks/usePhoneInput';
import { RE_EMAIL, RE_NAME } from '../../../../constants';
import { winnreAuth } from '../../../../utils/axios';

function LeadDetailsForm({
    changeCurrentStep,
    userRole,
    userProject,
    customerData,
    config,
    customerID,
    employeeTypeSelected
}) {
    // console.log('lead form', userRole);
    // const [referredProject, setReferredProject] = useState(''); //linked to Select Input
    // const [projectsToBeReferred, setProjectToBeRefferred] = useState([]); //list of all Godrej projects to be fetched
    const [termsCheckbox, setTermsCheckbox] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [referredLeadRelation, setReferredLeadRelation] = useState('');
    let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const {
        state: {
            isValid: mobileNumberIsValid,
            mobile,
            country,
            mobileHasError,
            isInputFocused
        },
        onChange,
        validator,
        setIsTouched,
        setMobile,
        getMobileWithoutCountryCode,
        setInputFocused
    } = usePhoneInput();

    const {
        value: referredLeadName,
        isValid: referredLeadNameIsValid,
        hasError: referredLeadNameHasError,
        valueChangeHandler: referredLeadNameChangedHandler,
        inputBlurHandler: referredLeadNameBlurHandler,
        reset: resetReferredLeadNameInput
    } = useInput((value) => !RE_NAME.test(value) && value !== '');

    // const {
    //     value: groupCompanyName,
    //     isValid: groupCompanyNameIsValid,
    //     hasError: groupCompanyNameHasError,
    //     valueChangeHandler: groupCompanyNameChangedHandler,
    //     inputBlurHandler: groupCompanyNameBlurHandler,
    //     reset: resetGroupCompanyNameInput
    // } = useInput((value) => value.trim() !== '');

    // const {
    //     value: employeeNumber,
    //     isValid: employeeNumberIsValid,
    //     hasError: employeeNumberHasError,
    //     valueChangeHandler: employeeNumberChangedHandler,
    //     inputBlurHandler: employeeNumberBlurHandler,
    //     reset: resetEmployeeNumberInput
    // } = useInput((value) => value.trim() !== '');

    //OPTIONAL VALUES START

    const {
        value: comments,
        valueChangeHandler: commentsChangedHandler,
        reset: resetcommentsInput
    } = useInput((value) => value.trim() !== '');

    const {
        value: referredLeadEmail,
        isValid: referredLeadEmailIsValid,
        valueChangeHandler: referredLeadEmailChangedHandler,
        reset: resetreferredLeadEmailInput,
        hasError: referredLeadEmailHasError,
        inputBlurHandler: referredLeadEmailBlurHandler
    } = useInput((value) => RE_EMAIL.test(value));

    // OPTIONAL VALUES ENDS

    // const fetchProjectToBeReferred = () => {
    //     axios({
    //         method: 'get',
    //         url: 'http://dev.loyalie.in/connectre_web/project/getAllProjects',
    //         headers: headers
    //     })
    //         .then((res) => {
    //             setProjectToBeRefferred(res.data.payload.projectsList);
    //         })
    //         .catch(() => {
    //             setModalMessage('Could not load Projects. Please try again!');
    //             setShowModal(true);
    //         });
    // };

    // useEffect(() => {
    //     if (token !== '') {
    //         fetchProjectToBeReferred();
    //     }
    // }, [token]);

    const backClickHandler = (e) => {
        e.preventDefault();
        // if (userRole === 'RELATIONSHIP_MANAGER') {
        //     changeCurrentStep('userProjectForm');
        //     return;
        // }

        // if (userRole === 'EMPLOYEE' && !config?.userTypes?.employee) {
        //     changeCurrentStep('userProjectForm');
        //     return;
        // }

        changeCurrentStep('userProjectForm');
        // }
    };

    const handleOnChange = () => {
        setTermsCheckbox(!termsCheckbox);
    };

    let formIsValid = false;
    if (referredLeadNameIsValid && mobileNumberIsValid && termsCheckbox) {
        if (config?.leadEmailMandatory && referredLeadEmailIsValid) {
            formIsValid = true;
        } else if (!config?.leadEmailMandatory) {
            formIsValid = true;
        }
    }
    let referralSource = userRole;
    if (userRole === 'RELATIONSHIP_MANAGER') {
        config?.userTypes.salesAdmin
            ? employeeTypeSelected === 'EMPLOYEE'
                ? (referralSource = 'EMPLOYEE')
                : (referralSource = 'CUSTOMER')
            : (referralSource = 'EMPLOYEE');
    } else if (userRole === 'EMPLOYEE') {
        config?.userTypes.employee
            ? employeeTypeSelected === 'RELATIONSHIP_MANAGER'
                ? (referralSource = 'CUSTOMER')
                : (referralSource = 'EMPLOYEE')
            : (referralSource = 'CUSTOMER');
    }
    const referHandler = (e) => {
        e.preventDefault();
        let data = {
            name: referredLeadName,
            mobileNumber: getMobileWithoutCountryCode(),
            projectId: userProject,
            referralSource,
            source: 'REFERRAL',
            relation: referredLeadRelation,
            email: referredLeadEmail,
            comment: comments
        };
        let url;
        if (
            (userRole === 'RELATIONSHIP_MANAGER' &&
                referralSource === 'CUSTOMER') ||
            (userRole === 'EMPLOYEE' && referralSource === 'CUSTOMER')
        ) {
            url = `/winnre/sm/enquiry?userId=${customerID}`;
        } else {
            url = `/winnre/user/enquiry`;
        }
        if (!formIsValid) {
            return;
        }
        window?.webengage?.track('ReferralDone', {
            LeadName: `${referredLeadName}`,
            ProjectId: `${userProject}`,
            Source: 'Referral'
        });
        setLoading(true);
        setModalMessage('Submitting referral details');
        setShowModal(true);

        winnreAuth({
            method: 'post',
            url,
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            data: JSON.stringify(data)
        })
            .then((res) => {
                // console.log(res);
                // console.log(res.lead);
                setLoading(false);
                if (res?.status === 'FAILED') {
                    setModalMessage(
                        res?.message ||
                            'Something went wrong. Please try again!'
                    );
                    setShowModal(true);
                } else if (res?.status === 'TOKEN_EXPIRED') {
                    setModalMessage(
                        'Session expired. Please refresh and submit form again!'
                    );
                    setShowModal(true);
                } else if (res?.lead) {
                    // setModalMessage('Lead created successfully!');
                    // setShowModal(true);
                    resetReferredLeadNameInput();
                    setReferredLeadRelation('');
                    setMobile('+91');
                    setIsTouched(false);
                    setTermsCheckbox(false);
                    resetcommentsInput();
                    resetreferredLeadEmailInput();
                    changeCurrentStep('afterFormSubmittedChoice');
                    return;
                }
                // if (res.data.status === 1) {
                //     setModalMessage(
                //         res.data.payload.leadCreationMessage
                //             ? res.data.payload.leadCreationMessage
                //             : 'Lead created successfully!'
                //     );
                //     setShowModal(true);
                //     resetReferredLeadNameInput();
                //     setReferredLeadRelation('');
                //     setMobile('+91');
                //     setIsTouched(false);
                //     setTermsCheckbox(false);
                //     resetcommentsInput();
                //     resetreferredLeadEmailInput();

                //     changeCurrentStep('afterFormSubmittedChoice');
                // } else {
                //     setModalMessage(
                //         res.data.payload?.leadCreationMessage ||
                //             res.data?.message ||
                //             'Something went wrong. Please try again!'
                //     );
                //     setShowModal(true);
                //     return;
                // }
            })
            .catch(() => {
                setModalMessage('Could not create lead! Please try again!');
                setShowModal(true);
                setLoading(false);
            });
    };

    return (
        <div className="">
            <form className="form-control px-6  relative ">
                <p
                    className={` 'text-white'
                    text-xl font-semibold `}
                >
                    Enter referral details
                </p>
                <div className="flex gap-x-7 mt-7">
                    <div className="w-[35%]">
                        <h2>
                            Referral Name
                            <span className="text-red-400"> *</span>
                        </h2>
                        <input
                            type="text"
                            className="inputfield w-full mt-2 text-grey-900 text-base placeholder-[#D0CFD5]"
                            placeholder="Referral Name*"
                            onChange={referredLeadNameChangedHandler}
                            onBlur={referredLeadNameBlurHandler}
                            value={referredLeadName}
                            autoComplete="off"
                        />
                        {referredLeadNameHasError && (
                            <p className="text-left text-sm font-semibold text-red-500">
                                Please enter a valid Name
                            </p>
                        )}
                        <h2 className="mt-4">
                            Phone Number
                            <span className="text-red-400"> *</span>
                        </h2>
                        <div className="">
                            <PhoneInput
                                country={'in'}
                                containerClass={`mt-2 bg-white rounded-md ${
                                    isInputFocused
                                        ? 'border-primary border-[2px]'
                                        : 'border-gray-100'
                                }`}
                                countryCodeEditable={false}
                                containerStyle={{ width: '100%' }}
                                inputStyle={{
                                    width: '100%',
                                    height: 'auto',
                                    fontSize: '16px'
                                }}
                                isValid={validator}
                                onChange={onChange}
                                value={mobile}
                                enableSearch
                                onBlur={() => {
                                    setIsTouched(true);
                                    setInputFocused(false);
                                }}
                                onFocus={() => setInputFocused(true)}
                                inputClass="input-sm  h-auto py-1.5 w-full opacity-90 bg-transparent outline-none border-gray-100 border-[2px] text-grey-900 placeholder-white font-semibold text-base"
                            />
                            {mobileHasError && (
                                <p className="text-left text-sm font-semibold text-red-500">
                                    Please enter a valid Number
                                </p>
                            )}
                        </div>
                        <h2 className="mt-4">
                            Email
                            <span className="text-red-400"> *</span>
                        </h2>
                        <input
                            type="email"
                            className="inputfield w-full mt-2 text-grey-900 text-base placeholder-[#D0CFD5]"
                            placeholder={
                                config?.leadEmailMandatory
                                    ? 'Referral Email*'
                                    : 'Referral Email (Optional)'
                            }
                            onChange={referredLeadEmailChangedHandler}
                            onBlur={referredLeadEmailBlurHandler}
                            value={referredLeadEmail}
                            autoComplete="off"
                        />
                        {config?.leadEmailMandatory &&
                            referredLeadEmailHasError && (
                                <p className="text-left font-semibold text-red-500">
                                    Please enter a valid Email
                                </p>
                            )}
                    </div>

                    {/* {!config?.hideComments && ( */}
                    <div className="w-[35%]">
                        {!config?.hideRelation && (
                            <>
                                <h2>
                                    Select your Relation
                                    <span className="text-red-400"> *</span>
                                </h2>
                                <select
                                    name="project"
                                    className="inputfield mt-2 mb-4 text-grey-900 text-base placeholder-[#D0CFD5]"
                                    onChange={(e) =>
                                        setReferredLeadRelation(e.target.value)
                                    }
                                    value={referredLeadRelation}
                                >
                                    <option value="">
                                        Select Your Relation (Optional)
                                    </option>
                                    <option value="Family">Family</option>
                                    <option value="Friend">Friend</option>
                                    <option value="Neighbour">Neighbour</option>
                                    <option value="Colleague">Colleague</option>
                                    <option value="Other">Other</option>
                                </select>
                            </>
                        )}
                        <h2>
                            Comments
                            <span className="text-red-400"> *</span>
                        </h2>
                        <textarea
                            type="text"
                            className="textarea textarea-primary w-full h-32 rounded-md mt-2 opacity-90  outline-none border-gray-100 border-[2px]  text-grey-900 placeholder-[#D0CFD5] font-semibold bg-white text-base"
                            placeholder="Add your comment"
                            onChange={commentsChangedHandler}
                            value={comments}
                        />
                    </div>

                    {/* )} */}
                </div>

                <div className="flex mt-4">
                    <input
                        type="checkbox"
                        checked={termsCheckbox}
                        onChange={handleOnChange}
                        className="checkbox-sm bg-white rounded-md border-gray-100 checked:bg-blue-600"
                        autoComplete="off"
                    />
                    <p
                        className={`${
                            config?.customConfig?.headingColor || 'text-white'
                        } mx-2 text-sm `}
                    >
                        I agree to the
                        <a
                            href={
                                config?.isDirectTAndCLink
                                    ? config?.termsAndConditionsLink
                                    : `${config?.termsAndConditionsLink}`
                            }
                            target="_blank"
                            className="font-semibold text-primary"
                        >
                            {` Terms & Conditions`}
                        </a>
                    </p>
                </div>
                <div className="flex justify-between mt-6">
                    <button className="back-btn" onClick={backClickHandler}>
                        Back
                    </button>

                    <button
                        className="sub-btn text-white hover:bg-primary-focus disabled:opacity-80 disabled:hover:bg-gray-400 disabled:bg-gray-400 disabled:cursor-not-allowed"
                        onClick={referHandler}
                        disabled={formIsValid && !loading ? false : true}
                    >
                        Refer Now
                    </button>
                </div>
                {/* <h1 className="lg:hidden text-sm text-gray-200 absolute bottom-2 md:bottom-7 lg:left-16 xl:left-36 2xl:left-60">
                    Managed by Loyalie
                </h1> */}
            </form>
            <Modal
                visible={showModal}
                onClick={() => setShowModal(false)}
                content={modalMessage}
            />
        </div>
    );
}

export default LeadDetailsForm;
