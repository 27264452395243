import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../theme';
import { useSelector } from 'react-redux';
import useData from './Components/PropertyResult/data';
import ListTitle from './Molecules/ListTitle';
import PropertyMapView from './Components/PropertyMapView';
import { getConfig } from '../../utils/config';
import MapBox from './Molecules/Map/mapbox';
import { toast } from 'react-toastify';
import ComponentsRenderer from '../../Components/ComponentsRenderer';
import GMaps from './Molecules/Map/GMaps';

function Projects({
    projectsHeading,
    paginationButtonClass,
    propertyBackgroundColorWithOverlay
}) {
    const isMapView = useSelector((state) => state.propertyLayout.isMapView);

    const {
        projects,
        loadingProjects,
        totalPages,
        currentPage,
        setCurrentPage,
        addFilterHandlerNew
    } = useData(isMapView);

    const config = getConfig();

    const [mapViewActive, setMapViewActive] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [activeMarker, setActiveMarker] = useState(-1);
    const [lngs, setLngs] = useState(28.38);
    const [lats, setLats] = useState(77.12);

    const viewMapHandler = (projectId) => {
        const filt = markers.filter((mark) => mark.id === projectId);
        if (filt.length !== 0) {
            setActiveMarker(projectId);
            setLats(Number(filt[0]?.lat));
            setLngs(Number(filt[0]?.lng));
            setMapViewActive(projectId);
        } else toast.error('Location not available');
    };

    useEffect(() => {
        let marks = [];
        projects?.map((project) => {
            marks = [
                ...marks,
                {
                    id: project.id,
                    name: project.projectName,
                    price:project.price,
                    city:project.city,
                    lat: project.projectLocation?.latitude || 0.0,
                    lng: project.projectLocation?.longitude || 0.0
                }
            ];
        });
        if (marks?.length === projects?.length) setMarkers(marks);
    }, [projects]);

    return (
        <ThemeProvider theme={theme}>
                  <ComponentsRenderer
                            components={config?.projects.components}
                        />
            <div
                className="relative min-h-screen h-auto"
                style={{
                    backgroundImage: `url("${config?.projects.moduleConfig?.sectionBackgroundImage}")`,
                    backgroundAttachment: 'fixed',
                    backgroundSize: `${'30% auto'} `
                }}
            >
                {config?.projects.moduleConfig?.overlayClass && (
                    <div
                        className={`${config?.projects.moduleConfig?.overlayClass} absolute top-0 bottom-0 left-0 right-0 z-0`}
                    ></div>
                )}
                <div
                    className=" bg-white z-40 px-2 lg:px-4 xl:px-12 2xl:px-20"
                    id="project_map_container"
                >
                    <ListTitle addFilterHandlerNew={addFilterHandlerNew} />
                </div>
                <div id='project_section' className="flex relative justify-between w-full pl-4 lg:pl-6 xl:pl-12 2xl:pl-20">
                    <div className="w-full lg:w-[50%] mt-7 mb-0 lg:my-5">
                        <h2 className={`${config.fontBody} mb-4`}>
                            {projects?.length} Projects listed here
                        </h2>
                        <PropertyMapView
                            projects={projects}
                            loadingProjects={loadingProjects}
                            totalPages={totalPages}
                            projectsHeading={projectsHeading}
                            currentPage={currentPage}
                            onPageChange={setCurrentPage}
                            propertyBackgroundColorWithOverlay={
                                propertyBackgroundColorWithOverlay
                            }
                            paginationButtonClass={paginationButtonClass}
                            viewMapHandler={viewMapHandler}
                            mapViewActive={mapViewActive}
                            isMapView={isMapView}
                        />
                    </div>
                    <div className="hidden lg:block z-20 ">
                        {/* <MapBox
                            markers={markers}
                            activeMarker={activeMarker}
                            lat={lats}
                            lng={lngs}
                        /> */}
                        <GMaps
                        locations={markers}
                        viewMapHandler={viewMapHandler}
                        activeMarker={activeMarker}
                        />
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default Projects;
