import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../../../theme';
import { axiosProjectsInstance } from '../../../../utils/axios';
import Content from '../../Molecules/Content';

const PropertyDetail = ({
 
    constructionVisible,
    propertyBackgroundColorWithOverlay,
    addHeader,
    headerClass,
}) => {
    
    return (
        <ThemeProvider theme={theme}>
            <Content
                constructionVisible={constructionVisible}
                propertyBackgroundColorWithOverlay={
                    propertyBackgroundColorWithOverlay
                }
                headerClass={{addHeader, headerClass}}
            />
        </ThemeProvider>
    );
};

export default PropertyDetail;

