import React, { useEffect, useState } from 'react';
import { winnreAuth } from '../../../../../../utils/axios';
import Modal from '../../../Modal';
import Select from 'react-select';
import { scrollToTargetAdjusted } from '../../../../../../utils/ui';
import BsInfoCircle from '@meronex/icons/bs/BsInfoCircle';
import BiCheckCircle from '@meronex/icons/bi/BiCheckCircle';
import {
    useHistory,
    useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import { label } from 'yet-another-react-lightbox';
import { paginationSvg } from '../../../../svg';
import { getConfig } from '../../../../../../utils/config';

const StepOne = ({
    setStep,
    enquiryCities,
    projectToRefer,
    setProjectToRefer,
    setPlayInfluencerVideo,
    showModal,
    setShowModal,
    modalMessage,
    setModalMessage,
    selectedCity,
    setSelectedCity,
    cityProjectList,
    setCityProjectList
}) => {
    // const [cityProjectList, setCityProjectList] = useState([]);
    // const [selectedCity, setSelectedCity] = useState({})
    // const [showModal, setShowModal] = useState(false);
    //  const [modalMessage, setModalMessage] = useState('');
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const defaultSelectedCity = queryParams.get('city');
    const defaultProjectId = queryParams.get('projectId');
    const [clicked, setClicked] = useState(false);
    const projectAutoSelected = queryParams.get('autoSelect');
    const config = getConfig();
    useEffect(() => {
        if (enquiryCities?.length == 1 && !defaultSelectedCity) {
            setSelectedCity({
                value: enquiryCities[0],
                label: enquiryCities[0]
            });
            getCityBasedProjectList(enquiryCities[0]);
        }
    }, [enquiryCities]);

    useEffect(() => {
        if (defaultSelectedCity) {
            setSelectedCity({
                value: defaultSelectedCity,
                label: defaultSelectedCity
            });
            getCityBasedProjectList(defaultSelectedCity);
        }
    }, [defaultSelectedCity]);

    const getCityBasedProjectList = (city) => {
        setProjectToRefer(null);
        setSelectedCity({ value: city, label: city });
        winnreAuth({
            method: 'get',
            url: `/winnre/user/project/names?city=${city}`
        })
            .then((res) => {
                console.log('city based', res);
                setCityProjectList(
                    res?.map((obj) => {
                        return {
                            id: obj?.id,
                            projectName: obj?.projectName,
                            city: obj?.city,
                            projectLogo: obj?.projectLogo,
                            projectBgImage: obj?.projectBgImage
                        };
                    })
                );
                if (defaultProjectId) {
                    const selectedProject = res.filter(
                        (project) => project.id == defaultProjectId
                    );
                    setProjectToRefer(
                        selectedProject.map((data) => {
                            return {
                                projectId: data.id,
                                projectName: data.projectName,
                                city: data.city,
                                logo: data.projectLogo,
                                image: data.projectBgImage
                            };
                        })[0]
                    );
                }
            })
            .catch(() => {
                setModalMessage('Could not load Projects. Please try again!');
                setShowModal(true);
            });
    };
    const referredProjectHandler = (project) => {
        console.log('project chosen');
        setProjectToRefer({
            projectId: project.id,
            projectName: project.projectName,
            city: project.city,
            logo: project.projectLogo,
            image: project.projectBgImage
        });
    };
    let ProjectToReferIsValid = false;
    if (projectToRefer) {
        ProjectToReferIsValid = true;
    }

    const handleClick = () => {
        setClicked(!clicked);
    };

    return (
        <div>
            <div className="grid grid-cols-1  mt-3 gap-x-7 ">
                <div className="w-full mt-2 py-2 lg:mt-0 md:w-[60%]">
                    <h2>
                        City
                        <span className="text-red-400"> *</span>
                    </h2>

                    <div className="h-12 lg:h-10 xl:h-12">
                        <Select
                            placeholder="All cities"
                            value={selectedCity ? selectedCity : null}
                            options={enquiryCities?.map((p) => ({
                                label: p,
                                value: p
                            }))}
                            onChange={(val) =>
                                getCityBasedProjectList(val.value)
                            }
                            styles={{
                                container: (provided, state) => ({
                                    ...provided,
                                    width: '100%',
                                    color: 'black',
                                    height: '100%'
                                }),

                                control: (styles, state) => ({
                                    ...styles,
                                    opacity: '0.95',
                                    height: '100%',
                                    color: 'black',
                                    border: `1px solid #BFBFBF `,
                                    boxShadow: 'none'
                                }),
                                placeholder: (styles) => ({
                                    ...styles,
                                    color: '#130E2F',
                                    fontWeight: 300
                                })
                            }}
                            isSearchable={false}
                            className="text-grey-900  placeholder-gray-800 font-semibold bg-white text-base text-left rounded-md mt-3"
                        />
                    </div>
                </div>

                {Object.keys(selectedCity || {}).length > 0 && (
                    <div
                        className={
                            config.referralForm.showProjectsInDropdown
                                ? 'w-full mt-2 py-2 lg:mt-0 md:w-[60%]'
                                : 'mt-2 py-2 lg:mt-2'
                        }
                        // className="mt-2 py-2 lg:mt-2"
                    >
                        <h2>
                            Select a project to be referred
                            <span className="text-red-400"> *</span>
                        </h2>
                        {config.referralForm.showProjectsInDropdown ? (
                            <div className="h-12 lg:h-10 xl:h-12">
                                <Select
                                    placeholder="All projects"
                                    value={
                                        projectToRefer
                                            ? {
                                                  label: projectToRefer.projectName,
                                                  value: projectToRefer
                                              }
                                            : null
                                    }
                                    options={cityProjectList?.map((p) => ({
                                        label: p.projectName,
                                        value: p
                                    }))}
                                    onChange={(val) =>
                                        referredProjectHandler(val.value)
                                    }
                                    styles={{
                                        container: (provided, state) => ({
                                            ...provided,
                                            width: '100%',
                                            color: 'black',
                                            height: '100%'
                                        }),

                                        control: (styles, state) => ({
                                            ...styles,
                                            opacity: '0.95',
                                            height: '100%',
                                            color: 'black',
                                            border: `1px solid #BFBFBF `,
                                            boxShadow: 'none'
                                        }),
                                        placeholder: (styles) => ({
                                            ...styles,
                                            color: '#130E2F',
                                            fontWeight: 300
                                        })
                                    }}
                                    isSearchable={false}
                                    className="text-grey-900  placeholder-gray-800 font-semibold bg-white text-base text-left rounded-md mt-3"
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    id="ProjectList"
                                    className="overflow-x-scroll overflow-x-hidden mt-2 flex space-x-4"
                                >
                                    {cityProjectList.map((project) => (
                                        <div className="w-36 md:w-64 lg:w-48">
                                            <label
                                                key={project.id}
                                                className="relative inline-block cursor-pointer w-36 md:w-64 lg:w-48"
                                            >
                                                <input
                                                    type="radio"
                                                    name="project"
                                                    value={project.id}
                                                    checked={
                                                        projectToRefer &&
                                                        projectToRefer.projectId ===
                                                            project.id
                                                    }
                                                    onChange={() =>
                                                        referredProjectHandler(
                                                            project
                                                        )
                                                    }
                                                    className="absolute inset-0 opacity-0 cursor-pointer"
                                                />
                                                <div className="relative w-36 h-24 md:w-64 md:h-40 lg:w-48 lg:h-32">
                                                    <img
                                                        src={
                                                            project.projectBgImage
                                                        }
                                                        alt={
                                                            project.projectName
                                                        }
                                                        className="w-full h-full object-cover object-center rounded-xl"
                                                    />
                                                    {projectToRefer &&
                                                        projectToRefer.projectId ===
                                                            project.id && (
                                                            <>
                                                                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 rounded-xl"></div>
                                                                <BiCheckCircle className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  text-2xl text-white" />
                                                            </>
                                                        )}
                                                    {clicked && (
                                                        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 rounded-xl"></div>
                                                    )}
                                                </div>
                                            </label>

                                            <p className="text-base font-semibold md:font-bold break-all leading-5">
                                                {project.projectName}
                                            </p>
                                            <p className="mt-1 text-gray-500 text-sm italic">
                                                {project.city}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                                {cityProjectList.length > 3 && (
                                    <div className="mt-2 flex justify-center items-center">
                                        {paginationSvg}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
            {projectToRefer && (
                <div className="flex items-center justify-between mt-4 bg-primary md:hidden rounded px-4 py-2">
                    <button
                        onClick={() => {
                            scrollToTargetAdjusted('influencer_link');
                        }}
                        className=" rounded text-white whitespace-nowrap"
                    >
                        Get personalised link
                    </button>

                    <BsInfoCircle
                        onClick={() => setPlayInfluencerVideo(true)}
                        className="cursor-pointer w-7 h-5 text-white font-bold"
                    />
                </div>
            )}

            <div
                className={
                    'flex flex-1 mx-auto w-full  py-4 mt-4 lg:mt-6 justify-end'
                }
            >
                <button
                    className="sub-btn sub-btn px-16 md:px-8 mt-auto text-white disabled:opacity-90 disabled:bg-gray-400 disabled:hover:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={(e) => {
                        setStep((prev) => prev + 1);
                    }}
                    disabled={!ProjectToReferIsValid}
                >
                    Next
                </button>
            </div>

            <Modal
                visible={showModal}
                onClick={() => setShowModal(false)}
                content={modalMessage}
            />
        </div>
    );
};

export default StepOne;
