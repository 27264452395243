import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { propertyLayoutActions } from '../../../../store/property-layout-switch-slice';
import { propertyFilterActions } from '../../../../store/property-filter-slice';
import MdOutlineViewWeek from '@meronex/icons/mdc/MdcViewWeekOutline';
import MdOutlineGridView from '@meronex/icons/mdc/MdcViewGridOutline';
import { useHistory, useLocation } from 'react-router-dom';
import { scrollToTargetAdjusted } from '../../../../utils/ui';
import useData from './data';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ProjectFilter from '../../Components/ProjectFilter';
import { winnreNoAuth } from '../../../../utils/axios';
import GoSettings from '@meronex/icons/go/GoSettings';
import BsViewList from '@meronex/icons/bs/BsViewList';
import BsGrid from '@meronex/icons/bs/BsGrid';
import { getConfig } from '../../../../utils/config';

const Filter = (props) => {
    const { addFilterHandlerNew } = props;
    const theme = useTheme();
    const screenAboveLg = useMediaQuery(theme.breakpoints.up('lg'));
    const isMapView = useSelector((state) => state.propertyLayout.isMapView);
    const dispatch = useDispatch();
    const location = useLocation();
    const config = getConfig();
    const params = new URLSearchParams(location.search);
    let userId;
    userId = params.get('userId');

    const mapButtonClick = () => {
        dispatch(propertyLayoutActions.switchPropertyLayout(true));
    };
    const gridButtonClick = () => {
        dispatch(propertyLayoutActions.switchPropertyLayout(false));
    };

    const clearAllFilters = () => {
        dispatch(propertyFilterActions.clearAllFilters());
    };

    const [openFilter, setOpenFilter] = useState(false);

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };

    const handleResetFilter = () => {
        clearAllFilters();
        handleCloseFilter();
    };

    const applyFilter = (cityList, priceRange, typeList) => {
        // console.log(cityList, priceRange, typeList);
        addFilterHandlerNew(priceRange, 'BUDGET');
        addFilterHandlerNew(cityList, 'LOCATION');
        addFilterHandlerNew(typeList, 'APARTMENT_TYPE');
        handleCloseFilter();
    };
    // console.log(isMapView);
    return (
        <div id="projectsContainer" className="block ">
            <div>
                <div className="bg-white">
                    <div className="container mx-auto ">
                        <div className="flex justify-end">
                            <div className="flex lg:gap-x-4 z-10">
                                {openFilter && (
                                    <ProjectFilter
                                        isOpenFilter={openFilter}
                                        onResetFilter={handleResetFilter}
                                        applyFilter={applyFilter}
                                        onCloseFilter={handleCloseFilter}
                                    />
                                )}

                                <div className="lg:pr-4 lg:border-r">
                                    <button
                                        type="button"
                                        className={
                                            screenAboveLg
                                                ? `flex items-center text-gray-700 px-4 py-3 border-[1.9px] font-medium rounded-lg  ${config?.projects.moduleConfig?.fontTitle}`
                                                : ''
                                        }
                                        onClick={() =>
                                            setOpenFilter(!openFilter)
                                        }
                                    >
                                        <GoSettings className="w-5 h-5 lg:mr-2 " />

                                        {screenAboveLg ? 'Filter' : ''}
                                    </button>
                                </div>

                                {screenAboveLg && (
                                    <button
                                        className={`px-3.5 py-2 rounded-lg ${
                                            isMapView
                                                ? 'text-white'
                                                : 'text-black border-[1.9px]'
                                        }`}
                                        style={{
                                            backgroundColor: isMapView
                                                ? config.primaryColor
                                                : '#FFFFFF'
                                        }}
                                        onClick={mapButtonClick}
                                    >
                                        <BsViewList className=" h-6 w-6" />
                                    </button>
                                )}
                                {screenAboveLg && (
                                    <button
                                        className={`px-3 py-2 rounded-lg ${
                                            isMapView
                                                ? 'text-black border-[1.9px]'
                                                : 'text-white'
                                        } `}
                                        style={{
                                            backgroundColor: isMapView
                                                ? '#FFFFFF'
                                                : config.primaryColor
                                        }}
                                        onClick={gridButtonClick}
                                    >
                                        <BsGrid className=" h-6 w-6" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filter;
