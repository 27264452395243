import { Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { getConfig, routePathMap } from '../../../utils/config';

import { scrollToTargetAdjusted } from '../../../utils/ui';

function NavItemDropdown({
    label,
    show,
    pathname,
    scrollTo,
    customUrl,
    dropDown,
    dropDownOptions,
    showNavBar,
    onClick,
    openDropdown,
    setOpenDropdown
}) {
    const location = useLocation();
    const config = getConfig();
    const onCategoryClick = (e, label) => {
        window?.webengage?.track('CategoryViewed', {
            CategoryName: `${label}`
        });
        if (customUrl) {
            e.preventDefault();
            window.open(customUrl);
        }
        if (scrollTo) {
            if (location.pathname !== pathname) {
                setTimeout(() => {
                    scrollToTargetAdjusted(scrollTo);
                }, 500);
            } else {
                e.preventDefault();
                scrollToTargetAdjusted(scrollTo);
            }
        }
        onClick();
    };
    const onSubCategoryClick = (e) => {};
    return show ? (
        dropDown ? (
            <div
                class="relative"
                onMouseOver={() => setOpenDropdown(true)}
                onMouseLeave={() => setOpenDropdown(false)}
            >
                <button
                    className={
                        'flex flex-row items-center px-6 py-2 2xl:px-2 2xl:py-2 lg:pb-2 lg:border-none  lg:px-0 lg:inline-block transition ease-out duration-75 w-full md:w-auto md:inline ' +
                        (showNavBar
                            ? `${config?.navBarConfig.stickyNavBar.itemClass}`
                            : ` ${config.navBarConfig.baseNavBar.itemClass}`)
                    }
                >
                    <span>{label}</span>
                    <svg
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        class={
                            `${openDropdown ? ' rotate-180' : ' rotate-0'}` +
                            ' inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1'
                        }
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
                {/* <Transition
                    as={Fragment}
                    show={openDropdown}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                > */}
                  {openDropdown && <div
                        class={`lg:absolute right-0 w-full lg:pt-2 origin-top-right lg:rounded-md lg:shadow-lg md:w-48 z-10 `}
                    >
                        <div
                            class={`px-2 lg:py-2 lg:rounded-md lg:shadow ${
                                config?.navBarConfig.dropDownBg
                                    ? config?.navBarConfig.dropDownBg
                                    : 'bg-white'
                            }`}
                        >
                            {
                                // console.log('dropdown', option);
                                // console.log(Object.keys(option));
                                Object.keys(dropDownOptions)
                                    .sort(
                                        (a, b) =>
                                            dropDownOptions[a].order -
                                            dropDownOptions[b].order
                                    )
                                    .map(
                                        (page) =>
                                            dropDownOptions[page]?.show && (
                                                <NavLink
                                                    to={
                                                        dropDownOptions[page]
                                                            .path ||
                                                        routePathMap[page]
                                                    }
                                                    className={`block px-4 py-2 lg:mt-2 bg-transparent rounded-lg md:mt-0 ${
                                                        config?.navBarConfig
                                                            .baseNavBar
                                                            .dropDownItemClass
                                                    }  ${
                                                        config?.navBarConfig
                                                            .baseNavBar
                                                            .dropDownContainerClass
                                                            ? config
                                                                  ?.navBarConfig
                                                                  .baseNavBar
                                                                  .dropDownContainerClass
                                                            : 'hover:text-gray-900 hover:bg-gray-200'
                                                    } `}
                                                    onClick={() => {
                                                        window?.webengage?.track(
                                                            'SubCategoryViewed',
                                                            {
                                                                SubCategoryName: `${dropDownOptions[page]?.name}`
                                                            }
                                                        );
                                                        onClick();
                                                    }}
                                                >
                                                    {
                                                        dropDownOptions[page]
                                                            ?.name
                                                    }
                                                </NavLink>
                                            )
                                    )
                            }
                        </div>
                    </div>}
                {/* </Transition> */}
            </div>
        ) : (
            <NavLink
                className={
                    `px-6 py-2 2xl:px-2 2xl:py-2 lg:pb-2 lg:border-none  lg:px-0 block lg:inline-block transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                    (showNavBar
                        ? `${config?.navBarConfig.stickyNavBar.itemClass}`
                        : ` ${config.navBarConfig.baseNavBar.itemClass}`)
                }
                activeClassName={
                    config.navBarConfig.showActiveLinkColor
                        ? 'text-secondary-focus'
                        : ''
                }
                to={pathname}
                onClick={(e) => onCategoryClick(e, label)}
            >
                {label}
            </NavLink>
        )
    ) : (
        <></>
    );
}

export default NavItemDropdown;
