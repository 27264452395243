import BsXCircle from '@meronex/icons/bs/BsXCircle';
import React, { useEffect } from 'react';
import { getConfig } from '../../../utils/config';
import { useData } from './data';
import Select from 'react-select';
import qs from 'qs';
import PhoneInput from 'react-phone-input-2';
import { authActions } from '../../../store/auth-slice';
import { winnreAuth } from '../../../utils/axios';
import { useDispatch } from 'react-redux';

function SignUp({
    backHandler,
    closeLoginModal,
    isInternationalCustomer,
    selectedOption,
    createRegistration,
    email,
    emailBlurHandler,
    loginWithEmail,
    emailChangedHandler,
    emailHasError,
    emailIsValid,
    setAlertMessage,
    setShowErrorAlert,
    setShowSuccessAlert
}) {
    const config = getConfig();
    const dispatch = useDispatch();

    const {
        state: {
            name,
            nameIsValid,
            nameHasError,
            unitNumber,
            unitNumberIsValid,
            unitNumberHasError,
            currentMobile,
            currentCountry,
            currentMobileHasError,
            project,
            projectList,
            unitValidated,
            currentMobileNumberIsValid
        },
        resetName,
        resetUnitNumber,
        nameChangedHandler,
        nameBlurHandler,
        unitNumberChangedHandler,
        unitNumberBlurHandler,
        getCurrentMobileWithoutCountryCode,
        setCurrentMobileIsTouched,
        setCurrentMobileInputFocused,
        currentMobileOnChange,
        currentMobileValidtor,
        setProject,
        getProjects,
        setUnitValidated
    } = useData();

    const projectSelected = (val) => {
        setProject({ projectName: val.label, id: val.value });
    };

    useEffect(() => {
        if (createRegistration) {
            getProjects();
        }
    }, [createRegistration]);

    let unitDetailIsValid = false;
    if (unitNumberIsValid && projectSelected) {
        unitDetailIsValid = true;
    }

    let formIsValid = false;
    if (selectedOption == 'Customer') {
        if (nameIsValid && unitValidated) {
            // console.log(isInternationalCustomer, emailIsValid);
            if (isInternationalCustomer) {
                currentMobileNumberIsValid
                    ? (formIsValid = true)
                    : (formIsValid = false);
            } else {
                emailIsValid ? (formIsValid = true) : (formIsValid = false);
            }
        }
    } else if (selectedOption == 'Site Visitor') {
        if (nameIsValid) {
            if (isInternationalCustomer) {
                currentMobileNumberIsValid
                    ? (formIsValid = true)
                    : (formIsValid = false);
            } else {
                emailIsValid ? (formIsValid = true) : (formIsValid = false);
            }
        }
    }

    const backClickedHandler = () => {
        resetName();
        resetUnitNumber();
        setProject(null);
        backHandler();
    };

    const validateUnitDetails = (e) => {
        e.preventDefault();

        winnreAuth({
            method: 'post',
            url: `/winnre/user/customer/registration/checkExistingAccount?projectId=${project?.id}&unitNumber=${unitNumber}`
        })
            .then((res) => {
                // console.log(res);
                if (res?.status === 'SUCCESS') {
                    window?.webengage?.track('ProjectValidated');
                    setUnitValidated(true);
                    setAlertMessage(
                        "Thankyou! We've captured your unit details."
                    );
                    setShowSuccessAlert(true);
                    return;
                }
                if (res.status === 'FAILED') {
                    setAlertMessage(res.message);
                    setShowErrorAlert(true);
                }
            })
            .catch((e) => {});
    };

    const registrationHandler = (e) => {
        e.preventDefault();

        let url = `winnre/user/customer/registration?name=${name}&email=${email}${
            selectedOption == 'Customer'
                ? `&projectId=${project?.id}&unitNumber=${unitNumber}`
                : ''
        }`;
        let data = {
            countryCode: currentMobile
                ? Number(currentCountry?.dialCode) || ''
                : '',
            phone: getCurrentMobileWithoutCountryCode()
        };

        if (!unitDetailIsValid && selectedOption == 'Customer') {
            setAlertMessage('Please enter your unit details');
            setShowErrorAlert(true);
        }
        if (!formIsValid) {
            return;
        }
        // setShowLoader(true);

        winnreAuth({
            method: 'post',
            url: url,
            data: qs.stringify(data)
            // headers: headers
        })
            .then((res) => {
                // console.log(res);
                if (res.status === 'FAILED') {
                    setShowErrorAlert(true);
                    setAlertMessage(res.message);
                    return;
                }
                if (res.user) {
                    window?.webengage?.track('RegistrationFormSubmitted');
                    if (config?.autoLogout) {
                        sessionStorage.setItem(
                            'refreshToken',
                            res.refreshToken
                        );
                        sessionStorage.setItem('accessToken', res.accessToken);
                    } else {
                        localStorage.setItem('refreshToken', res.refreshToken);
                        localStorage.setItem('accessToken', res.accessToken);
                    }
                    dispatch(authActions.authenticationToken(res.accessToken));
                    // setAuthToken(res.accessToken);
                    dispatch(
                        authActions.setUserData({
                            userDetails: {
                                mobileCountryCode: res.user.mobileCountryCode,
                                mobileNumber: res.user.mobileNumber,
                                email: res.user.email,
                                status: res?.user?.status,
                                preferredName: res?.user?.preferredName,
                                birthDate: res?.user?.dateOfBirth,
                                address: res?.address
                            },
                            name: res.user.name,
                            userRole:
                                selectedOption == 'Site Visitor'
                                    ? 'SITE_VISITOR'
                                    : 'CUSTOMER',
                            userId: res?.user?.id,
                            hashId: res?.user.hashId,
                            profileCompletionPercentage:
                                res?.user?.profileCompletionPercentage,
                            finishedFirstTimeLoginGuide:
                                res?.user?.finishedFirstTimeLoginGuide
                        })
                    );
                    dispatch(authActions.login());
                    dispatch(authActions.closeLoginModal());
                } else {
                    setShowErrorAlert(true);
                    setAlertMessage(res.message);
                    return;
                }
            })
            .catch(() => {});
    };
    return (
        <>
            <div
                className={`flex justify-between px-8 pb-4 ${config?.loginModal.border} `}
            >
                <h1
                    className={`self-center text-lg md:text-2xl font-semibold ${config?.loginModal.text} `}
                >
                    Sign up
                </h1>
                <BsXCircle
                    onClick={closeLoginModal}
                    className={`self-center h-5 w-5 cursor-pointer ${config?.loginModal.text} `}
                />
            </div>

            <div class="px-8 pt-4">
                {selectedOption == 'Customer' && (
                    <div>
                        <p
                            className={`w-full lg:w-[85%] text-sm lg:text-base font-semibold ${config?.loginModal.text} `}
                        >
                            Please enter your unit details
                        </p>
                        <form class="w-full">
                            {/* PROJECT */}
                            <div className="mt-4 mb-2 md:mb-2">
                                <Select
                                    placeholder={`Your Project*`}
                                    options={projectList?.map((p) => ({
                                        label: p.projectName,
                                        value: p.id
                                    }))}
                                    onChange={(val) => {
                                        projectSelected(val);
                                    }}
                                    value={
                                        project
                                            ? {
                                                  value: project.value,
                                                  label: project.projectName
                                              }
                                            : null
                                    }
                                    styles={{
                                        container: (provided, state) => ({
                                            ...provided,
                                            width: '100%'
                                        }),

                                        control: (styles, state) => ({
                                            ...styles,
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: '6px',
                                            opacity: '0.95',
                                            paddingLeft: '2px',
                                            paddingRight: '2px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            border: state.isFocused
                                                ? '1px solid #6b7280'
                                                : `1px solid #d1d5db`,
                                            boxShadow: 'none',
                                            '&:hover': {
                                                border: '1px solid #E5E7EB'
                                            }
                                        }),
                                        placeholder: (styles) => ({
                                            ...styles,
                                            color: '#333c4d',
                                            fontFamily: 'system-ui',
                                            fontWeight: 'normal'
                                        })
                                    }}
                                />
                            </div>
                            {/* UNIT NO */}
                            <div class={`mb-2 relative`}>
                                <input
                                    type="text"
                                    id="otp"
                                    class="peer pt-8 border border-gray-300 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full p-3 h-12 placeholder-transparent"
                                    placeholder="unit number"
                                    onChange={unitNumberChangedHandler}
                                    onBlur={unitNumberBlurHandler}
                                    value={unitNumber}
                                    autoComplete="off"
                                />
                                <label
                                    for="text"
                                    class="peer-placeholder-shown:opacity-100   opacity-75 peer-focus:opacity-75 peer-placeholder-shown:scale-100 scale-75 peer-focus:scale-75 peer-placeholder-shown:translate-y-0 -translate-y-3 peer-focus:-translate-y-3 peer-placeholder-shown:translate-x-0 translate-x-1 peer-focus:translate-x-1 absolute top-0 left-0 px-3 py-3 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out"
                                >
                                    Your Unit Number*
                                </label>
                                {unitNumberHasError && (
                                    <p className="text-left text-sm mt-1 font-Roboto text-red-400">
                                        {`Please enter a valid Unit Number`}
                                    </p>
                                )}
                            </div>
                            <div className=" flex justify-end">
                                {/* <button
        onClick={backClickedHandler}
        class={`w-auto ${config?.loginModal.buttonBg} text-white py-2 px-4 md:px-8 rounded-md mb-5  `}
    >
        Back
    </button> */}
                                <button
                                    disabled={!unitDetailIsValid}
                                    onClick={validateUnitDetails}
                                    class={`w-auto ${config?.loginModal.buttonBg} text-white py-2 px-4 md:px-8 rounded-md mb-2  disabled:hover:text-white disabled:opacity-90 disabled:bg-gray-400 disabled:cursor-not-allowed`}
                                >
                                    Validate
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                <p
                    className={`w-full lg:w-[85%] text-sm lg:text-base font-semibold ${config?.loginModal.text} `}
                >
                   Your {loginWithEmail? 'email' : 'phone'} was not found on our system as a registered user. Please enter your details to signup:
                </p>
                <form class="w-full">
                    {/* NAME */}
                    <div
                        class={`mt-4 mb-2 md:mb-2
relative`}
                    >
                        <input
                            type="text"
                            id="otp"
                            class="peer pt-8 border border-gray-300 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full p-3 h-12 placeholder-transparent"
                            placeholder="name*"
                            onChange={nameChangedHandler}
                            onBlur={nameBlurHandler}
                            value={name}
                            autoComplete="off"
                        />
                        <label
                            for="text"
                            class="peer-placeholder-shown:opacity-100 opacity-75 peer-focus:opacity-75 peer-placeholder-shown:scale-100 scale-75 peer-focus:scale-75 peer-placeholder-shown:translate-y-0 -translate-y-3 peer-focus:-translate-y-3 peer-placeholder-shown:translate-x-0 translate-x-1 peer-focus:translate-x-1 absolute top-0 left-0 px-3 py-3 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out"
                        >
                            Your Name*
                        </label>
                        {nameHasError && (
                            <p className="text-left text-sm mt-1 font-Roboto text-red-400">
                                {`Please enter a valid Name`}
                            </p>
                        )}
                    </div>
                    {/* EMAIL */}
                    {selectedOption !== 'Employee' &&
                                !loginWithEmail && !isInternationalCustomer && (
                        <div class={`mb-2 md:mb-2 relative`}>
                            <input
                                type="email"
                                id="email"
                                class="peer pt-8 border border-gray-300 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full p-3 h-12 placeholder-transparent"
                                placeholder="name@example.com"
                                onChange={emailChangedHandler}
                                onBlur={emailBlurHandler}
                                value={email}
                                autoComplete="off"
                            />
                            <label
                                for="email"
                                class="peer-placeholder-shown:opacity-100  opacity-75 peer-focus:opacity-75 peer-placeholder-shown:scale-100 scale-75 peer-focus:scale-75 peer-placeholder-shown:translate-y-0 -translate-y-3 peer-focus:-translate-y-3 peer-placeholder-shown:translate-x-0 translate-x-1 peer-focus:translate-x-1 absolute top-0 left-0 px-3 py-3 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out"
                            >
                                {selectedOption === 'Employee'
                                    ? 'Company Email Address*'
                                    : 'Email address*'}
                            </label>
                            {emailHasError && (
                                <p className="text-left text-sm mt-1 font-Roboto text-red-400">
                                    Please enter a valid email address
                                </p>
                            )}
                        </div>
                    )}
                    {/* MOBILE NUMBER */}

                    {(isInternationalCustomer ||
                                selectedOption === 'Employee' ||
                                loginWithEmail) && (
                        <div
                            className={` w-full flex flex-col mx-auto mb-2 md:mb-5'
          relative`}
                        >
                            {/* <label className="block text-sm md:text-base tracking-wide self-centre text-gray-600 ">
            Enter your mobile number
        </label> */}
                            <PhoneInput
                                // disabled={isOtpSent}
                                country="in"
                                countryCodeEditable={false}
                                containerClass={
                                    'peer py-3 border border-gray-300 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full  h-12 placeholder-transparent'
                                }
                                containerStyle={{
                                    width: '100%',
                                    border: '2px solid grey !important',
                                    outline: 'none'
                                }}
                                buttonStyle={{
                                    backgroundColor: 'white',
                                    border: 'none'
                                }}
                                inputStyle={{
                                    width: '100%',
                                    height: 'auto',
                                    border: 'none',
                                    outline: 'none',
                                    paddingLeft: '44px',
                                    fontSize: '16px'
                                }}
                                isValid={currentMobileValidtor}
                                onChange={currentMobileOnChange}
                                value={currentMobile}
                                enableSearch
                                onBlur={() => {
                                    setCurrentMobileIsTouched(true);
                                    setCurrentMobileInputFocused(false);
                                }}
                                onFocus={() =>
                                    setCurrentMobileInputFocused(true)
                                }
                                inputClass="border border-gray-300"
                            />
                            {currentMobileHasError && (
                                <p
                                    className={` text-left text-sm mt-1 font-Roboto text-red-400`}
                                >
                                    Please enter a valid number
                                </p>
                            )}
                        </div>
                    )}

                    <div className=" flex justify-between">
                        <button
                            onClick={backClickedHandler}
                            class={`w-auto ${config?.loginModal.buttonBg} text-white py-2 px-4 md:px-8 rounded-md mb-5  `}
                        >
                            Back
                        </button>
                        <button
                            disabled={!formIsValid}
                            onClick={registrationHandler}
                            class={`w-auto ${config?.loginModal.buttonBg} text-white py-2 px-4 md:px-8 rounded-md mb-5  disabled:hover:text-white disabled:opacity-90 disabled:bg-gray-400 disabled:cursor-not-allowed`}
                        >
                            Register
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default SignUp;
