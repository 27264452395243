import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { scrollToTargetAdjusted } from '../../../utils/ui';
function IconsLayout(props) {
    const {
        info = [],
        infotxtClassName,
        infoHeadingClassName,
        imgClassName,
        imgContainerClassName,
        containerClassName,
        subContainerClassName,
        headingClassName,
        heading,
        heading2,
        infoContainerClassName,
        gridClassName,
        dividerClassName,
        onClick,
        path
    } = props;
    const history = useHistory();

    const changePath = (path) => {
        history.push(`${path}`);
    };

    const changePathAndScroll = (path, scrollTo) => {
        history.push({
            pathname: path,
            state: { scrollTo: scrollTo }
        });
    };

    const onClickHandler = (e, type, path, scrollTo, link) => {
        // console.log(type, path);
        switch (type) {
            case 'SCROLL':
                return scrollToTargetAdjusted(scrollTo);
            case 'LINK':
                return window.open(link);
            case 'CHANGE_PATH':
                return changePath(path);
            case 'CHANGE_PATH_AND_SCROLL':
                return changePathAndScroll(path);
            default:
                return;
        }
    };
    return (
        <div
            className={
                containerClassName ||
                'h-content xl:w-full w-full lg:flex lg:h-auto bg-white mb-4 lg:py-2 xl:px-16 px-8 items-center'
            }
        >
            <div
                className={
                    subContainerClassName ||
                    'text-center w-full text-[#f58020] font-semibold'
                }
            >
                {heading && (
                    <h2
                        className={
                            headingClassName ||
                            'xl:text-xl xl:tracking-normal text-lg'
                        }
                    >
                        {heading}
                    </h2>
                )}
                {heading2 && (
                    <h2
                        className={
                            headingClassName ||
                            'lg:text-xl lg:tracking-normal text-lg'
                        }
                    >
                        {heading2}
                    </h2>
                )}
                <div
                    className={
                        gridClassName ||
                        'flex overflow-x-scroll lg:overflow-x-hidden lg:grid lg:grid-cols-3  xl:w-[70%] lg:pt-3 lg:m-auto m-auto lg:text-center'
                    }
                >
                    {info.map((i, index) => (
                        <div
                            className={
                                infoContainerClassName ||
                                'mt-10 marker:mb-4 lg:mt-0 min-w-[60vw] lg:min-w-full'
                            }
                            key={index}
                            style={{
                                background: i.BGColor
                            }}
                            onClick={(e) =>
                                onClickHandler(
                                    e,
                                    i?.onClick,
                                    i?.path,
                                    i?.scrollTo
                                )
                            }
                        >
                            <div className={imgContainerClassName || ''}>
                                <img
                                    className={
                                        imgClassName ||
                                        'w-[22%] md:w-[12%] lg:w-[26%] lg:mx-auto mx-auto lg:mb-2'
                                    }
                                    src={i.imgUrl}
                                />
                            </div>
                            <h1
                                className={
                                    infoHeadingClassName ||
                                    'w-full md:w-[50%] mx-auto lg:w-full md:px-2 font-Helvetica_Light text-lg text-[#373737] font-normal mb-4 '
                                }
                            >
                                {i.infoHeading}
                            </h1>

                            {i.divider ? (
                                <hr
                                    color={i.dividerColor}
                                    className={
                                        dividerClassName ||
                                        'w-[8%] border-none h-[4px]'
                                    }
                                ></hr>
                            ) : (
                                ' '
                            )}
                            <p
                                className={
                                    infotxtClassName
                                        ? infotxtClassName
                                        : 'w-full md:w-[50%] mx-auto lg:w-full md:px-2 lg:text-lg font-Helvetica_Light text-lg text-[#373737] font-normal  '
                                }
                            >
                                {i.infoText}
                            </p>
                            {i.buttonName ? (
                                <button
                                    onClick={(e) =>
                                        onClickHandler(e, onClick, path)
                                    }
                                    className={`${i?.buttonClass}`}
                                >
                                    {i.buttonName}
                                </button>
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default IconsLayout;
