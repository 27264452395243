import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

function CustomSlide(props) {
    const {
        imgdata,
        imageClassName,
        imageContainerClassName,
        captionClass,
        index,
        onImageClick,
        internalRedirect
    } = props;

    const [showImageOverlay, setShowImageOverlay] = useState(true);
    const history = useHistory()

const onClickHandler = (imgdata, index) => {
    if(internalRedirect) {
        history.push(imgdata?.url)
    } else {
    onImageClick(imgdata, index)}
}
    return (
        <>
            <div
                className="relative"
                onClick={() => onClickHandler(imgdata, index)}
            >
                <div className={imageContainerClassName}>
                    <img className={imageClassName} src={imgdata.imgUrl} />
                    <div
                        onMouseOver={() => setShowImageOverlay(false)}
                        onMouseLeave={() => setShowImageOverlay(true)}
                        className="bg-cover bg-center absolute inset-0 hover:bg-transparent hover:ease-out hover:delay-300 hover:transition-all cursor-pointer"
                        style={{
                            backgroundImage: `url(${
                                showImageOverlay ? imgdata.coverImg : ''
                            })`
                        }}
                    ></div>
                </div>
            </div>
            {imgdata?.caption && (
                <p
                    className={
                        captionClass +
                        `${
                            showImageOverlay
                                ? ' text-secondary-focus'
                                : ' text-primary '
                        }`
                    }
                >
                    {imgdata.caption}
                </p>
            )}
        </>
    );

    // <div className="relative">
    //     <div>
    //         <img src="https://cdn.loyalie.in/42174972.jpg" />
    //     </div>
    //     <h1 className=" text-center">Embassy Boulevard</h1>
    //     <div className=" absolute inset-0 bg-pink-300 hover:bg-transparent opacity-70"></div>
    // </div>
}

export default CustomSlide;
