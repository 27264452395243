import React, { useState } from 'react';
import {
    useLocation,
    useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import useData from './data';
import DealsLayout from '../DealsLayout/newIndex';
import CustomSelect from '../../../../Components/Molecules/CustomSelect';
import SearchBar from '../../../../Components/Organisms/SearchBar';
import useTagsData from '../../../../Components/Molecules/FilterOptions/data';
import { filterSvg, loadMoreSvg, noDealsvg } from './svg';
import SideModal from '../../../../Components/Organisms/SideModal';
import GrClose from '@meronex/icons/gr/GrClose';
import SkeletonListCard from '../../../../Components/Molecules/Skeleton/SkeletonCategoryBar/SkeletonListCard';
import SkeletonGeneralOffer from '../../../../Components/Molecules/Skeleton/SkeletonCategoryBar/SkeletonGeneralOffer';
import { getConfig } from '../../../../utils/config';
import { useSelector } from 'react-redux';
import DealSection from '../DealSection';
import ZeroDeals from '../ZeroDeals';

const filterOptions = ['Categories', 'Tags', 'Brands'];

function ProductListing() {
    const [openMobileFilter, setOpenMobileFilter] = useState(false);
    const [selectedMobileFilter, setSelectedMobileFilter] =
        useState('Categories');

    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const config = getConfig();

    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );

    const {
        offers,
        categories,
        brands,
        handleChange,
        searchInput,
        selectedTags,
        selectedBrands,
        selectedCategories,
        setSelectedTags,
        setSelectedBrands,
        setSelectedCategories,
        totalPages,
        clearFilters,
        setLoadMoreOffers,
        loadMoreOffers,
        tags,
        loadingOffers,
        topOffers
    } = useData(params.id, queryParams?.get('vendor'));
    const formattedTags = tags?.map((tag) => {
        return { value: tag.id, label: tag.name };
    });
    const formattedCategories = categories
        ?.filter(
            (category) =>
                category.id !== 4 && category.id !== 6 && category.id !== 7
        )
        .map((category) => {
            return { value: category.id, label: category.name };
        });
    const formattedBrands = brands?.map((brand) => {
        return { value: brand.id, label: brand.name };
    });
    console.log('top Offers', topOffers);
    console.log('selected categories', selectedCategories);
    console.log('selectedTags', selectedTags);

    const applyFilter = (filterState, setFun, option) => {
        console.log('apply', option);
        if (!!filterState.find((element) => element.value == option.value)) {
            setFun((prev) => [...prev.filter((e) => e.value != option.value)]);
        } else {
            setFun((prev) => [...prev, option]);
        }
    };
    console.log('look', formattedCategories, selectedCategories);
    console.log('tags', tags);
    const renderFilterOptions = () => {
        switch (selectedMobileFilter) {
            case 'Categories':
                return formattedCategories?.map((category) => (
                    <div className="flex items-center gap-x-2 mt-2">
                        <input
                            type="checkbox"
                            value={category.value}
                            checked={
                                !!selectedCategories.find(
                                    (option) => option.value == category.value
                                )
                            }
                            onClick={() => {
                                applyFilter(
                                    selectedCategories,
                                    setSelectedCategories,
                                    category
                                );
                            }}
                            className="checkbox-md  bg-white rounded-md border-2 checked:bg-primary"
                            autoComplete="off"
                        />
                        <p>{category.label}</p>
                    </div>
                ));
            case 'Tags':
                return formattedTags?.map((tag) => (
                    <div className="flex items-center gap-x-2 mt-2">
                        <input
                            type="checkbox"
                            value={tag.value}
                            checked={
                                !!selectedTags.find(
                                    (option) => option.value == tag.value
                                )
                            }
                            onClick={() =>
                                applyFilter(selectedTags, setSelectedTags, tag)
                            }
                            className="checkbox-md  bg-white rounded-md border-2 checked:bg-primary"
                            autoComplete="off"
                        />
                        <p>{tag.label}</p>
                    </div>
                ));
            case 'Brands':
                return formattedBrands?.map((brand) => (
                    <div className="flex items-center gap-x-2 mt-2">
                        <input
                            type="checkbox"
                            value={brand.value}
                            checked={
                                !!selectedBrands.find(
                                    (option) => option.value == brand.value
                                )
                            }
                            onClick={() =>
                                applyFilter(
                                    selectedBrands,
                                    setSelectedBrands,
                                    brand
                                )
                            }
                            className="checkbox-md  bg-white rounded-md border-2 checked:bg-primary"
                            autoComplete="off"
                        />
                        <p>{brand.label}</p>
                    </div>
                ));
            default:
                return null;
        }
    };

    const handleTagsChange = (selected) => {
        console.log('option clicked', selected);
        setSelectedTags(() => [...selectedTags, ...selected]);
    };

    const allFiltersApplied = [
        ...selectedCategories.map((c) => ({ ...c, type: 'Category' })),
        ...selectedTags.map((t) => ({ ...t, type: 'Tag' })),
        ...selectedBrands.map((b) => ({ ...b, type: 'Brand' }))
    ];
    const removeAppliedFilter = (type, value) => {
        switch (type) {
            case 'Category':
                setSelectedCategories(
                    selectedCategories.filter((tag) => tag.value != value)
                );
                break;

            case 'Tag':
                setSelectedTags(
                    selectedTags.filter((tag) => tag.value != value)
                );
                break;
            case 'Brand':
                setSelectedBrands(
                    selectedBrands.filter((tag) => tag.value != value)
                );
                break;
        }
    };

    return (
        <div className={`${internalWebview ? 'mb-16' : ''}`}>
            <div
                className={`
                    ${config?.bannerNegativeTopMarginClass} w-full h-auto  `}
            >
                {config?.header && (
                    <div className={config?.header.headerClass}></div>
                )}
            </div>
            <div className="w-full lg:w-[90%] mx-auto">
                <div
                    className={`border-b-2 py-4 px-4 lg:px-0 ${
                        internalWebview ? 'sticky top-0 z-10 bg-white' : ''
                    }`}
                >
                    <div className={`flex gap-x-2 `}>
                        <SearchBar
                            width="w-full lg:w-[40%]"
                            searchValue={searchInput}
                            onSearchChange={handleChange}
                        />
                        <div className="hidden w-full lg:flex gap-x-2">
                            <CustomSelect
                                onChangeCallback={(categories) => {
                                    // console.log(categories)
                                    setSelectedCategories(categories);
                                }}
                                placeholder="Select Categories"
                                className="w-full"
                                options={formattedCategories}
                                selectedValues={selectedCategories}
                            />
                            <CustomSelect
                                onChangeCallback={(tags) => {
                                    // console.log('from select', tags)
                                    setSelectedTags(tags);
                                }}
                                placeholder="Tags"
                                className="w-full"
                                options={formattedTags}
                                selectedValues={selectedTags}
                            />
                            <CustomSelect
                                onChangeCallback={(brands) => {
                                    // console.log(" From Brands" ,brands)
                                    setSelectedBrands(brands);
                                }}
                                placeholder="Brands"
                                className="w-full"
                                options={formattedBrands}
                                selectedValues={selectedBrands}
                            />
                        </div>
                        <div
                            onClick={() => setOpenMobileFilter(true)}
                            className="flex items-center justify-center py-2 px-4 lg:hidden rounded-lg border border-[#DDDDDD]"
                        >
                            {filterSvg}
                        </div>
                    </div>
                </div>
                {allFiltersApplied.length > 0 && (
                    <div className="py-2 lg:py-4 px-4 lg:px-0">
                        <div className="flex justify-between mt-2">
                            <div className="w-full md:w-[75%] flex flex-wrap gap-x-2 gap-y-2">
                                {allFiltersApplied.map((filter) => (
                                    <button
                                        className={`text-sm md:text-base lg:text-sm xl:text-base bg-[#f0f0f0] font-medium rounded-2xl py-1 px-3 `}
                                    >
                                        {filter.label}
                                        <span className="ml-2 cursor-pointer">
                                            <GrClose
                                                onClick={() =>
                                                    removeAppliedFilter(
                                                        filter.type,
                                                        filter.value
                                                    )
                                                }
                                                className={`inline h-3 w-3 `}
                                            />
                                        </span>
                                    </button>
                                ))}
                            </div>
                            <div className="w-[25%] hidden lg:flex justify-end self-start">
                                <button
                                    onClick={clearFilters}
                                    className=" text-sm text-[#16519E] underline font-medium"
                                >
                                    Clear all
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className=" ">
                    {offers.length > 0 && (
                        <h1 className="mt-7 font-bold text-center lg:text-left">
                            {searchInput.length > 0
                                ? 'Search Results'
                                : 'Product Lists'}
                        </h1>
                    )}
                    {loadingOffers && loadMoreOffers <= 8 && (
                        <div className="flex flex-wrap justify-center lg:justify-start gap-2 xl:gap-4 mt-4">
                            {[...Array(8).keys()].map((n) => (
                                <SkeletonGeneralOffer key={n} />
                            ))}
                        </div>
                    )}
                    {offers.length == 0 &&
                        (!loadingOffers || loadMoreOffers > 8) && (
                            <>
                                <ZeroDeals />
                                <div className="w-[93%] lg:w-full mx-auto pt-2 pb-5">
                                    {topOffers.length > 0 && (
                                        <DealSection
                                            type="OFFERS OF THE MONTH"
                                            offers={topOffers.slice(0, 5)}
                                            className="mt-7"
                                            hide={true}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    {
                        <div className="flex flex-wrap justify-center lg:justify-start gap-y-5 gap-x-4 xl:gap-4 mt-4 mb-10">
                            {(!loadingOffers || loadMoreOffers > 8) &&
                                offers.map((offer) => (
                                    <DealsLayout {...offer} fromProductPage />
                                ))}
                        </div>
                    }
                    {totalPages > 1 && (
                        <button
                            onClick={() =>
                                setLoadMoreOffers((prev) => prev + 8)
                            }
                            className="flex items-center justify-center gap-x-2 mx-auto px-4 py-2 text-[#908F95] border-[3px] border-[#DDDDDD] rounded mb-6"
                        >
                            {loadingOffers ? (
                                'Loading...'
                            ) : (
                                <>Load More{loadMoreSvg}</>
                            )}
                        </button>
                    )}
                </div>
                <SideModal
                    visible={openMobileFilter}
                    onClick={() => setOpenMobileFilter(false)}
                    onlyMobile
                >
                    <div className="h-full">
                        <div className="flex justify-between p-3">
                            <h1 className="font-bold text-lg md:text-2xl lg:text-lg xl:text-2xl">
                                Filters
                            </h1>
                            <h1
                                onClick={() => clearFilters()}
                                className=" text-sm text-[#16519E] underline font-medium"
                            >
                                Clear all filters
                            </h1>
                        </div>
                        <div className="flex border-b-2 border-t-2">
                            <div className="w-[30%] border-r-2">
                                {filterOptions.map((option) => (
                                    <p
                                        onClick={() =>
                                            setSelectedMobileFilter(option)
                                        }
                                        className={`${
                                            selectedMobileFilter == option
                                                ? 'bg-primary text-white'
                                                : ''
                                        } border-b-2 p-3`}
                                    >
                                        {option}
                                    </p>
                                ))}
                            </div>
                            <div className="w-[70%] p-3">
                                <h1 className="font-semibold">
                                    {selectedMobileFilter}
                                </h1>
                                {renderFilterOptions()}
                            </div>
                        </div>
                        <div className="p-3">
                            <button
                                onClick={() => setOpenMobileFilter(false)}
                                className="bg-primary text-white w-full rounded-lg py-3 font-bold"
                            >
                                Apply Filter
                            </button>
                        </div>
                    </div>
                </SideModal>
            </div>
        </div>
    );
}

export default ProductListing;
