import React from 'react';
import { getConfig } from '../../../utils/config';

function RazorPayTerms(props) {
    const config = getConfig();
    // console.log(config?.termsAndConditions)
    return (
        <div className={props?.classNameContainer}>
            <h2 className={config?.termsAndConditions?.titleClass}>
                Terms and Conditions to comply with Razorpay Guidelines
            </h2>
            <h3 className={config?.termsAndConditions?.titleClass}>Refunds:</h3>
            <ul className={config?.termsAndConditions?.listStyle}>
                <li className={config?.termsAndConditions?.listClassName}>
                    All orders made on the Reloy App are final and
                    non-refundable. Please ensure you are certain about your
                    purchase before confirming your order.
                </li>
                <li className={config?.termsAndConditions?.listClassName}>
                    Orders cannot be cancelled by the Users once placed. This
                    policy facilitates prompt order processing and delivery by
                    our brand partners.
                </li>
            </ul>
            <h3 className={config?.termsAndConditions?.titleClass}>
                Shipping and Delivery:
            </h3>
            <ul className={config?.termsAndConditions?.listStyle}>
                <li className={config?.termsAndConditions?.listClassName}>
                    Each product ordered on the Reloy App is shipped directly by
                    the respective brand partner, with specific shipping details
                    provided on the product page.
                </li>
                <li className={config?.termsAndConditions?.listClassName}>
                    Delivery times vary by brand and product. General guidelines
                    and estimated delivery times are available on each product
                    page.
                </li>
                <li className={config?.termsAndConditions?.listClassName}>
                    The brand partner will provide shipping confirmation and
                    tracking details for your order once it is dispatched.
                </li>
                <li className={config?.termsAndConditions?.listClassName}>
                    Delivery shall be made only to Users located in specific
                    locations and areas, as per the shipping policies of the
                    brand partner. If the order is not serviceable on the
                    location provided by the User, the Company and/ or the brand
                    reserve the right to cancel the delivery of products outside
                    the specific locations and areas, and the amount paid by the
                    customer will be refunded in the manner specified by the
                    Company and the brand partner.
                </li>
                <li className={config?.termsAndConditions?.listClassName}>
                    Customers must enter a complete and correct shipping address
                    in India, including pin code and contact information, to
                    ensure hassle free delivery. In the event the User enters an
                    incorrect or incomplete shipping address, the brand partner
                    and/ or the Company will not be responsible for ensuring
                    delivery or providing any refunds.
                </li>
            </ul>
            <h3 className={config?.termsAndConditions?.titleClass}>
                Customer Support:
            </h3>
            <ul className={config?.termsAndConditions?.listStyle}>
                <li className={config?.termsAndConditions?.listClassName}>
                    For product-specific issues or queries post-delivery, please
                    refer to the contact details on the product page.
                </li>
                <li className={config?.termsAndConditions?.listClassName}>
                    All product-specific issues including quality issues, damage
                    to the product, partial delivery of the product or delivery
                    of a wrong item shall be raised by the User as per the
                    policies of the brand partner. The brand partners are solely
                    responsible for resolution of any product-specific issues
                    raised by the User.
                </li>
                <li className={config?.termsAndConditions?.listClassName}>
                    The Company shall have no liability arising out of or any
                    obligation to resolve any product-specific issues and
                    queries raised by the User.
                </li>
                <li className={config?.termsAndConditions?.listClassName}>
                    For general assistance or questions about our platform, our
                    customer support team is available to provide help and
                    guidance.
                </li>
            </ul>
            <h3 className={config?.termsAndConditions?.titleClass}>
                Limitation of Liability and Brand Partner Responsibilities
            </h3>
            <ul className={config?.termsAndConditions?.listStyle}>
                <li className={config?.termsAndConditions?.listClassName}>
                    Product returns (and subsequent refunds / replacements),
                    shipping charges and grievance redressal will be governed by
                    the policies of the brand partner.
                </li>
                <li className={config?.termsAndConditions?.listClassName}>
                    The Company does not make any representation and warranty as
                    to the attributes (such as quality, marketability,
                    merchantability or fitness for a particular purpose) of the
                    products or services sold or offered or proposed to be sold
                    or offered on the Reloy App by the brand partners
                </li>
            </ul>
        </div>
    );
}

export default RazorPayTerms;
