import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from '../../../../Components/Atoms';
import { getConfig } from '../../../../utils/config';
import useData from './data';
import ReactGA from 'react-ga4';
import { LinkParagraph, renderDate } from '../../../../helpers';
import { cmsClient, winnreAuth } from '../../../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../../store/auth-slice';
import MdPlayCircleOutline from '@meronex/icons/md/MdPlayCircleOutline';
import EiPlay from '@meronex/icons/ei/EiPlay';
import ReactPlayer from 'react-player';
import Modal from '../../../../Components/Organisms/Modal';
import TiTick from '@meronex/icons/ti/TiTick';
import GrClose from '@meronex/icons/gr/GrClose';
import { toast } from 'react-toastify';
import DealFaq from '../DealFaq';
import DealSection from '../DealSection';
import SkeletonDeal from '../../../../Components/Molecules/Skeleton/SkeletonDeal';
import PhoneNumber from '../PhoneNumber';
import AiFillCalendar from '@meronex/icons/ai/AiFillCalendar';
import DealGalleryItem from '../DealGalleryItem';
import RedeemAnimation from '../RedeemButtonAnimation';
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';
import SiWhatsapp from '@meronex/icons/si/SiWhatsapp';
import FaFacebookF from '@meronex/icons/fa/FaFacebookF';
import FiCopy from '@meronex/icons/fi/FiCopy.js';
import MdcEmailOutline from '@meronex/icons/mdc/MdcEmailOutline';
import EnShareAlternative from '@meronex/icons/en/EnShareAlternative';
import EiCloseO from '@meronex/icons/ei/EiCloseO';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BsArrowLeft from '@meronex/icons/bs/BsArrowLeft';
import { copyCodeHandler } from '../../../../helpers';
import { copySvg } from '../../../../commonSVG';

const settings = {
    // dots: true,
    infinite: true,
    // speed: 10000,
    slidesToShow: 5,
    // slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    width: '70px',
    draggable: true,
    slidesToScroll: 3
    // adaptiveHeight: true,
    // easing: 'linear'
};

const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

function DealDetails() {
    const params = useParams();
    const history = useHistory();
    const [showTerms, setShowTerms] = useState(false);
    const [copiedText, setCopiedText] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showShare, setShowShare] = useState(true);
    const [voucherCode, setVoucherCode] = useState(null);
    const [modalMessage, setModalMessage] = useState(null);
    const [rememptionLoading, setRedemptionLoading] = useState(false);
    const [termsCheckbox, setTermsCheckbox] = useState(true);
    const [previewItem, setPreviewItem] = useState(null);
    const {
        loadingDeal,
        deal,
        similarOffers,
        topOffers,
        sameBrandOffers,
        bundleDeals,
        bundleDealDetails,
        faultyDeal
    } = useData(params.dealId);
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const accessToken = queryParams.get('token');
    const userToken = queryParams.get('userToken');
    const clientToken = queryParams.get('clientToken');
    const dealCategory = queryParams.get('category');
    const tagId = queryParams.get('tagid');
    const channel = queryParams.get('utm_medium');
    const userHash = queryParams.get('user');
    const isRedeemUser = queryParams.get('user') ? true : false;
    const autoRedeem = queryParams.get('autoRedeem');
    const config = getConfig();
    const [showShareModal, setShowShareModal] = useState(false);
    const [copiedUrl, setCopiedUrl] = useState(false);
    const [captureUserMobile, setCaptureUserMobile] = useState(false);
    const [link, setLink] = useState('');
    const [shareLink, setShareLink] = useState(''); // used to generate short share link
    const redeemNowButtonConfig =
        config?.deals.dealLayout.redeemNowButtonConfig;

    // const token = useSelector((state) => state.auth.token);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userMobile = useSelector(
        (state) => state.auth.userDetails.mobileNumber
    );
    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
    };
    const openLoginModalForDealRedeem = () => {
        dispatch(authActions.openLoginModalForDealRedeem());
    };
    // const buttonRef = useRef(null);

    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    const clickThroughMobile = clientWebview || internalWebview;
    let isBundleDeal = deal?.category?.id == '8';
    let isFinanceDeal = deal?.category?.id == '3';

    // useEffect(() => {

    //     if (internalWebview) {

    //       const button = buttonRef.current;
    //       // Function to set button position as fixed at the bottom
    //       const setStickyButton = () => {
    //         button.style.position = 'fixed';
    //         button.style.bottom = '0';
    //         button.style.left = '0';
    //         button.style.width = '100%';
    //       };
    //       setStickyButton();
    //     }
    //   }, [internalWebview]);

    useEffect(() => {
        setTermsCheckbox(true);
    }, [params?.dealId]);

    useEffect(() => {
        let enableShare = userHash ? false : true;
        if (
            !isAuthenticated &&
            !userToken &&
            !accessToken &&
            !clientToken &&
            enableShare &&
            !deal?.shareable
        ) {
            openLoginModal();
        }
        setShowShare(enableShare);
    }, []);

    useEffect(() => {
        if (deal) {
            window?.webengage?.track('DealViewed', {
                OfferName: `${deal?.title}`,
                Brand: `${deal?.vendor?.name}`,
                OfferType: `${deal?.category?.name}`,
                OfferImage: `${deal?.displayImage?.url}`,
                CashbackPercentage: `${deal?.eligibleCashback}`,
                CouponCode: `${deal?.dealRedemptionCode?.dealCode}`,
                Category: `${deal?.category?.name}`,
                Vendor: `${deal?.vendor?.name}`,
                Link: `${deal?.url}`,
                EndDate: `${deal?.endDate}`,
                Tags: `${deal?.tags?.map((tag) => tag?.name)}`,
                DealId: `${deal?.id}`
            });
        }

        if (deal?.videoUrl) {
            setPreviewItem(deal?.videoUrl);
        } else {
            setPreviewItem(deal?.displayImage?.url);
        }
    }, [deal]);

    useEffect(() => {
        if (deal && autoRedeem) {
            if (isBundleDeal) {
                if (bundleDeals?.length > 0 && bundleDealDetails?.length > 0) {
                    queryParams.delete('autoRedeem');
                    history.replace({
                        search: queryParams.toString()
                    });
                    redeemNowClickedHandler(
                        deal.url,
                        deal.id,
                        deal?.redemptionType
                    );
                }
            } else {
                queryParams.delete('autoRedeem');
                history.replace({
                    search: queryParams.toString()
                });
                redeemNowClickedHandler(
                    deal.url,
                    deal.id,
                    deal?.redemptionType
                );
            }
        }
    }, [deal, bundleDealDetails, bundleDeals]);

    const handleCopyCode = async () => {
        const code =
            deal?.redemptionType == 'VOUCHER'
                ? voucherCode
                : deal?.dealRedemptionCode?.dealCode;
        await copyCodeHandler(code, setCopiedText);
        // if ('clipboard' in navigator && code) {
        //     await navigator.clipboard.writeText(code);
        // }
        // setCopiedText(true);
    };

    const imgArr = bundleDeals?.map((deal) => {
        return {
            imgUrl: deal?.vendorLogoUrl || '',
            dealName: deal?.title,
            dealImage: deal?.thumbnailImageUrl
                ? deal?.thumbnailImageUrl
                : deal?.displayImageUrl,
            dealId: deal?.id
        };
    });
    const redeemNowClickedHandler = (url, id, redemptionType, mobile) => {
        if (isRedeemUser && !isAuthenticated) {
            // enable only if the user access the shared deal
            openLoginModalForDealRedeem();
        } else if (
            !isAuthenticated &&
            !userToken &&
            !accessToken &&
            !clientToken
        ) {
            openLoginModal();
        } else {
            if (!userMobile && !mobile) {
                setCaptureUserMobile(true);
                setShowModal(true);
                return;
            }
            setRedemptionLoading(true);
            setModalMessage(null);
            if (
                cmsClient.defaults.baseURL === 'https://prod.loyalie.in' ||
                cmsClient.defaults.baseURL === 'https://uat.loyalie.in'
            ) {
                window.fbq('trackCustom', 'RedeemNowButtonClicked');
            }
            ReactGA.event({
                category: 'OFFER',
                action: 'REDEEM NOW CLICKED',
                label: 'REDEEM OFFER BUTTON'
            });
            let apiCall;

            // To add source user if the deal is shared.

            let sourceUserId = userHash ? `&sourceUserId=${userHash}` : '';

            if (isBundleDeal) {
                apiCall = Promise.all(
                    bundleDeals?.map((bundleDeal) =>
                        winnreAuth({
                            method: 'POST',
                            url: `winnre/user/deals/redeem-v2?dealId=${
                                bundleDeal?.id
                            }&sourcePlatform=${
                                clickThroughMobile ? 'MOBILE' : 'WEBSITE'
                            }${
                                channel ? `&channel=${channel}` : ''
                            }${sourceUserId}`
                        })
                    )
                );
            } else {
                apiCall = winnreAuth({
                    method: 'POST',
                    url: `winnre/user/deals/redeem-v2?dealId=${id}&sourcePlatform=${
                        clickThroughMobile ? 'MOBILE' : 'WEBSITE'
                    }${channel ? `&channel=${channel}` : ''}${sourceUserId}`
                });
            }
            apiCall
                .then((res) => {
                    console.log('redeem', res);
                    if (isBundleDeal) {
                        let index = 0;
                        const offers = bundleDealDetails || [];
                        for (const bundleDeal of bundleDeals) {
                            // console.log(bundleDeal.title, bundleDeal.redemptionType, bundleDeal.eligibleCashback, bundleDeal.dealRedemptionCode, bundleDeal.endDate, bundleDeal.id, bundleDeal.categoryName, bundleDeal.vendorName)
                            window?.webengage?.track('DealClicked', {
                                OfferName: `${bundleDeal?.title}`,
                                RedemptionType: `${bundleDeal?.redemptionType}`,
                                CashbackPercentage: `${bundleDeal?.eligibleCashback}`,
                                CouponCode: `${bundleDeal?.dealRedemptionCode}`,
                                Category: `${bundleDeal?.categoryName}`,
                                Vendor: `${bundleDeal?.vendorName}`,
                                Link: `${bundleDeal?.url}`,
                                EndDate: `${bundleDeal?.endDate}`,
                                Tags: `${bundleDeal?.tags?.map(
                                    (tag) => tag?.name
                                )}`,
                                DealId: `${bundleDeal?.id}`,
                                VoucherCode:
                                    bundleDeal.dealType === 'OFFLINE'
                                        ? ''
                                        : bundleDeal?.redemptionType ===
                                              'VOUCHER' &&
                                          res[index]?.status == 'SUCCESS'
                                        ? res[index]?.dealCode
                                        : res[index]?.status == 'FAILED' &&
                                          res[index]?.message.includes(
                                              'Repeat:'
                                          )
                                        ? res[index]?.message.split(
                                              'Repeat:'
                                          )[1]
                                        : '',
                                Channel: channel ? channel : 'Direct',
                                ...(bundleDeal.redemptionType === 'VOUCHER' &&
                                    bundleDeal.dealType === 'OFFLINE' && {
                                        QRCode:
                                            res[index]?.status == 'SUCCESS'
                                                ? res[index]?.dealQrCodeLink
                                                : res[index]?.status == 'FAILED'
                                                ? res[index]?.message.split(
                                                      'Repeat:'
                                                  )[1]
                                                : ''
                                    })
                            });
                            const offersIndex = offers.findIndex(
                                (o) => o.id === bundleDeal?.id
                            );
                            offers[offersIndex] = {
                                ...offers[offersIndex],
                                ...bundleDeal,
                                VoucherCode:
                                    bundleDeal.dealType === 'OFFLINE'
                                        ? ''
                                        : bundleDeal?.redemptionType ===
                                              'VOUCHER' &&
                                          res[index]?.status == 'SUCCESS'
                                        ? res[index]?.dealCode
                                        : res[index]?.status == 'FAILED' &&
                                          res[index]?.message.includes(
                                              'Repeat:'
                                          )
                                        ? res[index]?.message.split(
                                              'Repeat:'
                                          )[1]
                                        : '',
                                ...(bundleDeal.redemptionType === 'VOUCHER' &&
                                    bundleDeal.dealType === 'OFFLINE' && {
                                        QRCode:
                                            res[index]?.status == 'SUCCESS'
                                                ? res[index]?.dealQrCodeLink
                                                : res[index]?.status == 'FAILED'
                                                ? res[index]?.message.split(
                                                      'Repeat:'
                                                  )[1]
                                                : ''
                                    })
                            };
                            index++;
                        }
                        setRedemptionLoading(false);
                        history.push({
                            pathname: '/bundleDetails',
                            state: { offers }
                        });
                        window?.webengage?.track('PdfViewed');
                    } else {
                        window?.webengage?.track('DealClicked', {
                            OfferName: `${deal?.title}`,
                            RedemptionType: `${deal?.redemptionType}`,
                            CashbackPercentage: `${deal?.eligibleCashback}`,
                            CouponCode: `${deal?.dealRedemptionCode?.dealCode}`,
                            Category: `${deal?.category?.name}`,
                            Vendor: `${deal?.vendor?.name}`,
                            Link: `${deal?.url}`,
                            EndDate: `${deal?.endDate}`,
                            Tags: `${deal?.tags?.map((tag) => tag?.name)}`,
                            DealId: `${deal?.id}`,
                            VoucherCode:
                                deal?.dealType === 'OFFLINE'
                                    ? ''
                                    : deal?.redemptionType === 'VOUCHER'
                                    ? res?.status == 'SUCCESS'
                                        ? res?.dealCode
                                        : res?.status == 'FAILED' &&
                                          res?.message.includes('Repeat:')
                                        ? res?.message.split('Repeat:')[1]
                                        : ''
                                    : '',
                            ...(redemptionType === 'VOUCHER' &&
                                deal.dealType === 'OFFLINE' && {
                                    QRCode:
                                        res?.status == 'SUCCESS'
                                            ? res?.dealQrCodeLink
                                            : res?.status == 'FAILED'
                                            ? res?.message.includes('Repeat:')
                                                ? res?.message.split(
                                                      'Repeat:'
                                                  )[1]
                                                : ''
                                            : ''
                                }),
                            Status: res?.status,
                            Channel: channel ? channel : 'Direct'
                        });
                        setRedemptionLoading(false);
                        if (
                            res?.status === 'SUCCESS' &&
                            isFinanceDeal &&
                            res?.dealRedemptionId
                        ) {
                            console.log('navigate to home loan');
                            // Navigate to '/home-loan' and pass dealRedemptionId as state
                            history.push('/home-loans', {
                                dealRedemptionId: res.dealRedemptionId
                            });
                        }
                        if (redemptionType === 'CODE') {
                            handleCopyCode();
                            window.open(url);
                            return;
                        }
                        if (redemptionType === 'LINK') {
                            window.open(url);
                            return;
                        }
                        if (redemptionType === 'CONSULTATION') {
                            setShowModal(true);
                            if (res.status === 'FAILED') {
                                setModalMessage(res.message);
                            }
                            return;
                        }
                        if (redemptionType === 'VOUCHER') {
                            setShowModal(true);
                            if (res?.status == 'SUCCESS') {
                                if (deal?.dealType !== 'OFFLINE') {
                                    setVoucherCode(res?.dealCode);
                                    setModalMessage(null);
                                }
                            }
                            if (res?.status == 'FAILED') {
                                let code = res?.message.split('Repeat:');
                                if (code.length > 1) {
                                    if (deal?.dealType !== 'OFFLINE') {
                                        setVoucherCode(code[1]);
                                        setModalMessage(
                                            'Hey, you have already redeemed this deal! Here is your voucher code '
                                        );
                                    } else {
                                        setModalMessage(
                                            'Hey, you have already redeemed this deal. We have resent the details to you!'
                                        );
                                    }
                                } else {
                                    setModalMessage(res?.message);
                                    setVoucherCode(null);
                                }
                            }
                        }
                    }
                })
                .catch(() => {
                    setRedemptionLoading(false);
                    if (redemptionType === 'CONSULTATION') {
                        toast.error('Something Went Wrong');
                    }
                });
        }
    };
    const handleTnC = () => {
        setShowTerms(true);
        window?.webengage?.track('TnCViewed', {
            dealId: `${deal.id}`,
            dealTitle: `${deal.title}`,
            vendorName: `${deal.vendor.name}`
        });
    };

    const handleOnChange = () => {
        setTermsCheckbox(!termsCheckbox);
    };
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription((prev) => !prev);
    };

    const handlePreviewItem = (data) => {
        setPreviewItem(data);
    };

    //To get logged in user details for share link.
    const userId = useSelector((state) => state.auth.userHash);
    const user = useSelector((state) => state.auth.userId);
    const userRole = useSelector((state) => state.auth.userRole);

    //To generate shared deal link.
    const copyDealHandler = async () => {
        if ('clipboard' in navigator) {
            if (!isAuthenticated || userRole !== 'CUSTOMER') {
                await navigator.clipboard.writeText(window.location.href);
            } else if (userRole === 'CUSTOMER') {
                await navigator.clipboard.writeText(link);
            }
        }
        setCopiedUrl(true);
    };

    //To copied deal url enable & disable.
    useEffect(() => {
        const timer = setTimeout(() => {
            setCopiedUrl(false);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [copiedUrl]);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setCopiedText(false);
    //     }, 1500);

    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, [copiedText]);

    //On click share deal.
    const handleShareClick = (e) => {
        e.preventDefault();
        let links = encodeURIComponent(
            `${window.location.href.split('?')[0]}?dealId=${
                deal?.id
            }&userId=${userId}&user=${user}`
        );
        //To get shortLink
        winnreAuth.post('/winnre/user/short-link?URL=' + links).then((res) => {
            setShareLink(res.shortURL);
            setLink(res.shortURL);
            if (internalWebview) {
                // Message passing for Mobile view
                var valueToSend = {
                    message: 'SHARE_LINK',
                    data: {
                        message: deal.shareMessage
                            ? deal.shareMessage
                            : "Hello! Hope you're doing well. I wanted to share this exciting deal I found recently. Click to learn more!",
                        value: res.shortURL
                    }
                };
                // IOS message handler
                window?.webkit?.messageHandlers?.jsMessageHandler.postMessage(
                    valueToSend
                );
                var stringifiedValueToSend = JSON.stringify(valueToSend);
                // Android message handler
                window?.JSBridge?.showMessageInNative(stringifiedValueToSend);
            }
            // Show Modal for web view
            else {
                setShowShareModal(true);
            }
        });
    };
    return (
        <div className={`${internalWebview ? 'mb-16' : ''}`}>
            <section
                className={
                    config?.deals.dealsSection.overlayClass ? 'relative' : ''
                }
                // style={{
                //     backgroundImage: `linear-gradient(90deg, rgba(${config?.deals.dealsSection.linearGradient?.R}, ${config?.deals.dealsSection.linearGradient?.G}, ${config?.deals.dealsSection.linearGradient?.B}, ${config?.deals.dealsSection.linearGradient?.opacity}),rgba(${config?.deals.dealsSection.linearGradient?.R}, ${config?.deals.dealsSection.linearGradient?.G}, ${config?.deals.dealsSection.linearGradient?.B}, ${config?.deals.dealsSection.linearGradient?.opacity})), url("${config?.deals.dealsSection?.sectionBackgroundImage}")`,
                //     backgroundAttachment: 'fixed',
                //     backgroundSize: `${
                //         config?.deals.dealsSection.linearGradient?.size
                //             ? config?.deals.dealsSection.linearGradient?.size
                //             : 'cover'
                //     } `
                // }}
            >
                {config?.deals.dealsSection.overlayClass ? (
                    <div
                        className={
                            `${config?.deals.dealsSection.overlayClass}` +
                            ' absolute top-0 bottom-0 left-0 right-0 z-0'
                        }
                    ></div>
                ) : null}

                <div
                    className={`
                    ${config?.bannerNegativeTopMarginClass} w-full h-auto  `}
                >
                    {config?.header && (
                        <div className={config?.header.headerClass}></div>
                    )}
                    {loadingDeal && (
                        <div className="h-auto mx-auto ">
                            <div className="lg:mx-4 ">
                                {[...Array(1).keys()].map((n) => (
                                    <SkeletonDeal key={n} shape="square" />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                {!isAuthenticated &&
                    !deal.shareable &&
                    !accessToken &&
                    !userToken &&
                    !clientToken && (
                        <div className="py-12 flex justify-center items-center h-[80vh]">
                            <Button
                                {...redeemNowButtonConfig}
                                content="Login to view Offer Details"
                                onClick="FUNC"
                                onClickFunc={openLoginModal}
                                className={redeemNowButtonConfig.className}
                            />
                        </div>
                    )}
                {!loadingDeal && (
                    <div className="w-full lg:w-[95%] xl:w-[93%] mx-auto lg:py-8">
                        {/* Deal details */}
                        {faultyDeal && (
                            <div className="flex flex-col items-center justify-center">
                                <img
                                    className="w-36 "
                                    src="https://previews.123rf.com/images/roxanabalint/roxanabalint1701/roxanabalint170100188/69079058-not-available-grunge-rubber-stamp-on-white-background-vector-illustration.jpg"
                                />
                                <p className="text-lg font-semibold text-center">
                                    Sorry, the deal you have selected has either
                                    expired or stocks have finished. Checkout
                                    other exciting deals below:
                                </p>
                            </div>
                        )}
                        {!faultyDeal &&
                            (isAuthenticated ||
                                (!isAuthenticated && deal?.shareable)) && (
                                <div className="flex flex-col lg:flex-row lg:border-b-2 border-gray-200 items-start lg:pb-7 ">
                                    {(deal?.displayImage?.url &&
                                        deal?.videoUrl) ||
                                    deal?.additionalBannerImages?.length > 0 ? (
                                        <div className="hidden lg:flex flex-col pr-4 max-h-[250px] md:max-h-[480px] lg:max-h-[335px] xl:max-h-[410px] 2xl:max-h-[406px] overflow-hidden">
                                            {deal?.videoUrl && (
                                                <div className="flex relative w-full  justify-center">
                                                    <DealGalleryItem
                                                        imageUrl={
                                                            deal?.displayImage
                                                                ?.url
                                                        }
                                                        videoUrl={
                                                            deal?.videoUrl
                                                        }
                                                        previewItem={
                                                            previewItem
                                                        }
                                                        setPreviewItem={
                                                            setPreviewItem
                                                        }
                                                    />
                                                    <MdPlayCircleOutline className=" absolute self-center text-white text-4xl " />
                                                </div>
                                            )}

                                            {deal?.displayImage?.url && (
                                                <DealGalleryItem
                                                    imageUrl={
                                                        deal?.displayImage?.url
                                                    }
                                                    previewItem={previewItem}
                                                    setPreviewItem={
                                                        setPreviewItem
                                                    }
                                                />
                                            )}
                                            <div
                                                id="DealsDetail"
                                                className="max-w-[70px] max-h-[250px] md:max-h-[480px] lg:max-h-[335px] xl:max-h-[410px] 2xl:max-h-[406px] overflow-y-scroll overflow-y-hidden overflow-x-hidden"
                                            >
                                                {deal?.additionalBannerImages
                                                    ?.length > 0 &&
                                                    deal?.additionalBannerImages?.map(
                                                        (d) => (
                                                            <DealGalleryItem
                                                                imageUrl={
                                                                    d?.url
                                                                }
                                                                previewItem={
                                                                    previewItem
                                                                }
                                                                setPreviewItem={
                                                                    setPreviewItem
                                                                }
                                                            />
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                    ) : null}

                                    {previewItem &&
                                    previewItem === deal.videoUrl ? (
                                        <div
                                            className={`relative h-[250px] md:h-[480px] lg:h-[335px] xl:h-[410px] 2xl:h-[406px] w-full ${
                                                // deal?.redemptionType === 'CONSULTATION'
                                                //     ? 'lg:w-[60%]' :
                                                'lg:w-[50%]'
                                            }`}
                                        >
                                            <ReactPlayer
                                                url={previewItem}
                                                className="react-player absolute self-center !h-[90%]"
                                                playIcon={
                                                    <div className="flex relative w-full mt-10 justify-center ">
                                                        <img
                                                            className="object-contain  w-full brightness-75"
                                                            src={
                                                                deal
                                                                    ?.displayImage
                                                                    ?.url
                                                            }
                                                        />
                                                        <EiPlay className=" absolute self-center text-white text-9xl " />
                                                    </div>
                                                }
                                                playing={playVideo}
                                                light={true}
                                                onClickPreview={() => {
                                                    setPlayVideo(true);
                                                }}
                                                width="100%"
                                                height="100%"
                                                controls={true}
                                            />
                                        </div>
                                    ) : (
                                        <div className="relative w-full lg:w-[45%] lg:sticky lg:top-0 h-full">
                                            <img
                                                src={previewItem}
                                                className={`lg:sticky lg:top-0 w-full h-full object-contain object-top `}
                                            />
                                            {clientWebview && (
                                                <div
                                                    onClick={() =>
                                                        history.goBack()
                                                    }
                                                    className="absolute  flex justify-center items-center top-2 left-2 rounded-full h-10 w-10 bg-[#ffffffb3]"
                                                >
                                                    <BsArrowLeft className="text-black w-6 h-full" />
                                                </div>
                                            )}
                                            {/* {showShare && deal.shareable && (
                                            <div
                                                onClick={handleShareClick}
                                                className="absolute lg:hidden flex justify-center items-center top-2 right-2 rounded-full h-10 w-10 bg-[#00000066]"
                                            >
                                                <EnShareAlternative className="h-5 w-5 text-white" />
                                            </div>
                                        )} */}
                                        </div>
                                    )}

                                    {(deal?.displayImage?.url &&
                                        deal?.videoUrl) ||
                                    deal?.additionalBannerImages?.length > 0 ? (
                                        <div
                                            id="DealsDetail"
                                            className="flex flex-row lg:hidden mt-2 overflow-x-scroll max-w-[100%]"
                                        >
                                            {deal?.videoUrl && (
                                                <div className="flex relative w-full justify-center">
                                                    <DealGalleryItem
                                                        imageUrl={
                                                            deal?.displayImage
                                                                ?.url
                                                        }
                                                        videoUrl={
                                                            deal?.videoUrl
                                                        }
                                                        previewItem={
                                                            previewItem
                                                        }
                                                        setPreviewItem={
                                                            setPreviewItem
                                                        }
                                                    />
                                                    <MdPlayCircleOutline className=" absolute self-center text-white text-4xl " />
                                                </div>
                                            )}
                                            {deal?.displayImage?.url && (
                                                <DealGalleryItem
                                                    imageUrl={
                                                        deal?.displayImage?.url
                                                    }
                                                    previewItem={previewItem}
                                                    setPreviewItem={
                                                        setPreviewItem
                                                    }
                                                />
                                            )}

                                            {deal?.additionalBannerImages
                                                ?.length > 0 &&
                                                deal?.additionalBannerImages?.map(
                                                    (d) => (
                                                        <DealGalleryItem
                                                            imageUrl={d?.url}
                                                            previewItem={
                                                                previewItem
                                                            }
                                                            setPreviewItem={
                                                                setPreviewItem
                                                            }
                                                        />
                                                    )
                                                )}
                                        </div>
                                    ) : null}

                                    <div className="w-[93%] mx-auto lg:w-[50%] lg:pl-7 h-full ">
                                        <div className="flex flex-col ">
                                            <div className="flex items-center justify-between mt-3 lg:mt-0">
                                                <p className="text-primary uppercase font-bold text-sm xl:text-base ">
                                                    {deal?.vendor?.name}
                                                </p>
                                                {/* Share deal section */}
                                                {showShare &&
                                                    deal.shareable && (
                                                        <div className="flex ml-2">
                                                            <button
                                                                className=""
                                                                title="Click to share"
                                                                onClick={
                                                                    handleShareClick
                                                                }
                                                            >
                                                                <div className="flex flex-row items-center gap-1 sm:gap-2">
                                                                    <EnShareAlternative className="h-4 w-4 md:h-5 md:w-5" />
                                                                    <p className="z-10 text-md font-semibold capitalize  font-sans cursor-pointer">
                                                                        Share
                                                                    </p>
                                                                </div>
                                                            </button>{' '}
                                                        </div>
                                                    )}
                                            </div>

                                            <div className=" flex flex-col justify-center ">
                                                <h1
                                                    className={`text-black medium text-2xl xl:text-3xl mt-1 lg:mt-3 font-semibold border-b-2 pb-3 `}
                                                >
                                                    {deal?.title}
                                                </h1>
                                                <div className="flex mt-5 lg:mt-6 items-center">
                                                    <AiFillCalendar />
                                                    <p
                                                        className={`ml-2 font-medium capitalize text-sm xl:text-base tracking-wide `}
                                                    >
                                                        Valid till:{' '}
                                                        <span className="text-primary font-medium">
                                                            {renderDate(
                                                                deal?.endDate,
                                                                false
                                                            )}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* {deal?.description &&
                                    isHTML(deal?.description) && (
                                        <div
                                            style={{ whiteSpace: 'pre-line' }}
                                            className={`
                                ${
                                    config?.deals.dealsSection
                                        .subAdditionalTextClass
                                        ? config?.deals.dealsSection
                                              .subAdditionalTextClass
                                        : ''
                                } z-10 mt-4 lg:mt-5 xl:mt-4 2xl:pr-10 text-sm xl:text-base`}
                                            dangerouslySetInnerHTML={{
                                                __html: deal?.description
                                                    .split(' ')
                                                    .slice(0, 20)
                                                    .join(' ')
                                            }}
                                        />
                                    )}
                                {deal?.description &&
                                    !isHTML(deal?.description) && (
                                        <div
                                            style={{ whiteSpace: 'pre-line' }}
                                            className={`
                                ${
                                    config?.deals.dealsSection
                                        .subAdditionalTextClass
                                        ? config?.deals.dealsSection
                                              .subAdditionalTextClass
                                        : ''
                                } z-10 mt-4 lg:mt-5 xl:mt-4 2xl:pr-10 text-sm xl:text-base `}
                                        >
                                            {LinkParagraph(
                                                deal?.description
                                                    .split(' ')
                                                    .slice(0, 20)
                                                    .join(' ')
                                            )}
                                        </div>
                                    )} */}
                                        {
                                            // <div className="z-10 flex flex-col lg:flex-row mt-4 w-full">
                                            <div
                                                className={`flex items-center border-b-2 border-gray-200 lg:border-0  lg:pb-0 w-full ${
                                                    internalWebview
                                                        ? ' mt-2 md:mt-3  pb-3'
                                                        : 'mt-4 pb-6'
                                                }`}
                                            >
                                                {deal.redemptionType ===
                                                    'CONTACT' && (
                                                    <a
                                                        href={`tel:${deal?.vendor?.contactNumber}`}
                                                    >
                                                        <RedeemAnimation
                                                            content={
                                                                deal.ctaText
                                                            }
                                                            onClick={() => {
                                                                redeemNowClickedHandler(
                                                                    deal.url,
                                                                    deal.id,
                                                                    deal?.redemptionType
                                                                );
                                                            }}
                                                            disabled={
                                                                !termsCheckbox ||
                                                                rememptionLoading
                                                            }
                                                        />
                                                    </a>
                                                )}
                                                <div className="flex flex-col lg:flex-row w-full">
                                                    {!copiedText &&
                                                        deal.redemptionType ===
                                                            'CODE' &&
                                                        deal?.dealRedemptionCode
                                                            ?.dealCode && (
                                                            <div
                                                                onClick={() =>
                                                                    handleCopyCode()
                                                                }
                                                                title="Click to copy "
                                                                className="min-w-[35%] py-2 lg:py-1 flex justify-between items-center cursor-pointer rounded mr-2 md:mr-5 border-2 border-gray-300 border-dashed px-2 mb-4 lg:mb-0 "
                                                            >
                                                                <p
                                                                    className={`${
                                                                        config
                                                                            ?.deals
                                                                            .dealLayout
                                                                            .couponClassName
                                                                            ? config
                                                                                  ?.deals
                                                                                  .dealLayout
                                                                                  .couponClassName
                                                                            : ''
                                                                    } z-10 font-semibold  ${
                                                                        config?.darktheme
                                                                            ? 'hover:border-[#68BBE3]'
                                                                            : 'hover:border-blue-900'
                                                                    }`}
                                                                >
                                                                    {'' +
                                                                        deal
                                                                            ?.dealRedemptionCode
                                                                            ?.dealCode}
                                                                </p>
                                                                {copySvg}
                                                            </div>
                                                        )}

                                                    {copiedText &&
                                                        deal.redemptionType ===
                                                            'CODE' && (
                                                            <p
                                                                className={`z-10 self-center font-normal animate-pulse ${
                                                                    config?.darktheme
                                                                        ? 'bg-[#68BBE3B3]  text-white'
                                                                        : 'bg-[#154F9D1A] text-[#006EB7]'
                                                                }   py-2 px-12 text-sm rounded `}
                                                            >
                                                                Code Copied
                                                            </p>
                                                        )}
                                                    <div></div>
                                                    {!copiedText &&
                                                        deal.redemptionType !==
                                                            'CONTACT' && (
                                                            <div
                                                                className={`${
                                                                    internalWebview
                                                                        ? 'fixed bottom-0 left-0 w-full px-3 py-2 bg-white'
                                                                        : ''
                                                                } `}
                                                                style={
                                                                    internalWebview
                                                                        ? {
                                                                              zIndex: 1000,
                                                                              position:
                                                                                  'fixed',
                                                                              bottom: '0'
                                                                          }
                                                                        : {}
                                                                }
                                                            >
                                                                <div
                                                                    className={`${
                                                                        internalWebview
                                                                            ? ' flex justify-center'
                                                                            : ''
                                                                    } `}
                                                                    //  style={internalWebview ? { zIndex: 1000 ,  position: 'fixed', bottom: '0'  } : {}}
                                                                >
                                                                    <RedeemAnimation
                                                                        content={
                                                                            deal.ctaText
                                                                        }
                                                                        onClick={() => {
                                                                            redeemNowClickedHandler(
                                                                                deal.url,
                                                                                deal.id,
                                                                                deal?.redemptionType
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            !termsCheckbox ||
                                                                            rememptionLoading
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    {/* Share deal modal */}
                                                    {showShareModal && (
                                                        <Modal
                                                            visible={
                                                                showShareModal
                                                            }
                                                            showButton
                                                            showCloseButton={
                                                                false
                                                            }
                                                            onClick={() =>
                                                                setShowShareModal(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            <div className="flex flex-col px-5 ">
                                                                {
                                                                    <>
                                                                        <div className="flex justify-between items-center my-4 ">
                                                                            <h1 className=" text-lg sm:text-xl font-bold ">
                                                                                Share
                                                                                this
                                                                                Deal
                                                                            </h1>
                                                                            <EiCloseO
                                                                                onClick={() =>
                                                                                    setShowShareModal(
                                                                                        false
                                                                                    )
                                                                                }
                                                                                className={` h-7 w-7 sm:h-9 sm:w-10 cursor-pointer   text-gray-500`}
                                                                            />
                                                                        </div>

                                                                        <svg viewBox="0 0 457 2">
                                                                            <defs></defs>
                                                                            <g
                                                                                id="Layer_2"
                                                                                data-name="Layer 2"
                                                                            >
                                                                                <g
                                                                                    id="Layer_1-2"
                                                                                    data-name="Layer 1"
                                                                                >
                                                                                    <polygon
                                                                                        class="cls-1"
                                                                                        points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1"
                                                                                        fill="#e5e5e5"
                                                                                    />
                                                                                </g>
                                                                            </g>
                                                                        </svg>

                                                                        <div className="flex gap-5 items-center my-5">
                                                                            <img
                                                                                variant="square"
                                                                                loading="lazy"
                                                                                className={`${
                                                                                    config
                                                                                        ?.projects
                                                                                        .moduleConfig
                                                                                        ?.QRCodeImage
                                                                                        ? 'h-10 w-10'
                                                                                        : 'h-20 w-28'
                                                                                } z-10  object-center object-cover rounded-lg`}
                                                                                src={
                                                                                    deal
                                                                                        .vendor
                                                                                        ?.logo
                                                                                        ?.url
                                                                                }
                                                                                alt="property"
                                                                            />
                                                                            <div className="flex flex-col ">
                                                                                <p className="text-black font-semibold text-base sm:text-lg">
                                                                                    {
                                                                                        deal?.title
                                                                                    }
                                                                                </p>
                                                                                <p className="text-[#908F95] text-sm sm:text-base italic">
                                                                                    {' '}
                                                                                    Valid
                                                                                    upto
                                                                                    :{' '}
                                                                                    {renderDate(
                                                                                        deal?.endDate
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <svg viewBox="0 0 457 2">
                                                                            <defs></defs>
                                                                            <g
                                                                                id="Layer_2"
                                                                                data-name="Layer 2"
                                                                            >
                                                                                <g
                                                                                    id="Layer_1-2"
                                                                                    data-name="Layer 1"
                                                                                >
                                                                                    <polygon
                                                                                        class="cls-1"
                                                                                        points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1"
                                                                                        fill="#e5e5e5"
                                                                                    />
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <div className="flex flex-wrap justify-between items-center mt-5 ">
                                                                            <div
                                                                                className={` w-[90%] lg:w-[45%]  md:w-[45%]  cursor-pointer  flex gap-2  border border-gray-400 rounded-[10px] p-3 my-2`}
                                                                            >
                                                                                <button
                                                                                    title={
                                                                                        copiedUrl
                                                                                            ? 'Link copied'
                                                                                            : 'Click to copy'
                                                                                    }
                                                                                    className=""
                                                                                    onClick={() =>
                                                                                        copyDealHandler()
                                                                                    }
                                                                                >
                                                                                    {!copiedUrl && (
                                                                                        <div className="flex items-center gap-3">
                                                                                            <FiCopy className="cursor-pointer w-5 h-full" />
                                                                                            <p className="font-bold text-sm sm:text-base">
                                                                                                Link
                                                                                                Copy
                                                                                            </p>
                                                                                        </div>
                                                                                    )}
                                                                                    {copiedUrl && (
                                                                                        <p className="mx-auto px-6 self-center  rounded-lg text-center font-normal animate-pulse bg-white text-xs sm:text-sm   text-[#006EB7] ">
                                                                                            Link
                                                                                            Copied
                                                                                        </p>
                                                                                    )}
                                                                                </button>
                                                                            </div>
                                                                            <div
                                                                                className={` w-[90%] lg:w-[45%] md:w-[45%]  cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}
                                                                            >
                                                                                <EmailShareButton
                                                                                    url={
                                                                                        userRole ===
                                                                                        'CUSTOMER'
                                                                                            ? shareLink
                                                                                            : window
                                                                                                  .location
                                                                                                  .href
                                                                                    }
                                                                                    body={
                                                                                        deal.shareMessage
                                                                                            ? deal.shareMessage
                                                                                            : "Hello! Hope you're doing well. I wanted to share this exciting deal I found recently. Here's the link: 🏘️ Click to learn more!"
                                                                                    }
                                                                                    subject={
                                                                                        deal.shareMessage
                                                                                            ? deal.shareMessage
                                                                                            : "Hello! I wanted to share this exciting deal I found recently. Here's the link: 🏘️ Click to learn more!"
                                                                                    }
                                                                                >
                                                                                    <div className="flex items-center gap-3">
                                                                                        <MdcEmailOutline className="w-5 h-full" />
                                                                                        <p className="  font-bold text-sm sm:text-base">
                                                                                            Email
                                                                                        </p>
                                                                                    </div>
                                                                                </EmailShareButton>
                                                                            </div>
                                                                            <div
                                                                                className={` w-[90%] lg:w-[45%]  md:w-[45%] cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}
                                                                            >
                                                                                <WhatsappShareButton
                                                                                    url={
                                                                                        userRole ===
                                                                                        'CUSTOMER'
                                                                                            ? shareLink
                                                                                            : window
                                                                                                  .location
                                                                                                  .href
                                                                                    }
                                                                                    title={
                                                                                        deal.shareMessage
                                                                                            ? deal.shareMessage
                                                                                            : "Hello! Hope you're doing well. I wanted to share this exciting deal I found recently. Here's the link: 🏘️ Click to learn more!"
                                                                                    }
                                                                                >
                                                                                    <div className="flex items-center gap-3">
                                                                                        <SiWhatsapp className="w-5 h-full" />

                                                                                        <p className="  font-bold text-sm sm:text-base ">
                                                                                            WhatsApp
                                                                                        </p>
                                                                                    </div>
                                                                                </WhatsappShareButton>
                                                                            </div>
                                                                            <div
                                                                                className={` w-[90%] lg:w-[45%]  md:w-[45%] cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}
                                                                            >
                                                                                <FacebookShareButton
                                                                                    url={
                                                                                        userRole ===
                                                                                        'CUSTOMER'
                                                                                            ? shareLink
                                                                                            : window
                                                                                                  .location
                                                                                                  .href
                                                                                    }
                                                                                    title={
                                                                                        deal.shareMessage
                                                                                            ? deal.shareMessage
                                                                                            : "Hey friends! Just stumbled upon this incredible deal that I couldn't wait to share with you all. Take a look: 🌟 Click on the below link to explore further!"
                                                                                    }
                                                                                >
                                                                                    <div className="flex items-center gap-3">
                                                                                        <FaFacebookF className="w-5 h-full" />
                                                                                        <p className="font-bold text-sm sm:text-base ">
                                                                                            Facebook
                                                                                        </p>
                                                                                    </div>
                                                                                </FacebookShareButton>
                                                                            </div>
                                                                            <div
                                                                                className={` w-[90%] lg:w-[45%]  md:w-[45%]  cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}
                                                                            >
                                                                                <TwitterShareButton
                                                                                    url={
                                                                                        userRole ===
                                                                                        'CUSTOMER'
                                                                                            ? shareLink
                                                                                            : window
                                                                                                  .location
                                                                                                  .href
                                                                                    }
                                                                                    title={
                                                                                        deal.shareMessage
                                                                                            ? deal.shareMessage
                                                                                            : "Exciting news! Discovered a fantastic deal worth checking out. Here's the link: 🏢 Click to learn more!"
                                                                                    }
                                                                                >
                                                                                    <div className="flex items-center gap-3">
                                                                                        <svg
                                                                                            size={
                                                                                                20
                                                                                            }
                                                                                            className="w-5 text-black"
                                                                                            stroke="currentColor"
                                                                                            fill="currentColor"
                                                                                            stroke-width="0"
                                                                                            viewBox="0 0 24 24"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path d="M8 2H1L9.26086 13.0145L1.44995 21.9999H4.09998L10.4883 14.651L16 22H23L14.3917 10.5223L21.8001 2H19.1501L13.1643 8.88578L8 2ZM17 20L5 4H7L19 20H17Z"></path>
                                                                                        </svg>
                                                                                        <button className="  font-bold text-sm sm:text-base">
                                                                                            Twitter
                                                                                        </button>
                                                                                    </div>
                                                                                </TwitterShareButton>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </Modal>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        <div className="hidden lg:block w-[93%] lg:w-full mx-auto mt-4  ">
                                            {/* <h1 className="font-bold text-xl">Description</h1> */}
                                            {deal?.description &&
                                                isHTML(deal?.description) && (
                                                    <div
                                                        style={{
                                                            whiteSpace:
                                                                'pre-line'
                                                        }}
                                                        className={`
                                ${
                                    config?.deals.dealsSection
                                        .subAdditionalTextClass
                                        ? config?.deals.dealsSection
                                              .subAdditionalTextClass
                                        : ''
                                } z-10 2xl:pr-10 text-sm xl:text-base `}
                                                        dangerouslySetInnerHTML={{
                                                            __html: showFullDescription
                                                                ? deal?.description
                                                                : deal?.description
                                                                      .split(
                                                                          ' '
                                                                      )
                                                                      .slice(
                                                                          0,
                                                                          50
                                                                      )
                                                                      .join(' ')
                                                        }}
                                                    />
                                                )}
                                            {deal?.description &&
                                                !isHTML(deal?.description) && (
                                                    <div
                                                        style={{
                                                            whiteSpace:
                                                                'pre-line'
                                                        }}
                                                        className={`
                                ${
                                    config?.deals.dealsSection
                                        .subAdditionalTextClass
                                        ? config?.deals.dealsSection
                                              .subAdditionalTextClass
                                        : ''
                                } z-10 lg:mt-5 xl:mt-4 2xl:pr-10  `}
                                                    >
                                                        {LinkParagraph(
                                                            showFullDescription
                                                                ? deal.description
                                                                : deal?.description
                                                                      .split(
                                                                          ' '
                                                                      )
                                                                      .slice(
                                                                          0,
                                                                          50
                                                                      )
                                                                      .join(' ')
                                                        )}
                                                    </div>
                                                )}
                                            {deal?.description?.split(' ')
                                                .length > 50 && (
                                                <button
                                                    onClick={toggleDescription}
                                                    className="text-primary font-bold block"
                                                >
                                                    {showFullDescription
                                                        ? 'Read less'
                                                        : 'Read more'}
                                                </button>
                                            )}
                                            {deal?.termsAndConditions && (
                                                <div
                                                    className={
                                                        'mt-5 flex text-accent-content mb-3 gap-2'
                                                    }
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={termsCheckbox}
                                                        onChange={
                                                            handleOnChange
                                                        }
                                                        className="checkbox-sm bg-white rounded-md border-gray-100 checked:bg-blue-600"
                                                        autoComplete="off"
                                                    />
                                                    <p
                                                        className={`${
                                                            config?.deals
                                                                .dealLayout
                                                                .termsAndConditionsClassName
                                                                ? config?.deals
                                                                      .dealLayout
                                                                      .termsAndConditionsClassName
                                                                : ' text-accent-content mx-auto text-[12px] break-words leading-4'
                                                        }`}
                                                    >
                                                        I agree to the{' '}
                                                        <button
                                                            onClick={handleTnC}
                                                            // onClick={() =>
                                                            //     setShowTerms(
                                                            //         true
                                                            //     )
                                                            // }
                                                            className="text-primary font-bold underline "
                                                        >
                                                            Terms & Conditions
                                                        </button>
                                                        {/* <Modal
                                            className="w-[95%] lg:w-[80%]"
                                            visible={showTerms}
                                            onClick={() => setShowTerms(false)}
                                        >
                                            <div
                                                className={` text-[#373737] text-sm lg:text-base font-medium break-words leading-[1.4] p-2 lg:p-10 overflow-y-scroll h-[90vh] lg:h-auto `}
                                            >
                                                <p>
                                                    By clicking on{' '}
                                                    {deal.ctaText} , you agree &
                                                    authorize Reloy and $
                                                    {isBundleDeal
                                                        ? 'all partner brands part of this bundle deal'
                                                        : 'partner brands'}{' '}
                                                    to call you or contact you
                                                    via various communication
                                                    modes to assist you with
                                                    your queries. This consent
                                                    will override your DNC/NDNC
                                                    registration if any.
                                                </p>
                                                <div
                                                    className="mt-5"
                                                    dangerouslySetInnerHTML={
                                                        isHTML(
                                                            deal.termsAndConditions
                                                        )
                                                            ? {
                                                                  __html: deal.termsAndConditions
                                                              }
                                                            : {
                                                                  __html: `<p>${deal.termsAndConditions}</p>`
                                                              }
                                                    }
                                                ></div>
                                            </div>
                                        </Modal> */}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        {/* Deal Description */}
                        <div
                            className={`w-[93%] lg:hidden mx-auto  ${
                                internalWebview ? 'py-4' : 'py-5'
                            }`}
                        >
                            {/* <h1 className="font-bold text-xl">Description</h1> */}
                            {deal?.description && isHTML(deal?.description) && (
                                <div
                                    style={{ whiteSpace: 'pre-line' }}
                                    className={`
                                ${
                                    config?.deals.dealsSection
                                        .subAdditionalTextClass
                                        ? config?.deals.dealsSection
                                              .subAdditionalTextClass
                                        : ''
                                } z-10 mt-4 lg:mt-2 xl:mt-2 2xl:pr-10 text-sm xl:text-base `}
                                    dangerouslySetInnerHTML={{
                                        __html: showFullDescription
                                            ? deal?.description
                                            : deal?.description
                                                  .split(' ')
                                                  .slice(0, 50)
                                                  .join(' ')
                                    }}
                                />
                            )}
                            {deal?.description &&
                                !isHTML(deal?.description) && (
                                    <div
                                        style={{ whiteSpace: 'pre-line' }}
                                        className={`
                                ${
                                    config?.deals.dealsSection
                                        .subAdditionalTextClass
                                        ? config?.deals.dealsSection
                                              .subAdditionalTextClass
                                        : ''
                                } z-10 lg:mt-5 xl:mt-4 2xl:pr-10  `}
                                    >
                                        {LinkParagraph(
                                            showFullDescription
                                                ? deal.description
                                                : deal?.description
                                                      .split(' ')
                                                      .slice(0, 50)
                                                      .join(' ')
                                        )}
                                    </div>
                                )}

                            {deal?.description?.split(' ').length > 50 && (
                                <button
                                    onClick={toggleDescription}
                                    className="text-primary font-bold block"
                                >
                                    {showFullDescription
                                        ? 'Read less'
                                        : 'Read more'}
                                </button>
                            )}

                            {deal?.termsAndConditions && (
                                <div
                                    className={
                                        'mt-5 flex text-accent-content mb-3 gap-2'
                                    }
                                >
                                    <input
                                        type="checkbox"
                                        checked={termsCheckbox}
                                        onChange={handleOnChange}
                                        className="checkbox-sm bg-white rounded-md border-gray-100 checked:bg-blue-600"
                                        autoComplete="off"
                                    />
                                    <p
                                        className={`${
                                            config?.deals.dealLayout
                                                .termsAndConditionsClassName
                                                ? config?.deals.dealLayout
                                                      .termsAndConditionsClassName
                                                : ' text-accent-content mx-auto text-[12px] break-words leading-4'
                                        }`}
                                    >
                                        I agree to the{' '}
                                        <button
                                            onClick={() => setShowTerms(true)}
                                            className="text-primary font-bold underline "
                                        >
                                            Terms & Conditions
                                        </button>
                                        <Modal
                                            className="w-[95%] lg:w-[80%]"
                                            visible={showTerms}
                                            onClick={() => setShowTerms(false)}
                                        >
                                            <div
                                                className={` text-[#373737] text-sm lg:text-base font-medium break-words leading-[1.4] p-4 lg:p-10 overflow-y-scroll pb-6 max-h-[80vh]`}
                                            >
                                                <div
                                                    className={`flex justify-between mb-6`}
                                                >
                                                    <h1
                                                        className={`self-center text-lg md:text-2xl font-semibold `}
                                                    >
                                                        Terms and Conditions
                                                    </h1>
                                                    <GrClose
                                                        onClick={() =>
                                                            setShowTerms(false)
                                                        }
                                                        className={`self-center h-4 w-4 cursor-pointer `}
                                                    />
                                                </div>
                                                <p>
                                                    By clicking on{' '}
                                                    {deal.ctaText} , you agree &
                                                    authorize Reloy and{' '}
                                                    {isBundleDeal
                                                        ? 'all partner brands part of this bundle deal'
                                                        : 'partner brands'}{' '}
                                                    to call you or contact you
                                                    via various communication
                                                    modes to assist you with
                                                    your queries. This consent
                                                    will override your DNC/NDNC
                                                    registration if any.
                                                </p>
                                                <div
                                                    className="mt-5"
                                                    dangerouslySetInnerHTML={
                                                        isHTML(
                                                            deal.termsAndConditions
                                                        )
                                                            ? {
                                                                  __html: deal.termsAndConditions
                                                              }
                                                            : {
                                                                  __html: `<p>${deal.termsAndConditions}</p>`
                                                              }
                                                    }
                                                ></div>
                                            </div>
                                        </Modal>
                                    </p>
                                </div>
                            )}
                            {!!deal?.eligibleCashback ? <DealFaq /> : <></>}
                        </div>
                        <div className="w-[93%] lg:w-full mx-auto py-5">
                            {similarOffers.length > 0 && (
                                <DealSection
                                    type={
                                        isBundleDeal
                                            ? 'OTHER DEALS IN THIS BUNDLE'
                                            : 'SIMILAR OFFERS'
                                    }
                                    offers={similarOffers.slice(0, 4)}
                                    // className="mt-16"
                                />
                            )}
                            {sameBrandOffers.length > 0 && (
                                <DealSection
                                    type="OTHER OFFERS FROM THIS BRAND"
                                    offers={sameBrandOffers.slice(0, 4)}
                                    className="mt-16"
                                />
                            )}
                            {topOffers.length > 0 && (
                                <DealSection
                                    type="OFFERS OF THE MONTH"
                                    offers={topOffers.slice(0, 4)}
                                    className="mt-16"
                                />
                            )}
                        </div>
                    </div>
                )}
            </section>
            <Modal visible={showModal} onClick={() => setShowModal(false)}>
                <div className="flex justify-end px-4 ">
                    <GrClose
                        onClick={() => setShowModal(false)}
                        className={` h-5 w-5 cursor-pointer `}
                    />
                </div>
                {captureUserMobile && (
                    <PhoneNumber
                        redeemNowClickedHandler={(mobile) =>
                            redeemNowClickedHandler({
                                url: deal.url,
                                id: deal.id,
                                redemptionType: deal?.redemptionType,
                                mobile
                            })
                        }
                        setCaptureUserMobile={setCaptureUserMobile}
                    />
                )}
                {!captureUserMobile && (
                    <div className="relative py-6 flex flex-col">
                        <div className="  h-auto rounded flex flex-col justify-center items-center animate-pulse">
                            {!modalMessage &&
                                deal?.redemptionType !== 'VOUCHER' && (
                                    <div className=" rounded-full bg-green-500 p-2 md:p-4">
                                        <TiTick className=" text-white h-7 w-7 md:h-8 md:w-8" />
                                    </div>
                                )}
                        </div>
                        <p
                            className={`
                        ${
                            config?.deals.dealsSection.subAdditionalTextClass
                                ? config?.deals.dealsSection
                                      .subAdditionalTextClass
                                : ''
                        } z-10 mt-2 mx-auto w-[90%] lg:w-[80%] text-center font-semibold `}
                        >
                            {isBundleDeal ? (
                                <p>
                                    Thank you for redeeming the exciting offers
                                    part of this Housewarming Kit. You will be
                                    communicated the steps to redeem shortly.{' '}
                                </p>
                            ) : deal?.redemptionType === 'VOUCHER' ? (
                                voucherCode && !modalMessage ? (
                                    <div className=" flex flex-col justify-center items-center">
                                        <img
                                            className=" h-[84px] w-[84px] -mb-3"
                                            src="https://htmlemailer.s3.ap-south-1.amazonaws.com/html-media/godrej/Gift-box-new.gif"
                                        />
                                        <p className="font-semibold text-lg">
                                            Congratulations!
                                        </p>
                                        <p> Your voucher is redeemed 🎉</p>
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-center items-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-12 h-auto p-0 m-0"
                                            viewBox="0 0 81.452 95.027"
                                        >
                                            <g
                                                id="_299112_warning_shield_icon"
                                                data-name="299112_warning_shield_icon"
                                                transform="translate(-3 -1030.4)"
                                            >
                                                <path
                                                    id="Path_171"
                                                    data-name="Path 171"
                                                    d="M3,1031.4v45.251c0,19,16.436,36.2,40.726,45.251,24.291-9.05,40.726-26.246,40.726-45.251V1031.4Z"
                                                    transform="translate(0 3.525)"
                                                    fill="#95a5a6"
                                                />
                                                <path
                                                    id="Path_172"
                                                    data-name="Path 172"
                                                    d="M3,1030.4v45.25c0,19.005,16.436,36.2,40.726,45.251,24.291-9.05,40.726-26.246,40.726-45.251V1030.4Z"
                                                    transform="translate(0 0)"
                                                    fill="#ecf0f1"
                                                />
                                                <path
                                                    id="Path_173"
                                                    data-name="Path 173"
                                                    d="M3,1030.4v45.25c0,19.005,16.436,36.2,40.726,45.251v-90.5Z"
                                                    transform="translate(0 0)"
                                                    fill="#bdc3c7"
                                                />
                                                <path
                                                    id="Path_174"
                                                    data-name="Path 174"
                                                    d="M5,1032.4v36.2c0,15.386,12.784,28.961,31.676,36.2,18.892-7.24,31.676-20.815,31.676-36.2v-36.2Z"
                                                    transform="translate(7.05 7.051)"
                                                    fill="#f39c12"
                                                />
                                                <path
                                                    id="Path_175"
                                                    data-name="Path 175"
                                                    d="M12,1032.4v72.4c18.892-7.24,31.676-20.815,31.676-36.2v-36.2Z"
                                                    transform="translate(31.726 7.051)"
                                                    fill="#f1c40f"
                                                />
                                                <path
                                                    id="Path_176"
                                                    data-name="Path 176"
                                                    d="M15.525,1034.4A4.394,4.394,0,0,0,11,1038.925l2.263,31.675h4.525l2.263-31.675A4.394,4.394,0,0,0,15.525,1034.4Zm0,40.726A4.394,4.394,0,0,0,11,1079.651a4.685,4.685,0,0,0,4.525,4.525,4.685,4.685,0,0,0,4.525-4.525A4.394,4.394,0,0,0,15.525,1075.126Z"
                                                    transform="translate(28.201 14.1)"
                                                    fill="#34495e"
                                                />
                                            </g>
                                        </svg>
                                        <p className="mt-2">{modalMessage}</p>
                                    </div>
                                )
                            ) : modalMessage ? (
                                modalMessage
                            ) : (
                                'Thank you for submitting your enquiry! We have received\nyour information and will be in touch with you shortly.'
                            )}
                        </p>
                        {deal?.redemptionType === 'VOUCHER' && voucherCode && (
                            <div className="flex my-4 justify-center items-center">
                                {!copiedText && (
                                    <p
                                        className={`${
                                            config?.deals.dealLayout
                                                .couponClassName
                                                ? config?.deals.dealLayout
                                                      .couponClassName
                                                : ''
                                        } z-10 border-2 font-semibold border-gray-300 border-dashed py-1 px-3 md:px-7 ${
                                            config?.darktheme
                                                ? 'hover:border-[#68BBE3]'
                                                : 'hover:border-blue-900'
                                        }  cursor-pointer rounded`}
                                        title="Click to copy "
                                        onClick={() => handleCopyCode()}
                                    >
                                        {'CODE: ' + voucherCode}
                                    </p>
                                )}
                                {copiedText && (
                                    <p
                                        className={`z-10 self-center font-normal animate-pulse ${
                                            config?.darktheme
                                                ? 'bg-[#68BBE3B3]  text-white'
                                                : 'bg-[#154F9D1A] text-[#006EB7]'
                                        }   py-2 px-12 text-sm rounded `}
                                    >
                                        Code Copied
                                    </p>
                                )}
                            </div>
                        )}
                        {deal?.redemptionType === 'VOUCHER' && deal?.url ? (
                            voucherCode && (
                                <button
                                    className={
                                        'bg-primary mx-auto px-5 py-1.5 text-white text-center rounded self-center mt-2'
                                    }
                                    onClick={() => window.open(deal?.url)}
                                >
                                    Go to Offer
                                </button>
                            )
                        ) : isBundleDeal ? (
                            <></>
                        ) : (
                            <button
                                className={
                                    'bg-primary justify-self-center px-5 mt-2 py-1.5 text-white text-center mr-2 rounded self-center'
                                }
                                onClick={() =>
                                    history.push('/myprofile/enquiries')
                                }
                            >
                                Track your Enquiries
                            </button>
                        )}
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default DealDetails;
