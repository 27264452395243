import { useEffect, useState } from 'react';
import { winnreAuth } from '../../../../../utils/axios';
import { LoadingSpinner } from '../../../../../Components/Molecules/LoadingUI';
import AiOutlineExclamationCircle from '@meronex/icons/ai/AiOutlineExclamationCircle';

const terms = [
    {
        id: 0,
        title: (
            <label for={0} className={` ml-2 text-sm flex-1`}>
                I have not represented anything that is contrary to the
                project's official RERA details.
            </label>
        )
    },
    {
        id: 1,
        title: (
            <label for={1} className={` ml-2 text-sm flex-1`}>
                I understand that 10% TDS will be deducted at the time of payout
                which will be available in my 26AS.
            </label>
        )
    },
    {
        id: 2,
        title: (
            <label for={2} className={` ml-2 text-sm flex-1`}>
                {' '}
                I agree to the{' '}
                <a
                    className="text-[#006EB7] underline"
                    href={`${window.reloy_env.PUBLIC_URL}/terms-and-conditions`}
                >
                    Terms & Conditions
                </a>{' '}
                and{' '}
                <a
                    className="text-[#006EB7] underline"
                    href="https://reloy.co/privacy-policy/"
                >
                    Privacy Policy
                </a>{' '}
                laid out by Loyalie IT Solutions Pvt Ltd.
            </label>
        )
    },
    {
        id: 3,
        title: (
            <label for={3} className={` ml-2 text-sm flex-1`}>
                I am not liable to pay GST in the account where I am receiving
                funds.
            </label>
        )
    }
];
const FirstStep = ({
    loading,
    checkKycVerificationStatus,
    error,
    setOpenUploadBox
}) => {
    const [checkedState, setCheckedState] = useState([
        false,
        false,
        false,
        false
    ]);
    const [showNoGSTDisclaimer, setNoGSTDisclaimer] = useState(false);
    const allChecked = checkedState.every((item, i) =>
        i === 3 ? true : item === true
    );
    const noGST = !checkedState[3];

    useEffect(() => {
        return setCheckedState(
            checkedState.map((item) => {
                return false;
            })
        );
    }, []);

    const acceptTerms = () => {
        setCheckedState(
            checkedState.map((item) => {
                return true;
            })
        );
    };
    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    };

    const onContinue = () => {
        if (showNoGSTDisclaimer) {
            setOpenUploadBox(false);
        } else if (noGST) {
            setNoGSTDisclaimer(true);
        } else {
            checkKycVerificationStatus();
        }
    };

    return (
        <div className="px-3 md:px-8 py-4">
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <div className=" flex flex-col justify-center items-center text-center">
                    <div className=" rounded-full bg-red-500 p-2 md:p-4 ">
                        <AiOutlineExclamationCircle className=" text-white h-7 w-7 md:h-8 md:w-8" />
                    </div>
                    <p className="leading-5 mt-3">{error}</p>
                </div>
            ) : (
                <>
                    {showNoGSTDisclaimer ? (
                        <div>
                            Email the required documents to accounts@reloy.co
                            and we will process the payout accordingly.{' '}
                        </div>
                    ) : (
                        <>
                            {' '}
                            <div
                                className={`flex justify-between pb-0 md:pb-2 `}
                            >
                                <h1 className=" font-Poppins font-bold text-lg ">
                                    Terms & Conditions
                                </h1>
                                <button
                                    onClick={acceptTerms}
                                    className="text-lg text-[#006EB7] underline"
                                >
                                    Select all
                                </button>
                            </div>
                            {terms.map((term) => (
                                <div className="flex my-3">
                                    <input
                                        type="checkbox"
                                        onChange={() => handleOnChange(term.id)}
                                        id={term.id}
                                        value={term.title}
                                        checked={checkedState[term.id]}
                                        className="checkbox-sm bg-white rounded-md border-gray-100 checked:bg-blue-600"
                                        autoComplete="off"
                                    />

                                    {term.title}
                                </div>
                            ))}
                        </>
                    )}

                    <div className="flex pt-8">
                        <button
                            onClick={onContinue}
                            disabled={!allChecked || loading}
                            className="disabled:bg-[#BFBFBF] disabled:cursor-not-allowed bg-primary w-full text-white rounded font-bold px-6 h-8 md:h-10 "
                        >
                            Accept
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};
export default FirstStep;
