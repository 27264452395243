import { getFont } from '../../helpers';
import centuryImg from '../../assets/century1.jpg';
import reloySection from '../../assets/reloysection.png';
import Icon1 from '../../assets/Icons/Icon1.png';
import Icon2 from '../../assets/Icons/Icon2.png';
import Icon3 from '../../assets/Icons/Icon3.png';
import store from '../../store';

const reloyConfig = {
    clientId:
        '43f9ae0bd0e04f52e4d95b93bcf34138dbf6052b78dc2710ff4eb90b3c69f096',
    id: 1,
    name: 'Loyalie',
    fullName: 'Reloy',
    programName:'Reloy',
    theme: 'reloyTheme',
    favicon: 'https://cdn.loyalie.in/66663889.png',
    whatsappChatbot: '9088771616',
    devWhatsappChatbot: '6292315128',
    primaryColor: '#4653a4',
    headingFont: `${getFont('BRAMHA').heading }`,
    bodyFont: `pt-2 ${getFont('BRAMHA').body }`,
    googleConfig: {
        gID: '',
        keywordsContent: '',
        descriptionContent: '',
        authorContent: ''
    },
    facebookPixelConfig: {
        id: '', //PROD
        metaName: '',
        metaContent: ''
    },
    webengageLic: 'in~~71680b12',
    devWebengageLic: 'in~~71680b12',
    uatWebengageLic: 'in~~13410618a',
    webengageAPIKey:'c3857bc4-66f0-4ccc-bd83-a0c434c9bf02',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },
    layouts: {
        events: 'Layout2',
        blogs: 'Layout3',
        
    },
    activateReferPage: true,
    logoLeft: {
        url: 'https://cdn.loyalie.in/27822817.png',
        stickyNavBarLogoClass:
            'relative h-7 lg:h-10 xl:h-12 2xl:h-12 left-4 lg:left-4 xl:left-7 3xl:left-10 top-0', //to match godrej nav bar
        baseNavBarLogoClass:
            'relative h-7 lg:h-10 xl:h-12 2xl:h-12 left-4 lg:left-4 xl:left-7 3xl:left-10 top-0',
        name: 'reloyLogo'
    },

    navBarConfig: {
        showLogin: true,
        font: `${getFont('GODREJ').heading}`,
        height: 'h-20 2xl:h-20 ',
        mobileIconColor: 'text-primary',
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-white', // default: transparent
            containerClass: 'lg:mr-4',
            itemClass:
                'uppercase text-primary hover:text-accent text-[14px] lg:text-[12px] xl:text-[14px] lg:mr-6'
        },
        stickyNavBar: {
            border: true,
            borderClass: 'border-b-[2px] border-secondary-content',
            backgroundColor: 'bg-white', //default: primary-content,
            containerClass: 'lg:mr-4', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase text-primary hover:text-accent text-[14px] lg:text-[12px] xl:text-[14px] lg:mr-6' // Each list element in NavBar (Text color, hover color, size)
        },
        showActiveLinkColor: false,
        footerNavItemClass:
            'font-Montserrat font-semibold text-[14px] lg:text-[15px] uppercase'
    },
    floatingButton: true,
    floaterType: 'CALL',
    mobile: '9513238779',
    floaterColor: 'bg-primary',
    bannerNegativeTopMarginClass: '', //corresponding to  navBarHeightClass
    scrollToTargetAdjustedValue: 70,
    clientButtonClassName:
        'bg-[#85CDB7] text-white rounded-full py-3 px-5 hover:bg-transparent hover:border-2 hover:border-[#85CDB7] hover:text-[#85CDB7]',
    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },
    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,
    routes: {
        home: {
            name: 'HOME',
            path: '/',
            show: true,
            components: [
                {
                    type: 'BANNER',
                    backgroundImage: 'https://cdn.loyalie.in/83953515.jpg',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/83953515.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0' //no gradient
                    },
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Connect With Your Homebuyers And Channel Partners',
                            color: 'white',
                            className: ` text-center text-[20px] md:text-[25px] lg:text-[22px] mt-[85px] tracking-[3px] md:mt-10 lg:mt-24 xl:mt-10 md:w-[70%] mx-auto tracking-widest ${getFont('GODREJ').body
                                }`
                        },

                        {
                            type: 'TEXT',
                            content: 'SELL UP TO ',
                            color: '#ffffff',
                            className: `inline-block mt-1 text-[34px] md:text-[28px] lg:text-[50px] font-bold tracking-wider break-words ${getFont('GODREJ').heading
                                }`
                        },
                        {
                            type: 'TEXT',
                            content: ' 30% ',
                            color: '#85CDB7',
                            className: `ml-4 mr-1 inline-block mt-1 text-[34px] md:text-[28px] lg:text-[56px] font-bold tracking-wider  break-words ${getFont('GODREJ').heading
                                }`
                        },
                        {
                            type: 'TEXT',
                            content: ' MORE',
                            color: '#ffffff',
                            className: `ml-2 inline-block  mt-1 text-[34px] md:text-[28px] lg:text-[50px] font-bold tracking-wider leading-5  break-words ${getFont('GODREJ').heading
                                }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Optimize your homebuyer and channel partner journeys to increase sales!',
                            color: '#ffffff',
                            className: `text-center mt-1 text-[24px] md:text-[28px] lg:text-[18px] xl:text-[20px] mb-5 tracking-wide break-words ${getFont('GODREJ').body
                                }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'CONTACT US NOW',
                            fontSize: '14px',
                            className: `text-center flex justify-center mx-auto bg-[#85CDB7] rounded-full py-3 px-5 hover:bg-transparent hover:border-2 hover:border-[#85CDB7] ${getFont('GODREJ').heading
                                }`,
                            onClick: 'CHANGE_PATH',
                            path: '/contact-us'
                        }
                    ]
                },
                {
                    type: 'IMAGE',
                    url: reloySection,
                    position: 'center',
                    className: 'w-full'
                },

                {
                    type: 'CUSTOM',
                    className: 'text-center w-[100%] bg-[#f2f2f2] pt-10 pb-4',

                    components: [
                        {
                            type: 'TEXT',
                            content: 'We bring to you',

                            className: `tracking-wide font-semibold text-secondary-content bg-[#f2f2f2] text-2xl px-2 md:text-2xl ${getFont('GODREJ').heading
                                }`
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: 'blog',
                    className:
                        'relative grid lg:h-[500px] 2xl:h-[600px] sm:grid-cols-1 lg:grid-cols-2 bg-secondary-content',

                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'lg:pl-6 3xl:pl-20 lg:pr-7 xl:pr-20 m-auto h-auto mb-4 lg:m-auto bg-secondary-content text-left text-primary   ',

                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'Referrals and Earnings',
                                    fontSize: '30px',
                                    className: `tracking-wide uppercase text-primary-focus px-7 py-4 lg:px-7 lg:py-0 ${getFont('GODREJ').heading
                                        }`
                                },
                                {
                                    type: 'DIVIDER',

                                    className:
                                        'mx-7 lg:mx-7 w-[25%] md:w-[16%] lg:w-[80%]   bg-primary-focus border-none h-[2px]',
                                    classNameContainer: 'mt-2'
                                },

                                {
                                    type: 'TEXT',
                                    content:
                                        'We are committed to building wholesome communities that care for one another. As a member of the loyalty program, you will have the opportunity to refer your friends and family and earn rewards up to Rs. XXXX.',

                                    className: `text-[19px] lg:text-[16px] xl:text-[19px] font-normal px-7 py-4 lg:px-7 lg:py-5   3xl:pr-32  text-accent-content  ${getFont('CENTURY').body
                                        }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'You get the following rewards for successfully referring with us:',

                                    className: `text-[19px] lg:text-[16px] xl:text-[19px] px-7 pt-4 lg:px-7   3xl:pr-32 text-accent-content lg:pt-2 ${getFont('CENTURY').body
                                        }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        '~ Reward you receive for successfully referring a friend',

                                    className: ` text-[17px] lg:text-[15px] xl:text-[17px] px-7  lg:px-7  3xl:pr-32  text-accent-content  ${getFont('CENTURY').body
                                        }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        '~ Reward your friend receives when they buy with us',

                                    className: `text-[17px] lg:text-[15px] xl:text-[17px] px-7   3xl:pr-32  lg:px-7 text-accent-content  ${getFont('CENTURY').body
                                        }`
                                }
                            ]
                        },
                        {
                            type: 'CHANGING_IMAGE',
                            position: 'center',
                            imageArr: [
                                {
                                    url: 'https://cdn.loyalie.in/6774823.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/4858221.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/11617410.jpg'
                                }
                            ],
                            className:
                                'h-full lg:h-[500px] 2xl:h-[600px] w-full object-fill ',
                            classNameContainer:
                                'w-full h-full  order-2 lg:order-none',
                            swipeable: false,
                            draggable: false,
                            showDots: false,
                            arrows: false,
                            infinite: true,
                            autoPlay: true,
                            autoPlaySpeed: 3000,
                            transitionDuration: 3000,
                            customTransition: 'animate-fadeInAnimation'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'blog',
                    className:
                        'relative grid lg:h-[500px] 2xl:h-[600px] sm:grid-cols-1 lg:grid-cols-2 bg-primary-focus',
                    linearGradient: {
                        R: '100',
                        G: '65',
                        B: '43',
                        opacity: '0'
                    },
                    components: [
                        {
                            type: 'CHANGING_IMAGE',
                            url: '',
                            position: 'center',
                            imageArr: [
                                {
                                    url: 'https://cdn.loyalie.in/43655443.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/29614220.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/88471850.jpg'
                                }
                            ],
                            className:
                                'h-full lg:h-[500px] 2xl:h-[600px] w-full object-fill ',
                            classNameContainer:
                                'w-full h-full order-2 lg:order-none',
                            swipeable: false,
                            draggable: false,
                            showDots: false,
                            arrows: false,
                            infinite: true,
                            autoPlay: true,
                            autoPlaySpeed: 3000,
                            transitionDuration: 3000,
                            customTransition: 'animate-fadeInAnimation'
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'lg:pl-6 3xl:pl-20 order-1 lg:pr-7 xl:pr-20 m-auto h-auto mb-4 lg:m-auto text-left text-primary',
                            linearGradient: {
                                R: '100',
                                G: '65',
                                B: '43',
                                opacity: '0'
                            },
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'OFFERS AND PROMOTIONS',
                                    fontSize: '30px',
                                    className: `tracking-wide uppercase text-primary px-7 py-4 lg:px-7 lg:py-0 ${getFont('GODREJ').heading
                                        }`
                                },
                                {
                                    type: 'DIVIDER',
                                    position: '',
                                    className:
                                        'mx-7 lg:mx-7 w-[25%] md:w-[16%] lg:w-[80%]  bg-primary border-none h-[2px]',
                                    classNameContainer: 'mt-2'
                                },

                                {
                                    type: 'TEXT',
                                    content:
                                        'Being at the center of our attention means availing of exclusive offers and promotions from top Pan-India and luxury brands across the country.',

                                    className: `text-[19px] lg:text-[16px] xl:text-[19px] font-normal px-7 py-4 lg:px-7   3xl:pr-32 text-accent-content lg:py-5 ${getFont('CENTURY').body
                                        }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'As part of our loyalty program, we are looking forward to bringing you curated experiences that will become long delightful conversations with your loved ones. Enjoy a luxury experience, unlike anything you have ever done before.',

                                    className: `text-[19px] lg:text-[16px] xl:text-[19px] px-7 pt-4 lg:px-7 text-accent-content  3xl:pr-32  lg:pt-2 ${getFont('CENTURY').body
                                        }`
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'blog',
                    className:
                        'relative grid lg:h-[500px] 2xl:h-[600px] sm:grid-cols-1 lg:grid-cols-2 bg-secondary-content',

                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'lg:pl-6 3xl:pl-20 lg:pr-7 xl:pr-20 m-auto h-auto mb-4 lg:m-auto text-left text-primary   ',

                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'Workshops and Events ',
                                    fontSize: '30px',
                                    className: `tracking-wide uppercase text-primary-focus px-7 py-4 lg:px-7 lg:py-0 ${getFont('GODREJ').heading
                                        }`
                                },
                                {
                                    type: 'DIVIDER',
                                    position: '',
                                    className:
                                        'mx-7 lg:mx-7 w-[25%] md:w-[16%] lg:w-[80%]  bg-primary-focus border-none h-[2px]',
                                    classNameContainer: 'mt-2'
                                },

                                {
                                    type: 'TEXT',
                                    content:
                                        'Engagement and interaction form the founding pillars of a model community. In our efforts to create the same feeling for every member, we will bring you exclusive workshops and events with the top collaborators in each field. Now, community experiences will move beyond waves and smiles, into relationships forged with lasting memories. ',

                                    className: `text-[19px] lg:text-[16px] xl:text-[19px] font-normal px-7 py-4 lg:px-7  3xl:pr-32  text-accent-content lg:py-5 ${getFont('CENTURY').body
                                        }`
                                }
                            ]
                        },
                        {
                            type: 'CHANGING_IMAGE',
                            url: '',
                            position: 'center',
                            imageArr: [
                                {
                                    url: 'https://cdn.loyalie.in/32777991.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/51782615.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/25919298.jpg'
                                }
                            ],
                            className:
                                'h-full lg:h-[500px] 2xl:h-[600px] w-full object-fill ',
                            classNameContainer:
                                'w-full h-full  order-2 lg:order-none',
                            swipeable: false,
                            draggable: false,
                            showDots: false,
                            arrows: false,
                            infinite: true,
                            autoPlay: true,
                            autoPlaySpeed: 3000,
                            transitionDuration: 3000,
                            customTransition: 'animate-fadeInAnimation'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'blog',
                    className:
                        'relative grid lg:h-[500px] 2xl:h-[600px]  sm:grid-cols-1 lg:grid-cols-2 bg-primary-focus',

                    components: [
                        {
                            type: 'CHANGING_IMAGE',
                            url: '',
                            position: 'center',
                            imageArr: [
                                {
                                    url: 'https://cdn.loyalie.in/47752960.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/30191276.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/27127705.jpg'
                                }
                            ],
                            className:
                                'h-full lg:h-[500px] 2xl:h-[600px] w-full object-fill ',
                            classNameContainer:
                                'w-full h-full  order-2 lg:order-none',
                            swipeable: false,
                            draggable: false,
                            showDots: false,
                            arrows: false,
                            infinite: true,
                            autoPlay: true,
                            autoPlaySpeed: 3000,
                            transitionDuration: 3000,
                            customTransition: 'animate-fadeInAnimation'
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'lg:pl-6 3xl:pl-20 lg:pr-7 xl:pr-20 m-auto h-auto mb-4  lg:m-auto text-left text-primary',

                            components: [
                                {
                                    type: 'TEXT',
                                    content: ' Interactive Blogs ',
                                    fontSize: '30px',
                                    className: `tracking-wide uppercase text-primary px-7 py-4 lg:px-7 lg:py-0 ${getFont('GODREJ').heading
                                        }`
                                },
                                {
                                    type: 'DIVIDER',

                                    className:
                                        'mx-7 lg:mx-7 w-[25%] md:w-[16%] lg:w-[60%]  bg-primary border-none h-[2px]',
                                    classNameContainer: 'mt-2'
                                },

                                {
                                    type: 'TEXT',
                                    content:
                                        'Learning is part of every successful community, but it should come with a sprinkle of fun. Our interactive blogs are curated and written keeping you in mind. We drive towards enabling unique interactions and talking points between you and your neighbors, while sharing a life together.',

                                    className: `text-[19px] lg:text-[16px] xl:text-[19px] font-normal px-7 py-4 lg:px-7  3xl:pr-32  text-accent-content lg:py-5 ${getFont('CENTURY').body
                                        }`
                                }
                            ]
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-10',

                    components: [
                        {
                            type: 'TEXT',
                            content: 'Trusted By',

                            className: `tracking-wide font-extrabold  text-3xl md:text-[32px] lg:text-[32px] text-[#595A5C] ${getFont('CENTURY').body
                                }`
                        }
                    ]
                },
                {
                    type: 'CAROUSEL',
                    caraouselClassName:
                        'px-8 md:px-10 md:py-10 lg:pt-10 lg:pb-10 relative',
                    imageContainerClassName: 'w-auto  p-2',
                    imageClassName: 'px-3',
                    arrows: true,
                    autoplay: true,
                    autoplaySpeed: 1000,
                    infinite: true,
                    dots: false,
                    slidesToShow: 4,
                    pauseOnHover: true,
                    speed: 1000,
                    onImageClick: () => {
                        return;
                    },
                    imgArr: [
                        {
                            imgUrl: 'https://cdn.loyalie.in/65669652.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/73528146.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/2512678.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/26498333.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/63401464.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/1821850.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/68365980.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/78466246.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/70522513.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/48375892.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/21557500.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/84255681.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/56743891.png',
                            alt: 'img'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/55164196.png',
                            alt: 'img'
                        }
                    ]
                }
            ]
        },
        referPage: {
            name: 'REFER AND EARN',
            path: '/gift-and-earn',
            component: 'ReferAndEarn',
            show: true
        },
        refer: {
            show: false,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/refer',
            component: 'ReferAndEarn'
        },

        events: {
            name: 'EVENTS',
            path: '/events',
            component: 'Events',
            show: true
        },
        blogs: {
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs',
            show: true
        },
        deals: {
            name: 'EXCLUSIVE OFFERS',
            authenticatedRoute: true,
            path: '/deals',
            component: 'DealsAndDiscounts',
            show: true
        },
        projects: {
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects',
            show: true
        },

        contactUs: {
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us',
            show: false
        },
        termsAndConditions: {
            name: 'T&C',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions',
            show: false
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://cdn.loyalie.in/1692633683952_refer%20page%20banner',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1692633683952_refer%20page%20banner',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[80vh] md:h-[60vh] object-cover bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'Refer your loved ones and get benefits ',
                            className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest  lg:w-[80%] mx-auto  ${getFont('RELOY').body
                                }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'Start Referring Now',
                            onClickFunc: () =>
                                store.dispatch({
                                    type: 'authentication/openLoginModal'
                                }),
                            className: `text-center mt-4 flex justify-center mx-auto bg-[#85CDB7] rounded-full py-3 px-5 hover:bg-transparent hover:text-[#85CDB7] hover:border-2 hover:border-[#85CDB7] ${getFont('GODREJ').heading
                                }`,
                            onClick: 'FUNC'
                        }
                    ]
                }
            ]
        },

        components: [
        ],

        customize: {
            claimButton: `text-sm md:text-md lg:font-bold bg-primary text-white ${getFont('RELOY').heading
                }`
        }
    },
    blogs: {
        banner: {
            linearGradient: {
                R: '70',
                G: '83',
                B: '164',
                opacity: '0.3'
            },
            backgroundImage:
                'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/39091238.jpg',
            backgroundImageMobile:
                'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/52735240.jpg',
            bannerContainerClassName:
                'relative text-center hero hero-content max-w-full h-[75vh] lg:h-[97vh] bg-center object-cover lg:bg-center xl:bg-center',
            components: [
                {
                    type: 'TEXT',
                    content: 'THE READERS CLUB',
                    className: `font-extrabold text-center mt-16 md:mt-10 mb-2 md:mb-3 lg:mb-3 xl:mb-5 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] leading-8 md:leading-none tracking-wider ${getFont('SRIJAN').secondary
                        }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-0 md:mt-1 lg:mt-0 xl:mt-1 self-center mx-auto w-[30%] md:w-[20%] lg:w-[20%] bg-white border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-5 lg:mt-3 xl:mt-5 mb-3 lg:mb-6 xl:mb-4 uppercase text-[13px] md:text-base xl:text-[17px] tracking-wider ${getFont('SRIJAN').heading
                        }`
                },
                {
                    type: 'BUTTON',
                    content: 'READ MORE',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL'
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'THE READERS CLUB',
                    className: `font-extrabold text-center mt-16 md:mt-10 mb-2 md:mb-3 lg:mb-3 xl:mb-5 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] leading-8 md:leading-none tracking-wider ${getFont('SRIJAN').secondary
                        }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-0 md:mt-1 lg:mt-0 xl:mt-1 self-center mx-auto w-[30%] md:w-[20%] lg:w-[20%] bg-white border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-5 lg:mt-3 xl:mt-5 mb-3 lg:mb-6 xl:mb-4 uppercase text-[13px] md:text-base xl:text-[17px] tracking-wider ${getFont('SRIJAN').heading
                        }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        })
                }
            ]
        },

        sideBarClass: `text-neutral ${getFont('SRIJAN').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass:
            ' text-[16px] text-primary capitalize leading-5 font-Montserrat font-semibold',
        sideBarDateClass: 'text-[#64412b] text-[12px]',
        comments: {
            commentsBg: '',
            commentButtonClass: '',
            initialsClass: '',
            replyBorder: ''
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0 font-Montserrat font-semibold text-[20px] text-[#1c2936]`,
                headingClass:
                    'text-[20px] uppercase font-Montserrat text-primary mt-3',
                summaryClass:
                    'inline text-[17px] mt-8 text-[#1c2936] font-Montserrat min-h-[40px] lg:min-h-[70px]',
                dateClass:
                    'text-[12px] uppercase font-Montserrat text-[#1c2936] font-semibold mt-3 mb-10 ',
                buttonClass: 'inline text-[17px] text-info font-Montserrat '
            },
            sectionHeadings: `px-10 lg:px-16 pt-16 pb-4 text-2xl md:text-[28px] lg:text-[25px] xl:text-[30px] text-neutral text-center font-medium font-Libre_Baskerville   `,
            dividerClass: 'hidden m-0 p-0 '
        },

        layout3: {
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: ` text-secondary font-Montserrat mb-2 text-[18px] 3xl:text-[20px] font-normal uppercase leading-6 h-[90px] md:h-[75px] lg:h-[90px]`,
            categoryClass:
                'bg-[#00000075]  text-white text-[11px] absolute right-0 text-[12px] font-medium 3xl:text-base px-4 py-1 m-3 rounded-full uppercase',
            summaryClass: `font-Roboto font-normal indent-8 text-[14px] lg:my-4 text-[#777777] h-[90px]`,
            buttonClass: `bg-primary flex justify-center self-start text-white mt-2 md:mt-0 mb-3 md:mb-4 2xl:mb-5 rounded-full py-2 px-4 md:py-2 md:px-5 text-xs md:text-base lg:text-[14px] xl:text-sm font-medium font-Montserrat`,
            dateClass: `text-[10px] md:text-[12px] self-center text-[#adadad]`,
            noBlogsClass: ''
        }
    },
    referAndEarn: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '70',
                    G: '83',
                    B: '164',
                    opacity: '0.4'
                },

                bannerContainerClassName:
                    'relative text-center hero hero-content max-w-full h-[75vh] lg:h-[100vh] bg-center ',
                backgroundImage: 'https://cdn.loyalie.in/64377711.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/64377711.jpg',
                components: [
                    {
                        type: 'TEXT',
                        content: 'ONE FAMILY MANY REWARDS',

                        className: `text-left text-[20px] md:text-[25px] lg:text-[26px] mt-[60px] tracking-[3px] md:mt-10  lg:mb-3 tracking-widest ${getFont('GODREJ').heading
                            }`
                    },

                    {
                        type: 'TEXT',
                        content:
                            'An exclusive referral campaign, curated just for you!',

                        className: `text-left text-gray-300 lg:mt-3 text-[28px] md:text-[28px] lg:text-[25px] mb-5 break-words leading-8 ${getFont('GODREJ').heading
                            }`
                    },
                    {
                        type: 'BUTTON',
                        content: 'REFER NOW',
                        className: `text-left flex bg-accent text-white hover:text-accent rounded-full py-2 px-10 hover:bg-transparent hover:border-2 hover:border-accent ${getFont('CENTURY').body
                            }`,
                        path: 'gift-and-earn',
                        onClick: 'CHANGE_PATH_AND_SCROLL',
                        scrollTo: 'referral_section'
                    }
                ]
            },
            {
                type: 'CUSTOM',
                className: 'flex flex-col justify-center text-center pb-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'Making Referrals Easy',
                        className: `tracking-wide font-semibold pt-20 text-xl md:text-[32px] lg:text-[32px] text-secondary-content ${getFont('SRIJAN').heading
                            }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'Here’s a look at how seamless your referral experience will be.',

                        fontSize: '19px',
                        className: `text-secondary-content leading-7 w-[85%] mx-auto xl:px-16 mt-2 mb-10 ${getFont('CENTURY').body
                            }`
                    },
                    {
                        type: 'ICONS_LAYOUT_WITH_ARROW',
                        containerClassName:
                            'h-content xl:w-full w-full lg:flex justify-around lg:h-auto pt-24 bg-white items-center',
                        gridClassName:
                            'grid lg:grid-cols-3 xl:w-[90%] lg:pt-3 lg:m-auto m-auto lg:text-center',

                        infoContainerClassName: 'px-6 mt-4 lg:mt-0',
                        heading: '',
                        heading2: '',
                        txtClassName: `w-full md:w-[50%] mx-auto lg:w-full md:px-2 lg:text-lg  text-lg text-secondary font-normal  ${getFont('CENTURY').heading
                            }`,
                        icon1: Icon1,
                        icon2: Icon2,
                        icon3: Icon3,

                        icontext1:
                            'Introduce your loved ones to your community',
                        icontext2:
                            'Wait for them to complete their property booking & registration',
                        icontext3:
                            'Receive your referral reward within 90 days post registration'
                    }
                ]
            },
            {
                type: 'REFERRAL_FORM_BANNER'
            },
            {
                type: 'CUSTOM',
                className:
                    'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-10',

                components: [
                    {
                        type: 'TEXT',
                        content: 'Our Curated Loyalty And Referral Brands',

                        className: `tracking-wide font-extrabold  text-3xl md:text-[25px] lg:text-[25px] text-[#595A5C] ${getFont('CENTURY').body
                            }`
                    }
                ]
            },
            {
                type: 'CAROUSEL',
                caraouselClassName:
                    'px-8 md:px-10 md:py-10 lg:pt-10 lg:pb-10 relative',
                imageContainerClassName: 'w-auto  p-2',
                imageClassName: 'px-3',
                arrows: true,
                autoplay: true,
                autoplaySpeed: 1000,
                infinite: true,
                dots: false,
                slidesToShow: 4,
                pauseOnHover: true,
                speed: 1000,
                onImageClick: () => {
                    return;
                },
                imgArr: [
                    {
                        imgUrl: 'https://cdn.loyalie.in/11582642.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/61510437.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/46813002.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/68518003.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/2839398.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/13847368.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/14307629.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/18203268.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/660802.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/67058338.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/48005917.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/8737702.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/85384194.jpg',
                        alt: 'img'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/42558135.jpg',
                        alt: 'img'
                    }
                ]
            }
        ]
    },

    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/69080231.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/69080231.jpg',
            linearGradient: {
                R: '70',
                G: '83',
                B: '164',
                opacity: '0.4'
            },
            containerClass: 'xl:ml-5 text-white lg:w-[40%]',
            headingClass: `font-medium text-center mt-16 md:mt-10 mb-2 md:mb-2 lg:mb-2 xl:mb-2 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] leading-8 md:leading-none tracking-wider ${getFont('SRIJAN').heading
                }`,
            subHeadingClass: `text-secondary-focus uppercase font-semibold text-[33px] ${getFont('CENTURY').heading
                }`
        },
        loginBanner: {
            bannerContainerClassName:
                'hero hero-content  max-w-full h-[50vh] lg:h-[90vh] 2xl:h-[99vh] bg-right ',

            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${getFont('ARVIND').heading
                        }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Arvind Prive is driven to create experiences that add more value to your life',
                //     className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${
                //         getFont('ARVIND').heading
                //     }`
                // },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    onClick: 'FUNC',
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${getFont('ARVIND').body
                        }`
                }
            ]
        },

        eventsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/17509686.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.99' //no gradient
            },
            heading: 'PAST EVENTS',
            headingClass: `text-secondary text-[35px] text-center tracking-wider font-extrabold mt-16 ${getFont('SRIJAN').body
                }`,
            dividerClass:
                'bg-secondary-content h-[4px] self-center w-[25%] lg:w-[10%] ',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-[18px] md:text-[18px] mt-16 text-neutral w-[60%] ${getFont('SRIJAN').secondary
                }`,
            additionalText: ' ',
            additionalTextClass: `self-center text-md text-center text-black  w-[60%] ${getFont('CENTURY').body
                } `,
            subAdditionalText: ' ',
            subAdditionalTextClass: `capitalize self-center text-md text-center text-black w-[60%] mb-16 ${getFont('CENTURY').body
                }`,
            noEvents: 'No events to show',
            noEventsClass: ` w-[30%] mx-auto py-1 text-primary bg-primary-focus text-2xl text-center ${getFont('CENTURY').body
                }`
        },
        eventLayout: {
            titleClass: `text-primary leading-5 uppercase text-[18px] font-medium mt-2 ${getFont('SRIJAN').body
                }`,
            hostClass: 'text-secondary capitalize ',
            dateClass: '',
            loadMoreButtonClass: `bg-accent text-white hover:text-accent rounded-full py-2 px-10 hover:bg-transparent hover:border-2 hover:border-accent ${getFont('CENTURY').body
                }`
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-accent font-semibold text-xl  my-4 z-10 ${getFont('SRIJAN').heading
            }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${getFont('SRIJAN').heading
            }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[30vh] lg:h-[45vh] bg-center lg:bg-top bg-cover', //
            linearGradient: {
                R: '70',
                G: '83',
                B: '164',
                opacity: '0'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692637238907_offer kohinoor',

            backgroundImageMobile:
                'https://cdn.loyalie.in/1692637238907_offer kohinoor',
            bannerComponents: [],
            loginBannerComponents: []
        },
        dealsSection: {
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center font-semibold text-[20px] text-center text-xl text-secondary-content w-[60%] ${getFont('CENTURY').body
                }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `text-lg font-semibold uppercase text-primary ${getFont('SRIJAN').heading
                } `,
            subAdditionalText:
                'We are driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-medium text-neutral ${getFont('SRIJAN').heading
                }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-black text-2xl text-center my-6
             ${getFont('CENTURY').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.95',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-sm lg:text-base leading-6 lg:leading-5 text-white ${getFont('SRIJAN').heading
                }`,
            listBodyClass: `${getFont('SRIJAN').body}`,
            logoClassName: 'self-center rounded-lg drop-shadow-lg',
            titleClassName: ` text-primary font-medium uppercase  text-2xl xl:text-3xl ${getFont('SRIJAN').secondary
                } `,
            couponClassName: `text-sm md:text-base xl:text-xl block text-semibold text-neutral    ${getFont('SRIJAN').heading
                }`,
            dateClassName: ` text-[#595a5c] font-medium capitalize text-[16px]  ${getFont('SRIJAN').heading
                }`,
            termsAndConditionsClassName: ` text-[#373737]  font-medium capitalize text-sm leading-5 ${getFont('SRIJAN').heading
                }`,
            termsArrowClassName:
                'self-center text-2xl lg:text-xl xl:text-2xl text-[#373737]',
            termsClassName: `text-[#373737] text-xs font-medium break-words leading-4 ${getFont('SRIJAN').heading
                }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                rounded: 'TRUE',
                className: `text-white self-center bg-[#85CDB7] rounded-full py-2 px-8 hover:bg-transparent hover:border-2 hover:border-[#85CDB7] hover:text-[#85CDB7] uppercase ${getFont('CENTURY').heading
                    } `
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                rounded: 'TRUE',

                className: `text-white self-center bg-[#85CDB7] rounded-full py-2 px-8 hover:bg-transparent hover:border-2 hover:border-[#85CDB7] hover:text-[#85CDB7] uppercase ${getFont('CENTURY').heading
                    } `
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '70',
                    G: '83',
                    B: '164',
                    opacity: '0.4'
                },

                bannerContainerClassName:
                    'hidden relative text-center md:hero hero-content max-w-full h-[75vh] lg:h-[85vh] bg-center ',
                backgroundImage: 'https://cdn.loyalie.in/21650802.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/21650802.jpg',
                components: [
                    {
                        type: 'TEXT',
                        content: 'PROJECTS',
                        className: `font-medium text-center mt-16 md:mt-10 mb-2 md:mb-2 lg:mb-2 xl:mb-2 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] leading-8 md:leading-none tracking-wider ${getFont('SRIJAN').heading
                            }`
                    }
                ]
            }
        ],
        moduleConfig: {
     
            constructionVisible: true,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ' ',
        }
    },
    contactUs: {
        components: [],

  
    },
    termsAndConditions: {
        listStyle: '',
        titleClass: `tracking-wide text-[#595A5C] mt-4 font-semibold text-[20px] md:text-[20px] ${getFont('CENTURY').heading
            }`,
        listClassName: `text-black text-[17px] leading-7 ${getFont('CENTURY').body
            }`,
        components: [
            {
                type: 'BANNER',
                bannerContainerClassName:
                    'relative text-center hero hero-content max-w-full h-[75vh] lg:h-[85vh] bg-center ',

                linearGradient: {
                    R: '70',
                    G: '83',
                    B: '164',
                    opacity: '0.4'
                },

                backgroundImage: 'https://cdn.loyalie.in/16132901.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/16132901.jpg',

                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS AND CONDITIONS',
                        className: `font-medium text-center mt-16 md:mt-10 mb-2 md:mb-2 lg:mb-2 xl:mb-2 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] leading-8 md:leading-none tracking-wider ${getFont('SRIJAN').heading
                            }`
                    }
                ]
            },
            {
                type: 'CUSTOM',
                className: 'text-left w-full p-16',

                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '1'
                },
                url: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/31147715.png',
                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-[#595A5C] font-semibold text-[20px] md:text-[20px] ${getFont('CENTURY').heading
                            }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: `tracking-wide text-[#595A5C] mt-4 font-semibold text-[20px] md:text-[20px] ${getFont('CENTURY').heading
                            }`
                    },
                    {
                        type: 'LIST',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Century Circle project, and has entered into a registered Agreement to Sell; or (ii) employee of Century Circle; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Century Circle or any of its sister concerns/subsidiaries/affiliates (“Century Circle Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Century Circle Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Century Circle.',
                            'Referrer shall not be able to refer existing customer(s) of Century Circle. Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Century Circle or (ii) is/are already registered with Century Circle as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Century Circle’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Century Circle within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Century Circle',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Century Circle or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.',
                            'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Century Circle, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on reloy.co/terms-and-conditions and privacy policy on reloy.co/privacy-policy laid out by Loyalie IT-Solutions Pvt. Ltd.',
                            'In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id support@reloy.co. Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.'
                        ],
                        listClassName: `text-black text-[17px] leading-7 ${getFont('CENTURY').body
                            }`,
                        classNameContainer: 'px-6 mt-6'
                    },
                    { type: 'RAZORPAY_TERMS', classNameContainer: 'px-6 mt-6' }
                ]
            }
        ],
        terms: {
            phone: '+91 22 6169 8500 ',
            phoneLogoClassName: ``,
            email: 'marketing@godrejproperties.com',
            emailLogoClassName: ``,
            address:
                'Unit No. 5C, 5th Floor, Godrej One, Pirojshanagar, Vikhroli East, Mumbai - 400 079',
            addressLogoClassName: ``
        }
    },
    myProfile: {
        allowOnboarding: false,
        showMyProperty: true,
        appStoreLink: 'https://apps.apple.com/us/app/reloy/id1614645093',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.reloy',
        appScreen:
            'https://d1pkuz21mplpj3.cloudfront.net/srijan-App-screens.png'
    },

    footer: {
        footerLayout: 'Footer2',
        // footerLogo:
        //     'https://cdn.loyalie.in/6673243.png',
        containerClassName: 'bg-secondary-content py-4',

        termsAndConditionsClassName: `font-normal text-white text-[13px] xl:-mt-2 md:text-[13px] 2xl:text-[14px] 3xl:text-[16px] text-right mr-10 ${getFont('CENTURY').heading
            }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent:
            '© 2021 by Century Real Estate Holdings Pvt. Ltd. All rights reserved.',
        allRightsReservedClassName: `text-[12px]`,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in. All brands have their respective terms and conditions and neither Century Real Estate nor Reloy will be responsible in case of any concerns.',
        loyalieFooterContentClassName: `font-normal text-[11px] px-10 self-center md:w-[85%] text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[15px] leading-5 tracking-tight  text-white ${getFont('CENTURY').heading
            }`
    },
    commonComponents: {
        filterTagClassName: `text-[#373737] font-medium  text-[19px] ${
            getFont('SRIJAN').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-info'
    },
    referralForm: {
        name: '',
        theme: 'reloyTheme',
        color: '#4653a4',
        backgroundImage: 'https://cdn.loyalie.in/66016443.jpeg',
        bgOverlay: true,
        linearGradient: {
            degree: '90deg',
            R1: '255',
            G1: '255',
            B1: '255',
            opacity1: '0',
            R2: '70',
            G2: '83',
            B2: '164',
            opacity2: '0.7'
        },
        showLoginScreen: true,
        userTypes: {
            length: 5,
            siteVisitor: true,
            siteVisitorHeading1: '',
            siteVisitorHeading2: '',

            bookedCustomer: true,
            bookedCustomerHeading1: '',
            bookedCustomerHeading2: '',

            employee: true,
            employeeHeading1: 'I am an ',
            employeeHeading2: 'Employee',

            salesAdmin: true,
            salesAdminHeading1: '',
            salesAdminHeading2: '',

            influencer: true,
            influencerHeading1: '',
            influencerHeading2: ''
        },
        formContent: {
            heading: ' ',
            headingClass: `self-center text-white sm:mt-0 text-4xl sm:text-5xl  w-[80%] lg:w-full  text-center tracking-widest  ${getFont('SRIJAN').heading
                }`,
            notification: {
                line1: 'REFER',
                line1Class: `self-center text-center text-white font-semibold text-xl md:text-2xl lg:text-2xl 2xl:text-3xl lg:tracking-wider ${getFont('GODREJ').heading
                    }`,
                line2: 'EARN ',
                line2Class: `self-center text-center text-primary mt-1 text-5xl md:text-6xl lg:text-6xl 2xl:text-7xl ${getFont('GODREJ').heading
                    }`,
                line3: ' REPEAT',
                line3Class: `self-center  text-center text-primary-focus font-semibold tracking-wider mt-1 text-2xl md:text-3xl lg:text-3xl 2xl:text-4xl ${getFont('GODREJ').heading
                    }`
            },
            employeeDisclaimer: false,
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%234653a4'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        isDirectTAndCLink: true,
        influencerLinkActive: true,
        termsAndConditionsLink: 'https://reloy.co/terms-conditions/',
        customLink: '/reloy-website/projects/detail'
    }
};
export default reloyConfig;
