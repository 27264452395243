import { useEffect } from 'react';
import { winnreAuth } from '../../../utils/axios';
import { useState } from 'react';

const useData = () => {
    const [allLeads, setAllLeads] = useState([]);
    // const [active, setActive] = useState('all');
    const [displayLeads, setDisplayLeads] = useState([]);
    const [loadingLeads, setLoadingLeads] = useState(false);
    const fetchUsersLeads = () => {
        setLoadingLeads(true);
        winnreAuth({
            method: 'get',
            url: `/winnre/user/enquiry?pageSize=50`
        })
            .then((res) => {
                // console.log('past referral', res?.content);
                setLoadingLeads(false);
                if (res.content.length === 0) {
                    setLoadingLeads(false);
                    return;
                }
                  console.log(res.content);
                setAllLeads(
                    res.content
                        .filter((lead) => {
                            return lead.status !== 'REJECTED';
                        })
                       
                        .map((lead) => ({
                            name: lead?.lead.name,
                            status: lead?.status,
                            projectName: lead?.projectName,
                            createdOn: lead?.lead?.createdOn
                        }))
                );
                setDisplayLeads(
                    res.content
                        .filter((lead) => {
                            return lead.status !== 'REJECTED';
                        })
                        .map((lead) => ({
                            name: lead?.lead.name,
                            mobileNumber:lead?.lead.mobileNumber,
                            status: lead?.status,
                            projectName: lead?.projectName,
                            createdOn: lead?.lead?.createdOn
                        }))
                );
            })
            .catch(() => {
                setDisplayLeads([])
                // setModalMessage(
                //     'Could not load your past referrals. Please try again!'
                // );
                // setShowModal(true);
            });
    };
    function changeStatus(status) {
        // setActive(status);
        if (status === 'all') {
            setDisplayLeads(allLeads);
        } else {
            setDisplayLeads(allLeads.filter((lead) => lead.status === status));
        }
    }
    useEffect(() => {
        // checkDocumentsForInvoicing();
        fetchUsersLeads();
    }, []);

    return {
        displayLeads,
        loadingLeads,
        changeStatus
    };
};

export default useData;
