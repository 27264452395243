import React from 'react';
import { backArrowSvg } from '../../svg';
const LoanAmountTypes = [
    { value: 'Upto ₹25 Lacs' },
    { value: '₹25 - ₹50 Lacs' },
    { value: '₹50 - ₹75 Lacs' },
    { value: '₹75 Lacs - ₹1 Cr' },
    { value: '₹1 Cr+' }
];

const TenureTypes = [
    { value: 'Upto 5 yrs' },
    { value: '5 to 10 yrs' },
    { value: '10 to 20 yrs' },
    { value: 'More than 20 yrs' }
];
function StepTwo({
    setStep,
    loanAmount,
    onLoanAmountChange,
    tenure,
    onTenureChange
}) {
    return (
        <div>
            <div className="flex text-lg font-sm justify-between py-5">
                <div
                    className="flex items-center gap-1 cursor-pointer"
                    onClick={() => setStep((prev) => prev - 1)}
                >
                   {backArrowSvg}

                    <button className="text-primary">Back to Step 1</button>
                </div>

                <p>
                    Step <span className="text-primary">2</span>
                    <span className="text-sm">/3</span>
                </p>
            </div>
            <div className="flex flex-col gap-4">
                <div className="flex  items-center">
                    <h2 className="font-bold">Loan Amount</h2>
                </div>
                <div className="flex flex-wrap justify-between items-center ">
                    {LoanAmountTypes.map((type) => (
                        <div
                            onClick={() => onLoanAmountChange(type.value)}
                            className={`w-[45%] cursor-pointer flex gap-2 border rounded-[10px] p-3 my-2 ${
                                loanAmount === type.value ? 'bg-primary' : ''
                            }`}
                        >
                            <input
                                type="radio"
                                id={type.value}
                                value={type.value}
                                checked={loanAmount === type.value}
                            />
                            <label
                                className={`${
                                    loanAmount === type.value
                                        ? 'text-white'
                                        : ''
                                }`}
                            >
                                {type.value}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="flex  items-center">
                    <h2 className="font-bold">Tenure (Years)</h2>
                </div>
                <div className="flex flex-wrap justify-between items-center ">
                    {TenureTypes.map((type) => (
                        <div
                            onClick={() => onTenureChange(type.value)}
                            className={`w-auto lg:w-[30%] cursor-pointer border rounded-[10px] p-3  my-2 ${
                                tenure === type.value ? 'bg-primary' : ''
                            }`}
                        >
                            <button
                                className={`${
                                    tenure === type.value ? 'text-white' : ''
                                }`}
                            >
                                {type.value}
                            </button>
                        </div>
                    ))}
                </div>
                <button
                    className={`w-full text-white p-3 rounded-[10px] disabled:cursor-not-allowed disabled:bg-[#adadad] bg-primary`}
                    onClick={() => setStep((prev) => prev + 1)}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}

export default StepTwo;
