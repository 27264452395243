export const homeSvg =  <svg xmlns="http://www.w3.org/2000/svg" width="21.08" height="20.981" viewBox="0 0 21.08 20.981">
<g id="home-182" transform="translate(0.5 -2.51)">
<path id="Path_6347" data-name="Path 6347" d="M.454,12.6a.453.453,0,0,1-.3-.791L9.759,3.183a.453.453,0,0,1,.606.675L.757,12.487A.453.453,0,0,1,.454,12.6Z" transform="translate(0)" fill="#16519e" stroke="#16519e" stroke-width="1"/>
<path id="Path_6348" data-name="Path 6348" d="M502.292,12.606a.451.451,0,0,1-.3-.117L492.48,3.86a.453.453,0,1,1,.609-.672l9.508,8.629a.454.454,0,0,1-.3.79Z" transform="translate(-482.723 -0.003)" fill="#16519e" stroke="#16519e" stroke-width="1"/>
<path id="Path_6349" data-name="Path 6349" d="M109.64,401.432H97.816a.453.453,0,0,1-.447-.382L95.53,389.566a.453.453,0,0,1,.9-.144l1.778,11.1h11.051l1.765-11.1a.453.453,0,1,1,.9.142l-1.826,11.485A.453.453,0,0,1,109.64,401.432Z" transform="translate(-93.66 -378.44)" fill="#16519e" stroke="#16519e" stroke-width="1"/>
<path id="Path_6350" data-name="Path 6350" d="M423.131,777.1a.453.453,0,0,1-.453-.453v-3.108a1.4,1.4,0,0,0-.85-.221,1.169,1.169,0,0,0-.856.255l.006,3.075a.453.453,0,1,1-.907,0v-3.108c0-.643.755-1.128,1.757-1.128s1.757.485,1.757,1.128v3.108A.453.453,0,0,1,423.131,777.1Z" transform="translate(-411.873 -754.326)" fill="#16519e" stroke="#16519e" stroke-width="1"/>
</g>
</svg>

export const coinSvg =  <svg xmlns="http://www.w3.org/2000/svg" width="33.615" height="43.357" viewBox="0 0 33.615 43.357">
<g id="Group_10517" data-name="Group 10517" transform="translate(-529.955 -190.644)">
<g id="Group_10516" data-name="Group 10516" transform="translate(8274.694 10537.923)">
<path id="Path_6428" data-name="Path 6428" d="M644.1,864.6H629.15a1.15,1.15,0,0,1-1.15-1.15v-2.3a1.15,1.15,0,0,1,1.15-1.15H644.1a1.15,1.15,0,0,1,1.15,1.15v2.3A1.15,1.15,0,0,1,644.1,864.6Z" transform="translate(-8363.832 -11169.099)" fill="#ffd740"/>
<path id="Path_6429" data-name="Path 6429" d="M636.679,857.751H621.725A1.728,1.728,0,0,1,620,856.026v-2.3A1.728,1.728,0,0,1,621.725,852h14.953a1.728,1.728,0,0,1,1.725,1.725v2.3A1.727,1.727,0,0,1,636.679,857.751Zm-14.953-4.6a.575.575,0,0,0-.575.575v2.3a.575.575,0,0,0,.575.575h14.953a.575.575,0,0,0,.575-.575v-2.3a.575.575,0,0,0-.575-.575Z" transform="translate(-8356.407 -11161.673)" fill="#263238"/>
<path id="Path_6430" data-name="Path 6430" d="M644.1,800.6H629.15a1.15,1.15,0,0,1-1.15-1.15v-2.3a1.15,1.15,0,0,1,1.15-1.15H644.1a1.15,1.15,0,0,1,1.15,1.15v2.3A1.15,1.15,0,0,1,644.1,800.6Z" transform="translate(-8363.832 -11109.699)" fill="#ffd740"/>
<path id="Path_6431" data-name="Path 6431" d="M636.679,793.751H621.725A1.728,1.728,0,0,1,620,792.026v-2.3A1.728,1.728,0,0,1,621.725,788h14.953a1.728,1.728,0,0,1,1.725,1.725v2.3A1.727,1.727,0,0,1,636.679,793.751Zm-14.953-4.6a.575.575,0,0,0-.575.575v2.3a.575.575,0,0,0,.575.575h14.953a.575.575,0,0,0,.575-.575v-2.3a.575.575,0,0,0-.575-.575Z" transform="translate(-8356.407 -11102.274)" fill="#263238"/>
<path id="Path_6432" data-name="Path 6432" d="M644.1,736.6H629.15a1.15,1.15,0,0,1-1.15-1.15v-2.3a1.15,1.15,0,0,1,1.15-1.15H644.1a1.15,1.15,0,0,1,1.15,1.15v2.3A1.15,1.15,0,0,1,644.1,736.6Z" transform="translate(-8363.832 -11050.301)" fill="#ffd740"/>
<path id="Path_6433" data-name="Path 6433" d="M636.679,729.751H621.725A1.728,1.728,0,0,1,620,728.026v-2.3A1.728,1.728,0,0,1,621.725,724h14.953a1.727,1.727,0,0,1,1.725,1.725v2.3A1.727,1.727,0,0,1,636.679,729.751Zm-14.953-4.6a.575.575,0,0,0-.575.575v2.3a.575.575,0,0,0,.575.575h14.953a.575.575,0,0,0,.575-.575v-2.3a.575.575,0,0,0-.575-.575Z" transform="translate(-8356.407 -11042.875)" fill="#263238"/>
<path id="Path_6434" data-name="Path 6434" d="M629.2,485.2m-9.2,0a9.2,9.2,0,1,0,9.2-9.2A9.2,9.2,0,0,0,620,485.2Z" transform="translate(-8356.407 -10812.704)" fill="#ffd740"/>
<path id="Path_6435" data-name="Path 6435" d="M621.777,487.554a9.777,9.777,0,1,1,9.777-9.777A9.788,9.788,0,0,1,621.777,487.554Zm0-18.4a8.627,8.627,0,1,0,8.627,8.627A8.636,8.636,0,0,0,621.777,469.15Z" transform="translate(-8348.982 -10805.279)" fill="#263238"/>
<path id="Path_6436" data-name="Path 6436" d="M740.575,534.876A.575.575,0,0,1,740,534.3v-1.725a.575.575,0,0,1,1.15,0V534.3A.575.575,0,0,1,740.575,534.876Z" transform="translate(-8467.78 -10864.679)" fill="#263238"/>
<path id="Path_6437" data-name="Path 6437" d="M740.575,638.876A.575.575,0,0,1,740,638.3v-1.725a.575.575,0,0,1,1.15,0V638.3A.575.575,0,0,1,740.575,638.876Z" transform="translate(-8467.78 -10961.202)" fill="#263238"/>
<path id="Path_6438" data-name="Path 6438" d="M711.738,562.9h-3.163a.575.575,0,1,1,0-1.15h3.163a.863.863,0,1,0,0-1.725h-1.725a2.013,2.013,0,0,1,0-4.026H712.6a.575.575,0,0,1,0,1.15h-2.588a.863.863,0,0,0,0,1.725h1.725a2.013,2.013,0,1,1,0,4.026Z" transform="translate(-8438.081 -10886.953)" fill="#263238"/>
</g>
<path id="Path_6439" data-name="Path 6439" d="M247.032,237.349l1.2,2.184,2.184,1.194-2.184,1.2-1.2,2.184-1.194-2.184-2.184-1.2,2.184-1.194Z" transform="translate(286.302 -36.705)" fill="#16519e"/>
<path id="Path_6440" data-name="Path 6440" d="M247.032,237.349l1.2,2.184,2.184,1.194-2.184,1.2-1.2,2.184-1.194-2.184-2.184-1.2,2.184-1.194Z" transform="translate(299.302 -46.705)" fill="#16519e"/>
<path id="Path_6441" data-name="Path 6441" d="M245.96,237.349l.816,1.492,1.491.816-1.491.816-.816,1.491-.816-1.491-1.492-.816,1.492-.816Z" transform="translate(315.302 -25.562)" fill="#16519e"/>
<path id="Path_6442" data-name="Path 6442" d="M245.96,237.349l.816,1.492,1.491.816-1.491.816-.816,1.491-.816-1.491-1.492-.816,1.492-.816Z" transform="translate(311.305 -39.562)" fill="#16519e"/>
</g>
</svg>

export const backArrowSvg =  <svg
xmlns="http://www.w3.org/2000/svg"
width="20"
height="20"
viewBox="0 0 20 20"
>
<g
    id="Group_10498"
    data-name="Group 10498"
    transform="translate(-502 -112)"
>
    <rect
        id="Rectangle_16987"
        data-name="Rectangle 16987"
        width="20"
        height="20"
        rx="5"
        transform="translate(502 112)"
        fill="#16519e"
    />
    <path
        id="_8666777_chevron_right_arrow_icon"
        data-name="8666777_chevron_right_arrow_icon"
        d="M13.5,14.992,9,10.5,13.5,6"
        transform="translate(500.5 111.5)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
    />
</g>
</svg>

export const diamondSvg =       <svg  xmlns="http://www.w3.org/2000/svg" width="76.593" height="74.532" viewBox="0 0 76.593 74.532">
<g id="Group_10514" data-name="Group 10514" transform="translate(-644.953 -114.468)">
<path id="diamonds-40" d="M113.184,114.232l-8.828-15.245a3.716,3.716,0,0,0-2.98-1.742H64.151a3.713,3.713,0,0,0-2.979,1.742l-8.829,15.245c-.188.325-.365.633-.5.868-.066.117-.118.213-.155.28a1.153,1.153,0,0,0-.045,1.146l.3.539h.122c.14.176.329.416.559.71L81.112,154.3a2.3,2.3,0,0,0,.619.556l.281.143a1.974,1.974,0,0,0,1.546,0l.281-.143a2.3,2.3,0,0,0,.619-.556l28.486-36.529a3.336,3.336,0,0,0,.24-3.543Zm-2.007.7H96.544l6.347-14.306,8.285,14.306Zm-28.4-14.789L92.93,114.932H72.618l10.156-14.789ZM70.811,113.868,64.382,99.377h16.38l-9.951,14.491ZM84.787,99.377h16.38l-6.429,14.491L84.787,99.377Zm8.721,17.687L82.784,151.276,72.04,117.065Zm-30.86-16.459L69,114.932H54.351l8.3-14.326Zm-7.917,16.459H69.862l10.2,32.489L54.731,117.065Zm40.955,0H110.84L85.5,149.56l10.186-32.5Zm0,0" transform="translate(600.489 33.339)" fill="#16519e" stroke="#16519e" stroke-width="1"/>
<g id="Group_10513" data-name="Group 10513">
<line id="Line_1283" data-name="Line 1283" y1="4" x2="7" transform="translate(712.5 127.5)" fill="none" stroke="#e2a500" stroke-linecap="round" stroke-width="3"/>
<line id="Line_1284" data-name="Line 1284" y1="10.085" x2="5.429" transform="translate(707.071 116.5)" fill="none" stroke="#e2a500" stroke-linecap="round" stroke-width="3"/>
<line id="Line_1285" data-name="Line 1285" x1="1" y1="7" transform="translate(699.5 117.5)" fill="none" stroke="#e2a500" stroke-linecap="round" stroke-width="3"/>
</g>
<g id="Group_10512" data-name="Group 10512" transform="translate(647 117.085)">
<line id="Line_1286" data-name="Line 1286" x1="7" y1="4" transform="translate(0 11)" fill="none" stroke="#e2a500" stroke-linecap="round" stroke-width="3"/>
<line id="Line_1287" data-name="Line 1287" x1="5.429" y1="10.085" transform="translate(7)" fill="none" stroke="#e2a500" stroke-linecap="round" stroke-width="3"/>
<line id="Line_1288" data-name="Line 1288" y1="6" x2="1" transform="translate(19.5 1.415)" fill="none" stroke="#e2a500" stroke-linecap="round" stroke-width="3"/>
</g>
</g>
</svg>