import React from 'react';
import ReactDom from 'react-dom';
import AiOutlineCloseSquare from '@meronex/icons/ai/AiOutlineCloseSquare';

export const Overlay = ({ visible, onClick }) => {
    return (
        <div
            onClick={onClick}
            className={
                'fixed z-30 inset-0 bg-gray-700 bg-opacity-60 overflow-y-auto h-full w-full' +
                (visible ? ' block' : ' hidden')
            }
            id="my-modal"
        ></div>
    );
};
const Modal = ({ className, visible, onClick, children, showCloseButton }) => {
    if(!visible){
        return null
    }
    return ReactDom.createPortal(
        <>
            <Overlay visible={visible} onClick={onClick} />
            {visible && (
                <div
                    className={` fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${
                        className
                            ? className
                            : 'w-4/5 md:w-3/5 lg:w-3/6 xl:w-2/5 2xl:w-1/3'
                    }  mx-auto overflow-y-visible  z-40 "`}
                >
                      {showCloseButton && <AiOutlineCloseSquare
                        onClick={onClick}
                        className="absolute h-8 w-8 right-0 top-0 z-10 text-black cursor-pointer"
                    />}
                    <div
                        className={` ${
                            className ? '' : 'py-4'
                        } bg-white h-full shadow-sm rounded-md overflow-y-auto`}
                    >
                        {children}
                    </div>
                </div>
            )}
        </>,
        document.getElementById('modal')
    );
};

export default Modal;
