import { useEffect } from 'react';
import { useState } from 'react';
import useInitialRender from './useInitialRender';

function useSearchInput(initialValue, callback) {
    const [searchInput, setSearchInput] = useState(initialValue);
    const isInitialRender = useInitialRender();

    useEffect(() => {
        if (searchInput.length === 0 && isInitialRender) {
            return;
        }
        const timer = setTimeout(() => {
            callback(searchInput);
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [searchInput]);

    const handleChange = (e) => {
        setSearchInput(String(e.target.value));
    };

    return [searchInput, handleChange];
}

export default useSearchInput;
