import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../index.css';
import { useHistory } from 'react-router-dom';
import { authActions } from '../../../../store/auth-slice';

function Completed({ setStep, userProperty }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const profileCompletedValue = useSelector(
        (state) => state.auth.profileCompletionPercentage
    );
    
    const completedHandler = () => {
        dispatch(authActions.closeOnboardingModal());
        setStep(1);
        history.push('/');
    };

    useEffect(() => {
        window?.webengage?.track('OnboardingFormSubmitted');  
    }, [])

    return (
        <div className="flex flex-col lg:flex-row items-center py-10 px-7">
            <div className="w-full lg:w-1/2 flex justify-center">
                <img
                    src="https://cdn.loyalie.in/59514097.png"
                    className=" h-48"
                />
            </div>
            <div className="font-Poppins w-full lg:w-1/2 text-center lg:text-left">
                <h1 className="text-3xl font-semibold mt-4 lg:mt-0">
                    Congratulations
                </h1>
                <h3 className="text-xl font-semibold mt-5">
                    {`${
                        userProperty
                            ? `You have completed ${profileCompletedValue}% of your profile`
                            : 'You have completed onboarding for all your properties'
                    }  `}
                </h3>
                <p className="mt-3 lg:pr-14 text-sm leading-6 lg:leading-normal">
                    Once your profile is completed 100%, you will receive a
                    special welcome gift from us. You can go back to the profile
                    section and complete the onboarding anytime!
                </p>
                <button
                    onClick={completedHandler}
                    className="mt-4 form-button bg-primary"
                >
                    Go to homepage
                </button>
            </div>
        </div>
    );
}

export default Completed;
