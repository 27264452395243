import { getConfig } from '../../../utils/config';

const Footer3 = () => {
    const config = getConfig();
    return (
        <div
            className={
                config?.footer.containerClassName
                    ? config?.footer.containerClassName
                    : 'bg-secondary-content pt-5'
            }
        >
            <footer className="md:px-4 lg:px-2 xl:px-2 3xl:px-4 font-medium ">
                <div className=" flex flex-col md:flex-row justify-center w-full">
                    <div
                        className={
                            config?.footer.footerLogoClassName
                                ? config?.footer.footerLogoClassName
                                : ' self-center h-full px-10 md:px-0 '
                        }
                    >
                        <img
                            className="w-24  md:pl-8 lg:w-24"
                            src={
                                config?.footer?.footerLogo ||
                                'https://cdn.loyalie.in/28602817.png'
                            }
                        />
                    </div>
                    <p
                        className={
                            config?.footer.loyalieFooterContentClassName
                                ? config?.footer.loyalieFooterContentClassName
                                : ' font-brandFont md:flex-1 mt-4 md:mt-0 px-10 md:px-8 text-[12px] 3xl:text-[15px] leading-[18px] tracking-tight text-white'
                        }
                    >
                        {config?.footer.loyalieFooterContent}
                    </p>
                </div>
                <p
                    className={
                        config?.footer.termsAndConditionsClassName
                            ? config?.footer.termsAndConditionsClassName
                            : ' font-brandFont text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 '
                    }
                >
                    *T&C Apply
                </p>

                {config?.footer.allRightsReservedVisible && (
                    <p className=" text-center text-white text-[14px] md:text-[16px] 3xl:text-[17px] px-7 pt-3">
                        {config?.footer.allRightsReservedContent}
                    </p>
                )}
            </footer>
        </div>
    );
};

export default Footer3;
