import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
const ArvindConfig = {
    clientId:
        '48999a82ce0a3ea0a08e23b5e495b8bd6b8e8189e99bdd6563bd9700cada9b2a',
    id: 23,
    name: 'Arvind Prive',
    fullName: 'Arvind SmartSpaces',
    programName:'Arvind Prive',
    theme: 'arvindTheme',
    favicon: 'https://cdn.loyalie.in/18694123.png',
    primaryColor: '#9f1b3c',
    fontBody: `${getFont('ARVIND').body}`,
    fontTitle: `${getFont('ARVIND').heading}`,
    googleConfig: {
        gID: 'G-VY4KTLRYC0',
        keywordsContent:
            'Arvind real estate, arvind prive real estate referral programs, arvind smartspaces real estate referral program, arvind prive real estate refer and earn, arvind prive real estate project refer, arvind smartspaces real estate, arvind smartspaces real estate loyalty program, All New Projects by arvind smartspaces real estate Builders & Developers, Ongoing Projects by arvind smartspaces real estate limited, arvind smartspaces real estate new program, arvind smartspaces annual report, arvind smartspaces upcoming projects, arvind smartspaces news, arvind smartspaces review, arvind smartspaces projects, arvind smartspaces projects near kolkata, arvind smartspaces projects near barasat, arvind smartspaces real estate news, arvind smartspaces launch arvind prive, arvind smartspaces real estate launch, arvind smartspaces real estate program, arvind smartspaces real estate projects, arvind smartspaces real estate blogs, arvind smartspaces real estate offers, arvind smartspaces real estate events, arvind smartspaces, arvind prive, arvind smartspaces real estate best projects, arvind smartspaces real estate real estate developers,',
        descriptionContent:
            'Arvind Prive is an exclusive loyalty and referral program, curated especially for the homeowners of Arvind SmartSpaces. With this initiative, we wish to enrich your living experiences through our',
        authorContent: 'Arvind Prive'
    },
    facebookPixelConfig: {
        id: '399058069098554', //PROD
        metaName: '',
        metaContent: ''
    },
    webengageLic: 'in~d3a49bd4',
    webengageAPIKey:'f39609ae-7c6d-40e6-8ca3-e6140fbe7795',

    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: false,
    logoLeft: {
        name: 'arvindLogo',
        url: 'https://cdn.loyalie.in/29804434.png',
        stickyNavBarLogoClass: ' h-14 md:h-24 lg:h-14 xl:h-20 pl-5 xl:pl-12',
        baseNavBarLogoClass: ' h-14 md:h-24 lg:h-14 xl:h-20 pl-5 xl:pl-12'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('ARVIND').heading}`,
        height: 'h-24 2xl:h-28',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-white ',
        showActiveLinkColor: true,
        footerNavItemClass: `tracking-widest uppercase text-white text-[14px] lg:text-white font-light lg:text-[12px] xl:text-[15px] 3xl:text-lg  ${
            getFont('ARVIND').heading
        }`,
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-primary-content drop-shadow-md ', // default: transparent
            containerClass: ' ',
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-secondary-focus text-[14px] lg:text-secondary-focus hover:text-primary font-light lg:text-[12px] xl:text-[15px] 3xl:text-lg ',
            dropDownContainerClass: `hover:text-primary focus:text-primary  lg:focus:shadow-outline `,
            dropDownItemClass:
                'tracking-widest text-secondary-focus hover:text-primary text-[14px] lg:text-[12px] xl:text-[15px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-primary-content drop-shadow-md', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-secondary-focus lg:text-white text-[14px] lg:text-secondary-focus hover:text-primary font-medium lg:text-[12px] xl:text-[15px] 3xl:text-lg ' // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 ${
        getFont('ARVIND').bold
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage: 'https://cdn.loyalie.in/77920868.jpg',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/70939582.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    bannerContainerClass:
                        'h-[65vh] md:h-[85vh] lg:h-[85vh] 2xl:h-[95vh] bg-center lg:bg-top ',
                    bannerItemContainerClass:
                        'items-start justify-center  px-8 md:pl-10 lg:pl-20',
                    position: 'text-left',

                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'w-full text-left mt-5 lg:mt-0 leading-6 md:leading-9 lg:leading-none',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'WELCOME TO A',
                                    className: ` text-secondary text-[28px] md:text-[40px] lg:text-[30px]  2xl:text-[45px] ${
                                        getFont('ARVIND').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: ' NEW WORLD OF',
                                    className: `text-secondary text-[28px] md:text-[40px] lg:text-[30px] py-1 2xl:text-[45px] ${
                                        getFont('ARVIND').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: 'EXCLUSIVE PRIVILEGES',
                                    className: `text-secondary text-[28px] md:text-[40px] lg:text-[30px] 2xl:text-[45px] ${
                                        getFont('ARVIND').heading
                                    }`
                                }
                            ]
                        },
                        {
                            type: 'TEXT',
                            content: 'More referrals more privileges',
                            className: ` mt-5 text-secondary text-left font-medium text-[20px] leading-6  ${
                                getFont('ARVIND').body
                            }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'Click to Refer Now',
                            className: `md:mx-0 text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white font-normal rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                                getFont('ARVIND').body
                            }`,
                            onClick: 'CHANGE_PATH_AND_SCROLL',
                            scrollTo: 'referral_section',
                            path: 'gift-and-earn'
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: 'overview',
                    url: 'https://cdn.loyalie.in/43800302.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.89',
                        size: '70%'
                    },
                    bannerItemContainerClass: 'bg-cover',
                    className:
                        'flex flex-col justify-center text-center py-16 px-4 ',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Arvind Prive is an exclusive loyalty and referral program, curated especially for the homeowners of Arvind SmartSpaces. With this initiative, we wish to enrich your living experiences through our community engagement initiatives and make your journey more memorable. We aim to offer a bouquet of bespoke offers and special promotions, exclusive events and experiences, and curated blogs to entral, excite and pamper you.',
                            className: `pt-1 text-[18px] md:text-[21px] lg:text-[20px]  text-secondary-focus leading-6 xl:leading-7 self-center w-[90%] lg:w-[85%] xl:w-[75%] break-words mb-5  ${
                                getFont('ARVIND').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Beyond the luxuries and benefits of the exclusive loyalty program, Arvind SmartSpaces also opens to you a world like no other. We present to you an unique opportunity to build your own community with your loved ones and to get rewarded with upto 1% of the basic agreement value, on every successful referral.',
                            className: `pt-1 text-[18px] md:text-[21px] lg:text-[20px] text-secondary-focus leading-6 xl:leading-7 self-center w-[90%] lg:w-[85%] xl:w-[75%]  break-words mb-5 ${
                                getFont('ARVIND').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'THE PRIVILEGES HERE ARE MANIFOLD',
                            className: `${
                                getFont('ARVIND').heading
                            } text-primary self-center text-center w-[90%] md:w-[80%] lg:w-[70%] 2xl:w-[60%] text-[25px] md:text-[30px] lg:text-[30px] xl:text-[35px]  leading-7 xl:leading-9 pt-10 `
                        },
                        {
                            type: 'TEXT',
                            content: 'WITH ARVIND PRIVE, YOU GET ACCESS TO:',
                            className: `${
                                getFont('ARVIND').heading
                            } text-secondary self-center text-center w-[90%] md:w-[80%] lg:w-[60%] 2xl:w-[50%] text-[20px] md:text-[23px] lg:text-[22px] xl:text-[27px] leading-7 xl:leading-9 pt-4`
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/4667363.jpg',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0.70',
                        repeat: 'no-repeat',
                        size: 'cover',
                        position: 'bottom right'
                    },
                    className:
                        'grid content-center px-5 text-center h-[50vh] lg:h-[45vh] xl:h-[50vh] 2xl:h-[55vh] ',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OFFERS AND PROMOTIONS',
                            onClick: 'CHANGE_PATH',
                            path: 'deals',
                            className: `text-white text-[23px] md:text-[33px] lg:text-[30px] xl:text-[35px] hover:text-primary cursor-pointer ${
                                getFont('ARVIND').heading
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Discover a wide range of promotional offers from top Pan India brands, designed to complement your distinguished lifestyle.',
                            className: `text-white m-auto leading-6 lg:leading-7 pt-7 text-[18px] md:text-[21px] lg:text-[20px] w-[90%] md:w-[60%] lg:w-[80%] xl:w-[50%]  font-light ${
                                getFont('ARVIND').body
                            }`
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/11959384.jpeg',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0.70',
                        repeat: 'no-repeat',
                        size: 'cover',
                        position: 'center center'
                    },
                    className:
                        'grid content-center px-5 text-center h-[50vh] lg:h-[45vh] xl:h-[50vh] 2xl:h-[55vh] ',
                    id: 'srijan_projects_locations',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'REFERRALS AND EARNINGS',
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH',
                            className: `text-white text-[23px] md:text-[33px] lg:text-[30px] xl:text-[35px] hover:text-primary cursor-pointer ${
                                getFont('ARVIND').heading
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Pass along your experiences to your loved ones and earn up to 1% on every successful referral.',
                            className: `text-white m-auto leading-6 lg:leading-7 pt-7 text-[18px] md:text-[21px] lg:text-[20px] w-[90%] md:w-[60%] lg:w-[90%] 2xl:w-[55%]  font-light ${
                                getFont('ARVIND').body
                            }`
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/85303762.jpeg',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0.70',
                        repeat: 'no-repeat',
                        size: 'cover',
                        position: 'center center'
                    },
                    className:
                        'grid content-center px-5 text-center h-[50vh] lg:h-[45vh] xl:h-[50vh] 2xl:h-[55vh] ',
                    id: 'srijan_projects_locations',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'WORKSHOPS AND EVENTS',
                            onClick: 'CHANGE_PATH',
                            path: 'events',
                            className: `text-white text-[23px] md:text-[33px] lg:text-[30px] xl:text-[35px] hover:text-primary cursor-pointer  ${
                                getFont('ARVIND').heading
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Enjoy curated online & offline workshops & events from top collaborators to add more delight to your community experiences.',
                            className: `text-white m-auto leading-6 lg:leading-7 pt-7 text-[18px] md:text-[21px] lg:text-[20px] w-[90%] md:w-[60%] lg:w-[90%] xl:w-[50%]  font-light ${
                                getFont('ARVIND').body
                            }`
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/46028624.jpeg',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0.70',
                        repeat: 'no-repeat',
                        size: 'cover',
                        position: 'center center'
                    },
                    className:
                        'grid content-center px-5 text-center h-[50vh] lg:h-[45vh] xl:h-[50vh] 2xl:h-[55vh] ',
                    id: 'srijan_projects_locations',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'INTERACTIVE BLOGS',
                            onClick: 'CHANGE_PATH',
                            path: 'blogs',
                            className: `text-white text-[23px] md:text-[33px] lg:text-[30px] xl:text-[35px] hover:text-primary cursor-pointer ${
                                getFont('ARVIND').heading
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Know about the latest trends and insights on the real estate industry and unlock a chance to interact with a community of like-minded people.',
                            className: `text-white m-auto leading-6 lg:leading-7 pt-7 text-[18px] md:text-[21px] lg:text-[20px] w-[90%] md:w-[60%] lg:w-[90%] xl:w-[50%]  font-light ${
                                getFont('ARVIND').body
                            }`
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: '',
                    url: 'https://cdn.loyalie.in/43800302.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.89',
                        size: '70%'
                    },
                    className: 'flex flex-col justify-center text-center py-20',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'INTRODUCE YOUR LOVED ONES TO YOUR EXCLUSIVE COMMUNITY TO BEGIN A REWARDING AND ENRICHING JOURNEY.',
                            className: `text-secondary-focus w-[80%] m-auto text-center leading-tight text-[22px] md:text-[24px] lg:text-[25px] xl:text-[35px] ${
                                getFont('ARVIND').heading
                            }`
                        }
                    ]
                },
                {
                    type: 'REFERRAL_FORM_BANNER'
                },
                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/43800302.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.89',
                        size: '70%'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] py-20  -mb-28',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR LATEST BLOGS',
                            className: `text-secondary-focus tracking-wide text-center px-2 text-[22px] md:text-[24px] lg:text-[25px] xl:text-[35px]   ${
                                getFont('ARVIND').heading
                            }`
                        },

                        { type: 'BLOG_LAYOUT_VERTICAL1' },

                        {
                            type: 'TEXT',
                            content: 'OUR PROJECTS',
                            className: `text-secondary-focus tracking-wide text-center px-2 text-[22px] md:text-[24px] lg:text-[25px] xl:text-[35px]   ${
                                getFont('ARVIND').heading
                            }`
                        },
                        {
                            type: 'CAROUSEL',
                            customSlide: true,
                            caraouselClassName:
                                'px-8 md:px-10 xl:px-36 md:pb-10 pt-5 relative',
                            imageContainerClassName: 'm-4 relative',
                            imageClassName:
                                'w-full h-40 lg:bg-cover object-cover',
                            captionClass: ` text-sm lg:text-lg font-semibold leading-8 ${
                                getFont('ARVIND').body
                            }`,
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 3,
                            pauseOnHover: true,
                            speed: 1500,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/81165669.jpg',
                                    alt: 'Arvind Uplands',
                                    url: 'https://www.arvindsmartspaces.com/projects/arvind-uplands-one/',

                                    caption: 'Arvind Uplands'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/25132630.jpg',
                                    alt: 'Arvind Forreste',
                                    url: 'https://www.arvindsmartspaces.com/projects/arvind-forreste/',
                                    caption: 'Arvind Forreste'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/40528960.jpg',
                                    alt: 'Arvind Highgrove',
                                    url: 'https://www.arvindsmartspaces.com/projects/arvind-highgrove/',
                                    caption: 'Arvind Highgrove'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/22519798.jpg',
                                    alt: 'Arvind Aavishkaar',
                                    url: 'https://www.arvindsmartspaces.com/projects/arvind-aavishkaar/',
                                    caption: 'Arvind Aavishkaar'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/69608515.jpg',
                                    alt: 'Arvind Bel Air',
                                    url: 'https://www.arvindsmartspaces.com/projects/arvind-bel-air/',
                                    caption: 'Arvind Bel Air'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/36595789.jpg',
                                    alt: 'Arvind Oasis',
                                    url: 'https://www.arvindsmartspaces.com/projects/arvind-oasis/',
                                    caption: 'Arvind Oasis'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/81904307.jpg',
                                    alt: 'Arvind The Edge',
                                    url: 'https://www.arvindsmartspaces.com/projects/arvind-the-edge/',
                                    caption: 'Arvind The Edge'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/56765115.jpg',
                                    alt: 'Arvind Elan',
                                    url: 'https://www.arvindsmartspaces.com/projects/arvind-elan/',
                                    caption: 'Arvind Elan'
                                }
                            ]
                        },
                        {
                            type: 'TEXT',
                            content:
                                '*A joint initiative by Arvind SmartSpaces & Reloy',
                            className: `text-secondary text-center p-5 md:text-right font-medium text-[17px] md:pr-5 md:pb-2 ${
                                getFont('ARVIND').body
                            }`
                        }
                    ]
                }
            ]
        },

        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        dropDown: {
            show: true,
            name: 'ENGAGEMENT',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'ENGAGEMENT',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms & Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://cdn.loyalie.in/1692626196588_refer%20page%20banner',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1692625772205_refer%20page%20mob',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover bg-right lg:bg-left ',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-left text-secondary text-[28px] md:text-[27px] xl:text-[32px] uppercase my-5 tracking-widest lg:w-[60%] xl:w-[70%] ml-auto leading-8 md:leading-10 ${
                                getFont('ARVIND').heading
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: ' lg:w-[60%] xl:w-[70%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `flex text-[15px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-3 2xl:py-3 px-6 md:px-5  ${
                                        getFont('ARVIND').body
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0'
                },
                backgroundImage:
                    'https://cdn.loyalie.in/1692626196588_refer%20page%20banner',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1692625772205_refer%20page%20mob',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[70vh] object-cover bg-right lg:bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  my-5 tracking-widest  lg:w-[80%] mx-auto  ${
                    //         getFont('ARVIND').body
                    //     }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[45px] font-bold  pb-5  tracking-widest lg:w-[50%] mx-auto  ${
                    //         getFont('ARVIND').heading
                    //     }`
                    // },
                    // {
                    //     type: 'BUTTON',
                    //     content: 'Start Referring Now',
                    //     className: `text-md font-medium ARVIND-button hover:ARVIND-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 lg:w-[20%] mx-auto ${
                    //         getFont('ARVIND').heading
                    //     }`,
                    //     scrollTo: 'referral_section',
                    //     onClick: 'SCROLL'
                    // }
                ]
            }
        ],

        customize: {
            heading: `${getFont('ARVIND').heading}`,
            body: `${getFont('ARVIND').body}`,
            claimButton: `text-md font-bold bg-primary text-white ${
                getFont('ARVIND').heading
            }`
        }
    },
    blogs: {
        components: [
            {
                type: 'BANNER',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[60vh] md:h-[70vh] lg:h-[97vh] lg:h-screen bg-top mx-auto text-center ',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.20'
                },
                backgroundImage: 'https://cdn.loyalie.in/46972415.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/49165600.jpg',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ARVIND PRIVE READERS’ CLUB',
                        className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                            getFont('ARVIND').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    },
                    {
                        type: 'TEXT',
                        content:
                            'Bringing Together Information And Entertainment For You.',
                        className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${
                            getFont('ARVIND').heading
                        }`
                    },
                    {
                        type: 'BUTTON',
                        content: 'Read More',
                        scrollTo: 'blogs_section',
                        onClick: 'SCROLL',
                        className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                            getFont('ARVIND').body
                        }`
                    }
                ]
            }
        ],
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[60vh] md:h-[70vh] lg:h-[97vh] md:bg-top mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.20'
            },
            backgroundImage: 'https://cdn.loyalie.in/46972415.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/49165600.jpg',

            components: [
                {
                    type: 'TEXT',
                    content: 'ARVIND PRIVE READERS’ CLUB',
                    className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                        getFont('ARVIND').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${
                        getFont('ARVIND').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                        getFont('ARVIND').body
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'ARVIND PRIVE READERS’ CLUB',
                    className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                        getFont('ARVIND').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${
                        getFont('ARVIND').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                        getFont('ARVIND').body
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('ARVIND').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('ARVIND').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-[#1c2936] ${
                    getFont('ARVIND').heading
                }`,
                headingClass: `text-[30px] uppercase font-semibold text-primary mt-3 leading-9 break-words ${
                    getFont('ARVIND').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('ARVIND').heading
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('ARVIND').heading
                } `,
                buttonClass: `inline text-[17px] text-primary ml-1 ${
                    getFont('ARVIND').heading
                }`
            },
            sectionHeadings: `px-10 lg:px-16 pt-10 pb-2 text-[22px] lg:text-[25px] xl:text-[35px] tracking-wider text-center uppercase ${
                getFont('ARVIND').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            dividerClass: 'hidden m-0 p-0 ',

            backgroundColor: 'bg-accent',
            cardHeight: ' h-[530px] lg:h-[520px] xl:h-[540px] 3xl:h-[580px] ',
            bodyHeight:
                ' h-[280px] md:h-[280px] lg:h-[270px] xl:h-[290px] 3xl:h-[380px]',
            headingClass: `text-primary my-5 md:my-7 text-[21px] 3xl:text-[20px] font-medium uppercase leading-6 font-semibold break-words mx-5 ${
                getFont('ARVIND').heading
            }`,

            summaryClass: `font-normal text-[16.5px] lg:text-[15px] xl:text-[17px] mt-2 lg:my-6 leading-6 text-secondary mx-5 ${
                getFont('ARVIND').body
            }`,
            categoryClass: 'bg-[#00000075] text-white text-[11px]',
            buttonClass: `bg-primary flex justify-center self-start text-[13px] xl:text-[14px] text-white rounded-md py-2 px-4 md:px-4 m-5 ${
                getFont('ARVIND').body
            }`,
            dateClass: `text-[14px] md:text-[17px] xl:text-[15px]  self-center text-[#919396] ${
                getFont('ARVIND').heading
            }`,
            noBlogsClass: `bg-primary text-white p-2 md:w-[30%] lg:w-[70%] xl:w-[50%] 3xl:w-[30%] mt-10 ${
                getFont('ARVIND').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/36794903.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/30484082.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0'
            },
            negativeMargin: 'lg:-mt-24',
            containerClass: ' text-white w-[50%] ',
            heading: '',
            headingClass: `font-semibold text-center lg:w-[70%] text-[30px] 2xl:text-[45px] text-secondary uppercase ${
                getFont('ARVIND').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('ARVIND').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: ` mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                        getFont('ARVIND').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${
                        getFont('ARVIND').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    onClick: 'FUNC',
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                        getFont('ARVIND').body
                    }`
                }
            ]
        },
        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: `self-center text-center text-[22px] md:text-[24px] lg:text-[25px] xl:text-[35px] text-neutral w-[60%] mt-16 ${
                getFont('ARVIND').heading
            }`,
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('ARVIND').body
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('ARVIND').body
            }`
        },
        eventLayout: {
            titleClass: `text-accent leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('ARVIND').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${
            getFont('ARVIND').heading
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('ARVIND').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content  bg-right bg-cover max-w-full  h-[30vh] lg:h-[45vh] bg-top mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692626308381_deals%20banner',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692629526987_deals%20banner%20mob',
            components: [],
            loginBannerComponents: [
                // {
                //     type: 'TEXT',
                //     content: 'EXCLUSIVE OFFERS AND PROMOTIONS',
                //     className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${getFont('ARVIND').heading
                //         }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Arvind Prive is driven to create experiences that add more value to your life',
                //     className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${getFont('ARVIND').heading
                //         }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC',
                //     className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${getFont('ARVIND').body
                //         }`
                // }
            ]
        },

        dealsSection: {
            sectionBackgroundImage: '',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.89',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center font-semibold md:text-[20px] text-center text-[17px] text-neutral w-[90%] ${
                getFont('ARVIND').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `uppercase text-secondary text-2xl xl:text-3xl ${
                getFont('ARVIND').heading
            } `,
            subAdditionalText:
                'Arvind Prive is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-normal text-[14px] py-2 mt-2 leading-6 ${
                getFont('ARVIND').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('ARVIND').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/43800302.png',
            pattern2: 'https://cdn.loyalie.in/43800302.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.89',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-[18px] leading-6 lg:leading-5 2xl:leading-6 text-primary ${
                getFont('ARVIND').body
            }`,
            listBodyClass: `${getFont('ARVIND').body}`,
            logoClassName: `self-center rounded-md drop-shadow-lg z-10 `,
            titleClassName: ` text-primary font-medium uppercase text-2xl xl:text-xl 2xl:text-2xl leading-7  mt-4 ${
                getFont('ARVIND').body
            } `,
            couponClassName: `block  lg:text-xl self-center text-center text-primary font-semibold leading-7 ${
                getFont('ARVIND').body
            }`,
            dateClassName: ` text-neutral capitalize  text-secondary-focus  ${
                getFont('ARVIND').body
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-[14px]  ${
                getFont('ARVIND').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-neutral text-[12px] break-words leading-1 ${
                getFont('ARVIND').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-primary text-white uppercase self-center rounded text-[16px] py-2.5 px-7  ${
                    getFont('ARVIND').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `bg-primary border-primary border-[2px] hover:bg-white hover:text-primary hover:text-bold self-center text-white rounded uppercase text-[16px] py-[9px] px-7 xl:px-10 ${
                    getFont('ARVIND').heading
                }`
            }
        }
    },
    myProfile: {
        allowOnboarding: false,
        showMyProperty: true,
        appContainerClassName: 'bg-white text-secondary-focus border-primary',
        appFontHeading: `${getFont('ARVIND').body}`,
        appStoreLink:
            'https://apps.apple.com/in/app/arvind-prive/id1638147338?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/search?q=Arvind%20prive&c=apps&hl=en&gl=US&utm_medium=email',
        appScreen: 'https://cdn.loyalie.in/25243853.png'
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.55'
                },
                backgroundImage: 'https://cdn.loyalie.in/61690377.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/61690377.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[50vh] md:h-[60vh] md:bg-right',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ARVIND SmartSpaces PROJECTS',

                        className: ` mx-auto uppercase text-center text-secondary mb-3 text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px] tracking-wider ${
                            getFont('ARVIND').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {
      
            constructionVisible: true,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/46329169.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.97',
            repeat: 'repeat',
            size: 'contain'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/47738787.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/69109900.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center h-[50vh] md:h-[70vh] lg:h-[95vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: ` mx-auto text-center mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]   tracking-wider ${
                            getFont('ARVIND').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[15%] xl:w-[10%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],

    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('ARVIND').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('ARVIND').body
        }`,
        components: [
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('ARVIND').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program Arvind Prive As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('ARVIND').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Arvind SmartSpaces project, and has entered into a registered Agreement to Sell; or (ii) an employee of Arvind ; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Arvind SmartSpaces or any of its sister concerns/subsidiaries/affiliates (“Arvind Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Arvind Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Arvind SmartSpaces or the Project Entity',
                            'Referrer shall not be able to refer existing customer(s) of Arvind SmartSpaces . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Arvind or (ii) is/are already registered with Arvind as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Arvind SmartSpaces’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',
                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Arvind within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Arvind.',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions. Appropriate TDS amount will be deducted as per applicable norms while remitting the referral payments through vouchers.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Arvind or otherwise.',
                            'Employees of Arvind SmartSpaces working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('ARVIND').body
                        }`,
                        classNameContainer: 'px-6 mt-6'
                    },
                    { type: 'RAZORPAY_TERMS', classNameContainer: 'px-6 mt-6' },
                    {
                        type: 'CUSTOM',
                        className: 'px-6 pt-2',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Arvind SmartSpaces, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('ARVIND').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('ARVIND').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('ARVIND').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('ARVIND').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd. In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('ARVIND').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('ARVIND').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('ARVIND').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },

    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content xl:pt-0',
        footerLogoClassName: 'self-center md:w-[15%] lg:w-[16%] lg:pl-3 ',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('ARVIND').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterExpandContent: true,
        loyalieFooterExpandName: 'Disclaimer',
        loyalieFooterExpandClassName: `text-center text-white cursor-pointer pb-2 ${
            getFont('ARVIND').heading
        }`,
        loyalieFooterExpandContentClassName: ` self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-2 xl:pr-6 text-[13px] 3xl:text-[15px] tracking-tight text-white ${
            getFont('ARVIND').body
        }`,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[13.5px] 3xl:text-[15px] tracking-tight text-white ${
            getFont('ARVIND').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-secondary-focus text-extrabold text-[17.px] ${
            getFont('ARVIND').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-80'
    },
    referralForm: {
        name: 'Arvind',
        theme: 'arvindTheme',
        color: '#9f1b3c',
        backgroundImage: 'https://cdn.loyalie.in/21914593.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '52',
            G1: '11',
            B1: '24',
            opacity1: '0.8',
            R2: '255',
            G2: '255',
            B2: '255',
            opacity2: '0.5'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading1: '',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own an',
            employee: true,
            employeeHeading1: 'I am an',
            salesAdmin: true,
            salesAdminHeading1: 'I am an',
            influencer: false,
            influencerHeading: ''
        },
        formContent: {
            heading: '"LET THE MAGICAL JOURNEY UNFOLD"',
            headingClass: `self-center text-white sm:mt-0 text-3xl md:text-4xl 2xl:text-5xl w-[80%] lg:w-[80%] 2xl:w-[80%] text-center leading-10 tracking-wide ${
                getFont('ARVIND').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%239f1b3c'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: false,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,

        isDirectTAndCLink: false,
        termsAndConditionsLink: '/arvind-website/terms-and-conditions',
        customLink: '/arvind-website/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: true,
        footerDisclaimerContent:
            'The sales team at Arvind Smartspaces is not eligible to be a part of the referral program. No Arvind Smartspaces group employee can be submitted as a referral.'
    }
};
export default ArvindConfig;
