import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import { getConfig } from '../../../utils/config';

export const Overlay = ({ visible, onClick }) => {
    return (
        <div
            onClick={onClick}
            className={
                'fixed z-30 inset-0 bg-gray-700 bg-opacity-60 overflow-y-auto h-full w-full' +
                (visible ? ' block' : ' hidden')
            }
            id="my-modal"
        ></div>
    );
};
const   SideModal = ({ className, visible, onClick, children, onlyMobile }) => {
    const config = getConfig();

    return ReactDom.createPortal(
        <>
            <Overlay visible={visible} onClick={onClick} />
            {visible && (
                <div
                    className={` fixed h-screen right-0 top-48 lg:top-0 bottom-0 ${onlyMobile? 'lg:hidden' : ''} ${
                        className
                            ? className
                            : 'w-full lg:w-[25%]'
                    }  ml-auto overflow-y-visible z-40 "`}
                >
                    <div
                        className={` ${
                            className ? '' : 'py-4'
                        } bg-white shadow-sm h-full rounded-t-2xl lg:rounded-none`}
                    >
                        {children}
                    </div>
                </div>
            )}
        </>,
        document.getElementById('sideModal')
    );
};

export default SideModal;
