import {
    useHistory,
    useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import { winnreAuth } from '../../utils/axios';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const useData = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const scParam = queryParams.get('sc');
    const EventName = queryParams.get('eventName');

    const history = useHistory();

    const [userScratchcard, setUserScratchcard] = useState([]);
    const [loadingSC, setLoadingSC] = useState(false);
    const [rewardData, setRewardData] = useState();
    const [openScratchCard, setOpenScratchCard] = useState(
        !!scParam ? 'TRIGGER' : 'CLOSE'
    );

    const userHash = useSelector((state) => state.auth.userHash);
    const userRole = useSelector((state) => state.auth.userRole);

    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    const clickThroughMobile = clientWebview || internalWebview;

    const generateScratchCard = () => {
        if (userRole === 'RELATIONSHIP_MANAGER' || userRole === 'EMPLOYEE') {
            return;
        }
        winnreAuth({
            method: 'post',
            url: `/winnre/user/scratchCard/generate?eventName=${EventName}`
        })
            .then((item) => {
                console.log('sc', item);

                if (item.status === 'FAILED') {
                    console.log('No more Assigned deals');
                    return;
                }
                setOpenScratchCard('OPEN');
                const deal = item.deal;
                const sc = {
                    dealId: deal.id,
                    status: item.status,
                    expiryDate: item.expiry,
                    id: item?.id,
                    title: deal?.title,
                    description: deal?.description,
                    redemptionType: deal?.redemptionType,
                    endDate: deal?.endDate,
                    eligibleCashback: deal?.eligibleCashback,
                    category: deal?.category?.name,
                    tags: deal?.tags?.map((tag) => tag?.name),
                    redemptionCode: deal?.dealRedemptionCode?.dealCode,
                    redeemLink: deal?.dealRedemptionCode.voucherCodeLink,
                    logoUrl: deal?.vendor?.logo?.url,
                    vendorName: deal?.vendor.name,
                    url: deal?.url
                };
                setRewardData(sc);
                console.log('rewardData', item);
            })
            .catch((err) => {
                console.log(err);
                toast.error('Something went wrong. Please try again');
            });
    };

    const getUserScratchcards = () => {
        setLoadingSC(true);
        winnreAuth({
            method: 'GET',
            url: `/winnre/user/scratchCard/getAllForUser?userHash=${userHash}`
        })
            .then((res) => {
                setLoadingSC(false);
                console.log('getAll', res);
                if (res.status === 'APIErrorCondition') {
                    toast.error(res?.message);
                    setUserScratchcard([]);
                    return;
                } else {
                    setUserScratchcard(
                        res.content.map((item) => {
                            const deal = item.deal;
                            return {
                                dealId: deal.id,
                                status: item.status,
                                expiryDate: item.expiry,
                                id: item?.id,
                                title: deal?.title,
                                description: deal?.description,
                                redemptionType: deal?.redemptionType,
                                endDate: deal?.endDate,
                                eligibleCashback: deal?.eligibleCashback,
                                category: deal?.category?.name,
                                tags: deal?.tags?.map((tag) => tag?.name),
                                redemptionCode:
                                    deal?.dealRedemptionCode?.dealCode,
                                redeemLink:
                                    deal?.dealRedemptionCode.voucherCodeLink,
                                logoUrl: deal?.vendor?.logo?.url,
                                vendorName: deal?.vendor.name,
                                url: deal?.url
                            };
                        })
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const updateScratchCardStatus = (id, status, silentUpdate) => {
        winnreAuth({
            method: 'post',
            url: `/winnre/user/scratchCard/updateStatus?id=${id}&status=${status}`
        })
            .then((res) => {
                console.log('Update Status Response:', res);
                if (res.status === 'FAILED') {
                    toast.error('Failed to update status. Please try again');
                } else if (silentUpdate) {
                    return;
                } else {
                    getUserScratchcards();
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Something went wrong. Please try again');
            });
    };
    const rewardRedeemHandler = (data, isReward) => {
        winnreAuth({
            method: 'post',
            url: `winnre/user/deals/redeem-v2?dealId=${
                data.id
            }&sourcePlatform=${clickThroughMobile ? 'MOBILE' : 'WEBSITE'}`
        })
            .then((res) => {
                console.log('reward', res);

                if (isReward) {
                    window?.webengage?.track('DealClicked', {
                        OfferName: `${data?.deal?.title}}`,
                        RedemptionType: `${data?.deal?.redemptionType}`,
                        CashbackPercentage: `${data?.deal?.eligibleCashback}`,
                        CouponCode: `${data?.deal?.dealRedemptionCode?.dealCode}`,
                        Category: `${data?.deal?.category?.name}`,
                        Vendor: `${data?.deal?.vendor?.name}`,
                        Link: `${data?.deal?.url}`,
                        EndDate: `${data?.deal?.endDate}`,
                        Tags: `${data?.deal?.tags?.map((tag) => tag?.name)}`,
                        DealId: `${data?.id}`,
                        VoucherCode:
                            data?.deal?.redemptionType === 'VOUCHER' &&
                            res?.status == 'SUCCESS'
                                ? res?.message
                                : '',
                        Status: res?.status
                        // Channel: channel ? channel : 'Direct'
                    });
                } else {
                    window?.webengage?.track('DealClicked', {
                        OfferName: `${data?.title}`,
                        RedemptionType: `${data?.redemptionType}`,
                        CashbackPercentage: `${data?.eligibleCashback}`,
                        CouponCode: `${data?.redemptionCode}`,
                        Category: `${data?.category}`,
                        Vendor: `${data?.vendorName}`,
                        Link: `${data?.url}`,
                        EndDate: `${data?.endDate}`,
                        Tags: `${data?.tags}`,
                        DealId: `${data?.id}`,
                        VoucherCode:
                            data?.redemptionType === 'VOUCHER' &&
                            res?.status === 'SUCCESS'
                                ? res?.message
                                : '',
                        Status: res?.status
                        // Channel: channel ? channel : 'Direct'
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Something went wrong. Please try again');
            });
    };

    useEffect(() => {
        // if (scParam && EventName) {
        //     queryParams.delete('sc');
        //     queryParams.delete('eventName');
        //     history.replace({
        //         search: queryParams.toString()
        //     });

        generateScratchCard();
        // }
    }, [scParam, EventName]);

    useEffect(() => {
        getUserScratchcards();
    }, []);

    return {
        openScratchCard,
        setOpenScratchCard,
        rewardData,
        loadingSC,
        userScratchcard,
        updateScratchCardStatus,
        rewardRedeemHandler,
        setRewardData
    };
};

export default useData;
