import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { currencyToIndianRsWord } from '../../../../helpers';
import { getConfig } from '../../../../utils/config';

// const locations = [
//   { id: 1, lat: 22.520240438595458, lng: 88.34836542606354 },
//   { id: 2, lat: 21.17254999753366, lng: 83.43662549289871 },
//   { id: 3, lat: 22.796990585243496, lng: 82.92750706741614 },
//   { id: 4, lat: 22.60604796004955, lng:71.8679249175013 },
// ];
const config = getConfig();
const GMaps = ({ locations, viewMapHandler, activeMarker }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyD8g5LLt4YxRR363AOmHEZhGHaUctJIz4Q"
  });

  const [map, setMap] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    if (map && activeMarker) {
      const selectedLocation = locations.find(location => location.id === activeMarker);
      if (selectedLocation) {
        map.setCenter({ lat: parseFloat(selectedLocation.lat), lng: parseFloat(selectedLocation.lng) });
        map.setZoom(16);
      }
    }
  }, [map, activeMarker, locations]);

  useEffect(() => {
    const formattedLocations = locations.map(location => ({
      ...location,
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng)
    }));

    if (isLoaded && formattedLocations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      formattedLocations.forEach(location => {
        const lat = parseFloat(location.lat);
        const lng = parseFloat(location.lng);
        if (!isNaN(lat) && !isNaN(lng) && (lat !== 0 || lng !== 0)) {
          bounds.extend({ lat, lng });
        }
      });

      if (map) {
        map.fitBounds(bounds);
      }
    }
  }, [isLoaded, locations, map]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, [locations]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const currencyFormating = (price) => {
    if (price >= 10000000)
      return currencyToIndianRsWord
        .format(price)
        .slice(0, -2)
        .concat(' Cr*');
    return currencyToIndianRsWord.format(price).slice(0, -1).concat(' L');
  };

  const filteredLocations = locations.filter(location => {
    const lat = parseFloat(location.lat);
    const lng = parseFloat(location.lng);
    return lat !== 0 && lng !== 0;
  });


  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={{ width: '45vw', height: '100vh' }}
        center={
          selectedProject ? selectedProject :
            filteredLocations.length > 0 ? { lat: filteredLocations[0].lat, lng: filteredLocations[0].lng } : { lat: 0, lng: 0 }
        }
        zoom={selectedProject ? 24 : 18}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {filteredLocations.map((location) => {
          const lat = parseFloat(location.lat);
          const lng = parseFloat(location.lng);
          if (lat !== 0 && lng !== 0) {
            return (
              <Marker
                key={location.id}
                position={{ lat, lng }}
                onClick={() => {
                  handleProjectClick(location);
                  viewMapHandler(location.id);
                }} >
                <InfoWindow position={{ lat, lng }} onCloseClick={() => setSelectedProject(null)}>
                  <div onClick={() => {
                    handleProjectClick(location);
                    viewMapHandler(location.id);
                  }}
                    className='select-none cursor-pointer '
                  >
                    <h2 className={`${config.fontBody} font-semibold uppercase md:text-[11px]`}>{location.name} {`(${location.city})`}</h2>
                    <p className={`${config.fontTitle} pt-1 text-center font-semibold md:text-[11px]`}>
                      STARTING:<span className='md:text-[12px]'> {currencyFormating(location.price)}</span>
                    </p>
                  </div>
                </InfoWindow>
              </Marker>
              // options={{
              //   animation: activeMarker === location.id ? window.google.maps.Animation.BOUNCE : null,
              // }}
            );
          } else {
            return null;
          }
        })}
      </GoogleMap>
    </div>
  ) : <></>;
};

export default GMaps;
