import React from 'react';
import styled from 'styled-components';

const P = styled.p`
    ${(props) => props.color && `color: ${props.color} !important;`}
    ${(props) => props.fontSize && `font-size: ${props.fontSize} !important;`}
    ${(props) =>
        props.fontFamily && `font-family: ${props.fontFamily} !important;`}
`;

const S = styled.span`
    ${(props) => props.spanColor && `color: ${props.spanColor} !important;`}
    ${(props) =>
        props.spanFontSize && `font-size: ${props.spanFontSize} !important;`}
    ${(props) =>
        props.spanFontFamily &&
        `font-family: ${props.spanFontFamily} !important;`}
    ${(props) => props.display && `display: ${props.display} !important;`}
`;

function Span(props) {
    const {
        beforeSpan,
        afterSpan,
        betweenSpan,
        spanClassName,
        className,
        color,
        fontFamily,
        fontSize,
        spanFontFamily,
        spanFontSize,
        spanColor,
        display
    } = props;
    return (
        <P
            className={className || ' '}
            fontSize={fontSize}
            fontFamily={fontFamily}
            color={color}
            display={display}
        >
            {beforeSpan}
            <S
                className={spanClassName || ''}
                spanColor={spanColor}
                spanFontFamily={spanFontFamily}
                spanFontSize={spanFontSize}
            >
                {betweenSpan}
            </S>
            {afterSpan}
        </P>
    );
}

export default Span;
