import moment from 'moment';
import React from 'react';

const getStatus = (status) => {
    switch (status) {
        case 'INVOICE_IN_REVIEW':
            return 'Under Review';
        default:
            return status
                ? status
                      .split('_')
                      .join(' ')
                      .replace(/\w\S*/g, function (txt) {
                          return (
                              txt.charAt(0).toUpperCase() +
                              txt.substr(1).toLowerCase()
                          );
                      })
                : 'Enquired';
    }
};

const getStatusColor = (status) => {
    switch (status) {
        case 'CASHBACK_DISBURSED':
        case 'PURCHASE_CONFIRMED':
            return 'text-green-400';
        case 'CASHBACK_REJECTED':
        case 'NOT_INTERESTED':
            return 'text-red-400';
        case 'INVOICE_IN_REVIEW':
            return 'text-yellow-500';
        default:
            return 'text-primary';
    }
};

const getActionButtons = (
    status,
    onNotInterested,
    onInvoiceUpload,
    onReopen,
    loading
) => {
    const ActionButtons = (
        <div className="flex flex-row justify-end">
            <button
                className={
                    'bg-red-400 px-5 mt-2 py-1.5 text-white text-center mr-2 rounded'
                }
                onClick={onNotInterested}
            >
                Not Interested
            </button>
            <button
                onClick={onInvoiceUpload}
                className={
                    'bg-yellow-500 px-5 mt-2 py-1.5 text-white text-center rounded'
                }
            >
                {status === 'CASHBACK_REJECTED' ? 'Re-Upload ' : 'Upload '}
                Invoice
            </button>
        </div>
    );

    const OpenActionButtons = (
        <div className="flex flex-row justify-end">
            <button
                disabled={loading}
                className={
                    'bg-red-400 px-5 mt-2 py-1.5 text-white text-center rounded'
                }
                onClick={onReopen}
            >
                {loading ? 'Loading...' : 'Re-Open'}
            </button>
        </div>
    );

    if (!status) {
        return ActionButtons;
    }
    switch (status) {
        case 'CALL_COMPLETED':
        case 'CALL_SCHEDULED':
        case 'ENQUIRED':
        case 'CASHBACK_REJECTED':
            return ActionButtons;
        case 'NOT_INTERESTED':
            return OpenActionButtons;

        default:
            return null;
    }
};

const EnquiryItem = ({
    enquiry,
    setSelectedDealId,
    setNotInterestedModalVisibility,
    setInvoiceModalVisibility,
    reopen,
    reopenLoading
}) => {
    const onNotInterested = () => {
        setSelectedDealId(enquiry.id);
        setNotInterestedModalVisibility(true);
    };

    const onInvoiceUpload = () => {
        setSelectedDealId(enquiry.id);
        setInvoiceModalVisibility(true);
    };

    const onReopen = () => {
        reopen(enquiry.dealId);
    };

    return (
        <div className="mt-5 p-4 flex flex-col lg:flex-row justify-between rounded border-[2px] border-[#EDEDED]">
            <div className="flex ">
                <img
                    className="h-24 w-24 md:h-20 md:w-20 rounded-lg"
                    src={enquiry?.vendorLogoUrl}
                />
                <div className="pl-3">
                    <h2 className="font-bold">{enquiry?.dealName}</h2>
                    <h3 className="text-xs mt-1">{enquiry?.vendorName}</h3>
                    <p className="lg:block text-xs">
                        {enquiry?.cashbackValue &&
                        enquiry?.cashbackValue > 0 ? (
                            <>
                                Cashback: <b> Rs {enquiry?.cashbackValue}</b>
                            </>
                        ) : (
                            <></>
                        )}
                    </p>
                </div>
            </div>
            <div className="mt-4 lg:mt-0">
                {/* <button className="bg-primary text-white py-2 px-3 rounded">
                                Share Contact
                            </button>
                            <button className="text-[#F02106] underline text-sm lg:self-end">
                                Block Brand
                            </button> */}
                <div className="pl-3 text-right">
                    <span className="text-[#6A7C79] text-xs">
                        {moment(enquiry.enquiryDate).format(
                            'DD MMM, YYYY | hh:mm a'
                        )}
                    </span>
                    <p
                        className={`italic ${getStatusColor(
                            enquiry.enquiryStatus
                        )} font-bold text-sm leading-6`}
                    >
                        {getStatus(enquiry.enquiryStatus)}
                    </p>
                    {getActionButtons(
                        enquiry.enquiryStatus,
                        onNotInterested,
                        onInvoiceUpload,
                        onReopen,
                        reopenLoading
                    )}
                </div>
            </div>
        </div>
    );
};

export default EnquiryItem;
