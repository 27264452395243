import React from 'react';
import { Fragment } from 'react';
import Filter from '../Filter';
import useData from './data';
import Select from 'react-select';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ListTitle = ({ addFilterHandlerNew }) => {
    const { searchProjects } = useData();
    const history = useHistory();
    return (
        <Fragment>
            <div className="flex justify-between border-b-[1.9px] py-6">
                <div className="w-[70%] lg:w-[40%]">
                    <Select
                        placeholder="Search"
                        options={searchProjects?.map((value) => ({
                            label: value?.projectName,
                            value: value?.id
                        }))}
                        onChange={(val) => {
                            history.push(
                                `/projects/detail?projectId=${val.value}`
                            );
                        }}
                        styles={{
                            container: (provided, state) => ({
                                ...provided,
                                color: 'black',
                                width: '100%'
                            }),

                            control: (styles, state) => ({
                                ...styles,
                                opacity: '0.95',
                                height: '100%',
                                color: 'black',
                                border: `1px solid #BFBFBF `,
                                boxShadow: 'none',
                                '&:hover': {
                                    border: '1px solid #BFBFBF'
                                },
                                borderRadius: '10px'
                            }),
                            placeholder: (styles) => ({
                                ...styles,
                                color: '#000000B3',
                                fontFamily: 'inherit',
                                fontWeight: 400
                            })
                        }}
                        className="text-grey-900 placeholder-[#000000B3] font-semibold bg-white text-base text-left h-12"
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                    />
                </div>
                <div className="w-[20%] flex lg:block items-center justify-center">
                    <Filter addFilterHandlerNew={addFilterHandlerNew} />
                </div>
            </div>
        </Fragment>
    );
};

export default ListTitle;
