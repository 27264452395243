import React from 'react';
import moment from 'moment';
import { truncate } from '../../../helpers';
import { getConfig } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import useHomeBlogs from '../BlogLayoutVertical1/data'
import { winnreAuth, winnreNoAuth } from '../../../utils/axios';
import { useSelector } from 'react-redux';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';



function BlogLayoutSlider({ className, classNameContainer, headingClass, summaryClass, tagClass , buttonClass , arrowClass , prevNextArrow, dividerClass , perPage}) {
    const config = getConfig();
    const { blogs } = useHomeBlogs();
    const history = useHistory();

    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    const clickThroughMobile = clientWebview || internalWebview;

    const isUserAuthenticated = useSelector(
        (state) => state.auth.isAuthenticated
    );
    const userId = useSelector((state) => state.auth.userId);
    const blogClickedHandler = (blogId, visibleToAll) => {
        if (isUserAuthenticated) {
            winnreAuth({
                method: 'POST',
                url: `winnre/user/blogs/trackClick?blogId=${blogId}&userId=${userId}&sourcePlatform=${clickThroughMobile ? 'MOBILE' : 'WEBSITE'
                    }`
                // headers: headers2
            })
                .then(() => { })
                .catch(() => { });
        } else {
            winnreNoAuth({
                method: 'POST',
                url: `winnre/user/blogs/trackClick?blogId=${blogId}&sourcePlatform=${clickThroughMobile ? 'MOBILE' : 'WEBSITE'
                    }`
            })
                .then(() => { })
                .catch(() => { });
        }
        if (visibleToAll) {
            history.push(`/blogs/${blogId}`);
        } else {
            history.push(`/blogs`);
        }
    };

    return (

        <>
            <div className={
                    className
                        ? className
                        : 'w-full'
                }>
                <Splide
                    options={{
                        rewind: true,
                        gap: '1rem',
                        autoplay: false,
                        pagination: false,
                        perPage: `${perPage}`,
                        arrows: true,
                        width: '100%',
                        classes: {
                            arrows: `${'splide__arrows your-class-arrows ' + arrowClass}`,
                            arrow: ' your-class-arrow !rounded-0 text-white   ',
                            prev: `${'custom_campaign splide__arrow--prev your-class-prev' + prevNextArrow }`,
                            next: `${'custom_campaign splide__arrow--next your-class-next' + prevNextArrow}`,
                        },

                    }}>
                    {blogs?.map((blog) => {
                        // console.log('v', blog.visibleToAll);
                        return (
                            <SplideSlide key={blog.id} >
                                <div className={classNameContainer? classNameContainer : 'w-full '}>
                                    <div className=''>
                                        <img
                                            src={blog?.displayImageUrl}
                                            className=" w-full h-[500px] rounded-t-sm object-cover"
                                            alt={truncate(blog.heading, 15)}
                                        />
                                    </div>
                                    <div>
                                        <h1 className={`${ tagClass? tagClass : 'uppercase  text-[20px] lg:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal pb-5'} px-6 lg:px-14 xl:px-28`}>{blog.tags.map((obj) => {
                                            return (
                                                ' ' + `${obj.name}` + '  '
                                            );
                                        })}</h1>
                                        <hr className={ dividerClass? dividerClass : 'h-[2px] w-[27%] border-none bg-black' } />

                                        <h2 className={ `${headingClass? headingClass : 'text-[20px] cursor-pointer lg:text-[27px] tracking-wide leading-7 2xl:leading-8 font-normal pt-8'} px-6 lg:px-14 xl:px-28`
                                        } onClick={() =>
                                            blogClickedHandler(blog.id, blog.visibleToAll)
                                        } >{truncate(blog.heading, 15)}</h2>

                                        <h3 className={` ${summaryClass? summaryClass :'text-[20px] lg:text-[25px] tracking-wide leading-7 2xl:leading-8 font-normal  pt-3'} px-6 lg:px-14 xl:px-28`} >{truncate(blog.summary, 20) + '...'}</h3>

                                        <button className={`${buttonClass? buttonClass :'text-white bg-black py-1 px-4 md:py-2 md:px-4  mt-8 mb-12'} mx-6 lg:mx-14 xl:mx-28`} onClick={() =>
                                            blogClickedHandler(blog.id, blog.visibleToAll)
                                        }>
                                            READ MORE
                                        </button>
                                    </div>
                                </div>
                            </SplideSlide>
                        );
                    })}
                </Splide>
                <style >{`
                     .splide__arrow--prev svg , .splide__arrow--next svg {
                     width: 15px;
                     height: 15px;
                     }

                     .splide__arrow--prev:hover svg, .splide__arrow--next:hover svg  {
                        width: 15px;
                        height: 15px;
                        fill:#703E1D;
                       
                     }
                     
                     `}
                </style>

            </div>
        </>
    );
}

export default BlogLayoutSlider;
