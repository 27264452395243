export const copySvg = <svg
className="ml-2"
xmlns="http://www.w3.org/2000/svg"
width="16.391"
height="17.859"
viewBox="0 0 16.391 17.859"
>
<path
    id="copy-copy"
    d="M76.7-4.892H68.5a2.05,2.05,0,0,1-2.049-2.05V-16.6a2.05,2.05,0,0,1,2.049-2.05h8.2a2.05,2.05,0,0,1,2.049,2.05v9.658A2.05,2.05,0,0,1,76.7-4.892ZM77.72-16.6A1.025,1.025,0,0,0,76.7-17.626H68.5A1.025,1.025,0,0,0,67.476-16.6v9.658A1.025,1.025,0,0,0,68.5-5.918h8.2A1.025,1.025,0,0,0,77.72-6.943V-16.6ZM63.378-12.5v9.658A1.025,1.025,0,0,0,64.4-1.817h8.2a1.025,1.025,0,0,0,1.024-1.025V-3.867h1.024v1.025A2.05,2.05,0,0,1,72.6-.792H64.4a2.05,2.05,0,0,1-2.049-2.05V-12.5A2.05,2.05,0,0,1,64.4-14.55h1.024v1.025H64.4A1.025,1.025,0,0,0,63.378-12.5Z"
    transform="translate(-62.354 18.651)"
    fill="#16519e"
/>
</svg>