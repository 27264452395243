import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useBlogsData from '../../../Routes/Blogs/data';
import { truncate } from '../../../helpers';

function VerticalBlogLayout({
    headingClass,
    bodyClass,
    buttonClass,
    className,
    API,
    fetchDashboardBlogs,
    url,
    linearGradient
}) {
    const [blogs, setBlog] = useState([]);
    const history = useHistory();

    const { blogs: dashboardBlogs } = useBlogsData();

    useEffect(() => {
        if (fetchDashboardBlogs && dashboardBlogs.length !== 0) {
            setBlog(dashboardBlogs.slice(0, 5));
        }
    }, [dashboardBlogs]);

    useEffect(() => {
        if (API) {
            axios.get(API).then((res) => {
                // console.log(res.data);
                setBlog(res.data.slice(0, 3));
            });
        }
    }, []);
    return (
        <div className=" w-full px-5 md:px-44 mx-auto lg:px-0 xl:w-[80%] overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-3 gap-x-4 md:gap-x-8 lg:gap-x-6 xl:gap-x-10 3xl:gap-x-16 gap-y-14 ">
            {blogs.map((blog, i) => {
                if (fetchDashboardBlogs) {
                    return (
                        <div
                            className={
                                className
                                    ? className
                                    : 'min-w-[80%] lg:w-full mr-6 lg:mr-0 relative grid lg:h-[500px] 2xl:h-[600px] grid-cols-1 lg:grid-cols-2 bg-white'
                            }
                        >
                            <div
                                className={
                                    `${
                                        i % 2 === 0
                                            ? 'order-last lg:order-1'
                                            : 'order-last lg:order-none'
                                    }` +
                                    ' lg:px-6 m-auto h-auto text-left text-primary'
                                }
                            >
                                <a
                                    onClick={() =>
                                        history.push(`/blogs/${blog.id}`)
                                    }
                                    className="cursor-pointer"
                                >
                                    <p
                                        className={
                                            headingClass
                                                ? headingClass
                                                : 'text-[25px] tracking-wide text-primary px-7 py-4 lg:px-10  pointer lg:py-5 '
                                        }
                                    >
                                        {blog?.heading}
                                    </p>
                                </a>
                                <p
                                    className={
                                        bodyClass
                                            ? bodyClass
                                            : 'text-secondary-focus px-7 py-4 lg:px-10 lg:py-5'
                                    }
                                >
                                    {blog?.summary.slice(0, 250) + '...'}
                                </p>
                                <button
                                    onClick={() =>
                                        history.push(`/blogs/${blog.id}`)
                                    }
                                    className={
                                        buttonClass
                                            ? buttonClass
                                            : ' px-7 py-4 lg:px-10 lg:py-5 primary-text'
                                    }
                                >
                                    Continue Reading...
                                </button>
                            </div>
                            <a
                                onClick={() =>
                                    history.push(`/blogs/${blog.id}`)
                                }
                                className="cursor-pointer"
                            >
                                <img
                                    src={blog?.displayImageUrl}
                                    className="h-full lg:h-[500px] 2xl:h-[600px] w-full object-cover object-center"
                                />
                            </a>
                        </div>
                    );
                } else {
                    return (
                        <div
                            style={{
                                backgroundImage: `linear-gradient(90deg, rgba(${
                                    linearGradient?.R
                                }, ${linearGradient?.G}, ${
                                    linearGradient?.B
                                }, ${linearGradient?.opacity}),rgba(${
                                    linearGradient?.R
                                }, ${linearGradient?.G}, ${
                                    linearGradient?.B
                                }, ${linearGradient?.opacity})), url('${
                                    url || ''
                                }')`,
                                backgroundRepeat: `${
                                    linearGradient?.repeat
                                        ? linearGradient?.repeat
                                        : 'repeat'
                                } `,
                                backgroundSize: `${
                                    linearGradient?.size
                                        ? linearGradient?.size
                                        : '50% auto'
                                } `,
                                backgroundPosition: `${linearGradient?.position}`
                            }}
                            className="text-left min-w-[80vw] lg:min-w-full  bg-white rounded-sm flex flex-col justify-center h-auto shadow-xl hover:shadow-2xl cursor-pointer my-2"
                        >
                            <div className="relative h-full">
                                {' '}
                                <img
                                    src={blog?.featured_image_src}
                                    className=" h-[200px] w-full rounded-t-sm object-cover"
                                    alt="Blog image"
                                />
                            </div>
                            <div className="h-auto">
                                <a
                                    onClick={() =>
                                        history.push({
                                            pathname: `/blogs/${blog.id}`,
                                            state: { url: `${blog.link}` }
                                        })
                                    }
                                    className="cursor-pointer"
                                >
                                    <h1
                                        dangerouslySetInnerHTML={{
                                            __html: truncate(
                                                blog.title?.rendered,
                                                15
                                            )
                                        }}
                                        className={
                                            headingClass
                                                ? headingClass
                                                : 'text-[25px] tracking-wide text-primary px-7 py-4 lg:px-10  pointer lg:py-5 '
                                        }
                                    ></h1>
                                </a>

                                <p
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            blog?.excerpt?.rendered.slice(
                                                0,
                                                250
                                            ) + '...'
                                    }}
                                    className={
                                        bodyClass
                                            ? bodyClass
                                            : 'text-secondary-focus px-7 py-4 lg:px-10 lg:py-5'
                                    }
                                ></p>
                                <button
                                    onClick={() =>
                                        history.push({
                                            pathname: `/blogs/${blog.id}`,
                                            state: { url: `${blog.link}` }
                                        })
                                    }
                                    className={
                                        buttonClass
                                            ? buttonClass
                                            : ' px-7 py-4 lg:px-10 lg:py-5 primary-text'
                                    }
                                >
                                    Continue Reading...
                                </button>
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    );
}

export default VerticalBlogLayout;
