import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ScratchCard2 from '../ReferralPage/Components/Scratchcard2';
import Modal from '../ReferralPage/Components/Modal';
import AiOutlineClose from '@meronex/icons/ai/AiOutlineClose';
import BiCopy from '@meronex/icons/bi/BiCopy';
import EiExternalLink from '@meronex/icons/ei/EiExternalLink';
import RewardCard from './RewardCard';
import SkeletonRewards from '../../Components/Molecules/Skeleton/SkeletonCategoryBar/SkeletonRewards';
import ZeroRewards from './ZeroRewards';
import useData from './data';
import { renderDate } from '../../helpers';
import { copyCodeHandler } from '../../helpers';
import { copySvg } from '../../commonSVG';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { truncateHtml } from '../../helpers';

const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
const isHTML2 = (content) => /<\/?[a-z][\s\S]*>/i.test(content);

const Rewards = () => {
    const {
        openScratchCard,
        setOpenScratchCard,
        rewardData,
        loadingSC,
        userScratchcard,
        updateScratchCardStatus,
        rewardRedeemHandler,
        setRewardData
    } = useData();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const [rewardClass, setRewardClass] = useState('');
    const [copiedText, setCopiedText] = useState(false);
    const [viewStates, setViewStates] = useState({});
    const history = useHistory();

    const toggleView = (cardId) => {
        setViewStates((prevState) => ({
            ...prevState,
            [cardId]: !prevState[cardId]
        }));
    };

    const handleView = (id) => {
        updateScratchCardStatus(id, 'VIEWED');
    };
    const handleRedeem = (data, isReward) => {
        rewardRedeemHandler(data, isReward);
        updateScratchCardStatus(data?.id, 'REDEEMED');
    };
    const handleScratch = (id, silentUpdate) => {
        updateScratchCardStatus(id, 'SCRATCHED', silentUpdate);
    };

    // const copyCodeHandler = async (CouponCode, cardId) => {
    //     if ('clipboard' in navigator) {
    //         await navigator.clipboard.writeText(CouponCode);
    //     }
    //     setCopiedText((prev) => ({ ...prev, [cardId]: true }));

    //     setTimeout(() => {
    //         setCopiedText((prev) => ({ ...prev, [cardId]: false }));
    //     }, 1500);
    // // };

    const description = rewardData?.description || '';
    const isHtmlContent = isHTML2(description);

    const truncatedDescription = isHtmlContent
        ? truncateHtml(description, 135)
        : description.slice(0, 135).replace(/<\/?p>/g, '') + '...';

    return (
        <>
            <div>
                {isAuthenticated && (
                    <div className="w-full md:pl-3 lg:pl-7 2xl:pl-7 ">
                        <div className="flex justify-between flex-wrap">
                            <div className="">
                                <h1 className=" font-Poppins text-xl md:text-2xl font-bold">
                                    Rewards
                                </h1>
                                <h3 className=" font-Poppins pt-1 text-sm md:text-sm">
                                    Manage your Rewards and get your scratch
                                    cards
                                </h3>
                            </div>
                        </div>
                        {userScratchcard.length == 0 && !loadingSC && (
                            <ZeroRewards />
                        )}
                        <div
                            className={`flex flex-wrap items-center gap-10 md:gap-8 lg:gap-4 xl:gap-4 justify-center md:justify-start mt-6 `}
                        >
                            {loadingSC && (
                                <>
                                    {[...Array(4).keys()].map((n) => (
                                        <SkeletonRewards key={n} />
                                    ))}
                                    {[...Array(4).keys()].map((n) => (
                                        <SkeletonRewards key={n} />
                                    ))}
                                </>
                            )}
                            {!loadingSC &&
                                userScratchcard
                                    .filter(
                                        (card) =>
                                            card.status === 'VIEWED' ||
                                            card.status == 'ASSIGNED'
                                    )
                                    .map((card) => (
                                        <div
                                            key={card.id}
                                            onClick={() => {
                                                setRewardData(card);
                                                setOpenScratchCard('OPEN');
                                            }}
                                            className="w-[260px] h-[260px] lg:w-[210px] lg:h-[210px] xl:w-[260px] xl:h-[260px] bg-[#fafafa] "
                                        >
                                            <img src="https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1718014789061_reward" />
                                            {/* <ScratchCard2
                                            
                                            completedAt={100}
                                            onCompleted={() => {
                                                handleScratch(card.id, true);
                                                setRewardClass('z-50');
                                            }}
                                        >
                                            <div
                                                className={`z-50 w-full h-full bg-[#fafafa] border border-gray-300 cursor-default flex flex-col justify-center rounded-2xl ${rewardClass}`}
                                            >
                                                <img
                                                    className=" w-[60px] h-[60px] mx-auto rounded-lg"
                                                    src={card.logoUrl}
                                                />
                                                <h1 className="mt-4 mx-auto text-center text-black font-semibold uppercase text-[13px] leading-4">
                                                    {card.title}
                                                </h1>

                                                {card?.redemptionType ===
                                                    'CODE' && (
                                                    <div className="flex flex-col justify-center w-full">
                                                        <h2 className="uppercase text-gray-600 md:py-2 mt-2 text-center text-xs tracking-widest">
                                                            Coupon Code
                                                        </h2>
                                                        <div className="flex justify-center px-5 mb-2">
                                                            {!copiedText && (
                                                                <div
                                                                    title="Click to copy"
                                                                    onClick={() =>
                                                                        copyCodeHandler(
                                                                            card?.redemptionCode,
                                                                            setCopiedText
                                                                        )
                                                                    }
                                                                    className="border-2 tracking-widest flex items-center bg-[#f0f0f0] text-gray-700 border-[#dcdcdc] border-dashed py-2 px-4 hover:border-blue-900 cursor-pointer my-auto rounded-lg text-[15px] relative"
                                                                >
                                                                    <p className="text-sm">
                                                                        {
                                                                            card.redemptionCode
                                                                        }{' '}
                                                                    </p>
                                                                {copySvg}
                                                                </div>
                                                            )}
                                                            {copiedText && (
                                                                <p
                                                                    className={`self-center mx-auto text-center font-normal animate-pulse bg-[#154F9D1A] text-[#006EB7] py-2 px-12 text-sm rounded text-[15px] md:text-base`}
                                                                >
                                                                    Code Copied
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <button
                                                    onClick={() => {
                                                        if (card?.redemptionType !== 'CODE') {
                                                            history.push(`/deals/${card.dealId}`);
                                                        } else {
                                                            window.open(card?.url); 
                                                        }

                                                        // window.open(card?.url);
                                                    }}
                                                    // className={`text-xs cursor-pointer font-semibold text-[#16519E] w-full flex items-center justify-center`}
                                                    className={`cursor-pointer text-white self-center bg-[#16519E] rounded-full py-2 px-8 font-CorporateSSC_Medium transition-all duration-300 ease-in-out  hover:bg-transparent hover:border-2 hover:border-[#16519E]  hover:text-[#16519E] ${card?.redemptionType !== 'CODE' ? 'mt-4' : 'mt-2'}`}
                                                >
                                                    {/* Click to redeem{' '}
                                                    <EiExternalLink className="w-5 h-5 text-[#16519E]" /> */}
                                            {/* REDEEM NOW
                                                </button>
                                            </div>
                                        </ScratchCard2>  */}
                                        </div>
                                    ))}

                            {!loadingSC &&
                                userScratchcard
                                    .filter(
                                        (card) =>
                                            card.status === 'SCRATCHED' ||
                                            card.status === 'REDEEMED'
                                    )
                                    .map((card) => (
                                        <RewardCard
                                            key={card.id}
                                            card={card}
                                            rewardClass={rewardClass}
                                            toggleView={toggleView}
                                            viewStates={viewStates}
                                            copyCodeHandler={copyCodeHandler}
                                            copiedText={copiedText}
                                            handleRedeem={handleRedeem}
                                            setCopiedText={setCopiedText}
                                        />
                                    ))}
                        </div>
                    </div>
                )}
            </div>

            {openScratchCard === 'OPEN' && (
                <Modal
                    onClick={() => {}}
                    visible
                    opacity="bg-opacity-90"
                    showButton={false}
                >
                    {console.log('Correct modal')}
                    <div className="flex flex-col items-center justify-center">
                        <button
                            onClick={() => {
                                setOpenScratchCard('CLOSE');
                                if (rewardData.status == 'ASSIGNED') {
                                    handleView(rewardData?.id);
                                }
                            }}
                            className="text-white mb-6 font-semibold flex items-center justify-center"
                        >
                            <AiOutlineClose />
                            CLOSE
                        </button>
                        <ScratchCard2
                            foregroundImageSrc="https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1718014789061_reward"
                            completedAt={100}
                            onCompleted={() => {
                                setRewardClass('z-50');
                                handleScratch(rewardData?.id);
                            }}
                            size="h-[370px] w-[370px] "
                            isModalSC
                        >
                            <div
                                className={`bg-gray-100 cursor-default flex flex-col h-[370px] w-[370px] rounded-2xl justify-center px-2 ${rewardClass}`}
                            >
                                <img
                                    className="w-[100px] h-[100px] mx-auto rounded-lg"
                                    src={rewardData?.logoUrl}
                                />

                                <h1 className=" mt-4 mx-auto text-center text-gray-600 font-medium uppercase text-[16px] leading-4 ">
                                    <span>{rewardData?.title}</span>
                                </h1>
                                {isHtmlContent && (
                                    <div
                                        className="text-sm px-5 py-2 text-center break-all"
                                        dangerouslySetInnerHTML={{
                                            __html: truncatedDescription
                                        }}
                                    />
                                )}

                                {!isHTML(rewardData?.description) && (
                                    <p className="text-sm px-5 py-2 text-center break-all">
                                        {rewardData?.description
                                            .slice(0, 135)
                                            .replace(/<\/?p>/g, '')}
                                        ...
                                    </p>
                                )}
                                {rewardData?.redemptionType === 'CODE' && (
                                    <div className="flex flex-col justify-center  w-full">
                                        <h2 className="uppercase text-gray-600 md:py-2  text-center text-xs tracking-widest">
                                            Coupon Code
                                        </h2>
                                        <div className="flex justify-center mb-2">
                                            {!copiedText && (
                                                <div
                                                    title="Click to copy "
                                                    onClick={() =>
                                                        copyCodeHandler(
                                                            rewardData?.redemptionCode,
                                                            setCopiedText
                                                        )
                                                    }
                                                    className="border-2 tracking-widest flex items-center justify-between bg-[#f0f0f0] text-gray-700 border-[#dcdcdc] border-dashed py-2 px-12 hover:border-blue-900 cursor-pointer my-auto rounded-lg text-[15px] relative"
                                                >
                                                    <p className="text-sm">
                                                        {
                                                            rewardData?.redemptionCode
                                                        }
                                                    </p>
                                                    <svg
                                                        className="ml-2"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16.391"
                                                        height="17.859"
                                                        viewBox="0 0 16.391 17.859"
                                                    >
                                                        <path
                                                            id="copy-copy"
                                                            d="M76.7-4.892H68.5a2.05,2.05,0,0,1-2.049-2.05V-16.6a2.05,2.05,0,0,1,2.049-2.05h8.2a2.05,2.05,0,0,1,2.049,2.05v9.658A2.05,2.05,0,0,1,76.7-4.892ZM77.72-16.6A1.025,1.025,0,0,0,76.7-17.626H68.5A1.025,1.025,0,0,0,67.476-16.6v9.658A1.025,1.025,0,0,0,68.5-5.918h8.2A1.025,1.025,0,0,0,77.72-6.943V-16.6ZM63.378-12.5v9.658A1.025,1.025,0,0,0,64.4-1.817h8.2a1.025,1.025,0,0,0,1.024-1.025V-3.867h1.024v1.025A2.05,2.05,0,0,1,72.6-.792H64.4a2.05,2.05,0,0,1-2.049-2.05V-12.5A2.05,2.05,0,0,1,64.4-14.55h1.024v1.025H64.4A1.025,1.025,0,0,0,63.378-12.5Z"
                                                            transform="translate(-62.354 18.651)"
                                                            fill="#16519e"
                                                        />
                                                    </svg>
                                                </div>
                                            )}
                                            {copiedText && (
                                                <p
                                                    className={`self-center mx-auto text-center font-normal animate-pulse bg-[#154F9D1A] text-[#006EB7] py-2 px-12 text-sm rounded text-[15px] md:text-base`}
                                                >
                                                    Code Copied
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {rewardData?.redemptionType !== 'CODE' && (
                                    <p
                                        className={` text-sm text-[#373737] mt-2 text-center`}
                                    >
                                        Valid upto :{' '}
                                        {renderDate(rewardData?.endDate)}
                                    </p>
                                )}
                                <button
                                    onClick={() => {
                                        handleRedeem(rewardData, true);
                                        if (
                                            rewardData?.redemptionType !==
                                            'CODE'
                                        ) {
                                            history.push(
                                                `/deals/${rewardData.dealId}`
                                            );
                                        } else {
                                            window.open(rewardData?.url);
                                        }
                                    }}
                                    className={`cursor-pointer text-white self-center bg-[#16519E] rounded-full py-2 px-8 font-CorporateSSC_Medium transition-all duration-300 ease-in-out  hover:bg-transparent hover:border-2 hover:border-[#16519E]  hover:text-[#16519E] ${
                                        rewardData?.redemptionType !== 'CODE'
                                            ? 'mt-4'
                                            : ''
                                    }`}
                                >
                                    REDEEM NOW
                                </button>
                            </div>
                        </ScratchCard2>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default Rewards;
