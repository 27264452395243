import { Box, Slider, useTheme } from '@mui/material';
import React, { useState } from 'react';
import '../index.css';
import { winnreAuth, winnreNoAuth } from '../../../../utils/axios';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../../store/auth-slice';

const getAmenityIcon = (amenity) => {
    switch (amenity) {
        case 'Swimming Pool':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                >
                    <g
                        id="Group_9457"
                        data-name="Group 9457"
                        transform="translate(-25 -484)"
                    >
                        <circle
                            id="Ellipse_156"
                            data-name="Ellipse 156"
                            cx="18"
                            cy="18"
                            r="18"
                            transform="translate(25 484)"
                            fill="#4d78eb"
                            opacity="0.1"
                        />

                        <g
                            id="swimming_pool_leisure"
                            data-name="swimming pool, leisure"
                            transform="translate(28 485.995)"
                        >
                            <path
                                id="Path_2095"
                                data-name="Path 2095"
                                d="M25.17,37.59a15.9,15.9,0,0,1-10.522.29,19.268,19.268,0,0,0-10.219-.306.547.547,0,1,0,.237,1.069,18.3,18.3,0,0,1,9.714.3,19.706,19.706,0,0,0,5.088.8,18.922,18.922,0,0,0,6.048-1.112.547.547,0,0,0-.346-1.038Z"
                                transform="translate(0 -14.044)"
                                fill="#4d78eb"
                            />

                            <path
                                id="Path_2096"
                                data-name="Path 2096"
                                d="M4.666,21.316a18.311,18.311,0,0,1,9.714.3,19.706,19.706,0,0,0,5.088.8A18.923,18.923,0,0,0,25.516,21.3a.547.547,0,0,0-.346-1.038A17.549,17.549,0,0,1,19.323,21.3V8.194a1.095,1.095,0,0,1,2.189,0,.547.547,0,1,0,1.095,0,2.189,2.189,0,0,0-4.378,0v2.189H10.567V8.194a1.095,1.095,0,1,1,2.189,0,.547.547,0,0,0,1.095,0,2.189,2.189,0,0,0-4.378,0v11.52a20.448,20.448,0,0,0-5.044.532.547.547,0,1,0,.237,1.069Zm5.9-6.554h7.662V16.95H10.567Zm7.662-3.284v2.189H10.567V11.478Zm-7.662,6.567h7.662v3.2a23.013,23.013,0,0,1-3.581-.689,25.62,25.62,0,0,0-4.081-.778Z"
                                transform="translate(0 0)"
                                fill="#4d78eb"
                            />
                        </g>
                    </g>
                </svg>
            );

        case 'Gym':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                >
                    <g
                        id="Group_9458"
                        data-name="Group 9458"
                        transform="translate(-25 -551)"
                    >
                        <g id="Group_949" data-name="Group 949">
                            <circle
                                id="Ellipse_157"
                                data-name="Ellipse 157"
                                cx="18"
                                cy="18"
                                r="18"
                                transform="translate(25 551)"
                                fill="#14897b"
                                opacity="0.1"
                            />

                            <g
                                id="fitness_gym_hotel"
                                data-name="fitness, gym, hotel"
                                transform="translate(23 548)"
                            >
                                <path
                                    id="Path_2098"
                                    data-name="Path 2098"
                                    d="M28.352,16.936h-.734V16.2a2.2,2.2,0,1,0-4.4,0v2.936H17.341V16.2a2.2,2.2,0,1,0-4.4,0v.734H12.2a2.2,2.2,0,0,0-2.2,2.2v4.4a2.2,2.2,0,0,0,2.2,2.2h.734v.734a2.2,2.2,0,0,0,4.4,0V23.543h5.873V26.48a2.2,2.2,0,0,0,4.4,0v-.734h.734a2.2,2.2,0,0,0,2.2-2.2v-4.4A2.2,2.2,0,0,0,28.352,16.936ZM12.2,24.277a.735.735,0,0,1-.734-.734v-4.4A.735.735,0,0,1,12.2,18.4h.734v5.873Zm3.67,2.2a.734.734,0,1,1-1.468,0V16.2a.734.734,0,1,1,1.468,0Zm1.468-4.4V20.607h5.873v1.468Zm8.809,4.4a.734.734,0,1,1-1.468,0V16.2a.734.734,0,1,1,1.468,0Zm2.936-2.936a.735.735,0,0,1-.734.734h-.734V18.4h.734a.735.735,0,0,1,.734.734Z"
                                    fill="#14897b"
                                    stroke="#e7f3f1"
                                    strokeWidth="0.4"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            );

        case 'Spa':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                >
                    <g
                        id="Group_9459"
                        data-name="Group 9459"
                        transform="translate(-25 -741)"
                    >
                        <g id="Group_948" data-name="Group 948">
                            <circle
                                id="Ellipse_158"
                                data-name="Ellipse 158"
                                cx="18"
                                cy="18"
                                r="18"
                                transform="translate(25 741)"
                                fill="#edb600"
                                opacity="0.1"
                            />

                            <g
                                id="_751073_massage_spa_oil_relax_therapy_icon"
                                data-name="751073_massage_spa_oil_relax_therapy_icon"
                                transform="translate(30.446 749.999)"
                            >
                                <path
                                    id="Path_2099"
                                    data-name="Path 2099"
                                    d="M10.863,19.927H7.847A1.291,1.291,0,0,0,6.554,21.22v11.2h5.6V21.22A1.294,1.294,0,0,0,10.863,19.927Zm.4,11.667H7.44V22.057a1.009,1.009,0,0,1,.883-1.1h2.058a1.01,1.01,0,0,1,.883,1.1v9.537Z"
                                    transform="translate(0 -14.276)"
                                    fill="#edb600"
                                />

                                <path
                                    id="Path_2100"
                                    data-name="Path 2100"
                                    d="M13.084,4.846a1.374,1.374,0,0,0,1.229-1.384h.009s.085-1-1.755-3.46c.532,1.229-.378,1.928-.813,2.767a.112.112,0,0,0-.023.044c-.023.043-.045.087-.067.135a1.188,1.188,0,0,0-.05.137c0,.014-.006.028-.009.042a1.326,1.326,0,0,0-.039.492A1.378,1.378,0,0,0,13.084,4.846Zm-.756-1.722c0-.006.006-.013.008-.019a.519.519,0,0,1,.034-.062c.013-.022.028-.041.042-.06a.048.048,0,0,1,.014-.019c.268-.37.752-.639.585-1.264.7,1.307.592,1.778.592,1.778h0a.665.665,0,0,1-1.323-.121A.652.652,0,0,1,12.328,3.124Z"
                                    transform="translate(-3.586)"
                                    fill="#edb600"
                                />

                                <path
                                    id="Path_2101"
                                    data-name="Path 2101"
                                    d="M36.539,21.05a8.266,8.266,0,0,1-3.733,2.124c-.874.2-3.284,1.038-4.052,3.086a8.144,8.144,0,0,0,.739,6.968c-1.329-4.835.821-5.834,2.338-6.689.263-.145.5-.281.714-.413l-.024.018c-2.832,2.007-2.81,3.145-2.826,3.953-.011.573.192.568.447.423a31.329,31.329,0,0,0,3.285-1.481h0a6.541,6.541,0,0,0,1.489-1.079C37.023,25.794,36.539,21.05,36.539,21.05ZM34.1,27.6a3.88,3.88,0,0,1-.452.3,5.868,5.868,0,0,1-2.334.857c-.077-.016-.091-.147-.053-.322a5.578,5.578,0,0,1,1.214-1.5c.11-.094.221-.191.33-.291l0,0h0c.133-.12.261-.243.375-.364l.02-.018c.406-.384.453-.652.447-.778,0-.009,0-.018.005-.027a.17.17,0,0,0-.014-.039c0-.011-.006-.018-.006-.018h0c-.079-.145-.353-.15-.355-.15A8.317,8.317,0,0,0,29.8,27.131s-.005,0-.01.007c-.251.243-.337-.064-.343-.09a2.34,2.34,0,0,1,.451-1.013h0a.111.111,0,0,0,.012-.013c.023-.03.048-.06.073-.09h0a4.768,4.768,0,0,1,2.817-1.818h0a13.138,13.138,0,0,1,1.386-.469l.005,0c.339-.1,1.379-.367,1.379-.367h0c.1-.041.2-.054.19.088C35.7,24.139,35.426,26.542,34.1,27.6Z"
                                    transform="translate(-15.604 -15.081)"
                                    fill="#edb600"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            );

        case 'Outdoor Games':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                >
                    <g
                        id="Group_9463"
                        data-name="Group 9463"
                        transform="translate(-25 -873)"
                    >
                        <g id="Group_946" data-name="Group 946">
                            <circle
                                id="Ellipse_160"
                                data-name="Ellipse 160"
                                cx="18"
                                cy="18"
                                r="18"
                                transform="translate(25 873)"
                                fill="#1cb0bd"
                                opacity="0.1"
                            />
                        </g>

                        <g
                            id="_172468_ball_soccer_football_icon"
                            data-name="172468_ball_soccer_football_icon"
                            transform="translate(34 882)"
                        >
                            <rect
                                id="Rectangle_1060"
                                data-name="Rectangle 1060"
                                width="18"
                                height="18"
                                fill="none"
                            />

                            <g
                                id="Group_956"
                                data-name="Group 956"
                                transform="translate(0.737 0.737)"
                            >
                                <path
                                    id="Path_2303"
                                    data-name="Path 2303"
                                    d="M10.479,2a8.479,8.479,0,1,0,8.479,8.479A8.479,8.479,0,0,0,10.479,2Z"
                                    transform="translate(-2 -2)"
                                    fill="none"
                                    stroke="#1cb0bd"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="10"
                                    strokeWidth="1"
                                />
                            </g>

                            <path
                                id="Path_2304"
                                data-name="Path 2304"
                                d="M16.242,22.4,14.8,17.951,18.57,15.2l3.77,2.748L20.9,22.4Z"
                                transform="translate(-9.345 -9.599)"
                                fill="none"
                                stroke="#1cb0bd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <path
                                id="Path_2305"
                                data-name="Path 2305"
                                d="M22.738,3.788l-3.212,1.37-3.211-1.37"
                                transform="translate(-10.301 -2.392)"
                                fill="none"
                                stroke="#1cb0bd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <path
                                id="Path_2306"
                                data-name="Path 2306"
                                d="M4.247,10.106l.309,3.488L2.263,16.234"
                                transform="translate(-1.429 -6.381)"
                                fill="none"
                                stroke="#1cb0bd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <path
                                id="Path_2307"
                                data-name="Path 2307"
                                d="M5.576,36.957l3.4.783,1.795,3"
                                transform="translate(-3.521 -23.333)"
                                fill="none"
                                stroke="#1cb0bd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <path
                                id="Path_2308"
                                data-name="Path 2308"
                                d="M30.388,40.741l1.793-3,3.4-.783"
                                transform="translate(-19.186 -23.331)"
                                fill="none"
                                stroke="#1cb0bd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <path
                                id="Path_2309"
                                data-name="Path 2309"
                                d="M43.863,16.233l-2.295-2.641.308-3.486"
                                transform="translate(-26.245 -6.381)"
                                fill="none"
                                stroke="#1cb0bd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <line
                                id="Line_205"
                                data-name="Line 205"
                                y2="2.838"
                                transform="translate(9.225 2.767)"
                                fill="none"
                                stroke="#1cb0bd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <line
                                id="Line_206"
                                data-name="Line 206"
                                y1="1.251"
                                x2="2.328"
                                transform="translate(12.995 7.212)"
                                fill="none"
                                stroke="#1cb0bd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <line
                                id="Line_207"
                                data-name="Line 207"
                                x2="1.44"
                                y2="1.607"
                                transform="translate(11.556 12.799)"
                                fill="none"
                                stroke="#1cb0bd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <line
                                id="Line_208"
                                data-name="Line 208"
                                x1="1.44"
                                y2="1.608"
                                transform="translate(5.457 12.799)"
                                fill="none"
                                stroke="#1cb0bd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <line
                                id="Line_209"
                                data-name="Line 209"
                                x2="2.329"
                                y2="1.138"
                                transform="translate(3.127 7.214)"
                                fill="none"
                                stroke="#1cb0bd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />
                        </g>
                    </g>
                </svg>
            );

        case 'Indoor Games':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                >
                    <g
                        id="Group_9462"
                        data-name="Group 9462"
                        transform="translate(-25 -807)"
                    >
                        <g id="Group_892" data-name="Group 892">
                            <g id="Group_947" data-name="Group 947">
                                <circle
                                    id="Ellipse_159"
                                    data-name="Ellipse 159"
                                    cx="18"
                                    cy="18"
                                    r="18"
                                    transform="translate(25 807)"
                                    fill="red"
                                    opacity="0.1"
                                />
                            </g>
                        </g>

                        <g
                            id="Layer_2"
                            data-name="Layer 2"
                            transform="translate(32.696 813.86)"
                        >
                            <path
                                id="Path_2302"
                                data-name="Path 2302"
                                d="M18.58,12.547a7.192,7.192,0,0,0-1.019-8.458A6.749,6.749,0,0,0,12.744,2.14h0a8.255,8.255,0,0,0-8.078,8.078,6.979,6.979,0,0,0,.567,2.772l-2.657,1.9A3,3,0,0,0,4.347,20.35h.23a3,3,0,0,0,2.2-1.249l1.913-2.657a7.086,7.086,0,0,0,2.772.567,7.617,7.617,0,0,0,2.507-.434,3.091,3.091,0,1,0,4.641-4ZM8.333,5.816a6.315,6.315,0,0,1,4.429-1.9h0A4.986,4.986,0,0,1,16.3,5.346a5.42,5.42,0,0,1,.673,6.412,3.056,3.056,0,0,0-.425,0,3.1,3.1,0,0,0-3.011,2.383L7.04,7.649A6.59,6.59,0,0,1,8.333,5.816ZM5.313,18.065a1.231,1.231,0,0,1-.886.514,1.2,1.2,0,0,1-.974-.354A1.213,1.213,0,0,1,3.1,17.25a1.231,1.231,0,0,1,.514-.886L6.163,14.54a6.537,6.537,0,0,0,.469.523,6.4,6.4,0,0,0,.523.469Zm2.551-4.278a4.978,4.978,0,0,1-1.426-3.543,5.687,5.687,0,0,1,0-.629l5.571,5.571A5.093,5.093,0,0,1,7.863,13.787Zm8.671,2.4a1.329,1.329,0,1,1,1.329-1.329A1.329,1.329,0,0,1,16.534,16.187Z"
                                transform="translate(0 0)"
                                fill="red"
                                stroke="#ffe5e5"
                                strokeWidth="0.8"
                            />
                        </g>
                    </g>
                </svg>
            );

        default:
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                >
                    <g
                        id="Group_9461"
                        data-name="Group 9461"
                        transform="translate(-25 -1007)"
                    >
                        <circle
                            id="Ellipse_378"
                            data-name="Ellipse 378"
                            cx="18"
                            cy="18"
                            r="18"
                            transform="translate(25 1007)"
                            fill="#edb600"
                            opacity="0.1"
                        />

                        <g
                            id="_7246417_star_sky_night_moon_christmas_icon"
                            data-name="7246417_star_sky_night_moon_christmas_icon"
                            transform="translate(-411 597.68)"
                        >
                            <path
                                id="Path_2290"
                                data-name="Path 2290"
                                d="M450.765,673.889l-1.926.9-1.133,1.8-.9-1.926-1.8-1.133,1.926-.9,1.133-1.8.905,1.926Z"
                                transform="translate(0 -244.59)"
                                fill="none"
                                stroke="#edb600"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <path
                                id="Path_2291"
                                data-name="Path 2291"
                                d="M727.845,800.949l-1.926.905-1.133,1.8-.9-1.926-1.8-1.133,1.926-.9,1.133-1.8.905,1.926Z"
                                transform="translate(-268.389 -367.664)"
                                fill="none"
                                stroke="#edb600"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />

                            <path
                                id="Path_2292"
                                data-name="Path 2292"
                                d="M587.336,424.637l-3.977,1.869-2.341,3.719-1.869-3.977-3.719-2.34,3.977-1.869,2.34-3.719,1.869,3.977Z"
                                transform="translate(-126.339 0)"
                                fill="none"
                                stroke="#edb600"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                            />
                        </g>
                    </g>
                </svg>
            );
    }
};
function StepTwo({
    config,
    amenities,
    setStep,
    propertyId,
    setAlertMessage,
    setShowErrorAlert,
    showErrorAlert,
    alertMessage,
    userProperty
}) {
    const [onboardData, setOnboardData] = useState({
        propertyUse: null,
        takenHomeLoan: null,
        familyMemberCount: 1,
        preferReFinanceOffers: null,
        interestedAmenities: []
    });
    const theme = useTheme();
    const dispatch = useDispatch();
    const familyMemberCountHandler = (e) => {
        setOnboardData({ ...onboardData, familyMemberCount: e.target.value });
    };
    const propertyUseHandler = (e) => {
        setOnboardData({ ...onboardData, propertyUse: e.target.value });
    };
    const takenHomeLoanHandler = (e) => {
        setOnboardData({ ...onboardData, takenHomeLoan: e.target.value });
    };
    const preferReFinanceOffersHandler = (e) => {
        setOnboardData({
            ...onboardData,
            preferReFinanceOffers: e.target.value
        });
    };
    const closeModal = () => {
        window?.webengage?.track('OnboardingFormSkipped');
        dispatch(authActions.closeOnboardingModal());
    };
    const interestedAmenitiesHandler = (id) => {
        if (onboardData.interestedAmenities.includes(id)) {
                const updatedArray = onboardData.interestedAmenities.filter((value) => value!=id
                );
                setOnboardData({
                    ...onboardData,
                    interestedAmenities: updatedArray
                });
            
        } else {
            setOnboardData({
                ...onboardData,
                interestedAmenities: [...onboardData.interestedAmenities, id]
            });
        }
    };
    const isEmpty = Object.values(onboardData).some((val) => val == null || val == '')
    const onboardSubmissionHandler = () => {
        if(isEmpty) {
            setAlertMessage('Please answer all questions')
            setShowErrorAlert(true)
            return
        }
        winnreAuth({
            method: 'POST',
            url: `/winnre/user/property/on-board-data/${propertyId}`,
            data: JSON.stringify({
                familyMemberCount: onboardData?.familyMemberCount,
                interestedAmenities: onboardData?.interestedAmenities,
                preferReFinanceOffers: onboardData?.preferReFinanceOffers,
                propertyUse: onboardData?.propertyUse,
                takenHomeLoan: onboardData?.takenHomeLoan
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            if (res?.status === 'INVALID_TOKEN' || res?.status === 'FAILED') {
                setAlertMessage(res?.message);
                setShowErrorAlert(true);
                return;
            }
            window?.webengage?.track('PropertyOnboardingCompleted');
            dispatch(
                authActions.setProfileCompletionPercentage({
                    userDetails: {
                        mobileCountryCode: res?.mobileCountryCode,
                        mobileNumber: res?.mobileNumber,
                        email: res?.email,
                        status: res?.status,
                        preferredName: res?.preferredName,
                        birthDate: res?.dateOfBirth
                    },
                    profileCompletionPercentage:
                        res?.profileCompletionPercentage
                })
            );
            setStep(3);
        });
    };
    if(!userProperty) {
        return <div>Your properties have not been onboarded yet.</div>
    }
    return (
        <>
            {userProperty && <div className="overflow-y-scroll h-96 font-Poppins">
                <div className="flex mt-4 lg:mt-0">
                    <p className="text-primary mr-2 font-semibold ">01.</p>
                    <div>
                        <p className="text-sm lg:text-base">
                            Is this for self use, or are you planning to rent it
                            out?
                        </p>
                        <div className="flex mt-4 gap-x-4">
                            <button
                                onClick={(e) => propertyUseHandler(e)}
                                className={`w-24 form-button ${
                                    onboardData?.propertyUse === 'SELF_USE'
                                        ? 'bg-primary'
                                        : 'bg-gray-400 opacity-90'
                                } `}
                                value="SELF_USE"
                            >
                                Self Use
                            </button>
                            <button
                                onClick={(e) => propertyUseHandler(e)}
                                className={`w-24 form-button ${
                                    onboardData?.propertyUse === 'INVESTMENT'
                                        ? 'bg-primary'
                                        : 'bg-gray-400 opacity-90'
                                }`}
                                value="INVESTMENT"
                            >
                                Rent Out
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex mt-4 lg:mt-6">
                    <p className="text-primary mr-2 font-semibold">02.</p>
                    <div>
                        <p className="mb-12 text-sm lg:text-base">
                            How many family members will be living here?
                        </p>
                        <Box
                            sx={{
                                [theme.breakpoints.down('md')]: {
                                    width: 200
                                },
                                width: 300
                            }}
                        >
                            <Slider
                                aria-label="Family Member Count"
                                defaultValue={1}
                                valueLabelDisplay="on"
                                step={1}
                                onChange={(e) => familyMemberCountHandler(e)}
                                marks={[
                                    { value: 1, label: 1 },
                                    { value: 2, label: 2 },
                                    { value: 3, label: 3 },
                                    { value: 4, label: 4 },
                                    { value: 5, label: 5 },
                                    { value: 6, label: 6 },
                                    { value: 7, label: 7 },
                                    { value: 8, label: 8 }
                                ]}
                                min={1}
                                max={8}
                            />
                        </Box>
                    </div>
                </div>
                <div className="flex mt-4 lg:mt-6">
                    <p className="text-primary mr-2 font-semibold">03.</p>
                    <div>
                        <p className="mb-4 text-sm lg:text-base">
                            Which amenities are you interested in using?
                        </p>
                        <div className="flex gap-x-2 flex-wrap gap-y-2 ">
                            {amenities?.map((amenity) => (
                                <div
                                    className={`flex border-[2px] rounded items-center px-1 lg:px-2 py-1 ${
                                        onboardData.interestedAmenities.includes(
                                            amenity.id
                                        )
                                            ? 'border-gray-600'
                                            : 'border-[#e0dad7]'
                                    }`}
                                    onClick={() =>
                                        interestedAmenitiesHandler(
                                            amenity.id
                                        )
                                    }
                                >
                                    {getAmenityIcon(amenity.title)}
                                    <button
                                        
                                        className="p-1 lg:p-2 text-sm lg:text-base rounded cursor-pointer"
                                    >
                                        {amenity.title}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex mt-4 lg:mt-6">
                    <p className="text-primary mr-2 font-semibold">04.</p>
                    <div>
                        <p className="text-sm lg:text-base">
                            Have you taken home loan for this property?
                        </p>
                        <div className="flex mt-4 gap-x-4">
                            <button
                                value={true}
                                onClick={(e) => takenHomeLoanHandler(e)}
                                className={`w-20 form-button ${
                                    onboardData?.takenHomeLoan == 'true'
                                        ? 'bg-primary'
                                        : 'bg-gray-400 opacity-90'
                                }`}
                            >
                                Yes
                            </button>
                            <button
                                value={false}
                                onClick={(e) => takenHomeLoanHandler(e)}
                                className={`w-20 form-button ${
                                    onboardData?.takenHomeLoan == 'false'
                                        ? 'bg-primary'
                                        : 'bg-gray-400 opacity-90'
                                }`}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex mt-4 lg:mt-6">
                    <p className="text-primary mr-2 font-semibold">05.</p>
                    <div>
                        <p className="text-sm lg:text-base">
                            Are you interested in re-finance offers?
                        </p>
                        <div className="flex mt-4 gap-x-4">
                            <button
                                value={true}
                                onClick={(e) => preferReFinanceOffersHandler(e)}
                                className={`w-20 form-button ${
                                    onboardData?.preferReFinanceOffers == 'true'
                                        ? 'bg-primary'
                                        : 'bg-gray-400 opacity-90'
                                } `}
                            >
                                Yes
                            </button>
                            <button
                                value={false}
                                onClick={(e) => preferReFinanceOffersHandler(e)}
                                className={`w-20 form-button ${
                                    onboardData?.preferReFinanceOffers ==
                                    'false'
                                        ? 'bg-primary'
                                        : 'bg-gray-400 opacity-90'
                                } `}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div>}

            <div
                className={`flex justify-between
                 mt-4 lg:mt-7`}
            >
                <button
                    onClick={closeModal}
                    className={` ${config?.loginModal.buttonBg} form-button w-24 lg:w-28`}
                >
                    {/* {propertyId ? 'Cancel' : 'Close'} */}
                    Cancel
                </button>

                <button
                    onClick={onboardSubmissionHandler}
                    disabled={isEmpty}
                    className={` ${config?.loginModal.buttonBg} form-button w-24 lg:w-28 disabled:opacity-90 disabled:bg-gray-400 disabled:cursor-not-allowed`}
                >
                    Next
                </button>
            </div>
            {showErrorAlert && (
                <div class="alert alert-error w-full animate-pulse h-auto mt-2 ">
                    <label>{alertMessage}</label>
                </div>
            )}
        </>
    );
}

export default StepTwo;
