import { useEffect, useState } from 'react';
import Accordian from '../../Molecules/Accordian';
import Map from '../../Molecules/Map/smallMapIndex';
import useData from '../PropertyMapView/data';
import React from 'react';
import AmenityAccordianNew from '../../Molecules/AmenityAccordian/newIndex';
import AccordianNew from '../../Molecules/Accordian/newIndex';
import { getConfig } from '../../../../utils/config';
import SingleGMaps from '../../Molecules/Map/singleGmaps';
export default function ProjectLocationNew({
    projectId,
    projectDetails,
    highlightCategories,
    projectAdditionalHighlights,
}) {
    const { lng, lat, viewport, setViewport } = useData(projectId);
    const [location, setLocation] = useState();
    const config = getConfig()
    useEffect(() => {
        if (projectDetails.projectLocation !== null)
            setLocation(projectDetails.projectLocation);
    }, [projectDetails]);


    const [highlights, setHighlights] = useState(highlightCategories);

    useEffect(() => {
        if (highlightCategories !== highlights && highlightCategories.length !== 0)
            setHighlights(highlightCategories);
        if (projectAdditionalHighlights !== undefined) {
            let additional = [];
            Object.values(projectAdditionalHighlights).map((addHigh) => {
                addHigh[0] = {
                    ...addHigh[0],
                    name: addHigh[0].title
                };
                additional = [...additional, ...addHigh];
            });
            if (additional?.length > 0)
                highlightCategories['Additional Highlights'] = additional;
            setHighlights(highlightCategories);
        }
    }, [highlightCategories, projectAdditionalHighlights]);


    return (
        <div id='location_highlights' className="flex flex-col mt-8">
            <div
                className="z-20 text-black  "
            >
                <div className="w-full flex flex-col">
                    <h4 className={`z-10 text-[#130E2F] text-xl font-bold ${config.fontTitle}`}>Location Highlights</h4>
                </div>
                <div className={` ${config.fontBody} grid grid-cols-1 lg:grid-cols-2 mt-4`}>
                    {projectDetails.projectLocation !== null &&
                        <SingleGMaps
                            location={location}
                            viewport={viewport}
                            setViewport={setViewport}
                            projectName={projectDetails.projectName}
                            projectPrice={projectDetails.price}
                            projectCity={projectDetails.city}
                        />
                    }

                    {/* <Map
                        location={location}
                        lng={lng}
                        lat={lat}
                        viewport={viewport}
                        setViewport={setViewport}
                    /> */}
                    {Object.keys(highlights).length === 0 && (
                        <p className={`${projectDetails.projectLocation !== null ? 'm-6 text-center ' : ' text-left' } text-lg text-[#130E2F]`}>
                            Soon updating with all facilities in vicinity
                        </p>
                    )}
                    {Object.keys(highlights).length >= 1 && (
                        <div>
                            <AccordianNew
                                highlightCategories={highlights}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
