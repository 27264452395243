import React, { useEffect, useState } from 'react';
import FaWhatsapp from '@meronex/icons/fa/FaWhatsapp';
import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '../../store/auth-slice';
import { toast } from 'react-toastify';

function PageNotFound() {
    const dispatch = useDispatch();
    const [submitWE, setSubmitWE] = useState(false);
    const { isAuthenticated } = useSelector((state) => state.auth);

    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
        setSubmitWE(true);
    };

    const getLink = () => {
        if (isAuthenticated) {
            setSubmitWE(true);
        } else {
            openLoginModal();
        }
    };

    useEffect(async () => {
        if (isAuthenticated && submitWE) {
            await window?.webengage?.track('RequestedAppLink');
            toast.success('You will receive the link shortly.');
            setSubmitWE(false);
        }
    }, [isAuthenticated, submitWE]);

    if (
        !window.reloy_env.client_config.MY_PROFILE?.PLAY_STORE_LINK &&
        !window.reloy_env.client_config.MY_PROFILE?.APP_STORE_LINK
    )
        return (
            <div className="h-full lg:h-screen flex flex-col-reverse lg:flex-row items-center justify-center">
                <div className="flex flex-col justify-center items-center mt-7 lg:mt-0">
                    <h1 className="font-bold text-5xl lg:text-5xl text-center lg:text-left">
                        404
                    </h1>
                    <p className="mt-2">Page not found!</p>
                </div>
            </div>
        );
    return (
        <div className="h-full lg:h-screen flex flex-col-reverse lg:flex-row items-center justify-center">
            <div className="flex flex-col justify-center items-center mt-7 lg:mt-0">
                <h1 className="font-bold text-2xl lg:text-3xl text-center lg:text-left">
                    Get the latest updates for your property
                </h1>
                <h2 className="text-lg lg:text-xl">
                    Payments, Construction updates and More
                </h2>
                <p className="text-lg lg:text-xl mt-7 font-semibold">
                    Download the app today!
                </p>
                <div className="flex gap-x-4 mt-2">
                    {window.reloy_env.client_config.MY_PROFILE
                        ?.PLAY_STORE_LINK && (
                        <div
                            onClick={() =>
                                window.open(
                                    window.reloy_env.client_config.MY_PROFILE
                                        ?.PLAY_STORE_LINK
                                )
                            }
                            className=" cursor-pointer"
                        >
                            <img
                                className=" w-[150px]"
                                src="https://cdn.loyalie.in/21166287.png"
                            />
                        </div>
                    )}
                    {window.reloy_env.client_config.MY_PROFILE
                        ?.APP_STORE_LINK && (
                        <div
                            onClick={() =>
                                window.open(
                                    window.reloy_env.client_config.MY_PROFILE
                                        ?.APP_STORE_LINK
                                )
                            }
                            className=" cursor-pointer"
                        >
                            <img
                                className=" w-[150px]"
                                src="https://cdn.loyalie.in/53219008.png"
                            />
                        </div>
                    )}
                </div>
                <div className="relative my-12 w-1/2">
                    <hr className="h-[2px] bg-[#D1D1D3] text-[#D1D1D3] w-full" />
                    <div className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white text-gray-400 font-semibold rounded-full border-2 border-[#D1D1D3] inline p-2">
                        OR
                    </div>
                </div>
                <p className="font-semibold">Receive link on Whatsapp?</p>
                <button
                    className="mt-2 flex items-center bg-[#009545] text-white py-2 px-4 rounded-lg"
                    onClick={getLink}
                >
                    <FaWhatsapp className="w-6 h-6 mr-2" />
                    Get link on Whatsapp
                </button>
            </div>
            <div className=" flex justify-center w-full lg:w-2/5 h-[500px] mt-12">
                <img
                    src={window.reloy_env.client_config.MY_PROFILE?.APP_SCREEN}
                    className="h-full "
                />
            </div>
        </div>
    );
}

export default PageNotFound;
