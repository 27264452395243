import GeraImg from '../../assets/ReferralForm/Gera.jpg';

const GeraFormConfig = {
    clientId:
        '453938a2652120520921743e1c7d58b5514e6fb5145aceae929361652ffeadc3',
    id: 4,
    name: 'Gera',
    theme: 'geraTheme',
    favicon: 'https://cdn.loyalie.in/86902123.png',
    logo: {
        url: '',
        stickyNavBarLogoClass: 'hidden',
        baseNavBarLogoClass: 'hidden',
        name: 'logo'
    },
    facebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },
    navBarConfig: {
        showLogin: false,

        font: ``,
        height: 'h-0',
        mobileIconColor: '',
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: '',
            containerClass: '',
            itemClass: ''
        },
        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: '',
            containerClass: '',
            itemClass: ''
        },
        showActiveLinkColor: false
    },
    floatingButton: false,
    showFooterRoutes: false,
    routes: {
        home: {
            name: '',
            path: '/',
            show: true,
            components: [
                {
                    type: 'REFERRAL_FORM_MODULE'
                }
            ]
        },
        refer: {
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn',
            show: false
        },

        events: {
            name: 'EVENTS',
            path: '/events',
            component: 'Events',
            show: false,
            layout: 'Layout2'
        },
        blogs: {
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs',
            show: false
        },
        deals: {
            name: 'OFFERS',
            authenticatedRoute: true,
            path: '/deals',
            component: 'DealsAndDiscounts',
            show: false
        },
        projects: {
            name: 'PROJECTS',
            authenticatedRoute: false,
            path: '/projects',
            component: 'Projects',
            show: false
        },
        contactUs: {
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us',
            show: false,
            layout: 'Layout2'
        },
        termsAndConditions: {
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions',
            show: false
        }
    },

    referralForm: {
        name: 'Gera',
        theme: 'geraTheme',
        color: '#A88470',
        backgroundImage: 'https://cdn.loyalie.in/77176318.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '20',
            G1: '20',
            B1: '20',
            opacity1: '0',
            R2: '20',
            G2: '20',
            B2: '20',
            opacity2: '0'
        },

        userTypes: {
            length: 2,
            siteVisitor: false,
            siteVisitorHeading1: '',
            siteVisitorHeading2: '',

            bookedCustomer: true,
            bookedCustomerHeading1: '',
            bookedCustomerHeading2: '',

            employee: true,
            employeeHeading1: '',
            employeeHeading2: '',

            salesAdmin: false,
            salesAdminHeading1: '',
            salesAdminHeading2: '',

            influencer: false,
            influencerHeading1: '',
            influencerHeading2: ''
        },
        formContent: {
            heading: ' ',
            headingClass: ``,
            notification: {
                line1: ' ',
                line1Class: ``,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            }
        },
        leadEmailMandatory: true,
        sendReferralAttemptNotification: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink:
            'https://cluboutdo.gera.in/terms-and-conditions/',
        customLink: `/gera-website/projects/detail`,
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default GeraFormConfig;
