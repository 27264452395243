import React from 'react';
import { getConfig, routePathMap } from '../../../utils/config';
import { NavLink } from 'react-router-dom';
import AppDownload from '../../Molecules/AppDownload';
function FooterNavBar() {
    const config = getConfig();
    const routes = window.reloy_env.client_config.NAVIGATION_FOOTER || {};

    // console.log(routes, 'testing');
    return (
        <>
            <div className={'bg-secondary-content text-white'}>
                {window.reloy_env.client_config.MY_PROFILE?.PLAY_STORE_LINK ||
                window.reloy_env.client_config.MY_PROFILE?.APP_STORE_LINK ? (
                    <AppDownload />
                ) : (
                    ''
                )}
                <div
                    className={
                        'bg-secondary-content text-white flex justify-center pt-5 pb-3 '
                    }
                >
                    <ul>
                        <li>
                            {Object.keys(routes)
                                .sort(
                                    (a, b) => routes[a].order - routes[b].order
                                )
                                .map((page, i) => (
                                    <>
                                        {i > 0 && (
                                            <button className="px-3">|</button>
                                        )}
                                        <NavLink
                                            key={routes[page].name}
                                            to={
                                                routes[page].path ||
                                                routePathMap[page] ||
                                                '/'
                                            }
                                            className={`${config?.navBarConfig.footerNavItemClass}`}
                                        >
                                            {routes[page].name}
                                        </NavLink>
                                    </>
                                ))}
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default FooterNavBar;
