import IosArrowBack from '@meronex/icons/ios/IosArrowBack';
import React, { useEffect, useState } from 'react';
import { getConfig } from '../../utils/config';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import StepOne from './Components/StepOne';
import StepThree from './Components/StepThree';
import StepFour from './Components/StepFour';
import moment from 'moment';
import { toast } from 'react-toastify';
import { winnreAuth } from '../../utils/axios';
import StepTwo from './Components/StepTwo';
import { LoadingSpinner } from '../../Components/Molecules/LoadingUI';

const HomeLoans = () => {
    const config = getConfig();
    const location = useLocation();
    const dealRedemptionId = location.state?.dealRedemptionId;
    const [step, setStep] = useState(1);
    const [loading,setLoading]=useState(false);
    const [formData, setFormData] = useState({
      employmentType: 'SALARIED',
      loanAmount: 'Upto ₹25 Lacs',
      tenure: '5 to 10 yrs',
      gender: 'FEMALE',
      duration: '1-2 (Months)',
      mobile: '',
      birthDate: null,
      pin: null,
      formattedBirthDate: null,
      loanUpperLimit: null,
      plannedLoanDate: null,
      tenureInMonths: null
  });

  useEffect(() => {
    if (formData.birthDate) {
        const formattedDate = moment(formData.birthDate).format('YYYY-MM-DD');
        setFormData(prevData => ({ ...prevData, formattedBirthDate: formattedDate }));
    }
}, [formData.birthDate]);

  useEffect(() => {
      const calculatePlannedLoanDate = (duration) => {
        const currentDateObj = new Date(); 
        let plannedLoanDate = new Date(currentDateObj);
  
        if (duration.includes('Days')) {
          const daysLimit = parseInt(duration.split('-')[1]); 
          plannedLoanDate.setDate(plannedLoanDate.getDate() + daysLimit);
        } else if (duration.includes('Months')) {
          const monthsLimit = parseInt(duration.split('-')[1]); 
          plannedLoanDate.setMonth(plannedLoanDate.getMonth() + monthsLimit);
        }
  
        return plannedLoanDate.toISOString().slice(0, 10);
      };
  
     
      const newPlannedLoanDate = calculatePlannedLoanDate(formData.duration);
      setFormData(prevData => ({ ...prevData, plannedLoanDate: newPlannedLoanDate }));
    }, [formData.duration]); 

  useEffect(() => {
      const convertLoanAmountRange = (loanRange) => {
          switch (loanRange) {
            case 'Upto ₹25 Lacs':
              return 2500000 ;
            case '₹25 - ₹50 Lacs':
              return 5000000 ;
            case '₹50 - ₹75 Lacs':
              return 7500000 ;
            case '₹75 Lacs - ₹1 Cr':
              return  1000000 ;
            case '₹1 Cr+':
              return 15000000 ;
            default:
              return ''; 
          }
        };
      const newUpperLimit = convertLoanAmountRange(formData.loanAmount);
      setFormData(prevData => ({ ...prevData, loanUpperLimit: newUpperLimit }));
    }, [formData.loanAmount]);

    useEffect(() => {
      const calculateTenureInMonths = (tenure) => {
        const tenureRanges = {
          'Upto 5 yrs': 5 * 12,
          '5 to 10 yrs': 10 * 12,
          '10 to 20 yrs': 20 * 12,
          'More than 20 yrs': 25 * 12 
        };
  
        return tenureRanges[tenure];
      };
  
      const tenureInMonths = calculateTenureInMonths(formData.tenure);
      setFormData(prevData => ({ ...prevData, tenureInMonths: tenureInMonths }));
    }, [formData.tenure]);

  const handleEmploymentTypeChange = (type) => {
      setFormData(prevData => ({ ...prevData, employmentType: type }));
  };

  const handleLoanAmountChange = (amount) => {
     setFormData(prevData => ({ ...prevData, loanAmount: amount }));
  };

  const handleTenureChange = (years) => {
      setFormData(prevData => ({ ...prevData, tenure: years }));
  };
  const handleGenderChange = (selectedGender) => {
     setFormData(prevData => ({ ...prevData, gender: selectedGender }));
  };

  const handleDurationChange = (selectedDuration) => {
     setFormData(prevData => ({ ...prevData, duration: selectedDuration }));
  };

  const handleMobileChange = (selectedMobile) => {
      setFormData(prevData => ({ ...prevData, mobile: selectedMobile }));
  };

  const handleBirthDateChange = (selectedDate) => {
      setFormData(prevData => ({ ...prevData, birthDate: selectedDate }));
  };

  const handlePinChange = (enteredPin) => {
      setFormData(prevData => ({ ...prevData, pin: enteredPin }));
  };
 
   
    const handleFormSubmit = () => {
        setLoading(true);
        let data = {
            alternateMobileNumber:formData.mobile,
            amountRequested :formData.loanUpperLimit,
            dateOfBirth: formData.formattedBirthDate,
            employmentType:formData.employmentType,
            gender:formData.gender,
            pincode: formData.pin,
            plannedLoanDate: formData.plannedLoanDate,
            tenureInMonths:formData.tenureInMonths
        };
        let url=`winnre/user/deals/loan-update?dealRedemptionId=${dealRedemptionId}` ;

        
        winnreAuth({
            method: 'post', 
            url,
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            data: JSON.stringify(data)
        })
            .then((res) => {
               setLoading(false);
                console.log('home loan', res);
                if (
                    res?.status === 'INVALID_TOKEN' ||
                    res?.status === 'FAILED'
                ) {
                    toast.error('Something Went Wrong');
                    return;
                }
                 setStep(step+1);
                 window?.webengage?.track('HomeLoanDetailsSubmitted', {
                  EmploymentType :`${formData.employmentType}`,
                  amountRequested:`${formData.loanUpperLimit}`,
                  tenureInMonths:`${formData.tenureInMonths}`,
                  plannedLoanDate: `${formData.plannedLoanDate}`,
                  alternateMobileNumber: `${formData.mobile} `,
                  dateOfBirth:` ${formData.formattedBirthDate}`,
                  gender:`${formData.gender}`,
                  pincode:`${formData.pin}`
              });
        
                
            })
            .catch(() => {
                toast.error('Something Went Wrong');
            });

    };

    const getConditionalFormStep = () => {
        switch (step) {
            case 1:
                return <StepOne setStep={setStep} employmentType={formData.employmentType} onEmploymentTypeChange={handleEmploymentTypeChange}/>;
            case 2:
                return <StepTwo setStep={setStep} loanAmount={formData.loanAmount} onLoanAmountChange={handleLoanAmountChange} tenure={formData.tenure} onTenureChange={handleTenureChange} />;
            case 3: 
                 return <StepThree 
                 setStep={setStep}
                    gender={formData.gender}
                    onGenderChange={handleGenderChange}
                    duration={formData.duration}
                    onDurationChange={handleDurationChange}
                    onMobileChange={handleMobileChange}
                    birthDate={formData.birthDate}
                    onBirthDateChange={handleBirthDateChange}
                    onPinChange={handlePinChange}
                    onFormSubmit={handleFormSubmit}
                 /> 
            case 4:
                 return <StepFour/>  
            default:
                <></>;
        }
    };
    return (
        <>
            <div
                className={`
                    ${config?.bannerNegativeTopMarginClass} w-full h-auto  `}
            >
                {config?.header && (
                    <div className={config?.header.headerClass}></div>
                )}
            </div>
        
            <div className="w-full bg-white h-full flex items-center justify-center">
                <div className="w-[90%] lg:w-[35%] xl:w-[25%] mx-auto ">
                 {loading && <LoadingSpinner/>}
                 {!loading && getConditionalFormStep()}
                </div>
            </div>
        </>
    );
};

export default HomeLoans;


