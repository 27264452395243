import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';

const config = {
    clientId:
        '453938a2652120520921743e1c7d58b5514e6fb5145aceae929361652ffeadc3',
    id: 4,
    name: 'Gera World',
    fullName: 'Gera',
    programName:'Club Outdo',
    theme: 'geraTheme',
    darktheme: true,
    favicon: 'https://cdn.loyalie.in/16615819.png',
    primaryColor: '#A88470',
    fontBody: `${getFont('GERA').body}`,
    fontTitle: `${getFont('GERA').bold}`,
    facebookPixelConfig: {
        id: '921405108780861',
        metaName: '',
        metaContent: ''
    },
    webengageLic: 'in~58adcbdc',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },
    googleConfig: {
        gID: 'G-KLM937QV84',
        keywordsContent: '',
        descriptionContent: '',
        authorContent: 'GERA CLUB OUT DO'
    },
    layouts: {
        events: 'Layout1',
        blogs: 'Layout5',
        
    },
    logoLeft: {
        baseUrl: 'https://cdn.loyalie.in/86953374.png',
        stickyUrl: 'https://cdn.loyalie.in/60749441.png',
        stickyNavBarLogoClass:
            'bg-primary-content h-7 md:h-12 lg:h-10 mt-2 ml-2 lg:ml-6', //to match godrej nav bar
        baseNavBarLogoClass:
            'relative lg:block h-7 lg:h-10 xl:h-14  mt-2 ml-2 lg:top-3 xl:top-4 lg:left-4 xl:left-12 ',
        name: 'Gera Logo'
    },
    navBarConfig: {
        showLogin: true,
        dropDown: true,
        font: `${getFont('GERA').heading}`,
        height: 'h-16 2xl:h-16',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-primary-content shadow-lg',
        footerNavItemClass: `uppercase text-primary hover:text-primary text-[14px] lg:text-[12px] xl:text-[13px] 2xl:text-[14px] ${
            getFont('GERA').heading
        }`,
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-primary-content lg:bg-transparent', // default: transparent
            containerClass: 'lg:mt-7 2xl:mt-7',
            itemClass:
                'uppercase lg:mr-7 text-white hover:text-white text-[14px] lg:text-[15px]',
            dropDownContainerClass: ` focus:text-secondary-focus focus:shadow-outline `,
            dropDownItemClass:
                'tracking-widest text-center text-white  divide-b divide-accent text-[15px] lg:text-[13px] xl:text-[14px] 3xl:text-lg '
        },
        stickyNavBar: {
            border: true,
            borderClass: 'border-b-[1px] border-primary',
            backgroundColor: 'bg-primary-content', //default: primary-content,
            containerClass: 'md:text-lg ', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 text-primary hover:text-primary text-[14px] lg:text-[14px] lg:mr-12 xl:mr-12' // Each list element in NavBar (Text color, hover color, size)
        },
        showActiveLinkColor: false
    },
    floatingButton: true,
    changePath: true,
    floaterClass: 'gera-button hover:gera-button-hover',
    floaterType: 'REFERRAL',
    bannerNegativeTopMarginClass: '-mt-16 2xl:-mt-16',
    scrollToTargetAdjustedValue: 60,
    clientButtonClassName: '',
    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },
    increasePageSize: true,
    showFooterRoutes: true,
    routes: {
        home: {
            name: 'HOME',
            scrollTo: '',
            path: '/',
            show: true,
            components: [
                {
                    type: 'BANNER',
                    backgroundImage: 'https://cdn.loyalie.in/85099547.jpg',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/83833816.jpg',
                    bannerContainerClassName:
                        'hero hero-content max-w-full h-screen mx-auto text-center lg:h-[110vh] ',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0.5'
                    },
                    components: [
                        {
                            type: 'TEXT',
                            content: 'ENJOY A LOYALTY EXPERIENCE',
                            className: `text-white text-[16px] md:text-[25px] lg:text-[34.5px] 2xl:text-[36px] leading-6 mt-[60px] tracking-[1px] md:mt-10 lg:mt-0 xl:mt-0 text-center mx-auto  ${
                                getFont('GERA').heading
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'THAT OFFERS MUCH MORE',
                            className: `text-white text-[16px] md:text-[25px] lg:text-[34.5px] 2xl:text-[36px] mx-auto tracking-[2.5px] md:tracking-[3.2px] lg:tracking-[4px] 2xl:tracking-[4.2px] text-center ${
                                getFont('GERA').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            content: '',
                            className: `w-[82%] md:w-[55%] lg:w-[54%] xl:w-[41%] 2xl:w-[43%] border-t-0 border-b-2 border-[#d38a76] mx-auto text-center   ${
                                getFont('GERA').heading
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'INTRODUCING',
                            className: `text-white text-[15px] md:text-[22px] lg:text-[29px] text-center my-3 md:my-5 tracking-[0.5px] md:w-[50%] mx-auto  ${
                                getFont('GERA').heading
                            }`
                        },

                        {
                            type: 'IMAGE',
                            url: 'https://cdn.loyalie.in/60749441.png',

                            position: 'center',
                            className:
                                'w-[80%] md:w-[50%] lg:w-[35%] lg:w-[370px] py-2 md:py-4 lg:py-0'
                        },
                        {
                            type: 'TEXT',
                            content:
                                'An exclusive Club designed to enable you to Outdo each day.',
                            className: `text-secondary font-thin my-3 md:my-4 text-[15px] text-center lg:px-16 xl:px-0 lg:text-[20px] break-words ${
                                getFont('GERA').body
                            }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'Refer Now',
                            className: `text-md mx-auto text-center font-medium gera-button hover:gera-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 ${
                                getFont('GERA').heading
                            }`,
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH_AND_SCROLL',
                            scrollTo: 'referral_section'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'overview',
                    className:
                        'flex flex-col justify-center text-center pt-8 bg-primary-content',

                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'At Gera Developments we have believed in building and nurturing communities to foster strong eco systems that allow our customers with opportunities to Outdo. Club Outdo is our way of providing you with a loyalty and referral experience that does more. As part of this exclusive Club, you enjoy specials and discounts that come with buying with the best. What’s delightful is that you get to engage with a like-minded community in an environment where not just you, but your family can also thrive.',

                            className: `text-secondary-focus text-[15.5px] xl:text-[16px] mt-[20px]  mb-[5px] w-[80%] xl:w-[82%] mx-auto xl:px-16 xl:mt-5 font-extralight	 ${
                                getFont('GERA').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'We welcome you to Club Outdo and hope that you make the most of this exclusive experience designed for you.',

                            className: `text-secondary-focus text-[15.5px] xl:text-[16px] mt-[20px] mb-[5px] w-[80%] xl:w-[80%] mx-auto xl:px-16 xl:mt-5 font-extralight		 ${
                                getFont('GERA').body
                            }`
                        },

                        {
                            type: 'ICONS_LAYOUT',
                            containerClassName:
                                'h-content w-[90%] lg:w-[80%] xl:w-full  mx-auto lg:flex lg:h-auto bg-primary-content mb-4 lg:py-2 xl:px-16 lg:px-8 px-4 items-center',
                            className: 'bg-primary-content',
                            infotxtClassName: ` w-full  md:w-[50%] mx-auto lg:w-[80%] xl:w-[90%] md:px-2 text-[15.5px] xl:text-[16px] text-secondary-focus font-light	${
                                getFont('GERA').body
                            }`,
                            infoContainerClassName: '',
                            info: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/4818513.png',
                                    infoText:
                                        'Earn up to ₹2.50 Lacs* on every successful referral'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/7709042.png',
                                    infoText:
                                        'Enjoy exclusive deals and discounts'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/35987771.png',
                                    infoText:
                                        'Engage with a like-minded community through events, content and more'
                                }
                            ]
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Join A Vibrant, Engaged Community Of Outdoers.',

                            className: `z-10  font-light px-4 pb-10 text-[18px] lg:text-[20px] xl:text-[22px] text-primary ${
                                getFont('GERA').heading
                            }`
                        }
                    ]
                },

                {
                    type: 'SQUARE_BLOGS',
                    // API: 'https://cluboutdo.gera.in/wp-json/wp/v2/posts',
                    fetchDashboardBlogs: true,
                    className:
                        'min-w-[80%] lg:w-full mr-6 lg:mr-0 relative grid lg:h-[500px] 2xl:h-[600px] grid-cols-1 lg:grid-cols-2 bg-primary-content',
                    headingClass: `text-[23px] lg:text-[30px] xl:text-[40px] leading-7 lg:leading-10 tracking-wide text-white hover:text-primary px-4 md:px-12 py-4 md:py-7 lg:px-10 lg:py-5 ${
                        getFont('GERA').heading
                    }`,
                    bodyClass: `text-secondary-focus px-4 text-[15.5px] xl:text-[16px] md:px-12 py-4 md:py-7 lg:px-10 lg:py-5  ${
                        getFont('GERA').body
                    }`,
                    buttonClass: ` text-[17.5px] px-4 md:px-12 py-4 md:py-7 lg:px-10 lg:py-5 text-primary ${
                        getFont('GERA').heading
                    }`
                },

                {
                    type: 'CUSTOM',
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-white bg-primary-content  xl:py-8  py-10',
                    components: [
                        {
                            type: 'BUTTON',
                            content: 'Load More',
                            className: `text-lg text-primary  border-[1px] border-primary font-medium px-10 py-2 lg:mt-2 xl:mt-0 ${
                                getFont('GERA').heading
                            }`,
                            path: '/blogs',
                            scrollTo: 'blogs_section',
                            onClick: 'CHANGE_PATH_AND_SCROLL'
                        }
                    ]
                }
            ]
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        refer: {
            show: true,
            name: 'REFER AND EARN',
            path: '/refer',
            component: 'ReferAndEarn'
        },
        events: {
            name: 'EVENTS',
            path: '/events',
            component: 'Events',
            show: true
        },
        blogs: {
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs',
            show: true
        },
        deals: {
            name: 'EXCLUSIVE OFFERS',
            authenticatedRoute: true,
            path: '/deals',
            component: 'DealsAndDiscounts',
            show: true
        },
        projects: {
            name: 'PROJECTS',
            authenticatedRoute: false,
            path: '/projects',
            component: 'Projects',
            show: false
        },
        contactUs: {
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us',
            show: false
        },
        termsAndConditions: {
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions',
            show: false
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            bannerContainerClassName:
                'hero hero-content max-w-full h-[90vh] lg:h-[97vh] lg:h-screen bg-top  mx-auto text-center ',
            overlayClass: 'bg-neutral',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0.65'
            },
            backgroundImage: 'https://cdn.loyalie.in/28576933.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/37803648.jpg',

            loginBannerComponents: [
          
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage: 'https://cdn.loyalie.in/85821283.png',
                backgroundImageMobile: 'https://cdn.loyalie.in/85821283.png',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[80vh] rounded-xl lg:rounded-none md:h-[97vh] object-cover bg-top ',
                components: [
                    {
                        type: 'CUSTOM',
                        url: 'https://cdn.loyalie.in/84097187.jpg',
                        linearGradient: {
                            R: '0',
                            G: '0',
                            B: '0',
                            opacity: '0.60',
                            repeat: 'no-repeat',
                            size: 'cover',
                            position: 'bottom right'
                        },
                        className:
                            'relative grid content-center px-5 text-center h-[80vh] mt-32 rounded-xl',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'Refer your loved ones and get benefits upto ',
                                className: `text-white text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  my-5 tracking-widest lg:w-[80%] mx-auto  ${
                                    getFont('GERA').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content: '₹2,75,000 ',
                                className: `text-white text-[15px] md:text-[20px] xl:text-[45px] font-bold  pb-5  tracking-widest lg:w-[50%] mx-auto  ${
                                    getFont('GERA').heading
                                }`
                            },
                            {
                                type: 'BUTTON',
                                content: 'Start Referring Now',
                                className: `text-md font-medium gera-button hover:gera-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 w-[20%] mx-auto ${
                                    getFont('GERA').heading
                                }`,
                                path: 'gift-and-earn',
                                onClick: 'CHANGE_PATH_AND_SCROLL',
                                scrollTo: 'referral_section'
                            }
                        ]
                    }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold  gera-button hover:gera-button-hover ${
                getFont('GERA').heading
            }`
        }
    },
    referAndEarn: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.5'
                },
                position: 'text-center',
                backgroundImage: 'https://cdn.loyalie.in/84097187.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/84097187.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-screen md:h-[99vh] lg:h-[100vh] object-cover bg-top  md:bg-center lg:bg-center',
                components: [
                    {
                        type: 'TEXT',
                        content: 'Outdoing Referral Experiences with ',
                        className: `text-white text-[15px] md:text-[20px] xl:text-[25px] font-thin  my-5 tracking-widest lg:w-[50%] mx-auto  ${
                            getFont('GERA').body
                        }`
                    },

                    {
                        type: 'IMAGE',
                        url: 'https://cdn.loyalie.in/60749441.png',

                        position: 'center',
                        className:
                            'w-[80%] md:w-[50%] lg:w-[35%] lg:w-[402px] py-2 md:py-4 lg:py-0'
                    },
                    {
                        type: 'TEXT',
                        content: 'Refer And Earn* On Every Successful Referral',
                        className: `text-white text-[15px] md:text-[20px] xl:text-[25px] font-thin pt-5 tracking-widest lg:w-[50%] mx-auto  ${
                            getFont('GERA').body
                        }`
                    },
                    {
                        type: 'TEXT',
                        content: 'An Initiative by Gera Developments ',
                        className: `text-white text-[15px] md:text-[20px] xl:text-[25px] font-extralight pb-5  tracking-widest lg:w-[50%] mx-auto  ${
                            getFont('GERA').body
                        }`
                    },
                    {
                        type: 'TEXT',
                        content: 'Managed by Reloy ',
                        className: `text-white text-[13px] lg:text-[16px] font-extralight tracking-[0.5px] absolute bottom-5 lg:bottom-3 left-4 lg:left-4 ${
                            getFont('GERA').body
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            '*The referral amount differs from project to project',
                        className: `text-white text-[13px] font-extralight tracking-[0.5px] absolute bottom-1 left-2 lg:right-4  ${
                            getFont('GERA').body
                        }`
                    },
                    {
                        type: 'BUTTON',
                        content: 'Refer Now',
                        className: `text-md font-medium gera-button hover:gera-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 ${
                            getFont('GERA').heading
                        }`,
                        path: 'gift-and-earn',
                        onClick: 'CHANGE_PATH_AND_SCROLL',
                        scrollTo: 'referral_section'
                    }
                ]
            },
            {
                type: 'CUSTOM',
                id: 'overview',
                className:
                    'relative flex bg-primary-content flex-col justify-center text-center pt-8',
                url: '',
                overlayClass: '',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0',
                    size: '30% auto'
                },
                components: [
                    {
                        type: 'CUSTOM',
                        className:
                            'z-10 w-full text-center w-[90%] lg:w-[82%] mx-auto mb-3 ',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'Club Outdo believes in outdoing the benchmarks of community building. This is why every member has the opportunity to enable their loved ones to find their dream spaces and get rewarded up to ₹ 2.50 lacs on every successful referral. At Gera Developments, we believe that happiness multiplies when it is shared. Here’s a look at how easy your referral journey will be.',
                                className: `text-secondary-focus text-[15.5px] xl:text-[16px] mt-[20px] mb-[5px]   mx-auto xl:px-16 xl:mt-5 font-extralight  ${
                                    getFont('GERA').body
                                }`
                            }
                        ]
                    },
                    {
                        type: 'TEXT',
                        content: 'Making Referrals Easy',
                        className: `  font-light text-[18px] md:text-[22px] xl:text-[25px] xl:pt-5  text-primary ${
                            getFont('GERA').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'To enrich your referral experience, we have designed a seamless journey for you:',

                        className: `text-secondary-focus text-[15.5px] md:text-[16px] mt-[10px] mb-[5px]   mx-auto px-8 xl:px-16 xl:mt-2 font-extralight ${
                            getFont('GERA').body
                        }`
                    },

                    {
                        type: 'ICONS_LAYOUT_WITH_ARROW',
                        icontextclassName: `xl:px-10 px-2 text-[15.5px] xl:text-[16px] text-secondary-focus  ${
                            getFont('GERA').body
                        }`,
                        ArrowRightIcon:
                            'hidden lg:block self-center h-7 md:h-8 lg:h-5 w-7 md:w-8 my-10 lg:my-0 lg:w-10 mx-7 text-primary',
                        ArrowDownIcon:
                            'block lg:hidden self-center h-7 md:h-8 lg:h-5 w-7 md:w-8 my-5 lg:my-0 lg:w-10 mx-7 text-primary',

                        icon1: 'https://cdn.loyalie.in/77436928.png',
                        icontext1: 'Refer your loved one to us',

                        icon2: 'https://cdn.loyalie.in/10042266.png',
                        icontext2:
                            'Wait for them to complete property booking and registration',

                        icon3: 'https://cdn.loyalie.in/52485167.png',
                        icontext3:
                            'Receive your referral reward within 90 days from registration'
                    },
                    {
                        type: 'TEXT',
                        content:
                            'Go On, Introduce Your Loved Ones To The World Of Gera',

                        className: `z-10  font-light pt-5  pb-10 text-[18px] md:text-[22px] xl:text-[25px] text-primary ${
                            getFont('GERA').heading
                        }`
                    }
                ]
            },
            {
                type: 'REFERRAL_FORM_BANNER'
            },
            {
                type: 'CUSTOM',
                className:
                    'relative flex flex-col justify-center bg-primary-content text-center py-5',
                url: '',
                overlayClass: '',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0',
                    size: '30% auto'
                },
                components: [
                    {
                        type: 'TEXT',
                        content: 'Reward Opportunities',

                        className: `z-10  tracking-[0.5px] text-[22px] md:text-[22px] xl:text-[25px] text-primary ${
                            getFont('GERA').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content: 'Earn Referral Benefits Up To ₹ 2,50,000*',
                        className: `text-secondary-focus text-[15.5px] md:text-[16px] p-4 leading-5 mb-[5px] w-[98%] border-[1px] border-primary  mx-auto xl:px-16 mt-2 font-extralight ${
                            getFont('GERA').body
                        }`
                    }
                ]
            },
            {
                type: 'CAROUSEL',
                url: '',
                overlayClass: 'bg-white opacity-[.97]',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0',
                    size: '30% auto'
                },
                caraouselClassName:
                    'px-10 md:px-10 lg:px-10 xl:px-24 2xl:px-48 py-2 relative',
                imageContainerClassName: 'w-auto',
                imageClassName:
                    ' mx-auto cursor-pointer grayscale hover:grayscale-0',
                arrows: true,
                autoplay: true,
                autoplaySpeed: 3000,
                infinite: true,
                dots: false,
                slidesToShow: 3,
                pauseOnHover: true,
                speed: 2000,
                onImageClick: openURLNewWindow,
                imgArr: [
                    {
                        imgUrl: 'https://cdn.loyalie.in/69194014.jpg',
                        alt: 'ir',
                        url: 'https://www.gera.in/isleroyalenew'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/53604880.jpg',
                        alt: 'Geras-Planet-of-Joy',
                        url: 'https://gera.in/projects/childcentric-homes/pune/ongoing/gera-s-planet-of-joy/overview'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/37652749.jpg',
                        alt: 'Geras-World-of-Joy',
                        url: 'https://gera.in/projects/childcentric-homes/pune/ongoing/gera-s-world-of-joy/overview'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/33218861.jpg',
                        alt: 'Geras-River-of-Joy',
                        url: 'https://gera.in/projects/childcentric-homes/goa/ongoing/gera-s-river-of-joy/overview'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/65913070.jpg',
                        alt: 'Geras-Imperium-Gateway',
                        url: 'https://gera.in/projects/commercial/pune/ongoing/gera-s-imperium-gateway/overview'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/72925358.jpg',
                        alt: 'Geras-Imperium-Rise',
                        url: 'https://gera.in/projects/leasing-commercial/pune/ongoing/gera-s-imperium-rise/overview'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/85456569.jpg',
                        alt: 'Geras-Imperium-Premio',
                        url: 'https://gera.in/projects/commercial/kadamba-plateau/ongoing/gera-s-imperium-premio/overview'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/74971523.jpg',
                        alt: 'Geras-Imperium-Grand',
                        url: 'https://gera.in/projects/leasing-commercial/goa/ongoing/gera-s-imperium-grand/overview'
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/27004895.jpg',
                        alt: 'Geras-Imperium-Star',
                        url: 'https://gera.in/projects/leasing-commercial/panjim/ongoing/gera-s-imperium-star/overview'
                    }
                ]
            }
        ]
    },

    blogs: {
        banner: {
            bannerContainerClassName:
                'hero hero-content max-w-full h-[90vh] lg:h-[97vh] lg:h-screen bg-top  mx-auto text-center ',
            overlayClass: 'bg-neutral',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0.65'
            },
            backgroundImage: 'https://cdn.loyalie.in/28576933.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/37803648.jpg',

            components: [
                {
                    type: 'TEXT',
                    content: 'CLUB OUDTO READERS’ CLUB',
                    className: `text-white mt-10 mb-5 text-[16px] md:text-[25px] lg:text-4xl tracking-widest ${
                        getFont('GERA').heading
                    }`
                },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[3px]',
                //     classNameContainer: ''
                // },
                {
                    type: 'TEXT',
                    content:
                        'Bringing together information and entertainment for you',
                    className: `text-secondary font-thin lg:mt-4 text-[15px] lg:px-16 xl:px-0 md:text-[20px] mb-5 break-words ${
                        getFont('GERA').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `text-md font-medium gera-button hover:gera-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 ${
                        getFont('GERA').heading
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'CLUB OUDTO READERS’ CLUB',
                    className: `text-white mt-10 mb-5 text-[16px] md:text-[25px] lg:text-4xl tracking-widest ${
                        getFont('GERA').heading
                    }`
                },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[3px]',
                //     classNameContainer: ''
                // },
                {
                    type: 'TEXT',
                    content:
                        'Bringing together information and entertainment for you',
                    className: `text-secondary font-thin lg:mt-4 text-[15px] lg:px-16 xl:px-0 md:text-[20px] mb-5 break-words ${
                        getFont('GERA').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `text-md font-medium gera-button hover:gera-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 ${
                        getFont('GERA').heading
                    }`
                }
            ]
        },

        linearGradient: {
            R: '34',
            G: '34',
            B: '34',
            opacity: '1',
            size: '30% auto'
        },
        detailsPageBackgroundColor: `bg-primary-content text-secondary-focus ${
            getFont('GERA').heading
        }`,
        sideBarClass: `text-primary ${getFont('GERA').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-primary capitalize leading-5 font-normal`,
        sideBarDateClass: `text-secondary-focus text-[12px] ${
            getFont('GERA').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                components: [
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[15%] lg:w-[10%] bg-[#919396] border-none h-[2px]',
                        classNameContainer: ''
                    }
                ],
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-normal text-[20px] text-secondary-focus ${
                    getFont('GERA').heading
                }`,
                headingClass: `text-[22px] md:text-[30px] uppercase font-normal text-primary mt-3 leading-8 md:leading-9 break-words ${
                    getFont('GERA').heading
                }`,
                summaryClass: `inline text-[15px] md:text-[17px] mt-8 text-secondary-focus  min-h-[40px] lg:min-h-[70px] ${
                    getFont('GERA').body
                }`,
                dateClass: `text-[13px] uppercase text-secondary-focus  font-normal my-3 ${
                    getFont('GERA').heading
                } `,
                buttonClass: `inline text-[17px] text-secondary ml-1 ${
                    getFont('GERA').body
                }`
            },
            sectionHeadings: `text-primary px-10 lg:px-16 pt-6 pb-2 text-[22px] md:text-[24px] lg:text-[25px] xl:text-[30px] tracking-wider text-center uppercase ${
                getFont('GERA').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },

        layout5: {
            components: [
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[15%] lg:w-[10%] bg-[#919396] border-none h-[3px]',
                    classNameContainer: ''
                }
            ],
            cardHeight:
                ' h-[500px] lg:h-[500px] xl:h-[500px] 3xl:h-[520px]  border-info border-[1px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-primary  my-5 md:my-7 text-[19px] 3xl:text-[20px] font-normal uppercase leading-6 break-words mx-5 ${
                getFont('GERA').heading
            }`,
            categoryClass: `bg-primary-content text-secondary ${
                getFont('GERA').heading
            }`,
            summaryClass: `text-secondary-focus text-[15.5px] xl:text-[16px] mt-2  mx-5 ${
                getFont('GERA').body
            }`,
            buttonClass: `bg-accent-focus hover:bg-primary flex justify-center self-end float-right text-md font-medium gera-button hover:gera-button-hover px-6 py-2 lg:mt-2 m-4 ${
                getFont('GERA').heading
            }`,

            dateClass: ``,
            // hidden text-[13px] md:text-[17px] xl:text-[15px]  self-center text-[#919396] ${
            //     getFont('GERA').heading
            // }
            noBlogsClass: `bg-primary text-white  p-2 md:w-[30%] lg:w-[70%] xl:w-[50%] 3xl:w-[30%] mt-10 ${
                getFont('GERA').body
            }`
        }
    },

    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/34725038.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/34725038.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.60'
            },
            containerClass: 'hero self-center my-auto h-full text-white',
            heading: 'Coming Soon...',
            headingClass: `text-white mt-10 mb-5 text-[18px] md:text-[25px]  lg:text-[40px] uppercase tracking-widest ${
                getFont('GERA').heading
            }`,
            subHeadingClass: `text-white uppercase font-semibold text-[28px] md:text-[33px] ${
                getFont('GERA').heading
            }`,

            negativeMargin: '-mt-16 2xl:-mt-16'
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-16 2xl:mt-16 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `text-white mt-10 mb-5 text-[18px] md:text-[25px] lg:text-4xl tracking-widest ${
                        getFont('GERA').heading
                    }`
                },

                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `text-secondary font-thin lg:mt-4 text-[15.5px] lg:px-16 xl:px-0 md:text-[20px] mb-5 break-words ${
                        getFont('GERA').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    onClick: 'FUNC',
                    className: `text-md font-medium gera-button hover:gera-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 ${
                        getFont('GERA').heading
                    }`
                }
            ]
        },

        eventsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/46430513.jpg',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0' //no gradient
            },
            heading: 'Past Events',
            headingClass: `self-center text-[30px] text-center lg:text-[30px] mt-14 text-primary w-[60%] ${
                getFont('GERA').heading
            }`,
            dividerClass:
                'w-[10%] bg-[#919396] my-2 mx-auto border-none h-[2px]',
            filterHeading: 'Browse Event Categories',
            filterHeadingClass: `self-center text-center font-light text-[18px] md:text-[22px] xl:text-[25px]  mt-8 text-primary w-[60%] ${
                getFont('GERA').heading
            }`,
            additionalText: ' ',
            additionalTextClass: ` `,
            subAdditionalText: ' ',
            subAdditionalTextClass: `pb-16`,
            noEvents: '',
            noEventsClass: `mx-auto px-6 lg:px-10 rounded-sm py-2 text-white text-lg md:text-xl 3xl:text-2xl bg-accent text-center ${
                getFont('GERA').body
            }`
        },
        eventLayout: {
            titleClass: `capitalize text-primary text-2xl ${
                getFont('GERA').heading
            }`,
            hostClass: '',
            dateClass: `text-secondary-focus font-normal text-lg ${
                getFont('GERA').heading
            }`,
            loadMoreButtonClass: `text-md font-medium gera-button hover:gera-button-hover px-10 py-2 lg:mt-2 xl:mt-0 ${
                getFont('GERA').heading
            }`
        }
    },
    eventsGallery: {
        show: true,
        noGalleryClass: `text-primary font-semibold ${getFont('GERA').body}`
    },

    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content  max-w-full h-[30vh] lg:h-[45vh] ',
            backgroundImage: 'https://cdn.loyalie.in/35272931.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/35272931.jpg',

            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.5'
            },
            components: [
                //4C
                // {
                //     type: 'TEXT',
                //     content: 'OUTDOING LIFESTYLE WITH CLUB OUTDO',
                //     className: `mx-auto text-center text-white text-center mt-10 mb-5 text-[16px] md:text-[25px] lg:text-4xl tracking-widest ${
                //         getFont('GERA').heading
                //     }`
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Enjoy exclusively curated offers on brands you love',
                //     className: `text-secondary font-thin text-center lg:mt-4 text-[15px] md:text-[20px] mx-auto lg:px-16 xl:px-0 mb-5 break-words ${
                //         getFont('GERA').body
                //     }`
                // }
            ],
            loginBannerComponents: [
                //4C
                // {
                //     type: 'TEXT',
                //     content: 'OUTDOING LIFESTYLE WITH CLUB OUTDO',
                //     className: ` mx-auto text-center text-white mt-10 mb-5 text-[16px] md:text-[25px] lg:text-4xl tracking-widest ${
                //         getFont('GERA').heading
                //     }`
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Enjoy exclusively curated offers on brands you love',
                //     className: `mx-auto text-center text-secondary font-thin lg:mt-4 text-[15px] md:text-[20px] lg:px-16 xl:px-0  mb-5 break-words ${
                //         getFont('GERA').body
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC',
                //     className: `mx-auto text-center flex text-md font-medium gera-button hover:gera-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 ${
                //         getFont('GERA').heading
                //     }`
                // }
            ]
        },
        darkTheme: {
            selected: '',
            text: 'text-gray-200',
            border: ''
        },
        dealsSection: {
            overlayClass: ' ',
            linearGradient: {
                R: '34',
                G: '34',
                B: '34',
                opacity: '1'
            },
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `z-10 self-center text-[16px]  text-center lg:text-xl text-primary w-[60%] ${
                getFont('GERA').heading
            }`,

            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `capitalize z-10  text-2xl xl:text-3xl uppercase  font-normal text-primary ${
                getFont('GERA').heading
            } `,
            subAdditionalText:
                'Gera Developments is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: `z-10 text-secondary-focus ${
                getFont('GERA').body
            }`,
            noDeals: 'No deals to show',
            noDealsClass: `z-10 text-secondary-focus text-xl text-center my-6
             ${getFont('GERA').body}`
        },
        dealLayout: {
            containerClassNameBg1:
                'bg-primary-content text-accent-content px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2:
                'bg-primary-content text-accent-content px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            listTitleClass: `text-primary text-2xl lg:text-xl  leading-6 lg:leading-6 text-primary ${
                getFont('GERA').body
            }`,
            listBodyClass: `text-gray-200 ${getFont('GERA').body}`,
            overlayClass: ' ',
            linearGradient: {
                R: '34',
                G: '34',
                B: '34',
                opacity: '1'
            },

            logoClassName:
                'self-center rounded-md border-[1px] border-grey-100',
            titleClassName: ` text-primary text-2xl xl:text-3xl leading-8  ${
                getFont('GERA').body
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-secondary-focus  ${
                getFont('GERA').heading
            }`,
            dateClassName: `text-secondary font-normal capitalize text-[12px] ${
                getFont('GERA').body
            }`,
            termsAndConditionsClassName: `text-secondary-focus font-normal capitalize text-sm ${
                getFont('GERA').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-secondary-focus',
            termsClassName: ` text-secondary-focus text-xs font-normal break-words ${
                getFont('GERA').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',

                className: `text-md mx-auto font-medium gera-button hover:gera-button-hover px-10 py-2 lg:mt-2 xl:mt-0 ${
                    getFont('GERA').heading
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',

                className: `rounded z-10 text-md font-medium gera-button hover:gera-button-hover px-10 py-2 lg:mt-2 xl:mt-0 ${
                    getFont('GERA').heading
                }`
            }
        }
    },
    projects: {},
    header: {
        headerClass: 'hidden lg:block h-24 bg-primary-content'
    },
    contactUs: {
        overlayClass: 'bg-neutral',
        font: `${getFont('GERA').heading}`,
        components: [
            {
                type: 'BANNER',
                backgroundImage: 'https://cdn.loyalie.in/48370717.jpg',

                backgroundImageMobile: 'https://cdn.loyalie.in/48370717.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[400px] lg:h-[480px] 2xl:h-[580px] ',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.6'
                }
                // components: [
                //     {
                //         type: 'TEXT',
                //         content: 'CONTACT US',
                //         className: `mt-10 font-semibold text-2xl md:text-4xl text-white tracking-widest ${
                //             getFont('GERA').heading
                //         }`
                //     }
                // ]
            }
        ],

    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: `text-secondary-focus font-normal px-4 lg:px-16 text-[16px] md:text-[17px] xl:text-lg ${
            getFont('GERA').body
        }`,
        listClassName: `text-secondary-focus text-[15.5px] md:text-[17px] xl:text-lg pb-4  ${
            getFont('GERA').body
        }`,
        components: [
            {
                type: 'CUSTOM',
                className:
                    '-mt-16 text-left w-full pt-32 pb-24 px-2 lg:px-32 bg-neutral',

                components: [
                    {
                        type: 'TEXT',
                        content: 'Terms & Conditions',
                        className: ` text-center text-primary font-semibold mb-5 text-[18px] md:text-3xl ${
                            getFont('GERA').body
                        }`
                    },
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: ` text-[#7a7a7a] px-4 lg:px-16 font-semibold mt-12 mb-5 text-[18px] md:text-lg ${
                            getFont('GERA').body
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',
                        className: `text-secondary-focus font-normal px-4 lg:px-16 text-[16px] md:text-[17px] xl:text-lg ${
                            getFont('GERA').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',

                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Gera project, and has entered into a registered Agreement to Sell; or (ii) employee of Gera; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Gera or any of its sister concerns/subsidiaries/affiliates (“Gera Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Gera Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Gera.',
                            'Referrer shall not be able to refer existing customer(s) of Gera. Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Gera or (ii) is/are already registered with Gera as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Gera’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',
                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Gera within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Gera.',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'The Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by Gera or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-secondary-focus text-[15.5px] md:text-[17px] xl:text-lg pb-4  ${
                            getFont('GERA').body
                        }`,
                        classNameContainer: 'px-4 lg:px-16 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-4 lg:px-16 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-4 lg:px-16 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'z-10 px-4 lg:px-16',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Gera, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('GERA').body
                                } inline text-secondary-focus text-[15.5px] md:text-[17px] xl:text-lg  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary  text-[15.5px] md:text-[17px] ${
                                    getFont('GERA').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-secondary-focus text-[15.5px] md:text-[17px]  ${
                                    getFont('GERA').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary text-[15.5px] md:text-[17px]  ${
                                    getFont('GERA').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-secondary-focus text-[15.5px] md:text-[17px]  ${
                                    getFont('GERA').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary text-[15.5px] md:text-[17px] ${
                                    getFont('GERA').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-secondary-focus text-[15.5px] md:text-[17px]  ${
                                    getFont('GERA').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/64180896.png',
        containerClassName: 'bg-primary-content md:pt-4 xl:pt-5',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('GERA').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[11px] 3xl:text-[15px] tracking-tight text-white justify-center ${
            getFont('GERA').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-white text-[17.px] uppercase ${
            getFont('GERA').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-primary border-primary hover:bg-opacity-90 hover:border-primary hover:bg-primary disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-80',
        headingColor: 'text-primary', //contact-us blog comments headings,
        blogHeadingColor: 'text-[#919396]'
    },
    referralForm: {
        name: 'Gera',
        theme: 'geraTheme',
        color: '#A88470',
        backgroundImage: 'https://cdn.loyalie.in/77176318.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '20',
            G1: '20',
            B1: '20',
            opacity1: '0',
            R2: '20',
            G2: '20',
            B2: '20',
            opacity2: '0'
        },
        customConfig: {
            welcomeContent: '',
            headingColor: 'text-primary',
            userBgColor: '',
            borderColor: '',
            animateUserType: false
        },
        // pastReferralConfig: {
        //     selectedStatusColor: 'text-[#B39C64]',
        //     bgColor: 'bg-primary'
        // },
        showLoginScreen: true,
        userTypes: {
            length: 2,
            siteVisitor: false,
            siteVisitorHeading1: '',
            siteVisitorHeading2: '',

            bookedCustomer: true,
            bookedCustomerHeading1: '',
            bookedCustomerHeading2: '',

            employee: true,
            employeeHeading1: '',
            employeeHeading2: '',

            salesAdmin: true,
            salesAdminHeading1: '',
            salesAdminHeading2: '',

            influencer: false,
            influencerHeading1: '',
            influencerHeading2: ''
        },
        formContent: {
            heading: ' ',
            headingClass: ``,
            notification: {
                line1: ' ',
                line1Class: ``,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            }
        },
        leadEmailMandatory: true,
        sendReferralAttemptNotification: true,
        isDirectTAndCLink: false,
        influencerLinkActive: true,
        termsAndConditionsLink: '/gera-website/terms-and-conditions',
        customLink: `/gera-website/projects/detail`,
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default config;
