import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useHistory,
    useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import ProjectMapLoadingUI, {
    LoadingSpinner
} from '../../Components/Molecules/LoadingUI';
import { authActions } from '../../store/auth-slice';
import BookNowModal from './BookNowModal';
import CancelModal from './CancelModal';
import RescheduleModal from './RescheduleModal';
import RevisitModal from './RevisitModal';
import useData from './data';
import moment from 'moment';
import SiteVisitItem from './SiteVisitItem';
import Pagination from '../../Components/Molecules/Pagination';
import HomeLoanModal from './HomeLoanModal';

function SiteVisit() {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let accessToken = queryParams.get('token');
    const dispatch = useDispatch();
    const history = useHistory();
    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
    };

    const [showBookNowModal, setShowBookNowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);
    const [showRevisitModal, setShowRevisitModal] = useState(false);
    const [selectedVisitId, setSelectedVisitId] = useState(null);
    const [projectDetails, setProjectDetails] = useState(null);
    const [showHomeLoanModal, setShowHomeLoanModal] = useState(false)
    const {
        siteVisits,
        loadingVisits,
        cancelVisit,
        bookNow,
        totalPages,
        currentPage,
        setCurrentPage
    } = useData();

    const visits = [
        {
            img: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/20/07hAQK0CeJ.jpg',
            projectName: 'Embassy Lake Terraces',
            status: 'VISITED',
            city: 'North Bangalore',
            date: '26-May-2023',
            time: '3PM'
        },
        {
            img: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/20/07hAQK0CeJ.jpg',
            projectName: 'Embassy Lake Terraces',
            status: 'SCHEDULED',
            city: 'North Bangalore',
            date: '26-May-2023',
            time: '3PM'
        },
        {
            img: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/20/07hAQK0CeJ.jpg',
            projectName: 'Embassy Lake Terraces',
            status: 'CANCELLED',
            city: 'North Bangalore',
            date: '26-May-2023',
            time: '3PM'
        }
    ];

    return (
        <>
            {!isAuthenticated && !accessToken && (
                <div className="py-12 flex justify-center">
                    <button
                        onClick={openLoginModal}
                        className="self-start bg-primary text-white py-2 px-6 rounded-lg hover:shadow-xl "
                    >
                        Login to access your Enquiries
                    </button>
                </div>
            )}
            {!isAuthenticated && accessToken && (
                <div className="h-screen">
                    <LoadingSpinner />
                </div>
            )}
            {isAuthenticated && (
                <div className="w-full lg:pl-5 2xl:pl-7">
                    <div className="flex justify-between">
                        <div>
                            <h1 className=" font-Poppins text-xl md:text-2xl font-bold">
                                My Visits
                            </h1>
                            <h3 className=" font-Poppins pt-1 text-sm">
                                Manage your Visits & Bookings
                            </h3>
                        </div>
                    </div>
                    {loadingVisits &&
                        [...Array(5)].map(() => (
                            <ProjectMapLoadingUI className="flex flex-col animate-bounce mt-7" />
                        ))}
                    {siteVisits?.length == 0 && (
                        <div className="flex flex-col justify-center items-center text-center mt-12">
                            <p className={`inline-flex z-10 mt-6 `}>
                                No Site Visits Found
                            </p>
                            <button
                                onClick={() => history.push('/projects')}
                                className="inline-flex bg-primary text-white py-2 px-3 mt-2 rounded"
                            >
                                Click Here to Explore Projects
                            </button>
                        </div>
                    )}
                    {!loadingVisits && (
                        <div className="mt-7">
                            {siteVisits?.map((visit) => {
                                return (
                                    <SiteVisitItem
                                        visit={visit}
                                        setProjectDetails={setProjectDetails}
                                        setSelectedVisitId={setSelectedVisitId}
                                        setSelectedEnquiryId={
                                            setSelectedEnquiryId
                                        }
                                        setShowBookNowModal={
                                            setShowBookNowModal
                                        }
                                        setShowCancelModal={setShowCancelModal}
                                        setShowRevisitModal={
                                            setShowRevisitModal
                                        }
                                        setShowHomeLoanModal={setShowHomeLoanModal}
                                    />
                                );
                            })}
                        </div>
                    )}
                    <div className=" flex justify-end mt-7">
                        <Pagination
                            scrollTo="blogs_section"
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={setCurrentPage}
                        />
                    </div>
                </div>
            )}
            <BookNowModal
                bookNow={() => bookNow(selectedEnquiryId, setShowBookNowModal)}
                showBookNowModal={showBookNowModal}
                setShowBookNowModal={setShowBookNowModal}
            />
            <CancelModal
                cancelVisit={() =>
                    cancelVisit(selectedVisitId, setShowCancelModal)
                }
                showCancelModal={showCancelModal}
                setShowCancelModal={setShowCancelModal}
            />
            <RevisitModal
                projectDetails={projectDetails}
                showRevisitModal={showRevisitModal}
                setShowRevisitModal={setShowRevisitModal}
            />
            <HomeLoanModal showHomeLoanModal={showHomeLoanModal} setShowHomeLoanModal={setShowHomeLoanModal}/>
        </>
    );
}

export default SiteVisit;
