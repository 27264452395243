import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../../store/auth-slice';
import BsArrowRightShort from '@meronex/icons/bs/BsArrowRightShort';
import moment from 'moment';
import { getConfig } from '../../../../utils/config';
import { Button } from '../../../../Components/Atoms';
import { cmsClient, winnreAuth, winnreNoAuth } from '../../../../utils/axios';
import { renderDate, titleCase, truncate } from '../../../../helpers';
import ReactGA from 'react-ga4';
import useTimer from '../../../../Hooks/use-timer';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function DealsLayout({
    id,
    title,
    endDate,
    displayImageUrl,
    vendorLogoUrl,
    hideVendorLogo,
    fromProductPage,
    thumbnailImageUrl
}) {
    const preLoginWebview = useSelector(
        (state) => state.webView.preLoginWebview
    );

    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch();
    const config = getConfig();
    
    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
    };

    const userId = useSelector((state) => state.auth.userId);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const dealClickedHandler = () => {
        if (
            cmsClient.defaults.baseURL === 'https://prod.loyalie.in' ||
            cmsClient.defaults.baseURL === 'https://uat.loyalie.in'
        ) {
            window.fbq('trackCustom', 'GrabOfferButtonClicked');
        }
        ReactGA.event({
            category: 'OFFER',
            action: 'GRAB OFFER CLICKED',
            label: 'GRAB OFFER BUTTON'
        });
        var valueToSend = { message: 'OPEN_LOGIN' };
        if (!isAuthenticated && preLoginWebview) {
            // console.log('app login');
            window?.webkit?.messageHandlers?.jsMessageHandler.postMessage(
                valueToSend
            );
            window?.JSBridge?.showMessageInNative('OPEN_LOGIN');
            return;
        }
        if (isAuthenticated) {
            winnreAuth({
                method: 'POST',
                url: `winnre/user/deals/trackClick?dealId=${id}&userId=${userId}`
            })
                .then(() => {})
                .catch(() => {});
        } else {
            winnreNoAuth({
                method: 'POST',
                url: `winnre/user/deals/trackClick?dealId=${id}`
            })
                .then(() => {})
                .catch(() => {});
        }
        if (isAuthenticated) {
            history.push(`/deals/${id}`);
        } else {
            if(fromProductPage){
                history.push(`${location.pathname}${location.search}?postLoginUrl=/deals/${id}`)
            } else {
            history.push(`/deals?postLoginUrl=/deals/${id}`);}
            openLoginModal();
        }
    };
    
    return (
            <div
                onClick={dealClickedHandler}
                className="rounded-2xl shadow-lg hover:shadow-xl flex-none cursor-pointer w-[300px] h-full bg-[#130E2F]"
            >
                <div className=" flex flex-col">
                    <div className="relative">
                        <img
                            loading="lazy"
                            src={thumbnailImageUrl? thumbnailImageUrl: displayImageUrl}
                            className=" object-cover rounded-t-2xl w-full h-[300px]"
                            alt="Deal"
                        />

                        {!hideVendorLogo && (
                            <img
                                src={vendorLogoUrl}
                                className="absolute top-3 right-3 md:top-3 md:right-3 w-[60px] h-[60px] md:w-[60px] md:h-[60px] rounded-lg shadow-lg"
                                alt="Vendor Logo"
                            />
                        )}
                    </div>
                    <div className="flex flex-col justify-between content-between p-5 rounded-b-2xl h-[126px]">
                        <h1
                            className={`text-2xl lg:text-[18px] leading-6 lg:leading-6 text-white`}
                        >
                            {titleCase(title)}
                        </h1>
                        <div className="flex justify-between items-center mt-2 ">
                            <div className="flex justify-start">
                                <button
                                    className={`text-xs font-semibold ${
                                        config?.darktheme
                                            ? config?.deals?.darkTheme?.text
                                            : 'text-gray-300'
                                    }`}
                                >
                                    Know more {`>`}
                                </button>
                            </div>
                            <p
                                className={`${config?.deals?.dealLayout?.listBodyClass} text-[12px] italic text-gray-300`}
                            >
                                Valid till: {renderDate(endDate)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default DealsLayout;
