import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
    isAuthenticated: false,
    isLoginModalVisible: false,
    onboardingModalVisible: false,
    token: null,
    userId: null,
    userHash: null,
    userName: null,
    userRole: null,
    userDetails: {},
    unauthorizedUser: false,
    unauthorizedUserMessage: null,
    profileCompletionPercentage: null,
    finishedFirstTimeLoginGuide: null,
    showSessionTimeout: false,
    dealRedeemUser: false
};

const authSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthState,
    reducers: {
        login(state) {
            // console.log('loging in');
            state.isAuthenticated = true;
            state.showSessionTimeout = false;
        },
        logout(state) {
            // localStorage.removeItem('accessToken');
            // localStorage.removeItem('refreshToken');

            state.isAuthenticated = false;
            state.token = null;
            state.userName = null;
            state.userRole = null;
            state.userId = null;
            state.userHash = null;
            state.userDetails = {};
            state.profileCompletionPercentage = null;
            state.showSessionTimeout = false;
        },
        openLoginModal(state) {
            state.isLoginModalVisible = true;
            state.showSessionTimeout = false;
            state.dealRedeemUser = false;
        },
        closeLoginModal(state) {
            state.isLoginModalVisible = false;
            state.showSessionTimeout = false;
            state.dealRedeemUser = false;
        },
        authenticationToken(state, action) {
            state.token = action.payload;
            state.showSessionTimeout = false;
        },
        setUserData(state, action) {
            // console.log(action.payload);
            state.userDetails = action.payload.userDetails;
            state.userName = action.payload.name;
            state.userRole = action.payload.userRole;
            state.userId = action.payload.userId;
            state.userHash = action.payload.hashId;
            state.profileCompletionPercentage =
                action.payload.profileCompletionPercentage;
            state.finishedFirstTimeLoginGuide =
                action.payload.finishedFirstTimeLoginGuide;
        },
        setProfileCompletionPercentage(state, action) {
            state.userDetails = action.payload.userDetails;
            state.profileCompletionPercentage =
                action.payload.profileCompletionPercentage;
        },
        setFinishedFirstTimeLoginGuide(state, action) {
            state.finishedFirstTimeLoginGuide = action.payload;
        },
        setUnauthorizedUser(state, action) {
            state.unauthorizedUser = true;
            state.unauthorizedUserMessage = action.payload;
        },
        openOnboardingModal(state) {
            state.onboardingModalVisible = true;
        },
        closeOnboardingModal(state) {
            state.onboardingModalVisible = false;
        },
        setSessionTimeout(state, action) {
            // console.log('inside session', action.payload);
            state.showSessionTimeout = action.payload;
        },
        setUserDetails(state, action) {
            const { fieldName, value } = action.payload;
            if (state.userDetails.hasOwnProperty(fieldName)) {
                state.userDetails[fieldName] = value;
            }
        },
        setUserHash(state, action) {
            state.userHash = action.payload;
        },
        setUserMobile(state, action) {
            return {
                ...state,
                userDetails: { ...state.userDetails, ...action.payload }
            };
        },
        openLoginModalForDealRedeem(state) {
            state.isLoginModalVisible = true;
            state.showSessionTimeout = false;
            state.dealRedeemUser = true;
        }
    }
});

export const authActions = authSlice.actions;
export default authSlice;
