import react, { useEffect, useState } from 'react';
import { propertyFilterActions } from '../../../../store/property-filter-slice';
import { useDispatch, useSelector } from 'react-redux';
import { winnreNoAuth } from '../../../../utils/axios';
import { useLocation } from 'react-router-dom';
import useDidMount from '../../../../Hooks/useDidMount';

const useData = () => {
    // const data = [{id: 1, projectName:'ABC',projectDescription:'Test project details',city:'Kolkata 1',state:'West Benegal',price:'1.5 Cr',projectLogo:'https://dev.loyalie.in/reloy-website/',projectBgImage:'https://dev.loyalie.in/reloy-website/static/media/Rectangle%20790.d1c5ff74.png',status:'READY_TO_OCCUPY'},{id: 2, projectName:'ABC',projectDescription:'Test project details',city:'Kolkata 2',state:'West Benegal',price:'1.5 Cr',projectLogo:'https://dev.loyalie.in/reloy-website/',projectBgImage:'https://dev.loyalie.in/reloy-website/static/media/Rectangle%20790.d1c5ff74.png',status:'READY_TO_OCCUPY'},{id: 3, projectName:'ABC',projectDescription:'Test project details',city:'Kolkata 3',state:'West Benegal',price:'1.5 Cr',projectLogo:'https://dev.loyalie.in/reloy-website/',projectBgImage:'https://dev.loyalie.in/reloy-website/static/media/Rectangle%20790.d1c5ff74.png',status:'READY_TO_OCCUPY'},{id: 4, projectName:'ABC',projectDescription:'Test project details',city:'Kolkata 4',state:'West Benegal',price:'1.5 Cr',projectLogo:'https://dev.loyalie.in/reloy-website/',projectBgImage:'https://dev.loyalie.in/reloy-website/static/media/Rectangle%20790.d1c5ff74.png',status:'READY_TO_OCCUPY'},{id: 5, projectName:'ABC',projectDescription:'Test project details',city:'Kolkata 5',state:'West Benegal',price:'1.5 Cr',projectLogo:'https://dev.loyalie.in/reloy-website/',projectBgImage:'https://dev.loyalie.in/reloy-website/static/media/Rectangle%20790.d1c5ff74.png',status:'READY_TO_OCCUPY'},{id: 6, projectName:'ABC',projectDescription:'Test project details',city:'Kolkata 6',state:'West Benegal',price:'1.5 Cr',projectLogo:'https://dev.loyalie.in/reloy-website/',projectBgImage:'https://dev.loyalie.in/reloy-website/static/media/Rectangle%20790.d1c5ff74.png',status:'READY_TO_OCCUPY'}]
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');
    const [projects, setProjects] = useState([]);
    const [catalyst, setCatalyst] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(
        !isNaN(Number(page)) && Number(page) > 0 ? Number(page) : 1
    );
    // console.log('current', currentPage)
    const [initial, setInitial] = useState(true);
    const [loadingProjects, setLoadingProjects] = useState(false);
    const isMapView = useSelector((state) => state.propertyLayout.isMapView);

    const locationFilters = useSelector(
        (state) => state.propertyFilter.locationFilters
    );
    const budgetFilters = useSelector(
        (state) => state.propertyFilter.budgetFilters
    );
    const apartmentTypeFilters = useSelector(
        (state) => state.propertyFilter.apartmentTypeFilters
    );

    // const addFilterHandler = (e, category) => {
    //     // console.log(category);
    //     // console.log('BUD ID ADDED', e.target.id);
    //     // console.log('BEFORE DISPATCHING');
    //     console.log('HI', category, e.target.id);
    //     dispatch(
    //         propertyFilterActions.addFilter({
    //             category: category,
    //             value: e.target.id
    //         })
    //     );
    //     if (currentPage === 1) {
    //         setCatalyst((prevState) => prevState + 1);
    //     } else {
    //         setCurrentPage(1);
    //     }
    // };

    const addFilterHandlerNew = (val, category) => {
        // console.log('ME A');
        dispatch(
            propertyFilterActions?.addAllFilter({
                category: category,
                value: val
            })
        );
        if (currentPage === 1) {
            setCatalyst((prevState) => prevState + 1);
        } else {
            setCurrentPage(1);
        }
    };

    // const removeFilterHandler = (e, category) => {
    //     // console.log(category);
    //     // console.log(e.target.id);
    //     dispatch(
    //         propertyFilterActions.removeFilter({
    //             category: category,
    //             value: e.target.id
    //         })
    //     );
    // };

    const getProjectsWithFilters = () => {
        setLoadingProjects(true);
        let url = `/winnre/user/project?pageNumber=${
            currentPage - 1
        }&pageSize=${isMapView ? 5 : 4}${
            locationFilters.length >= 1
                ? `&cities=${locationFilters.join()}`
                : ''
        }${
            apartmentTypeFilters.length >= 1
                ? `&apartmentTypes=${apartmentTypeFilters.join()}`
                : ''
        }${
            budgetFilters.length >= 1
                ? `&minAmount=${
                      budgetFilters.length === 0 ? 0 : budgetFilters[0]
                  }&maxAmount=${
                      budgetFilters.length === 0
                          ? 99999999999
                          : budgetFilters[1]
                  }`
                : ''
        }`;
        winnreNoAuth.get(url).then((data) => {
            // console.log(data)
            setLoadingProjects(false);
            setTotalPages(data?.totalPages);
            setProjects(data?.content);
        });
    };

    useEffect(() => {
        if (!initial) {
            setCurrentPage(1);
            getProjectsWithFilters();
        }
    }, [isMapView]);

    useEffect(() => {
        getProjectsWithFilters();
        setInitial(false);
    }, [
        currentPage,
        catalyst,
        locationFilters,
        budgetFilters,
        apartmentTypeFilters
    ]);

    useDidMount(() => {
        setCurrentPage(1);
    }, [locationFilters, budgetFilters, apartmentTypeFilters]);

    return {
        projects,
        loadingProjects,
        catalyst,
        currentPage,
        totalPages,
        setCatalyst,
        setCurrentPage,
        setProjects,
        setTotalPages,
        // addFilterHandler,
        addFilterHandlerNew
        // removeFilterHandler
    };
};

export default useData;
