import { getFont } from '../../helpers';
import { openURLNewWindow } from '../../helpers';
import srijanBgImage from '../../assets/ReferralForm/Srijan.jpg';
import store from '../../store';

const SrijannConfig = {
    clientId:
        'f44e78c8b0cd6357b7393b2ed2f77998e41aba2db0a1f424561b1e67ada29884',
    id: 16,
    name: 'Srijan',
    fullName: 'Srijan Realty',
    theme: 'srijanTheme',
    color: '#AD2543', // for-projects module
    favicon:
        'https://srijanuat.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/20975235.png',

    googleConfig: {
        gID: 'G-S79L9R5KPL',
        keywordsContent:
            'Srijan Connect referral program, srijan realty referral programs,srijan connect refer and earn,srijan connect project refer, srijanconnect, srijan realty loyalty program, srijan realty news, srijan realty upcoming projects in Kolkata, srijan realty launch srijan connect, srijan realty launch, srijan realty program, srijan realty projects, srijan realty blogs, srijan realty offers, srijan upcoming projects in Kolkata, srijan realty events, Srijan Realty Pvt Ltd, Srijan Realty best residential projects, Srijan Realty real estate developers, All New Projects by Srijan Realty Builders & Developers, Ongoing Projects by Srijan Realty Pvt Ltd, srijan realty madhyamgram, srijan realty new program,',
        descriptionContent:
            'At Srijan Connect, you come above everything else. The Srijan Connect referral program is designed to enrich your experience through events, offers, and blogs',
        authorContent: 'Srijan Connect'
    },
    facebookPixelConfig: {
        id: '523926476033132',
        metaName: '',
        metaContent: ''
    },

    uatFacebookPixelConfig: {
        id: '352151026892027',
        metaName: '',
        metaContent: ''
    },
    layouts: {
        events: 'Layout2',
        blogs: 'Layout3',
        
    },

    logo: {
        name: 'srijanLogo',
        url: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/18473685.png',
        stickyNavBarLogoClass:
            'relative   h-10 lg:h-14 xl:h-14 2xl:h-16 left-4 lg:left-6 xl:left-10 3xl:left-16 top-0 ', //to match godrej nav bar
        baseNavBarLogoClass:
            'relative mt-2 lg:mt-8 h-10 lg:h-14 xl:h-14 2xl:h-16 left-4 lg:left-6 xl:left-10 3xl:left-16 top-0 '
    },

    navBarConfig: {
        showLogin: true,

        font: `${getFont('SRIJAN').heading}`,
        height: 'h-20 2xl:h-24',
        mobileIconColor: 'text-white',
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: ' ', // default: transparent
            containerClass: 'lg:mr-4 xl:mr-7 3xl:mr-10',
            itemClass:
                'text-white text-[14px] lg:mt-8 lg:text-white hover:text-accent font-semibold lg:text-[14px] xl:text-[14px] 3xl:text-lg lg:mr-6 xl:mr-5'
        },
        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: '', //default: primary-content,
            containerClass: 'font-semibold lg:mr-4 xl:mr-7 3xl:mr-10', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'text-white text-[14px] lg:text-white hover:text-accent font-semibold lg:text-[14px] xl:text-[14px] 3xl:text-lg lg:mr-6 xl:mr-5 ' // Each list element in NavBar (Text color, hover color, size)
        },
        showActiveLinkColor: false,
        footerNavItemClass:
            'font-Montserrat font-semibold text-[12px] lg:text-[14px] xl:text-[15px] uppercase hover:text-accent'
    },

    floatingButton: true,
    floaterType: 'SCROLL_TOP',
    bannerNegativeTopMarginClass: '-mt-20 2xl:-mt-24', //corresponding to  navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `bg-accent text-white rounded-full py-2 md:py-[10px] px-6 md:px-10 lg:py-[10px] lg:px-8 xl:py-[10px] xl:px-10  text-[12px] md:text-[17px]  lg:text-[15px] xl:text-[17px] font-bold hover:bg-transparent hover:border-2 hover:border-accent ${
        getFont('SRIJAN').heading
    }`,
    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-accent',
        text: 'text-accent',
        buttonBg: 'bg-accent'
    },
    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,
    booklet: true,
    routes: {
        home: {
            name: 'HOME',
            path: '/',
            show: true,
            components: [
                {
                    type: 'BANNER',
                    backgroundImage:
                        'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/38397525.jpg',
                    backgroundImageMobile:
                        'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/45125277.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content text-center max-w-full h-[75vh] md:h-[99vh] bg-center lg:bg-center xl:bg-center',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0.3'
                    },
                    components: [
                        {
                            type: 'TEXT',
                            content: 'WHERE HOMES MEET',
                            className: `md:mt-10 xl:mt-1 text-white text-center font-medium text-[16px] px-2 md:text-[24px] lg:text-[20px] xl:text-[26px] 2xl:text-[27px] break-words ${
                                getFont('SRIJAN').secondary
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'EXPERIENCES',
                            className: ` lg:-mt-1 xl:-mt-2 tracking-wide text-center font-extrabold text-white text-[25px] px-2 md:text-[37px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px]
                            ${getFont('SRIJAN').secondary}`
                        },

                        {
                            type: 'IMAGE',
                            url: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/77985018.png',
                            position: 'center',
                            className:
                                'w-[45%] md:w-[27%] lg:w-[18%] xl:w-[18%] 2xl:w-[18%] mb-3 lg:mt-2 xl:my-3 3xl:mt-6 '
                        },
                        // {
                        //     type: 'TEXT',
                        //     content:
                        //         'A Joint Initiative by Srijan Realty and Reloy',
                        //     className: `uppercase text-center mt-3 text-white text-[13px] md:text-[14px] lg:text-[16px] xl:text-[16.5px] 2xl:text-md mb-5 break-words ${
                        //         getFont('SRIJAN').heading
                        //     }`
                        // },
                        {
                            type: 'BUTTON',
                            content: 'REFER NOW',
                            className: `mb-24 text-[12px] md:text-[17px] lg:text-[15px] xl:text-[17px] font-bold bg-accent rounded-full py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 ${
                                getFont('SRIJAN').heading
                            }`,
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH_AND_SCROLL',
                            scrollTo: 'referral_section'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: '',
                    url: 'https://cdn.loyalie.in/46329169.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.97'
                    },
                    className:
                        'flex flex-col justify-center text-center pt-12 xl:pt-16 xl:px-16 2xl:px-28',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'ABOUT US',
                            className: `text-neutral text-center font-medium px-2 text-2xl md:text-[28px] lg:text-[25px] xl:text-[30px]  mb-4 lg:mb-3  ${
                                getFont('SRIJAN').secondary
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Srijan_Divider.svg',
                            width: '85',
                            height: '64',
                            name: 'first',
                            className: 'm-auto text-center mt-2'
                        },
                        {
                            type: 'TEXT',
                            content:
                                'SRIJAN CONNECT IS AN EXCLUSIVE LOYALTY INITIATIVE FOR THE RESIDENTS OF SRIJAN REALTY, DESIGNED TO UNLEASH THE POWER OF HAPPIER COMMUNITIES.',
                            className: `text-secondary text-normal self-center w-[90%] lg:w-[80%] 2xl:w-[90%] text-[17px] md:text-[20px] lg:text-[18px] xl:text-[20px] pb-5 xl:pb-8 pt-10 leading-6 md:leading-7 ${
                                getFont('SRIJAN').heading
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'We are initiating the platform to ensure richer experiences for our home owners and unlock a new lifestyle. We understand our brand is based on your trust and relationship with us, and we bring you this program to create something stronger and more lasting.',
                            className: `font-medium text-[17px] xl:text-[17px] text-neutral font-Montserrat self-center w-[90%] lg:w-[80%] lg:px-2 xl:px-0 2xl:w-[90%] break-words mb-14 lg:mb-4 xl:mb-10 ${
                                getFont('SRIJAN').heading
                            }`
                        },
                        {
                            type: 'ICONS_LAYOUT',
                            infoContainerClassName:
                                'px-4 lg:px-3 xl:px-5 my-4 lg:mt-0',
                            imgContainerClassName: 'h-[67px]',
                            imgClassName:
                                ' w-[22%] md:w-[12%] lg:w-full h-full object-contain mx-auto lg:pb-4 py-3 md:py-2 xl:py-3 2xl:py-2',
                            containerClassName:
                                'bg-transparent h-content w-full lg:flex lg:h-auto mb-4 lg:py-2 xl:px-4 px-8 items-center',
                            gridClassName:
                                'grid lg:grid-cols-4 lg:w-[85%]  xl:w-[90%] lg:pt-3 m-auto lg:text-center',
                            infoHeadingClassName:
                                'w-full md:w-[50%] lg:w-full xl:w-[80%] mx-auto md:px-2 lg:px-0 xl:px-2 font-Montserrat text-[18px] leading-7 md:text-[18px] text-secondary font-normal mt-2 mb-1 md:mb-1 lg:mb-4',
                            infotxtClassName:
                                'font-medium text-[17px] md:text-[15.5px] text-neutral font-Montserrat w-full md:w-[50%] mx-auto lg:w-full leading-5 break-words',
                            info: [
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/38742212.png',
                                    infoHeading: 'REFERRALS AND EARNINGS',
                                    infoText:
                                        'Refer your loved ones to us and earn up to 2% on every successful referral'
                                },
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/60060283.png',
                                    infoHeading: 'OFFERS AND PROMOTIONS',
                                    infoText:
                                        'Enjoy exclusive offers from premium pan India brands'
                                },
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/13156896.png',
                                    infoHeading: 'WORKSHOPS AND EVENTS',
                                    infoText:
                                        'Participate in specially curated events and workshops from top collaborators'
                                },
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/30274494.png',
                                    infoHeading: 'INTERACTIVE BLOGS',
                                    infoText:
                                        'Unlock a chance to interact with a community of like-minded people'
                                }
                            ]
                        },
                        {
                            type: 'TEXT',
                            content: 'START YOUR REFERRAL JOURNEY NOW',
                            className: `text-secondary text-normal px-2 pt-6 lg:pt-2 xl:pt-6 pb-12 text-[18px] md:text-[22px] lg:text-[20px] tracking-wide ${
                                getFont('SRIJAN').heading
                            }`
                        }
                    ]
                },
                {
                    type: 'REFERRAL_FORM'
                },
                {
                    type: 'CUSTOM',
                    id: 'overview',
                    url: 'https://cdn.loyalie.in/46329169.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '1'
                    },
                    className:
                        'flex flex-col justify-center text-center pt-12 lg:pt-16 xl:pt-16',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'READ WITH US',
                            className: `text-neutral font-medium px-2 text-2xl md:text-[28px] lg:text-[25px] xl:text-[30px] mb-4 lg:mb-3 ${
                                getFont('SRIJAN').secondary
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Srijan_Divider.svg',
                            width: '85',
                            height: '64',
                            name: 'second',
                            className: 'm-auto text-center mt-2'
                        },
                        { type: 'BLOG_LAYOUT_VERTICAL' }
                    ]
                },

                {
                    type: 'CUSTOM',
                    url: 'hhttps://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/69071169.jpg',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0.57',
                        repeat: 'no-repeat',
                        size: 'cover',
                        position: 'bottom right'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] py-20 lg:py-32 xl:py-36',
                    id: 'srijan_projects_locations',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'ENJOY REWARDING EXPERIENCES WITH US',
                            className: `text-white font-medium px-2 text-2xl md:text-[28px] lg:text-[25px] xl:text-[30px] mb-4 lg:mb-3 leading-10 lg:leading-none ${
                                getFont('SRIJAN').secondary
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Srijan_Divider.svg',
                            width: '85',
                            height: '64',
                            name: 'third',
                            className:
                                'm-auto flex justify-center text-center mt-5'
                        },
                        {
                            type: 'CAROUSEL',
                            caraouselClassName:
                                'px-8 md:px-24 lg:px-10 xl:px-24 2xl:px-56 md:py-10 2xl:py-20 mx-auto relative',
                            imageContainerClassName:
                                'w-auto lg:w-40 xl:w-auto p-10 md:px-10 lg:px-2 ',
                            imageClassName:
                                ' w-32 md:w-28 xl:w-32 xl:h-32 mx-auto',
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 1000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 5,
                            pauseOnHover: true,
                            speed: 1000,
                            onImageClick: () => {
                                return;
                            },
                            getVendorLogos: true //fetches from BE,
                        },
                        {
                            type: 'BUTTON',
                            content: 'GET REWARDED',
                            path: '/deals',
                            onClick: 'CHANGE_PATH'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR CONNECT STORIES',
                            className: `text-neutral text-center pt-12 2xl:pt-16 font-medium px-2 text-2xl md:text-[28px] lg:text-[25px] xl:text-[30px] mb-4 lg:mb-3 ${
                                getFont('SRIJAN').secondary
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Srijan_Divider.svg',
                            width: '85',
                            height: '64',
                            name: 'fourth',
                            className:
                                'm-auto text-center flex justify-center mt-2'
                        }
                    ]
                },

                {
                    type: 'CUSTOM',

                    className:
                        'px-10 md:px-12 xl:px-20 h-[1000px] w-[90%] md:w-full xl:w-[90%] mx-auto md:h-[450px] lg:h-[590px] 3xl:h-[650px] pt-14 pb-10 lg:pb-20 flex flex-col md:flex-row gap-x-4',

                    components: [
                        {
                            type: 'CUSTOM',
                            url: '',
                            linearGradient: {
                                R: '255',
                                G: '255',
                                B: '255',
                                opacity: '0'
                            },
                            className:
                                ' w-full md:w-[65%] h-[300px] md:h-[316px] lg:h-full mb-4 md:mb-0',

                            components: [
                                {
                                    type: 'VIDEO',
                                    videoUrl:
                                        'https://www.youtube.com/embed/psFI_ggqQ2E?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fwww.srijanconnect.loyalie.com&widgetid=1'
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            url: '',
                            linearGradient: {
                                R: '255',
                                G: '255',
                                B: '255',
                                opacity: '0'
                            },
                            className:
                                'w-full md:w-[35%] h-[600px] md:h-[300px] lg:h-full mb-4 flex flex-col gap-y-4 lg:gap-y-5  ',

                            components: [
                                {
                                    type: 'VIDEO',
                                    videoUrl:
                                        'https://www.youtube.com/embed/K0FT0779LrA?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fwww.srijanconnect.loyalie.com&widgetid=3'
                                },
                                {
                                    type: 'VIDEO',
                                    videoUrl:
                                        'https://www.youtube.com/embed/sfBTI6v4RQ4?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fwww.srijanconnect.loyalie.com&widgetid=5'
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    linearGradient: {
                        R: '242',
                        G: '242',
                        B: '243',
                        opacity: '1'
                    },
                    className: 'text-center w-full text-[#f58020] pt-16 ',
                    id: 'srijan_projects_locations',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR PROJECTS',
                            className: `text-neutral font-medium px-2 text-2xl md:text-[28px] lg:text-[25px] xl:text-[30px] mb-4 lg:mb-3  ${
                                getFont('SRIJAN').secondary
                            }`
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Srijan_Divider.svg',
                            width: '85',
                            height: '64',
                            name: 'fifth',
                            className:
                                'mx-auto text-center flex justify-center mb-5 mt-2'
                        },
                        {
                            type: 'CAROUSEL',
                            caraouselClassName:
                                'px-8 md:px-24 lg:px-10 xl:px-24 2xl:px-56 md:pb-5 lg:pb-5 mx-auto relative',
                            imageContainerClassName:
                                'w-auto lg:w-40 xl:w-auto p-10 md:px-10 lg:px-2',
                            imageClassName:
                                ' w-32 h-32 md:w-28 md:h-28 xl:w-32 xl:h-32 mx-auto cursor-pointer',
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 1000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 5,
                            pauseOnHover: true,
                            speed: 1000,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/30707692.jpg',
                                    alt: 'img',
                                    url: 'https://www.srijanrealty.com/project/detail/botanica'
                                },
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/41846070.jpg',
                                    alt: 'img',
                                    url: 'https://www.srijanrealty.com/project/detail/eternia'
                                },
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/23463580.jpg',
                                    alt: 'img',
                                    url: 'https://www.srijanrealty.com/project/detail/eternis'
                                },
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/24472893.jpg',
                                    alt: 'img',
                                    url: 'https://www.srijanrealty.com/project/detail/greenfield-city'
                                },
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/19101315.jpg',
                                    alt: 'img',
                                    url: 'https://www.srijanrealty.com/project/detail/laguna-bay'
                                },
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/44858443.jpg',
                                    alt: 'img',
                                    url: 'https://www.srijanrealty.com/project/detail/natura'
                                },
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/7135449.jpg',
                                    alt: 'img',
                                    url: 'https://www.srijanrealty.com/project/detail/nirvana'
                                },
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/22173771.jpg',
                                    alt: 'img',
                                    url: 'https://www.srijanrealty.com/project/detail/ozone'
                                },
                                {
                                    imgUrl: 'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/19720477.jpg',
                                    alt: 'img',
                                    url: 'https://www.srijanrealty.com/project/detail/solus'
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        refer: {
            show: false,
            name: 'REFER AND EARN',
            path: '/refer',
            component: 'ReferAndEarn'
        },

        events: {
            show: true,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: true,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: false,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },

    blogs: {
        banner: {
            bannerContainerClassName:
                'relative text-center hero hero-content max-w-full h-[75vh] lg:h-[97vh] bg-center object-cover lg:bg-center xl:bg-center',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.3'
            },
            backgroundImage:
                'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/39091238.jpg',
            backgroundImageMobile:
                'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/52735240.jpg',
            components: [
                {
                    type: 'TEXT',
                    content: 'THE SRIJAN CONNECT CLUB',
                    className: `font-extrabold text-center mt-16 md:mt-10 mb-2 md:mb-3 lg:mb-3 xl:mb-5 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] leading-8 md:leading-none tracking-wider ${
                        getFont('SRIJAN').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-0 md:mt-1 lg:mt-0 xl:mt-1 self-center mx-auto w-[30%] md:w-[20%] lg:w-[20%] bg-white border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-5 lg:mt-3 xl:mt-5 mb-3 lg:mb-6 xl:mb-4 uppercase text-[13px] md:text-base xl:text-[17px] tracking-wider ${
                        getFont('SRIJAN').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'READ MORE',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL'
                }
            ],
            loginBannerComponents: []
        },
        sideBarClass: `text-neutral ${getFont('SRIJAN').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass:
            ' text-[16px] text-[#AD2543] capitalize leading-5 font-Montserrat font-semibold',
        sideBarDateClass: 'text-[#64412b] text-[12px]',
        comments: {
            commentsBg: '',
            commentButtonClass: '',
            initialsClass: '',
            replyBorder: ''
        },
        sliderBanner: {
            featuredPost: {
                components: [
                    {
                        type: 'ANIMATE',
                        url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Srijan_Divider.svg',
                        width: '85',
                        height: '64',
                        name: 'ninth',
                        className: 'flex justify-center m-auto text-center'
                    }
                ],
                featuredPostClass:
                    'uppercase mt-3 lg:mt-0 font-Montserrat font-semibold text-[20px] text-[#1c2936]',
                headingClass:
                    'text-[20px] uppercase font-Montserrat text-secondary mt-3',
                summaryClass:
                    'inline text-[17px] mt-8 text-[#1c2936] font-Montserrat min-h-[40px] lg:min-h-[70px]',
                dateClass:
                    'text-[12px] uppercase font-Montserrat text-[#1c2936] font-semibold mt-3 mb-10 ',
                buttonClass: 'inline text-[17px] text-info font-Montserrat '
            },
            sectionHeadings:
                'px-10 lg:px-16 pt-16 pb-4 text-2xl md:text-[28px] lg:text-[25px] xl:text-[30px] text-neutral text-center uppercase font-medium font-Libre_Baskerville',
            dividerClass: 'hidden m-0 p-0 '
        },

        layout3: {
            components: [
                {
                    type: 'ANIMATE',
                    url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Srijan_Divider.svg',
                    width: '85',
                    height: '64',
                    name: 'tenth',
                    className: 'flex justify-center m-auto text-center'
                }
            ],
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: ` text-secondary font-Montserrat text-[18px] xl:text-[16px] 2xl:text-[18px] 3xl:text-[20px] font-normal uppercase leading-6 h-[80px] md:h-[70px] lg:h-[80px]`,
            categoryClass: ' bg-secondary text-white',
            summaryClass: `font-Roboto font-normal indent-8 text-[14px] my-4  text-[#777777] h-[70px] md:h-[50px] lg:h-[90px]`,
            buttonClass: `bg-[#AD2543] flex justify-center self-start text-white mt-2 mb-4 md:mb-5 rounded-full py-2 px-4 md:py-2 md:px-5 text-xs md:text-base lg:text-[14px] xl:text-sm font-medium font-Montserrat`,
            dateClass: `text-[10px] md:text-[12px] self-center text-[#adadad]`,
            noBlogsClass: ''
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage:
                'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/20523823.jpg',
            mobileDefaultActiveBannerImage:
                'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/22816144.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.3'
            },
            negativeMargin: '-mt-20 2xl:-mt-24',
            containerClass: ' hero self-center my-auto h-full text-white',
            heading: '',
            headingClass: `font-extrabold text-center mt-16 md:mt-10 mb-2 md:mb-3 lg:mb-3 xl:mb-5 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] leading-8 md:leading-none tracking-wider ${
                getFont('SRIJAN').secondary
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('SRIJAN').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[75vh] md:h-[97vh] mx-auto text-center ',
            components: [
                // {
                //     type: 'TEXT',
                //     content: 'CURATED WORKSHOPS AND EVENTS',
                //     className: `font-extrabold text-center mt-16 md:mt-10 mb-2 md:mb-3 lg:mb-3 xl:mb-5 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] leading-8 md:leading-none tracking-wider ${
                //         getFont('SRIJAN').secondary
                //     }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-0 md:mt-1 lg:mt-0 xl:mt-1 self-center mx-auto w-[30%] md:w-[20%] lg:w-[20%] bg-white border-none h-[4px]',
                //     classNameContainer: ''
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Meet and connect with like-minded people of your bespoke community',
                //     className: `font-normal mt-5 lg:mt-3 xl:mt-5 mb-3 lg:mb-6 xl:mb-4 uppercase text-[13px] md:text-base xl:text-[17px] tracking-wider ${
                //         getFont('SRIJAN').heading
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Participate Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC'
                // }
            ]
        },

        eventsSection: {
            // sectionBackgroundImage:
            //     'https://cdn.loyalie.in/17509686.png',
            // overlayClass: 'bg-neutral opacity-95',
            // linearGradient: {
            //     R: '255',
            //     G: '255',
            //     B: '255',
            //     opacity: '0',
            //     size: '30% auto'
            // },
            heading: 'PAST EVENTS',
            headingClass: `self-center font-medium text-center text-2xl md:text-[28px] lg:text-[25px] xl:text-[30px] text-neutral w-full md:w-[60%] mt-16 ${
                getFont('SRIJAN').secondary
            }`,
            components: [
                {
                    type: 'ANIMATE',
                    url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Srijan_Divider.svg',
                    width: '85',
                    height: '64',
                    name: 'first',
                    className: 'm-auto text-center mt-2'
                }
            ],
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center font-semibold text-[16px] md:text-[20px] text-center  mt-14 text-neutral w-[90%] md:w-[60%] ${
                getFont('SRIJAN').secondary
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl 2xl:text-2xl text-center m-auto ${
                getFont('SRIJAN').body
            }`
        },
        eventLayout: {
            titleClass: `text-accent leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('SRIJAN').heading
            }`,
            hostClass: 'font-Helvetica_Light capitalize ',
            dateClass: '',
            loadMoreButtonClass: `bg-accent text-white  text-[12px] md:text-[17px]  lg:text-[15px] xl:text-[17px]  hover:text-accent rounded-full py-2 md:py-[10px] px-6 md:px-10 lg:py-[10px] lg:px-8 xl:py-[10px] xl:px-10 hover:bg-transparent hover:border-2 hover:border-accent ${
                getFont('SRIJAN').heading
            }`
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-accent font-semibold text-base md:text-xl uppercase ml-4 z-10 ${
            getFont('SRIJAN').heading
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('SRIJAN').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'relative hero hero-content max-w-full h-[90vh] lg:h-[97vh] bg-top lg:bg-center ', // h-[75vh] xl:bg-top
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0' //.3
            },
            // backgroundImage:
            //     'https://cdn.loyalie.in/33273711.jpg',
            // backgroundImageMobile:
            //     'https://cdn.loyalie.in/50795630.jpg',
            backgroundImage:
                'https://d3lsue9ruympma.cloudfront.net/Christmas_Banner/Christmas-banner-final.gif',
            backgroundImageMobile:
                'https://d3lsue9ruympma.cloudfront.net/Christmas_Banner/Christmas-banner-final-mobile.gif',
            components: [],
            loginBannerComponents: [
                // {
                //     type: 'TEXT',
                //     content: 'EXCLUSIVE OFFERS AND PROMOTIONS',
                //     className: `font-extrabold text-center mt-16 md:mt-10 mb-2 md:mb-3 lg:mb-3 xl:mb-5 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] leading-8 md:leading-none tracking-wider ${
                //         getFont('SRIJAN').secondary
                //     }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-0 md:mt-1 lg:mt-0 xl:mt-1 self-center mx-auto w-[30%] md:w-[20%] lg:w-[20%] bg-white border-none h-[4px]',
                //     classNameContainer: ''
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Srijan Connect is driven to create experiences that add more value to your life',
                //     className: `font-normal mt-5 lg:mt-3 xl:mt-5 mb-3 lg:mb-6 xl:mb-4 uppercase text-[13px] md:text-base xl:text-[17px] tracking-wider ${
                //         getFont('SRIJAN').heading
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC'
                // }
            ]
        },

        dealsSection: {
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center text-center font-semibold text-[18px] md:text-[18px] mt-5 text-neutral w-[60%] ${
                getFont('SRIJAN').secondary
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `text-2xl xl:text-3xl uppercase text-secondary  ${
                getFont('SRIJAN').heading
            } `,
            subAdditionalText:
                'Srijan Connect is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` text-neutral ${
                getFont('SRIJAN').heading
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary-focus text-2xl text-center my-6
             ${getFont('SRIJAN').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1:
                'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/23586323.png',
            pattern2:
                'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/52253550.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.95',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-2xl lg:text-xl leading-6 lg:leading-5 text-accent ${
                getFont('SRIJAN').heading
            }`,
            listBodyClass: `${getFont('SRIJAN').heading}`,
            logoClassName: 'self-center rounded-lg drop-shadow-lg',
            titleClassName: ` text-accent font-medium uppercase text-2xl xl:text-3xl ${
                getFont('SRIJAN').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-semibold text-neutral    ${
                getFont('SRIJAN').heading
            }`,
            dateClassName: `text-[#595a5c] font-medium capitalize  ${
                getFont('SRIJAN').heading
            }`,
            termsAndConditionsClassName: `text-sm text-[#373737] font-medium capitalize  ${
                getFont('SRIJAN').heading
            }`,
            termsArrowClassName:
                'self-center text-2xl lg:text-xl xl:text-2xl text-[#373737]',
            termsClassName: `text-[#373737] text-xs font-medium break-words leading-3 ${
                getFont('SRIJAN').heading
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-[#AD2543] text-white font-bold uppercase self-center rounded-full text-[15px]  lg:text-[13px] xl:text-[15px] py-3 lg:py-[10px] xl:py-3 px-5 lg:px-6 xl:px-5  ${
                    getFont('SRIJAN').heading
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `bg-[#AD2543] border-[#AD2543] border-[2px] hover:bg-white hover:text-[#AD2543] hover:font-semibold  self-center font-bold uppercase text-white rounded-full text-[15px]  lg:text-[13px] xl:text-[15px] py-3 lg:py-[10px] xl:py-3 px-5 lg:px-6 xl:px-7 ${
                    getFont('SRIJAN').heading
                }`
            }
        }
    },
    projects: {
        color: '#AD2543', // projects-module
        applyBtnBgColor: 'bg-accent',
        clearFilterTextColor: 'text-accent',
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.5'
                },
                backgroundImage:
                    'https://x3i9k7m8.stackpathcdn.com/images/project_gallery/159928728720200905.jpg',
                backgroundImageMobile:
                    'https://x3i9k7m8.stackpathcdn.com/images/project_gallery/159928728720200905.jpg',
                bannerContainerClassName:
                    'relative text-center hero hero-content max-w-full h-[75vh] lg:h-[97vh] bg-center object-cover lg:bg-center xl:bg-center',

                components: [
                    {
                        type: 'TEXT',
                        content: 'SRIJAN PROPERTIES THAT YOU CAN REFER',

                        className: `font-extrabold mb-2 md:mb-3 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] tracking-wider ${
                            getFont('SRIJAN').secondary
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[30%]  lg:w-[16%] xl:w-[20%] bg-white border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ]
    },
    header: {
        headerClass: 'block h-20 lg:h-28 bg-[#15519e]'
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/46329169.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.97',
            repeat: 'repeat',
            size: 'contain'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.3'
                },
                backgroundImage:
                    'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/45900172.jpg',
                backgroundImageMobile:
                    'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/69537340.jpg',
                bannerContainerClassName:
                    'relative text-center hero hero-content max-w-full h-[75vh] lg:h-[97vh] bg-bottom lg:bg-center xl:bg-center',
                // bannerContainerClassName:
                //     'hero hero-content max-w-full h-[75vh] lg:h-[97vh] bg-center',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',
                        className: `font-extrabold mb-2 md:mb-3 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] tracking-wider ${
                            getFont('SRIJAN').secondary
                        }`
                    },
                    {
                        type: 'DIVIDER',

                        className:
                            'mt-1 self-center mx-auto w-[25%]  lg:w-[20%] xl:w-[20%] bg-white border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],


    },
    termsAndConditions: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.3'
                },

                backgroundImage:
                    'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/87052952.jpg',
                backgroundImageMobile:
                    'https://srijanprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Srijan/Images/45352024.jpg',
                bannerContainerClassName:
                    'relative  hero hero-content max-w-full h-[75vh] lg:h-[97vh] bg-bottom lg:bg-center xl:bg-center',
                bannerContainerClassName:
                    'hero text-center hero-content max-w-full h-[75vh] lg:h-[97vh]',

                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS & CONDITIONS',
                        className: `font-extrabold mb-2 md:mb-3 text-[22px] px-2 md:text-[35px] lg:text-[31px] xl:text-[38px] 2xl:text-[40px] tracking-wider ${
                            getFont('SRIJAN').secondary
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[30%] lg:w-[20%] xl:w-[20%] bg-white border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            },
            {
                type: 'CUSTOM',

                className: 'text-left w-full p-4 md:p-16 2xl:px-36',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-[#434141] font-medium text-[18px] md:text-[20px] ${
                            getFont('SRIJAN').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: `tracking-wide text-[#434141] mt-4 font-medium text-[18px] md:text-[20px] ${
                            getFont('SRIJAN').heading
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” is an existing eligible customer having booked a unit in a client project, who has entered into a registered Agreement to Sell and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s of Client. ‘Referred’ is the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered Client Project/s and registers an agreement for sale for such unit(s). ',
                            ,
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Client. ',
                            'Referrer shall not be able to refer existing customer(s) of Client. ',
                            'Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Client or (ii) is/are already registered with Client as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Client’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time. ',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers. ',
                            'If your referred client is not in touch with any other Sales Teams or Sales Partners, then it will be accepted as an eligible referral lead after confirmation of the LOI by us. But, if the referred client does not book within 90 days from the date of his LOI submission, then your entitlement for reward money for that client ends & we will be free to deal with those clients through other Sales Teams/Partners.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Reloy. - ',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days post the date of registration of the agreement for sale by both the Referrer & the Referred party',
                            'Srijan Connect Loyalty members to be awarded with -'
                            // 'i) For upto 1cr the reward bonus is 1% of the total sale value',
                            // 'ii) For upto 1.01-2cr the reward bonus is 1.5% of the total sale value',
                            // 'iii) For 2.01cr and above the reward bonus is 2% of the total sale value',
                            // 'Sale value = unit price, parking charges and floor escalation as applicable ',
                            // 'Note: Base connect reward 1% will be released immediately once the agreement is executed and the client has made 20% payment of the total consideration value. Remaining bonus reward will be released at the end of the financial year of the scheme '
                            // '•	The offers will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions. ',
                            // '•	Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward. ',
                            // '•	Reloy and Srijan will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards or offers from any 3rd party by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            // '•	The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy & Srijan.',
                            // '•	All disputes shall be subject to exclusive jurisdiction of Kolkata courts. '
                        ],

                        listClassName: `text-[#434141] font-medium text-[15px] md:text-[17px] pb-2 leading-7 ${
                            getFont('SRIJAN').heading
                        }`,
                        classNameContainer: 'px-6 mt-6'
                    },
                    {
                        type: 'CUSTOM',
                        className: 'px-6 -',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'i) For upto 1.5 Cr the reward bonus is 1% of the total sale value',

                                className: `${
                                    getFont('SRIJAN').heading
                                } text-[#434141] font-medium text-[15px] md:text-[17px] leading-7  `
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'ii) For above 1.5 Cr upto 2.5 Cr the reward bonus is 1.5% of the total sale value',

                                className: `${
                                    getFont('SRIJAN').heading
                                } itext-[#434141] font-medium text-[15px] md:text-[17px] leading-7  `
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'iii) For above 2.5 Cr the reward bonus is 2% of the total sale value',

                                className: `${
                                    getFont('SRIJAN').heading
                                } text-[#434141] font-medium text-[15px] md:text-[17px] leading-7  `
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Sale value = unit price, parking charges and floor escalation as applicable  ',

                                className: `${
                                    getFont('SRIJAN').heading
                                }  text-[#434141] font-semibold text-[15px] md:text-[17px] leading-7  `
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Note: Base Connect Payout of 1% will be released immediately once the agreement is executed and the referred customer & agent has made 20% payment of the total consideration value. The remaining bonus payout will be released at the end of the financial year of the scheme',

                                className: `${
                                    getFont('SRIJAN').heading
                                } text-[#434141] font-medium text-[15px] md:text-[17px] leading-7  `
                            }
                        ]
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            'The offers will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions. ',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward. ',
                            'Reloy and Srijan will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards or offers from any 3rd party by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy & Srijan.',
                            'All disputes shall be subject to exclusive jurisdiction of Kolkata courts. '
                        ],

                        listClassName: `text-[#434141] font-medium text-[15px] md:text-[17px] pb-5 leading-7 ${
                            getFont('SRIJAN').heading
                        }`,
                        classNameContainer: 'px-6 mt-6'
                    },
                    {
                        type: 'CUSTOM',
                        className: 'px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('SRIJAN').heading
                                } inline text-[#434141] font-medium text-[15px] md:text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-red-400 font-medium text-[15px] md:text-[17px] leading-7 ${
                                    getFont('SRIJAN').heading
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-[#434141] font-medium text-[15px] md:text-[17px] leading-7 ${
                                    getFont('SRIJAN').heading
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-red-400 font-medium text-[15px] md:text-[17px] leading-7 ${
                                    getFont('SRIJAN').heading
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-[#434141] font-medium text-[15px] md:text-[17px] leading-7 ${
                                    getFont('SRIJAN').heading
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-red-400 font-medium text-[15px] md:text-[17px] leading-7 ${
                                    getFont('SRIJAN').heading
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-[#434141] font-medium text-[15px] md:text-[17px] leading-7 ${
                                    getFont('SRIJAN').heading
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },

    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content ',
        footerLogoClassName: 'self-center md:w-[15%] lg:w-[15%] lg:pl-6',
        termsAndConditionsClassName: `text-white text-[11px] 2xl:text-[11.5px] 3xl:text-[14.5px] text-right mr-4 pb-1 font-Montserrat mt-1 md:mt-0 2xl:-mt-3`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT Solutions Pvt Ltd and the referral payout will be processed by them. They are registered under West Bengal RERA - HIRA/A/KOL/2018/000350 | http://hira.wb.gov.in',
        loyalieFooterContentClassName: `md:w-[75%] lg:w-[90%] self-center font-Montserrat md:flex-1 mt-4 md:mt-0 px-10 md:px-0 lg:pr-10 md:pl-6 2xl:pl-0 text-[12px] md:text-[14px] lg:text-[12px] xl:text-[13px] leading-[18px] 3xl:leading-8 tracking-tight text-white`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('SRIJAN').heading
        }`,
        paginationClass:
            ' text-white disabled:text-white disabled:cursor-not-allowed bg-primary border-primary hover:bg-opacity-90 hover:border-primary hover:bg-primary disabled:bg-primary',
        paginationActivePageColor: 'text-white bg-opacity-90'
    },
    referralForm: {
        name: 'Srijan',
        theme: 'srijanTheme',
        color: '#15519e',
        backgroundImage: srijanBgImage,
        bgOverlay: true,
        linearGradient: {
            degree: '90deg',
            R1: '255',
            G1: '255',
            B1: '255',
            opacity1: '0',
            R2: '255',
            G2: '71',
            B2: '19',
            opacity2: '0'
        },
        userTypes: {
            length: 2,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading: '',
            employee: false,
            employeeHeading: '',
            salesAdmin: true,
            salesAdminHeading: '',
            influencer: false,
            influencerHeading: ''
        },
        formContent: {
            heading: '"CONNECT TO CREATE MAGICAL EXPERIENCES"',
            headingClass: `self-center text-white text-3xl xl:text-4xl w-[80%] lg:w-[50%] xl:w-[60%] 2xl:w-[50%] text-center lg:text-left tracking-widest lg:leading-normal 2xl:leading-relaxed ${
                getFont('SRIJAN').secondary
            }`,
            notification: {
                line1: ' ',
                line1Class: `
                `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            employeeDisclaimer: false
        },
        leadEmailMandatory: false,
        influencerLinkActive: false,
        isDirectTAndCLink: true,
        termsAndConditionsLink:
            'https://srijanconnect.com/srijan-website/terms-and-conditions',
        customLink: 'srijan-website/projects/detail'
    }
};
export default SrijannConfig;
