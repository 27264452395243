import React from 'react';
import useTagsData from './data';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import CategoryBar from '../CategoryBar';
import SkeletonCategoryBar from '../Skeleton/SkeletonCategoryBar/SkeletonCaegoryBar';

function FilterOptions({
    selectedCategories,
    onFilterClick,
    type,
    config,
    color,
    categoryId,
    showArrowIndicator,
    hideCashbackButton
}) {
    const { categories, isLoading } = useTagsData({
        type: type,
        categoryId: categoryId
    });
    const { path } = useRouteMatch();
    if (path === '/events') {
        return (
            <div className="flex flex-wrap gap-x-2 lg:gap-x-4 gap-y-2 self-center mx-auto justify-evenly pt-6 w-[80%] md:w-[70%] ">
                {categories?.map((tag) => (
                    <div
                        key={tag.id}
                        className="flex self-center justify-center"
                    >
                        <input
                            type="checkbox"
                            value={tag.id}
                            checked={selectedCategories.includes(tag.id)}
                            onClick={() => onFilterClick(tag.id)}
                            className="z-10  self-center checkbox-sm mx-2  bg-white rounded-md border-gray-100 checked:bg-primary"
                            autoComplete="off"
                        />
                        <p
                            className={
                                config?.commonComponents.filterTagClassName
                                    ? config?.commonComponents
                                          .filterTagClassName
                                    : 'font-brandFont text-accent-content uppercase'
                            }
                            // style={{ color: `${color}` }}
                        >
                            {tag.name}
                        </p>
                    </div>
                ))}
            </div>
        );
    }
    return (
        <>
            {/* {isLoading && (
                <>
                    <div className="flex lg:hidden justify-evenly">
                        {[...Array(4).keys()].map((n) => (
                            <SkeletonCategoryBar key={n} />
                        ))}
                    </div>
                    <div className="hidden lg:flex justify-evenly">
                        {[...Array(10).keys()].map((n) => (
                            <SkeletonCategoryBar key={n} />
                        ))}
                    </div>
                </>
            )} */}
            {/* {!isLoading && ( */}
            <CategoryBar
                categories={categories}
                selectedCategories={selectedCategories}
                onFilterClick={onFilterClick}
                showArrowIndicator={showArrowIndicator}
                scrollable={true}
                showCashbackButton={!hideCashbackButton}
            />
            {/* )} */}
            {/* <div className="flex justify-start overflow-x-scroll scrollbar-thin border-b-[1px] md:w-full lg:w-full  mx-auto ">
                {tags?.map((tag) => (
                    <div
                        key={tag.id}
                        className={`${
                            filters.includes(tag.id)
                                ? config?.darktheme
                                    ? 'bg-gray-700'
                                    : 'bg-[#f7f7f7]'
                                : ''
                        } min-w-[30%] lg:min-w-[15%]  z-10 flex self-center justify-center ${
                            config?.darktheme
                                ? 'hover:bg-gray-700 focus:bg-gray-700'
                                : 'hover:bg-[#f7f7f7] focus:bg-[#f7f7f7]'
                        }  `}
                    >
                        <button
                            className={`  ${
                                filters.includes(tag.id)
                                    ? 'text-info'
                                    : config?.darktheme
                                    ? 'text-gray-200'
                                    : 'text-black'
                            } p-2 text-sm font-sans font-semibold hover:transition-all hover:ease-in hover:duration-300 hover:-translate-y-1 overflow-y-hidden`}
                            type="button"
                            value={tag.id}
                            onClick={() => onFilterClick(tag.id)}
                        >
                            <img
                                src={tag?.logo?.url}
                                className={`${
                                    filters.includes(tag.id)
                                        ? 'w-10 md:w-12 xl:w-14'
                                        : 'w-8 md:w-10 xl:w-12'
                                }  mx-auto mb-1`}
                            />
                            {tag.name}
                        </button>
                    </div>
                ))}
            </div> */}
        </>
    );
}

export default FilterOptions;
