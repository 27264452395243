import { useEffect, useRef } from 'react';

const useInitialRender = () => {
    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        }
    }, []);

    return isInitialRender.current;
};

export default useInitialRender;
