import { useState } from "react"
import { winnreAuth, winnreNoAuth } from "../../../utils/axios"

const useCampaign = () => {
  const [campaigns, setCampaigns] = useState([])
  const [projects, setProjects] = useState([])
  
  const getActiveCampaigns = () => {
        winnreAuth({url:'/winnre/cp/user-ladder/incentive?incentiveFilterType=ACTIVE', method:'GET'}).then((res) => {
          if(res?.content?.length == 0) { 
            setCampaigns([])
            winnreNoAuth({url:`/winnre/user/project/names`, method:'GET'}).then((res) => {
                setProjects(res)} )
            return
          } else{
        setCampaigns(res?.content)
      setProjects([])}})
    }
    const getCityBasedProjectList = (city) => { winnreNoAuth({url:`/winnre/user/project/names${city?`?city=${city}`:''}`, method:'GET'}).then((res) => {

        setProjects(res)} )}

  return {getActiveCampaigns, campaigns, projects, getCityBasedProjectList}
}

export default useCampaign