import React from 'react';
import ReactPlayer from 'react-player/youtube';

function Video({ videoUrl }) {
    return (
        <ReactPlayer
            url={videoUrl}
            className="react-player px-1"
            light={false}
            // onClickPreview={() =>
            //     this.props.onVideoClick(imgdata, i)
            // }
            width="100%"
            height="100%"
            controls={true}
        />
    );
}

export default Video;
