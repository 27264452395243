import React from 'react'
import SkeletonElement from '../../SkeletonElement';
const SkeletonDeal = ({shape}) => {
    const addCssByShape = () => {
        switch (shape) {
            case 'square':
                return 'w-[350px] h-[350px] ';
            case 'rectangle':
                return 'w-[500px] ';
        }
    }

  
  return (
  
      

            <div className="flex  flex-col lg:items-center mx-auto p-2  w-full lg:w-full 3xl:w-[365px] lg:flex-row lg:gap-8">
             <div className={`lg:w-[50%]  sm:w-full  `}>
              <SkeletonElement type="thumbnail"   size="h-36  w-full   lg:h-[400px]"/>
             </div>
            <div className='lg:w-[50%]'>
             <SkeletonElement type="text"  size='w-[65%] h-5' style="rounded-none mt-3"/>
              <SkeletonElement type="title" size='w-[35%] h-5'style="rounded-none mt-2" />
              <SkeletonElement type="text"  size='w-[55%] h-5' style="rounded-none mt-4"/>
              <SkeletonElement type="title" size='w-[25%] h-5' style="rounded-none mt-3" />
             <SkeletonElement type="text" size=" w-[95%] h-5" style="rounded-none mt-4"/>
             <SkeletonElement type="text" size=" w-[95%] h-5" style="rounded-none mt-1"/>
             <SkeletonElement type="text"  size='w-[65%] h-5' style="rounded-none mt-7 "/>
             <SkeletonElement type="text" size=" w-[95%] h-5" style="rounded-none mt-4"/>
             <SkeletonElement type="text" size=" w-[95%] h-5" style="rounded-none mt-1"/>
             <SkeletonElement type="text" size=" w-[95%] h-5" style="rounded-none mt-4"/>
             <SkeletonElement type="text" size=" w-[95%] h-5" style="rounded-none mt-1"/>
             {/* <SkeletonElement type="text" size=" w-[95%] h-5" style="rounded-none mt-4"/>
             <SkeletonElement type="text" size=" w-[95%] h-5" style="rounded-none mt-1"/> */}
            </div>
         </div>

       
    );

}
export default SkeletonDeal
