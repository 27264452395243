import React from 'react';
import { getConfig } from '../../../utils/config';
import { scrollToTargetAdjusted } from '../../../utils/ui';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Butt = styled.button`
    ${(props) =>
        props.fontFamily && `font-family: ${props.fontFamily} !important;`}
    ${(props) => props.fontSize && `font-size: ${props.fontSize} !important;`}
`;

const Button = (props) => {
    const config = getConfig();
    const {
        content,
        className = config?.clientButtonClassName,
        link,
        path,
        fontFamily,
        fontSize,
        scrollTo,
        onClick,
        onClickFunc,
        redeemNow,
        pdf,
        embeddedReferSection,
        embeddedPath,
        disabled
    } = props;

    const history = useHistory();

    const changePath = (path) => {
        history.push(`${path}${pdf ? `?pdf=${pdf}` : ''}`);
    };

    const changePathAndScroll = (path) => {
        history.push({
            pathname: path,
            state: { scrollTo: scrollTo }
        });
    };

    const onClickHandler = (e, type) => {
        switch (type) {
            case 'SCROLL':
                return scrollToTargetAdjusted(scrollTo);
            case 'LINK':
                return window.open(link);
            case 'FUNC':
                return onClickFunc(e);
            case 'CHANGE_PATH':
                return changePath(path);
            case 'CHANGE_PATH_AND_SCROLL':
                return changePathAndScroll(path);
            default:
                return;
        }
    };

    return (
        <Butt
            onClick={(e) => onClickHandler(e, onClick)}
            className={`
                ${
                    className
                        ? className
                        : ' py-2 px-10 rounded-none bg-primary font-brandFont text-primary hover:bg-primary-focus'
                } ${
                redeemNow ? redeemNow : ''
            } disabled:bg-gray-300 disabled:cursor-not-allowed disabled:hover:bg-gray-300 disabled:text-white disabled:hover:text-white disabled:hover:border-none disabled:border-none
           `}
            fontFamily={fontFamily}
            fontSize={fontSize}
            disabled={!!disabled}
        >
            {content}
        </Butt>
    );
};

export default Button;
