import React from 'react';
import GrClose from '@meronex/icons/gr/GrClose';
import Modal from '../../Components/Organisms/Modal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const RevisitModal = ({
    projectDetails,
    showRevisitModal,
    setShowRevisitModal,
    loading
}) => {
    const history = useHistory()
    const reVisit = () => {
        history.push(`/projects/book-a-site-visit?id=${projectDetails?.id}&image=${projectDetails?.projectBgImage}&name=${projectDetails?.projectName}&city=${projectDetails?.city}`)

        // history.push({
        //     pathname: '/projects/book-a-site-visit',
        //     state: {
        //         projectId: projectDetails?.id,
        //         projectBgImage: projectDetails?.projectBgImage,
        //         projectCity: projectDetails?.city,
        //         projectName: projectDetails?.projectName
        //     }
        // })
    }
    return (
        <Modal
            visible={showRevisitModal}
            onClick={() => {
                if (loading) return;
                setShowRevisitModal(false);
            }}
        >
            <div className="flex justify-end px-4 ">
                <GrClose
                    onClick={() => {
                        if (loading) return;
                        setShowRevisitModal(false);
                    }}
                    className={` h-5 w-5 cursor-pointer `}
                />
            </div>
            <div className="py-6 flex flex-col">
                <p
                    className={`
             z-10 mt-2 mx-auto w-[90%] lg:w-[80%] text-center `}
                >
                    Thankyou for your interest. Do you want to proceed with scheduling another visit?
                </p>
                <div className="flex flex-row align-items-center justify-center mt-2">
                    <button
                        onClick={() => setShowRevisitModal(false)}
                        className={
                            'bg-secondary justify-self-center px-5 mt-2 py-1.5 text-white text-center mr-2 rounded self-center'
                        }
                    // disabled={loading}
                    >
                        {loading ? 'Loading...' : 'Return'}
                    </button>

                    <button
                        onClick={() => reVisit()}
                        className={
                            'bg-primary justify-self-center px-5 mt-2 py-1.5 text-white text-center mr-2 rounded self-center'
                        }
                        disabled={loading}
                    >
                        Yes
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default RevisitModal;
