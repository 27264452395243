import React, { useState } from 'react';
import useData from '../data';
import { useHistory} from 'react-router-dom';

const TotalReferral = ({ active, setActive, userAccountSummary }) => {
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);

    const changeStatus = (e) => {
        setActive(e.target.id);
    };

    const history = useHistory();
    // Check if the user has made any referrals

    const hasReferrals = userAccountSummary && (userAccountSummary.leadSubmissions > 0);
    const handleReferNowClick = () => {
        history.push('/gift-and-earn');
    };


    return (
        <div className="">
            {showWelcomeMessage && !hasReferrals ? (
                <div className="flex flex-col items-center justify-center text-center py-10">
                    <h1 className="text-black text-lg md:text-xl font-bold">
                        Welcome to the Referral Program!
                    </h1>
                    <p className="font-Poppins pt-1 text-sm lg:whitespace-nowrap">
                        It looks like you haven't started referring your friends yet. It's easy and rewarding!
                    </p>
                    <button className="mt-4 bg-primary text-white px-4 py-2 rounded-full" onClick={handleReferNowClick}>
                        Click here to refer now
                    </button>
                </div>
            ) : (
                <div className="">
                    <div className='flex'>
                    <div className="w-[60%] lg:w-[30%] flex-grow ">
                        <div className='flex items-center justify-between'>
                            <div>
                            <h1 className="text-black text-lg md:text-xl font-bold">
                            Past Referral List
                        </h1>
                            </div>
                       
                        {hasReferrals && (
                        <div className="">
                            <button className="bg-primary text-white px-4 py-2 rounded-full" onClick={handleReferNowClick}>
                                Start Referring
                            </button>
                        </div>
                    )}

                        </div>
                        
                        <p className="font-Poppins pt-1 text-sm whitespace-nowrap">
                            All your cashback information comes here
                        </p>
                    </div>
                    
                    </div>
                    <div id="pastReferrals" className="py-7 lg:pt-10 border-[#EBEAF2]">
                        <div className="flex lg:items-center gap-x-10">
                            <div className="hidden lg:flex justify-between gap-x-10">
                                <div
                                    onClick={(e) => changeStatus(e)}
                                    className={`flex items-center gap-1 pr-5 cursor-pointer ${active == 'all' ? 'text-primary font-bold' : 'text-black'}`}>
                                    <h3 id="all">All</h3>
                                    <h1>({userAccountSummary?.leadSubmissions || '0'})</h1>
                                    {active === 'all' && <hr className="h-[5px] bg-primary rounded w-full" />}
                                </div>
                                <div
                                    onClick={(e) => changeStatus(e)}
                                    className={`flex items-center gap-1 pr-5 cursor-pointer ${active == 'SITE_VISIT_DONE' ? 'text-primary font-bold' : 'text-black'}`}>
                                    <h3 id="SITE_VISIT_DONE">Site Visit</h3>
                                    <h3>({userAccountSummary?.siteVisits || '0'})</h3>
                                </div>
                                <div
                                    onClick={(e) => changeStatus(e)}
                                    className={`flex items-center gap-1 pr-5 lg:pr-0 xl:pr-10 cursor-pointer ${active == 'BOOKING_DONE' ? 'text-primary font-bold' : 'text-black'}`}>
                                    <h3 id="BOOKING_DONE">Booking</h3>
                                    <h3>({userAccountSummary?.bookingsDone || '0'})</h3>
                                    {active === 'BOOKING_DONE' && <hr className="h-[5px] bg-primary rounded w-full" />}
                                </div>
                                <div
                                    onClick={(e) => changeStatus(e)}
                                    className={`flex items-center gap-1 cursor-pointer ${active == 'REGISTRATION_DONE' ? 'text-primary font-bold' : 'text-black'}`}>
                                    <h3 id="REGISTRATION_DONE">Registration</h3>
                                    <h3>({userAccountSummary?.bookingsDone || '0'})</h3>
                                    {active === 'REGISTRATION_DONE' && <hr className="h-[5px] bg-primary rounded w-full" />}
                                </div>
                            </div>
                            {/* Mobile view */}
                            <div className="flex justify-between gap-x-7 sm:gap-x-10 md:gap-x-20 lg:hidden">
                                <div
                                    onClick={(e) => changeStatus(e)}
                                    className={`flex flex-col items-center gap-[2px] cursor-pointer ${active == 'all' ? 'text-primary font-bold' : 'text-black'}`}>
                                    <h3 id="all">All</h3>
                                    <h1>({userAccountSummary?.leadSubmissions || '0'})</h1>
                                    
                                </div>
                                <div
                                    onClick={(e) => changeStatus(e)}
                                    className={`flex flex-col items-center gap-[2px] cursor-pointer ${active == 'SITE_VISIT_DONE' ? ' text-primary font-bold' : 'text-black'}`}>
                                    <h3 id="SITE_VISIT_DONE" className="whitespace-nowrap">Site Visit</h3>
                                    <h3>({userAccountSummary?.siteVisits || '0'})</h3>
                                   
                                </div>
                                <div
                                    onClick={(e) => changeStatus(e)}
                                    className={`flex flex-col items-center gap-[2px] cursor-pointer ${active == 'BOOKING_DONE' ? 'text-primary font-bold' : 'text-black'}`}>
                                    <h3 id="BOOKING_DONE">Booking</h3>
                                    <h3>({userAccountSummary?.bookingsDone || '0'})</h3>
                                   
                                </div>
                                <div
                                    onClick={(e) => changeStatus(e)}
                                    className={`flex flex-col items-center gap-[2px] cursor-pointer ${active == 'REGISTRATION_DONE' ? 'text-primary font-bold' : 'text-black'}`}>
                                    <h3 id="REGISTRATION_DONE">Registration</h3>
                                    <h3>({userAccountSummary?.bookingsDone || '0'})</h3>
                                   
                                </div>
                            </div>
                        </div>
                        <hr className="bg-[#EBEAF2] h-2 mt-5" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TotalReferral;
