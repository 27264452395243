import React from 'react'
import BundleOffers from '../DealsAndDiscounts/Components/BundleOffers';
import useData from '../DealsAndDiscounts/data';
import { getConfig } from '../../utils/config';
import SkeletonBundleDeals from '../../Components/Molecules/Skeleton/SkeletonBundleDeals';
import { useSelector } from 'react-redux';
import { getFont } from '../../helpers';



const BundleDeals = () => {
    const config = getConfig();
    const {
        bundleOffers,
        isLoadingBundle
    } = useData();

    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );

  return (
    <div className={`${internalWebview ? 'mb-16' : ''}`}>
        <div
                className={`
                    ${config?.bannerNegativeTopMarginClass} w-full h-auto  `}
            >
                {config?.header && (
                    <div className={config?.header.headerClass}></div>
                )}
            </div>
            <div className="px-3 lg:pl-0 w-full lg:w-[95%] xl:w-[90%] lg:mx-auto mt-5">
            {isLoadingBundle && <SkeletonBundleDeals/>}
         {bundleOffers?.length > 0 && !isLoadingBundle && (
                    <BundleOffers bundleOffers={bundleOffers} />
                )} 

<p
                    className={`flex mx-auto text-center items-center w-[75%] text-primary z-10 pb-6 leading-5 justify-center text-sm mt-12 ${
                        getFont((config?.name).toUpperCase()).body
                    } `}
                >
                    {`*By clicking on Redeem Now, you authorize RELOY and partner brands to contact you via WhatsApp, SMS, phone, email etc. All brands have their respective terms and conditions and neither Reloy nor ${config?.fullName} will be responsible in case of any concerns. `}
                </p>
        </div>
    </div>
  )
}

export default BundleDeals
