import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import 'react-awesome-slider/dist/styles.css';
import ComponentsRenderer from '../../ComponentsRenderer';

function SliderBanner({ slides, linearGradient, components, classWeb }) {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    // console.log(linearGradient, slides);
    return (
        <>
            {/* DESKTOP */}
            <AutoplaySlider
                bullets={false}
                play={true}
                cancelOnInteraction={false}
                interval={3000}
                organicArrows={false}
                className={`${
                    classWeb ? classWeb : ' hidden lg:block h-[85vh] '
                }`}
            >
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className=" flex flex-col justify-center w-full min-w-full h-full lg:h-[90vh] relative  " //
                        // data-src={slide.displayImage?.url}
                        style={{
                            backgroundImage: `linear-gradient(${
                                '90deg' || slide?.linearGradient?.deg
                            }, rgba(${
                                slide?.linearGradient?.R ||
                                slide?.linearGradient?.R1
                            }, ${
                                slide?.linearGradient?.G ||
                                slide?.linearGradient?.G1
                            }, ${
                                slide?.linearGradient?.B ||
                                slide?.linearGradient?.B1
                            }, ${
                                slide?.linearGradient?.opacity ||
                                slide?.linearGradient?.opacity1
                            }),rgba(${
                                slide?.linearGradient?.R ||
                                slide?.linearGradient?.R2
                            }, ${
                                slide?.linearGradient?.G ||
                                slide?.linearGradient?.G2
                            }, ${
                                slide?.linearGradient?.B ||
                                slide?.linearGradient?.B2
                            }, ${
                                slide?.linearGradient?.opacity ||
                                slide?.linearGradient?.opacity2
                            })), url('${slide.displayImage?.url}')`,
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'cover'
                        }}
                    >
                        {/* <div
                            className=" absolute inset-0  " //bg-[#64412B] opacity-70
                            style={{
                                backgroundImage: `linear-gradient(90deg, rgba(${slide?.linearGradient?.R}, ${slide?.linearGradient?.G}, ${slide?.linearGradient?.B}, ${slide?.linearGradient?.opacity}),rgba(${slide?.linearGradient?.R}, ${slide?.linearGradient?.G}, ${slide?.linearGradient?.B}, ${slide?.linearGradient?.opacity})))`
                            }}
                        ></div> */}
                        <div className=" z-10">
                            <ComponentsRenderer
                                components={slide?.components}
                            />

                            <p
                                key={index}
                                className={
                                    `${
                                        index % 2 === 0
                                            ? 'animate-slideIn'
                                            : 'animate-slideOut'
                                    }` +
                                    '  font-CorporateS_Regular text-[#fffadf] text-[22px] mt-6 '
                                }
                            >
                                {slide?.text ? slide?.text : ''}
                            </p>
                        </div>
                    </div>
                ))}
            </AutoplaySlider>
            {/* MOBILE */}
            <AutoplaySlider
                bullets={false}
                play={true}
                cancelOnInteraction={false}
                interval={4000}
                organicArrows={false}
                className=" lg:hidden block relative h-full w-full"
            >
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className=" flex flex-col justify-center w-full min-w-full h-full "
                        style={{
                            backgroundImage: `linear-gradient(${
                                '90deg' || slide?.linearGradient?.deg
                            }, rgba(${
                                slide?.linearGradient?.R ||
                                slide?.linearGradient?.R1
                            }, ${
                                slide?.linearGradient?.G ||
                                slide?.linearGradient?.G1
                            }, ${
                                slide?.linearGradient?.B ||
                                slide?.linearGradient?.B1
                            }, ${
                                slide?.linearGradient?.opacity ||
                                slide?.linearGradient?.opacity1
                            }),rgba(${
                                slide?.linearGradient?.R ||
                                slide?.linearGradient?.R2
                            }, ${
                                slide?.linearGradient?.G ||
                                slide?.linearGradient?.G2
                            }, ${
                                slide?.linearGradient?.B ||
                                slide?.linearGradient?.B2
                            }, ${
                                slide?.linearGradient?.opacity ||
                                slide?.linearGradient?.opacity2
                            })), url('${slide.displayImage?.mobileUrl}')`,

                            width: '100%',
                            height: '100%',
                            backgroundSize: 'cover'
                        }}
                        // data-src={slide.displayImage?.mobileUrl}
                    >
                        {/* <div
                            className=" absolute inset-0 bg-[#64412B] opacity-70 "
                            style={{
                                backgroundImage: `linear-gradient(90deg, rgba(${linearGradient?.R}, ${linearGradient.G}, ${linearGradient.B}, ${linearGradient.opacity}),rgba(${linearGradient?.R}, ${linearGradient.G}, ${linearGradient.B}, ${linearGradient.opacity})))`
                            }}
                        ></div> */}
                        <div className="z-10 ">
                            <ComponentsRenderer components={slide.components} />

                            <p
                                key={index}
                                className={
                                    `${
                                        index % 2 === 0
                                            ? 'animate-slideIn'
                                            : 'animate-slideOut'
                                    }` +
                                    '  font-CorporateS_Regular text-[#fffadf] text-[17px] md:text-[25px]  leading-5 mt-6 px-2' ///
                                }
                            >
                                {slide?.text ? slide?.text : ''}
                            </p>
                        </div>
                    </div>
                ))}
            </AutoplaySlider>
        </>
    );
}

export default SliderBanner;
