export const giftSvg = <svg className="h-10 w-10 lg:w-14 lg:h-14" xmlns="http://www.w3.org/2000/svg" width="0" height="0" viewBox="0 0 60.831 60.897">
<g id="giftbox" transform="translate(-1 -0.95)">
    <rect id="Rectangle_17113" data-name="Rectangle 17113" width="60.831" height="10.579" transform="translate(1 18.208)" fill="#ffad35" />
    <path id="Path_6924" data-name="Path 6924" d="M61.186,14v7.935H8.29A5.29,5.29,0,0,1,3,16.645V14Z" transform="translate(0.645 4.208)" fill="#ffd34a" />
    <rect id="Rectangle_17114" data-name="Rectangle 17114" width="55.542" height="33.06" transform="translate(3.645 28.787)" fill="#ffad36" />
    <path id="Path_6925" data-name="Path 6925" d="M57.9,22V52.416H12.935A7.935,7.935,0,0,1,5,44.481V22Z" transform="translate(1.29 6.787)" fill="#ffd34a" />
    <rect id="Rectangle_17115" data-name="Rectangle 17115" width="7.935" height="33.06" transform="translate(27.448 28.787)" fill="#d82b44" />
    <path id="Path_6926" data-name="Path 6926" d="M28.29,22c0,31.183-.132,30.416,0,30.416A5.29,5.29,0,0,1,23,47.126V22Z" transform="translate(7.093 6.787)" fill="#fd4457" />
    <path id="Path_6927" data-name="Path 6927" d="M23.7,19.515H22.127c-8.635.5-13.4-4.5-13.052-8.3C10.674-7.806,27.086,3.9,28.686,12.9A5.29,5.29,0,0,0,23.7,19.515Z" transform="translate(2.598 0.015)" fill="#d82b44" />
    <path id="Path_6928" data-name="Path 6928" d="M28.042,12.918a5.29,5.29,0,0,0-5.157,5.29c-5.118,0-8.662-1.957-10.764-4.721a5.171,5.171,0,0,1-1.045-3.623c.2-2.38.886-6.612,3.161-8.834C19.592.13,27.063,7.046,28.042,12.918Z" transform="translate(3.241 0)" fill="#fd4457" />
    <path id="Path_6929" data-name="Path 6929" d="M24.935,17.39a40.691,40.691,0,0,0-6.678,20.233L14.29,31.011,9,34.978c.6-6.281,2.962-15.5,7.247-20.418,4.205,1.23,7.26.185,7.5.661A5.131,5.131,0,0,0,24.935,17.39Z" transform="translate(2.579 4.388)" fill="#d82b44" />
    <path id="Path_6930" data-name="Path 6930" d="M23.581,15.221c-2.843,4.721-5.488,12.589-5.488,18.435l-3.967-6.612L9.51,30.508c.992-5.29,3.042-11.9,6.612-15.948C20.116,15.737,23.382,14.811,23.581,15.221Z" transform="translate(2.744 4.388)" fill="#fd4457" />
    <path id="Path_6931" data-name="Path 6931" d="M43.711,11.212c.344,3.8-4.39,8.807-13.052,8.3H29.086A5.29,5.29,0,0,0,24.1,12.905C25.7,3.913,42.111-7.83,43.711,11.212Z" transform="translate(7.448 0.013)" fill="#d82b44" />
    <path id="Path_6932" data-name="Path 6932" d="M42.869,34.978l-5.29-3.967-3.967,6.612A40.691,40.691,0,0,0,27,17.39a5.29,5.29,0,0,0,1.217-2.248,23.05,23.05,0,0,0,7.472-.582C39.854,19.427,42.287,28.657,42.869,34.978Z" transform="translate(8.383 4.388)" fill="#d82b44" />
    <path id="Path_6933" data-name="Path 6933" d="M29.24,18.861a5.29,5.29,0,1,1,.9-5.8A5.29,5.29,0,0,1,29.24,18.861Z" transform="translate(6.142 2.917)" fill="#d82b44" />
    <path id="Path_6934" data-name="Path 6934" d="M29.457,17.472a5.29,5.29,0,0,1-6.982-7.009,5.29,5.29,0,0,1,6.982,7.009Z" transform="translate(6.772 2.918)" fill="#fd4457" />
    <path id="Path_6935" data-name="Path 6935" d="M24.322,15.645a1.322,1.322,0,1,1,1.322-1.322A1.322,1.322,0,0,1,24.322,15.645Z" transform="translate(7.093 3.885)" fill="#c4455e" />
    <path id="Path_6936" data-name="Path 6936" d="M25.383,12.243c-1.891.992-1.746,2.142-2.129,1.56-2.076-2.777-6.784-8.173-8.873-5.356a1.322,1.322,0,1,1-2.116-1.587c2.314-3.108,5.726-1.984,8.675.489a30.878,30.878,0,0,1,4.443,4.893Z" transform="translate(3.547 1.296)" fill="#c4304d" />
    <path id="Path_6937" data-name="Path 6937" d="M24.1,12.918a5.29,5.29,0,0,1,5.157,5.29c5.118,0,8.662-1.957,10.764-4.721a5.171,5.171,0,0,0,1.045-3.623c-.2-2.38-.886-6.612-3.161-8.834C32.55.13,25.079,7.046,24.1,12.918Z" transform="translate(7.448 0)" fill="#fd4457" />
    <path id="Path_6938" data-name="Path 6938" d="M27.85,15.221c2.843,4.721,5.488,12.589,5.488,18.435l3.967-6.612,4.615,3.465c-.992-5.29-3.042-11.9-6.612-15.948C31.315,15.737,28.048,14.811,27.85,15.221Z" transform="translate(8.657 4.388)" fill="#fd4457" />
    <path id="Path_6939" data-name="Path 6939" d="M38.734,8.712a1.322,1.322,0,0,1-1.851-.264c-2.645-3.491-8.7,5.29-9.072,5.554a5.29,5.29,0,0,0-1.931-1.759c0-.132,5.2-7.273,9.574-7.273C37.795,5.01,40.427,7.443,38.734,8.712Z" transform="translate(8.022 1.296)" fill="#c4304d" />
</g>
</svg>
