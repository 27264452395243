import React from 'react'

function Refer(props) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.717"
    height="20.223"
    viewBox="0 0 15.717 20.223"
    className="w-5 h-5"
>
    <g
        id="Layer_2"
        data-name="Layer 2"
        transform="translate(-6.11 -3.25)"
    >
        <path
            id="Path_1833"
            data-name="Path 1833"
            d="M15.8,9.769a13.12,13.12,0,0,1,1.387-.579h0a2.822,2.822,0,0,0,2.385,1.328,2.941,2.941,0,0,0,2.862-3,2.858,2.858,0,1,0-5.708,0,2.989,2.989,0,0,0,.167.97C16.91,8.492,15.815,9.764,15.8,9.769Z"
            transform="translate(-1.655 -0.205)"
            fill="none"
            stroke={props.stroke}
            stroke-width="0.5"
        />
        <path
            id="Path_1834"
            data-name="Path 1834"
            d="M18.962,3.5a3.736,3.736,0,0,0-3.649,3.808,3.768,3.768,0,0,0,.278,1.439L11.569,11.76a.4.4,0,0,0,.238.715.453.453,0,0,0,.238-.079L13.5,11.31l.535-.4,1.933-1.446a3.593,3.593,0,0,0,3,1.646,3.736,3.736,0,0,0,3.649-3.8A3.736,3.736,0,0,0,18.962,3.5Zm0,6.813a2.822,2.822,0,0,1-2.385-1.328,3,3,0,0,1-.318-.708,2.989,2.989,0,0,1-.167-.97,2.866,2.866,0,1,1,5.724,0,2.941,2.941,0,0,1-2.854,3Z"
            transform="translate(-1.035)"
            fill={props.fill}
            stroke={props.stroke}
            stroke-width="0.5"
        />
        <path
            id="Path_1835"
            data-name="Path 1835"
            d="M14.675,19.059a2.878,2.878,0,0,0-1.439.405.246.246,0,0,0-.079-.119l-1.876-1.972a2.862,2.862,0,0,0,.541-1.661A2.735,2.735,0,0,0,9.142,12.93a2.782,2.782,0,0,0,0,5.565,2.576,2.576,0,0,0,1.59-.549l1.86,1.948h.04a3.18,3.18,0,0,0-.946,2.266,3,3,0,0,0,5.994,0A3.069,3.069,0,0,0,14.675,19.059ZM7.266,15.712a1.94,1.94,0,0,1,1.876-1.987,1.987,1.987,0,1,1,0,3.975A1.94,1.94,0,0,1,7.266,15.712Zm7.409,8.8A2.329,2.329,0,1,1,17,22.183,2.329,2.329,0,0,1,14.675,24.513Z"
            transform="translate(0 -1.933)"
            fill={props.fill}
            stroke={props.stroke}
            stroke-width="0.5"
        />
    </g>
</svg>
  )
}

export default Refer
