import React from 'react';

function List(props) {
    const { points, classNameContainer, listClassName, listStyle } = props;
    return (
        <div className={classNameContainer || 'px-10'}>
            <ul className={listStyle}>
                {points?.map((point) => (
                    <li
                        className={
                            listClassName ||
                            ' font-brandFont text-accent-content'
                        }
                    >
                        {point}
                        {/* <br></br> */}
                        {/* <br></br> */}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default List;
