import React from 'react';
import { useLocation } from 'react-router-dom';
import { getConfig } from '../../../utils/config';

const getPdfUrl = (pdfName) => {
    switch (pdfName) {
        case 'SOBO':
            return 'https://drive.google.com/file/d/1GNpUYtubfyE4ATK0I15Q_8QY3rIK6diO/preview';
        case 'SUBURB':
            return 'https://drive.google.com/file/d/1rLBJpWsgE8GTw8OFE_m4uW36VYBmgGrL/preview';
        default:
    }
};

function Booklet() {
    const config = getConfig();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pdfName = queryParams.get('pdf');

    return (
        <div className="w-full h-full">
            <div
                className={`
                    ${config?.bannerNegativeTopMarginClass} w-full h-auto  `}
            >
                {config?.header && (
                    <div className={config?.header.headerClass}></div>
                )}
            </div>

            <iframe
                src={getPdfUrl(pdfName)}
                height="750px"
                allow="autoplay"
                width="100%"
            />
        </div>
    );
}

export default Booklet;
