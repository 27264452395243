import react, { useEffect, useState } from 'react';
import { winnreNoAuth } from '../../../../utils/axios';

const useData = () => {
    const [apartmentTypesList, setApartmentTypesList] = useState([]);
    const [projectCityList, setProjectCityList] = useState([]);

    useEffect(() => {
        // console.log('GET FILTER API DATA');
        winnreNoAuth
            .get(`/winnre/user/project/apartment-types`)
            .then((data) => {
                setApartmentTypesList(data);
            });
        winnreNoAuth
            .get(`/winnre/no-auth/enum/enquiry-project-cities`)
            .then((data) => {
                setProjectCityList(data);
            });
    }, []);

    return {
        apartmentTypesList,
        projectCityList
    };
};

export default useData;
