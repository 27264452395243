import React from 'react';
import SkeletonElement from '../../../SkeletonElement';

function SkeletonGeneralOffer({ shape }) {
    const addCssByShape = () => {
        switch (shape) {
            case 'square':
                return 'w-[250px] h-[250px]';
            case 'rectangle':
                return 'w-[500px]';
        }
    };

    return (
        <div
            className={` shadow-xl p-2 min-w-[300px] rounded-xl`}
        >
            <SkeletonElement
                type="thumbnail"
                size="h-[300px] w-full"
            />
            <SkeletonElement type="title" />
            <SkeletonElement type="text" />
        </div>
    );
}

export default SkeletonGeneralOffer;
