import { useSelect } from '@mui/base';
import react, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAxios from '../../Hooks/use-axios';
import useSearchInput from '../../Hooks/useSearchInput';
import { authActions } from '../../store/auth-slice';
import { getConfig } from '../../utils/config';

const useBlogsData = () => {
    const [blogs, setBlogs] = useState([]);
    const [recentBlogs, setRecentBlogs] = useState([]);
    const [featuredBlogs, setFeaturedBlogs] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [tags, setTags] = useState([]);
    const [catalyst, setCatalyst] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    // const [searchInput, handleChange] = useSearchInput('');

    // const authToken = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const config = getConfig();
    // const headers2 = {
    //     'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    //     Authorization: `Bearer ${authToken}`
    // };
    const onFilterClick = (tag) => {
        if (tags.includes(tag)) {
            setTags(tags.filter((t) => t !== tag));
        } else {
            setTags(tags.concat(tag));
        }
        if (currentPage === 1) {
            setCatalyst((prevState) => prevState + 1);
        } else {
            setCurrentPage(1);
        }
    };

    const onSelectFilterClick = (filterArray) => {
        setTags(filterArray.map((obj) => obj.value));
    };

    const {
        isLoading: loadingBlogs,
        error,
        sendRequest: fetchBlogs
    } = useAxios();
    // console.log(loadingBlogs);
    const { sendRequest: fetchSearchedBlogs } = useAxios();

    const getBlogs = () => {
        // console.log('GET ALL BLOGS');

        let url;
        if (isAuthenticated) {
            url = `/winnre/user/blogs/filter-v2?pageNumber=${
                currentPage - 1
            }&status=PUBLISHED&isAscending=false&sortBy=publishDate&pageSize=6${
                tags.length > 0 ? `&tagList=${tags.join(',')}` : ''
            }`;
        } else {
            url = `/cms_service/blogs/filter-v2?clientId=${
                config?.id
            }&status=PUBLISHED&isAscending=false&sortBy=publishDate&isVisibleToAll=true&pageNumber=${
                currentPage - 1
            }&pageSize=6${tags.length > 0 ? `&tagList=${tags.join(',')}` : ''}`;
        }

        fetchBlogs(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url,
                method: 'get',
                // headers: isAuthenticated ? headers2 : '',
                isFormData: true
            },
            (data) => {
                // const data = { status: 'FAILED' };

                if (isAuthenticated) {
                    if (
                        data?.status === 'UNAUTHORIZED' ||
                        data?.status === 'FAILED'
                    ) {
                        // console.log('unauth');
                        setBlogs([]);
                        setTotalPages(0);
                        return;
                    }
                    if (
                        data?.status === 'TOKEN_EXPIRED' ||
                        data?.status === 'INVALID_TOKEN'
                    ) {
                        // console.log('token expired');
                        // dispatch(authActions.logout());
                        setBlogs([]);
                        setTotalPages(0);
                        return;
                    }
                    setTotalPages(data.totalPages);
                    setBlogs(data.content || []);
                    if (currentPage === 1 && tags.length === 0) {
                        setRecentBlogs(data?.content.slice(0, 5));
                        setFeaturedBlogs(data?.content.slice(0, 5));
                    }
                } else {
                    setTotalPages(data?.totalPages);
                    setBlogs(data?.content || []);
                    if (currentPage === 1 && tags.length === 0) {
                        setRecentBlogs(data?.content.slice(0, 5));
                        setFeaturedBlogs(data?.content.slice(0, 5));
                    }
                }
            }
        );
    };

    const searchBasedBlogs = (searchInput) => {
        let method = 'get';

        fetchSearchedBlogs(
            {
                isEncryptedResponse: false,
                url: `/cms_service/blogs/filter-v2?clientId=${
                    config?.id
                }&status=PUBLISHED&isAscending=false&sortBy=publishDate&isVisibleToAll=true&title=${searchInput}&pageNumber=${
                    currentPage - 1
                }&pageSize=6`,
                method,
                isFormData: true
            },
            (data) => {
                setTotalPages(data.totalPages);
                setBlogs(data.content);
            }
        );
    };

    useEffect(() => {
        if (searchInput === '') {
            getBlogs();
            return;
        }

        const timer = setTimeout(() => {
            searchBasedBlogs(searchInput);
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [currentPage, catalyst, searchInput, tags, isAuthenticated]);

    useEffect(() => {
        setCurrentPage(1);
    }, [isAuthenticated]);

    return {
        blogs,
        recentBlogs,
        featuredBlogs,
        loadingBlogs,
        error,
        onFilterClick,
        tags,
        setCatalyst,
        currentPage,
        setCurrentPage,
        totalPages,
        searchInput,
        // handleChange,
        setSearchInput,
        onSelectFilterClick
    };
};

export default useBlogsData;
