import React, { useState } from 'react';
import Modal from '../Modal';

function UserTypeForm({ userRole, setUserRole, changeCurrentStep, config }) {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const len = config?.userTypes.length;

    const closeEmployeeDisclaimer = () => {
        setShowModal(false);
        // bearerToken
        //     ?
        userRole === 'EMPLOYEE'
            ? userRole === 'RELATIONSHIP_MANAGER'
                ? changeCurrentStep('createOnBehalfForm')
                : changeCurrentStep('userProjectForm')
            : changeCurrentStep('userAuthenticationForm');
    };

    const userTypeClickedHandler = (e) => {
        setUserRole(e.target.id);
        // if (bearerToken) {
        e.target.id === 'EMPLOYEE'
            ? changeCurrentStep('userProjectForm')
            : changeCurrentStep('createOnBehalfForm');
        return;
        // }
        // if (e.target.id === 'EMPLOYEE' && config?.employeeDisclaimer) {
        //     setModalMessage(
        //         config?.employeeDisclaimerContent ||
        //             `Please note: *${config?.name} Employees can only refer on behalf of existing customers. Employees are not eligible for rewards through this programme.`
        //     );
        //     setShowModal(true);
        //     return;
        // }
        // if (
        //     e.target.id === 'RELATIONSHIP_MANAGER' &&
        //     config?.salesAdminDisclaimer
        // ) {
        //     setModalMessage(
        //         config?.salesAdminDisclaimerContent ||
        //             `Please note: *${config?.name} Employees can only refer on behalf of existing customers. Employees are not eligible for rewards through this programme.`
        //     );
        //     setShowModal(true);
        //     return;
        // }

        changeCurrentStep('userAuthenticationForm');
    };

    const getPosition = () => {
        switch (len) {
            case 1:
                return 'justify-center ';
            case 2:
                return 'justify-center';
            case 3:
                return 'justify-center';
            case 4:
                return 'justify-start';
            case 5:
                return 'justify-center xl:justify-start';
        }
    };

    return (
        <div className={`flex flex-col h-full w-full z-10 lg:pl-12 mt-10 md:mt-0`}>
            <div
                className={` flex flex-col w-[80%] mb-6 lg:mb-10`}
            >
                <h1
                    className={`${
                        config?.customConfig?.headingColor || 'text-gray-200'
                    } font-semibold text-xl `}
                >
                    Hello there,
                </h1>
                <h1
                    className={` ${
                        config?.customConfig?.headingColor || 'text-white'
                    } ${
                        config.formContent?.font
                    } font-semibold  mb-4 text-3xl md:text-4xl lg:text-3xl xl:text-4xl `}
                >
                    {config?.customConfig?.welcomeContent ||
                        'How do you know us?'}
                </h1>
                {/* <hr className="w-1/3 2xl:w-1/5 bg-primary border-none h-1"></hr> */}
            </div>

            {/* Div containing all user types */}
            <div
                className={`w-[88%] flex flex-wrap  mb-24 md:mb-32 lg:mb-0 `}
            >
                {/* Booked Customer New Design */}
                {/* {!bearerToken && config?.userTypes.bookedCustomer && (
                    <div
                        onClick={userTypeClickedHandler}
                        id="CUSTOMER"
                        className={`${
                            config?.customConfig?.userBgColor ||
                            'bg-transparent'
                        } ${
                            config?.customConfig?.animateUserType
                                ? 'animate-pulse'
                                : ''
                        }  w-full md:w-[45%] lg:w-[45%] xl:w-[30%] md:mr-4 cursor-pointer px-2 py-6  flex flex-col justify-center mb-6  hover:bg-primary  hover:bg-opacity-70 text-white border border-gray-300 hover:border-transparent rounded-lg`}
                    >
                        <div className="flex w-full justify-center cursor-pointer">
                            <svg
                                id="CUSTOMER"
                                xmlns="http://www.w3.org/2000/svg"
                                width="35.101"
                                height="33.746"
                                viewBox="0 0 35.101 33.746"
                                className=" self-center w-full cursor-pointer"
                            >
                                <g
                                    id="CUSTOMER"
                                    data-name="1530085_building_business_city_commercial_company_icon (1)"
                                    transform="translate(-19.544 -18.757)"
                                >
                                    <rect
                                        id="CUSTOMER"
                                        data-name="Rectangle 18"
                                        width="2.142"
                                        height="3.944"
                                        transform="translate(26.774 34.248)"
                                        fill="#ebeaf2"
                                    />
                                    <rect
                                        id="CUSTOMER"
                                        data-name="Rectangle 19"
                                        width="2.142"
                                        height="3.944"
                                        transform="translate(26.774 42.136)"
                                        fill="#ebeaf2"
                                    />
                                    <rect
                                        id="CUSTOMER"
                                        data-name="Rectangle 20"
                                        width="2.142"
                                        height="3.944"
                                        transform="translate(26.774 26.361)"
                                        fill="#ebeaf2"
                                    />
                                    <rect
                                        id="CUSTOMER"
                                        data-name="Rectangle 21"
                                        width="2.142"
                                        height="3.944"
                                        transform="translate(33.347 42.136)"
                                        fill="#ebeaf2"
                                    />
                                    <rect
                                        id="CUSTOMER"
                                        data-name="Rectangle 22"
                                        width="2.142"
                                        height="3.944"
                                        transform="translate(33.347 34.248)"
                                        fill="#ebeaf2"
                                    />
                                    <rect
                                        id="CUSTOMER"
                                        data-name="Rectangle 23"
                                        width="2.142"
                                        height="3.944"
                                        transform="translate(33.347 26.361)"
                                        fill="#ebeaf2"
                                    />
                                    <rect
                                        id="CUSTOMER"
                                        data-name="Rectangle 24"
                                        width="4.191"
                                        height="2.142"
                                        transform="translate(45.595 38.796)"
                                        fill="#ebeaf2"
                                    />
                                    <rect
                                        id="CUSTOMER"
                                        data-name="Rectangle 25"
                                        width="4.191"
                                        height="2.142"
                                        transform="translate(45.595 44.053)"
                                        fill="#ebeaf2"
                                    />
                                    <path
                                        id="CUSTOMER"
                                        data-name="Path 8"
                                        d="M42.722,52.5H50.4a4.247,4.247,0,0,0,4.242-4.242V32.588l-11.923,0V18.757H19.544V52.5H42.722ZM52.5,34.73V48.26a2.1,2.1,0,0,1-2.1,2.1H42.722V34.728ZM21.686,50.36V20.9H40.579v29.46H21.686Z"
                                        fill="#ebeaf2"
                                    />
                                </g>
                            </svg>
                        </div>
                        <button id="CUSTOMER" value="CUSTOMER" className="mt-4">
                            <span className="block" id="CUSTOMER">
                                {config?.userTypes.bookedCustomerHeading1 ||
                                    `I own a `}
                            </span>
                            <span className="block" id="CUSTOMER">
                                {config?.userTypes.bookedCustomerHeading2 ||
                                    `${config?.name} Property`}
                            </span>
                        </button>
                    </div>
                )} */}
                {/* Site Visitor New Design */}
                {/* {!bearerToken && config?.userTypes.siteVisitor && (
                    <div
                        onClick={userTypeClickedHandler}
                        id="SITE_VISITOR"
                        className={` ${
                            config?.customConfig?.userBgColor ||
                            'bg-transparent'
                        } ${
                            config?.customConfig?.animateUserType
                                ? 'animate-pulse'
                                : ''
                        }  w-full md:w-[45%] lg:w-[45%] xl:w-[30%] md:mr-4 cursor-pointer px-2 py-6  flex flex-col justify-center mb-6 hover:bg-primary  hover:bg-opacity-70 text-white border border-gray-300 hover:border-transparent rounded-lg`}
                    >
                        <div className="flex w-full justify-center cursor-pointer">
                            <svg
                                id="SITE_VISITOR"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30.584"
                                height="30.584"
                                viewBox="0 0 30.584 30.584"
                                className=" self-center  w-full cursor-pointer"
                            >
                                <path
                                    id="SITE_VISITOR"
                                    data-name="Path 3"
                                    d="M9.943,0A.765.765,0,0,0,9.6.081L.424,4.668A.765.765,0,0,0,0,5.352V29.82a.765.765,0,0,0,1.105.684l8.859-4.429,10.379,4.447a.765.765,0,0,0,.642-.018l9.175-4.588a.765.765,0,0,0,.424-.684V.765A.765.765,0,0,0,29.479.081L20.621,4.51,10.242.063A.765.765,0,0,0,9.943,0Zm.021,1.607L20.343,6.054a.765.765,0,0,0,.642-.018L29.055,2V24.76l-8.435,4.217L10.242,24.53a.765.765,0,0,0-.642.018l-8.07,4.035V5.824Zm-.036,1.44a.765.765,0,0,0-.753.777V22.174a.765.765,0,1,0,1.529,0V3.823a.765.765,0,0,0-.777-.777Zm10.7,4.588a.765.765,0,0,0-.753.777V26.761a.765.765,0,1,0,1.529,0V8.411a.765.765,0,0,0-.777-.777Z"
                                    transform="translate(0 0)"
                                    fill="#ebeaf2"
                                />
                            </svg>
                        </div>
                        <button
                            id="SITE_VISITOR"
                            value="SITE_VISITOR"
                            className="mt-4"
                        >
                            <span className="block" id="SITE_VISITOR">
                                {config?.userTypes.siteVisitorHeading1 ||
                                    `I have visited a `}
                            </span>
                            <span className="block" id="SITE_VISITOR">
                                {config?.userTypes.siteVisitorHeading2 ||
                                    `${config.name} Property`}
                            </span>
                        </button>
                    </div>
                )} */}
                {/* Employee New Design */}
                {config?.userTypes.employee && (
                    <div
                        onClick={userTypeClickedHandler}
                        id="EMPLOYEE"
                        className={`${
                            config?.customConfig?.userBgColor ||
                            'bg-transparent'
                        } ${
                            config?.customConfig?.animateUserType
                                ? 'animate-pulse'
                                : ''
                        }  w-full md:w-[45%] lg:w-[45%] xl:w-[30%] md:mr-4 cursor-pointer px-0 py-10 flex flex-col justify-center mb-6 hover:bg-primary  hover:bg-opacity-70 text-white border border-gray-300 hover:border-transparent rounded-lg`}
                    >
                        <div className="flex w-full justify-center cursor-pointer">
                            <svg
                                id="EMPLOYEE"
                                xmlns="http://www.w3.org/2000/svg"
                                width="26.645"
                                height="36.796"
                                viewBox="0 0 26.645 36.796"
                                className=" self-center w-full cursor-pointer"
                            >
                                <g
                                    id="EMPLOYEE"
                                    data-name="1471105_business man_employee_general_human_member_icon"
                                    transform="translate(-25.352 -18.581)"
                                >
                                    <path
                                        id="EMPLOYEE"
                                        data-name="Path 4"
                                        d="M51.847,50.308V42.544c0-2.627-1.591-5.06-4.234-6.7a10.9,10.9,0,1,0-17.984.066A8.056,8.056,0,0,0,25.5,42.544v7.765h2.212V42.544c0-1.9,1.264-3.7,3.36-4.936a10.857,10.857,0,0,0,5.208,2.8L34.015,51.457l4.66,3.728,4.66-3.728L41.028,40.373a10.862,10.862,0,0,0,5.151-2.818c2.155,1.236,3.456,3.059,3.456,4.99v7.765h2.212ZM38.675,52.353l-2.221-1.776,1.864-9.09H39l1.892,9.091ZM38.6,38.447a8.752,8.752,0,1,1,8.689-8.752A8.729,8.729,0,0,1,38.6,38.447Z"
                                        fill="#ebeaf2"
                                        stroke="#130e2f"
                                        strokeWidth="0.3"
                                    />
                                </g>
                            </svg>
                        </div>
                        <button id="EMPLOYEE" value="EMPLOYEE" className="mt-4">
                            <span className="block" id="EMPLOYEE">
                                {config?.userTypes.employeeHeading1 ||
                                    `I am a `}
                            </span>
                            <span className="block" id="EMPLOYEE">
                                {config?.userTypes.employeeHeading2 ||
                                    `${config.name} Employee`}
                            </span>
                        </button>
                    </div>
                )}
                {/* Influencer New Design */}
                {/* {!bearerToken && config?.userTypes.influencer && (
                    <div
                        onClick={userTypeClickedHandler}
                        id="INFLUENCER"
                        className={` ${
                            config?.customConfig?.userBgColor ||
                            'bg-transparent'
                        } ${
                            config?.customConfig?.animateUserType
                                ? 'animate-pulse'
                                : ''
                        }  w-full  md:w-[45%] lg:w-[45%] xl:w-[30%] md:mr-4 cursor-pointer px-2 py-6 flex flex-col justify-center mb-6 hover:bg-primary  hover:bg-opacity-70 text-white border border-gray-300 hover:border-transparent rounded-lg`}
                    >
                        <div className="flex w-full justify-center cursor-pointer">
                            <svg
                                id="INFLUENCER"
                                xmlns="http://www.w3.org/2000/svg"
                                width="36.242"
                                height="37.837"
                                viewBox="0 0 36.242 37.837"
                                className=" self-center w-full  cursor-pointer"
                            >
                                <g
                                    id="INFLUENCER"
                                    data-name="4229842_award_badge_employee_male_man_icon"
                                    transform="translate(-35.549 -7.768)"
                                >
                                    <path
                                        id="INFLUENCER"
                                        data-name="Path 5"
                                        d="M107.129,54.128a3.8,3.8,0,0,0,7.456,0l.451-2.355a3.956,3.956,0,0,0-3.9-4.474h-.53a3.939,3.939,0,0,0-3.885,4.533Zm1.805-4.317a2.214,2.214,0,0,1,1.668-.746h.53a2.277,2.277,0,0,1,1.668.746,2.209,2.209,0,0,1,.53,1.707l-.451,2.3a2.058,2.058,0,0,1-4.042,0l-.432-2.256A2.252,2.252,0,0,1,108.934,49.812Z"
                                        transform="translate(-57.168 -31.775)"
                                        fill="#ebeaf2"
                                    />
                                    <path
                                        id="INFLUENCER"
                                        data-name="Path 6"
                                        d="M101.009,112.642a.876.876,0,0,0,.667-1.04l-.961-4.552a1.716,1.716,0,0,0-1.118-1.275l-3.12-1.1a.853.853,0,0,0-.883.177L93.2,107.089l-2.394-2.237a.862.862,0,0,0-.883-.177l-3.12,1.1a1.716,1.716,0,0,0-1.118,1.275l-.961,4.552a.876.876,0,0,0,.667,1.04.543.543,0,0,0,.177.02.859.859,0,0,0,.844-.687l.961-4.552,2.61-.922,2.59,2.433a.878.878,0,0,0,1.2,0l2.59-2.433,2.61.922.961,4.552A.937.937,0,0,0,101.009,112.642Z"
                                        transform="translate(-39.51 -77.849)"
                                        fill="#ebeaf2"
                                    />
                                    <path
                                        id="INFLUENCER"
                                        data-name="Path 7"
                                        d="M63.107,42.249a1.764,1.764,0,0,0,.706.118,1.844,1.844,0,0,0,1.236-.549,1.775,1.775,0,0,0,.53-1.334,6.942,6.942,0,0,1,4.886-6.75,1.576,1.576,0,0,0,.569-.294,1.778,1.778,0,0,0,.706-1.2,1.8,1.8,0,0,0-.353-1.393,6.946,6.946,0,0,1-.02-8.339l.235-.275.1-.294a1.8,1.8,0,0,0-.118-1.393,1.824,1.824,0,0,0-1.1-.922A7,7,0,0,1,65.6,12.9a2.241,2.241,0,0,0-.1-.648,1.877,1.877,0,0,0-.961-1.06,1.813,1.813,0,0,0-1.393-.078,6.8,6.8,0,0,1-2.256.373,7.03,7.03,0,0,1-5.71-2.943,2.239,2.239,0,0,0-.432-.432,1.876,1.876,0,0,0-2.59.432,7,7,0,0,1-5.71,2.943,6.609,6.609,0,0,1-2.237-.373,1.89,1.89,0,0,0-1.923.451,1.761,1.761,0,0,0-.53,1.315,6.918,6.918,0,0,1-4.886,6.73,1.842,1.842,0,0,0-.589.294,1.865,1.865,0,0,0-.373,2.59,6.946,6.946,0,0,1,0,8.359,1.948,1.948,0,0,0-.275.53,1.839,1.839,0,0,0,.137,1.432,1.81,1.81,0,0,0,1.079.9,6.981,6.981,0,0,1,4.886,6.73,1.536,1.536,0,0,0,.1.667,1.864,1.864,0,0,0,2.355,1.138,7,7,0,0,1,2.276-.373,6.88,6.88,0,0,1,5.671,2.943,1.8,1.8,0,0,0,.432.432,1.778,1.778,0,0,0,1.079.353,1.687,1.687,0,0,0,.314-.02,1.813,1.813,0,0,0,1.2-.746A7.009,7.009,0,0,1,63.107,42.249Zm-9.4,1.491a8.848,8.848,0,0,0-7.221-3.748,7.6,7.6,0,0,0-2.826.51,8.749,8.749,0,0,0-6.181-8.535,8.712,8.712,0,0,0-.02-10.537,8.866,8.866,0,0,0,6.24-8.516,8.818,8.818,0,0,0,2.786.451,8.7,8.7,0,0,0,7.182-3.748,8.945,8.945,0,0,0,7.26,3.748,7.892,7.892,0,0,0,2.806-.491,8.867,8.867,0,0,0,6.142,8.535,8.83,8.83,0,0,0,.02,10.537,8.742,8.742,0,0,0-6.122,8.516h-.02A8.75,8.75,0,0,0,53.709,43.741Z"
                                        fill="#ebeaf2"
                                    />
                                </g>
                            </svg>
                        </div>
                        <button
                            id="INFLUENCER"
                            value="INFLUENCER"
                            className="mt-4"
                        >
                            <span className="block" id="INFLUENCER">
                                {config?.userTypes.influencerHeading1 ||
                                    'I am an'}
                            </span>
                            <span className="block" id="INFLUENCER">
                                {config?.userTypes.influencerHeading2 ||
                                    'Influencer'}
                            </span>
                        </button>
                    </div>
                )} */}
                {/* Sales Manger New Design */}
                {config?.userTypes.salesAdmin && (
                    <div
                        onClick={userTypeClickedHandler}
                        id="RELATIONSHIP_MANAGER"
                        className={`${
                            config?.customConfig?.userBgColor ||
                            'bg-transparent'
                        } ${
                            config?.customConfig?.animateUserType
                                ? 'animate-pulse'
                                : ''
                        }   w-full md:w-[45%] lg:w-[45%] xl:w-[30%] md:mr-4 cursor-pointer px-0 py-10 flex flex-col justify-center mb-6 hover:bg-primary  hover:bg-opacity-70 text-white border border-gray-300 hover:border-transparent rounded-lg`}
                    >
                        <div
                            className="flex justify-center cursor-pointer"
                            // onClick={userTypeClickedHandler}
                        >
                            <svg
                                id="RELATIONSHIP_MANAGER"
                                // onClick={userTypeClickedHandler}

                                xmlns="http://www.w3.org/2000/svg"
                                width="35.399"
                                height="32.905"
                                viewBox="0 0 35.399 32.905"
                                className=" self-center  w-full cursor-pointer"
                            >
                                <g
                                    id="RELATIONSHIP_MANAGER"
                                    data-name="&lt;Group&gt;"
                                    transform="translate(-1.61 -2.48)"
                                >
                                    <circle
                                        id="RELATIONSHIP_MANAGER"
                                        data-name="&lt;Path&gt;"
                                        cx="7.428"
                                        cy="7.428"
                                        r="7.428"
                                        transform="translate(11.908 3.48)"
                                        fill="none"
                                        stroke="#ebeaf2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    />
                                    <path
                                        id="RELATIONSHIP_MANAGER"
                                        data-name="&lt;Path&gt;"
                                        d="M2.61,27.862h0A16.032,16.032,0,0,1,18.642,11.83h1.336A16.032,16.032,0,0,1,36.009,27.862h0"
                                        transform="translate(0 6.524)"
                                        fill="none"
                                        stroke="#ebeaf2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    />
                                </g>
                            </svg>
                        </div>
                        <button
                            id="RELATIONSHIP_MANAGER"
                            value="RELATIONSHIP_MANAGER"
                            className="mt-4"
                        >
                            <span className="block" id="RELATIONSHIP_MANAGER">
                                {config?.userTypes.salesAdminHeading1 ||
                                    `I am a `}
                            </span>
                            <span className="block" id="RELATIONSHIP_MANAGER">
                                {config?.userTypes.salesAdminHeading2 ||
                                    `${config.name} Sales Admin`}
                            </span>
                        </button>
                    </div>
                )}
            </div>

            <Modal
                visible={showModal}
                content={modalMessage}
                onClick={closeEmployeeDisclaimer}
                showButton={false}
            />
        </div>
    );
}

export default UserTypeForm;
