import { Divider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getConfig } from '../../../../utils/config';

export default function ApartmentToggle({
    apartments,
    apartmentUpdate,
    apartmentTypes
}) {
    const [formats, setFormats] = useState(apartments);
    const [show, setShow] = useState(false);
const config = getConfig()
    const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
    };

    useEffect(() => {
        apartmentUpdate(formats);
    }, [formats]);

    return (
        <div className="mb-16">
            <ToggleButtonGroup
                color={config.primaryColor}
                value={formats}
                onChange={handleFormat}
                aria-label="text formatting"
                sx={{
                    display: 'grid',
                    gridGap: '4px',
                    color: 'purple',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    height: '80px',
                    overflowY: 'scroll',
                    margin: '0px 0px 16px 0px',
                    // fontFamily:`${fontDetails.fontBody}`
                }}
                className={`scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-300 ${config?.projects.moduleConfig
                    ?.fontBody}`}
            >
                {apartmentTypes.map((type, index) =>
                    index < 4 ? (
                        <ToggleButton
                            value={type}
                            size="small"
                            color="primary"
                            style={{
                                border: '1px solid gray',
                                margin: '4px',
                                borderRadius: '80px',
                                padding: '0px',
                                fontSize: '16px',
                                
                                // fontFamily:`${fontDetails.fontBody}`
                            }}
                            aria-label={type}
                        >
                            {type}
                        </ToggleButton>
                    ) : (
                        <ToggleButton
                            value={type}
                            size="small"
                            color="primary"
                            style={{
                                border: '1px solid gray',
                                margin: '4px',
                                borderRadius: '80px',
                                padding: '0px',
                                fontSize: '16px',
                                display: show ? 'none' : 'inline',
                                // fontFamily:`${fontDetails.fontBody}`
                            }}
                            aria-label={type}
                        >
                            {type}
                        </ToggleButton>
                    )
                )}
                <p
                    onClick={() => setShow(!show)}
                    className={`m-auto text-blue-500 font-medium text-md align-bottom cursor-pointer ${config?.projects.moduleConfig
                        ?.fontBody}`}
                >
                    {!show ? '-5 less' : '+5 more'}
                </p>
            </ToggleButtonGroup>
            <Divider sx={{ margin: '12px 0px 0px 0px' }} />
        </div>
    );
}
