import React from 'react'

function Event(props) {
  return (
    <svg className='text-white'
    xmlns="http://www.w3.org/2000/svg"
    width="19.193"
    height="19.126"
    viewBox="0 0 19.193 19.126"
>
    <g id="Layer_5" transform="translate(0.19 0.104)">
        <g
            id="Group_9468"
            data-name="Group 9468"
            transform="translate(0.06 0.146)"
        >
            <path
                id="Path_2644"
                data-name="Path 2644"
                d="M8.538,18.74l-.56-1.5-.093-.316a6,6,0,0,0,4.47-2.788v.012c.059-.093.118-.2.178-.306.011-.023.023-.046.035-.068a28.247,28.247,0,0,0,1.605-4.363h0A7.2,7.2,0,1,0,.06,7.353a7.1,7.1,0,0,0,.182,1.6v0c0,.013.007.025.01.039a27.3,27.3,0,0,0,1.6,4.546v0c.083.167.168.328.255.476h0A5.983,5.983,0,0,0,6.7,16.922l-.056.216L6,18.74l.006,0c-.005.009-.008.019-.012.028H8.542c0-.009-.008-.018-.012-.027ZM2.951,13.053c-.005-.008-.01-.011-.015-.021a20.725,20.725,0,0,1-1.5-4.247l.008.007a6.007,6.007,0,1,1,11.7-.24h.011s-.024.105-.067.285c0,.014-.005.028-.009.041a17.8,17.8,0,0,1-1.482,4.157l-.015.021a4.79,4.79,0,0,1-8.624-.006Z"
                transform="translate(-0.06 -0.146)"
                fill={props.fill}
                stroke={props.stroke}
                stroke-width="0.5"
            />
            <path
                id="Path_2645"
                data-name="Path 2645"
                d="M34.726,14.04A6.042,6.042,0,0,0,29.755,8.1a7.353,7.353,0,0,1,.7,1.233,5.033,5.033,0,0,1,3.265,4.71,4.964,4.964,0,0,1-.11,1.006h.009s-.02.088-.057.239c0,.012,0,.023-.008.035a14.831,14.831,0,0,1-1.243,3.487l-.013.018a4.019,4.019,0,0,1-3.615,2.266,3.967,3.967,0,0,1-1.451-.282l0,0-.614.411a2.307,2.307,0,0,1-.352.236,4.979,4.979,0,0,0,1.944.608l-.047.181-.544,1.344.005,0c0,.007-.006.015-.011.023h2.142a.086.086,0,0,1-.011-.023l.005,0-.469-1.262-.078-.265a5.032,5.032,0,0,0,3.75-2.339v.01c.05-.078.1-.165.149-.256.011-.019.02-.039.03-.057a23.708,23.708,0,0,0,1.347-3.659h0A5.965,5.965,0,0,0,34.726,14.04Z"
                transform="translate(-16.033 -4.993)"
                fill={props.fill}
                stroke={props.stroke}
                stroke-width="0.5"
            />
        </g>
    </g>
</svg>
  )
}

export default Event
