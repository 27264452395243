import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getConfig } from '../../../utils/config';

const Footer2 = () => {
    const config = getConfig();
    const history = useHistory();

    const navigateTnC = () => {
        history.push('/terms-and-conditions');
    };
    return (
        <div
            className={
                config?.footer.containerClassName
                    ? config?.footer.containerClassName
                    : 'bg-secondary-content pt-5'
            }
        >
            <footer className=" md:pl-12 md:pr-4 lg:pl-4 lg:pr-2 xl:pl-10 xl:pr-7 3xl:pl-10 3xl:pr-10 font-Corporate_S font-medium ">
                {config?.footer.loyalieFooterExpandContent ? (
                    <details>
                        <summary
                            className={
                                config?.footer
                                    .loyalieFooterExpandContentClassName
                                    ? config?.footer
                                          .loyalieFooterExpandClassName
                                    : 'px-10 lg:pr-6 xl:pl-4 self-center md:w-[85%] text-[14px] lg:text-[13px] xl:text-[14px] 3xl:text-[15px] leading-5 tracking-tight  text-white'
                            }
                        >
                            {' '}
                            {config?.footer.loyalieFooterExpandName}{' '}
                        </summary>
                        <div className="  flex flex-col md:flex-row justify-center">
                            <div
                                className={
                                    config?.footer.footerLogoClassName
                                        ? config?.footer.footerLogoClassName
                                        : ' self-center w-full md:w-[20%] lg:w-[16%]'
                                }
                            >
                                <img
                                    className=" h-auto px-24 my-4 w-full md:h-auto md:w-auto md:px-0 md:py-0 xl:pl-0 xl:pr-2 2xl:pr-8 3xl:pr-10 "
                                    src={
                                        config?.footer?.footerLogo ||
                                        'https://cdn.loyalie.in/28602817.png'
                                    }
                                />
                            </div>
                            <p
                                className={
                                    config?.footer
                                        .loyalieFooterExpandContentClassName
                                        ? config?.footer
                                              .loyalieFooterExpandContentClassName
                                        : 'px-10 lg:pr-6 xl:pl-4 self-center md:w-[85%] text-[14px] lg:text-[13px] xl:text-[14px] 3xl:text-[15px] leading-5 tracking-tight  text-white'
                                }
                            >
                                {config?.footer.loyalieFooterContent}

                                <p
                                    className={
                                        config?.footer
                                            .termsAndConditionsClassName
                                            ? config?.footer
                                                  .termsAndConditionsClassName
                                            : ' text-white text-[14px] md:text-base 3xl:text-[17px] text-right mr-4 md:mr-0'
                                    }
                                    onClick={navigateTnC}
                                >
                                    {config?.footer?.termsAndConditionsContent
                                        ? config?.footer
                                              ?.termsAndConditionsContent
                                        : '*T&C Apply'}
                                </p>
                            </p>
                        </div>
                    </details>
                ) : (
                    <>
                        <div className=" flex flex-col md:flex-row justify-center">
                            <div
                                className={
                                    config?.footer.footerLogoClassName
                                        ? config?.footer.footerLogoClassName
                                        : ' self-center w-full md:w-[20%] lg:w-[15%]'
                                }
                            >
                                <img
                                    className=" h-auto px-24 my-4 w-full md:h-auto md:w-auto md:px-0 md:py-0 xl:pl-0 xl:pr-2 2xl:pr-8 3xl:pr-10 "
                                    src={
                                        config?.footer?.footerLogo ||
                                        'https://cdn.loyalie.in/28602817.png'
                                    }
                                />
                            </div>
                            <p
                                className={
                                    config?.footer.loyalieFooterContentClassName
                                        ? config?.footer
                                              .loyalieFooterContentClassName
                                        : 'px-10 lg:pr-6 xl:pl-4 self-center md:w-[85%] text-[14px] lg:text-[13px] xl:text-[14px] 3xl:text-[15px] leading-5 tracking-tight  text-white'
                                }
                            >
                                {config?.footer.loyalieFooterContent}
                            </p>
                        </div>
                        <p
                            className={
                                config?.footer.termsAndConditionsClassName
                                    ? config?.footer.termsAndConditionsClassName
                                    : ' text-white text-[14px] md:text-base 3xl:text-[17px] text-right mr-4 md:mr-0'
                            }
                            onClick={navigateTnC}
                        >
                            {config?.footer?.termsAndConditionsContent
                                ? config?.footer?.termsAndConditionsContent
                                : '*T&C Apply'}
                        </p>
                    </>
                )}
            </footer>
            {config?.footer.allRightsReservedVisible && (
                <p
                    className={
                        config?.footer.allRightsReservedClassName
                            ? config?.footer.allRightsReservedClassName
                            : ' text-center text-white text-[14px] md:text-[16px] 3xl:text-[17px] px-7 pt-1'
                    }
                >
                    {config?.footer.allRightsReservedContent}
                </p>
            )}
        </div>
    );
};

export default Footer2;
