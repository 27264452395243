import react, { useEffect, useState } from 'react';
import { winnreNoAuth } from '../../../../utils/axios';

const useData = () => {
    const [searchProjects, setSearchProjects] = useState([]);

    const getProjectsWithFilters = () => {
        let url = `/winnre/user/project/marketing-names`;

        winnreNoAuth.get(url).then((data) => {
            // console.log('search names', data);
            const projects = data?.map((project) => {
                return { id: project?.id, projectName: project?.projectName };
            });
            setSearchProjects(projects);
        });
    };

    useEffect(() => {
        getProjectsWithFilters();
    }, []);

    return {
        searchProjects
    };
};

export default useData;
