import React from 'react';

function Link({ url, content, className }) {
    return (
        <a className={className || 'text-red-400'} href={url}>
            {content}
        </a>
    );
}

export default Link;
