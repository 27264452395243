import React from 'react';
import Carousel from '../Molecules/Carousel';
import { Button, Text, Image } from '../Atoms';
import Divider from '../Atoms/Divider';
import Banner from '../Molecules/Banner';
import CustomSection from '../Molecules/CustomSection';
import IconsLayout from '../Molecules/IconsLayout';
import PhotoSection from '../Molecules/PhotoSection';
import Iframe from '../Atoms/Iframe';
import Span from '../Atoms/Span';
import List from '../Atoms/List';
import ChangingImage from '../Molecules/ChangingImage';
import Icon from '../Atoms/Icon';
import SliderBanner from '../Atoms/SliderBanner';
import IconsLayoutWithArrow from '../Molecules/IconsLayoutWithArrow';
import ImageHovertext from '../Molecules/ImageHoverText';
import { getConfig } from '../../utils/config';
import { cmsClient, winnreAuth } from '../../utils/axios';
import BlogLayoutVertical from '../Molecules/BlogLayoutVertical';
import BlogLayoutVertical1 from '../Molecules/BlogLayoutVertical1';
import BlogLayoutVertical2 from '../Molecules/BlogLayoutVertical2';
import Video from '../Atoms/Video';
// import { SimpleReferralForm } from 'new-referral-form-module';
import Link from '../Atoms/Link';
import AnimationDivider from '../Atoms/Animation';
import SquareBlogLayout from '../Molecules/SquareBlogLayout';
import BannerNew from '../Molecules/BannerNew';
import Sup from '../Atoms/Sup';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/auth-slice';
import CustomCarousel from '../Molecules/CustomCarousel';
import HorizontalCarousel from '../Molecules/HorizontalCarousel';
import { useHistory } from 'react-router-dom';
import VerticalBlogLayout from '../Molecules/VerticalBlogLayout';
import ReferralFormMain from '../../Routes/ReferralPage/ReferralForm';
import BlogLayoutSlider from '../Molecules/BlogLayoutSlider';
import ReferralFormBanner from '../Molecules/ReferralFormBanner';
import BlogLayoutGrid1 from '../Molecules/BlogLayoutGrid1';
import RazorPayTerms from '../../Routes/TermsAndConditions/Razorpay';
import AppDownload from '../Molecules/AppDownload';
const config = getConfig();

const getComponent = (
    type,
    props,
    userName,
    userRole,
    jwtToken,
    userEmail,
    userMobileCountryCode,
    userMobileNumber,
    userStatus,
    userHash,
    setParentAuthToken,
    openLoginModal,
    openMyDocuments
) => {
    switch (type) {
        case 'TEXT':
            return <Text {...props}>{props.content || ''}</Text>;
        case 'BANNER':
            return <Banner {...props} />;
        case 'BANNER_NEW':
            return <BannerNew {...props} />;
        case 'SUP':
            return <Sup {...props} />;
        case 'BUTTON':
            return <Button {...props}>{props.content || ''}</Button>;
        case 'IMAGE':
            return <Image {...props} />;
        case 'VIDEO':
            return <Video {...props} />;
        case 'CUSTOM':
            return <CustomSection {...props} />;
        case 'ICONS_LAYOUT':
            return <IconsLayout {...props} />;
        case 'ICONS_LAYOUT_WITH_ARROW':
            return <IconsLayoutWithArrow {...props} />;
        case 'DIVIDER':
            return <Divider {...props} />;
        case 'IMAGE_SECTION':
            return <PhotoSection {...props} />;
        case 'IMAGE_HOVER_TEXT':
            return <ImageHovertext {...props} />;
        case 'IFRAME':
            return <Iframe {...props} />;
        case 'SPAN':
            return <Span {...props} />;
        case 'LIST':
            return <List {...props} />;
        case 'CHANGING_IMAGE':
            return <ChangingImage {...props} />;
        case 'CAROUSEL':
            return <Carousel {...props} />;
        case 'ICON':
            return <Icon {...props} />;
        case 'SQUARE_BLOGS':
            return <SquareBlogLayout {...props} />;
        case 'VERTICAL_BLOGS':
            return <VerticalBlogLayout {...props} />;
        // case 'REFERRAL_FORM_MODULE':
        //     return <div id="referral_section">
        //         <App
        //             baseUrl={cmsClient.defaults.baseURL}
        //             clientId={config?.clientId}
        //             id={config?.id}
        //             listTitleClass={
        //                 config?.deals?.dealLayout?.listTitleClass
        //             }
        //             listBodyClass={config?.deals?.dealLayout?.listBodyClass}
        //             config={config?.referralForm}
        //             parentUserRole={userRole}
        //             userName={userName}
        //             userHash={userHash}
        //             jwtToken={jwtToken}
        //             setParentAuthToken={setParentAuthToken}
        //             openLoginModal={openLoginModal}
        //             openMyDocuments={openMyDocuments}
        //             pendingUser={userStatus === 'PENDING_APPROVAL'}
        //             webengageLic={
        //                 cmsClient.defaults.baseURL ===
        //                     'https://uat.loyalie.in'
        //                     ? config?.uatWebengageLic
        //                     : config?.webengageLic
        //             }
        //         />
        //     </div>
        case 'REFERRAL_FORM':
            return <ReferralFormMain referralConfig={config?.referralForm} />;
        // case 'SIMPLE_REFERRAL_FORM':
        //     return (
        //         <div
        //             className="hero h-auto lg:min-h-[90vh]"
        //             id="referral_section"
        //         >
        //             <SimpleReferralForm
        //                 baseUrl={cmsClient.defaults.baseURL}
        //                 clientId={config?.clientId}
        //                 config={config?.referralForm}
        //                 id={config?.id}
        //                 listTitleClass={
        //                     config?.deals?.dealLayout?.listTitleClass
        //                 }
        //                 listBodyClass={config?.deals?.dealLayout?.listBodyClass}
        //                 websiteUserRole={userRole}
        //                 userName={userName}
        //                 userHash={userHash}
        //                 jwtToken={jwtToken}
        //                 userEmail={userEmail}
        //                 userMobileCountryCode={userMobileCountryCode}
        //                 userMobileNumber={userMobileNumber}
        //                 setParentAuthToken={setParentAuthToken}
        //                 openLoginModal={openLoginModal}
        //                 openMyDocuments={openMyDocuments}
        //             />
        //         </div>
        //     );
        case 'SLIDER_BANNER':
            return <SliderBanner {...props} />;
        case 'BLOG_LAYOUT_VERTICAL':
            return <BlogLayoutVertical {...props} />;
        case 'BLOG_LAYOUT_VERTICAL1':
            return <BlogLayoutVertical1 {...props} />;
        case 'BLOG_LAYOUT_VERTICAL2':
            return <BlogLayoutVertical2 {...props} />;
        case 'BLOG_LAYOUT_SLIDER':
            return <BlogLayoutSlider {...props} />;
        case 'BLOG_LAYOUT_GRID1':
            return <BlogLayoutGrid1 {...props} />;
        case 'LINK':
            return <Link {...props} />;
        case 'ANIMATE':
            return <AnimationDivider {...props} />;
        case 'CAROUSEL_NEW':
            return <CustomCarousel {...props} />;
        case 'HORIZONTAL_CAROUSEL':
            return <HorizontalCarousel {...props} />;
        case 'REFERRAL_FORM_BANNER':
            return <ReferralFormBanner />;

        case 'RAZORPAY_TERMS':
            return <RazorPayTerms {...props} />;
        case 'APP_DOWNLOAD':
            return <AppDownload />;
        default:
            return <div />;
    }
};

const ComponentsRenderer = (props) => {
    const { components = [] } = props;
    const dispatch = useDispatch();

    const userName = useSelector((state) => state.auth.userName);
    const userRole = useSelector((state) => state.auth.userRole);
    const userHash = useSelector((state) => state.auth.userHash);
    const jwtToken = useSelector((state) => state.auth.token);

    const userEmail = useSelector((state) => state.auth.userDetails?.email);
    const userMobileCountryCode = useSelector(
        (state) => state.auth.userDetails?.mobileCountryCode
    );
    const userMobileNumber = useSelector(
        (state) => state.auth.userDetails?.mobileNumber
    );
    const userStatus = useSelector((state) => state.auth.userDetails?.status);

    const setParentAuthToken = ({
        accessToken,
        refreshToken,
        user,
        userRole
    }) => {
        dispatch(authActions.authenticationToken(accessToken));
        // check this
        dispatch(
            authActions.setUserData({
                userDetails: {
                    mobileCountryCode: user?.mobileCountryCode,
                    mobileNumber: user?.mobileNumber,
                    email: user?.email,
                    status: user?.status,
                    preferredName: user?.preferredName,
                    birthDate: user?.dateOfBirth,
                    address: user?.address
                },
                name: user.name,
                userRole,
                userId: user.id,
                hashId: user.hashId,
                profileCompletionPercentage: user?.profileCompletionPercentage,
                finishedFirstTimeLoginGuide: user?.finishedFirstTimeLoginGuide
            })
        );
        dispatch(authActions.login());
        if (config?.autoLogout) {
            sessionStorage.setItem('refreshToken', refreshToken);
            sessionStorage.setItem('accessToken', accessToken);
        } else {
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('accessToken', accessToken);
        }
        // console.log(localStorage.accessToken, localStorage.refreshToken);
    };
    // console.log('hash', userHash);
    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
    };

    const history = useHistory();

    const openMyDocuments = () => {
        history.push('/myprofile/docs');
    };

    return components?.map((component) => {
        return getComponent(
            component.type,
            { ...component },
            userName,
            userRole,
            jwtToken,
            userEmail,
            userMobileCountryCode,
            userMobileNumber,
            userStatus,
            userHash,
            setParentAuthToken,
            openLoginModal,
            openMyDocuments
        );
    });
};

export default ComponentsRenderer;
