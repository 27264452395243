import { getConfig } from "../../../utils/config"

const config = getConfig()
export const shareLinkSvg =    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"  className="cursor-pointer">
<g id="Group_11169" data-name="Group 11169" transform="translate(-918 -558)">
<rect id="Rectangle_17163" data-name="Rectangle 17163" width="50" height="50" rx="15" transform="translate(918 558)" fill={config.primaryColor} opacity="0.1"/>
<g id="_4634463_attach_attachment_interface_link_icon" data-name="4634463_attach_attachment_interface_link_icon" transform="translate(926.998 566.998)">
<g id="Group_11166" data-name="Group 11166">
<path id="Path_7118" data-name="Path 7118" d="M10.705,30A4.75,4.75,0,0,1,7.336,28.6L3.4,24.664a4.772,4.772,0,0,1,0-6.739l3.278-3.278a4.766,4.766,0,0,1,6.739,0A1,1,0,0,1,12,16.061a2.832,2.832,0,0,0-3.911,0L4.81,19.339a2.771,2.771,0,0,0,0,3.911l3.94,3.94a2.769,2.769,0,0,0,3.911,0l3.278-3.278a2.767,2.767,0,0,0,0-3.911,1,1,0,0,1,1.414-1.414,4.766,4.766,0,0,1,0,6.739L14.075,28.6A4.751,4.751,0,0,1,10.705,30Z" fill={config.primaryColor}/>
</g>
<g id="Group_11167" data-name="Group 11167">
<path id="Path_7119" data-name="Path 7119" d="M21.956,18.749a4.729,4.729,0,0,1-3.369-1.4A1,1,0,0,1,20,15.939a2.83,2.83,0,0,0,3.911,0l3.278-3.278a2.771,2.771,0,0,0,0-3.911L23.25,4.81a2.769,2.769,0,0,0-3.911,0L16.061,8.088a2.767,2.767,0,0,0,0,3.911,1,1,0,0,1-1.414,1.414,4.766,4.766,0,0,1,0-6.739L17.925,3.4a4.772,4.772,0,0,1,6.739,0l3.94,3.94a4.772,4.772,0,0,1,0,6.739l-3.278,3.278A4.733,4.733,0,0,1,21.956,18.749Z" fill={config.primaryColor}/>
</g>
<g id="Group_11168" data-name="Group 11168">
<path id="Path_7120" data-name="Path 7120" d="M10.044,22.956a1,1,0,0,1-.707-1.707L21.249,9.337a1,1,0,0,1,1.414,1.414L10.751,22.663A1,1,0,0,1,10.044,22.956Z" fill={config.primaryColor}/>
</g>
</g>
</g>
</svg>

export const dividerSvg = <svg xmlns="http://www.w3.org/2000/svg"  width="314" height="2" viewBox="0 0 314 2">
<defs>
  <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
    <stop offset="0" stop-color="#f0efef"/>
    <stop offset="0.498" stop-color="#dedede"/>
    <stop offset="1" stop-color="#f0efef"/>
  </linearGradient>
</defs>
<rect id="Rectangle_1790" data-name="Rectangle 1790" width="314" height="2" fill="url(#linear-gradient)"/>
</svg>

export const icon1 =   <svg xmlns="http://www.w3.org/2000/svg" width="80.61" height="80.61" viewBox="0 0 80.61 80.61"  className='w-[62px] h-[62px]'>
<g id="Group_11062" data-name="Group 11062" transform="translate(-110.695 -711.756)">
<rect id="Rectangle_17127" data-name="Rectangle 17127" width="57" height="57" rx="20" transform="translate(151 711.756) rotate(45)" fill="#fff"/>
<g id="like" transform="translate(137.918 738.918)">
<path id="Path_6941" data-name="Path 6941" d="M24.155,8.673a49.991,49.991,0,0,0-7.03-.363,12.078,12.078,0,0,0,.207-2.722C17.332,2.617,15.167,0,13.252,0a2.5,2.5,0,0,0-2.485,2.466,6.623,6.623,0,0,1-3.313,6.01c-.2.108-.787.4-.872.432l.044.037A2.624,2.624,0,0,0,4.97,8.283H2.485A2.488,2.488,0,0,0,0,10.767V24.019A2.488,2.488,0,0,0,2.485,26.5H4.97a2.456,2.456,0,0,0,2.21-1.43.267.267,0,0,0,.039.01l.2.051.038.01c.477.118,1.4.338,3.359.789a24.571,24.571,0,0,0,4.946.57h4.528a3.126,3.126,0,0,0,2.967-1.6,6.066,6.066,0,0,0,.354-.891,2.758,2.758,0,0,0,.019-1.458,3.709,3.709,0,0,0,1.362-2.137,3.245,3.245,0,0,0,0-2.259A3.892,3.892,0,0,0,26.1,15.872a3.112,3.112,0,0,0-.322-1.965,2.89,2.89,0,0,0,.707-1.8l.01-.173c.006-.109.011-.176.011-.414A3.067,3.067,0,0,0,24.155,8.673ZM5.8,24.019a.827.827,0,0,1-.828.828H2.485a.827.827,0,0,1-.828-.828V10.767a.827.827,0,0,1,.828-.828H4.97a.827.827,0,0,1,.828.828ZM24.829,12.039c-.017.409-.188,1.213-1.637,1.213H21.535a.414.414,0,0,0,0,.828H23.14a1.257,1.257,0,0,1,1.325,1.527,1.807,1.807,0,0,1-1.792,1.786H20.706a.414.414,0,0,0,0,.828h1.631c1.4,0,1.275,1.066,1.074,1.7-.264.836-.426,1.611-2.187,1.611H19.873a.414.414,0,0,0,0,.828h1.3a.876.876,0,0,1,.854,1.165,4.57,4.57,0,0,1-.231.6,1.491,1.491,0,0,1-1.507.723H15.759a23.289,23.289,0,0,1-4.589-.529c-3.441-.793-3.622-.854-3.838-.915,0,0-.7-.118-.7-.73l-.006-11.44a.9.9,0,0,1,.658-.863c.051-.02.121-.041.171-.062a8.145,8.145,0,0,0,4.97-7.824.83.83,0,0,1,.828-.828c.875,0,2.423,1.758,2.423,3.933a9.928,9.928,0,0,1-.767,4.35c8.283,0,8.225.119,8.955.311a1.271,1.271,0,0,1,.984,1.268C24.848,11.8,24.839,11.76,24.829,12.039Z" fill="#16519e" fill-rule="evenodd"/>
<path id="Path_6942" data-name="Path 6942" d="M4.242,26a1.242,1.242,0,1,0,1.242,1.242A1.243,1.243,0,0,0,4.242,26Zm0,1.657a.414.414,0,1,1,.414-.414A.415.415,0,0,1,4.242,27.657Z" transform="translate(-0.515 -4.465)" fill="#16519e" fill-rule="evenodd"/>
</g>
</g>
</svg>

export const icon2 =           <svg xmlns="http://www.w3.org/2000/svg" width="80.61" height="80.61" viewBox="0 0 80.61 80.61">
<g id="Group_11061" data-name="Group 11061" transform="translate(-110.695 -821.309)">
  <rect id="Rectangle_17128" data-name="Rectangle 17128" width="57" height="57" rx="20" transform="translate(151 821.309) rotate(45)" fill="#fff"/>
  <g id="road_tracking" data-name="road tracking" transform="translate(137.77 846.958)">
    <path id="Path_6943" data-name="Path 6943" d="M8,49.94l1.2-3.97L8,42l7.373,3.97Z" transform="translate(-8 -22.15)" fill="#16519e"/>
    <path id="Path_6944" data-name="Path 6944" d="M31.268,39.611H17.94a.567.567,0,1,1,0-1.134H31.268a2.552,2.552,0,1,0,0-5.1H13.686a3.686,3.686,0,1,1,0-7.373H27.014a.567.567,0,0,1,0,1.134H13.686a2.552,2.552,0,0,0,0,5.1H31.268a3.686,3.686,0,1,1,0,7.373Z" transform="translate(-8.866 -15.224)" fill="#16519e"/>
    <path id="Path_6945" data-name="Path 6945" d="M42.567,17.208A.567.567,0,0,1,42,16.641V7.567a.567.567,0,1,1,1.134,0v9.074A.567.567,0,0,1,42.567,17.208Z" transform="translate(-22.717 -7)" fill="#16519e"/>
    <path id="Path_6946" data-name="Path 6946" d="M43,12.537h5.671l-1.7-2.246L48.671,8H43Z" transform="translate(-23.15 -7.433)" fill="#16519e"/>
    <circle id="Ellipse_1742" data-name="Ellipse 1742" cx="1.692" cy="1.692" r="1.692" transform="translate(17.668 9.517)" fill="#16519e"/>
  </g>
</g>
</svg>  

export const icon3 =                      <svg xmlns="http://www.w3.org/2000/svg" width="80.61" height="80.61" viewBox="0 0 80.61 80.61">
<g id="Group_11060" data-name="Group 11060" transform="translate(-110.695 -930.309)">
  <rect id="Rectangle_17129" data-name="Rectangle 17129" width="57" height="57" rx="20" transform="translate(151 930.309) rotate(45)" fill="#fff"/>
  <path id="reshot-icon-gift-box-WYG95ZHCXM" d="M21.958,0H2.331A2.333,2.333,0,0,0,0,2.331V21.958a2.332,2.332,0,0,0,2.331,2.331H21.958a2.332,2.332,0,0,0,2.331-2.331V2.331A2.333,2.333,0,0,0,21.958,0ZM8.342.736h2.7V3.86A3.327,3.327,0,0,0,9.7,5.237a3.336,3.336,0,0,0-1.36-1.388ZM10.07,9.322V6.747a2.576,2.576,0,1,1,2.576,2.576Zm5.152,3.313a2.576,2.576,0,0,1-5.152,0V10.058h2.576a2.581,2.581,0,0,1,2.576,2.577ZM6.757,9.322A2.576,2.576,0,1,1,9.333,6.746V9.322Zm2.576.737v2.577a2.576,2.576,0,1,1-2.576-2.577ZM.736,2.331A1.6,1.6,0,0,1,2.331.736H7.605V3.55A3.3,3.3,0,0,0,3.562,7.606H.736Zm0,6.01H3.864a3.34,3.34,0,0,0,1.382,1.35A3.335,3.335,0,0,0,3.864,11.04H.736Zm6.87,15.212H2.331A1.6,1.6,0,0,1,.737,21.961V11.777H3.563a3.3,3.3,0,0,0,3.194,4.171,3.238,3.238,0,0,0,.849-.116v7.722Zm3.434,0h-2.7V15.534A3.334,3.334,0,0,0,9.7,14.147a3.343,3.343,0,0,0,1.339,1.377Zm12.512-1.6a1.6,1.6,0,0,1-1.594,1.595H11.776V15.827a3.3,3.3,0,0,0,4.064-4.051h7.712V21.958Zm0-10.918H15.538a3.334,3.334,0,0,0-1.382-1.349,3.333,3.333,0,0,0,1.382-1.35h8.014Zm0-3.434H15.838a3.3,3.3,0,0,0-4.064-4.051V.736H21.957a1.6,1.6,0,0,1,1.594,1.595V7.606Z" transform="translate(138.986 957.986)" fill="#16519e" stroke="#16519e" stroke-width="0.5"/>
</g>
</svg>

export const moneyBagSvg =   <svg
xmlns="http://www.w3.org/2000/svg"
width="86.237"
height="103.086"
viewBox="0 0 86.237 103.086"
>
<g
    id="OBJECTS"
    transform="translate(-80.32 -39.34)"
>
    <path
        id="Path_6949"
        data-name="Path 6949"
        d="M166.557,393.432c0,3.738-19.3,8.879-43.118,8.879S80.32,397.17,80.32,393.432s19.3-6.772,43.118-6.772S166.557,389.693,166.557,393.432Z"
        transform="translate(0 -259.885)"
        fill="#ddd"
    />
    <g
        id="Group_11058"
        data-name="Group 11058"
        transform="translate(88.441 39.34)"
    >
        <g
            id="Group_11046"
            data-name="Group 11046"
            transform="translate(58.436 76.171)"
        >
            <g
                id="Group_11045"
                data-name="Group 11045"
                transform="translate(0 0)"
            >
                <circle
                    id="Ellipse_1743"
                    data-name="Ellipse 1743"
                    cx="8.197"
                    cy="8.197"
                    r="8.197"
                    transform="translate(0 16.056) rotate(-78.363)"
                    fill="#dba32c"
                />
                <circle
                    id="Ellipse_1744"
                    data-name="Ellipse 1744"
                    cx="6.069"
                    cy="6.069"
                    r="6.069"
                    transform="translate(1.609 12.59) rotate(-64.785)"
                    fill="#edcb3a"
                />
                <g
                    id="Group_11044"
                    data-name="Group 11044"
                    transform="translate(4.676 4.771)"
                >
                    <g
                        id="Group_11043"
                        data-name="Group 11043"
                    >
                        <path
                            id="Path_6950"
                            data-name="Path 6950"
                            d="M379.664,372.571a4.991,4.991,0,0,0-.509-1.521l-7.326,6.5a4.994,4.994,0,0,0,1.45.687Z"
                            transform="translate(-369.678 -368.487)"
                            fill="#ffeea4"
                        />
                        <path
                            id="Path_6951"
                            data-name="Path 6951"
                            d="M369.458,360.87l-6.178,5.48a5.031,5.031,0,0,0,1.551,3.1l7.514-6.666A5.022,5.022,0,0,0,369.458,360.87Z"
                            transform="translate(-363.28 -360.87)"
                            fill="#ffeea4"
                        />
                    </g>
                </g>
            </g>
            <path
                id="Path_6952"
                data-name="Path 6952"
                d="M377.684,365.19h0a.378.378,0,0,0-.355-.4l-4.919-.292a.378.378,0,0,0-.4.355h0a.378.378,0,0,0,.355.4l1.334.081a1.78,1.78,0,0,1,1.6,1.163l-3-.179a.378.378,0,0,0-.4.355h0a.378.378,0,0,0,.355.4l3.1.184a1.735,1.735,0,0,1-1.85,1.357l-1.334-.081a.378.378,0,0,0-.4.355h0a.378.378,0,0,0,.355.4l.816.048,2.261,3.441a.46.46,0,0,0,.352.2h0a.452.452,0,0,0,.4-.7l-1.891-2.88,2.978.176a.378.378,0,0,0,.4-.355h0a.378.378,0,0,0-.355-.4l-1.322-.078a2.532,2.532,0,0,0,.692-1.425l.715.043a.378.378,0,0,0,.4-.355h0a.378.378,0,0,0-.355-.4l-.778-.045a2.57,2.57,0,0,0-.481-1.1l1.322.078A.38.38,0,0,0,377.684,365.19Z"
                transform="translate(-364.954 -358.813)"
                fill="#aa7921"
            />
        </g>
        <path
            id="Path_6953"
            data-name="Path 6953"
            d="M350.636,355.541a8.2,8.2,0,0,0,3.983,7.53c.038-.043.078-.083.116-.126l.038-.043c.081-.1.159-.191.237-.287l.063-.078c.076-.093.146-.189.219-.284l.068-.091c.07-.1.141-.191.209-.289.023-.03.045-.063.065-.1q.1-.147.2-.3c.018-.028.035-.055.055-.083.07-.108.138-.216.206-.325.01-.015.018-.033.028-.048.151-.249.3-.5.438-.765q.125-.234.242-.476c.063-.128.121-.257.181-.388,0,0,0-.008.005-.01.058-.126.111-.254.166-.383.013-.03.025-.058.038-.086l.053-.126c.033-.078.06-.159.093-.239.015-.038.03-.076.043-.113.045-.121.091-.242.133-.365.015-.04.028-.083.043-.123.043-.123.083-.247.123-.373l.038-.121c.04-.131.081-.262.118-.393.01-.03.018-.063.025-.093.033-.111.063-.224.093-.337.1-.38.189-.768.269-1.166.04-.2.078-.4.111-.607.068-.408.128-.828.176-1.256s.086-.868.113-1.319q.023-.393.03-.785c0-.083,0-.169,0-.252,0-.181,0-.363,0-.544,0-.1-.005-.194-.008-.289-.005-.176-.015-.352-.025-.529-.005-.093-.01-.186-.018-.279-.015-.216-.035-.436-.058-.655-.005-.058-.01-.116-.018-.171-.028-.247-.058-.493-.093-.743-.008-.06-.018-.123-.028-.184A8.192,8.192,0,0,0,350.636,355.541Z"
            transform="translate(-290.696 -270.185)"
            fill="#554c3d"
            opacity="0.32"
        />
        <g
            id="Group_11047"
            data-name="Group 11047"
            transform="translate(29.831 2.104)"
        >
            <path
                id="Path_6954"
                data-name="Path 6954"
                d="M241.852,80.94l-9.891-6.127a1.867,1.867,0,0,1-.6-2.57l14.659-23.661a1.867,1.867,0,0,1,2.57-.6l9.891,6.127a1.867,1.867,0,0,1,.6,2.57L244.422,80.336A1.866,1.866,0,0,1,241.852,80.94Z"
                transform="translate(-231.077 -47.697)"
                fill="#efc2ec"
            />
            <path
                id="Path_6955"
                data-name="Path 6955"
                d="M260.792,58.829l-14.51,23.42a1.245,1.245,0,0,1-1.712.4l-9.649-5.979a1.245,1.245,0,0,1-.4-1.712l14.51-23.42a1.245,1.245,0,0,1,1.712-.4l9.649,5.979A1.243,1.243,0,0,1,260.792,58.829Z"
                transform="translate(-233.513 -50.133)"
                fill="#d292cc"
            />
            <path
                id="Path_6956"
                data-name="Path 6956"
                d="M271,52.1l-7.1,11.467,11.764,7.288,7.107-11.469a1.527,1.527,0,0,0-.493-2.1L273.109,51.6A1.53,1.53,0,0,0,271,52.1Z"
                transform="translate(-255.637 -50.448)"
                fill="#a0679c"
            />
            <circle
                id="Ellipse_1745"
                data-name="Ellipse 1745"
                cx="6.387"
                cy="6.387"
                r="6.387"
                transform="translate(6.445 12.033) rotate(-13.433)"
                fill="#efc2ec"
            />
            <path
                id="Path_6957"
                data-name="Path 6957"
                d="M270.721,95.8h0a.409.409,0,0,0-.564.133l-2.809,4.536a.409.409,0,0,0,.133.564h0a.409.409,0,0,0,.564-.133l.763-1.231a1.928,1.928,0,0,1,1.928-.924l-1.712,2.762a.409.409,0,0,0,.133.564h0a.409.409,0,0,0,.564-.133l1.77-2.855a1.876,1.876,0,0,1,.34,2.46l-.763,1.231a.409.409,0,0,0,.133.564h0a.409.409,0,0,0,.564-.133l.466-.753,4.443-.383a.493.493,0,0,0,.375-.229h0a.49.49,0,0,0-.458-.745l-3.716.32,1.7-2.747a.409.409,0,0,0-.133-.564h0a.409.409,0,0,0-.564.133l-.755,1.218a2.768,2.768,0,0,0-1-1.395l.408-.66a.409.409,0,0,0-.133-.564h0a.409.409,0,0,0-.564.133l-.443.717a2.776,2.776,0,0,0-1.291-.106l.755-1.218A.412.412,0,0,0,270.721,95.8Z"
                transform="translate(-258.171 -83.643)"
                fill="#604260"
            />
            <path
                id="Path_6958"
                data-name="Path 6958"
                d="M236.558,135.3l-1.568,2.533a1.527,1.527,0,0,0,.493,2.1l2.548,1.578A4.608,4.608,0,0,0,236.558,135.3Z"
                transform="translate(-233.833 -113.247)"
                fill="#604260"
            />
            <path
                id="Path_6959"
                data-name="Path 6959"
                d="M263.97,163.8l2.533,1.568a1.527,1.527,0,0,0,2.1-.493l1.578-2.548A4.61,4.61,0,0,0,263.97,163.8Z"
                transform="translate(-255.689 -132.99)"
                fill="#604260"
            />
            <path
                id="Path_6960"
                data-name="Path 6960"
                d="M326.343,74.783l1.568-2.533a1.527,1.527,0,0,0-.493-2.1L324.87,68.57A4.607,4.607,0,0,0,326.343,74.783Z"
                transform="translate(-300.78 -63.315)"
                fill="#604260"
            />
            <path
                id="Path_6961"
                data-name="Path 6961"
                d="M292.083,53.148,289.55,51.58a1.527,1.527,0,0,0-2.1.493l-1.578,2.548A4.612,4.612,0,0,0,292.083,53.148Z"
                transform="translate(-272.076 -50.431)"
                fill="#604260"
            />
        </g>
        <g
            id="Group_11048"
            data-name="Group 11048"
            transform="translate(6.635 2.433)"
        >
            <path
                id="Path_6962"
                data-name="Path 6962"
                d="M168.188,74.354l-9.2,7.124a1.868,1.868,0,0,1-2.621-.332l-17.045-22a1.868,1.868,0,0,1,.332-2.621l9.2-7.124a1.868,1.868,0,0,1,2.621.332l17.045,22A1.87,1.87,0,0,1,168.188,74.354Z"
                transform="translate(-138.935 -49.005)"
                fill="#efcc80"
            />
            <path
                id="Path_6963"
                data-name="Path 6963"
                d="M153.413,52.757l16.872,21.781a1.243,1.243,0,0,1-.222,1.745l-8.972,6.951a1.243,1.243,0,0,1-1.745-.222L142.475,61.23a1.243,1.243,0,0,1,.222-1.745l8.972-6.951A1.243,1.243,0,0,1,153.413,52.757Z"
                transform="translate(-141.389 -51.451)"
                fill="#d2ae5d"
            />
            <path
                id="Path_6964"
                data-name="Path 6964"
                d="M142.979,61.795l8.262,10.666,10.938-8.474-8.262-10.666a1.527,1.527,0,0,0-2.142-.272l-8.526,6.6A1.529,1.529,0,0,0,142.979,61.795Z"
                transform="translate(-141.721 -51.792)"
                fill="#a0803b"
            />
            <circle
                id="Ellipse_1746"
                data-name="Ellipse 1746"
                cx="6.387"
                cy="6.387"
                r="6.387"
                transform="translate(5.997 17.284) rotate(-50.401)"
                fill="#efcc80"
            />
            <path
                id="Path_6965"
                data-name="Path 6965"
                d="M178.648,101.9h0a.409.409,0,0,0-.073.574l3.268,4.217a.409.409,0,0,0,.574.073h0a.409.409,0,0,0,.073-.574l-.886-1.143a1.921,1.921,0,0,1-.191-2.13l1.989,2.568a.409.409,0,0,0,.574.073h0a.409.409,0,0,0,.073-.574l-2.057-2.656a1.877,1.877,0,0,1,2.424.541l.886,1.143a.409.409,0,0,0,.574.073h0a.409.409,0,0,0,.073-.574l-.541-.7,1.2-4.3a.489.489,0,0,0-.086-.43h0a.49.49,0,0,0-.858.169l-1,3.595L182.681,99.3a.409.409,0,0,0-.574-.073h0a.409.409,0,0,0-.073.574l.879,1.133a2.752,2.752,0,0,0-1.656.451l-.476-.612a.409.409,0,0,0-.574-.073h0a.409.409,0,0,0-.073.574l.516.667a2.766,2.766,0,0,0-.549,1.173l-.879-1.133A.407.407,0,0,0,178.648,101.9Z"
                transform="translate(-168.532 -85.589)"
                fill="#785f3a"
            />
            <path
                id="Path_6966"
                data-name="Path 6966"
                d="M202.32,161.4l1.825,2.356a1.527,1.527,0,0,0,2.142.272l2.369-1.835A4.61,4.61,0,0,0,202.32,161.4Z"
                transform="translate(-186.363 -132.424)"
                fill="#785f3a"
            />
            <path
                id="Path_6967"
                data-name="Path 6967"
                d="M239.894,136.766l2.356-1.825a1.527,1.527,0,0,0,.272-2.142l-1.835-2.369A4.611,4.611,0,0,0,239.894,136.766Z"
                transform="translate(-213.794 -109.932)"
                fill="#785f3a"
            />
            <path
                id="Path_6968"
                data-name="Path 6968"
                d="M175.8,55.667l-1.825-2.356a1.527,1.527,0,0,0-2.142-.272l-2.369,1.835A4.609,4.609,0,0,0,175.8,55.667Z"
                transform="translate(-161.775 -51.784)"
                fill="#785f3a"
            />
            <path
                id="Path_6969"
                data-name="Path 6969"
                d="M145.587,72.96l-2.356,1.825a1.527,1.527,0,0,0-.272,2.142l1.835,2.369A4.611,4.611,0,0,0,145.587,72.96Z"
                transform="translate(-141.707 -66.929)"
                fill="#785f3a"
            />
        </g>
        <g
            id="Group_11049"
            data-name="Group 11049"
            transform="translate(24.731)"
        >
            <path
                id="Path_6970"
                data-name="Path 6970"
                d="M224.336,70.908l-11.635.005a1.867,1.867,0,0,1-1.868-1.865l-.013-27.835a1.867,1.867,0,0,1,1.865-1.868l11.635-.005a1.867,1.867,0,0,1,1.868,1.865L226.2,69.04A1.867,1.867,0,0,1,224.336,70.908Z"
                transform="translate(-210.82 -39.34)"
                fill="#bdefdc"
            />
            <path
                id="Path_6971"
                data-name="Path 6971"
                d="M227.7,43.624l.013,27.551a1.243,1.243,0,0,1-1.244,1.244l-11.351.005a1.243,1.243,0,0,1-1.244-1.244l-.013-27.551a1.243,1.243,0,0,1,1.244-1.244l11.351-.005A1.243,1.243,0,0,1,227.7,43.624Z"
                transform="translate(-213.095 -41.615)"
                fill="#8ed2bb"
            />
            <path
                id="Path_6972"
                data-name="Path 6972"
                d="M213.87,43.921l.005,13.491,13.838-.005-.005-13.491a1.527,1.527,0,0,0-1.528-1.526L215.4,42.4A1.521,1.521,0,0,0,213.87,43.921Z"
                transform="translate(-213.102 -41.622)"
                fill="#64a08a"
            />
            <circle
                id="Ellipse_1747"
                data-name="Ellipse 1747"
                cx="6.387"
                cy="6.387"
                r="6.387"
                transform="translate(1.304 9.4)"
                fill="#bdefdc"
            />
            <path
                id="Path_6973"
                data-name="Path 6973"
                d="M225.13,89.823h0a.41.41,0,0,0-.41.41l0,5.334a.41.41,0,0,0,.41.41h0a.41.41,0,0,0,.41-.41V94.12a1.926,1.926,0,0,1,1.153-1.8l0,3.247a.41.41,0,0,0,.41.41h0a.41.41,0,0,0,.41-.41l0-3.358a1.874,1.874,0,0,1,1.586,1.911v1.448a.41.41,0,0,0,.41.41h0a.41.41,0,0,0,.41-.41v-.886l3.575-2.666a.494.494,0,0,0,.2-.393h0a.487.487,0,0,0-.78-.39l-2.991,2.23,0-3.23a.41.41,0,0,0-.41-.41h0a.41.41,0,0,0-.41.41v1.432a2.754,2.754,0,0,0-1.583-.657V90.23a.41.41,0,0,0-.41-.41h0a.41.41,0,0,0-.41.41v.843a2.77,2.77,0,0,0-1.153.592V90.23A.414.414,0,0,0,225.13,89.823Z"
                transform="translate(-221.221 -77.112)"
                fill="#406052"
            />
            <path
                id="Path_6974"
                data-name="Path 6974"
                d="M213.91,143.82l0,2.978a1.527,1.527,0,0,0,1.528,1.526l3,0A4.611,4.611,0,0,0,213.91,143.82Z"
                transform="translate(-213.132 -117.518)"
                fill="#406052"
            />
            <path
                id="Path_6975"
                data-name="Path 6975"
                d="M250.98,148.286l2.978,0a1.527,1.527,0,0,0,1.526-1.528l0-3A4.61,4.61,0,0,0,250.98,148.286Z"
                transform="translate(-240.87 -117.473)"
                fill="#406052"
            />
            <path
                id="Path_6976"
                data-name="Path 6976"
                d="M255.386,46.894l0-2.978a1.527,1.527,0,0,0-1.528-1.526l-3,0A4.61,4.61,0,0,0,255.386,46.894Z"
                transform="translate(-240.78 -41.622)"
                fill="#406052"
            />
            <path
                id="Path_6977"
                data-name="Path 6977"
                d="M218.374,42.36l-2.978,0a1.527,1.527,0,0,0-1.526,1.528l0,3A4.61,4.61,0,0,0,218.374,42.36Z"
                transform="translate(-213.102 -41.6)"
                fill="#406052"
            />
        </g>
        <g
            id="Group_11053"
            data-name="Group 11053"
            transform="translate(0 22.093)"
        >
            <path
                id="Path_6978"
                data-name="Path 6978"
                d="M160.4,142.549l5.327-7.791a4.9,4.9,0,0,0-4.043-7.658H130.021a4.9,4.9,0,0,0-4.043,7.658l5.382,7.877a4.894,4.894,0,0,1-.974,6.576c-9.267,7.462-17.806,19.729-17.806,31.314,0,18.324,14.855,23.855,33.18,23.855s33.18-5.528,33.18-23.855c0-11.625-8.295-23.941-17.569-31.395A4.9,4.9,0,0,1,160.4,142.549Z"
                transform="translate(-112.58 -127.1)"
                fill="#d0b170"
            />
            <path
                id="Path_6979"
                data-name="Path 6979"
                d="M161.465,161.534a4.892,4.892,0,0,1-.977-6.578l5.327-7.791c.025-.038.048-.078.073-.116-4.327,3.368-10.905,5.289-20.9,3.786-9.377-1.412-15.605-4.078-19.724-7.154a4.818,4.818,0,0,0,.806,3.484l5.382,7.877a4.889,4.889,0,0,1,.242,5.125c9.408-1.375,32.019-3.484,37.731,24.819,5.239,25.96-45.55,30.01-56.727,9.2.667,17.322,15.256,22.6,33.154,22.6,18.324,0,33.18-5.528,33.18-23.855C179.031,181.3,170.739,168.988,161.465,161.534Z"
                transform="translate(-112.67 -139.506)"
                fill="#c19e61"
            />
            <path
                id="Path_6980"
                data-name="Path 6980"
                d="M222.086,264.271V261.26H199.47v3.011h6.822a7.068,7.068,0,0,1,6.618,4.237H199.47v3.011h13.848a6.893,6.893,0,0,1-7.026,5.823H199.47v3.011h4.758l10.331,13.863,4.478-.005-10.329-13.856h13.375v-3.011h-6.772a10.11,10.11,0,0,0,2.417-5.823h4.355V268.51h-4.6a10.2,10.2,0,0,0-2.173-4.237h6.777Z"
                transform="translate(-177.596 -227.486)"
                fill="#554c3d"
            />
            <path
                id="Path_6981"
                data-name="Path 6981"
                d="M182.616,297.744a32.374,32.374,0,0,0-3.545-13.984c3.907,27.971-34.37,45-61.581,22.246,3.917,11.779,16.733,15.593,31.946,15.593C167.76,321.6,182.616,316.069,182.616,297.744Z"
                transform="translate(-116.254 -244.322)"
                fill="#af8c54"
            />
            <path
                id="Path_6982"
                data-name="Path 6982"
                d="M211.711,182.1l.458-.67a53.074,53.074,0,0,1-31.01-1.473l1.521,2.225a4.867,4.867,0,0,1,.851,2.621c13.823-.272,23.6,1.959,28.842,3.592A4.892,4.892,0,0,1,211.711,182.1Z"
                transform="translate(-163.896 -166.653)"
                fill="#af8c54"
            />
            <path
                id="Path_6983"
                data-name="Path 6983"
                d="M130.51,245.44s3.132-14.143,15.9-22.01Z"
                transform="translate(-125.996 -199.18)"
                fill="#e8c589"
            />
            <g
                id="Group_11050"
                data-name="Group 11050"
                transform="translate(17.037 15.994)"
            >
                <path
                    id="Path_6984"
                    data-name="Path 6984"
                    d="M181.754,194.433a1.5,1.5,0,0,1-.216-2.978,130.073,130.073,0,0,1,29.746-.008,1.5,1.5,0,1,1-.315,2.978,129.809,129.809,0,0,0-29-.008A1.538,1.538,0,0,1,181.754,194.433Z"
                    transform="translate(-180.258 -190.633)"
                    fill="#554c3d"
                />
            </g>
            <path
                id="Path_6985"
                data-name="Path 6985"
                d="M180.31,197.921a.086.086,0,0,0,0,.025,1.5,1.5,0,0,0,1.48,1.281,1.628,1.628,0,0,0,.219-.015,129.809,129.809,0,0,1,29,.008,1.493,1.493,0,0,0,1.639-1.294A143.35,143.35,0,0,0,180.31,197.921Z"
                transform="translate(-163.26 -179.433)"
                fill="#3f3b34"
            />
            <g
                id="Group_11051"
                data-name="Group 11051"
                transform="translate(17.793 15.993)"
            >
                <path
                    id="Path_6986"
                    data-name="Path 6986"
                    d="M277.71,194.723l.76.048v-3c-.254-.018-.509-.033-.76-.048Z"
                    transform="translate(-253.933 -191.446)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6987"
                    data-name="Path 6987"
                    d="M270.97,194.343c.252.013.506.025.76.04v-3c-.254-.015-.509-.028-.76-.04Z"
                    transform="translate(-248.89 -191.161)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6988"
                    data-name="Path 6988"
                    d="M257.48,193.833l.76.023v-3l-.76-.023Z"
                    transform="translate(-238.796 -190.78)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6989"
                    data-name="Path 6989"
                    d="M264.22,194.061l.76.03v-3l-.76-.03Z"
                    transform="translate(-243.839 -190.952)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6990"
                    data-name="Path 6990"
                    d="M297.95,196.311l.76.076v-3.011l-.76-.076Z"
                    transform="translate(-269.078 -192.628)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6991"
                    data-name="Path 6991"
                    d="M304.7,194.12v2.85a1.492,1.492,0,0,0,.76-.556v-1.734A1.484,1.484,0,0,0,304.7,194.12Z"
                    transform="translate(-274.128 -193.241)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6992"
                    data-name="Path 6992"
                    d="M250.73,193.7c.252,0,.5.008.76.013v-3c-.257-.005-.509-.01-.76-.013Z"
                    transform="translate(-233.745 -190.682)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6993"
                    data-name="Path 6993"
                    d="M284.46,195.173c.252.018.506.038.76.058v-3l-.76-.058Z"
                    transform="translate(-258.984 -191.782)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6994"
                    data-name="Path 6994"
                    d="M291.21,195.713c.252.023.506.043.76.068v-3.006c-.254-.023-.509-.045-.76-.065Z"
                    transform="translate(-264.034 -192.186)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6995"
                    data-name="Path 6995"
                    d="M223.74,193.873l.76-.025V190.84l-.76.023Z"
                    transform="translate(-213.55 -190.787)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6996"
                    data-name="Path 6996"
                    d="M196.76,195.421c.237-.023.491-.045.76-.068V192.34c-.267.023-.521.045-.76.068Z"
                    transform="translate(-193.361 -191.91)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6997"
                    data-name="Path 6997"
                    d="M190.01,196.071c.211-.025.466-.053.76-.086V192.97l-.76.083Z"
                    transform="translate(-188.311 -192.381)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6998"
                    data-name="Path 6998"
                    d="M183.26,193.984v2.6a1.493,1.493,0,0,0,.74.2l.02,0V193.77l-.237.033A1.488,1.488,0,0,0,183.26,193.984Z"
                    transform="translate(-183.26 -192.98)"
                    fill="#685d4e"
                />
                <path
                    id="Path_6999"
                    data-name="Path 6999"
                    d="M203.5,194.889c.244-.018.5-.038.76-.055V191.82c-.262.018-.516.038-.76.055Z"
                    transform="translate(-198.405 -191.52)"
                    fill="#685d4e"
                />
                <path
                    id="Path_7000"
                    data-name="Path 7000"
                    d="M243.98,193.643c.252,0,.5,0,.76.005v-3l-.76-.005Z"
                    transform="translate(-228.694 -190.637)"
                    fill="#685d4e"
                />
                <path
                    id="Path_7001"
                    data-name="Path 7001"
                    d="M230.49,193.721l.76-.015V190.7c-.257.005-.511.008-.76.015Z"
                    transform="translate(-218.6 -190.682)"
                    fill="#685d4e"
                />
                <path
                    id="Path_7002"
                    data-name="Path 7002"
                    d="M210.25,194.456l.76-.045V191.4c-.259.015-.514.028-.76.043Z"
                    transform="translate(-203.455 -191.206)"
                    fill="#685d4e"
                />
                <path
                    id="Path_7003"
                    data-name="Path 7003"
                    d="M237.24,193.641c.249,0,.5-.005.76-.005V190.63c-.257,0-.511,0-.76.005Z"
                    transform="translate(-223.651 -190.63)"
                    fill="#685d4e"
                />
                <path
                    id="Path_7004"
                    data-name="Path 7004"
                    d="M217,194.124c.249-.013.5-.023.76-.035V191.08c-.259.01-.511.023-.76.033Z"
                    transform="translate(-208.506 -190.967)"
                    fill="#685d4e"
                />
            </g>
            <path
                id="Path_7005"
                data-name="Path 7005"
                d="M295.1,199.78s2.115,7.99,7.363,12.688"
                transform="translate(-249.152 -181.483)"
                fill="none"
                stroke="#554c3d"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="13"
            />
            <path
                id="Path_7006"
                data-name="Path 7006"
                d="M284.862,198.19a25.454,25.454,0,0,0,1.687,11.678"
                transform="translate(-241.404 -180.294)"
                fill="none"
                stroke="#554c3d"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="13"
            />
            <g
                id="Group_11052"
                data-name="Group 11052"
                transform="translate(39.899 13.047)"
            >
                <circle
                    id="Ellipse_1748"
                    data-name="Ellipse 1748"
                    cx="3.429"
                    cy="3.429"
                    r="3.429"
                    transform="translate(0 4.849) rotate(-45)"
                    fill="#554c3d"
                />
                <path
                    id="Path_7007"
                    data-name="Path 7007"
                    d="M280.149,198.312a3.429,3.429,0,0,1-3.363-2.762,3.429,3.429,0,1,0,6.727,0A3.433,3.433,0,0,1,280.149,198.312Z"
                    transform="translate(-275.298 -191.366)"
                    fill="#3f3b34"
                />
                <ellipse
                    id="Ellipse_1749"
                    data-name="Ellipse 1749"
                    cx="2.125"
                    cy="1.334"
                    rx="2.125"
                    ry="1.334"
                    transform="translate(2.726 2.183)"
                    fill="#685d4e"
                />
            </g>
            <path
                id="Path_7008"
                data-name="Path 7008"
                d="M206.6,360.91c-4.705,9.1-15.5,16.509-31.813,16.509-12.167,0-19.865-1.624-24.585-3.318,6.022,3.8,14.42,5.224,23.714,5.224C190.282,379.325,203.879,374.912,206.6,360.91Z"
                transform="translate(-140.729 -302.05)"
                fill="#937344"
            />
            <path
                id="Path_7009"
                data-name="Path 7009"
                d="M186.8,130.38h29.615a.129.129,0,0,1,.02.257c-4.564.617-17.358,1.984-29.655,0A.131.131,0,0,1,186.8,130.38Z"
                transform="translate(-168.018 -129.554)"
                fill="#e8c589"
            />
        </g>
        <g
            id="Group_11057"
            data-name="Group 11057"
            transform="translate(49.293 77.289)"
        >
            <g
                id="Group_11056"
                data-name="Group 11056"
                transform="translate(0 0)"
            >
                <circle
                    id="Ellipse_1750"
                    data-name="Ellipse 1750"
                    cx="8.197"
                    cy="8.197"
                    r="8.197"
                    transform="translate(0 11.592) rotate(-45)"
                    fill="#dba32c"
                />
                <circle
                    id="Ellipse_1751"
                    data-name="Ellipse 1751"
                    cx="6.069"
                    cy="6.069"
                    r="6.069"
                    transform="translate(3.77 8.052) rotate(-20.622)"
                    fill="#edcb3a"
                />
                <g
                    id="Group_11055"
                    data-name="Group 11055"
                    transform="translate(6.587 6.687)"
                >
                    <g
                        id="Group_11054"
                        data-name="Group 11054"
                    >
                        <path
                            id="Path_7010"
                            data-name="Path 7010"
                            d="M350.924,384.621a4.993,4.993,0,0,0-.509-1.521l-7.326,6.5a4.993,4.993,0,0,0,1.45.687Z"
                            transform="translate(-340.94 -380.537)"
                            fill="#ffeea4"
                        />
                        <path
                            id="Path_7011"
                            data-name="Path 7011"
                            d="M340.728,372.92l-6.178,5.48a5.03,5.03,0,0,0,1.551,3.1l7.514-6.666A5.029,5.029,0,0,0,340.728,372.92Z"
                            transform="translate(-334.55 -372.92)"
                            fill="#ffeea4"
                        />
                    </g>
                </g>
            </g>
            <path
                id="Path_7012"
                data-name="Path 7012"
                d="M348.944,377.24h0a.378.378,0,0,0-.355-.4l-4.919-.292a.378.378,0,0,0-.4.355h0a.378.378,0,0,0,.355.4l1.334.081a1.78,1.78,0,0,1,1.6,1.163l-3-.179a.378.378,0,0,0-.4.355h0a.378.378,0,0,0,.355.4l3.1.184a1.735,1.735,0,0,1-1.85,1.357l-1.334-.081a.378.378,0,0,0-.4.355h0a.378.378,0,0,0,.355.4l.816.048,2.261,3.441a.46.46,0,0,0,.352.2h0a.452.452,0,0,0,.4-.7l-1.891-2.88,2.978.176a.378.378,0,0,0,.4-.355h0a.378.378,0,0,0-.355-.4l-1.322-.078a2.533,2.533,0,0,0,.692-1.425l.715.043a.378.378,0,0,0,.4-.355h0a.378.378,0,0,0-.355-.4l-.778-.045a2.57,2.57,0,0,0-.481-1.1l1.322.078A.379.379,0,0,0,348.944,377.24Z"
                transform="translate(-334.307 -368.947)"
                fill="#aa7921"
            />
        </g>
        <path
            id="Path_7013"
            data-name="Path 7013"
            d="M391.277,347.1a2.151,2.151,0,0,0-1.687-1.87,2.148,2.148,0,0,0,1.9-1.656,2.151,2.151,0,0,0,1.687,1.87A2.153,2.153,0,0,0,391.277,347.1Z"
            transform="translate(-319.855 -266.983)"
            fill="#fff"
        />
        <path
            id="Path_7014"
            data-name="Path 7014"
            d="M328.807,361.207a2.151,2.151,0,0,0-1.687-1.87,2.148,2.148,0,0,0,1.9-1.656,2.151,2.151,0,0,0,1.687,1.87A2.154,2.154,0,0,0,328.807,361.207Z"
            transform="translate(-273.111 -277.541)"
            fill="#fff"
        />
        <path
            id="Path_7015"
            data-name="Path 7015"
            d="M376.907,405.777a2.151,2.151,0,0,0-1.687-1.87,2.148,2.148,0,0,0,1.9-1.656,2.151,2.151,0,0,0,1.687,1.87A2.151,2.151,0,0,0,376.907,405.777Z"
            transform="translate(-309.103 -310.89)"
            fill="#fff"
        />
    </g>
</g>
</svg>

export const paginationSvg =                 <svg xmlns="http://www.w3.org/2000/svg" width="40" height="8" viewBox="0 0 40 8">
<g id="Group_10340" data-name="Group 10340" transform="translate(-166 -1481)">
  <circle id="Ellipse_1660" data-name="Ellipse 1660" cx="4" cy="4" r="4" transform="translate(182 1481)" fill="#16519e"/>
  <circle id="Ellipse_1661" data-name="Ellipse 1661" cx="3" cy="3" r="3" transform="translate(193 1482)" fill="#16519e" opacity="0.7"/>
  <circle id="Ellipse_1662" data-name="Ellipse 1662" cx="2" cy="2" r="2" transform="translate(202 1483)" fill="#16519e" opacity="0.5"/>
  <circle id="Ellipse_1663" data-name="Ellipse 1663" cx="3" cy="3" r="3" transform="translate(173 1482)" fill="#16519e" opacity="0.7"/>
  <circle id="Ellipse_1664" data-name="Ellipse 1664" cx="2" cy="2" r="2" transform="translate(166 1483)" fill="#16519e" opacity="0.5"/>
</g>
</svg>