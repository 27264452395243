import { useEffect, useState } from "react";
import { winnreAuth } from "../../../utils/axios";
import { toast } from "react-toastify";
import { APIErrorCondition } from "../../../constants";

export const useData = () => {

    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteClicked, setDeleteClicked] = useState(false)
 

    const fetchUserOffers = () => {
        const date = new Date().toISOString(); // Assuming you want to fetch offers for the current date
        setLoading(true);
        winnreAuth({
            method: 'GET',
            url: `/winnre/user/deals/filter-v2?&status=PUBLISHED&isAscending=false&sortBy=priority&endDate=${date}&categoryList=1,2,3,5&pageSize=10`
        })
            .then((response) => {
                console.log(response);
                setLoading(false);
                if (response.status == APIErrorCondition) {
                    toast.error(response.message);
                    return;
                }
                setOffers(response.content); // Assuming the data structure matches the expected format
            })
            .catch((error) => {
                console.error('Error fetching offers:', error);
                setLoading(false);
                toast.error(error.message);
            });
    };



    return {
        offers,
        loading,
        deleteClicked, setDeleteClicked, 
        fetchUserOffers
    }

}