import { LoadingSpinner } from "../../../../../Components/Molecules/LoadingUI";
import { getConfig } from "../../../../../utils/config";
import { scrollToTargetAdjusted } from "../../../../../utils/ui";
import GoStop from '@meronex/icons/go/GoStop';
const ThirdStep = ({ loading, nothingToClaim, closeUploadBox, claimInProgress }) => {
    const config = getConfig()
    if(loading) {
        return <LoadingSpinner />
    }
    if (nothingToClaim) {
        return (
            <div className="px-3 md:px-8 py-4 flex flex-col items-center justify-center">

                <svg xmlns="http://www.w3.org/2000/svg" width="100.857" height="100.985" viewBox="0 0 100.857 100.985">
                    <g id="Group_3310" data-name="Group 3310" transform="translate(17623 15557)">
                        <g id="Outline" transform="translate(-17625 -15558.924)">
                            <path id="Path_6157" data-name="Path 6157" d="M7.043,33.99V91.142A11.782,11.782,0,0,0,18.81,102.909H86.048A11.782,11.782,0,0,0,97.814,91.143V33.99a5.043,5.043,0,0,0,5.043-5.043v-8.4A5.043,5.043,0,0,0,97.814,15.5H87.729V10.3A8.46,8.46,0,0,0,76.2,2.5l-15.7,6.277H44.348L28.653,2.5a8.459,8.459,0,0,0-11.525,7.8v5.2H7.043A5.043,5.043,0,0,0,2,20.542v8.4A5.043,5.043,0,0,0,7.043,33.99ZM10.4,91.142V33.99H42.343v6.075a28.527,28.527,0,0,0,0,53.407v6.075H18.81a8.415,8.415,0,0,1-8.4-8.4ZM59.152,18.862V30.628H45.7V18.862ZM52.429,95.345a28.429,28.429,0,0,0,6.724-.832v5.034H45.7V94.513a28.429,28.429,0,0,0,6.724.832Zm0-3.362A25.214,25.214,0,1,1,77.643,66.769,25.214,25.214,0,0,1,52.429,91.983Zm0-53.791a28.428,28.428,0,0,0-6.724.84V33.99H59.152v5.043a28.428,28.428,0,0,0-6.724-.84Zm42.024,52.95a8.415,8.415,0,0,1-8.4,8.4H62.514V93.472a28.527,28.527,0,0,0,0-53.407V33.99H94.452Zm5.043-70.6v8.4a1.681,1.681,0,0,1-1.681,1.681h-35.3V18.862h35.3A1.681,1.681,0,0,1,99.5,20.542ZM77.451,5.621A5.066,5.066,0,0,1,84.367,10.3v5.2H62.514v-3.9Zm-18.3,6.517V15.5H45.7V12.138ZM20.49,10.3a5.066,5.066,0,0,1,6.915-4.681l14.937,5.974v3.9H20.49ZM5.362,20.542a1.681,1.681,0,0,1,1.681-1.681h35.3V30.628H7.043a1.681,1.681,0,0,1-1.681-1.681Z" transform="translate(0 0)" />
                        </g>
                        <g id="_01" data-name="01" transform="translate(-17591.225 -15509)">
                            <path id="Path_6158" data-name="Path 6158" d="M14.93,12.482H5V10h9.93ZM32.307,10h-9.93v2.482h9.93ZM9.965,27.377H27.342V24.895H9.965Z" />
                        </g>
                    </g>
                </svg>

                <h1 className=" font-Poppins text-lg mt-7 leading-5 font-semibold text-center">
                    You currently have 0 rewards to claim.
                </h1>
                <h1 className="text-center">
                    Refer a friend, complete their booking, and unlock exclusive
                    rewards!{' '}
                </h1>
                <button onClick={() => {
                    closeUploadBox()
                    scrollToTargetAdjusted('referral_section')
                }} className={`${config?.referPage?.customize?.claimButton}
                                 uppercase px-16 md:px-10 py-3 mt-4 tracking-widest rounded`}>Refer Now</button>
            </div>
        );
    }
    return (

        <div className="px-3 md:px-8 py-4 flex flex-col items-center justify-center">
            {claimInProgress ?
                <div className="flex flex-col justify-center items-center"> <GoStop className='h-12 w-12' /> <h3 className="mt-3 font-semibold text-center"> Your KYC and/or Bank Verification is under processing. We will notify you once it is completed so you can come back to claim your reward!</h3></div>
                : <> <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="191.301"
                    height="219.08"
                    viewBox="0 0 191.301 219.08"
                >
                    <g
                        id="Group_3239"
                        data-name="Group 3239"
                        transform="translate(-105.85 -265)"
                    >
                        <circle
                            id="Ellipse_1530"
                            data-name="Ellipse 1530"
                            cx="6"
                            cy="6"
                            r="6"
                            transform="translate(112 292)"
                            fill="#fcc71a"
                        />
                        <circle
                            id="Ellipse_1531"
                            data-name="Ellipse 1531"
                            cx="4"
                            cy="4"
                            r="4"
                            transform="translate(236 269)"
                            fill="#15519e"
                        />
                        <circle
                            id="Ellipse_1532"
                            data-name="Ellipse 1532"
                            cx="6"
                            cy="6"
                            r="6"
                            transform="translate(201 286)"
                            fill="#1cb0bd"
                        />
                        <path
                            id="_9310131_star_sparkle_icon"
                            data-name="9310131_star_sparkle_icon"
                            d="M20.546,11.913c-.12,0-.083-.363-.913-.442-.049,0-.5-.035-1.251-.154a5.5,5.5,0,0,1-2.735-1.178,4.922,4.922,0,0,1-1.215-1.56,9.264,9.264,0,0,1-.734-2.2,21.969,21.969,0,0,1-.429-4.735c0-.089,0-.207,0-.3a.407.407,0,0,0-.417-.436c-.288-.023-.523.152-.523.353s0,.31,0,.4a26.457,26.457,0,0,1-.264,3.679,9.372,9.372,0,0,1-1.25,3.757,4.72,4.72,0,0,1-2.771,1.963,11.954,11.954,0,0,1-2.991.409c-.863.006-.641.408-.641.408,0,.429.641.51.641.51L6,12.424a10.688,10.688,0,0,1,1.961.3,4.884,4.884,0,0,1,2.351,1.314A6.607,6.607,0,0,1,11.786,16.9a17.965,17.965,0,0,1,.524,3.466c.062.812.071,1.636.087,2.358,0,0,.03.287.414.275s.4-.275.4-.275a24.22,24.22,0,0,1,.512-5.653A6.163,6.163,0,0,1,15.452,13.8,4.8,4.8,0,0,1,17.4,12.728a5.6,5.6,0,0,1,.88-.218,5.781,5.781,0,0,1,.751-.114,10.56,10.56,0,0,0,1.27-.153C20.546,12.218,20.4,11.913,20.546,11.913Z"
                            transform="translate(101.454 325.363)"
                            fill="#7700ed"
                        />
                        <path
                            id="_9310131_star_sparkle_icon-2"
                            data-name="9310131_star_sparkle_icon"
                            d="M20.546,11.913c-.12,0-.083-.363-.913-.442-.049,0-.5-.035-1.251-.154a5.5,5.5,0,0,1-2.735-1.178,4.922,4.922,0,0,1-1.215-1.56,9.264,9.264,0,0,1-.734-2.2,21.969,21.969,0,0,1-.429-4.735c0-.089,0-.207,0-.3a.407.407,0,0,0-.417-.436c-.288-.023-.523.152-.523.353s0,.31,0,.4a26.457,26.457,0,0,1-.264,3.679,9.372,9.372,0,0,1-1.25,3.757,4.72,4.72,0,0,1-2.771,1.963,11.954,11.954,0,0,1-2.991.409c-.863.006-.641.408-.641.408,0,.429.641.51.641.51L6,12.424a10.688,10.688,0,0,1,1.961.3,4.884,4.884,0,0,1,2.351,1.314A6.607,6.607,0,0,1,11.786,16.9a17.965,17.965,0,0,1,.524,3.466c.062.812.071,1.636.087,2.358,0,0,.03.287.414.275s.4-.275.4-.275a24.22,24.22,0,0,1,.512-5.653A6.163,6.163,0,0,1,15.452,13.8,4.8,4.8,0,0,1,17.4,12.728a5.6,5.6,0,0,1,.88-.218,5.781,5.781,0,0,1,.751-.114,10.56,10.56,0,0,0,1.27-.153C20.546,12.218,20.4,11.913,20.546,11.913Z"
                            transform="translate(276.604 325.363)"
                            fill="#7700ed"
                        />
                        <circle
                            id="Ellipse_1536"
                            data-name="Ellipse 1536"
                            cx="4"
                            cy="4"
                            r="4"
                            transform="translate(171 265)"
                            fill="red"
                        />
                        <path
                            id="_9310131_star_sparkle_icon-3"
                            data-name="9310131_star_sparkle_icon"
                            d="M26.545,16c-.164,0-.114-.5-1.252-.606-.067,0-.692-.048-1.715-.212a7.541,7.541,0,0,1-3.75-1.616,6.751,6.751,0,0,1-1.667-2.14,12.705,12.705,0,0,1-1.006-3.012,30.13,30.13,0,0,1-.589-6.494c0-.122.006-.284.006-.408A.559.559,0,0,0,16,.915c-.4-.032-.717.209-.717.484s0,.425,0,.554A36.285,36.285,0,0,1,14.925,7a12.854,12.854,0,0,1-1.714,5.152,6.473,6.473,0,0,1-3.8,2.692,16.4,16.4,0,0,1-4.1.561c-1.183.008-.879.559-.879.559,0,.589.879.7.879.7l1.281.039a14.658,14.658,0,0,1,2.689.409,6.7,6.7,0,0,1,3.224,1.8,9.062,9.062,0,0,1,2.027,3.925,24.639,24.639,0,0,1,.718,4.753c.085,1.114.1,2.244.119,3.234,0,0,.041.394.568.378s.552-.378.552-.378a33.217,33.217,0,0,1,.7-7.753,8.452,8.452,0,0,1,2.37-4.477,6.584,6.584,0,0,1,2.667-1.476,7.686,7.686,0,0,1,1.208-.3,7.929,7.929,0,0,1,1.03-.157,14.483,14.483,0,0,0,1.742-.209C26.545,16.418,26.339,16,26.545,16Z"
                            transform="translate(239.454 291.219)"
                            fill="#fcc71a"
                        />
                        <path
                            id="_9310131_star_sparkle_icon-4"
                            data-name="9310131_star_sparkle_icon"
                            d="M26.545,16c-.164,0-.114-.5-1.252-.606-.067,0-.692-.048-1.715-.212a7.541,7.541,0,0,1-3.75-1.616,6.751,6.751,0,0,1-1.667-2.14,12.705,12.705,0,0,1-1.006-3.012,30.13,30.13,0,0,1-.589-6.494c0-.122.006-.284.006-.408A.559.559,0,0,0,16,.915c-.4-.032-.717.209-.717.484s0,.425,0,.554A36.285,36.285,0,0,1,14.925,7a12.854,12.854,0,0,1-1.714,5.152,6.473,6.473,0,0,1-3.8,2.692,16.4,16.4,0,0,1-4.1.561c-1.183.008-.879.559-.879.559,0,.589.879.7.879.7l1.281.039a14.658,14.658,0,0,1,2.689.409,6.7,6.7,0,0,1,3.224,1.8,9.062,9.062,0,0,1,2.027,3.925,24.639,24.639,0,0,1,.718,4.753c.085,1.114.1,2.244.119,3.234,0,0,.041.394.568.378s.552-.378.552-.378a33.217,33.217,0,0,1,.7-7.753,8.452,8.452,0,0,1,2.37-4.477,6.584,6.584,0,0,1,2.667-1.476,7.686,7.686,0,0,1,1.208-.3,7.929,7.929,0,0,1,1.03-.157,14.483,14.483,0,0,0,1.742-.209C26.545,16.418,26.339,16,26.545,16Z"
                            transform="translate(144.529 291.219)"
                            fill="#4d78eb"
                        />
                        <path
                            id="Path_6070"
                            data-name="Path 6070"
                            d="M-11190.579,17451.926l-27.5-31.309-5.526-9.254-2.683-11.592,1.334-9.434,3.546-10.316,5.959-8.4,7.865-6.645,7.787-3.41,9.223-2.551,11.415,1.176,13.279,4.785,11.147,12.271,5.037,13.086v17.764l-5.037,9.549-11.147,13.715-17.968,20.561,4.688,9.4h-15.9Z"
                            transform="translate(11391 -17051)"
                            fill="#fff"
                        />
                        <path
                            id="Path_6071"
                            data-name="Path 6071"
                            d="M-11147.15,17490.154l4.808-9.684-28.34-32.51-1.731-8.109,11.523-14.168,7.274-8.689,3.487-8.184,1.111-6.693v-13.4l5.654-1.4h7.257l7.1,1.4,7.5,2.543,6.737,4.465,3.814,4.2,4.043,5.3,2.776,6.676,3.015,10.092v8.975l-3.015,8.875-2.776,6.605-6.444,7.441-8.15,9.33-7.5,7.379-5.061,6.211-2.035,3.652,4.569,8.742Z"
                            transform="translate(11391 -17051)"
                            fill="#da867d"
                        />
                        <path
                            id="Path_6072"
                            data-name="Path 6072"
                            d="M-11226.293,17490.154h-15.482l5.247-9.58-28.694-32.83-4.191-8.885-2.418-12.41v-9.17l4.271-8.361,4.4-7.463,6.458-6.721,8.384-4.6,8.374-2.031,5.316-1.238h8.339l1.777,1.238-1.777,8.793v5.979l1.777,6.041,4,8.361,4.156,5.434,4.839,5.678,3.136,3.525,4.457,4.389,5.24,6.277-6.377,8.77-25.8,29.223Z"
                            transform="translate(11391 -17051)"
                            fill="#69aef8"
                        />
                        <g id="Balloons" transform="translate(46.493 248.09)">
                            <path
                                id="Path_6061"
                                data-name="Path 6061"
                                d="M155.3,349.45a.929.929,0,0,0-.929.929V389.62a.929.929,0,1,0,1.858,0V350.379A.929.929,0,0,0,155.3,349.45Z"
                                transform="translate(-44.379 -155.059)"
                                fill="#1e247e"
                                stroke="#1e247e"
                                stroke-width="1"
                            />
                            <path
                                id="Path_6062"
                                data-name="Path 6062"
                                d="M357.679,349.45a.929.929,0,0,0-.929.929V389.62a.929.929,0,1,0,1.858,0V350.379A.929.929,0,0,0,357.679,349.45Z"
                                transform="translate(-152.768 -155.059)"
                                fill="#1e247e"
                                stroke="#1e247e"
                                stroke-width="1"
                            />
                            <path
                                id="Path_6063"
                                data-name="Path 6063"
                                d="M254.929,289.557a.929.929,0,0,0-.929.929v67.057a.929.929,0,1,0,1.858,0V290.486A.929.929,0,0,0,254.929,289.557Z"
                                transform="translate(-97.738 -122.982)"
                                fill="#1e247e"
                                stroke="#1e247e"
                                stroke-width="1"
                            />
                            <path
                                id="Path_6064"
                                data-name="Path 6064"
                                d="M418.957,204.462a26.869,26.869,0,0,1-.762,6.245.929.929,0,0,0,1.805.44,28.735,28.735,0,0,0,.814-6.677.929.929,0,0,0-.925-.933h0a.929.929,0,0,0-.929.925Z"
                                transform="translate(-185.662 -76.912)"
                                fill="#fff"
                                stroke="#fff"
                                stroke-width="1"
                            />
                            <path
                                id="Path_6065"
                                data-name="Path 6065"
                                d="M385.17,157.74a29.409,29.409,0,0,0-27.491-17.81.929.929,0,1,0,0,1.858,27.6,27.6,0,0,1,25.8,16.711,30.53,30.53,0,0,1,1.564,4.335.929.929,0,0,0,1.791-.495A32.386,32.386,0,0,0,385.17,157.74Z"
                                transform="translate(-152.768 -42.846)"
                                fill="#fff"
                                stroke="#fff"
                                stroke-width="1"
                            />
                            <path
                                id="Path_6066"
                                data-name="Path 6066"
                                d="M317.139,143.537h0a.929.929,0,0,0-.929.925,26.881,26.881,0,0,1-.762,6.245.929.929,0,0,0,1.805.439,28.74,28.74,0,0,0,.814-6.677A.929.929,0,0,0,317.139,143.537Z"
                                transform="translate(-130.632 -44.778)"
                                fill="#14897b"
                                stroke="#14897b"
                                stroke-width="1"
                            />
                            <path
                                id="Path_6067"
                                data-name="Path 6067"
                                d="M283.184,103.516a.93.93,0,0,0,.9-1.176,32.386,32.386,0,0,0-1.66-4.6,29.409,29.409,0,0,0-27.491-17.81.929.929,0,0,0,0,1.858,27.6,27.6,0,0,1,25.8,16.711,30.535,30.535,0,0,1,1.564,4.335A.929.929,0,0,0,283.184,103.516Z"
                                transform="translate(-97.738 -10.711)"
                                fill="#14897b"
                                stroke="#14897b"
                                stroke-width="1"
                            />
                            <circle
                                id="Ellipse_1537"
                                data-name="Ellipse 1537"
                                cx="2.747"
                                cy="2.747"
                                r="2.747"
                                transform="translate(158.814 93.361) rotate(-45)"
                                fill="#14897b"
                                stroke="#14897b"
                                stroke-width="1"
                            />
                            <circle
                                id="Ellipse_1538"
                                data-name="Ellipse 1538"
                                cx="2.747"
                                cy="2.747"
                                r="2.747"
                                transform="translate(148.38 91.32) rotate(-13.282)"
                                fill="#14897b"
                                stroke="#14897b"
                                stroke-width="1"
                            />
                            <path
                                id="Path_6068"
                                data-name="Path 6068"
                                d="M253.433,144.193a.93.93,0,0,0-1.279.3,14.3,14.3,0,0,1-24.329,0,.929.929,0,1,0-1.58.977,16.154,16.154,0,0,0,27.489,0A.929.929,0,0,0,253.433,144.193Z"
                                transform="translate(-82.799 -45.055)"
                                fill="#14897b"
                                stroke="#14897b"
                                stroke-width="1"
                            />
                            <path
                                id="Path_6069"
                                data-name="Path 6069"
                                d="M240.879,111.1A38.961,38.961,0,0,0,204.91,87.8a40.809,40.809,0,0,0-9.687,1.155,40.785,40.785,0,0,0-2.065-5.72,39.412,39.412,0,0,0-71.937,0,40.836,40.836,0,0,0-1.98,5.411,41.046,41.046,0,0,0-8.323-.846,39.412,39.412,0,0,0-29.407,65.651l25.418,28.486-6.02,10.428h20.018l-6.02-10.427,25.418-28.486a42,42,0,0,0,2.88-3.607.929.929,0,1,0-1.514-1.077,40.166,40.166,0,0,1-2.752,3.446L112.628,181.7l5.083,8.8H104.128l5.083-8.8L82.9,152.21A37.554,37.554,0,0,1,110.92,89.653a39.138,39.138,0,0,1,7.853.79,38.883,38.883,0,0,0,9.01,35.139L153.2,154.067l-6.02,10.428H167.2l-6.02-10.427L186.6,125.582a38.883,38.883,0,0,0,9.082-34.828,38.9,38.9,0,0,1,9.232-1.1,37.554,37.554,0,0,1,28.021,62.557L206.619,181.7l5.083,8.8H198.119l5.083-8.8L176.89,152.21a39.988,39.988,0,0,1-3.018-3.824.929.929,0,1,0-1.527,1.059,41.818,41.818,0,0,0,3.158,4l25.418,28.486-6.02,10.428H214.92L208.9,181.933l25.418-28.486a38.961,38.961,0,0,0,6.561-42.351Zm-55.667,13.248L158.9,153.833l5.083,8.8H150.4l5.083-8.8L129.17,124.345a37.554,37.554,0,1,1,56.041,0Z"
                                fill="#1e247e"
                                stroke="#1e247e"
                                stroke-width="1"
                            />
                        </g>
                    </g>
                </svg>

                    <h1 className=" font-Poppins font-bold text-lg mt-2 leading-5 ">
                        Hurray!
                    </h1>
                    <h1>Your reward is being processed</h1></>}
            {/* <button className="bg-[#BFBFBF] w-full text-white rounded font-bold px-6 h-8 md:h-10 mt-4">
                Back to Home
            </button> */}
        </div>
    );
};
export default ThirdStep;
