import React, { useCallback, useEffect, useRef, useState } from 'react';
import BsArrowLeft from '@meronex/icons/bs/BsArrowLeft';
import BsArrowRight from '@meronex/icons/bs/BsArrowRight';
import { useHistory } from 'react-router-dom';
import { isInViewport } from '../../../helpers';
import { scrollToTargetAdjusted } from '../../../utils/ui';
import ComponentsRenderer from '../../ComponentsRenderer';

const HorizontalCarousel = (props) => {
    const { arrows, images, components, duration, imgHeight, imageFit } = props;
    const [currentImage, setCurrentImage] = React.useState(0);
    const [cursorExited, setCursorExited] = useState(true);
    const intervalRef = useRef();
    const history = useHistory();
    const refs = images.reduce((acc, val, i) => {
        acc[i] = React.createRef();
        return acc;
    }, {});

    useEffect(() => {
        if (intervalRef.current) {
            clearTimeout(intervalRef.current);
        }
        if (!cursorExited) {
            return;
        }
        intervalRef.current = setTimeout(() => {
            const carousel = document.getElementById('customCarousel');
            const inViewport = isInViewport(carousel);
            const position =
                currentImage === images.length - 1 ? 0 : currentImage + 1;
            if (inViewport) {
                scrollToImage(position);
            }
            setCurrentImage(position);
        }, duration || 3000);
    }, [currentImage, cursorExited]);

    const scrollToImage = (i) => {
        // console.log('I', i);
        refs[i]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
        });
    };

    const totalImages = images.length;

    const nextImage = () => {
        // console.log('next clicked', currentImage);
        if (currentImage === totalImages - 1) {
            scrollToImage(0);
            setCurrentImage(0);
        } else {
            scrollToImage(currentImage + 1);
            setCurrentImage((prev) => prev + 1);
        }
    };

    const previousImage = () => {
        // console.log('prev clicked', currentImage);
        if (currentImage === 0) {
            scrollToImage(totalImages - 1);
            setCurrentImage(totalImages - 1);
        } else {
            scrollToImage(currentImage - 1);
            setCurrentImage((prev) => prev - 1);
        }
    };

    const arrowStyle =
        'absolute text-white text-2xl z-10  hover:text-primary bg-secondary-focus hover:bg-white border-[2px] border-white rounded-full opacity-75 hover:opacity-100 flex items-center justify-center';

    const sliderControl = (isLeft) => (
        <button
            type="button"
            onClick={isLeft ? previousImage : nextImage}
            className={`${arrowStyle} ${isLeft ? 'left-8' : 'right-8'
                } top-[28%] md:top-[50%] lg:top-[45%]`}
        >
            <span role="img" aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
                {isLeft ? (
                    <BsArrowLeft className="h-6 w-6 m-2 md:h-8 md:w-8 md:m-3" />
                ) : (
                    <BsArrowRight className="h-6 w-6 m-2 md:h-8 md:w-8 md:m-3" />
                )}
            </span>
        </button>
    );

    const navigateToPageHandler = (path, scrollTo) => {
        if (path) {
            history.push(path);
        }
        if (scrollTo) {
            scrollToTargetAdjusted(scrollTo);
        }
    };

    const changePath = (path) => {
        history.push(path);
    };

    const onClickHandler = (type, link, path, onClickFunc, scrollTo) => {
        switch (type) {
            case 'LINK':
                return window.open(link);
            case 'CHANGE_PATH':
                return changePath(path);
            case 'FUNC':
                return onClickFunc(onClickFunc);
            case 'SCROLL':
                return scrollToTargetAdjusted(scrollTo);
            default:
                return;
        }
    }

    return (
        <div id="customCarousel" className=" flex w-full">
            <div className="relative w-full">
                <div className="carousel">
                    {arrows && sliderControl(true)}
                    {images.map((data, i) => {
                        return (
                            <>
                                <div
                                    className={`w-full ${imgHeight ? imgHeight : 'h-[70vh] md:h-[93vh] lg:h-[80vh] xl:h-[70vh] 2xl:h-[80vh]'}  flex-shrink-0`}
                                    key={data.imgUrl}
                                    ref={refs[i]}
                                    onMouseOver={() => {
                                        setCursorExited(false);
                                    }}
                                    onMouseLeave={() => {
                                        setCursorExited(true);
                                    }}
                                >
                                    <div className=" h-full w-full hidden relative lg:block bg-top">
                                        <img
                                            onClick={() => onClickHandler(data.onClick, data?.link, data?.path, data.onClickFunc, data.scrollTo)}
                                            className={`absolute h-full w-full ${imageFit? imageFit : 'object-cover'}  ${data.imgPosition ? data.imgPosition : 'object-top'} inset-0 z-0 bg-top ${data.onClick ? 'cursor-pointer' : 'cursor-auto'}`}
                                            src={data.imgUrl}
                                        />

                                        <ComponentsRenderer
                                            components={
                                                components || data.components
                                            }
                                        />
                                    </div>
                                    {/* mob */}
                                    <div className="  h-full w-full relative block lg:hidden bg-top">
                                        <img
                                             onClick={() => onClickHandler(data.onClick, data?.link, data?.path, data.onClickFunc, data.scrollTo)}
                                            className={`absolute h-full w-full ${imageFit? imageFit : 'object-cover'}  ${data.mobileImgPosition ? data.mobileImgPosition : 'object-top'}  inset-0 z-0 bg-top ${data.onClick ? 'cursor-pointer' : 'cursor-auto'}`}
                                            src={data.mobileImgUrl}
                                        />
                                        <ComponentsRenderer
                                            components={
                                                components || data.components
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        );
                    })}
                    {arrows && sliderControl()}
                </div>
            </div>
        </div>
    );
};

export default HorizontalCarousel;
