import React from 'react';
import { Route } from 'react-router-dom';

import PageNotFound from '../../../Routes/404';

const ConditionalRoute = ({ isVisible, children, ...props }) => {
    console.log(isVisible, "isvisible")
    if (isVisible) {
        return <Route {...props}>{children}</Route>;
    } else {
        return (
            <Route {...props}>
                <PageNotFound />
            </Route>
        );
    }
};

export default ConditionalRoute;
