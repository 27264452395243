import './App.css';
import './Font.css';
import React, { useEffect, useState } from 'react';
import NavBar from './Components/Organisms/NavBar/index';
import Routes from './Routes';
import { Footer, Footer1 } from './Components/Organisms';
import { LoadingSpinner } from './Components/Molecules/LoadingUI';
import {
    CallFloater,
    ChatbotFloater,
    DiwaliOffer,
    ScrollToTop
} from './Components/Atoms/FixedFloater';
import Footer2 from './Components/Organisms/Footer2';
import FooterNavBar from './Components/Organisms/FooterNavBar';
import Footer3 from './Components/Organisms/Footer3';
import Footer4 from './Components/Organisms/Footer4';
import Footer5 from './Components/Organisms/Footer5';
import DropDownNavBar from './Components/Organisms/DropDownNavBar';
import { useAppInit } from './Hooks';
import setAuthToken from './utils/authtoken';
import Modal from './Components/Organisms/Modal';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import WebviewMenu, { WebviewModal } from './Components/Molecules/WebviewMenu';
import FilterOptions from './Components/Molecules/FilterOptions';
import CategoryBar from './Components/Molecules/CategoryBar';
import { scrollToTargetAdjusted } from './utils/ui';
import { useDispatch, useSelector } from 'react-redux';
import Onboarding from './Components/Forms/Onboarding';
import useData from './Components/Forms/Onboarding/data';
import { authActions } from './store/auth-slice';
import AiOutlineCloseSquare from '@meronex/icons/ai/AiOutlineCloseSquare';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginModal from './Components/LoginModal';
import { useIdleTimer } from 'react-idle-timer';
import { deleteWinnreAuthToken } from './utils/axios';
import { getConfig } from './utils/config';
import { Button } from './Components/Atoms';

let appConfig = getConfig();

if (appConfig.autoLogout ? sessionStorage.jwtToken : localStorage.jwtToken) {
    setAuthToken(
        appConfig.autoLogout ? sessionStorage.jwtToken : localStorage.jwtToken
    );
}

function App() {
    const {
        loading,
        config,
        loadingWebviewToken,
        tokenLoading,
        clientWebview,
        userWebview,
        internalWebview,
        hiddenOnWebview,
        preLoginWebview,
        clientAndUserTokenLoading,
        refreshOnNoActivity
    } = useAppInit();
    const [initiateOnboarding, setInitiateOnboarding] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const webViewAccessToken = queryParams.get('token');
    const clientToken = queryParams.get('clientToken');
    const userToken = queryParams.get('userToken');
    const initiateRegistration = queryParams.get('initiateRegistration');
    const hideNavAndFooter =
        !!location?.pathname.includes('/redeem-voucher') ||
        !!location?.pathname.toLowerCase().includes('/bundledetails');

    const {
        unauthorizedUser,
        unauthorizedUserMessage,
        userRole,
        isAuthenticated,
        onboardingModalVisible,
        profileCompletionPercentage,
        finishedFirstTimeLoginGuide,
        isLoginModalVisible,
        showSessionTimeout
    } = useSelector((state) => state.auth);
    const { status } = useSelector((state) => state.auth.userDetails);

    let scrollTo = location?.state?.scrollTo;
  

    useEffect(() => {
        if (scrollTo) {
            scrollToTargetAdjusted(scrollTo);
        }
    }, [scrollTo]);

    useEffect(() => {
        if (initiateRegistration) {
            dispatch(authActions.openLoginModal());
        }
    }, [initiateRegistration]);

    const [showPopUp, setShowPopUp] = useState(false);
    const [openWebviewMenu, setOpenWebviewMenu] = useState(false);
    const isRegisteredCustomer =
        userRole == 'CUSTOMER' && status == 'REGISTERED';

    const logout = () => {
        if (config?.autoLogout) {
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('accessToken');
            // setAuthToken(false);
            deleteWinnreAuthToken();
            window?.webengage?.track('LogOut');
            window?.webengage?.user?.logout();
            history.push('/');
            dispatch(authActions.logout());
        } else {
            if (isAuthenticated) dispatch(authActions.setSessionTimeout(true));
        }
    };

    const { getRemainingTime } = useIdleTimer({
        onIdle: logout,
        timeout: 15 * 60 * 1000 //10 minute idle timeout
    });

    // useEffect(() => {
    //     if (clientToken || webViewAccessToken) {
    //         return;
    //     }
    //     if (window.location.pathname.includes('deals')) {
    //         return;
    //     }
    //     const timer = setTimeout(() => {
    //         setShowPopUp(true);
    //     }, 1000);

    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, []);
    // const [finished, setFinished] = useState(false)

    useEffect(() => {
        if (clientToken || webViewAccessToken) {
            return;
        }
        if (
            !finishedFirstTimeLoginGuide &&
            isAuthenticated &&
            isRegisteredCustomer
        ) {
            // console.log('OPEN MODAL')
            dispatch(authActions.openOnboardingModal());
        }
    }, [finishedFirstTimeLoginGuide, isAuthenticated]);

    const changePathAndScroll = (path, scrollTo) => {
        history.push({
            pathname: path,
            state: { scrollTo: scrollTo }
        });
    };
    const smallCategories = [
        {
            id: 0,
            name: 'Home',
            logo: {
                url: `https://cdn.loyalie.in/1691524387246_20671202.png`
            }
        },
        {
            id: 1,
            name: 'Refer Now',
            logo: {
                url: 'https://cdn.loyalie.in/62332939.png'
            }
        },
        // {
        //     id: 2,
        //     name: 'Projects',
        //     logo: {
        //         url: 'https://cdn.loyalie.in/1691524457975_74943494.png'
        //     }
        // },
        {
            id: 3,
            name: 'Contact Us',
            logo: {
                url: 'https://cdn.loyalie.in/1691524657952_50456750.png'
            }
        }
    ];
    const allCategories = [
        {
            id: 0,
            name: 'Referrals',
            logo: {
                url: `${
                    config?.webview?.customIcon
                        ? config?.webview?.customIcon?.refer
                        : config?.webview?.coloredTheme
                        ? 'https://cdn.loyalie.in/56756890.png'
                        : 'https://cdn.loyalie.in/62332939.png'
                }`
            }
        },
        {
            id: 1,
            name: 'Offers',
            logo: {
                url: `${
                    config?.webview?.customIcon
                        ? config?.webview?.customIcon?.offer
                        : config?.webview?.coloredTheme
                        ? 'https://cdn.loyalie.in/80572819.png'
                        : ' https://cdn.loyalie.in/38886458.png'
                }`
            }
        },
        {
            id: 2,
            name: 'Blogs',
            logo: {
                url: `${
                    config?.webview?.customIcon
                        ? config?.webview?.customIcon?.blogs
                        : config?.webview?.coloredTheme
                        ? 'https://cdn.loyalie.in/25428662.png'
                        : 'https://cdn.loyalie.in/60309056.png'
                }`
            }
        },
        {
            id: 3,
            name: 'More',
            logo: {
                url: `${
                    config?.webview?.customIcon
                        ? config?.webview?.customIcon?.more
                        : 'https://cdn.loyalie.in/78765839.png'
                } `
            }
        }
        // {
        //     id: 3,
        //     name: 'Events',
        //     logo: {
        //         url: 'https://cdn.loyalie.in/89877577.png'
        //     }
        // },
        // {
        //     id: 4,
        //     name: 'Track Referrals',
        //     logo: {
        //         url: 'https://cdn.loyalie.in/30936449.png'
        //     }
        // }
    ];

    // useEffect(() => {
    //     window?.webengage?.notification?.options({
    //         forcedRender: true
    //     });
    // }, []);

    const onFilterClick = (categoryId, categoryName) => {
        switch (categoryName) {
            case 'Home':
                history.push('/');
                return;
            case 'Contact Us':
                history.push('/contact-us');
                return;
            case 'Refer Now':
            case 'Refer & Earn':
            case 'Referrals':
                history.push('/gift-and-earn');
                return;
            case 'Track Referrals':
                changePathAndScroll('/gift-and-earn', 'pastReferrals');
                return;
            case 'More':
                setOpenWebviewMenu((prev) => !prev);
                return;
            case 'Offers':
                history.push('/deals');
                return;
            case 'Events':
                history.push('/events');
                return;
            case 'Blogs':
                history.push('/blogs');
                return;
            case 'Projects':
                history.push('/projects');
                return;
            default:
                history.push('/');
                return;
        }
    };
    if (unauthorizedUser && !loading) {
        return (
            <div className="h-screen flex items-center justify-center text-center">
                <p>{unauthorizedUserMessage}</p>
            </div>
        );
    }
    const blogPageHandler = () => {
        setShowPopUp(false);
        switch (config?.name) {
            case 'Arvind':
                return history.push('/blogs/303');
            case 'Embassy':
                return history.push('/blogs/302');
            case 'JP Infra':
                return history.push('/blogs/295');
            case 'Kohinoor':
                return history.push('/blogs/294');
            case 'M3M':
                return history.push('/blogs/305');
            case 'Evos':
                return history.push('/blogs/304');
            default:
                return history.push('/blogs');
        }
    };

    return (
        <>
            {showSessionTimeout && (
                <Modal visible>
                    <div className="flex flex-col justify-center items-center">
                        <div className="text-center text-lg py-2 mb-3">
                            {
                                'Hey, are you still there?\nYour Session is about to expire!'
                            }
                        </div>
                        <Button
                            onClickFunc={() => refreshOnNoActivity()}
                            content="Click Here to Continue"
                            onClick="FUNC"
                        ></Button>
                    </div>
                </Modal>
            )}
            {(loading ||
                tokenLoading ||
                loadingWebviewToken ||
                clientAndUserTokenLoading) && (
                <div className="h-[100vh]">
                    <LoadingSpinner
                        text={
                            'Hold on while we elevate your homeowning experience…'
                        }
                        color="text-info"
                        size={16}
                    />
                </div>
            )}

            {!loading &&
                !tokenLoading &&
                !unauthorizedUser &&
                !loadingWebviewToken &&
                !clientAndUserTokenLoading && (
                    <div className="App relative">
                        {/* NavBar */}
                        {!hiddenOnWebview &&
                            !hideNavAndFooter &&
                            (!window.reloy_env.client_config
                                .NAVIGATION_DROPDOWN ||
                                Object.keys(
                                    window.reloy_env.client_config
                                        .NAVIGATION_DROPDOWN
                                )?.length === 0) && (
                                <NavBar navBarConfig={config.routes} />
                            )}
                        {!hiddenOnWebview &&
                            !hideNavAndFooter &&
                            Object.keys(
                                window.reloy_env.client_config
                                    .NAVIGATION_DROPDOWN || {}
                            )?.length > 0 && (
                                <DropDownNavBar navBarConfig={config.routes} />
                            )}
                        {clientWebview &&
                            !unauthorizedUser &&
                            !hideNavAndFooter && (
                                <div className="sticky bg-white top-0 z-30">
                                    <CategoryBar
                                        noScroll
                                        showArrowIndicator={false}
                                        categories={
                                            config?.webview?.smallCategories
                                                ? smallCategories
                                                : allCategories
                                        }
                                        onFilterClick={onFilterClick}
                                        scrollable={false}
                                        forClient
                                    />
                                </div>
                            )}

                        {/* Main */}
                        <Routes routesConfig={config} />
                        <ToastContainer position="top-right" />

                        {/* Footer */}
                        {config?.showFooterRoutes &&
                            !hiddenOnWebview &&
                            !hideNavAndFooter && (
                                <FooterNavBar config={config.footerRoutes} />
                            )}
                        {config.footer?.footerLayout === 'Footer' &&
                            !hiddenOnWebview &&
                            !hideNavAndFooter && <Footer />}
                        {config.footer?.footerLayout === 'Footer1' &&
                            !hiddenOnWebview &&
                            !hideNavAndFooter && <Footer1 />}
                        {config.footer?.footerLayout === 'Footer2' &&
                            !hiddenOnWebview &&
                            !hideNavAndFooter && <Footer2 />}
                        {config.footer?.footerLayout === 'Footer3' &&
                            !hiddenOnWebview &&
                            !hideNavAndFooter && <Footer3 />}
                        {config.footer?.footerLayout === 'Footer4' &&
                            !hiddenOnWebview &&
                            !hideNavAndFooter && <Footer4 />}
                        {config.footer?.footerLayout === 'Footer5' &&
                            !hiddenOnWebview &&
                            !hideNavAndFooter && <Footer5 />}

                        {!hiddenOnWebview &&
                            window.reloy_env.client_config.CONTACT_DETAILS
                                ?.FLOATING_BUTTON &&
                            window.reloy_env.client_config.CONTACT_DETAILS
                                ?.FLOATER_TYPE === 'CALL' &&
                            !hideNavAndFooter && <CallFloater />}
                        {!hiddenOnWebview &&
                            window.reloy_env.client_config.CONTACT_DETAILS
                                ?.FLOATING_BUTTON &&
                            window.reloy_env.client_config.CONTACT_DETAILS
                                ?.FLOATER_TYPE === 'CHATBOT' &&
                            !hideNavAndFooter && <ChatbotFloater />}
                        {window.reloy_env.client_config.CONTACT_DETAILS
                            ?.FLOATING_BUTTON &&
                            (!window.reloy_env.client_config.CONTACT_DETAILS
                                ?.FLOATER_TYPE ||
                                window.reloy_env.client_config.CONTACT_DETAILS
                                    ?.FLOATER_TYPE === 'SCROLL_TOP') &&
                            !hideNavAndFooter && <ScrollToTop />}
                        {window.reloy_env.client_config.CONTACT_DETAILS
                            ?.FLOATING_BUTTON &&
                            (!window.reloy_env.client_config.CONTACT_DETAILS
                                ?.FLOATER_TYPE ||
                                window.reloy_env.client_config.CONTACT_DETAILS
                                    ?.FLOATER_TYPE === 'REFERRAL') &&
                            !hideNavAndFooter && <DiwaliOffer />}
                    </div>
                )}
            {/* {clientWebview && !unauthorizedUser && (
                <div
                    onClick={() => setOpenWebviewMenu((prev) => !prev)}
                    className="fixed flex items-center right-0 top-2 h-12 w-12 z-40  mx-auto"
                >
                    <FiMenu className="text-black h-full text-center w-full p-1" />
                </div>
            )} */}
            {isLoginModalVisible && !internalWebview && !preLoginWebview && (
                <LoginModal />
            )}

            {openWebviewMenu && clientWebview && !unauthorizedUser && (
                <WebviewModal
                    visible={openWebviewMenu}
                    onClick={() => {
                        setOpenWebviewMenu(false);
                    }}
                >
                    <WebviewMenu
                        onClick={() => {
                            setOpenWebviewMenu(false);
                        }}
                    />
                </WebviewModal>
            )}
            {window.reloy_env.client_config.MY_PROFILE?.ALLOW_ONBOARDING &&
                isAuthenticated &&
                onboardingModalVisible && <Onboarding />}
        </>
    );
}

export default App;
