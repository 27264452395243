import React from 'react';

function PhotoSection(props) {
    const { imgArr = [], photosection, imgClassName, className, id } = props;
    return (
        <div id={id || ''} className={className || ' '}>
            {imgArr.map((i, index) => (
                <div
                    className={
                        photosection ||
                        '2xl:h-full 2xl:w-full flex flex-col justify-center'
                    }
                    key={index}
                >
                    <a href={i.link} className=" text-center" target="_blank">
                        <img className={imgClassName || ' '} src={i.imgSrc} />
                        <h1 className="text-center text-[#373737] font-Helvetica_Medium m-auto self-center">
                            {i.text}
                        </h1>
                    </a>
                </div>
            ))}
        </div>
    );
}

export default PhotoSection;
