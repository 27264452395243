import React, { useEffect } from 'react';
import useData from './data';
import { getInitials } from '../../../helpers';
import { LoadingSpinner } from '../../../Components/Molecules/LoadingUI';
import moment from 'moment';
const dummy = [
    { name: 'Dummy 1', status: 'REJECTED', projectName:'test project' },
    { name: 'Dummy 2', status: 'REJECTED',projectName:'test project' },
    { name: 'Dummy 11', status: 'LOST',projectName:'test project' },
    { name: 'Dummy 16', status: 'INVOICE_PAID',projectName:'test project' },
    { name: 'Dummy 17', status: 'INVOICE_PAID',projectName:'test project' },
    { name: 'Dummy 18', status: 'INVOICE_PAID',projectName:'test project' },

    { name: 'Dummy 3', status: 'REGISTRATION_DONE',projectName:'test project' },
    { name: 'Dummy 4', status: 'SITE_VISIT_DONE',projectName:'test project' },
    { name: 'Dummy 5', status: 'BOOKING_DONE',projectName:'test project' },
    { name: 'Dummy 12', status: 'LOST',projectName:'test project' },

    { name: 'Dummy 6', status: 'REGISTRATION_DONE',projectName:'test project' },
    { name: 'Dummy 7', status: 'SITE_VISIT_DONE' },
    { name: 'Dummy 8', status: 'BOOKING_DONE' },
    { name: 'Dummy 9', status: 'REGISTRATION_DONE' },
    { name: 'Dummy 10', status: 'LOST' }
];
function checkValue(status, name, mobileNumber, date) {
    let stat =
        status == 'BOOKING_DONE'
            ? 2
            : status == 'REGISTRATION_DONE'
                ? 3
                : status == 'SITE_VISIT_DONE'
                    ? 1
                    : status == 'LOST'
                        ? 5
                        : status == 'INVOICE_PAID'
                            ? 4
                            : 0;

    return (
        <div className={`${stat ==5? 'w-full bg-[#CE1D1D1A] ' : stat ==4 ? 'w-full bg-[#14897B1A]' : ' w-full border-2 border-[#F4F4F8]' } rounded-md px-2 xl:pl-5 xl:pr-9 py-4`}>
            <div className='flex items-center'>
                <div className={`p-1 h-10 w-10 rounded-lg ${stat ==5? 'bg-[#CE1D1D] text-white' : stat ==4? 'bg-[#14897B] text-white' :'bg-primary text-primary'} bg-gray-200 flex items-center justify-center uppercase font-bold  text-lg`}>{getInitials(name, true)}</div>
                <div className=" ml-2 lg:ml-4">
                    <h1 className=" leading-5 font-bold text-[#130E2F] capitalize">{name}</h1>
                    <p className="text-xs md:text-xs text-[#B7B4CE]">+91 {mobileNumber}</p>
                </div>
                <div className='justify-end ml-auto self-start'>
                    <p className=' text-[13px] text-[#B7B4CE] italic'>Referred on</p>
                    <p className='text-[13px] font-semibold italic'>{moment(date).format('DD MMM, YY')}</p>

                </div>
            </div>
            <div className=' flex mt-1 mr-1 xl:mr-3'>
                {stat ==5 && <p className='text-[#CE1D1D] py-2 font-medium text-[10px] md:text-[12px]'>Lost</p>}
                {stat < 5 && <div className=' font-medium  flex text-[10px] sm:text-[11px] md:text-[12px] items-center gap-x-1 sm:gap-x-2 md:gap-x-2 lg:gap-x-1 xl:gap-x-3 py-2'>
                    <p id="ENQUIRY" className={stat == 4? 'text-[#14897B] italic' :`text-primary italic`}>Submitted</p>
                    <hr className={`h-[2px] w-2 lg:w-2 xl:w-5 rounded ${stat == 4? 'bg-[#14897B]' :stat >= 1 && stat < 5
                        ? 'bg-primary'
                        : 'bg-[#B7B4CE]'
                        }`} />
                    <p className={`${stat == 4? 'text-[#14897B]' : stat >= 1 && stat < 5
                        ? 'text-primary'
                        : 'text-[#B7B4CE] italic'
                        } `}>Visited</p>
                    <hr className={`h-[2px] w-2 lg:w-2 xl:w-5 rounded ${stat == 4? 'bg-[#14897B]' :stat >= 2 && stat < 5
                        ? 'bg-primary'
                        : 'bg-[#B7B4CE]'
                        }`} />

                    <p className={`${stat == 4? 'text-[#14897B]' : stat >= 2 && stat < 5
                        ? 'text-primary'
                        : 'text-[#B7B4CE] italic'
                        } `}>Booked</p>
                    <hr className={`h-[2px] w-2  lg:w-2 xl:w-5 rounded ${stat == 4? 'bg-[#14897B]' :stat >= 3 && stat < 5
                        ? 'bg-primary'
                        : 'bg-[#B7B4CE]'
                        }`} />

                    <p className={`${stat == 4? 'text-[#14897B]' : stat >= 3 && stat < 5
                        ? 'text-primary'
                        : 'text-[#B7B4CE] italic'
                        } `}>Registered</p>
                    <hr className={`h-[2px] w-2 lg:w-2 xl:w-5 rounded ${stat == 4
                        ? 'bg-[#14897B]'
                        : 'bg-[#B7B4CE]'
                        }`} />

                    <p id="INVOICE_PAID" className={`${stat == 4
                        ? 'text-[#14897B]'
                        : 'text-[#B7B4CE] italic'
                        } `}>Rewarded</p>
                </div>}
            </div>
        </div>
    );
}
function populateList(leadDetails) {
    return (
        <div className=" max-h-[57vh] md:max-h-[45vh] 2xl:max-h-[60vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-400 ">
            <div className={` flex flex-wrap  md:grid md:grid-cols-2 gap-x-4 xl:gap-x-6  gap-y-3`}>

                {leadDetails?.map((leads) =>
                    checkValue(
                        leads.status,
                        leads.name,
                        leads.mobileNumber, 
                        leads?.createdOn
                    )
                )}

            </div>

         </div>
    );
}
function PastReferrals({ active }) {
    const {
        displayLeads,
        loadingLeads,
        changeStatus
    } = useData();

    useEffect(() => { changeStatus(active) }, [active])

    return (
        <div>
            {loadingLeads && <div>
                <p className='text-center'>Loading past referrals</p>
                <LoadingSpinner /></div>}

            {!loadingLeads &&( displayLeads.length > 0 ? populateList(displayLeads) : active == 'all' ?  <p> </p> : <p>No referrals have reached this stage</p>)}

        </div>
    );
}

export default PastReferrals;
