import { getFont } from '../../helpers';
import centuryImage from '../../assets/ReferralForm/Century.jpg';
import { openURLNewWindow } from '../../helpers';
import store from '../../store';
const centuryConfig = {
    clientId:
        '73a80a393c511787ed36c7e96e90d8f7780eb7e32a4aeecadddd292028aa33bb',
    id: 13,
    name: 'Century',
    theme: 'centuryTheme',
    darktheme: true,
    favicon: 'https://cdn.loyalie.in/74164776.png',
    primaryColor: '#64412B',

    googleConfig: {
        gID: 'G-ZBL5WVGZ9W',
        keywordsContent:
            'century real estate referral program, century real estate referral programs, century real estate refer and earn, century real estate project refer, century real estate, century real estate loyalty program, century real estate news, century real estate upcoming projects in Kolkata, century real estate launch century real estate, century real estate launch, century real estate program, century real estate projects, century real estate blogs, century real estate offers, century real estate upcoming projects in Kolkata, century real estate events, century real estate Pvt Ltd, century real estate best residential projects, century real estate real estate developers, All New Projects by century real estate Builders & Developers, Ongoing Projects by century real estate Pvt Ltd, century real estate madhyamgram, century real estate new program,',
        descriptionContent:
            'At Century Circle , you come above everything else. The Century Circle referral program is designed to enrich your experience through events, offers, and blogs',
        authorContent: 'Century Circle'
    },

    facebookPixelConfig: {
        id: '1235660637190794',
        metaName: '',
        metaContent: ''
    },

    uatFacebookPixelConfig: {
        id: '748971079458784',
        metaName: '',
        metaContent: ''
    },
    layouts: {
        events: 'Layout2',
        blogs: 'Layout1',
        
    },
    logo: {
        name: 'centuryLogo',
        url: 'https://cdn.loyalie.in/45645227.png',
        stickyNavBarLogoClass:
            'relative w-28 lg:w-[138px] xl:w-36 ml-4 lg:ml-7 3xl:ml-10', //to match godrej nav bar
        baseNavBarLogoClass:
            'relative w-28 lg:w-[138px] xl:w-36 left-4 lg:left-7 3xl:left-10 '
    },
    navBarConfig: {
        showLogin: true,
        font: `font-CorporateS_Demi`,
        height: 'h-20 lg:h-24 ',
        mobileIconColor: 'text-secondary',
        baseNavBar: {
            border: true,
            borderClass: 'border-b-[1px] border-secondary-focus',
            backgroundColor: 'bg-primary-content', // default: transparent
            containerClass: 'lg:mr-4 xl:mr-2 3xl:mr-10 ',
            itemClass: `uppercase text-black hover:text-secondary-focus text-[15px] md:text-[14px] lg:text-[13px] lg:mr-7 xl:text-[14px]  xl:mr-7 3xl:text-lg 3xl:mr-5 ${
                getFont('CENTURY').navbar
            }` ///
        },
        stickyNavBar: {
            border: true,
            borderClass: 'border-b-[1px] border-secondary-focus',
            backgroundColor: '', //default: primary-content,
            containerClass: 'lg:mr-4 xl:mr-2 3xl:mr-10', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass: `uppercase text-black  hover:text-secondary-focus text-[15px] md:text-[14px] lg:text-[13px] lg:mr-7 xl:text-[14px] xl:mr-7 3xl:text-lg 3xl:mr-5 ${
                getFont('CENTURY').navbar ///
            } ` // Each list element in NavBar (Text color, hover color, size)
        },
        showActiveLinkColor: false
        // footerNavItemClass: ''
    },

    floatingButton: true,
    floaterType: 'CALL',
    mobile: '080 - 44334441',
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 80,

    clientButtonClassName:
        'z-10 bg-accent rounded-sm text-[16px] py-[5.5px] px-10 self-center font-CorporateS_Demi text-[#64412b]',

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-secondary-focus',
        text: 'text-secondary-focus',
        buttonBg: 'bg-secondary-focus'
    },
    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: false,
    routes: {
        home: {
            name: 'HOME',
            path: '/',
            show: true,
            components: [
                // {
                //     type: 'SLIDER_BANNER',
                //     linearGradient: {
                //         R: '100',
                //         G: '65',
                //         B: '43',
                //         opacity: '0.7'
                //     },
                //     slides: [
                //         {
                //             id: 0,
                //             text: '',
                //             linearGradient: {
                //                 R: '100',
                //                 G: '65',
                //                 B: '43',
                //                 opacity: '0.7'
                //             },
                //             displayImage: {
                //                 url: 'https://cdn.loyalie.in/14750662.jpg',
                //                 mobileUrl:
                //                     'https://cdn.loyalie.in/48439452.jpg'
                //             },
                //             components: [
                //                 {
                //                     type: 'IMAGE',
                //                     url: 'https://cdn.loyalie.in/78479901.png',

                //                     position: 'center',
                //                     className:
                //                         ' w-[50%] md:w-[40%] lg:w-[30%] xl:w-[28%] 2xl:w-[26%] py-2 md:py-4 mt-0 lg:py-2 lg:px-4'
                //                 },
                //                 {
                //                     type: 'TEXT',
                //                     content: 'MOVE INTO THE CIRCLE OF TRUST',
                //                     className: `text-[#E6C168] px-2 leading-5 text-center md:mt-2 xl:mt-2 2xl:mt-2 text-[21px]  md:text-[45px] lg:text-[36px] xl:text-[40px] 3xl:text-[56px]  break-words ${
                //                         getFont('CENTURY').heading
                //                     }`
                //                 },
                //                 // {
                //                 //     type: 'TEXT',
                //                 //     content:
                //                 //         'An Initiative by Century Real Estate and Reloy',

                //                 //     className: `font-medium text-center text-neutral md:pt-4 2xl:pt-4 text-[16px] px-2 md:text-[27.5px] md:px-6 lg:text-[21.5px] 3xl:text-[30px] mb-5 lg:mb-6 xl:mb-5 break-words ${
                //                 //         getFont('CENTURY').body
                //                 //     }`
                //                 // },
                //                 {
                //                     type: 'BUTTON',
                //                     content: 'REFER NOW',
                //                     className: `bg-accent rounded-sm text-[16px] py-1 md:py-[5.5px] px-5 md:px-10 self-center font-CorporateS_Demi text-[#64412b] mt-5 md:mt-7 lg:mt-6 xl:mt-9 2xl:mt-9`,
                //                     path: 'refer',
                //                     scrollTo: 'referral_section',
                //                     onClick: 'CHANGE_PATH_AND_SCROLL'
                //                 }
                //             ]
                //         }
                //         // {
                //         //     id: 1,

                //         //     text: '',
                //         //     displayImage: {
                //         //         url: 'https://cdn.loyalie.in/5564546.jpg',
                //         //         mobileUrl:
                //         //             'https://cdn.loyalie.in/69475264.jpg'
                //         //     }
                //         // },
                //         // {
                //         //     id: 2,

                //         //     text: '',
                //         //     displayImage: {
                //         //         url: 'https://cdn.loyalie.in/85993605.jpg',
                //         //         mobileUrl:
                //         //             'https://cdn.loyalie.in/5355469.jpg'
                //         //     }
                //         // }
                //     ]
                // },
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '100',
                        G: '65',
                        B: '43',
                        opacity: '0.7'
                    },
                    position: 'text-center',
                    backgroundImage: 'https://cdn.loyalie.in/14750662.jpg',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/48439452.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[60vh] md:h-[85vh] lg:h-[85vh] object-cover bg-top  md:bg-center lg:bg-bottom xl:bg-center',
                    components: [
                        {
                            type: 'IMAGE',
                            url: 'https://cdn.loyalie.in/78479901.png',

                            position: 'center',
                            className:
                                ' w-[50%] md:w-[40%] lg:w-[30%] xl:w-[28%] 2xl:w-[32%] py-2 md:py-4 mt-0 lg:py-2 lg:px-4'
                        },
                        {
                            type: 'TEXT',
                            content: 'MOVE INTO THE CIRCLE OF TRUST',
                            className: `text-[#E6C168] px-2 leading-5 text-center md:mt-2 xl:mt-2 2xl:mt-2 text-[21px]  md:text-[45px] lg:text-[36px] xl:text-[40px] 3xl:text-[56px]  break-words ${
                                getFont('CENTURY').heading
                            }`
                        },
                        // {
                        //     type: 'TEXT',
                        //     content:
                        //         'An Initiative by Century Real Estate and Reloy',

                        //     className: `font-medium text-center text-neutral md:pt-4 2xl:pt-4 text-[16px] px-2 md:text-[27.5px] md:px-6 lg:text-[21.5px] 3xl:text-[30px] mb-5 lg:mb-6 xl:mb-5 break-words ${
                        //         getFont('CENTURY').body
                        //     }`
                        // },
                        {
                            type: 'BUTTON',
                            content: 'REFER NOW',
                            className: `bg-accent rounded-sm text-[16px] py-1 md:py-[5.5px] px-5 md:px-10 self-center font-CorporateS_Demi text-[#64412b] mt-5 md:mt-7 lg:mt-6 xl:mt-9 2xl:mt-9`,
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH_AND_SCROLL',
                            scrollTo: 'referral_section'
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: 'overview',
                    className:
                        'relative flex flex-col justify-center text-center pt-10 ',
                    url: 'https://cdn.loyalie.in/17509686.png',
                    overlayClass: 'bg-neutral opacity-[.97]',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0',
                        size: '30% auto'
                    },
                    components: [
                        {
                            type: 'TEXT',
                            content: 'ABOUT CENTURY CIRCLE',
                            className: `z-10 tracking-wide font-semibold text-[25px] md:text-[30px] lg:text-[33px] text-secondary-focus ${
                                getFont('CENTURY').heading
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'Where YOU are at the centre',
                            className: `z-10 tracking-wide text-[20px] md:text-[19px] lg:text-[20px] italic text-secondary-focus ${
                                getFont('CENTURY').body
                            }`
                        },
                        // {
                        //     type: 'CUSTOM',
                        //     className:
                        //         'relative flex justify-center w-[90%] xl:w-full mx-auto',
                        //     url: 'https://cdn.loyalie.in/17509686.png',
                        //     overlayClass: 'bg-neutral opacity-[.97]',
                        //     linearGradient: {
                        //         R: '255',
                        //         G: '255',
                        //         B: '255',
                        //         opacity: '0',
                        //         size: '30% auto'
                        //     },
                        //     components: [
                        //         {
                        //             type: 'TEXT',
                        //             content: 'Where',
                        //             className: `${
                        //                 getFont('CENTURY').body
                        //             } z-10 inline-block tracking-wide text-[20px] md:text-[19px] lg:text-[20px] italic text-secondary-focus `
                        //         },
                        //         {
                        //             type: 'TEXT',

                        //             content: ' YOU',
                        //             className: `z-10 inline-block ml-1  tracking-wide text-[20px] md:text-[19px] lg:text-[20px] text-secondary-focus  ${
                        //                 getFont('CENTURY').body
                        //             }`
                        //         },
                        //         {
                        //             type: 'TEXT',

                        //             content: ' are at the centre',
                        //             className: `z-10 inline-block ml-1 tracking-wide text-[20px] md:text-[19px] lg:text-[20px] italic text-secondary-focus  ${
                        //                 getFont('CENTURY').body
                        //             } `
                        //         }
                        //     ]
                        // },
                        {
                            type: 'DIVIDER',
                            className:
                                'z-10 self-center m-auto w-[20%] lg:w-[14%] xl:w-[12%] bg-secondary-focus border-none h-[2px]',
                            classNameContainer: 'mt-3'
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Century Real Estate values each of its residents as an indispensable part of its larger family.',
                            className: `z-10 mt-[20px] leading-6 mb-1 xl:mb-3 w-[92%] text-secondary-focus font-thin text-[18px] md:text-[19px] lg:text-[18px] mx-auto xl:px-16 xl:mt-5 ${
                                getFont('CENTURY').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Century Circle is an initiative to establish this value through action, and give you the opportunity to build communities you want to be a part of. As a loyalty and referral program, it puts your life at its core and constantly evolves to create experiences worth sharing.',
                            className: `z-10 mt-[10px] leading-6 mb-[14px] w-full w-[92%] xl:w-[77
                                %] text-secondary-focus text-[18px] md:text-[19px] lg:text-[18px] mx-auto lg:px-12 xl:px-16 2xl:px-16 xl:mt-0 3xl:px-32 ${
                                    getFont('CENTURY').body
                                }`
                        },
                        {
                            type: 'TEXT',
                            content: 'Century Circle Encompasses:',
                            className: `z-10  uppercase pb-4 pt-8 2xl:pt-7 font-semibold text-secondary-focus text-[20px] px-2 md:text-[22px] ${
                                getFont('CENTURY').heading
                            }`
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: 'blog',
                    className:
                        'relative grid lg:h-[90vh] sm:grid-cols-1 lg:grid-cols-2 bg-secondary-focus md:grid-cols-reverse',
                    linearGradient: {
                        R: '100',
                        G: '65',
                        B: '43',
                        opacity: '0.7'
                    },
                    components: [
                        {
                            type: 'CHANGING_IMAGE',
                            position: 'center',
                            imageArr: [
                                {
                                    url: 'https://cdn.loyalie.in/77079779.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/8235255.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/58879223.jpg'
                                }
                            ],
                            className: 'h-full w-full object-fill ',
                            classNameContainer:
                                'w-full h-full  order-first lg:order-none',
                            swipeable: false,
                            draggable: false,
                            showDots: false,
                            arrows: false,
                            infinite: true,
                            autoPlay: true,
                            autoPlaySpeed: 3000,
                            transitionDuration: 3000,
                            customTransition: 'animate-fadeInAnimation'
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'lg:pl-6 3xl:pl-20 lg:pr-7 xl:pr-20 m-auto h-auto mb-4 lg:m-auto text-left text-primary py-5 md:py-10 lg:py-0    ',
                            linearGradient: {
                                R: '100',
                                G: '65',
                                B: '43',
                                opacity: '0.7'
                            },
                            components: [
                                {
                                    type: 'TEXT',
                                    onClick: 'CHANGE_PATH',
                                    path: 'refer',
                                    content: 'Referrals and Earnings',
                                    className: ` cursor-pointer tracking-wide w-full xl:w-full font-semibold text-[27px] xl:text-[37px] uppercase text-secondary px-7 py-4 lg:px-7 lg:py-0 ${
                                        getFont('CENTURY').heading
                                    }`
                                },
                                {
                                    type: 'DIVIDER',

                                    className:
                                        'mx-7 lg:mx-7 w-[25%] md:w-[16%] lg:w-[20%] xl:w-[19%] 2xl:w-[16%] bg-secondary border-none h-[2px]',
                                    classNameContainer: 'mt-2'
                                },

                                {
                                    type: 'TEXT',
                                    content:
                                        'Century Circle is committed to building wholesome communities that care for one another. As a member of the loyalty program, you will have the opportunity to refer your friends and family and earn anywhere between ₹50,000 – ₹7,00,000.',

                                    className: `text-[18px] md:text-[19px] leading-6 lg:text-[18px] px-7 py-4 lg:px-7 lg:pt-5 3xl:pr-32 text-neutral ${
                                        getFont('CENTURY').secondary
                                    }`
                                },
                                // {
                                //     type: 'TEXT',
                                //     content:
                                //         'You get the following rewards for successfully referring with us:',

                                //     className: `text-[18px] md:text-[19px] leading-6 lg:text-[18px] xl:text-[18px] px-7 py-2 lg:px-7 3xl:pr-32 text-neutral  ${
                                //         getFont('CENTURY').secondary
                                //     }`
                                // },
                                // {
                                //     type: 'CUSTOM',
                                //     className: 'flex ',
                                //     components: [
                                //         {
                                //             type: 'CUSTOM',
                                //             className: '',
                                //             components: [
                                //                 {
                                //                     type: 'TEXT',

                                //                     content: '~ ',

                                //                     className: `pt-1 text-[18px] md:text-[19px] leading-6 lg:text-[18px] xl:text-[18px] pl-7  lg:pl-7 text-neutral ${
                                //                         getFont('CENTURY')
                                //                             .secondary
                                //                     }`
                                //                 }
                                //             ]
                                //         },
                                //         {
                                //             type: 'CUSTOM',
                                //             className: '',

                                //             components: [
                                //                 {
                                //                     type: 'TEXT',
                                //                     content:
                                //                         ' Reward you receive for recommending a friend to us',

                                //                     className: `ml-1 text-[18px] md:text-[19px] leading-6 lg:text-[18px] xl:text-[18px] pr-7 3xl:pr-32 text-neutral ${
                                //                         getFont('CENTURY')
                                //                             .secondary
                                //                     }`
                                //                 }
                                //             ]
                                //         }
                                //     ]
                                // },
                                // {
                                //     type: 'CUSTOM',
                                //     className: 'flex ',
                                //     components: [
                                //         {
                                //             type: 'CUSTOM',
                                //             className: '',
                                //             components: [
                                //                 {
                                //                     type: 'TEXT',

                                //                     content: '~ ',

                                //                     className: `pt-1 text-[18px] md:text-[19px] leading-6 lg:text-[18px] xl:text-[18px] pl-7  lg:pl-7 text-neutral ${
                                //                         getFont('CENTURY')
                                //                             .secondary
                                //                     }`
                                //                 }
                                //             ]
                                //         },
                                //         {
                                //             type: 'CUSTOM',
                                //             className: '',

                                //             components: [
                                //                 {
                                //                     type: 'TEXT',
                                //                     content:
                                //                         ' Reward your friend receives when they buy a home or plot with Century Real Estate',

                                //                     className: `ml-1 text-[18px] md:text-[19px] leading-6 lg:text-[18px] xl:text-[18px] pr-7 3xl:pr-32 text-neutral ${
                                //                         getFont('CENTURY')
                                //                             .secondary
                                //                     }`
                                //                 }
                                //             ]
                                //         }
                                //     ]
                                // },
                                // {
                                //     type: 'TEXT',
                                //     content:
                                //         '~ Reward you receive for successfully referring a friend',

                                //     className: ` text-[18px] md:text-[17px] leading-6 lg:text-[16px] xl:text-[18px] px-7  lg:px-7  3xl:pr-32 text-neutral ${
                                //         getFont('CENTURY').secondary
                                //     }`
                                // },
                                // {
                                //     type: 'TEXT',
                                //     content:
                                //         '~ Reward your friend receives when they buy a home or plot with Century Real Estate',

                                //     className: `md:hidden text-[18px] md:text-[17px] leading-6 lg:text-[16px] xl:text-[18px] px-7 3xl:pr-32 lg:px-7 text-neutral ${
                                //         getFont('CENTURY').secondary
                                //     }`
                                // },
                                // {
                                //     type: 'TEXT',
                                //     content:
                                //         '~ Reward your friend receives when they buy a home or plot',

                                //     className: `hidden md:block  text-[18px] md:text-[17px] leading-6 lg:text-[16px] xl:text-[18px] px-7 3xl:pr-32 lg:px-7 text-neutral ${
                                //         getFont('CENTURY').secondary
                                //     }`
                                // },
                                // {
                                //     type: 'TEXT',
                                //     content: '  with Century Real Estate',

                                //     className: `hidden md:block md:ml-4 xl:ml-[18px] text-[18px] md:text-[17px] leading-6 lg:text-[16px] xl:text-[18px] px-7 3xl:pr-32 lg:px-7 text-neutral ${
                                //         getFont('CENTURY').secondary
                                //     }`
                                // },
                                {
                                    type: 'TEXT',
                                    content:
                                        'With us, every homeowner is at the center.',

                                    className: `text-[18px] md:text-[19px] leading-6 lg:text-[18px] xl:text-[18px] px-7 pt-5 3xl:pr-32  lg:px-7 text-neutral ${
                                        getFont('CENTURY').secondary
                                    }`
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'blog',
                    className:
                        'relative grid lg:h-[90vh] sm:grid-cols-1 lg:grid-cols-2 bg-secondary-focus',
                    linearGradient: {
                        R: '100',
                        G: '65',
                        B: '43',
                        opacity: '0.7'
                    },
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'lg:pl-6 3xl:pl-20 lg:pr-7 xl:pr-20 m-auto h-auto mb-4 lg:m-auto text-left text-primary py-5 md:py-10 lg:py-0',
                            linearGradient: {
                                R: '100',
                                G: '65',
                                B: '43',
                                opacity: '0.7'
                            },
                            components: [
                                {
                                    type: 'TEXT',

                                    onClick: 'CHANGE_PATH',
                                    path: 'deals',
                                    content: 'OFFERS AND PROMOTIONS',
                                    className: `cursor-pointer tracking-wide w-full xl:w-full font-semibold text-[27px] xl:text-[37px] uppercase text-secondary px-7 py-4 lg:px-7 lg:py-0 ${
                                        getFont('CENTURY').heading
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    position: '',
                                    className:
                                        'mx-7 lg:mx-7 w-[25%] md:w-[16%] lg:w-[20%] xl:w-[19%] 2xl:w-[16%] bg-primary border-none h-[2px]',
                                    classNameContainer: 'mt-2'
                                },

                                {
                                    type: 'TEXT',
                                    content:
                                        'Being at the center of our attention means availing of exclusive offers and promotions from top Pan-India and luxury brands across the country.',

                                    className: `text-[18px] md:text-[19px] leading-6 lg:text-[17px] xl:text-[18px] px-7 py-4 lg:px-7 lg:pt-5 3xl:pr-32  text-neutral  ${
                                        getFont('CENTURY').secondary
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'As part of our loyalty program, we are looking forward to bringing you curated experiences that will become long delightful conversations with your loved ones. Enjoy a luxury experience, unlike anything you have ever done before.',

                                    className: `text-[18px] md:text-[19px] leading-6 lg:text-[17px] xl:text-[18px]  px-7 py-4 lg:px-7 lg:pt-5 3xl:pr-32  text-neutral  ${
                                        getFont('CENTURY').secondary
                                    }`
                                }
                            ]
                        },
                        {
                            type: 'CHANGING_IMAGE',
                            position: 'center',
                            imageArr: [
                                {
                                    url: 'https://cdn.loyalie.in/31448170.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/82111289.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/88471850.jpg'
                                }
                            ],
                            className:
                                'h-full lg:h-[500px] 2xl:h-[600px] w-full object-fill ',
                            classNameContainer:
                                'w-full h-full order-first lg:order-none',
                            swipeable: false,
                            draggable: false,
                            showDots: false,
                            arrows: false,
                            infinite: true,
                            autoPlay: true,
                            autoPlaySpeed: 3000,
                            transitionDuration: 3000,
                            customTransition: 'animate-fadeInAnimation'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'blog',
                    className:
                        'relative grid lg:h-[90vh] sm:grid-cols-1 lg:grid-cols-2 bg-secondary-focus',
                    linearGradient: {
                        R: '100',
                        G: '65',
                        B: '43',
                        opacity: '0.7'
                    },
                    components: [
                        {
                            type: 'CHANGING_IMAGE',
                            position: 'center',
                            imageArr: [
                                {
                                    url: 'https://cdn.loyalie.in/9547235.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/81061435.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/86282995.jpg'
                                }
                            ],
                            className:
                                'h-full lg:h-[500px] 2xl:h-[600px] w-full object-fill ',
                            classNameContainer:
                                'w-full h-full  order-first lg:order-none',
                            swipeable: false,
                            draggable: false,
                            showDots: false,
                            arrows: false,
                            infinite: true,
                            autoPlay: true,
                            autoPlaySpeed: 3000,
                            transitionDuration: 3000,
                            customTransition: 'animate-fadeInAnimation'
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'lg:pl-6 3xl:pl-20 lg:pr-7 xl:pr-20 m-auto h-auto mb-4 lg:m-auto text-left text-primary py-5 md:py-10 lg:py-0 ',
                            linearGradient: {
                                R: '100',
                                G: '65',
                                B: '43',
                                opacity: '0.7'
                            },
                            components: [
                                {
                                    type: 'TEXT',

                                    onClick: 'CHANGE_PATH',
                                    path: 'events',
                                    content: 'Workshops and Events ',
                                    className: `cursor-pointer tracking-wide w-full  xl:w-full font-semibold text-[27px] xl:text-[37px] uppercase text-secondary px-7 py-4 lg:px-7 lg:py-0 ${
                                        getFont('CENTURY').heading
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    position: '',
                                    // color: '#676766',
                                    className:
                                        'mx-7 lg:mx-7 w-[25%] md:w-[16%] lg:w-[20%] xl:w-[19%] 2xl:w-[16%] bg-primary border-none h-[2px]',
                                    classNameContainer: 'mt-2'
                                },

                                {
                                    type: 'TEXT',
                                    content:
                                        'Engagement and interaction form the founding pillars of a model community. In our efforts to create the same feeling for every member of Century Circle, we will bring you exclusive workshops and events with the top collaborators in each field. Now, community experiences will move beyond waves and smiles, into relationships forged with lasting memories. ',

                                    className: `text-[18px] md:text-[19px] leading-6 lg:text-[17px] xl:text-[18px] px-7 py-4 lg:px-7 lg:pt-5 3xl:pr-32  text-neutral  ${
                                        getFont('CENTURY').secondary
                                    }`
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'blog',
                    className:
                        'relative grid lg:h-[90vh] sm:grid-cols-1 lg:grid-cols-2 bg-secondary-focus',
                    linearGradient: {
                        R: '100',
                        G: '65',
                        B: '43',
                        opacity: '0.7'
                    },
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'lg:pl-6 3xl:pl-20 lg:pr-7 xl:pr-20 m-auto h-auto mb-4  lg:m-auto text-left text-primary py-5 md:py-10 lg:py-0',
                            linearGradient: {
                                R: '100',
                                G: '65',
                                B: '43',
                                opacity: '0.7'
                            },
                            components: [
                                {
                                    type: 'TEXT',

                                    onClick: 'CHANGE_PATH',
                                    path: 'blogs',
                                    content: ' Interactive Blogs ',
                                    className: `cursor-pointer tracking-wide w-full xl:w-full font-semibold text-[27px] xl:text-[37px] uppercase text-secondary px-7 py-4 lg:px-7 lg:py-0 ${
                                        getFont('CENTURY').heading
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'mx-7 lg:mx-7 w-[25%] md:w-[16%] lg:w-[20%] xl:w-[19%] 2xl:w-[16%] bg-primary border-none h-[2px]',
                                    classNameContainer: 'mt-2'
                                },

                                {
                                    type: 'TEXT',
                                    content:
                                        'Learning is part of every successful community, but it should come with a sprinkle of fun. Our interactive blogs are curated and written keeping you in mind. At Century Circle, we drive towards enabling unique interactions and talking points between you and your neighbors, while sharing a life together.',

                                    className: `text-[18px] md:text-[19px] leading-6 lg:text-[17px] xl:text-[18px] px-7 py-4 lg:px-7 lg:pt-5 3xl:pr-32  text-neutral  ${
                                        getFont('CENTURY').secondary
                                    }`
                                }
                            ]
                        },
                        {
                            type: 'CHANGING_IMAGE',
                            position: 'center',
                            imageArr: [
                                {
                                    url: 'https://cdn.loyalie.in/47752960.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/30191276.jpg'
                                },
                                {
                                    url: 'https://cdn.loyalie.in/27127705.jpg'
                                }
                            ],
                            className:
                                'h-full lg:h-[500px] 2xl:h-[600px] w-full object-fill ',
                            classNameContainer:
                                'w-full h-full  order-first lg:order-none',
                            swipeable: false,
                            draggable: false,
                            showDots: false,
                            arrows: false,
                            infinite: true,
                            autoPlay: true,
                            autoPlaySpeed: 3000,
                            transitionDuration: 3000,
                            customTransition: 'animate-fadeInAnimation'
                        }
                    ]
                }
                // {
                //     type: 'CUSTOM',
                //     className:
                //         'relative flex flex-col justify-center text-center pt-8',
                //     url: 'https://cdn.loyalie.in/17509686.png',
                //     overlayClass: 'bg-neutral opacity-[.97]',
                //     linearGradient: {
                //         R: '255',
                //         G: '255',
                //         B: '255',
                //         opacity: '0',
                //         size: '30% auto'
                //     },
                //     components: [
                //         {
                //             type: 'TEXT',
                //             content: 'OUR PROJECTS',
                //             className: `z-10 tracking-wide font-semibold text-[25px] md:text-[30px] lg:text-[33px] text-secondary-focus ${
                //                 getFont('CENTURY').heading
                //             }`
                //         },
                //         {
                //             type: 'DIVIDER',
                //             className:
                //                 'z-10 self-center mt-2 2xl:mt-3 mx-auto w-[25%] md:w-[16%] lg:w-[14%] xl:w-[10%] 2xl:w-[9%] bg-secondary-focus border-none h-[3px]',
                //             classNameContainer: ''
                //         }
                //     ]
                // },
                // {
                //     type: 'CAROUSEL',
                //     url: 'https://cdn.loyalie.in/17509686.png',
                //     overlayClass: 'bg-neutral opacity-[.97]',
                //     linearGradient: {
                //         R: '255',
                //         G: '255',
                //         B: '255',
                //         opacity: '0',
                //         size: '30% auto'
                //     },
                //     caraouselClassName:
                //         'px-10 md:px-10 lg:px-10 xl:px-24 2xl:px-48 md:py-4 2xl:pt-5 relative',
                //     imageContainerClassName:
                //         'w-auto p-12 md:p-10 lg:p-12 xl:p-7 2xl:p-7',
                //     imageClassName:
                //         ' border-2 border-secondary-focus p-2 lg:p-4 2xl:p-4 cursor-pointer',
                //     arrows: true,
                //     autoplay: true,
                //     autoplaySpeed: 1000,
                //     infinite: true,
                //     dots: false,
                //     slidesToShow: 4,
                //     pauseOnHover: true,
                //     speed: 1000,
                //     onImageClick: openURLNewWindow,
                //     imgArr: [
                //         {
                //             imgUrl: 'https://cdn.loyalie.in/59273536.png',
                //             alt: 'img',
                //             url: 'https://www.centuryrealestate.in/projects/century-greens'
                //         },
                //         {
                //             imgUrl: 'https://cdn.loyalie.in/46222224.png',
                //             alt: 'img',
                //             url: 'https://www.centuryrealestate.in/projects/century-ethos'
                //         },
                //         {
                //             imgUrl: 'https://cdn.loyalie.in/42185439.png',
                //             alt: 'img',
                //             url: 'https://www.centuryrealestate.in/projects/century-breeze'
                //         },
                //         {
                //             imgUrl: 'https://cdn.loyalie.in/60175521.png',
                //             alt: 'img',
                //             url: 'https://www.centuryrealestate.in/projects/century-wintersun'
                //         },
                //         {
                //             imgUrl: 'https://cdn.loyalie.in/7460415.png',
                //             alt: 'img',
                //             url: 'https://www.centuryrealestate.in/projects/century-seasons'
                //         },
                //         {
                //             imgUrl: 'https://cdn.loyalie.in/66594545.png',
                //             alt: 'img',
                //             url: 'https://www.centuryrealestate.in/projects/century-renata'
                //         },
                //         {
                //             imgUrl: 'https://cdn.loyalie.in/48083396.png',
                //             alt: 'img',
                //             url: 'https://www.centuryrealestate.in/projects/century-linea'
                //         },
                //         {
                //             imgUrl: 'https://cdn.loyalie.in/74960159.png',
                //             alt: 'img',
                //             url: 'https://www.centuryrealestate.in/projects/century-horizon'
                //         }
                //     ]
                // }
                // {
                //     type: 'CUSTOM',
                //     className: 'grid grid-cols-1  md:grid-cols-4 mb-7 3xl:my-6',
                //     linearGradient: {
                //         R: '100',
                //         G: '65',
                //         B: '43',
                //         opacity: '0'
                //     },
                //     components: [
                //         {
                //             type: 'IMAGE',
                //             url: 'https://cdn.loyalie.in/49738520.png',

                //             className: ' self-center object-contain',
                //             classNameContainer:
                //                 'ml-6 my-4 md:my-0 md:ml-0 col-span-1 w-full h-full flex justify-start md:justify-center  '
                //         },
                //         {
                //             type: 'TEXT',
                //             content:
                //                 'No. 3/1 Fourth Floor, J P Techno Park, Millers Road, Bangalore – 560052, India',

                //             className: `col-span-2 p-6 xl:pl-16 xl:pr-28 self-center tracking-wide text-[#383838] text-[18px] lg:text-[20px] 3xl:text-[24px] ${
                //                 getFont('CENTURY').heading
                //             }`
                //         },
                //         {
                //             type: 'CUSTOM',
                //             className:
                //                 'flex justify-around self-center pr-10 col-span-1',
                //             linearGradient: {
                //                 R: '100',
                //                 G: '65',
                //                 B: '43',
                //                 opacity: '0'
                //             },
                //             components: [
                //                 {
                //                     type: 'ICON',
                //                     onClickUrl:
                //                         'https://www.facebook.com/centuryrealestatebangalore',
                //                     iconName: 'BsFacebook',
                //                     classNameIcon:
                //                         'h-full w-full  cursor-pointer text-[#64412B]',
                //                     classNameContainer:
                //                         'h-6 w-6 3xl:h-8 3xl:w-8'
                //                 },
                //                 {
                //                     type: 'ICON',
                //                     onClickUrl:
                //                         'https://www.instagram.com/centuryrealestate/',
                //                     iconName: 'BsInstagram',
                //                     classNameIcon:
                //                         'h-full w-full cursor-pointer text-[#64412B]',
                //                     classNameContainer:
                //                         'h-6 w-6 3xl:h-8 3xl:w-8'
                //                 },
                //                 {
                //                     type: 'ICON',
                //                     onClickUrl:
                //                         'https://twitter.com/centuryblr',
                //                     iconName: 'BsTwitter',
                //                     classNameIcon:
                //                         'h-full w-full cursor-pointer text-[#64412B]',
                //                     classNameContainer:
                //                         'h-6 w-6 3xl:h-8 3xl:w-8'
                //                 },
                //                 {
                //                     type: 'ICON',
                //                     onClickUrl:
                //                         'https://www.linkedin.com/company/century-real-estate-holdings-pvt-ltd',
                //                     iconName: 'BsLinkedin',
                //                     classNameIcon:
                //                         'h-full w-full cursor-pointer text-[#64412B]',
                //                     classNameContainer:
                //                         'h-6 w-6 3xl:h-8 3xl:w-8'
                //                 }
                //             ]
                //         }
                //     ]
                // }
            ]
        },
        refer: {
            show: true,
            name: 'REFER AND EARN',
            path: '/refer',
            component: 'ReferAndEarn'
        },
        events: {
            show: true,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: true,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'BENEFITS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },

        contactUs: {
            show: true,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: true,
            name: 'T&C',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    blogs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/17509686.png',
        overlayClass: 'bg-neutral opacity-[.97]',
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0',
            size: '30% auto'
        },
        banner: {
            bannerContainerClassName: 'hidden',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.20'
            },
            backgroundImage: '',
            backgroundImageMobile: '',

            components: [],
            loginBannerComponnets: []
        },

        detailsPageBackgroundColor: 'bg-accent',
        sideBarClass: `text-[#64412b] ${getFont('CENTURY').body}`,
        sideBarBorder: 'border-[#64412b]',
        sideBarHeadingClass: 'text-[#64412b] text-[16px] capitalize leading-5 ',
        sideBarDateClass: 'text-[#64412b] text-[12.5px]',
        comments: {
            commentSubmittedBg: 'bg-secondary-focus',
            commentsBg: 'bg-accent',
            commentButtonClass:
                'bg-secondary-focus text-accent hover:text-accent hover:bg-secondary-focus ',
            initialsClass:
                'bg-secondary-focus text-accent border-secondary-focus',
            replyBorder: 'border-accent'
        },
        layout1: {
            borderClass: 'border-[#64412B]',
            headingClass: `text-[#64412b] mb-2 md:mb-4 lg:mb-2 xl:mb-4 text-lg uppercase font-semibold leading-6 ${
                getFont('CENTURY').body
            }`,
            categoryClass: 'bg-[#64412b] text-accent',
            summaryClass: `break-words text-secondary-focus text-lg indent-8 mb-[25px] text-[18px] leading-6 ${
                getFont('CENTURY').body
            }`,
            buttonClass: `bg-secondary-focus rounded-sm text-xs py-2 px-8 self-center font-CorporateS_Demi text-neutral `,
            dateClass: `text-sm text-[#64412b]`,
            noBlogsClass: 'h-[400px] text-black text-xl mt-20 text-center'
        }
    },
    referAndEarn: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '100',
                    G: '65',
                    B: '43',
                    opacity: '0.6'
                },
                position: 'text-center',
                backgroundImage: 'https://cdn.loyalie.in/21261287.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/40909042.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[60vh] md:h-[85vh] lg:h-[85vh] object-cover bg-top  md:bg-center lg:bg-center xl:bg-top',
                components: [
                    {
                        type: 'TEXT',
                        content: 'REFERRAL EXPERIENCES WITH',
                        className: `text-center text-primary mt-6 lg:mt-0 xl:-mt-20 2xl:-mt-16 3xl:mt-16 text-[21px] leading-5 px-2 md:text-[36px] lg:text-[40px] xl:text-[40px] mb-0 break-words ${
                            getFont('CENTURY').body
                        }`
                    },
                    {
                        type: 'IMAGE',
                        url: 'https://cdn.loyalie.in/78479901.png',

                        position: 'center',
                        className:
                            'w-[72%] md:w-[54.5%] lg:w-[26%]  3xl:w-[30%] py-2 md:py-4 lg:py-0 mt-0 lg:mt-6 xl:mt-10 2xl:mt-6 lg:pb-2 2xl:pb-0 lg:px-4 xl:px-0 '
                    },
                    {
                        type: 'BUTTON',
                        content: 'REFER NOW',
                        className:
                            'bg-accent rounded-sm text-[16px] py-1 md:py-[5.5px] px-5 md:px-10 self-center font-CorporateS_Demi text-[#64412b] mt-2 lg:mt-5 ',
                        path: 'gift-and-earn',
                        onClick: 'CHANGE_PATH_AND_SCROLL',
                        scrollTo: 'referral_section'
                    }
                    // {
                    //     type: 'TEXT',
                    //     content: 'Managed by Reloy',
                    //     className: `absolute text-neutral text-[12px] bottom-0 left-1  md:text-[16px] 2xl:text-[18px] md:bottom-2 md:left-3 ${
                    //         getFont('CENTURY').body
                    //     }`
                    // }
                ]
            },
            {
                type: 'CUSTOM',
                id: 'overview',
                className:
                    'relative flex flex-col justify-center text-center pt-8',
                url: 'https://cdn.loyalie.in/17509686.png',
                overlayClass: 'bg-neutral opacity-[.97]',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0',
                    size: '30% auto'
                },
                components: [
                    {
                        type: 'CUSTOM',
                        className:
                            'z-10 w-full text-center w-[92%] 2xl:w-[87%] mx-auto mb-3 ',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'Century Real Estate values each of its residents as an indispensable part of its larger family. Century Circle is an initiative to establish this value through action and give you the opportunity to build communities you want to be a part of. As a loyalty and referral program, it puts your life at its core and constantly evolves to create experiences worth sharing. Also, earn anywhere between',
                                className: `inline z-10 mt-[20px] text-[#64412B] text-[18px] md:text-[18px] lg:[18px] 3xl:text-[18px] leading-6 mb-3 font-normal mx-auto  ${
                                    getFont('CENTURY').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content: ' ₹50,000 – ₹7,00,000.',
                                className: ` inline z-10 text-[#64412B] text-[18px] text-[20px] md:text-[18px] lg:[18px] 3xl:text-[18px] leading-6 font-semibold  ${
                                    getFont('CENTURY').body
                                }`
                            }
                        ]
                    },

                    {
                        type: 'TEXT',
                        content:
                            'Here’s a look at how seamless your referral experience will be.',

                        className: `z-10 text-[#64412B] text-[18px] md:text-[19px] lg:[20px] leading-6 mb-[14px] w-[92%] mx-auto xl:px-16 ${
                            getFont('CENTURY').body
                        }`
                    },
                    {
                        type: 'TEXT',
                        content: 'Making Referrals Easy',
                        className: `z-10 tracking-wide font-semibold py-4 xl:pt-5 text-[22px] md:text-[22px] uppercase lg:text-[30px] text-[#64412B] ${
                            getFont('CENTURY').heading
                        }`
                    },

                    {
                        type: 'ICONS_LAYOUT_WITH_ARROW',
                        icon1: 'https://cdn.loyalie.in/83185043.png',
                        icontext1:
                            'Introduce your loved ones to your community',
                        icon2: 'https://cdn.loyalie.in/36754030.png',
                        icontext2:
                            'Wait for them to complete their property booking & registration',
                        icon3: 'https://cdn.loyalie.in/1572011.png',
                        icontext3:
                            'Receive your referral reward within 90 days post agreement signing and 10% of property value paid'
                    },
                    {
                        type: 'TEXT',
                        content:
                            'Go on, introduce your loved ones to the world of Century Circle.',

                        className: `z-10 text-[22px] text-[#64412B] font-semibold leading-7 w-[80%] text-center mx-auto xl:px-16 mb-4 mt-12 lg:mb-8 lg:mt-6 ${
                            getFont('CENTURY').body
                        }`
                    }
                ]
            },

            {
                type: 'SIMPLE_REFERRAL_FORM'
            }
            // {
            //     type: 'IFRAME',
            //     id: 'referral_section',
            //     url: 'http://dev.loyalie.in/century-referral',
            //     className: 'w-full',
            //     height: '760px'
            // },
            // {
            //     type: 'CUSTOM',
            //     className:
            //         'relative flex flex-col justify-center text-center pt-8 2xl:pt-10',
            //     url: 'https://cdn.loyalie.in/17509686.png',
            //     overlayClass: 'bg-neutral opacity-[.97]',
            //     linearGradient: {
            //         R: '255',
            //         G: '255',
            //         B: '255',
            //         opacity: '0',
            //         size: '30% auto'
            //     },
            //     components: [
            //         {
            //             type: 'TEXT',
            //             content: 'OUR PROJECTS',
            //             className: `z-10 tracking-wide font-semibold text-[25px] md:text-[30px] lg:text-[33px] text-secondary-focus ${
            //                 getFont('CENTURY').heading
            //             }`
            //         },
            //         {
            //             type: 'DIVIDER',
            //             className:
            //                 'z-10 self-center mt-2 2xl:mt-3 mx-auto  w-[25%] md:w-[16%] lg:w-[14%] 2xl:w-[9%]  bg-secondary-focus border-none h-[2px]',
            //             classNameContainer: ''
            //         }
            //     ]
            // },
            // {
            //     type: 'CAROUSEL',
            //     url: 'https://cdn.loyalie.in/17509686.png',
            //     overlayClass: 'bg-neutral opacity-[.97]',
            //     linearGradient: {
            //         R: '255',
            //         G: '255',
            //         B: '255',
            //         opacity: '0',
            //         size: '30% auto'
            //     },
            //     caraouselClassName:
            //         'px-10 md:px-10 lg:px-10 xl:px-24 2xl:px-48 md:py-4 2xl:pt-5 relative',
            //     imageContainerClassName:
            //         'w-auto p-12 md:p-10 lg:p-12 xl:p-7 2xl:p-7 ',
            //     imageClassName:
            //         ' border-2 border-secondary-focus p-2 lg:p-4 2xl:p-4 cursor-pointer',
            //     arrows: true,
            //     autoplay: true,
            //     autoplaySpeed: 1000,
            //     infinite: true,
            //     dots: false,
            //     slidesToShow: 4,
            //     pauseOnHover: true,
            //     speed: 1000,
            //     onImageClick: openURLNewWindow,
            //     imgArr: [
            //         {
            //             imgUrl: 'https://cdn.loyalie.in/59273536.png',
            //             alt: 'img',
            //             url: 'https://www.centuryrealestate.in/projects/century-greens'
            //         },
            //         {
            //             imgUrl: 'https://cdn.loyalie.in/46222224.png',
            //             alt: 'img',
            //             url: 'https://www.centuryrealestate.in/projects/century-ethos'
            //         },
            //         {
            //             imgUrl: 'https://cdn.loyalie.in/42185439.png',
            //             alt: 'img',
            //             url: 'https://www.centuryrealestate.in/projects/century-breeze'
            //         },
            //         {
            //             imgUrl: 'https://cdn.loyalie.in/60175521.png',
            //             alt: 'img',
            //             url: 'https://www.centuryrealestate.in/projects/century-wintersun'
            //         },
            //         {
            //             imgUrl: 'https://cdn.loyalie.in/7460415.png',
            //             alt: 'img',
            //             url: 'https://www.centuryrealestate.in/projects/century-seasons'
            //         },
            //         {
            //             imgUrl: 'https://cdn.loyalie.in/66594545.png',
            //             alt: 'img',
            //             url: 'https://www.centuryrealestate.in/projects/century-renata'
            //         },
            //         {
            //             imgUrl: 'https://cdn.loyalie.in/48083396.png',
            //             alt: 'img',
            //             url: 'https://www.centuryrealestate.in/projects/century-linea'
            //         },
            //         {
            //             imgUrl: 'https://cdn.loyalie.in/74960159.png',
            //             alt: 'img',
            //             url: 'https://www.centuryrealestate.in/projects/century-horizon'
            //         }
            //     ]
            // }
        ]
    },

    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/72371396.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/72371396.jpg',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0',
                repeat: 'repeat',
                size: '50% auto',
                position: ''
            },
            negativeMargin: '',
            containerClass: 'xl:ml-5 text-white lg:w-[40%]',
            heading: ' ',
            headingClass: `text-accent text-3xl md:text-4xl uppercase ${
                getFont('CENTURY').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-neutral uppercase font-semibold text-[33px] ${
                getFont('CENTURY').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                ' hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[97vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `-mt-36 md:mt-56 lg:-mt-48 mb-3 text-secondary text-2xl md:text-4xl xl:text-[40px] text-secondary tracking-wider ${
                        getFont('CENTURY').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    onClick: 'FUNC',
                    className: `bg-accent rounded-sm text-[16px] py-1 md:py-[5.5px] px-5 md:px-10 self-center font-CorporateS_Demi text-[#64412b]`
                }
            ]
        },

        eventsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/17509686.png',
            overlayClass: 'bg-neutral opacity-[.97] ',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0',
                size: '30% auto'
            },
            heading: 'PAST EVENTS',
            headingClass: `z-10 text-secondary-focus text-[25px] md:text-text-[30px] lg:text-[33px] text-center font-extrabold mt-10 2xl:mt-7 ${
                getFont('CENTURY').heading
            }`,
            dividerClass:
                'z-10 self-center mt-2 2xl:mt-3 mx-auto  w-[25%] md:w-[16%] lg:w-[14%] 2xl:w-[9%]  bg-secondary-focus border-none h-[2px]  mb-8',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `z-10 self-center font-semibold text-[20px]  text-center text-xl text-secondary-focus w-[60%] ${
                getFont('CENTURY').heading
            }`,
            additionalText: ' ',
            additionalTextClass: `self-center text-md text-center text-black  w-[60%] ${
                getFont('CENTURY').body
            } `,
            subAdditionalText: ' ',
            subAdditionalTextClass: `capitalize self-center text-md text-center text-black w-[60%] mb-16 ${
                getFont('CENTURY').body
            }`,
            noEvents: '',
            noEventsClass: `z-10 w-[30%] mx-auto py-1 text-accent bg-secondary-focus text-xl text-center ${
                getFont('CENTURY').body
            }`
        },
        eventLayout: {
            titleClass: `text-secondary-focus uppercase font-semibold ${
                getFont('CENTURY').secondary
            }`,
            hostClass: `${getFont('CENTURY').body}`,
            dateClass: '',
            loadMoreButtonClass:
                'z-10 bg-secondary-focus rounded-sm text-[16px] py-1 md:py-[5.5px] px-5 md:px-10 self-center font-CorporateS_Demi text-accent'
        }
    },
    eventsGallery: {
        show: true,
        titleClass: `text-secondary-focus font-semibold text-xl 2xl:text-2xl my-4 z-10 ${
            getFont('CENTURY').body
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('CENTURY').body
        }`
    },
    deals: {
        components: [
            {
                type: 'BANNER',
                position: 'text-center',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '100',
                    G: '65',
                    B: '43',
                    opacity: '0.7'
                },
                backgroundImage: 'https://cdn.loyalie.in/1773849.jpg',

                backgroundImageMobile: 'https://cdn.loyalie.in/1773849.jpg',
                bannerContainerClassName:
                    'hero hero-content  max-w-full h-[400px] lg:h-[480px] 2xl:h-[580px]  ',
                components: [
                    {
                        type: 'TEXT',
                        content: 'EXCLUSIVE OFFERS FOR YOU',
                        className: ` mb-5 text-2xl text-primary md:text-4xl xl:text-[40px] tracking-wide ${
                            getFont('CENTURY').body
                        }`
                    },
                    {
                        type: 'BUTTON',
                        content: 'START SHOPPING',
                        scrollTo: 'deals_section',
                        onClick: 'SCROLL'
                    }
                ]
            }
        ],
        banner: {
            bannerContainerClassName:
                'hero hero-content max-w-full h-[400px] lg:h-[480px] 2xl:h-[580px]  ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '100',
                G: '65',
                B: '43',
                opacity: '0.7'
            },
            backgroundImage: 'https://cdn.loyalie.in/1773849.jpg',

            backgroundImageMobile: 'https://cdn.loyalie.in/1773849.jpg',
            components: [],
            loginBannerComponents: [
                // {
                //     type: 'TEXT',
                //     content: 'EXCLUSIVE OFFERS AND PROMOTIONS',
                //     className: ` mt-12 md:mt-12 mb-3 text-secondary text-2xl md:text-4xl xl:text-[40px] text-secondary tracking-wider ${
                //         getFont('CENTURY').heading
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC',
                //     className: `bg-accent rounded-sm text-[16px] py-1 md:py-[5.5px] px-5 md:px-10 self-center font-CorporateS_Demi text-[#64412b] `
                // }
            ]
        },
        darkTheme: {
            selected: 'hover:bg-gray-50 focus:bg-gray-50',
            text: 'text-secondary-focus',
            border: ''
        },
        dealsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/17509686.png',
            // overlayClass: 'bg-neutral opacity-[.97]',
            linearGradient: {
                R: '255',
                G: '250',
                B: '233',
                opacity: '0.98',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `z-10 self-center font-semibold text-[20px]  text-center text-xl text-secondary-focus w-[60%] ${
                getFont('CENTURY').heading
            }`,

            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `z-10 uppercase text-2xl xl:text-3xl uppercase text-secondary-focus font-semibold  ${
                getFont('CENTURY').heading
            } `,
            subAdditionalText:
                'Century Circle is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: `z-10 font-normal text-[16px] py-2 mt-2 leading-6 ${
                getFont('CENTURY').body
            }`,
            noDeals: 'No deals to show',
            noDealsClass: `z-10  text-secondary-focus text-2xl text-center my-6
             ${getFont('CENTURY').body}`
        },
        dealLayout: {
            // dealImageBgColor: 'bg-secondary-focus', //when image is loading, this shows
            containerClassNameBg1:
                ' px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: ' p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            linearGradient: {
                R: '255',
                G: '250',
                B: '233',
                opacity: '0.98',
                size: '30% auto'
            },
            listTitleClass: `text-[18px] leading-6 lg:leading-5 2xl:leading-6 text-secondary-focus ${
                getFont('CENTURY').body
            }`,
            listBodyClass: `${getFont('CENTURY').body}`,
            logoClassName:
                'self-center rounded-md border-[1px] border-grey-100',
            titleClassName: `text-secondary-focus font-semibold uppercase text-2xl xl:text-xl 2xl:text-2xl leading-7  mt-4 ${
                getFont('CENTURY').body
            } `,
            couponClassName: `block lg:text-xl self-center text-center text-secondary-focus font-semibold ${
                getFont('CENTURY').secondary
            }`,
            dateClassName: `text-secondary-focus capitalize ${
                getFont('CENTURY').secondary
            }`,
            termsAndConditionsClassName: ` font-semibold capitalize text-secondary-focus text-[14px]  ${
                getFont('CENTURY').secondary
            }`,
            termsArrowClassName: 'self-center text-2xl text-secondary-focus',
            termsClassName: `font-semibold text-secondary-focus mx-auto text-[12px] break-words leading-1 ${
                getFont('CENTURY').secondary
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',

                className: `bg-secondary-focus rounded-sm text-xs py-2 px-8 self-center font-CorporateS_Demi text-neutral`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',

                className: `bg-secondary-focus rounded-sm text-sm py-2 px-8 self-center font-CorporateS_Demi text-neutral`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                position: 'center',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '100',
                    G: '65',
                    B: '43',
                    opacity: '0.7'
                },
                backgroundImage: centuryImage,
                backgroundImageMobile: centuryImage,
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[400px] lg:h-[480px] 2xl:h-[580px] bg-left lg:bg-center',

                components: [
                    {
                        type: 'TEXT',
                        content: 'CENTURY PROJECTS',
                        className: ` text-2xl md:text-4xl xl:text-[40px] text-primary tracking-wide ${
                            getFont('CENTURY').body
                        }`
                    }
                ]
            }
        ],
        moduleConfig: {
         
            constructionVisible: true,
            
            sectionBackgroundImage: 'https://cdn.loyalie.in/17509686.png',
            overlayClass: 'bg-neutral opacity-[.97]',
            propertyBackgroundColorWithOverlay: 'bg-neutral',
            fontBody: `${getFont('CENTURY').body}`,
            fontTitle: `${getFont('CENTURY').body}`
        }
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/17509686.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0',
            repeat: 'repeat',
            size: '30% auto'
        },
        overlayClass: 'bg-neutral opacity-[.97]',
        components: [
            {
                type: 'BANNER',
                position: 'text-center',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.3'
                },
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '100',
                    G: '65',
                    B: '43',
                    opacity: '0.7'
                },
                backgroundImage: 'https://cdn.loyalie.in/16571251.jpg',

                backgroundImageMobile: 'https://cdn.loyalie.in/16571251.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[400px] lg:h-[480px] 2xl:h-[580px] ',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',
                        className: ` text-2xl md:text-4xl xl:text-[40px] text-primary tracking-wide ${
                            getFont('CENTURY').body
                        }`
                    }
                ]
            }
        ],
    
    },
    termsAndConditions: {
        components: [
            {
                type: 'BANNER',
                position: 'text-center',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '100',
                    G: '65',
                    B: '43',
                    opacity: '0.7'
                },

                backgroundImage: 'https://cdn.loyalie.in/16132901.jpg',

                backgroundImageMobile: 'https://cdn.loyalie.in/16132901.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[400px] lg:h-[480px] 2xl:h-[580px] bg-right lg:bg-left',

                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS AND CONDITIONS',
                        className: ` text-primary font-medium text-2xl md:text-4xl 2xl:text-[40px] tracking-wide ${
                            getFont('CENTURY').body
                        }`
                    }
                ]
            },
            {
                type: 'CUSTOM',
                className:
                    'relative flex flex-col justify-start text-left p-7 md:p-16 lg:px-24 xl:px-16 2xl:px-36',
                url: 'https://cdn.loyalie.in/17509686.png',
                overlayClass: 'bg-neutral opacity-[.97]',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0',
                    size: '30% auto'
                },
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content: 'ANNEXURE A',
                    //     className: `z-10 tracking-wide text-[#64412b] font-semibold text-[20px] md:text-[20px] ${
                    //         getFont('CENTURY').body
                    //     }`
                    // },
                    {
                        type: 'TEXT',
                        content:
                            'Terms And Conditions Of The Referral Reward Program Are Mentioned Below:',

                        className: `z-10 tracking-wide text-[#64412b] mt-4 font-semibold text-[20px] md:text-[20px] ${
                            getFont('CENTURY').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-decimal',
                        points: [
                            'The validity of the terms outlined here are applicable from 1st April 2022 onward. For Property Purchases (alternatively “Bookings”) made prior to 1st April 2022 any previous policy rolled out by the Company will be applicable.',
                            '“Company” (or alternatively “Developer”) refers to M/s Century Real Estate Holdings Private Limited engaged in the business of developing and/or promoting residential and commercial real estate projects.',
                            '“Reloy” (or alternatively “Partner”) refers to Loyalie IT-Solutions Private Limited who operates and manages the Referral Reward Program i.e Century Circle on behalf of the Company. ',
                            '“Referrer” shall mean either of (i) an “Existing Customer” who has booked a “Unit”(shall mean either apartments/plots/villas/villaments) in the Company’s project, and has entered into a registered “Agreement for Sale”; or (ii) an “Employee” (alternatively “Associate”) who is in the current service of the Company either part time or full time under a contract of employment; or (iii) a prospective customer  who visited but didn’t book (“VDNB Client”); or (iv) “Guests” who have yet to visit the Company’s project and wishes to introduce/refer another person(s) to purchase a Unit in any of the eligible registered real estate project/s being developed or to be developed by the Company or any of its sister concerns/subsidiaries/affiliates (“Company’s Project/s”).',
                            '“Referred” (or alternatively “Buyer”) shall mean the eligible person introduced/referred to the Company by the Referrer and who intends to book one or more Unit(s) in the Company’s Project/s and registers an Agreement for Sale for such Unit(s).',
                            'The Referrer and the Referred may collectively be called the “Participants” of the program.',
                            'Referrer participation in the Program shall be subject to the Referred paying the 10% of the property value and completing the agreement process and producing a copy of a valid and subsisting registered Agreement for Sale executed in his/her/their favor by the Company.',
                            'Subject to the terms mentioned herein, the “Referral Reward” (or alternatively “Referral Bonus” or “Referral Benefits) shall mean the remuneration or fees or reward or benefit which will be paid by Reloy to the Referrer within 90 (ninety) days from the date of registration of the Agreement for Sale by the Referred. ',
                            'In some cases, the Referral Reward may also be adjusted against the outstanding or future dues of the Referrer by the Company. It may also be in the form of a pay-out or gift vouchers/cards having value and a validity period with its own applicable Terms and Conditions in which case Reloy will not be responsible. The Company will not bypass Reloy with respect to any sale to an invitee or payment of the inviter reward to an inviter, without the prior written consent of Reloy.',
                            'Referrer shall not be able to refer Existing Customer(s) of the Company. Referrer shall not be able to refer Customer(s) who has/have in the last three months: (i) either made inquiries (in any form, whether by way of site visit or otherwise with Century Circle or (ii) is/are already registered with the Company as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Company’s Channel Partner or any other sourcing channels.',
                            '“Channel Partner” may either be an individual or an organization in partnership with the Company and registered under RERA with the responsibility of promoting and selling the Company’s Project(s).',
                            'Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and Century Real Estate and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted simultaneously from one or more Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by other Referrers. Occasionally on good faith basis the Company may interject and assess any merit (if any) to the dispute and decide to either award, reject, or share the referral claim.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',
                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to the Company within the said period of three months. ',
                            'By accepting the terms and conditions outlined here, both the Referrer and Referred represent that they have the authority to disclose the name and details of the person/party or business to the Company and Reloy. ',
                            'This Referral Reward Program and its associated benefits cannot be transferred to any Third Party nor can it be clubbed/replaced either partially or in full with any other offer/scheme/program, whether run by the Company or otherwise.',
                            'Employees working either in marketing, sales or CRM departments of the Company and the employees of Reloy will not be eligible for participating in the Program and accordingly shall not be entitled for any Referral Reward. ',
                            'The Referrer or the Referred will aid Reloy and/or the Company with any necessary information or documentation as may be deemed necessary to validate the claim of Referral and subsequently to process the Referral Reward. ',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed by it as a Referral Reward or on account of any dispute relating to the rights to utilize the product or services subsequent to disbursement of the same. ',
                            'All costs, charges, incidental expenses, duties and other applicable taxes if any, arising out of the transaction of the Referral Reward shall be borne by the Referrer and/or the Referred unless mentioned otherwise. ',
                            'If the Booking is canceled or terminated by the Company at its own discretion, for any reason not attributable to the Referrer or the Referred, the Company shall remain liable to pay the Referral Reward for the services rendered with respect to the purchase of the property.',
                            'The terms and conditions of this program may be canceled or discontinued or withdrawn or changed or altered or modified or extended by Reloy and The Company with or without notice.',
                            'All disputes shall be subject to exclusive jurisdiction of Bangalore courts.',
                            "Reloy and The Company reserves the right to prohibit either the Referrer or the Referred from participating in the Program if they use the Program in a manner inconsistent with the Terms and Conditions, or the intent of the Program, or any portion of the Program, including, but not limited to, Referral Reward redemption, or, if any participating member of the transaction commits misconduct or fraud, or misuses the Program's benefits and reward or act in a manner inconsistent with local or state laws, statutes or ordinances."
                        ],
                        listClassName: `z-10 text-secondary-focus text-[17px] leading-6 ${
                            getFont('CENTURY').body
                        }`,
                        classNameContainer: 'z-10 px-6 mt-6'
                    },
                    {
                        type: 'CUSTOM',
                        className: 'z-10 px-6 pt-2',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By participating in the Program, the Participants agree to the terms and conditions on',

                                className: `${
                                    getFont('CENTURY').body
                                } inline text-secondary-focus text-[17px] leading-6 `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-red-400 font-medium text-[17px] leading-6 ${
                                    getFont('CENTURY').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and to the privacy policy on',
                                className: `inline text-secondary-focus text-[17px] leading-6 ${
                                    getFont('CENTURY').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy',
                                className: `inline-block ml-1 text-red-400 font-medium text-[17px] leading-6 ${
                                    getFont('CENTURY').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by the Partner. The Participants also provides consent to receive messages, emails and any other form of communication from Reloy and/or the Company, for the purposes of promoting this Program and its benefits or the Company’s Project/s.',
                                className: `inline ml-1 text-secondary-focus text-[17px] leading-6 ${
                                    getFont('CENTURY').body
                                } `
                            }
                        ]
                    },
                    {
                        type: 'CUSTOM',
                        className: 'z-10 px-6 pt-2',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'Any clarifications pertinent to the Program can be obtained by writing email to',

                                className: `${
                                    getFont('CENTURY').body
                                } inline text-secondary-focus text-[17px] leading-6 `
                            },

                            {
                                type: 'LINK',
                                url: 'mailto:centurycircle@centuryrealestate.in',
                                content: 'centurycircle@centuryrealestate.in',
                                className: `inline ml-1 text-red-400 font-medium text-[17px] leading-6 ${
                                    getFont('CENTURY').body
                                }`
                            }
                        ]
                    },
                    {
                        type: 'CUSTOM',
                        className: 'z-10 px-6 pt-2',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'In the event that either the Referrer or the Referred wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ to the email id',

                                className: `${
                                    getFont('CENTURY').body
                                } inline text-secondary-focus text-[17px] leading-6 `
                            },

                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-red-400 font-medium text-[17px] leading-6 ${
                                    getFont('CENTURY').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    '. Please note that upon consent being withdrawn, the account of Referrer or the Referred will be deleted and will not be eligible to participate further in this Program. ',

                                className: `${
                                    getFont('CENTURY').body
                                } inline text-secondary-focus text-[17px] leading-6 `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    // footer1: {
    //     footerLogo:
    //         'https://cdn.loyalie.in/6673243.png',
    //     containerClassName:
    //         'bg-[#3c3c3c] xl:px-16 px-8 py-5 text-gray-100 border-t-4 border-[#f58020]',
    //     clientAddress:
    //         ' Unit No. 5C, 5th Floor, Godrej One, Pirojshanagar, Vikhroli East, Mumbai Maharashtra 400079.',
    //     clientAddressClassName: `text-[15px] leading-6 pt-3 ${
    //         getFont('CENTURY').body
    //     }`,
    //     contactUsClassName: `hover:text-primary  text-left pt-4 ${
    //         getFont('CENTURY').body
    //     }`,
    //     contactUsPath: '/contact-us',
    //     termsAndConditionsClassName: `hover:text-primary pt-4${
    //         getFont('CENTURY').body
    //     }`,
    //     termsAndConditionsPath: '/terms-and-conditions',
    //     footerContent1:
    //         'This is to inform user / customer(s) that www.godrejproperties.com, www.godrejthetrees.com & www.godrej.com are the only official websites of Godrej Properties Limited (“Company”). User/Customer(s) are cautioned and advised not to rely upon any information stated on any other websites which may appear to be similar to the company’s official website, including containing company’s logo / brand name. The information contained in such websites may be misleading/false and user/customer(s) may suffer loss if they rely on such information. In the event user/customer(s) come across any such websites similar to company’s official website containing its brand name/logo or any other information, then kindly contact and inform us on partnerconnect@godrejproperties.com in order to enable the Company to take necessary action.',
    //     footerContent1ClassName: `text-[15px] leading-6  ${
    //         getFont('CENTURY').body
    //     }`,
    //     footerContent2:
    //         'Please ensure that you deal with only RERA registered real estate agents (“Registered Real Estate Agents”) whose name appear as a real estate agent under the project name on the RERA web site. If you have received any unsolicited calls or text messages in breach of the regulatory norms or from agents not registered with us, please report such instances to us on partnerconnect@godrejproperties.com for our further investigation.',

    //     footerContent2ClassName: `leading-6 pt-4 text-[15px] ${
    //         getFont('CENTURY').body
    //     }`,
    //     loyalieFooterContent:
    //         ' This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in the following states : Maharashtra - MAHARERA NO – A51900010267, Karnataka RERA NO -PRM/KA/RERA/1251/309/AG/180524/000935, Tamil Nadu RERA NO– TN/Agent/0092/2023, West Bengal RERA NO - HIRA/A/KOL/2019/000350, Haryana RERA NO– HRERA-PKL-REA-239-2020',
    //     loyalieFooterContentClassName: `leading-6 text-[15px] ${
    //         getFont('CENTURY').body
    //     }`
    // },
    myProfile: {
        allowOnboarding: false
    },
    footer: {
        footerLayout: 'Footer4',
        containerClassName: 'bg-secondary-content pt-5 lg:mx-7 xl:mx-0',
        footerClientLogo: 'https://cdn.loyalie.in/45645227.png',
        footerLogo: 'https://cdn.loyalie.in/62632889.png',
        footerClientLogoClassName:
            'w-[45%] md:w-[20%] lg:w-[17%] xl:w-[14%] 3xl:w-[14%] lg:pl-6 lg:pr-0 self-start md:self-end',
        footerLogoClassName: 'self-center w-full md:w-[20%] lg:w-[15%]  ',

        // containerClassName:
        //     'bg-[#3c3c3c] xl:px-16 px-8 py-5 text-gray-100 border-t-4 border-[#f58020]',

        termsAndConditionsClassName: `text-black text-[13px] xl:-mt-2 md:text-[12px] 2xl:text-[12px] 3xl:text-[12px] text-right mr-10 ${
            getFont('CENTURY').body
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: true,
        allRightsReservedContent:
            '© 2022 by Century Real Estate Holdings Pvt. Ltd. All rights reserved.',
        allRightsReservedClassName: `text-[14px] 3xl:text-[15px] text-black text-center text-white px-7 py-1 ${
            getFont('CENTURY').body
        }`,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in All brands have their respective terms and conditions and neither Century Real Estate nor Reloy will be responsible in case of any concerns.',
        loyalieFooterContentClassName: `font-thin text-[12px] pl-10 md:pl-4 lg:pl-7 2xl:pl-4 pr-5 md:pr-6 lg:pr-0 xl:pr-3 self-center md:w-[90%] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[15px] leading-4 tracking-tight text-black ${
            getFont('CENTURY').body
        }`
    },
    commonComponents: {
        filterTagClassName: `z-10 text-[#373737] uppercase leading-8 text-[16px] lg:text-[19px] ${
            getFont('CENTURY').body
        }`,
        paginationClass:
            ' text-accent disabled:text-accent disabled:cursor-not-allowed  bg-secondary-focus border-secondary-focus hover:border-secondary-focus hover:bg-secondary-focus disabled:bg-secondary-focus',
        paginationActivePageColor: 'text-primary-content bg-opacity-90',
        backgroundColor: 'bg-accent'
    },
    referralForm: {
        name: 'Century',
        theme: 'centuryTheme',
        color: '#E6C168',
        backgroundImage: centuryImage,
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '100',
            G1: '65',
            B1: '43',
            opacity1: '0.75',
            R2: '100',
            G2: '65',
            B2: '43',
            opacity2: '0.75'
        },
        userTypes: {
            length: 6,
            siteVisitor: true,
            siteVisitorHeading1: '',
            siteVisitorHeading2: '',

            bookedCustomer: true,
            bookedCustomerHeading1: '',
            bookedCustomerHeading2: '',

            employee: true,
            employeeHeading1: '',
            employeeHeading2: '',

            salesAdmin: true,
            salesAdminHeading1: 'I am a ',
            salesAdminHeading2: 'Century Employee',
            influencer: true,
            influencerHeading1: '',
            influencerHeading2: ''
        },
        formContent: {
            heading: '"CIRCLE OF COMMUNITY"',
            headingClass: `self-center text-center lg:text-left text-neutral font-semibold mx-auto text-4xl lg:text-5xl xl:text-7xl w-[90%] md:w-[80%] tracking-widest ${
                getFont('CENTURY').body
            }`,
            notification: {
                line1: ' ',
                line1Class: `
                `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            employeeDisclaimer: false
        },
        leadEmailMandatory: true,
        termsAndConditionsLink: '/century-website/terms-and-conditions',
        customLink: '/century-website/projects/detail'
    },

    components: {
        card: {
            showHeader: true,
            showImage: true,
            showFooter: true
        }
    }
};
export default centuryConfig;
