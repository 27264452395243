import React from 'react';
import GrClose from '@meronex/icons/gr/GrClose';
import Modal from '../../Components/Organisms/Modal';
import { winnreAuth } from '../../utils/axios';

const CancelModal = ({
    cancelVisit,
    setShowCancelModal,
    showCancelModal,
    loading
}) => {

 

    return (
        <Modal
            visible={showCancelModal}
            onClick={() => {
                if (loading) return;
                setShowCancelModal(false);
            }}
        >
            <div className="flex justify-end px-4 ">
                <GrClose
                    onClick={() => {
                        if (loading) return;
                        setShowCancelModal(false);
                    }}
                    className={` h-5 w-5 cursor-pointer `}
                />
            </div>
            <div className="py-6 flex flex-col">
                <p
                    className={`
             z-10 mt-2 mx-auto w-[90%] lg:w-[80%] text-center `}
                >
                    Are you sure you want to cancel your visit?
                </p>
                <div className="flex flex-row align-items-center justify-center mt-2">
                    <button
                        onClick={() => setShowCancelModal(false)}
                        className={
                            'bg-secondary justify-self-center px-5 mt-2 py-1.5 text-white text-center mr-2 rounded self-center'
                        }
                        // disabled={loading}
                    >
                        {loading ? 'Loading...' : 'Return'}
                    </button>

                    <button
                        onClick={() => cancelVisit()}
                        className={
                            'bg-primary justify-self-center px-5 mt-2 py-1.5 text-white text-center mr-2 rounded self-center'
                        }
                        disabled={loading}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default CancelModal;
