import React, { useCallback, useEffect, useRef, useState } from 'react';
import BsArrowLeft from '@meronex/icons/bs/BsArrowLeft';
import BsArrowRight from '@meronex/icons/bs/BsArrowRight';
import { useHistory } from 'react-router-dom';
import { isInViewport } from '../../../helpers';
import { scrollToTargetAdjusted } from '../../../utils/ui';

// const images = [
//     {
//         imgUrl: 'https://mahindra-prod.s3.ap-south-1.amazonaws.com/Website/Uploads/Mahindra/Images/78891272.jpg',
//         bgColor: '#3db151',
//         logoUrl: 'https://mldlprodstorage.blob.core.windows.net/live/2021/09/Mahindra-Alcove-logo.png',
//         txtOne: 'Referrals and Earnings',
//         txtTwo: 'Building a community needs trust. As part of M-Life, you can share your lifestyle with friends and family and earn benefits for successfully referring them. With us, you can build a community surrounded by people you love, creating a neighborhood that you feel connected to.',
//         buttonText: 'REFER NOW',
//         scrollTo: 'referral_section',
//         linkTwo: 'https://www.mahindralifespaces.com/real-estate-properties'
//     },
//     {
//         imgUrl: 'https://mahindra-prod.s3.ap-south-1.amazonaws.com/Website/Uploads/Mahindra/Images/7941133.jpg',
//         bgColor: '#045D74',
//         logoUrl: 'https://mldlprodstorage.blob.core.windows.net/live/2021/09/Mahindra-Alcove-logo.png',
//         txtOne: 'Offers and Promotions',
//         txtTwo: 'At M-Life, we are all about creating value-added experiences that add more to your lifestyle. We give you access to premium and pan-india brands that elevate your lifestyle above the ordinary. Our goal is to curate the best experiences and bring them to you.',
//         buttonText: 'AVAIL NOW',
//         path: '/deals',
//         linkTwo: 'https://www.mahindralifespaces.com/real-estate-properties'
//     },
//     {
//         imgUrl: 'https://cdn.loyalie.in/13662471.jpg',
//         bgColor: '#49489D',
//         logoUrl: 'https://mldlprodstorage.blob.core.windows.net/live/2021/09/Mahindra-Alcove-logo.png',
//         txtOne: 'Workshops and Events',
//         txtTwo: 'Everyone needs a break from the monotony of life. Our workshops and events are designed to create engaging experiences with top collaborators, experts, and content creators.',
//         buttonText: 'PARTICIPATE NOW',
//         path: '/events',
//         linkTwo: 'https://www.mahindralifespaces.com/real-estate-properties'
//     },
//     {
//         imgUrl: 'https://cdn.loyalie.in/43584331.jpg',
//         bgColor: '#be8c46',
//         logoUrl: 'https://mldlprodstorage.blob.core.windows.net/live/2021/09/Mahindra-Alcove-logo.png',
//         txtOne: 'Interactive Blogs',
//         txtTwo: 'Reading adds more to what we know and how we perceive the world. As part of the M-Life experience, we bring you bite-sized infotainment to keep adding to the things you know.',
//         buttonText: 'READ NOW',
//         path: '/blogs',
//         linkTwo: 'https://www.mahindralifespaces.com/real-estate-properties'
//     }
// ];

const CustomCarousel = ({
    images,
    carouselHeight,
    buttonClass,
    headingClass,
    bodyClass,
    fullWidth,
    imgClassName,
    showArrows
}) => {
    const [currentImage, setCurrentImage] = React.useState(0);
    const [cursorExited, setCursorExited] = useState(true);
    const intervalRef = useRef();
    const history = useHistory();
    const refs = images.reduce((acc, val, i) => {
        acc[i] = React.createRef();
        return acc;
    }, {});

    useEffect(() => {
        if (intervalRef.current) {
            clearTimeout(intervalRef.current);
        }
        if (!cursorExited) {
            return;
        }
        intervalRef.current = setTimeout(() => {
            const carousel = document.getElementById('customCarousel');
            const inViewport = isInViewport(carousel);
            const position =
                currentImage === images.length - 1 ? 0 : currentImage + 1;
            if (inViewport) {
                scrollToImage(position);
            }
            setCurrentImage(position);
        }, 3000);
    }, [currentImage, cursorExited]);

    const scrollToImage = (i) => {
        // console.log('I', i);
        refs[i]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
        });
    };

    const totalImages = images.length;

    const nextImage = () => {
        // console.log('next clicked', currentImage);
        if (currentImage === totalImages - 1) {
            scrollToImage(0);
            setCurrentImage(0);
        } else {
            scrollToImage(currentImage + 1);
            setCurrentImage((prev) => prev + 1);
        }
    };

    const previousImage = () => {
        // console.log('prev clicked', currentImage);
        if (currentImage === 0) {
            scrollToImage(totalImages - 1);
            setCurrentImage(totalImages - 1);
        } else {
            scrollToImage(currentImage - 1);
            setCurrentImage((prev) => prev - 1);
        }
    };

    const arrowStyle =
        'absolute text-white text-2xl z-10  hover:text-primary bg-secondary-focus hover:bg-white border-[2px] border-white rounded-full bg-[#2f30314d] hover:opacity-100 flex items-center justify-center';

    const sliderControl = (isLeft) => (
        <button
            type="button"
            onClick={isLeft ? previousImage : nextImage}
            className={`${arrowStyle} ${
                isLeft ? 'left-8' : 'right-8'
            } top-[28%] md:top-[50%] lg:top-[45%]`}
        >
            <span role="img" aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
                {isLeft ? (
                    <BsArrowLeft className="h-6 w-6 m-2 md:h-8 md:w-8 md:m-3" />
                ) : (
                    <BsArrowRight className="h-6 w-6 m-2 md:h-8 md:w-8 md:m-3" />
                )}
            </span>
        </button>
    );

    const navigateToPageHandler = (path, scrollTo) => {
        if (path) {
            history.push(path);
        }
        if (scrollTo) {
            scrollToTargetAdjusted(scrollTo);
        }
    };

    return (
        <div
            id="customCarousel"
            className="flex justify-center w-full items-center"
        >
            <div className="relative w-full">
                <div className="carousel">
                    {showArrows && sliderControl(true)}
                    {images.map((data, i) => {
                        return (
                            <>
                                <div
                                    className={`w-full ${
                                        fullWidth ? '' : 'lg:w-[90%]'
                                    } ${
                                        carouselHeight
                                            ? carouselHeight
                                            : 'h-auto lg:h-[647px]'
                                    } flex-shrink-0`}
                                    key={data.imgUrl}
                                    ref={refs[i]}
                                    style={{
                                        background: data.bgColor
                                    }}
                                    onMouseOver={() => {
                                        // console.log('mouse over', currentImage);
                                        setCursorExited(false);
                                        // clearTimeout(intervalRef.current);
                                    }}
                                    onMouseLeave={() => {
                                        setCursorExited(true);
                                        // if (
                                        //     currentImage ===
                                        //     images.length - 1
                                        // ) {
                                        //     setCurrentImage(images.length - 1);
                                        // } else {
                                        //     setCurrentImage(0);
                                        // }
                                    }}
                                >
                                    <div className="lg:flex w-full h-full">
                                        <div className="w-full lg:w-[60%] ">
                                            <img
                                                src={data.imgUrl}
                                                className={`${
                                                    imgClassName
                                                        ? imgClassName
                                                        : 'w-full object-cover h-full'
                                                }`}
                                            />
                                        </div>
                                        <div className=" w-full lg:w-[40%] px-12 text-left my-8 m-auto self-center">
                                            <h1
                                                className={`${
                                                    headingClass
                                                        ? headingClass
                                                        : 'text-white font-semibold font-SourceSansPro text-[35px] font-heading leading-8 '
                                                }`}
                                            >
                                                {data.txtOne}
                                            </h1>
                                            <p
                                                className={`${
                                                    bodyClass
                                                        ? bodyClass
                                                        : 'text-white py-5 text-[20px] leading-6 font-normal font-SourceSansPro'
                                                } `}
                                            >
                                                {data.txtTwo}
                                            </p>
                                            <button
                                                onClick={() => {
                                                    navigateToPageHandler(
                                                        data?.path,
                                                        data?.scrollTo
                                                    );
                                                }}
                                                className={buttonClass}
                                            >
                                                {data.buttonText}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {i === images.length - 1 && (
                                    <div className="hidden lg:block w-[10%] bg-blue-200 h-[75vh] flex-shrink-0">
                                        <img
                                            src={images[0].imgUrl}
                                            className=" h-full w-full object-cover object-left"
                                        />
                                    </div>
                                )}
                            </>
                        );
                    })}
                    {showArrows && sliderControl()}
                </div>
            </div>
        </div>
    );
};

export default CustomCarousel;
