import react, { useEffect, useState } from 'react';
import useAxios from '../../Hooks/use-axios';
import { getConfig } from '../../utils/config';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/auth-slice';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import useSearchInput from '../../Hooks/useSearchInput';
import { cmsClient, deleteWinnreAuthToken } from '../../utils/axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useData = () => {
    const [topOffers, setTopOffers] = useState([]);
    const [benefits, setBenefits] = useState([]);
    const [moveInOffers, setMoveInOffers] = useState([]);
    const [financeOffers, setFinanceOffers] = useState([]);
    const [digitalAmenities, setDigitalAmenities] = useState([]);
    const [bundleOffers, setBundleOffers] = useState([])
    const [vendors, setVendors] = useState([])

    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const tagID = queryParams.get('tagid');

    useEffect(() => {
        if(tagID == Number(65)){
            history.push('/bundle-deals')
        }
    }, [tagID])

    const callback = (searchValue) => {
        window?.webengage?.track('OfferSearch', {
            'Keyword ': `${searchValue}`
        });
        getLifestyleOffers();
        getMoveInOffers();
        getFinanceOffers();
        getDigitalAmenities();
        getBundleOffers()
    };
    const [searchInput, handleChange] = useSearchInput('', callback);
    const [unauthorizedUserMessage, setUnauthorizedUserMessage] =
        useState(null);

    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const isInternalWebview = useSelector(
        (state) => state.webView.internalWebview
    );

    const config = getConfig();

    const logout = () => {
        setBenefits([]);
        setMoveInOffers([]);
        setFinanceOffers([]);
        setDigitalAmenities([]);
        window?.webengage?.track('LogOut');
        window?.webengage?.user?.logout();
        if (config?.autoLogout) {
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('accessToken');
        } else {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
        }
        deleteWinnreAuthToken();
        dispatch(authActions.logout());
    };

    const {
        isLoading: isLoadingBenefits,
        error,
        sendRequest: fetchBenefits
    } = useAxios();

    const { isLoading: isLoadingTopDeals, sendRequest: fetchTopOffers } =
        useAxios();
    const { isLoading: isLoadingFinance, sendRequest: fetchFinanceOffers } =
        useAxios();
    const { isLoading: isLoadingMovein, sendRequest: fetchMoveInOffers } =
        useAxios();
    const { isLoading: isLoadingDigital, sendRequest: fetchDigitalAmenities } =
        useAxios();
    const { isLoading: isLoadingBundle, sendRequest: fetchBundleOffers } =
        useAxios();

    const date = moment().format();

    const getBundleOffers = () => {
        // console.log('TOP OFFERS');

        let url;
        if (isAuthenticated) {
            url = `/winnre/user/deals/filter-v2?&status=PUBLISHED&isAscending=false&sortBy=priority&endDate=${date}&categoryList=8&pageSize=10${searchInput.length > 0 ? `&searchValue=${searchInput}` : ''}`;
        } else {
            url = `/cms_service/deals/filter-v2?isVisibleToAll=true&endDate=${date}&&status=PUBLISHED&clientId=${config?.id
                }&pageSize=10&isAscending=false&sortBy=priority&categoryList=8${searchInput.length > 0 ? `&searchValue=${searchInput}` : ''}`;
        }
        let method = 'get';

        fetchBundleOffers(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url,
                method,
                isFormData: true
            },
            (data) => {
                console.log("bundle offers all", data)
                if (isAuthenticated) {
                    if (
                        data?.status === 'UNAUTHORIZED' ||
                        data?.status === 'FAILED'
                    ) {
                        setBundleOffers([]);
                        toast.error('Failed to load bundle offers')
                        return;
                    }
                    if (
                        data?.status === 'TOKEN_EXPIRED' ||
                        data?.status === 'INVALID_TOKEN'
                    ) {
                        if (isInternalWebview) {
                            dispatch(
                                authActions.setUnauthorizedUser(data.message)
                            );
                            return;
                        }
                        logout();
                        return;
                    }
                }
                
                setBundleOffers(data?.content || []);
                
            }
        );
    };

    const getTopOffers = () => {
        // console.log('TOP OFFERS');

        let url;
        if (isAuthenticated) {
            url = `/winnre/user/deals/filter-v2?&status=PUBLISHED&isAscending=false&sortBy=priority&endDate=${date}&categoryList=1,2,3,5&pageSize=10&tagList=52`;
        } else {
            url = `/cms_service/deals/filter-v2?isVisibleToAll=true&tagList=52&endDate=${date}&&status=PUBLISHED&clientId=${config?.id
                }&pageSize=10&isAscending=false&sortBy=priority&categoryList=1,2,3,5`;
        }
        let method = 'get';

        fetchTopOffers(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url,
                method,
                isFormData: true
            },
            (data) => {
                if (isAuthenticated) {
                    if (
                        data?.status === 'UNAUTHORIZED' ||
                        data?.status === 'FAILED'
                    ) {
                        setTopOffers([]);
                        toast.error('Failed to load offers of the month')

                        return;
                    }
                    if (
                        data?.status === 'TOKEN_EXPIRED' ||
                        data?.status === 'INVALID_TOKEN'
                    ) {
                        if (isInternalWebview) {
                            dispatch(
                                authActions.setUnauthorizedUser(data.message)
                            );
                            return;
                        }
                        logout();
                        return;
                    }
                }
                console.log("TopOffers" , data?.content);
                setTopOffers(data?.content || []);
            }
        );
    };

    const getVendors = () => {
        cmsClient({method:'GET', url: `/cms_service/vendors/getAllByFilter?clientId=${config?.id}&pageSize=30&categoryIds=1,2,3,5,8`}).then((res) => {console.log('vendor data', res)
            

            if (
                res?.status === 'TOKEN_EXPIRED' ||
                res?.status === 'INVALID_TOKEN'
            ) {
                if (isInternalWebview) {
                    dispatch(
                        authActions.setUnauthorizedUser(res.message)
                    );
                    return;
                }
                logout();
                return;
            }
        
        setVendors(res?.data?.content)
        })
    }

    const getLifestyleOffers = () => {
        // console.log('LIFESTYLE BENEFITS');

        let url;
        if (isAuthenticated) {
            url = `/winnre/user/deals/filter-v2?&status=PUBLISHED&isAscending=false&sortBy=priority&endDate=${date}&pageSize=5&categoryList=1${searchInput.length > 0 ? `&searchValue=${searchInput}` : ''}`;
        } else {
            url = `/cms_service/deals/filter-v2?isVisibleToAll=true&&endDate=${date}&status=PUBLISHED&clientId=${config?.id
                }&pageSize=5&isAscending=false&sortBy=priority&categoryList=1${searchInput.length > 0 ? `&searchValue=${searchInput}` : ''
                }`;
        }
        let method = 'get';

        fetchBenefits(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url,
                method,
                isFormData: true
            },
            (data) => {
                // console.log('data', data);
                if (isAuthenticated) {
                    if (
                        data?.status === 'UNAUTHORIZED' ||
                        data?.status === 'FAILED'
                    ) {
                        setUnauthorizedUserMessage(
                            data?.status === 'UNAUTHORIZED'
                                ? 'Become our customer to avail deals.'
                                : 'Something went wrong. Could not load offers. Please try again!'
                        );
                        toast.error('Failed to load member benefits')

                        setBenefits([]);
                        return;
                    }
                    if (
                        data?.status === 'TOKEN_EXPIRED' ||
                        data?.status === 'INVALID_TOKEN'
                    ) {
                        if (isInternalWebview) {
                            dispatch(
                                authActions.setUnauthorizedUser(data.message)
                            );
                            return;
                        }
                        logout();
                        return;
                    }
                }
                setBenefits(data.content || []);
            }
        );
    };

    const getMoveInOffers = () => {
        // console.log('move in');

        let url;
        // console.log('TGAS WHEN GETTING DEALS', selectedCategories);
        if (isAuthenticated) {
            url = `/winnre/user/deals/filter-v2?&status=PUBLISHED&isAscending=false&endDate=${date}&sortBy=priority&pageSize=5&categoryList=2${searchInput.length > 0 ? `&searchValue=${searchInput}` : ''}`;
        } else {
            url = `/cms_service/deals/filter-v2?isVisibleToAll=true&&status=PUBLISHED&clientId=${config?.id
                }&pageSize=5&isAscending=false&sortBy=priority&endDate=${date}&categoryList=2${searchInput.length > 0 ? `&searchValue=${searchInput}` : ''
                }`;
        }
        let method = 'get';

        fetchMoveInOffers(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url,
                method,
                // headers: isAuthenticated ? headers2 : '',
                // data: data1,
                isFormData: true
            },
            (data) => {
                // console.log('DEAL DATA', data);
                if (isAuthenticated) {
                    if (
                        data?.status === 'UNAUTHORIZED' ||
                        data?.status === 'FAILED'
                    ) {
                        // console.log('failed');

                        setUnauthorizedUserMessage(
                            data?.status === 'UNAUTHORIZED'
                                ? 'Become our customer to avail deals.'
                                : 'Something went wrong. Could not load offers. Please try again!'
                        );
                        toast.error('Failed to load move in offers')

                        setMoveInOffers([]);
                        return;
                    }
                    if (
                        data?.status === 'TOKEN_EXPIRED' ||
                        data?.status === 'INVALID_TOKEN'
                    ) {
                        if (isInternalWebview) {
                            dispatch(
                                authActions.setUnauthorizedUser(data.message)
                            );
                            return;
                        }
                        logout();
                        return;
                    }
                }
                setMoveInOffers(data.content || []);
            }
        );
    };
    const getFinanceOffers = () => {
        // console.log('FINANCE');

        let url;
        if (isAuthenticated) {
            url = `/winnre/user/deals/filter-v2?&status=PUBLISHED&isAscending=false&endDate=${date}&sortBy=priority&pageSize=5&categoryList=3${searchInput.length > 0 ? `&searchValue=${searchInput}` : ''}`;
        } else {
            url = `/cms_service/deals/filter-v2?isVisibleToAll=true&&status=PUBLISHED&clientId=${config?.id
                }&pageSize=5&isAscending=false&sortBy=priority&endDate=${date}&categoryList=3${searchInput.length > 0 ? `&searchValue=${searchInput}` : ''
                }`;
        }
        let method = 'get';

        fetchFinanceOffers(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url,
                method,
                isFormData: true
            },
            (data) => {
                if (isAuthenticated) {
                    if (
                        data?.status === 'UNAUTHORIZED' ||
                        data?.status === 'FAILED'
                    ) {
                        setUnauthorizedUserMessage(
                            data?.status === 'UNAUTHORIZED'
                                ? 'Become our customer to avail deals.'
                                : 'Something went wrong. Could not load offers. Please try again!'
                        );
                        toast.error('Failed to load home financing deals')

                        setFinanceOffers([]);
                        return;
                    }
                    if (
                        data?.status === 'TOKEN_EXPIRED' ||
                        data?.status === 'INVALID_TOKEN'
                    ) {
                        if (isInternalWebview) {
                            dispatch(
                                authActions.setUnauthorizedUser(data.message)
                            );
                            return;
                        }
                        logout();
                        return;
                    }
                }
                setFinanceOffers(data.content || []);
            }
        );
    };

    const getDigitalAmenities = () => {
        // console.log('DIGITAL AMENITIES');
        let url;
        if (isAuthenticated) {
            url = `/winnre/user/deals/filter-v2?&status=PUBLISHED&isAscending=false&endDate=${date}&sortBy=priority&pageSize=5&categoryList=5${searchInput.length > 0 ? `&searchValue=${searchInput}` : ''}`;
        } else {
            url = `/cms_service/deals/filter-v2?isVisibleToAll=true&&status=PUBLISHED&endDate=${date}&clientId=${config?.id
                }&pageSize=5&isAscending=false&sortBy=priority&categoryList=5${searchInput.length > 0 ? `&searchValue=${searchInput}` : ''
                }`;
        }
        let method = 'get';

        fetchDigitalAmenities(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url,
                method,
                isFormData: true
            },
            (data) => {
                // console.log('data', data);
                if (isAuthenticated) {
                    if (
                        data?.status === 'UNAUTHORIZED' ||
                        data?.status === 'FAILED'
                    ) {
                        setUnauthorizedUserMessage(
                            data?.status === 'UNAUTHORIZED'
                                ? 'Become our customer to avail deals.'
                                : 'Something went wrong. Could not load offers. Please try again!'
                        );
                        toast.error('Failed to load digital amenities')

                        setBenefits([]);
                        return;
                    }
                    if (
                        data?.status === 'TOKEN_EXPIRED' ||
                        data?.status === 'INVALID_TOKEN'
                    ) {
                        if (isInternalWebview) {
                            dispatch(
                                authActions.setUnauthorizedUser(data.message)
                            );
                            return;
                        }
                        logout();
                        return;
                    }
                }
                setDigitalAmenities(data.content || []);
            }
        );
    };

    useEffect(() => {

            getTopOffers();
            getVendors()
            getLifestyleOffers();
            getFinanceOffers();
            getMoveInOffers();
            getDigitalAmenities();
            getBundleOffers()
    }, []);

    return {
        bundleOffers,
        topOffers,
        benefits,
        financeOffers,
        moveInOffers,
        digitalAmenities,
        vendors,
        isLoadingTopDeals,
        isLoadingBenefits,
        isLoadingFinance,
        isLoadingMovein,
        isLoadingDigital,
        error,
        unauthorizedUserMessage,
        handleChange,
        searchInput,
        isLoadingBundle
    };
};

export default useData;
