import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { scrollToTargetAdjusted } from '../../../utils/ui';

const P = styled.p`
    ${(props) => props.color && `color: ${props.color} !important;`}
    ${(props) => props.fontSize && `font-size: ${props.fontSize} !important;`}
    ${(props) =>
        props.fontFamily && `font-family: ${props.fontFamily} !important;`}
`;

const Text = (props) => {
    const {
        content,
        color,
        fontSize,
        fontFamily,
        className,
        children,
        path,
        onClick,
        scrollTo
    } = props;
    const history = useHistory();

    const changePath = (path) => {
        history.push(path);
    };
    const onClickHandler = (type) => {
        switch (type) {
            case 'CHANGE_PATH':
                return changePath(path);
            case 'SCROLL':
                return scrollToTargetAdjusted(scrollTo);
            default:
                return;
        }
    };

    if (!content) {
        return <p></p>;
    } else {
        return (
            <P
                color={color}
                fontSize={fontSize}
                fontFamily={fontFamily}
                className={className || ''}
                onClick={() => onClickHandler(onClick)}
            >
                {children}
            </P>
        );
    }
};

export default Text;
