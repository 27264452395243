import React, { useEffect } from 'react';
import ReactDom from 'react-dom';

const Overlay = ({ visible, onClick, opacity }) => {
    return (
        <div
            onClick={onClick}
            className={` z-30 inset-0 bg-gray-700 h-full w-full  ${
                visible ? 'fixed' : 'hidden'
            }
                 ${opacity ? opacity : 'bg-opacity-70'}`}
            id="my-modal"
        ></div>
    );
};

const Modal = (props) => {
    const {
        visible,
        onClick,
        content,
        component,
        opacity,
        showButton = true,
        changeCurrentStep
    } = props;
    // const body = document.getElementsByTagName('body')[0];
    // console.log('body', body);

    useEffect(() => {
        if (visible) {
            document.documentElement.style.overflow = 'hidden';
            document.documentElement.style.position = 'fixed';
            document.body.scroll = 'no';
        } else {
            document.documentElement.style.overflow = 'scroll';
            document.documentElement.style.position = '';
            document.body.scroll = 'yes';
        }
        return () => {
            document.documentElement.style.overflow = 'scroll';
            document.documentElement.style.position = '';
            document.body.scroll = 'yes';
        };
    }, [visible]);

    return ReactDom.createPortal(
        <>
            <Overlay visible={visible} onClick={onClick} opacity={opacity} />
            {visible && (
                <div
                    className={`${
                        visible ? 'fixed' : 'hidden'
                    } top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mx-auto w-full z-40 m-8`}
                >
                    {props.children ? (
                        props.children
                    ) : (
                        <div className="shadow-lg font-semibold mx-auto  w-4/5 md:w-1/2 lg:w-1/3 bg-white text-center rounded-lg p-8">
                            <h1>{content}</h1>
                            {component}
                            <button
                                onClick={onClick}
                                className=" bg-primary hover:opacity-80 text-white font-semibold py-1 px-6 mt-4 rounded "
                                type="button"
                            >
                                OK
                            </button>
                        </div>
                    )}
                </div>
            )}
        </>,
        document.getElementById('referralModal')
    );
};

export default Modal;
