import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 340,
            md: 768,
            lg: 1024,
            xl: 1280,
            dxl: 1536,
            txl: 1900
        }
    }
});

export default theme;
