import { useDispatch, useSelector } from 'react-redux';
import { winnreAuth } from '../../utils/axios';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { authActions } from '../../store/auth-slice';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useDidMountEffect from '../../Hooks/useDidMount';
import useTagsData from '../../Components/Molecules/FilterOptions/data';

const useData = () => {
    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    const clickThroughMobile = clientWebview || internalWebview;
    const [showBlockedBrands, setShowBlockedBrands] = useState(false);
    const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
    const [enquiries, setEnquiries] = useState([
        // {
        //     cashbackValue: 20,
        //     dealId: 0,
        //     dealName: 'Flat 20% discount on Home Interiors',
        //     enquiryDate: '2023-09-06T10:44:57.818Z',
        //     enquiryStatus: 'CALL_COMPLETED',
        //     enquiryStatusReason: 'string',
        //     id: 0,
        //     vendorId: 0,
        //     vendorLogoUrl:
        //         'https://cdn.loyalie.in/1652958597329_myntra-logo.jpg',
        //     vendorName: 'Myntra'
        // },
        // {
        //     cashbackValue: 20,
        //     dealId: 0,
        //     dealName: 'Flat 20% discount on Home Interiors',
        //     enquiryDate: '2023-09-06T10:44:57.818Z',
        //     enquiryStatus: 'CASHBACK_DISBURSED',
        //     enquiryStatusReason: 'string',
        //     id: 1,
        //     vendorId: 0,
        //     vendorLogoUrl:
        //         'https://cdn.loyalie.in/1652958597329_myntra-logo.jpg',
        //     vendorName: 'Myntra'
        // },
        // {
        //     cashbackValue: 20,
        //     dealId: 0,
        //     dealName: 'Flat 20% discount on Home Interiors',
        //     enquiryDate: '2023-09-06T10:44:57.818Z',
        //     enquiryStatus: 'CASHBACK_REJECTED',
        //     enquiryStatusReason: 'string',
        //     id: 2,
        //     vendorId: 0,
        //     vendorLogoUrl:
        //         'https://cdn.loyalie.in/1652958597329_myntra-logo.jpg',
        //     vendorName: 'Myntra'
        // },
        // {
        //     cashbackValue: 30,
        //     dealId: 0,
        //     dealName: 'Flat 30% discount on Home Interiors',
        //     enquiryDate: '2023-09-06T10:44:57.818Z',
        //     enquiryStatus: 'INVOICE_IN_REVIEW',
        //     enquiryStatusReason: 'string',
        //     id: 2,
        //     vendorId: 0,
        //     vendorLogoUrl:
        //         'https://cdn.loyalie.in/1652958597329_myntra-logo.jpg',
        //     vendorName: 'Myntra'
        // }
    ]);
    const [enquiryError, setEnquiryError] = useState(false);
    const [enquiryLoading, setEnquiryLoading] = useState(false);
    const [selectedDealId, setSelectedDealId] = useState(false);
    const [showInvoiceModal, setInvoiceModalVisibility] = useState(false);
    const [showNotInterestedModal, setNotInterestedModalVisibility] =
        useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentFilters, setCurrentFilters] = useState([]);
    const [currentTags, setCurrentTags] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [summary, setSummary] = useState(null);
    const [summaryLoading, setSummaryLoading] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const { isAuthenticated } = useSelector((state) => state.auth);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const channel = queryParams.get('utm_medium');
    let accessToken = queryParams.get('token');
    const dispatch = useDispatch();
    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
    };

    const { categories: tags, isLoading: isTagsLoading } = useTagsData({
        type: 'DEAL'
    });

    const onCategoryClick = (id) => {
        setCurrentCategory(id);
    };

    const onFilterClick = ({ status, removeAll }) => {
        if (removeAll) {
            setCurrentFilters([]);
            return;
        }
        if (currentFilters.includes(status)) {
            setCurrentFilters(currentFilters.filter((f) => f != status));
        } else {
            setCurrentFilters([...currentFilters, status]);
        }
    };

    const onTagClick = (id) => {
        if (currentTags.includes(id)) {
            setCurrentTags(currentTags.filter((c) => c != id));
        } else {
            setCurrentTags([...currentTags, id]);
        }
    };

    const clearAll = () => {
        setCurrentTags([]);
        setCurrentFilters([]);
        setCurrentCategory(null);
        setCurrentPage(0);
    };

    const getSummary = () => {
        setSummaryLoading(true);
        setSummary(null);
        winnreAuth({
            url: '/winnre/user/deals/cashback-enquires/summary',
            method: 'GET'
        })
            .then((res) => {
                console.log('cashback', res)
                if (
                    res?.status === 'INVALID_TOKEN' ||
                    res?.status === 'FAILED'
                ) {
                    setSummaryLoading(false);
                    toast.error('Something went wrong');
                    return;
                }
                setSummary(res);
                setSummaryLoading(false);
            })
            .catch((err) => {
                setSummaryLoading(false);
                toast.error('Something went wrong');
            });
    };

    const getEnquiries = () => {
        setEnquiryLoading(true);
        setEnquiryError(false);
        winnreAuth({
            url: `/winnre/user/deals/cashback-enquires?pageSize=5&pageNumber=${currentPage}${
                currentFilters?.length > 0
                    ? `&statuses=${currentFilters.join(',')}`
                    : ''
            }${
                currentTags.length > 0 ? `&tagIds=${currentTags.join(',')}` : ''
            }${!!currentCategory ? `&categoryIds=${currentCategory}` : ''}`,
            method: 'GET'
        })
            .then((res) => {
                // console.log(res);
                if (
                    res?.status === 'INVALID_TOKEN' ||
                    res?.status === 'FAILED'
                ) {
                    setEnquiryError(true);
                    setEnquiryLoading(false);
                    return;
                }
                setEnquiries(res.content);
                setTotalPages(res.totalPages);
                setEnquiryLoading(false);
            })
            .catch((err) => {
                setEnquiryError(true);
                setEnquiryLoading(false);
            });
    };

    const onRefresh = () => {
        getSummary();
        getEnquiries();
    };

    const updateStatus = (status, dealId) => {
        setUpdateStatusLoading(true);
        winnreAuth({
            url: `winnre/user/deals/cashback-enquires/${dealId}/update-status?status=${status}`,
            method: 'POST'
        })
            .then((res) => {
                if (
                    res?.status === 'INVALID_TOKEN' ||
                    res?.status === 'FAILED'
                ) {
                    setUpdateStatusLoading(false);
                    toast.error('Something Went Wrong');
                    return;
                }
                setUpdateStatusLoading(false);
                setSelectedDealId(null);
                toast.success('Status Updated Successfully');
                setNotInterestedModalVisibility(false);
                onRefresh();
            })
            .catch(() => {
                setUpdateStatusLoading(false);
                toast.error('Something Went Wrong');
            });
    };

    const onReopen = (id) => {
        setUpdateStatusLoading(true);
        winnreAuth({
            method: 'POST',
            url: `winnre/user/deals/redeem?dealId=${id}&sourcePlatform=${
                clickThroughMobile ? 'MOBILE' : 'WEBSITE'
            }${channel ? `&channel=${channel}` : ''}`
        })
            .then((res) => {
                if (
                    res?.status === 'INVALID_TOKEN' ||
                    res?.status === 'FAILED'
                ) {
                    setUpdateStatusLoading(false);
                    toast.error(res.message || 'Something Went Wrong');
                    return;
                }
                setUpdateStatusLoading(false);
                setSelectedDealId(null);
                toast.success('Enquiry Re-Opened Successfully');
                setNotInterestedModalVisibility(false);
                onRefresh();
            })
            .catch(() => {
                setUpdateStatusLoading(false);
                toast.error('Something Went Wrong');
            });
    };

    const updateInvoice = (file, dealId) => {
        const formData = new FormData();
        formData.append('invoice', file);
        setUpdateStatusLoading(true);
        winnreAuth({
            url: `winnre/user/deals/cashback-enquires/${dealId}/upload-invoice`,
            method: 'POST',
            data: formData
        })
            .then((res) => {
                if (
                    res?.status === 'INVALID_TOKEN' ||
                    res?.status === 'FAILED'
                ) {
                    setUpdateStatusLoading(false);
                    toast.error('Something Went Wrong');
                    return;
                }
                setUpdateStatusLoading(false);
                setSelectedDealId(null);
                toast.success('Invoice Uploaded Successfully');
                setInvoiceModalVisibility(false);
                onRefresh();
            })
            .catch(() => {
                setUpdateStatusLoading(false);
                toast.error('Something Went Wrong');
            });
    };

    useEffect(() => {
        if (!(showInvoiceModal || showNotInterestedModal)) {
            setSelectedDealId(null);
        }
    }, [showInvoiceModal, showNotInterestedModal]);

    useEffect(() => {
        onRefresh();
    }, []);

    useDidMountEffect(() => {
        getEnquiries();
    }, [currentFilters, currentPage, currentCategory, currentTags]);

    const state = {
        showBlockedBrands,
        isAuthenticated,
        summaryLoading,
        summary,
        currentFilters,
        totalPages,
        currentPage,
        enquiries,
        enquiryError,
        enquiryLoading,
        showInvoiceModal,
        showNotInterestedModal,
        selectedDealId,
        updateStatusLoading,
        showFilterModal,
        currentCategory,
        tags,
        isTagsLoading,
        currentTags
    };

    return {
        state,
        openLoginModal,
        accessToken,
        setShowBlockedBrands,
        onFilterClick,
        setCurrentPage,
        setInvoiceModalVisibility,
        setNotInterestedModalVisibility,
        setSelectedDealId,
        updateStatus,
        onRefresh,
        updateInvoice,
        onReopen,
        setShowFilterModal,
        onCategoryClick,
        onTagClick,
        clearAll
    };
};

export default useData;
