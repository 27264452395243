import { MenuItem, Select, Slider, Stack, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getConfig } from '../../../../utils/config';

const AirbnbSlider = styled(Slider)(({ theme, config }) => ({
    color: '#FFB300',
    height: 4,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 18,
        width: 18,
        backgroundColor: config.primaryColor,
        border: `1px solid ${config.primaryColor}`,
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)'
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: config.primaryColor,
            marginLeft: 1,
            marginRight: 1
        }
    },
    '& .MuiSlider-track': {
        height: 3,
        color: config.primaryColor
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
        opacity: theme.palette.mode === 'dark' ? undefined : 1,
        height: 3
    }
}));

export default function PriceRange({ range, priceUpdate }) {
    const [value1, setValue1] = useState(range);
    const minDistance = 5;
    const config = getConfig()
    const handleChange1 = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue1([
                Math.min(newValue[0], value1[1] - minDistance),
                value1[1]
            ]);
        } else {
            setValue1([
                value1[0],
                Math.max(newValue[1], value1[0] + minDistance)
            ]);
        }
    };
    const valuetext = (value) => {
        return `${value}Lac`;
    };

    useEffect(() => {
        // console.log(value1);
        priceUpdate(value1);
    }, [value1]);

    return (
        <div className="mx-2">
            <AirbnbSlider
            config={config}
                getAriaLabel={() => 'Price Range'}
                value={value1}
                onChange={handleChange1}
                valueLabelDisplay="off"
                getAriaValueText={valuetext}
                disableSwap
            />
            <Stack direction="row" spacing={2} maxHeight={40}>
                <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value1[0]}
                    // label="Min Val"
                    displayEmpty
                    inputProps={{ readOnly: true }}
                    // inputProps={{ 'aria-label': 'Without label' }}
                    // onChange={(val) => {
                    //     let newValue = [val.target.value, value1[1]];
                    //     // value1[0] = val.target.value;
                    //     setValue1(newValue);
                    // }}
                >
                    <MenuItem value={0}>5 Lac</MenuItem>
                    <MenuItem value={1}>10 Lac</MenuItem>
                    <MenuItem value={2}>20 Lac</MenuItem>
                    <MenuItem value={3}>30 Lac</MenuItem>
                    <MenuItem value={4}>40 Lac</MenuItem>
                    <MenuItem value={5}>50 Lac</MenuItem>
                    <MenuItem value={6}>60 Lac</MenuItem>
                    <MenuItem value={7}>70 Lac</MenuItem>
                    <MenuItem value={8}>80 Lac</MenuItem>
                    <MenuItem value={9}>90 Lac</MenuItem>
                    <MenuItem value={10}>1 Cr</MenuItem>
                    <MenuItem value={11}>1 Cr 10 Lac</MenuItem>
                    <MenuItem value={12}>1 Cr 20 Lac</MenuItem>
                    <MenuItem value={13}>1 Cr 30 Lac</MenuItem>
                    <MenuItem value={14}>1 Cr 40 Lac</MenuItem>
                    <MenuItem value={15}>1 Cr 50 Lac</MenuItem>
                    <MenuItem value={16}>1 Cr 60 Lac</MenuItem>
                    <MenuItem value={17}>1 Cr 70 Lac</MenuItem>
                    <MenuItem value={18}>1 Cr 80 Lac</MenuItem>
                    <MenuItem value={19}>1 Cr 90 Lac</MenuItem>
                    <MenuItem value={20}>2 Cr</MenuItem>
                    <MenuItem value={21}>2 Cr 10 Lac</MenuItem>
                    <MenuItem value={22}>2 Cr 20 Lac</MenuItem>
                    <MenuItem value={23}>2 Cr 30 Lac</MenuItem>
                    <MenuItem value={24}>2 Cr 40 Lac</MenuItem>
                    <MenuItem value={25}>2 Cr 50 Lac</MenuItem>
                    <MenuItem value={26}>2 Cr 60 Lac</MenuItem>
                    <MenuItem value={27}>2 Cr 70 Lac</MenuItem>
                    <MenuItem value={28}>2 Cr 80 Lac</MenuItem>
                    <MenuItem value={29}>2 Cr 90 Lac</MenuItem>
                    <MenuItem value={30}>3 Cr</MenuItem>
                    <MenuItem value={31}>3 Cr 10 Lac</MenuItem>
                    <MenuItem value={32}>3 Cr 20 Lac</MenuItem>
                    <MenuItem value={33}>3 Cr 30 Lac</MenuItem>
                    <MenuItem value={34}>3 Cr 40 Lac</MenuItem>
                    <MenuItem value={35}>3 Cr 50 Lac</MenuItem>
                    <MenuItem value={36}>3 Cr 60 Lac</MenuItem>
                    <MenuItem value={37}>3 Cr 70 Lac</MenuItem>
                    <MenuItem value={38}>3 Cr 80 Lac</MenuItem>
                    <MenuItem value={39}>3 Cr 90 Lac</MenuItem>
                    <MenuItem value={40}>4 Cr</MenuItem>
                    <MenuItem value={41}>4 Cr 10 Lac</MenuItem>
                    <MenuItem value={42}>4 Cr 20 Lac</MenuItem>
                    <MenuItem value={43}>4 Cr 30 Lac</MenuItem>
                    <MenuItem value={44}>4 Cr 40 Lac</MenuItem>
                    <MenuItem value={45}>4 Cr 50 Lac</MenuItem>
                    <MenuItem value={46}>4 Cr 60 Lac</MenuItem>
                    <MenuItem value={47}>4 Cr 70 Lac</MenuItem>
                    <MenuItem value={48}>4 Cr 80 Lac</MenuItem>
                    <MenuItem value={49}>4 Cr 90 Lac</MenuItem>
                    <MenuItem value={50}>5 Cr</MenuItem>
                    <MenuItem value={51}>5 Cr 10 Lac</MenuItem>
                    <MenuItem value={52}>5 Cr 20 Lac</MenuItem>
                    <MenuItem value={53}>5 Cr 30 Lac</MenuItem>
                    <MenuItem value={54}>5 Cr 40 Lac</MenuItem>
                    <MenuItem value={55}>5 Cr 50 Lac</MenuItem>
                    <MenuItem value={56}>5 Cr 60 Lac</MenuItem>
                    <MenuItem value={57}>5 Cr 70 Lac</MenuItem>
                    <MenuItem value={58}>5 Cr 80 Lac</MenuItem>
                    <MenuItem value={59}>5 Cr 90 Lac</MenuItem>
                    <MenuItem value={60}>6 Cr</MenuItem>
                    <MenuItem value={61}>6 Cr 10 Lac</MenuItem>
                    <MenuItem value={62}>6 Cr 20 Lac</MenuItem>
                    <MenuItem value={63}>6 Cr 30 Lac</MenuItem>
                    <MenuItem value={64}>6 Cr 40 Lac</MenuItem>
                    <MenuItem value={65}>6 Cr 50 Lac</MenuItem>
                    <MenuItem value={66}>6 Cr 60 Lac</MenuItem>
                    <MenuItem value={67}>6 Cr 70 Lac</MenuItem>
                    <MenuItem value={68}>6 Cr 80 Lac</MenuItem>
                    <MenuItem value={69}>6 Cr 90 Lac</MenuItem>
                    <MenuItem value={70}>7 Cr</MenuItem>
                    <MenuItem value={71}>7 Cr 10 Lac</MenuItem>
                    <MenuItem value={72}>7 Cr 20 Lac</MenuItem>
                    <MenuItem value={73}>7 Cr 30 Lac</MenuItem>
                    <MenuItem value={74}>7 Cr 40 Lac</MenuItem>
                    <MenuItem value={75}>7 Cr 50 Lac</MenuItem>
                    <MenuItem value={76}>7 Cr 60 Lac</MenuItem>
                    <MenuItem value={77}>7 Cr 70 Lac</MenuItem>
                    <MenuItem value={78}>7 Cr 80 Lac</MenuItem>
                    <MenuItem value={79}>7 Cr 90 Lac</MenuItem>
                    <MenuItem value={80}>8 Cr</MenuItem>
                    <MenuItem value={81}>8 Cr 10 Lac</MenuItem>
                    <MenuItem value={82}>8 Cr 20 Lac</MenuItem>
                    <MenuItem value={83}>8 Cr 30 Lac</MenuItem>
                    <MenuItem value={84}>8 Cr 40 Lac</MenuItem>
                    <MenuItem value={85}>8 Cr 50 Lac</MenuItem>
                    <MenuItem value={86}>8 Cr 60 Lac</MenuItem>
                    <MenuItem value={87}>8 Cr 70 Lac</MenuItem>
                    <MenuItem value={88}>8 Cr 80 Lac</MenuItem>
                    <MenuItem value={89}>8 Cr 90 Lac</MenuItem>
                    <MenuItem value={90}>9 Cr</MenuItem>
                    <MenuItem value={91}>9 Cr 10 Lac</MenuItem>
                    <MenuItem value={92}>9 Cr 20 Lac</MenuItem>
                    <MenuItem value={93}>9 Cr 30 Lac</MenuItem>
                    <MenuItem value={94}>9 Cr 40 Lac</MenuItem>
                    <MenuItem value={95}>9 Cr 50 Lac</MenuItem>
                    {/* <MenuItem value={96}>9 Cr 60 Lac</MenuItem>
                    <MenuItem value={97}>9 Cr 70 Lac</MenuItem>
                    <MenuItem value={98}>9 Cr 80 Lac</MenuItem>
                    <MenuItem value={99}>9 Cr 90 Lac</MenuItem> */}
                    {/* <MenuItem value={100}>10 Cr</MenuItem> */}
                </Select>
                <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    displayEmpty
                    // inputProps={{ 'aria-label': 'Without label' }}
                    inputProps={{ readOnly: true }}
                    id="demo-simple-select"
                    value={value1[1]}
                    // label="Max Val"
                    // onChange={(val) => {
                    //     let newValue = [value1[0], val.target.value];
                    //     setValue1(newValue);
                    // }}
                >
                    {/* <MenuItem value={0}>5 Lac</MenuItem> */}
                    {/* <MenuItem value={1}>10 Lac</MenuItem>
                    <MenuItem value={2}>20 Lac</MenuItem>
                    <MenuItem value={3}>30 Lac</MenuItem>
                    <MenuItem value={4}>40 Lac</MenuItem> */}
                    <MenuItem value={5}>50 Lac</MenuItem>
                    <MenuItem value={6}>60 Lac</MenuItem>
                    <MenuItem value={7}>70 Lac</MenuItem>
                    <MenuItem value={8}>80 Lac</MenuItem>
                    <MenuItem value={9}>90 Lac</MenuItem>
                    <MenuItem value={10}>1 Cr</MenuItem>
                    <MenuItem value={11}>1 Cr 10 Lac</MenuItem>
                    <MenuItem value={12}>1 Cr 20 Lac</MenuItem>
                    <MenuItem value={13}>1 Cr 30 Lac</MenuItem>
                    <MenuItem value={14}>1 Cr 40 Lac</MenuItem>
                    <MenuItem value={15}>1 Cr 50 Lac</MenuItem>
                    <MenuItem value={16}>1 Cr 60 Lac</MenuItem>
                    <MenuItem value={17}>1 Cr 70 Lac</MenuItem>
                    <MenuItem value={18}>1 Cr 80 Lac</MenuItem>
                    <MenuItem value={19}>1 Cr 90 Lac</MenuItem>
                    <MenuItem value={20}>2 Cr</MenuItem>
                    <MenuItem value={21}>2 Cr 10 Lac</MenuItem>
                    <MenuItem value={22}>2 Cr 20 Lac</MenuItem>
                    <MenuItem value={23}>2 Cr 30 Lac</MenuItem>
                    <MenuItem value={24}>2 Cr 40 Lac</MenuItem>
                    <MenuItem value={25}>2 Cr 50 Lac</MenuItem>
                    <MenuItem value={26}>2 Cr 60 Lac</MenuItem>
                    <MenuItem value={27}>2 Cr 70 Lac</MenuItem>
                    <MenuItem value={28}>2 Cr 80 Lac</MenuItem>
                    <MenuItem value={29}>2 Cr 90 Lac</MenuItem>
                    <MenuItem value={30}>3 Cr</MenuItem>
                    <MenuItem value={31}>3 Cr 10 Lac</MenuItem>
                    <MenuItem value={32}>3 Cr 20 Lac</MenuItem>
                    <MenuItem value={33}>3 Cr 30 Lac</MenuItem>
                    <MenuItem value={34}>3 Cr 40 Lac</MenuItem>
                    <MenuItem value={35}>3 Cr 50 Lac</MenuItem>
                    <MenuItem value={36}>3 Cr 60 Lac</MenuItem>
                    <MenuItem value={37}>3 Cr 70 Lac</MenuItem>
                    <MenuItem value={38}>3 Cr 80 Lac</MenuItem>
                    <MenuItem value={39}>3 Cr 90 Lac</MenuItem>
                    <MenuItem value={40}>4 Cr</MenuItem>
                    <MenuItem value={41}>4 Cr 10 Lac</MenuItem>
                    <MenuItem value={42}>4 Cr 20 Lac</MenuItem>
                    <MenuItem value={43}>4 Cr 30 Lac</MenuItem>
                    <MenuItem value={44}>4 Cr 40 Lac</MenuItem>
                    <MenuItem value={45}>4 Cr 50 Lac</MenuItem>
                    <MenuItem value={46}>4 Cr 60 Lac</MenuItem>
                    <MenuItem value={47}>4 Cr 70 Lac</MenuItem>
                    <MenuItem value={48}>4 Cr 80 Lac</MenuItem>
                    <MenuItem value={49}>4 Cr 90 Lac</MenuItem>
                    <MenuItem value={50}>5 Cr</MenuItem>
                    <MenuItem value={51}>5 Cr 10 Lac</MenuItem>
                    <MenuItem value={52}>5 Cr 20 Lac</MenuItem>
                    <MenuItem value={53}>5 Cr 30 Lac</MenuItem>
                    <MenuItem value={54}>5 Cr 40 Lac</MenuItem>
                    <MenuItem value={55}>5 Cr 50 Lac</MenuItem>
                    <MenuItem value={56}>5 Cr 60 Lac</MenuItem>
                    <MenuItem value={57}>5 Cr 70 Lac</MenuItem>
                    <MenuItem value={58}>5 Cr 80 Lac</MenuItem>
                    <MenuItem value={59}>5 Cr 90 Lac</MenuItem>
                    <MenuItem value={60}>6 Cr</MenuItem>
                    <MenuItem value={61}>6 Cr 10 Lac</MenuItem>
                    <MenuItem value={62}>6 Cr 20 Lac</MenuItem>
                    <MenuItem value={63}>6 Cr 30 Lac</MenuItem>
                    <MenuItem value={64}>6 Cr 40 Lac</MenuItem>
                    <MenuItem value={65}>6 Cr 50 Lac</MenuItem>
                    <MenuItem value={66}>6 Cr 60 Lac</MenuItem>
                    <MenuItem value={67}>6 Cr 70 Lac</MenuItem>
                    <MenuItem value={68}>6 Cr 80 Lac</MenuItem>
                    <MenuItem value={69}>6 Cr 90 Lac</MenuItem>
                    <MenuItem value={70}>7 Cr</MenuItem>
                    <MenuItem value={71}>7 Cr 10 Lac</MenuItem>
                    <MenuItem value={72}>7 Cr 20 Lac</MenuItem>
                    <MenuItem value={73}>7 Cr 30 Lac</MenuItem>
                    <MenuItem value={74}>7 Cr 40 Lac</MenuItem>
                    <MenuItem value={75}>7 Cr 50 Lac</MenuItem>
                    <MenuItem value={76}>7 Cr 60 Lac</MenuItem>
                    <MenuItem value={77}>7 Cr 70 Lac</MenuItem>
                    <MenuItem value={78}>7 Cr 80 Lac</MenuItem>
                    <MenuItem value={79}>7 Cr 90 Lac</MenuItem>
                    <MenuItem value={80}>8 Cr</MenuItem>
                    <MenuItem value={81}>8 Cr 10 Lac</MenuItem>
                    <MenuItem value={82}>8 Cr 20 Lac</MenuItem>
                    <MenuItem value={83}>8 Cr 30 Lac</MenuItem>
                    <MenuItem value={84}>8 Cr 40 Lac</MenuItem>
                    <MenuItem value={85}>8 Cr 50 Lac</MenuItem>
                    <MenuItem value={86}>8 Cr 60 Lac</MenuItem>
                    <MenuItem value={87}>8 Cr 70 Lac</MenuItem>
                    <MenuItem value={88}>8 Cr 80 Lac</MenuItem>
                    <MenuItem value={89}>8 Cr 90 Lac</MenuItem>
                    <MenuItem value={90}>9 Cr</MenuItem>
                    <MenuItem value={91}>9 Cr 10 Lac</MenuItem>
                    <MenuItem value={92}>9 Cr 20 Lac</MenuItem>
                    <MenuItem value={93}>9 Cr 30 Lac</MenuItem>
                    <MenuItem value={94}>9 Cr 40 Lac</MenuItem>
                    <MenuItem value={95}>9 Cr 50 Lac</MenuItem>
                    <MenuItem value={96}>9 Cr 60 Lac</MenuItem>
                    <MenuItem value={97}>9 Cr 70 Lac</MenuItem>
                    <MenuItem value={98}>9 Cr 80 Lac</MenuItem>
                    <MenuItem value={99}>9 Cr 90 Lac</MenuItem>
                    <MenuItem value={100}>10 Cr</MenuItem>
                </Select>
            </Stack>
        </div>
    );
}
