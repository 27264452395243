import React from 'react';

export const LoadingSpinner = (props) => {
    const { color, size, text } = props;
    return (
        <div className="flex flex-col items-center justify-center w-full h-full">
            <div
                className={
                    `${color ? color : 'text-primary'}` +
                    'flex flex-col self-center flex justify-center items-center space-x-1 text-sm  w-full h-full'
                }
            >
                {text && <p className="text-[black]">{text}</p>}
                <svg
                    fill="none"
                    className={
                        `${color ? color : 'text-primary'}` +
                        ` w-${size || 24} h-${size || 24} animate-spin`
                    }
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        clipRule="evenodd"
                        d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                        fill="currentColor"
                        fillRule="evenodd"
                    />
                </svg>
            </div>
        </div>
    );
};
export const ProjectGridLoadingUI = (props) => {
    return (
        <div class="min-h-screen flex p-16">
            <div class="bg-white p-4 rounded-md">
                <div class="w-64 h-44 bg-gray-200 animate-pulse"></div>
                <div class="mt-8 h-32 w-full space-y-3">
                    <div class="w-20 h-6 bg-gray-200 rounded-full animate-pulse"></div>
                    <div class="w-full h-4 bg-gray-200 rounded-full animate-pulse"></div>
                    <div class="w-full h-4 bg-gray-200 rounded-full animate-pulse"></div>
                    <div class="w-1/2 h-4 bg-gray-200 rounded-full animate-pulse"></div>
                </div>
            </div>
        </div>
    );
};
export const DetailProjectLoadingUI = (props) => {
    return (
        <div className="px-7  mb-10">
            <div class="bg-white p-2 sm:p-4 sm:h-64 rounded-2xl shadow-lg flex flex-col sm:flex-row gap-5 select-none ">
                <div class="h-52 sm:h-full sm:w-72 rounded-xl bg-gray-200 animate-pulse"></div>
                <div class="flex flex-col flex-1 gap-5 sm:p-2">
                    <div class="flex flex-1 flex-col gap-3">
                        <div class="bg-gray-200 w-full animate-pulse h-14 rounded-2xl"></div>
                        <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
                        <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
                        <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
                        <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
                    </div>
                    <div class="mt-auto flex gap-3">
                        <div class="bg-gray-200 w-20 h-8 animate-pulse rounded-full"></div>
                        <div class="bg-gray-200 w-20 h-8 animate-pulse rounded-full"></div>
                        <div class="bg-gray-200 w-20 h-8 animate-pulse rounded-full ml-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
function ProjectMapLoadingUI(props) {
    const { className } = props;
    return (
        <>
            {/* <div className="flex w-[40%] mx-auto shadow-lg h-16 animate-pulse">
                <p>Loading projects</p>
                <FcHome className="" />
            </div> */}
            <div
                class={
                    className
                        ? className
                        : 'w-full flex items-center flex-col animate-bounce mt-16'
                }
            >
                {/* <p>Loading</p> */}
                <div class="flex bg-white shadow-md p-4 rounded-md">
                    <div
                        data-placeholder
                        class="mr-2 h-20 w-20  overflow-hidden relative bg-gray-200"
                    ></div>
                    {/* <FcHome /> */}
                    <div class="flex flex-col justify-between">
                        <div
                            data-placeholder
                            class="mb-2 h-5 w-40 overflow-hidden relative bg-gray-200"
                        ></div>
                        <div
                            data-placeholder
                            class="h-10 w-40 overflow-hidden relative bg-gray-200"
                        ></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectMapLoadingUI;
