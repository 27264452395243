import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
// console.log(window.location.origin);
const domain = window.location.origin;
const SushmaConfig = {
    clientId:
        'fbbc10bec56bdff797eebe2fa934a435fdbe2b511526d66cfbdee767d90dfb96',
    id: 38,
    name: ' Sushma Buildtech',
    fullName: 'Club Sushma',
    programName:'Club Sushma',
    primaryColor: '#000000',
    fontBody: `${getFont('SUSHMA').body}`,
    fontTitle: `${getFont('SUSHMA').bold}`,
    theme: 'sushmaTheme',
    favicon: 'https://www.sushma.co.in/wp-content/uploads/2022/07/favicon.jpg',

    googleConfig: {
        gID: 'G-7VH1C2HF5J',
        keywordsContent:
            'Sushma priority real estate referral programs, Sushma real estate referral program, Sushma real estate refer and earn, Sushma priority real estate project refer, Sushma real estate, Sushma priority real estate loyalty program, Sushma real estate news, Sushma real estate upcoming projects in Bengaluru, Sushma real estate launch Sushma priority real estate, Sushma real estate launch, Sushma real estate program, Sushma real estate projects, Sushma real estate blogs, Sushma real estate offers, Sushma real estate upcoming projects in Bengaluru, Sushma real estate events, Sushma, Sushma priority, Sushma real estate best residential projects, Sushma real estate real estate developers, All New Projects by Sushma real estate Builders & Developers, Ongoing Projects by Sushma real estate Pvt Ltd, Sushma real estate Bengaluru, Sushma real estate new program, Sushma press release, Sushma upcoming projects, Sushma property group, Sushma bangalore, Sushma real estate, Sushma reit, jitu virwani, Sushma commercial projects, Sushma projects, Sushma projects in bangalore',
        descriptionContent:
            'Sushma Nest is an exclusive referral and loyalty program designed for Kolte-Patil residents. The program aims to make you the brand ambassador of the Kolte-Patil lifestyle by adding more value to your home ownership and creating delight.',
        authorContent: 'Sushma'
    },

    facebookPixelConfig: {
        id: '202785562623854', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~~71680b3c',
    webengageAPIKey:'e3757109-c6d7-4430-9d3f-32c99f5b18a2',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: false,
    logoLeft: {
        name: 'Sushma Nest Logo',
        baseUrl: 'https://cdn.loyalie.in/35302360.png',
        stickyUrl: 'https://cdn.loyalie.in/35302360.png',
        stickyNavBarLogoClass:
            'relative h-10 lg:h-10 xl:h-14 left-2 py-0.5 lg:left-8 ease-in duration-300',
        baseNavBarLogoClass:
            ' relative h-10 lg:h-12 xl:h-16 left-2 py-0.5 lg:left-8 ease-out duration-300'
    },

    logoRight: {
        name: 'Sushma Logo',
        url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/79899199.png',
        stickyNavBarLogoClass:
            'relative h-10 lg:h-10 xl:h-12  right-2 lg:right-8 ease-in duration-300',
        baseNavBarLogoClass:
            ' relative h-10 lg:h-12 xl:h-14 right-2 lg:right-8 ease-out duration-300'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('SUSHMA').heading}`,
        height: 'h-16 lg:h-20 ease-out duration-300',
        stickyHeight: 'h-16 lg:h-16 ease-in duration-300 	',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-white  ',
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-white drop-shadow-md ', // default: transparent
            containerClass: '  ',
            itemClass: `uppercase lg:mr-7 text-primary lg:text-primary hover:text-primary  text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-bold ${
                getFont('SUSHMA').body
            }`,
            dropDownContainerClass: `text-primary-focus lg:text-primary-focus hover:text-primary-focus  focus:text-primary-focus lg:focus:shadow-outline `,
            dropDownItemClass: `uppercase  text-primary lg:text-primary hover:text-primary  text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-bold hover:bg-gradient-to-r from-[#b7955b] to-[#f4ebac] ${
                getFont('SUSHMA').body
            }`
        },
        stickyNavBar: {
            border: true,
            borderClass: ' ',
            backgroundColor: 'bg-white drop-shadow-md ', //default: primary-content,
            containerClass: ' ', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass: `uppercase lg:mr-7 text-primary lg:text-primary hover:text-primary  text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-bold ${
                getFont('SUSHMA').body
            }` // Each list element in NavBar (Text color, hover color, size)
        },
        showActiveLinkColor: false
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: ' ', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[15px] md:text-[17px] tracking-wider  text-secondary-focus  font-semibold py-3 md:py-3 2xl:py-4 px-5 md:px-10 rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] ${
        getFont('SUSHMA').body
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            path: '/',
            show: true,

            components: [
                {
                    type: 'HORIZONTAL_CAROUSEL',
                    duration: 2500,
                    arrows: false,
                    imgHeight:
                        'h-[70vh] md:h-[70vh] lg:h-[62vh] xl:h-[72vh] 2xl:h-[78vh]',
                    images: [
                        {
                            imgUrl: 'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1721458589543_dubai web',
                            imgPosition: 'object-center',
                            mobileImgUrl:
                                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1721458574028_dubai mob',
                            mobileImgPosition: 'object-center',
                            onClick: 'LINK',
                            link: 'https://re.loyali.in/SushmaDubaiContest'
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/1695822392514_Winner%20Announcement',
                            imgPosition: 'object-center',
                            mobileImgUrl:
                                'https://cdn.loyalie.in/1695822676017_Winner%20Announcement%20SM',
                            mobileImgPosition: 'object-center'
                        }

                        // {
                        //     imgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/58770532.jpg',
                        //     mobileImgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/69367745.jpg',
                        //     components: [
                        //         {
                        //             type: 'CUSTOM',
                        //             className:
                        //                 'relative w-full h-full flex items-end justify-center lg:justify-start lg:text-right lg:pr-10 2xl:pr-20 lg:pt-32 xl:pt-36 2xl:pt-40',
                        //             components: [
                        //                 {
                        //                     type: 'BUTTON',
                        //                     content: 'Refer Now',
                        //                     className: ` text-[15px] md:text-[18px] 2xl:text-[20px] uppercase tracking-wider bg-transparent text-primary font-semibold  w-[80%] lg:w-[50%] h-[250px] xl:h-[350px] rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] opacity-0 ${getFont('SUSHMA').body
                        //                         }`,

                        //                     path: 'blogs/370',

                        //                     onClick: 'CHANGE_PATH'
                        //                 }

                        //             ]
                        //         }
                        //     ]
                        // },
                        // {
                        //     imgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/86568810.jpg',
                        //     mobileImgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/54399906.jpg',
                        //     components: [
                        //         {
                        //             type: 'CUSTOM',
                        //             className:
                        //                 'relative w-full h-full flex items-center justify-center lg:justify-end lg:text-right lg:pr-10 2xl:pr-20 lg:pt-32 xl:pt-36 2xl:pt-40',
                        //             components: [
                        //                 {
                        //                     type: 'BUTTON',
                        //                     content: 'Refer Now',
                        //                     className: ` text-[15px] md:text-[18px] 2xl:text-[20px] uppercase tracking-wider bg-transparent text-primary font-semibold  w-[70%] lg:w-[50%] h-[250px] md:h-[350px] xl:h-[350px] rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] opacity-0 ${getFont('SUSHMA').body
                        //                         }`,
                        //                     path: 'events',
                        //                     scrollTo:
                        //                         'referral_section',
                        //                     onClick: 'CHANGE_PATH'
                        //                 }

                        //             ]
                        //         }
                        //     ]
                        // },
                    ]
                },
                // {
                //     type: 'BANNER_NEW',
                //     backgroundImage:
                //         'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/65185100.jpg',
                //     backgroundImageMobile:
                //         'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/65185100.jpg',
                //     linearGradient: {
                //         R: '255',
                //         G: '255',
                //         B: '255',
                //         opacity: '0'
                //     },
                //     bannerContainerClass:
                //         'h-[65vh] md:h-[85vh] lg:h-[82vh] 2xl:h-[84vh] bg-center lg:bg-top',
                //     bannerItemContainerClass:
                //         'items-center justify-end  lg:px-8 md:pl-10 lg:pl-20 pb-7 lg:pb-14 ',
                //     position: 'text-left',

                //     components: [
                //         {
                //             type: 'TEXT',
                //             content:
                //                 'Building a new way of life around you with',
                //             className: ` text-center text-white text-[17px] md:text-[18px] xl:text-[20px] 2xl:text-[24px] tracking-wide    ${getFont('SUSHMA').body
                //                 }`
                //         },
                //         {
                //             type: 'TEXT',
                //             content: 'CLUB SUSHMA',
                //             className: ` text-white uppercase text-[22px] md:text-[25px] lg:text-[28px] xl:text-[32px] 2xl:text-[35px] tracking-wide leading-9 font-bold ${getFont('SUSHMA').bold
                //                 }`
                //         },
                //         {
                //             type: 'TEXT',
                //             content: 'An Exclusive Customer Loyalty Program',
                //             className: ` text-center text-white text-[17px] md:text-[18px]  xl:text-[20px] 2xl:text-[24px]  tracking-wide   ${getFont('SUSHMA').body
                //                 }`
                //         },

                //         {
                //             type: 'BUTTON',
                //             content: 'Refer Now',
                //             className: `md:mx-0 mt-4 xl:mt-6  text-[15px] md:text-[18px] 2xl:text-[20px] uppercase tracking-wider bg-transparent text-primary font-semibold py-3 md:py-3 2xl:py-4 px-3 md:px-10 rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] -mb-8 2xl:-mb-9 ${
                //                 getFont('SUSHMA').body
                //             }`,
                //             path: 'refer',
                //             scrollTo: 'referral_section',
                //             onClick: 'SCROLL'
                //         }
                //     ]
                // },
                {
                    type: 'DIVIDER',
                    className:
                        'w-full bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[16px]',
                    classNameContainer: ' '
                },
                {
                    type: 'CUSTOM',
                    id: '',
                    url: 'https://cdn.loyalie.in/84696683.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0',
                        size: '70%'
                    },
                    className: 'flex flex-col justify-center text-center px-2',
                    components: [
                        {
                            type: 'CUSTOM',
                            id: 'overview',
                            className:
                                'flex flex-col justify-center text-center my-10 2xl:my-12 w-[85%] mx-auto ',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className: 'w-full ',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content: ' ABOUT CLUB SUSHMA',
                                            className: ` inline-block ml-2  text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-6 text-center text-[22px] md:text-[25px] xl:text-[30px] 2xl:text-[35px] tracking-wider leading-8 font-bold ${
                                                getFont('SUSHMA').heading
                                            }`
                                        },
                                        {
                                            type: 'DIVIDER',
                                            className:
                                                'w-full bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[8px]',
                                            classNameContainer: ' py-4 lg:py-8 '
                                        }
                                    ]
                                },

                                {
                                    type: 'TEXT',
                                    content:
                                        'At Sushma Buildtech, we stand by our philosophy of building your world around you. Club Sushma is our step towards creating a vibrant community that involves and rewards you at every step of your journey. ',
                                    className: ` text-[17px] md:text-[18px] h-full xl:text-[20px] 2xl:text-[24px] text-secondary-focus leading-7 xl:leading-8 self-center w-[90%] md:w-full break-words mb-12  ${
                                        getFont('SUSHMA').body
                                    }`
                                }
                                // {
                                //     type: 'CUSTOM',
                                //     className:
                                //         'w-full pt-14 md:flex items-center',
                                //     components: [
                                //         {
                                //             type: 'CUSTOM',
                                //             className:
                                //                 'w-full md:w-[50%] lg:w-[40%] h-[197px] lg:h-[221px] xl:h-[277px] 2xl:h-[331px]',
                                //             components: [
                                //                 {
                                //                     type: 'IMAGE',
                                //                     url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/66314078.jpg',
                                //                     position: 'center',
                                //                     classNameContainer:
                                //                         'w-[100%] flex lg:gap-0 gap-0 '
                                //                 }
                                //             ]
                                //         },
                                //         {
                                //             type: 'CUSTOM',
                                //             className:
                                //                 'w-[90%] md:w-[50%] lg:w-[60%] grid lg:flex bg-white h-[160px] md:h-[150px] xl:h-[190px] 2xl:h-[230px] drop-shadow-md items-center mx-auto lg:mx-0',
                                //             components: [
                                //                 {
                                //                     type: 'CUSTOM',
                                //                     className:
                                //                         'w-full lg:w-[70%]',
                                //                     components: [
                                //                         {
                                //                             type: 'TEXT',
                                //                             content:
                                //                                 'Keep your loved ones closer with our referral program. ',
                                //                             className: `${
                                //                                 getFont(
                                //                                     'SUSHMA'
                                //                                 ).heading
                                //                             } text-secondary w-[95%] lg:w-[80%] uppercase text-center lg:text-left mx-auto text-[22px] md:text-[24px] lg:text-[20px] xl:text-[22px] 2xl:text-[25px] leading-6 xl:leading-[26px] 2xl:leading-7 py-5 font-semibold tracking-wider `
                                //                         }
                                //                     ]
                                //                 },
                                //                 {
                                //                     type: 'CUSTOM',
                                //                     className:
                                //                         'w-full lg:w-[30%]',
                                //                     components: [
                                //                         {
                                //                             type: 'BUTTON',
                                //                             content: 'Refer Now',
                                //                             className: `lg:mx-0 text-[15px] xl:text-[17px] uppercase tracking-wider  text-secondary-focus  font-semibold py-3 md:py-2 2xl:py-3 px-5 xl:px-6 rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] ${
                                //                                 getFont(
                                //                                     'SUSHMA'
                                //                                 ).body
                                //                             }`,
                                //                             path: 'refer',
                                //                             scrollTo:
                                //                                 'referral_section',
                                //                             onClick: 'SCROLL'
                                //                         }
                                //                     ]
                                //                 }
                                //             ]
                                //         }
                                //     ]
                                // }
                            ]
                        },

                        {
                            type: 'CUSTOM',
                            id: ' ',
                            className:
                                'px-5 lg:px-0 overflow-x-scroll  lg:overflow-x-visible flex lg:grid lg:grid-cols-3 gap-x-8 xl:gap-x-14 2xl:gap-x-16 justify-left lg:justify-center text-center lg:mt-24 2xl:mt-28 pb-10 lg:pb-0 pt-16 lg:pt-0 mb-20 2xl:mb-28 w-[87%] mx-auto  ',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-full lg:mt-0 leading-6 md:leading-9 text-center lg:leading-none bg-white drop-shadow-lg ',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/32874151.jpg',
                                            position: 'center',
                                            classNameContainer:
                                                ' w-[275px] h-[275px] md:w-[327px] md:h-[327px] lg:w-[227px] lg:h-[227px] xl:w-[270px] xl:h-[270px] 2xl:w-[316px] mx-auto 2xl:h-[316px] object-cover object-center -mt-28 xl:-mt-32 2xl:-mt-40 px-5 lg:px-0'
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'ENJOY MEMBERSHIP BENEFITS FROM TOP BRANDS',
                                            className: `text-primary uppercase lg:pt-10 pb-10 text-center text-[22px] md:text-[24px] lg:text-[20px] xl:text-[22px] 2xl:text-[25px]  w-[80%] mx-auto tracking-wider leading-6 xl:leading-[26px] 2xl:leading-7 font-bold  ${
                                                getFont('SUSHMA').heading
                                            }`
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full -mb-5 xl:-mb-5 2xl:-mb-6',
                                            components: [
                                                {
                                                    type: 'BUTTON',
                                                    content: 'AVAIL NOW',
                                                    className: `md:mx-0  text-[15px] md:text-[17px] tracking-wider  text-secondary-focus  font-semibold py-3 md:py-3 2xl:py-4 px-5 md:px-10 rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] ${
                                                        getFont('SUSHMA').body
                                                    }`,
                                                    path: 'deals',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-full lg:mt-0 leading-6 md:leading-9 text-center lg:leading-none bg-white drop-shadow-lg ',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/61001990.jpg',
                                            position: 'center',
                                            classNameContainer:
                                                '  w-[275px] h-[275px] md:w-[327px] md:h-[327px] lg:w-[227px] lg:h-[227px] xl:w-[270px] xl:h-[270px] 2xl:w-[316px] mx-auto 2xl:h-[316px] object-cover object-center -mt-28 xl:-mt-32 2xl:-mt-40 px-5 lg:px-0 '
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'PARTICIPATE IN COMMUNITY EVENTS & WORKSHOPS',
                                            className: `text-primary uppercase lg:pt-10 pb-10 text-center text-[22px] md:text-[24px] lg:text-[20px] xl:text-[22px] 2xl:text-[25px] w-[90%] xl:w-[70%] mx-auto tracking-wider leading-6 xl:leading-[26px] 2xl:leading-7 font-bold  ${
                                                getFont('SUSHMA').heading
                                            }`
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full -mb-5 xl:-mb-5 2xl:-mb-6',
                                            components: [
                                                {
                                                    type: 'BUTTON',
                                                    content: 'KNOW NOW',
                                                    className: `md:mx-0  text-[15px] md:text-[17px] tracking-wider  text-secondary-focus  font-semibold py-3 md:py-3 2xl:py-4 px-5 md:px-10 rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] ${
                                                        getFont('SUSHMA').body
                                                    }`,
                                                    path: 'events',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-full lg:mt-0 leading-6 md:leading-9 text-center lg:leading-none bg-white drop-shadow-lg ',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/18739868.jpg',
                                            position: 'bottom',
                                            classNameContainer:
                                                '  w-[275px] h-[275px] md:w-[327px] md:h-[327px] lg:w-[227px] lg:h-[227px] xl:w-[270px] xl:h-[270px]  2xl:w-[316px] mx-auto 2xl:h-[316px] object-cover object-center -mt-28 xl:-mt-32 2xl:-mt-40 px-5 lg:px-0'
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'ENGAGE WITH TRENDING BLOGS',
                                            className: `text-primary uppercase lg:pt-10 pb-10 text-center text-[22px] md:text-[24px] lg:text-[20px] xl:text-[22px] 2xl:text-[25px] w-[60%] xl:w-[50%] mx-auto tracking-wider leading-6 xl:leading-[26px] 2xl:leading-7 font-bold  ${
                                                getFont('SUSHMA').heading
                                            }`
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full -mb-5 xl:-mb-5 2xl:-mb-6',
                                            components: [
                                                {
                                                    type: 'BUTTON',
                                                    content: 'READ NOW',
                                                    className: `md:mx-0  text-[15px] md:text-[17px] tracking-wider  text-secondary-focus  font-semibold py-3 md:py-3 2xl:py-4 px-5 md:px-10 rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] ${
                                                        getFont('SUSHMA').body
                                                    }`,
                                                    path: 'blogs',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },

                //REFERRAL FORM
                {
                    type: 'REFERRAL_FORM_BANNER'
                },

                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/84696683.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0',
                        size: '70%'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-8 relative pb-10 lg:pb-20 -mb-28',
                    components: [
                        {
                            type: 'TEXT',
                            content: ' OUR Exclusive Offers',
                            className: ` inline-block ml-2 uppercase text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-6 text-center text-[22px] md:text-[25px] xl:text-[30px] 2xl:text-[35px] tracking-wider leading-8 font-bold ${
                                getFont('SUSHMA').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                'w-[15%] lg:w-[5%] mx-auto bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[6px]',
                            classNameContainer: ' py-4 '
                        },
                        {
                            type: 'CAROUSEL',
                            getVendorLogos: true,
                            offerCards: true,
                            getVendorData: true,
                            backgroundImageNone: true,
                            classNameCTA: `bg-gradient-to-r from-[#b7955b] to-[#f4ebac] text-[15px] rounded-md px-5 py-2.5 text-primary text-center mt-2 mx-auto uppercase font-semibold ${
                                getFont('SUSHMA').body
                            }`,
                            ContainerClassName: 'bg-cover rounded-lg h-auto ',
                            caraouselClassName: `w-[80%] drop-shadow-lg md:w-full xl:w-[86%] 2xl:w-[95%] 3xl:w-[80%] mx-auto md:px-12  pt-6 relative pb-10 ${
                                getFont('SUSHMA').body
                            }`,

                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 5,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow
                        },
                        {
                            type: 'TEXT',
                            content: ' LATEST BLOGS',
                            className: ` inline-block ml-2 uppercase text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-6 text-center text-[22px] md:text-[25px] xl:text-[30px] 2xl:text-[35px] tracking-wider leading-8 font-bold ${
                                getFont('SUSHMA').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                'w-[15%] lg:w-[5%] mx-auto bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[6px]',
                            classNameContainer: ' py-4 '
                        },
                        {
                            type: 'BLOG_LAYOUT_VERTICAL1',
                            hideInfo: true,
                            className:
                                'w-full h-auto flex flex-col-reverse lg:flex-row mt-6 px-10 xl:px-14 2xl:px-16 justify-center ',
                            classNameContainer:
                                ' w-full px-3 lg:px-10 xl:w-[90%] overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-3 gap-x-4 md:gap-x-20 lg:gap-x-6 xl:gap-x-10 2xl:gap-x-16 3xl:gap-x-20 gap-y-14 '
                        },
                        {
                            type: 'TEXT',
                            content: ' GROUP PROJECTS',
                            className: ` inline-block ml-2 uppercase text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-6 text-center text-[22px] md:text-[25px] xl:text-[30px] 2xl:text-[35px] tracking-wider leading-8 font-bold ${
                                getFont('SUSHMA').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                'w-[15%] lg:w-[5%] mx-auto bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[6px]',
                            classNameContainer: ' py-4 '
                        },
                        {
                            type: 'CUSTOM',
                            className: 'w-[80%] mx-auto',
                            components: [
                                {
                                    type: 'CAROUSEL',
                                    customSlide: true,
                                    caraouselClassName:
                                        'md:pb-10 py-1 relative pb-16 lg:pb-10',
                                    imageContainerClassName: 'm-4 relative',
                                    imageClassName:
                                        'w-full h-40 lg:h-56 lg:bg-cover object-cover object-right-top  drop-shadow-xl border ',
                                    captionClass: ` text-sm lg:text-lg leading-8 font-medium ${
                                        getFont('SUSHMA').body
                                    }`,
                                    arrows: false,
                                    autoplay: true,
                                    autoplaySpeed: 3000,
                                    infinite: true,
                                    dots: false,
                                    slidesToShow: 3,
                                    pauseOnHover: true,
                                    speed: 3000,
                                    onImageClick: openURLNewWindow,
                                    imgArr: [
                                        {
                                            imgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/58843952.jpg',
                                            alt: 'Sushma Belleza',
                                            url: `${domain}/sushma-website/projects/detail?projectId=2`,
                                            caption: 'Sushma Belleza'
                                        },
                                        {
                                            imgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/69394445.jpg',
                                            alt: 'Sushma Grande Nxt',
                                            url: `${domain}/sushma-website/projects/detail?projectId=3`,
                                            caption: 'Sushma Grande Nxt'
                                        },
                                        {
                                            imgUrl: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/6417158.jpg',
                                            alt: 'Sushma Crescent',
                                            url: `${domain}/sushma-website/projects/detail?projectId=4`,
                                            caption: 'Sushma Crescent'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },

        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                },
                {
                    deals: {
                        show: false,
                        name: 'BENEFITS',
                        path: '/deals'
                    }
                }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://cdn.loyalie.in/1692634565592_refer%20page%20main',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1692634520683_refer%20page',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center md:text-left text-black text-[15px] md:text-[20px] xl:text-[22px] uppercase my-5 tracking-widest w-full md:w-[40%] font-semibold md:font-normal lg:w-[48%] ml-auto  lg:leading-7 ${
                                getFont('SUSHMA').body
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'flex -full md:w-[40%] lg:w-[48%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `rounded md:mx-0 text-left text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E] text-primary font-medium uppercase py-3 md:py-2 px-5 md:px-6 mx-auto lg:mx-0 ${
                                        getFont('SUSHMA').body
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://cdn.loyalie.in/1692634565592_refer%20page%20main',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1692634520683_refer%20page',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[60vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left '
            }
        ],

        customize: {
            claimButton: `text-md font-bold tracking-widest bg-primary text-white px-8 md:px-6 py-3 lg:mt-2 xl:mt-0 mx-auto ${
                getFont('SUSHMA').heading
            }`
        }
    },
    blogs: {
        banner: {
            bannerItemContainerClass: '  ',
            bannerContainerClassName:
                ' flex flex-col h-full w-full items-center justify-end h-[45vh] md:h-[60vh] lg:h-[83vh]  bg-center lg:bg-top bg-cover  ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage: 'https://cdn.loyalie.in/59166155.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/59166155.jpg',
            components: [
                {
                    type: 'CUSTOM',
                    className: 'w-full -mb-12 2xl:-mb-14 text-center',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'CLUB SUSHMA READERS’ CLUB',
                            className: ` text-white uppercase text-[20px] md:text-[25px] lg:text-[28px] xl:text-[32px] 2xl:text-[35px] tracking-wide leading-9 font-bold ${
                                getFont('SUSHMA').bold
                            }`
                        },

                        {
                            type: 'TEXT',
                            content:
                                'Bringing Together Information And Entertainment For You.',
                            className: `text-white text-[17px] md:text-[18px] lg:leading-9  xl:text-[19px] tracking-wide ${
                                getFont('SUSHMA').body
                            }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'Read More',
                            scrollTo: 'blogs_section',
                            onClick: 'SCROLL',
                            className: `md:mx-0 mt-4 xl:mt-6  text-[15px] md:text-[20px] uppercase tracking-wider bg-transparent text-primary font-semibold py-3 md:py-3 2xl:py-4 px-3 md:px-10 rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] ${
                                getFont('SUSHMA').body
                            }`
                        }
                    ]
                }
            ],
            loginBannerComponents: [
                {
                    type: 'CUSTOM',
                    className: 'w-full  lg:mb-5 2xl:mb-14 text-center',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'CLUB SUSHMA READERS’ CLUB',
                            className: `text-white uppercase text-[20px] md:text-[25px] lg:text-[28px] xl:text-[32px] 2xl:text-[35px] tracking-wide lg:leading-9 font-bold ${
                                getFont('SUSHMA').bold
                            }`
                        },

                        {
                            type: 'TEXT',
                            content:
                                'Bringing Together Information And Entertainment For You.',
                            className: ` text-white text-[17px] md:text-[18px] lg:leading-9 xl:text-[19px] tracking-wide ${
                                getFont('SUSHMA').body
                            }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'Login To Read Now',
                            onClick: 'FUNC',
                            onClickFunc: () =>
                                store.dispatch({
                                    type: 'authentication/openLoginModal'
                                }),
                            className: `md:mx-0 mt-4 xl:mt-6  text-[15px] md:text-[20px] uppercase tracking-wider bg-transparent text-primary font-semibold py-3 md:py-2 2xl:py-4 px-3 md:px-10 rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] ${
                                getFont('SUSHMA').body
                            }`
                        }
                    ]
                }
            ]
        },

        sideBarClass: `text-primary ${getFont('SUSHMA').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('SUSHMA').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                components: [
                    {
                        type: 'DIVIDER',
                        className:
                            'w-full bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[16px]',
                        classNameContainer: ' '
                    },
                    {
                        type: 'CUSTOM',
                        className: 'w-full mx-auto pt-10',
                        components: [
                            {
                                type: 'TEXT',
                                content: ' FEATURED POST',
                                className: ` ml-2 uppercase text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-6 text-center text-[22px] md:text-[25px] xl:text-[30px] 2xl:text-[35px] tracking-wider leading-8 font-bold ${
                                    getFont('SUSHMA').heading
                                }`
                            },
                            {
                                type: 'DIVIDER',
                                className:
                                    'w-[15%] lg:w-[5%] mx-auto bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[6px]',
                                classNameContainer: ' pt-4'
                            }
                        ]
                    }
                ],
                featuredPostClass: `uppercase  lg:mt-0  font-normal text-[20px] text-[#1c2936] ${
                    getFont('SUSHMA').heading
                }`,
                headingClass: `text-[20px] md:text-[20px] lg:text-[25px] 2xl:text-[30px] uppercase font-semibold text-black mt-3 leading-9 break-words ${
                    getFont('SUSHMA').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-black  min-h-[40px] lg:min-h-[70px] ${
                    getFont('SUSHMA').body
                }`,
                dateClass: `text-[13px] uppercase text-black font-normal my-3 ${
                    getFont('SUSHMA').heading
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('SUSHMA').heading
                }`
            },
            sectionHeadings: `hidden px-10 lg:px-16 pt-8 text-[28px] md:text-[40px] lg:text-[30px] 2xl:text-[40px] tracking-wider text-center uppercase ${
                getFont('SUSHMA').bold
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            components: [
                {
                    type: 'CUSTOM',
                    className: 'w-full mx-auto pt-10',
                    components: [
                        {
                            type: 'TEXT',
                            content: ' LATEST POSTS',
                            className: ` ml-2 uppercase text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-6 text-center text-[22px] md:text-[25px] xl:text-[30px] 2xl:text-[35px] tracking-wider leading-8 font-bold ${
                                getFont('SUSHMA').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                'w-[15%] lg:w-[5%] mx-auto bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[6px]',
                            classNameContainer: ' pt-4'
                        }
                    ]
                }
            ],
            cardHeight:
                'h-[520px] md:h-[470px] lg:h-[530px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[280px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 md:h-[45px] lg:h-[70px] text-[19px] 3xl:text-[20px] font-medium uppercase leading-6 break-words mx-5 ${
                getFont('SUSHMA').heading
            }`,
            categoryClass: `bg-secondary-content bg-opacity-[0.65] text-white ${
                getFont('SUSHMA').body
            }`,
            summaryClass: `font-normal text-[16.5px] xl:text-[17px] mt-2 leading-6 text-[#777777] mx-5 md:h-[50px] lg:h-[100px] xl:h-[80px]  ${
                getFont('SUSHMA').body
            }`,
            buttonClass: `flex justify-center self-end float-right text-[13px] xl:text-[16px] text-primary py-1.5 px-4 md:px-4 m-5 rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] font-semibold ${
                getFont('SUSHMA').body
            }`,
            buttonArrowUrl: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.16 11.59'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23000;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cline class='cls-1' y1='5.62' x2='55' y2='5.62'/%3E%3Cpolyline class='cls-1' points='48.5 0.39 51.93 3.09 55.35 5.79 51.93 8.49 48.5 11.19'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px] font-normal  self-center text-[#777777] ${
                getFont('SUSHMA').body
            }`,
            noBlogsClass: `bg-secondary text-white p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('SUSHMA').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/53822036.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/53822036.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.20'
            },

            negativeMargin: '-mt-16 2xl:-mt-20',
            containerClass: ' text-white lg:w-[50%] ',
            heading: '',
            headingClass: ` text-white uppercase text-[22px] md:text-[25px] lg:text-[28px] xl:text-[32px] 2xl:text-[35px] tracking-wide leading-9 font-bold lg:pl-20 ${
                getFont('SUSHMA').bold
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('SUSHMA').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('SUSHMA').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('SUSHMA').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('SUSHMA').bold
                    }`
                }
            ]
        },

        eventsSection: {
            heading: ' ',
            headingClass: `self-center text-center text-[22px] md:text-[24px] lg:text-[25px] xl:text-[30px] text-neutral w-[60%] ${
                getFont('SUSHMA').heading
            }`,
            components: [
                {
                    type: 'DIVIDER',
                    className:
                        'w-full bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[16px]',
                    classNameContainer: ' '
                },
                {
                    type: 'CUSTOM',
                    className:
                        'w-full mb-3 text-center lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto mt-12',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'PAST EVENTS',
                            className: ` ml-2 uppercase text-transparent bg-clip-text bg-gradient-to-r from-accent  to-primary uppercase pt-6 text-center text-[22px] md:text-[25px] xl:text-[30px] 2xl:text-[35px] tracking-wider leading-8 font-bold ${
                                getFont('SUSHMA').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                'w-[15%] lg:w-[5%] mx-auto bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[6px]',
                            classNameContainer: ' pt-4'
                        }
                    ]
                }
            ],
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center font-semibold text-xl mt-5 text-primary w-[60%] ${
                getFont('SUSHMA').body
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('SUSHMA').body
            }`
        },
        eventLayout: {
            titleClass: `text-primary leading-5 uppercase text-[17px] md:text-[18px] font-medium mt-2 ${
                getFont('SUSHMA').body
            }`,
            hostClass: 'capitalize ',
            dateClass: ' text-[14px]'
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${
            getFont('SUSHMA').body
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('SUSHMA').body
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content items-start md:items-center max-w-full h-[30vh] lg:h-[45vh] bg-bottom lg:bg-center mx-auto text-center', // //bg-top
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0' //0.5
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692635066109_offer%20page',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692635066109_offer%20page',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/4685974.png',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.9',
                size: '30% auto'
            },
            defaultBackgroundImage:
                'https://godrejambassador.com/wp-content/uploads/2021/12/website-Event_Banner.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `font-semibold text-[20px] text-center text-xl text-neutral w-[60%] ${
                getFont('SUSHMA').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `uppercase text-secondary text-2xl xl:text-[26px] ${
                getFont('SUSHMA').heading
            } `,
            subAdditionalText:
                'Embassy Priority is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-normal text-[14px] py-2 mt-2 leading-6 ${
                getFont('SUSHMA').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('SUSHMA').heading}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/4685974.png',
            pattern2: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '1',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-[18px] leading-6 lg:leading-5 2xl:leading-6 text-primary ${
                getFont('SUSHMA').body
            }`,
            listBodyClass: `${getFont('SUSHMA').body}`,
            titleClassName: ` text-primary font-bold uppercase text-2xl mt-3 ${
                getFont('SUSHMA').heading
            } `,
            couponClassName: `block my-[0px] text-[18px] self-center text-center text-primary font-semibold ${
                getFont('SUSHMA').body
            }`,
            dateClassName: `py-[6px] text-neutral capitalize text-[17px] text-black  ${
                getFont('SUSHMA').body
            }`,
            termsAndConditionsClassName: `font-semibold self-center text-left capitalize text-neutral text-[12px]  ${
                getFont('SUSHMA').body
            }`,
            termsArrowClassName: 'text-2xl text-neutral',
            termsClassName: `font-semibold text-neutral mx-auto text-[11px] text-left  break-words leading-1 ${
                getFont('SUSHMA').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] font-bold uppercase self-center text-[16px] py-2.5 px-7 ${
                    getFont('SUSHMA').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `rounded-md bg-gradient-to-r from-[#b7955b] to-[#f4ebac] font-bold uppercase self-center text-[16px] py-2.5 px-7 ${
                    getFont('SUSHMA').body
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/33244856.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/33244856.jpg',
                bannerContainerClassName:
                    'w-full flex flex-col items-center justify-end text-center h-[45vh] md:h-[60vh] lg:h-[85vh] bg-cover bg-center',

                components: [
                    {
                        type: 'CUSTOM',
                        className:
                            'w-full lg:mt-0 lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                        components: [
                            {
                                type: 'TEXT',
                                content: 'CLUB SUSHMA RESIDENTIAL PROJECTS',
                                className: `text-white uppercase text-[20px] md:text-[25px] lg:text-[28px] xl:text-[32px] 2xl:text-[35px] tracking-wide lg:leading-9 font-bold pb-5 lg:pb-10 ${
                                    getFont('SUSHMA').bold
                                }`
                            }
                        ]
                    }
                ]
            },
            {
                type: 'DIVIDER',
                className:
                    'w-full bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[16px]',
                classNameContainer: ' '
            }
        ],
        moduleConfig: {
          
            constructionVisible: false,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: '',
            addHeader: true,
            headerClass: 'hidden lg:block h-32 bg-[#58595B]'
        }
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/84696683.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0',
            size: 'cover'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/81174860.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/81174860.jpg',
                bannerContainerClassName:
                    'w-full flex flex-col items-center justify-end text-center bg-cover lg:bg-center h-[45vh] md:h-[60vh] lg:h-[85vh]',
                components: [
                    {
                        type: 'CUSTOM',
                        className:
                            'w-full  lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                        components: [
                            {
                                type: 'TEXT',
                                content: 'CONTACT US',
                                className: `text-white uppercase text-[20px] md:text-[25px] lg:text-[28px] xl:text-[32px] 2xl:text-[35px] tracking-wide lg:leading-9 font-bold pb-5 lg:pb-10 ${
                                    getFont('SUSHMA').bold
                                }`
                            }
                        ]
                    }
                ]
            },
            {
                type: 'DIVIDER',
                className:
                    'w-full bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[16px]',
                classNameContainer: ' '
            }
        ],

  
    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-primary font-medium mt-4 text-[18px] md:text-[20px] ${
            getFont('SUSHMA').body
        }`,
        listClassName: `text-primary font-medium text-[17px] leading-7 ${
            getFont('SUSHMA').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/62009700.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/62009700.jpg',
                bannerContainerClassName:
                    'w-full flex flex-col items-center justify-end text-center bg-cover lg:bg-center h-[45vh] md:h-[60vh] lg:h-[85vh]',
                components: [
                    {
                        type: 'CUSTOM',
                        className:
                            'w-full lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto',
                        components: [
                            {
                                type: 'TEXT',
                                content: 'TERMS & CONDITIONS',
                                className: `text-white uppercase text-[20px] md:text-[25px] lg:text-[28px] xl:text-[32px] 2xl:text-[35px] tracking-wide lg:leading-9 font-bold pb-5 lg:pb-10 ${
                                    getFont('SUSHMA').bold
                                }`
                            }
                        ]
                    }
                ]
            },
            {
                type: 'DIVIDER',
                className:
                    'w-full bg-gradient-to-r from-[#b7955b] to-[#f4ebac] border-none h-[16px]',
                classNameContainer: ' '
            },

            {
                type: 'CUSTOM',
                url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Sushma-Buildtech/Images/84696683.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0'
                },
                className: 'text-left p-6 lg:p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-primary font-medium text-[18px] md:text-[20px] ${
                            getFont('SUSHMA').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-primary font-medium mt-4 text-[18px] md:text-[20px] ${
                            getFont('SUSHMA').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Sushma Group  project, and has entered into a registered Agreement to Sell; or (ii) employee of Sushma Group ; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Sushma Group  or any of its sister concerns/subsidiaries/affiliates (“Sushma Group Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Sushma Group Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Sushma Group.',
                            'Referrer shall not be able to refer existing customer(s) of Sushma Group. Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Sushma Group or (ii) is/are already registered with Sushma Group as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Sushma Group’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Sushma Group within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Sushma Group',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Sushma Group or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-primary font-medium text-[17px] leading-7 ${
                            getFont('SUSHMA').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-0 lg:px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Sushma Group, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('SUSHMA').body
                                } inline text-primary font-medium text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-[#b7955b] font-medium text-[17px] leading-7 ${
                                    getFont('SUSHMA').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('SUSHMA').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-[#b7955b] font-medium text-[17px] leading-7 ${
                                    getFont('SUSHMA').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('SUSHMA').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-[#b7955b] font-medium text-[17px] leading-7 ${
                                    getFont('SUSHMA').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('SUSHMA').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        appContainerClassName: 'bg-white text-secondary border-[#b7955b] ',
        appFontHeading: `${getFont('SUSHMA').body}`,
        appStoreLink:
            'https://apps.apple.com/fr/app/club-sushma/id6449425347?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.sushma&utm_medium=email',
        appScreen: 'https://cdn.loyalie.in/16711457.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('SUSHMA').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[10.5px] 3xl:text-[15px] leading-[16px] tracking-tight text-white ${
            getFont('SUSHMA').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('SUSHMA').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-primary bg-opacity-90'
    },
    referralForm: {
        name: 'Sushma Group',
        theme: 'sushmaTheme',
        color: '#F7941d',
        backgroundImage: 'https://cdn.loyalie.in/50376319.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '0',
            G1: '0',
            B1: '0',
            opacity1: '0.50',
            R2: '0',
            G2: '0',
            B2: '0',
            opacity2: '0.10'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: ' ',
            bookedCustomerHeading2: 'Sushma Group Home Owner',

            employee: false,
            employeeHeading1: 'I am an',
            // employeeHeading1: 'Sushma Group Employee',

            salesAdmin: true,
            salesAdminHeading1: ' ',
            salesAdminHeading2: 'Sushma Group Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        formContent: {
            heading: '"CREATE YOUR OWN STORY AT OUR COMMUNITY!"',
            headingClass: `self-center text-white sm:mt-0 text-3xl md:text-3xl 2xl:text-4xl w-[80%] lg:w-[80%] 2xl:w-[80%] text-center leading-7 tracking-wide ${
                getFont('SUSHMA').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton: ` w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold  ${
                getFont('SUSHMA').heading
            }`
        },
        borderUrl:
            "data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E path %7B animation: stroke 5s infinite linear; %7D @keyframes stroke %7B to %7B stroke-dashoffset: 776; %7D %7D %3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23b7955b' /%3E%3Cstop offset='25%25' stop-color='%23b7955b' /%3E%3Cstop offset='50%25' stop-color='%23b7955b' /%3E%3Cstop offset='100%25' stop-color='%23f4ebac' /%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='%23ffffff' stroke-width='7' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='7' stroke-dasharray='388' /%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink: 'sushma-website/terms-and-conditions',
        customLink: '/sushma-website/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default SushmaConfig;
