import react, { useEffect, useState } from 'react';
import useAxios from '../../../../Hooks/use-axios';
import qs from 'qs';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { winnreNoAuth } from '../../../../utils/axios';

const useData = () => {
    // const [initial, setInitial] = useState(true);
    const [lng, setLng] = useState(28.38);
    const [lat, setLat] = useState(77.12);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

    const [viewport, setViewport] = useState({
        width: 400,
        height: 400,
        latitude: 22.43,
        longitude: 80.84,
        zoom: 4
    });

    const { isLoading, error, sendRequest: fetchProjectLocation } = useAxios();

    const getProjectLocation = (projectId) => {

        winnreNoAuth.get(`/winnre/user/projectLocation/${projectId}`).then((data) => {
            if (!isNaN(data.latitude) && !isNaN(data.longitude))
                setViewport({
                    ...viewport,
                    latitude: Number(data.latitude),
                    longitude: Number(data.longitude),
                    zoom: 9
                });

            setLat(Number(data.latitude));
            setLng(Number(data.longitude));
        })

    };

    return {
        getProjectLocation,
        lng,
        lat,
        viewport,
        setViewport
    };
};

export default useData;
