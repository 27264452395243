import React from 'react'
import { noDealsvg } from '../ProductListing/svg'
const ZeroDeals = () => {
  return (
    <div>
       <div className="mt-4 px-4 lg:px-0">
                            <p className="font-bold text-xl">Search Results</p>
                            <div className="w-full pl-0 xl:pl-6 mt-4 border border-gray-300 rounded-xl">
                                <div className="flex flex-row items-center justify-center p-7 gap-5">
                                    <div className="mt-1 lg:mt-0">
                                        {noDealsvg}
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-2xl font-bold">
                                            Oops!
                                        </p>
                                        <p className="whitespace-nowrap mt-2">
                                            No deals found,
                                        </p>
                                        <p className="lg:whitespace-nowrap mt-1 text-gray-400">
                                            We're working hard to bring you
                                            exciting offers — stay tuned!
                                        </p>
                                    </div>
                                </div>
                            </div>
            </div> 
      
    </div>
  )
}

export default ZeroDeals
