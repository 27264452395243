import { Link } from 'react-router-dom';
import { getConfig } from '../../../utils/config';
import ReloyLogo from '../../../assets/logo/Reloy_White_Logo.png';

const Footer1 = () => {
    const config = getConfig();
    return (
        <div
            className={
                config?.footer.containerClassName
                    ? config.footer.containerClassName
                    : 'bg-secondary-content xl:px-16 px-8 py-5 text-gray-100 border-t-4 border-secondary'
            }
        >
            <div className=" w-full mt-2 flex flex-col lg:flex-row justify-end ">
                <div>
                    <h2
                        className={
                            config?.footer.footerHeadingClassName
                                ? config?.footer.footerHeadingClassName
                                : 'text-3xl uppercase'
                        }
                    >
                        {config?.footer.footerHeading || 'CORPORATE OFFICE'}
                    </h2>
                    <p
                        className={
                            config?.footer.clientAddressClassName
                                ? config?.footer.clientAddressClassName
                                : 'pt-3 font-brandFont'
                        }
                    >
                        {config?.footer.clientAddress}
                    </p>{' '}
                </div>{' '}
                <div className="w-full  flex lg:justify-end mt-5 lg:mt-0">
                    <div className="text-left">
                        <Link
                            to={config?.footer.contactUsPath || '/contact-us'}
                            className={
                                config?.footer.contactUsClassName
                                    ? config?.footer.contactUsClassName
                                    : ' text-left pt-4 font-brandFont'
                            }
                        >
                            Contact Us
                        </Link>
                    </div>
                    <span className="px-2">|</span>
                    <div className="text-right">
                        <Link
                            to={config?.footer.termsAndConditionsPath}
                            className={
                                config?.footer.termsAndConditionsClassName
                                    ? config?.footer.termsAndConditionsClassName
                                    : 'pt-4 font-brandFont'
                            }
                        >
                            Terms and Conditions
                        </Link>
                    </div>
                </div>
            </div>

            <hr className="w-full bg-gray-100 mt-4 lg:mt-10 mb-4  border-none h-[1px]"></hr>
            <details>
                <summary className="font-Helvetica_Light cursor-pointer">
                    {' '}
                    Disclaimer
                </summary>{' '}
                <div className="w-full  text-justify">
                    <p
                        className={
                            config?.footer.footerContent1ClassName
                                ? config?.footer.footerContent1ClassName
                                : ' font-brandFont text-accent-content'
                        }
                    >
                        {config?.footer.footerContent1}
                    </p>
                    <p
                        className={
                            config?.footer.footerContent2ClassName
                                ? config?.footer.footerContent2ClassName
                                : 'pt-4 font-brandFont text-accent-content'
                        }
                    >
                        {config?.footer.footerContent2}
                    </p>
                </div>
                <div className="w-full md:flex">
                    <div className="w-[90%] md:w-[20%]  lg:w-[20%] xl:w-[15%] text-left align-middle m-auto ">
                        <img
                            src={
                                config?.footer.footerLogo
                                    ? config?.footer.footerLogo
                                    : `https://cdn.loyalie.in/53565661.png`
                            }
                            alt="RELOY"
                        />
                    </div>
                    <div className="xl:w-[85%] lg:w-[80%] md:w-[80%] text-justify xl:text-sm lg:pl-4  py-4">
                        <p
                            className={
                                config?.footer.loyalieFooterContentClassName
                                    ? config?.footer
                                          .loyalieFooterContentClassName
                                    : ' font-brandFont text-accent-content'
                            }
                        >
                            {config?.footer.loyalieFooterContent}
                        </p>
                    </div>
                </div>
            </details>
        </div>
    );
};

export default Footer1;
