import { useState } from "react";
import { useInput, usePhoneInput } from "../../../Hooks";
import { winnreAuth } from "../../../utils/axios";

export const useData = () => {
    const [projectList, setProjectList] = useState([]);
    const [unitValidated, setUnitValidated] = useState(false);
    const [project, setProject] = useState(null); //selected

    const {
        value: unitNumber,
        isValid: unitNumberIsValid,
        hasError: unitNumberHasError,
        valueChangeHandler: unitNumberChangedHandler,
        inputBlurHandler: unitNumberBlurHandler,
        reset: resetUnitNumber
    } = useInput((value) => value !== '');

    const {
        value: name,
        isValid: nameIsValid,
        hasError: nameHasError,
        valueChangeHandler: nameChangedHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetName
    } = useInput((value) => value !== '');

    const {
        state: {
            isValid: currentMobileNumberIsValid,
            mobile: currentMobile,
            country: currentCountry,
            mobileHasError: currentMobileHasError,
            isInputFocused: isCurrentMobileInputFocused,
            isInternationalCustomer: isCurrentMobileInternational
        },
        onChange: currentMobileOnChange,
        validator: currentMobileValidtor,
        setIsTouched: setCurrentMobileIsTouched,
        setMobile: setCurrentMobile,
        getMobileWithoutCountryCode: getCurrentMobileWithoutCountryCode,
        setInputFocused: setCurrentMobileInputFocused,
        setIsInternationalCustomer: setIsCurrentMobileInternational
    } = usePhoneInput();

    const getProjects = () => {
        winnreAuth({
            method: 'GET',
            url: `winnre/user/project/all-names`
            // headers: headers2
        })
            .then((res) => {
                // console.log('res', res);
                setProjectList(
                    res.map((obj) => {
                        return {
                            id: obj.id,
                            projectName: obj.projectName
                        };
                    })
                );
            })
            .catch(() => {});
    };

    return {
        state: {
            name,
            nameIsValid,
            nameHasError,
            unitNumber,
            unitNumberIsValid,
            unitNumberHasError,
            currentMobile,
            currentCountry,
            currentMobileHasError,
            isCurrentMobileInputFocused,
            project,
            projectList,
            unitValidated,currentMobileNumberIsValid
        },
        resetName,
        resetUnitNumber,
        nameChangedHandler,
        nameBlurHandler,
        unitNumberChangedHandler,
        unitNumberBlurHandler,
        setCurrentMobile,
        setIsCurrentMobileInternational,
        getCurrentMobileWithoutCountryCode,
        setCurrentMobileIsTouched,
        setCurrentMobileInputFocused,
        currentMobileOnChange,
        currentMobileValidtor,
        setProject,
        setProjectList,
        setUnitValidated, 
        getProjects
    };
}