import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
// console.log(window.location.origin);
const domain = window.location.origin;
const AjmeraConfig = {
    clientId:
        '1879e2bd97969b10daee96c287081c6d01828015f28a06b0eccc08b348e3ea89',
    id: 45,
    name: 'Ajmera',
    fullName: 'Ajmera',
    programName:'Ajmera Select',
    primaryColor: '#BB3231',
    fontBody: `${getFont('AJMERA').medium}`,
    fontTitle: `${getFont('AJMERA').heading}`,
    theme: 'ajmeraTheme',
    favicon:
        'https://ajmera.com/wp-content/uploads/2023/08/ajmera-new-logo-white.png',

    googleConfig: {
        gID: 'G-JM1K557RY9',
        keywordsContent:
            'Ajmera real estate referral programs, Ajmera Group real estate referral program, Ajmera Group real estate refer and earn, Ajmera real estate project refer, Ajmera Group real estate, Ajmera real estate loyalty program, Ajmera Group real estate news, Ajmera Group real estate upcoming projects in Bengaluru, Ajmera Group real estate launch Ajmera real estate, Ajmera Group real estate launch, Ajmera Group real estate program, Ajmera Group real estate projects, Ajmera Group real estate blogs, Ajmera Group real estate offers, Ajmera Group real estate upcoming projects in Bengaluru, Ajmera Group real estate events, Ajmera Group, Ajmera, Ajmera Group real estate best residential projects, Ajmera Group real estate real estate developers, All New Projects by Ajmera Group real estate Builders & Developers, Ongoing Projects by Ajmera Group real estate Pvt Ltd, Ajmera Group real estate Bengaluru, Ajmera Group real estate new program, Ajmera Group press release, Ajmera Group upcoming projects, ajmera group, Ajmera Group bangalore, JP real estate, JP reit, jitu virwani, JP commercial projects, Ajmera Group projects, Ajmera Group projects in bangalore',
        descriptionContent:
            "We present to you a bouquet of such experiences and more with Ajmera - a bespoke loyalty and referral programme that's designed exclusively for JP homeowners",
        authorContent: 'Ajmera'
    },

    facebookPixelConfig: {
        id: '7843553039004724', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~~99199217',
    webengageAPIKey:'66d2d755-c203-4984-bd36-9f09ba8a8e36',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout3',
        
    },
    activateReferPage: true,
    logoLeft: {
        name: 'Ajmera Select Logo',
        url: 'https://cdn.loyalie.in/1698834540467_Ajmera_Select_Logo',
        stickyNavBarLogoClass:
            ' h-10 md:h-12 lg:h-10 xl:h-10 2xl:h-12 pl-3 xl:pl-10 ease-out duration-500',
        baseNavBarLogoClass:
            ' h-10 md:h-12 lg:h-10 xl:h-10 2xl:h-14 pl-3 xl:pl-10 ease-in duration-500'
    },

    logoRight: {
        name: 'Ajmera Logo',
        url: 'https://cdn.loyalie.in/1698836887097_Ajmera%20Logo',
        stickyNavBarLogoClass:
            'hidden lg:block lg:h-12 2xl:h-16 pr-3 pl-10 xl:pr-14 ease-out duration-500',
        baseNavBarLogoClass:
            'h-20 md:h-25 lg:h-14 2xl:h-20 pr-3 pl-10 xl:pr-14 ease-in duration-500'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('AJMERA').body}`,
        height: 'h-16 xl:h-20 2xl:h-24 ease-out duration-500',
        stickyHeight: 'h-14 xl:h-16 2xl:h-20 ease-in duration-500 	',
        mobileIconColor: 'text-primary-focus',
        dropDownBg: 'bg-white lg:bg-primary',
        showActiveLinkColor: false,
        footerNavItemClass: `tracking-widest uppercase text-white text-[12px] md:text-[14px] lg:text-white hover:text-white font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg  ${
            getFont('AJMERA').body
        }`,
        baseNavBar: {
            border: true,
            borderClass: 'shadow lg:shadow-none',
            backgroundColor: 'bg-primary-content lg:bg-transparent', // default: transparent
            containerClass: '',
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black text-[14px] lg:text-black hover:text-primary font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg ',
            dropDownContainerClass: `hover:text-primary-focus lg:hover:text-white lg:hover:bg-primary-focus lg:focus:text-white lg:focus:bg-accent lg:focus:outline-none lg:focus:shadow-outline  `,
            dropDownItemClass:
                'tracking-widest text-black lg:text-white text-[14px] font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: true,
            borderClass: 'shadow ',
            backgroundColor: 'bg-primary-content ', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black text-[14px] lg:text-black hover:text-primary font-semibold lg:text-[12px] xl:text-[14px] 3xl:text-lg ' // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: true,
    floaterType: 'CALL',
    mobile: '+919513438871',
    floaterColor: 'bg-primary',
    bannerNegativeTopMarginClass: 'lg:-mt-20 2xl:-mt-24', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,
    clientButtonClassName: `mx-auto text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 ${
        getFont('AJMERA').body
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage:
                        'https://cdn.loyalie.in/1698836557215_HomeBanner',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1699000926325_Homebanner_SM',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0',
                        size: 'cover'
                    },
                    bannerContainerClass:
                        'h-[92vh] lg:h-[82vh] xl:h-[92vh] bg-center lg:bg-right-bottom lg:bg-cover border-b-[4px] border-primary',
                    bannerItemContainerClass:
                        'items-center md:items-start justify-start lg:justify-end  px-8 py-8 xl:py-16 md:pl-10 xl:pl-16 ',
                    position: 'text-left',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'text-center lg:text-left w-full md:w-[90%] lg:w-[33%] xl:w-[35%] 2xl:w-[32.5%] mx-auto lg:mx-0 px-4 lg:py-20 md:p-8 xl:p-10',
                            components: [
                                {
                                    type: 'TEXT',
                                    content:
                                        'BE PART OF A NEW HOMEOWNING LEGACY',
                                    className: `uppercase text-secondary tracking-wide leading-[1.1] text-[20px] md:text-[35px] lg:text-[20px] xl:text-[25px] 2xl:text-[30px]  ${
                                        getFont('AJMERA').heading
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'mt-4 mx-auto lg:mx-0 w-[25%] xl:w-[30%] bg-secondary border-none h-[4px]',
                                    classNameContainer: ''
                                },
                                {
                                    type: 'IMAGE',
                                    url: 'https://cdn.loyalie.in/1698834540467_Ajmera_Select_Logo',
                                    position: 'center',
                                    classNameContainer:
                                        'w-[80%] mx-auto lg:mx-0 md:w-[57%] lg:w-[77%] py-5 md:py-8 lg:py-5 '
                                },

                                {
                                    type: 'TEXT',
                                    content:
                                        'A Loyalty & Referral Program for Ajmera residents',
                                    className: `text-[15px] md:text-[17px] lg:text-[15px] xl:text-[17px] 2xl:text-[20px] text-secondary tracking-wider leading-[1.3] text-center lg:text-left font-medium self-center break-words w-full lg:w-[90%] xl:w-[80%] ${
                                        getFont('AJMERA').secondary
                                    }`
                                },
                                {
                                    type: 'BUTTON',
                                    content: 'Refer Now',
                                    className: `mx-auto text-center mt-3 border-primary border-[3px] font-medium text-[15px] xl:text-[17px] tracking-wider rounded-md py-2 xl:py-2 px-6 ${
                                        getFont('AJMERA').heading
                                    }`,
                                    path: 'gift-and-earn',
                                    onClick: 'CHANGE_PATH_AND_SCROLL',
                                    scrollTo: 'referral_section'
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: '',
                    url: 'https://cdn.loyalie.in/1698910811488_Pattern',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0',
                        size: 'cover'
                    },
                    className:
                        'flex flex-col justify-center text-center bg-top pt-8 px-4 xl:px-8 2xl:px-28 pb-10 lg:pb-20 -mb-28',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'About AJMERA SELECT',
                            className: `text-secondary uppercase pt-5 text-center uppercase text-[22px] md:text-[25px] 2xl:text-[35px] tracking-wide  ${
                                getFont('AJMERA').heading
                            }`
                        },

                        {
                            type: 'DIVIDER',
                            className:
                                'my-4 mx-auto w-[25%] xl:w-[10%] bg-secondary border-none h-[4px]',
                            classNameContainer: ''
                        },
                        {
                            type: 'TEXT',
                            content:
                                'In 1968, the Ajmera Group was conceived with a vision and nurtured with quality workmanship and integrity. Today, with a rich legacy spanning 55 years and 46,000+ Happy Families, Ajmera Group has expanded from Gujarat to Mumbai, Pune, Bengaluru, and beyond Indian borders to Bahrain and the United Kingdom.',
                            className: `text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] text-secondary tracking-wide leading-[1.3] font-medium self-center break-words w-[80%] ${
                                getFont('AJMERA').medium
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Introducing Ajmera Select—an exclusive membership program for Ajmera Properties owners. This program curates a lifestyle that goes beyond homeownership, enriching every facet of your life.',
                            className: `pt-5 text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] text-secondary tracking-wide leading-[1.3] font-medium self-center break-words w-[80%] ${
                                getFont('AJMERA').medium
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'Indulge in privileges with us',
                            className: `text-secondary py-10 text-center uppercase text-[18px] md:text-[20px] 2xl:text-[25px] tracking-wide  ${
                                getFont('AJMERA').heading
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'pb-10 lg:pb-20 w-[90%] mx-auto lg:w-full flex flex-row lg:flex-col overflow-x-auto lg:overflow-x-hidden gap-x-20 lg:gap-x-0 ',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-[90%] 2xl:w-[80%] mx-auto flex flex-col-reverse lg:flex-row',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-[300px] lg:w-[50%] h-[200px]  lg:h-auto border-b-[4px] lg:border-b-0 lg:border-t-[4px] border-primary flex flex-col justify-center items-center',
                                            url: 'https://cdn.loyalie.in/1698925088798_gradient',
                                            linearGradient: {
                                                R: '255',
                                                G: '255',
                                                B: '255',
                                                opacity: '0.70',
                                                size: 'cover'
                                            },
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        '*Earn up to 1% referral reward.',
                                                    className: `text-secondary w-[80%] lg:w-[42%] text-[16px] lg:text-[18px] 2xl:text-[22px] leading-[1.2] tracking-wide  ${
                                                        getFont('AJMERA').medium
                                                    }`
                                                },
                                                {
                                                    type: 'BUTTON',
                                                    content: 'Refer Now',
                                                    className: `mx-auto text-center mt-3 border-primary border-[3px] font-medium text-[15px] xl:text-[17px] tracking-wider rounded-md py-2 xl:py-2 px-6 ${
                                                        getFont('AJMERA')
                                                            .heading
                                                    }`,
                                                    path: 'gift-and-earn',
                                                    onClick:
                                                        'CHANGE_PATH_AND_SCROLL',
                                                    scrollTo: 'referral_section'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-[300px] lg:w-[50%]',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/1698915822749_Image_4',
                                                    position: 'center',
                                                    classNameContainer: 'w-full'
                                                }
                                            ]
                                        }
                                    ]
                                },

                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-[90%] 2xl:w-[80%] mx-auto flex flex-col lg:flex-row',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-[300px] lg:w-[50%]',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/1698915835988_Image_3',
                                                    position: 'center',
                                                    classNameContainer: 'w-full'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-[300px] lg:w-[50%] h-[200px] lg:h-auto border-b-[4px] lg:border-b-0 border-primary flex flex-col justify-center items-center',
                                            url: 'https://cdn.loyalie.in/1698925088798_gradient',
                                            linearGradient: {
                                                R: '255',
                                                G: '255',
                                                B: '255',
                                                opacity: '0.70',
                                                size: 'cover'
                                            },
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'Enjoy exclusive membership benefits from curated brands.',
                                                    className: `text-secondary  w-[80%] lg:w-[60%] text-[16px] lg:text-[18px] 2xl:text-[22px] leading-[1.2] tracking-wide  ${
                                                        getFont('AJMERA').medium
                                                    }`
                                                },
                                                {
                                                    type: 'BUTTON',
                                                    content: 'Redeem Now',
                                                    className: `mx-auto text-center mt-3 border-primary border-[3px] font-medium text-[15px] xl:text-[17px] tracking-wider rounded-md py-2 xl:py-2 px-6 ${
                                                        getFont('AJMERA')
                                                            .heading
                                                    }`,
                                                    path: 'deals',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-[90%] 2xl:w-[80%] mx-auto flex flex-col-reverse lg:flex-row ',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-[300px] lg:w-[50%] h-[200px] lg:h-auto border-b-[4px] lg:border-b-0 border-primary flex flex-col justify-center items-center',
                                            url: 'https://cdn.loyalie.in/1698925088798_gradient',
                                            linearGradient: {
                                                R: '255',
                                                G: '255',
                                                B: '255',
                                                opacity: '0.70',
                                                size: 'cover'
                                            },
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'Explore curated workshops & events.',
                                                    className: `text-secondary  w-[80%] lg:w-[42%] text-[16px] lg:text-[18px] 2xl:text-[22px] leading-[1.2] tracking-wide  ${
                                                        getFont('AJMERA').medium
                                                    }`
                                                },
                                                {
                                                    type: 'BUTTON',
                                                    content: 'Know More',
                                                    className: `mx-auto text-center mt-3 border-primary border-[3px] font-medium text-[15px] xl:text-[17px] tracking-wider rounded-md py-2 xl:py-2 px-6 ${
                                                        getFont('AJMERA')
                                                            .heading
                                                    }`,
                                                    path: 'events',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-[300px] lg:w-[50%]',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/1698915846585_Image_2',
                                                    position: 'center',
                                                    classNameContainer: 'w-full'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-[90%] 2xl:w-[80%] mx-auto flex flex-col lg:flex-row',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-[300px] lg:w-[50%]',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/1698915856387_Image_1',
                                                    position: 'center',
                                                    classNameContainer: 'w-full'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-[300px] lg:w-[50%] h-[200px] lg:h-auto border-b-[4px] border-primary flex flex-col justify-center items-center',
                                            url: 'https://cdn.loyalie.in/1698925088798_gradient',
                                            linearGradient: {
                                                R: '255',
                                                G: '255',
                                                B: '255',
                                                opacity: '0.70',
                                                size: 'cover'
                                            },
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'Explore our home & lifestyle blogs for new trends.',
                                                    className: `text-secondary w-[80%] lg:w-[42%] text-[16px] lg:text-[18px] 2xl:text-[22px] leading-[1.2] tracking-wide  ${
                                                        getFont('AJMERA').medium
                                                    }`
                                                },
                                                {
                                                    type: 'BUTTON',
                                                    content: 'Read Now',
                                                    className: `mx-auto text-center mt-3 border-primary border-[3px] font-medium text-[15px] xl:text-[17px] tracking-wider rounded-md py-2 xl:py-2 px-6 ${
                                                        getFont('AJMERA')
                                                            .heading
                                                    }`,
                                                    path: 'blogs',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            className: 'w-[90%] 2xl:w-[80%] mx-auto',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'our latest blogs',
                                    className: `text-secondary uppercase text-center lg:text-left uppercase text-[22px] md:text-[25px] 2xl:text-[35px] tracking-wide pt-5 ${
                                        getFont('AJMERA').heading
                                    }`
                                }
                            ]
                        },
                        {
                            type: 'BLOG_LAYOUT_VERTICAL',
                            className:
                                'w-full h-auto flex flex-col-reverse lg:flex-row mt-10  justify-center',
                            classNameContainer:
                                'overflow-x-auto lg:overflow-x-hidden mx-auto w-[90%] 2xl:w-[86%] 2xl:px-10 flex lg:grid lg:grid-cols-12 gap-x-10 gap-y-14 pb-10 lg:pb-20 '
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'w-[90%] 2xl:w-[80%] mx-auto flex flex-col lg:flex-row lg:gap-x-10 ',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-full lg:w-[30%] mx-auto flex items-center justify-center',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content: 'Exclusive Offers',
                                            className: `py-10 text-secondary uppercase text-center lg:text-left uppercase text-center text-[22px] md:text-[25px] 2xl:text-[35px] tracking-wide leading-[1.2] ${
                                                getFont('AJMERA').heading
                                            }`
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-full lg:w-[85%] xl:w-[90%] mx-auto flex border-b-[4px] lg:border-b-0 lg:border-l-[4px] border-primary',
                                    url: 'https://cdn.loyalie.in/1698925088798_gradient',
                                    linearGradient: {
                                        R: '255',
                                        G: '255',
                                        B: '255',
                                        opacity: '0.70',
                                        size: 'cover'
                                    },
                                    components: [
                                        {
                                            type: 'CAROUSEL',
                                            getVendorData: true,
                                            offerCards: true,
                                            ContainerClassName:
                                                'bg-cover rounded-lg h-auto mx-3 2xl:mx-5 p-8',
                                            caraouselClassName:
                                                'w-[80%] md:w-[95%] lg:w-[85%] xl:w-[80%] mx-auto  pt-6 relative pb-10 drop-shadow',
                                            classNameCTA: `bg-transparents text-[14px] px-4 py-2 text-secondary-focus text-center mt-3 mx-auto uppercase font-semibold border-[3px] rounded-lg border-primary ${
                                                getFont('AJMERA').medium
                                            }`,
                                            backgroundImageNone: true,
                                            xlSlidesToShow: 4,
                                            arrows: true,
                                            autoplay: true,
                                            autoplaySpeed: 3000,
                                            infinite: true,
                                            dots: false,
                                            slidesToShow: 3,
                                            pauseOnHover: true,
                                            speed: 1000,
                                            onImageClick: openURLNewWindow
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            className: 'pb-10',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-[89%] 2xl:w-[90%] mx-auto  pt-16 ',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content: 'our projects',
                                            className: `text-secondary uppercase text-center lg:text-left uppercase text-[22px] lg:text-[25px] 2xl:text-[35px] tracking-wide leading-[1.2] ${
                                                getFont('AJMERA').heading
                                            }`
                                        }
                                    ]
                                },
                                {
                                    type: 'CAROUSEL',
                                    customSlide: true,
                                    caraouselClassName:
                                        'px-8 w-[99%] mx-auto md:pb-5 pt-1 relative pb-5 lg:py-5',
                                    imageContainerClassName: 'm-4 relative',
                                    imageClassName:
                                        'w-[100%] mx-auto h-[200px] lg:bg-cover rounded-lg',
                                    captionClass: `uppercase text-sm lg:text-lg leading-8 ${
                                        getFont('AJMERA').body
                                    }`,
                                    xlSlidesToShow: 3,
                                    arrows: true,
                                    autoplay: true,
                                    autoplaySpeed: 3000,
                                    infinite: true,
                                    dots: false,
                                    slidesToShow: 4,
                                    pauseOnHover: true,
                                    speed: 3000,
                                    onImageClick: openURLNewWindow,
                                    imgArr: [
                                        {
                                            imgUrl: 'https://ajmeraprod.s3.ap-south-1.amazonaws.com/Document/Project/13/Ff94hqh3wV.jpg',
                                            alt: 'img',
                                            url: `${domain}/ajmera-home/projects/detail?projectId=13`,
                                            caption:
                                                'Ajmera & Mehta Destination One Kalyan'
                                        },
                                        {
                                            imgUrl: 'https://ajmeraprod.s3.ap-south-1.amazonaws.com/Document/Project/12/Ej2GSyQxiG.jpg',
                                            alt: 'img',
                                            url: `${domain}/ajmera-home/projects/detail?projectId=12`,
                                            caption: 'Ajmera Lakeside Paradise'
                                        },
                                        {
                                            imgUrl: 'https://ajmeraprod.s3.ap-south-1.amazonaws.com/Document/Project/7/hP3783LWrq.jpg',
                                            alt: 'img',
                                            url: `${domain}/ajmera-home/projects/detail?projectId=7`,
                                            caption: 'Ajmera Prive'
                                        },
                                        {
                                            imgUrl: 'https://ajmeraprod.s3.ap-south-1.amazonaws.com/Document/Project/14/PBBW32ZkRX.jpg',
                                            alt: 'img',
                                            url: `${domain}/ajmera-home/projects/detail?projectId=14`,
                                            caption: 'Ajmera Eden'
                                        },
                                        {
                                            imgUrl: 'https://ajmeraprod.s3.ap-south-1.amazonaws.com/Document/Project/4/dgzeSk8rxR.jpg',
                                            alt: 'img',
                                            url: `${domain}/ajmera-home/projects/detail?projectId=4`,
                                            caption: 'Ajmera Nucleus'
                                        },
                                        {
                                            imgUrl: 'https://ajmeraprod.s3.ap-south-1.amazonaws.com/Document/Project/8/07PXy7ncDG.jpg',
                                            alt: 'img',
                                            url: `${domain}/ajmera-home/projects/detail?projectId=8`,
                                            caption: 'Ajmera Exotica'
                                        },
                                        {
                                            imgUrl: 'https://ajmeraprod.s3.ap-south-1.amazonaws.com/Document/Project/6/snNM9ZUZPD.jpg',
                                            alt: 'img',
                                            url: `${domain}/ajmera-home/projects/detail?projectId=6`,
                                            caption: 'Ajmera Arham'
                                        },
                                        {
                                            imgUrl: 'https://ajmeraprod.s3.ap-south-1.amazonaws.com/Document/Project/5/qI0Fwh2Q06.jpg',
                                            alt: 'img',
                                            url: `${domain}/ajmera-home/projects/detail?projectId=5`,
                                            caption: 'Ajmera Sikova'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },

        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        referPage: {
            show: true,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            scrollTo: 'referral_section',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: false,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[75vh] md:h-[70vh] object-cover bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center lg:text-left text-black text-[15px] md:text-[20px] xl:text-[25px] uppercase my-5 tracking-widest lg:w-[55%] ml-auto  ${
                                getFont('AJMERA').body
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: 'flex lg:w-[55%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `text-black text-center mt-3 border-primary border-[3px] font-medium text-[15px] xl:text-[17px] tracking-wider rounded-md py-2 xl:py-2 px-6 ${
                                        getFont('AJMERA').heading
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/JP-Infra/Images/83352489.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[45vh] md:h-[70vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').body
                    //         }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-black text-[15px] leading-10 md:text-[20px] xl:text-[45px] font-bold tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').heading
                    //         }`
                    // },
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex w-[70%] ml-auto justify-center mt-7', components: [{
                    //         type: 'BUTTON',
                    //         content: 'Start Referring Now',
                    //         scrollTo: 'referral_section',
                    //         onClick: 'SCROLL'
                    //     }]
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold bg-primary text-white  ${
                getFont('AJMERA').body
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[87vh] bg-center md:bg-right  mx-auto text-center border-b-[4px] border-primary',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.40'
            },
            backgroundImage: 'https://cdn.loyalie.in/1695987591646_blog_banner',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1695987591646_blog_banner',
            components: [
                {
                    type: 'TEXT',
                    content: 'AJMERA READERS’ CLUB',
                    className: `mt-12 md:mt-10 mb-3 lg:text-[30px]  text-[35px] md:text-[40px] lg:text-[30px]  text-secondary uppercase drop-shadow tracking-wider ${
                        getFont('AJMERA').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] g:w-[15%] xl:w-[25%] bg-primary-focus border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-medium mt-5 mb-3  text-xl md:text-[16px] text-secondary xl:text-[17px] tracking-wider ${
                        getFont('AJMERA').medium
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `mx-auto text-center mt-3 font-medium text-[12px] md:text-[17px] tracking-wider border-[3px] border-primary rounded-lg text-secondary py-3 2xl:py-3 px-12 md:px-8 ${
                        getFont('AJMERA').medium
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'AJMERA READERS’ CLUB',
                    className: `mt-12 md:mt-10 mb-3 lg:text-[30px]  text-[35px] md:text-[40px] lg:text-[30px]  text-secondary uppercase drop-shadow tracking-wider ${
                        getFont('AJMERA').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] g:w-[15%] xl:w-[25%] bg-primary-focus border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-medium mt-5 mb-3  text-xl md:text-[16px] text-secondary xl:text-[17px] tracking-wider ${
                        getFont('AJMERA').medium
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `mx-auto text-center mt-3 font-medium text-[12px] md:text-[17px] tracking-wider border-[3px] border-primary text-secondary py-3 2xl:py-3 px-12 md:px-8 rounded-lg ${
                        getFont('AJMERA').medium
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('AJMERA').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('AJMERA').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary-focus text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0 tracking-normal text-[20px] text-secondary ${
                    getFont('AJMERA').heading
                }`,
                headingClass: `text-[25px] lg:text-[27px] 2xl:text-[32px] uppercase tracking-tight text-secondary-focus mt-3 leading-8 break-words ${
                    getFont('AJMERA').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-secondary  min-h-[40px] lg:min-h-[70px] ${
                    getFont('AJMERA').body
                }`,
                dateClass: `text-[13px] uppercase text-secondary font-semibold my-3 ${
                    getFont('AJMERA').body
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 font-medium ${
                    getFont('AJMERA').medium
                }`
            },
            sectionHeadings: `text-secondary uppercase pt-10 text-center uppercase text-[22px] md:text-[25px] 2xl:text-[35px] tracking-wide uppercase ${
                getFont('AJMERA').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout3: {
            cardHeight:
                'h-[500px] md:h-[470px] lg:h-[500px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[260px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 md:h-[45px] lg:h-[65px] text-[18px] 3xl:text-[20px] tracking-tight	 font-medium uppercase leading-[1.2] break-words  ${
                getFont('AJMERA').heading
            }`,
            categoryClass: `bg-primary text-white ${getFont('AJMERA').body}`,
            summaryClass: `font-normal text-[15.5px] xl:text-[16px] mt-2 leading-[1.2] text-secondary-focus md:h-[80px] lg:h-[100px] ${
                getFont('AJMERA').body
            }`,
            buttonClass: `border-[3px] rounded-lg border-primary flex justify-center self-center float-right text-[13px] xl:text-[16px] text-secondary py-1 px-3 md:px-3 m-3 ${
                getFont('AJMERA').body
            }`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[14px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-secondary-focus ${
                getFont('AJMERA').body
            }`,
            noBlogsClass: `bg-secondary text-white p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('AJMERA').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/33191553.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/33191553.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0',
                size: 'cover'
            },

            negativeMargin: ' ',
            containerClass: ' text-white lg:w-[50%] bg-top',
            heading: '',
            headingClass: ` text-center lg:w-[70%] text-[25px] xl:text-[35px] text-secondary uppercase ${
                getFont('AJMERA').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('AJMERA').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('AJMERA').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('AJMERA').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('AJMERA').heading
                    }`
                }
            ]
        },

        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: `self-center text-[22px] lg:text-[25px] xl:text-[35px] uppercase tracking-tight text-secondary mt-14 leading-10 break-words ${
                getFont('AJMERA').heading
            }`,
            dividerClass:
                'my-4 mx-auto  w-[30%] md:w-[20%] lg:w-[10%] bg-[#eb0988] border-none h-2 md:h-[8px] hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('AJMERA').heading
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('AJMERA').body
            }`
        },
        eventLayout: {
            loadMoreButtonClass: `mx-auto text-center mt-3 font-medium text-[15px] xl:text-[17px] tracking-wider bg-primary-focus text-white py-2.5 xl:py-3 px-8 ${
                getFont('AJMERA').body
            }`,
            titleClass: `text-primary-focus leading-5 uppercase text-[18px] h-[40px] font-medium mt-2 ${
                getFont('AJMERA').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-accent font-semibold text-lg my-4 z-10 ${
            getFont('AJMERA').body
        }`,
        noGalleryClass: ` text-accent font-semibold ${getFont('AJMERA').body}`
    },
    deals: {
        banner: {
            hideSearch: false,
            bannerContainerClassName:
                'hidden md:hero hero-content  max-w-full h-[30vh] lg:h-[45vh] bg-top bg-cover', //bg-top bg-cover
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0',
                size: 'cover'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692636102370_deals%20banner',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692636102370_deals%20banner',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            sectionBackgroundImage:
                'https://cdn.loyalie.in/1695201979484_Pattern',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.98',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center text-[22px] md:text-[20px] text-center text-xl text-neutral w-[60%] ${
                getFont('AJMERA').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `text-secondary uppercase text-[22px] lg:text-[25px] xl:text-[35px] leading-7 xl:leading-9 ${
                getFont('AJMERA').heading
            } `,
            subAdditionalText:
                'Ajmera is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-normal text-[14px] text-neutral ${
                getFont('AJMERA').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('AJMERA').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/82775700.png',
            pattern2: 'https://cdn.loyalie.in/82775700.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.40',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-2xl lg:text-xl leading-6 lg:leading-5 text-primary ${
                getFont('AJMERA').body
            }`,
            listBodyClass: `${getFont('AJMERA').body}`,
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` text-2xl xl:text-3xl text-secondary  leading-7 xl:leading-9 font-normal ${
                getFont('AJMERA').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('AJMERA').medium
            }`,
            dateClassName: ` text-neutral capitalize font-medium  text-black ${
                getFont('AJMERA').body
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-sm  ${
                getFont('AJMERA').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-xs text-neutral break-words leading-1 ${
                getFont('AJMERA').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `rounded-lg border-[3px] border-primary text-secondary font-medium self-center text-[16px] py-2 px-7 py-2 2xl:py-2 px-12 md:px-8  ${
                    getFont('AJMERA').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `hover:shadow-xl rounded-lg border-[3px] border-primary text-secondary font-normal hover:font-medium self-center text-[16px] py-2 px-7 py-2 2xl:py-2 px-7 md:px-8 ${
                    getFont('AJMERA').medium
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0'
                },
                backgroundImage:
                    'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1700722737019_projects banner',
                backgroundImageMobile:
                    'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1700722737019_projects banner',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[87vh]  md:bg-top',

                components: []
            }
        ],
        moduleConfig: {
            constructionVisible: true,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },
    header: {
        headerClass: 'hidden lg:block h-32 bg-white'
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/1698910811488_Pattern', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0',
            repeat: 'repeat',
            size: 'contain'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.20'
                },
                backgroundImage: 'https://cdn.loyalie.in/38922635.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/38922635.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center lg:bg-top h-[90vh] lg:h-[97vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: `text-center text-[25px] xl:text-[35px] text-secondary uppercase ${
                            getFont('AJMERA').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[20%] xl:w-[20%] bg-primary-focus border-none h-[5px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],

    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('AJMERA').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('AJMERA').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.20'
                },
                backgroundImage:
                    'https://cdn.loyalie.in/1699007303645_TermsandConditions_Banner',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1699007303645_TermsandConditions_Banner',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center h-[90vh] lg:h-[97vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS & CONDITIONS',

                        className: `text-center text-[25px] xl:text-[35px] text-secondary uppercase ${
                            getFont('AJMERA').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[20%] xl:w-[20%] bg-primary-focus border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-10 lg:p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('AJMERA').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('AJMERA').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Ajmera  project, and has entered into a registered Agreement to Sell; or (ii) employee of Ajmera ; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Ajmera  or any of its sister concerns/subsidiaries/affiliates (“Ajmera  Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Ajmera  Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Ajmera .',
                            'Referrer shall not be able to refer existing customer(s) of Ajmera . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Ajmera  or (ii) is/are already registered with Ajmera  as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Ajmera ’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Ajmera  within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Ajmera ',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Ajmera  or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('AJMERA').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Ajmera Lifespaces, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('AJMERA').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('AJMERA').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('AJMERA').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('AJMERA').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('AJMERA').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('AJMERA').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('AJMERA').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        showMyProperty: false,
        appContainerClassName: 'bg-white text-secondary border-primary',
        appFontHeading: `${getFont('AJMERA').body}`,
        appStoreLink:
            'https://apps.apple.com/uz/app/ajmera-select/id6467878854?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.ajmera&utm_medium=email'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('AJMERA').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | maharera.mahaonline.gov.in | rera.karnataka.gov.in | https://gujrera.gujarat.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[10.5px] 3xl:text-[15px] leading-[18px] tracking-tight text-white ${
            getFont('AJMERA').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('AJMERA').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-90'
    },
    referralForm: {
        name: 'Ajmera',
        theme: 'ajmeraTheme',
        color: '#BB3231',
        // backgroundImage:
        //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/33213763.jpg',
        backgroundImage: 'https://cdn.loyalie.in/7023826.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '20',
            G1: '20',
            B1: '20',
            opacity1: '0',
            R2: '52',
            G2: '52',
            B2: '52',
            opacity2: '0'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own a',
            // bookedCustomerHeading2: 'Ajmera Property',

            employee: true,
            employeeHeading1: 'I am a',
            // employeeHeading1: 'Ajmera Employee',

            salesAdmin: true,
            salesAdminHeading1: 'I am an',
            // salesAdminHeading2: 'Ajmera Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        customConfig: {
            welcomeContent: '',
            headingColor: 'text-white',
            userBgColor: 'bg-transparent',
            borderColor: 'border-primary',
            animateUserType: true
        },
        pastReferralConfig: {
            selectedStatusColor: 'text-white',
            bgColor: 'bg-primary'
        },
        formContent: {
            heading: '"Build your own bespoke community"',
            headingClass: `self-center text-white sm:mt-0 text-3xl md:text-4xl xl:text-[48px] 2xl:text-[62px] w-[80%] lg:w-[50%] xl:w-[80%] 2xl:w-[90%] text-center tracking-wide leading-8 xl:leading-[50px] 2xl:leading-[60px] ${
                getFont('AJMERA').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%230c5391'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink: '/ajmera-home/terms-and-conditions',
        customLink: '/ajmera-home/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default AjmeraConfig;
