import React from 'react';
import { getConfig } from '../../../../utils/config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DealsLayout from '../DealsLayout/newIndex';
import { getCategoryIdByName } from '../../../../helpers';
import BsArrowRight from '@meronex/icons/bs/BsArrowRight';

function DealSection({ offers, type, className , hide }) {
    const config = getConfig();
    const history = useHistory();
    console.log('offers as prop', offers);

    const onClick = () => {
        switch (type) {
            case 'LIFESTYLE BENEFITS':
                history.push(
                    '/dealListing?categoryID=1&categoryName=Member Benefits'
                );
                return;
            case 'EXCLUSIVE MOVE IN OFFERS':
                history.push(
                    '/dealListing?categoryID=2&categoryName=Exclusive Move In Offers'
                );
                return;
            case 'HOME FINANCING OFFERS':
                history.push(
                    '/dealListing?categoryID=3&categoryName=Home Financing Deals'
                );
                return;
            case 'YOUR DIGITAL AMENITIES':
                history.push(
                    '/dealListing?categoryID=5&categoryName=Digital Amenities'
                );
                return
                case 'OFFERS OF THE MONTH':
                    offers.forEach(offer => {
                        window?.webengage?.track('OfferOfTheMonthClicked', {
                            dealId: offer.id,
                            category: offer.categoryName,
                            tag: 'Offers of the Month',
                        });
                    });
                    history.push(
                        '/dealListing?tagID=52&tagName=Offers of the Month'
                    );
                    return
                    case 'OTHER OFFERS FROM THIS BRAND':
                        history.push(
                            `/dealListing?brandID=${offers[0].vendorId}&brandName=${offers[0].vendorName}`
                        );
                return;
                case 'SIMILAR OFFERS':
                    history.push(
                        `/dealListing?categoryID=${getCategoryIdByName(offers[0].categoryName)}&categoryName=${offers[0].categoryName}`
                    );
            return;
            case 'OTHER DEALS IN THIS BUNDLE':
                history.push(
                    '/dealListing?categoryID=8&categoryName=Bundle'
                );
                return

        }
    };
    return (
        <div className={className}>
            <div className="flex justify-between w-full items-center">
                <h1
                    onClick={onClick}
                    className={`text-lg font-semibold uppercase text-primary hover:border-b-2 hover:border-primary hover:pb-2 cursor-pointer
                                       `}
                >
                    {type}
                </h1>
                {!hide && offers?.length >= 4 && (
                    <p
                        onClick={onClick}
                        className={`z-10 text-sm font-semibold capitalize text-primary font-sans cursor-pointer `}
                    >
                        <BsArrowRight className='w-6 h-6 text-primary'/>
                    </p>
                )}
            </div>
            <div
                id="DealsLayout"
                className="flex w-full gap-4 mt-4 overflow-x-scroll overflow-x-hidden"
            >
                {offers?.map((offer) => (
                    <DealsLayout {...offer} />
                ))}
            </div>
        </div>
    );
}

export default DealSection;
