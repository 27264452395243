import { createSlice } from '@reduxjs/toolkit';

const webViewSlice = createSlice({
    name: 'webView',
    initialState: {
        unauthorizedWebViewUser: false,
        clientWebview: false,
        internalWebview: false,
        userWebview: false,
        preLoginWebview: false,
        hiddenOnWebview: false
    },
    reducers: {
        activateClientWebview(state) {
            state.clientWebview = true;
            state.hiddenOnWebview = true;
        },
        activateUserWebview(state) {
            state.userWebview = true;
        },
        activateInternalWebview(state) {
            state.internalWebview = true;
            state.hiddenOnWebview = true;
        },
        activatePreloginWebview(state) {
            state.preLoginWebview = true;
            state.hiddenOnWebview = true;
        },
        loginWebViewUser(state) {
            state.unauthorizedWebViewUser = false;
        },
        unauthorizedWebviewUser(state) {
            state.unauthorizedWebViewUser = true;
        }
    }
});

export const webViewActions = webViewSlice.actions;
export default webViewSlice;
