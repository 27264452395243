import React from 'react';

function DealGalleryItem({ imageUrl, videoUrl, previewItem, setPreviewItem }) {
    return (
        <div className={`max-w-[70px] w-[70px] min-w-[70px] mt-0 mr-1 lg:mr-0 lg:mt-1 rounded ${previewItem == videoUrl || previewItem == imageUrl ? 'border-2 border-primary shadow-xl' : ''}`}>
            <img
                src={imageUrl}
                onClick={() => setPreviewItem(!!videoUrl ? videoUrl : imageUrl)}
                onMouseOver={() =>
                    setPreviewItem(!!videoUrl ? videoUrl : imageUrl)
                }
                className={`object-contain cursor-pointer rounded w-full `}
            />
        </div>
    );
}

export default DealGalleryItem;
