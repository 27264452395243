import React from 'react';
import ReactDOM from 'react-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import ScrollToTop from './Routes/ScrollToTop';
import store from './store/index';
import App from './App';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from './Components/Atoms';

function MyFallbackComponent({ error, resetErrorBoundary }) {
    return (
        <div
            role="alert"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                flexDirection: 'column',
                height: '100vh'
            }}
        >
            <p style={{ marginBottom: '4px' }}>Something went wrong</p>
            <p style={{ marginBottom: '4px' }}>
                Refresh this page to continue!
            </p>
        </div>
    );
}

ReactDOM.render(
    <ErrorBoundary
        FallbackComponent={MyFallbackComponent}
        onError={(error, info) => {
            console.log(error)
            const ENV = window.reloy_env.ENV || process.env.REACT_APP_ENV;

            window?.webengage?.track('WebsiteCrash', {
                errorName: error.name,
                errorMessage: error.message,
                info: info.digest,
                env: ENV
            });
        }}
    >
        <Provider store={store}>
            <BrowserRouter basename={window.reloy_env.PUBLIC_URL}>
                <ThemeProvider theme={theme}>
                    <ScrollToTop />
                    <App />
                </ThemeProvider>
            </BrowserRouter>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
);
