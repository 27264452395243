import React, { useState, Fragment, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
    getConfig,
    pathMapWithConfig,
    routePathMap
} from '../../../utils/config';
import NavItemDropdown from '../../Atoms/NavItemDropdown';
import { useDispatch, useSelector } from 'react-redux';
import setAuthToken from '../../../utils/authtoken';
import { authActions } from '../../../store/auth-slice';
import LoginModal from '../../LoginModal';
import { Transition } from '@headlessui/react';
import { getFormattedUserName } from '../../../helpers';
import { deleteWinnreAuthToken, winnreAuth } from '../../../utils/axios';
import { isDealsVisible } from '../../../utils/routing';

function DropDownNavBar({ navBarConfig }) {
    const [showNavBar, setShowNavBar] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [openMyProfile, setOpenMyProfile] = useState(false);

    const [openMobileDropdown, setOpenMobileDropdown] = useState(false);
    const dispatch = useDispatch();
    const { userName, userRole, isAuthenticated, isLoginModalVisible } =
        useSelector((state) => state.auth);

    const config = getConfig();

    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
    };

    const logout = async () => {
        try {
            await winnreAuth({
                method: 'POST',
                url: '/winnre/login/logout'
            });
        } catch {}
        // localStorage.removeItem('token');
        if (config?.autoLogout) {
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('accessToken');
        } else {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
        }

        // setAuthToken(false);
        window?.webengage?.track('LogOut');
        window?.webengage?.user?.logout();
        deleteWinnreAuthToken();
        dispatch(authActions.logout());
    };
    const setClickFalse = () => {
        setOpenMobileDropdown(false);
    };

    const transitionNavBar = () => {
        if (window.scrollY > 100) {
            setShowNavBar(true);
        } else {
            setShowNavBar(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', transitionNavBar);

        return () => {
            window.removeEventListener('scroll', transitionNavBar);
        };
    }, []);

    const routesList = window.reloy_env.client_config.NAVIGATION_ROUTES;
    const dropdownRoutes = window.reloy_env.client_config.NAVIGATION_DROPDOWN;

    return (
        <>
            {/* {isLoginModalVisible && <LoginModal />} */}
            {/* {showMMenu && <Overlay visible={showMMenu} />} */}
            <header
                class={`flex flex-col sticky top-0 z-30 mx-auto lg:items-center lg:justify-between lg:flex-row  ${
                    showNavBar
                        ? config.navBarConfig.stickyHeight
                            ? config.navBarConfig.stickyHeight
                            : config.navBarConfig.height || 'h-20 '
                        : config.navBarConfig.height || 'h-20 '
                } ${config.navBarConfig?.font}
            ${
                showNavBar
                    ? ` ${
                          config?.navBarConfig.stickyNavBar.backgroundColor ||
                          'bg-primary-content'
                      }  ${
                          config?.navBarConfig.stickyNavBar.border
                              ? `${config?.navBarConfig.stickyNavBar.borderClass}`
                              : ``
                      } `
                    : ` ${
                          config.navBarConfig.baseNavBar.backgroundColor ||
                          'bg-transparent'
                      } ${
                          config?.navBarConfig.baseNavBar.border
                              ? `${config?.navBarConfig.baseNavBar.borderClass}`
                              : ''
                      }
         `
            }`}
            >
                {/* LEFT LOGO */}
                {config?.logoLeft && (
                    <div class=" cursor-pointer flex flex-row items-center justify-between h-full">
                        <Link
                            className="h-full flex justify-center items-center"
                            to="/"
                        >
                            <img
                                src={
                                    showNavBar
                                        ? config?.logoLeft?.stickyUrl ||
                                          config?.logoLeft?.url
                                        : config?.logoLeft?.baseUrl ||
                                          config?.logoLeft?.url
                                }
                                className={
                                    '  object-contain ' +
                                    (showNavBar
                                        ? `${config.logoLeft?.stickyNavBarLogoClass}`
                                        : `${config.logoLeft?.baseNavBarLogoClass}`)
                                }
                            />
                        </Link>

                        <button
                            onClick={() =>
                                setOpenMobileDropdown((prev) => !prev)
                            }
                            class="lg:hidden rounded-lg focus:outline-none focus:shadow-outline p-4"
                        >
                            <svg
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                class={` w-6 h-6 ${config?.navBarConfig.mobileIconColor}`}
                            >
                                {!openMobileDropdown && (
                                    <path
                                        fillRule="evenodd"
                                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                                        clipRule="evenodd"
                                    ></path>
                                )}
                                {openMobileDropdown && (
                                    <path
                                        onClick={() => setOpenDropdown(false)}
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                )}
                            </svg>
                        </button>
                    </div>
                )}
                {/* NAV */}

                <div>
                    {config?.logoCenter && (
                        <div class="hidden lg:block cursor-pointer flex flex-row items-center justify-center h-full">
                            <Link
                                className="h-full flex justify-center items-center"
                                to="/"
                            >
                                <img
                                    src={
                                        showNavBar
                                            ? config?.logoCenter?.stickyUrl ||
                                              config?.logoCenter?.url
                                            : config?.logoCenter?.baseUrl ||
                                              config?.logoCenter?.url
                                    }
                                    className={
                                        '  object-contain ' +
                                        (showNavBar
                                            ? `${config.logoCenter?.stickyNavBarLogoClass}`
                                            : `${config.logoCenter?.baseNavBarLogoClass}`)
                                    }
                                />
                            </Link>
                        </div>
                    )}

                    <ul
                        className={` lg:flex lg:items-center  ${
                            showNavBar
                                ? `${config?.navBarConfig.stickyNavBar.containerClass}`
                                : `${config?.navBarConfig.baseNavBar.containerClass} `
                        }`}
                    >
                        <li
                            class={`${
                                openMobileDropdown
                                    ? 'flex-col flex animate-dropDownOpen '
                                    : 'hidden'
                            } bg-primary-content lg:bg-transparent lg:flex-grow py-4 md:py-0 lg:flex md:justify-center lg:items-center lg:flex-row md:mx-auto `}
                        >
                            {Object.keys(routesList)
                                .sort(
                                    (a, b) =>
                                        routesList[a].order -
                                        routesList[b].order
                                )
                                .map((page) => (
                                    <NavItemDropdown
                                        key={routesList[page].name}
                                        label={routesList[page].name}
                                        show={routesList[page]?.show}
                                        pathname={
                                            routesList[page].path ||
                                            routePathMap[page] ||
                                            '/'
                                        }
                                        scrollTo={routesList[page].scrollTo}
                                        customUrl={routesList[page].customUrl}
                                        showNavBar={showNavBar}
                                        onClick={setClickFalse}
                                        dropDown={routesList[page].dropDown}
                                        dropDownOptions={dropdownRoutes}
                                        openDropdown={openDropdown}
                                        setOpenDropdown={setOpenDropdown}
                                    />
                                ))}
                            {isAuthenticated && userRole === 'CUSTOMER' && (
                                <NavLink
                                    className={
                                        `px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                        (showNavBar
                                            ? `${
                                                  config?.navBarConfig
                                                      .stickyNavBar.itemClass ||
                                                  'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                              }`
                                            : ` ${
                                                  config.navBarConfig.baseNavBar
                                                      .itemClass ||
                                                  'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                              }`)
                                    }
                                    activeClassName={
                                        config.navBarConfig.showActiveLinkColor
                                            ? 'text-secondary-focus'
                                            : ''
                                    }
                                    to="/myprofile/info"
                                    onClick={setClickFalse}
                                >
                                    MY PROFILE
                                </NavLink>
                            )}
                            {isAuthenticated && userRole === 'CUSTOMER' && (
                                <NavLink
                                    className={
                                        `hidden lg:block px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                        (showNavBar
                                            ? `${
                                                  config?.navBarConfig
                                                      .stickyNavBar.itemClass ||
                                                  'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                              }`
                                            : ` ${
                                                  config.navBarConfig.baseNavBar
                                                      .itemClass ||
                                                  'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                              }`)
                                    }
                                    activeClassName={
                                        config.navBarConfig.showActiveLinkColor
                                            ? 'text-secondary-focus'
                                            : ''
                                    }
                                    to="/myprofile/docs"
                                    onClick={setClickFalse}
                                >
                                    DOCUMENTS
                                </NavLink>
                            )}
                            {isAuthenticated &&
                                userRole === 'CUSTOMER' &&
                                window.reloy_env.client_config.MY_PROFILE
                                    ?.SHOW_MY_PROPERTY && (
                                    <NavLink
                                        className={
                                            `hidden lg:block px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                            (showNavBar
                                                ? `${
                                                      config?.navBarConfig
                                                          .stickyNavBar
                                                          .itemClass ||
                                                      'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                                  }`
                                                : ` ${
                                                      config.navBarConfig
                                                          .baseNavBar
                                                          .itemClass ||
                                                      'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                                  }`)
                                        }
                                        activeClassName={
                                            config.navBarConfig
                                                .showActiveLinkColor
                                                ? 'text-secondary-focus'
                                                : ''
                                        }
                                        to="/myprofile/property"
                                        onClick={setClickFalse}
                                    >
                                        MY PROPERTY
                                    </NavLink>
                                )}
                            {isAuthenticated && userRole === 'CUSTOMER' && isDealsVisible && (
                                <NavLink
                                    className={
                                        `hidden lg:block px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                        (showNavBar
                                            ? `${
                                                  config?.navBarConfig
                                                      .stickyNavBar.itemClass ||
                                                  'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                              }`
                                            : ` ${
                                                  config.navBarConfig.baseNavBar
                                                      .itemClass ||
                                                  'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                              }`)
                                    }
                                    activeClassName={
                                        config.navBarConfig.showActiveLinkColor
                                            ? 'text-secondary-focus'
                                            : ''
                                    }
                                    to="/myprofile/enquiries"
                                    onClick={setClickFalse}
                                >
                                    ENQUIRIES
                                </NavLink>
                            )}
                                   {isAuthenticated && !config.projects.moduleConfig?.hideProjects && (
                                <NavLink
                                    className={
                                        `hidden lg:block px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                        (showNavBar
                                            ? `${
                                                  config?.navBarConfig
                                                      .stickyNavBar.itemClass ||
                                                  'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                              }`
                                            : ` ${
                                                  config.navBarConfig.baseNavBar
                                                      .itemClass ||
                                                  'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                              }`)
                                    }
                                    activeClassName={
                                        config.navBarConfig.showActiveLinkColor
                                            ? 'text-secondary-focus'
                                            : ''
                                    }
                                    to="/myprofile/site-visits"
                                    onClick={setClickFalse}
                                >
                                    MY VISITS
                                </NavLink>
                            )}
                     
                            {isAuthenticated && (
                                <button
                                    onClick={logout}
                                    className={
                                        ` text-left px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                        (showNavBar
                                            ? `${
                                                  config?.navBarConfig
                                                      .stickyNavBar.itemClass ||
                                                  'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                                              }`
                                            : ` ${
                                                  config.navBarConfig.baseNavBar
                                                      .itemClass ||
                                                  'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                              }`)
                                    }
                                    activeClassName={
                                        config.navBarConfig.showActiveLinkColor
                                            ? 'text-secondary-focus'
                                            : ''
                                    }
                                >
                                    Logout
                                </button>
                            )}
                            {!isAuthenticated && (
                                <button
                                    onClick={openLoginModal}
                                    className={`text-left px-6 w-full py-2 lg:p-0 lg:border-none lg:w-auto lg:mr-6  ${
                                        showNavBar
                                            ? ` ${config.navBarConfig.stickyNavBar.itemClass}`
                                            : ` ${config.navBarConfig.baseNavBar.itemClass}`
                                    }  `}
                                >
                                    Login
                                </button>
                            )}
                            {isAuthenticated && (
                                <div
                                    class="hidden lg:relative lg:inline-block "
                                    onMouseOver={() => setOpenMyProfile(true)}
                                    onMouseLeave={() => setOpenMyProfile(false)}
                                >
                                    <button
                                        className={
                                            'uppercase flex flex-row items-center px-6 py-2 2xl:px-2 2xl:py-2 lg:pb-2 lg:border-none lg:mr-7 lg:px-0 lg:inline-block transition ease-out duration-75 w-full md:w-auto md:inline ' +
                                            (showNavBar
                                                ? `${config?.navBarConfig.stickyNavBar.itemClass}`
                                                : ` ${config.navBarConfig.baseNavBar.itemClass}`)
                                        }
                                    >
                                        {getFormattedUserName(userName)}

                                        <svg
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            class={
                                                `${
                                                    openMyProfile
                                                        ? ' rotate-180'
                                                        : ' rotate-0'
                                                }` +
                                                ' inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1'
                                            }
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </button>

                                    {/* <Transition
                                        as={Fragment}
                                        show={openMyProfile}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    > */}
                                       {openMyProfile && <div
                                            class={`lg:absolute right-0 w-full lg:pt-2 origin-top-right lg:rounded-md lg:shadow-lg md:w-48 z-10 `}
                                        >
                                            <div
                                                class={`px-2 ${
                                                    userRole === 'CUSTOMER'
                                                        ? 'lg:py-4'
                                                        : ''
                                                } lg:rounded-md lg:shadow ${
                                                    config?.navBarConfig
                                                        .dropDownBg
                                                        ? config?.navBarConfig
                                                              .dropDownBg
                                                        : 'bg-white'
                                                }`}
                                            >
                                                {/* {userRole === 'CUSTOMER' && ( */}
                                                <NavLink
                                                    to="/myprofile/docs"
                                                    className={`block px-4 py-2 bg-transparent rounded-lg md:mt-0 ${
                                                        config?.navBarConfig
                                                            .baseNavBar
                                                            .dropDownItemClass
                                                    }  ${
                                                        config?.navBarConfig
                                                            .baseNavBar
                                                            .dropDownContainerClass
                                                            ? config
                                                                  ?.navBarConfig
                                                                  .baseNavBar
                                                                  .dropDownContainerClass
                                                            : 'hover:text-gray-900 hover:bg-gray-200'
                                                    } `}
                                                >
                                                    MY PROFILE
                                                </NavLink>
                                                {/* )} */}
                                                <a
                                                    onClick={logout}
                                                    className={`block px-4 py-2 lg:mt-2 bg-transparent rounded-lg md:mt-0 ${
                                                        config?.navBarConfig
                                                            .baseNavBar
                                                            .dropDownItemClass
                                                    }  ${
                                                        config?.navBarConfig
                                                            .baseNavBar
                                                            .dropDownContainerClass
                                                            ? config
                                                                  ?.navBarConfig
                                                                  .baseNavBar
                                                                  .dropDownContainerClass
                                                            : 'hover:text-gray-900 hover:bg-gray-200'
                                                    } `}
                                                >
                                                    LOGOUT
                                                </a>
                                            </div>
                                        </div>}
                                    {/* </Transition> */}
                                </div>
                            )}
                        </li>
                    </ul>
                </div>

                {/* RIGHT LOGO */}
                {config?.logoRight && (
                    <Link>
                        <div className=" cursor-pointer hidden lg:flex h-full items-center">
                            <img
                                src={config.logoRight.url}
                                className={
                                    '  object-contain ' +
                                    (showNavBar
                                        ? `${config.logoRight?.stickyNavBarLogoClass}`
                                        : `${config.logoRight?.baseNavBarLogoClass}`)
                                }
                            />
                        </div>
                    </Link>
                )}
            </header>
        </>
    );
}

export default DropDownNavBar;
