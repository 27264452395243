import React from 'react';
import { Overlay } from '../../Organisms/Modal';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import {
    getConfig,
    pathMapWithConfig,
    routePathMap
} from '../../../utils/config';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import WebviewNavItem from '../../Atoms/WebviewNavItem';
import { getFormattedUserName, getInitials } from '../../../helpers';
import BsFolderCheck from '@meronex/icons/bs/BsFolderCheck';
import MdcFormatListChecks from '@meronex/icons/mdc/MdcFormatListChecks';
import MdcHeadphones from '@meronex/icons/mdc/MdcHeadphones';
import MdClose from '@meronex/icons/md/MdClose';
import Contact from '../../Atoms/WebviewNavItem/Icons/Contact';
import Terms from '../../Atoms/WebviewNavItem/Icons/Terms';

export const WebviewModal = ({ className, visible, onClick, children }) => {
    return ReactDOM.createPortal(
        <>
            <Overlay visible={visible} onClick={onClick} />
            {/* left-1/2 -translate-x-1/2 bottom-10 */}
            {visible && (
                <div
                    className={` fixed   ${
                        className ? className : 'w-full'
                    }  mx-auto overflow-y-visible  z-40 bottom-0 "`}
                >
                    <div
                        className={` ${
                            className ? '' : ''
                        } bg-white shadow-sm rounded-t-xl`}
                    >
                        <div>{children}</div>
                    </div>
                </div>
            )}
        </>,
        document.getElementById('webviewModal')
    );
};

function WebviewMenu({ onClick }) {
    const config = getConfig();
    const navBarConfig =
        window.reloy_env.client_config.NAVIGATION_WEBVIEW ||
        window.reloy_env.client_config.NAVIGATION_ROUTES;

    const dropdownList = window.reloy_env.client_config.NAVIGATION_DROPDOWN;
    const [openDropdown, setOpenDropdown] = useState(false);

    const { userRole, isAuthenticated, userName } = useSelector(
        (state) => state.auth
    );

    return (
        <div className="bg-primary-content lg:bg-transparent">
            <div className="h-[80px] w-full flex justify-between px-3 py-2 items-center rounded-t-lg">
                <div className="flex">
                    <div
                        className={
                            `${
                                config?.webview?.initialsClass
                                    ? config?.webview?.initialsClass
                                    : 'border-blue-50 bg-gray-50 '
                            }` +
                            ' rounded-full h-11 w-11 border-2 flex justify-center'
                        }
                    >
                        <p className="self-center capitalize">
                            {getInitials(userName)}
                        </p>
                    </div>
                    <div
                        className={`flex flex-col pl-2 font-Poppins ${
                            config?.webview?.initialsClass
                                ? config?.webview?.initialsClass
                                : ''
                        }`}
                    >
                        <h3 className="leading-5 text-sm">Welcome</h3>
                        <h3 className="font-semibold leading-5">
                            {getFormattedUserName(userName)}
                        </h3>
                    </div>
                </div>
                <MdClose onClick={onClick} className="" />
            </div>

            <hr className="bg-gray-200 h-[2px] mx-3 mb-3" />
            <ul className={`${config.navBarConfig?.font}`}>
                <li
                    class={`bg-primary-content lg:bg-transparent lg:flex-grow lg:flex md:justify-center lg:items-center lg:flex-row md:mx-auto `}
                >
                    {Object.keys(navBarConfig)
                        .sort(
                            (a, b) =>
                                navBarConfig[a].order - navBarConfig[b].order
                        )
                        .map((page) => {
                            // if (
                            //     navBarConfig[page].name === 'PROJECTS' ||
                            //     navBarConfig[page].name === 'REFER NOW' ||
                            //     navBarConfig[page].name ===
                            //         'HOME & LIFESTYLE TRENDS' ||
                            //     navBarConfig[page].name === 'MEMBERSHIP BENEFITS' ||
                            //     navBarConfig[page].name ===
                            //         'ABOUT GODREJ PROPERTIES'
                            // ) {
                            //     return;
                            // }
                            return (
                                <>
                                    <WebviewNavItem
                                        key={navBarConfig[page].name}
                                        label={navBarConfig[page].name}
                                        show={navBarConfig[page].show}
                                        pathname={
                                            navBarConfig[page].path ||
                                            routePathMap[page] ||
                                            '/'
                                        }
                                        scrollTo={navBarConfig[page].scrollTo}
                                        customUrl={navBarConfig[page].customUrl}
                                        onClick={onClick}
                                        dropDown={navBarConfig[page].dropDown}
                                        dropDownOptions={dropdownList}
                                        openDropdown={openDropdown}
                                        setOpenDropdown={setOpenDropdown}
                                    />
                                </>
                            );
                        })}
                    {/* <NavLink
                        className={
                            `border-b-[1px] border-gray-300 px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                            ` ${
                                config.navBarConfig.baseNavBar.itemClass ||
                                'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                            }`
                        }
                        activeClassName={
                            config.navBarConfig.showActiveLinkColor
                                ? 'text-secondary-focus'
                                : ''
                        }
                        to="/"
                        onClick={onClick}
                    >
                        TRACK YOUR REFERRALS
                    </NavLink> */}
                    {isAuthenticated &&
                        userRole === 'CUSTOMER' &&
                        config?.webviewRoutes?.document?.show && (
                            <div className="flex items-center pl-3">
                                <BsFolderCheck
                                    className={`h-5 w-5 ${
                                        config?.webview?.iconColor
                                            ? config?.webview?.iconColor
                                            : ''
                                    }`}
                                />
                                <NavLink
                                    className={
                                        ` px-2 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                        ` ${
                                            config.navBarConfig.baseNavBar
                                                .itemClass ||
                                            'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                        }`
                                    }
                                    activeClassName={
                                        config.navBarConfig.showActiveLinkColor
                                            ? 'text-secondary-focus'
                                            : ''
                                    }
                                    to="/myprofile/docs"
                                    onClick={onClick}
                                >
                                    DOCUMENTS
                                </NavLink>
                            </div>
                        )}
                    <div className="flex items-center pl-3">
                        <Contact
                            className="h-5 w-5"
                            fill={config?.webview?.iconColor}
                            stroke={config?.webview?.iconColor}
                        />
                        <NavLink
                            className={
                                ` px-2 lg:px-6 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                ` ${
                                    config.navBarConfig.baseNavBar.itemClass ||
                                    'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                }`
                            }
                            activeClassName={
                                config.navBarConfig.showActiveLinkColor
                                    ? 'text-secondary-focus'
                                    : ''
                            }
                            to="/contact-us"
                            onClick={onClick}
                        >
                            CONTACT US
                        </NavLink>
                    </div>
                    <div className="flex items-center pl-3">
                        <Terms
                            className="h-5 w-5"
                            fill={config?.webview?.iconColor}
                            stroke={config?.webview?.iconColor}
                        />
                        <NavLink
                            className={
                                ` px-2 py-2 block lg:hidden transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                                ` ${
                                    config.navBarConfig.baseNavBar.itemClass ||
                                    'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                                }`
                            }
                            activeClassName={
                                config.navBarConfig.showActiveLinkColor
                                    ? 'text-secondary-focus'
                                    : ''
                            }
                            to="terms-and-conditions"
                            onClick={onClick}
                        >
                            TERMS AND CONDITIONS
                        </NavLink>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default WebviewMenu;
