import { useState, useCallback, useEffect } from 'react';
import { winnreNoAuth } from '../../utils/axios';
import qs from 'qs';
import { authActions } from '../../store/auth-slice';
import { useDispatch } from 'react-redux';
import { usePhoneInput, useInput } from '../../Hooks';
import { getConfig } from '../../utils/config';
import { useHistory, useLocation } from 'react-router';
import { checkRole } from '../../helpers';
import { RE_EMAIL } from '../../constants';
import { useSelector } from 'react-redux';

let interval = null;

export const useData = (selectedOption) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const postLoginUrl = queryParams.get('postLoginUrl');
    const history = useHistory();
    const config = getConfig();
    const [createRegistration, setCreateRegistration] = useState(false);
    const dealRedeemUser = useSelector((state) => state.auth.dealRedeemUser);

    const {
        state: {
            isValid: mobileNumberIsValid,
            mobile,
            country,
            mobileHasError,
            isInputFocused,
            isInternationalCustomer
        },
        onChange,
        validator,
        setIsTouched,
        setMobile,
        getMobileWithoutCountryCode,
        setInputFocused,
        setIsInternationalCustomer
    } = usePhoneInput();

    const {
        value: OTP,
        isValid: OTPIsValid,
        hasError: OTPHasError,
        valueChangeHandler: OTPChangedHandler,
        inputBlurHandler: OTPBlurHandler,
        reset: resetOTP
    } = useInput((value) => value !== '');
    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail
    } = useInput((value) => RE_EMAIL.test(value));

    const dispatch = useDispatch();

    const [token, setToken] = useState(null);
    const [sendOtpLoading, setSendOtpLoading] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [timeLeftToResend, setTimeLeftToResend] = useState(0);
    const [isOTPGenerated, setIsOtpGenerated] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [loginWithEmail, setLoginWithEmail] = useState(
        !!window.reloy_env.client_config?.LOGIN?.LOGIN_WITH_EMAIL
    );

    const startTimer = useCallback(() => {
        interval = setInterval(() => {
            if (timeLeftToResend >= 1) {
                setTimeLeftToResend((prevState) => prevState - 1);
            } else if (timeLeftToResend < 1) {
                setTimeLeftToResend(0);
            }
        }, 1000);
    }, [timeLeftToResend]);

    useEffect(() => {
        if (timeLeftToResend < 1 && interval) {
            clearInterval(interval);
        }
        if (timeLeftToResend === 59) {
            if (interval) {
                clearInterval(interval);
            }
            startTimer();
        }
    }, [timeLeftToResend]);

    useEffect(() => {
        if (interval && !isOtpSent) {
            clearInterval(interval);
        }
    }, [isOtpSent]);

    const headers2 = {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        Authorization: `Bearer ${token}`
    };

    const authenticateHandler = (e) => {
        e.preventDefault();

        const data = {
            password: OTP
        };

        setLoginLoading(true);
        winnreNoAuth
            .post('/winnre/login/authenticate', qs.stringify(data), {
                headers: headers2
            })
            .then((res) => {
                // console.log('USER', res);
                setLoginLoading(false);
                console.log('LOGIN RES', res);
                if (res.status === 'FAILED') {
                    setSendOtpLoading(false);
                    setShowErrorAlert(true);
                    setAlertMessage(res.message);
                    // setLoginLoading(false);
                    return;
                }
                resetOTP();
                // setMobile('+91');
                resetEmail();
                setIsTouched(false);
                window?.webengage?.user?.login(res?.user?.hashId);
                window?.webengage?.user?.setAttribute(
                    'we_email',
                    res?.user?.email
                );
                window?.webengage?.user?.setAttribute(
                    'we_phone',
                    res?.user?.mobileNumber
                );
                window?.webengage?.user?.setAttribute(
                    'we_first_name',
                    res.user?.name.split(' ')[0]
                );
                window?.webengage?.user?.setAttribute(
                    'we_last_name',
                    res.user?.name.split(' ').pop()
                );
                window?.webengage?.track('LoggedIn', {
                    ProfileType: `${checkRole(res?.user?.eligibleRoles)}`
                });
                if (
                    res.user.status === 'REGISTERED' ||
                    res.user.status === 'PENDING_APPROVAL' ||
                    dealRedeemUser
                ) {
                    if (config?.autoLogout) {
                        sessionStorage.setItem(
                            'refreshToken',
                            res.refreshToken
                        );
                        sessionStorage.setItem('accessToken', res.accessToken);
                    } else {
                        localStorage.setItem('refreshToken', res.refreshToken);
                        localStorage.setItem('accessToken', res.accessToken);
                    }
                    dispatch(authActions.authenticationToken(res.accessToken));
                    // setAuthToken(res.accessToken);
                    dispatch(
                        authActions.setUserData({
                            userDetails: {
                                mobileCountryCode: res?.user?.mobileCountryCode,
                                mobileNumber: res?.user?.mobileNumber,
                                email: res?.user?.email,
                                status: res?.user?.status,
                                preferredName: res?.user?.preferredName,
                                birthDate: res?.user?.dateOfBirth,
                                address: res?.address
                            },
                            name: res.user.name,
                            userRole: checkRole(res?.user?.eligibleRoles),
                            userId: res?.user?.id,
                            hashId: res?.user.hashId,
                            profileCompletionPercentage:
                                res?.user?.profileCompletionPercentage,
                            finishedFirstTimeLoginGuide:
                                res?.user?.finishedFirstTimeLoginGuide
                        })
                    );

                    dispatch(authActions.login());
                    dispatch(authActions.closeLoginModal());
                    if (postLoginUrl) {
                        history.push(postLoginUrl);
                    }
                } else if (
                    res?.user?.status === 'NEW_USER' &&
                    selectedOption === 'Employee'
                ) {
                    if (config?.autoLogout) {
                        sessionStorage.setItem(
                            'refreshToken',
                            res.refreshToken
                        );
                        sessionStorage.setItem('accessToken', res.accessToken);
                    } else {
                        localStorage.setItem('refreshToken', res.refreshToken);
                        localStorage.setItem('accessToken', res.accessToken);
                    }
                    dispatch(authActions.authenticationToken(res.accessToken));
                    // setAuthToken(res.accessToken);
                    dispatch(
                        authActions.setUserData({
                            userDetails: {
                                mobileCountryCode: res.user.mobileCountryCode,
                                mobileNumber: res.user.mobileNumber,
                                email: res.user.email,
                                status: res?.user?.status,
                                preferredName: res?.user?.preferredName,
                                birthDate: res?.user?.dateOfBirth,
                                address: res?.address
                            },
                            name: res.user.name,
                            userRole: checkRole(res?.user?.eligibleRoles),
                            userId: res?.user?.id,
                            hashId: res?.user.hashId,
                            profileCompletionPercentage:
                                res?.user?.profileCompletionPercentage,
                            finishedFirstTimeLoginGuide:
                                res?.user?.finishedFirstTimeLoginGuide
                        })
                    );
                    dispatch(authActions.login());
                    dispatch(authActions.closeLoginModal());
                    if (postLoginUrl) {
                        history.push(postLoginUrl);
                    }
                } else if (
                    res?.user?.status === 'NEW_USER' &&
                    selectedOption === 'Customer'
                ) {
                    resetOTP();
                    // setMobile('+91');
                    resetEmail();
                    setIsTouched(false);
                    // setMobile(null);
                    // setAuthToken(res.accessToken);
                    dispatch(authActions.authenticationToken(res.accessToken));
                    setCreateRegistration(true);
                } else if (
                    res?.user?.status === 'NEW_USER' &&
                    selectedOption === 'Site Visitor'
                ) {
                    resetOTP();
                    resetEmail();
                    setIsTouched(false);
                    dispatch(authActions.authenticationToken(res.accessToken));
                    setCreateRegistration(true);
                }
            })
            .catch(() => {
                setLoginLoading(false);
                setShowErrorAlert(true);
                setAlertMessage('Something went wrong. Please try again!');
            });
    };

    const getOTP = (e) => {
        setTimeLeftToResend(59);
        if (e) {
            e.preventDefault();
        }
        let data;
        data = {
            userRole: dealRedeemUser ? null : selectedOption, // Set userRole null if deal redeem user is true
            ...((selectedOption === 'Employee' ||
                isInternationalCustomer ||
                loginWithEmail) && { email: email }),
            appType: 'CONNECTRE',
            countryCode: Number(country.dialCode),
            mobile: getMobileWithoutCountryCode()
        };
        let url;
        if (selectedOption === 'Employee' || loginWithEmail) {
            url = '/winnre/login/email?sendOtp=true';
        } else {
            url = 'winnre/login/phone';
        }
        setSendOtpLoading(true);

        winnreNoAuth
            .post(url, qs.stringify(data))
            .then((res) => {
                if (res.status === 'FAILED') {
                    setSendOtpLoading(false);
                    setAlertMessage(res.message);
                    setShowErrorAlert(true);
                    return;
                }
                if (!res.accessToken) {
                    setSendOtpLoading(false);
                    setShowErrorAlert(true);
                    setAlertMessage(
                        'Something went wrong. Try sending OTP again'
                    );
                    return;
                }
                if (res.accessToken) {
                    setToken(res.accessToken);
                }
                setSendOtpLoading(false);
                setIsOtpSent(true);
                setShowSuccessAlert(true);
                setAlertMessage(
                    `The verification code has been sent to the ${
                        isInternationalCustomer ||
                        selectedOption === 'Employee' ||
                        loginWithEmail
                            ? 'email'
                            : 'mobile number'
                    } entered`
                );
            })
            .catch((err) => {
                // console.log(err);
                setSendOtpLoading(false);
                setShowErrorAlert(true);
                setAlertMessage('Something went wrong. Please try again!');
            });
    };

    return {
        state: {
            mobileNumberIsValid,
            mobile,
            country,
            mobileHasError,
            isInputFocused,
            isOtpSent,
            sendOtpLoading,
            OTP,
            OTPHasError,
            OTPIsValid,
            isOTPGenerated,
            loginLoading,
            email,
            emailIsValid,
            emailHasError,
            alertMessage,
            showSuccessAlert,
            showErrorAlert,
            isInternationalCustomer,
            createRegistration,
            timeLeftToResend,
            loginWithEmail
        },
        onChange,
        validator,
        setIsTouched,
        resetEmail,
        getMobileWithoutCountryCode,
        setInputFocused,
        getOTP,
        OTPChangedHandler,
        OTPBlurHandler,
        emailChangedHandler,
        emailBlurHandler,
        authenticateHandler,
        setShowSuccessAlert,
        setShowErrorAlert,
        setAlertMessage,
        setCreateRegistration,
        setMobile,
        setIsInternationalCustomer,
        setIsOtpSent,
        setLoginWithEmail
    };
};
