import React, { useState, useEffect } from 'react';
import {
    deleteWinnreAuthToken,
    winnreAuth,
    winnreNoAuth
} from '../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import setAuthToken from '../utils/authtoken';
import { authActions } from '../store/auth-slice';
import { useLocation } from 'react-router-dom';
import { getConfig } from '../utils/config';
import { checkRole } from '../helpers';

const useRefreshToken = ({ callOnInit }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const webViewAccessToken = queryParams.get('token');
    const clientToken = queryParams.get('clientToken');
    const config = getConfig();
    const { showSessionTimeout } = useSelector((state) => state.auth);

    const isWebView =
        queryParams.get('isWebView') || sessionStorage.isInternalWebView;
    const refreshTokenHandler = (
        noLoading,
        accessToken,
        refreshToken,
        forceRefresh
    ) => {
        // console.log(accessToken, refreshToken);
        // console.log('refresh called');
        if (showSessionTimeout && !forceRefresh) {
            return;
        }
        const tokens = {
            accessToken: accessToken
                ? accessToken
                : config.autoLogout
                ? sessionStorage.accessToken
                : localStorage.accessToken,
            refreshToken: refreshToken
                ? refreshToken
                : config.autoLogout
                ? sessionStorage.refreshToken
                : localStorage.refreshToken
        };
        // if (webViewAccessToken) {
        //     return;
        // }
        // if (clientToken) {
        //     return;
        // }
        if (!tokens.accessToken || !tokens.refreshToken) {
            if (forceRefresh) {
                window?.webengage?.user?.logout();
                deleteWinnreAuthToken();
                dispatch(authActions.logout());
                dispatch(authActions.openLoginModal());
            }
            return;
        }
        setLoading(!noLoading);
        // code for headers or we can call setAuthtoken globally
        // console.log('API');
        winnreNoAuth({
            method: 'get',
            url: `/winnre/login/refreshToken${
                isWebView ? '?deviceType=WEBVIEW' : ''
            }`,
            headers: { Authorization: `Bearer ${tokens.refreshToken}` }
        })
            .then((res) => {
                // console.log('refresh', res);
                dispatch(authActions.authenticationToken(res.accessToken));
                dispatch(
                    authActions.setUserData({
                        userDetails: {
                            mobileCountryCode: res.user.mobileCountryCode,
                            mobileNumber: res.user.mobileNumber,
                            email: res.user.email,
                            status: res?.user?.status,
                            preferredName: res?.user?.preferredName,
                            birthDate: res?.user?.dateOfBirth,
                            address: res?.user?.address
                        },
                        name: res.user.name,
                        userRole: checkRole(res?.user?.eligibleRoles),
                        userId: res?.user?.id,
                        hashId: res?.user.hashId,
                        profileCompletionPercentage:
                            res?.user?.profileCompletionPercentage,
                        finishedFirstTimeLoginGuide:
                            res?.user?.finishedFirstTimeLoginGuide
                    })
                );
                dispatch(authActions.login());
                if (config.autoLogout) {
                    sessionStorage.setItem('refreshToken', res.refreshToken);
                    sessionStorage.setItem('accessToken', res.accessToken);
                } else {
                    localStorage.setItem('refreshToken', res.refreshToken);
                    localStorage.setItem('accessToken', res.accessToken);
                }

                setLoading(false);
            })
            .catch((e) => {
                // console.log('error in refresh api', e);
                // ideally sab auth a 1 or 2 hooks to have same code across the board
                window.href = '/';
                setLoading(false);
                // logouut ke code mai it was token
                // localStorage.removeItem('token');
                if (config?.autoLogout) {
                    sessionStorage.removeItem('refreshToken');
                    sessionStorage.removeItem('accessToken');
                } else {
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('accessToken');
                }
                // haven't seen you setting this up anywhere
                // setAuthToken(false);
                window?.webengage?.track('LogOut');
                window?.webengage?.user?.logout();
                deleteWinnreAuthToken();
                dispatch(authActions.logout());
            });
    };

    useEffect(() => {
        if (callOnInit) {
            if (webViewAccessToken) {
                return;
            }
            refreshTokenHandler();
        }
    }, []);
    return {
        loading,
        refreshTokenHandler
    };
};

export default useRefreshToken;
