import React from 'react';

function Circle({ children, className, bgColor, height, width }) {
    return (
        <div
            className={`rounded-full flex items-center justify-center ${
                className ? className : bgColor + ' ' + height + ' ' + width
            }`}
        >{children}</div>
    );
}

export default Circle;
