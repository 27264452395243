import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
const domain = window.location.origin;

const BirlaConfig = {
    clientId:
        '707fc887817bec30baa040d88710a6989a8ae9f9ed9c115bedcdd710f0ec7d56',
    id: 23,
    name: 'Birla Estates',
    fullName: 'Birla Estates',
    programName:'Birla Estates',
    theme: 'birlaTheme',
    favicon: 'https://cdn.loyalie.in/1706593220566_favicon_birla',
    primaryColor: '#9f1b3c',
    fontBody: `${getFont('BIRLA').medium}`,
    fontTitle: `${getFont('BIRLA').heading}`,
    googleConfig: {
        gID: 'G-5FVZDFTWSS',
        keywordsContent:
            'Birla Estates real estate, Birla Estates real estate referral programs, Birla Estates real estate referral program, Birla Estates real estate refer and earn, Birla Estates real estate project refer, Birla Estates real estate, Birla Estates real estate loyalty program, All New Projects by Birla Estates real estate Builders & Developers, Ongoing Projects by Birla Estates real estate limited, Birla Estates real estate new program, Birla Estates annual report, Birla Estates upcoming projects, Birla Estates news, Birla Estates review, Birla Estates projects, Birla Estates projects near kolkata, Birla Estates projects near barasat, Birla Estates real estate news, Birla Estates launch Birla Estates, Birla Estates real estate launch, Birla Estates real estate program, Birla Estates real estate projects, Birla Estates real estate blogs, Birla Estates real estate offers, Birla Estates real estate events, Birla Estates, Birla Estates, Birla Estates real estate best projects, Birla Estates real estate real estate developers,',
        descriptionContent:
            'Birla Estates is an exclusive loyalty and referral program, curated especially for the homeowners of Birla Estates. With this initiative, we wish to enrich your living experiences through our',
        authorContent: 'Birla Estates'
    },
    facebookPixelConfig: {
        id: '1150908949245885', //PROD
        metaName: '',
        metaContent: ''
    },
    webengageLic: 'in~~2024c1d7',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5'
    },
    activateReferPage: false,
    logoLeft: {
        name: 'Birla EstatesLogo',
        url: 'https://cdn.loyalie.in/1706592631475_birla_logo',
        stickyNavBarLogoClass:
            ' h-14 md:h-16 lg:h-14 xl:h-16 pl-5 xl:pl-12 2xl:pl-20 ease-in duration-700',
        baseNavBarLogoClass:
            ' h-16 md:h-20 lg:h-14 xl:h-20 pl-5 xl:pl-12 2xl:pl-20 ease-out duration-500'
    },

    logoRight: {
        name: 'Birla EstatesLogo',
        url: 'https://cdn.loyalie.in/1706593356889_Aditya_birla_logo',
        stickyNavBarLogoClass:
            ' h-14 md:h-24 lg:h-14 xl:h-16 pr-5 xl:pr-8 xl:pr-16 ease-in duration-700',
        baseNavBarLogoClass:
            ' h-14 md:h-24 lg:h-14 xl:h-20 pr-5 xl:pr-8 xl:pr-16 ease-out duration-500'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('BIRLA').heading}`,
        height: 'h-20 md:h-24 2xl:h-24 ease-out duration-500',
        stickyHeight: 'h-16 md:h-20 2xl:h-20 ease-in duration-500',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-white ',
        showActiveLinkColor: true,
        footerNavItemClass: `tracking-wide uppercase text-white text-[14px] lg:text-white font-light lg:text-[12px] xl:text-[15px] 3xl:text-lg  ${
            getFont('BIRLA').heading
        }`,
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-primary-content drop-shadow-md ', // default: transparent
            containerClass: ' ',
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-secondary-focus text-[14px] lg:text-secondary-focus hover:text-primary font-light lg:text-[12px] xl:text-[15px] 3xl:text-lg ',
            dropDownContainerClass: `hover:text-primary focus:text-primary  lg:focus:shadow-outline `,
            dropDownItemClass:
                'tracking-widest text-secondary-focus hover:text-primary text-[14px] lg:text-[12px] xl:text-[15px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-primary-content drop-shadow-md', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-secondary-focus lg:text-white text-[14px] lg:text-secondary-focus hover:text-primary font-medium lg:text-[12px] xl:text-[15px] 3xl:text-lg ' // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 ${
        getFont('BIRLA').bold
    }`,

    loginModal: {
        showEmployeeLogin: true,
        showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage:
                        'https://cdn.loyalie.in/1706593517537_homebanner',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1706777074224_sm_homebanner',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    bannerContainerClass:
                        'h-[80vh] md:h-[90vh] lg:h-[85vh] 2xl:h-[90vh] bg-center lg:bg-top ',
                    bannerItemContainerClass:
                        'items-start justify-end lg:justify-center px-8 pb-5 md:pb-10 lg:pb-0 md:pl-10 2xl:pl-20',
                    position: 'text-center',

                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'w-full text-center lg:text-left mt-5 lg:mt-0 leading-6 md:leading-9 lg:leading-none w-full lg:w-[45%] xl:w-[40%] 2xl:w-[45%] text-white',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'Indulge in the precious moments ',
                                    className: ` text-[18px] md:text-[30px] lg:text-[25px]  2xl:text-[30px] leading-[1.2] ${
                                        getFont('BIRLA').thin
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'in life with the ones you love the most.',
                                    className: ` text-[18px] md:text-[30px] lg:text-[25px]  2xl:text-[30px] ${
                                        getFont('BIRLA').thin
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'my-2 md:my-3 lg:my-5 mx-auto lg:mx-0 w-[50px] bg-primary-focus border-none h-[4px]',
                                    classNameContainer: ''
                                },
                                {
                                    type: 'TEXT',
                                    content: 'INTRODUCING',
                                    className: ` text-[16px] md:text-[20px] lg:text-[18px] xl:text-[20px]  2xl:text-[22px] tracking-widest ${
                                        getFont('BIRLA').thin
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: 'BIRLA ESTATES REFERRAL PROGRAM',
                                    className: ` text-primary-focus my-1 md:my-3 xl:my-4 text-[18px] leading-[1.1] md:text-[35px] lg:text-[28px] xl:text-[30px] 2xl:text-[40px] w-full lg:w-[70%] ${
                                        getFont('BIRLA').secondary
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'A Loyalty & Referral Program for the homeowners of Birla Estates.',
                                    className: `text-center lg:text-left font-medium text-[16px] md:text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] leading-[1.1] md:leading-[1.2] lg:w-[60%] ${
                                        getFont('BIRLA').thin
                                    }`
                                },
                                {
                                    type: 'BUTTON',
                                    content: 'REFER NOW',
                                    className: `md:mx-0 text-left mt-3 xl:mt-6  text-[16px] md:text-[22px] tracking-wider bg-primary-focus text-primary font-normal rounded-[5px] leading-[1] px-5 md:px-6 pt-3 md:pt-4 2xl:pt-5 pb-2 md:pb-3 ${
                                        getFont('BIRLA').medium
                                    }`,
                                    path: 'gift-and-earn',
                                    onClick: 'CHANGE_PATH_AND_SCROLL',
                                    scrollTo: 'referral_section'
                                }
                            ]
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: 'overview',
                    // url: 'https://cdn.loyalie.in/43800302.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.89',
                        size: '70%'
                    },
                    bannerItemContainerClass: 'bg-cover',
                    className:
                        'flex flex-col justify-center text-center py-10 relative ',
                    components: [
                        {
                            type: 'CUSTOM',
                            className: 'w-[85%] mx-auto ',
                            components: [
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'my-5 mx-auto lg:mx-0 w-[10px] md:w-[15px] bg-primary border-none h-16 2xl:h-20 absolute top-6  left-0'
                                },
                                {
                                    type: 'TEXT',
                                    content: 'ABOUT US',
                                    className: ` text-secondary mt-4 text-[25px] md:text-[30px]  lg:text-[30px] xl:text-[35px] 2xl:text-[40px]  text-left  ${
                                        getFont('BIRLA').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'Welcome to Birla Estates, where the legacy of the Birla Group converges with a commitment to redefine modern living. Drawing inspiration from decades of trust and integrity, we envision and create spaces that embody luxury, sustainability, and community. With a focus on innovative design, our projects reflect a perfect blend of form and function, while our deep commitment to sustainability ensures eco-friendly practices and green spaces.',
                                    className: `pt-5 md:pt-1 text-[18px] md:text-[22px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]  text-secondary-focus font-medium leading-[1.2] break-words mb-5 text-left  ${
                                        getFont('BIRLA').body
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'Beyond structures, we foster vibrant communities, believing in building not just for today but for a sustainable and thriving future. At Birla Estates, transparency, ethical practices, and a customer-first approach define our trusted partnerships, inviting you to explore a new standard of living where every square foot is a canvas of innovation.',
                                    className: `pt-1 text-[18px] md:text-[22px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] text-secondary-focus font-medium leading-[1.2] break-words mb-5 text-left ${
                                        getFont('BIRLA').body
                                    }`
                                }
                            ]
                        },

                        {
                            type: 'TEXT',
                            content:
                                'BIRLA ESTATES REFERRAL PROGRAM IS AN EXCLUSIVE LOYALTY PROGRAM FOR HOMEOWNERS AT BIRLA PROPERTIES.',
                            className: `${
                                getFont('BIRLA').heading
                            } text-primary self-center text-center w-[90%] md:w-[80%] lg:w-[67%] xl:w-[65%] 2xl:w-[63%] text-[18px] md:text-[28px] lg:text-[25px] xl:text-[30px] 2xl:text-[35px] leading-[1.1]  2xl:leading-[1.2] pt-6 xl:pt-8 2xl:pt-10 mb-5`
                        },
                        {
                            type: 'TEXT',
                            content: `The program curates a lifestyle that goes beyond homeowning and enriches every facet of the member's life.`,
                            className: `text-[18px] md:text-[22px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]  text-secondary-focus leading-[1.2] md:leading-[1.3] w-[90%] md:w-[80%] lg:w-[80%] 2xl:w-[63%] break-words font-medium text-center self-center ${
                                getFont('BIRLA').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: `As a part of the program, you can:`,
                            className: ` text-[18px] md:text-[22px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]  text-secondary-focus leading-[1.3] w-[90%] md:w-[80%] lg:w-[70%] 2xl:w-[63%] break-words mb-3 text-center self-center ${
                                getFont('BIRLA').body
                            }`
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/1706608733320_Banner_1',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0',
                        repeat: 'no-repeat',
                        size: 'cover',
                        position: 'top right'
                    },
                    className:
                        'flex items-center justify-start text-left h-[25vh] md:h-[50vh] lg:h-[70vh] 2xl:h-[85vh] px-5 md:px-10 lg:px-20 2xl:px-36',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'w-[70%] md:w-[50%] lg:w-[45%] xl:w-[40%] 2xl:w-[40%] ',
                            components: [
                                {
                                    type: 'TEXT',
                                    content:
                                        'GET 1% OF AGREEMENT VALUE ON EVERY SUCCESSFUL REFERRAL',
                                    className: `text-white text-[18px] md:text-[28px] lg:text-[25px] xl:text-[30px] leading-[1.1] md:leading-[1.2] ${
                                        getFont('BIRLA').body
                                    }`
                                },
                                {
                                    type: 'BUTTON',
                                    content: 'REFER NOW',
                                    className: `md:mx-0 text-left mt-3 md:mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary-focus text-primary font-normal rounded-[5px] leading-[1] px-5 md:px-6 pt-3 md:pt-4 2xl:pt-5 pb-2 md:pb-3 ${
                                        getFont('BIRLA').medium
                                    }`,
                                    // embeddedReferSection: true,
                                    // scrollTo: 'referral_section',
                                    // embeddedPath:'/',
                                    path: 'gift-and-earn',
                                    onClick: 'CHANGE_PATH_AND_SCROLL',
                                    scrollTo: 'referral_section'
                                }
                            ]
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/1706608748685_Banner_2',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0',
                        repeat: 'no-repeat',
                        size: 'cover',
                        position: 'top right'
                    },
                    className:
                        'flex items-center justify-end text-left h-[25vh] md:h-[50vh] lg:h-[70vh] 2xl:h-[85vh] px-5 md:px-10 lg:px-20 2xl:px-36',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'w-[50%] md:w-[55%] lg:w-[39%] xl:w-[37%] 2xl:w-[35%] ',
                            components: [
                                {
                                    type: 'TEXT',
                                    content:
                                        'ENJOY MEMBERSHIP BENEFITS FROM TOP BRANDS',
                                    // onClick: 'CHANGE_PATH',
                                    // path: 'deals',
                                    className: `text-white text-[18px] md:text-[28px] lg:text-[25px] xl:text-[30px] leading-[1.1] md:leading-[1.2] ${
                                        getFont('BIRLA').body
                                    }`
                                },
                                {
                                    type: 'BUTTON',
                                    content: 'AVAIL NOW',
                                    className: `md:mx-0 text-left mt-3 md:mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary-focus text-primary font-normal rounded-[5px] leading-[1] px-5 md:px-6 pt-3 md:pt-4 2xl:pt-5 pb-2 md:pb-3 ${
                                        getFont('BIRLA').medium
                                    }`,

                                    path: 'deals',
                                    onClick: 'CHANGE_PATH'
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/1706608759492_Banner_3',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0',
                        repeat: 'no-repeat',
                        size: 'cover',
                        position: 'top right'
                    },
                    className:
                        'flex items-center justify-start text-left h-[25vh] md:h-[50vh] lg:h-[70vh] 2xl:h-[85vh] px-5 md:px-10 lg:px-20 2xl:px-36',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'w-[70%] md:w-[50%] lg:w-[35%] xl:w-[35%] 2xl:w-[30%] ',
                            components: [
                                {
                                    type: 'TEXT',
                                    content:
                                        'EXPLORE OUR HOME AND LIFESTYLE GUIDE',
                                    // onClick: 'CHANGE_PATH',
                                    // path: 'deals',
                                    className: `text-white text-[18px] md:text-[28px] lg:text-[25px] xl:text-[30px] w-[80%] leading-[1.1] md:leading-[1.2] ${
                                        getFont('BIRLA').body
                                    }`
                                },
                                {
                                    type: 'BUTTON',
                                    content: 'READ NOW',
                                    className: `md:mx-0 text-left mt-3 md:mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary-focus text-primary font-normal rounded-[5px] leading-[1] px-5 md:px-6 pt-3 md:pt-4 2xl:pt-5 pb-2 md:pb-3 ${
                                        getFont('BIRLA').medium
                                    }`,
                                    path: 'blogs',
                                    onClick: 'CHANGE_PATH'
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'overview',
                    // url: 'https://cdn.loyalie.in/43800302.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.89',
                        size: '70%'
                    },
                    bannerItemContainerClass: 'bg-cover',
                    className:
                        'flex flex-col justify-center text-center py-10 relative ',
                    components: [
                        {
                            type: 'CUSTOM',
                            className: 'w-[75%] md:w-[85%] mx-auto ',
                            components: [
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'my-5 mx-auto lg:mx-0 w-[10px] md:w-[15px] bg-primary border-none h-16 2xl:h-20 absolute top-8  left-0'
                                },
                                {
                                    type: 'TEXT',
                                    content: 'MEMBERSHIP BENEFITS',
                                    className: ` text-secondary mt-7 text-[25px] md:text-[30px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px]  text-left  ${
                                        getFont('BIRLA').heading
                                    }`
                                }
                            ]
                        },
                        {
                            type: 'CAROUSEL',
                            getVendorLogos: true,
                            offerCards: true,
                            getVendorData: true,
                            backgroundImageNone: true,
                            classNameCTA: `text-[14px] px-4 pt-2 pb-1 text-primary text-center mt-3 mx-auto uppercase  bg-accent rounded-[5px]  ${
                                getFont('BIRLA').medium
                            }`,
                            border: 'border-[2px] border-primary rounded-[10px] p-4  ',
                            ContainerClassName: 'bg-cover bg-[#f4f4f4] h-auto ',
                            caraouselClassName: ` w-[85%] xl:w-[85%] 2xl:w-[88%] 3xl:w-[80%] mx-auto md:px-0  pt-10 relative pb-10 ${
                                getFont('BIRLA').body
                            }`,

                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 5,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow
                        }
                    ]
                },

                {
                    type: 'CUSTOM',

                    linearGradient: {
                        R: '232',
                        G: '232',
                        B: '232',
                        opacity: '1',
                        size: '70%'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-10 ',
                    components: [
                        {
                            type: 'CUSTOM',
                            className: 'lg:flex items-center',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className: 'lg:w-[25%] flex',
                                    components: [
                                        {
                                            type: 'DIVIDER',
                                            className:
                                                ' mx-auto lg:mx-0 w-[10px] md:w-[15px] bg-primary border-none h-16 2xl:h-20  '
                                        },
                                        {
                                            type: 'TEXT',
                                            content: 'GROUP PROJECTS',
                                            className: ` text-secondary pt-6 lg:pt-2 text-[25px] md:text-[30px] w-[70%] ml-10 lg:ml-auto lg:text-[30px] xl:text-[35px] 2xl:text-[40px]  text-left leading-[1]  ${
                                                getFont('BIRLA').heading
                                            }`
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className: 'lg:w-[75%]',
                                    components: [
                                        {
                                            type: 'CAROUSEL',
                                            customSlide: true,
                                            caraouselClassName:
                                                ' px-10  py-5 relative',
                                            imageContainerClassName:
                                                'lg:ml-auto m-4 relative',
                                            imageClassName:
                                                'w-full h-56 lg:bg-cover object-cover',
                                            captionClass: `lg:text-left text-sm lg:text-lg font-semibold leading-[1] ${
                                                getFont('BIRLA').body
                                            }`,
                                            arrows: true,
                                            autoplay: true,
                                            autoplaySpeed: 3000,
                                            infinite: true,
                                            dots: false,
                                            slidesToShow: 3,
                                            pauseOnHover: true,
                                            speed: 1500,
                                            onImageClick: openURLNewWindow,
                                            imgArr: [
                                                {
                                                    imgUrl: 'https://cdn.loyalie.in/1706774476355_birla-nayara',
                                                    alt: 'BIRLA NAYARA',
                                                    url: `${domain}/birla-loyalty-home/projects/detail?projectId=2`,
                                                    caption: 'BIRLA NAYARA'
                                                },
                                                {
                                                    imgUrl: 'https://cdn.loyalie.in/1706774696998_birla-vanya',
                                                    alt: 'BIRLA VANYA',
                                                    url: `${domain}/birla-loyalty-home/projects/detail?projectId=1`,
                                                    caption: 'BIRLA VANYA'
                                                },
                                                {
                                                    imgUrl: 'https://cdn.loyalie.in/1706775271676_birla-trimaya',
                                                    alt: 'BIRLA TRIMAYA',
                                                    url: `${domain}/birla-loyalty-home/projects/detail?projectId=2`,
                                                    caption: 'BIRLA TRIMAYA'
                                                },
                                                {
                                                    imgUrl: 'https://cdn.loyalie.in/1706775311417_birla-tisya-bengaluru',
                                                    alt: 'BIRLA TISYA',
                                                    url: `${domain}/birla-loyalty-home/projects/detail?projectId=2`,
                                                    caption: 'BIRLA TISYA'
                                                },
                                                {
                                                    imgUrl: 'https://cdn.loyalie.in/1706775409966_birla-alokya-bengaluru',
                                                    alt: 'BIRLA ALOKYA',
                                                    url: `${domain}/birla-loyalty-home/projects/detail?projectId=3`,
                                                    caption: 'BIRLA ALOKYA'
                                                },
                                                {
                                                    imgUrl: 'https://cdn.loyalie.in/1706775455554_birla-navya-gurugram',
                                                    alt: 'BIRLA NAVYA',
                                                    url: `${domain}/birla-loyalty-home/projects/detail?projectId=2`,
                                                    caption: 'BIRLA NAVYA'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                '*A joint initiative by Birla Estates & Reloy',
                                            className: `text-secondary text-center p-5 lg:text-right font-medium text-[17px] md:pr-5 md:pb-2 ${
                                                getFont('BIRLA').body
                                            }`
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        dropDown: {
            show: true,
            name: 'ENGAGEMENT',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'ENGAGEMENT',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms & Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://cdn.loyalie.in/1692626196588_refer%20page%20banner',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1692625772205_refer%20page%20mob',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover bg-right lg:bg-left px-10 xl:px-20',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-left text-secondary text-[28px] md:text-[27px] lg:text-[22px] xl:text-[25px]  2xl:text-[30px] uppercase my-5 tracking-widest lg:w-[45%] xl:w-[55%] 2xl:w-[60%] ml-auto leading-[1.1] ${
                                getFont('BIRLA').heading
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                ' lg:w-[45%] xl:w-[55%] 2xl:w-[60%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `flex text-[15px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary-focus  text-primary rounded-md leading-[1] px-5 md:px-6 pt-3 xl:pt-4 pb-3 uppercase ${
                                        getFont('BIRLA').medium
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0'
                },
                backgroundImage:
                    'https://cdn.loyalie.in/1692626196588_refer%20page%20banner',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1692625772205_refer%20page%20mob',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[70vh] object-cover bg-right lg:bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  my-5 tracking-widest  lg:w-[80%] mx-auto  ${
                    //         getFont('BIRLA').body
                    //     }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[45px] font-bold  pb-5  tracking-widest lg:w-[50%] mx-auto  ${
                    //         getFont('BIRLA').heading
                    //     }`
                    // },
                    // {
                    //     type: 'BUTTON',
                    //     content: 'Start Referring Now',
                    //     className: `text-md font-medium Birla Estates-button hover:Birla Estates-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 lg:w-[20%] mx-auto ${
                    //         getFont('BIRLA').heading
                    //     }`,
                    //     scrollTo: 'referral_section',
                    //     onClick: 'SCROLL'
                    // }
                ]
            }
        ],

        customize: {
            heading: `${getFont('BIRLA').heading}`,
            body: `${getFont('BIRLA').body}`,
            claimButton: `text-md font-bold bg-primary text-white ${
                getFont('BIRLA').heading
            }`
        }
    },
    blogs: {
        components: [
            {
                type: 'BANNER',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[60vh] md:h-[70vh] lg:h-[97vh] lg:h-screen bg-top mx-auto text-center ',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.4'
                },
                backgroundImage: 'https://cdn.loyalie.in/43584331.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/43584331.jpg',

                components: [
                    {
                        type: 'TEXT',
                        content: 'Birla Estates READERS’ CLUB',
                        className: ` mt-12 md:mt-10 mb-3 text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px] uppercase text-white tracking-wider ${
                            getFont('BIRLA').secondary
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary-focus border-none h-[4px]',
                        classNameContainer: ''
                    },
                    {
                        type: 'TEXT',
                        content:
                            'Bringing Together Information And Entertainment For You.',
                        className: `mt-5 text-white font-medium text-[20px] 2xl:text-[20px] leading-6 ${
                            getFont('BIRLA').body
                        }`
                    },
                    {
                        type: 'BUTTON',
                        content: 'READ MORE',
                        scrollTo: 'blogs_section',
                        onClick: 'SCROLL',
                        className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary-focus text-primary rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                            getFont('BIRLA').medium
                        }`
                    }
                ]
            }
        ],
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[60vh] md:h-[70vh] lg:h-[85vh] md:bg-center mx-auto text-center ',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.4'
            },
            backgroundImage: 'https://cdn.loyalie.in/43584331.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/43584331.jpg',

            components: [
                {
                    type: 'TEXT',
                    content: 'Birla Estates READERS’ CLUB',
                    className: ` mt-12 md:mt-10 mb-3 text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px] uppercase text-white tracking-wider ${
                        getFont('BIRLA').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary-focus border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `mt-5 text-white font-medium text-[20px] 2xl:text-[20px] leading-6 ${
                        getFont('BIRLA').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'READ MORE',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary-focus text-primary rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                        getFont('BIRLA').medium
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'Birla Estates READERS’ CLUB',
                    className: ` mt-12 md:mt-10 mb-3 text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px] uppercase text-white tracking-wider ${
                        getFont('BIRLA').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary-focus border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `mt-5 text-white font-medium text-[20px] 2xl:text-[20px] leading-6 ${
                        getFont('BIRLA').body
                    }`
                },

                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                        getFont('BIRLA').body
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('BIRLA').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('BIRLA').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-[#1c2936] ${
                    getFont('BIRLA').heading
                }`,
                headingClass: `text-[30px] uppercase font-semibold text-secondary mt-3 leading-9 break-words ${
                    getFont('BIRLA').secondary
                }`,
                summaryClass: `inline text-[19px] mt-8 text-[#1c2936] font-bold min-h-[40px] lg:min-h-[70px] ${
                    getFont('BIRLA').body
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('BIRLA').heading
                } `,
                buttonClass: `inline text-[17px] text-primary ml-1 ${
                    getFont('BIRLA').heading
                }`
            },
            sectionHeadings: `px-10 lg:px-16 pt-10 pb-2 text-[22px] lg:text-[25px] xl:text-[35px] tracking-wider text-center uppercase ${
                getFont('BIRLA').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            dividerClass: 'hidden m-0 p-0 ',

            backgroundColor: 'bg-accent',
            cardHeight: ' h-[530px] lg:h-[520px] xl:h-[540px] 3xl:h-[580px] ',
            bodyHeight:
                ' h-[280px] md:h-[280px] lg:h-[270px] xl:h-[290px] 3xl:h-[380px]',
            headingClass: `text-secondary my-5 md:my-7 text-[21px] 3xl:text-[20px] font-medium uppercase leading-6 font-semibold break-words mx-5 ${
                getFont('BIRLA').secondary
            }`,

            summaryClass: `font-normal text-[16.5px] lg:text-[15px] xl:text-[17px] mt-2 lg:my-6 leading-6 text-secondary mx-5 ${
                getFont('BIRLA').body
            }`,
            categoryClass: 'bg-[#00000075] text-white text-[11px]',
            buttonClass: `bg-primary flex justify-center self-start text-[13px] xl:text-[14px] text-white rounded-md py-2 px-4 md:px-4 m-5 ${
                getFont('BIRLA').body
            }`,
            dateClass: `text-[14px] md:text-[17px] xl:text-[15px]  self-center text-[#919396] ${
                getFont('BIRLA').heading
            }`,
            noBlogsClass: `bg-primary text-white p-2 md:w-[30%] lg:w-[70%] xl:w-[50%] 3xl:w-[30%] mt-10 ${
                getFont('BIRLA').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/36794903.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/30484082.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.4'
            },
            negativeMargin: 'lg:-mt-24',
            containerClass: ' text-white w-[50%] ',
            heading: '',
            headingClass: `font-semibold text-center lg:w-[70%] text-[30px] 2xl:text-[35px] text-white uppercase ${
                getFont('BIRLA').secondary
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('BIRLA').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: ` mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                        getFont('BIRLA').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${
                        getFont('BIRLA').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    onClick: 'FUNC',
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                        getFont('BIRLA').body
                    }`
                }
            ]
        },
        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: `self-center text-center text-[22px] md:text-[24px] lg:text-[25px] xl:text-[35px] text-neutral w-[60%] mt-36 ${
                getFont('BIRLA').heading
            }`,
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('BIRLA').body
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('BIRLA').body
            }`
        },
        eventLayout: {
            titleClass: `text-accent leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('BIRLA').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${
            getFont('BIRLA').heading
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('BIRLA').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content  bg-right bg-cover max-w-full  h-[30vh] lg:h-[45vh] bg-top mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692626308381_deals%20banner',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692629526987_deals%20banner%20mob',
            components: [],
            loginBannerComponents: [
                // {
                //     type: 'TEXT',
                //     content: 'EXCLUSIVE OFFERS AND PROMOTIONS',
                //     className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${getFont('BIRLA').heading
                //         }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Birla Estates is driven to create experiences that add more value to your life',
                //     className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${getFont('BIRLA').heading
                //         }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC',
                //     className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${getFont('BIRLA').body
                //         }`
                // }
            ]
        },

        dealsSection: {
            sectionBackgroundImage: '',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.89',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center font-semibold md:text-[20px] text-center text-[17px] text-neutral w-[90%] ${
                getFont('BIRLA').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `uppercase text-secondary text-2xl xl:text-3xl ${
                getFont('BIRLA').heading
            } `,
            subAdditionalText:
                'Birla Estates is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-normal text-[14px] py-2 mt-2 leading-6 ${
                getFont('BIRLA').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('BIRLA').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/43800302.png',
            pattern2: 'https://cdn.loyalie.in/43800302.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.89',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-[18px] leading-6 lg:leading-5 2xl:leading-6 text-primary ${
                getFont('BIRLA').body
            }`,
            listBodyClass: `${getFont('BIRLA').body}`,
            logoClassName: `self-center rounded-md drop-shadow-lg z-10 `,
            titleClassName: ` text-secondary uppercase text-2xl xl:text-xl font-bold 2xl:text-2xl leading-7  mt-4 ${
                getFont('BIRLA').secondary
            } `,
            couponClassName: `block  lg:text-xl self-center text-center text-primary font-bold leading-7 ${
                getFont('BIRLA').heading
            }`,
            dateClassName: ` text-neutral capitalize  text-secondary-focus   ${
                getFont('BIRLA').body
            }`,
            termsAndConditionsClassName: `font-bold capitalize font-bold text-neutral text-[14px]  ${
                getFont('BIRLA').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-bold text-neutral text-[12px] break-words leading-1 ${
                getFont('BIRLA').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-primary-focus border-none border-[2px]   hover:text-bold self-center text-primary rounded uppercase text-[16px] py-[9px] px-7 xl:px-10 ${
                    getFont('BIRLA').medium
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `bg-primary-focus border-none border-[2px]   hover:text-bold self-center text-primary rounded uppercase text-[16px] py-[9px] px-7 xl:px-10 ${
                    getFont('BIRLA').medium
                }`
            }
        }
    },
    myProfile: {
        allowOnboarding: false,
        showMyProperty: true,
        appContainerClassName: 'bg-white text-secondary-focus border-primary',
        appFontHeading: `${getFont('BIRLA').body}`,
        appStoreLink:
            'https://apps.apple.com/in/app/winnre/id1628780202?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.winnre.reloy&hl=en&gl=US&utm_medium=email',
        appScreen: 'https://cdn.loyalie.in/25243853.png'
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.30'
                },
                backgroundImage:
                    'https://cdn.loyalie.in/1706620176212_projects_banner',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1706620176212_projects_banner',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[50vh] md:h-[70vh] lg:h-[80vh] md:bg-right',

                components: [
                    {
                        type: 'TEXT',
                        content: 'Birla Estates PROJECTS',
                        className: ` mx-auto uppercase text-center mb-3 text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px] tracking-wider ${
                            getFont('BIRLA').secondary
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary-focus border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {
            constructionVisible: true,

            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },
    contactUs: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/47738787.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/69109900.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content text-center max-w-full bg-top h-[50vh] md:h-[70vh] lg:h-[80vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: ` mx-auto text-center mb-3 text-white text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]   tracking-wider ${
                            getFont('BIRLA').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[15%] xl:w-[10%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ]
    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('BIRLA').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('BIRLA').body
        }`,
        components: [
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('BIRLA').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program Birla Estates As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('BIRLA').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Birla Estates project, and has entered into a registered Agreement to Sell; or (ii) an employee of Birla Estates ; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Birla Estates or any of its sister concerns/subsidiaries/affiliates (“Birla Estates Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Birla Estates Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Birla Estates or the Project Entity',
                            'Referrer shall not be able to refer existing customer(s) of Birla Estates . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Birla Estates or (ii) is/are already registered with Birla Estates as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Birla Estates’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',
                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Birla Estates within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Birla Estates.',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions. Appropriate TDS amount will be deducted as per applicable norms while remitting the referral payments through vouchers.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Birla Estates or otherwise.',
                            'Employees of Birla Estates working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('BIRLA').body
                        }`,
                        classNameContainer: 'px-6 mt-6'
                    },
                    { type: 'RAZORPAY_TERMS', classNameContainer: 'px-6 mt-6' },
                    {
                        type: 'CUSTOM',
                        className: 'px-6 pt-2',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Birla Estates, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('BIRLA').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('BIRLA').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('BIRLA').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('BIRLA').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd. In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('BIRLA').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('BIRLA').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('BIRLA').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },

    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/27822817.png',
        containerClassName: 'bg-primary-focus xl:pt-0',
        footerLogoClassName: 'self-center md:w-[15%] lg:w-[16%] lg:pl-3 ',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('BIRLA').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterExpandContent: true,
        loyalieFooterExpandName: 'Disclaimer',
        loyalieFooterExpandClassName: `text-center text-white cursor-pointer pb-2 ${
            getFont('BIRLA').heading
        }`,
        loyalieFooterExpandContentClassName: ` self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-2 xl:pr-6 text-[13px] 3xl:text-[15px] tracking-tight text-white leading-[1.2] ${
            getFont('BIRLA').body
        }`,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[13.5px] 3xl:text-[15px] tracking-tight text-white ${
            getFont('BIRLA').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-secondary-focus text-extrabold text-[17.px] ${
            getFont('BIRLA').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-80'
    },
    referralForm: {
        name: 'Birla Estates',
        theme: 'Birla EstatesTheme',
        color: '#9f1b3c',
        backgroundImage: 'https://cdn.loyalie.in/21914593.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '52',
            G1: '11',
            B1: '24',
            opacity1: '0.8',
            R2: '255',
            G2: '255',
            B2: '255',
            opacity2: '0.5'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading1: '',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own an',
            employee: true,
            employeeHeading1: 'I am an',
            salesAdmin: true,
            salesAdminHeading1: 'I am an',
            influencer: false,
            influencerHeading: ''
        },
        formContent: {
            heading: '"LET THE MAGICAL JOURNEY UNFOLD"',
            headingClass: `self-center text-white sm:mt-0 text-3xl md:text-4xl 2xl:text-5xl w-[80%] lg:w-[80%] 2xl:w-[80%] text-center leading-10 tracking-wide ${
                getFont('BIRLA').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%23830c6f'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: false,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,

        isDirectTAndCLink: false,
        termsAndConditionsLink: '/birla-loyalty-home/terms-and-conditions',
        customLink: '/birla-loyalty-home/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: true,
        footerDisclaimerContent:
            'The sales team at Birla Estates is not eligible to be a part of the referral program. No Birla Estates group employee can be submitted as a referral.'
    }
};
export default BirlaConfig;
