import React from 'react';
import GrClose from '@meronex/icons/gr/GrClose';
import Modal from '../../Components/Organisms/Modal';

const BookNowModal = ({
    setShowBookNowModal,
    showBookNowModal,
    loading,
    bookNow
}) => {
    return (
        <Modal
            visible={showBookNowModal}
            onClick={() => {
                if (loading) return;
                setShowBookNowModal(false);
            }}
        >
            <div className="flex justify-end px-4 ">
                <GrClose
                    onClick={() => {
                        if (loading) return;
                        setShowBookNowModal(false);
                    }}
                    className={` h-5 w-5 cursor-pointer `}
                />
            </div>
            <div className="py-6 flex flex-col">
                <p
                    className={`
             z-10 mt-2 mx-auto w-[90%] lg:w-[80%] text-center `}
                >
                    {/* Thank You! Someone from our team will get in touch with you. */}
                    Would you like to Book this property?
                </p>
               
                <div className="flex flex-row align-items-center justify-center mt-2">
                    <button
                        onClick={() => setShowBookNowModal(false)}
                        className={
                            'bg-secondary justify-self-center px-5 mt-2 py-1.5 text-white text-center mr-2 rounded self-center'
                        }
                        // disabled={loading}
                    >
                        {loading ? 'Loading...' : 'Return'}
                    </button>

                    <button
                        onClick={() => bookNow()}
                        className={
                            'bg-primary justify-self-center px-5 mt-2 py-1.5 text-white text-center mr-2 rounded self-center'
                        }
                        disabled={loading}
                    >
                        Book Now
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default BookNowModal;
