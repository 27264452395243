import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { getConfig } from '../../../../utils/config';
import { scrollToTargetAdjusted } from '../../../../utils/ui';
import { cmsClient, winnreAuth } from '../../../../utils/axios';
import {
    useHistory,
    useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import Modal from '../Modal';
import ScratchCard2 from '../Scratchcard2';
import AiOutlineClose from '@meronex/icons/ai/AiOutlineClose';
import EiExternalLink from '@meronex/icons/ei/EiExternalLink';
import moment from 'moment';
import { truncateHtml } from '../../../../helpers';

import { renderDate } from '../../../../helpers';
import { copyCodeHandler } from '../../../../helpers';
import { useSelector } from 'react-redux';

const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
const isHTML2 = (content) => /<\/?[a-z][\s\S]*>/i.test(content);

function AfterFormSubmittedChoice({ changeCurrentStep, userRole }) {
    const config = getConfig();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const scParam = queryParams.get('sc');
    const [openScratchCard, setOpenScratchCard] = useState(
        !!scParam ? 'TRIGGER' : 'CLOSE'
    );
    const [rewardData, setRewardData] = useState();
    const [rewardClass, setRewardClass] = useState('');
    const [copiedText, setCopiedText] = useState(false);

    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    const clickThroughMobile = clientWebview || internalWebview;

    const generateScratchcard = () => {
        let url = `/winnre/user/scratchCard/generate?eventName=ReferralDone`;
        // if (openScratchCard === 'TRIGGER') {
        if (userRole === 'RELATIONSHIP_MANAGER' || userRole === 'EMPLOYEE') {
            return;
        }
        winnreAuth({
            method: 'post',
            url
        })
            .then((res) => {
                console.log('sc', res);

                if (res.status === 'FAILED') {
                    console.log('No more Assigned deals');
                    return;
                }
                setOpenScratchCard('OPEN');
                setRewardData(res);
            })
            .catch((err) => {
                console.log(err);
                // toast.error('Something went wrong. Please try again');
            });
        // }
    };

    useEffect(() => {
        generateScratchcard();
    }, []);

    const description = rewardData?.deal?.description || '';
    const isHtmlContent = isHTML2(description);

    const truncatedDescription = isHtmlContent
        ? truncateHtml(description, 135)
        : description.slice(0, 135).replace(/<\/?p>/g, '') + '...';

    const updateScratchCardStatus = (id, status) => {
        winnreAuth({
            method: 'post',
            url: `/winnre/user/scratchCard/updateStatus?id=${id}&status=${status}`
        })
            .then((res) => {
                console.log('Update Status Response:', res);
                if (res.status === 'FAILED') {
                    toast.error('Failed to update status. Please try again');
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Something went wrong. Please try again');
            });
    };
    const rewardRedeemHandler = (id) => {
        winnreAuth({
            method: 'post',
            url: `winnre/user/deals/redeem-v2?dealId=${id}&sourcePlatform=${
                clickThroughMobile ? 'MOBILE' : 'WEBSITE'
            }`
        })
            .then((res) => {
                console.log('reward', res);
                window?.webengage?.track('DealClicked', {
                    OfferName: `${rewardData?.deal?.title}}`,
                    RedemptionType: `${rewardData?.deal?.redemptionType}`,
                    CashbackPercentage: `${rewardData?.deal?.eligibleCashback}`,
                    CouponCode: `${rewardData?.deal?.dealRedemptionCode?.dealCode}`,
                    Category: `${rewardData?.deal?.category?.name}`,
                    Vendor: `${rewardData?.deal?.vendor?.name}`,
                    Link: `${rewardData?.deal?.url}`,
                    EndDate: `${rewardData?.deal?.endDate}`,
                    Tags: `${rewardData?.deal?.tags?.map((tag) => tag?.name)}`,
                    DealId: `${rewardData?.id}`,
                    VoucherCode:
                        rewardData?.deal?.redemptionType === 'VOUCHER' &&
                        res?.status == 'SUCCESS'
                            ? res?.message
                            : '',
                    Status: res?.status
                    // Channel: channel ? channel : 'Direct'
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error('Something went wrong. Please try again');
            });
    };
    const handleView = (id) => {
        updateScratchCardStatus(id, 'VIEWED');
    };
    const handleRedeem = (id) => {
        rewardRedeemHandler(id);
        updateScratchCardStatus(id, 'REDEEMED');
    };
    const handleScratch = (id) => {
        updateScratchCardStatus(id, 'SCRATCHED');
    };

    // const [showModal, setShowModal] = useState(false);

    // const ref = useRef < ScratchCard > null;

    // const [rewardData, setRewardData] = useState(null);
    // const [rewardClass, setRewardClass] = useState('');
    // const onClickReset = () => {
    //     ref.current && ref.current.reset();
    // };

    const feedbackHandler = () => {
        changeCurrentStep('feedBackForm');
    };

    // const initForm = () => {
    //     changeCurrentStep('leadDetailsForm');
    // };

    const initForm = () => {
        // console.log(config?.userTypes?.employee, config?.userTypes?.salesAdmin);
        if (userRole === 'CUSTOMER') {
            changeCurrentStep('userProjectForm');
            return;
        }
        if (config?.userTypes?.employee && config?.userTypes?.salesAdmin) {
            changeCurrentStep('userTypeForm');
            return;
        }
        if (userRole === 'RELATIONSHIP_MANAGER') {
            if (!config?.userTypes?.salesAdmin) {
                changeCurrentStep('userProjectForm');
                return;
            }
            changeCurrentStep('createOnBehalfForm');
        } else if (userRole === 'EMPLOYEE') {
            if (!config?.userTypes?.employee) {
                changeCurrentStep('createOnBehalfForm');
                return;
            }
            changeCurrentStep('userProjectForm');
        } else {
            changeCurrentStep('userProjectForm');
        }
    };
    const history = useHistory();
    const handleClick = () => {
        history.push('/myProfile/referrals');
    };

    return (
        <>
            <div className="w-full flex flex-col items-center justify-center py-10 md:py-14 lg:py-20">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92.725"
                    height="92"
                    viewBox="0 0 92.725 92"
                >
                    <g id="_x32_2_signboard" transform="translate(0 -2)">
                        <g
                            id="Group_11222"
                            data-name="Group 11222"
                            transform="translate(0 2)"
                        >
                            <g
                                id="Group_11217"
                                data-name="Group 11217"
                                transform="translate(1.358 1.358)"
                            >
                                <path
                                    id="Path_7141"
                                    data-name="Path 7141"
                                    d="M17.291,9.5C11.908,9.5,7.5,13.37,7.5,18.1V57.574c0,4.735,4.408,8.6,9.791,8.6H87.718c5.388,0,9.791-3.87,9.791-8.6V18.1c0-4.73-4.4-8.6-9.791-8.6Z"
                                    transform="translate(-7.5 -9.5)"
                                    fill="#ffefd2"
                                    fill-rule="evenodd"
                                />
                            </g>
                            <g
                                id="Group_11218"
                                data-name="Group 11218"
                                transform="translate(39.753 58.037)"
                            >
                                <path
                                    id="Path_7142"
                                    data-name="Path 7142"
                                    d="M232.745,322.463H219.506v12.412a24.413,24.413,0,0,1,5.606-3.2c-.462,5.543.28,5.564,1.245,12.739.545,4.025-.285,8.216-2.359,10.651h8.748Z"
                                    transform="translate(-219.506 -322.463)"
                                    fill="#fea610"
                                    fill-rule="evenodd"
                                />
                            </g>
                            <g
                                id="Group_11219"
                                data-name="Group 11219"
                                transform="translate(32.253 67.253)"
                            >
                                <path
                                    id="Path_7143"
                                    data-name="Path 7143"
                                    d="M191.2,373.35a24.414,24.414,0,0,0-5.606,3.2,20.021,20.021,0,0,0-6.212,7.657c-2.069,4.652-1.68,9.49,1.426,12.537h9.277c2.074-2.435,2.9-6.626,2.36-10.651C191.479,378.914,190.737,378.893,191.2,373.35Z"
                                    transform="translate(-178.093 -373.35)"
                                    fill="#a2e62c"
                                    fill-rule="evenodd"
                                />
                            </g>
                            <g
                                id="Group_11220"
                                data-name="Group 11220"
                                transform="translate(22.284 70.881)"
                            >
                                <path
                                    id="Path_7144"
                                    data-name="Path 7144"
                                    d="M134.3,400.609c-2.9-2.316-5.31-4.419-6.819-5.771-1.9-1.7-1.509-2.321-2.541.435-2.805,7.475-2.619,14.531,1.369,17.872h9.417C132.623,410.1,132.234,405.261,134.3,400.609Z"
                                    transform="translate(-123.046 -393.384)"
                                    fill="#8bc725"
                                    fill-rule="evenodd"
                                />
                            </g>
                            <path
                                id="Path_7145"
                                data-name="Path 7145"
                                d="M81.576,2H11.149C5,2,0,6.467,0,11.958V51.432C0,56.926,5,61.4,11.149,61.4H38.395V71.8a21.8,21.8,0,0,0-5.314,6.191c-2.284-1.87-4.186-3.532-5.453-4.667-.363-.325-.638-.611-.859-.841a2.1,2.1,0,0,0-1.973-.927c-.944.2-1.22.94-1.721,2.29l-.167.448a27.338,27.338,0,0,0-1.947,11.048,13.146,13.146,0,0,0,1.692,5.943h-1.32a1.358,1.358,0,0,0,0,2.717H63.156a1.358,1.358,0,0,0,0-2.717H54.351V61.4H81.576c6.148,0,11.149-4.469,11.149-9.963V11.958c0-5.491-5-9.958-11.149-9.958ZM25.453,75.246l.065-.174q.14.132.3.274c1.387,1.242,3.521,3.106,6.083,5.184a16.382,16.382,0,0,0-.737,2.588,12.026,12.026,0,0,0,1.09,8.166H26.074C23.065,88.352,22.814,82.277,25.453,75.246Zm14.2,16.038a14.325,14.325,0,0,1,.516-5.429,1.358,1.358,0,0,0-2.571-.878,16.579,16.579,0,0,0-.668,6.307h-1.38a8.693,8.693,0,0,1-1.721-7.645A17.392,17.392,0,0,1,40.467,73.6a1.362,1.362,0,0,0,.227-.176,22.518,22.518,0,0,1,3.175-1.991A22.669,22.669,0,0,0,44.418,77c.233,1.259.524,2.826.839,5.171.472,3.492-.184,6.98-1.679,9.111H39.652Zm11.982,0H46.7A17.177,17.177,0,0,0,47.95,81.81c-.324-2.41-.621-4.014-.86-5.3a21.033,21.033,0,0,1-.377-7.142A1.358,1.358,0,0,0,44.843,68a26.4,26.4,0,0,0-3.732,1.888V61.4H51.634V91.284ZM90.008,51.432c0,4-3.783,7.246-8.432,7.246H11.149c-4.65,0-8.432-3.251-8.432-7.246V11.958c0-3.993,3.783-7.241,8.432-7.241H81.576c4.65,0,8.432,3.248,8.432,7.241Z"
                                transform="translate(0 -2)"
                            />
                            <g
                                id="Group_11221"
                                data-name="Group 11221"
                                transform="translate(12.451 14.334)"
                            >
                                <path
                                    id="Path_7146"
                                    data-name="Path 7146"
                                    d="M213.477,94.536a1.359,1.359,0,0,0,1.254-.835l.754-1.807h4.9l.755,1.807a1.358,1.358,0,1,0,2.506-1.048l-.978-2.339a1.349,1.349,0,0,0-.241-.576l-3.241-7.756a1.357,1.357,0,0,0-2.475-.065c-.01.019-.021.041-.031.066l-4.455,10.672a1.359,1.359,0,0,0,1.253,1.882Zm4.456-8.506,1.315,3.147s-2.3.787-2.629,0S217.933,86.03,217.933,86.03Z"
                                    transform="translate(-186.154 -81.147)"
                                />
                                <path
                                    id="Path_7147"
                                    data-name="Path 7147"
                                    d="M77.83,81.149H70.108a1.358,1.358,0,1,0,0,2.717h2.5v9.313a1.358,1.358,0,0,0,2.717,0V83.865H77.83a1.358,1.358,0,1,0,0-2.717Z"
                                    transform="translate(-68.75 -81.149)"
                                />
                                <path
                                    id="Path_7148"
                                    data-name="Path 7148"
                                    d="M143.71,93.179V89.2h4.632v3.977a1.358,1.358,0,0,0,2.717,0V82.507a1.358,1.358,0,0,0-2.717,0v3.978H143.71V82.507a1.358,1.358,0,0,0-2.717,0V93.179a1.358,1.358,0,0,0,2.717,0Z"
                                    transform="translate(-127.91 -81.149)"
                                />
                                <path
                                    id="Path_7149"
                                    data-name="Path 7149"
                                    d="M301.859,81.149a1.358,1.358,0,0,0-1.358,1.358v6.585l-5.529-7.4a1.357,1.357,0,0,0-2.445.793c0,.007,0,.013,0,.02V93.179a1.358,1.358,0,0,0,2.717,0V86.593l5.529,7.4.01.011a1.357,1.357,0,0,0,2.436-.824V82.507A1.358,1.358,0,0,0,301.859,81.149Z"
                                    transform="translate(-251.999 -81.149)"
                                />
                                <path
                                    id="Path_7150"
                                    data-name="Path 7150"
                                    d="M377.921,86.36l3.03-2.867a1.358,1.358,0,0,0-1.867-1.973l-5.243,4.96V82.507a1.358,1.358,0,0,0-2.717,0V93.179a1.358,1.358,0,0,0,2.717,0V90.22l2.021-1.912,2.772,5.483a1.358,1.358,0,1,0,2.424-1.226Z"
                                    transform="translate(-316.364 -81.148)"
                                />
                                <path
                                    id="Path_7151"
                                    data-name="Path 7151"
                                    d="M159.292,188.352a1.358,1.358,0,0,0-1.894.321l-2.689,3.786-2.688-3.786a1.358,1.358,0,0,0-2.215,1.573l3.545,4.993v4.892a1.358,1.358,0,1,0,2.717,0v-4.892l3.545-4.993A1.358,1.358,0,0,0,159.292,188.352Z"
                                    transform="translate(-134.921 -168.731)"
                                />
                                <path
                                    id="Path_7152"
                                    data-name="Path 7152"
                                    d="M221.638,188.245a5.654,5.654,0,0,0-5.652,5.642v2.083a5.652,5.652,0,0,0,11.3,0v-2.083a5.654,5.654,0,0,0-5.652-5.642Zm2.936,7.725a2.935,2.935,0,0,1-5.871,0v-2.083a2.935,2.935,0,0,1,5.871,0Z"
                                    transform="translate(-189.321 -168.849)"
                                />
                                <path
                                    id="Path_7153"
                                    data-name="Path 7153"
                                    d="M301.961,188.1a1.358,1.358,0,0,0-1.358,1.358v7.247a2.228,2.228,0,0,1-4.445,0V189.46a1.358,1.358,0,0,0-2.717,0v7.247a4.942,4.942,0,0,0,9.878,0V189.46A1.358,1.358,0,0,0,301.961,188.1Z"
                                    transform="translate(-252.749 -168.732)"
                                />
                                <path
                                    id="Path_7154"
                                    data-name="Path 7154"
                                    d="M372.483,188.1a1.358,1.358,0,0,0-1.358,1.358v5.512a1.358,1.358,0,0,0,2.717,0V189.46A1.358,1.358,0,0,0,372.483,188.1Z"
                                    transform="translate(-316.364 -168.732)"
                                />
                                <path
                                    id="Path_7155"
                                    data-name="Path 7155"
                                    d="M372.483,246.655a1.358,1.358,0,0,0-1.358,1.358v.067a1.358,1.358,0,1,0,2.717,0v-.067A1.358,1.358,0,0,0,372.483,246.655Z"
                                    transform="translate(-316.364 -216.681)"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
                <div className=" mt-4">
                    <h1 className="font-bold text-xl  text-center text-black">
                        Thank you for referring!
                    </h1>
                </div>
                <div className=" mt-2">
                    <p className="whitespace-nowrap  text-base font-semibold text-center">
                        You are one step closer to earn more.
                    </p>
                </div>
                <div className=" mt-2">
                    <p className="  text-base font-semibold text-center md:whitespace-nowrap">
                        Refer more friends to increase your rewards.
                    </p>
                </div>
                <div className=" mt-2">
                    <button
                        onClick={initForm}
                        className="text-primary text-base cursor-pointer font-bold text-center"
                    >
                        Refer more
                    </button>
                </div>

                <button
                    className=" text-white font-semibold py-3 px-10  text-base mt-8  bg-primary rounded-md hover:bg-opacity-70"
                    onClick={handleClick}
                >
                    View Past Referrals
                </button>
            </div>
            {/* <Modal
                onClick={() => {}}
                visible={showModal}
                opacity="bg-opacity-90"
                showButton={false}
            >
                <div className="flex flex-col items-center justify-center">
                    <button
                        onClick={() => setShowModal(false)}
                        className="text-white mb-1 font-semibold flex items-center justify-center"
                    >
                        <AiOutlineClose />
                        CLOSE
                    </button>
                    <ScratchCard
                        width={311}
                        height={381}
                        image="https://cdn.loyalie.in/21703033.jpg"
                        finishPercent={50}
                        onComplete={() => {
                            // console.log('complete');
                            setRewardClass('absolute inset-0 z-30');
                        }}
                    >
                        <div
                            onClick={() => {
                                window.open(rewardData?.url);
                            }}
                            className={` bg-white h-full w-full cursor-pointer flex flex-col items-center rounded-lg ${rewardClass}`}
                        >
                            <img
                                src={rewardData?.displayImage?.url}
                                className="h-48 rounded-t-lg"
                            />
                            <div className="px-3">
                                <div className="flex items-center mt-3">
                                    <img
                                        className="h-24 w-24 md:h-20 md:w-20 rounded-lg shadow-lg   "
                                        src={rewardData?.vendor?.logo?.url}
                                    />
                                    <h1
                                        className={`${
                                            listTitleClass
                                                ? listTitleClass
                                                : 'text-xl'
                                        } font-semibold ml-2 animate-pulse `}
                                    >
                                        {rewardData?.title.slice(0, 50)}
                                    </h1>
                                </div>
                                <p
                                    className={`${listBodyClass} text-sm text-[#373737] mt-5`}
                                >
                                    Valid upto :{' '}
                                    {renderDate(rewardData?.endDate)}
                                </p>
                                <p
                                    className={`${listBodyClass} text-sm text-[#373737] `}
                                >
                                    T&C Apply
                                </p>
                            </div>

                            <button
                                onClick={() => {
                                    window.open(rewardData?.url);
                                }}
                                className={`text-sm text-white rounded-b-md cursor-pointer font-semibold bg-primary w-full py-2 mt-2 `}
                            >
                                AVAIL NOW
                            </button>
                        </div>
                    </ScratchCard>
                </div>
            </Modal> */}

            <Modal
                onClick={() => {}}
                visible={openScratchCard === 'OPEN'}
                opacity="bg-opacity-90"
                showButton={false}
            >
                <div className="flex flex-col items-center justify-center">
                    <button
                        onClick={() => {
                            setOpenScratchCard('CLOSE');
                            handleView(rewardData?.id);
                        }}
                        className="text-white mb-6 font-semibold flex items-center justify-center"
                    >
                        <AiOutlineClose />
                        CLOSE
                    </button>
                    <ScratchCard2
                        completedAt={100}
                        onCompleted={() => {
                            setRewardClass('z-50');
                            handleScratch(rewardData?.id);
                        }}
                        size="h-[370px] w-[370px] "
                        isModalSC
                    >
                        <div
                            className={`bg-gray-100 cursor-default flex flex-col h-[370px] w-[370px] rounded-2xl justify-center px-2 ${rewardClass}`}
                        >
                            <img
                                className="w-[100px] h-[100px] mx-auto rounded-lg"
                                src={rewardData?.deal?.vendor?.logo?.url}
                            />

                            <h1 className=" mt-4 mx-auto text-center text-gray-600 font-medium uppercase text-[16px] leading-4 ">
                                <span>{rewardData?.deal?.title}</span>
                            </h1>

                            {!isHTML(rewardData?.deal?.description) && (
                                <p className="text-sm px-5 py-2 text-center break-all">
                                    {rewardData?.deal?.description
                                        .slice(0, 135)
                                        .replace(/<\/?p>/g, '')}
                                    ...
                                </p>
                            )}
                            {isHtmlContent && (
                                <div
                                    className="text-sm px-5 py-2 text-center break-all"
                                    dangerouslySetInnerHTML={{
                                        __html: truncatedDescription
                                    }}
                                />
                            )}
                            {rewardData?.deal?.redemptionType === 'CODE' && (
                                <div className="flex flex-col justify-center  w-full">
                                    <h2 className="uppercase text-gray-600 md:py-2 mt-2 text-center text-xs tracking-widest">
                                        Coupon Code
                                    </h2>
                                    <div className="flex justify-center mb-2">
                                        {!copiedText && (
                                            <div
                                                title="Click to copy "
                                                onClick={() =>
                                                    copyCodeHandler(
                                                        rewardData?.deal
                                                            ?.dealRedemptionCode
                                                            ?.dealCode,
                                                        setCopiedText
                                                    )
                                                }
                                                className="border-2 tracking-widest flex items-center justify-between bg-[#f0f0f0] text-gray-700 border-[#dcdcdc] border-dashed py-2 px-12 hover:border-blue-900 cursor-pointer my-auto rounded-lg text-[15px] relative"
                                            >
                                                <p className="text-sm">
                                                    {
                                                        rewardData?.deal
                                                            ?.dealRedemptionCode
                                                            ?.dealCode
                                                    }
                                                </p>
                                                <svg
                                                    className="ml-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16.391"
                                                    height="17.859"
                                                    viewBox="0 0 16.391 17.859"
                                                >
                                                    <path
                                                        id="copy-copy"
                                                        d="M76.7-4.892H68.5a2.05,2.05,0,0,1-2.049-2.05V-16.6a2.05,2.05,0,0,1,2.049-2.05h8.2a2.05,2.05,0,0,1,2.049,2.05v9.658A2.05,2.05,0,0,1,76.7-4.892ZM77.72-16.6A1.025,1.025,0,0,0,76.7-17.626H68.5A1.025,1.025,0,0,0,67.476-16.6v9.658A1.025,1.025,0,0,0,68.5-5.918h8.2A1.025,1.025,0,0,0,77.72-6.943V-16.6ZM63.378-12.5v9.658A1.025,1.025,0,0,0,64.4-1.817h8.2a1.025,1.025,0,0,0,1.024-1.025V-3.867h1.024v1.025A2.05,2.05,0,0,1,72.6-.792H64.4a2.05,2.05,0,0,1-2.049-2.05V-12.5A2.05,2.05,0,0,1,64.4-14.55h1.024v1.025H64.4A1.025,1.025,0,0,0,63.378-12.5Z"
                                                        transform="translate(-62.354 18.651)"
                                                        fill="#16519e"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                        {copiedText && (
                                            <p
                                                className={`self-center mx-auto text-center font-normal animate-pulse bg-[#154F9D1A] text-[#006EB7] py-2 px-12 text-sm rounded text-[15px] md:text-base`}
                                            >
                                                Code Copied
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                            {rewardData?.deal?.redemptionType !== 'CODE' && (
                                <p
                                    className={` text-sm text-[#373737] mt-4 text-center`}
                                >
                                    Valid upto :{' '}
                                    {renderDate(rewardData?.deal?.endDate)}
                                </p>
                            )}
                            <button
                                onClick={() => {
                                    handleRedeem(rewardData?.id);

                                    if (
                                        rewardData?.deal?.redemptionType !==
                                        'CODE'
                                    ) {
                                        history.push(
                                            `/deals/${rewardData.deal.id}`
                                        );
                                    } else {
                                        window.open(rewardData?.deal?.url);
                                    }
                                }}
                                className={`cursor-pointer text-white self-center bg-[#16519E] rounded-full py-2 px-8 font-CorporateSSC_Medium transition-all duration-300 ease-in-out  hover:bg-transparent hover:border-2 hover:border-[#16519E]  hover:text-[#16519E] ${
                                    rewardData?.deal?.redemptionType !== 'CODE'
                                        ? 'mt-4'
                                        : ''
                                }`}
                            >
                                REDEEM NOW
                            </button>
                        </div>
                    </ScratchCard2>
                </div>
            </Modal>
        </>
    );
}

export default AfterFormSubmittedChoice;
