import { useEffect, useState } from 'react';
import useAxios from '../../../Hooks/use-axios';
import { getConfig } from '../../../utils/config';

const useHomeBlogs = () => {
    const [blogs, setBlogs] = useState([]);
    const config = getConfig();
    const {
        isLoading: loadingBlogs,
        error,
        sendRequest: fetchHomeBlogs
    } = useAxios();

    const getHomeBlogs = () => {
        fetchHomeBlogs(
            {
                isEncryptedResponse: false,
                url: `/cms_service/blogs/filter-v2?clientId=${config?.id}&status=PUBLISHED&isAscending=false&sortBy=publishDate&pageNumber=0&pageSize=3`,
                method: 'get',
                isFormData: true
            },
            (data) => {
                // console.log('BLOG DATA, ', data);
                setBlogs(data?.content || []);
            }
        );
    };

    useEffect(() => {
        getHomeBlogs();
    }, []);

    return {
        blogs
    };
};

export default useHomeBlogs;
