import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Rectangle from '../../images/Rectangle 790.png';
import { currencyToIndianRsWord, truncate } from '../../../../helpers';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import VscKey from '@meronex/icons/vsc/VscKey';
import GiBrickWall from '@meronex/icons/gi/GiBrickWall';
import EiLocation from '@meronex/icons/ei/EiLocation';
import { getConfig } from '../../../../utils/config';

const Property = (props) => {
    const isMapView = useSelector((state) => state.propertyLayout.isMapView);
    const theme = useTheme();
    const {
        projectId,
        projectName,
        projectDescription,
        projectApartmentSize,
        projectCity,
        projectPrice,
        projectStatus,
        projectBackground,
        getProjectLocation,
        mapViewActive,
        onClick,
        propertyBackgroundColorWithOverlay,
        currentPage,
        projectLocation
    } = props;
    const config = getConfig();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let history = useHistory();
    let userId;
    userId = queryParams.get('userId');

    const handleDetail = (e, projectId) => {
        e.stopPropagation();
        const source = queryParams.get('source');
        const initiateBooking = queryParams.get('initiateBooking');

        if (source === 'whatsapp' || source === 'chatbot') {
            if (initiateBooking) {
                history.push(`/projects/book-a-site-visit?id=${projectId}&image=${projectBackground}&name=${projectName}&city=${projectCity}&redirect=whatsapp`)

                // history.push({
                //     pathname: '/projects/book-a-site-visit',
                //     state: {
                //         projectId: projectId,
                //         projectBgImage: projectBackground,
                //         projectCity: projectCity,
                //         projectName: projectName,
                //         redirect: 'whatsapp'
                //     }
                // });
            } else {
                window.open(
                    `https://wa.me/+91${window.reloy_env.client_config.CONTACT_DETAILS?.WHATSAPP_CHATBOT}?text=Project%20Name%3A%20${projectName}%0AProject%20ID%3A%20${projectId}`
                );
            }
        } else {
            history.push(
                `/projects/detail?projectId=${projectId}${
                    userId ? `&userId=${userId}` : ''
                }&page=${currentPage}`
            );
        }
    };

    const mapClickedHandler = (e, projectId) => {
        // console.log('MAP VIEW CLICKED');
        e.stopPropagation();
        getProjectLocation(projectId);
        onClick(projectId);
    };

    const currencyFormating = (price) => {
        if (price >= 10000000)
            return currencyToIndianRsWord
                .format(price)
                .slice(0, -2)
                .concat(' Cr*');
        return currencyToIndianRsWord.format(price).slice(0, -1).concat(' L');
    };

    return (
        <div
            className={`z-10 ${
                propertyBackgroundColorWithOverlay
                    ? propertyBackgroundColorWithOverlay
                    : 'bg-white'
                } w-full flex ${
                    isMapView ? 'flex-row ' : 'flex-col'
                } cursor-pointer rounded-xl `}
            onClick={(E) => handleDetail(E, projectId)}
        >
            <div
                className={`h-44 xl:h-44 2xl:h-52 3xl:h-56 
                      ${
                        isMapView
                        ? 'w-[40%] h-44 xl:h-44 2xl:h-52 3xl:h-56'
                        : 'w-full h-44 xl:h-48 2xl:h-56 3xl:h-64'
                    } `}
            >
                <div className="relative">
                    <img
                        src={projectBackground || Rectangle}
                        alt="Contemplative Reptile"
                        loading="lazy"
                        width="100%"
                        className={`h-44 xl:h-44 2xl:h-52 3xl:h-56 rounded-xl  ${
                            config?.projects?.moduleConfig?.QRCodeImage
                                ? 'object-contain border-[1.5px] border-gray-200'
                                : 'object-cover object-center'
                            }`} // add object-contain for QR code logos
                    />
                    <div className="absolute top-1 left-3">
                        <Tooltip
                            title={
                                (projectStatus === 'READY_TO_OCCUPY' &&
                                    'Ready To Occupy') ||
                                (projectStatus === 'UNDER_CONSTRUCTION' &&
                                    'Under Construction') ||
                                (projectStatus === 'NEWLY_LAUNCHED' &&
                                    'Newly Launched') ||
                                (projectStatus === 'SOLD_OUT' && 'Sold Out') ||
                                (projectStatus === 'READY_FOR_FIT_OUTS' &&
                                    'Ready for Fit Outs')
                            }
                            arrow
                        >
                            <div
                                className={
                                    (projectStatus === 'READY_TO_OCCUPY'
                                        ? 'bg-green-500'
                                        : projectStatus ===
                                            'UNDER_CONSTRUCTION' ||
                                            projectStatus === 'SOLD_OUT'
                                            ? 'bg-red-500'
                                            : ' bg-blue-500') +
                                    '  flex mt-2 justify-between rounded-md'
                                }
                            >
                                <svg
                                    className="m-2 "
                                    id="Group_4"
                                    data-name="Group 4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18.774"
                                    height="15.675"
                                    viewBox="0 0 18.774 15.675"
                                >
                                    <path
                                        id="Shape"
                                        d="M17.772,15.675H11.666a1.3,1.3,0,0,1-.547-.182.72.72,0,0,1-.548.182H4.465a1.016,1.016,0,0,1-1-1V11.984H1a1.016,1.016,0,0,1-1-1v-2.6a1.016,1.016,0,0,1,1-1h2.46V4.6H1a1.016,1.016,0,0,1-1-1V1A1.016,1.016,0,0,1,1,0H7.108a1.3,1.3,0,0,1,.547.182A.722.722,0,0,1,8.2,0h6.106a1.016,1.016,0,0,1,1,1V3.691h2.462a1.016,1.016,0,0,1,1,1v2.6a1.016,1.016,0,0,1-1,1H15.31v2.779h2.462a1.016,1.016,0,0,1,1,1v2.6A1.016,1.016,0,0,1,17.772,15.675ZM4.465,12.121a.1.1,0,0,0-.091.091v2.6a.1.1,0,0,0,.091.091h6.106a.067.067,0,0,0,.062-.038.048.048,0,0,0-.016-.053v-2.6a.1.1,0,0,0-.091-.091H4.465Zm7.2-.046a.1.1,0,0,0-.091.091v2.6a.1.1,0,0,0,.091.091h6.106a.1.1,0,0,0,.091-.091v-2.6a.1.1,0,0,0-.091-.091H11.666ZM1,8.385a.1.1,0,0,0-.091.091v2.6A.1.1,0,0,0,1,11.164H7.108a.1.1,0,0,0,.091-.091v-2.6a.1.1,0,0,0-.091-.091H1Zm7.245-.046a.1.1,0,0,0-.091.091v2.6a.1.1,0,0,0,.091.092h6.06a.1.1,0,0,0,.091-.092V8.43a.1.1,0,0,0-.091-.091H8.248Zm3.418-3.691a.1.1,0,0,0-.091.091v2.6a.1.1,0,0,0,.091.091h6.106a.1.1,0,0,0,.091-.091v-2.6a.1.1,0,0,0-.091-.091H11.666Zm-7.2,0a.1.1,0,0,0-.091.091v2.6a.1.1,0,0,0,.091.091h6.061a.1.1,0,0,0,.091-.091v-2.6a.1.1,0,0,0-.091-.091H4.465ZM1,.911A.1.1,0,0,0,.911,1V3.6A.1.1,0,0,0,1,3.691H7.108A.1.1,0,0,0,7.2,3.6V1A.1.1,0,0,0,7.108.911ZM8.2.865A.066.066,0,0,0,8.141.9a.05.05,0,0,0,.016.056v2.6a.1.1,0,0,0,.091.091h6.06a.1.1,0,0,0,.091-.091V.957a.1.1,0,0,0-.091-.092Z"
                                        transform="translate(0 0)"
                                        fill="#fff"
                                    />
                                </svg>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div
                className={` ${
                    isMapView ? 'w-[60%] px-4' : 'w-full '
                    } flex flex-col justify-between scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-300`}
            >
                <div className=" pl-2 flex-1 flex flex-col justify-center">
                    <div>
                        <h1
                            className={`${config.fontTitle} text-[#3E4B54] truncate text-lg font-bold capitalize mt-2`}
                        >
                            {projectName}
                        </h1>
                        <p
                            className={`${config.fontBody} text-[14px] text-[#838687] capitalize italic`}
                        >
                            {projectCity}
                        </p>
                    </div>
                    <div className={config.fontBody}>
                        <div
                            className={` ${
                                isMapView
                                    ? 'w-[60%] lg:w-full xl:w-[80%]'
                                    : 'w-full '
                                } flex flex-row justify-between mt-4`}
                        >
                            <div>
                                <p className="text-[13px] leading-4 uppercase tracking-wider text-[#838687]">
                                    Starting
                                </p>
                                <h1 className=" self-end font-bold md:text-[16px]">
                                    {currencyFormating(projectPrice)}
                                </h1>
                            </div>
                            <div>
                                <p className="text-[13px] leading-4 uppercase tracking-wider text-[#838687]">
                                    Configurations
                                </p>
                                <h1 className=" self-end font-bold  md:text-[16px]">
                                    {projectApartmentSize === 'Plot' ||
                                        projectApartmentSize === 'Villa'
                                        ? projectApartmentSize
                                        : projectApartmentSize + ' BHK'}
                                </h1>
                            </div>
                        </div>
                        <button
                            className={
                                `${
                                    mapViewActive === projectId 
                                    ? ' text-blue-500' 
                                    : 'text-yellow-500 '
                                } ${projectLocation == null && 'cursor-not-allowed !text-gray-500'}` + 'text-sm self-center text-right'
                            }
                            onClick={(e) => {
                                mapClickedHandler(e, projectId);
                                onClick(projectId);
                            }}
                            disabled={projectLocation == null}
                        >
                            View on map
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Property;
