import { useEffect, useState } from 'react';

const useInput = (validateValue, data) => {
    const [enteredValue, setEnteredValue] = useState('');
    const [isTouched, setIsTouched] = useState(false);

    const valueIsValid = validateValue(enteredValue);
    const hasError = !valueIsValid && isTouched;

    const valueChangeHandler = (event) => {
        setEnteredValue(event.target.value);
    };

    const inputBlurHandler = () => {
        setIsTouched(true);
    };

    const reset = () => {
        setIsTouched(false);
        setEnteredValue('');
    };
    useEffect(() => {
        if (data) {
            setEnteredValue(data);
        }
    }, [data]);

    return {
        value: enteredValue,
        isValid: valueIsValid,
        hasError,
        valueChangeHandler,
        inputBlurHandler,
        reset, 
        setIsTouched
    };
};

export default useInput;
