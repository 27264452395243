import { createSlice } from '@reduxjs/toolkit';

const propertyFilterSlice = createSlice({
    name: 'propertyFilter',
    initialState: {
        locationFilters: [],
        budgetFilters: [],
        apartmentTypeFilters: []
    },
    reducers: {
        addFilter(state, action) {
            switch (action.payload.category) {
                case 'LOCATION':
                    // console.log(state.locationFilters);

                    if (state.locationFilters?.includes(action.payload.value)) {
                        return;
                    } else {
                        state.locationFilters?.push(action?.payload.value);
                        return;
                    }

                case 'BUDGET':
                    if (state.budgetFilters.includes(action.payload.value)) {
                        return;
                    } else {
                        state.budgetFilters.push(Number(action.payload.value));
                        return;
                    }
                case 'APARTMENT_TYPE':
                    if (
                        state.apartmentTypeFilters.includes(
                            action?.payload.value
                        )
                    ) {
                        return;
                    } else {
                        state.apartmentTypeFilters.push(action?.payload.value);
                        return;
                    }
                default:
                    return state;
            }
        },

        addAllFilter(state, action) {
            // console.log('ALL', state, action);
            switch (action.payload.category) {
                case 'LOCATION':
                    state.locationFilters = action.payload.value;
                    return;
                case 'BUDGET':
                    state.budgetFilters = action.payload.value;
                    return;
                case 'APARTMENT_TYPE':
                    state.apartmentTypeFilters = action.payload.value;
            }
        },

        removeFilter(state, action) {
            switch (action.payload.category) {
                case 'LOCATION':
                    if (state.locationFilters.includes(action.payload.value)) {
                        state.locationFilters = state.locationFilters.filter(
                            (filter) => filter !== action.payload.value
                        );
                        return;
                    } else {
                        return;
                    }
                case 'BUDGET':
                    if (
                        state.budgetFilters.includes(
                            Number(action.payload.value)
                        )
                    ) {
                        state.budgetFilters = state.budgetFilters.filter(
                            (filter) => filter !== Number(action.payload.value)
                        );
                        return;
                    } else {
                        return;
                    }
                case 'APARTMENT_TYPE':
                    if (
                        state.apartmentTypeFilters.includes(
                            action.payload.value
                        )
                    ) {
                        state.apartmentTypeFilters =
                            state.apartmentTypeFilters.filter(
                                (filter) => filter !== action.payload.value
                            );
                        return;
                    } else {
                        return;
                    }
                default:
                    return state;
            }
        },

        clearAllFilters(state) {
            state.locationFilters = [];
            state.budgetFilters = [];
            state.apartmentTypeFilters = [];
        }
    }
});

export const propertyFilterActions = propertyFilterSlice.actions;
export default propertyFilterSlice;
export const propertyFilterSliceReducer = propertyFilterSlice.reducer;
