import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';

const KohinoorConfig = {
    clientId:
        'd67de6d4cb772b21ace9f161cf907809fb1f40690f34fa08d0e0d65bb0399be6',
    id: 24,
    name: 'Kohinoor',
    fullName: 'Kohinoor Group',
    programName:'Kohinoor Star',
    theme: 'kohinoorTheme',
    favicon: 'https://cdn.loyalie.in/30448834.png',
    primaryColor: '#005480',
    fontBody: `${getFont('KOHINOOR').body}`,
    fontTitle: `${getFont('KOHINOOR').heading}`,
    googleConfig: {
        gID: 'G-4JB3G5X43R',
        keywordsContent:
            'Kohinoor star, Kohinoor star real estate, Kohinoor star real estate referral programs, Kohinoor star real estate referral program, Kohinoor star real estate refer and earn, Kohinoor star real estate project refer, Kohinoor star real estate, Kohinoor star real estate loyalty program, All New Projects by Kohinoor star real estate Builders & Developers, Ongoing Projects by Kohinoor star real estate limited, Kohinoor star real estate new program, Kohinoor star upcoming projects, Kohinoor real estate news, Kohinoor star review, Kohinoor star projects, Kohinoor star real estate news, Kohinoor star launch Kohinoor star, Kohinoor star real estate launch, Kohinoor star real estate program, Kohinoor star real estate projects, Kohinoor star real estate blogs, Kohinoor star real estate offers, Kohinoor star real estate events, Kohinoor star, Kohinoor star, Kohinoor star real estate best projects, Kohinoor star real estate real estate developers',
        descriptionContent:
            'Kohinoor intends to provide living spaces designed keeping your needs in mind. A home planned so that you can move right in. Kohinoor Star is an initiative to establish this value through action and give you the opportunity to build happy communities.',
        authorContent: 'Kohinoor Star'
    },
    facebookPixelConfig: {
        id: '803262457659776', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~11b564286',
    webengageAPIKey:'cb0147a9-791c-4cfb-ae54-95cd4d2be7fd',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout3',
        
    },
    activateReferPage: false,
    logoLeft: {
        name: 'kohinoorLogo',
        url: 'https://cdn.loyalie.in/42798730.png',
        stickyNavBarLogoClass:
            ' h-16 md:h-25 lg:h-[60px] xl:h-[75px] 2xl:h-[80px] py-1 pl-5 xl:pl-12',
        baseNavBarLogoClass:
            ' h-16 md:h-25 lg:h-[60px] xl:h-[75px] 2xl:h-[80px] py-1  pl-5 xl:pl-12'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('KOHINOOR').body}`,
        height: 'h-20 xl:h-24 2xl:h-28',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-white lg:shadow-lg',
        showActiveLinkColor: false,

        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-primary-content ', // default: transparent
            containerClass: ' ',
            itemClass:
                'uppercase text-primary hover:text-secondary text-[14px] font-semibold lg:text-[12px] xl:text-[15px] 3xl:text-lg lg:mr-7',
            dropDownContainerClass: `hover:text-secondary focus:text-secondary focus:shadow-outline `,
            dropDownItemClass:
                'tracking-widest text-primary font-semibold divide-b divide-accent text-[15px] lg:text-[13px] xl:text-[15px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-primary-content drop-shadow-md', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase text-primary hover:text-secondary text-[14px] font-semibold lg:text-[12px] xl:text-[15px] 3xl:text-lg lg:mr-7' // Each list element in NavBar (Text color, hover color, size)
        },
        footerNavItemClass: `text-primary hover:text-secondary text-[14px] font-semibold lg:text-[12px] xl:text-[15px] 3xl:text-lg ${
            getFont('KOHINOOR').body
        }`
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 80,
    clientButtonClassName: `text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 ${
        getFont('KOHINOOR').bold
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true,

            components: [
                {
                    type: 'HORIZONTAL_CAROUSEL',
                    duration: 2500,
                    arrows: false,
                    images: [
                        {
                            imgUrl: 'https://cdn.loyalie.in/15850674.jpg',
                            mobileImgUrl: 'https://cdn.loyalie.in/25281597.jpg',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'relative w-full text-center lg:text-left lg:pl-10 xl:pl-20 pt-20 md:pt-32 lg:pt-40 xl:pt-48 2xl:pt-40 ',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content:
                                                'DISCOVER EXCEPTIONAL LIVING',
                                            className: ` text-primary-focus text-3xl leading-none md:text-5xl lg:text-5xl 2xl:text-6xl ${
                                                getFont('KOHINOOR').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'EXPERIENCES WITH KOHINOOR STAR',
                                            className: `text-primary-focus text-3xl leading-none md:text-5xl lg:text-5xl 2xl:text-6xl ${
                                                getFont('KOHINOOR').heading
                                            }`
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full text-center lg:text-left',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'A joint initiative by Kohinoor Group & Reloy',
                                                    className: `mt-1 text-secondary-focus font-semibold text-center lg:text-left text-[16px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 w-full ${
                                                        getFont('KOHINOOR').body
                                                    }`
                                                },
                                                {
                                                    type: 'BUTTON',
                                                    content: 'Refer Now',
                                                    className: `md:mx-0 mx-auto text-center font-semibold lg:text-left mt-3 xl:mt-5 text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] tracking-wider bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2 md:py-2 xl:py-3 2xl:py-[11px] px-5 md:px-6 ${
                                                        getFont('KOHINOOR').body
                                                    }`,
                                                    path: 'gift-and-earn',
                                                    onClick:
                                                        'CHANGE_PATH_AND_SCROLL',
                                                    scrollTo: 'referral_section'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/44065158.jpg',
                            mobileImgUrl: 'https://cdn.loyalie.in/32052343.jpg',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'relative w-full text-center lg:text-left lg:pl-10 xl:pl-20 pt-20 md:pt-32 lg:pt-44 xl:pt-48 2xl:pt-40',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content: 'A WORLD OF LUXURY,',
                                            className: `text-primary-focus text-3xl leading-none md:text-5xl lg:text-5xl 2xl:text-6xl ${
                                                getFont('KOHINOOR').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content: 'CURATED JUST FOR YOU.',
                                            className: `text-primary-focus text-3xl leading-none md:text-5xl lg:text-5xl 2xl:text-6xl ${
                                                getFont('KOHINOOR').heading
                                            }`
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'Refer Now',
                                            className: `md:mx-0 mx-auto text-center font-semibold lg:text-left mt-3 xl:mt-5 text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] tracking-wider bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2 md:py-2 xl:py-3 2xl:py-[11px] px-5 md:px-6 ${
                                                getFont('KOHINOOR').body
                                            }`,
                                            path: 'gift-and-earn',
                                            onClick: 'CHANGE_PATH_AND_SCROLL',
                                            scrollTo: 'referral_section'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            imgUrl: 'https://cdn.loyalie.in/37019337.jpg',
                            mobileImgUrl: 'https://cdn.loyalie.in/48099467.jpg',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'relative w-full text-center lg:text-left lg:pl-10 xl:pl-20 pt-20 md:pt-32 lg:pt-44 xl:pt-48 2xl:pt-40',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content: 'MAKE YOUR INNER CIRCLE',
                                            className: `text-primary-focus text-3xl leading-none md:text-5xl lg:text-5xl 2xl:text-6xl ${
                                                getFont('KOHINOOR').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'A PART OF YOUR COMMUNITY.',
                                            className: `text-primary-focus text-3xl leading-none md:text-5xl lg:text-5xl 2xl:text-6xl ${
                                                getFont('KOHINOOR').heading
                                            }`
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'Refer Now',
                                            className: `md:mx-0 mx-auto text-center font-semibold lg:text-left mt-3 xl:mt-5 text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] tracking-wider bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2 md:py-2 xl:py-3 2xl:py-[11px] px-5 md:px-6 ${
                                                getFont('KOHINOOR').body
                                            }`,
                                            path: 'gift-and-earn',
                                            onClick: 'CHANGE_PATH_AND_SCROLL',
                                            scrollTo: 'referral_section'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                // {
                //     type: 'BANNER_NEW',
                //     backgroundImage:
                //         'https://cdn.loyalie.in/15850674.jpg',
                //     backgroundImageMobile:
                //         'https://cdn.loyalie.in/25281597.jpg',
                //     linearGradient: {
                //         R: '255',
                //         G: '255',
                //         B: '255',
                //         opacity: '0'
                //     },
                //     bannerContainerClass:
                //         'h-[70vh] md:h-[93vh] lg:h-[80vh] xl:h-[90vh] xl:h-[95vh] bg-bottom lg:bg-top xl:bg-right',
                //     bannerItemContainerClass:
                //         'items-start justify-start lg:justify-center px-8 md:pl-10 lg:pl-16',
                //     position: 'text-left',

                //     components: [
                //         {
                //             type: 'CUSTOM',
                //             className:
                //                 'w-full text-center lg:text-left leading-7 mt-8 lg:mt-0 md:leading-9 lg:leading-none',
                //             components: [
                //                 {
                //                     type: 'TEXT',
                //                     content: 'DISCOVER EXCEPTIONAL LIVING',
                //                     className: ` text-primary-focus tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] ${
                //                         getFont('KOHINOOR').heading
                //                     }`
                //                 },
                //                 {
                //                     type: 'TEXT',
                //                     content: 'EXPERIENCES WITH KOHINOOR STAR',
                //                     className: `text-primary-focus tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] ${
                //                         getFont('KOHINOOR').heading
                //                     }`
                //                 }
                //             ]
                //         },
                //         {
                //             type: 'CUSTOM',
                //             className: 'w-full text-center lg:text-left',
                //             components: [
                //                 {
                //                     type: 'TEXT',
                //                     content:
                //                         'A joint initiative by Kohinoor Group & Reloy',
                //                     className: `mt-1 text-secondary-focus font-semibold text-center lg:text-left text-[16px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 w-full ${
                //                         getFont('KOHINOOR').body
                //                     }`
                //                 },
                //                 {
                //                     type: 'BUTTON',
                //                     content: 'Refer Now',
                //                     className: `md:mx-0 mx-auto text-center font-semibold lg:text-left mt-3 xl:mt-5 text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] tracking-wider bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2 md:py-2 xl:py-3 2xl:py-[11px] px-5 md:px-6 ${
                //                         getFont('KOHINOOR').body
                //                     }`,
                //                     path: 'refer',
                //                     scrollTo: 'referral_section',
                //                     onClick: 'SCROLL'
                //                 }
                //             ]
                //         }
                //     ]
                // },

                {
                    type: 'CUSTOM',
                    id: 'overview',
                    url: 'https://cdn.loyalie.in/75916960.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.96',
                        size: '70% auto'
                    },
                    bannerItemContainerClass: 'bg-cover',
                    className:
                        'flex flex-col justify-center text-center pb-10 px-4',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'z-10 bg-primary w-[95%] md:w-[90%]  drop-shadow-2xl -mt-10 lg:-mt-10 xl:-mt-16  mx-auto  py-10 2xl:py-14',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'ABOUT KOHINOOR STAR',
                                    className: `${
                                        getFont('KOHINOOR').heading
                                    } text-white self-center tracking-wide text-center w-[90%] md:w-[80%] lg:w-[70%] 2xl:w-[60%] text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] mx-auto text-center leading-6 xl:leading-10 `
                                },
                                {
                                    type: 'ANIMATE',
                                    url: 'https://d1pkuz21mplpj3.cloudfront.net/Asset+2.svg',
                                    width: '120',
                                    height: '64',
                                    name: 'first',
                                    imgHeight: 'h-14',
                                    className:
                                        'flex text-primary justify-center text-center m-auto text-center'
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'Kohinoor intends to provide living spaces designed keeping your needs in mind. A home planned so that you can move right in. Kohinoor Star is an initiative to establish this value through action and give you the opportunity to build happy communities. As a loyalty and referral program, it completely focuses on making your living experience top-notch. Take a step ahead with Kohinoor Star to enjoy delightsome benefits and create unforgettable moments. Make indulgence an everyday affair.',
                                    className: `text-white font-medium mx-auto text-center text-[14px] xl:text-[17px] leading-5 xl:leading-6 self-center w-[85%] lg:w-[90%] xl:w-[80%] 2xl:w-[75%] break-words  ${
                                        getFont('KOHINOOR').body
                                    }`
                                }
                            ]
                        },

                        {
                            type: 'TEXT',
                            content: 'THE PRIVILEGES HERE ARE MANIFOLD',
                            className: `${
                                getFont('KOHINOOR').heading
                            } text-primary-focus pt-10 self-center  text-center w-[90%] md:w-[80%] lg:w-[70%] 2xl:w-[60%] text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px]  mx-auto text-center `
                        },
                        {
                            type: 'TEXT',
                            content:
                                'With kohinoor ambassador, you get access to:',
                            className: `${
                                getFont('KOHINOOR').body
                            } mt-1 text-secondary-focus tracking-wide uppercase font-semibold text-center  font-semibold text-[16px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6`
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: '',
                    url: 'https://cdn.loyalie.in/50813339.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0',
                        size: 'cover'
                    },
                    bannerItemContainerClass: 'bg-cover ',
                    className: 'text-left p-3 xl:p-5 bg-left lg:bg-center',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'flex flex-col  justify-start lg:justify-center border-[2px] lg:border-[2px] xl:border-[3px] border-secondary p-1 md:p-4 h-[95vh] md:h-[85vh]  lg:h-[60vh] xl:h-[60vh] 2xl:h-[80vh] ',
                            bannerItemContainerClass: '',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-full lg:w-[40%] text-center lg:text-left lg:absolute lg:right-2 xl:right-4 lg:pr-20 xl:pr-28 2xl:pr-36 ',
                                    bannerItemContainerClass: '',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content: 'Referrals and Earnings',
                                            className: `text-primary-focus uppercase tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] ${
                                                getFont('KOHINOOR').heading
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'Share your exclusive living experiences with your loved ones and earn referral benefits up to ',
                                            className: `inline text-secondary-focus font-semibold w-full md:w-[60%] lg:w-[70%] xl:w-[75%] text-[14px] md:text-[16px] lg:text-[14px] xl:text-[17px] mx-auto lg:mx-0 leading-0 md:leading-5 2xl:leading-6   ${
                                                getFont('KOHINOOR').body
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content: 'Rs. 40,000 ',
                                            className: `inline text-primary font-semibold w-full md:w-[60%] lg:w-[70%] xl:w-[75%] text-[16xp] md:text-[18px] lg:text-[16px] xl:text-[19px] mx-auto lg:mx-0 leading-0 md:leading-5 2xl:leading-6  ${
                                                getFont('KOHINOOR').body
                                            }`
                                        },
                                        {
                                            type: 'TEXT',
                                            content:
                                                'on every successful referral.',
                                            className: `inline text-secondary-focus font-semibold w-full md:w-[60%] lg:w-[70%] xl:w-[75%] text-[14px] md:text-[16px] lg:text-[14px] xl:text-[17px] mx-auto lg:mx-0 leading-0 md:leading-5 2xl:leading-6  ${
                                                getFont('KOHINOOR').body
                                            }`
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },

                /* 3 Grid-COls Section */
                {
                    type: 'CUSTOM',
                    id: '',
                    url: 'https://cdn.loyalie.in/75916960.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.96',
                        size: '70% auto'
                    },
                    bannerItemContainerClass: 'bg-cover',
                    className: 'py-14 xl:py-16',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-8 xl:gap-12 w-[90%] md:w-[45%] lg:w-[90%] xl:w-[82%] mx-auto justify-center h-auto ',
                            bannerItemContainerClass: '',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    id: '',
                                    url: 'https://cdn.loyalie.in/47578314.jpg',
                                    linearGradient: {
                                        R: '255',
                                        G: '255',
                                        B: '255',
                                        opacity: '0',
                                        size: 'cover'
                                    },
                                    bannerItemContainerClass: 'bg-cover ',
                                    className: 'text-left p-3 xl:p-4 ',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'flex flex-col justify-center py-14 xl:py-20 px-8 2xl:px-10 3xl:px-24 border border-white p-4 ',
                                            bannerItemContainerClass: '',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className: '',
                                                    bannerItemContainerClass:
                                                        '',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://cdn.loyalie.in/67723790.png',
                                                            position: 'center',
                                                            className:
                                                                'h-24 md:h-20 xl:h-28  py-3 xl:py-4'
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Discover a wide range of promotional offers from top Pan India brands, designed to complement your distinguished lifestyle.',
                                                            className: `text-white font-medium text-center  text-[16px] lg:text-[14px] xl:text-[17px] leading-5  break-words  ${
                                                                getFont(
                                                                    'KOHINOOR'
                                                                ).body
                                                            }`
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    id: '',
                                    url: 'https://cdn.loyalie.in/1462482.jpg',
                                    linearGradient: {
                                        R: '255',
                                        G: '255',
                                        B: '255',
                                        opacity: '0',
                                        size: 'cover'
                                    },
                                    bannerItemContainerClass: 'bg-cover ',
                                    className: 'text-left p-3 xl:p-4 ',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'flex flex-col justify-center py-14 xl:py-20 px-7 xl:px-8 2xl:px-10 3xl:px-24 border border-white p-4 ',
                                            bannerItemContainerClass: '',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className: '',
                                                    bannerItemContainerClass:
                                                        '',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://cdn.loyalie.in/42910179.png',
                                                            position: 'center',
                                                            className:
                                                                'h-24 md:h-20 xl:h-28 py-3 xl:py-4'
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Enjoy curated online & offline workshops & events from top collaborators to add more delight to your community experiences.',
                                                            className: `text-white font-medium text-center  text-[16px] lg:text-[14px] xl:text-[17px] leading-5 break-words  ${
                                                                getFont(
                                                                    'KOHINOOR'
                                                                ).body
                                                            }`
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    id: '',
                                    url: 'https://cdn.loyalie.in/7120721.jpg',
                                    linearGradient: {
                                        R: '255',
                                        G: '255',
                                        B: '255',
                                        opacity: '0',
                                        size: 'cover'
                                    },
                                    bannerItemContainerClass: 'bg-cover ',
                                    className: 'text-left p-3 xl:p-4 ',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'flex flex-col justify-center py-14 xl:py-20 px-5 xl:px-6 2xl:px-10 3xl:px-24 border border-white p-4 ',
                                            bannerItemContainerClass: '',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className: '',
                                                    bannerItemContainerClass:
                                                        '',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://cdn.loyalie.in/48689647.png',
                                                            position: 'center',
                                                            className:
                                                                'h-24 md:h-20 xl:h-28 py-3 xl:py-4'
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Know about the latest trends and insights on the real estate industry and unlock a chance to interact with a community of like-minded people.',
                                                            className: `text-white font-medium text-center text-[16px] lg:text-[14px] xl:text-[17px] leading-5  break-words  ${
                                                                getFont(
                                                                    'KOHINOOR'
                                                                ).body
                                                            }`
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: '',
                    url: '',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0',
                        size: 'cover'
                    },
                    bannerItemContainerClass: 'bg-cover ',
                    className:
                        'flex flex-col justify-center text-center py-8 xl:py-10 bg-primary',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'INTRODUCE YOUR LOVED ONES TO YOUR EXCLUSIVE COMMUNITY TO BEGIN AN ENRICHING JOURNEY.',
                            className: `text-white self-center tracking-wide text-center w-[90%] md:w-[95%] lg:w-[60%] xl:w-[58%] 2xl:w-[60%] text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] mx-auto text-center leading-8 lg:leading-[40px] xl:leading-[50px] ${
                                getFont('KOHINOOR').heading
                            }`
                        }
                    ]
                },

                //REFERRAL FORM
                {
                    type: 'REFERRAL_FORM_BANNER'
                    // https://cdn.loyalie.in/69396701.jpg
                },

                // OUR PROJECTS
                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/75916960.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.96',
                        size: '70% auto'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-14 ',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR LATEST BLOGS',
                            className: `${
                                getFont('KOHINOOR').heading
                            } text-primary-focus self-center tracking-wide text-center w-[90%] md:w-[80%] lg:w-[70%] 2xl:w-[60%] text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] mx-auto text-center leading-6 xl:leading-10 `
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://d1pkuz21mplpj3.cloudfront.net/Asset+2.svg',
                            width: '100',
                            height: '64',
                            name: 'second',
                            imgHeight: 'h-14',
                            className:
                                'flex text-primary justify-center text-center m-auto text-center -mb-5'
                        },

                        { type: 'BLOG_LAYOUT_VERTICAL' }
                    ]
                },

                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/75916960.png',
                    linearGradient: {
                        R: '225',
                        G: '225',
                        B: '225',
                        opacity: '1',
                        size: 'cover'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] py-10 text-primary',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR PROJECTS',
                            className: `${
                                getFont('KOHINOOR').heading
                            } text-primary-focus self-center tracking-wide text-center w-[90%] md:w-[80%] lg:w-[70%] 2xl:w-[60%] text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] mx-auto text-center xl:leading-10 `
                        },
                        {
                            type: 'ANIMATE',
                            url: 'https://d1pkuz21mplpj3.cloudfront.net/Asset+2.svg',
                            width: '100',
                            height: '64',
                            name: 'third',
                            imgHeight: 'h-10',
                            className:
                                'flex text-primary justify-center text-center m-auto text-center -mb-5'
                        },
                        {
                            type: 'CAROUSEL',
                            customSlide: true,
                            caraouselClassName:
                                'px-8 md:px-10 xl:px-36 pt-5 pb-2 relative text-primary',
                            imageContainerClassName: 'm-4 relative ',
                            imageClassName:
                                'w-full h-40 xl:h-42 lg:bg-cover object-cover border-4 border-secondary',
                            captionClass: ` text-[18px] lg:text-[16px] text-primary hover:text-secondary xl:text-[19px] font-medium leading-5 ${
                                getFont('KOHINOOR').body
                            }`,
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 4,
                            pauseOnHover: true,
                            speed: 1500,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://kohinoor-group.in/images/peden.jpg',
                                    alt: 'Kohinoor Eden',
                                    url: 'https://kohinoor-group.in/project-eden.html',
                                    caption: 'Kohinoor Eden'
                                },
                                {
                                    imgUrl: 'https://kohinoor-group.in/images/pprime.jpg',
                                    alt: 'Kohinoor Prime',
                                    url: 'https://kohinoor-group.in/project-prime.html',
                                    caption: 'Kohinoor Prime'
                                },
                                {
                                    imgUrl: 'https://kohinoor-group.in/images/pmajestic.jpg',
                                    alt: 'Kohinoor Majestic',
                                    url: 'https://kohinoor-group.in/project-majestic.html',
                                    caption: 'Kohinoor Majestic'
                                },
                                {
                                    imgUrl: 'https://kohinoor-group.in/images/paashiyana.jpg',
                                    alt: 'Kohinoor Aashiyana',
                                    url: 'https://kohinoor-group.in/project-aashiyana.html',
                                    caption: 'Kohinoor Aashiyana'
                                },
                                {
                                    imgUrl: 'https://kohinoor-group.in/images/pluxuria.jpg',
                                    alt: 'Kohinoor Luxuria',
                                    url: 'https://kohinoor-group.in/project-luxuria.html',
                                    caption: 'Kohinoor Luxuria'
                                },
                                {
                                    imgUrl: 'https://kohinoor-group.in/images/pmaxciti.jpg',
                                    alt: 'Kohinoor Maxciti',
                                    url: 'https://kohinoor-group.in/project-maxciti.html',
                                    caption: 'Kohinoor Maxciti'
                                },
                                {
                                    imgUrl: 'https://kohinoor-group.in/images/plifestyle.jpg',
                                    alt: 'Kohinoor Lifestyle',
                                    url: 'https://kohinoor-group.in/project-lifestyle.html',
                                    caption: 'Kohinoor Lifestyle'
                                },
                                {
                                    imgUrl: 'https://kohinoor-group.in/images/pcastles.jpg',
                                    alt: 'Kohinoor Castles',
                                    url: 'https://kohinoor-group.in/project-castles.html',
                                    caption: 'Kohinoor Castles'
                                },
                                {
                                    imgUrl: 'https://kohinoor-group.in/images/pwaves.jpg',
                                    alt: 'Kohinoor Waves',
                                    url: 'https://kohinoor-group.in/project-waves.html',
                                    caption: 'Kohinoor Waves'
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        dropDown: {
            show: true,
            name: 'ENGAGEMENT',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'ENGAGEMENT',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://cdn.loyalie.in/1692633683952_refer%20page%20banner',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1692633683952_refer%20page%20banner',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[80vh] lg:h-[70vh] object-cover  bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center lg:text-left text-primary-focus text-[15px] md:text-[20px] xl:text-3xl uppercase my-5 tracking-widest w-full md:w-[50%] xl:w-[55%] ml-auto lg:leading-8 ${
                                getFont('KOHINOOR').heading
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'flex w-full md:w-[50%] xl:w-[55%] ml-auto ',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `lg:mx-0 mx-auto text-center font-semibold lg:text-left text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] tracking-wider bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2 md:py-2 xl:py-3 2xl:py-[11px] px-5 md:px-6 ${
                                        getFont('KOHINOOR').body
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://cdn.loyalie.in/1692633683952_refer%20page%20banner',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1692633683952_refer%20page%20banner',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[45vh] md:h-[70vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').body
                    //    }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-black text-[15px] leading-10 md:text-[20px] xl:text-[45px] font-bold tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').heading
                    //         }`
                    // },
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex w-[70%] ml-auto justify-center mt-7', components: [{
                    //         type: 'BUTTON',
                    //         content: 'Start Referring Now',
                    //         scrollTo: 'referral_section',
                    //         onClick: 'SCROLL'
                    //     }]
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold bg-primary text-white  ${
                getFont('KOHINOOR').body
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content items-start md:items-center max-w-full h-[70vh] md:h-[90vh] lg:h-[80vh] xl:h-[90vh] xl:h-[95vh] bg-bottom lg:bg-center mx-auto text-center ',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.5'
            },
            backgroundImage: 'https://cdn.loyalie.in/40618345.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/83893671.jpg',
            className: 'justify-start',
            components: [
                {
                    type: 'TEXT',
                    content: 'KOHINOOR READERS’ CLUB',
                    className: `text-primary-focus tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] ${
                        getFont('KOHINOOR').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-secondary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `mt-5 text-secondary-focus  font-semibold text-center text-[16px] md:text-[19px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 ${
                        getFont('KOHINOOR').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: ` md:mx-0 mx-auto text-center font-medium lg:text-left mt-3 xl:mt-5 text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] tracking-wider bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2 md:py-2 xl:py-3 2xl:py-[11px] px-5 md:px-6 ${
                        getFont('KOHINOOR').body
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'KOHINOOR READERS’ CLUB',
                    className: `text-primary-focus tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] ${
                        getFont('KOHINOOR').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-secondary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `mt-5 text-secondary-focus  font-semibold text-center text-[16px] md:text-[19px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 ${
                        getFont('KOHINOOR').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: ` md:mx-0 mx-auto text-center font-medium lg:text-left mt-3 xl:mt-5 text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] tracking-wider bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2 md:py-2 xl:py-3 2xl:py-[11px] px-5 md:px-6 ${
                        getFont('KOHINOOR').body
                    }`
                }
            ]
        },
        sideBarClass: `text-primary-focus text-[20px] lg:text-[25px] ${
            getFont('KOHINOOR').heading
        }`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-secondary-focus capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('KOHINOOR').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                components: [
                    {
                        type: 'ANIMATE',
                        url: 'https://d1pkuz21mplpj3.cloudfront.net/Asset+2.svg',
                        width: '90',
                        height: '64',
                        name: 'first',
                        imgHeight: 'h-14',
                        className:
                            'flex text-primary justify-center text-center m-auto text-center  -mb-10'
                    }
                ],
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-secondary-focus ${
                    getFont('KOHINOOR').body
                }`,
                headingClass: `text-primary-focus  my-4 text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] uppercase leading-7 xl:leading-9 ${
                    getFont('KOHINOOR').heading
                }`,
                summaryClass: `inline text-[15px] md:text-[19px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[25px] mt-8 font-medium leading-7 xl:leading-8 text-secondary-focus min-h-[40px] lg:min-h-[70px] ${
                    getFont('KOHINOOR').body
                }`,
                dateClass: `text-[13px] uppercase text-secondary-focus font-semibold my-3 ${
                    getFont('KOHINOOR').body
                } `,
                buttonClass: `inline text-[15px] md:text-[19px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[25px] font-medium text-primary-focus ml-2 ${
                    getFont('KOHINOOR').body
                }`
            },
            sectionHeadings: `text-primary-focus self-center tracking-wide text-center w-[90%] md:w-[80%] lg:w-[70%] 2xl:w-[60%] text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] mx-auto text-center leading-6 xl:leading-10 mt-10 uppercase ${
                getFont('KOHINOOR').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout3: {
            components: [
                {
                    type: 'ANIMATE',
                    url: 'https://d1pkuz21mplpj3.cloudfront.net/Asset+2.svg',
                    width: '90',
                    height: '64',
                    name: 'second',
                    imgHeight: 'h-14',
                    className:
                        'flex text-primary justify-center text-center m-auto text-center -mb-5'
                }
            ],
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-white',
            headingClass: `text-primary-focus text-[25px] md:text-[30px] lg:text-[25px]  xl:text-[30px] uppercase leading-7 xl:leading-8 h-[90px] md:h-[75px] lg:h-[90px] ${
                getFont('KOHINOOR').heading
            }`,
            summaryClass: `text-[14px] md:text-[16px] lg:text-[15px] lg:my-4  font-medium text-secondary-focus h-[90px] ${
                getFont('KOHINOOR').body
            }`,
            categoryClass: `bg-[#00000075]  text-white text-[10px] ${
                getFont('KOHINOOR').body
            }`,
            buttonClass: `flex justify-center self-start font-medium lg:text-left text-[13px] xl:text-[15px] bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white lg:mt-2 mb-5 py-2 md:px-5 px-6  ${
                getFont('KOHINOOR').body
            }`,
            dateClass: `text-[12px] xl:text-[13px] self-center text-secondary-focus font-medium ${
                getFont('KOHINOOR').body
            }`,
            dateClassContainer:
                'flex font-Roboto justify-start space-x-6 xl:space-x-4 border-t border-primary-focus px-[25px] py-2 lg:py-3 3xl:py-3',
            noBlogsClass: `bg-primary text-white font-semibold p-2 md:w-[30%] lg:w-[70%] xl:w-[50%] 3xl:w-[30%] mt-10 ${
                getFont('KOHINOOR').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/54312903.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/74272435.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.5'
            },
            negativeMargin: '-mt-0 2xl:-mt-24',
            containerClass: ' text-white w-[50%]  ',
            heading: '',
            headingClass: `font-semibold text-center lg:w-[70%] text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] text-primary-focus uppercase ${
                getFont('KOHINOOR').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('KOHINOOR').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                '2xl:mt-24 hero hero-content items-start md:items-center max-w-full h-[70vh] md:h-[90vh] lg:h-[80vh] xl:h-[90vh] xl:h-[95vh] bg-bottom lg:bg-center mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `text-primary-focus tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] ${
                        getFont('KOHINOOR').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-secondary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `mt-5 text-secondary-focus  font-semibold text-center text-[16px] md:text-[19px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 ${
                        getFont('KOHINOOR').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: ` md:mx-0 mx-auto text-center font-medium lg:text-left mt-3 xl:mt-5 text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] tracking-wider bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2 md:py-2 xl:py-3 2xl:py-[11px] px-5 md:px-6 ${
                        getFont('KOHINOOR').body
                    }`
                }
            ]
        },
        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: `self-center text-center text-[22px] md:text-[35px] lg:text-[25px] xl:text-[30px] text-neutral w-[60%] mt-16 ${
                getFont('KOHINOOR').heading
            }`,
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('KOHINOOR').body
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('KOHINOOR').body
            }`
        },
        eventLayout: {
            titleClass: `text-accent leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('KOHINOOR').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-primary-focus font-semibold text-lg my-4 z-10 ${
            getFont('KOHINOOR').body
        }`,
        noGalleryClass: ` text-primary-focus font-semibold ${
            getFont('KOHINOOR').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content items-start md:items-center max-w-full  mx-auto text-center bg-right lg:bg-top bg-cover h-[30vh] lg:h-[45vh]',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692637238907_offer%20kohinoor',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692637238907_offer%20kohinoor',
            loginBannerComponents: [
                // {
                //     type: 'TEXT',
                //     content: 'Exclusive Offers And Promotions',
                //     className: `text-primary-focus tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] ${
                //         getFont('KOHINOOR').heading
                //     }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-secondary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Kohinoor Star is driven to create experiences that add more value to your life',
                //     className: `mt-5 text-secondary-focus  font-semibold text-center text-[16px] md:text-[19px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 ${
                //         getFont('KOHINOOR').body
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClick: 'FUNC',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     className: ` md:mx-0 mx-auto text-center font-medium lg:text-left mt-3 xl:mt-5 text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] tracking-wider bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2 md:py-2 xl:py-3 2xl:py-[11px] px-5 md:px-6 ${
                //         getFont('KOHINOOR').body
                //     }`
                // }
            ]
        },

        dealsSection: {
            sectionBackgroundImage: '',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.89',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE CATEGORIES',
            filterHeadingClass: `self-center font-semibold md:text-[25px] text-center text-[17px] text-secondary w-[90%] ${
                getFont('KOHINOOR').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: ` text-primary-focus tracking-wide text-2xl xl:text-3xl leading-7 ${
                getFont('KOHINOOR').heading
            } `,

            subAdditionalText:
                'Kohinoor is driven to create luxury experiences that add more value to your life.',
            subAdditionalTextClass: ` leading-5 xl:leading-6  break-words ${
                getFont('KOHINOOR').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('KOHINOOR').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-5 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-5 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/75916960.png',
            pattern2: 'https://cdn.loyalie.in/75916960.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.96',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-[18px] leading-5  text-primary-focus ${
                getFont('KOHINOOR').body
            }`,
            listBodyClass: `${getFont('KOHINOOR').body}`,
            logoClassName: `self-center rounded-md drop-shadow-lg z-10 `,
            titleClassName: ` text-2xl xl:text-4xl text-primary-focus  leading-8 xl:leading-9 ${
                getFont('KOHINOOR').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-secondary font-semibold leading-7 ${
                getFont('KOHINOOR').body
            }`,
            dateClassName: ` text-neutral font-medium capitalize text-black ${
                getFont('KOHINOOR').body
            }`,
            termsAndConditionsClassName: `capitalize text-black text-sm font-semibold  ${
                getFont('KOHINOOR').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-black',
            termsClassName: ` text-black text-xs break-words leading-1 ${
                getFont('KOHINOOR').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `flex justify-center self-center font-medium lg:text-left text-[15px] xl:text-[17px] bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2.5 px-7  ${
                    getFont('KOHINOOR').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `rounded hover:shadow-xl self-center font-medium lg:text-left text-[15px] xl:text-[17px] bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2.5 px-7  ${
                    getFont('KOHINOOR').body
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/65541061.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/15446209.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content items-start lg:items-center py-16 text-center max-w-full h-[70vh] md:h-[90vh] lg:h-[90vh] bg-center lg:bg-bottom bg-cover',
                components: [
                    {
                        type: 'TEXT',
                        content: 'KOHINOOR PROJECTS',
                        className: `text-primary-focus  tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] ${
                            getFont('KOHINOOR').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-secondary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {

            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/46329169.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.97',
            repeat: 'repeat',
            size: 'contain'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.50'
                },
                backgroundImage: 'https://cdn.loyalie.in/38922635.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/77340726.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full lg:bg-center h-[80vh] md:h-[90vh] lg:h-[90vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: `text-primary-focus font-semibold mx-auto text-center text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] ${
                            getFont('KOHINOOR').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[20%] md:w-[18%] lg:w-[12%] xl:w-[13%] bg-secondary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],

  
    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-medium  text-[20px] md:text-[20px] ${
            getFont('KOHINOOR').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('KOHINOOR').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.5'
                },
                backgroundImage: 'https://cdn.loyalie.in/64522851.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/53646480.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full lg:bg-center h-[60vh] md:h-[90vh] lg:h-[95vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'Terms And Conditions',

                        className: `text-primary-focus font-semibold mx-auto text-center text-[25px] md:text-[35px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] ${
                            getFont('KOHINOOR').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[20%] md:w-[18%] lg:w-[15%] xl:w-[15%] bg-secondary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-8 lg:p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('KOHINOOR').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-medium  text-[20px] md:text-[20px] ${
                            getFont('KOHINOOR').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Kohinoor  project, and has entered into a registered Agreement to Sell; or (ii) employee of Kohinoor ; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Kohinoor  or any of its sister concerns/subsidiaries/affiliates (“Kohinoor  Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Kohinoor  Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Kohinoor .',
                            'Referrer shall not be able to refer existing customer(s) of Kohinoor . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Kohinoor  or (ii) is/are already registered with Kohinoor  as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Kohinoor ’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Kohinoor  within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Kohinoor ',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Kohinoor  or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('KOHINOOR').body
                        }`,
                        classNameContainer: 'px-6 mt-6'
                    },
                    { type: 'RAZORPAY_TERMS', classNameContainer: 'px-6 mt-6' },

                    {
                        type: 'CUSTOM',
                        className: 'px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Kohinoor Star, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('KOHINOOR').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('KOHINOOR').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('KOHINOOR').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('KOHINOOR').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('KOHINOOR').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('KOHINOOR').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('KOHINOOR').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/55355631.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-0',
        footerLogoClassName: '',
        termsAndConditionsClassName: `text-secondary-focus text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('KOHINOOR').body
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[10.5px] 3xl:text-[15px] leading-[18px] tracking-tight text-secondary-focus ${
            getFont('KOHINOOR').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('KOHINOOR').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-primary border-primary hover:bg-opacity-90 hover:border-primary hover:bg-primary hover:opacity-90 disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-secondary bg-opacity-905'
    },
    referralForm: {
        name: 'Kohinoor',
        color: '#005480',
        backgroundImage: 'https://cdn.loyalie.in/40152448.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '255',
            G1: '255',
            B1: '255',
            opacity1: '0',
            R2: '52',
            G2: '52',
            B2: '52',
            opacity2: '0'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading1: '',
            bookedCustomer: true,
            bookedCustomerHeading1: '',
            employee: true,
            employeeHeading1: '',
            salesAdmin: true,
            salesAdminHeading1: '',
            influencer: false,
            influencerHeading: ''
        },
        customConfig: {
            welcomeContent: '',
            headingColor: 'text-primary',
            userBgColor: 'bg-primary',
            borderColor: 'border-primary',
            animateUserType: true
        },
        pastReferralConfig: {
            selectedStatusColor: 'text-[#B39C64]',
            bgColor: 'bg-primary'
        },
        formContent: {
            heading: '"EMBARK ON YOUR REWARDING JOURNEY NOW"',
            headingClass: `self-center text-white uppercase sm:mt-0 text-3xl md:text-4xl 2xl:text-5xl w-[80%] lg:w-[50%] 2xl:w-[80%] text-center leading-8 tracking-wide ${
                getFont('KOHINOOR').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton: `md:mx-0 mx-auto text-center font-semibold lg:text-left mt-3 xl:mt-5 text-[15px] md:text-[15px] xl:text-[17px] 2xl:text-[20px] tracking-wider bg-gradient-to-r from-[#9A834C] via-[#AE9760] to-[#9A834C] text-white py-2 md:py-2 xl:py-3 2xl:py-[11px] px-5 md:px-6 ${
                getFont('KOHINOOR').body
            }`
        },

        leadEmailMandatory: false,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: false,
        termsAndConditionsLink: '/kohinoor-website/terms-and-conditions',
        customLink: '/kohinoor-website/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default KohinoorConfig;
