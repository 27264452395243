import React from 'react';
import { getConfig } from '../../../utils/config';
import { Button } from '../../Atoms';

function ImageHovertext(props) {
    const { imgArr = [], photosection, imgClassName, className, id } = props;
    const config = getConfig();
    const data = [
        {
            imgUrl: 'https://cdn.loyalie.in/87412147.jpg',
            heading: 'REFERRALS AND EARNINGS',
            body: 'The best communities care about one another, and our vision is to build such communities with this platform. As a member, you can share your experiences living the Srijan lifestyle with your friends and family and earn up to 1% on every successful referral.',
            tagLine: 'You are the voice of our brand.',
            bgColor: 'bg-[#ec1c24]',
            button: {
                visible: true,
                path: 'gift-and-earn',
                onClick: 'CHANGE_PATH',
                buttonContent: 'Refer Now'
            },

            animation: 'hover:animate-slideLeftSide'
        },
        {
            imgUrl: 'https://cdn.loyalie.in/32922516.jpg',
            heading: 'OFFERS AND PROMOTIONS',
            body: 'An enriched life is created out of curated offers and promotions. Srijan Connect delivers you exclusive offers from premium and Pan-India brands to add more to your lifestyle. It is like our brand collaborators treat all members of Srijan Connect with preference. Our goal is to give you something that can become a talking point in your conversations.',
            tagLine: '',
            bgColor: 'bg-[#15519e]',
            button: {
                visible: false,
                path: 'gift-and-earn',
                onClick: 'CHANGE_PATH',
                buttonContent: ''
            },
            animation: 'hover:animate-slideFromBottom'
        },
        {
            imgUrl: 'https://cdn.loyalie.in/46798682.jpg',
            heading: 'WORKSHOPS AND EVENTS',
            body: 'To enjoy what life has to offer, we must often break the monotony of our daily lives. Srijan Connect brings you curated workshops and events to add to your experiences. We collaborate with experts and content creators to give you online and offline events and fun workshops and add value to your life.',
            tagLine: '',
            bgColor: 'bg-[#ec1c24]',
            button: {
                visible: true,
                onClick: 'CHANGE_PATH',
                path: '/events',
                scrollTo: '',
                buttonContent: 'Coming Soon'
            },
            animation: 'hover:animate-slideFromBottom'
        },
        {
            imgUrl: 'https://cdn.loyalie.in/63887187.jpg',
            heading: 'INTERACTIVE BLOGS',
            body: "Reading and learning always add more to our lives. However, it is hard to find time to read books in today's fast-paced life, and we might often miss out on a few good things. Srijan Connect brings you bite-sized reads that fit right in your schedule and always add more to your future conversations.",
            tagLine: '',
            bgColor: 'bg-[#15519e]',
            button: {
                visible: true,
                onClick: 'CHANGE_PATH',
                path: '/blogs',
                scrollTo: '',
                buttonContent: 'Read More'
            },
            animation: 'hover:animate-slideRightSide'
        }
    ];
    return (
        <div
            id={id || '1'}
            className={
                className ||
                'grid grid-flow-row auto-rows-fr lg:grid-flow-col md:auto-cols-fr  w-full h-[1500px] lg:h-[350px] 3xl:h-[450px] border-[#ec1c24] border-b-2  '
            }
        >
            {data.map((i, index) => (
                <div className="w-full h-full relative overflow-hidden">
                    <div
                        className="absolute inset-0 bg-cover bg-center z-0"
                        style={{
                            backgroundImage: `url(
							"${i.imgUrl}"
						)`
                        }}
                    ></div>
                    <div
                        className={
                            'flex flex-col  opacity-0 w-full h-full absolute inset-0 z-10 items-center text-white ' +
                            ` ${i.bgColor} ${i.animation}`
                        }
                    >
                        <h1 className="mt-7  md:mt-10 lg:mt-3 xl:mt-7 3xl:mt-10 px-24 md:px-8 3xl:px-24 text-center text-xl md:text-2xl lg:text-lg xl:text-xl 3xl:text-2xl font-semibold font-ProximaNovaRegular ">
                            {i.heading}
                        </h1>
                        <p className="mt-2 md:mt-5 lg:mt-2 xl:mt-3 px-4 lg:px-4 text-[17px] md:text-[20px] lg:text-[15px] 2xl:text-[17px] 3xl:text-[20px] font-normal text-center font-ProximaNovaRegular">
                            {i.body}
                        </p>
                        <p className="mt-2 md:mt-5 lg:mt-2 xl:mt-3 text-[17px] md:text-[20px] lg:text-[15px] 2xl:text-[17px] 3xl:text-[20px] font-bold font-ProximaNovaRegular">
                            {i.tagLine}
                        </p>
                        {i.button.visible && (
                            <Button
                                className={
                                    config?.clientButtonClassName + ' mt-2'
                                }
                                path={i.button.path}
                                onClick={i.button.onClick}
                                scrollTo={i.button.scrollTo}
                                content={i.button?.buttonContent}
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ImageHovertext;
