import {
    GodrejConfig,
    CenturyConfig,
    ReloyConfig,
    SrijanConfig,
    MahindraConfig,
    KRCFormConfig,
    GodrejFormConfig,
    EmbassyConfig,
    GeraFormConfig,
    ArvindConfig,
    KohinoorConfig,
    GeraConfig,
    SpreConfig,
    KrcConfig,
    JpwowConfig,
    M3MConfig,
    EvosConfig,
    KeeClubConfig,
    KpConfig,
    metroConfig,
    SushmaConfig,
    BramhaConfig,
    HeoConfig,
    PurtiConfig,
    MaxConfig,
    NamanConfig,
    AjmeraConfig,
    BPTPConfig,
    SmartWorldCareConfig,
    BirlaConfig,
    MayfairConfig,
    AlohaConfig
} from '../db/Configurations';

const clientId = window.reloy_env.CLIENT_ID;
const isWebFormOnly = window.reloy_env.WEBFORM_ONLY;
const tenantShortId = (window.reloy_env.allTenants || []).find(
    (t) => t.clientId == clientId
)?.id;

// export const newGetConfig = () => {
//     return configs.find((config) => config.clientId === clientId) || {};
// };

const getConfigInternal = () => {
    switch (clientId) {
        case '43f9ae0bd0e04f52e4d95b93bcf34138dbf6052b78dc2710ff4eb90b3c69f096':
            return ReloyConfig;
        case '89dcfa41903ebba52c0b371d5dcdddb67e8bfd8daf8fea6019ada584de2af9ca':
            return KrcConfig;
        case '4757d92ecbc85bd93320870e1d15ae79788920c0cbf41abe884bfbc4980a0c08':
            return GodrejConfig;
        case '73a80a393c511787ed36c7e96e90d8f7780eb7e32a4aeecadddd292028aa33bb':
            return CenturyConfig;
        case '89f7c75cc7d56d4969a90431f3aa0bf6dff0f21e8656455ae0068483f578584a':
            return MahindraConfig;
        case 'f44e78c8b0cd6357b7393b2ed2f77998e41aba2db0a1f424561b1e67ada29884':
            return SrijanConfig;
        case '576105b5b11f0894b219ccfbadece57ae637db4513eaee8c1c12436438925fed':
            return EmbassyConfig;
        case '48999a82ce0a3ea0a08e23b5e495b8bd6b8e8189e99bdd6563bd9700cada9b2a':
            return ArvindConfig;
        case 'd67de6d4cb772b21ace9f161cf907809fb1f40690f34fa08d0e0d65bb0399be6':
            return KohinoorConfig;
        case '453938a2652120520921743e1c7d58b5514e6fb5145aceae929361652ffeadc3':
            return isWebFormOnly ? GeraFormConfig : GeraConfig;
        case 'b170c980898c1639711e4613010044c8bfa37f205d1c599e54660417645e9c8b':
            return SpreConfig;
        case '51808609e781b8dd8ba561acf88914887185adba2fe5a2698192e9d19d24dc53':
            return JpwowConfig;
        case '519cccdf78f4f5a1f65ea6831f6eca84f48983b90c999e50d91fbe92247e82ea':
            return M3MConfig;
        case 'cb7b53153acf63d24539eae5875ba745d336aea5a00d4d03fa2928425fc73cbd':
            return EvosConfig;
        case '975fa9cb66b0d5d89cea9e33ecfa3a7438d368695e6addce57816a13c4b4e570':
            return KeeClubConfig;
        case '73fcf82a96d9d71fc9bfd320e43e79367e46dbac04f7191b8679bf04e9a3334d':
            return KpConfig;
        case 'dc024884e6e4b9419ef464014a0bfd3254d272e71b5bb68eed21b4f563e27170':
            return metroConfig;
        case 'fbbc10bec56bdff797eebe2fa934a435fdbe2b511526d66cfbdee767d90dfb96':
            return SushmaConfig;
        case '0aa480b36122c84cb5df47318322c02f05d0b5c047e28f7dbe28f2da6b8fefb5':
            return BramhaConfig;
        case '1ae3e252ad3b470f185954ae0f5c4375f7b26a6751da701445648b5bfe638d3c':
            return HeoConfig;
        case '6b6e22e7059b0914d97659142af96bd203db6edf2f8337f468912ae2f4ad9098':
            return PurtiConfig;
        case '98a4ea4028d47686c954d430b36af8aa288eccdfc8a9e28afcd1a266ec01252f':
            return MaxConfig;
        case 'fd7f9ec0d5ad805adc07b227e9de4b32e03380334c387f3c569b4c4198bc1e46':
            return NamanConfig;
        case '1879e2bd97969b10daee96c287081c6d01828015f28a06b0eccc08b348e3ea89':
            return AjmeraConfig;
        case '9821d132cf75037b0b77441d54485292112cd9de652b67431187cf324596d6f7':
            return BPTPConfig;
        case '03684311049f5cd78c9b0d8a932d4ea6e10af7e6f3ded89a860462321670b8bd':
            return SmartWorldCareConfig;
        case '707fc887817bec30baa040d88710a6989a8ae9f9ed9c115bedcdd710f0ec7d56':
            return BirlaConfig;
        case '29er65cb76f499014d6585d35a253fa1d6a96se39drq63701af8c8fe9d2458ee':
            return MayfairConfig
            case '239c65cb76f499014d6585d35a253fa1d6a967239b6953701af8c8fe9d24c526':
                return AlohaConfig;
        default:
            return {};
    }
};

export const getConfig = () => {
    return { ...getConfigInternal(), id: tenantShortId };
};

export const routePathMap = {
    DEALS: '/deals',
    EVENTS: '/events',
    BLOGS: '/blogs',
    PROJECTS: '/projects',
    REFERRAL: '/gift-and-earn',
    CONTACT_US: '/contact-us',
    TERMS: '/terms-and-conditions',
    HOME: '/'
};
