import React, { useState, useEffect } from 'react';
import IosArrowBack from '@meronex/icons/ios/IosArrowBack';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import BookedSite from './BookedSite';
import {
    useHistory,
    useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import { winnreAuth } from '../../../../../utils/axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { APIErrorCondition } from '../../../../../constants';
import { getConfig } from '../../../../../utils/config';

function BookSiteVisit({ props }) {
    const [showBooked, setShowBooked] = useState(false);
    const [selectDate, setSelectDate] = useState('');
    const [date, setDate] = useState('');
    const [hours, setHours] = useState('10');
    const [minutes, setMinutes] = useState('00');
    const [isValid, setIsValid] = useState(true);
    const [error, setError] = useState(null);
    const [selectedTime, setSelectedTime] = useState('AM');
    const [timestamp, setTimestamp] = useState(null);
    const config = getConfig()
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search)

    const projectdata = {
        projectId: queryParams.get('id'),
        projectName: queryParams.get('name'),
        projectBgImage: queryParams.get('image'),
        projectCity: queryParams.get('city')
    };
    const activityId = queryParams.get('activityId');
    const type = queryParams.get('type');

    // console.log(activityId, type, projectdata);

    const currentTime = new Date();

    // Extract the hours, minutes, and seconds
    const hour = currentTime.getHours();
    const minute = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();

    // Format the time as a string
    const formattedTime = `${hour}:${minute}:${seconds}`;

    // Display the current time

    useEffect(() => {
        // console.log(date, 'date');
        if (hours < 10) {
            setError('Sorry! Please select a slot after 10AM');
            return;
        }
        if (hours > 22) {
            setError('Sorry! Please select a slot before 10PM');
            return;
        }
        if (hours == 22 && minutes >= 0) {
            setError('Sorry! Please select a slot before 10PM');
            return;
        }
        if (
            hours < hour + 1 &&
            date &&
            moment(date, 'YYYY-MM-DD').isBefore(moment().endOf('day'))
        ) {
            setError(
                'Sorry! You can only book a slot in the next hour from now.'
            );
            return;
        }
        if (hours >= 12) {
            setSelectedTime('PM');
            setError(null);
            return;
        }
        if (hours < 12) {
            setSelectedTime('AM');
            setError(null);
            return;
        }
    }, [hours, minutes, error, date]);

    const today = moment();
    const next15Days = [];

    for (let i = 0; i < 16; i++) {
        if (i == 0 && moment().isAfter(moment('21:00', 'hh:mm'))) {
            continue;
        }
        const date = today.clone().add(i, 'days');
        const formattedDate = {
            weekName: date.format('ddd'), // Weekday name (short format)
            date: date.format('DD'),
            monthName: date.format('MMM'),
            month: date.format('MM'),
            year: date.format('YYYY') // Year
        };

        next15Days.push(formattedDate);
    }
    // console.log(next15Days);
    const handleTimeSelection = (time) => {
        setSelectedTime(time);
    };

    const handleDateSelection = (e) => {
        const { value } = e.target;
        setSelectDate(value);
        setDate(value);
        generateTimestamp(value, hours, minutes);
    };

    const handleHoursChange = (e) => {
        const { value } = e.target;

        if (value >= 1 && value <= 24) {
            setHours(value);
            generateTimestamp(date, value, minutes);
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };

    const handleMinutesChange = (e) => {
        const { value } = e.target;

        if (value >= 0 && value <= 59) {
            setMinutes(value);
            generateTimestamp(date, hours, value);
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };

    const generateTimestamp = (date, hours, minutes) => {
        if (date && hours !== '' && minutes !== '') {
            const combinedDateTime = moment(
                `${date} ${hours}:${minutes}`,
                'YYYY-MM-DD HH:mm'
            );
            const formattedTimestamp = combinedDateTime.format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
            );
            setTimestamp(formattedTimestamp);
        } else {
            setTimestamp(null);
        }
    };

    let dataIsValid = false;
    if (selectDate !== '' && hours < 22 && hours >= 10 && !error) {
        dataIsValid = true;
    }

    const rescheduleVisit = (visitId, timestamp) => {
        // console.log(visitId);
        winnreAuth({
            method: 'POST',
            url: `/winnre/user/activity/update/${visitId}`,
            data: JSON.stringify({
                timestamp: `${timestamp}`
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                // console.log(res);
                if (res?.status == APIErrorCondition) {
                    toast.error(res?.message);
                } else {
                    setShowBooked(true);
                }
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    };

    const BookSiteVisitSubmit = () => {
        if (type == 'Reschedule') {
            rescheduleVisit(activityId, timestamp);
            return;
        }

        winnreAuth({
            method: 'POST',
            url: `/winnre/user/enquiry/self?projectId=${projectdata?.projectId}&timestamp=${timestamp}`
        })
            .then((res) => {
                // console.log('site visit booking', res);
                if (res?.status == APIErrorCondition) {
                    toast.error(res?.message);
                } else {
                    setShowBooked(true);
                    return;
                }
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    };

    return (
        <>
            <div
                className={`
                    ${config?.bannerNegativeTopMarginClass} w-full h-auto  `}
            >
                {config?.header && (
                    <div className={config?.header.headerClass}></div>
                )}
            </div>
            <div
                onClick={() => history.goBack()}
                className="flex items-center text-primary cursor-pointer "
            >
                <IosArrowBack className="inline text-xl" />
                <p className="inline text-lg ">Back to listing page</p>
            </div>
            <div className="w-full bg-white h-full flex items-center justify-center">
                <div className="w-[90%] lg:w-[30%] xl:w-[25%] mx-auto ">
                    {!showBooked ? (
                        <div>
                            <h1 className="text-2xl font-bold py-5 text-center">
                                {type == 'Reschedule'
                                    ? 'Reschedule Visit'
                                    : 'Book a Site Visit'}
                            </h1>

                            <div className="flex items-center border rounded-[10px] p-5">
                                <div>
                                    <img
                                        src={projectdata.projectBgImage}
                                        className="w-16 h-10 object-cover object-center rounded-[5px]"
                                    />
                                </div>
                                <div className="text-left pl-3">
                                    <h1 className="font-bold leading-[1] text-lg">
                                        {projectdata.projectName}
                                    </h1>
                                    <p className="italic text-sm text-[#adadad]">
                                        {projectdata.projectCity}
                                    </p>
                                </div>
                            </div>

                            <div className="w-full ">
                                <h2 className="font-bold py-3">Select Date</h2>
                                <Splide
                                    options={{
                                        rewind: true,
                                        rewindByDrag: true,
                                        width: '100%',
                                        gap: '4rem',
                                        perPage: 6,
                                        arrows: false,
                                        omitEnd: true
                                    }}
                                >
                                    {next15Days.map((day, id) => (
                                        <SplideSlide>
                                            <button
                                                value={`${day.year}-${day.month}-${day.date}`}
                                                onClick={handleDateSelection}
                                                className={`p-2 border rounded-[10px] w-16 text-center ${selectDate ===
                                                    `${day.year}-${day.month}-${day.date}`
                                                    ? 'bg-primary text-white cursor-not-allowed'
                                                    : ' '
                                                    }`}
                                            >
                                                {day.monthName.slice(0, 3)}
                                                <br />
                                                {day.date}
                                                <br />
                                                {`(${day.weekName.slice(
                                                    0,
                                                    3
                                                )})`}
                                            </button>
                                        </SplideSlide>
                                    ))}
                                </Splide>
                            </div>

                            <div>
                                <div className="py-3 flex  items-center">
                                    <h2 className="font-bold">Select Time</h2>
                                    <p className="pl-3 text-[13px]  text-[#ff0000]">
                                        {error}
                                    </p>
                                </div>

                                <div className="flex items-center space-x-5">
                                    <input
                                        className={`w-20 h-16 rounded-[10px] text-center outline-none border-[1px] ${error ? 'border-[#EE4B2B]' : ' '
                                            } `}
                                        placeholder="HH"
                                        type="number"
                                        value={hours}
                                        onChange={handleHoursChange}
                                    />
                                    <div className="text-3xl leading-[1]">
                                        :
                                    </div>
                                    <input
                                        className={`w-20 h-16 rounded-[10px] text-center outline-none border-[1px]  ${error ? 'border-[#EE4B2B]' : ''
                                            } `}
                                        type="number"
                                        placeholder="MM"
                                        value={minutes}
                                        onChange={handleMinutesChange}
                                    />

                                    <div className="flex border w-30 rounded-[10px] ">
                                        <button
                                            onClick={() =>
                                                handleTimeSelection('AM')
                                            }
                                            className={`cursor-not-allowed rounded-[10px] w-[50%] p-4 m-1 ${selectedTime === 'AM'
                                                ? 'bg-primary text-white'
                                                : 'text-black'
                                                }`}
                                            disabled
                                        >
                                            AM
                                        </button>
                                        <button
                                            onClick={() =>
                                                handleTimeSelection('PM')
                                            }
                                            className={`cursor-not-allowed rounded-[10px] w-[50%] p-4 m-1 ${selectedTime === 'PM'
                                                ? 'bg-primary text-white'
                                                : 'text-black'
                                                }`}
                                            disabled
                                        >
                                            PM
                                        </button>
                                    </div>
                                </div>

                                <p className="text-sm text-[#adadad] pt-2 mb-5">
                                    Select a time between 10AM - 10PM
                                </p>

                                <button
                                    disabled={!dataIsValid}
                                    onClick={BookSiteVisitSubmit}
                                    className={`w-full text-white p-3 rounded-[7px] disabled:cursor-not-allowed disabled:bg-[#adadad] bg-primary`}
                                >
                                    {type == 'Reschedule'
                                        ? 'Reschedule Visit'
                                        : 'Book Site Visit'}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <BookedSite
                            type={type}
                            projectName={projectdata.projectName}
                            projectCity={projectdata.projectCity}
                            projectBgImage={projectdata.projectBgImage}
                            bookedDate={selectDate}
                            bookedTime={`${hours}:${minutes} ${selectedTime}`}
                        />
                    )}
                    <button
                        onClick={() => history.push('/myProfile/site-visits')}
                        className="w-full text-primary p-3 rounded-[7px] border-primary border-2 mt-2 "
                    >
                        View My Visits
                    </button>
                </div>
            </div>
        </>
    );
}

export default BookSiteVisit;
