
import { useState, useEffect } from 'react';

const usePhoneInput = (onMobileChange ,data) => {
    const [mobile, setMobile] = useState(data ? data : null);
    const [isValid, setIsValid] = useState(false);
    const [country, setCountry] = useState('in');
    const [isInternationalCustomer, setIsInternationalCustomer] =
        useState(false);
    const [isInputFocused, setInputFocused] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const mobileHasError = !isValid && isTouched;

    const getMobileWithoutCountryCode = () => {
        if (country) {
            return mobile?.slice(country.dialCode.length) || '';
        } else return null;
    };

    const onChange = (value, country) => {
        if (country.dialCode !== '91') {
            setIsInternationalCustomer(true);
        } else setIsInternationalCustomer(false);
        setCountry(country);
        setMobile(value);
        // Call the callback function to update the mobile state in the parent component
        if (onMobileChange) {
            onMobileChange(value);
        }
    };
    

    useEffect(() => {
        console.log(isValid, isTouched);

    }, [isTouched, isValid]);
    const getLength = (country) => {
        switch (country) {
            case 'Kuwait':
                return 11;
            case 'Malaysia':
                return 9;
            case 'Oman':
                return 11;
            case 'Qatar':
                return 11;
            case 'Saudi Arabia':
                return 12;
            case 'United Arab Emirates':
                return 12;
            case 'Thailand':
                return 10;
            case 'Indonesia':
                return 13;
            case 'India':
                return 12;
            case 'United States':
                return 11;
            case 'Hong Kong':
                return 11;
            case 'Singapore':
                return 10;
            case 'United Kingdom':
                return 12;
            default:
                return null;
        }
    };

    const validator = (value, countryInValidator) => {
        let len = getLength(countryInValidator.name);
        if (!value || !countryInValidator) {
            return false;
        }
        if (!len) {
            setIsValid(true);
            return true;
        }
        if (
            value.startsWith(countryInValidator.dialCode) &&
            value.length === len
        ) {
            setIsValid(true);
            return true;
        }
        if (isTouched) {
            setIsValid(false);
        }
        return !isTouched;
    };

    const state = {
        mobile,
        mobileHasError,
        isValid,
        country,
        isTouched,
        isInputFocused,
        isInternationalCustomer
    };

    return {
        state,
        onChange,
        getMobileWithoutCountryCode,
        setIsTouched,
        validator,
        setMobile,
        setInputFocused,
        setIsInternationalCustomer
    };
};

export default usePhoneInput;
