import React, { useState} from 'react';
import 'react-phone-input-2/lib/style.css';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../Organisms/Modal';
import { authActions } from '../../store/auth-slice';
import LoginForm from '../Forms/LoginForm';

const LoginModal = () => {

    const isLoginModalVisible = useSelector(
        (state) => state.auth.isLoginModalVisible
    );

    const dispatch = useDispatch();

    const closeLoginModal = () => {
        dispatch(authActions.closeLoginModal());
    };

    return (
        <Modal onClick={closeLoginModal} visible={isLoginModalVisible}>
            <LoginForm closeLoginModal={closeLoginModal} />
        </Modal>
    );
};

export default LoginModal;
