import React, { useState } from 'react';
import { getConfig } from '../../../utils/config';
import { scrollToTargetAdjusted } from '../../../utils/ui';

const Pagination = ({
    scrollTo,
    currentPage,
    onPageChange,
    totalPages,
    maxPages = 4
}) => {
    const isPrevActive = currentPage !== 1;
    const isNextActive = currentPage !== totalPages;

    const [basePage, setBasePage] = useState(1);
    const config = getConfig();
    const onChangeOfPage = (page) => {
        // console.log('SCROLLLLL', scrollTo);
        if (scrollTo) {
            scrollToTargetAdjusted(scrollTo);
        }

        if (page % maxPages === 1) {
            setBasePage(page);
        } else if (page % maxPages === 0) {
            setBasePage(page - maxPages + 1);
        } else {
            setBasePage(page - (page % maxPages) + 1);
        }
        onPageChange(page);
    };

    let pageArr = [];
    for (let i = basePage - 1; i < basePage + maxPages - 1; ++i) {
        if (i + 1 <= totalPages) {
            pageArr.push(i + 1);
        } else {
            break;
        }
    }

    if (totalPages <= 1) {
        return null;
    }

    return (
        <div className={`z-20 flex `}>
            {totalPages > 1 && (
                <>
       

                    <button
                        disabled={!isPrevActive}
                        className={`btn bg-white text-black border-none hover:bg-primary hover:text-white z-20 ${!isPrevActive ? '' : ''}`}
                        onClick={() => onChangeOfPage(currentPage - 1)}
                    >
                        «
                    </button>
                </>
            )}
            <div className="">
                {pageArr.map((page) => {
                    // console.log(
                    //     'page ->',
                    //     page,
                    //     currentPage,
                    //     page === currentPage
                    // );
                    return (
                        <button
                            onClick={() => onChangeOfPage(page)}
                            key={page}
                            className={`btn z-20 ml-2 bg-white text-black border-none hover:bg-primary hover:text-white
                            ${
                                page === currentPage
                                    ? 'bg-primary text-white'
                                    : ''
                            }`}
                        >
                            {page < 10 ? `0${page}` : page}
                        </button>
                    );
                })}
            </div>
            {totalPages > 1 && (
                <>
                    <button
                        onClick={() => onChangeOfPage(currentPage + 1)}
                        disabled={!isNextActive}
                        className={`btn ml-2 z-20 bg-white text-black border-none hover:bg-primary hover:text-white ${!isNextActive ? '' : ''}`}
                    >
                        »
                    </button>
                </>
            )}
        </div>
    );
};

export default Pagination;
