import React from 'react'
import Modal from '../../Components/Organisms/Modal';
import GrClose from '@meronex/icons/gr/GrClose';

function HomeLoanModal({showHomeLoanModal, setShowHomeLoanModal}) {
  return (
    <Modal
        visible={showHomeLoanModal}
        onClick={() => {
            setShowHomeLoanModal(false);
        }}
    >
        <div className="flex justify-end px-4 ">
            <GrClose
                onClick={() => {
                    setShowHomeLoanModal(false);
                }}
                className={` h-5 w-5 cursor-pointer `}
            />
        </div>
        <div className="py-6 z-10 mt-2 mx-auto w-[90%] lg:w-[80%] text-center">
        We appreciate your interest! Our Home Loan Eligibility feature is on its way. Stay tuned for further announcements!
        </div>
    </Modal>
);
}

export default HomeLoanModal
