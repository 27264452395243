import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    OutlinedInput
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AiOutlineSearch from '@meronex/icons/ai/AiOutlineSearch';
import { getConfig } from '../../../../utils/config';

const CITIES = [
    { name: 'Delhi', checked: false },
    { name: 'Kolkata', checked: false },
    { name: 'Mumbai', checked: false },
    { name: 'Ranchi', checked: false },
    { name: 'Patna', checked: false },
    { name: 'Hyderabad', checked: false },
    { name: 'Bangalore', checked: false },
    { name: 'Gurgaon', checked: false },
    { name: 'Chandigarh', checked: false }
];

export default function CityList({
    cities,
    cityUpdate,
    projectCityList
}) {
    const [name, setName] = useState('');

    const [foundUsers, setFoundUsers] = useState([]);
    const [cityNames, setCityNames] = useState([]);
const config = getConfig()
    useEffect(() => {
        let citys = projectCityList.map((city) => {
            let c = {
                name: city,
                checked: cities.includes(city)
            };
            return c;
        });
        setCityNames([...citys]);
        setFoundUsers([...citys]);
    }, [projectCityList]);

    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = cityNames.filter((user) => {
                return user.name
                    .toLowerCase()
                    .startsWith(keyword.toLowerCase());
            });
            setFoundUsers(results);
        } else {
            setFoundUsers(cityNames);
        }

        setName(keyword);
    };

    const handleChange = (event, city, index) => {
        const list = cityNames;
        if (name === '') {
            city.checked = event.target.checked;
            list[index] = city;
            setCityNames([...list]);
            setFoundUsers([...list]);
        } else {
            // console.log('YES');
            let cindex = cityNames.indexOf(city);
            city.checked = event.target.checked;
            list[cindex] = city;
            setCityNames([...list]);
            let newFound = foundUsers;
            newFound[index] = city;
            setFoundUsers([...newFound]);
        }
    };

    useEffect(() => {
        cityUpdate(cityNames);
    }, [cityNames]);

    return (
        <div>
            <OutlinedInput
                fullWidth
                sx={{
                    py: 0,
                    height: '36px',
                    marginBottom: 1,
                    fontSize: 14
                }}
                id="outlined-adornment-weight"
                value={name}
                onChange={(val) => filter(val)}
                placeholder="Search locations..."
                endAdornment={
                    <InputAdornment position="end">
                        <AiOutlineSearch />
                    </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                    'aria-label': 'weight'
                }}
            />
            {/* <div className="h-24 overflow-y-scroll overflow-hidden mt-2"> */}
            <div className="h-24 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-300 overflow-hidden mt-2">
                {foundUsers && foundUsers.length > 0 ? (
                    <FormGroup component="fieldset" variant="standard">
                        {foundUsers.map((user, index) => (
                            <FormControlLabel
                                sx={{ padding: 0, margin: 0, display: 'block' }}
                                key={user.name}
                                onChange={(event) =>
                                    handleChange(event, user, index)
                                }
                                control={
                                    <Checkbox
                                        sx={{
                                            padding: '4px 4px 4px 0px',
                                            margin: 0,
                                            '&.Mui-checked': {
                                                color: config.primaryColor
                                            }
                                        }}
                                        checked={user.checked}
                                        value={user.name}
                                    />
                                }
                                label={user.name}
                            />
                        ))}
                    </FormGroup>
                ) : (
                    <h1 className={`${config?.projects.moduleConfig
                        ?.fontBody}`}>
                        No cities found!
                    </h1>
                )}
            </div>
        </div>
    );
}
