import React from 'react';
import { useState } from 'react';
import { coinSvg, homeSvg } from '../../svg';

function StepOne({setStep ,employmentType, onEmploymentTypeChange }) {
   
     const employmentTypes = [{ state:'SALARIED' ,value: 'Salaried', tagline: 'Receive fixed amount of income every month'}, { state:'SELF_EMPLOYED_BUSINESS', value: 'Self-Employed Business', tagline: 'Run a business'}, { state:'SELF_EMPLOYED_PROFESSIONAL', value:'Self-Employed Professional', tagline: 'Engage in a profession (eg.-Doctor,Lawyer,CA etc.)'}]
   

    return (
        <div>
            
        <div className='flex text-lg font-sm justify-between py-5 '>
            <div className='flex  items-center gap-1'>
               {homeSvg}
             <button>Home Loan</button> 
          </div>
              
               <p>Step <span className='text-primary'>1</span>
               <span className='text-sm'>/3</span>
               </p>
            </div>

            <div className="flex items-center border rounded-[10px] p-5">

                    <div>
                   {coinSvg}
  
                    </div>
                   
                 <div className="text-left pl-3">
                    <h1 className="font-bold leading-[1] text-lg ">Thank you for your enquiry</h1>
                    <p className=" text-sm text-[#adadad]">Please share a few more details to help serve you better</p>
                

                </div>   
               
            </div>

            <div>
                <div className="py-3 flex  items-center">
                    <h2 className="font-bold">Employment Type</h2>
                </div>
            
            <div className='flex flex-col gap-4'>
             {employmentTypes.map((type) => ( <div onClick={() =>onEmploymentTypeChange(type.state)} className={ `flex cursor-pointer gap-2 items-center border rounded-[10px] p-5 ${employmentType=== type.state ? 'bg-primary' : ''}`}>
                 <input type='radio' value={type.state} checked={employmentType===type.state}/>
                 <div className='flex flex-col gap-1'> 
                 <label className={`font-bold ${employmentType===type.state ? 'text-white' : ''}`}>{type.value}</label>
                  <p className={`text-sm ${employmentType===type.state ? 'text-white' : ''} `}>{type.tagline}</p>
                </div> 
               </div>))}

               <button
                    className={`w-full text-white p-3 rounded-[10px] disabled:cursor-not-allowed disabled:bg-[#adadad] bg-primary`}
                    onClick={()=>setStep((prev)=>prev+1)}>
                    Continue
                </button>
            </div>
            
           
        </div>
        </div>
    );
}

export default StepOne;
