import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { winnreAuth } from '../../utils/axios';

const useData = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [uploadDocuments, setUploadDocuments] = useState(false);

    const [userAccountSummary , setUserAccountSummary ] = useState(null);

    const getUserAccountSummary = () => {
            winnreAuth({
                method: 'get',
                url: '/winnre/user/booking/account-summary'
            }).then((res) => {
                setUserAccountSummary(res);

                // if(res?.commissionEarned) { 
                //     winnreAuth({
                //         method: 'get',
                //         url: '/winnre/user/invoice/check-documents-for-invoice'
                //     }).then((res) => {
                //         if (res?.status === 'FAILED') {
                //             setUploadDocuments(true);
                //         }
                //     });
                // }
            }).catch((e) => {setUserAccountSummary(null)})
        
    }

    useEffect(()=> {

        if(isAuthenticated) {
            getUserAccountSummary();
        } 

    }, [isAuthenticated]);

    return { isAuthenticated, userAccountSummary, uploadDocuments ,getUserAccountSummary};

};


export default useData;
