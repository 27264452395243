import { useEffect, useState } from 'react';
import useAxios from '../../../Hooks/use-axios';

const useTagsData = ({ type, categoryId }) => {
    const [tags, setTags] = useState([]);

    const { isLoading, error, sendRequest: fetchTags } = useAxios();

    const getTags = () => {
        fetchTags(
            {
                url: `/cms_service/tags/getAllByType?types=${type}&pageSize=50${
                    categoryId ? `&categoryIds=${categoryId}` : ''
                }`,
                method: 'get'
            },
            (data) => {
                setTags(data.content);
                console.log('tags',data.content);
            }
        );
    };

    useEffect(() => {
        getTags();
    }, []);

    return {
        isLoading,
        error,
        tags
    };
};

export default useTagsData;
