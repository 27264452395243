import React from 'react';
import { getConfig } from '../../../utils/config';
import Video from '../../Atoms/Video';

function ReferralLink() {
    const config = getConfig();

    return (
        <div className="w-full h-full">
            <div
                className={`
                    ${config?.bannerNegativeTopMarginClass} w-full h-auto  `}
            >
                {config?.header && (
                    <div className={config?.header.headerClass}></div>
                )}
            </div>
            <div className="h-[80vh] md:h-[110vh] w-[90%] xl:w-[80%] py-16 mx-auto">
                <Video videoUrl="https://www.youtube.com/watch?v=IeWkxIvT_JE" />
            </div>
        </div>
    );
}

export default ReferralLink;
