import React from 'react';
import { getConfig } from '../../../utils/config';
import ComponentsRenderer from '../../ComponentsRenderer';

const getAnimation = (animation) => {
    switch (animation) {
        case 'slideInRight':
            return 'animate-slideInRight';
    }
};

function Banner({
    backgroundImage = '',
    backgroundImageMobile = '',
    components,
    animation,
    linearGradient,
    bannerContainerClassName,
    position
}) {
    // console.log('componnets', components);
    const config = getConfig();
    return (
        <section className={`relative ${config?.bannerNegativeTopMarginClass}`}>
            <div className=" block lg:hidden ">
                <div
                    className={
                        bannerContainerClassName
                            ? bannerContainerClassName
                            : 'hero hero-content max-w-full lg:h-screen'
                    }
                    style={{
                        backgroundImage: `linear-gradient(${'90deg' || linearGradient?.deg }, rgba(${linearGradient?.R || linearGradient?.R1}, ${linearGradient?.G || linearGradient?.G1}, ${linearGradient?.B || linearGradient?.B1}, ${linearGradient?.opacity || linearGradient?.opacity1}),rgba(${linearGradient?.R || linearGradient?.R2}, ${linearGradient?.G || linearGradient?.G2}, ${linearGradient?.B || linearGradient?.B2}, ${linearGradient?.opacity || linearGradient?.opacity2})), url('${backgroundImageMobile}')`
                    }}
                >
                    <div
                        class={`${
                            position ? position : ''
                        } hero-content w-full text-neutral-content`}
                    >
                        <div class={`w-full ${getAnimation(animation)}`}>
                            <ComponentsRenderer components={components} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block ">
                <div
                    className={
                        bannerContainerClassName
                            ? bannerContainerClassName
                            : 'hero hero-content  max-w-full lg:h-screen'
                    }
                    style={{
                        backgroundImage: `linear-gradient(${'90deg' || linearGradient?.deg }, rgba(${linearGradient?.R || linearGradient?.R1}, ${linearGradient?.G || linearGradient?.G1}, ${linearGradient?.B || linearGradient?.B1}, ${linearGradient?.opacity || linearGradient?.opacity1}),rgba(${linearGradient?.R || linearGradient?.R2}, ${linearGradient?.G || linearGradient?.G2}, ${linearGradient?.B || linearGradient?.B2}, ${linearGradient?.opacity || linearGradient?.opacity2})), url('${backgroundImage}')`
                    }}
                >
                    <div className="text-center hero-content w-full text-neutral-content">
                        <div className={`w-full ${getAnimation(animation)}`}>
                            <ComponentsRenderer components={components} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;
