import React from 'react';
import { getConfig } from '../../../utils/config';

function AppDownload() {
    const config = getConfig();

    return (
        <div className="text-white flex justify-center bg-transparent mt-28">
            <div
                className={`w-[85%] lg:w-[75%] mx-auto flex-col flex md:flex-row -mt-16 gap-y-5 lg:-mt-16  px-7 lg:px-10 xl:px-20 py-5 md:py-10 ${
                    config.myProfile?.appContainerClassName
                        ? config.myProfile.appContainerClassName
                        : 'bg-white text-primary  border-primary'
                } drop-shadow-xl border-[3.5px] rounded-lg justify-between`}
            >
                <div className="flex flex-col justify-center">
                    <h1
                        className={`text-[18px] lg:text-xl font-semibold text-center md:text-left leading-[1] ${
                            config.myProfile?.appFontHeading
                                ? config.myProfile.appFontHeading
                                : ' '
                        }`}
                    >
                        Elevate your homeowning experience
                    </h1>
                    <h2
                        className={`font-semibold text-center md:text-left ${
                            config.myProfile?.appFontBody
                                ? config.myProfile.appFontBody
                                : config.myProfile.appFontHeading
                                ? config.myProfile.appFontHeading
                                : ''
                        }`}
                    >
                        Download the App now
                    </h2>
                </div>
                <div className="flex gap-x-4 items-center">
                    <div
                        onClick={() =>
                            window.open(
                                window.reloy_env.client_config.MY_PROFILE
                                    ?.PLAY_STORE_LINK
                            )
                        }
                        className={`cursor-pointer ${
                            window.reloy_env.client_config.MY_PROFILE
                                ?.PLAY_STORE_LINK
                                ? ''
                                : 'hidden'
                        }`}
                    >
                        <img
                            className=" w-[150px]"
                            src="https://cdn.loyalie.in/21166287.png"
                        />
                    </div>

                    <div
                        onClick={() =>
                            window.open(
                                window.reloy_env.client_config.MY_PROFILE
                                    ?.APP_STORE_LINK
                            )
                        }
                        className={`cursor-pointer ${
                            window.reloy_env.client_config.MY_PROFILE
                                ?.APP_STORE_LINK
                                ? ''
                                : 'hidden'
                        }`}
                    >
                        <img
                            className=" w-[150px]"
                            src="https://cdn.loyalie.in/53219008.png"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppDownload;
