import React from 'react';
import HiOutlineArrowNarrowRight from '@meronex/icons/hi/HiOutlineArrowNarrowRight';
import { renderDate, truncate } from '../../../helpers';
import { getConfig } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import useHomeBlogs from '../BlogLayoutVertical1/data';
import { winnreAuth, winnreNoAuth } from '../../../utils/axios';
import { useSelector } from 'react-redux';

function BlogLayoutVertical({ className, classNameContainer }) {
    const config = getConfig();
    const { blogs } = useHomeBlogs();
    const history = useHistory();

    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    const clickThroughMobile = clientWebview || internalWebview;

    const isUserAuthenticated = useSelector(
        (state) => state.auth.isAuthenticated
    );
    const userId = useSelector((state) => state.auth.userId);
    const blogClickedHandler = (blogId, visibleToAll) => {
        if (isUserAuthenticated) {
            winnreAuth({
                method: 'POST',
                url: `winnre/user/blogs/trackClick?blogId=${blogId}&userId=${userId}&sourcePlatform=${
                    clickThroughMobile ? 'MOBILE' : 'WEBSITE'
                }`
                // headers: headers2
            })
                .then(() => {})
                .catch(() => {});
        } else {
            winnreNoAuth({
                method: 'POST',
                url: `winnre/user/blogs/trackClick?blogId=${blogId}&sourcePlatform=${
                    clickThroughMobile ? 'MOBILE' : 'WEBSITE'
                }`
            })
                .then(() => {})
                .catch(() => {});
        }
        if (visibleToAll) {
            history.push(`/blogs/${blogId}`);
        } else {
            history.push(`/blogs`);
        }
    };
    return (
        <div className={className? className : " w-full h-auto flex flex-col-reverse lg:flex-row mt-10 px-5 lg:px-4 xl:px-2 2xl:px-10 justify-center pb-20"}>
            <div className={classNameContainer? classNameContainer : " w-full px-5 md:px-36 lg:px-0 xl:w-[80%] grid grid-cols-12 gap-x-4 lg:gap-x-6 xl:gap-x-14 3xl:gap-x-24 gap-y-14 "}>
                {blogs.slice(0, 3).map((blog) => {
                    return (
                        <div
                            key={blog.id}
                            onClick={() =>
                                blogClickedHandler(blog.id, blog.visibleToAll)
                            }
                            className="text-left min-w-[80vw] md:min-w-[50vw] lg:min-w-full bg-white rounded-md col-span-12 lg:col-span-4 xl:col-span-4 flex flex-col h-[500px]  lg:h-[550px] 3xl:h-[600px] shadow-xl hover:shadow-2xl cursor-pointer"
                        >
                            <div className="relative h-52 3xl:h-72 mb-8 ">
                                <div
                                    className={
                                        `${config?.blogs?.layout3?.categoryClass}` +
                                        ' absolute top-1 right-0 text-[12px] font-medium 3xl:text-base px-4 py-1 m-3 rounded-full uppercase'
                                    }
                                >
                                    <p>
                                        {blog.tags.map((obj) => {
                                            return ' ' + `${obj.name}` + '  ';
                                        })}
                                    </p>
                                </div>

                                <img
                                    src={blog?.displayImageUrl}
                                    className=" h-full w-full rounded-t-md object-cover"
                                    alt="Blog image"
                                />
                            </div>
                            <div className="flex flex-col justify-between px-[25px] h-auto">
                                {/* TOP */}
                                <div className=" overflow-y-hidden ">
                                    <h2
                                        className={
                                            config?.blogs?.layout3?.headingClass
                                                ? config?.blogs?.layout3
                                                      ?.headingClass
                                                : ' mb-2 text-[18px] 3xl:text-[20px] font-normal '
                                        }
                                    >
                                        {truncate(blog.heading, 12)}
                                    </h2>
                                    <p
                                        className={
                                            config?.blogs?.layout3?.summaryClass
                                                ? config?.blogs?.layout3
                                                      .summaryClass
                                                : ' font-normal indent-8 text-[14px] mt-2 lg:mt-6 text-[#777777] '
                                        }
                                    >
                                        {truncate(blog.summary, 28) + '...'}
                                    </p>
                                </div>
                                {/* BOTTOM */}
                                <button
                                    className={
                                        config?.blogs?.layout3?.buttonClass
                                            ? config?.blogs?.layout3
                                                  ?.buttonClass
                                            : ' flex justify-center text-white py-1 px-4 md:py-2 md:px-4'
                                    }
                                >
                                    READ MORE
                                    <span className=" inline-block self-center h-4">
                                        <HiOutlineArrowNarrowRight className="h-4 ml-1" />
                                    </span>
                                </button>
                            </div>

                            <div
                                className={
                                    config?.blogs.layout3.dateClassContainer
                                        ? config?.blogs.layout3
                                              .dateClassContainer
                                        : ' flex font-Roboto justify-start space-x-6 xl:space-x-4 border-t  border-gray-200 px-[25px] py-2 lg:py-3 3xl:py-3'
                                }
                            >
                                <p
                                    className={
                                        config?.blogs.layout3.dateClass
                                            ? config?.blogs.layout3.dateClass
                                            : ' text-[10px] md:text-[12px] self-center text-[#adadad]'
                                    }
                                >
                                    {renderDate(
                                        blog?.publishDate
                                            ? blog?.publishDate
                                            : blog?.updatedOn
                                    )}
                                </p>
                                <p
                                    className={
                                        config?.blogs.layout3.dateClass
                                            ? config?.blogs.layout3.dateClass
                                            : ' text-[10px] md:text-[12px] self-center text-[#adadad]'
                                    }
                                >
                                    {blog.commentsCount === 0
                                        ? 'No comments'
                                        : blog.commentsCount + ' comments'}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default BlogLayoutVertical;
