import { getConfig } from './config';

export const scrollToTargetAdjusted = (targetId) => {
    const config = getConfig();
    const element = document.getElementById(targetId);
    // console.log('element', element);
    if (!element) return;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition =
        elementPosition - config?.scrollToTargetAdjustedValue || 80; //set this dynamically
    // console.log(elementPosition, offsetPosition, config?.scrollToTargetAdjustedValue, 'xyz');
    window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth'
    });
};
