import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { getConfig } from '../../../utils/config';

import { scrollToTargetAdjusted } from '../../../utils/ui';

function NavItem({ label, show, pathname, scrollTo, customUrl, showNavBar }) {
    const location = useLocation();
    const config = getConfig();

    return (
        show && (
            <NavLink
                className={
                    `px-6 py-2 2xl:px-2 2xl:py-2 lg:pb-2 lg:border-none lg:px-0 block lg:inline-block transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                    (showNavBar
                        ? `${
                              config?.navBarConfig.stickyNavBar.itemClass ||
                              'text-secondary-focus text-[10px] xl:text-base 2xl:text-[15px] lg:mr-12 xl:mr-14'
                          }`
                        : ` ${
                              config.navBarConfig.baseNavBar.itemClass ||
                              'text-primary lg:text-[10px] xl:text-[13px] 2xl:text-[13px]'
                          }`)
                }
                activeClassName={
                    config.navBarConfig.showActiveLinkColor
                        ? 'text-secondary-focus'
                        : ''
                }
                to={pathname}
                onClick={(e) => {
                    if (customUrl) {
                        e.preventDefault();
                        window.open(customUrl);
                    }
                    if (scrollTo) {
                        if (location.pathname !== pathname) {
                            setTimeout(() => {
                                scrollToTargetAdjusted(scrollTo);
                            }, 500);
                        } else {
                            e.preventDefault();
                            scrollToTargetAdjusted(scrollTo);
                        }
                    }
                }}
            >
                {label}
            </NavLink>
        )
    );
}

export default NavItem;
