import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EmailShareButton, WhatsappShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import Modal from "../../../../../Components/Organisms/Modal";
import { getConfig } from "../../../../../utils/config";
import { copyCodeHandler } from '../../../../../helpers';
import SiWhatsapp from '@meronex/icons/si/SiWhatsapp';
import EiCloseO from '@meronex/icons/ei/EiCloseO';
import FaFacebookF from '@meronex/icons/fa/FaFacebookF';
import useData from '../../../Molecules/Content/data';
import FiCopy from '@meronex/icons/fi/FiCopy.js';
import MdcEmailOutline from '@meronex/icons/mdc/MdcEmailOutline';
import {
    DetailProjectLoadingUI,
    LoadingSpinner
} from '../../../../../Components/Molecules/LoadingUI';

const VRGallery = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [VRLink, setVRLink] = useState(null);
    const [copiedUrl, setCopiedUrl] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [shareLink, setShareLink] = useState('');
    const [clickedVrItem, setClickedVrItem] = useState(null);
    const config = getConfig();
    const shareMessage = "Transform your vision into reality with your dream home: ";
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get('projectId');
    const {
        projectDetails,
        VrGallery,
    } = useData(projectId);
    const [loading, setLoading] = useState(false);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [vrLinks, setVrLinks] = useState([]);
    useEffect(() => {
        const links = VrGallery.map((vrItem) => vrItem.url);
        setVrLinks(links);
    }, [VrGallery]);

    const userId = useSelector((state) => state.auth.userHash);
    let link = `${window.location.href.split('?')[0]}?projectId=${projectId}&userId=${userId}`;

    const userHash = queryParams.get('userId');
    const openVRView = (link) => {
    setShowModal(false); // Close any open modal
    setShowShareModal(false); // Close share modal if open
    setVRLink(link);
    setShowModal(true);
    };

    const handleCopyCode = async (VRLink) => {
        await copyCodeHandler(VRLink , setCopiedUrl);
        
        // if ('clipboard' in navigator) {
        //     await navigator.clipboard.writeText(VRLink);
        //     setCopiedUrl(true);
        // }
    };

    // useEffect(() => {
    //     if (copiedUrl) {
    //         const timer = setTimeout(() => {
    //             setCopiedUrl(false);
    //         }, 2000); 
    //         return () => clearTimeout(timer);
    //     }
    // }, [copiedUrl]);

    useEffect(() => {
        if (userHash) {
            const url = `https://api.in.webengage.com/v1/accounts/${config?.webengageLic}/events`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${config?.webengageAPIKey}`
                },
                body: JSON.stringify({
                    userId: userHash,
                    eventName: 'InfluencerLinkViewed',
                    eventData: {
                        ProjectName: projectDetails?.projectName
                    }
                })
            };

            fetch(url, requestOptions)
                .then((res) => {
                    // console.log(res);
                })
                .catch((err) => {
                    // console.log(err);
                });
        }
    }, [userHash]);

    const handleShareClick = (obj) => {
        setClickedVrItem(obj);
        setShowShareModal(true);
        setCopiedUrl(false);  
        setShareLink(obj.url);  
    };

    return (
        <>
            <div className="mt-5">
                <div className="flex flex-col">
                    <h4 className={`z-10 text-xl mt-2 text-[#130E2F] font-bold ${config.fontTitle}`}>
                        AR/VR
                    </h4>
                </div>
                <div className={` ${config.fontBody} grid grid-cols-1 md:grid-cols-2 gap-4 mt-4`}>
                    {VrGallery?.map((obj) => (
                        <div
                            key={obj?.url}
                            className="border-2 flex flex-col hover:border-primary cursor-pointer rounded-lg p-2 lg:p-5"
                        >
                            <div className="flex flex-col h-full justify-start">
                                <div className="relative" onClick={() => { setClickedVrItem(obj); openVRView(obj?.url); }} >
                                    <img className="rounded h-40 lg:h-28 xl:h-56 w-full" src={obj?.thumbnailUrl} />
                                    <button className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#000000B3] mt-2 text-white text-center px-4 py-2">
                                        TAP TO EXPERIENCE
                                    </button>
                                </div>
                                <h1 className="font-semibold mt-2">{obj?.caption}</h1>
                                <p className="text-sm">{obj?.tagLine}</p>
                                <div className="flex justify-end mt-auto"> 
                                <button
                                    className="bg-white rounded-full p-2"
                                    onClick={() => handleShareClick(obj)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18.277"
                                        height="16.312"
                                        viewBox="0 0 18.277 16.312"
                                        className="w-5 h-5"
                                    >
                                        <path
                                            id="_10131836_share_line_icon"
                                            data-name="10131836_share_line_icon"
                                            d="M20,12,13.6,5V8.5C10.4,8.5,4,10.6,4,19c0-1.167,1.92-3.5,9.6-3.5V19Z"
                                            transform="translate(0.166 -4.42) rotate(9)"
                                            fill="none"
                                            stroke="#16519e"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                    </svg>
                                </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showModal && (
                <Modal
                    visible={showModal}
                    onClick={() => {
                        setVRLink(null);
                        setShowModal(false);
                    }}
                    className="w-full lg:w-[95%] h-full lg:h-[90%] m-auto"
                    showCloseButton
                    showButton={false}
                >
                    <iframe allowFullScreen width="100%" height="100%" src={VRLink} />
                </Modal>
            )}

            {showShareModal &&  clickedVrItem && (
                <Modal
                visible={showShareModal}
                showButton
                showCloseButton={false}
                onClick={() => {
                    setVRLink(null);
                    setClickedVrItem(null);  
                    setShowShareModal(false); 
                }}
                
            >
                    <div className="flex flex-col px-5">
                        {loading && <LoadingSpinner />}
                        {!loading && (
                            <>
                                <div className="flex justify-between items-center my-4">
                                    <h1 className="text-lg sm:text-xl font-bold">
                                        Share AR/VR
                                    </h1>
                                    <EiCloseO
                                        onClick={() => setShowShareModal(false)}
                                        className={`h-7 w-7 sm:h-9 sm:w-10 cursor-pointer text-gray-500`}
                                    />
                                </div>

                                <svg viewBox="0 0 457 2">
                                    <defs></defs>
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g
                                            id="Layer_1-2"
                                            data-name="Layer 1"
                                        >
                                            <polygon
                                                className="cls-1"
                                                points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1"
                                                fill="#e5e5e5"
                                            />
                                        </g>
                                    </g>
                                </svg>

                                <div className="flex gap-5 items-center my-5">
                                    <img
                                        variant="square"
                                        loading="lazy"
                                        className={`${
                                            config?.projects.moduleConfig?.QRCodeImage
                                                ? 'h-10 w-10'
                                                : 'h-20 w-28'
                                        } z-10 object-center object-cover rounded-lg`}
                                        src={clickedVrItem?.thumbnailUrl}
                                        alt="property"
                                    />
                                    <div className="flex flex-col">
                                        <p className="text-black font-semibold text-base sm:text-lg">
                                            {clickedVrItem?.caption}
                                        </p>
                                        <p className="text-[#908F95] text-sm sm:text-base italic">
                                            {clickedVrItem?.tagLine}
                                        </p>
                                    </div>
                                </div>

                                <svg viewBox="0 0 457 2">
                                    <defs></defs>
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g
                                            id="Layer_1-2"
                                            data-name="Layer 1"
                                        >
                                            <polygon
                                                className="cls-1"
                                                points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1"
                                                fill="#e5e5e5"
                                            />
                                        </g>
                                    </g>
                                </svg>

                                <div className="flex flex-wrap justify-between items-center mt-5">
                                    <div className={`w-[90%] lg:w-[45%] md:w-[45%] cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}>
                                        <button
                                            title={
                                                copiedUrl
                                                    ? 'Link copied'
                                                    : 'Click to copy'
                                            }
                                            className=""
                                            onClick={() => handleCopyCode(shareLink)}
                                        >
                                            {!copiedUrl && (
                                                <div className="flex items-center gap-3">
                                                    <FiCopy className="cursor-pointer w-5 h-full" />
                                                    <p className="font-bold text-sm sm:text-base">
                                                        Link Copy
                                                    </p>
                                                </div>
                                            )}
                                            {copiedUrl && (
                                                <p className="mx-auto px-6 self-center rounded-lg text-center font-normal animate-pulse bg-white text-xs sm:text-sm text-[#006EB7]">
                                                    Link Copied
                                                </p>
                                            )}
                                        </button>
                                    </div>

                                    <div className={`w-[90%] lg:w-[45%] md:w-[45%] cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}>
                                        <EmailShareButton
                                            url={shareLink}
                                            title={shareMessage}
                                        >
                                            <div className="flex items-center gap-3">
                                                <MdcEmailOutline className="w-5 h-full" />
                                                <p className="font-bold text-sm sm:text-base">
                                                    Email
                                                </p>
                                            </div>
                                        </EmailShareButton>
                                    </div>

                                    <div className={`w-[90%] lg:w-[45%] md:w-[45%] cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}>
                                        <WhatsappShareButton
                                            url={shareLink}
                                            title={shareMessage}
                                        >
                                            <div className="flex items-center gap-3">
                                                <SiWhatsapp className="w-5 h-full" />
                                                <p className="font-bold text-sm sm:text-base">
                                                    WhatsApp
                                                </p>
                                            </div>
                                        </WhatsappShareButton>
                                    </div>

                                    <div className={`w-[90%] lg:w-[45%] md:w-[45%] cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}>
                                        <FacebookShareButton
                                            url={shareLink}
                                            title={shareMessage}
                                        >
                                            <div className="flex items-center gap-3">
                                                <FaFacebookF className="w-5 h-full" />
                                                <p className="font-bold text-sm sm:text-base">
                                                    Facebook
                                                </p>
                                            </div>
                                        </FacebookShareButton>
                                    </div>

                                    <div className={`w-[90%] lg:w-[45%] md:w-[45%] cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}>
                                        <TwitterShareButton
                                            url={shareLink}
                                            title={shareMessage}
                                        >
                                            <div className="flex items-center gap-3">
                                                <svg
                                                    size={20}
                                                    className="w-5 text-black"
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    strokeWidth="0"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M8 2H1L9.26086 13.0145L1.44995 21.9999H4.09998L10.4883 14.651L16 22H23L14.3917 10.5223L21.8001 2H19.1501L13.1643 8.88578L8 2ZM17 20L5 4H7L19 20H17Z"></path>
                                                </svg>
                                                <button className="font-bold text-sm sm:text-base">
                                                    Twitter
                                                </button>
                                            </div>
                                        </TwitterShareButton>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
};

export default VRGallery;