var aesEcb = require('aes-ecb');

export const decryptData = async (data) => {
    let decrypt;
    try {
        const keyString = '/:0?M#957faC%%nD';
        const Data = new Uint8Array(
            atob(data)
                .split('')
                .map((char) => char.charCodeAt(0))
        );
        const keyBytes = new TextEncoder().encode(keyString);
        const iv = Data.slice(0, 12);
        const encrypted = Data.slice(12);
        const algorithm = { name: 'AES-GCM', tagLength: 128, iv };
        const cryptoKey = await window.crypto.subtle.importKey(
            'raw',
            keyBytes,
            'AES-GCM',
            false,
            ['decrypt']
        );
        const decrypted = await window.crypto.subtle.decrypt(
            algorithm,
            cryptoKey,
            encrypted
        );
        const plaintext = new TextDecoder().decode(decrypted);
        decrypt = plaintext;
    } catch (err) {
        console.log(err, 'gcm');
    }
    if (!decrypt) {
        try {
            // console.log('trying aes')
            const keyString = '72e?5ve#7fa4*exi';
            decrypt = await aesEcb.decrypt(keyString, data);
            // console.log(decrypt)
        } catch (err) {
            console.log(err, 'aes');
        }
    }
    return decrypt;
};
