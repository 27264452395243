import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';

const HeoConfig = {
    clientId:
        '1ae3e252ad3b470f185954ae0f5c4375f7b26a6751da701445648b5bfe638d3c',
    id: 44,
    name: 'HDFC Capital',
    fullName: 'HDFC Capital',
    programName:'HEO',
    theme: 'hdfcTheme',
    favicon: 'https://cdn.loyalie.in/25122162.png',
    primaryColor: '#703E1D',
    fontBody: `${getFont('HDFC').body}`,
    fontTitle: `${getFont('HDFC').heading}`,
    googleConfig: {
        gID: '',
        keywordsContent:
            'HDFC Capital real estate referral programs, HDFC real estate referral program, HDFC real estate refer and earn, HDFC Capital real estate project refer, HDFC real estate, HDFC Capital real estate loyalty program, HDFC real estate news, HDFC real estate upcoming projects in Bengaluru, HDFC real estate launch HDFC Capital real estate, HDFC real estate launch, HDFC real estate program, HDFC real estate projects, HDFC real estate blogs, HDFC real estate offers, HDFC real estate upcoming projects in Bengaluru, HDFC real estate events, HDFC, HDFC Capital, HDFC real estate best residential projects, HDFC real estate real estate developers, All New Projects by HDFC real estate Builders & Developers, Ongoing Projects by HDFC real estate Pvt Ltd, HDFC real estate Bengaluru, HDFC real estate new program, HDFC press release, HDFC upcoming projects, HDFC property group, HDFC bangalore, HDFC real estate, HDFC reit, jitu virwani, HDFC commercial projects, HDFC projects, HDFC projects in bangalore',
        descriptionContent:
            'Br is an exclusive referral and loyalty program designed for HDFC residents. The program aims to make you the brand ambassador of the HDFC lifestyle by adding more value to your home ownership and creating delight.',
        authorContent: 'HDFC Capital'
    },

    facebookPixelConfig: {
        id: '', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~76aa293',
    webengageAPIKey:'8ad687ea-2e58-4bb3-8fb4-a0df97c832fa',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: true,
    logoLeft: {
        name: 'HDFC Logo',
        baseUrl: 'https://cdn.loyalie.in/5702879.png',
        stickyUrl: 'https://cdn.loyalie.in/5702879.png',
        stickyNavBarLogoClass:
            'relative h-8 lg:h-9 xl:h-10 2xl:h-12 left-6 lg:left-12 ease-in duration-300',
        baseNavBarLogoClass:
            ' relative h-8 lg:h-9 xl:h-12 2xl:h-14 left-6 lg:left-12 ease-out duration-300'
    },
    navBarConfig: {
        dropDown: true,
        font: `${getFont('HDFC').heading}`,
        height: 'h-16 lg:h-14 xl:h-16 ease-out duration-300',
        stickyHeight: 'h-16 lg:h-14 xl:h-16 ease-in duration-300 	',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-white',
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: ' ', // default: transparent
            containerClass: ' lg:mr-5',
            itemClass: `uppercase lg:mr-7 text-primary-focus lg:text-primary-focus hover:text-primary-focus  text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-medium tracking-wide ${
                getFont('HDFC').secondary
            }`,
            dropDownContainerClass: `text-primary-focus lg:text-primary-focus hover:text-primary-focus  focus:text-primary-focus lg:focus:shadow-outline tracking-wide ${
                getFont('HDFC').secondary
            }`,
            dropDownItemClass: `tracking-widest hover:text-primary text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-medium hover:bg-[#f4f4f4] tracking-wide ${
                getFont('HDFC').secondary
            }`
        },
        stickyNavBar: {
            border: true,
            borderClass: ' ',
            backgroundColor: 'bg-white lg:drop-shadow-md ', //default: primary-content,
            containerClass: 'lg:mr-5 ', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass: `uppercase lg:mr-7 text-primary-focus lg:text-primary-focus hover:text-primary-focus text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px] font-medium tracking-wide ${
                getFont('HDFC').secondary
            }` // Each list element in NavBar (Text color, hover color, size)
        },
        showActiveLinkColor: false
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: 'lg:-mt-14 xl:-mt-16', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-secondary-focus rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 ${
        getFont('HDFC').bold
    }`,

    loginModal: {
        showEmployeeLogin: false,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage: 'https://cdn.loyalie.in/74614114.jpg',
                    backgroundImageMobile: 'https://cdn.loyalie.in/3384315.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    bannerContainerClass:
                        'h-[70vh] md:h-[85vh] lg:h-[99vh] lg:bg-left xl:bg-center ',
                    bannerItemContainerClass:
                        'items-start lg:justify-center pt-10 lg:pt-0 2xl:pt-20  px-6 md:pl-10 lg:pl-12',
                    position: 'text-left',

                    components: [
                        {
                            type: 'TEXT',
                            content: 'A SYMPHONY OF LUXURY:',
                            className: `text-secondary-content xl:pt-2 2xl:pt-3 text-[20px] md:text-[24px] xl:text-[27px] tracking-widest leading-6 lg:leading-6 xl:leading-8  font-medium  ${
                                getFont('HDFC').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'UNLEASHING A VIBRANT LIFESTYLE!',
                            className: `text-secondary-content text-[20px] md:text-[24px] xl:text-[27px] tracking-widest leading-6 lg:leading-7 xl:leading-8  font-medium  ${
                                getFont('HDFC').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'INTRODUCING',
                            className: `text-secondary-focus pt-4 md:pt-5 md:text-[16px] xl:text-[19px] tracking-wider leading-5 xl:leading-6 font-normal ${
                                getFont('HDFC').body
                            }`
                        },
                        {
                            type: 'IMAGE',
                            url: 'https://cdn.loyalie.in/84260409.png',
                            position: 'center',
                            classNameContainer:
                                'w-[50%] md:w-[30%] lg:w-[25%] xl:w-[22%] 2xl:w-[23%] pt-2 ml-5 md:ml-10'
                        },
                        {
                            type: 'TEXT',
                            content: 'An exclusive loyalty & referral program',
                            className: `text-secondary-focus md:pt-2 lg:pt-3 text-base md:text-[16px] xl:text-[21px] leading-5 xl:leading-6 font-medium w-[60%] md:w-full ${
                                getFont('HDFC').body
                            }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'EXPLORE NOW',
                            className: ` mt-4 xl:mt-6 text-sm xl:text-[17px] uppercase tracking-wider text-secondary-focus font-normal py-2 px-4 border-2 md:border-[3px]  border-accent rounded-lg drop-shadow-lg ${
                                getFont('HDFC').body
                            }`,
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    className: 'w-full bg-[#EFEFEF] pt-5 lg:pt-10 pb-6',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'EMPOWERING COMMUNITIES TO BUILD A BRIGHT FUTURE',
                            className: ` text-secondary-focus uppercase pt-6 text-[20px] lg:text-[22px] xl:text-[25px] tracking-wide leading-7 2xl:leading-8 font-normal px-6 md:px-12  ${
                                getFont('HDFC').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                'w-[35%] lg:w-[15%] bg-primary border-none h-[2px]',
                            classNameContainer: ' pt-4 '
                        },
                        {
                            type: 'CUSTOM',
                            id: 'overview',
                            className:
                                'overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-3 gap-2 lg:gap-10 2xl:gap-16 py-7 lg:py-12 lg:w-full px-6 lg:px-12 h-auto',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        ' items-center min-w-[80%] md:min-w-[50%] lg:w-full ',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-full',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/40264697.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-full h-auto'
                                                }
                                            ]
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full lg:px-8 xl:px-14 p-6 lg:py-10 xl:py-16 bg-white relative',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content: `Enjoy membership benefits from top brands`,
                                                    className: `cursor-pointer text-[17px] xl:text-[20px] 2xl:text-[22px] text-secondary-focus leading-5 xl:leading-7 2xl:leading-8 break-words w-full xl:w-[90%] ${
                                                        getFont('HDFC').body
                                                    }`,
                                                    path: 'gift-and-earn',
                                                    onClick: 'CHANGE_PATH'
                                                },
                                                {
                                                    type: 'BUTTON',
                                                    content: 'AVAIL NOW',
                                                    className: ` mt-4 lg:mt-6 text-sm xl:text-[17px] uppercase tracking-wider text-secondary-focus font-normal py-2 px-4 border-2 md:border-[3px]  border-accent rounded-lg drop-shadow-lg ${
                                                        getFont('HDFC').body
                                                    }`,
                                                    path: 'deals',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/67897644.png',
                                            position: 'center',
                                            classNameContainer: 'w-full -mt-8'
                                        }
                                    ]
                                },

                                {
                                    type: 'CUSTOM',
                                    className:
                                        ' items-center min-w-[80%] md:min-w-[50%] lg:w-full',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-full',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/39455305.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-full h-auto'
                                                }
                                            ]
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full lg:px-8 xl:px-14 p-6 lg:py-10 xl:py-16 bg-white relative',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content: `Experience specially curated events and workshops`,
                                                    className: `cursor-pointer text-[17px] xl:text-[20px] 2xl:text-[22px] text-secondary-focus leading-5 xl:leading-7 2xl:leading-8 break-words w-full xl:w-[90%] ${
                                                        getFont('HDFC').body
                                                    }`,
                                                    path: 'gift-and-earn',
                                                    onClick: 'CHANGE_PATH'
                                                },
                                                {
                                                    type: 'BUTTON',
                                                    content: 'KNOW MORE',
                                                    className: ` mt-4 lg:mt-6 text-sm xl:text-[17px] uppercase tracking-wider text-secondary-focus font-normal py-2 px-4 border-2 md:border-[3px]  border-accent rounded-lg drop-shadow-lg ${
                                                        getFont('HDFC').body
                                                    }`,
                                                    path: 'events',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/67897644.png',
                                            position: 'center',
                                            classNameContainer: 'w-full -mt-8'
                                        }
                                    ]
                                },

                                {
                                    type: 'CUSTOM',
                                    className:
                                        ' items-center min-w-[80%] md:min-w-[50%] lg:w-full',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className: 'w-full',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/11111571.jpg',
                                                    position: 'center',
                                                    classNameContainer:
                                                        'w-full h-auto'
                                                }
                                            ]
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'w-full lg:px-8 xl:px-14 p-6 lg:py-10 xl:py-16 bg-white relative',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content: `Explore our home & lifestyle guide`,
                                                    className: `cursor-pointer text-[17px] xl:text-[20px] 2xl:text-[22px] text-secondary-focus leading-5 xl:leading-7 2xl:leading-8 break-words w-full xl:w-[80%] ${
                                                        getFont('HDFC').body
                                                    }`,
                                                    path: 'gift-and-earn',
                                                    onClick: 'CHANGE_PATH'
                                                },
                                                {
                                                    type: 'BUTTON',
                                                    content: 'READ NOW',
                                                    className: ` mt-4 lg:mt-6 text-sm xl:text-[17px] uppercase tracking-wider text-secondary-focus font-normal py-2 px-4 border-2 md:border-[3px]  border-accent rounded-lg drop-shadow-lg ${
                                                        getFont('HDFC').body
                                                    }`,
                                                    path: 'blogs',
                                                    onClick: 'CHANGE_PATH'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/67897644.png',
                                            position: 'center',
                                            classNameContainer: 'w-full -mt-8'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    className:
                        'w-full flex flex-col lg:flex-row items-center bg-[#F7F6F5]',
                    components: [
                        {
                            type: 'CUSTOM',
                            className: 'lg:w-[50%]',
                            components: [
                                {
                                    type: 'IMAGE',
                                    url: 'https://cdn.loyalie.in/75309833.jpg',
                                    position: 'center',
                                    classNameContainer: 'w-full h-auto'
                                }
                            ]
                        },

                        {
                            type: 'CUSTOM',
                            className: 'lg:w-[50%] md:py-10 lg:py-0',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'ABOUT US',
                                    className: `px-6 text-secondary-focus uppercase pt-6 text-[20px] md:text-[25px] xl:text-[35px] tracking-wide leading-7 2xl:leading-8 font-normal md:px-10 xl:px-24  ${
                                        getFont('HDFC').heading
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'w-[30%] bg-primary border-none h-[2px]',
                                    classNameContainer: ' pt-4 lg:pb-12 '
                                },
                                {
                                    type: 'TEXT',
                                    content: `HDFC Capital Advisors Limited is a leading real estate private equity investment manager established in 2015 as a subsidiary of HDFC Ltd. We are dedicated to bridging the supply gap in affordable housing in India.`,
                                    className: `xl:pt-7 px-6 cursor-pointer text-[17px] md:text-[18px] lg:text-[17px] xl:text-[18px] 2xl:text-[20px] text-secondary-focus leading-5 xl:leading-7 break-words md:px-10 xl:px-24 pt-6 lg:pt-0 pb-4 ${
                                        getFont('HDFC').body
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: `We deeply value your role in our community and want to express our heartfelt appreciation by providing exclusive benefits to enrich your experience.`,
                                    className: `pb-7 px-6 cursor-pointer text-[17px] md:text-[18px] lg:text-[17px] xl:text-[18px] 2xl:text-[20px] text-secondary-focus leading-5 xl:leading-7 break-words md:px-10 xl:px-24 ${
                                        getFont('HDFC').body
                                    }`
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    className:
                        'w-full pt-10 lg:pt-14 pb-12 lg:pb-16 bg-[#EFEFEF]',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'EXCLUSIVE MEMBERSHIP BENEFITS',
                            className: `px-6 text-secondary-focus text-[20px] md:text-[22px] xl:text-[25px] tracking-wide leading-7 2xl:leading-8 font-normal md:px-10 xl:px-12  ${
                                getFont('HDFC').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                'w-[35%] lg:w-[15%] bg-primary border-none h-[2px]',
                            classNameContainer: ' pt-4 '
                        },
                        {
                            type: 'CAROUSEL',
                            getVendorLogos: true,
                            offerCards: true,
                            getVendorData: true,
                            classNameCTA: `bg-transparents text-[14px] px-5 py-2 text-secondary-focus text-center mt-3 mx-auto uppercase font-semibold border-[2px] border-[#92b4a7] ${
                                getFont('SUSHMA').body
                            }`,
                            ContainerClassName:
                                'bg-cover bg-[#f4f4f4] rounded-lg h-auto ',
                            caraouselClassName: `drop-shadow-lg w-[70%] md:w-[95%] xl:w-[86%] 2xl:w-[95%] 3xl:w-[80%] mx-auto lg:px-12 pt-16 relative  ${
                                getFont('HDFC').body
                            }`,

                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 4,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow
                        },
                        {
                            type: 'BUTTON',
                            content: 'SIGN UP NOW',
                            className: `mt-12 flex justify-center mx-auto text-sm xl:text-[17px] tracking-wider text-secondary-focus font-normal py-2 px-4 border-2 md:border-[3px]  border-accent rounded-lg drop-shadow-lg ${
                                getFont('HDFC').body
                            }`,
                            path: 'deals',
                            onClick: 'CHANGE_PATH'
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    className: 'w-full  mx-auto relative',
                    components: [
                        {
                            type: 'CUSTOM',
                            className: 'w-full  bg-[#F7F6F5] lg:py-16',
                            components: [
                                {
                                    type: 'BLOG_LAYOUT_SLIDER',
                                    perPage: 1,
                                    classNameContainer:
                                        'grid grid-cols-1 lg:grid-cols-2 items-center',
                                    dividerClass:
                                        'h-[2px] w-[27%] border-none bg-primary',
                                    arrowClass:
                                        'absolute bottom-2 lg:bottom-14 right-0 lg:right-[37.5vw] z-10',
                                    prevNextArrow:
                                        'bg-transparent opacity-0.7 ml-3 p-3',
                                    headingClass: `text-[20px] lg:text-[22px] xl:text-[25px] capitalize text-secondary cursor-pointer tracking-wide leading-7 2xl:leading-8 font-normal  pt-8 ${
                                        getFont('HDFC').heading
                                    }`,
                                    summaryClass: `text-[17px] xl:text-[18px] 2xl:text-[20px] text-secondary tracking-wide leading-6 2xl:leading-6 font-normal  pt-5 w-[80%] lg:w-[90%] ${
                                        getFont('HDFC').body
                                    }`,
                                    tagClass: `uppercase text-[20px] lg:text-[24px] xl:text-[27px] text-secondary tracking-wide leading-7 2xl:leading-8 font-normal  pb-5 pt-8 lg:pt-0 ${
                                        getFont('HDFC').heading
                                    }`,
                                    buttonClass: ` text-left  text-sm md:text-[17px] uppercase tracking-wider bg-transparent text-secondary font-normal py-2 md:py-2 2xl:py-2 px-2 md:px-6 border-[3px]  border-accent rounded-lg drop-shadow-lg mx-6 lg:mx-28 mt-4 lg:mt-8 mb-12 ${
                                        getFont('HDFC').body
                                    }`
                                }
                            ]
                        },

                        {
                            type: 'CUSTOM',
                            className: 'w-full bg-[#EFEFEF]  pt-10 pb-6',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'PROJECTS',
                                    className: `px-6 text-secondary-focus text-[20px] lg:text-[22px] xl:text-[25px] tracking-wide leading-7 2xl:leading-8 font-normal md:px-10 xl:px-12   ${
                                        getFont('HDFC').heading
                                    }`
                                },

                                {
                                    type: 'DIVIDER',
                                    className:
                                        'w-[35%] lg:w-[15%] bg-primary border-none h-[2px]',
                                    classNameContainer: ' pt-4 '
                                },

                                {
                                    type: 'CUSTOM',
                                    className: 'w-[80%] lg:w-[90%] mx-auto',
                                    components: [
                                        {
                                            type: 'CAROUSEL',
                                            customSlide: true,
                                            caraouselClassName:
                                                ' md:py-10 py-1 relative py-16 lg:py-5',
                                            imageContainerClassName:
                                                'm-4 relative',
                                            imageClassName:
                                                'w-full h-40 lg:h-full lg:bg-cover object-cover object-center',
                                            captionClass: ` uppercase px-4 text-[16px] ${
                                                getFont('HDFC').body
                                            }`,
                                            arrows: true,
                                            autoplay: true,
                                            autoplaySpeed: 3000,
                                            infinite: true,
                                            dots: false,
                                            slidesToShow: 4,
                                            pauseOnHover: true,
                                            speed: 3000,
                                            onImageClick: openURLNewWindow,
                                            imgArr: [
                                                {
                                                    imgUrl: 'https://images.prismic.io/bramhacorp/20d788a4-3477-4fc5-9d93-e0d974a44cd9_AUGUST%20TOWERS_Desktop%20Banner%20Size%20with%20logo%203110%20x%201570.jpg',
                                                    alt: 'img',
                                                    url: ``,
                                                    caption: 'Project 1'
                                                },
                                                {
                                                    imgUrl: 'https://images.prismic.io/bramhacorp/cdac64ea-d369-4931-8590-eef887753b0a_TC_Project%20Main%20Page%20-%20Desktop.jpg',
                                                    alt: 'img',
                                                    url: ``,
                                                    caption: 'Project 2'
                                                },
                                                {
                                                    imgUrl: 'https://images.prismic.io/bramhacorp/cabc7ab2-153e-4609-8ae2-66e2ad892ec6_Townhouse_Desktop%20Banner%20Size%20with%20logo%203110%20x%201570.jpg',
                                                    alt: 'img',
                                                    url: ``,

                                                    caption: 'Project 3'
                                                },
                                                {
                                                    imgUrl: 'https://images.prismic.io/bramhacorp/20d788a4-3477-4fc5-9d93-e0d974a44cd9_AUGUST%20TOWERS_Desktop%20Banner%20Size%20with%20logo%203110%20x%201570.jpg',
                                                    alt: 'img',
                                                    url: ``,
                                                    caption: 'Project 4'
                                                },
                                                {
                                                    imgUrl: 'https://images.prismic.io/bramhacorp/cdac64ea-d369-4931-8590-eef887753b0a_TC_Project%20Main%20Page%20-%20Desktop.jpg',
                                                    alt: 'img',
                                                    url: ``,
                                                    caption: 'Project 5'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        referPage: {
            show: true,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: false,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                },
                {
                    deals: {
                        show: false,
                        name: 'BENEFITS',
                        path: '/deals'
                    }
                }
            ]
        },

        events: {
            show: false,
            name: 'EVENTS',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },

    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Hdfc-Heo/Images/21949402.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Hdfc-Heo/Images/21949402.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center lg:text-left text-primary text-[18px] md:text-[20px] xl:text-[22px] uppercase my-5 tracking-widest md:w-[55%] lg:w-[45%] xl:w-[50%] ml-auto leading-6 lg:leading-7  ${
                                getFont('HDFC').body
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                ' md:w-[55%] lg:w-[45%] xl:w-[50%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `flex py-[5.5px] 2xl:py-[10px] px-4 lg:px-8 self-center text-secondary-focus rounded border-[3px] border-accent uppercase mx-auto lg:mx-0 ${
                                        getFont('HDFC').heading
                                    } `
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Hdfc-Heo/Images/21949402.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Hdfc-Heo/Images/21949402.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[70vh] md:h-[70vh] object-cover bg-left',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  my-5 tracking-widest  lg:w-[80%] mx-auto  ${
                    //         getFont('BRAMHA').body
                    //     }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[45px] font-bold  pb-5  tracking-widest lg:w-[50%] mx-auto  ${
                    //         getFont('BRAMHA').heading
                    //     }`
                    // },
                    // {
                    //     type: 'BUTTON',
                    //     content: 'Start Referring Now',
                    //     className: `text-md font-medium BRAMHA-button hover:BRAMHA-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 lg:w-[20%] mx-auto ${
                    //         getFont('BRAMHA').heading
                    //     }`,
                    //     scrollTo: 'referral_section',
                    //     onClick: 'SCROLL'
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold bg-primary text-white ${
                getFont('HDFC').heading
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hero hero-content max-w-full h-[70vh] md:h-[90vh] bg-top mx-auto text-center ',
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage: 'https://cdn.loyalie.in/65354426.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/89376275.jpg',
            components: [
                {
                    type: 'CUSTOM',
                    className:
                        'w-full lg:w-[50%] xl:w-[60%] 2xl:w-[70%] mx-auto mt-20'
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('HDFC').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('HDFC').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-[#1c2936] ${
                    getFont('HDFC').heading
                }`,
                headingClass: `text-[25px] md:text-[30px] uppercase font-semibold text-primary mt-3 leading-7 md:leading-9 break-words ${
                    getFont('HDFC').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('HDFC').heading
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('HDFC').heading
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('HDFC').heading
                }`
            },
            sectionHeadings: ` text-secondary-content uppercase pt-10 text-center ext-[20px] lg:text-[22px] xl:text-[25px] 2xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                getFont('HDFC').heading
            }`,
            dividerClass: 'w-[10%] mx-auto bg-primary border-none h-[3px] my-4'
        },
        layout5: {
            cardHeight:
                'h-[470px] md:h-[470px] lg:h-[500px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[230px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 md:h-[45px] lg:h-[70px] text-[19px] 3xl:text-[20px] font-bold uppercase leading-6 break-words mx-5  ${
                getFont('HDFC').heading
            }`,
            categoryClass: `bg-secondary-content bg-opacity-[0.65] text-white text-[11px] ${
                getFont('HDFC').body
            }`,
            summaryClass: `font-normal text-[16.5px] xl:text-[17px] mt-2 leading-6 text-secondary-focus mx-5 md:h-[50px] lg:h-[70px] ${
                getFont('HDFC').body
            }`,
            buttonClass: `bg-transparent hover:border-primary flex justify-center self-end float-right text-[13px] xl:text-[16px] text-primary py-3 px-4 md:px-4 mt-5 font-medium ${
                getFont('HDFC').secondary
            }`,
            buttonArrowUrl: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.16 11.59'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%2392b4a7;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cline class='cls-1' y1='5.62' x2='55' y2='5.62'/%3E%3Cpolyline class='cls-1' points='48.5 0.39 51.93 3.09 55.35 5.79 51.93 8.49 48.5 11.19'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-[#919396] ${
                getFont('HDFC').heading
            }`,
            noBlogsClass: `bg-secondary p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('HDFC').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/22584268.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/22584268.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0'
            },

            negativeMargin: '',
            containerClass: ' text-secondary-focus w-[50%] ',
            heading: '',
            headingClass: ` text-secondary-content uppercase lg:pl-10  text-[22px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wide font-semibold ${
                getFont('HDFC').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('HDFC').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('HDFC').secondary
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('HDFC').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `uppercase md:mt-6 text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-secondary-focus rounded-md py-2 md:py-2 2xl:py-[7px] px-10 md:px-5 ${
                        getFont('HDFC').bold
                    }`
                }
            ]
        },

        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: ` text-secondary-content uppercase mt-16 text-center ext-[20px] lg:text-[22px] xl:text-[25px] 2xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal ${
                getFont('HDFC').heading
            }`,
            dividerClass: 'w-[10%] mx-auto bg-primary border-none h-[3px] my-4',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-2 text-black w-[60%] ${
                getFont('HDFC').heading
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('HDFC').heading
            }`
        },
        eventLayout: {
            titleClass: `text-primary leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('HDFC').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ' text-[15px]'
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-primary font-semibold text-lg my-4 z-10 ${
            getFont('HDFC').heading
        }`,
        noGalleryClass: ` text-primary ${getFont('HDFC').heading}`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                ' hidden md:hero hero-content items-start md:items-center max-w-full h-[30vh] lg:h-[45vh]bg-center bg-cover', // //bg-top
            linearGradient: {
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692635625636_offer%20godrej',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692635625636_offer%20godrej',
            components: [],
            loginBannerComponents: []
        },

        dealsSection: {
            additionalTextClass: `tracking-wide text-2xl xl:text-3xl leading-7 text-secondary-content uppercase text-left hover:border-b-accent ${
                getFont('HDFC').heading
            } `,
            subAdditionalTextClass: ` leading-5 xl:leading-6  break-words ${
                getFont('HDFC').heading
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('HDFC').heading}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/4685974.png',
            pattern2: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '1',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            listTitleClass: `text-2xl lg:text-[18px] leading-6 lg:leading-5 text-primary ${
                getFont('HDFC').heading
            }`,
            listBodyClass: `${getFont('HDFC').heading}`,
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` text-2xl xl:text-3xl text-primary uppercase leading-8 xl:leading-9  ${
                getFont('HDFC').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('HDFC').heading
            }`,
            dateClassName: `py-[6px] text-neutral capitalize text-[17px] text-black  ${
                getFont('HDFC').heading
            }`,
            termsAndConditionsClassName: `capitalize text-black text-sm font-semibold text-[12px] ${
                getFont('HDFC').heading
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `text-black text-xs break-words leading-1 ${
                getFont('HDFC').heading
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-transparent text-secondary-focus font-bold uppercase self-center rounded text-[16px] py-2.5 px-7 border-[3px] border-accent ${
                    getFont('HDFC').bold
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `bg-transparent text-secondary-focus rounded uppercase text-[16px] py-[9px] px-10 border-[3px] border-accent ${
                    getFont('HDFC').heading
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/12464393.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/63685699.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[70vh] md:h-[85vh] bg-bottom '
            }
        ],
        moduleConfig: {
      
            constructionVisible: false,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: '',
            addHeader: true,
            headerClass: 'hidden lg:block h-32 bg-[#58595B]'
        }
    },
    header: {
        headerClass: 'hidden lg:block lg:h-28 bg-white '
    },
    contactUs: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/89848625.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/89848625.jpg',
                bannerContainerClassName:
                    'hero hero-content  text-center max-w-full lg:bg-center h-[70vh] md:h-[85vh]'
            }
        ],

    
    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('HDFC').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('HDFC').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/62822202.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/62822202.jpg',
                bannerContainerClassName:
                    'hero hero-content text-center max-w-full h-[70vh] md:h-[85vh]'
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-secondary-content font-medium text-[20px] md:text-[20px] ${
                            getFont('HDFC').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('HDFC').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a HDFC Capital project, and has entered into a registered Agreement to Sell; or (ii) employee of HDFC Capital; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by HDFC Capital  or any of its sister concerns/subsidiaries/affiliates (“HDFC Capital Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate HDFC Capital Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by HDFC Capital.',
                            'Referrer shall not be able to refer existing customer(s) of HDFC Capital. Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with HDFC Capital or (ii) is/are already registered with HDFC Capital as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the HDFC Capital’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to HDFC Capital within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to HDFC Capital',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the HDFC Capital or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('HDFC').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-0 lg:px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or HDFC Capital, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('HDFC').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('HDFC').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('HDFC').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('HDFC').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('HDFC').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('HDFC').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('HDFC').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content md:pt-4 xl:pt-2',
        footerLogoClassName: '',
        termsAndConditionsClassName: 'pb-3',
        termsAndConditionsContent: ' ',
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[11px] 3xl:text-[15px] leading-[17px] tracking-tight text-white font-normal ${
            getFont('HDFC').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('HDFC').heading
        }`,
        paginationClass:
            ' text-secondary-focus disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-primary bg-opacity-90'
    },
    referralForm: {
        name: 'HDFC',
        theme: 'hdfcTheme',
        color: '#F7941d',
        backgroundImage: 'https://cdn.loyalie.in/87252958.jpg',
        reloyLogo: 'https://cdn.loyalie.in/27822817.png',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '0',
            G1: '0',
            B1: '0',
            opacity1: '0',
            R2: '0',
            G2: '0',
            B2: '0',
            opacity2: '0.20'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: ' ',
            bookedCustomerHeading2: 'HDFC Home Owner',

            employee: false,
            employeeHeading1: 'I am an',
            // employeeHeading1: 'Bramhacorp Employee',

            salesAdmin: true,
            salesAdminHeading1: ' ',
            salesAdminHeading2: 'HDFC Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        customConfig: {
            welcomeContent: '',
            headingColor: 'text-white',
            userBgColor: 'bg-[#4D4D4F]',
            borderColor: 'border-primary',
            animateUserType: true
        },
        pastReferralConfig: {
            selectedStatusColor: 'text-primary',
            bgColor: 'bg-[#4D4D4F]'
        },
        formContent: {
            heading: 'STAY CONNECTED. GET REWARDED.',
            headingClass: `self-center text-center lg:text-left text-secondary-focus text-[22px] lg:text-[25px] xl:text-[30px] 2xl:text-[35px] w-[80%] lg:w-[55%] 2xl:w-[65%] text-left leading-7 xl:leading-9 tracking-wide font-bold ${
                getFont('HDFC').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton: ` w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold  ${
                getFont('HDFC').heading
            }`
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='white'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='%23c14953' stroke-width='7' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='7' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: true,
        sendReferralAttemptNotification: false,
        influencerLinkActive: true,
        isDirectTAndCLink: true,
        termsAndConditionsLink: 'heo-home/terms-and-conditions',
        customLink: '/heo-home/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default HeoConfig;
