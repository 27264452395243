import React from 'react';
import styled from 'styled-components';

const HrContainer = styled.div`
    ${(props) =>
        props.position && `justify-content: ${props.position} !important;`}
`;

const Hr = styled.hr`
    ${(props) => props.color && `background-color: ${props.color} !important`}
`;

const Divider = (props) => {
    const { className, position, color, classNameContainer } = props;
    return (
        <HrContainer
            className={classNameContainer + ' flex'}
            position={position}
        >
            <Hr
                color={color}
                className={className || 'w-[8%]  border-none h-[4px]'}
            ></Hr>
        </HrContainer>
    );
};

export default Divider;
