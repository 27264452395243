import React ,{useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import useData from '../ReferralPage/data';
import TotalRewards from '../ReferralPage/TotalRewards';
import PastReferrals from '../ReferralPage/PastReferrals';
import TotalReferral from '../ReferralPage/TotalReferral';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { primary } from 'daisyui/colors/colorNames';

const Referrals = ({config}) => {
    const history=useHistory();
    const { userRole } = useSelector((state) => state.auth);
    const [pastReferralStatus, setPastReferralStatus] = useState('all');
    const { isLoading, isAuthenticated, userAccountSummary, uploadDocuments ,getUserAccountSummary } = useData();

  return (
<>
       
                <div className=" w-full lg:pl-5 2xl:pl-7">
                    <div className="flex justify-between">
                        <div>
                            <h1 className="text-black text-lg md:text-xl font-bold">
                                Refer & Earn
                            </h1>
                            <h3 className=" font-Poppins pt-1 text-sm">
                                Track your ongoing enquires and claim your
                                rewards
                            </h3>
                        </div>
                    </div>
                    {(userRole == 'CUSTOMER' ||
                                (userRole == 'EMPLOYEE' &&
                                    config?.referralForm?.userTypes?.employee &&
                                    userAccountSummary?.commissionEarned)) && (
                                    <div
                                        className="  rounded-xl "
                                    >
                                        <TotalRewards
                                            userAccountSummary={userAccountSummary}
                                            config={config}
                                            getUserAccountSummary={getUserAccountSummary}
                                        />
                                    </div>
                     )}

                     <button className=' flex items-center gap-1 text-primary cursor-pointer  mt-4 '  onClick={() => {
                      history.push('/contact-us?referralissue=true');
                }}
>
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                     <g id="_4213468_help_lifesaver_support_icon" data-name="4213468_help_lifesaver_support_icon" transform="translate(-1.2 -1.2)">
                     <path id="Path_7102" data-name="Path 7102" d="M11.2,1.2a10,10,0,1,0,10,10A10,10,0,0,0,11.2,1.2Zm0,1.21A8.683,8.683,0,0,1,16.869,4.5l-2.42,2.4a5.351,5.351,0,0,0-6.5,0L5.531,4.5A8.768,8.768,0,0,1,11.2,2.41Zm4.172,8.79A4.172,4.172,0,1,1,11.2,7.028,4.176,4.176,0,0,1,15.372,11.2ZM2.41,11.2A8.668,8.668,0,0,1,4.655,5.356l2.4,2.4a5.368,5.368,0,0,0-.143,6.688L4.5,16.869A8.768,8.768,0,0,1,2.41,11.2Zm8.79,8.79a8.668,8.668,0,0,1-5.844-2.245l2.4-2.4a5.383,5.383,0,0,0,6.879,0l2.4,2.4A8.746,8.746,0,0,1,11.2,19.99Zm6.7-3.121-2.4-2.42A5.4,5.4,0,0,0,16.6,11.2a5.321,5.321,0,0,0-1.242-3.439l2.4-2.4A8.776,8.776,0,0,1,20.006,11.2,8.868,8.868,0,0,1,17.9,16.869Z" transform="translate(0 0)" fill={config.primaryColor}/>
                     </g>
                    </svg>
                     <p className='underline text-sm font-semibold'>Data not reflecting correctly?</p> 
                    </button>

                           
                  <div className='xl:w-[93%]'>
                  {(userRole == 'CUSTOMER' ||
                                (userRole == 'EMPLOYEE' &&
                                    config?.referralForm?.userTypes
                                        ?.employee)) && (
                                    <div className="bg-white py-10 rounded-xl ">
                                        <TotalReferral
                                            userAccountSummary={userAccountSummary}
                                            active={pastReferralStatus}
                                            setActive={setPastReferralStatus}
                                        />
                                                <PastReferrals
                                                active={pastReferralStatus}
                                            />
                                    </div>
                                )}
                  </div>
                    
                            
                    </div>

                   
      
    </>
       
  );
}

export default Referrals;
