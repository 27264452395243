import React from 'react'
import SkeletonElement from '../../../SkeletonElement';

const SkeletonRewards = () => {
  return (

       <div className="flex flex-col justify-center items-center shadow-xl px-6 w-[250px] h-[250px] lg:w-[200px] lg:h-[200px] xl:w-[250px] xl:h-[250px]">
                <SkeletonElement type="thumbnail" size="h-24 w-24" />
      
                <SkeletonElement type="title"   />
              <SkeletonElement type="text"   />  
        </div>
  )
}

export default SkeletonRewards;
