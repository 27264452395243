import React, { useEffect, useState } from 'react';
import BsInfoCircle from '@meronex/icons/bs/BsInfoCircle';
import SiTwitter from '@meronex/icons/si/SiTwitter';
import SiWhatsapp from '@meronex/icons/si/SiWhatsapp';
import FaFacebookF from '@meronex/icons/fa/FaFacebookF';
import BilLinkedin from '@meronex/icons/bi/BilLinkedin';
import { useSelector } from 'react-redux';
import FiCopy from '@meronex/icons/fi/FiCopy';
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookShareButton
} from 'react-share';
import EnShareAlternative from '@meronex/icons/en/EnShareAlternative';
import Modal from '../../../../Components/Organisms/Modal';
import ReactPlayer from 'react-player';
import { getConfig } from '../../../../utils/config';
import { winnreAuth } from '../../../../utils/axios';
import axios from 'axios';
import { urlShortner } from '../../../../helpers';
import {
    dividerSvg,
    icon1,
    icon2,
    icon3,
    moneyBagSvg,
    shareLinkSvg
} from '../../svg';

function CurrentStepIndicator({
    referralConfig,
    projectToRefer,
    setPlayInfluencerVideo,
    playInfluencerVideo
}) {
    const userHash = useSelector((state) => state.auth.userHash);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );

    const [copiedText, setCopiedText] = useState(false);
    const [influencerLink, setInfluencerLink] = useState(null);
    const [activeIcon, setActiveIcon] = useState('');
    const config = getConfig();

    const copy = async () => {
        if ('clipboard' in navigator) {
            // const shortLink = urlShortner(link);
            await navigator.clipboard.writeText(influencerLink);
            setCopiedText(true);
        }
        window?.webengage?.track('InfluencerLinkCopied', {
            ProjectName: `${projectToRefer?.projectName}`,
            ProjectCity: `${projectToRefer?.city}`
        });
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setCopiedText(false);
        }, 10000);

        return () => {
            clearTimeout(timer);
        };
    }, [copiedText]);

    const shareHandler = (platform) => {
        setActiveIcon(platform);
        // Simulate share process and feedback
        setTimeout(() => {
            setActiveIcon('');
        }, 4000);
        window?.webengage?.track('InfluencerLinkShared', {
            Link: `${influencerLink}`
        });
    };

    // Share value only for internal view
    const handleShareClick = (platform) => {
        var valueToSend = {
            message: 'SHARE_LINK',
            data: {
                link: platform + influencerLink
            }
        };
        console.log(valueToSend);
        // IOS message handler
        window?.webkit?.messageHandlers?.jsMessageHandler.postMessage(
            valueToSend
        );
        var stringifiedValueToSend = JSON.stringify(valueToSend);
        // Android message handler
        window?.JSBridge?.showMessageInNative(stringifiedValueToSend);
        // Trigger event
        window?.webengage?.track('InfluencerLinkShared', {
            Link: `${influencerLink}`
        });
    };

    useEffect(() => {
        if (projectToRefer) {
            // Create short Link
            let links = encodeURIComponent(
                `${window.location.origin}${referralConfig.customLink}?projectId=${projectToRefer?.projectId}&userId=${userHash}`
            );
            winnreAuth
                .post('/winnre/user/short-link?URL=' + links)
                .then((res) => {
                    setInfluencerLink(res.shortURL);
                });
        }
    }, [projectToRefer]);

    return (
        <div className="text-black h-full p-8 2xl:p-10">
            {!projectToRefer && (
                <div className=" flex gap-4 items-center lg:justify-center pb-4 md:pb-6 ">
                    <div className="md:w-16">{moneyBagSvg}</div>
                    <h1
                        className={` text-lg lg:text-base xl:text-xl pl-4 font-semibold w-full ${referralConfig?.referPage?.customize?.heading}`}
                    >
                        {projectToRefer ? (
                            'PROJECT DETAILS'
                        ) : (
                            <div className=" md:whitespace-nowrap">
                                <span>Earn more by simply</span>
                                <br></br>
                                referring your friends & family
                            </div>
                        )}
                    </h1>
                </div>
            )}

            {!projectToRefer && (
                <>
                    {dividerSvg}
                    <div className=" mt-5 md:mt-6 flex flex-col gap-y-6 lg:gap-y-10 ">
                        <div className="flex items-center">
                            {icon1}
                            <h3 className="pl-3  text-base lg:text-sm xl:text-base">
                                Refer your loved ones to us
                            </h3>
                        </div>

                        <div className="flex items-center">
                            {icon2}
                            <h3 className="pl-4  text-base lg:text-sm  xl:text-base">
                                Keep a track till they complete property booking
                            </h3>
                        </div>
                        <div className="flex items-center">
                            {icon3}
                            <h3 className="pl-4  text-base  lg:text-sm  xl:text-base">
                                Receive your referral rewards post registration
                            </h3>
                        </div>
                    </div>
                </>
            )}

            {projectToRefer && (
                <div id="influencer_link">
                    {!config.projects.moduleConfig?.hideProjects && (
                        <div>
                            <div className="flex items-center gap-x-4">
                                <div
                                    onClick={() => setPlayInfluencerVideo(true)}
                                >
                                    {shareLinkSvg}
                                </div>
                                <h3 className="text-black text-sm font-semibold">
                                    Here's a shareable link for your referral
                                    form
                                </h3>
                            </div>
                            {dividerSvg}

                            <div className="mt-8 mb-4">
                                {projectToRefer?.image && (
                                    <img
                                        className="h-36 w-60 object-cover rounded-xl "
                                        src={projectToRefer?.image}
                                    />
                                )}
                                <h1 className="text-black text-base font-bold mt-2 leading-4 ">
                                    {projectToRefer?.projectName}
                                </h1>
                                <p className="text-gray-500 italic text-sm">
                                    {projectToRefer.city}
                                </p>
                            </div>

                            {!copiedText && (
                                <div class="form-control flex flex-row w-64 h-12">
                                    <button
                                        type="text"
                                        className=" text-black flex-1 px-2 overflow-hidden bg-white border-[#DDDDDD] border-t-2 border-b-2 border-l-2 rounded-l-md whitespace-nowrap"
                                    >
                                        {influencerLink}
                                    </button>
                                    <div className="bg-primary flex justify-center border-[#DDDDDD] border-r-2 border-t-2 border-b-2 rounded-r-md px-2">
                                        <FiCopy
                                            onClick={copy}
                                            className="text-white cursor-pointer w-5 h-5 m-auto"
                                        />
                                    </div>
                                </div>
                            )}
                            {copiedText && (
                                <p className="flex justify-center items-center mt-4 w-64 rounded-md font-normal animate-pulse bg-white h-12 text-sm text-primary text-center">
                                    Link Copied
                                </p>
                            )}
                            {/* <button
                                onClick={() => window.open(link)}
                                className="text-black underline mt-2"
                            >
                                Preview link
                            </button> */}
                            {!internalWebview && (
                                <>
                                    <p className="mt-6 text-black font-bold ">
                                        Share
                                    </p>
                                    <div
                                        // onClick={shareHandler}
                                        className="flex  items-center py-1 gap-x-3"
                                    >
                                        <WhatsappShareButton
                                            url={influencerLink}
                                            title="Checkout this fantastic project! 🏡 Discover top-notch amenities and more. Submit your enquiry using this link and enjoy a special 1% discount on the property price - "
                                            onClick={() =>
                                                shareHandler('WhatsApp')
                                            }
                                        >
                                            <SiWhatsapp
                                                className={`w-5 h-full text-primary ${
                                                    activeIcon === 'WhatsApp'
                                                        ? 'text-green-300'
                                                        : 'text-primary'
                                                }`}
                                            />
                                        </WhatsappShareButton>
                                        <TwitterShareButton
                                            url={influencerLink}
                                            title="Checkout this fantastic project! 🏡 Discover top-notch amenities and more. Submit your enquiry using this link and enjoy a special 1% discount on the property price - "
                                            hashtags={[
                                                'RealEstate',
                                                'ReferAndEarn',
                                                'ReferralRewards',
                                                'Houseowner'
                                            ]}
                                            onClick={() =>
                                                shareHandler('Twitter')
                                            }
                                        >
                                            <svg
                                                className={`w-5 ${
                                                    activeIcon === 'Twitter'
                                                        ? 'text-green-300'
                                                        : 'text-primary'
                                                }`}
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M8 2H1L9.26086 13.0145L1.44995 21.9999H4.09998L10.4883 14.651L16 22H23L14.3917 10.5223L21.8001 2H19.1501L13.1643 8.88578L8 2ZM17 20L5 4H7L19 20H17Z"></path>
                                            </svg>
                                        </TwitterShareButton>
                                        <LinkedinShareButton
                                            url={influencerLink}
                                            onClick={() =>
                                                shareHandler('LinkedIn')
                                            }
                                        >
                                            <BilLinkedin
                                                className={`w-6 h-full ${
                                                    activeIcon === 'LinkedIn'
                                                        ? 'text-green-300'
                                                        : 'text-primary'
                                                }`}
                                            />
                                        </LinkedinShareButton>

                                        <FacebookShareButton
                                            url={influencerLink}
                                            hashtag="#RealEstate"
                                            onClick={() =>
                                                shareHandler('Facebook')
                                            }
                                        >
                                            <FaFacebookF
                                                className={`w-5 h-full ${
                                                    activeIcon === 'Facebook'
                                                        ? 'text-green-300'
                                                        : 'text-primary'
                                                }`}
                                            />
                                        </FacebookShareButton>
                                    </div>
                                </>
                            )}
                            {internalWebview && (
                                <>
                                    <p className="mt-6 text-black font-bold ">
                                        Share
                                    </p>
                                    <div className="flex  items-center py-1 gap-x-3">
                                        <SiWhatsapp
                                            className={`w-5 h-full text-primary ${
                                                activeIcon === 'WhatsApp'
                                                    ? 'text-green-300'
                                                    : 'text-primary'
                                            }`}
                                            onClick={() =>
                                                handleShareClick(
                                                    'https://web.whatsapp.com/send?text='
                                                )
                                            }
                                        />

                                        <svg
                                            className={`w-5 ${
                                                activeIcon === 'Twitter'
                                                    ? 'text-green-300'
                                                    : 'text-primary'
                                            }`}
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            onClick={() =>
                                                handleShareClick(
                                                    'https://twitter.com/share?url='
                                                )
                                            }
                                        >
                                            <path d="M8 2H1L9.26086 13.0145L1.44995 21.9999H4.09998L10.4883 14.651L16 22H23L14.3917 10.5223L21.8001 2H19.1501L13.1643 8.88578L8 2ZM17 20L5 4H7L19 20H17Z"></path>
                                        </svg>

                                        <BilLinkedin
                                            className={`w-6 h-full ${
                                                activeIcon === 'LinkedIn'
                                                    ? 'text-green-300'
                                                    : 'text-primary'
                                            }`}
                                            onClick={() =>
                                                handleShareClick(
                                                    'https://www.linkedin.com/shareArticle?url='
                                                )
                                            }
                                        />
                                        <FaFacebookF
                                            className={`w-5 h-full ${
                                                activeIcon === 'Facebook'
                                                    ? 'text-green-300'
                                                    : 'text-primary'
                                            }`}
                                            onClick={() =>
                                                handleShareClick(
                                                    'https://www.facebook.com/sharer/sharer.php?u='
                                                )
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}

            <Modal
                className="w-[90vw] lg:w-1/2"
                visible={playInfluencerVideo}
                onClick={() => {
                    setPlayInfluencerVideo(false);
                }}
            >
                <ReactPlayer
                    url="https://t9002150650.p.clickup-attachments.com/t9002150650/5d217065-c13f-4959-a49d-3b9a4d2f668b/Referral%20link%20video%201.mp4?view=open   "
                    className="react-player px-1"
                    light={false}
                    // onClickPreview={() =>
                    //     this.props.onVideoClick(imgdata, i)
                    // }
                    playing
                    width="100%"
                    height="100%"
                    controls={true}
                />
            </Modal>
        </div>
    );
}

export default CurrentStepIndicator;
