import React, { Fragment, useState } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { getConfig, routePathMap } from '../../../utils/config';

import { scrollToTargetAdjusted } from '../../../utils/ui';
import Event from './Icons/Event';
import Blogs from './Icons/Blogs';
import Refer from './Icons/Refer';
import Offers from './Icons/Offers';
import Overview from './Icons/Overview';
function WebviewNavItem({
    label,
    show,
    pathname,
    scrollTo,
    customUrl,
    dropDown,
    dropDownOptions,
    onClick
}) {
    const location = useLocation();
    const config = getConfig();

    const getListIcon = (listName) => {
        // console.log(listName);
        switch (listName) {
            case 'OVERVIEW':
            case 'HOME':
                return (
                    <Overview
                        fill={config?.webview?.iconColor}
                        stroke={config?.webview?.iconColor}
                    />
                );
            case 'REFER NOW':
                return (
                    <Refer
                        fill={config?.webview?.iconColor}
                        stroke={config?.webview?.iconColor}
                    />
                );
            case 'OFFERS':
                return (
                    <Offers
                        fill={config?.webview?.iconColor}
                        stroke={config?.webview?.iconColor}
                    />
                );
            case 'EVENTS':
            case 'CURATED EVENTS':
                return (
                    <Event
                        fill={config?.webview?.iconColor}
                        stroke={config?.webview?.iconColor}
                    />
                );
            case 'BLOGS':
                return (
                    <Blogs
                        fill={config?.webview?.iconColor}
                        stroke={config?.webview?.iconColor}
                    />
                );
        }
    };

    return show ? (
        dropDown ? (
            <div class="relative">
                <div
                    class={`lg:absolute right-0 w-full lg:pt-2 origin-top-right lg:rounded-md lg:shadow-lg md:w-48 z-10 `}
                >
                    <div
                        class={`lg:py-2 lg:rounded-md lg:shadow ${
                            config?.navBarConfig.dropDownBg
                                ? config?.navBarConfig.dropDownBg
                                : 'bg-white'
                        }`}
                    >
                        {Object.keys(dropDownOptions)
                            .sort(
                                (a, b) =>
                                    dropDownOptions[a].order -
                                    dropDownOptions[b].order
                            )
                            .map(
                                (page) =>
                                    dropDownOptions[page].show && (
                                        <div className="flex items-center">
                                            <div className="pl-3">
                                                {getListIcon(
                                                    dropDownOptions[page].name
                                                )}
                                            </div>
                                            <NavLink
                                                to={
                                                    dropDownOptions[page]
                                                        .path ||
                                                    routePathMap[page] ||
                                                    '/'
                                                }
                                                className={`px-2 block py-2 lg:mt-2 bg-transparent lg:rounded-lg md:mt-0 ${
                                                    config?.navBarConfig
                                                        .baseNavBar
                                                        .dropDownItemClass
                                                }  ${
                                                    config?.navBarConfig
                                                        .baseNavBar
                                                        .dropDownContainerClass
                                                        ? config?.navBarConfig
                                                              .baseNavBar
                                                              .dropDownContainerClass
                                                        : 'hover:text-gray-900 hover:bg-gray-200'
                                                } `}
                                                onClick={onClick}
                                            >
                                                {dropDownOptions[page].name}
                                            </NavLink>
                                            {/* <div className="border-t-[2px] border-primary bg-primary"></div> */}
                                        </div>
                                    )
                            )}
                    </div>
                </div>
            </div>
        ) : (
            <div className="flex items-center pl-3">
                {getListIcon(label)}
                <NavLink
                    className={
                        ` px-2 py-2 2xl:px-2 2xl:py-2 lg:pb-2 lg:border-none  lg:px-0 block lg:inline-block transition ease-out duration-75  ` + //Common class for mobnavbar and navbar
                        ` ${config.navBarConfig.baseNavBar.itemClass}`
                    }
                    activeClassName={
                        config.navBarConfig.showActiveLinkColor
                            ? 'text-secondary-focus'
                            : ''
                    }
                    // style={{ textTransform: 'capitalize' }}
                    to={pathname}
                    onClick={(e) => {
                        if (customUrl) {
                            e.preventDefault();
                            window.open(customUrl);
                        }
                        if (scrollTo) {
                            if (location.pathname !== pathname) {
                                setTimeout(() => {
                                    scrollToTargetAdjusted(scrollTo);
                                }, 500);
                            } else {
                                e.preventDefault();
                                scrollToTargetAdjusted(scrollTo);
                            }
                        }
                        onClick();
                    }}
                >
                    {label}
                </NavLink>
                {/* <div className="border-t-[2px] border-primary bg-primary"></div> */}
            </div>
        )
    ) : (
        <></>
    );
}

export default WebviewNavItem;
