import React, { useContext, useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import usePhoneInput from '../../../../Hooks/usePhoneInput';
import useInput from '../../../../Hooks/useInput';
import { APIErrorCondition, RE_EMAIL } from '../../../../constants';
import PhoneInput from 'react-phone-input-2';
import { useHistory, useLocation } from 'react-router-dom';
import useData from './data';
import {
    DetailProjectLoadingUI,
    LoadingSpinner
} from '../../../../Components/Molecules/LoadingUI';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../../store/auth-slice';
import ProjectDetailNew from '../../Components/ProjectDetail/newIndex';
import ProjectGalleryNew from '../../Components/ProjectGallery/newIndex';
import ProjectConsUpdateNew from '../../Components/ProjectConsUpdate/newIndex';
import ProjectAmenitiesNew from '../../Components/ProjectAmenities/newIndex';
import ProjectLocationNew from '../../Components/ProjectLocation/newIndex';
import './index.css';
import { winnreAuth } from '../../../../utils/axios';
import VRGallery from '../../Components/AR/VR';
import Modal from '../../../../Components/Organisms/Modal';
import { toast } from 'react-toastify';
import GrClose from '@meronex/icons/gr/GrClose';
import EiCloseO from '@meronex/icons/ei/EiCloseO';
import { getConfig } from '../../../../utils/config';
import ProjectDocs from '../../Components/ProjectDocs/index.js';
import ProjectBrochures from '../../Components/ProjectBrochure/newIndex.js';
import Disclaimer from '../../Components/Disclaimer/index.js';
import TiTick from '@meronex/icons/ti/TiTick.js';
import SiWhatsapp from '@meronex/icons/si/SiWhatsapp';
import FaFacebookF from '@meronex/icons/fa/FaFacebookF';
import FiCopy from '@meronex/icons/fi/FiCopy.js';
import MdcEmailOutline from '@meronex/icons/mdc/MdcEmailOutline';
import EnShareAlternative from '@meronex/icons/en/EnShareAlternative';
import { scrollToTargetAdjusted } from '../../../../utils/ui.js';
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';

const Content = (props) => {
    const { propertyBackgroundColorWithOverlay, headerClass } = props;
    const config = getConfig();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get('projectId');
    const {
        projectDetails,
        highlightCategories,
        projectAdditionalHighlights,
        projectAmenities,
        projectGallery,
        constructionGallery,
        loadingProject,
        VrGallery, 
        projectDocs,
        projectBrochure
    } = useData(projectId);
    const [terms, setTerms] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [copiedUrl, setCopiedUrl] = useState(false);
    const [loading, setLoading] = useState(false);

    const[showShareModal,setShowShareModal]=useState(false);

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userRole = useSelector((state) => state.auth.userRole);
    const userName = useSelector((state) => state.auth.userName);
    const { mobileNumber: userMobileNumber, email: userEmail } = useSelector(
        (state) => state.auth.userDetails
    );
   
     const userId = useSelector((state) => state.auth.userHash);
     let link = `${window.location.href.split('?')[0]}?projectId=${projectId}&userId=${userId}`;
      

    const openLoginModal = () => {
        dispatch(authActions.openLoginModal());
    };
    const copyCodeHandler = async () => {
        if ('clipboard' in navigator) {
            if (!isAuthenticated || userRole !== 'CUSTOMER') {
                await navigator.clipboard.writeText(window.location.href);
            }
            else if (userRole === 'CUSTOMER') {
                await navigator.clipboard.writeText(link);
            }
            
        }
       
        setCopiedUrl(true);
    };
    

    const userHash = queryParams.get('userId');

    const [fromInfluencerLink, setFromInfluencerLink] = useState(
        queryParams.get('userId') !== null
    );
    // console.log("GALERY", VrGallery, constructionGallery, projectGallery)

    useEffect(() => {
        if (userHash) {
            const url = `https://api.in.webengage.com/v1/accounts/${config?.webengageLic}/events`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${config?.webengageAPIKey}`
                },
                body: JSON.stringify({
                    userId: userHash,
                    eventName: 'InfluencerLinkViewed',
                    eventData: {
                        ProjectName: projectDetails?.projectName
                    }
                })
            };

            fetch(url, requestOptions)
                .then((res) => {
                    // console.log(res);
                })
                .catch((err) => {
                    // console.log(err);
                });
        }
    }, [userHash]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCopiedUrl(false);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };

        if (queryParams.get('userId')) {
            dispatch(authActions.setUserHash(queryParams.get('userId')));
        }

        // WEBENGAGE for project selection
        const webengageProject = {
            ProjectName:
                projectDetails?.projectName !== undefined
                    ? projectDetails?.projectName
                    : '',
            ProjectImage:
                projectDetails?.projectBgImage !== undefined
                    ? projectDetails?.projectBgImage
                    : '',
            DevelopmentStatus:
                projectDetails?.status !== undefined
                    ? projectDetails?.status
                    : '',
            ProjectDetails:
                projectDetails?.projectDescription !== undefined
                    ? projectDetails?.projectDescription
                    : '',
            Configuration:
                projectDetails?.apartmentTypeNames !== undefined
                    ? projectDetails?.apartmentTypeNames
                    : '',
            Possession:
                projectDetails?.possessionDate !== undefined
                    ? projectDetails?.possessionDate
                    : '',
            Address:
                projectDetails?.address !== undefined
                    ? projectDetails?.address
                    : '',
            BasePrice:
                projectDetails?.price !== undefined ? projectDetails?.price : 0,
            Area: projectDetails?.area !== undefined ? projectDetails?.area : ''
        };
        window?.webengage?.track('ProjectViewed', webengageProject);
        return () => {
            clearTimeout(timer);
        };
    }, [copiedUrl]);

    const {
        state: {
            isValid: mobileNumberIsValid,
            mobile,
            country,
            mobileHasError,
            isInputFocused
        },
        onChange,
        validator,
        setMobile,
        setIsTouched,
        getMobileWithoutCountryCode,
        setInputFocused
    } = usePhoneInput();

    const {
        value: name,
        isValid: nameIsValid,
        hasError: nameHasError,
        valueChangeHandler: nameChangedHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetNameInput
    } = useInput((value) => value.trim() !== '');

    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmailInput
    } = useInput((value) => RE_EMAIL.test(value));

    const {
        value: comments,
        valueChangeHandler: commentsChangedHandler,
        reset: resetcommentsInput
    } = useInput((value) => value.trim() !== '');

    const termsOnChangeHandler = () => {
        setTerms(!terms);
    };

    let formIsValid = false;
    if (nameIsValid && mobileNumberIsValid && terms) {
        if(email !== '') {
            if(emailIsValid) {
                formIsValid = true
            }
        } else {
        formIsValid = true;}
    }
    const enquireNowHandler = () => {
        if (!isAuthenticated) {
            openLoginModal();
            return;
        }
        if (userRole == 'CUSTOMER') {
            enquirySubmission({ initiateSelfReferral: true });
        } else {
            executeScrollReferral();
        }
    };
    const bookSiteVisitHandler = () => {
        if (isAuthenticated) {
        history.push(`/projects/book-a-site-visit?id=${projectDetails?.id}&image=${projectDetails?.projectBgImage}&name=${projectDetails?.projectName}&city=${projectDetails?.city}`)

            // history.push({
            //     pathname: `/projects/book-a-site-visit?id=${projectDetails?.id}&image=${projectDetails?.projectBgImage}&name=${projectDetails?.projectName}&city=${projectDetails?.city}`,
            //     state: {
            //         projectId: projectDetails?.id,
            //         projectBgImage: projectDetails?.projectBgImage,
            //         projectCity: projectDetails?.city,
            //         projectName: projectDetails?.projectName
            //     }
            // });
        } else {
            dispatch(authActions.openLoginModal());
        }
    };
    const enquirySubmission = ({ initiateSelfReferral = false }) => {
        let enquiryData;
        if (initiateSelfReferral) {
            enquiryData = {
                name: userName,
                mobileNumber: userMobileNumber,
                projectId: projectDetails?.id,
                referralSource: 'CUSTOMER',
                source: 'REFERRAL',
                email: userEmail
            };
        } else {
            enquiryData = {
                name: name,
                mobileNumber: getMobileWithoutCountryCode(),
                projectId: projectDetails?.id,
                referralSource: 'CUSTOMER',
                source: 'REFERRAL',
                email: email,
                comment: comments
            };
        }
        winnreAuth({
            method: 'post',
            url: `/winnre/user/enquiry`,
            data: JSON.stringify(enquiryData),
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then((res) => {
                setLoading(false);
                // console.log(res);
                if (res?.status === APIErrorCondition) {
                    toast.error(res.message);
                } else {
                    setShowModal(true);
                    if (initiateSelfReferral) {
                        setModalMessage(
                            'Thank You for your Enquiry. Someone from our team will contact you shortly!'
                        );
                    } else {
                        setModalMessage(
                            'Thank You. Someone from our team will contact you shortly!'
                        );
                        resetcommentsInput();
                        resetNameInput();
                        resetEmailInput();
                        setMobile('+91');
                        setTerms(false);
                        setIsTouched(false);
                    }
                }
            })
            .catch(() => {
                toast.error('Something went wrong!');
            });
    };
    const influencerLinkSubmission = () => {
        const data = {
            userHash: userHash,
            leadName: name,
            leadMobileNumber: getMobileWithoutCountryCode(),
            leadMobileCountryCode: country.dialCode,
            leadEmail: email,
            leadCountry: country?.countryCode.toUpperCase(),
            leadProjectId: projectDetails?.id,
            comment: comments
        };
        winnreAuth({
            method: 'POST',
            url: `/winnre/user/enquiry/refer-from-link/`,
            params: data
        })
            .then((res) => {
                setLoading(false);
                if (res?.status == APIErrorCondition) {
                    toast.error(res.message);
                } else {
                    setModalMessage(
                        'Thank you. Someone from our team will contact you shortly!'
                    );
                    setShowModal(true);
                    resetcommentsInput();
                    resetNameInput();
                    resetEmailInput();
                    setMobile('+91');
                    setTerms(false);
                    setIsTouched(false);
                    return;
                }
            })
            .catch(() => {
                toast.error('Something went wrong! Please try again!');
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        setShowModal(true);
        if (fromInfluencerLink) {
            influencerLinkSubmission();
        } else if (isAuthenticated) {
            enquirySubmission({});
        }
    };

    const handleShareClick = (e) => {
        e.preventDefault();
        setShowShareModal(true);
        
      };
     
      const bookNowHandler = () => {
        if (!isAuthenticated) {
            openLoginModal();
            return;
        }
        window.open('https://rzp.io/i/GJSRKdg')
    };
    

    const referralForm = () => {
        return (
            <div id="referral_form" className="mt-8">
                <p
                    className={`font-semibold text-xl text-primary ${config.fontTitle}`}
                >
                    {userRole !== 'SITE_VISITOR' &&
                    (userHash === undefined || userHash === null)
                        ? `Get Rewarded on Successful Referrals`
                        : `Exclusive Offers Await!`}
                </p>
                <div className="mt-4">
                    <form
                        onSubmit={handleSubmit}
                        className=" p-6 bg-white rounded-xl border-2 max-w-screen md:w-full relative  3xl:h-[400px] mx-auto "
                    >
                        <div className="flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="43.352"
                                height="48"
                                viewBox="0 0 43.352 48"
                            >
                                <g
                                    id="gift-box-39"
                                    transform="translate(-183.7 -174)"
                                >
                                    <path
                                        id="Path_6443"
                                        data-name="Path 6443"
                                        d="M333.289,547.907v-.073a2.908,2.908,0,0,1-2.073.872H330.6a.739.739,0,1,1,0-1.479h.614a1.429,1.429,0,0,0,1.426-1.426v-.462a.741.741,0,0,1,.647-.733v-1.221a.741.741,0,0,1-.647-.733v-.726a.741.741,0,0,1,.647-.733v-3.044H320.434v11.779h11.435A2.149,2.149,0,0,0,333.289,547.907Zm-.4-21.207H320.434v9.983h12.848v-8.742A2.077,2.077,0,0,0,332.886,526.7Zm-13.931,0h-10.6a2.266,2.266,0,0,0-2.258,2.258v7.725h12.848V526.7Zm0,11.462H306.107v10.577a2.259,2.259,0,0,0,.35,1.2h12.505V538.162Z"
                                        transform="translate(-114.319 -329.413)"
                                        fill="#63c0b2"
                                    />
                                    <path
                                        id="Path_6444"
                                        data-name="Path 6444"
                                        d="M697.913,541.206a.433.433,0,0,1,.092-.007.738.738,0,0,1,.74.739v.726a.738.738,0,0,1-.74.74c-.033,0-.059-.007-.092-.007v1.215a.437.437,0,0,1,.092-.007.738.738,0,0,1,.74.74v.462a2.887,2.887,0,0,1-.832,2.034v.073a2.175,2.175,0,0,1-1.413,2.027h1.967a1.429,1.429,0,0,0,1.426-1.426V538.162h-1.981Zm.654-14.5c-.033,0-.066-.007-.1-.007h-.951a2.123,2.123,0,0,1,.4,1.241v8.742h1.981v-9.448a2.168,2.168,0,0,0-1.281-.528Z"
                                        transform="translate(-478.943 -329.413)"
                                        fill="#00ac9a"
                                    />
                                    <path
                                        id="Path_6445"
                                        data-name="Path 6445"
                                        d="M254.539,573.146a.738.738,0,0,0,.739-.739v-1.367a.739.739,0,1,0-1.479,0v1.367A.738.738,0,0,0,254.539,573.146Z"
                                        transform="translate(-65.472 -370.134)"
                                    />
                                    <path
                                        id="Path_6446"
                                        data-name="Path 6446"
                                        d="M252.448,366.7h18.494a1.708,1.708,0,0,0,1.7-1.7v-5.625a2.04,2.04,0,0,0-1.314-.475H235.467a1.369,1.369,0,0,0-1.367,1.367v5.216a1.952,1.952,0,0,0,.442,1.228h17.906Z"
                                        transform="translate(-47.072 -172.692)"
                                        fill="#f7f797"
                                    />
                                    <path
                                        id="Path_6447"
                                        data-name="Path 6447"
                                        d="M223.869,183.309H213.728a5.39,5.39,0,1,0-8.359-6.629,5.39,5.39,0,1,0-8.359,6.629H186.882a3.184,3.184,0,0,0-3.182,3.182v5.81a3.184,3.184,0,0,0,3.182,3.182H188.7a2.89,2.89,0,0,0-.376,1.426v1.07a.739.739,0,1,0,1.479,0v-1.07a1.429,1.429,0,0,1,1.426-1.426h13.4v11.772H189.807v-1.472a.739.739,0,1,0-1.479,0v13.311A2.905,2.905,0,0,0,191.233,222h28.285a2.905,2.905,0,0,0,2.905-2.905V196.911a2.909,2.909,0,0,0-.376-1.426h1.822a3.184,3.184,0,0,0,3.182-3.182v-5.81A3.184,3.184,0,0,0,223.869,183.309Zm-18.494-1.2a5.566,5.566,0,0,0,.951,1.2h-1.908A5.4,5.4,0,0,0,205.376,182.114Zm4.655-6.629a3.9,3.9,0,0,1,3.182,1.644c.007.007.007.013.013.02a3.907,3.907,0,0,1-5.447,5.447c-.007-.007-.013-.007-.02-.013a3.911,3.911,0,0,1,2.271-7.1ZM196.813,179.4a3.911,3.911,0,0,1,7.1-2.271c.007.007.007.013.013.02a3.907,3.907,0,0,1-5.447,5.447c-.007-.007-.013-.007-.02-.013A3.908,3.908,0,0,1,196.813,179.4Zm-9.93,14.611a1.708,1.708,0,0,1-1.7-1.7V186.5a1.708,1.708,0,0,1,1.7-1.7h36.987a1.708,1.708,0,0,1,1.7,1.7v5.81a1.708,1.708,0,0,1-1.7,1.7ZM220.945,219.1a1.429,1.429,0,0,1-1.426,1.426h-13.4V208.749h14.829Zm-29.711,1.426a1.429,1.429,0,0,1-1.426-1.426V208.749h14.829v11.779Zm29.711-23.61V207.27H206.115V195.5h13.4A1.419,1.419,0,0,1,220.945,196.917Z"
                                        transform="translate(0)"
                                    />
                                    <path
                                        id="Path_6448"
                                        data-name="Path 6448"
                                        d="M395.086,214.72a3.991,3.991,0,0,1-.231-.35A3.991,3.991,0,0,0,395.086,214.72Zm5.539-3.777a3.243,3.243,0,0,0-6.325,1.439,3.812,3.812,0,0,0,.522,1.928,3.883,3.883,0,0,0,5.8-3.367Zm-.013-1.578c-.007-.007-.007-.013-.013-.02s-.033-.026-.053-.04a.483.483,0,0,0,.066.059Zm-5.467,5.434c.007.007.013.007.02.013a.59.59,0,0,1-.059-.066A.369.369,0,0,0,395.145,214.8Zm9.31,0c.007.007.013.007.02.013a.6.6,0,0,1-.059-.066A.365.365,0,0,0,404.455,214.8Zm5.46-5.434c-.007-.007-.007-.013-.013-.02s-.033-.026-.053-.04c.026.02.046.046.066.059Zm-5.526,5.355a3.9,3.9,0,0,1-.231-.343C404.23,214.488,404.309,214.607,404.389,214.72Zm5.546-3.777a3.243,3.243,0,0,0-6.325,1.439,3.813,3.813,0,0,0,.522,1.928,3.883,3.883,0,0,0,5.8-3.367Z"
                                        transform="translate(-196.695 -32.222)"
                                        fill="#63c0b2"
                                    />
                                    <path
                                        id="Path_6449"
                                        data-name="Path 6449"
                                        d="M407.8,216.664a3.53,3.53,0,0,0-.4-.264,3.881,3.881,0,0,1-5.289,5.3H402.1a.229.229,0,0,1,.033.053,4,4,0,0,0,.231.35.114.114,0,0,0,.026.033c.02.02.04.046.059.066a3.907,3.907,0,0,0,5.447-5.447.6.6,0,0,0-.066-.059C407.818,216.684,407.811,216.671,407.8,216.664Zm9.31,0a3.255,3.255,0,0,0-.4-.264,3.881,3.881,0,0,1-5.289,5.3h-.007a.229.229,0,0,1,.033.053,3.121,3.121,0,0,0,.231.343.115.115,0,0,0,.026.033.594.594,0,0,1,.059.066,3.907,3.907,0,0,0,5.447-5.447.59.59,0,0,0-.066-.059A.143.143,0,0,1,417.107,216.664Z"
                                        transform="translate(-203.98 -39.601)"
                                        fill="#00ac9a"
                                    />
                                    <path
                                        id="Path_6450"
                                        data-name="Path 6450"
                                        d="M668.78,750.446v.462a1.429,1.429,0,0,1-1.426,1.426h-.614a.739.739,0,1,0,0,1.479h.614a2.908,2.908,0,0,0,2.905-2.905v-.462a.738.738,0,0,0-.74-.74c-.033,0-.059.007-.092.007A.736.736,0,0,0,668.78,750.446Zm0-3.407v.726a.741.741,0,0,0,.647.733.436.436,0,0,0,.092.007.738.738,0,0,0,.74-.74v-.726a.738.738,0,0,0-.74-.739c-.033,0-.059.007-.092.007A.736.736,0,0,0,668.78,747.039Z"
                                        transform="translate(-450.456 -534.514)"
                                        fill="#fff"
                                    />
                                </g>
                            </svg>

                            <p
                                className={`ml-2 text-lg md:text-xl text-[632e3e] w-full md:w-1/2 ${config.fontBody}`}
                            >
                                {userRole !== 'SITE_VISITOR' &&
                                (userHash === undefined || userHash === null)
                                    ? `Find your loved ones their Dream Home & Get Rewarded for it.`
                                    : `Please enter the details of the person who is looking to buy with us :`}
                            </p>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2  pt-4">
                            <div className="my-2 w-full">
                                <input
                                    type="text"
                                    placeholder="Referral Name*"
                                    className="h-12 text-md bg-white xs:text-base w-full md:w-11/12 border-2 border-[#EBEAF2] px-3 py-2 rounded focus:outline-none "
                                    value={name}
                                    onBlur={nameBlurHandler}
                                    onChange={nameChangedHandler}
                                />
                                {nameHasError && (
                                    <p
                                        className={`text-left font-medium text-red-500 text-sm mt-2 ${config.fontTitle}`}
                                    >
                                        Please enter a valid name.
                                    </p>
                                )}
                            </div>
                            <div className="my-2 w-full md:w-[91%]">
                                <div className='h-12'>
                                <PhoneInput
                                    country={'in'}
                                    countryCodeEditable={false}
                                    containerClass={`bg-white h-10 rounded outline-none border-none ${
                                        isInputFocused ? 'outline-none ' : ''
                                    }`}
                                    onlyCountries={[
                                        'in',
                                        'hk',
                                        'id',
                                        'kw',
                                        'my',
                                        'om',
                                        'qa',
                                        'sa',
                                        'sg',
                                        'ae',
                                        'gb',
                                        'eu',
                                        'us',
                                        'th'
                                    ]}
                                    containerStyle={{
                                        width: '100%',
                                        boxShadow: '0px',
                                        border: 'none',
                                        outline: 'none',
                                        borderRadius: 'none',
                                        marginTop: '-1.5px',
                                        height: '100%'
                                    }}
                                    inputStyle={{
                                        width: '100%',
                                        height: '100%',

                                        border: 'none',
                                        borderRadius: 'none',
                                        border: '2px solid #EBEAF2',
                                        backgroundColor: '#FFFFFF'
                                    }}
                                    buttonStyle={{
                                        backgroundColor: '#FFFFFF',
                                        border: 'none',
                                        border: '2px solid #EBEAF2'
                                    }}
                                    isValid={validator}
                                    onChange={onChange}
                                    value={mobile}
                                    enableSearch
                                    onBlur={() => {
                                        setIsTouched(true);
                                        setInputFocused(false);
                                    }}
                                    onFocus={() => setInputFocused(true)}
                                    inputClass=""
                                />
                                </div>
                                {mobileHasError && (
                                    <p className="text-left font-medium text-red-500 text-sm mt-2">
                                        Please enter a valid phone number.
                                    </p>
                                )}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Referral Email"
                                    className="h-12 text-md my-2 bg-white xs:text-base w-full md:w-11/12 border-2 border-[#EBEAF2] px-3 py-2 rounded focus:outline-none "
                                    value={email}
                                    onBlur={emailBlurHandler}
                                    onChange={emailChangedHandler}
                                />
                                {emailHasError && (
                                    <p
                                        className={`text-left font-medium text-red-500 text-sm ${config.fontTitle}`}
                                    >
                                        Please enter a valid email.
                                    </p>
                                )}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="h-12 text-md my-2 bg-white xs:text-base w-full md:w-11/12 border-2 border-[#EBEAF2] px-3 py-2 rounded focus:outline-none "
                                    placeholder="Comments"
                                    value={comments}
                                    onChange={commentsChangedHandler}
                                />
                            </div>
                        </div>
                        <div className="mt-4 flex items-center">
                            <input
                                type="checkbox"
                                checked={terms}
                                onChange={termsOnChangeHandler}
                                id="terms_checkbox"
                                className="checkbox w-5 h-5 md:h-7 md:w-7 md:flex-initial my-auto"
                            />
                            <p
                                className={`text-xs xs:text-sm ml-2 text-left text-[632e3e]  ${config.fontBody}`}
                            >
                                I have agreed to all
                                <a href="#" className="hover:underline">
                                    {` Terms & Conditions `}
                                </a>
                                and Privacy Policy laid down by Loyalie and its
                                partners/clients
                            </p>
                        </div>
                        <button
                            value="submit"
                            type="submit"
                            disabled={!formIsValid}
                            className={`rounded-lg cursor-pointer disabled:cursor-not-allowed font-medium bg-primary disabled:hover:bg-gray-200 disabled:hover:border-none disabled:bg-gray-200 hover:bg-white text-white disabled:hover:text-white hover:text-primary hover:border hover:border-primary px-10 h-10 mt-4 xs:mt-6 ${config.fontBody}`}
                        >
                            Submit
                        </button>
                    </form>
                </div>
                {showModal && (
                    <Modal
                        visible={showModal}
                        showButton
                        showCloseButton={false}
                        onClick={() => setShowModal(false)}
                    >
                        <div className="flex flex-col px-5 items-center">
                            <GrClose
                                onClick={() => setShowModal(false)}
                                className={`self-end h-5 w-5 cursor-pointer `}
                            />
                            {loading && <LoadingSpinner />}
                            {!loading && (
                                <>
                                    {' '}
                                    <div className=" rounded-full bg-green-500 p-2 md:p-4 animate-pulse">
                                        <TiTick className=" text-white h-7 w-7 md:h-8 md:w-8" />
                                    </div>
                                    <p className="my-4 text-center">
                                        {modalMessage}
                                    </p>
                                </>
                            )}
                        </div>
                    </Modal>
                )}
            </div>
        );
    };

    const referNowHandler = () => {
        if (isAuthenticated) {
            executeScrollReferral();
        } else {
            openLoginModal();
        }
    };

    const executeScrollAmen = () => scrollToTargetAdjusted('amenities');
    const executeScrollLoc = () =>
        scrollToTargetAdjusted('location_highlights');
    const executeScrollGallery = () => scrollToTargetAdjusted('gallery');
    const executeScrollReferral = () => {
        scrollToTargetAdjusted('referral_form');
    };

    return (
        <Fragment>
            {loadingProject && (
                <div className="h-screen">
                    <DetailProjectLoadingUI />
                </div>
            )}
            {!loadingProject && (
                <>
                    {headerClass.addHeader && (
                        <div
                            className={`${headerClass?.headerClass} ${config?.bannerNegativeTopMarginClass}`}
                        ></div>
                    )}
                    <div
                        className={`${
                            headerClass.addHeader
                                ? ''
                                : config?.bannerNegativeTopMarginClass
                        } relative`}
                        style={{
                            backgroundImage: `url("${config?.projects.moduleConfig?.sectionBackgroundImage}")`,
                            backgroundAttachment: 'fixed',
                            backgroundSize: `${'30% auto'} `
                        }}
                    >
                        {config?.projects.moduleConfig?.overlayClass && (
                            <div
                                className={`${config?.projects.moduleConfig?.overlayClass} opacity-95 absolute top-0 bottom-0 left-0 right-0 z-0`}
                            ></div>
                        )}

                        <div className="lg:flex z-10">
                            <div className="z-10 flex md:flex">
                              
                                <div
                                    className={`z-20 lg:h-[100vh] lg:sticky lg:top-0 w-[100vw] lg:w-[30vw] lg:pl-8 lg:pr-4 py-8 mb-20 ${
                                        config?.projects.moduleConfig
                                            ?.leftPaneColor !== undefined
                                            ? config?.projects.moduleConfig
                                                  ?.leftPaneColor
                                            : ' bg-white'
                                    }`}
                                >
                                    <div className="z-20 lg:hidden max-h-full px-6">
                                    <img
                                        variant="square"
                                        loading="lazy"
                                        className={` ${
                                            config?.projects.moduleConfig
                                                ?.QRCodeImage
                                                ? 'h-full max-h-full '
                                                : 'h-56 max-h-56 '
                                        } z-10
                            w-full mb-4 object-center object-cover rounded-lg`}
                                        src={projectDetails?.projectBgImage}
                                        alt="property"
                                    />
                                </div>
                                    <ProjectDetailNew
                                        fromInfluencerLink={fromInfluencerLink}
                                        referClick={executeScrollReferral}
                                        projectDetails={projectDetails}
                                    />
                                    {/* <div className="z-10 h-[0.5px] bg-gray-200 mt-4 mb-4 sm:mb-2 md:mb-4 lg:mb-2 xl:mb-4 mx-8" /> */}
                                    <div
                                        className={`${config.fontTitle} z-10 grid grid-cols-3 gap-2 xl:gap-3 2xl:gap-4  py-2 mt-4 sm:mt-2 md:mt-4 lg:mt-2 xl:mt-4 mb-2 px-8 lg:px-0 `}
                                    >
                                        <button
                                            className={`z-10 hover:text-primary text-sm xl:text-[16px] text-left xl:text-center  border-gray-200 `}
                                            onClick={executeScrollGallery}
                                        >
                                            Gallery
                                        </button>
                                        <button
                                            className={` z-10 hover:text-primary   text-sm xl:text-[16px] text-left   xl:text-center  border-gray-200 `}
                                            onClick={executeScrollAmen}
                                        >
                                            Amenities
                                        </button>
                                        <button
                                            className={` z-10 hover:text-primary  text-left xl:text-center  text-sm xl:text-[16px]  `}
                                            onClick={executeScrollLoc}
                                        >
                                            Highlights
                                        </button>
                                    </div>

                                    <svg viewBox="0 0 457 2">
                                        <defs></defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g
                                                id="Layer_1-2"
                                                data-name="Layer 1"
                                            >
                                                <polygon
                                                    class="cls-1"
                                                    points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1"
                                                    fill="#e5e5e5"
                                                />
                                            </g>
                                        </g>
                                    </svg>

                                    {projectDetails?.supportsReferral && (
                                        <div
                                            className={`${config.fontTitle} flex lg:flex-wrap xl:flex-nowrap gap-x-4 mt-6 z-10 self-center px-8 lg:px-0 2xl:px-8 space-y-2`}
                                        >
                                            <button
                                                onClick={enquireNowHandler}
                                                className={`w-[40%] h-14 lg:w-full xl:w-1/2 hover:text-lg text-white py-3 mt-2 bg-primary rounded-md fixed bottom-4 left-0  md:relative`}
                                            >
                                                Enquire Now
                                            </button>

                                            {userRole != 'CUSTOMER' && (
                                                <button
                                                    onClick={
                                                        bookSiteVisitHandler
                                                    }
                                                    className={`w-1/2 lg:w-full xl:w-1/2 hover:text-lg py-3 border-2 border-gray-200 font-medium rounded-md `}
                                                >
                                                    Book A Site Visit
                                                </button>
                                            )}

                                            {userRole == 'CUSTOMER' && (
                                                <div className="border-2 border-gray-200 h-14 bg-white rounded w-1/2 lg:w-full xl:w-1/2 flex items-center rounded-md justify-center  gap-2 fixed bottom-4 md:-mt-2 right-0 w-full md:relative ">
                                                <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="text-primary"
                                                        width="17.865"
                                                        height="17.998"
                                                        viewBox="0 0 17.865 19.998"
                                                    >
                                                        <g
                                                            id="_7671601_present_gift_packaging_birthday_celebration_icon"
                                                            data-name="7671601_present_gift_packaging_birthday_celebration_icon"
                                                            transform="translate(-56.573 -32.76)"
                                                        >
                                                            <path
                                                                id="Path_6313"
                                                                data-name="Path 6313"
                                                                d="M306.42,35.36V36.2a6.133,6.133,0,0,1,3.632-.847l-3.632,1.334v.549h3.494c1.971,0,1.718-2.368.369-3.807l-.051-.054a1.818,1.818,0,0,0-1.326-.614c-1.123,0-2.187,1.113-2.724,1.779A1.529,1.529,0,0,1,306.42,35.36Z"
                                                                transform="translate(-238.427)"
                                                                fill="currentColor"
                                                            />
                                                            <rect
                                                                id="Rectangle_1842"
                                                                data-name="Rectangle 1842"
                                                                width="3.756"
                                                                height="3.756"
                                                                rx="1.878"
                                                                transform="translate(63.629 34.427)"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                id="Path_6314"
                                                                data-name="Path 6314"
                                                                d="M73.955,146.757a1.727,1.727,0,0,0-1.258-.537H67.874a1.543,1.543,0,0,1-1.422.948h-1.89a1.543,1.543,0,0,1-1.422-.948H58.312a1.739,1.739,0,0,0-1.738,1.815l.5,11.436a1.736,1.736,0,0,0,1.738,1.664H72.2a1.736,1.736,0,0,0,1.738-1.664l.5-11.436A1.722,1.722,0,0,0,73.955,146.757ZM63.522,160.224H58.809a.827.827,0,0,1-.829-.792L57.485,148a.829.829,0,0,1,.828-.864h2.1l-1.821,2.706,1.9-.358.19,2.47,2.836-4.575Zm9.508-.792a.827.827,0,0,1-.828.792H67.488V147.435l2.8,4.516.19-2.472,1.9.358-1.821-2.707H72.7a.829.829,0,0,1,.828.864Z"
                                                                transform="translate(0 -108.378)"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                id="Path_6315"
                                                                data-name="Path 6315"
                                                                d="M91.994,37.235h3.494v-.549l-3.632-1.334a6.13,6.13,0,0,1,3.632.847V35.36a1.531,1.531,0,0,1,.238-.818c-.537-.666-1.6-1.781-2.726-1.781a1.818,1.818,0,0,0-1.324.613l-.052.054C90.276,34.866,90.021,37.235,91.994,37.235Z"
                                                                transform="translate(-32.468)"
                                                                fill="currentColor"
                                                            />
                                                        </g>
                                                    </svg>
                                                    <button
                                                        onClick={
                                                            referNowHandler
                                                        }
                                                        className={`w-[40%] lg:w-full xl:w-1/2 hover:text-lg whitespace-nowrap mt-2 rounded-md `}
                                                    >

                                                        Refer Now
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {/* <div onClick={bookNowHandler} className='mx-8 lg:mx-0 2xl:mx-8'>
                                    <button className=' bg-primary text-white py-3 rounded-md w-full mt-3 hover:text-lg font-medium'>Book Now</button>
                                    </div> */}
                                    <div
                                        className={`${config.fontBody} z-10 mt-6 p-3 border-2 bordey-gray-200 font-bold rounded mx-8 lg:mx-0 2xl:mx-8`}
                                    >
                                        <p className={config.fontTitle}>
                                            RERA Details
                                        </p>
                                        <p
                                            className={`z-10 flex text-sm text-gray-600 md:my-1 my-1 break-all `}
                                        >
                                            {projectDetails?.reraNumber}
                                        </p>
                                        <p
                                            className={`z-10 flex text-sm text-gray-600 md:my-1 my-1 break-all `}
                                        >
                                            {projectDetails?.reraWebsite}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[100vw] lg:w-[70%] px-8 md:pt-8">
                                <div className="hidden lg:block z-20 max-h-full flex-1 flex justify-end">
                                    <img
                                        variant="square"
                                        loading="lazy"
                                        className={` ${
                                            config?.projects.moduleConfig
                                                ?.QRCodeImage
                                                ? 'h-full max-h-full '
                                                : 'h-72 max-h-72 '
                                        } z-10
                            w-full mb-2 object-center object-cover rounded-lg`}
                                        src={projectDetails?.projectBgImage}
                                        alt="property"
                                    />
                                </div>
                                <div
                                    className={` ${config.fontBody} z-10 flex justify-between items-center`}
                                >
                                    <div className="flex flex-col">
                                        <p
                                            className={`z-10 text-xl mt-3 font-bold sm:text-2xl text-[#130E2F] ${config.fontTitle}`}
                                        >
                                            {projectDetails.projectName}
                                        </p>
                                        <p
                                            className={`z-10 text-lg italics text-[#908F95] `}
                                        >
                                            {projectDetails.city}
                                        </p>
                                    </div>
                                <div className="z-10 flex  flec row gap-4 items-center justify-center py-2 px-2 md:px-6  text-black w-[130px] ">
                                     
                                        <button
                                         className=""
                                          title="Click to share"
                                         onClick={handleShareClick}
                                         >
                                            <div className='flex flex-row items-center gap-1 sm:gap-2'>
                                              <EnShareAlternative className='h-4 w-4 md:h-5 md:w-5'/>
                                              <p className="font-semibold pl-1  text-lg sm:text-xl underline">
                                                Share
                                              </p>
                                           </div>
                                       </button>
                                     
                                    {showShareModal && (
                                      <Modal
                                        visible={showShareModal}
                                        showButton
                                        showCloseButton={false}
                                        onClick={() => setShowShareModal(false)}
                                     >
                                     <div className="flex flex-col px-5 ">
                                     

                                   {loading && <LoadingSpinner />}
                                   {!loading && (
                                <>
                                <div className='flex justify-between items-center my-4 '>
                                   <h1 className=" text-lg sm:text-xl font-bold ">
                                           Share this Project
                                    </h1>
                                    <EiCloseO 
                                      onClick={() => setShowShareModal(false)}
                                      className={` h-7 w-7 sm:h-9 sm:w-10 cursor-pointer   text-gray-500`}/>
                                    
                                </div>

                                <svg viewBox="0 0 457 2">
                                        <defs></defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g
                                                id="Layer_1-2"
                                                data-name="Layer 1"
                                            >
                                                <polygon
                                                    class="cls-1"
                                                    points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1"
                                                    fill="#e5e5e5"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                    
                               <div className="flex gap-5 items-center my-5">
                                   <img
                                   variant="square"
                                   loading="lazy"
                                    className={`${
                                   config?.projects.moduleConfig?.QRCodeImage
                                   ? 'h-10 w-10' 
                                   : 'h-20 w-28' 
                                   } z-10  object-center object-cover rounded-lg`}
                                  src={projectDetails?.projectBgImage}
                                  alt="property"
                                  />
                                  <div className='flex flex-col '>
                                  <p className='text-black font-semibold text-base sm:text-lg'>{projectDetails?.projectName}</p>
                                  <p className='text-[#908F95] text-sm sm:text-base italic'>{projectDetails.city}</p>
                                  </div>
                                  
                                </div>
                                <svg viewBox="0 0 457 2">
                                        <defs></defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g
                                                id="Layer_1-2"
                                                data-name="Layer 1"
                                            >
                                                <polygon
                                                    class="cls-1"
                                                    points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1"
                                                    fill="#e5e5e5"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                <div className="flex flex-wrap justify-between items-center mt-5 ">
                                  <div className={` w-[90%] lg:w-[45%]  md:w-[45%]  cursor-pointer  flex gap-2  border border-gray-400 rounded-[10px] p-3 my-2`}>
                                    <button 
                                       title={
                                       copiedUrl
                                        ? 'Link copied'
                                        : 'Click to copy'
                                        }
                                        className=''
                                        onClick={() => copyCodeHandler()}
                                    >
                                    {!copiedUrl && ( 
                                     <div className='flex items-center gap-3'>
                                       <FiCopy
                                        className="cursor-pointer w-5 h-full"
                                       />
                                            <p className='font-bold text-sm sm:text-base'>Link Copy</p>
                                     </div>
                                     
                                     )}
                                    {copiedUrl && <p className='mx-auto px-6 self-center  rounded-lg text-center font-normal animate-pulse bg-white text-xs sm:text-sm   text-[#006EB7] '>Link Copied</p>}
                                   </button>
                                  </div>
                                   <div className={` w-[90%] lg:w-[45%] md:w-[45%]  cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}>
                                    <EmailShareButton
                                       url={userRole === 'CUSTOMER' ? link : window.location.href}
                                       title={"Hello! Hope you're doing well. I wanted to share this exciting project I found recently. Here's the link: 🏘️ Click to learn more!"}
                                       
                                    >  
                                      <div className='flex items-center gap-3'>
                                        < MdcEmailOutline className='w-5 h-full'/>
                                        <p className='  font-bold text-sm sm:text-base'>
                                        Email
                                       </p>
                                      </div>
                                        
                                     </EmailShareButton>
                               
                                   </div>
                                  <div className={` w-[90%] lg:w-[45%]  md:w-[45%] cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}>
                                   <WhatsappShareButton
                                      url={userRole === 'CUSTOMER' ? link : window.location.href}
                                      title={"Hey there! I came across this amazing project that I think you'd love. Check it out: 🏡 Click on the below link to know more!"}
                                        
                                    >
                                        <div className='flex items-center gap-3'>
                                        <SiWhatsapp className="w-5 h-full" />
                                       
                                        <p className='  font-bold text-sm sm:text-base '>
                                        WhatsApp
                                       </p>
                                        </div>
                                    </WhatsappShareButton>
                                  </div>
                                  <div className={` w-[90%] lg:w-[45%]  md:w-[45%] cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}>

                                   <FacebookShareButton
                                      url={userRole === 'CUSTOMER' ? link : window.location.href}
                                      title={"Hey friends! Just stumbled upon this incredible project that I couldn't wait to share with you all. Take a look: 🌟 Click on the below link to explore further!"}
                                       
                                    >
                                        <div className='flex items-center gap-3'>
                                        <FaFacebookF className="w-5 h-full" />
                                        <p className='font-bold text-sm sm:text-base '>
                                          Facebook
                                       </p>
                                        </div>
                                    </FacebookShareButton>
                                  </div>
                                  <div className={` w-[90%] lg:w-[45%]  md:w-[45%]  cursor-pointer flex gap-2 border border-gray-400 rounded-[10px] p-3 my-2`}>
                                   <TwitterShareButton
                                      url={userRole === 'CUSTOMER' ? link : window.location.href}
                                      title={"Exciting news! Discovered a fantastic project worth checking out. Here's the link: 🏢 Click to learn more!"}
                                      
                                    >
                                       <div className='flex items-center gap-3'>
                                        <svg
                                            size={20}
                                            className="w-5 text-black"
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M8 2H1L9.26086 13.0145L1.44995 21.9999H4.09998L10.4883 14.651L16 22H23L14.3917 10.5223L21.8001 2H19.1501L13.1643 8.88578L8 2ZM17 20L5 4H7L19 20H17Z"></path>
                                         </svg>
                                         <button className='  font-bold text-sm sm:text-base'>
                                          Twitter
                                         </button>
                                       </div>
                                    </TwitterShareButton>
                                  </div>
                                </div>
                               </>
                                  )}
                                  </div>
                                   </Modal>
                                   )}
                            </div>

                        </div>
                                <div className="flex flex-col mt-3">
                                    <h4
                                        className={`z-10 text-xl mt-2 text-[#130E2F] font-bold ${config.fontTitle}`}
                                    >
                                        Project Description
                                    </h4>
                                    <p
                                        className={`text-[#130E2F] mt-2 z-10 ${config.fontBody}`}
                                    >
                                        {projectDetails?.projectDescription}
                                    </p>
                                </div>

                                {projectGallery?.length > 0 && (
                                    <div>
                                        <ProjectGalleryNew
                                            projectGallery={projectGallery}
                                        />
                                    </div>
                                )}
                                {VrGallery?.length > 0 && (
                                    <div>
                                        <VRGallery VrGallery={VrGallery} />
                                    </div>
                                )}
                                {!projectDetails.readyToOccupy && (
                                    <ProjectConsUpdateNew
                                        constructionGallery={
                                            constructionGallery
                                        }
                                        constructionProgress={
                                            Math.round(
                                                projectDetails.constructionProgress *
                                                    10
                                            ) / 10
                                        }
                                    />
                                )}
                                {projectDocs?.length > 0 && (
                                    <div>
                                        <ProjectDocs documents={projectDocs} />
                                    </div>
                                )}
                                 {projectBrochure && (
                                    <div>
                                        <ProjectBrochures brochure={projectBrochure} />
                                    </div>
                                )}
                                {projectAmenities?.length > 0 && (
                                    <div>
                                        <ProjectAmenitiesNew
                                            consVisible={
                                                !projectDetails.readyToOccupy
                                            }
                                            amenties={projectAmenities}
                                            propertyBackgroundColorWithOverlay={
                                                propertyBackgroundColorWithOverlay
                                            }
                                        />
                                    </div>
                                )}
                                <div>
                                    <ProjectLocationNew
                                        consVisible={
                                            !projectDetails.readyToOccupy
                                        }
                                        projectId={projectId}
                                        projectDetails={projectDetails}
                                        address={
                                            projectDetails?.address +
                                            (projectDetails?.addressLine2
                                                ?.length > 0
                                                ? ', ' +
                                                  projectDetails.addressLine2
                                                : '')
                                        }
                                        highlightCategories={
                                            highlightCategories
                                        }
                                        projectAdditionalHighlights={
                                            projectAdditionalHighlights
                                        }
                                    />
                                </div>
                                <div>
                                    {(isAuthenticated || fromInfluencerLink) &&
                                        projectDetails?.supportsReferral &&
                                        referralForm()}
                                </div>
                                <Disclaimer
                                    disclaimer={projectDetails.disclaimer}
                                />
                            </div>
                        </div>
                        </div>
                </>
            )}
        </Fragment>
    );
};

export default Content;
