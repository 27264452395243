import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
const domain = window.location.origin;
const MaxConfig = {
    clientId:
        '98a4ea4028d47686c954d430b36af8aa288eccdfc8a9e28afcd1a266ec01252f',
    id: 39,
    name: 'Max Estates',
    fullName: 'MAX ESTATES',
    programName:'Max Estates',
    primaryColor: '#045ba8',
    // fontBody: `${getFont('MAX').body}`,
    // fontTitle: `${getFont('MAX').heading}`,
    theme: 'maxTheme',
    favicon: 'https://cdn.loyalie.in/44651579.png',
    setSolidBg: 'bg-white',
    googleConfig: {
        gID: '',
        keywordsContent: '',
        descriptionContent: '',
        authorContent: 'Max Estates'
    },

    facebookPixelConfig: {
        id: '', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~~47b6668a',
    webengageAPIKey:'22deb6b5-0501-41a2-a881-592162e5cc49',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: true,

    logoLeft: {
        name: 'Max Estates Logo',
        url: 'https://cdn.loyalie.in/46482371.png',
        stickyNavBarLogoClass:
            'h-14 md:h-20 lg:h-12 xl:h-14 2xl:h-16 pl-4 lg:pl-10 py-2 ease-in duration-300 ',
        baseNavBarLogoClass:
            ' h-12 md:h-20  lg:h-16 xl:h-20 2xl:h-24 pl-4 lg:pl-10 lg:py-2 ease-out duration-300'
    },

    // logoRight: {
    //     name: 'Max Estates Logo',
    //     url: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/2297157.png',
    //     stickyNavBarLogoClass:
    //         'hidden lg:block lg:h-10 2xl:h-12 pr-3 xl:pr-10 ',
    //     baseNavBarLogoClass: 'h-20 md:h-25 lg:h-10 xl:h-12 pr-3 xl:pr-10 '
    // },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('MAX').heading}`,
        height: 'h-16 xl:h-20 2xl:h-24 ease-out duration-300 drop-shadow-lg',
        stickyHeight:
            'h-14 md:h-16 lg:h-12 xl:h-14 2xl:h-16 ease-in duration-300 drop-shadow-lg	',
        mobileIconColor: 'text-primary-focus',
        dropDownBg: 'bg-white lg:bg-accent',
        showActiveLinkColor: false,
        footerNavItemClass: `tracking-widest uppercase text-white text-[12px] md:text-[14px] lg:text-white hover:text-primary font-bold lg:text-[12px] xl:text-[14px] 3xl:text-lg  ${getFont('MAX').body
            }`,
        baseNavBar: {
            border: true,
            borderClass: '',
            backgroundColor: 'bg-white', // default: transparent
            containerClass: '',
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black text-[14px] lg:text-secondary hover:text-primary font-bold lg:text-[12px] xl:text-[14px] 3xl:text-lg ',
            dropDownContainerClass: `hover:text-primary lg:hover:text-white lg:hover:bg-primary lg:focus:text-secondary lg:focus:bg-primary lg:focus:outline-none lg:focus:shadow-outline  `,
            dropDownItemClass:
                'tracking-widest text-black lg:text-white text-[14px] font-bold lg:text-[12px] xl:text-[14px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: true,
            borderClass: '',
            backgroundColor: 'bg-primary-content', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black text-[14px] lg:text-secondary hover:text-primary font-bold lg:text-[12px] xl:text-[14px] 3xl:text-lg ' // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 ${getFont('MAX').bold
        }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage: 'https://cdn.loyalie.in/80244209.jpg',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/50308552.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    bannerContainerClass:
                        'h-[65vh] md:h-[80vh] lg:h-[90vh] bg-right-bottom lg:bg-center',
                    bannerItemContainerClass:
                        'items-start justify-center md:pt-0 md:px-8 pl-4 lg:pl-12 pt-6 lg:pt-10',
                    position: 'text-left',

                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Redefining modern living, unleashing a vibrant lifestyle!',
                            className: `lg:mt-12 text-white md:text-left font-normal text-[15px] md:text-[20px] lg:text-[20px] xl:text-[22px] 2xl:text-[24px] tracking-wide w-[65%] md:w-[50%] lg:w-[35%] xl:w-[30%] leading-5 md:leading-6 xl:leading-7 2xl:leading-8 ${getFont('MAX').body
                                }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'w-[15%] md:w-[5%] bg-primary h-[5px] 2xl:h-[6px] my-3 md:my-5'
                        },
                        {
                            type: 'TEXT',
                            content: 'Introducing',
                            className: ` text-white md:text-left font-normal md:text-[17px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] ${getFont('MAX').body
                                }`
                        },
                        {
                            type: 'TEXT',
                            content: 'Max Estates LiveWell',
                            className: `text-white text-[23px] md:text-[35px] lg:text-[33px] xl:text-[37px] 2xl:text-[42px] tracking-wide font-normal lg:-mt-2 ${getFont('MAX').heading
                                }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'A Loyalty & Referral Program for the Homeowners of Max Estates.',
                            className: ` text-white md:text-left font-normal text-[14px] md:text-[17px] lg:text-[16px] xl:text-[17px] 2xl:text-[18px] leading-5 md:leading-6 lg:leading-5 xl:leading-6 2xl:leading-6  tracking-wide  w-[60%] md:w-[50%] lg:w-[35%] xl:w-[30%] xl:w-[29%] 2xl:w-[30%] ${getFont('MAX').body
                                }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'Refer Now',
                            className: ` text-center md:text-left mt-4 md:mt-6 text-[15px] md:text-[17px] lg:text-[15px] xl:text-[17px] 2xl:text-[19px] tracking-wider bg-accent text-white font-semibold rounded-md py-3 md:py-2 2xl:py-[11px] px-6 md:px-6 uppercase ${getFont('MAX').body
                                }`,
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH_AND_SCROLL',
                            scrollTo: 'referral_section'
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    className: 'w-full py-5 lg:py-10',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'About Max Estates',
                            className: ` text-secondary pt-6 text-[28px] lg:text-[32px] xl:text-[36px] 2xl:text-[46px] tracking-wide leading-7 2xl:leading-8 font-normal px-6 md:pl-12  ${getFont('MAX').heading
                                }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                'w-[35%] lg:w-[17%] xl::w-[16%] 2xl:w-[15%] bg-secondary border-none h-[4px] 2xl:h-[6px]',
                            classNameContainer: ' py-4 '
                        },
                        {
                            type: 'TEXT',
                            content: `Max Estates is the real estate development arm of the Max Group. Established in 2016, the mission of Max Estates is to offer spaces for Residential and Commercial use with utmost attention to detail, design and lifestyle. Max Estates aspires to be the most trusted Real Estate company driven by the desire to enhance the well-being of everyone we touch.`,
                            className: `xl:pt-5 px-6 text-[17px] md:text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[19px] text-secondary-focus leading-6 xl:leading-7 break-words md:px-10 lg:pl-12 pt-6 lg:pt-0 pb-4 w-[98%]  ${getFont('MAX').body
                                }`
                        }
                        // {
                        //     type: 'TEXT',
                        //     content: `Furthermore, we deeply value your role in expanding our community, and we want to express our heartfelt appreciation. That's why we have meticulously crafted exclusive benefits to prioritize your experience and put you at the centre of affluence.`,
                        //     className: `xl:pt-2 px-6 text-[17px] md:text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] text-secondary-focus leading-5 xl:leading-7 break-words md:px-10 lg:pl-12 pt-6 lg:pt-0 pb-4 w-[98%]  ${getFont('MAX').body
                        //         }`,
                        // }
                        // {
                        //     type: 'CUSTOM',
                        //     id: 'overview',
                        //     className: 'mx-auto overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-3 w-[90%] items-center justify-center -mb-44 xl:-mb-52 2xl:-mb-56 pt-14 xl:pt-16',
                        //     components: [
                        //         {
                        //             type: 'CUSTOM',
                        //             className:
                        //                 'w-full text-center ',
                        //             components: [

                        //             ]
                        //         },
                        //         {
                        //             type: 'CUSTOM',
                        //             className:
                        //                 'w-full text-center z-10 relative ',
                        //             components: [
                        //                 {
                        //                     type: 'IMAGE',
                        //                     url: 'https://cdn.loyalie.in/8726102.png',
                        //                     position: 'center',
                        //                     classNameContainer:
                        //                         'w-[71%] xl:w-[74%] 2xl:w-[72%] h-full mx-auto'
                        //                 }
                        //             ]
                        //         },
                        //         {
                        //             type: 'CUSTOM',
                        //             className:
                        //                 'w-full text-center ',
                        //             components: [

                        //             ]
                        //         }
                        //     ]
                        // },
                        // {
                        //     type: 'CUSTOM',
                        //     id: 'overview',
                        //     className: 'mx-auto overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-3 w-[90%]  -mb-16 items-center h-[130px] justify-center bg-white shadow-inner drop-shadow-lg z-0  ',
                        //     components: [
                        //         {
                        //             type: 'CUSTOM',
                        //             className:
                        //                 'w-full ',
                        //             components: [
                        //                 {
                        //                     type: 'TEXT',
                        //                     content: `GIFT REFERRAL DISCOUNTS & EARN UP TO RS. XXXX`,
                        //                     className: `text-[17px] xl:text-[20px] 2xl:text-[22px] text-secondary-focus leading-5 xl:leading-7 2xl:leading-8 break-words pl-20 xl:pl-24 2xl:pl-28 ${getFont('MAX').body
                        //                         }`,
                        //                     onClick: 'SCROLL',
                        //                     scrollTo: 'referral_section'
                        //                 }
                        //             ]
                        //         },
                        //         {
                        //             type: 'CUSTOM',
                        //             className:
                        //                 'w-full text-center ',
                        //             components: [

                        //             ]
                        //         },
                        //         {
                        //             type: 'CUSTOM',
                        //             className:
                        //                 'w-full text-center ',
                        //             components: [
                        //                 {
                        //                     type: 'BUTTON',
                        //                     content: 'GIFT NOW',
                        //                     className: `md:mx-0 text-center md:text-left text-[15px] md:text-[15px] tracking-wider bg-accent text-white font-semibold rounded-md py-3 md:py-2 2xl:py-3 px-6 md:px-8 uppercase  ${getFont('MAX').body
                        //                         }`,
                        //                     path: 'deals',
                        //                     onClick: 'CHANGE_PATH'
                        //                 }
                        //             ]
                        //         },
                        //     ]
                        // },

                        // {
                        //     type: 'CUSTOM',
                        //     id: 'overview',
                        //     className: 'bg-accent h-[450px] xl:h-[550px] 2xl:h-[620px] z-0',
                        //     components: [

                        //     ]
                        // },
                        // {
                        //     type: 'CUSTOM',
                        //     id: 'overview',
                        //     className: 'z-10 -mt-56 2xl:-mt-64 ',
                        //     components: [
                        //         {
                        //             type: 'CUSTOM',
                        //             id: 'overview',
                        //             className:
                        //                 ' flex lg:grid lg:grid-cols-3 gap-x-2 lg:gap-x-10 2xl:gap-x-16 py-7 lg:py-12 lg:w-full px-6 lg:px-16 2xl:px-20 h-auto',
                        //             components: [
                        //                 {
                        //                     type: 'CUSTOM',
                        //                     className:
                        //                         ' items-center min-w-[80%] md:min-w-[50%] lg:w-full bg-white hover:bg-primary text-secondary-focus hover:text-white  drop-shadow-xl',
                        //                     components: [
                        //                         {
                        //                             type: 'CUSTOM',
                        //                             className:
                        //                                 'w-full',
                        //                             components: [
                        //                                 {
                        //                                     type: 'IMAGE',
                        //                                     url: 'https://cdn.loyalie.in/6178028.png',
                        //                                     position: 'center',
                        //                                     classNameContainer:
                        //                                         'w-[80%] h-full mx-auto -mt-28 xl:-mt-40 2xl:-mt-44'
                        //                                 },
                        //                             ]
                        //                         },

                        //                         {
                        //                             type: 'CUSTOM',
                        //                             className:
                        //                                 'w-full px-6 xl:px-8 2xl:px-12 py-8 mx-auto text-center uppercase',
                        //                             components: [

                        //                                 {
                        //                                     type: 'TEXT',
                        //                                     content: `Enjoy membership benefits from top brands`,
                        //                                     className: `cursor-pointer text-[15px] xl:text-[20px] 2xl:text-[22px] leading-5 xl:leading-7 2xl:leading-8 break-words      text-center ${getFont('MAX').body
                        //                                         }`,
                        //                                     onClick: 'SCROLL',
                        //                                     scrollTo: 'referral_section'
                        //                                 },
                        //                                 {
                        //                                     type: 'BUTTON',
                        //                                     content: 'AVAIL NOW',
                        //                                     className: ` mt-4 lg:mt-6 text-sm xl:text-[15px] uppercase tracking-wider font-normal py-3 px-6 xl:py-4 xl:px-10 text-white bg-accent rounded-md drop-shadow-lg    ${getFont('MAX').body
                        //                                         }`,
                        //                                     path: 'deals',
                        //                                     onClick: 'CHANGE_PATH'
                        //                                 }
                        //                             ]
                        //                         }
                        //                     ]
                        //                 },

                        //                 {
                        //                     type: 'CUSTOM',
                        //                     className:
                        //                         ' items-center min-w-[80%] md:min-w-[50%] lg:w-full bg-white hover:bg-primary text-secondary-focus hover:text-white  drop-shadow-xl',
                        //                     components: [
                        //                         {
                        //                             type: 'CUSTOM',
                        //                             className:
                        //                                 'w-full',
                        //                             components: [
                        //                                 {
                        //                                     type: 'IMAGE',
                        //                                     url: 'https://cdn.loyalie.in/6390115.png',
                        //                                     position: 'center',
                        //                                     classNameContainer:
                        //                                         'w-[80%] h-full mx-auto -mt-28 xl:-mt-40 2xl:-mt-44'
                        //                                 },
                        //                             ]
                        //                         },

                        //                         {
                        //                             type: 'CUSTOM',
                        //                             className:
                        //                                 'w-full lg:px-12 xl:px-8 xl:px-20 py-8 mx-auto text-center uppercase',
                        //                             components: [

                        //                                 {
                        //                                     type: 'TEXT',
                        //                                     content: `Participate in curated events`,
                        //                                     className: `cursor-pointer text-[15px] xl:text-[20px] 2xl:text-[22px] leading-5 xl:leading-7 2xl:leading-8 break-words      text-center ${getFont('MAX').body
                        //                                         }`,
                        //                                     onClick: 'SCROLL',
                        //                                     scrollTo: 'referral_section'
                        //                                 },
                        //                                 {
                        //                                     type: 'BUTTON',
                        //                                     content: 'KNOW MORE',
                        //                                     className: ` mt-4 lg:mt-6 text-sm xl:text-[15px] uppercase tracking-wider font-normal py-3 px-6 xl:py-4 xl:px-10 text-white bg-accent rounded-md drop-shadow-lg    ${getFont('MAX').body
                        //                                         }`,
                        //                                     path: 'deals',
                        //                                     onClick: 'CHANGE_PATH'
                        //                                 }
                        //                             ]
                        //                         }
                        //                     ]
                        //                 },

                        //                 {
                        //                     type: 'CUSTOM',
                        //                     className:
                        //                         ' items-center min-w-[80%] md:min-w-[50%] lg:w-full bg-white hover:bg-primary text-secondary-focus hover:text-white  drop-shadow-xl',
                        //                     components: [
                        //                         {
                        //                             type: 'CUSTOM',
                        //                             className:
                        //                                 'w-full',
                        //                             components: [
                        //                                 {
                        //                                     type: 'IMAGE',
                        //                                     url: 'https://cdn.loyalie.in/67449626.png',
                        //                                     position: 'center',
                        //                                     classNameContainer:
                        //                                         'w-[80%] h-full mx-auto -mt-28 xl:-mt-40 2xl:-mt-44'
                        //                                 },
                        //                             ]
                        //                         },

                        //                         {
                        //                             type: 'CUSTOM',
                        //                             className:
                        //                                 'w-full lg:px-8 2xl:px-20 py-8 mx-auto text-center uppercase',
                        //                             components: [

                        //                                 {
                        //                                     type: 'TEXT',
                        //                                     content: `Explore our home & lifestyle guide`,
                        //                                     className: `cursor-pointer text-[15px] xl:text-[20px] 2xl:text-[22px] leading-5 xl:leading-7 2xl:leading-8 break-words      text-center ${getFont('MAX').body
                        //                                         }`,
                        //                                     onClick: 'SCROLL',
                        //                                     scrollTo: 'referral_section'
                        //                                 },
                        //                                 {
                        //                                     type: 'BUTTON',
                        //                                     content: 'READ NOW',
                        //                                     className: ` mt-4 lg:mt-6 text-sm xl:text-[15px] uppercase tracking-wider font-normal py-3 px-6 xl:py-4 xl:px-10 text-white bg-accent rounded-md drop-shadow-lg    ${getFont('MAX').body
                        //                                         }`,
                        //                                     path: 'deals',
                        //                                     onClick: 'CHANGE_PATH'
                        //                                 }
                        //                             ]
                        //                         }
                        //                     ]
                        //                 },
                        //             ]
                        //         }
                        //     ]
                        // },
                    ]
                },

                //REFERRAL FORM
                {
                    type: 'REFERRAL_FORM_BANNER'
                },
                // {
                //     type: 'CUSTOM',
                //     // url: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
                //     linearGradient: {
                //         R: '255',
                //         G: '255',
                //         B: '255',
                //         opacity: '0.35',
                //         size: 'cover'
                //     },
                //     className:
                //         ' xl:w-full w-[100%] text-[#f58020] pt-5 ',
                //     components: [
                //         {
                //             type: 'TEXT',
                //             content: 'OUR EXCLUSIVE OFFERS',
                //             className: `text-secondary-focus uppercase pt-6 text-[20px] lg:text-[25px] xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal py-4 mx-24 mt-12 pl-10 border-l-[6px] border-primary  ${getFont('MAX').heading
                //                 }`
                //         },
                //         {
                //             type: 'CAROUSEL',

                //             getVendorData: true,
                //             offerCards: true,
                //             backgroundImageNone: true,
                //             classNameCTA: `bg-accent text-[14px] px-4 py-2 text-white text-center mt-3 mx-auto uppercase font-normal rounded-md ${
                //                 getFont('MAX').body
                //             }`,
                //             border: 'rounded-full shadow-inner !w-[250px] h-[250px] ',
                //             ContainerClassName:
                //                 'bg-cover rounded-lg h-auto ',
                //             caraouselClassName:
                //                 'w-[80%] md:w-full xl:w-[80%] 2xl:w-[90%] 3xl:w-[75%] mx-auto md:px-10  pt-6 relative pb-10 drop-shadow-xl',
                //             arrows: true,
                //             autoplay: true,
                //             autoplaySpeed: 3000,
                //             infinite: true,
                //             dots: false,
                //             slidesToShow: 4,
                //             pauseOnHover: true,
                //             speed: 3000,
                //             onImageClick: openURLNewWindow
                //         },
                //         {
                //             type: 'TEXT',
                //             content: 'OUR LATEST BLOGS',
                //             className: `text-secondary-focus uppercase pt-6 text-[20px] lg:text-[25px] xl:text-[30px] tracking-wide leading-7 2xl:leading-8 font-normal py-4 mx-24 mt-12 pl-10 border-l-[6px] border-primary  ${getFont('MAX').heading
                //                 }`
                //         },
                //         { type: 'BLOG_LAYOUT_VERTICAL1' }
                //     ]
                // },
                {
                    type: 'CUSTOM',
                    className:
                        'xl:text-center  text-center xl:w-full w-[100%] pt-8',
                    components: [
                        {
                            type: 'CUSTOM',
                            className: 'w-full lg:flex items-center',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className: 'w-[20%] ',
                                    components: [
                                        {
                                            type: 'TEXT',
                                            content: 'Group Projects',
                                            className: `text-secondary text-[28px] lg:text-[32px] xl:text-[36px] 2xl:text-[46px] tracking-wide leading-9 2xl:leading-12 font-normal mx-12 xl:mx-24 pl-6 xl:pl-10 border-l-[6px] border-secondary  ${getFont('MAX').heading
                                                }`
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className: 'mt-7 lg:mt-0 cursor-pointer',
                                    onClick:'CHANGE_PATH',
                                    path:`/projects/detail?projectId=5`,
                                    components: [
                                        {
                                            type: 'IMAGE',
                                                url: 'https://maxestates-prod.s3.ap-south-1.amazonaws.com/Document/Project/5/VgyCmbX4xn.jpeg',
                                                            position: 'center',
                                                            classNameContainer:
                                                                'w-[80%] h-full mx-auto',
                                                                // onImageClick: () =>  window.open(`${domain}/maxestates-home/projects/detail?projectId=5`)
                                        },
                                        {
                                            type: 'TEXT',
                                            content: 'Estate 128',
                                            className: ` text-sm lg:text-base mt-1 font-semibold hover:text-primary leading-8  ${getFont('MAX').body
                                                }`
                                        }
                                    ]
                                },
                                // {
                                //     type: 'CUSTOM',
                                //     className: 'w-full xl:w-[60%] mx-auto',
                                //     components: [
                                //         {
                                //             type: 'CAROUSEL',
                                //             customSlide: true,
                                //             caraouselClassName:
                                //                 'px-8 md:px-10 lg:pl-14 xl:pl-24 2xl:pl-36 md:pb-10 py-5 relative text-white ',
                                //             imageContainerClassName:
                                //                 'm-4 relative',
                                //             imageClassName:
                                //                 'w-[314px] h-[220px] xl:h-[235px] lg:bg-cover object-cover',
                                //             captionClass: ` text-sm lg:text-sm font-semibold  leading-8  ${getFont('MAX').body
                                //                 }`,
                                //             arrows: false,
                                //             autoplay: true,
                                //             autoplaySpeed: 3000,
                                //             infinite: true,
                                //             dots: true,
                                //             slidesToShow: 1,
                                //             xlSlidesToShow: 1,
                                //             minSlides: true,
                                //             pauseOnHover: true,
                                //             speed: 1500,
                                //             onImageClick: openURLNewWindow,
                                //             imgArr: [
                                //                 {
                                //                     imgUrl: 'https://maxestates-prod.s3.ap-south-1.amazonaws.com/Document/Project/5/VgyCmbX4xn.jpeg',
                                //                     alt: 'Estate 128',
                                //                     url: `${domain}/maxestates-home/projects/detail?projectId=5`,
                                //                     caption: 'Estate 128'
                                //                 }
                                //             ]
                                //         }
                                //     ]
                                // }
                            ]
                        }
                    ]
                }
            ]
        },

        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },

        dropDown: {
            show: false,
            name: 'ENGAGEMENT',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                },
                {
                    deals: {
                        show: true,
                        name: 'BENEFITS',
                        path: '/deals'
                    }
                }
            ]
        },
        deals: {
            show: false,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        events: {
            show: false,
            name: 'ENGAGEMENT',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        referPage: {
            show: true,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: false,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms & Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://cdn.loyalie.in/1692633683952_refer%20page%20banner',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1692633683952_refer%20page%20banner',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[72vh] lg:h-[70vh] object-cover bg-center  lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center lg:text-left text-black text-[15px] md:text-[16px] lg:text-[20px] xl:text-[22px] 2xl:text-[24px] tracking-wide my-5 tracking-widest md:w-[70%] lg:w-[50%] ml-auto leading-6 xl:leading-8 ${getFont('MAX').body
                                }`
                        },
                        {
                            type: 'CUSTOM',
                            className: 'flex md:w-[70%] lg:w-[50%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: ` mx-auto lg:mx-0 text-[15px] md:text-[17px] lg:text-[15px] xl:text-[17px] 2xl:text-[19px] tracking-wider bg-accent text-white font-semibold rounded-md py-3 md:py-2 2xl:py-[11px] px-6 md:px-6 uppercase ${getFont('MAX').body
                                        }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://cdn.loyalie.in/1692633683952_refer%20page%20banner',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1692633683952_refer%20page%20banner',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[40vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: []
            }
        ],

        customize: {
            claimButton: ` text-center md:text-left mt-4 md:mt-6 tracking-wider bg-accent text-white font-semibold rounded-md py-3 md:py-2 2xl:py-[11px] px-6 md:px-6 uppercase ${getFont('MAX').body
                }`
        }
    },
    blogs: {
        // sectionBackgroundImage:
        //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.35',
            size: 'cover'
        },
        components: [
            {
                type: 'BANNER',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[80vh] md:h-[70vh] lg:h-[97vh] lg:h-screen md:bg-top mx-auto text-center ',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.20'
                },
                backgroundImage:
                    'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/52762917.jpg',
                backgroundImageMobile:
                    'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/52762917.jpg',

                components: [
                    {
                        type: 'TEXT',
                        content: 'Max Estates Readers’ Club',
                        className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[25px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${getFont('MAX').heading
                            }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-secondary border-none h-[4px]',
                        classNameContainer: ''
                    },
                    {
                        type: 'TEXT',
                        content:
                            'Bringing Together Information And Entertainment For You.',
                        className: `mt-5 text-secondary font-medium text-[16px] lg:text-[20px] 2xl:text-[22px] leading-6 ${getFont('MAX').heading
                            }`
                    },
                    {
                        type: 'BUTTON',
                        content: 'Read More',
                        scrollTo: 'blogs_section',
                        onClick: 'SCROLL',
                        className: ` lg:mx-0 mx-auto text-center text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[11px] px-5 md:px-10 ${getFont('MAX').body
                            }`
                    }
                ]
            }
        ],
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[80vh] md:h-[70vh] lg:h-[97vh] lg:h-screen bg-center mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.20'
            },
            backgroundImage:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/52762917.jpg',
            backgroundImageMobile:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/52762917.jpg',

            components: [
                {
                    type: 'TEXT',
                    content: 'Max Estates Readers’ Club',
                    className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[25px] lg:text-[30px] xl:text-[35px] leading-7 text-secondary tracking-wider ${getFont('MAX').heading
                        }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-secondary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `mt-5 text-secondary font-medium text-[16px] lg:text-[20px] 2xl:text-[22px] leading-5 ${getFont('MAX').heading
                        }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[11px] px-5 md:px-10 ${getFont('MAX').body
                        }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center fixed lg:top-[68px] xl:top-[70px] right-0 w-[20%] lg:w-[83.5%] xl:w-[83.7%] 2xl:w-[85%] bg-secondary border-none lg:h-[2.5px] 2xl:h-[3px]',
                    classNameContainer: ''
                }
            ],

            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'Max Estates READERS’ CLUB',
                    className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[25px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${getFont('MAX').heading
                        }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `mt-5 text-secondary font-medium text-[16px] lg:text-[20px] 2xl:text-[22px] leading-6 ${getFont('MAX').heading
                        }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[11px] px-5 md:px-5 ${getFont('MAX').body
                        }`
                }
            ]
        },

        sideBarClass: `text-primary ${getFont('MAX').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${getFont('MAX').heading
            }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-accent text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },

        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-[#1c2936] ${getFont('MAX').heading
                    }`,
                headingClass: `text-[25px] md:text-[30px] uppercase text-primary mt-3 leading-7 md:leading-9 break-words ${getFont('MAX').heading
                    }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${getFont('MAX').heading
                    }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${getFont('MAX').heading
                    } `,
                buttonClass: `inline text-[17px] text-primary ml-1 ${getFont('MAX').heading
                    }`
            },
            sectionHeadings: `px-10 lg:px-16 pt-10 pb-2 text-[22px] lg:text-[25px] xl:text-[35px] tracking-wider text-center uppercase ${getFont('MAX').heading
                }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            dividerClass: 'hidden m-0 p-0 ',

            backgroundColor: 'bg-accent',
            cardHeight: ' h-[530px] lg:h-[520px] xl:h-[540px] 3xl:h-[580px] ',
            bodyHeight:
                ' h-[280px] md:h-[280px] lg:h-[270px] xl:h-[290px] 3xl:h-[380px]',
            headingClass: `text-primary my-5 md:my-7 text-[22px] 3xl:text-[20px] font-medium uppercase leading-6 font-semibold break-words mx-5 ${getFont('MAX').heading
                }`,

            summaryClass: `font-normal text-[16.5px] lg:text-[15px] xl:text-[17px] mt-2 lg:my-6 leading-6 text-secondary mx-5 ${getFont('MAX').body
                }`,
            categoryClass: 'bg-[#00000075] text-white text-[11px]',
            buttonClass: `bg-accent flex justify-center self-start text-[13px] xl:text-[14px] text-white py-2 px-4 md:px-4 m-4 ${getFont('MAX').body
                }`,
            dateClass: `text-[14px] md:text-[17px] xl:text-[15px]  self-center text-[#919396] ${getFont('MAX').heading
                }`,
            noBlogsClass: `bg-primary text-white p-2 md:w-[30%] lg:w-[70%] xl:w-[50%] 3xl:w-[30%] mt-10 ${getFont('MAX').body
                }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/32090097.jpg',
            mobileDefaultActiveBannerImage:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/78237744.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0',
                size: 'contain',
                position: 'top'
            },
            bannerContainerClassName: 'bg-top',
            negativeMargin: '',
            containerClass: ' text-white w-full md:w-[50%] ',
            heading: '',
            headingClass: `font-semibold text-left lg:text-right w-[90%] md:w-[70%] text-[25px] md:text-[30px] 2xl:text-[45px] text-secondary uppercase ${getFont('MAX').heading
                }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${getFont('MAX').heading
                }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: ` mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${getFont('MAX').heading
                        }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-secondary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${getFont('MAX').heading
                        }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    onClick: 'FUNC',
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6 text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${getFont('MAX').body
                        }`
                }
            ]
        },
        eventsSection: {
            // sectionBackgroundImage:
            //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.35',
                size: 'cover'
            },
            heading: 'PAST EVENTS',
            headingClass: `self-center text-center text-[22px] md:text-[24px] lg:text-[25px] xl:text-[35px] text-neutral w-[60%] mt-16 ${getFont('MAX').heading
                }`,
            components: [
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center fixed lg:top-[68px] xl:top-[70px] right-0 w-[20%] lg:w-[83.5%] xl:w-[83.7%] 2xl:w-[85%] bg-secondary border-none lg:h-[2.5px] 2xl:h-[3px]',
                    classNameContainer: ''
                }
            ],
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${getFont('MAX').body
                }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${getFont('MAX').body
                }`
        },
        eventLayout: {
            titleClass: `text-accent leading-5 uppercase text-[18px] font-medium mt-2 ${getFont('MAX').heading
                }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${getFont('MAX').heading
            }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${getFont('MAX').heading
            }`
    },
    deals: {
        showOnlyBanner: true,
        banner: {
            hideSearch: true,
            bannerContainerClassName:
                'hero hero-content max-w-full h-[80vh] md:h-[80vh] lg:h-[90vh] bg-center mx-auto text-center ', //bg-right bg-cover bg-top
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0' //0.5
            },
            backgroundImage:
                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1701805720672_ajmera offer banner',
            backgroundImageMobile:
                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1709539643268_Deals%20banner',
            components: [
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center fixed lg:top-[68px] xl:top-[70px] right-0 w-[20%] lg:w-[83.5%] xl:w-[83.7%] 2xl:w-[85%] bg-secondary border-none lg:h-[2.5px] 2xl:h-[3px]',
                    classNameContainer: ''
                }
            ],
            loginBannerComponents: [
                // {
                //     type: 'TEXT',
                //     content: 'EXCLUSIVE DIWALI OFFERS  ',
                //     className: `mt-40 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                //        getFont('MAX').heading
                //     }`
                // },
                // {
                //     type: 'TEXT',
                //     content: 'EXCLUSIVE DIWALI OFFERS',
                //     className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                //        getFont('MAX').heading
                //     }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Max Estates is driven to create experiences that add more value to your life',
                //     className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${
                //        getFont('MAX').heading
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC',
                //     className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                //        getFont('MAX').body
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC',
                //     className: ` mt-36 md:mt-44 lg:mt-16 xl:mt-20 lg:mx-0 mx-auto text-center text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                //        getFont('MAX').body
                //     }`
                // }
            ]
        },

        dealsSection: {
            // sectionBackgroundImage:
            //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.35',
                size: 'cover'
            },
            defaultBackgroundImage:
                'https://godrejambassador.com/wp-content/uploads/2021/12/website-Event_Banner.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center font-semibold md:text-[20px] text-center text-[17px] text-neutral w-[90%] ${getFont('MAX').heading
                }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `uppercase text-secondary text-2xl xl:text-3xl ${getFont('MAX').heading
                } `,
            subAdditionalText:
                'Max Estates is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: `font-normal text-[14px] py-2 mt-2 leading-6 ${getFont('MAX').body
                }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('MAX').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            // pattern1:
            //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
            // pattern2:
            //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.60',
                repeat: 'no-repeat',
                size: '140%',
                position: ''
            },
            listTitleClass: `text-[18px] leading-6 lg:leading-5 2xl:leading-6 text-primary ${getFont('MAX').body
                }`,
            listBodyClass: `${getFont('MAX').body}`,
            logoClassName: `self-center rounded-md drop-shadow-lg z-10 `,
            titleClassName: ` text-primary font-medium uppercase text-2xl xl:text-xl 2xl:text-2xl leading-7  mt-4 ${getFont('MAX').body
                } `,
            couponClassName: `block md:text-[22px] text-[19px] self-center text-center text-primary font-bold leading-7 ${getFont('MAX').body
                }`,
            dateClassName: `py-[6px] text-neutral capitalize text-[17px] text-secondary-focus  ${getFont('MAX').body
                }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-[14px]  ${getFont('MAX').body
                }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-neutral mx-auto text-[12px] break-words leading-1 ${getFont('MAX').body
                }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-accent text-white uppercase self-center text-[16px] py-2.5 px-7  ${getFont('MAX').body
                    }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `rounded bg-accent border-accent border-[2px] hover:text-accent hover:bg-white hover:font-semibold text-white uppercase self-center text-[16px] py-2.5 px-7 ${getFont('MAX').heading
                    }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.55'
                },
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Max-Estate/Images/39889394.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Max-Estate/Images/39889394.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[60vh] md:h-[70vh] lg:h-[85vh] md:bg-center',

                components: [
                    {
                        type: 'TEXT',
                        content: 'MAX ESTATES PROJECTS',

                        className: ` mx-auto uppercase text-center text-white mb-3 text-[28px] md:text-[25px] lg:text-[30px] xl:text-[35px] tracking-wider ${getFont('MAX').heading
                            }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-primary border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {
         
            constructionVisible: true,
            
            // sectionBackgroundImage:
            //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: '',
            addHeader: true,
            headerClass:
                'hidden lg:block h-24 border-b-[3px] border-secondary bg-white'
        }
    },
    // header: {
    //     headerClass: 'hidden lg:block lg:h-24 xl:h-28 bg-white '
    // },
    contactUs: {
        // sectionBackgroundImage:
        //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.35',
            size: 'cover'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.35'
                },
                backgroundImage: 'https://cdn.loyalie.in/38922635.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/77340726.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center h-[80vh] md:h-[95vh] lg:h-[85vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: ` mx-auto text-center mb-3 text-white text-[25px] md:text-[40px] lg:text-[30px] xl:text-[35px]   tracking-wider ${getFont('MAX').heading
                            }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'self-center mx-auto w-[40%] md:w-[35%] lg:w-[22%] xl:w-[25%] bg-primary border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],


    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[16px] md:text-[20px] ${getFont('MAX').body
            }`,
        listClassName: `text-neutral font-normal text-[16px] md:text-[17px] leading-7 ${getFont('MAX').body
            }`,
        components: [
            {
                type: 'CUSTOM',
                // sectionBackgroundImage:
                //     'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.35',
                    size: 'cover'
                },
                className: 'text-left p-8 lg:p-16',
                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[18px] md:text-[20px] ${getFont('MAX').heading
                            }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program Max Estates As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[16px] md:text-[20px] ${getFont('MAX').body
                            }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Max Estates project, and has entered into a registered Agreement to Sell; or (ii) an employee of Max Estates ; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Max Estates or any of its sister concerns/subsidiaries/affiliates (“Max Estates Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Max Estates Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Max Estates or the Project Entity',
                            'Referrer shall not be able to refer existing customer(s) of Max Estates . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Max Estates or (ii) is/are already registered with Max Estates as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Max Estates’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',
                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Max Estates within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Max Estates.',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions. Appropriate TDS amount will be deducted as per applicable norms while remitting the referral payments through vouchers.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the Max Estates or otherwise.',
                            'Employees of Max Estates working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[16px] md:text-[17px] leading-7 ${getFont('MAX').body
                            }`,
                        classNameContainer: 'px-1 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-1 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-1 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-6 pt-2',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Max Estates, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${getFont('MAX').body
                                    } inline text-neutral font-normal text-[16px] md:text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-secondary font-medium text-[16px] md:text-[17px] leading-7 ${getFont('MAX').body
                                    }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[16px] md:text-[17px] leading-7 ${getFont('MAX').body
                                    } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-secondary font-medium text-[16px] md:text-[17px] leading-7 ${getFont('MAX').body
                                    }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd. In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[16px] md:text-[17px] leading-7 ${getFont('MAX').body
                                    } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-secondary font-medium text-[16px] md:text-[17px] leading-7 ${getFont('MAX').body
                                    }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[16px] md:text-[17px] leading-7 ${getFont('MAX').body
                                    } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        appContainerClassName: 'bg-white text-secondary border-secondary',
        appFontHeading: `${getFont('MAX').body}`,
        appStoreLink:
            'https://apps.apple.com/sn/app/max-estates-livewell/id6467768314?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.maxestates&utm_medium=email'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo:
            'https://www.srijanconnect.loyalie.com/wp-content/uploads/2022/01/Reloy-logo-White.png',
        containerClassName: 'bg-accent-focus xl:pt-0',
        footerLogoClassName: 'self-center md:w-[15%] lg:w-[16%] lg:pl-3 ',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${getFont('MAX').heading
            }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: true,
        allRightsReservedContent:
            '©Copyright 2022. ALL RIGHTS RESERVED BY RELOY',
        allRightsReservedClassName: `text-[12px] md:text-[16px] bg-white w-full mx-auto text-center py-2 ${getFont('MAX').body
            }`,
        loyalieFooterExpandContent: false,
        loyalieFooterExpandName: 'Disclaimer',
        loyalieFooterExpandClassName: `text-center text-white cursor-pointer pb-2 ${getFont('MAX').heading
            }`,
        loyalieFooterExpandContentClassName: ` self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-2 xl:pr-6 text-[13px] 3xl:text-[15px] tracking-tight text-white  lg:w-[84%] ${getFont('MAX').body
            }`,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 |Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA No: DLRERA2022A0017 | Goa RERA NO – AGGO02210817 | Telangana RERA No – A02500001321 |maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `self-center px-5 xl:px-0 md:flex-1 mt-4 md:mt-0 w-full text-[13.5px] 3xl:text-[15px] tracking-tight text-white ${getFont('MAX').body
            }`
    },
    commonComponents: {
        filterTagClassName: `text-secondary-focus text-extrabold text-[17.px] ${getFont('MAX').heading
            }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-80'
    },
    referralForm: {
        name: 'Max',
        theme: 'maxTheme',
        color: '#045ba8',
        backgroundImage: 'https://cdn.loyalie.in/7666541.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '194',
            G1: '181',
            B1: '168',
            opacity1: '0',
            R2: '194',
            G2: '181',
            B2: '168',
            opacity2: '0'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own a',
            // bookedCustomerHeading2: 'Max Estates Property',

            employee: true,
            employeeHeading1: 'I am a',
            // employeeHeading1: 'Max Estates Employee',

            salesAdmin: true,
            salesAdminHeading1: 'I am a',
            // salesAdminHeading2: 'Max Estates Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        // customConfig: {
        //     welcomeContent: '',
        //     // headingColor: 'text-white',
        //     // userBgColor: 'bg-primary',
        //     // borderColor: 'border-secondary-content',
        //     animateUserType: false
        // },
        pastReferralConfig: {
            selectedStatusColor: 'text-[#f58220]',
            bgColor: 'bg-secondary-content'
        },
        formContent: {
            heading: 'Embrace new horizons and build meaningful relationships.',
            headingClass: `self-center text-white sm:mt-0 text-[22px] xl:text-[27px] 2xl:text-[30px] w-[90%] md:w-[50%] lg:w-[80%] xl:w-[90%] 2xl:w-[85%] text-center lg:text-left tracking-wide leading-6 xl:leading-7 2xl:leading-8  ${getFont('MAX').body
                }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton: `w-[50%] lg:w-[35%] 2xl:w-[35%] tracking-wide text-white border-4 uppercase font-semibold ${getFont('MAX').body
                }`
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%23763300'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: false,
        sendReferralAttemptNotification: false,
        isDirectTAndCLink: false,
        influencerLinkActive: true,
        termsAndConditionsLink: '/maxestates-home/terms-and-conditions',
        customLink: '/max-home/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default MaxConfig;
