import React from 'react'

function Blogs(props) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
>
    <g id="页面-1" transform="translate(0 0.131)">
        <g id="导航图标">
            <g id="编组">
                <rect
                    id="矩形"
                    width="21"
                    height="21"
                    transform="translate(0 -0.131)"
                    fill={props.fill}
                />
                <path
                    id="路径"
                    d="M2.5,3.3A1.3,1.3,0,0,1,3.8,2H14.239a1.3,1.3,0,0,1,1.3,1.3V19.391H3.8a1.3,1.3,0,0,1-1.3-1.3Z"
                    transform="translate(-0.326 -0.261)"
                    fill={props.fill}
                    stroke={props.stroke}
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    fill-rule="evenodd"
                />
                <path
                    id="路径-2"
                    data-name="路径"
                    d="M17.5,11.87a.87.87,0,0,1,.87-.87h1.739a.87.87,0,0,1,.87.87v7.391a1.3,1.3,0,0,1-1.3,1.3H17.5Z"
                    transform="translate(-2.283 -1.435)"
                    fill={props.fill}
                    stroke={props.stroke}
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    fill-rule="evenodd"
                />
                <line
                    id="路径-3"
                    data-name="路径"
                    x2="3.478"
                    transform="translate(4.783 5.217)"
                    fill={props.fill}
                    stroke={props.stroke}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <line
                    id="路径-4"
                    data-name="路径"
                    x2="5.217"
                    transform="translate(4.783 8.261)"
                    fill={props.fill}
                    stroke={props.stroke}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
            </g>
        </g>
    </g>
</svg>
  )
}

export default Blogs
