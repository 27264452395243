import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import BsPeopleFill from '@meronex/icons/bs/BsPeopleFill';
import FdTelephone from '@meronex/icons/fd/FdTelephone';
import IosArrowUp from '@meronex/icons/ios/IosArrowUp';
import VscCallOutgoing from '@meronex/icons/vsc/VscCallOutgoing';
import BiSupport from '@meronex/icons/bi/BiSupport';
import FaWhatsapp from '@meronex/icons/fa/FaWhatsapp';
import { useHistory, useLocation } from 'react-router-dom';
import { getFont } from '../../../helpers';
import { getConfig } from '../../../utils/config';
import { scrollToTargetAdjusted } from '../../../utils/ui';
import { cmsClient } from '../../../utils/axios';

export const ChatbotFloater = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleSection = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (isVisible) {
                setIsVisible(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isVisible]);

    const config = getConfig();
    return ReactDOM.createPortal(
        <div className="fixed bottom-4 right-6">
            <div className="flex flex-row-reverse lg:flex-row">
                <div
                    className={`bg-primary px-3 items-center  rounded-xl relative mr-2 lg:ml-2 transition-opacity duration-500 ease-in-out ${
                        isVisible
                            ? 'flex opacity-100'
                            : 'opacity-0 lg:opacity-100 max-w-full '
                    } flex`}
                >
                    <p className="text-white text-[15px] px-2 leading-[1]">
                        Happy to Help you
                    </p>
                    <div className=" text-[50px] leading-[0.2] text-primary absolute -right-3 top-1/2 -translate-y-1/2 rotate-0 opacity-0 lg:opacity-100">
                        ‣
                    </div>
                    <div className=" text-[50px] leading-[0] text-primary absolute -right-3 top-1/2 opacity-100 lg:opacity-0">
                        ‣
                    </div>
                </div>
                <div
                    className={`rounded-full flex items-center justify-center p-[18px] drop-shadow-2xl  shadow-2xl ${
                        config?.floaterColor
                            ? config?.floaterColor
                            : ' bg-[#25D366]'
                    } h-14 w-14  md:mx-auto z-40 cursor-pointer `}
                    onClick={toggleSection}
                >
                    <BiSupport className=" text-white h-7 w-7 " />
                </div>
                <div
                    className={`rounded-full flex items-center justify-center p-[18px] drop-shadow-2xl  shadow-2xl ${
                        config?.floaterColor
                            ? config?.floaterColor
                            : ' bg-[#25D366]'
                    } h-14 w-14  md:mx-auto z-40 cursor-pointer `}
                    onClick={toggleSection}
                >
                    <BiSupport className=" text-white h-7 w-7 " />
                </div>
            </div>

            <div
                className={`py-4 px-6 bg-white rounded-lg mt-2 drop-shadow-xl overflow-hidden transition-max-height duration-500 ease-in-out ${
                    isVisible ? 'max-h-96' : 'max-h-0 mt-0 py-0'
                }`}
            >
                <a
                    href={`https://wa.me/+91${window.reloy_env.client_config.CONTACT_DETAILS?.WHATSAPP_CHATBOT}`}
                    target="_blank"
                >
                    <div className="flex justify-between items-center border-b-2 border-gray-200 pb-2 hover:text-primary">
                        <div>
                            <p className="text-sm text-gray-400">WhatsApp</p>
                            <h2 className="font-bold">
                                +91{' '}
                                {
                                    window.reloy_env.client_config
                                        .CONTACT_DETAILS?.WHATSAPP_CHATBOT
                                }
                            </h2>
                        </div>
                        <FaWhatsapp className="text-[#25d366] h-6 w-6" />
                    </div>
                </a>

                <a
                    href={`tel:${window.reloy_env.client_config.CONTACT_DETAILS?.MOBILE}`}
                >
                    <div className="flex justify-between items-center pt-2 hover:text-primary">
                        <div>
                            <p className="text-sm text-gray-400">Call Us</p>
                            <h2 className="font-bold ">{`+91 ${window.reloy_env.client_config.CONTACT_DETAILS?.MOBILE}`}</h2>
                        </div>
                        <VscCallOutgoing className="text-primary h-6 w-6" />
                    </div>
                </a>
            </div>
        </div>,
        document.getElementById('floater')
    );
};

export const DiwaliOffer = () => {
    const config = getConfig();
    const history = useHistory();
    const location = useLocation();
    return ReactDOM.createPortal(
        <div
            onClick={() => {
                history.push({
                    pathname: '/gift-and-earn',
                    state: { scrollTo: 'referral_section' }
                });
            }}
            className={`${config?.floaterClass} z-40 cursor-pointer w-28 md:w-32 lg:w-56 h-12 fixed bottom-32 hover:right-0 right-[-166px] transition duration-500 ease-in-out flex`}
        >
            <div className=" h-full ">
                <BsPeopleFill
                    className={`h-full w-14 px-4 object-cover ${
                        config?.iconColor ? config?.iconColor : 'text-white'
                    } `}
                />
            </div>
            <p
                className={`${
                    getFont((config?.name).toUpperCase()).heading
                } text-2xs lg:text-sm xl:text-base px-2 self-center `}
            >
                Start Referring Now
            </p>
        </div>,
        document.getElementById('diwali')
    );
};

export const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    //scroll-to-top classes: fixed, bottom:0, right:0
    return (
        isVisible && (
            <div
                onClick={scrollToTop}
                className="rounded-full p-3 drop-shadow-2xl  shadow-2xl bg-black opacity-50 h-10 w-10 fixed bottom-3 right-6 md:mx-auto z-40 cursor-pointer hover:opacity-100 hover:bg-info"
            >
                <IosArrowUp className=" text-white z-50" />
            </div>
        )
    );
};

export const CallFloater = () => {
    const config = getConfig();
    return ReactDOM.createPortal(
        <a
            href={`tel:${window.reloy_env.client_config.CONTACT_DETAILS?.MOBILE}`}
        >
            <div
                className={` rounded-full p-[18px] drop-shadow-2xl  shadow-2xl ${
                    config?.floaterColor
                        ? config?.floaterColor
                        : ' bg-[#25D366]'
                } h-14 w-14 fixed bottom-3 right-6 md:mx-auto z-40 cursor-pointer `}
            >
                <FdTelephone className="text-white h-5 w-5 " />
            </div>
        </a>,
        document.getElementById('floater')
    );
};
