import moment from 'moment';
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const getActionButtons = (
    id,
    status,
    onCancel,
    onReschedule,
    onRevisit,
    onBookNow,
    loading
) => {
    const ScheduledButtons = (
        <div className="flex flex-row justify-end gap-x-2">
            <button
                className={
                    'border-[#DDDDDD] border-2 px-5 mt-2 py-1.5 text-center mr-2 rounded'
                }
                onClick={onCancel}
            >
                Cancel
            </button>
            <button
                onClick={onReschedule}
                className={
                    'bg-primary px-5 mt-2 py-1.5 text-white text-center rounded'
                }
            >
                Reschedule

            </button>
        </div>
    );

    const CompletedButtons = (
        <div className="flex flex-row justify-end gap-x-2">
            <button
                disabled={loading}
                className={
                    'border-[#DDDDDD] border-2 px-5 mt-2 py-1.5 text-center rounded'
                }
                onClick={onRevisit}
            >
                {loading ? 'Loading...' : status == 'ENQUIRY' ? 'Book a Site Visit' : 'Revisit'}
            </button>
            {status != 'ENQUIRY' && <button
                onClick={onBookNow}
                className={
                    'bg-primary px-5 mt-2 py-1.5 text-white ml-2 text-center rounded'
                }
            >
                Book Now

            </button>}
        </div>
    );

    if (!status) {
        return ScheduledButtons;
    }
    switch (status) {
        case 'SITE_VISIT_BOOKED':
            return ScheduledButtons;
        case 'SITE_VISIT_DONE':
        case 'ENQUIRY':
            return CompletedButtons;

        default:
            return null;
    }
};

const getActionButtonsMobile = (
    id,
    status,
    onCancel,
    onReschedule,
    onRevisit,
    onBookNow,
    onHomeLoanInterest,
    loading
) => {
    const ScheduledButtons = (
        <div className="flex flex-row lg:justify-end border-[#EBEAF2] border-t-2">
            <button
                className={
                    'flex-1 [#EBEAF2] border-r-2 px-5 py-3 text-center'
                }
                onClick={onCancel}
            >
                Cancel
            </button>
            <button
                onClick={onReschedule}
                className={
                    'flex-1 px-5 py-3 text-center'
                }
            >
                Reschedule

            </button>
        </div>
    );

    const CompletedButtons = (
        <>
            <div className='flex px-4 '>
                {status == 'SITE_VISIT_DONE' && <div className='flex justify-center items-center pr-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.155" height="12.568" viewBox="0 0 13.155 12.568">
                        <path id="star-72" d="M100.925,72.609l-3.842-.558-1.718-3.482a.485.485,0,0,0-.869,0l-1.718,3.482-3.842.558a.484.484,0,0,0-.268.826l2.78,2.71-.657,3.827a.484.484,0,0,0,.7.51l3.437-1.807,3.437,1.807a.484.484,0,0,0,.7-.51l-.657-3.827,2.78-2.71a.485.485,0,0,0-.268-.826Zm-3.682,3.155.546,3.182-2.858-1.5-2.858,1.5.546-3.182-2.312-2.255,3.2-.465,1.428-2.895,1.428,2.895,3.2.465Z" transform="translate(-88.335 -68.123)" fill="#16519e" stroke="#16519e" stroke-width="0.3" />
                    </svg>

                    <button className='text-[#16519E] text-sm ml-1'>

                        Rate Experience  |</button>
                </div>}
                <div className='flex justify-center items-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.211" height="12.675" viewBox="0 0 15.211 12.675">
                        <g id="coins-22" transform="translate(0 -85.333)">
                            <path id="Path_6341" data-name="Path 6341" d="M1.775,86.221a7.842,7.842,0,0,1,3.8-.887,7.993,7.993,0,0,1,3.612.824,3.247,3.247,0,0,1,1.9,2.282.646.646,0,1,1-1.268.253c-.063-.444-.444-.951-1.268-1.331a6.992,6.992,0,0,0-3.042-.634,6.493,6.493,0,0,0-3.169.761c-.761.317-1.077.824-1.077,1.268a1.461,1.461,0,0,0,.761,1.2,5.576,5.576,0,0,0,2.218.887.646.646,0,0,1-.254,1.268,7.535,7.535,0,0,1-2.535-.951.062.062,0,0,1-.063.063,1.492,1.492,0,0,0-.127.507,1.544,1.544,0,0,0,.761,1.2,5.576,5.576,0,0,0,2.218.887.646.646,0,0,1-.254,1.268,6.381,6.381,0,0,1-2.6-1.014,1.194,1.194,0,0,0-.127.507c0,.444.317.887,1.014,1.331a6.067,6.067,0,0,0,2.852.824.625.625,0,0,1,.57.634.572.572,0,0,1-.7.57,7.029,7.029,0,0,1-3.422-1.014A2.81,2.81,0,0,1,0,94.586a2.621,2.621,0,0,1,.317-1.2l.19-.19A2.393,2.393,0,0,1,0,91.734a2.621,2.621,0,0,1,.317-1.2.5.5,0,0,1,.254-.254A2.056,2.056,0,0,1,0,88.756,3.052,3.052,0,0,1,1.775,86.221Z" transform="translate(0 0)" fill="#16519e" />
                            <path id="Path_6342" data-name="Path 6342" d="M393.507,386.915a2.582,2.582,0,0,0-1.521-2.155,7.249,7.249,0,0,0-6.464,0A2.582,2.582,0,0,0,384,386.915a2,2,0,0,0,.444,1.2,1.86,1.86,0,0,0-.444,1.2,2.582,2.582,0,0,0,1.521,2.155,7.154,7.154,0,0,0,3.232.761,6.418,6.418,0,0,0,3.232-.761,2.582,2.582,0,0,0,1.521-2.155,2.261,2.261,0,0,0-.38-1.2A2.459,2.459,0,0,0,393.507,386.915Zm-2.155,3.486a5.26,5.26,0,0,1-2.6.57,6.063,6.063,0,0,1-2.6-.57c-.634-.38-.887-.761-.887-1.077a.349.349,0,0,1,.127-.317,7.259,7.259,0,0,0,6.718,0c.063.127.127.19.127.317C392.239,389.641,391.986,390.021,391.352,390.4Zm.507-2.789a4.879,4.879,0,0,1-3.105.951,5.062,5.062,0,0,1-2.979-.824l-.063-.063-.063-.063a1.1,1.1,0,0,1-.38-.7c0-.317.254-.7.887-1.077a5.26,5.26,0,0,1,2.6-.57,6.063,6.063,0,0,1,2.6.57c.634.38.887.761.887,1.077A1.1,1.1,0,0,1,391.859,387.612Z" transform="translate(-378.296 -294.23)" fill="#16519e" />
                        </g>
                    </svg>

                    <button onClick={onHomeLoanInterest} className='text-[#16519E] text-sm ml-1'>

                        Home loan eligibility</button>
                </div>
            </div>
            <div className="flex border-t-2 border-[#EBEAF2] w-full mt-5">
                <button
                    disabled={loading}
                    className={
                        'flex-1 border-r-2 border-[#EBEAF2] py-3  text-center'
                    }
                    onClick={onRevisit}
                >
                    {loading ? 'Loading...' : status == 'ENQUIRY' ? 'Book a Site Visit' : 'Revisit'}
                </button>
                {status != 'ENQUIRY' &&
                    <button
                        onClick={onBookNow}
                        className={
                            'flex-1 py-3 text-center text-[#16519E]'
                        }
                    >
                        Book Now

                    </button>}
            </div>
        </>
    );

    if (!status) {
        return ScheduledButtons;
    }
    switch (status) {
        case 'SITE_VISIT_BOOKED':
            return ScheduledButtons;
        case 'SITE_VISIT_DONE':
        case 'ENQUIRY':
            return CompletedButtons;

        default:
            return null;
    }
};

function SiteVisitItem({


    visit,
    setSelectedVisitId,
    setShowBookNowModal,
    setShowCancelModal,
    setShowRevisitModal,
    setProjectDetails,
    setSelectedEnquiryId,
    setShowHomeLoanModal
}) {
    // console.log(visit)
    const history = useHistory()

    const onCancel = () => {
        setSelectedVisitId(visit?.activityId || '')
        setShowCancelModal(true)
    }

    const onBookNow = () => {
        setSelectedEnquiryId(visit?.enquiryId || '')
        setShowBookNowModal(true)
    }

    const onRevisit = (projectDetails, enquiryStatus) => {
        // console.log(projectDetails, enquiryStatus)
        if (enquiryStatus == 'ENQUIRY') {
        history.push(`/projects/book-a-site-visit?id=${projectDetails?.id}&image=${projectDetails?.projectBgImage}&name=${projectDetails?.projectName}&city=${projectDetails?.city}`)

            // history.push({
            //     pathname: '/projects/book-a-site-visit',
            //     state: {
            //         projectId: projectDetails?.id,
            //         projectBgImage: projectDetails?.projectBgImage,
            //         projectCity: projectDetails?.city,
            //         projectName: projectDetails?.projectName
            //     }
            // })
        } else
            setProjectDetails(projectDetails)
        setShowRevisitModal(true)
    }

    const onReschedule = (projectDetails) => {
        history.push(`/projects/book-a-site-visit?id=${projectDetails?.id}&image=${projectDetails?.projectBgImage}&name=${projectDetails?.projectName}&city=${projectDetails?.city}&type=Reschedule&activityId=${visit?.activityId}`)
        // history.push({
        //     pathname: `/projects/book-a-site-visit`,
        //     state: {
        //         projectId: projectDetails?.id,
        //         projectBgImage: projectDetails?.projectBgImage,
        //         projectCity: projectDetails?.city,
        //         projectName: projectDetails?.projectName,
        //         type: 'Reschedule',
        //         activityId: visit?.activityId,
        //     }
        // })
        setSelectedVisitId(visit?.activityId || '')
    }

    const onHomeLoanInterest = () => {
        setShowHomeLoanModal(true)
        window?.webengage?.track('CheckHLEligibility', {
            projectName:`${visit?.project.projectName}`
        });
    }

    return (
        <div id={visit?.activityId || ''} className='flex flex-wrap md:flex-nowrap justify-between mt-5 border-2 border-[#EBEAF2] md:border-0 md:border-b-2 md:pb-5 rounded-lg'>
            <div className='flex w-full md:w-auto p-4 md:p-0 justify-between md:justify-start relative'>
                <div className='hidden md:block'>
                    <img alt='project' className=' rounded-lg max-h-24 w-44' src={visit?.project?.projectBgImage} />
                    <div className={`absolute text-white left-2 text-sm top-2 rounded py-1.5 px-3 ${visit?.enquiryStatus == 'ENQUIRY' ? 'bg-[#FF0000]' : 'bg-[#009545]'}`}>{visit?.enquiryStatus == 'ENQUIRY' ? 'Interested' : visit?.enquiryStatus == 'SITE_VISIT_BOOKED' ? 'Scheduled' : visit?.enquiryStatus == 'SITE_VISIT_DONE' ? 'Visited' : visit?.enquiryStatus}</div>
                </div>
                <div className='md:pl-3'>
                    <h1 className='text-lg'>{visit?.project?.projectName}</h1>
                    <p className='text-sm text-[#908F95]'>{visit?.project?.city}</p>
                    {visit?.activityTime && <p className={`mt-1 text-sm ${visit?.enquiryStatus == 'SITE_VISIT_DONE' ? 'text-[#908F95]' : visit?.enquiryStatus == 'SITE_VISIT_BOOKED' ? 'text-[#009545]' : 'text-[#FF0000]'}`}>{moment(visit?.activityTime).format('MMM Do YYYY | h:mm a')}</p>}
                </div>
                <div className={`md:hidden self-end text-white text-sm rounded py-1.5 px-3 ${visit?.enquiryStatus == 'ENQUIRY' ? 'bg-[#FF0000]' : 'bg-[#009545]'}`}>{visit?.enquiryStatus == 'ENQUIRY' ? 'Interested' : visit?.enquiryStatus == 'SITE_VISIT_BOOKED' ? 'Scheduled' : visit?.enquiryStatus == 'SITE_VISIT_DONE' ? 'Visited' : visit?.enquiryStatus}</div>

            </div>
            <div className='w-full md:hidden'>
            {visit?.interestedInBooking && <p className='font-medium text-sm p-4'>Thank you for your interest. Your Relationship Manager will reach out to you shortly.</p>}
                {!visit?.interestedInBooking && getActionButtonsMobile(visit?.activityId || '', visit?.enquiryStatus, onCancel,
                    () => onReschedule(visit?.project),
                    () => onRevisit(visit?.project, visit?.enquiryStatus),
                    onBookNow, onHomeLoanInterest)}
            </div>
            <div className={`hidden md:block ${visit?.interestedInBooking? 'self-start' : 'self-end'}'`}>
            {visit?.interestedInBooking && <p className='font-medium text-right ml-auto w-4/5 xl:w-2/3 text-sm'>Thank you for your interest. Your Relationship Manager will reach out to you shortly.</p>}
                {!visit?.interestedInBooking && getActionButtons(visit?.activityId || '', visit?.enquiryStatus, onCancel,
                    () => onReschedule(visit?.project),
                    () => onRevisit(visit?.project, visit?.enquiryStatus),
                    onBookNow)}
                <div className='flex mt-3 justify-end'>
                    {visit?.enquiryStatus == 'SITE_VISIT_DONE' &&
                        <div className='flex justify-center items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.155" height="12.568" viewBox="0 0 13.155 12.568">
                                <path id="star-72" d="M100.925,72.609l-3.842-.558-1.718-3.482a.485.485,0,0,0-.869,0l-1.718,3.482-3.842.558a.484.484,0,0,0-.268.826l2.78,2.71-.657,3.827a.484.484,0,0,0,.7.51l3.437-1.807,3.437,1.807a.484.484,0,0,0,.7-.51l-.657-3.827,2.78-2.71a.485.485,0,0,0-.268-.826Zm-3.682,3.155.546,3.182-2.858-1.5-2.858,1.5.546-3.182-2.312-2.255,3.2-.465,1.428-2.895,1.428,2.895,3.2.465Z" transform="translate(-88.335 -68.123)" fill="#16519e" stroke="#16519e" stroke-width="0.3" />
                            </svg>

                            <button className='text-[#16519E] text-sm ml-1'>

                                Rate Experience |</button>
                        </div>}
                    <div className='flex justify-center items-center pl-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.211" height="12.675" viewBox="0 0 15.211 12.675">
                            <g id="coins-22" transform="translate(0 -85.333)">
                                <path id="Path_6341" data-name="Path 6341" d="M1.775,86.221a7.842,7.842,0,0,1,3.8-.887,7.993,7.993,0,0,1,3.612.824,3.247,3.247,0,0,1,1.9,2.282.646.646,0,1,1-1.268.253c-.063-.444-.444-.951-1.268-1.331a6.992,6.992,0,0,0-3.042-.634,6.493,6.493,0,0,0-3.169.761c-.761.317-1.077.824-1.077,1.268a1.461,1.461,0,0,0,.761,1.2,5.576,5.576,0,0,0,2.218.887.646.646,0,0,1-.254,1.268,7.535,7.535,0,0,1-2.535-.951.062.062,0,0,1-.063.063,1.492,1.492,0,0,0-.127.507,1.544,1.544,0,0,0,.761,1.2,5.576,5.576,0,0,0,2.218.887.646.646,0,0,1-.254,1.268,6.381,6.381,0,0,1-2.6-1.014,1.194,1.194,0,0,0-.127.507c0,.444.317.887,1.014,1.331a6.067,6.067,0,0,0,2.852.824.625.625,0,0,1,.57.634.572.572,0,0,1-.7.57,7.029,7.029,0,0,1-3.422-1.014A2.81,2.81,0,0,1,0,94.586a2.621,2.621,0,0,1,.317-1.2l.19-.19A2.393,2.393,0,0,1,0,91.734a2.621,2.621,0,0,1,.317-1.2.5.5,0,0,1,.254-.254A2.056,2.056,0,0,1,0,88.756,3.052,3.052,0,0,1,1.775,86.221Z" transform="translate(0 0)" fill="#16519e" />
                                <path id="Path_6342" data-name="Path 6342" d="M393.507,386.915a2.582,2.582,0,0,0-1.521-2.155,7.249,7.249,0,0,0-6.464,0A2.582,2.582,0,0,0,384,386.915a2,2,0,0,0,.444,1.2,1.86,1.86,0,0,0-.444,1.2,2.582,2.582,0,0,0,1.521,2.155,7.154,7.154,0,0,0,3.232.761,6.418,6.418,0,0,0,3.232-.761,2.582,2.582,0,0,0,1.521-2.155,2.261,2.261,0,0,0-.38-1.2A2.459,2.459,0,0,0,393.507,386.915Zm-2.155,3.486a5.26,5.26,0,0,1-2.6.57,6.063,6.063,0,0,1-2.6-.57c-.634-.38-.887-.761-.887-1.077a.349.349,0,0,1,.127-.317,7.259,7.259,0,0,0,6.718,0c.063.127.127.19.127.317C392.239,389.641,391.986,390.021,391.352,390.4Zm.507-2.789a4.879,4.879,0,0,1-3.105.951,5.062,5.062,0,0,1-2.979-.824l-.063-.063-.063-.063a1.1,1.1,0,0,1-.38-.7c0-.317.254-.7.887-1.077a5.26,5.26,0,0,1,2.6-.57,6.063,6.063,0,0,1,2.6.57c.634.38.887.761.887,1.077A1.1,1.1,0,0,1,391.859,387.612Z" transform="translate(-378.296 -294.23)" fill="#16519e" />
                            </g>
                        </svg>

                        <button 
                       // onClick={() => setShowHomeLoanModal(true)}  
                        onClick={onHomeLoanInterest}
                        className='text-[#16519E] text-sm ml-1'>

                            Home loan eligibility</button>
                    </div>
                </div>
            </div>

        </div>)

}

export default SiteVisitItem
