import * as React from 'react';

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const getExtension = (type) => {
    if (type === 'image/jpeg') {
        return 'jpeg';
    } else if (type === 'image/png') {
        return 'png';
    } else if (type === 'image/gif') {
        return 'gif';
    } else if (type === 'image/jpg') {
        return 'jpg';
    } else if (type === 'application/pdf') {
        return 'pdf';
    } else {
        return 'doc';
    }
};

const useFilePicker = ({
    inputId,
    multiple = true,
    customPickFiles,
    customRemoveFile
}) => {
    const [files, setFiles] = React.useState([]);

    const addAdditionalField = ({
        additionalField,
        index,
        additionalFieldName
    }) => {
        setFiles(
            files.map((file, i) => {
                if (i === index) {
                    return Object.assign(file, {
                        [additionalFieldName]: additionalField
                    });
                } else return file;
            })
        );
    };

    const pickFile = (e) => {
        const selectedFiles = [...e.target.files];
        // console.log('selected input files', selectedFiles);

        let file =
            selectedFiles && selectedFiles.length > 0 ? selectedFiles : null;

        if (!file) {
            return;
        }

        for (let i = 0; i < file.length; i++) {
            const type = getExtension(file[i].type);
            Object.assign(file[i], {
                preview: URL.createObjectURL(file[i]),
                formattedSize: formatBytes(file[i].size),
                format: type
            });
        }

        // console.log('files after looping', file);
        // const converted = [...file];

        if (multiple) {
            setFiles([...files.slice(), ...file]);
            if (customPickFiles) {
                customPickFiles([...files.slice(), ...[file]]);
            }
        } else {
            setFiles([...file]);
            if (customPickFiles) {
                customPickFiles([...file]);
            }
        }

        e.target.value = '';
    };

    const removeFile = (index) => {
        // const input = document.getElementById(inputId);
        // if (input) {
        //     input.value = null;
        // }
        setFiles(
            files.filter((_, ind) => {
                return ind !== index;
            })
        );
    };

    const removeAllFiles = () => setFiles([]);

    const state = {
        files
    };

    return {
        state,
        pickFile,
        removeFile,
        removeAllFiles,
        setFiles,
        addAdditionalField
    };
};

export default useFilePicker;
