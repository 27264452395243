import React from 'react';
import BsTrash from '@meronex/icons/bs/BsTrash';

const DeletingContent = () => {
    return (
        <div className="flex flex-col items-center justify-center h-full">
            <div className="text-red-500 text-5xl flex items-center justify-center mt-4">
                <BsTrash />
            </div>
            <div className="w-[50%] mx-auto mt-4">
                <svg viewBox="0 0 457 2">
                    <defs></defs>
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <polygon
                                class="cls-1"
                                points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1"
                                fill="#e5e5e5"
                            />
                        </g>
                    </g>
                </svg>
            </div>
            <div className="text-center mt-4 px-12">
                <h1 className="text-xl font-bold mb-2">
                    Your account has been deleted
                </h1>
                <p className="text-sm lg:text-base text-[#ADADAD] mb-4">
                    As per our terms, your data will be retained for 90 days,
                    within which you will be able to request for Account
                    restore.
                </p>
                <p className="text-sm lg:text-base text-[#ADADAD]">
                    After 90 days, your data will be permanently purged from our
                    systems.
                </p>
            </div>
        </div>
    );
};

export default DeletingContent;
