import React from 'react';
import moment from 'moment';
import { truncate } from '../../../helpers';
import { getConfig } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import useHomeBlogs from '../BlogLayoutVertical1/data';
import { winnreAuth, winnreNoAuth } from '../../../utils/axios';
import { useSelector } from 'react-redux';

function BlogLayoutVertical1({ 
    hideInfo, 
    className, 
    classNameContainer, 
    url, 
    linearGradient, 
    headingClass, 
    summaryClass , 
    buttonClass,
    sectionHeading,
    sectionHeadingClass }) {

    const config = getConfig();
    const { blogs } = useHomeBlogs();
    const history = useHistory();

    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    const clickThroughMobile = clientWebview || internalWebview;

    const isUserAuthenticated = useSelector(
        (state) => state.auth.isAuthenticated
    );
    const userId = useSelector((state) => state.auth.userId);
    const blogClickedHandler = (blogId, visibleToAll) => {
        if (isUserAuthenticated) {
            winnreAuth({
                method: 'POST',
                url: `winnre/user/blogs/trackClick?blogId=${blogId}&userId=${userId}&sourcePlatform=${clickThroughMobile ? 'MOBILE' : 'WEBSITE'
                    }`
                // headers: headers2
            })
                .then(() => { })
                .catch(() => { });
        } else {
            winnreNoAuth({
                method: 'POST',
                url: `winnre/user/blogs/trackClick?blogId=${blogId}&sourcePlatform=${clickThroughMobile ? 'MOBILE' : 'WEBSITE'
                    }`
            })
                .then(() => { })
                .catch(() => { });
        }
        if (visibleToAll) {
            history.push(`/blogs/${blogId}`);
        } else {
            history.push(`/blogs`);
        }
    };
    return (

        <div
            className={
                className
                    ? className
                    : 'w-full h-auto flex flex-col-reverse lg:flex-row px-5 lg:px-8 xl:px-2 2xl:px-10 justify-center pt-20 pb-10 bg-primary'
            }
            style={{
                backgroundImage: `linear-gradient(90deg, rgba(${linearGradient?.R
                    }, ${linearGradient?.G}, ${linearGradient?.B}, ${linearGradient?.opacity
                    }),rgba(${linearGradient?.R}, ${linearGradient?.G}, ${linearGradient?.B
                    }, ${linearGradient?.opacity})), url('${url || ''}')`,
                backgroundRepeat: `${linearGradient?.repeat ? linearGradient?.repeat : 'repeat'
                    } `,
                backgroundSize: `${linearGradient?.size ? linearGradient?.size : '50% auto'
                    } `,
                backgroundPosition: `${linearGradient?.position}`
            }}
        >

            <h1 className={sectionHeadingClass? sectionHeadingClass : 'text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-white text-center pb-5'}>{sectionHeading || 'latest blogs'}</h1>


            <div
                className={
                    classNameContainer
                        ? classNameContainer
                        : ' w-full px-5 md:px-44 lg:px-20 xl:w-[80%] mx-auto overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-2 lg:grid-flow-row gap-x-4 md:gap-x-8 lg:gap-x-6 xl:gap-x-36 3xl:gap-x-28 gap-y-5 '
                }
            >


                {blogs?.map((blog, index) => {
                    // console.log('v', blog.visibleToAll);
                    return (

                        <div
                            key={blog.id}
                            onClick={() =>
                                blogClickedHandler(blog.id, blog.visibleToAll)
                            }
                            className={`text-left min-w-[80vw] lg:min-w-full rounded-sm flex flex-col justify-center h-auto   cursor-pointer row-span-2 ${index === 0 ? 'row-end-3' : ''} ${index === 1 ? 'row-start-2 ' : ''} lg:mb-14`}
                        >
                            <div className="relative">
                                {hideInfo ? (
                                    <></>
                                ) : (
                                    <div
                                        className={
                                            `${config?.blogs.layout5.categoryClass}` +
                                            ' absolute right-0 text-[12px] font-medium 3xl:text-base px-4 py-1 m-3 rounded-full uppercase z-10'
                                        }
                                    >
                                        <p>
                                            {blog.tags.map((obj) => {
                                                return (
                                                    ' ' + `${obj.name}` + '  '
                                                );
                                            })}
                                        </p>
                                    </div>
                                )}

                                <img
                                    src={blog?.displayImageUrl}
                                    className="h-[300px] md:h-[400px] w-full rounded-t-sm object-cover relative mb-5"
                                    alt="Blog image"
                                />
                                  <button
                                        className={buttonClass
                                                ? buttonClass
                                                : ' text-black py-1 px-4 md:py-2 md:px-4 absolute -right-5 bottom-1 bg-white   '
                                        }
                                    >
                                        READ MORE
                                    </button>
                            </div>
                            <div className="h-auto">
                                <div className="h-auto ">
                                    <div className='flex justify-between'>
                                        <h2
                                            className={headingClass
                                                ? headingClass
                                                : ' text-secondary-focus font-Gotham_Light mt-5 text-[19px] 3xl:text-[20px] font-medium uppercase leading-6 break-words h-[70px] mr-5'
                                            }
                                        >
                                            {truncate(blog.heading, 15)}
                                        </h2>

                                        <div className='flex gap-x-3 mt-6'>
                                            <div className='w-[18px] h-[18px] border-[3px] border-white rounded-full'></div>
                                            <div className='w-[18px] h-[18px] border-[3px] border-white rounded-full'></div>
                                        </div>

                                    </div>
                                    <p
                                        className={summaryClass
                                                ? summaryClass
                                                : 'font-normal font-Gotham_Book text-[16.5px] xl:text-[17px]  leading-6 text-[#777777]'
                                        }
                                    >
                                        {blog.summary + '...'}
                                    </p>
                                  
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default BlogLayoutVertical1;
