import React from 'react';
import moment from 'moment';
import { truncate } from '../../../helpers';
import { getConfig } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import useHomeBlogs from './data';
import { winnreAuth, winnreNoAuth } from '../../../utils/axios';
import { useSelector } from 'react-redux';

function BlogLayoutVertical1({ hideInfo, className, classNameContainer }) {
    const config = getConfig();
    const { blogs } = useHomeBlogs();
    const history = useHistory();

    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    const clickThroughMobile = clientWebview || internalWebview;

    const isUserAuthenticated = useSelector(
        (state) => state.auth.isAuthenticated
    );
    const userId = useSelector((state) => state.auth.userId);
    const blogClickedHandler = (blogId, visibleToAll) => {
        if (isUserAuthenticated) {
            winnreAuth({
                method: 'POST',
                url: `winnre/user/blogs/trackClick?blogId=${blogId}&userId=${userId}&sourcePlatform=${
                    clickThroughMobile ? 'MOBILE' : 'WEBSITE'
                }`
                // headers: headers2
            })
                .then(() => {})
                .catch(() => {});
        } else {
            winnreNoAuth({
                method: 'POST',
                url: `winnre/user/blogs/trackClick?blogId=${blogId}&sourcePlatform=${
                    clickThroughMobile ? 'MOBILE' : 'WEBSITE'
                }`
            })
                .then(() => {})
                .catch(() => {});
        }
        if (visibleToAll) {
            history.push(`/blogs/${blogId}`);
        } else {
            history.push(`/blogs`);
        }
    };
    return (
        <div
            className={
                className
                    ? className
                    : 'w-full h-auto flex flex-col-reverse lg:flex-row mt-10 px-5 lg:px-8 xl:px-2 2xl:px-10 justify-center pb-20'
            }
        >
            <div
                className={
                    classNameContainer
                        ? classNameContainer
                        : ' w-full px-5 md:px-44 lg:px-0 xl:w-[80%] overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-3 gap-x-4 md:gap-x-8 lg:gap-x-6 xl:gap-x-10 3xl:gap-x-16 gap-y-14 '
                }
            >
                {blogs?.map((blog) => {
                    // console.log('v', blog.visibleToAll);
                    return (
                        <div
                            key={blog.id}
                            onClick={() =>
                                blogClickedHandler(blog.id, blog.visibleToAll)
                            }
                            className="text-left min-w-[80vw] lg:min-w-full  bg-white rounded-sm flex flex-col justify-center h-auto shadow-xl hover:shadow-2xl cursor-pointer mb-14"
                        >
                            <div className="relative h-full">
                                {hideInfo ? (
                                    <></>
                                ) : (
                                    <div
                                        className={
                                            `${config?.blogs.layout5.categoryClass}` +
                                            ' absolute right-0 text-[12px] font-medium 3xl:text-base px-4 py-1 m-3 rounded-full uppercase'
                                        }
                                    >
                                        <p>
                                            {blog.tags.map((obj) => {
                                                return (
                                                    ' ' + `${obj.name}` + '  '
                                                );
                                            })}
                                        </p>
                                    </div>
                                )}

                                <img
                                    src={blog?.displayImageUrl}
                                    className=" h-[200px] w-full rounded-t-sm object-cover"
                                    alt="Blog image"
                                />
                            </div>
                            <div className="h-auto">
                                <div className="h-auto ">
                                    <h2
                                        className={
                                            config?.blogs.layout5.headingClass
                                                ? config?.blogs.layout5
                                                      .headingClass
                                                : ' text-secondary-focus font-Gotham_Light mt-5 text-[19px] 3xl:text-[20px] font-medium uppercase leading-6 break-words mx-5 h-[90px]'
                                        }
                                    >
                                        {truncate(blog.heading, 15)}
                                    </h2>
                                    {hideInfo ? (
                                        <></>
                                    ) : (
                                        <>
                                            <img
                                                src="https://d1pkuz21mplpj3.cloudfront.net/SVG/Divider-2.svg"
                                                className="w-full"
                                            />

                                            <div className="flex font-Roboto justify-between  border-gray-200 mx-5 py-5 3xl:pt-5">
                                                <div className="flex space-x-4">
                                                    <img
                                                        src="https://d1pkuz21mplpj3.cloudfront.net/SVG/Like_icon.svg"
                                                        className="w-[18px] md:w-[22px]"
                                                    />
                                                    <img
                                                        src="https://d1pkuz21mplpj3.cloudfront.net/SVG/share_icon.svg"
                                                        className="w-[16px] md:w-[20px]"
                                                    />
                                                    <img
                                                        src="https://d1pkuz21mplpj3.cloudfront.net/SVG/comment_icon.svg"
                                                        className="w-[21px] md:w-[25px]"
                                                    />
                                                </div>

                                                <p
                                                    className={
                                                        'font-Gotham_Light  text-[14px] md:text-[17px] xl:text-[15px]  self-center text-[#919396]'
                                                    }
                                                >
                                                    {moment(
                                                        blog?.publishDate
                                                            ? blog?.publishDate
                                                            : blog?.updatedOn
                                                    ).format('DD.MM.YY')}
                                                </p>
                                            </div>
                                        </>
                                    )}
                                    <p
                                        className={
                                            config?.blogs.layout5.summaryClass
                                                ? config?.blogs.layout5
                                                      .summaryClass
                                                : 'font-normal font-Gotham_Book text-[16.5px] xl:text-[17px]  leading-6 text-[#777777] mx-5 h-[120px]'
                                        }
                                    >
                                        {truncate(blog.summary, 28) + ' ...'}
                                    </p>
                                    <button
                                        className={
                                            config?.blogs.layout5.buttonClass
                                                ? config?.blogs.layout5
                                                      .buttonClass
                                                : ' text-white py-1 px-4 md:py-2 md:px-4'
                                        }
                                    >
                                        READ MORE
                                        <span className=" inline-block self-center h-4">
                                            <img
                                                src={
                                                    config?.blogs.layout5
                                                        .buttonArrowUrl
                                                        ? config?.blogs.layout5
                                                              .buttonArrowUrl
                                                        : `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.16 11.59'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23fff;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cline class='cls-1' y1='5.62' x2='55' y2='5.62'/%3E%3Cpolyline class='cls-1' points='48.5 0.39 51.93 3.09 55.35 5.79 51.93 8.49 48.5 11.19'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E`
                                                }
                                                className="h-4 w-[40px] xl:w-[50px] ml-4"
                                            />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default BlogLayoutVertical1;
