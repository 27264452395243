import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';
const M3MConfig = {
    clientId:
        '519cccdf78f4f5a1f65ea6831f6eca84f48983b90c999e50d91fbe92247e82ea',
    id: 25,
    name: 'M3M',
    fullName: 'M3M Group',
    programName:'M3M Life',
    primaryColor: '#045ba8',
    // fontBody: `${getFont('M3M').body}`,
    // fontTitle: `${getFont('M3M').heading}`,
    theme: 'M3MTheme',
    favicon:
        'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/34072720.png',
    setSolidBg: 'bg-white',
    googleConfig: {
        gID: 'G-H7NLL63M9R',
        keywordsContent: '',
        descriptionContent: '',
        authorContent: 'M3M Group'
    },

    facebookPixelConfig: {
        id: '865810904466078', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~14507c738',
    webengageAPIKey:'5490ef83-5c4c-40f4-91dc-c711d26076ae',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: false,
    logoLeft: {
        name: 'M3M Life Logo',
        url: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/70976878.png',
        stickyNavBarLogoClass: 'h-14 md:h-25 lg:h-14 2xl:h-16 pl-3 xl:pl-10',
        baseNavBarLogoClass:
            ' h-14 md:h-25 lg:h-[90px] xl:h-24 2xl:h-28 pl-3 xl:pl-10 lg:pt-4 2xl:pt-6'
    },

    logoRight: {
        name: 'M3M Logo',
        url: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/2297157.png',
        stickyNavBarLogoClass:
            'hidden lg:block lg:h-10 2xl:h-12 pr-3 xl:pr-10 ',
        baseNavBarLogoClass: 'h-20 md:h-25 lg:h-10 xl:h-12 pr-3 xl:pr-10 '
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('M3M').body}`,
        height: 'h-20 2xl:h-20',
        mobileIconColor: 'text-primari-focus',
        dropDownBg: 'bg-white lg:bg-primary',
        showActiveLinkColor: false,
        footerNavItemClass: `tracking-widest uppercase text-white text-[12px] md:text-[14px] lg:text-white hover:text-primary font-bold lg:text-[12px] xl:text-[14px] 3xl:text-lg  ${
            getFont('M3M').body
        }`,
        baseNavBar: {
            border: true,
            borderClass: '',
            backgroundColor: 'bg-white lg:bg-transparent ', // default: transparent
            containerClass: '',
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black text-[14px] lg:text-secondary hover:text-primary font-bold lg:text-[12px] xl:text-[14px] 3xl:text-lg ',
            dropDownContainerClass: `hover:text-primary lg:hover:text-white lg:hover:bg-accent lg:focus:text-gray-900 lg:focus:bg-primary lg:focus:outline-none lg:focus:shadow-outline  `,
            dropDownItemClass:
                'tracking-widest text-black lg:text-white text-[14px] font-bold lg:text-[12px] xl:text-[14px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: true,
            borderClass: 'border-b-[3px] border-secondary',
            backgroundColor: 'bg-primary-content', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 tracking-widest text-black text-[14px] lg:text-secondary hover:text-primary font-bold lg:text-[12px] xl:text-[14px] 3xl:text-lg ' // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: 'lg:-mt-20', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 ${
        getFont('M3M').bold
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage:
                        'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/89378650.jpg',
                    backgroundImageMobile:
                        'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/41323757.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    bannerContainerClass:
                        'h-[75vh] md:h-[60vh] lg:h-[99vh] bg-contain bg-right-bottom lg:bg-right-bottom border-b-[3px] border-secondary',
                    bannerItemContainerClass:
                        'items-start justify-top md:justify-center pt-24 md:pt-0 md:px-8 md:pl-6 lg:pl-10',
                    position: 'text-left',

                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'w-full text-center md:text-left mt-5 lg:mt-0 leading-7 md:leading-6 lg:leading-none',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'A discerning lifestyle,',
                                    className: ` text-secondary text-[28px] uppercase md:text-[25px] lg:text-[30px] xl:text-[40px] 2xl:text-[45px] ${
                                        getFont('M3M').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: 'curated just for you.',
                                    className: `text-secondary text-[28px] uppercase md:text-[25px] lg:text-[30px] py-1 xl:text-[40px] 2xl:text-[45px] ${
                                        getFont('M3M').heading
                                    }`
                                }
                            ]
                        },
                        {
                            type: 'TEXT',
                            content: 'An Initiative by M3M and Reloy',
                            className: `mt-2 md:mt-3 text-secondary mx-auto md:mx-0 md:text-left font-normal md:text-[16px] lg:text-[20px] xl:text-[22px] leading-6  ${
                                getFont('M3M').body
                            }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'Refer Now',
                            className: `md:mx-0 text-center md:text-left mt-6 mx-auto text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[11px] px-5 md:px-10 ${
                                getFont('M3M').body
                            }`,
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH_AND_SCROLL',
                            scrollTo: 'referral_section'
                        }
                    ]
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center fixed lg:top-[68px] xl:top-[70px] right-0 w-[20%] lg:w-[83.5%] xl:w-[83.7%] 2xl:w-[85%] bg-secondary border-none lg:h-[2.5px] 2xl:h-[3px]',
                    classNameContainer: ''
                },

                {
                    type: 'CUSTOM',
                    id: 'overview',
                    url: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.35',
                        size: 'cover'
                    },
                    bannerItemContainerClass: 'bg-cover',
                    className:
                        'flex flex-col justify-center text-center py-10 px-4 ',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Discover curated experiences, premium benefits and more.',
                            className: `${
                                getFont('M3M').heading
                            } text-primary self-center text-center uppercase w-[90%] md:w-[95%] lg:w-[75%] text-[22px] md:text-[22px] lg:text-[24px] xl:text-[30px] 2xl:text-[34px]  leading-7 xl:leading-9 pt-4 2xl:pt-10 `
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Live a life of rare and extravagant experiences that are tailor-made just for you. We present to you a bouquet of such experiences with M3M Life – an exclusive loyalty and referral programme designed to enthrall, excite and pamper you.',
                            className: `pt-4 text-[16px] md:text-[16px] xl:text-[18px] 2xl:text-[20px]  text-secondary-focus leading-5 xl:leading-6 self-center w-[90%] lg:w-[80%] xl:w-[72%] 2xl:w-[70%] break-words font-normal ${
                                getFont('M3M').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Indulge in the many benefits of M3M Life:',
                            className: `${
                                getFont('M3M').heading
                            } text-primary self-center text-center uppercase w-[90%] md:w-[80%] lg:w-[70%] 2xl:w-[60%] text-[22px] md:text-[22px] lg:text-[25px] xl:text-[31px]  2xl:text-[34px] leading-7 xl:leading-9 pt-10 `
                        },
                        {
                            type: 'ICONS_LAYOUT',
                            containerClassName:
                                'h-content w-full lg:flex lg:h-auto items-center pt-40 lg:pt-32 xl:pt-44 2xl:pt-48 pb-10 ',
                            gridClassName:
                                'grid lg:grid-cols-3 w-[90%] md:w-[60%] lg:w-[85%] xl:w-[75%] 2xl:w-[70%] gap-y-40 lg:gap-x-8 2xl:gap-x-10  m-auto',
                            infoContainerClassName: ' py-5',
                            infotxtClassName: ` mx-auto leading-5 xl:leading-[20px] w-[90%] xl:w-[95%] 2xl:w-[90%] px-6 text-white text-[15px] 2xl:text-[16px] font-normal pb-6 ${
                                getFont('M3M').body
                            }`,
                            infoHeadingClassName: `w-full mx-auto leading-6 xl:leading-[27px] md:w-[80%] lg:w-[60%] xl:w-[60%] md:px-2 text-[20px] md:text-[22px] 2xl:text-[25px] uppercase text-white font-normal pt-10 pb-6 tracking-wide ${
                                getFont('M3M').heading
                            }`,
                            dividerClassName:
                                'w-[20%] mx-auto border-none h-[8px] my-6',

                            imgClassName:
                                'w-[70%] md:w-[50%] lg:w-[75%] rounded-[50%] lg:mx-auto mx-auto -mt-32 lg:-mt-28 xl:-mt-36',
                            info: [
                                {
                                    BGColor: '#b2a599',
                                    imgUrl: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/17777466.jpg',
                                    infoHeading: 'offers and promotions',
                                    infoText:
                                        'Explore an endless spectrum of select offers and special promotions handpicked to enrich your lifestyle.'
                                },
                                {
                                    BGColor: '#a4988c',
                                    imgUrl: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/28648747.jpg',
                                    infoHeading: 'workshops and events',
                                    infoText:
                                        ' Meet and connect with like-minded people from an esteemed circle at curated workshops and extravagant events.'
                                },
                                {
                                    BGColor: '#958a7e',
                                    imgUrl: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/29654275.jpg',
                                    infoHeading: 'informative blogs',
                                    infoText:
                                        'Get privileged access to content that’s crafted to your interests and preferences, and interact with people of similar tastes.'
                                }
                            ]
                        },
                        {
                            type: 'TEXT',
                            content: 'build your own bespoke community',
                            className: `${
                                getFont('M3M').heading
                            } text-primary self-center text-center uppercase w-[90%] md:w-[80%] lg:w-[70%] 2xl:w-[60%] text-[22px] md:text-[22px] lg:text-[24px] xl:text-[30px] 2xl:text-[34px]  leading-7 xl:leading-9 pt-4 2xl:pt-10 `
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Beyond the luxuries and benefits of an exclusive loyalty programme, M3M Life also opens to you a world unlike any other. A world of peers who share a taste for the finer things in life. This is a unique opportunity for you to craft one such world for yourself – by introducing your friends and family to the M3M community.',
                            className: `mx-auto pt-4 text-[16px] xl:text-[18px] 2xl:text-[20px]  text-secondary-focus leading-5 xl:leading-6 self-center w-[90%] lg:w-[80%] xl:w-[70%] break-words font-normal  ${
                                getFont('M3M').body
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: ' text-center pt-6',
                            components: [
                                {
                                    type: 'TEXT',
                                    content:
                                        'But that’s not all. You even receive referral benefits of up to ',
                                    className: `inline text-[16px] md:text-[16px] xl:text-[18px] 2xl:text-[20px]  text-secondary-focus leading-5 xl:leading-6 self-center w-[90%] lg:w-[85%] xl:w-[75%] font-normal ${
                                        getFont('M3M').body
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: '1% ',
                                    className: `inline text-[16px] md:text-[17px] xl:text-[19px] 2xl:text-[21px]  text-secondary-focus leading-5 xl:leading-6 self-center w-[90%] lg:w-[85%] xl:w-[75%] font-bold ${
                                        getFont('M3M').body
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'of the agreement value on every successful.',
                                    className: `inline text-[16px] md:text-[16px] xl:text-[18px] 2xl:text-[20px]  text-secondary-focus leading-5 xl:leading-6 self-center w-[90%] lg:w-[85%] xl:w-[75%] font-normal ${
                                        getFont('M3M').body
                                    }`
                                }
                            ]
                        }
                    ]
                },

                //REFERRAL FORM
                {
                    type: 'REFERRAL_FORM_BANNER'
                },
                {
                    type: 'CUSTOM',
                    url: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.35',
                        size: 'cover'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-5 ',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR EXCLUSIVE OFFERS',
                            className: `text-primary tracking-wide text-center px-2 text-[22px] md:text-[25px] lg:text-[24px] xl:text-[30px] 2xl:text-[34px] py-6  ${
                                getFont('M3M').heading
                            }`
                        },
                        {
                            type: 'CAROUSEL',

                            getVendorData: true,
                            offerCards: true,
                            ContainerClassName:
                                'bg-cover rounded-lg h-auto mx-3 2xl:mx-5 p-8',
                            caraouselClassName:
                                'w-[80%] md:w-full xl:w-[86%] 2xl:w-[95%] 3xl:w-[80%] mx-auto md:px-12  pt-6 relative pb-10',

                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 4,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    url: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.35',
                        size: 'cover'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-6 ',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'LATEST BLOGS',
                            className: `text-primary tracking-wide text-center px-2 text-[22px] md:text-[25px] lg:text-[24px] xl:text-[30px] 2xl:text-[34px]   ${
                                getFont('M3M').heading
                            }`
                        },

                        { type: 'BLOG_LAYOUT_VERTICAL1' }
                    ]
                },
                {
                    type: 'CUSTOM',
                    url: '',
                    linearGradient: {
                        R: '194',
                        G: '181',
                        B: '168',
                        opacity: '1',
                        size: 'cover'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-10 pb-20 lg:pb-24 -mb-28',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'PROJECTS',
                            className: `text-primary tracking-wide text-center px-2 text-[22px] md:text-[25px] lg:text-[24px] xl:text-[30px] 2xl:text-[34px]   ${
                                getFont('M3M').heading
                            }`
                        },
                        {
                            type: 'CAROUSEL',
                            customSlide: true,
                            caraouselClassName:
                                'px-8 md:px-10 xl:px-36 md:pb-10 py-5 relative',
                            imageContainerClassName: 'm-4 relative',
                            imageClassName:
                                'w-full h-auto lg:bg-cover object-cover',
                            captionClass: ` text-sm lg:text-sm text-white font-semibold leading-8 ${
                                getFont('M3M').body
                            }`,
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 3,
                            pauseOnHover: true,
                            speed: 1500,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/12860761.png',
                                    alt: 'M3M latitude',
                                    url: 'https://www.m3mindia.com/projects/latitude/',
                                    caption: 'M3M Latitude'
                                },
                                {
                                    imgUrl: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/60382883.png',
                                    alt: 'M3M fairway west',
                                    url: 'https://www.m3mindia.com/projects/fairway-west/',
                                    caption: 'M3M Fairway West'
                                },
                                {
                                    imgUrl: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/53663070.png',
                                    alt: 'M3M Merlin',
                                    url: 'https://www.m3mindia.com/projects/merlin/',
                                    caption: 'M3M Merlin'
                                },
                                {
                                    imgUrl: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/72089488.png',
                                    alt: 'M3M Polo Suites',
                                    url: 'https://www.m3mindia.com/projects/polo-suites/',
                                    caption: 'M3M Polo Suites'
                                },
                                {
                                    imgUrl: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/25167768.png',
                                    alt: 'M3M Woodshire',
                                    url: 'https://www.m3mindia.com/projects/woodshire/',
                                    caption: 'M3M Woodshire'
                                },
                                {
                                    imgUrl: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/40868427.png',
                                    alt: 'M3M Escala',
                                    url: 'https://www.m3mindia.com/projects/escala/',
                                    caption: 'M3M Escala'
                                },
                                {
                                    imgUrl: 'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/72942794.jpg',
                                    alt: 'M3M panorama',
                                    url: 'https://www.m3mindia.com/projects/panorama/',
                                    caption: 'M3M Panorama'
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },

        dropDown: {
            show: true,
            name: 'ENGAGEMENT',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        events: {
            show: false,
            name: 'ENGAGEMENT',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms & Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            bannerContainerClassName:
                'hero hero-content max-w-full h-[90vh] lg:h-[97vh] lg:h-screen bg-top  mx-auto text-center ',
            overlayClass: 'bg-neutral',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692637968652_refer%20page',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692637968652_refer%20page',

            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    position: 'text-center',
                    backgroundImage:
                        'https://cdn.loyalie.in/1692637968652_refer%20page',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1692637968652_refer%20page',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] md:h-[70vh] object-cover bg-center 2xl:bg-right-top ',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral',
                            className: `text-black text-[19px] md:text-[20px] xl:text-[25px] text-center lg:text-left uppercase  tracking-wide  lg:w-[50%] ml-auto lg:leading-8 ${
                                getFont('M3M').heading
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className: 'flex lg:w-[50%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    onClick: 'FUNC',
                                    className: ` mt-4 lg:mx-0 mx-auto text-center text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[8px] px-5 md:px-7 ${
                                        getFont('M3M').body
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://cdn.loyalie.in/1692637968652_refer%20page',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1692637968652_refer%20page',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[50vh] md:h-[70vh] object-cover bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  my-5 tracking-widest  lg:w-[80%] mx-auto  ${
                    //         getFont('M3M').body
                    //     }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-white text-[15px] md:text-[20px] xl:text-[45px] font-bold  pb-5  tracking-widest lg:w-[50%] mx-auto  ${
                    //         getFont('M3M').heading
                    //     }`
                    // },
                    // {
                    //     type: 'BUTTON',
                    //     content: 'Start Referring Now',
                    //     className: `text-md font-medium gera-button hover:gera-button-hover px-8 md:px-10 py-2 lg:mt-2 xl:mt-0 lg:w-[20%] mx-auto ${
                    //         getFont('M3M').heading
                    //     }`,
                    //     scrollTo: 'referral_section',
                    //     onClick: 'SCROLL'
                    // }
                ]
            }
        ],

        customize: {
            totalHeading: ` leading-7 font-normal tracking-wide  ${
                getFont('M3M').heading
            }`,
            circleText: ` leading-7 font-bold ${getFont('M3M').bold}`,
            circleHeading: ` leading-7 tracking-wide ${getFont('M3M').heading}`,
            totalTextClassName: `text-[25px] lg:text-[35px]  font-normal text-white ${
                getFont('M3M').bold
            }`,
            totalRewardsText: `text-[30px] font-bold ${getFont('M3M').bold}`,
            rewardsPriceText: `text-primary text-[50px] font-bold ${
                getFont('M3M').bold
            }`,
            claimButton: `text-md font-bold tracking-widest bg-primary text-white px-8 md:px-6 py-3 lg:mt-2 xl:mt-0 mx-auto ${
                getFont('M3M').heading
            }`,
            rewardsClaimText: ` text-black leading-7 text-[15px] md:text-[27px] font-bold tracking-wide ${
                getFont('M3M').heading
            }`,
            rewardsClaimAmountText: `text-black leading-7 mx-2 text-[15px] md:text-[27px] font-bold tracking-wide ${
                getFont('M3M').bold
            }`,

            rewardsIcon: 'https://cdn.loyalie.in/57800231.png',

            referralIcon: 'https://cdn.loyalie.in/7944112.png'
        }
    },
    blogs: {
        sectionBackgroundImage:
            'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.35',
            size: 'cover'
        },
        components: [
            {
                type: 'BANNER',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[80vh] md:h-[70vh] lg:h-[97vh] lg:h-screen md:bg-top mx-auto text-center ',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.20'
                },
                backgroundImage:
                    'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/52762917.jpg',
                backgroundImageMobile:
                    'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/52762917.jpg',

                components: [
                    {
                        type: 'TEXT',
                        content: 'M3M LIFE READERS’ CLUB',
                        className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[25px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                            getFont('M3M').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    },
                    {
                        type: 'TEXT',
                        content:
                            'Bringing Together Information And Entertainment For You.',
                        className: `mt-5 text-secondary font-medium text-[16px] lg:text-[20px] 2xl:text-[22px] leading-6 ${
                            getFont('M3M').heading
                        }`
                    },
                    {
                        type: 'BUTTON',
                        content: 'Read More',
                        scrollTo: 'blogs_section',
                        onClick: 'SCROLL',
                        className: ` lg:mx-0 mx-auto text-center text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[11px] px-5 md:px-10 ${
                            getFont('M3M').body
                        }`
                    }
                ]
            }
        ],
        banner: {
            bannerContainerClassName:
                'hero hero-content max-w-full h-[80vh] md:h-[70vh] lg:h-[97vh] lg:h-screen bg-center mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.20'
            },
            backgroundImage:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/52762917.jpg',
            backgroundImageMobile:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/52762917.jpg',

            components: [
                {
                    type: 'TEXT',
                    content: 'M3M LIFE READERS’ CLUB',
                    className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[25px] lg:text-[30px] xl:text-[35px] leading-7 text-secondary tracking-wider ${
                        getFont('M3M').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `mt-5 text-secondary font-medium text-[16px] lg:text-[20px] 2xl:text-[22px] leading-5 ${
                        getFont('M3M').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[11px] px-5 md:px-10 ${
                        getFont('M3M').body
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center fixed lg:top-[68px] xl:top-[70px] right-0 w-[20%] lg:w-[83.5%] xl:w-[83.7%] 2xl:w-[85%] bg-secondary border-none lg:h-[2.5px] 2xl:h-[3px]',
                    classNameContainer: ''
                }
            ],

            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'M3M LIFE READERS’ CLUB',
                    className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[25px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                        getFont('M3M').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `mt-5 text-secondary font-medium text-[16px] lg:text-[20px] 2xl:text-[22px] leading-6 ${
                        getFont('M3M').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[11px] px-5 md:px-5 ${
                        getFont('M3M').body
                    }`
                }
            ]
        },

        sideBarClass: `text-primary ${getFont('M3M').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('M3M').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-accent text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },

        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-[#1c2936] ${
                    getFont('M3M').heading
                }`,
                headingClass: `text-[25px] md:text-[30px] uppercase text-primary mt-3 leading-7 md:leading-9 break-words ${
                    getFont('M3M').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('M3M').heading
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('M3M').heading
                } `,
                buttonClass: `inline text-[17px] text-primary ml-1 ${
                    getFont('M3M').heading
                }`
            },
            sectionHeadings: `px-10 lg:px-16 pt-10 pb-2 text-[22px] lg:text-[25px] xl:text-[35px] tracking-wider text-center uppercase ${
                getFont('M3M').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            dividerClass: 'hidden m-0 p-0 ',

            backgroundColor: 'bg-accent',
            cardHeight: ' h-[530px] lg:h-[520px] xl:h-[540px] 3xl:h-[580px] ',
            bodyHeight:
                ' h-[280px] md:h-[280px] lg:h-[270px] xl:h-[290px] 3xl:h-[380px]',
            headingClass: `text-primary my-5 md:my-7 text-[22px] 3xl:text-[20px] font-medium uppercase leading-6 font-semibold break-words mx-5 ${
                getFont('M3M').heading
            }`,

            summaryClass: `font-normal text-[16.5px] lg:text-[15px] xl:text-[17px] mt-2 lg:my-6 leading-6 text-secondary mx-5 ${
                getFont('M3M').body
            }`,
            categoryClass: 'bg-[#00000075] text-white text-[11px]',
            buttonClass: `bg-accent flex justify-center self-start text-[13px] xl:text-[14px] text-white py-2 px-4 md:px-4 m-4 ${
                getFont('M3M').body
            }`,
            dateClass: `text-[14px] md:text-[17px] xl:text-[15px]  self-center text-[#919396] ${
                getFont('M3M').heading
            }`,
            noBlogsClass: `bg-primary text-white p-2 md:w-[30%] lg:w-[70%] xl:w-[50%] 3xl:w-[30%] mt-10 ${
                getFont('M3M').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/32090097.jpg',
            mobileDefaultActiveBannerImage:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/78237744.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0',
                size: 'contain',
                position: 'top'
            },
            bannerContainerClassName: 'bg-top',
            negativeMargin: ' lg:-mt-20',
            containerClass: ' text-white w-full md:w-[50%] ',
            heading: '',
            headingClass: `font-semibold text-left lg:text-right w-[90%] md:w-[70%] text-[25px] md:text-[30px] 2xl:text-[45px] text-secondary uppercase ${
                getFont('M3M').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('M3M').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-24 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: ` mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                        getFont('M3M').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${
                        getFont('M3M').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    onClick: 'FUNC',
                    className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6 text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                        getFont('M3M').body
                    }`
                }
            ]
        },
        eventsSection: {
            sectionBackgroundImage:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.35',
                size: 'cover'
            },
            heading: 'PAST EVENTS',
            headingClass: `self-center text-center text-[22px] md:text-[24px] lg:text-[25px] xl:text-[35px] text-neutral w-[60%] mt-16 ${
                getFont('M3M').heading
            }`,
            components: [
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center fixed lg:top-[68px] xl:top-[70px] right-0 w-[20%] lg:w-[83.5%] xl:w-[83.7%] 2xl:w-[85%] bg-secondary border-none lg:h-[2.5px] 2xl:h-[3px]',
                    classNameContainer: ''
                }
            ],
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('M3M').body
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('M3M').body
            }`
        },
        eventLayout: {
            titleClass: `text-accent leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('M3M').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${
            getFont('M3M').heading
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('M3M').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[45vh] md:h-[45vh] lg:h-[45vh] bg-top  mx-auto text-center ', //bg-right bg-cover bg-top
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0' //0.5
            },
            backgroundImage: 'https://cdn.loyalie.in/1692637778544_offer%20m3m',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692637778544_offer%20m3m',
            components: [
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center fixed lg:top-[68px] xl:top-[70px] right-0 w-[20%] lg:w-[83.5%] xl:w-[83.7%] 2xl:w-[85%] bg-secondary border-none lg:h-[2.5px] 2xl:h-[3px]',
                    classNameContainer: ''
                }
            ],
            loginBannerComponents: [
                // {
                //     type: 'TEXT',
                //     content: 'EXCLUSIVE DIWALI OFFERS  ',
                //     className: `mt-40 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                //         getFont('M3M').heading
                //     }`
                // },
                // {
                //     type: 'TEXT',
                //     content: 'EXCLUSIVE DIWALI OFFERS',
                //     className: ` mt-12 md:mt-10 mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                //         getFont('M3M').heading
                //     }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'M3M Life is driven to create experiences that add more value to your life',
                //     className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-6 ${
                //         getFont('M3M').heading
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC',
                //     className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                //         getFont('M3M').body
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC',
                //     className: ` mt-36 md:mt-44 lg:mt-16 xl:mt-20 lg:mx-0 mx-auto text-center text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-accent text-white font-normal py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                //         getFont('M3M').body
                //     }`
                // }
            ]
        },

        dealsSection: {
            sectionBackgroundImage:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.35',
                size: 'cover'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center font-semibold md:text-[20px] text-center text-[17px] text-neutral w-[90%] ${
                getFont('M3M').heading
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `uppercase text-secondary text-2xl xl:text-3xl ${
                getFont('M3M').heading
            } `,
            subAdditionalText:
                'M3M Life is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: `font-normal text-[14px] py-2 mt-2 leading-6 ${
                getFont('M3M').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('M3M').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
            pattern2:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.60',
                repeat: 'no-repeat',
                size: '140%',
                position: ''
            },
            listTitleClass: `text-[18px] leading-6 lg:leading-5 2xl:leading-6 text-primary ${
                getFont('M3M').body
            }`,
            listBodyClass: `${getFont('M3M').body}`,
            logoClassName: `self-center rounded-md drop-shadow-lg z-10 `,
            titleClassName: ` text-primary font-medium uppercase text-2xl xl:text-xl 2xl:text-2xl leading-7  mt-4 ${
                getFont('M3M').body
            } `,
            couponClassName: `block md:text-[22px] text-[19px] self-center text-center text-primary font-bold leading-7 ${
                getFont('M3M').body
            }`,
            dateClassName: `py-[6px] text-neutral capitalize text-[17px] text-secondary-focus  ${
                getFont('M3M').body
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-[14px]  ${
                getFont('M3M').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-neutral mx-auto text-[12px] break-words leading-1 ${
                getFont('M3M').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-accent text-white uppercase self-center text-[16px] py-2.5 px-7  ${
                    getFont('M3M').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `rounded bg-accent border-accent border-[2px] hover:text-accent hover:bg-white hover:font-semibold text-white uppercase self-center text-[16px] py-2.5 px-7 ${
                    getFont('M3M').heading
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.55'
                },
                backgroundImage:
                    'https://www.m3mindia.com/campaign/atrium/assets/images/gallery/4.jpg',
                backgroundImageMobile:
                    'https://www.m3mindia.com/campaign/atrium/assets/images/gallery/4.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[60vh] md:h-[70vh] lg:h-[95vh] md:bg-right',

                components: [
                    {
                        type: 'TEXT',
                        content: 'M3M PROJECTS',

                        className: ` mx-auto uppercase text-center text-secondary mb-3 text-[28px] md:text-[25px] lg:text-[30px] xl:text-[35px] tracking-wider ${
                            getFont('M3M').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {
         
            constructionVisible: true,
            
            sectionBackgroundImage:
                'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: '',
            addHeader: true,
            headerClass:
                'hidden lg:block h-24 border-b-[3px] border-secondary bg-white'
        }
    },
    header: {
        headerClass: 'hidden lg:block lg:h-24 xl:h-28 bg-white '
    },
    contactUs: {
        sectionBackgroundImage:
            'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.35',
            size: 'cover'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0'
                },
                backgroundImage: 'https://cdn.loyalie.in/38922635.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/77340726.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center h-[80vh] md:h-[95vh] lg:h-[95vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: ` mx-auto text-center mb-3 text-secondary text-[25px] md:text-[40px] lg:text-[30px] xl:text-[35px]   tracking-wider ${
                            getFont('M3M').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[15%] xl:w-[10%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],


    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[16px] md:text-[20px] ${
            getFont('M3M').body
        }`,
        listClassName: `text-neutral font-normal text-[16px] md:text-[17px] leading-7 ${
            getFont('M3M').body
        }`,
        components: [
            {
                type: 'CUSTOM',
                sectionBackgroundImage:
                    'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/50331604.jpg',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.35',
                    size: 'cover'
                },
                className: 'text-left p-16',
                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[18px] md:text-[20px] ${
                            getFont('M3M').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program M3M Life As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[16px] md:text-[20px] ${
                            getFont('M3M').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a M3M Life project, and has entered into a registered Agreement to Sell; or (ii) an employee of M3M ; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by M3M Life or any of its sister concerns/subsidiaries/affiliates (“M3M Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate M3M Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by M3M Life or the Project Entity',
                            'Referrer shall not be able to refer existing customer(s) of M3M Life . Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with M3M or (ii) is/are already registered with M3M as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the M3M Life’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',
                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to M3M within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to M3M.',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions. Appropriate TDS amount will be deducted as per applicable norms while remitting the referral payments through vouchers.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by the M3M or otherwise.',
                            'Employees of M3M Life working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[16px] md:text-[17px] leading-7 ${
                            getFont('M3M').body
                        }`,
                        classNameContainer: 'px-1 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-1 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-1 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-6 pt-2',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or M3M Life, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('M3M').body
                                } inline text-neutral font-normal text-[16px] md:text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[16px] md:text-[17px] leading-7 ${
                                    getFont('M3M').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[16px] md:text-[17px] leading-7 ${
                                    getFont('M3M').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[16px] md:text-[17px] leading-7 ${
                                    getFont('M3M').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd. In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[16px] md:text-[17px] leading-7 ${
                                    getFont('M3M').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[16px] md:text-[17px] leading-7 ${
                                    getFont('M3M').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[16px] md:text-[17px] leading-7 ${
                                    getFont('M3M').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        appFontHeading: `${getFont('M3M').body}`,
        appStoreLink:
            'https://apps.apple.com/mw/app/m3m-life/id6444639390?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.m3mlife&utm_medium=email'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content xl:pt-0',
        footerLogoClassName: 'self-center md:w-[15%] lg:w-[16%] lg:pl-3 ',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('M3M').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: true,
        allRightsReservedContent:
            '©Copyright 2022. ALL RIGHTS RESERVED BY RELOY',
        allRightsReservedClassName: `text-[12px] md:text-[16px] bg-white w-full mx-auto text-center py-2 ${
            getFont('M3M').body
        }`,
        loyalieFooterExpandContent: false,
        loyalieFooterExpandName: 'Disclaimer',
        loyalieFooterExpandClassName: `text-center text-white cursor-pointer pb-2 ${
            getFont('M3M').heading
        }`,
        loyalieFooterExpandContentClassName: ` self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-2 xl:pr-6 text-[13px] 3xl:text-[15px] tracking-tight text-white lg:w-[84%] ${
            getFont('M3M').body
        }`,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Haryana RERA No. - HRERA-PKL-REA-239-2020 | Delhi RERA No: DLRERA2022A0017 | haryanarera.gov.in | https://rera.delhi.gov.in',
        loyalieFooterContentClassName: `self-center px-5 xl:px-0 md:flex-1 mt-4 md:mt-0 w-full text-[13.5px] 3xl:text-[15px] tracking-tight text-white ${
            getFont('M3M').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-secondary-focus text-extrabold text-[17.px] ${
            getFont('M3M').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-80'
    },
    referralForm: {
        name: 'M3M',
        theme: 'M3MTheme',
        color: '#045ba8',
        backgroundImage:
            'https://m3muat.s3.ap-south-1.amazonaws.com/Website/Uploads/M3M/Images/33213763.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '194',
            G1: '181',
            B1: '168',
            opacity1: '0.50',
            R2: '194',
            G2: '181',
            B2: '168',
            opacity2: '0.50'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading: '',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own a',
            // bookedCustomerHeading2: 'M3M Life Property',

            employee: true,
            employeeHeading1: 'I am a',
            // employeeHeading1: 'M3M Life Employee',

            salesAdmin: true,
            salesAdminHeading1: 'I am a',
            // salesAdminHeading2: 'M3M Life Sales Admin',

            influencer: false,
            influencerHeading: ''
        },
        customConfig: {
            welcomeContent: '',
            borderColor: 'border-primary'
        },
        pastReferralConfig: {
            selectedStatusColor: 'text-[#f58220]',
            bgColor: 'bg-primary'
        },
        formContent: {
            heading: '"Embark on a rewarding journey"',
            headingClass: `self-center text-secondary-focus uppercase sm:mt-0 text-3xl md:text-4xl xl:text-[40px] 2xl:text-[45px] w-[80%] lg:w-[50%] xl:w-[90%] 2xl:w-[90%] text-center tracking-wide leading-8 xl:leading-[40px] 2xl:leading-[45px]  ${
                getFont('M3M').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%23045ba8'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: false,
        sendReferralAttemptNotification: false,
        isDirectTAndCLink: false,
        influencerLinkActive: true,
        termsAndConditionsLink: '/m3m-website/terms-and-conditions',
        customLink: '/m3m-website/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default M3MConfig;
