import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';

const metroConfig = {
    clientId:
        'dc024884e6e4b9419ef464014a0bfd3254d272e71b5bb68eed21b4f563e27170',
    id: 35,
    name: 'Metro',
    fullName: 'Metro Group',
    programName:'Elite Advantage',
    primaryColor: '#ccab5c',
    fontBody: `${getFont('METRO').body}`,
    fontTitle: `${getFont('METRO').bold}`,
    theme: 'metroTheme',
    favicon:
        'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1699351915415_favicon',
    googleConfig: {
        gID: 'G-21H9X08KYP',
        keywordsContent:
            'Metro Elite real estate referral programs, embassy group real estate referral program, embassy group real estate refer and earn, Metro Elite real estate project refer, embassy group real estate, Metro Elite real estate loyalty program, embassy group real estate news, embassy group real estate upcoming projects in Bengaluru, embassy group real estate launch Metro Elite real estate, embassy group real estate launch, embassy group real estate program, embassy group real estate projects, embassy group real estate blogs, embassy group real estate offers, embassy group real estate upcoming projects in Bengaluru, embassy group real estate events, embassy group, Metro Elite, embassy group real estate best residential projects, embassy group real estate real estate developers, All New Projects by embassy group real estate Builders & Developers, Ongoing Projects by embassy group real estate Pvt Ltd, embassy group real estate Bengaluru, embassy group real estate new program, embassy group press release, embassy group upcoming projects, embassy property group, embassy group bangalore, embassy real estate, embassy reit, jitu virwani, embassy commercial projects, embassy group projects, embassy group projects in bangalore',
        descriptionContent:
            "We present to you a bouquet of such experiences and more with Embassy Priority - a bespoke loyalty and referral programme that's designed exclusively for Embassy homeowners",
        authorContent: 'Metro Elite Advantage'
    },
    facebookPixelConfig: {
        id: '1265694364073815', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~~10a5cbb34',
    webengageAPIKey:'29edbc9e-68b6-4b74-9d4a-09c5f93b9cdb',
    uatFacebookPixelConfig: {
        id: '',
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout5',
        
    },
    activateReferPage: false,

    logoLeft: {
        name: 'Metro Logo',
        baseUrl:
            'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1699350125553_metro logo',
        stickyUrl:
            'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1699350125553_metro logo',
        stickyNavBarLogoClass:
            'relative h-10 xl:h-12  left-2 xl:left-8 ease-in duration-300',
        baseNavBarLogoClass:
            ' relative h-10 xl:h-16  left-2 xl:left-8 ease-out duration-300'
    },

    // logoCenter: {
    //     name: 'Metro Logo',
    //     baseUrl: 'https://cdn.loyalie.in/78588414.png',
    //     stickyUrl: 'https://cdn.loyalie.in/78588414.png',
    //     stickyNavBarLogoClass:
    //         'relative h-10 lg:h-10 mt-5  ease-in duration-300',
    //     baseNavBarLogoClass: ' relative h-10 lg:h-14 mt-5 ease-out duration-300'
    // },

    logoRight: {
        name: 'Metro Logo',
        url: 'https://cdn.loyalie.in/78588414.png',
        stickyNavBarLogoClass:
            'relative h-10 lg:h-8 xl:h-10  right-2 lg:right-4 xl:right-8 ease-in duration-300',
        baseNavBarLogoClass:
            ' relative h-10 lg:h-8 xl:h-12 right-2 lg:right-4 xl:right-8 ease-out duration-300'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('METRO').secondary}`,
        height: 'h-16 lg:h-20 2xl:h-24 ease-out duration-300',
        stickyHeight: 'h-16 lg:h-20 2xl:h-24 ease-in duration-300 	',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-white lg:bg-white ',
        showActiveLinkColor: true,
        footerNavItemClass: `tracking-widest uppercase text-black text-[14px] font-light lg:text-[12px] xl:text-[15px] 3xl:text-lg first:border-r-2 pr-6 border-black  ${
            getFont('METRO').body
        }`,
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-white drop-shadow-md   ', // default: transparent
            containerClass: 'lg:mt-4',
            itemClass: `uppercase tracking-widest text-black text-[14px] lg:mr-7 hover:text-black lg:hover:text-primary font-light lg:text-[12px] xl:text-[15px] 3xl:text-lg ${
                getFont('METRO').body
            }`,
            dropDownContainerClass: `text-black  hover:text-black  lg:hover:text-primary `,
            dropDownItemClass: `tracking-widest text-black hover:text-black  lg:hover:text-primary text-[14px] lg:text-[12px] xl:text-[15px] 3xl:text-lg lg:hover:bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E] rounded-none ${
                getFont('METRO').body
            }`
        },

        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-white drop-shadow-md', //default: primary-content,
            containerClass: 'mt-4', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass: `uppercase tracking-widest text-black hover:text-black lg:mr-7 text-[14px] lg:hover:text-primary font-medium lg:text-[12px] xl:text-[15px] 3xl:text-lg ${
                getFont('METRO').body
            }` // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: '', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 ${
        getFont('METRO').bold
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage: 'https://cdn.loyalie.in/55486569.jpg',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/55486569.jpg',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0'
                    },
                    bannerContainerClass:
                        'h-[65vh] md:h-[85vh] lg:h-[89vh] bg-center ',
                    bannerItemContainerClass:
                        'items-start justify-center  px-8 md:pl-10 lg:pl-20',
                    position: 'text-left',

                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'w-full text-left mt-5 lg:mt-0 leading-6 md:leading-9 lg:leading-none',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'INTRODUCING',
                                    className: ` text-secondary text-[20px] 2xl:text-[25px] tracking-wide ${
                                        getFont('METRO').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: 'ELITE ADVANTAGE',
                                    className: `py-2 text-secondary tracking-wide text-[28px] md:text-[40px] lg:text-[40px]  2xl:text-[50px] ${
                                        getFont('METRO').bold
                                    }`
                                }
                            ]
                        },
                        {
                            type: 'TEXT',
                            content: 'Building customer-centric innovation',
                            className: `  text-secondary text-left text-[20px] 2xl:text-[26px] font-normal tracking-wide leading-6  ${
                                getFont('METRO').body
                            }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'Refer Now',
                            className: `md:mx-0 text-left mt-10 text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E] text-primary font-medium uppercase py-3 md:py-2 px-5 md:px-6 ${
                                getFont('METRO').body
                            }`,
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH_AND_SCROLL',
                            scrollTo: 'referral_section'
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: 'overview',
                    url: 'https://cdn.loyalie.in/53618026.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.30',
                        size: '80%'
                    },
                    bannerItemContainerClass: 'bg-cover',
                    className:
                        'flex flex-col justify-center text-center py-8 px-4 ',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'ABOUT ELITE ADVANTAGE',
                            className: `py-2 text-primary tracking-wide text-[28px] md:text-[40px] lg:text-[30px] 2xl:text-[40px] ${
                                getFont('METRO').bold
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Metro Group places you above everything else, and the Elite Advantage referral program is tailored to enhance your experience by providing you with access to exclusive events, offers, and promotions in all categories.',
                            className: `pt-1 text-[18px] md:text-[18px] 2xl:text-[20px]  text-secondary-focus leading-6 self-center w-[90%] lg:w-[85%] 2xl:w-[75%] break-words mb-5  ${
                                getFont('METRO').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'Furthermore, we recognize and appreciate your efforts in expanding our community by rewarding your referrals. With this platform, we aim to prioritize your experience and give it the attention it deserves, placing you at the center of affluence.',
                            className: `pt-1 text-[18px] md:text-[18px] 2xl:text-[20px] text-secondary-focus leading-6 self-center w-[90%] lg:w-[90%] 2xl:w-[85%]  break-words mb-5 ${
                                getFont('METRO').body
                            }`
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/75717550.jpg',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0.60',
                        repeat: 'no-repeat',
                        size: 'cover',
                        position: 'bottom right'
                    },
                    className: 'grid content-center px-5 text-center h-[60vh] ',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'ELITE ADVANTAGE BRINGS YOU A WORLD OF EXCLUSIVE PRIVILEGES',
                            className: `text-transparent bg-clip-text bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E]  text-[28px] md:text-[40px] lg:text-[40px] 2xl:text-[50px] w-[80%] xl:w-[65%] 2xl:w-[70%] mx-auto leading-tight ${
                                getFont('METRO').bold
                            }`
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'overview',
                    url: 'https://cdn.loyalie.in/53618026.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.30',
                        size: '90%'
                    },
                    bannerItemContainerClass: 'bg-cover',
                    className:
                        'flex flex-col justify-center text-center py-10 px-4 ',
                    components: [
                        {
                            type: 'ICONS_LAYOUT',
                            containerClassName:
                                'h-content lg:h-auto items-center py-3 xl:py-5 ',
                            gridClassName:
                                'flex lg:grid lg:grid-cols-4  m-auto overflow-x-scroll lg:overflow-x-hidden',
                            infotxtClassName: ` mx-auto leading-6 xl:leading-[27px] w-[90%] xl:w-[92%] 2xl:w-[95%] px-11 md:px-20 text-secondary-focus text-[18px] xl:text-[22px] font-normal ${
                                getFont('METRO').body
                            }`,
                            infoHeadingClassName: `w-full mx-auto uppercase leading-6 xl:leading-[27px] md:w-[60%] lg:w-[95%] 2xl:w-[90%]  md:px-2 text-[20px] md:text-[22px] lg:text-[18px] xl:text-[20px] 2xl:text-[25px]  text-primary font-normal pt-10 tracking-wide ${
                                getFont('METRO').bold
                            }`,
                            infoContainerClassName:
                                'min-w-[80vw] lg:min-w-full mt-4 lg:mt-0 cursor-pointer',
                            imgContainerClassName:
                                'hover:scale-95 bg-black p-8 2xl:p-10 rounded-[100%] w-[120px] h-[120px] 2xl:w-[150px] 2xl:h-[150px] mx-auto ',
                            imgClassName:
                                ' object-cover lg:mx-auto mx-auto lg:mb-2',
                            info: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/70540479.png',
                                    infoHeading:
                                        'Referral benefit of 0.5% of the unit cost',
                                    path: 'gift-and-earn',
                                    onClick: 'CHANGE_PATH'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/68955749.png',
                                    infoHeading:
                                        'ENJOY EXCLSUIVE OFFERS FROM TOP BRANDS',
                                    onClick: 'CHANGE_PATH',
                                    path: 'deals'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/38873988.png',
                                    infoHeading:
                                        'PARTICIPATE IN COMMUNITY EVENTS & WORKSHOPS',
                                    onClick: 'CHANGE_PATH',
                                    path: 'events'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/9980203.png',
                                    infoHeading:
                                        'ENGAGE AND INTERACT VIA TRENDING BLOGS',
                                    onClick: 'CHANGE_PATH',
                                    path: 'blogs'
                                }
                            ]
                        }
                    ]
                },

                //REFERRAL FORM
                {
                    type: 'REFERRAL_FORM_BANNER'
                },

                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/53618026.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.30',
                        size: '90%'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-10 relative',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR Exclusive Offers',
                            className: `py-2 text-primary uppercase tracking-wide text-[28px] md:text-[40px] lg:text-[30px] 2xl:text-[40px] ${
                                getFont('METRO').bold
                            }`
                        },
                        {
                            type: 'CAROUSEL',
                            // getVendorLogos: true,
                            offerCards: true,
                            getVendorData: true,
                            classNameCTA: `bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E] px-5 py-1.5 text-primary text-center mt-2 mx-auto ${
                                getFont('METRO').body
                            }`,
                            ContainerClassName:
                                'bg-cover rounded-lg h-auto mx-3 2xl:mx-5 p-8',
                            caraouselClassName: `w-[80%] md:w-full xl:w-[86%] 2xl:w-[95%] 3xl:w-[80%] mx-auto md:px-12  pt-6 relative pb-10 ${
                                getFont('EVOS').body
                            }`,

                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 4,
                            pauseOnHover: true,
                            speed: 3000,
                            onImageClick: openURLNewWindow
                        },
                        {
                            type: 'TEXT',
                            content: 'LATEST BLOGS',
                            className: `py-2 text-primary uppercase tracking-wide text-[28px] md:text-[40px] lg:text-[30px] 2xl:text-[40px] ${
                                getFont('METRO').bold
                            }`
                        },
                        {
                            type: 'BLOG_LAYOUT_VERTICAL1',
                            className:
                                'w-full h-auto flex flex-col-reverse lg:flex-row mt-6 px-10 xl:px-14 2xl:px-16 justify-center ',
                            classNameContainer:
                                ' w-full px-5 md:px-44 lg:px-0 xl:w-[90%] overflow-x-scroll lg:overflow-x-hidden flex lg:grid lg:grid-cols-3 gap-x-4 md:gap-x-8 lg:gap-x-6 xl:gap-x-10 2xl:gap-x-16 3xl:gap-x-20 gap-y-14 '
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    className: 'block md:flex pt-0 ',
                    components: [
                        {
                            type: 'CUSTOM',
                            url: 'https://cdn.loyalie.in/53618026.png',
                            linearGradient: {
                                R: '0',
                                G: '0',
                                B: '0',
                                opacity: '0',
                                size: '290%'
                            },
                            bannerItemContainerClass: 'bg-cover',
                            className:
                                'w-full md:w-[25%] bg-[#231f20] p-10 md:p-8 xl:p-16 xl:py-24 flex items-center ',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'Projects',
                                    className: `py-2 text-transparent bg-clip-text bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E] uppercase tracking-wide mx-auto text-[28px] md:text-[40px] lg:text-[30px] 2xl:text-[40px] ${
                                        getFont('METRO').bold
                                    }`
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            bannerItemContainerClass: 'bg-cover',
                            className: 'w-full md:w-[75%]',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    bannerItemContainerClass: 'bg-cover',
                                    className: ' text-center',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'grid grid-cols-1 lg:grid-cols-3 h-auto items-center',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className: ' relative',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1712313871405_Metro%20100%20Acre',
                                                            className:
                                                                'z-1 w-full h-[170px] lg:h-[174px] xl:h-[214px] 2xl:h-[254px] object-cover object-center'
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Metro 100 Acre',
                                                            // onClick:
                                                            //     'CHANGE_PATH',
                                                            // path: `projects/detail?projectId=2`,
                                                            className: `text-white text-[16px] xl:text-[18px] cursor-pointer bg-black bg-opacity-[0.65] z-10 absolute bottom-0 w-full py-3 tracking-wide ${
                                                                getFont('METRO')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className: ' relative',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1712313837702_Metro%20Serenity',
                                                            className:
                                                                'z-1 w-full h-[170px] lg:h-[174px] xl:h-[214px] 2xl:h-[254px] object-cover object-center'
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                ' Metro Serenity',
                                                            // onClick:
                                                            //     'CHANGE_PATH',
                                                            // path: `projects/detail?projectId=3`,
                                                            className: `text-white text-[16px] xl:text-[18px] cursor-pointer bg-black bg-opacity-[0.65] z-10 absolute bottom-0 w-full py-3 tracking-wide ${
                                                                getFont('METRO')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className: ' relative',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/7/51820207.jpg',
                                                            className:
                                                                'z-1 w-full h-[170px] lg:h-[174px] xl:h-[214px] 2xl:h-[254px] object-cover object-center'
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Metro Greenwoods Pristine',
                                                            onClick:
                                                                'CHANGE_PATH',
                                                            path: `projects/detail?projectId=7`,
                                                            className: `text-white text-[16px] xl:text-[18px] cursor-pointer bg-black bg-opacity-[0.65] z-10 absolute bottom-0 w-full py-3 tracking-wide  ${
                                                                getFont('METRO')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                }
                                            ]
                                        },

                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'grid grid-rows-2 md:grid-cols-2 grid-flow-col  items-center',
                                            components: [
                                               
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'col-span-2 md:col-span-1 relative ease-in-out duration-300',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/8/58906764.jpg',
                                                            className:
                                                                'z-1 h-[170px] xl:h-[213px] 2xl:h-[255px] w-full object-cover object-center'
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Metro Royale Vista',
                                                            onClick:
                                                                'CHANGE_PATH',
                                                            path: `projects/detail?projectId=8`,
                                                            className: `text-white text-[16px] xl:text-[18px] cursor-pointer bg-black bg-opacity-[0.65] z-10 absolute bottom-0 w-full py-3 tracking-wide ${
                                                                getFont('METRO')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        ' col-span-2 md:col-span-1  relative',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/6/39968409.jpg',
                                                            className:
                                                                'z-1 h-[170px] xl:h-[213px] 2xl:h-[255px] w-full object-cover object-center'
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Metro Kings Court',
                                                            onClick:
                                                                'CHANGE_PATH',
                                                            path: `projects/detail?projectId=6`,
                                                            className: `text-white text-[16px] xl:text-[18px] cursor-pointer bg-black bg-opacity-[0.65] z-10 absolute bottom-0 w-full py-3 tracking-wide ${
                                                                getFont('METRO')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                },
                                                
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'row-span-2  relative',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1712313649935_Metro%20Greenwoods',
                                                            className:
                                                                'z-1 h-[340px] xl:h-[426px] 2xl:h-[510px] w-full object-cover object-center'
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Metro Greenwoods',
                                                            // onClick:
                                                            //     'CHANGE_PATH',
                                                            // path: `projects/detail?projectId=4`,
                                                            className: `text-white text-[16px] xl:text-[18px] cursor-pointer bg-black bg-opacity-[0.65] z-10 absolute bottom-0 w-full py-3 tracking-wide ${
                                                                getFont('METRO')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            className:
                                                'grid grid-cols-2 hidden h-auto items-center',
                                            components: [
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'relative lg:hidden',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://metrogroup.ind.in/a3.jpg',
                                                            className: 'z-1'
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Metro Serenity',
                                                            onClick:
                                                                'CHANGE_PATH',
                                                            path: 'deals',
                                                            className: `text-white text-[16px] xl:text-[18px] cursor-pointer bg-black bg-opacity-[0.65] z-10 absolute bottom-0 w-full py-3 tracking-wide ${
                                                                getFont('METRO')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'CUSTOM',
                                                    className:
                                                        'relative lg:hidden',
                                                    components: [
                                                        {
                                                            type: 'IMAGE',
                                                            url: 'https://metrogroup.ind.in/assets/front/images/metro100acres_new.jpg',
                                                            className:
                                                                'z-1 h-[170px] xl:h-[213px]  2xl:h-[255px] object-cover object-center'
                                                        },
                                                        {
                                                            type: 'TEXT',
                                                            content:
                                                                'Metro 100 Acres - The Access',
                                                            onClick:
                                                                'CHANGE_PATH',
                                                            path: 'deals',
                                                            className: `text-white text-[16px] xl:text-[18px] cursor-pointer bg-black bg-opacity-[0.65] z-10 absolute bottom-0 w-full py-3 tracking-wide ${
                                                                getFont('METRO')
                                                                    .body
                                                            }`
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        dropDown: {
            show: true,
            name: 'ENGAGEMENT',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'ENGAGEMENT',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://cdn.loyalie.in/1692634565592_refer%20page%20main',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/1692634520683_refer%20page',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center md:text-left text-black text-[15px] md:text-[20px] xl:text-[22px] uppercase my-5 tracking-widest w-full md:w-[40%] font-semibold md:font-normal lg:w-[48%] ml-auto  lg:leading-7 ${
                                getFont('METRO').body
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'flex -full md:w-[40%] lg:w-[48%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `md:mx-0 text-left text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E] text-primary font-medium uppercase py-3 md:py-2 px-5 md:px-6 mx-auto lg:mx-0 ${
                                        getFont('METRO').body
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://cdn.loyalie.in/1692634565592_refer%20page%20main',
                backgroundImageMobile:
                    'https://cdn.loyalie.in/1692634520683_refer%20page',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[60vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').body
                    //         }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-black text-[15px] leading-10 md:text-[20px] xl:text-[45px] font-bold tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').heading
                    //         }`
                    // },
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex w-[70%] ml-auto justify-center mt-7', components: [{
                    //         type: 'BUTTON',
                    //         content: 'Start Referring Now',
                    //         scrollTo: 'referral_section',
                    //         onClick: 'SCROLL'
                    //     }]
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold tracking-widest bg-primary text-white px-8 md:px-6 py-3 lg:mt-2 xl:mt-0 mx-auto ${
                getFont('METRO').heading
            }`
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[90vh] lg:h-[88vh] bg-center  mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage: 'https://cdn.loyalie.in/55049604.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/55049604.jpg',
            components: [
                {
                    type: 'TEXT',
                    content: 'METRO READERS’ CLUB',
                    className: `font-semibold text-secondary text-[28px] md:text-[40px] lg:text-[40px]  2xl:text-[50px] tracking-wider ${
                        getFont('METRO').bold
                    }`
                },

                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal text-secondary mb-3  text-xl md:text-base text-[16px] 2xl:text-[17px] tracking-wider ${
                        getFont('METRO').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `uppercase md:mt-6 text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E] text-primary font-medium uppercase py-3 md:py-2 px-5 md:px-6 ${
                        getFont('METRO').body
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'METRO READERS’ CLUB',
                    className: `font-semibold text-secondary text-[28px] md:text-[40px] lg:text-[40px]  2xl:text-[50px] tracking-wider ${
                        getFont('METRO').bold
                    }`
                },

                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal text-secondary mb-3  text-xl md:text-base text-[16px] 2xl:text-[17px] tracking-wider ${
                        getFont('METRO').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `uppercase md:mt-6 text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E] text-primary font-medium uppercase py-3 md:py-2 px-5 md:px-6 ${
                        getFont('METRO').body
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('METRO').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('METRO').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                components: [],
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-normal text-[20px] text-[#1c2936] ${
                    getFont('METRO').heading
                }`,
                headingClass: `text-[20px] md:text-[20px] lg:text-[25px] 2xl:text-[30px] uppercase font-semibold text-black mt-3 leading-9 break-words ${
                    getFont('METRO').bold
                }`,
                summaryClass: `inline text-[17px] mt-8 text-black  min-h-[40px] lg:min-h-[70px] ${
                    getFont('METRO').heading
                }`,
                dateClass: `text-[13px] uppercase text-black font-normal my-3 ${
                    getFont('METRO').heading
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('METRO').heading
                }`
            },
            sectionHeadings: `px-10 lg:px-16 pt-8 text-[28px] md:text-[40px] lg:text-[30px] 2xl:text-[40px] tracking-wider text-center uppercase ${
                getFont('METRO').bold
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            components: [],
            cardHeight:
                'h-[480px] md:h-[470px] lg:h-[500px] 2xl:h-[510px] 3xl:h-[620px]',
            bodyHeight:
                'h-[230px] md:h-[280px] lg:h-[280px] xl:h-[330px] 3xl:h-[380px]',
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-secondary-focus my-5 md:h-[45px] lg:h-[70px] text-[20px] 3xl:text-[22px] font-medium uppercase leading-6 break-words mx-5 ${
                getFont('METRO').bold
            }`,
            categoryClass: `bg-black bg-opacity-[0.65] text-white ${
                getFont('METRO').body
            }`,
            summaryClass: `font-normal text-[16.5px] xl:text-[17px] mt-2 leading-6  mx-5 md:h-[50px] lg:h-[70px] text-black ${
                getFont('METRO').body
            }`,
            buttonClass: `bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E] flex justify-center self-end float-right text-[13px] xl:text-[16px] text-black py-1.5 px-4 md:px-4 m-5 ${
                getFont('METRO').body
            }`,
            buttonArrowUrl: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.16 11.59'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23000;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cline class='cls-1' y1='5.62' x2='55' y2='5.62'/%3E%3Cpolyline class='cls-1' points='48.5 0.39 51.93 3.09 55.35 5.79 51.93 8.49 48.5 11.19'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-[#919396] ${
                getFont('METRO').heading
            }`,
            noBlogsClass: `bg-secondary p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('METRO').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/9761211.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/9761211.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0'
            },

            negativeMargin: '-mt-12 lg:-mt-0 2xl:-mt-0',
            containerClass: ' text-white w-[50%] ',
            heading: '',
            headingClass: ` text-center lg:w-[70%] text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] text-secondary uppercase ${
                getFont('METRO').bold
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('METRO').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-12 lg:mt-24 2xl:mt-28 hero hero-content items-start md:items-center max-w-full h-[90vh] lg:h-[100vh] bg-bottom lg:bg-center mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `text-white mt-16 md:mt-0 uppercase tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] ${
                        getFont('METRO').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `mt-3 xl:mt-4 text-primary  font-normal text-center text-[16px] md:text-[19px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 ${
                        getFont('METRO').secondary
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    onClick: 'FUNC',
                    className: `md:mx-0 text-left mt-3 xl:mt-4 uppercase text-[15px] md:text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-primary hover:bg-secondary text-white font-normal px-4 py-3  xl:px-6 xl:py-3.5 ${
                        getFont('METRO').medium
                    }`
                }
            ]
        },

        eventsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/53618026.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.30',
                size: '90%'
            },
            heading: 'PAST EVENTS',
            headingClass: `self-center text-center text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] text-black w-[60%] mt-16 ${
                getFont('METRO').bold
            }`,
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('METRO').body
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('METRO').body
            }`
        },
        eventLayout: {
            titleClass: `text-accent leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('METRO').bold
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${
            getFont('METRO').heading
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('METRO').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content items-start md:items-center max-w-full h-[30vh] lg:h-[45vh] bg-bottom lg:bg-center mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '0',
                G: '0',
                B: '0',
                opacity: '0'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692635066109_offer%20page',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692635066109_offer%20page',
            components: [],
            loginBannerComponents: [
                //4C
                // {
                //     type: 'TEXT',
                //     content: 'Select Offers And Benefits',
                //     className: `text-white mt-16 md:mt-0 uppercase tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] ${
                //         getFont('METRO').heading
                //     }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-primary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Enjoy specially curated offers from top pan India brands',
                //     className: `mt-3 xl:mt-4 text-primary  font-normal text-center text-[16px] md:text-[19px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 ${
                //         getFont('METRO').secondary
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClick: 'FUNC',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     className: `md:mx-0 text-left mt-3 xl:mt-4 uppercase text-[15px] md:text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-primary hover:bg-secondary text-white font-normal px-4 py-3  xl:px-6 xl:py-3.5 ${
                //         getFont('METRO').medium
                //     }`
                // }
            ]
        },

        dealsSection: {
            sectionBackgroundImage: '',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.89',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center font-semibold md:text-[20px] text-center text-[17px] text-primary w-[90%] ${
                getFont('METRO').secondary
            }`,
            additionalText: 'CURATING EXCLUSIVE OFFERS AND PROMOTIONS FOR YOU',
            additionalTextClass: `text-2xl xl:text-3xl uppercase text-black  ${
                getFont('METRO').bold
            } `,
            subAdditionalText:
                'Club Benefique is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-normal leading-6 ${
                getFont('METRO').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('METRO').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            listTitleClass: `text-2xl lg:text-xl leading-6 lg:leading-5 text-black ${
                getFont('METRO').body
            }`,
            listBodyClass: `${getFont('METRO').body}`,
            pattern1: 'https://cdn.loyalie.in/43800302.png',
            pattern2: 'https://cdn.loyalie.in/43800302.png',
            linearGradient: {
                R: '243',
                G: '243',
                B: '243',
                opacity: '1',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            logoClassName: `self-center rounded-md drop-shadow-lg z-10 `,
            titleClassName: ` text-primary font-medium uppercase text-2xl xl:text-3xl ${
                getFont('METRO').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-normal  ${
                getFont('METRO').heading
            }`,
            dateClassName: ` text-primary text-[16px] font-normal capitalize  ${
                getFont('METRO').body
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-sm  ${
                getFont('METRO').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-primary',
            termsClassName: `font-normal text-neutral text-xs break-words leading-1 ${
                getFont('METRO').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `self-center uppercase text-[15px] md:text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E] text-primary font-normal px-4 py-2  xl:px-6 xl:py-3  ${
                    getFont('METRO').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: ` self-center  uppercase text-[15px] md:text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E] text-primary font-normal px-4 py-2  xl:px-6 xl:py-2 ${
                    getFont('METRO').body
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.40'
                },
                backgroundImage:
                    'https://metrogroup.ind.in/assets/front/images/metro_builders/real_estate_new1.jpg',
                backgroundImageMobile:
                    'https://metrogroup.ind.in/assets/front/images/metro_builders/real_estate_new1.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[50vh] md:h-[70vh] lg:h-[87vh] bg-bottom',

                components: [
                    {
                        type: 'TEXT',
                        content: ' METRO GROUP PROJECTS',

                        className: ` mx-auto uppercase text-center text-secondary text-[28px] md:text-[40px] lg:text-[40px]  2xl:text-[50px] tracking-wider ${
                            getFont('METRO').bold
                        }`
                    }
                ]
            }
        ],
        moduleConfig: {
       
            constructionVisible: true,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },

    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/46329169.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.97',
            repeat: 'repeat',
            size: 'contain'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.40'
                },
                backgroundImage: 'https://cdn.loyalie.in/45495424.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/45495424.jpg',
                bannerContainerClassName:
                    'hero hero-content  text-center max-w-full lg:bg-center h-[50vh] md:h-[70vh] lg:h-[87vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: ` mx-auto text-center mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[40px]  2xl:text-[50px]  tracking-wider ${
                            getFont('METRO').bold
                        }`
                    }
                ]
            }
        ],

    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-primary mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('METRO').body
        }`,
        listClassName: `text-primary font-normal text-[17px] leading-6 ${
            getFont('METRO').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.30'
                },
                backgroundImage: 'https://cdn.loyalie.in/41393320.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/41393320.jpg',
                bannerContainerClassName:
                    'hero hero-content  text-center max-w-full lg:bg-top h-[50vh] md:h-[70vh] lg:h-[87vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS & CONDITIONS',

                        className: ` mx-auto text-center mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[40px]  2xl:text-[50px] tracking-wider  ${
                            getFont('METRO').bold
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[15%] xl:w-[10%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/53618026.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.20',
                    size: '90%'
                },
                className: 'text-left p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-primary font-medium text-[20px] md:text-[20px] ${
                            getFont('METRO').bold
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-primary mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('METRO').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Metro Group project, and has entered into a registered Agreement to Sell; or (ii) employee of  Metro Group; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Metro Group or any of its sister concerns/subsidiaries/affiliates (“Metro Group Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Metro Group Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Metro Group.',
                            'Referrer shall not be able to refer existing customer(s) of Metro Group. Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Metro Group or (ii) is/are already registered with Metro Group as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Metro Group’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',
                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Metro Group within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Metro Group.',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by Metro Group or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-primary font-normal text-[17px] leading-6 ${
                            getFont('METRO').body
                        }`,
                        classNameContainer: 'px-6 mt-6'
                    },
                    { type: 'RAZORPAY_TERMS', classNameContainer: 'px-6 mt-6' },

                    {
                        type: 'CUSTOM',
                        className: 'px-6 pt-2',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Metro Group, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('METRO').body
                                } inline text-primary font-normal text-[17px] leading-6  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-[#D0AB6E] font-medium text-[17px] leading-6 ${
                                    getFont('METRO').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-primary font-normal text-[17px] leading-6 ${
                                    getFont('METRO').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-[#D0AB6E] font-medium text-[17px] leading-6 ${
                                    getFont('METRO').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd. In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-primary font-normal text-[17px] leading-6 ${
                                    getFont('METRO').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-[#D0AB6E] font-medium text-[17px] leading-6 ${
                                    getFont('METRO').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-primary font-normal text-[17px] leading-6 ${
                                    getFont('METRO').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        appStoreLink: 'https://apps.apple.com/in/app/krc-royalty/id6444639384',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.krc&hl=en&gl=US',
        appScreen: 'https://cdn.loyalie.in/38345309.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/71940930.png',
        containerClassName: 'bg-secondary-content xl:pt-0',
        footerLogoClassName: 'self-center md:w-[15%] lg:w-[16%] lg:pl-3 ',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('METRO').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterExpandContent: false,
        loyalieFooterExpandName: 'Disclaimer',
        loyalieFooterExpandClassName: `text-center text-white cursor-pointer pb-2 ${
            getFont('METRO').heading
        }`,
        loyalieFooterExpandContentClassName: ` self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-2 xl:pr-6 text-[13px] 3xl:text-[15px] tracking-tight text-white ${
            getFont('METRO').body
        }`,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[11px] 3xl:text-[13px] tracking-tight text-black ${
            getFont('METRO').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('METRO').heading
        }`,
        paginationClass:
            ' text-black disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-primary border-primary hover:bg-opacity-90 hover:border-primary hover:bg-primary disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-black bg-opacity-80'
    },
    referralForm: {
        name: 'Metro Group Real Estate',
        theme: 'metroTheme',
        color: '#ccab5c',
        backgroundImage: 'https://cdn.loyalie.in/33695762.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '0',
            G1: '0',
            B1: '0',
            opacity1: '0',
            R2: '0',
            G2: '0',
            B2: '0',
            opacity2: '0'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading1: 'I have visited an',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own a',
            employee: true,
            employeeHeading1: 'I am a',
            salesAdmin: true,
            salesAdminHeading1: 'I am a',
            influencer: false,
            influencerHeading: ''
        },
        formContent: {
            heading: '"CONSTANTLY COMMITED TO DELIGHT"',
            headingClass: `self-center text-white sm:mt-0 text-2xl md:text-3xl 2xl:text-4xl w-[95%] md:w-[80%] text-center leading-7 md:leading-10 tracking-wide ${
                getFont('METRO').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='white'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='%23D0AB6E' stroke-width='7' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='7' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: false,
        sendReferralAttemptNotification: false,
        isDirectTAndCLink: false,
        influencerLinkActive: true,
        termsAndConditionsLink: '/elite-website/terms-and-conditions',
        customLink: '/elite-website/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default metroConfig;
