import { useState, useCallback } from 'react';
import axios from 'axios';
import { winnreAuth, cmsClient } from '../utils/axios';
import qs from 'qs';

const useAxios = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    // console.log("HOOK RUNNING");
    const sendRequest = useCallback(async (requestConfig, applyData) => {
        setIsLoading(true);
        setError(null);
        const params = {
            method: requestConfig.method,
            url: requestConfig.url,
            data: requestConfig.isFormData
                ? requestConfig.data
                : qs.stringify(requestConfig.data),
            headers: requestConfig.headers
        };
        // console.log('type enc', requestConfig.isEncryptedResponse);
        const axiosPromise = requestConfig?.isEncryptedResponse
            ? winnreAuth(params)
            : cmsClient(params);
        // console.log(axiosPromise);
        // Promise.all([axiosPromise])
        axiosPromise
            .then((response) => {
                // if (!requestConfig.isEncryptedResponse) {
                //     if (response.data.status === 0) {
                //         setModalMessage('Not working');
                //         setShowModal(true);
                //     }
                // }
                // setModalMessage('It worked');
                // setShowModal(true);
                if (applyData) {
                    applyData(
                        requestConfig?.isEncryptedResponse
                            ? response
                            : response.data
                    );
                }
                setIsLoading(false);
                // console.log('REQ CAME TILL HERE');
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong!');
                setIsLoading(false);
                // console.log('I RANN', err);
            });
    }, []);

    return {
        isLoading,
        error,
        sendRequest,
        showModal,
        modalMessage,
        setShowModal,
        setModalMessage
    };
};

export default useAxios;
