import React, { useEffect, useState } from 'react';
import Modal from '../../Organisms/Modal';
import { getConfig } from '../../../utils/config';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import useData from './data';
import { useDispatch, useSelector } from 'react-redux';
import Completed from './Completed';
import { authActions } from '../../../store/auth-slice';
import { LoadingSpinner } from '../../Molecules/LoadingUI';

function Onboarding() {
    const [step, setStep] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const { getUserProperty, userProperty, amenities, loading } = useData();
    // const dummyuserProperty = null
    // {
    //     interestedAmenities:[23, 24],
    // onboarded :false,
    // projectBgImage:"https://reloydev.s3.ap-south-1.amazonaws.com/Document/Project/5/18265290.jpg",
    // projectId: 5,
    // projectName:"Rejuve 360",
    // propertyId: 213
    // }
    const {
        userName,
        onboardingModalVisible,
        finishedFirstTimeLoginGuide,
        userRole
    } = useSelector((state) => state.auth);
    const { status } = useSelector((state) => state.auth.userDetails);
    const dispatch = useDispatch();
    const config = getConfig();
    useEffect(() => {
        getUserProperty();
        window?.webengage?.track('OnboardingFormViewed');
    }, []);
    const getConditionalFormStep = () => {
        switch (step) {
            case 1:
                return (
                    <StepOne
                        setStep={setStep}
                        config={config}
                        userProperty={userProperty}
                        setAlertMessage={setAlertMessage}
                        setShowErrorAlert={setShowErrorAlert}
                        showErrorAlert={showErrorAlert}
                        alertMessage={alertMessage}
                    />
                );
            case 2:
                return (
                    <StepTwo
                        setStep={setStep}
                        amenities={amenities}
                        config={config}
                        propertyId={userProperty?.propertyId}
                        setAlertMessage={setAlertMessage}
                        setShowErrorAlert={setShowErrorAlert}
                        showErrorAlert={showErrorAlert}
                        alertMessage={alertMessage}
                        userProperty={userProperty}

                    />
                );
        }
    };
    // console.log(loading)
    // const [finished, setFinished] = useState(false)
    useEffect(() => {
        if(finishedFirstTimeLoginGuide){
            if(userProperty){
                setStep(2)
            } else {
                setStep(3)
            }
        } else {setStep(1)}
      

            return () => {
                setStep(null)
                // console.log('ONBOARDING REMOVED')
            }
    }, [finishedFirstTimeLoginGuide, onboardingModalVisible, userProperty]);
    // console.log(step)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSuccessAlert(false);
            setShowErrorAlert(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, [showSuccessAlert, showErrorAlert]);

    return (
        <Modal
            className="w-[96%] lg:w-[70%] xl:w-[60%] 2xl:w-[55%]"
            visible={onboardingModalVisible}
            onClick={
                step === 1
                    ? () => {}
                    : () => {
                        dispatch(authActions.closeOnboardingModal());
                        // setStep(2);
                    }
            } // change to null
        >{loading && <div className='h-[40vh]'> <LoadingSpinner/></div>}
            {step < 3 && !loading && (
                <div className="flex flex-col lg:flex-row px-4 lg:px-8 py-6 lg:py-10 font-Poppins">
                    {/* Left Side */}
                    <div className="w-full lg:w-2/5 border-b-2 lg:border-b-0 lg:border-r-2 pr-4 pb-4 lg:pb-0">
                        <h2 className="inline-block lg:block font-semibold text-2xl lg:text-3xl">
                            Welcome
                        </h2>
                        <h2 className="inline-block lg:block font-semibold text-2xl lg:text-3xl pl-2 lg:pl-0">
                            Onboard!
                        </h2>
                        <div className="flex gap-x-2 mt-5 lg:mt-8 w-[25%] lg:w-[20%]">
                            <div
                                className={`w-1/3 h-2 ${step === 1 ? 'bg-[#14897b]' : 'bg-[#c0d6da]'
                                    }  rounded-2xl`}
                            />
                            <div
                                className={`w-1/3 h-2 ${step === 2 ? 'bg-[#14897b]' : 'bg-[#c0d6da]'
                                    }  rounded-2xl`}
                            />
                            <div
                                className={`w-1/3 h-2 ${step === 3 ? 'bg-[#14897b]' : 'bg-[#c0d6da]'
                                    }  rounded-2xl`}
                            />
                        </div>
                        {step === 1 && (
                            <>
                                <h3 className="font-semibold text-xl lg:text-2xl mt-4 lg:leading-9">
                                    {`Hey ${userName},`}
                                </h3>
                                <p className="text-sm mt-1">
                                    Tell us more so we can know you better
                                </p>
                            </>
                        )}
                        {step == 2 && userProperty && (
                            <>
                                <img
                                    src={userProperty?.projectBgImage}
                                    className="hidden lg:block rounded h-28 mt-5"
                                />
                                <h1 className="font-semibold text-xl mt-6 lg:mt-4 leading-6">
                                    {userProperty?.unitNumber +
                                        ' ' +
                                        (userProperty?.towerNumber || '') +
                                        ', ' +
                                        userProperty?.projectName}
                                </h1>
                                <p className="mt-2 lg:mt-4 text-sm">
                                    {`Tell us more about your ${userProperty?.projectName} property so we can
                            curate the best offers for you.`}
                                </p>
                            </>
                        )}
                    </div>
                    {/* Right Side */}
                    <div className="w-full lg:w-3/5 lg:pl-7">
                        {getConditionalFormStep()}
                    </div>
                </div>
            )}
            {step == 3 && !loading && (
                <Completed setStep={setStep} userProperty={userProperty} />
            )}
        </Modal>
    );
}

export default Onboarding;
