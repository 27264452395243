import React, { Component, useEffect, useState } from 'react';
import './Carousel.css';
import styled from 'styled-components';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactPlayer from 'react-player';
import CustomSlide from '../CustomSlide';
import { getConfig } from '../../../utils/config';
import OfferCarousel from '../OfferCarousel';
import { cmsClient, winnreNoAuth } from '../../../utils/axios';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { useAxios } from '../../../Hooks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-8 -mt-7  text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                />
            </svg>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-8 -mt-7  text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                />
            </svg>
        </div>
    );
}
export default function Responsive(props) {
    const config = getConfig();
    const [vendorLogos, setVendorLogos] = useState([]);
    const [projectLogos, setProjectLogos] = useState([]);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const date = moment().format();
    const { isLoading, sendRequest: fetchOffers } = useAxios();
    // console.log("get vendor data ",props?.getVendorData)
    const getVendorLogos = () => {
        let url;
        if (isAuthenticated) {
            url = `/winnre/user/deals/filter-v2?&status=PUBLISHED&isAscending=false&sortBy=priority&endDate=${date}&categoryList=1,2,3,5&pageSize=30`;
        } else {
            url = `/cms_service/deals/filter-v2?isVisibleToAll=true&&endDate=${date}&status=PUBLISHED&clientId=${config?.id}&pageSize=30&isAscending=false&sortBy=priority&categoryList=1,2,3,5`;
        }
        fetchOffers(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url,
                method: 'get',
                isFormData: true
            },
            (res) => {
                if (props.getVendorData) {
                    const logos = res?.content.map((deal) => {
                        return {
                            imgUrl: deal?.vendorLogoUrl || '',
                            dealName: deal?.title,
                            dealImage: deal?.thumbnailImageUrl
                                ? deal?.thumbnailImageUrl
                                : deal?.displayImageUrl,
                            dealId: deal?.id
                        };
                    });
                    setVendorLogos(
                        props.offerCards ? logos.splice(0, 10) : logos
                    );
                } else if (props.getVendorLogos) {
                    let setLogos = new Set(
                        res?.content.map((deal) => deal?.vendorLogoUrl || '')
                    );
                    let logos = Array.from(setLogos).map((logo) => {
                        return { imgUrl: logo };
                    });
                    setVendorLogos(logos);
                }
            }
        );
    };

    const getProjects = () => {
        winnreNoAuth
            .get('/winnre/user/project/names?isAscending=true')
            .then((res) => {
                console.log(res)
                if (props.getProjects) {
                    const logos = res?.map((project) => ({
                        imgUrl: project.projectBgImage,
                        coverImage: project.projectLogo,
                        projectId: project.project?.id,
                        url:
                            '/projects/detail?projectId=' + project.project?.id,
                        caption: project.projectName
                    }));
                    setProjectLogos(logos);
                    // console.log(res);
                }
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    useEffect(() => {
        if (props?.imgArr) {
            return;
        }
        if (props.getVendorData || props.getVendorLogos) {
            getVendorLogos();
        }
        if (props.getProjects) {
            getProjects();
        }
    }, [isAuthenticated]);
    var settings = {
        dots: props.dots,
        arrows: props.arrows,
        infinite: props.infinite,
        vertical: props.vertical,
        slidesToShow: props.slidesToShow,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: props.speed,
        autoplaySpeed: props.autoplaySpeed,
        cssEase: 'linear',
        pauseOnHover: props.pauseOnHover,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: props?.xlSlidesToShow || 3,
                    slidesToScroll: 1
                    // infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: props?.minSlides ? 2 : 3,
                    slidesToScroll: 1
                    // infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const history = useHistory();

    const onClickHandler = (imgdata, index) => {
        if (props?.internalRedirect) {
            history.push(imgdata?.url);
        } else {
            props?.onImageClick(imgdata, index);
        }
    };

    return (
        <div
            className={props.caraouselClassName || 'pb-10 '}
            style={{
                backgroundImage: `linear-gradient(90deg, rgba(${props.linearGradient?.R}, ${props.linearGradient?.G}, ${props.linearGradient?.B}, ${props.linearGradient?.opacity}),rgba(${props.linearGradient?.R}, ${props.linearGradient?.G}, ${props.linearGradient?.B}, ${props.linearGradient?.opacity})), url("${props.url}")`,
                backgroundAttachment: 'fixed',
                backgroundSize: `${
                    props.linearGradient?.size
                        ? props.linearGradient?.size
                        : 'cover'
                } `
            }}
        >
            {props.overlayClass ? (
                <div
                    className={
                        `${props.overlayClass}` +
                        ' absolute top-0 bottom-0 left-0 right-0 z-0'
                    }
                ></div>
            ) : null}

            <Slider {...settings}>
                {(props?.getVendorLogos || props.getVendorData
                    ? vendorLogos
                    : props?.getProjects
                    ? projectLogos
                    : props.imgArr
                )?.map((imgdata, i) => {
                    if (props?.customSlide) {
                        return (
                            <CustomSlide
                                {...props}
                                index={i}
                                imgdata={imgdata}
                            />
                        );
                    } else if (props.offerCards) {
                        return (
                            <OfferCarousel
                                dealName={imgdata.dealName}
                                dealImage={imgdata.dealImage}
                                imgUrl={imgdata.imgUrl}
                                dealId={imgdata.dealId}
                                {...props}
                            />
                        );
                    } else if (imgdata.imgUrl) {
                        return (
                            <div
                                key={i}
                                className={
                                    props?.imageContainerClassName ||
                                    'px-2 z-40 '
                                }
                                onClick={() => onClickHandler(imgdata, i)}
                            >
                                <img
                                    className={
                                        props?.imageClassName ||
                                        'rounded w-auto'
                                    }
                                    src={imgdata.imgUrl}
                                    alt={imgdata.alt}
                                />
                                {imgdata?.caption && (
                                    <p className=" px-2 text-primary font-semibold py-2 capitalize opacity-60">
                                        {imgdata.caption}
                                    </p>
                                )}
                            </div>
                        );
                    } else if (imgdata.videoUrl) {
                        return (
                            <div
                                key={i}
                                className={
                                    props.imageContainerClassName ||
                                    'px-2 z-40 '
                                }
                            >
                                <ReactPlayer
                                    url={imgdata.videoUrl}
                                    className="react-player px-1"
                                    // playing={playVideo}
                                    light={true}
                                    onClickPreview={() =>
                                        props.onVideoClick(imgdata, i)
                                    }
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                />
                                {imgdata?.caption && (
                                    <p className=" text-sm px-2 capitalize opacity-60">
                                        {imgdata.caption}
                                    </p>
                                )}
                            </div>
                        );
                    } else return <div />;
                })}
            </Slider>
        </div>
    );
}
