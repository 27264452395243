import { getFont, openURLNewWindow } from '../../helpers';
import godrejBgImage from '../../assets/ReferralForm/krc.jpg';
import store from '../../store';
const KrcConfig = {
    clientId:
        '89dcfa41903ebba52c0b371d5dcdddb67e8bfd8daf8fea6019ada584de2af9ca',
    id: 6,
    name: 'K Raheja',
    fullName: 'K Raheja Corp Homes',
    programName:'Royalty',
    theme: 'krcTheme',
    setSolidBg: 'bg-white',
    favicon: 'https://cdn.loyalie.in/83825478.png',
    primaryColor: '#006d7c',
    fontBody: `${getFont('KRC').body}`,
    fontTitle: `${getFont('KRC').bold}`,
    googleConfig: {
        gID: 'G-V92K4FG6YJ',
        keywordsContent: '',
        descriptionContent: '',
        authorContent: 'K Raheja Corp Royalty Program'
    },

    facebookPixelConfig: {
        id: '2153485144859442', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~311c474a',
    webengageAPIKey:'9d20eaa2-f47b-46e0-bb29-c0b0a84af292',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout2',
        
    },
    activateReferPage: false,
    logoLeft: {
        name: 'krcLogo',
        url: 'https://cdn.loyalie.in/1509419.png',
        stickyNavBarLogoClass:
            'h-16 lg:h-20 pl-6 lg:pl-7 xl:pl-10 mt-2 lg:mt-0',
        baseNavBarLogoClass: 'h-16 lg:h-20  pl-6 lg:pl-7 xl:pl-10 mt-2 lg:mt-10'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('KRC').body}`,
        height: 'h-20 2xl:h-20',
        mobileIconColor: 'text-white',
        dropDownBg: 'bg-white lg:bg-[#0000004D]',
        showActiveLinkColor: false,
        footerNavItemClass: `tracking-widest text-white text-[14px] lg:text-[12px] xl:text-[15px] lg:text-white hover:text-white font-medium lg:text-[12px] xl:text-[14px] 3xl:text-lg  ${
            getFont('KRC').body
        }`,
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-primary lg:bg-transparent', // default: transparent
            containerClass: 'lg:mt-10 lg:mr-4',
            itemClass: `uppercase text-black lg:mr-7 lg:text-white text-[14px] lg:text-white hover:text-primary lg:hover:text-white font-medium lg:text-[12px] xl:text-[15px] 3xl:text-lg ${
                getFont('KRC').body
            }`,
            dropDownContainerClass: `hover:text-primary lg:hover:text-white lg:hover:bg-primary lg:focus:text-white lg:focus:bg-primary lg:focus:outline-none lg:focus:shadow-outline `,
            dropDownItemClass:
                'text-black lg:text-white text-[14px] font-medium lg:text-[12px] xl:text-[15px] 3xl:text-lg '
        },

        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-primary', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass: `uppercase text-black lg:mr-7 lg:text-white text-[14px] lg:text-white font-medium lg:text-[12px] xl:text-[15px] 3xl:text-lg ${
                getFont('KRC').body
            }` // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: 'lg:-mt-20 2xl:-mt-20', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-primary mb-24 text-[12px] md:text-[17px] lg:text-[15px] xl:text-[17px] font-bold bg-white  py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 ${
        getFont('KRC').body
    }`,

    loginModal: {
        showEmployeeLogin: true,
showSVLogin: true,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER',
                    backgroundImage: 'https://cdn.loyalie.in/10006774.jpg',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/10006774.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content text-center max-w-full h-[75vh] md:h-[97vh] bg-center lg:bg-center xl:bg-right-center  ',
                    linearGradient: {
                        R: '40',
                        G: '40',
                        B: '40',
                        opacity: '0.5'
                    },
                    components: [
                        {
                            type: 'IMAGE',
                            url: 'https://cdn.loyalie.in/89502675.png',
                            position: 'center',
                            className:
                                'w-[60%] md:w-[35%] lg:w-[25%] 2xl:w-[25%] '
                        },
                        {
                            type: 'TEXT',
                            content:
                                "SHARING THE GOOD LIFE HAS IT'S OWN BENEFITS",
                            className: ` my-2 md:my-5 lg:my-6 xl:my-8 md:w-[60%] lg:w-full mx-auto tracking-wide text-center font-semibold text-white text-xl px-2 md:text-3xl lg:text-2xl xl:text-2xl 2xl:text-2xl
                            ${getFont('KRC').bold}`
                        },

                        {
                            type: 'BUTTON',
                            content: 'REFER NOW',
                            className: `text-primary text-[12px] md:text-sm lg:text-[15px] xl:text-[17px] font-semibold bg-white mt-5 lg:mt-0 py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 ${
                                getFont('KRC').body
                            }`,
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH_AND_SCROLL',
                            scrollTo: 'referral_section'
                        },
                        {
                            type: 'CUSTOM',
                            id: '',
                            className:
                                ' w-[30%] md:w-[20%] lg:w-[12%] absolute right-0 lg:right-6 bottom-2 lg:bottom-4 ',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'Managed by',
                                    className: `text-[10px] text-white leading-6 xl:leading-[27px] lg:self-center font-normal break-words pb-1 ${
                                        getFont('KRC').body
                                    }`
                                },
                                {
                                    type: 'IMAGE',
                                    url: 'https://cdn.loyalie.in/28602817.png',
                                    position: 'center',
                                    className: 'w-[80%] md:w-[70%] lg:w-full'
                                }
                            ]
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    id: '',
                    url: 'https://cdn.loyalie.in/35672323.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.95',
                        size: '150%'
                    },
                    className: 'flex flex-col justify-center text-center pt-14',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Being a part of the K Raheja Corp Homes community is always a rewarding experience. Whether it be through a privileged life our homes provide or the benefits you enjoy through the Royalty program. Invite your friends and family into the luxurious K Raheja Corp Homes lifestyle and earn referral benefits upto 1% of the agreement value. It allows you to build the community to your taste.',
                            className: `text-[18px] md:text-[19px] lg:text-[15px] xl:text-[19px] text-secondary-focus leading-7 xl:leading-8 self-center w-[95%] md:w-[93%] lg:w-[85%] xl:w-[77%] font-medium break-words ${
                                getFont('KRC').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content:
                                'As a part of the Royalty Program, enjoy Premium benefits, exciting experiences and exclusive offers specially curated for our customers.',
                            className: `text-[18px] md:text-[19px] lg:text-[15px] xl:text-[19px] text-secondary-focus uppercase leading-7 xl:leading-8 self-center w-[90%] xl:w-[77%] font-semibold break-words my-12  ${
                                getFont('KRC').body
                            }`
                        },
                        {
                            type: 'TEXT',
                            content: 'Privileges here are manifold',
                            className: `text-primary uppercase self-center text-center text-2xl md:text-4xl lg:text-[36px] xl:text-[40px] font-semibold pb-14 ${
                                getFont('KRC').bold
                            } `
                        },
                        {
                            type: 'CUSTOM',
                            className: `grid grid-cols-1 lg:grid-cols-3 ${
                                getFont('KRC').body
                            }`,
                            components: [
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'bg-primary flex flex-col items-center justify-center px-4 xl:px-16 py-16 xl:py-14 2xl:py-20',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/64669706.png',
                                            className: 'w-[80%] mx-auto pb-10 '
                                        },
                                        {
                                            type: 'TEXT',
                                            className:
                                                'lg:h-38 2xl:h-32 text-white text-[19px] lg:text-[15px] xl:text-[19px] leading-7 xl:leading-8 ',
                                            content:
                                                'Discover a wide range of promotional offers from Pan India brands, designed to complement your distinguished lifestyle. '
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'AVAIL NOW',
                                            className: `text-primary text-[12px] md:text-sm lg:text-[15px] xl:text-[17px] font-semibold bg-white mt-6  py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 ${
                                                getFont('KRC').body
                                            }`,
                                            path: 'deals',
                                            onClick: 'CHANGE_PATH'
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'bg-[#f5f5f5] flex flex-col items-center justify-center px-4 xl:px-16 py-16 xl:py-14 2xl:py-20',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/2279647.png',
                                            className: 'w-[80%] mx-auto pb-8 '
                                        },
                                        {
                                            type: 'TEXT',
                                            className:
                                                'lg:h-38 2xl:h-32 text-black font-medium text-[19px] lg:text-[15px] xl:text-[19px] leading-7 xl:leading-8',
                                            content:
                                                'Enjoy curated online and offline workshops and events from top collaborators to add more delight to your community experiences. '
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'PARTICIPATE NOW',
                                            className: `text-white text-[12px] md:text-sm lg:text-[15px] xl:text-[17px] font-semibold bg-primary mt-6 py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 ${
                                                getFont('KRC').body
                                            }`,
                                            path: 'events',
                                            onClick: 'CHANGE_PATH'
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'bg-primary flex flex-col items-center justify-center px-4 xl:px-16 py-16 xl:py-14 2xl:py-20',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/47635317.png',
                                            className: 'w-[80%] mx-auto pb-10 '
                                        },
                                        {
                                            type: 'TEXT',
                                            className:
                                                'lg:h-38 2xl:h-32 text-white text-[19px] lg:text-[15px] xl:text-[19px] leading-7 xl:leading-8',
                                            content:
                                                'Get insights on various lifestyle trends and unlock the chance to interact with like-minded people in your community.'
                                        },
                                        {
                                            type: 'BUTTON',
                                            content: 'READ NOW',
                                            className: `text-primary text-[12px] md:text-sm lg:text-[15px] xl:text-[17px] font-semibold bg-white mt-6 py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 ${
                                                getFont('KRC').body
                                            }`,
                                            path: 'blogs',
                                            onClick: 'CHANGE_PATH'
                                        }
                                    ]
                                }
                            ]
                        },

                        {
                            type: 'TEXT',
                            content:
                                'Introduce your loved ones to our bespoke community',
                            className: `text-primary uppercase self-center text-center text-2xl md:text-4xl lg:text-[36px] xl:text-[40px] leading-7 xl:leading-8 py-14 font-semibold ${
                                getFont('KRC').bold
                            }`
                        }
                    ]
                },
                {
                    type: 'REFERRAL_FORM_BANNER'
                },
                {
                    type: 'CUSTOM',
                    url: 'https://cdn.loyalie.in/35672323.png',
                    linearGradient: {
                        R: '255',
                        G: '255',
                        B: '255',
                        opacity: '0.95',
                        size: '150%'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-14 pb-10 lg:pb-20 -mb-28 ',
                    components: [
                        // {
                        //     type: 'TEXT',
                        //     content: 'EXCLUSIVE OFFERS',
                        //     className: `text-primary uppercase self-center text-center text-2xl md:text-4xl lg:text-[36px] xl:text-[40px] pb-10 font-semibold ${
                        //         getFont('KRC').bold
                        //     } `
                        // },

                        // {
                        //     type: 'CAROUSEL',
                        //     customSlide: true,
                        //     caraouselClassName:
                        //         'px-8 md:px-10 xl:px-56 md:pb-10 pt-1 relative pb-16 lg:pb-10',
                        //     imageContainerClassName: 'm-4 relative',
                        //     imageClassName: 'w-36 mx-auto  lg:bg-cover ',
                        //     captionClass: ` text-sm lg:text-lg leading-8 ${
                        //         getFont('KRC').body
                        //     }`,
                        //     arrows: true,
                        //     autoplay: true,
                        //     autoplaySpeed: 3000,
                        //     infinite: true,
                        //     dots: false,
                        //     slidesToShow: 4,
                        //     pauseOnHover: true,
                        //     speed: 3000,
                        //     onImageClick: openURLNewWindow,
                        //     getVendorLogos: true
                        // },
                        // {
                        //     type: 'BUTTON',
                        //     content: 'Avail Now',
                        //     className: `bg-primary text-white drop-shadow-lg font-medium uppercase self-center text-[16px] py-2.5 px-10 mb-10  ${
                        //         getFont('KRC').body
                        //     }`
                        // },
                        {
                            type: 'TEXT',
                            content: 'EXCLUSIVE OFFERS',
                            className: `text-primary uppercase self-center text-center text-2xl md:text-4xl lg:text-[36px] xl:text-[40px] pb-14 font-semibold ${
                                getFont('KRC').bold
                            } `
                        },
                        {
                            type: 'CUSTOM',
                            url: 'https://cdn.loyalie.in/74094647.jpeg',
                            linearGradient: {
                                R: '40',
                                G: '40',
                                B: '40',
                                opacity: '0.5',
                                repeat: 'no-repeat',
                                size: 'cover',
                                position: 'center'
                            },

                            className:
                                'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] py-28 mb-16',
                            id: 'krc_offers',
                            components: [
                                // {
                                //     type: 'TEXT',
                                //     content: 'EXCLUSIVE OFFERS',
                                //     className: `text-white uppercase self-center text-center text-2xl md:text-4xl lg:text-[36px] xl:text-[40px] font-semibold ${
                                //         getFont('KRC').bold
                                //     } `
                                // },
                                {
                                    type: 'CAROUSEL',
                                    caraouselClassName:
                                        'px-8 md:px-24 lg:px-10 xl:px-24 2xl:px-56 mx-auto relative',
                                    imageContainerClassName:
                                        'w-auto lg:w-40 xl:w-auto p-10 md:px-10 lg:px-2 ',
                                    imageClassName:
                                        ' w-32 md:w-28 xl:w-32 xl:h-32 mx-auto cursor-pointer',
                                    arrows: true,
                                    autoplay: true,
                                    autoplaySpeed: 2500,
                                    infinite: true,
                                    dots: false,
                                    slidesToShow: 5,
                                    pauseOnHover: true,
                                    speed: 2500,
                                    onImageClick: () => {
                                        window.open(
                                            `${window.location.href + 'deals'} `
                                        );
                                    },
                                    getVendorLogos: true
                                },
                                {
                                    type: 'BUTTON',
                                    content: 'AVAIL NOW',
                                    className: `text-primary text-[12px] md:text-sm lg:text-[15px] xl:text-[17px] font-semibold bg-white mt-5 lg:mt-10 py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 ${
                                        getFont('KRC').body
                                    }`,
                                    path: '/deals',
                                    onClick: 'CHANGE_PATH'
                                }
                            ]
                        },
                        {
                            type: 'TEXT',
                            content: 'OUR LATEST BLOGS',
                            className: `text-primary uppercase self-center text-center text-2xl md:text-4xl lg:text-[36px] xl:text-[40px] pb-5 font-semibold ${
                                getFont('KRC').bold
                            } `
                        },
                        {
                            type: 'BLOG_LAYOUT_VERTICAL1',
                            hideInfo: true
                        },

                        {
                            type: 'TEXT',
                            content: 'OUR PROJECTS',
                            className: `text-primary uppercase self-center text-center text-2xl md:text-4xl lg:text-[36px] xl:text-[40px] pb-10 font-semibold ${
                                getFont('KRC').bold
                            } `
                        },
                        {
                            type: 'CAROUSEL',
                            customSlide: true,
                            caraouselClassName:
                                'px-8 md:px-10 xl:px-56 md:pb-10 pt-1 relative pb-16 lg:pb-10',
                            imageContainerClassName: 'm-4 relative',
                            imageClassName: 'w-36 mx-auto  lg:bg-cover ',
                            captionClass: ` text-sm lg:text-lg leading-8 ${
                                getFont('KRC').body
                            }`,
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 2500,
                            infinite: true,
                            dots: false,
                            slidesToShow: 4,
                            pauseOnHover: true,
                            speed: 2500,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/35872270.jpg',
                                    alt: 'img',
                                    url: `https://www.krahejacorphomes.com/project/2-bhk-flats-in-chandivali-andheri-east/`
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/65361764.jpg',
                                    alt: 'img',
                                    url: `https://www.krahejacorphomes.com/project/estrella-pune/`
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/29568069.jpg',
                                    alt: 'img',
                                    url: `https://www.krahejacorphomes.com/project/apartments-in-west-pune/`
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/53059871.jpg',
                                    alt: 'img',
                                    url: `https://www.krahejacorphomes.com/project/orion-pune/`
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/37119679.jpg',
                                    alt: 'img',
                                    url: `https://www.krahejacorphomes.com/project/2-bhk-flats-in-nibm-pune/`
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/75425156.jpg',
                                    alt: 'img',
                                    url: `https://www.krahejacorphomes.com/project/raheja-stellar/`
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/62676107.jpg',
                                    alt: 'img',
                                    url: `https://www.krahejacorphomes.com/project/raheja-elite/`
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/89540349.jpg',
                                    alt: 'img',
                                    url: `https://www.krahejacorphomes.com/project/villas-and-plots-in-pune/`
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        dropDown: {
            show: true,
            name: 'ENGAGEMENTS',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'Events',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'Blogs',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: false,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        contactUs: {
            show: false,
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'Terms and Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/K-Raheja/Images/72974770.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/K-Raheja/Images/72974770.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover bg-right lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center md:text-left text-white text-[15px] md:text-[20px] xl:text-[25px] uppercase my-5 tracking-widest w-full md:w-[40%] xl:w-[50%]  ml-auto leading-6 xl:leading-8 ${
                                getFont('KRC').bold
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'flex w-full md:w-[40%] xl:w-[50%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `text-primary text-[12px] md:text-sm lg:text-[15px] xl:text-[17px] font-semibold bg-white mt-5 lg:mt-0 py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 mx-auto lg:mx-0 ${
                                        getFont('KRC').body
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/K-Raheja/Images/72974770.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/K-Raheja/Images/72974770.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[60vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').body
                    //         }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-black text-[15px] leading-10 md:text-[20px] xl:text-[45px] font-bold tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').heading
                    //         }`
                    // },
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex w-[70%] ml-auto justify-center mt-7', components: [{
                    //         type: 'BUTTON',
                    //         content: 'Start Referring Now',
                    //         scrollTo: 'referral_section',
                    //         onClick: 'SCROLL'
                    //     }]
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold tracking-widest bg-primary text-white px-8 md:px-6 py-3 lg:mt-2 xl:mt-0 mx-auto ${
                getFont('KRC').heading
            }`
        }
    },
    blogs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/35672323.png',
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.97',
            size: '150% auto'
        },
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[75vh] md:h-[97vh] bg-center md:bg-center  mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '40',
                G: '40',
                B: '40',
                opacity: '0.45'
            },
            backgroundImage: 'https://cdn.loyalie.in/19150976.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/19150976.jpg',
            components: [
                {
                    type: 'TEXT',
                    content: 'ROYALTY READERS’ CLUB',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('KRC').bold
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('KRC').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `mt-3 text-primary text-[12px] md:text-[17px] lg:text-[15px] xl:text-[17px] font-semibold bg-white  py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 ${
                        getFont('KRC').body
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'ROYALTY READERS’ CLUB',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('KRC').bold
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Bringing Together Information And Entertainment For You.',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('KRC').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `mt-3 text-primary text-[12px] md:text-[17px] lg:text-[15px] xl:text-[17px] font-semibold bg-white  py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 ${
                        getFont('KRC').body
                    }`
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('KRC').body}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${getFont('KRC').body}`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                components: [
                    {
                        type: 'ANIMATE',
                        url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Divider.svg',
                        width: '90',
                        height: '64',
                        name: 'first',
                        imgHeight: 'h-14',
                        className:
                            'flex justify-center text-center m-auto text-center '
                    }
                ],
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-[#1c2936] ${
                    getFont('KRC').body
                }`,
                headingClass: `text-[30px] uppercase font-semibold text-secondary mt-3 leading-9 break-words ${
                    getFont('KRC').body
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('KRC').body
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('KRC').body
                } `,
                buttonClass: `inline text-[17px] text-info ml-1 ${
                    getFont('KRC').body
                }`
            },
            sectionHeadings: `px-10 lg:px-16 pt-6 pb-2 text-[22px] md:text-[24px] lg:text-[25px] xl:text-[30px] tracking-wider text-center uppercase ${
                getFont('KRC').body
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            components: [
                {
                    type: 'ANIMATE',
                    url: 'https://d1pkuz21mplpj3.cloudfront.net/SVG/Divider.svg',
                    width: '90',
                    height: '64',
                    name: 'fourth',
                    imgHeight: 'h-14',
                    className:
                        'flex justify-center text-center m-auto text-center '
                }
            ],
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-accent',
            headingClass: `text-primary my-5 md:my-7 text-[19px] 3xl:text-[20px] font-semibold uppercase leading-6 break-words mx-5 ${
                getFont('KRC').body
            }`,
            categoryClass: `bg-primary bg-opacity-[.5] text-white ${
                getFont('KRC').body
            }`,
            summaryClass: `font-normal text-[16.5px] xl:text-[17px] mt-2 lg:my-6 leading-6 text-[#777777] mx-5 ${
                getFont('KRC').body
            }`,
            buttonClass: `bg-accent-focus hover:bg-primary flex justify-center self-end  text-[13px] xl:text-[16px] text-white py-1.5 px-4 md:px-4 m-5 ${
                getFont('KRC').body
            }`,
            dateClass: `  text-[13px] md:text-[17px] lg:text-[16px] xl:text-[13.5px] 2xl:text-[14px]  self-center text-[#919396] ${
                getFont('KRC').body
            }`,
            noBlogsClass: `bg-secondary p-2 w-full md:w-[30%] lg:w-[40%] xl:w-[30%] 3xl:w-[30%] mt-10 ${
                getFont('KRC').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/14141230.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/14141230.jpg',
            linearGradient: {
                R: '40',
                G: '40',
                B: '40',
                opacity: '0.45'
            },

            negativeMargin: '-mt-20 2xl:-mt-20',
            containerClass: ' text-white w-[50%] ',
            heading: '',
            headingClass: `font-semibold mt-20 lg:mt-0 text-center lg:w-[70%] text-[30px] lg:text-[40px] text-white uppercase ${
                getFont('KRC').bold
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('KRC').body
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-20 2xl:mt-20 hero hero-content bg-cover max-w-full h-[75vh] md:h-[97vh] text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                        getFont('KRC').body
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                        getFont('KRC').body
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `mt-3 text-primary text-[12px] md:text-[17px] lg:text-[15px] xl:text-[17px] font-semibold bg-white  py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 ${
                        getFont('KRC').body
                    }`
                }
            ]
        },

        eventsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/35672323.png',

            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.95',
                size: '150% auto'
            },
            heading: 'PAST EVENTS',
            headingClass: `self-center text-center font-semibold text-[22px] md:text-[24px] lg:text-[25px] xl:text-[30px] text-primary w-[60%] mt-16 ${
                getFont('KRC').bold
            }`,
            dividerClass:
                'z-10 self-center mt-2 2xl:mt-3 mx-auto  w-[25%] md:w-[16%] lg:w-[14%] 2xl:w-[9%]  bg-secondary-focus border-none h-[2px]  mb-8',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center font-semibold text-xl xl:text-[20px] mt-5 text-neutral w-[60%] ${
                getFont('KRC').body
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('KRC').body
            }`
        },
        eventLayout: {
            titleClass: `text-primary leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('KRC').body
            }`,
            hostClass: `capitalize ${getFont('KRC').bold}`,
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${
            getFont('KRC').body
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('KRC').body
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content  max-w-full h-[75vh] md:h-[97vh] md:bg-center mx-auto text-center ', //lg:bg-right bg-cover bg-top
            linearGradient: {
                string: '100,65,43,0.7',
                R: '40',
                G: '40',
                B: '40',
                opacity: '0.45'
            },
            backgroundImage: 'https://cdn.loyalie.in/1692637562324_Offer%20krc',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692637562324_Offer%20krc',
            components: [],
            loginBannerComponents: [
                // 4C
                // {
                //     type: 'TEXT',
                //     content: 'EXCLUSIVE OFFERS AND PROMOTIONS',
                //     className: `font-semibold mt-12 md:mt-10 mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                //         getFont('KRC').bold
                //     }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[20%] lg:w-[20%] bg-primary border-none h-[6px]',
                //     classNameContainer: ''
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'K Raheja Corp is driven to create experiences that add more value to your life',
                //     className: `font-normal mt-5 mb-3  text-xl md:text-base lg:text-[17px] tracking-wider ${
                //         getFont('KRC').body
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClick: 'FUNC',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     className: `mt-3 text-primary text-[12px] md:text-[17px] lg:text-[15px] xl:text-[17px] font-semibold bg-white  py-2 xl:py-[10px] px-6 md:px-8 lg:px-6 xl:px-8 ${
                //         getFont('KRC').body
                //     }`
                // }
            ]
        },

        dealsSection: {
            sectionBackgroundImage: 'https://cdn.loyalie.in/35672323.png',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.97',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center font-semibold text-[20px] text-center text-xl text-neutral w-[60%] ${
                getFont('KRC').body
            }`,
            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `uppercase text-primary font-semibold text-2xl xl:text-3xl ${
                getFont('KRC').bold
            } `,
            subAdditionalText:
                'K Raheja Corp is driven to create luxury experiences that add more value to your life. ',
            subAdditionalTextClass: `font-normal text-neutral ${
                getFont('KRC').body
            }`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('KRC').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',

            pattern1: 'https://cdn.loyalie.in/35672323.png',
            pattern2: 'https://cdn.loyalie.in/35672323.png',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.95',
                repeat: 'repeat',
                size: '200%',
                position: ''
            },
            listTitleClass: `text-2xl lg:text-xl leading-6 lg:leading-5 text-primary ${
                getFont('KRC').body
            }`,
            listBodyClass: `${getFont('KRC').body}`,
            logoClassName: `self-center rounded-md drop-shadow-md z-10 `,
            titleClassName: ` text-primary font-semibold text-2xl xl:text-3xl ${
                getFont('KRC').body
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-semibold ${
                getFont('KRC').body
            }`,
            dateClassName: ` text-neutral capitalize text-[16px] font-medium text-black ${
                getFont('KRC').body
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-[12px]  ${
                getFont('KRC').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-neutral  text-xs break-words leading-1 ${
                getFont('KRC').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `bg-primary text-white drop-shadow-lg font-medium uppercase self-center text-[16px] py-2.5 px-7  ${
                    getFont('KRC').body
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `rounded bg-primary text-white drop-shadow-lg font-medium uppercase self-center text-[16px] py-2.5 px-7 ${
                    getFont('KRC').body
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '40',
                    G: '40',
                    B: '40',
                    opacity: '0.45'
                },
                backgroundImage: 'https://cdn.loyalie.in/31038826.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/31038826.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[75vh] md:h-[97vh] bg-bottom',

                components: [
                    {
                        type: 'TEXT',
                        content: 'K RAHEJA CORP PROJECTS',

                        className: `font-semibold mx-auto text-center mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                            getFont('KRC').bold
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[25%] xl:w-[20%] bg-primary border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {
       
            constructionVisible: false,
            
            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },
    header: {
        headerClass: 'hidden lg:block h-28 bg-[#006d7c]'
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/35672323.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.9'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '40',
                    G: '40',
                    B: '40',
                    opacity: '0.45'
                },
                backgroundImage: 'https://cdn.loyalie.in/62759552.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/26633965.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center lg:bg-top h-[90vh] lg:h-[97vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: `font-semibold mx-auto text-center mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                            getFont('KRC').bold
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[25%] xl:w-[20%] bg-primary border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],

    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('KRC').body
        }`,
        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
            getFont('KRC').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '20',
                    G: '20',
                    B: '20',
                    opacity: '0.65'
                },
                backgroundImage: 'https://cdn.loyalie.in/14952553.jpeg',
                backgroundImageMobile: 'https://cdn.loyalie.in/14952553.jpeg',
                bannerContainerClassName:
                    'hidden md:hero hero-content  text-center max-w-full md:bg-center lg:bg-top h-[75vh] md:h-[97vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS & CONDITIONS',

                        className: `font-semibold mx-auto text-center mb-5 text-2xl md:text-4xl lg:text-[40px] tracking-wider ${
                            getFont('KRC').bold
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[25%] xl:w-[20%] bg-primary border-none h-[6px]',
                        classNameContainer: ''
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-neutral font-medium text-[20px] md:text-[20px] ${
                            getFont('KRC').body
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-neutral mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('KRC').body
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a K Raheja Corp. project, and has entered into a registered Agreement to Sell; or (ii) employee of K Raheja Corp.; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by K Raheja Corp. or any of its sister concerns/subsidiaries/affiliates (“K Raheja Corp. Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate K Raheja Corp Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by K Raheja Corp.',
                            'Referrer shall not be able to refer existing customer(s) of K Raheja Corp. Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with K Raheja Corp or (ii) is/are already registered with K Raheja Corp. as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the K Raheja Corp’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.  ',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',

                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to K Raheja Corp within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to K Raheja Corp.',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by K Raheja Corp. or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-neutral font-normal text-[17px] leading-7 ${
                            getFont('KRC').body
                        }`,
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-0 md:px-6 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'px-6',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or K Raheja Corp., for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('KRC').body
                                } inline text-neutral font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('KRC').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('KRC').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('KRC').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('KRC').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('KRC').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-neutral font-normal text-[17px] leading-7 ${
                                    getFont('KRC').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        appFontHeading: `${getFont('KRC').body}`,
        appStoreLink:
            'https://apps.apple.com/in/app/krc-royalty/id6444639384?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.connectre.krc&hl=en&gl=US&utm_medium=email',
        appScreen: 'https://cdn.loyalie.in/39125380.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/28602817.png',
        containerClassName: 'bg-secondary-content xl:pt-0',
        footerLogoClassName: 'self-center md:w-[15%] lg:w-[16%] lg:pl-3 ',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('KRC').body
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterExpandContent: true,
        loyalieFooterExpandName: 'Note :',
        loyalieFooterExpandClassName: `text-center text-white font-semibold cursor-pointer pb-2 ${
            getFont('KRC').bold
        }`,
        loyalieFooterExpandContentClassName: ` self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-2 xl:pr-6 text-[13px] 3xl:text-[15px] tracking-tight text-white ${
            getFont('KRC').body
        }`,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[13.5px] 3xl:text-[15px] tracking-tight text-white ${
            getFont('KRC').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('KRC').body
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-90'
    },
    referralForm: {
        name: 'K Raheja Corp',
        theme: 'krcTheme',
        color: '#006D7C',
        backgroundImage: godrejBgImage,
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '0',
            G1: '0',
            B1: '0',
            opacity1: '0.7',
            R2: '0',
            G2: '0',
            B2: '0',
            opacity2: '0.7'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: true,
            siteVisitorHeading1: '',
            siteVisitorHeading2: '',
            bookedCustomer: true,
            bookedCustomerHeading1: '',
            bookedCustomerHeading2: '',
            employee: false,
            employeeHeading1: '',
            employeeHeading2: '',
            salesAdmin: false,
            salesAdminHeading1: '',
            salesAdminHeading2: '',
            influencer: false,
            influencerHeading1: '',
            influencerHeading2: ''
        },
        formContent: {
            heading: 'HELP YOUR FRIENDS & FAMILY FIND A PERFECT HOME',
            headingClass: `self-center text-gray-100 text-3xl md:text-4xl w-[80%] lg:w-[90%] text-center ${
                getFont('KRC').bold
            } `,
            notification: {
                line1: ' ',
                line1Class: `
                `,
                line2: ' ',
                line2Class: `
                `,
                line3: ' ',
                line3Class: `
                `
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='%23006d7c'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='white' stroke-width='3' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: false,
        sendReferralAttemptNotification: false,
        isDirectTAndCLink: false,
        termsAndConditionsLink: '/krc-website/terms-and-conditions/',
        customLink: `/krc-website/projects/detai`,
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default KrcConfig;
