import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import './mapbox.css';
import { getConfig } from '../../../../utils/config';

mapboxgl.accessToken =
    'pk.eyJ1Ijoic2h1YmhyYWoiLCJhIjoiY2xmcXJzcW40MDFmZzN2cWhndDdqaWo4YyJ9.ot_ZNap3AeHKfSZ8ksnuaQ';

const MapBox = ({ markers, activeMarker, lat, lng }) => {
    const mapContainerRef = useRef('bigmap');
    const [centerPos, setCenterPos] = useState([80.84, 22.43]);
    const [zoomLimit, setZoomLimit] = useState(4);
    const config = getConfig();
    useEffect(() => {
        if (
            config?.clientId ===
            '0aa480b36122c84cb5df47318322c02f05d0b5c047e28f7dbe28f2da6b8fefb5'
        ) {
            setCenterPos([73.92, 18.54]);
            setZoomLimit(10);
        }
        if (
            config?.clientId ==
            'fd7f9ec0d5ad805adc07b227e9de4b32e03380334c387f3c569b4c4198bc1e46'
        ) {
            setCenterPos([72.86, 19.09]);
            setZoomLimit(10);
        }
    }, []);

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: centerPos,
            zoom: zoomLimit
        });
        map.on('load', function () {
            map.resize();
        });
        if (markers.length > 0) {
            // console.log('MARKERS:',markers, lat, lng);
            markers.forEach((feature) => {
                if (feature.id === activeMarker)
                    try {
                        new mapboxgl.Marker({ color: '#ff0000' })
                            .setLngLat([feature.lng, feature.lat])
                            .addTo(map);
                    } catch (err) {
                        new mapboxgl.Marker({ color: '#ff0000' })
                            .setLngLat([0.0, 0.0])
                            .addTo(map);
                    }
                else
                    try {
                        new mapboxgl.Marker()
                            .setLngLat([feature.lng, feature.lat])
                            .addTo(map);
                    } catch (err) {
                        new mapboxgl.Marker({ color: '#ff0000' })
                            .setLngLat([0.0, 0.0])
                            .addTo(map);
                    }
            });
        }

        if (activeMarker !== -1 && markers.length > 0) {
            try {
                map.setCenter([lng, lat]);
                map.setZoom(16);
            } catch (err) {
                map.setCenter([0.0, 0.0]);
                map.setZoom(16);
            }
        }

        map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        return () => map.remove();
    }, [markers, activeMarker]);

    return (
        <div className="h-full">
            {' '}
            <div className="map-container right-0 " ref={mapContainerRef} />
        </div>
    );
    //   !right-0
};

export default MapBox;
