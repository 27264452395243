import React from 'react';
import { useHistory } from 'react-router-dom';
import { cmsClient, winnreAuth, winnreNoAuth } from '../../../utils/axios';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

const OfferCarousel = ({
    dealImage,
    dealName,
    dealId,
    imgUrl,
    backgroundImageNone,
    classNameCTA,
    border
}) => {
    const history = useHistory();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    const clickThroughMobile = clientWebview || internalWebview;
    const dealClickedHandler = () => {
        if (
            cmsClient.defaults.baseURL === 'https://prod.loyalie.in' ||
            cmsClient.defaults.baseURL === 'https://uat.loyalie.in'
        ) {
            window.fbq('trackCustom', 'GrabOfferButtonClicked');
        }
        ReactGA.event({
            category: 'OFFER',
            action: 'GRAB OFFER CLICKED',
            label: 'GRAB OFFER BUTTON'
        });
        winnreNoAuth({
            method: 'POST',
            url: `winnre/user/deals/trackClick?dealId=${dealId}&sourcePlatform=${
                clickThroughMobile ? 'MOBILE' : 'WEBSITE'
            }`
        })
            .then(() => {})
            .catch(() => {});

        history.push(`/deals/${dealId}`);
    };
    return (
        <>
            {/* <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-x-12 mx-auto">
                {vendorLogos.map((deal) => ( */}
            {backgroundImageNone ? (
                <div
                    className="bg-cover rounded-lg h-auto cursor-pointer opacity-100  "
                    onClick={dealClickedHandler}
                >
                    <div
                        className={`bg-white flex flex-col items-center justify-center p-2 2xl:p-4  rounded-lg w-[190px] 2xl:w-[220px] h-auto mx-auto ${border}`}
                    >
                        <img
                            className="  mx-auto w-20 2xl:w-[50%] h-20 2xl:h-[86px] object-cover bg-center rounded-lg"
                            src={imgUrl}
                        />
                        <h1 className="h-[45px] mt-2 mx-auto text-center text-black font-bold uppercase text-[13px] leading-4">
                            {dealName.slice(0, 80)}
                        </h1>

                        <button
                            className={
                                classNameCTA
                                    ? classNameCTA
                                    : 'bg-black px-5 py-1.5 text-white text-center mt-2 mx-auto rounded'
                            }
                        >
                            View Offer
                        </button>
                    </div>
                </div>
            ) : (
                <div
                    className="bg-cover rounded-lg h-auto mx-5 p-8 cursor-pointer opacity-80 "
                    style={{
                        backgroundImage: `url('${dealImage}')`
                    }}
                    onClick={dealClickedHandler}
                >
                    <div className="bg-white flex flex-col items-center justify-center bg-opacity-95 p-2 2xl:p-4 rounded-lg w-[190px] 2xl:w-[220px] h-auto mx-auto">
                        <img
                            className="  mx-auto w-20 2xl:w-[50%] h-20 2xl:h-[86px] object-cover bg-center rounded-lg"
                            src={imgUrl}
                        />
                        <h1 className="h-[45px] mt-2 mx-auto text-center text-black font-bold uppercase text-[13px] leading-4">
                            {dealName.slice(0, 50)}
                        </h1>

                        <button
                            className={
                                classNameCTA
                                    ? classNameCTA
                                    : 'bg-black px-5 py-1.5 text-white text-center mt-2 mx-auto rounded'
                            }
                        >
                            View Offer
                        </button>
                    </div>
                </div>
            )}
            {/* ))} */}

            {/* <div
                    className="bg-cover rounded-lg h-auto "
                    style={{
                        backgroundImage: `url("https://cdn.loyalie.in/1661781782132_orange_tree_offers_banner.jpg")`
                    }}
                >
                    <div className="bg-white bg-opacity-75 m-12 p-6 rounded-lg w-[220px] h-auto mx-auto">
                        <img
                            className="  mx-auto w-[50%] object-cover object-center rounded-lg"
                            src="https://cdn.loyalie.in/1656484372908_1653025696921_orange_tree_logo.jpg"
                        />
                        <h1 className="h-[40px] my-2 w-[90%] mx-auto text-center text-black font-bold uppercase text-[17px] leading-5">
                            10% OFF On Shopping Cart
                        </h1>
                        <a href="#">
                            <button className="bg-black px-5 py-1.5 text-white">
                                View Offer
                            </button>
                        </a>
                    </div>
                </div>

                <div
                    className="bg-cover rounded-lg h-auto "
                    style={{
                        backgroundImage: `url("https://cdn.loyalie.in/1661497336217_Jaipur_rugs-banner.jpg")`
                    }}
                >
                    <div className="bg-white bg-opacity-75 m-12 p-6 rounded-lg w-[220px] h-auto mx-auto">
                        <img
                            className="  mx-auto w-[50%] object-cover object-center rounded-lg"
                            src="https://cdn.loyalie.in/1661494135721_Jaipur_rugs-logos.jpg"
                        />
                        <h1 className="h-[40px] my-2 w-[90%] mx-auto text-center text-black font-bold uppercase text-[17px] leading-5">
                            10% OFF On Shopping Cart
                        </h1>
                        <a href="#">
                            <button className="bg-black px-5 py-1.5 text-white">
                                View Offer
                            </button>
                        </a>
                    </div>
                </div>

                <div
                    className="bg-cover rounded-lg h-auto "
                    style={{
                        backgroundImage: `url("https://cdn.loyalie.in/1661496856585_Pitshop_offers.jpg")`
                    }}
                >
                    <div className="bg-white bg-opacity-75 m-12 p-6 rounded-lg w-[220px] h-auto mx-auto">
                        <img
                            className=" mx-auto w-[50%] object-cover object-center rounded-lg"
                            src="https://cdn.loyalie.in/1656484906399_1653307342726_pitshop_logo.png"
                        />
                        <h1 className="h-[40px] my-2 w-[90%] mx-auto text-center text-black font-bold uppercase text-[17px] leading-5">
                            10% OFF On Shopping Cart
                        </h1>
                        <a href="#">
                            <button className="bg-black px-5 py-1.5 text-white">
                                View Offer
                            </button>
                        </a>
                    </div>
                </div> */}
            {/* </div> */}
        </>
    );
};

export default OfferCarousel;
