import { useEffect, useState } from 'react';
import { getConfig } from '../../../../utils/config';
import { useSelector } from 'react-redux';

const RedeemAnimation = ({ content, onClick, disabled }) => {
    const [isClicked, setIsClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );

    const config = getConfig();
    const redeemNowButtonConfig =
        config?.deals.dealLayout.redeemNowButtonConfig;
    useEffect(() => {
        let timer1;
        let timer2;

        if (isClicked) {
          onClick()
            setIsLoading(true);

            timer1 = setTimeout(() => {
                setIsLoading(false);
                setIsCompleted(true);

                timer2 = setTimeout(() => {
                    setIsClicked(false);
                    setIsCompleted(false);
                }, 1000);
            }, 1000);
        }

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, [isClicked]);

    const handleClick = () => {
        setIsClicked(true);
    };

    return (
        <div className={`relative ${ internalWebview ? 'w-full flex justify-center items-center' : ''}`}>
            {isLoading && (
                <div className=" bg-white  px-10">
                    <svg
                        className="text-primary h-10 w-10 text-center"
                        viewBox="0 0 200 200"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs>
                            <linearGradient id="spinner-secondHalf">
                                <stop
                                    offset="0%"
                                    stop-opacity="0"
                                    stop-color="currentColor"
                                />
                                <stop
                                    offset="100%"
                                    stop-opacity="0.5"
                                    stop-color="currentColor"
                                />
                            </linearGradient>
                            <linearGradient id="spinner-firstHalf">
                                <stop
                                    offset="0%"
                                    stop-opacity="1"
                                    stop-color="currentColor"
                                />
                                <stop
                                    offset="100%"
                                    stop-opacity="0.5"
                                    stop-color="currentColor"
                                />
                            </linearGradient>
                        </defs>

                        <g stroke-width="8">
                            <path
                                stroke="url(#spinner-secondHalf)"
                                d="M 4 100 A 96 96 0 0 1 196 100"
                            />
                            <path
                                stroke="url(#spinner-firstHalf)"
                                d="M 196 100 A 96 96 0 0 1 4 100"
                            />

                            <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                d="M 4 100 A 96 96 0 0 1 4 98"
                            />
                        </g>

                        <animateTransform
                            from="0 0 0"
                            to="360 0 0"
                            attributeName="transform"
                            type="rotate"
                            repeatCount="indefinite"
                            dur="1300ms"
                        />
                    </svg>
                </div>
            )}

            {!isLoading && !isCompleted && (
                <button
                    onClick={handleClick}
                    className={
                        redeemNowButtonConfig.className +
                        `transition-all duration-300 ease-in-out  ${internalWebview? 'w-full font-semibold' : ''}` 
                    }
disabled={disabled}
                >
                    {content}
                </button>
            )}

            {isCompleted && (
                <button className={redeemNowButtonConfig.className }>
                    <svg
                        className="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                        />
                    </svg>
                </button>
            )}
        </div>
    );
};

export default RedeemAnimation;
