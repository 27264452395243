import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const AnimationDivider = (props) => {
    const { url, className, width, height, name, imgHeight } = props;

    gsap.registerPlugin(ScrollTrigger);

    // useEffect(() => {
    //     gsap.to(`.${name}`, {
    //         scaleX: 5,
    //         ease: 'linear',
    //         scale: 4,
    //         scrollTrigger: {
    //             trigger: `.${name}`,
    //             start: 'top center',
    //             end: 'top top',
    //             toggleActions: 'play pause resume reverse',
    //             scrub: true,
    //             markers: false
    //         }
    //     });
    // }, []);

    useEffect(() => {
        ScrollTrigger.matchMedia({
            // small

            '(max-width: 780px)': function () {
                gsap.to(`.${name}`, {
                    scaleX: 1,

                    ease: 'linear',

                    scale: 1,

                    scrollTrigger: {
                        trigger: `.${name}`,

                        start: 'top center',

                        end: 'top top',

                        toggleActions: 'play pause resume reverse',

                        scrub: true,

                        markers: false
                    }
                });
            }, // all

            '(max-width: 5099px)': function () {
                gsap.to(`.${name}`, {
                    scaleX: 4,

                    ease: 'linear',

                    scale: 4,

                    scrollTrigger: {
                        trigger: `.${name}`,

                        start: 'top center',

                        end: 'top top',

                        toggleActions: 'play pause resume reverse',

                        scrub: true,

                        markers: false
                    }
                });
            }
        });
    }, []);

    return (
        <>
            <div className={className || ' '}>
                <img
                    className={`${name} ${imgHeight}`}
                    src={url}
                    width={width}
                    height={height}
                />
            </div>
        </>
    );
};

export default AnimationDivider;
