import BsInfoCircle from '@meronex/icons/bs/BsInfoCircle'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function ZeroRewards() {
    const history = useHistory()
  return (
    <div className="w-full pl-0 xl:pl-6 mt-10 lg:mb-8  border border-gray-300 rounded-xl">
                                <div className="flex flex-col lg:flex-row items-center p-5 lg:gap-5 xl:gap-10 ">
                                        <BsInfoCircle className="h-16 w-16 text-black " />

                                    <div className=" flex-1 lg:border-l-2 mt-4 lg:mt-0 lg:pl-6 lg:border-gray-300">
                                        <h1 className="text-black font-bold text-xl  text-center lg:text-left">
                                            Hello There,
                                        </h1>
                                        <h3
                                            className={` text-black text-base mt-2 text-center lg:text-left `}
                                        >
                                                Haven't referred your friends
                                                and family yet?
                                        </h3>
                                        <h3
                                            className={` text-black text-base text-center lg:text-left `}
                                        >
                                                    Start now and unlock a world
                                                    full of rewards,
                                                    scratchcards, and more!
                                        </h3>
                                      
                                    </div>
                                    <div className="flex justify-end mt-2 lg:mt-0">
                                            <button
                                                className="bg-primary text-white px-7 py-2 rounded"
                                                onClick={() => {history.push('/gift-and-earn')}}
                                            >
                                                Refer Now
                                            </button>
                                        </div>
                                </div>
                            </div>
  )
}

export default ZeroRewards
