import React from 'react';
import useInput from '../../../../Hooks/useInput';
import PhoneInput from 'react-phone-input-2';
import usePhoneInput from '../../../../Hooks/usePhoneInput';
import { RE_EMAIL } from '../../../../constants';
import axios from 'axios';
import Modal from '../Modal';
import qs from 'qs';
import { useState } from 'react';
import { winnreAuth } from '../../../../utils/axios';

function CreateOnBehalForm({
    changeCurrentStep,
    setCustomerDetails,
    bearerToken,
    config
}) {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const {
        state: {
            isValid: mobileNumberIsValid,
            mobile,
            country,
            mobileHasError,
            isInputFocused
        },
        onChange,
        validator,
        setIsTouched,
        getMobileWithoutCountryCode,
        setInputFocused
    } = usePhoneInput();

    const {
        value: name,
        isValid: nameIsValid,
        hasError: nameHasError,
        valueChangeHandler: nameChangedHandler,
        inputBlurHandler: nameBlurHandler
    } = useInput((value) => value !== '');

    const {
        value: email,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler
    } = useInput((value) => RE_EMAIL.test(value));

    let formIsValid = false;
    // customerRoleIsValid
    if (nameIsValid && mobileNumberIsValid) {
        formIsValid = true;
    }

    const backClickHandler = () => {
        changeCurrentStep('userTypeForm');
    };

    const nextClickHandler = (e) => {
        e.preventDefault();
        const data = {
            customerName: name,
            customerMobileNumber: getMobileWithoutCountryCode(),
            customerEmail: email,
            customerMobileCountryCode: country.dialCode
        };

        winnreAuth({
            method: 'post',
            url: `/winnre/sm/validate-customer`,
            data: qs.stringify(data)
        })
            .then((res) => {
                if (res?.id) {
                    setCustomerDetails({ customerId: res?.id, ...data });
                    // setCustomerID(res.id);
                    // setCreateOnBehalfFormData(data);
                    changeCurrentStep('userProjectForm');
                    return;
                }
                if (res.status === 'FAILED') {
                    setModalMessage(
                        "Uh-oh. We couldn't locate the customer in our records"
                    );
                    setShowModal(true);
                    return;
                }
                if (
                    res.status === 'INVALID_TOKEN' ||
                    res.status === 'UNAUTHORIZED' ||
                    res.status === 'TOKEN_EXPIRED'
                ) {
                    setModalMessage(res?.message);
                    setShowModal(true);
                    return;
                }
            })
            .catch(() => {
                setModalMessage('Something went wrong. Please try again!');
                setShowModal(true);
            });
    };
    return (
        <div className="flex w-full mt-10 md:mt-0 xl:w-[90%] 2xl:w-[80%]">
            <form className="flex flex-col lg:pl-6 xl:pl-12 w-full ">
                <div className="">
                    <p className={` text-xl font-semibold text-white`}>
                        Enter customer details
                    </p>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-7">
                        <div className="">
                            <h2 className="mt-5 text-white">
                                Customer Name
                                <span className="text-red-400"> *</span>
                            </h2>
                            <input
                                type="text"
                                className="w-full mt-2 text-grey-900 h-12 rounded px-2 py-1 border-[#BFBFBF] focus:border-[#BFBFBF] outline-none border-[1px] placeholder-[#000000B3]"
                                placeholder="Customer Name*"
                                onChange={nameChangedHandler}
                                onBlur={nameBlurHandler}
                                value={name}
                                autoComplete="off"
                            />
                            {nameHasError && (
                                <p className="text-left font-semibold text-red-500">
                                    Please enter a valid name.
                                </p>
                            )}
                        </div>
                        <div className=" mt-5 ">
                            <h2 className="text-white">
                                Registered Mobile Number
                                <span className="text-red-400"> *</span>
                            </h2>
                            <PhoneInput
                                country={'in'}
                                placeholder="Mobile Number*"
                                countryCodeEditable={false}
                                containerClass={`mt-2 bg-white h-12 rounded-md border-[#BFBFBF] ${
                                    isInputFocused
                                        ? 'border-primary border-[2px]'
                                        : 'border-gray-100'
                                }`}
                                containerStyle={{ width: '100%' }}
                                inputStyle={{
                                    width: '100%',
                                    height: '3rem',
                                    fontSize: '16px'
                                }}
                                buttonStyle={{ backgroundColor: 'white' }}
                                isValid={validator}
                                onChange={onChange}
                                value={mobile}
                                enableSearch
                                onBlur={() => {
                                    setIsTouched(true);
                                    setInputFocused(false);
                                }}
                                onFocus={() => setInputFocused(true)}
                                inputClass="w-full mt-2 text-grey-900 h-12 rounded px-2 py-1 border-[#BFBFBF] focus:border-[#BFBFBF] outline-none border-[1px] placeholder-[#000000B3]"
                            />

                            {mobileHasError && (
                                <p className="text-left font-semibold text-red-500">
                                    Please enter a valid Number.
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="w-full md:w-[45%] xl:w-[48%] mt-4">
                        <h2 className="text-white">
                            Email
                            <span className="text-red-400"> *</span>
                        </h2>
                        <input
                            type="email"
                            className="w-full mt-2 text-grey-900 h-12 rounded px-2 py-1 border-[#BFBFBF] focus:border-[#BFBFBF] outline-none border-[1px] placeholder-[#000000B3]"
                            placeholder="Customer Email"
                            onChange={emailChangedHandler}
                            onBlur={emailBlurHandler}
                            value={email}
                            autoComplete="off"
                        />
                        {/* {emailHasError && (
                    <p className="text-left font-semibold text-red-500">
                        Please enter a valid email.
                    </p>
                    
                )} */}
                    </div>

                    <div className={'mt-7 w-full '}>
                        <div
                            className={`flex ${
                                config?.userTypes.employee &&
                                config?.userTypes.salesAdmin &&
                                'justify-between'
                            }`}
                        >
                            {config?.userTypes.employee &&
                                config?.userTypes.salesAdmin && (
                                    <button
                                        className="back-btn"
                                        onClick={backClickHandler}
                                    >
                                        Back
                                    </button>
                                )}

                            <button
                                className="sub-btn text-white hover:bg-primary-focus disabled:opacity-90 disabled:bg-gray-400 disabled:hover:bg-gray-400 disabled:cursor-not-allowed"
                                onClick={nextClickHandler}
                                disabled={formIsValid ? false : true}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <Modal
                visible={showModal}
                onClick={() => setShowModal(false)}
                content={modalMessage}
            />
        </div>
    );
}

export default CreateOnBehalForm;
