import { useHistory } from 'react-router-dom';
import React from 'react';
import BiArrowBack from '@meronex/icons/bi/BiArrowBack';
import { currencyToIndianRsWord, projectStatus } from '../../../../helpers';
import IosArrowBack from '@meronex/icons/ios/IosArrowBack';
import { getConfig } from '../../../../utils/config';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function ProjectDetailNew({
    projectDetails,
}) {


    const history = useHistory();
    const config = getConfig()
    const location = useLocation()
    const queryParams =  new URLSearchParams(location.search)
    const backClickHandler = () => {
        history.push(`/projects?page=${queryParams.get('page')}&scrollPosition=project_section`);
    };

    const getApartmentText = (apartmentTypes) => {
        // console.log(apartmentTypes)
        if (!apartmentTypes?.length > 0) return;
        let ans = [];
        apartmentTypes = apartmentTypes.sort();
        apartmentTypes?.map((type) => {
            switch (type) {
                case '_1_RK':
                    ans = ['1 RK', ...ans];
                    break;
                case '_1_BHK':
                    ans = [...ans, '1'];
                    break;
                case '_1_5_BHK':
                    ans = [...ans, '1.5'];
                    break;
                case '_2_BHK':
                    ans = [...ans, '2'];
                    break;
                case '_2_5_BHK':
                    ans = [...ans, '2.5'];
                    break;
                case '_3_BHK':
                    ans = [...ans, '3'];
                    break;
                case '_3_5_BHK':
                    ans = [...ans, '3.5'];
                    break;
                case '_4_BHK':
                    ans = [...ans, '4'];
                    break;
                case '_4_5_BHK':
                    ans = [...ans, '4.5'];
                    break;
                case '_5_BHK':
                    ans = [...ans, '5'];
                    break;
                case '_6_BHK':
                    ans = [...ans, '6'];
                    break;
                    case 'Studio':
                        ans = [...ans, 'Studio']
                        break
                default:
                    break;
            }
        });
        // ans=ans.sort();
        if (ans.includes('1 RK') && ans.length === 1) return '1 RK';
        else if (ans.length === 1) return ans[0] + ' BHK';
        let response = '';
        for (let i = 0; i < ans.length; i++) {
            if (ans[i] === '1RK') {
                response =
                    '1 RK' +
                    (i !== ans.length - 1 && ans.length > 2 ? ', ' : '');
            } else {
                if (i === ans.length - 1) response = response + ' & ' + ans[i];
                else if (i === ans.length - 2) response = response + ans[i];
                else response = response + ans[i] + ', ';
            }
        }
        return response + ' BHK';
    };

    const currencyFormating = (price) => {
        if (price >= 10000000)
            return currencyToIndianRsWord
                .format(price)
                .slice(0, -2)
                .concat(' Cr*');
        return currencyToIndianRsWord.format(price).slice(0, -1).concat(' L');
    };
    // console.log('project details', projectDetails)
    return (
        <div className="z-20 px-6 lg:px-0 2xl:px-8">
            <div className="flex flex-col">
                <div className='flex items-center cursor-pointer'
                    onClick={backClickHandler}
                >
                    <IosArrowBack
                        className="z-10 h-full w-5 -ml-2 "
                        style={{
                            color: config.primaryColor
                        }}
                    />
                    <p>Back to listing page</p>
                </div>
                <div className="flex my-6">

                    <div className={`z-10 flex-1 ${config.fontBody}`}>
                        <p
                            className={`z-10 clear-both text-lg xl:text-2xl font-bold ${config.fontTitle}`}
                        >
                            {projectDetails.projectName}
                        </p>
                        <p
                            className={`z-10 text-[#908F95] italic pb-1 `}
                        >
                            {projectDetails.city}
                        </p>
                        <button className='p-[4px] px-2 text-[#4652A4] bg-[#4652A433] mt-1 rounded'>{projectStatus(projectDetails?.status)}</button>

                    </div>
                </div>

                <svg viewBox="0 0 457 2"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1" fill="#e5e5e5" /></g></g></svg>

                <p
                    className={`mt-4 text-lg ${config.fontTitle}`}
                >
                    Project Details
                </p>
                <div className={`${config.fontBody} z-10 flex flex-wrap gap-y-4 text-[14px] my-4`}>
                    <div className="w-1/2 lg:w-full xl:w-1/2">
                        <p
                            className={` z-10 text-[#5E6164] tracking-widest ${config.fontTitle}`}
                        >
                            AREA
                        </p>
                        <p
                            className={`font-bold z-10 text-[16px]`}
                        >
                            {projectDetails?.area}
                        </p>
                    </div>
                    <div className="w-1/2">
                        <p
                            className={`z-10  text-[#5E6164] tracking-widest ${config.fontTitle}`}
                        >
                            {projectDetails?.type == 'COMMERCIAL'
                                ? 'TYPE'
                                : projectDetails?.apartmentTypes?.includes(
                                    'Plot'
                                )
                                    ? projectDetails?.apartmentTypes?.includes(
                                        'Plot'
                                    )
                                        ? 'PLOTS'
                                        : 'VILLA'
                                    : 'APARTMENTS'}
                        </p>

                        {projectDetails?.type == 'COMMERCIAL' && (
                            <p
                                className={`z-10 font-bold`}
                            >
                                Commercial
                            </p>
                        )}
                        {projectDetails?.type !== 'COMMERCIAL' && (
                            <p
                                className={`z-10 font-bold  text-[16px] `}
                            >
                                {projectDetails?.apartmentTypes?.includes(
                                    'Plot'
                                ) ||
                                    projectDetails?.apartmentTypes?.includes(
                                        'Villa'
                                    )
                                    ? 'Available'
                                    : getApartmentText(
                                        projectDetails?.apartmentTypes
                                    )}
                                {projectDetails?.apartmentTypes?.includes(
                                    'Plot'
                                ) ||
                                    projectDetails?.apartmentTypes?.includes(
                                        'Villa'
                                    )
                                    ? ''
                                    : ''}
                            </p>
                        )}
                    </div>

                    <div className="w-1/2 z-10 mr-8">
                        <p className={` z-10 text-[#5E6164] tracking-widest ${config.fontTitle}`}>
                            STARTING
                        </p>

                        <p
                            className={` font-bold text-[16px]`}
                        >
                            {currencyFormating(projectDetails?.price)}
                        </p>
                    </div>
                </div>

                <svg viewBox="0 0 457 2"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1" fill="#e5e5e5" /></g></g></svg>
            </div>


        </div>
    );
}
