import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, useHistory } from 'react-router-dom';
import { webViewActions } from '../store/webview';
import { authActions } from '../store/auth-slice';
import useAxios from './use-axios';
import { cmsClient, winnreAuth, winnreNoAuth } from '../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { getConfig } from '../utils/config';
import { useRefreshToken } from '.';
import jwtDecode from 'jwt-decode';
import { checkRole, webEngageInit } from '../helpers';
import { decryptData } from '../utils/sec';
import { scrollToTargetAdjusted } from '../utils/ui';

const useAppInit = () => {
    const config = getConfig();
    const { loading: tokenLoading, refreshTokenHandler } = useRefreshToken({
        callOnInit: true
    });

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const jwtToken = useSelector((state) => state.auth.token);
    const unauthorizedUser = useSelector(
        (state) => state.auth.unauthorizedUser
    );
    const unauthorizedUserMessage = useSelector(
        (state) => state.auth.unauthorizedUserMessage
    );

    const webViewAccessToken = queryParams.get('token');
    const webViewRefreshToken = queryParams.get('rhfwetnk');
    const clientToken = queryParams.get('clientToken');
    const isWebView =
        queryParams.get('isWebView') || sessionStorage.isInternalWebView;
    const userToken = queryParams.get('userToken');
    const clientSource = queryParams.get('source');
    const whatsappOptIn = queryParams.get('whatsappOptIn');
    const preLoginWebview = queryParams.get('preLoginWebview');
    const scrollTarget = queryParams.get('scrollPosition');
    const clientWebview = useSelector((state) => state.webView.clientWebview);
    const internalWebview = useSelector(
        (state) => state.webView.internalWebview
    );
    const userWebview = useSelector((state) => state.webView.userWebview);

    const hiddenOnWebview = useSelector(
        (state) => state.webView.hiddenOnWebview
    );
    const {
        loading: loadingWebviewToken,
        refreshTokenHandler: refereshWebviewToken
    } = useRefreshToken({
        callOnInit: false
    });
    const [loading, setLoading] = useState(true);
    const [clientAndUserTokenLoading, setClientAndUserTokenLoading] =
        useState(false);

    const {
        isLoading: loadingUserDetails,
        error: userDetailsError,
        sendRequest: fetchMobileUserDetails
    } = useAxios();

    const headers2 = {
        'content-type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${queryParams.get('token')}`
    };

    if (!window.reloy_env.WEBFORM_ONLY && config?.googleConfig?.gID) {
        ReactGA.initialize(config?.googleConfig?.gID);
    }

    function getFaviconEl() {
        return document.getElementById('favicon');
    }

    const getMobileUserDetailsAndAuthenticate = () => {
        fetchMobileUserDetails(
            {
                isEncryptedResponse: true,
                url: `/winnre/user/profile`,
                method: 'get',
                headers: headers2
            },
            (data) => {
                // console.log('appp ', data);
                if (
                    data.status === 'INVALID_TOKEN' ||
                    data.status === 'UNAUTHORIZED' ||
                    data.status === 'TOKEN_EXPIRED'
                ) {
                    // console.log('here');
                    dispatch(webViewActions.unauthorizedWebviewUser());
                    dispatch(authActions.setUnauthorizedUser(data?.message));
                    // setUnauthorizedUser(true);
                    // setUnauthorizedUserMessage(data?.message);
                    return;
                }
                // console.log('true');
                try {
                    dispatch(
                        authActions.setUserData({
                            userDetails: {
                                mobileCountryCode: data.mobileCountryCode,
                                mobileNumber: data.mobileNumber,
                                email: data.email,
                                status: data.status,
                                preferredName: data.preferredName,
                                birthDate: data.dateOfBirth,
                                address: data?.address
                            },
                            name: data.name,
                            userRole: checkRole(data?.eligibleRoles),
                            userId: data?.id,
                            hashId: data?.hashId,
                            profileCompletionPercentage:
                                data?.profileCompletionPercentage
                        })
                    );
                } catch (err) {
                    // console.log(err);
                }
                dispatch(authActions.login());
                dispatch(webViewActions.loginWebViewUser());
            }
        );
    };

    const loginClientByToken = () => {
        let url;
        setClientAndUserTokenLoading(true);
        if (clientToken) {
            url = `/winnre/login/loginByClientToken?token=${clientToken}${
                isWebView ? '&isWebview=true' : ''
            }`;
        }
        if (userToken) {
            url = `/winnre/login/loginByClientToken?userToken=${userToken}${
                clientSource ? `&source=${clientSource}` : ''
            }${whatsappOptIn ? `&whatsappOptIn=${whatsappOptIn}` : ''}${
                isWebView ? '&isWebview=true' : ''
            }`;
        }
        winnreNoAuth({
            method: 'post',
            url
        })
            .then((res) => {
                // console.log('client', res);
                if (
                    res?.status === 'FAILED' ||
                    res?.status === 'INVALID_TOKEN' ||
                    res?.status === 'UNAUTHORIZED' ||
                    res?.status === 'TOKEN_EXPIRED'
                ) {
                    if (userToken) {
                        // dispatch(webViewActions.unauthorizedWebviewUser());
                        // dispatch(authActions.setUnauthorizedUser(res?.message));
                        dispatch(authActions.openLoginModal());
                    } else {
                        dispatch(authActions.openLoginModal());
                    }
                    // setUnauthorizedUserMessage(
                    //     res?.message ||
                    //         'Sorry, we could not identify your identity'
                    // );
                    // setUnauthorizedUser(true);
                } else {
                    // setAuthToken(res?.token);
                    console.log('calling WE login');
                    window?.webengage?.user?.login(res?.user?.hashId);
                    window?.webengage?.user?.setAttribute(
                        'we_email',
                        res?.user?.email
                    );
                    window?.webengage?.user?.setAttribute(
                        'we_phone',
                        res?.user?.mobileNumber
                    );
                    window?.webengage?.user?.setAttribute(
                        'we_first_name',
                        res.user?.name.split(' ')[0]
                    );
                    window?.webengage?.user?.setAttribute(
                        'we_last_name',
                        res.user?.name.split(' ').pop()
                    );
                    window?.webengage?.track('LoggedIn', {
                        ProfileType: `${checkRole(res?.user?.eligibleRoles)}`,
                        ...(isWebView && {
                            Webview: true
                        }),
                        ...((userToken && !isWebView) && {
                            URLLogin: true
                        })
                    });
                    console.log('done calling WE login');

                    dispatch(authActions.authenticationToken(res.accessToken));

                    dispatch(
                        authActions.setUserData({
                            userDetails: {
                                mobileCountryCode: res.user.mobileCountryCode,
                                mobileNumber: res.user.mobileNumber,
                                email: res.user.email,
                                status: res?.user?.status,
                                preferredName: res?.user?.preferredName,
                                birthDate: res?.user?.dateOfBirth,
                                address: res?.user?.address
                            },
                            name: res.user.name,
                            userRole: checkRole(res?.user?.eligibleRoles),
                            userId: res?.user?.id,
                            hashId: res?.user.hashId,
                            profileCompletionPercentage:
                                res?.user?.profileCompletionPercentage
                        })
                    );
                    dispatch(authActions.login());
                    dispatch(webViewActions.loginWebViewUser());
                    if (config.autoLogout) {
                        sessionStorage.setItem(
                            'refreshToken',
                            res.refreshToken
                        );
                        sessionStorage.setItem('accessToken', res.accessToken);
                    } else {
                        localStorage.setItem('refreshToken', res.refreshToken);
                        localStorage.setItem('accessToken', res.accessToken);
                    }

                    if (
                        queryParams.has('userToken') ||
                        queryParams.has('clientToken')
                    ) {
                        queryParams.delete('userToken');
                        queryParams.delete('clientToken');
                        history.replace({
                            search: queryParams.toString()
                        });
                    }
                }
            })
            .catch((res) => {})
            .finally(() => {
                if (isWebView) {
                    dispatch(webViewActions.activateInternalWebview());
                    sessionStorage.setItem('isInternalWebView', true);
                }
                setClientAndUserTokenLoading(false);
            });
    };

    useEffect(() => {
        if (
            scrollTarget &&
            !(
                loading ||
                tokenLoading ||
                loadingWebviewToken ||
                clientAndUserTokenLoading
            )
        ) {
            scrollToTargetAdjusted(scrollTarget);
        }
    }, [
        scrollTarget,
        loading,
        tokenLoading,
        loadingWebviewToken,
        clientAndUserTokenLoading
    ]);

    useEffect(() => {
        if (sessionStorage.isInternalWebView) {
            dispatch(webViewActions.activateInternalWebview());
        }
    }, []);

    useEffect(() => {
        winnreAuth.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${jwtToken}`;
    }, [jwtToken]);

    useEffect(() => {
        if (clientToken) {
            dispatch(webViewActions.activateClientWebview());
            loginClientByToken();
        }
    }, [clientToken]);

    useEffect(() => {
        if (preLoginWebview) {
            dispatch(webViewActions.activatePreloginWebview());
        }
    }, [preLoginWebview]);

    useEffect(() => {
        if (userToken) {
            dispatch(webViewActions.activateUserWebview());
            loginClientByToken();
        }
    }, [userToken]);

    useEffect(async () => {
        if (webViewAccessToken) {
            if (
                config.autoLogout
                    ? sessionStorage.accessToken && sessionStorage.refreshToken
                    : localStorage.accessToken && localStorage.refreshToken
            ) {
                const decryptedWebviewToken = await decryptData(
                    webViewAccessToken
                );
                const decryptedLocalToken = await decryptData(
                    config.autoLogout
                        ? sessionStorage.accessToken
                        : localStorage.accessToken
                );
                const decodedWebviewToken = jwtDecode(decryptedWebviewToken);
                const decodedLocalToken = jwtDecode(decryptedLocalToken);
                //.exp is the time at which token expires
                // console.log(decodedWebviewToken.exp, decodedLocalToken.exp);
                if (decodedWebviewToken.exp < decodedLocalToken.exp) {
                    // console.log('Webview will expire sooner');
                    refereshWebviewToken(
                        false,
                        config.autoLogout
                            ? sessionStorage.accessToken
                            : localStorage.accessToken,
                        config.autoLogout
                            ? sessionStorage.refreshToken
                            : localStorage.refreshToken
                    );
                } else {
                    // console.log('Local will expire sooner');

                    refereshWebviewToken(
                        false,
                        webViewAccessToken,
                        webViewRefreshToken
                    );
                }
            } else {
                // console.log(
                //     'TOKEN NOT IN LS',
                //     webViewAccessToken,
                //     webViewRefreshToken
                // );
                refereshWebviewToken(
                    false,
                    webViewAccessToken,
                    webViewRefreshToken
                );
            }
            dispatch(webViewActions.activateInternalWebview());
            // getMobileUserDetailsAndAuthenticate();
            // dispatch(authActions.authenticationToken(webViewAccessToken));
        }
    }, [webViewAccessToken]);

    useEffect(() => {
        const htmlTag = document.getElementsByTagName('html');
        htmlTag[0].setAttribute('data-theme', `${config.theme}`);

        document.title = window.reloy_env.TITLE_NAME;

        const favicon = getFaviconEl();
        favicon.href = `${config?.favicon}`;
        setLoading(false);
    }, []);

    useEffect(() => {
        if (
            cmsClient.defaults.baseURL === 'https://dev.loyalie.in' &&
            config?.devWebengageLic
        ) {
            webEngageInit(config?.devWebengageLic);
        } else if (
            cmsClient.defaults.baseURL === 'https://uat.loyalie.in' &&
            config?.uatWebengageLic
        ) {
            webEngageInit(config?.uatWebengageLic);
        } else if (
            cmsClient.defaults.baseURL === 'https://prod.loyalie.in' &&
            config?.webengageLic
        ) {
            webEngageInit(config?.webengageLic);
        }
    }, []);

    useEffect(() => {
        if (!window.reloy_env.WEBFORM_ONLY && config?.googleConfig?.gID) {
            ReactGA.send('pageview');

            if (cmsClient.defaults.baseURL === 'https://prod.loyalie.in') {
                let googleTag = document.createElement('script');
                googleTag.async = true;
                googleTag.src = `https://www.googletagmanager.com/gtag/js?id=${config?.googleConfig?.gID}`;
                document.head.appendChild(googleTag);

                let googleScript = document.createElement('script');
                googleScript.innerHTML = `
                        window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${config?.googleConfig?.gID}");

                    `;
                document.head.appendChild(googleScript);
            }
        }
        if (
            cmsClient.defaults.baseURL === 'https://uat.loyalie.in' ||
            cmsClient.defaults.baseURL === 'https://prod.loyalie.in'
        ) {
            let myScript = document.createElement('script');
            myScript.innerHTML = `(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "e2wj6n53lm");`;
            document.head.appendChild(myScript);
        }
        if (
            cmsClient.defaults.baseURL === 'https://prod.loyalie.in' ||
            cmsClient.defaults.baseURL === 'https://uat.loyalie.in'
        ) {
            let myScript = document.createElement('script');
            myScript.innerHTML = `!(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", "${
        cmsClient.defaults.baseURL === 'https://prod.loyalie.in'
            ? config?.facebookPixelConfig?.id || ''
            : config?.uatFacebookPixelConfig.id || ''
    }");
    fbq("track", "PageView");
    `;
            document.head.appendChild(myScript);

            if (
                cmsClient.defaults.baseURL === 'https://prod.loyalie.in' ||
                cmsClient.defaults.baseURL === 'https://uat.loyalie.in'
            ) {
                window.fbq('track', 'PageView');
            }

            var meta = document.createElement('meta');
            meta.name = config?.facebookPixelConfig.metaName || '';
            meta.content = config?.facebookPixelConfig.metaContent || '';
            document.head.appendChild(meta);
        }
    }, []);

    useEffect(() => {
        if (!window.reloy_env.WEBFORM_ONLY && config?.googleConfig?.gID) {
            var meta = document.createElement('meta');
            meta.name = 'keywords';
            meta.content = config?.googleConfig?.keywordsContent || '';
            document.head.appendChild(meta);

            var meta = document.createElement('meta');
            meta.name = 'description';
            meta.content = config?.googleConfig?.descriptionContent || '';
            document.head.appendChild(meta);

            var meta = document.createElement('meta');
            meta.name = 'author';
            meta.content = config?.googleConfig?.authorContent || '';
            document.head.appendChild(meta);
        }
    }, []);

    useEffect(() => {
        const refreshInterval = setInterval(
            () => refreshTokenHandler(true),
            10000 * 60
        );
        return () => clearInterval(refreshInterval);
    }, []);

    const refreshOnNoActivity = () => {
        // console.log('refreshing on no activity');
        refreshTokenHandler(true, null, null, true);
    };

    return {
        loading,
        config,
        loadingWebviewToken,
        tokenLoading,
        unauthorizedUser,
        unauthorizedUserMessage,
        clientWebview,
        userWebview,
        internalWebview,
        hiddenOnWebview,
        preLoginWebview,
        refreshOnNoActivity,
        clientAndUserTokenLoading
    };
};

export default useAppInit;
