import axios from 'axios';
import { winnreAuth } from './axios';

const setAuthToken = (token) => {
    if (token) {
        // apply to every req
        winnreAuth.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        // delete auth header
        delete axios.defaults.headers.common['Authorization'];
    }
};

export default setAuthToken;
