import { getFont, openURLNewWindow } from '../../helpers';
import store from '../../store';

const SpreConfig = {
    clientId:
        'b170c980898c1639711e4613010044c8bfa37f205d1c599e54660417645e9c8b',
    id: 15,
    name: 'Spre',
    fullName: 'Shapoorji Pallonji',
    programName:'Club Benefique',
    theme: 'spreTheme',
    favicon: 'https://cdn.loyalie.in/39390676.png',
    primaryColor: '#ccab5c',
    fontBody: `${getFont('SPRE').body}`,
    fontTitle: `${getFont('SPRE').bold}`,
    googleConfig: {
        gID: 'G-DDEQ4T7FTP',
        keywordsContent:
            'Club Benefique, Club Benefique Shapoorji Pallonji, Shapoorji Pallonji, Shapoorji Pallonji Real Estate, Club Benefique referral program, Shapoorji Pallonji referral program, Clubbenefique, Clubbenefique referral, Club benefique referral program, Shapoorji referral program, SPRE',
        descriptionContent:
            'At Shapoorji Pallonji, you come above everything else. The Club Benefique referral program is designed to enrich your experience through events, offers, and blogs',
        authorContent: 'Club Benefique'
    },
    facebookPixelConfig: {
        id: '1107458623603381', //PROD
        metaName: 'facebook-domain-verification',
        metaContent: ''
    },
    webengageLic: 'in~~15ba20652',
    webengageAPIKey: 'ad6f71ea-b956-46cb-acd7-b4953a0ef652',
    uatFacebookPixelConfig: {
        id: '',
        metaName: 'facebook-domain-verification',
        metaContent: '5jj1hhrr9u84kl2mbxppxk6u0x9b4v'
    },

    layouts: {
        events: 'Layout2',
        blogs: 'Layout3'
    },
    activateReferPage: true,

    logoLeft: {
        name: 'SPRELOGO',
        url: 'https://cdn.loyalie.in/38674898.png',
        stickyNavBarLogoClass: ' h-10 md:h-25 lg:h-8 xl:h-12 pl-5 xl:pl-5',
        baseNavBarLogoClass: ' h-10 md:h-25 lg:h-8 xl:h-12  pl-5 xl:pl-5'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('SPRE').secondary}`,
        height: 'h-16 lg:h-20 2xl:h-28',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-primary lg:bg-white ',
        showActiveLinkColor: true,
        footerNavItemClass: `tracking-widest uppercase text-white text-[14px] lg:text-white font-light lg:text-[12px] xl:text-[15px] 3xl:text-lg  ${
            getFont('SPRE').secondary
        }`,
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-black lg:bg-transparent', // default: transparent
            containerClass: ' ',
            itemClass: `uppercase tracking-widest text-black lg:text-white text-[14px] lg:mr-7 hover:text-black lg:hover:text-primary font-light lg:text-[12px] xl:text-[15px] 3xl:text-lg ${
                getFont('SPRE').secondary
            }`,
            dropDownContainerClass: `text-black  hover:text-black  lg:hover:text-primary `,
            dropDownItemClass: `tracking-widest text-black hover:text-black  lg:mr-7 lg:hover:text-primary text-[14px] lg:text-[12px] xl:text-[15px] 3xl:text-lg ${
                getFont('SPRE').secondary
            }`
        },

        stickyNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-secondary', //default: primary-content,
            containerClass: '', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass: `uppercase tracking-widest text-black lg:text-white hover:text-black lg:mr-7 text-[14px] lg:hover:text-primary font-medium lg:text-[12px] xl:text-[15px] 3xl:text-lg ${
                getFont('SPRE').secondary
            }` // Each list element in NavBar (Text color, hover color, size)
        }
    },

    floatingButton: false,
    bannerNegativeTopMarginClass: '-mt-0 lg:-mt-24  2xl:-mt-28', //corresponding to navBarHeightClass
    scrollToTargetAdjustedValue: 92,

    clientButtonClassName: `text-[12px] md:text-[17px] 2xl:text-lg tracking-wider bg-primary text-white rounded-md py-2 md:py-2 2xl:py-[9px] px-6 md:px-5 ${
        getFont('SPRE').bold
    }`,

    loginModal: {
        showEmployeeLogin: true,
        showSVLogin: false,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },

    increasePageSize: true, // if event layout 2 then true otherwise false
    showFooterRoutes: true,

    routes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true,

            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage: 'https://cdn.loyalie.in/75749506.jpg',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/75749506.jpg',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0.60'
                    },
                    bannerContainerClass:
                        'h-[80vh] md:h-[100vh] lg:h-[100vh] bg-right xl:bg-center border-b-[6px] border-primary',
                    bannerItemContainerClass: 'items-start justify-center  ',
                    position: 'text-left',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'w-full text-center mt-10 lg:mt-0 leading-6 md:leading-9 lg:leading-none',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'THE FINEST HOUSING COMES',
                                    className: ` text-white text-[28px] md:text-[45px] lg:text-[30px] xl:text-[35px] ${
                                        getFont('SPRE').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: 'WITH FINER EXPERIENCES',
                                    className: ` text-white text-[28px] md:text-[45px] lg:text-[30px] xl:text-[35px] ${
                                        getFont('SPRE').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'REFERRAL & LOYALTY PROGRAM FOR HOMEBUYERS!',
                                    className: `mt-3 text-primary  font-medium text-[16px] lg:text-[14px] xl:text-[17px] tracking-widest  ${
                                        getFont('SPRE').secondary
                                    }`
                                },
                                {
                                    type: 'IMAGE',
                                    url: 'https://cdn.loyalie.in/50676970.png',
                                    position: 'center',
                                    className:
                                        'w-[25%] md:w-[15%] lg:w-[7%]  my-5 '
                                },
                                {
                                    type: 'IMAGE',
                                    url: 'https://cdn.loyalie.in/42924185.png',
                                    position: 'center',
                                    className:
                                        'w-[60%] md:w-[35%] lg:w-[22%] mb-3  xl:my-2 3xl:mt-6 '
                                },
                                {
                                    type: 'BUTTON',
                                    content: 'Stay Tuned For More',
                                    className: `md:mx-0 text-left mt-4 uppercase text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-primary hover:bg-secondary text-white font-normal px-4 py-3 xl:px-5 xl:py-3.5 ${
                                        getFont('SPRE').medium
                                    }`,
                                    path: 'gift-and-earn',
                                    onClick: 'CHANGE_PATH_AND_SCROLL',
                                    scrollTo: 'referral_section'
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'overview',
                    url: '',
                    linearGradient: {
                        R: '243',
                        G: '243',
                        B: '243',
                        opacity: '1',
                        size: '70%'
                    },
                    bannerItemContainerClass: 'bg-cover',
                    className: 'block lg:flex p-5 md:p-10 lg:px-5 xl:px-10 ',
                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                ' lg:px-5 xl:px-16 pb-10 lg:pb-0 border-b-2  lg:border-b-0 lg:border-r-2 border-secondary text-center lg:text-left w-full lg:w-[60%]',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'About Us',
                                    className: `pt-1 text-[22px] md:text-[24px]  lg:text-[20px] xl:text-[23px] 2xl:text-[25px] uppercase text-secondary-focus leading-6 xl:leading-7 break-words mb-3 ${
                                        getFont('SPRE').bold
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'mt-1 w-[18%] lg:w-[13%] mx-auto lg:mx-0 xl:w-[12%] bg-secondary border-none h-[5px] xl:h-[6px] mb-3',
                                    classNameContainer: ''
                                },
                                {
                                    type: 'TEXT',
                                    content: 'Welcome to Club Benefique',
                                    className: `text-[20px] md:text-[22px] lg:text-[20px] xl:text-[23px] 2xl:text-[25px] text-primary leading-6 xl:leading-7 break-words my-3 ${
                                        getFont('SPRE').bold
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'At Shapoorji Pallonji, you come above everything else. The Club Benefique referral program is designed to enrich your experience through events, offers, and promotions across all categories and reward your word for expanding our community to your loved ones. This platform is our effort at putting your experience right at the center of affluence and attention, where it deserves to be.',
                                    className: `text-[18px] md:text-[20px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] text-secondary-focus leading-6 md:leading-7 lg:leading-6 xl:leading-7 self-center break-words ${
                                        getFont('SPRE').body
                                    }`
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            bannerItemContainerClass: 'bg-cover',
                            className:
                                'grid grid-row-3 gap-y-4 lg:border-l-2 border-secondary w-[95%] md:w-[60%] lg:w-[40%] pt-10 lg:pt-0 mx-auto lg:pl-10 xl:px-16',
                            components: [
                                {
                                    type: 'CUSTOM',
                                    bannerItemContainerClass: 'bg-cover',
                                    className: 'flex ',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            bannerItemContainerClass:
                                                'bg-cover',
                                            className: 'w-[20%] my-auto',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/2119258.png',
                                                    position: 'center',
                                                    className: ''
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            bannerItemContainerClass:
                                                'bg-cover',
                                            className:
                                                'w-[80%] xl:w-[70%] my-auto',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'Earn up to ₹ 4 lacs on every successful referral',
                                                    className: `text-[18px] md:text-[20px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] text-secondary-focus leading-5 md:leading-6 xl:leading-7   px-5 self-center break-words ${
                                                        getFont('SPRE').body
                                                    }`
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    bannerItemContainerClass: 'bg-cover',
                                    className: 'flex',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            bannerItemContainerClass:
                                                'bg-cover',
                                            className: 'w-[20%] my-auto',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/13677425.png',
                                                    position: 'center',
                                                    className: ''
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            bannerItemContainerClass:
                                                'bg-cover',
                                            className:
                                                'w-[80%] xl:w-[70%] my-auto',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'Get offers and promotions',
                                                    className: `text-[18px] md:text-[20px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] text-secondary-focus leading-5 md:leading-6 xl:leading-7 px-5 self-center break-words ${
                                                        getFont('SPRE').body
                                                    }`
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'CUSTOM',
                                    bannerItemContainerClass: 'bg-cover',
                                    className: 'flex',
                                    components: [
                                        {
                                            type: 'CUSTOM',
                                            bannerItemContainerClass:
                                                'bg-cover',
                                            className: 'w-[20%] my-auto',
                                            components: [
                                                {
                                                    type: 'IMAGE',
                                                    url: 'https://cdn.loyalie.in/88877372.png',
                                                    position: 'center',
                                                    className: ''
                                                }
                                            ]
                                        },
                                        {
                                            type: 'CUSTOM',
                                            bannerItemContainerClass:
                                                'bg-cover',
                                            className:
                                                'w-[80%] xl:w-[70%] my-auto',
                                            components: [
                                                {
                                                    type: 'TEXT',
                                                    content:
                                                        'Access to exclusive events',
                                                    className: `text-[18px] md:text-[20px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] text-secondary-focus leading-5 md:leading-6 xl:leading-7 px-5 self-center break-words ${
                                                        getFont('SPRE').body
                                                    }`
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'REFERRAL_FORM_BANNER'
                },

                {
                    type: 'CUSTOM',
                    url: '',
                    linearGradient: {
                        R: '243',
                        G: '243',
                        B: '243',
                        opacity: '1',
                        size: '70%'
                    },
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-[#f58020] pt-10 ',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'RECENT BLOGS',
                            className: `text-black tracking-wide text-center px-2 text-[22px] md:text-[24px] lg:text-[23px] xl:text-[30px]  2xl:text-[35px]   ${
                                getFont('SPRE').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                'mt-1 self-center mx-auto w-[35%] md:w-[26%] lg:w-[12%] 2xl:w-[15%] bg-primary border-none h-[4px]',
                            classNameContainer: ''
                        },

                        { type: 'BLOG_LAYOUT_VERTICAL' },
                        {
                            type: 'BUTTON',
                            content: 'Read More Blogs',
                            className: ` text-left text-[15px] md:text-[15px] uppercase lg:text-[13px] xl:text-[15px] tracking-wider bg-none text-secondary font-normal px-4 py-3 xl:px-5 xl:py-3.5 border-2 border-secondary -mt-10 ${
                                getFont('SPRE').medium
                            }`,
                            path: 'blogs',
                            scrollTo: 'blogs_section',
                            onClick: 'CHANGE_PATH_AND_SCROLL'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    url: '',
                    linearGradient: {
                        R: '243',
                        G: '243',
                        B: '243',
                        opacity: '1',
                        size: '70%'
                    },
                    className: 'block md:flex pt-10 ',
                    components: [
                        {
                            type: 'CUSTOM',
                            bannerItemContainerClass: 'bg-cover',
                            className:
                                'w-full md:w-[43%] bg-primary p-10 md:p-8 lg:p-16 xl:py-24 ',
                            components: [
                                {
                                    type: 'TEXT',
                                    content: 'Start Enjoying',
                                    className: ` text-[18px] md:text-[20px] lg:text-[21px] xl:text-[30px] 2xl:text-[36px] text-secondary-focus leading-6 xl:leading-7 break-words mb-3 self-center ${
                                        getFont('SPRE').body
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: 'A REWARDING EXPERIENCE',
                                    className: `pt-1 text-[30px] md:text-[30px] lg:text-[35px] xl:text-[45px] 2xl:text-[50px] text-white leading-tight	 mb-3 self-center ${
                                        getFont('SPRE').medium
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'mt-1 w-[20%] xl:w-[18%] bg-secondary border-none h-[5px] xl:h-[6px] mb-3',
                                    classNameContainer: ''
                                },
                                {
                                    type: 'TEXT',
                                    content: 'DOWNLOAD THE APP NOW!',
                                    className: `pt-1 text-[18px] md:text-[18px] lg:text-[19px] xl:text-[24px] 2xl:text-[27px] text-secondary font-semibold self-center ${
                                        getFont('SPRE').secondary
                                    }`
                                },
                                {
                                    type: 'CUSTOM',
                                    bannerItemContainerClass: 'bg-cover',
                                    className:
                                        'flex gap-4 mt-2 lg:mt-5 w-[80%] lg:w-[75%] xl:w-[60%] ',
                                    components: [
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/30582663.png',
                                            position: '',
                                            className: 'cursor-pointer',
                                            onImageClick: () => {
                                                window.open(
                                                    'https://apps.apple.com/in/app/spre-affinity/id1606122742'
                                                );
                                            }
                                        },
                                        {
                                            type: 'IMAGE',
                                            url: 'https://cdn.loyalie.in/72506185.png',
                                            position: '',
                                            className: 'cursor-pointer',
                                            onImageClick: () => {
                                                window.open(
                                                    'https://play.google.com/store/apps/details?id=com.loyalie.winnre.spre'
                                                );
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'CUSTOM',
                            url: 'https://cdn.loyalie.in/48027791.jpg',
                            linearGradient: {
                                R: '0',
                                G: '0',
                                B: '0',
                                opacity: '0.6',
                                size: 'cover'
                            },
                            bannerItemContainerClass: 'bg-cover',
                            className:
                                'w-full md:w-[57%] py-20 md:py-0 md:pt-16 lg:pt-28 xl:pt-36',
                            components: [
                                {
                                    type: 'TEXT',
                                    content:
                                        'ENJOY OFFERS CURATED SPECIALLY FOR YOU:',
                                    className: `pt-1 text-[16px] md:text-[16px] lg:text-[18px] xl:text-[25px] font-light text-white leading-6 xl:leading-7 break-words mb-3 text-center self-auto ${
                                        getFont('SPRE').secondary
                                    }`
                                },
                                {
                                    type: 'CAROUSEL',
                                    customSlide: true,
                                    caraouselClassName:
                                        'mx-10  md:mx-5 lg:mx-10 xl:mx-20 px-5 lg:px-10 text-white self-auto',
                                    imageContainerClassName:
                                        'mx-20 md:m-4 p-6 md:p-5 lg:p-4 xl:p-7 rounded-[100%] bg-white ',
                                    imageClassName: ' lg:bg-cover object-cover',
                                    captionClass: ` text-sm lg:text-lg font-semibold leading-8 ${
                                        getFont('SPRE').body
                                    }`,
                                    arrows: true,
                                    autoplay: true,
                                    autoplaySpeed: 3000,
                                    infinite: true,
                                    dots: false,
                                    slidesToShow: 4,
                                    pauseOnHover: true,
                                    speed: 1500,
                                    onImageClick: (imgdata) => {
                                        // console.log(imgdata);
                                        return;
                                    },
                                    getVendorLogos: true
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    url: '',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0',
                        size: 'cover'
                    },
                    bannerItemContainerClass: 'bg-cover',
                    className: 'text-center',
                    components: [
                        {
                            type: 'CAROUSEL',
                            customSlide: true,
                            caraouselClassName:
                                'px-8 md:px-10 xl:px-36 md:pb-10 py-5 relative',
                            imageContainerClassName: 'm-4 mx-16  relative',
                            imageClassName:
                                'w-[50%] mx-auto md:w-full drop-shadow-xl rounded  lg:bg-cover object-cover',
                            captionClass: ` text-sm lg:text-lg font-semibold leading-8 ${
                                getFont('SPRE').body
                            }`,
                            arrows: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            infinite: true,
                            dots: false,
                            slidesToShow: 5,
                            pauseOnHover: true,
                            speed: 1500,
                            onImageClick: openURLNewWindow,
                            imgArr: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/86967307.jpg',
                                    alt: 'Joyville-Virar',
                                    url: '',
                                    caption: ''
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/16667637.jpg',
                                    alt: 'Joyvillle-Hinjawadi',
                                    url: '',
                                    caption: ''
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/85704062.jpg',
                                    alt: 'Northern-Lights',
                                    url: '',
                                    caption: ''
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/47486201.jpg',
                                    alt: 'Parkwest',
                                    url: '',
                                    caption: ''
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/86119374.jpg',
                                    alt: 'Vincinia',
                                    url: '',
                                    caption: ''
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/77084730.jpg',
                                    alt: 'Joyville-howrah',
                                    url: '',
                                    caption: ''
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/9365114.jpg',
                                    alt: 'Joyville-Gurugram',
                                    url: '',
                                    caption: ''
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/13742041.jpg',
                                    alt: 'hadapsar-logo',
                                    url: '',
                                    caption: ''
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/21495684.jpg',
                                    alt: 'BKC 28',
                                    url: '',
                                    caption: ''
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/1721862.jpg',
                                    alt: 'Sensorium',
                                    url: '',
                                    caption: ''
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/8798497.jpg',
                                    alt: 'The Designate',
                                    url: '',
                                    caption: ''
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        referPage: {
            show: true,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: false,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        dropDown: {
            show: true,
            name: 'ENGAGEMENT',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },

        events: {
            show: false,
            name: 'ENGAGEMENT',
            path: '/events',
            component: 'Events'
        },
        blogs: {
            show: false,
            name: 'BLOGS',
            path: '/blogs',
            component: 'Blogs'
        },
        deals: {
            show: true,
            name: 'OFFERS',
            path: '/deals',
            component: 'DealsAndDiscounts'
        },
        projects: {
            show: true,
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects'
        },
        contactUs: {
            show: false,
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            show: false,
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },
    footerRoutes: {
        contactUs: {
            name: 'Contact Us',
            path: '/contact-us',
            component: 'Contact-Us'
        },
        termsAndConditions: {
            name: 'Terms And Conditions',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions'
        }
    },

    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'BANNER',
                    linearGradient: {
                        R: '0',
                        G: '0',
                        B: '0',
                        opacity: '0'
                    },
                    backgroundImage:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Shapoorji/Images/77047253.jpg',
                    backgroundImageMobile:
                        'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Shapoorji/Images/77047253.jpg',
                    bannerContainerClassName:
                        'relative hero hero-content max-w-full h-[70vh] object-cover bg-center lg:bg-left',
                    components: [
                        {
                            type: 'TEXT',
                            content:
                                'Refer your loved ones and get rewards on every successful referral ',
                            className: `text-center md:text-left text-white text-[16px] md:text-[20px] xl:text-[23px] uppercase my-5 tracking-widest w-full md:w-[55%] lg:w-[50%] xl:w-[55%] ml-auto leading-6 md:leading-7 xl:leading-8 ${
                                getFont('SPRE').heading
                            }`
                        },
                        {
                            type: 'CUSTOM',
                            className:
                                'flex w-full md:w-[55%] lg:w-[50%] xl:w-[55%] ml-auto',
                            components: [
                                {
                                    type: 'BUTTON',
                                    content: 'Start Referring Now',
                                    onClick: 'FUNC',
                                    onClickFunc: () =>
                                        store.dispatch({
                                            type: 'authentication/openLoginModal'
                                        }),
                                    className: `mx-auto md:mx-0 text-left uppercase text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-primary hover:bg-secondary text-white font-normal px-4 py-3 xl:px-5  ${
                                        getFont('SPRE').medium
                                    }`
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '34',
                    G: '34',
                    B: '34',
                    opacity: '0'
                },
                position: 'text-center',
                backgroundImage:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Shapoorji/Images/77047253.jpg',
                backgroundImageMobile:
                    'https://reloydev.s3.ap-south-1.amazonaws.com/Website/Uploads/Shapoorji/Images/77047253.jpg',
                bannerContainerClassName:
                    'relative hero hero-content max-w-full h-[60vh] rounded-xl lg:rounded-none md:h-[70vh] object-cover bg-left ',
                components: [
                    // {
                    //     type: 'TEXT',
                    //     content:
                    //         'Refer your loved ones and get benefits upto ',
                    //     className: `text-black text-[15px] md:text-[20px] xl:text-[25px] font-thin uppercase  tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').body
                    //         }`
                    // },
                    // {
                    //     type: 'TEXT',
                    //     content: '₹2,75,000 ',
                    //     className: `text-black text-[15px] leading-10 md:text-[20px] xl:text-[45px] font-bold tracking-widest ml-auto lg:w-[70%]  ${getFont('RELOY').heading
                    //         }`
                    // },
                    // {
                    //     type: 'CUSTOM',
                    //     className: 'flex w-[70%] ml-auto justify-center mt-7', components: [{
                    //         type: 'BUTTON',
                    //         content: 'Start Referring Now',
                    //         scrollTo: 'referral_section',
                    //         onClick: 'SCROLL'
                    //     }]
                    // }
                ]
            }
        ],

        customize: {
            claimButton: `text-md font-bold tracking-widest bg-primary text-white px-8 md:px-6 py-3 lg:mt-2 xl:mt-0 mx-auto ${
                getFont('PURTI').heading
            }`
        }
    },

    blogs: {
        banner: {
            bannerContainerClassName:
                'hero hero-content items-start md:items-center max-w-full h-[90vh] lg:h-[100vh] bg-bottom lg:bg-center mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.50'
            },
            backgroundImage: 'https://cdn.loyalie.in/40618345.jpg',
            backgroundImageMobile: 'https://cdn.loyalie.in/83893671.jpg',
            components: [
                {
                    type: 'TEXT',
                    content: 'The Benefique Diary',
                    className: `text-white mt-16 md:mt-0 uppercase tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] ${
                        getFont('SPRE').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Access exclusive content to connect with like-minded people.',
                    className: `mt-3 xl:mt-4 text-primary  font-normal text-center text-[16px] md:text-[19px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 ${
                        getFont('SPRE').secondary
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read More',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL',
                    className: `md:mx-0 text-left mt-3 xl:mt-4  text-[15px] uppercase md:text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-primary hover:bg-secondary text-white font-normal px-4 py-3 xl:px-5 xl:py-3.5 ${
                        getFont('SPRE').medium
                    }`
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'The Benefique Diary',
                    className: `text-white mt-16 md:mt-0 uppercase tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] ${
                        getFont('SPRE').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Access exclusive content to connect with like-minded people.',
                    className: `mt-5 text-primary  font-normal text-center text-[16px] md:text-[19px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 ${
                        getFont('SPRE').secondary
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    className: `md:mx-0 text-left mt-6  uppercase text-[15px] md:text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-primary hover:bg-secondary text-white font-normal py-3 md:py-2 2xl:py-[8px] px-5 md:px-6 ${
                        getFont('SPRE').medium
                    }`
                }
            ]
        },

        sideBarClass: `text-primary-focus text-[20px] lg:text-[25px] ${
            getFont('SPRE').heading
        }`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-secondary-focus capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('SPRE').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                components: [
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-4 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ],
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-secondary-focus ${
                    getFont('SPRE').body
                }`,
                headingClass: `text-primary-focus  my-4 text-[25px] md:text-[35px] lg:text-[30px] xl:text-[30px] uppercase leading-7 xl:leading-9 ${
                    getFont('SPRE').heading
                }`,
                summaryClass: `inline text-[15px] md:text-[19px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[25px] mt-8 font-medium leading-7 xl:leading-8 text-secondary-focus min-h-[40px] lg:min-h-[70px] ${
                    getFont('SPRE').body
                }`,
                dateClass: `text-[13px] uppercase text-secondary-focus font-semibold my-3 ${
                    getFont('SPRE').body
                } `,
                buttonClass: `inline text-[15px] md:text-[19px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[25px] font-medium text-primary-focus ml-2 ${
                    getFont('SPRE').body
                }`
            },
            sectionHeadings: `text-secondary self-center tracking-wide text-center w-[90%] md:w-[80%] lg:w-[70%] 2xl:w-[50%] text-[22px] md:text-[24px] lg:text-[25px] xl:text-[35px] mx-auto text-center leading-6 xl:leading-10 mt-10 uppercase ${
                getFont('SPRE').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout3: {
            components: [
                {
                    type: 'DIVIDER',
                    className:
                        'mt-4 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                }
            ],
            dividerClass: 'hidden m-0 p-0 ',
            backgroundColor: 'bg-white',
            headingClass: `text-primary-focus  text-[20px] md:text-[24px] lg:text-[18px] h-[75px] uppercase leading-6 ${
                getFont('SPRE').heading
            }`,
            summaryClass: `text-[14px] xl:text-[15px] my-4 leading-5 font-medium text-secondary-focus h-[90px] ${
                getFont('SPRE').body
            }`,
            categoryClass: `bg-[#00000075]  text-white text-[10px] ${
                getFont('SPRE').body
            }`,
            buttonClass: `flex justify-center   self-center font-medium lg:text-left text-[13px] xl:text-[15px] bg-primary  hover:bg-secondary text-white mb-5 py-3 md:px-5 px-6  ${
                getFont('SPRE').medium
            }`,
            dateClass: `text-[12px] xl:text-[13px] self-center text-secondary-focus font-medium ${
                getFont('SPRE').body
            }`,
            dateClassContainer:
                'flex font-Roboto justify-start space-x-6 xl:space-x-4 border-t border-primary px-[25px] py-2 lg:py-3 3xl:py-3',
            noBlogsClass: `bg-primary text-white font-semibold p-2 md:w-[30%] lg:w-[70%] xl:w-[50%] 3xl:w-[30%] mt-10 ${
                getFont('SPRE').body
            }`
        }
    },
    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/46443367.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/46443367.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.50'
            },

            negativeMargin: '-mt-12 lg:-mt-24 2xl:-mt-28',
            containerClass: ' text-white w-[50%] ',
            heading: '',
            headingClass: `font-semibold text-center lg:w-[70%] text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] text-white uppercase ${
                getFont('SPRE').heading
            }`,
            subHeading: '',
            subHeadingClass: `text-accent uppercase font-semibold text-[33px] ${
                getFont('SPRE').heading
            }`
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-12 lg:mt-24 2xl:mt-28 hero hero-content items-start md:items-center max-w-full h-[90vh] lg:h-[100vh] bg-bottom lg:bg-center mx-auto text-center ',
            components: [
                {
                    type: 'TEXT',
                    content: 'CURATED WORKSHOPS AND EVENTS',
                    className: `text-white mt-16 md:mt-0 uppercase tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] ${
                        getFont('SPRE').heading
                    }`
                },
                {
                    type: 'DIVIDER',
                    className:
                        'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-primary border-none h-[4px]',
                    classNameContainer: ''
                },
                {
                    type: 'TEXT',
                    content:
                        'Meet and connect with like-minded people of your bespoke community',
                    className: `mt-3 xl:mt-4 text-primary  font-normal text-center text-[16px] md:text-[19px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 ${
                        getFont('SPRE').secondary
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Participate Now',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        }),
                    onClick: 'FUNC',
                    className: `md:mx-0 text-left mt-3 xl:mt-4 uppercase text-[15px] md:text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-primary hover:bg-secondary text-white font-normal px-4 py-3  xl:px-6 xl:py-3.5 ${
                        getFont('SPRE').medium
                    }`
                }
            ]
        },

        eventsSection: {
            heading: 'PAST EVENTS',
            headingClass: `self-center text-center text-[22px] md:text-[24px] lg:text-[25px] xl:text-[35px] text-neutral w-[60%] mt-16 ${
                getFont('SPRE').heading
            }`,
            dividerClass: 'hidden',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-center text-xl mt-5 text-neutral w-[60%] ${
                getFont('SPRE').body
            }`,
            additionalText: ' ',
            additionalTextClass: ``,
            subAdditionalText: ' ',
            subAdditionalTextClass: ``,
            noEvents: 'No events to show',
            noEventsClass: `text-[#373737] text-xl m-auto text-center ${
                getFont('SPRE').body
            }`
        },
        eventLayout: {
            titleClass: `text-accent leading-5 uppercase text-[18px] font-medium mt-2 ${
                getFont('SPRE').heading
            }`,
            hostClass: 'capitalize ',
            dateClass: ''
        }
    },
    eventsGallery: {
        show: true,
        titleClass: ` text-secondary-focus font-semibold text-lg my-4 z-10 ${
            getFont('SPRE').heading
        }`,
        noGalleryClass: ` text-secondary-focus font-semibold ${
            getFont('SPRE').heading
        }`
    },
    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content items-start md:items-center max-w-full h-[30vh] lg:h-[45vh] bg-center mx-auto text-center',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0' //0.5
            },
            backgroundImage:
                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1695628033484_Offer banner',
            backgroundImageMobile:
                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1695628033484_Offer banner',
            components: [],
            loginBannerComponents: [
                //4C
                // {
                //     type: 'TEXT',
                //     content: 'Select Offers And Benefits',
                //     className: `text-white mt-16 md:mt-0 uppercase tracking-wide text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] ${
                //         getFont('SPRE').heading
                //     }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[40%] lg:w-[20%] bg-primary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Enjoy specially curated offers from top pan India brands',
                //     className: `mt-3 xl:mt-4 text-primary  font-normal text-center text-[16px] md:text-[19px] lg:text-[15px] xl:text-[20.5px] 2xl:text-[23px] leading-6 ${
                //         getFont('SPRE').secondary
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     onClick: 'FUNC',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     className: `md:mx-0 text-left mt-3 xl:mt-4 uppercase text-[15px] md:text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-primary hover:bg-secondary text-white font-normal px-4 py-3  xl:px-6 xl:py-3.5 ${
                //         getFont('SPRE').medium
                //     }`
                // }
            ]
        },

        dealsSection: {
            sectionBackgroundImage: '',
            overlayClass: '',
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.89',
                size: '30% auto'
            },
            defaultBackgroundImage: 'https://cdn.loyalie.in/42228718.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `self-center font-semibold md:text-[20px] text-center text-[17px] text-primary w-[90%] ${
                getFont('SPRE').secondary
            }`,
            additionalText: 'CURATING EXCLUSIVE OFFERS AND PROMOTIONS FOR YOU',
            additionalTextClass: `text-2xl xl:text-3xl uppercase text-secondary  ${
                getFont('SPRE').heading
            } `,
            subAdditionalText:
                'Club Benefique is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: ` font-normal leading-6`,
            noDeals: 'No offers to show',
            noDealsClass: `text-primary text-2xl text-center my-6
         ${getFont('SPRE').body}`
        },
        dealLayout: {
            containerClassNameBg1: 'px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2: 'p-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            listTitleClass: `text-2xl lg:text-xl leading-6 lg:leading-5 text-black ${
                getFont('SPRE').body
            }`,
            listBodyClass: `${getFont('SPRE').body}`,
            pattern1: 'https://cdn.loyalie.in/43800302.png',
            pattern2: 'https://cdn.loyalie.in/43800302.png',
            linearGradient: {
                R: '243',
                G: '243',
                B: '243',
                opacity: '1',
                repeat: 'no-repeat',
                size: 'cover',
                position: ''
            },
            logoClassName: `self-center rounded-md drop-shadow-lg z-10 `,
            titleClassName: ` text-primary font-medium uppercase text-2xl xl:text-3xl ${
                getFont('SPRE').heading
            } `,
            couponClassName: `block text-sm md:text-base xl:text-xl text-primary font-bold  ${
                getFont('SPRE').secondary
            }`,
            dateClassName: ` text-secondary text-[16px] font-semibold capitalize  ${
                getFont('SPRE').body
            }`,
            termsAndConditionsClassName: `font-semibold capitalize text-neutral text-sm  ${
                getFont('SPRE').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-neutral',
            termsClassName: `font-semibold text-neutral text-xs break-words leading-1 ${
                getFont('SPRE').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',
                className: `self-center uppercase text-[15px] md:text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-primary hover:bg-secondary text-white font-normal px-4 py-3  xl:px-6 xl:py-3  ${
                    getFont('SPRE').medium
                }`
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',
                className: `rounded self-center  uppercase text-[15px] md:text-[15px] lg:text-[13px] xl:text-[15px] tracking-wider bg-primary hover:bg-secondary text-white font-normal px-4 py-3  xl:px-6 xl:py-3 ${
                    getFont('SPRE').medium
                }`
            }
        }
    },
    projects: {
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    string: '100,65,43,0.7',
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.55'
                },
                backgroundImage: 'https://cdn.loyalie.in/71567414.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/71567414.jpg',
                bannerContainerClassName:
                    'hero hero-content max-w-full h-[50vh] md:h-[70vh] lg:h-[99vh] bg-bottom',

                components: [
                    {
                        type: 'TEXT',
                        content: 'Shapoorji Pallonji PROJECTS',

                        className: ` mx-auto uppercase text-center text-white mb-3 text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] tracking-wider ${
                            getFont('SPRE').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ],
        moduleConfig: {
            constructionVisible: true,

            sectionBackgroundImage: '',
            overlayClass: '',
            propertyBackgroundColorWithOverlay: ''
        }
    },
    header: {
        headerClass: 'hidden lg:block h-28 bg-black'
    },
    contactUs: {
        sectionBackgroundImage: 'https://cdn.loyalie.in/46329169.png', //get link from mintu , set this value in code as well
        linearGradient: {
            R: '255',
            G: '255',
            B: '255',
            opacity: '0.97',
            repeat: 'repeat',
            size: 'contain'
        },
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.50'
                },
                backgroundImage: 'https://cdn.loyalie.in/45495424.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/45495424.jpg',
                bannerContainerClassName:
                    'hero hero-content  text-center max-w-full lg:bg-center h-[50vh] md:h-[70vh] lg:h-[99vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',

                        className: ` mx-auto text-center mb-3 text-white text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px]  tracking-wider ${
                            getFont('SPRE').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[15%] xl:w-[10%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            }
        ]
    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: ` text-secondary mt-4 font-normal  text-[20px] md:text-[20px] ${
            getFont('SPRE').secondary
        }`,
        listClassName: `text-secondary font-normal text-[17px] leading-7 ${
            getFont('SPRE').body
        }`,
        components: [
            {
                type: 'BANNER',
                linearGradient: {
                    R: '0',
                    G: '0',
                    B: '0',
                    opacity: '0.50'
                },
                backgroundImage: 'https://cdn.loyalie.in/41393320.jpg',
                backgroundImageMobile: 'https://cdn.loyalie.in/41393320.jpg',
                bannerContainerClassName:
                    'hero hero-content  text-center max-w-full lg:bg-top h-[50vh] md:h-[70vh] lg:h-[99vh]',
                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS & CONDITIONS',

                        className: ` mx-auto text-center mb-3 text-white text-[25px] md:text-[35px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px]  tracking-wider ${
                            getFont('SPRE').heading
                        }`
                    },
                    {
                        type: 'DIVIDER',
                        className:
                            'mt-1 self-center mx-auto w-[40%] md:w-[35%] lg:w-[15%] xl:w-[10%] bg-primary border-none h-[4px]',
                        classNameContainer: ''
                    }
                ]
            },
            {
                type: 'CUSTOM',
                url: 'https://cdn.loyalie.in/46329169.png',
                linearGradient: {
                    R: '255',
                    G: '255',
                    B: '255',
                    opacity: '0.98'
                },
                className: 'text-left p-16',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-secondary font-medium text-[20px] md:text-[20px] ${
                            getFont('SPRE').secondary
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',

                        className: ` text-secondary mt-4 font-normal  text-[20px] md:text-[20px] ${
                            getFont('SPRE').secondary
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',
                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Shapoorji Pallonji Real Estate project, and has entered into a registered Agreement to Sell; or (ii) employee of  Shapoorji Pallonji Real Estate; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Shapoorji Pallonji Real Estate or any of its sister concerns/subsidiaries/affiliates (“Shapoorji Pallonji Real Estate Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Shapoorji Pallonji Real Estate Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Shapoorji Pallonji Real Estate.',
                            'Referrer shall not be able to refer existing customer(s) of Shapoorji Pallonji Real Estate. Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Shapoorji Pallonji Real Estate or (ii) is/are already registered with Shapoorji Pallonji Real Estate as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Shapoorji Pallonji Real Estate’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',
                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Shapoorji Pallonji Real Estate within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Shapoorji Pallonji Real Estate.',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by Shapoorji Pallonji Real Estate or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-secondary font-normal text-[17px] leading-7 ${
                            getFont('SPRE').body
                        }`,
                        classNameContainer: 'px-6 mt-6'
                    },
                    { type: 'RAZORPAY_TERMS', classNameContainer: 'px-6 mt-6' },

                    {
                        type: 'CUSTOM',
                        className: 'px-6 pt-2',
                        components: [
                            {
                                type: 'TEXT',
                                content:
                                    'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Shapoorji Pallonji Real Estate, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                className: `${
                                    getFont('SPRE').body
                                } inline text-secondary font-normal text-[17px] leading-7  `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/terms-conditions/',
                                content: 'reloy.co/terms-and-conditions ',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('SPRE').body
                                }`
                            },

                            {
                                type: 'TEXT',

                                content: 'and privacy policy on',
                                className: `inline ml-1 text-secondary font-normal text-[17px] leading-7 ${
                                    getFont('SPRE').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'https://reloy.co/privacy-policy/',
                                content: 'reloy.co/privacy-policy ',
                                className: `inline-block ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('SPRE').body
                                }`
                            },

                            {
                                type: 'TEXT',
                                content:
                                    'laid out by Loyalie IT-Solutions Pvt. Ltd. In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                className: `inline ml-1 text-secondary font-normal text-[17px] leading-7 ${
                                    getFont('SPRE').body
                                } `
                            },
                            {
                                type: 'LINK',
                                url: 'mailto:support@reloy.co',
                                content: 'support@reloy.co',
                                className: `inline ml-1 text-primary font-medium text-[17px] leading-7 ${
                                    getFont('SPRE').body
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                className: `inline ml-1 text-secondary font-normal text-[17px] leading-7 ${
                                    getFont('SPRE').body
                                } `
                            }
                        ]
                    }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false,
        appContainerClassName: 'bg-white text-black border-primary',
        appFontHeading: `${getFont('SPRE').body}`,
        appStoreLink:
            'https://apps.apple.com/in/app/club-benefique/id1492099064?utm_medium=email',
        playStoreLink:
            'https://play.google.com/store/apps/details?id=com.loyalie.shapoorji&hl=en&gl=US&utm_medium=email',
        appScreen: 'https://cdn.loyalie.in/56238704.png'
    },
    footer: {
        footerLayout: 'Footer2',
        footerLogo: 'https://cdn.loyalie.in/64180896.png',
        containerClassName: 'bg-secondary-content xl:pt-0',
        footerLogoClassName: 'self-center md:w-[15%] lg:w-[16%] lg:pl-3 ',
        termsAndConditionsClassName: `text-white text-[12px] 3xl:text-[15px] text-right mr-7 pb-2 ${
            getFont('SPRE').heading
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        allRightsReservedVisible: false,
        allRightsReservedContent: '',
        allRightsReservedClassName: ``,
        loyalieFooterExpandContent: false,
        loyalieFooterExpandName: 'Disclaimer',
        loyalieFooterExpandClassName: `text-center text-white cursor-pointer pb-2 ${
            getFont('SPRE').heading
        }`,
        loyalieFooterExpandContentClassName: ` self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-2 xl:pr-6 text-[13px] 3xl:text-[15px] tracking-tight text-white ${
            getFont('SPRE').body
        }`,
        loyalieFooterContent:
            'Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 | Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 | maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in',
        loyalieFooterContentClassName: `self-center  md:flex-1 mt-4 md:mt-0 pl-10 pr-10 lg:pl-6 lg:pr-4 xl:pl-4 xl:pr-6 text-[11px] 3xl:text-[13px] tracking-tight text-white ${
            getFont('SPRE').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-extrabold text-[17.px] ${
            getFont('SPRE').heading
        }`,
        paginationClass:
            ' text-black disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-primary border-primary hover:bg-opacity-90 hover:border-primary hover:bg-primary disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-black bg-opacity-80'
    },
    referralForm: {
        name: 'Shapoorji Pallonji Real Estate',
        theme: 'spreTheme',
        color: '#ccab5c',
        backgroundImage: 'https://cdn.loyalie.in/76827468.jpg',
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '0',
            G1: '0',
            B1: '0',
            opacity1: '0.6',
            R2: '0',
            G2: '0',
            B2: '0',
            opacity2: '0.5'
        },
        showLoginScreen: true,
        userTypes: {
            length: 3,
            siteVisitor: false,
            siteVisitorHeading1: 'I have visited an',
            bookedCustomer: true,
            bookedCustomerHeading1: 'I own a',
            employee: true,
            employeeHeading1: 'I am a',
            salesAdmin: true,
            salesAdminHeading1: 'I am a',
            influencer: false,
            influencerHeading: ''
        },
        formContent: {
            heading: '"CONSTANTLY COMMITED TO DELIGHT"',
            headingClass: `self-center text-white sm:mt-0 text-2xl md:text-3xl xl:text-4xl w-[95%] md:w-[80%] lg:w-[90%] xl:w-[90%] text-center leading-7 md:leading-10 tracking-wide ${
                getFont('SPRE').heading
            }`,
            notification: {
                line1: ' ',
                line1Class: `
            `,
                line2: ' ',
                line2Class: ``,
                line3: ' ',
                line3Class: ``
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='white'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='%23ccab5c' stroke-width='7' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='7' stroke-dasharray='388'/%3E%3C/svg%3E",
        leadEmailMandatory: false,
        sendReferralAttemptNotification: false,
        isDirectTAndCLink: false,
        influencerLinkActive: true,
        termsAndConditionsLink: '/clubbenefique-website/terms-and-conditions',
        customLink: '/clubbenefique-website/projects/detail',
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: false,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: false,
        footerDisclaimerContent: ''
    }
};
export default SpreConfig;
